import React, { Component } from 'react';

import { observer } from 'mobx-react';

import { Button } from '../../../../components'

import PropTypes from 'prop-types';

import AddInsuredModal from '../add-insured-modal/AddInsuredModal'
import AddMemberCardModal from '../../../family-details-form/components/AddMemberCardModal';
import FamilyProtectionStore from '../../store/FamilyProtectionStore'

import MemberCard from './MemberCard'


import './family-collapse-view.css'
import { PlusIcon } from 'constants/Images';

const NeedCard = observer(class NeedCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isCollapseOpen: false,
            disabled: false,
            showInsuredMemberModal: false,
            showAddFamilyMemberModal: false,
            selectedCollapseKey: [0]
        }
    }

    onAddInsured = (e) => {
        // e.stopPropagation();
        console.log('add insured')
        this.setState({ showInsuredMemberModal: true })
    }


    onCollapseChange = (value, keyIndex) => {
        // console.log('onCollapseChange >> ', value);
        const { disabled } = this.props;
        if (!disabled) {

            if (value) {
                this.state.selectedCollapseKey.push(keyIndex)
            } else {
                this.state.selectedCollapseKey.map((item, index) => {
                    if (keyIndex == item) {
                        this.state.selectedCollapseKey.splice(index, 1);
                    }
                })
            }
            this.setState({ isCollapseOpen: value })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.disabled && this.props.disabled) {
            this.setState({ isCollapseOpen: false })
        }
    }

    onAddInsuredFamily = (value) => {
        this.setState({ showInsuredMemberModal: false, isCollapseOpen: false })
    }

    onAddFamilyMember = (value) => {
        console.log('ADD MEMBER >> ', value)
        this.setState({ showAddFamilyMemberModal: true })
    }


    addInsuredOnHide = () => {
        this.setState({ showInsuredMemberModal: false })
    }

    onRemoveInsured = (e, data) => {
        e.stopPropagation();
        FamilyProtectionStore.deleteFamilyMember(data)
    }

    componentDidMount() {
        
    }

    render() {
        const { isCollapseOpen, disabled, showInsuredMemberModal, showAddFamilyMemberModal, selectedCollapseKey, onRemoveInsured } = this.state;
        const { data, title, cardKey } = this.props;
        console.log('NEED CARD RENDER >> ', data)

        return (
            <div className='family-collapase-view'>
                <div className='d-flex justify-content-between align-items-center pb-md-3'>
                    <div className='d-md-flex d-block align-items-center'>
                        <span className='font-size-20 font-size-md-24 font-weight-bold text-header-style'>{title}</span>
                        {!disabled &&
                            <div className='select-deselect-btn'>
                                <Button
                                    className='font-size-14 font-size-md-20 px-md-1 px-0 pt-md-3 pt-0'
                                    variant='link'
                                    buttonText={disabled ? 'Select' : 'Deselect'}
                                    onClick={() => this.setState({ disabled: !disabled, isCollapseOpen: false, selectedCollapseKey: [] })}
                                />
                            </div>
                        }
                    </div>
                    <div>
                        {disabled ?
                            <div className='select-deselect-btn'>
                                <Button
                                    className='font-size-14 font-size-md-20 px-md-1 px-0 pt-md-3'
                                    variant='link'
                                    buttonText={disabled ? 'Select' : 'Deselect'}
                                    onClick={() => this.setState({ disabled: !disabled, isCollapseOpen: false, selectedCollapseKey: [] })}
                                />
                            </div> :
                            <Button variant='light' onClick={(e) => this.onAddInsured(e)} role='button' className='rounded title-button px-3 py-1 py-md-3 font-size-13 font-weight-medium add-insured-btn-style d-flex align-items-center px-md-4 py-md-3'><img color='red' className='mr-2 mr-md-3 add-insured-img-style' src={PlusIcon} alt='plus-icon' />Add Insured</Button>
                        }
                    </div>
                </div>
                {
                    data.map((item, index) => {
                        return (
                            <MemberCard
                                key={index}
                                index={index}
                                disabled={disabled}
                                open={isCollapseOpen}
                                onChange={this.onCollapseChange}
                                selectedCollapseKey={selectedCollapseKey}
                                onRemoveInsured={onRemoveInsured}
                                data={item}
                                cardKey={cardKey}
                                onAddMember={this.onAddFamilyMember}
                                onAddInsured={this.onAddInsured}
                            />
                        )
                    })
                }
                <AddInsuredModal cardKey={cardKey} show={showInsuredMemberModal} onAddMember={this.onAddFamilyMember} onAddInsured={this.onAddInsuredFamily} onHide={this.addInsuredOnHide} />
                <AddMemberCardModal isUpdateDetails={true} show={showAddFamilyMemberModal} onHide={() => this.setState({ showAddFamilyMemberModal: false })} />
            </div>
        );
    }
});

NeedCard.defaultProps = {
    disabled: false
}

NeedCard.propTypes = {
    cardKey: PropTypes.string.isRequired,
    data: PropTypes.array,
    disabled: PropTypes.bool
}

export default NeedCard;