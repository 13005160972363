// Package Imports
const { Form } = require("mobx-react-form");
import yup from "mobx-react-form/lib/validators/YUP";
import vjf from "mobx-react-form/lib/validators/VJF";
import * as $pkg from "yup";
import validator from "validator";

import { NUMBER_REGEX,EMAIL_REGEX } from 'constants/Constants'

// Project Imports
import formObserver from "./observer";
import initialDefaults from "./initialDefaults";
import {  validationForEmailAndMobileNumber,validationForName } from "./validation";
import AuthStore from "modules/login/store/AuthStore";

class CustomerCommunicationDetailsController extends Form {
  plugins() {
    return {
      vjf: vjf(validator),
      // yup: yup({
      //   package: $pkg,
      //   schema: (_yup) =>
      //     _yup.object().shape({
      //       journey_id: _yup.string().optional(),
            // email:_yup.string().email('Must be a valid email').max(255).required('Email is required'),
            // mobile_number:_yup.string().matches(phoneRegExp, 'Phone number is not valid')
            // journey_type: _yup.object().shape({
            //   id: _yup.mixed().required("This field is mandatory"),
            // }),
            // location: _yup.array().min(1, "This field is mandatory").required(),
            // customer_type: _yup.string().required(),
            // existing_customer_type_option_list: _yup.object().shape({
            //   id: _yup.mixed().optional(),
            // }),

            // existing_customer_value: _yup.string().optional(),
            // category: _yup.object().shape({
            //   id: _yup.mixed().required("This field is mandatory"),
            // }),
            // employee_code: _yup.string().optional(),
            // occupation: _yup.object().shape({
            //   id: _yup.mixed().required("This field is mandatory"),
            // }),
            // education: _yup.object().shape({
            //   id: _yup.mixed().required("This field is mandatory"),
            // }),
            // pfa_sp_code: _yup.string().optional(),
            // pfa_sp_name: _yup.string().optional(),
            // channel_name: _yup.string().optional(),
            // sales_branch: _yup.string().optional(),
            // dmrm_code: _yup.string().optional(),
            // dmrm_name: _yup.string().optional(),
            // partner_rm_code: _yup.string().optional(),
            // partner_branch_name: _yup.string().optional(),
            // is_sp_code_editable: _yup.mixed().optional(),
            // proceed_btn: _yup.mixed().optional(),
            // ulip_training_code: _yup.mixed().optional(),
            // posp_code: _yup.mixed().optional(),
          // }),
      // }),
    };
  }

  setup() {
    return {
        
    //   fields: [
    //    {
    //     name: "family_member_one",
    //     label: "Family Member 1",
    //     disabled: false,
    //     fields: [
    //       {
    //         name: "first_name",
    //         type: "text",
    //         placeholder: "First Name",
    //         value: "",
    //         validators: [validationForName],
    //       },
    //       {
    //        name: "middle_name",
    //        type: "text",
    //        placeholder: "Middle Name",
    //        value: "",
    //        validators: [validationForName],
    //      },
    //      {
    //       name: "last_name",
    //       type: "text",
    //       placeholder: "Last Name",
    //       value: "",
    //       validators: [validationForName],
    //     },
    //     {
    //      name: "mobile_number_code",
    //      type: "text",
    //      placeholder: "+91",
    //      value: "+91",
    //    },
    //    {
    //     name: "mobile_number",
    //     type: "text",
    //     placeholder: "XXXXXXXXXX",
    //     value: "",
    //     validators: [validationForEmailAndMobileNumber],
    //   },
    //   {
    //    name: "email",
    //    type: "text",
    //    placeholder: "email@website.com",
    //    value: "",
    //    validators: [validationForEmailAndMobileNumber],
    //   },
    //   {
    //    name: "whatsapp_mobile_number_code",
    //    type: "text",
    //    placeholder: "+91",
    //    value: "+91",
    //   },
    //   {
    //    name: "whatsapp_mobile_number",
    //    type: "text",
    //    placeholder: "XXXXXXXXXX",
    //    value: "",
    //    validators: [validationForEmailAndMobileNumber],
    //   },
    //   {
    //    name: "same_as_mobile_number",
    //    label: "same as above",
    //    disabled: false,
    //  },
    //     ],
    //   }
    //   ],
    };
  }

  setDefaults(journeyData, journeyId) {
    initialDefaults(this, journeyData, journeyId);
    // Observer fields
    formObserver(this);
  }
}

export default CustomerCommunicationDetailsController;
