import dvr from 'mobx-react-form/lib/validators/DVR';
import vjf from 'mobx-react-form/lib/validators/VJF';

import { Form } from 'mobx-react-form';
import validatorjs from 'validatorjs';

import Utils from './utils';
import formObserver from './observers';
import setInitialDefaults from './initialDefaults.js';
import * as keys from './keys';

class CriticarePlusFormController extends Form {

  plugins() {
    return {
      dvr: dvr(validatorjs),
      vjf: vjf()
    }
  }

  setup() {
    return {
      fields: [
        {
          name: "premium",
          label: "Premium",
          placeholder: "Premium",
          value: '',
          disabled: false,
          extra: {
            calculatePremium: (form, data) => {
              // let premium = form.$('premium').value
              // let productId = data.data.product_id
              // Utils.updateBi(keys.PREMIUM, premium, productId, form, data)
            }
          }
        },
        {
          name: "income",
          label: "Income",
          placeholder: "Income",
          value: '',
          disabled: false,
        },
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [],
          }
        },
        {
          name: "premium-paying-term",
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [],
          },
          disabled: true
        },
        {
          name: 'payment-frequency',
          label: 'Premium Paying Frequency',
          placeholder: 'Premium Paying Frequency',
          disabled: true,
          extra: {
            optionList: [
              { id: 1, label: 'Annual' }
            ]
          }
        },
        {
          name: 'claim-option',
          label: 'Claim Option',
          placeholder: 'Claim Option',
          disabled: false,
          extra: {
            optionList: [
              { id: '1', label: 'Single Claim' },
              { id: '2', label: 'Multi Claim' }
            ]
          }
        },
      ]
    }
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data)

    // Observer fields
    formObserver(this, data)
  }


}

export default CriticarePlusFormController;