import AuthStore from '../../../../login/store/AuthStore';
import errorMessage from './errorMessage';
import moment from 'moment';
import Utils from '../../../../../constants/utils';
import OptimizationStore from '../../../store/OptimizationStore';
import fetchRiderValidation from 'utils/fetchRiderValidation'

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    // tru or false
    errorMessage = [];

    // Entry age validation for plan option
    validatePlanOption = (form, data) => {
        let age = data.data.age;
        let ppt = form.$('premium-term').value.id
        let planOption = form.$('plan-option').value.id
        let isLevelSumAssured = planOption == 1;

        if (isLevelSumAssured) {
            // Level Sum Assured
            if (ppt == 60) {
                if (age > 50) {
                    this.errorMessage.push('Maximum entry age for Life Cover With Level Sum Assured for Pay Till 60 option is 50')
                }
            } else {
                if (age > 65) {
                    this.errorMessage.push('Maximum entry age for Life Cover With Level Sum Assured for Regular Pay option is 65')
                }
            }
        } else {
            // Decreasing Sum Assured
            if (ppt == 60) {
                if (age > 50) {
                    this.errorMessage.push('Maximum entry age for Life Cover Decreasing Sum Assured for Pay Till 60 option is 50')
                }
            } else {
                if (age > 55) {
                    this.errorMessage.push('Maximum entry age for Life Cover Decreasing Sum Assured for Regular Pay option is 65')
                }
            }
        }
    }

    // Minimum PT for normal & Pay till 60
    validPt = (form) => {
        let ppt = form.$('premium-term').value.id
        let pt = form.$('policy-term').value.id

        if (ppt == 60) {
            if (pt < 15) {
                this.errorMessage.push(errorMessage.MINIMUM_60_PAY_PT)
            }
        } else {
            if (pt < 10) {
                this.errorMessage.push(errorMessage.MINIMUM_REGULAR_PAY_PT)
            }
        }
    }

    // Minimum Premium Validation
    minimalAnnualPremium = (form) => {
        let frequency = form.$('payment-frequency').value.id.toString()
        let premium = form.$('premium-amt').value

        switch (frequency) {
            case '1':
                if (premium < 3000) {
                    this.errorMessage.push('Minimum Annual premium is 3000')
                }
                break;

            case '2':
                if (premium < 2000) {
                    this.errorMessage.push('Minimum Half-Yearly premium is 2000')
                }
                break

            case '3':
                if (premium < 1250) {
                    this.errorMessage.push('Minimum Quarterly premium is 1250')
                }
                break;

            case '4':
                if (premium < 500) {
                    this.errorMessage.push('Minimum Monthly premium is 500')
                }
                break;
        }
    }

    // Allow only top-Up Benefit or Wop Benefit
    topUpOrWopBenefit = (form) => {
        let wopBenefit = form.$('WaiverOfPremiumRider').value;
        let topUpBenefit = form.$('top-up-benefit').value;

        if (wopBenefit && topUpBenefit) {
            this.errorMessage.push('You can either choose Top Up Benefit or Waiver or Premium Benefit')
        }
    }

    // Top up Benefit is not available for Pay Till 60
    noTopUpBenefit = (form) => {
        let ppt = form.$('premium-term').value.id;
        let topUpBenefit = form.$('top-up-benefit').value;

        if (topUpBenefit && ppt == 60) {
            this.errorMessage.push('Top Up Benefit is not available for Pay Till 60 Option')
        }
    }

    // Wop is available for only regular pay
    wopForReguarPay = (form) => {
        let ppt = form.$('premium-term').value.id;
        let wopBenefit = form.$('WaiverOfPremiumRider').value;

        if (wopBenefit && ppt == 60) {
            this.errorMessage.push('Waiver of Premium Benefit is applicable for Regular Pay')
        }
    }

    // Better half benefit is only applicable for same proposar & LA
    betterHalfBenefitForLa = (form, data) => {
        let betterHalfBenefit = form.$('better-half-benefit').value;
        let isSameProposer = data.data.bi_request.SameProposer == true
        let spouseName = form.$('spouse-name').value
        let gender = form.$('spouse-gender').value
        let nationality = form.$("nationality").value
        let dob = form.$('spouse-dob').value
        let maritalStatus = form.$('marital-status').value.id

        
        if (betterHalfBenefit && !isSameProposer) {
            this.errorMessage.push('Better Half Benefit is available for same Proposer & La')
        }
        
        // if (betterHalfBenefit && maritalStatus == "10") {
        //     if ((spouseName == '' || gender == '' || dob == '')) {
        //         this.errorMessage.push(errorMessage.BETTER_HALF_BENEFIT_VALIDATION)
        //     }
        // }
        
        // if(betterHalfBenefit && maritalStatus == "10" && nationality.id !== 1){
        //     this.errorMessage.push(errorMessage.SPOUSE_NATIONALITY_VALIDATION)
        // }
    }

    // Wop is only applicable for Level Sum Assured
    wopForLevelSumAssured = (form) => {
        let wopBenefit = form.$('WaiverOfPremiumRider').value;
        let planOption = form.$('plan-option').value.id
        let isLevelSumAssured = planOption == 1;

        if (wopBenefit && !isLevelSumAssured) {
            this.errorMessage.push('Waiver of Premium Benefit is only available for Level Sum Assured Plan Option');
        }

    }

    // Second Life Assured name validation
    validateSecondLifeAssuredName = (formRef) => {
        let spouseName = formRef.$('spouse-name').value
        let gender = formRef.$('spouse-gender').value
        let dob = formRef.$('spouse-dob').value
        let maritalStatus = formRef.$('marital-status').value.id
        if (maritalStatus == '9') { return }

        if ((spouseName == '' || gender == '' || dob == '')) {
            this.errorMessage.push(errorMessage.SPOUSE_DETAILS_MANDATORY)
        }

        if (spouseName.length > 20) {
            this.errorMessage.push(errorMessage.SPOUSE_NAME)
        }
    }

    // Second Life Assured Gender validation
    validateSecondLifeAssuredGender = (formRef, data) => {
        let gender = data.data.bi_request.LI_GENDER;
        let spouseGender = formRef.$('spouse-gender').value;

        if (spouseGender.label == undefined) return;
        
        if (gender === spouseGender.value) {
            this.errorMessage.push(errorMessage.SPOUSE_GENDER)
        }
    }

    // rider sum assured validation
    riderSAValidation = (form, productData) => {
        let sum_assured = productData.data.firstYearResponse.SA

        if (form.$('ADB').value) {
            var ADB_value = Number(form.$('ADB-dropdown').value)
            if (ADB_value > sum_assured) {
                this.errorMessage.push(errorMessage.rider)
            }
        }

        if (form.$('ATPDRider').value) {
            var ATPDRider_value = Number(form.$('ATPDRider-dropdown').value)
            if (ATPDRider_value > sum_assured) {
                this.errorMessage.push(errorMessage.rider)
            }
        }

        if (form.$('CriticalIllnessRider').value) {
            var CriticalIllnessRider_value = Number(form.$('CriticalIllnessRider-dropdown').value)
            if (CriticalIllnessRider_value > sum_assured) {
                this.errorMessage.push(errorMessage.rider)
            }
        }

        if (form.$('HospitalityCashBenefitRider').value) {
            var HospitalityCashBenefitRider_value = Number(form.$('HospitalityCashBenefitRider-dropdown').value)
            if (HospitalityCashBenefitRider_value > sum_assured) {
                this.errorMessage.push(errorMessage.rider)
            }
        }
    }

    // Validate PT PPT
    validatePtPPt = (form) => {
        // let ppt = form.$('premium-term').value.id
        // let pt = form.$('policy-term').value.id

        // if (ppt == 60 || ppt == pt) {
        //     return
        // } else {
        //     this.errorMessage.push(errorMessage.PT_PPT)
        // }
    };

    // Spouse age validation for plan option
    validatePlanOption = (form, data) => {
        let value = form.$('spouse-dob').value
        let dob = moment(value).year()
        let currentYear = moment().year()
        let age = currentYear - dob;

        let ppt = form.$('premium-term').value.id
        let planOption = form.$('plan-option').value.id
        let isLevelSumAssured = planOption == 1;

        if (isLevelSumAssured) {
            // Level Sum Assured
            if (ppt == 60) {
                if (age > 50) {
                    this.errorMessage.push('Maximum entry age of spouse for Life Cover With Level Sum Assured for Pay Till 60 option is 50')
                }
            } else {
                if (age > 65) {
                    this.errorMessage.push('Maximum entry age of spouse for Life Cover With Level Sum Assured for Regular Pay option is 65')
                }
            }
        } else {
            // Decreasing Sum Assured
            if (ppt == 60) {
                if (age > 50) {
                    this.errorMessage.push('Maximum entry age of spouse for Life Cover Decreasing Sum Assured for Pay Till 60 option is 50')
                }
            } else {
                if (age > 55) {
                    this.errorMessage.push('Maximum entry age of spouse for Life Cover Decreasing Sum Assured for Regular Pay option is 65')
                }
            }
        }
    }

    // Validation based on riders
    ridersAgeValidation = (formRef, productData) => {
        let age = productData.data.bi_request.LI_ENTRY_AGE
        let criticalIllness = formRef.$('CriticalIllnessRider').value
        let adb = formRef.$('ADB').value
        let atpd = formRef.$('ATPDRider').value
        let hospitality = formRef.$('HospitalityCashBenefitRider').value
        let isMinimumAge = (age < 18)
        let isMaximumAge = (age > 65)

        let isRiderSelected = (adb || atpd || criticalIllness || hospitality);

        if (!isRiderSelected) {
            return;
        }

        if (isMinimumAge) {
            this.errorMessage.push(errorMessage.MINIMUM_RIDER_AGE)
        }
        if (isMaximumAge) {
            this.errorMessage.push(errorMessage.MAXIMUM_RIDER_AGE)
        }
    }

    // Riders Validation
    ridersValidation = (formRef, productData) => {
        let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
        let adb = formRef.$('ADB-dropdown').value
        let atpd = formRef.$('ATPDRider-dropdown').value

        let adbValidation = fetchRiderValidation(adb, formRef.$('ADB-dropdown').label)
        if (adbValidation) {
            this.errorMessage.push(adbValidation)
        }

        let atpdValidation = fetchRiderValidation(atpd, formRef.$('ATPDRider-dropdown').label)
        if (atpdValidation) {
            this.errorMessage.push(atpdValidation)
        }

        let criticalIllnessValidation = fetchRiderValidation(criticalIllness, formRef.$('CriticalIllnessRider-dropdown').label)
        if (criticalIllnessValidation) {
            this.errorMessage.push(criticalIllnessValidation)
        }
    }
}
export default new Validator();