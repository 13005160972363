import Utils from "./utils";
import * as keys from "./keys";

const FormObserver = (formRef, props) => {
  formRef.$("policy-term").observe({
    key: "value",
    call: ({ field }) => {
      formRef.$("premium-term").value = field.value;
      Utils.updateBi(
        keys.PR_PT,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("tobacco-user").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.LI_SMOKE,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("plan-option").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.PLAN_OPTION,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("premium-term").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.PR_PPT,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("payment-frequency").observe({
    key: "value",
    call: ({ field }) => {
      let premium = formRef.$('premium-amt').value
      let frequency = field.value.id
      let data = {
        premium: premium,
        frequency: frequency
      }
      Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)

    },
  });


  formRef.$("underwriting-status").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(keys.UNDERWRITING, field.value.id, props.data.product_id, formRef, props
      );
    },
  });
  

  formRef.$("marital-status").observe({
    key: "value",
    call: ({ field }) => {
      if(Number(field.value.id) === 9) {
        formRef.$("better-half-benefit").value = false;
      }
      Utils.updateBi(
        keys.MARITAL_STATUS,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  // Additional Benefits

  formRef.$("WaiverOfPremiumRider").observe({
    key: "value",
    call: ({ field }) => {
      let waiverPremiumBenefit = {
        additionalBenefit: field.value ? "8" : formRef.$('top-up-benefit').value ? "6" : "5",
        topUp: formRef.$('top-up-benefit').value ? "14" : "",
      };
      
      Utils.updateBi(
        keys.ADDTIONAL_BENEFIT,
        waiverPremiumBenefit,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("better-half-benefit").observe({
    key: "value",
    call: ({ field }) => {
      let benefit = field.value ? "12" : "13";
      Utils.updateBi(
        keys.BETTER_HALF_BENEFIT,
        benefit,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("top-up-benefit").observe({
    key: "value",
    call: ({ field }) => {
      let topUpBenefit = {
        additionalBenefit: field.value ? "6" : formRef.$('WaiverOfPremiumRider').value ? "8" : "5",
        topUp: field.value ? "14" : "",
      };
      Utils.updateBi(
        keys.ADDTIONAL_BENEFIT,
        topUpBenefit,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("top-up-benefit-dropdown").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.TOP_UP_BENEFIT,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$('ADB').observe({
    key: 'value',
    call: ({ field }) => {
      let adbValue = formRef.$('ADB-dropdown').value
      Utils.updateBi(keys.ADB_RIDER, adbValue, props.data.product_id, formRef, props)
    }
  })

  formRef.$('ATPDRider').observe({
    key: 'value',
    call: ({ field }) => {
      let atpdValue = formRef.$('ATPDRider-dropdown').value
      Utils.updateBi(keys.ATPD_RIDER, atpdValue, props.data.product_id, formRef, props)
    }
  })

  formRef.$('CriticalIllnessRider').observe({
    key: 'value',
    call: ({ field }) => {
      let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
      Utils.updateBi(keys.Critical_ILLNESS_RIDER, criticalIllness, props.data.product_id, formRef, props)
    }
  })

  formRef.$('HospitalityCashBenefitRider').observe({
    key: 'value',
    call: ({ field }) => {
      let hospitalityCashBenefit = formRef.$('HospitalityCashBenefitRider-dropdown').value
      Utils.updateBi(keys.HOSPITALITY_CASH_BENEFIT_RIDER, hospitalityCashBenefit, props.data.product_id, formRef, props)
    }
  })

  formRef.$('spouse-gender').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.SPOUSE_GENDER, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('spouse-tobacco-consumer').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.SPOUSE_TOBACCO_CONSUMER, field.value.id, props.data.product_id, formRef, props)
    }
  })
};

export default FormObserver;
