import { CircularProgressbar } from "components"
import React from "react"

import "./solution-screen-la-details.css"

const SolutionScreenLADetails = ({ LaName, ProductName, amount,percentValue }) => {
  return (
    <div className=" text-left text-md-center la-details py-md-1 d-flex justify-content-around d-md-block pt-5">

      <div className="d-md-none ">
      <CircularProgressbar
        value={percentValue}
        valuelabel="covered"
        isSingleImg={true}
        laName=""
        size="" // pass small for smaller doughnut chart and empty string for default doughnut
      />
      </div>

      <div className="">
        <p className="mb-2 mb-md-1   font-size-16 font-weight-medium la-name">
          [{LaName}]
        </p>

        <p className="mb-0 mb-md-1 font-size-14 font-size-md-12 font-weight-medium product-name">
          [{ProductName}]
        </p>

        <p className="mb-0 font-size-14 font-weight-light">
          {" "}
          <span className="year-value">
            <span className="font-weight-bold">1</span>st year
          </span>{" "}
          Total Premium Payable
        </p>

        <p className="mb-1 font-size-12 font-weight-light">[Product + Rider]</p>

        <p className="font-size-28 font-weight-medium amount m-0">
          {global.toINR(amount)}
        </p>
      </div>
    </div>
  )
}

export default SolutionScreenLADetails
