// React Imports 
import React, { Component } from "react";

// Package Imports
import { Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import AuthStore from "modules/login/store/AuthStore";

// Project Imports
import PreNeedSelectionModalStore from "../store/PreNeedSelectionModalStore";
import PreNeedForm from './form'
import PreNeedFormController from "./form/controller";
import utils from "./form/utils";
import * as images from "constants/Images";

// CSS Imports
import "./pre-need-selection-modal.css";

const PreNeedSelectionModal = observer(
  class PreNeedSelectionModal extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isOpen: false,
        formController: null,
        targetScreen: 'choose-needs',
        newJourney: false,
        refreshValues: false
      };
    }

    async componentDidMount() {

      let isOpen = false;
      let controller = new PreNeedFormController()
      let journeyData = toJS(AuthStore.journeyData);
      let journeyId = this.props.match.params.journeyId

      controller.setDefaults(journeyData, journeyId);

      if (journeyId === undefined) {
        isOpen = true
      }

      this.setState({
        isOpen: isOpen,
        formController: controller
      })
      PreNeedSelectionModalStore.fetchMasterData()
    }

    showModalPreNeedSelectionModal = () => {
      this.setState({ isOpen: true });
    };

    hideModalPreNeedSelectionModal = () => {
      this.setState({ isOpen: false });
    };

    onProceedClick = async () => {
      let journeyId = await PreNeedSelectionModalStore.onPreNeedProceedBtnClick()
      if (journeyId === undefined) return
      this.hideModalPreNeedSelectionModal()
      AuthStore.journeyId = journeyId
      this.props.history.push(`/choose-needs/${journeyId}`);
    }

    formSuccessHandler = async (responseData) => {
      let journeyId = responseData.data.journey_id
      await AuthStore.fetchAllData(journeyId);
      this.state.formController.$("journey_id").value = journeyId.toString();
      this.hideModalPreNeedSelectionModal()
      this.props.history.push(`/${this.state.targetScreen}/${journeyId}`, { refreshValues: this.state.refreshValues })
    }

    formErrorHandler = (responseData) => {
      console.log("Form Error")
    }

    checkJourneyFlow = (formValues) => {
      let journeyData = toJS(AuthStore.journeyData)
      let customerType = formValues.customer_type === "New Customer" ? 1 : 2
      let selectedPage = this.props.match.params.selectedPage

      let userDetails = journeyData.family_details_list && journeyData.family_details_list[0]
      let productsList = journeyData.family_product_details_list
      let termProduct = productsList && productsList.length > 0 && productsList.find(i => i.need_unique_name === "protection_for_my_family")
      let healthProduct = productsList && productsList.length > 0 && productsList.find(i => i.need_unique_name === "protection_for_my_health")

      this.setState({ newJourney: false, targetScreen: selectedPage, refreshValues: false })
      switch(selectedPage) {
        case "choose-needs":
        case "family-details":
        case "customization":
            // case 1 & Case 2 : User changing the journey type and details of the existing customer and/or Staff member
            if(journeyData.journey_details && journeyData.journey_details.journey_type != formValues.journey_type.id || journeyData.journey_details && journeyData.journey_details.customer_type != customerType){
              this.setState({ newJourney: true, targetScreen: "choose-needs" })
              return
            }
    
            // Case 3 : User changing the category
            if(journeyData.journey_details && journeyData.journey_details.category !== formValues.category.id){
              return
            }
            
            // Check if the product "protection_for_my_family" and it's calculator is selected, if not return
            if(termProduct && !termProduct.is_selected && !termProduct.is_calculator_used) {
              return
            }

            if(journeyData.family_details_list && (userDetails.education !== formValues.education.id || userDetails.occupation !== formValues.occupation.id)) {
              if(termProduct && termProduct.is_selected && termProduct.is_calculator_used){
                this.setState({ refreshValues: true, targetScreen: "choose-needs" })
                return
              }else if(termProduct && termProduct.is_selected && !termProduct.is_calculator_used){
                return
              }
            }
          break
        case "solution":
            // case 1 & Case 2 : User changing the journey type and details of the existing customer and/or Staff member
            if(journeyData.journey_details && journeyData.journey_details.journey_type != formValues.journey_type.id || journeyData.journey_details && journeyData.journey_details.customer_type != customerType){
              this.setState({ newJourney: true, targetScreen: "choose-needs" })
              return
            }
    
            // Case 3 : User changing the category
            if(journeyData.journey_details && journeyData.journey_details.category !== formValues.category.id){
              this.setState({ targetScreen: "customization" })
              return
            }

            // Check if the product "protection_for_my_family" and it's calculator is selected, if not return
            if(termProduct && !termProduct.is_selected && !termProduct.is_calculator_used) {
              return
            }

            if(journeyData.family_details_list && (userDetails.education !== formValues.education.id || userDetails.occupation !== formValues.occupation.id)) {
              if(termProduct && termProduct.is_selected && termProduct.is_calculator_used){
                this.setState({ refreshValues: true, targetScreen: "choose-needs" })
                return
              }else if(termProduct && termProduct.is_selected && !termProduct.is_calculator_used){
                this.setState({ targetScreen: "customization" })
                return
              }

              if(healthProduct && healthProduct.is_selected){
                this.setState({ targetScreen: "customization" })
                return
              }
            }
          break
      }
    }

    submitData = () => {
      this.state.formController.validate({ showErrors: true })
        .then(({ isValid }) => {
          if (isValid) {
            let formValues = this.state.formController.values()
            // Determine what user is changing and redirect accordingly
            if(formValues.pfa_sp_code){
              this.checkJourneyFlow(formValues)
              utils.submitDataToTheSrver(this.state.formController.values(), this.formSuccessHandler, this.formErrorHandler, this.state.newJourney)
            } else {
              this.state.formController.$("pfa_sp_code").invalidate('This field is mandatory')
            }
          } else {
            console.log("Form errors are ", this.state.formController.errors())
          }
        });
    }


    render() {
      let journeyTypeName = toJS(AuthStore.journeyTypeName)
      return (
        <div className='welcomeScreeenModalDiv d-flex align-items-center'>
          {journeyTypeName !== undefined && (
            <span className='font-size-lg-16 font-weight-light'>
              {journeyTypeName} | New Customer
            </span>
          )}
          <a onClick={this.showModalPreNeedSelectionModal} className='btn py-0 text-white d-flex flex-row align-items-center'>
            <Image width='12' height='24' src={images.EditIcon} alt='back-arrow' />
            <span className="font-size-lg-12 font-weight-light">Edit</span>
          </a>
          <Modal
            show={this.state.isOpen}
            backdrop="static"
            onHide={this.hideModalPreNeedSelectionModal}
            as='section'
            className='welcomeScreeenModal'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            keyboard={false}
          >
            <ModalBody className='preNeedSelectionModalBody'>
              <section>
                <div className='row preNeedSelectionModalContent'>
                  <div className='modal-hero'>
                    <div className='mr-lg-2 py-4 my-3 text-center text-lg-right'>
                      <h1 className='preHeading text-lg-right'>Welcome to</h1>
                      <h1 className='heading text-lg-right'>
                        NEED ANALYSIS <span>BI</span>
                      </h1>
                      <p className='subHeading text-lg-right d-none d-lg-block'>
                        Customer Engagement and <br /> Quote Generation Tool
                      </p>
                    </div>
                  </div>

                  <PreNeedForm
                    formController={this.state.formController}
                    hideModal={this.hideModalPreNeedSelectionModal}
                    submitData={this.submitData}
                    {...this.props}
                  />

                </div>
              </section>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);

export default PreNeedSelectionModal;
