// React Imports
import React from "react"

// Package Imports
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal"
import { observer } from "mobx-react"

// project imports
import GrowMyMoneyModalForm from "./grow-my-money-modal-form";

// CSS Imports
import "./add-insured-grow-my-money-pop-up-modal.css"

const AddInsuredGrowMyMoneyPopUpModal = (props) => {
  

      return (
        <div className="add-insured-pop-up-div d-flex align-items-center">
          <Modal
            show={props.showGrowMyMoneyModal}
            backdrop="static"
            onHide={props.hideGrowMyMoneyPopUpModal}
            as="section"
            className="add-insured-pop-up-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <GrowMyMoneyModalForm
              hideGrowMyMoneyPopUpModal={props.hideGrowMyMoneyPopUpModal}
              relation={props.relation}
              memberName={props.memberName}
              age={props.age}
            />
          </Modal>
        </div>
      )
  }

  AddInsuredGrowMyMoneyPopUpModal.propTypes = {
    showGrowMyMoneyModal:PropTypes.bool,
    hideGrowMyMoneyPopUpModal:PropTypes.func.isRequired,

  };

export default observer(AddInsuredGrowMyMoneyPopUpModal)
