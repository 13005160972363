import { observable, action, makeObservable, computed } from "mobx";
import { toJS } from "mobx";
import moment from "moment";

import axios from "../../../utils/axios";
import * as APITypes from "../../../constants/API";
import AuthStore from "../../login/store/AuthStore";
import FamilyDetailsStore from "../../family-details-form/store/FamilyDetailsStore";

import isObjectEmpty from "utils/isObjectEmpty";
import AIPUtils from "../components/products/active-income-plan/utils";
import smartLifeStyleUtils from "../components/products/smart-life-style/utils";
import wealthSecurePlusUtils from "../components/products/wealth-secure-plus/utils";
import dhanLabhUtils from "../components/products/dhan-labh-plus/utils";
import wealthUltimaUtils from "../components/products/wealth-ultima/utils";
import wealthGainPlusUtils from "../components/products/wealth-gain-plus/utils";
import wealthPlusUtils from "../components/products/wealth-plus/utils";
import cashFlowProtectionPlusUtils from "../components/products/cashflow-protection-plus/utils";
import wealthPremierUtils from "../components/products/wealth-premier/utils";
import gcapUtils from "../components/products/gcap/utils";
import immediateAnnuityUtils from "../components/products/immediate-annuity-plan/utils";
import posUtils from "../components/products/POS-saral-nivesh/utils";
import incomeBuilderUtils from "../components/products/income-builder/utils";
import zindagiPlusUtils from "../components/products/zindagi-plus/utils";
import simplyProtectUtils from "../components/products/simply-protect/utils";
import criticarePlusUtils from "../components/products/criticare-plus/utils";
import easyPensionUtils from "../components/products/easy-pension/utils";
import singlePayUtils from "../components/products/single-pay-endowment-assurance-plan/utils";
import protectionUtils from "../components/products/protection/utils";
import PGIUtils from "../components/products/premium-guaranteed-income/utils"
import createNewObject from "utils/createNewObject";
import fetchNeedDetailsBasedonNeedUniqueName from "utils/fetchNeedDetailsBasedonNeedUniqueName";
import FamilyMemberModalTypeEnum from "constants/familyMemberModalTypeEnum";
import StorageKeys from "constants/StorageKeys";
import SaralPensionUtils from "../components/products/saral-pension/utils";
import ForeverPensionUtils from "../components/products/forever-pension/utils";
import SaralJeevanBimaUtils from "../components/products/saral-jeevan-bima/utils";
import totalProtectPlusUtils from "../components/products/total-protect-plus/utils";
import gssUtils from "../components/products/gss/utils";
import gisUtils from "../components/products/GIS/utils";
import fspUtils from "../components/products/flexi-savings-plan/utils";
import ProductConstants from "constants/ProductConstants";
import NeedTypeEnum from "constants/NeedTypeEnum";
import SolutionStore from "modules/solution/store/SolutionStore";
const _ = require('lodash');

// const cashflowProtectionPlus = require('./cashflowProtectionPlus')
// const smartLifeStyle = require('./smartLifeStyle');
// const response = require('./response');
// const criticare = require('./criticare');
// const smartLifeStyle = require('./smartLifeStyle');
// const aip = require('./aip');
// const response = require('./response');
// const gcap = require('./gcap');
// const wealthPlus = require('./wealthPlus');
// const wealthSecurePlus = require('./wealthSecurePlus.js');
// const wealthGainPlus = require('./wealthGain+')
// const wealthSecurePlus = require('./wealthSecurePlus.js');
// const wealthGainPlus = require('./wealthGain+')
// const immediateAnnuityPlan = require('./immediateAnnuityPlan')
// const wealthGainPlus = require('./wealthGain+')
// const wealthGainPlus = require('./wealthGain+')
// const wealthGainPlus = require('./wealthGain+')
// const incomeBuilder = require('./incomeBuilder')
// const POSSaralNivesh = require('./POSSaralNivesh')
// const wealthPremier = require('./wealthPremier')
// const incomeBuilder = require('./incomeBuilder')
// const easyPension = require('./easyPension')
// const singlePayEndowmentAssurancePlan = require('./singlePayEndowmentAssurancePlan')
// const easyPension = require('./easyPension')
// const singlePayEndowmentAssurancePlan = require('./singlePayEndowmentAssurancePlan')
// const POSSaralNivesh = require('./POSSaralNivesh')
// const zindagiPlus = require("./zindagiPlus");
// const simplyProtect = require("./simplyProtect");
// const dhanLabh = require('./dhanLabh')
// const protection = require("./protection");
// const wealthUltima = require("./wealth-ultima");
// const SaralPension = require('./SaralPension')
// const ForeverPension = require('./ForeverPension')
// const SaralJeevanBima = require('./SaralJeevanBima')
const premiumGuaranteedIncome = require('./premiumGuaranteedIncome');
// const tpp = require('./totalProtectPlus')

// const premiumGuaranteedIncome = require('./premiumGuaranteedIncome');
// const BetterInningsGuaranteedIncomeStar_GIS = require('./betterInningsGuaranteedIncomeStar')
// const CashbackGuaranteedIncomeStar_GIS = require('./cashbackGuaranteedIncomeStar')
// const DoItYourselfGuaranteedIncomeStar_GIS = require('./doItYourSelfGuaranteedIncomeStar')
// const MilestonesGuaranteedIncomeStar_GIS = require('./milestonesGuaranteedIncomeStar')
// const PosCashbackGuaranteedIncomeStar_GIS = require('./PosCashbackGuaranteedIncomeStar ')
// const PosDoItYourselfGuaranteedIncomeStar_GIS = require('./PosDoItYourselfGuaranteedIncomeStar')
// const PosBetterInningsGuaranteedIncomeStar_GIS = require('./PosBetterInningsGuaranteedIncomeStar')
const gss = require('./gss')
const posGss = require('./posGss')
const flexiSavingsPlan = require('./flexiSavingsPlan')

class OptimizationStore {
  product_list_data = [];
  journeyData = null;
  isLoading = false;
  totalPriceData = {};
  defaultSelectedRisk = "low";
  selectedNeed = undefined;
  selectedFamilyMemberId = undefined;
  isAddInsuredModalOpen = false;
  familyMemberModalType = "";
  changeLaDetails = {};
  eligibleLaList = [];
  eligibleFamilyMembersList = {};
  eligibleProposerList = [];
  changeProposerDetails = {};
  addInsuredDetails = {};
  isCalculatorUsed = false;
  needFormController = {};
  isChangeProductModalOpen = false;
  isReasonModalOpen = false;
  changeProductList = [];
  selectedReason = {};
  selectedNeedCardData = {};
  solutionId = null;
  leadSpecificId = null;
  fetchIndividualProductTimeLineReqParam = {};
  selfFamilyMemberDetail = {};
  changeProductData = {};
  familyMemberDetailObj = {};
  listOfTotalNeeds = []
  showSelectAtLeastOneNeed = false

  protectionCalculatorReqestObject = {}
  familyMemberList = []
  maturityPayOutModeChange = false
  recalculateProductsSpecs = false

  constructor() {
    makeObservable(this, {
      product_list_data: observable,
      journeyData: observable,
      isLoading: observable,
      totalPriceData: observable,
      selectedNeed: observable,
      selectedFamilyMemberId: observable,
      isAddInsuredModalOpen: observable,
      familyMemberModalType: observable,
      changeLaDetails: observable,
      changeProposerDetails: observable,
      updateResponseStructure: action,
      totalPrice: computed,
      checkValidationExist: computed,
      emptyProductListData: action,
      resetSelectedNeedAndMemberId: action,
      setAddInsuredModalFunction: action,
      fetchProductIndex: action,
      setSingleProduct: action,
      setFamilyMemberModalType: action,
      setProposerDetails: action,
      isChangeProductModalOpen: observable,
      isReasonModalOpen: observable,
      selectedReason: observable,
      solutionId: observable,
      leadSpecificId: observable,
      fetchIndividualProductTimeLineReqParam: observable,
      selfFamilyMemberDetail: observable,
      changeProductData: observable,
      familyMemberDetailObj: observable,
      protectionCalculatorReqestObject: observable,
      familyMemberList: observable,
      maturityPayOutModeChange: observable,
      listOfTotalNeeds: observable,
      showSelectAtLeastOneNeed: observable,
      recalculateProductsSpecs: observable,
    });
  }

  setFamilyMemberDetails = (id, dob, monthlyIncome) => {
    this.familyMemberDetailObj = {
      id: id,
      dob: dob,
      monthlyIncome: monthlyIncome
    }
  }

  setListOfTotalNeeds = array => this.listOfTotalNeeds = array
  setShowSelectAtLeastOneNeed = bool => this.showSelectAtLeastOneNeed = bool

  setFamilyMemberList = (value) => {
    this.familyMemberList = value
  }


  setProtectionCalculatorReqestObject = (value) => {
    this.protectionCalculatorReqestObject = value
  }

  setChangeLaDetails = (productID, needUniqueName) => {
    this.changeLaDetails = {
      productID: productID,
      needUniqueName: needUniqueName,
    };
  };

  setProposerDetails = (productID, needUniqueName, familyMemberId) => {
    this.changeProposerDetails = {
      productID: productID,
      needUniqueName: needUniqueName,
      familyMemberId: familyMemberId,
    };
  };

  setSingleProduct = (productId, productDataFromApi, productIdArray, selectedRisk) => {
    var productListData = toJS(this.product_list_data);
    const productIndex = this.fetchProductIndex(productId, productIdArray);

    let currentNeedName = productDataFromApi.data.need_unique_name;

    let needIndex = productListData.findIndex((item) => item.need_unique_name === currentNeedName);

    let requestData = {
      data: [{ ...productDataFromApi.data }],
    };

    let productData = this.formatProductResponseData(requestData, selectedRisk);

    productListData[needIndex].data[productIndex] = productData[0].data[0];

    this.product_list_data = createNewObject(productListData);
  };

  setSelectedNeed = (value) => {
    this.selectedNeed = value;
  };

  setSelectedMemberId = (value) => {
    this.selectedFamilyMemberId = value;
  };

  setSelectedNeedCardData = (value) => {
    this.selectedNeedCardData = value;
  };

  resetSelectedNeedAndMemberId = () => {
    this.selectedNeed = undefined;
    this.selectedFamilyMemberId = undefined;
  };

  setAddInsuredModalFunction = (value) => {
    this.isAddInsuredModalOpen = value;
  };

  addNeed = (value) => {
    const journeyId = toJS(AuthStore.journeyId);
    AuthStore.fetchAllData(journeyId);
    let productData = this.formatProductResponseData(value);
    let selectedNeed = value.data[0].need_unique_name;
    if (productData[0].need_unique_name !== undefined) {
      let selectedNeedIndex = this.product_list_data.findIndex((item) => item.need_unique_name === selectedNeed);

      if (selectedNeedIndex !== -1) {
        // Push need in the data
        this.product_list_data[selectedNeedIndex].data.push(value.data[0].data[0]);
      } else {
        // add new need
        this.product_list_data.push(productData[0]);
      }
    }
  };

  addInsuredData = async (value, hideModal) => {
    let responseDataArray = [];
    const journeyId = toJS(AuthStore.journeyId);
    let productListDatatoJS = toJS(this.product_list_data);
    await AuthStore.fetchAllData(journeyId);
    responseDataArray.push(value);
    let finalData = { data: responseDataArray };
    let productData = this.formatProductResponseData(finalData);
    let selectedNeed = productData[0].need_unique_name;
    if (selectedNeed !== undefined) {
      let selectedNeedIndex = productListDatatoJS.findIndex((item) => item.need_unique_name === selectedNeed);
      if (selectedNeedIndex !== -1) {
        let selectedProduct = productListDatatoJS[selectedNeedIndex];
        if (selectedProduct.data && selectedProduct.data.length > 0) {
          selectedProduct.data.splice(0, 0, productData[0].data[0]);
        }
      }
      this.product_list_data = productListDatatoJS;
    }
    if (hideModal !== undefined) {
      hideModal();
    }
  };

  setFamilyMemberModalType = (value) => {
    if (value === undefined) {
      this.selectedNeed = undefined;
      this.selectedFamilyMemberId = undefined;
    }
    this.familyMemberModalType = value;
  };

  getChangeLaDetails() {
    return {
      productID: this.changeLaDetails.productID,
      needUniqueName: this.changeLaDetails.needUniqueName,
    };
  }

  getChangeProposerDetails() {
    return {
      productID: this.changeProposerDetails.productID,
      needUniqueName: this.changeProposerDetails.needUniqueName,
      familyMemberId: this.changeProposerDetails.familyMemberId,
    };
  }

  get checkValidationExist() {
    let isValidationExist = false;
    this.product_list_data.map((needData) => {
      let familyList = needData.data;
      familyList.map((memberData) => {
        let biData = memberData.BI;
        if (Array.isArray(biData)) {
          biData.map((bi) => {
            if (bi.risk == (memberData.selectedRisk || "high") && !memberData.isDisabledNeed && !memberData.productDisabled) {
              if (bi.isValidationExist) {
                isValidationExist = true;
              }
            }
          });
        } else {
          if (!memberData.isDisabledNeed && !memberData.productDisabled) {
            if (memberData.isValidationExist) {
              isValidationExist = true;
            }
          }
        }
      });
    });
    return isValidationExist;
  }

  get totalPrice() {
    let list = [];
    this.product_list_data.forEach((item) => {
      let price = 0;
      let isDisabledNeed = false;
      item.data.forEach((productData) => {
        let biData = productData.BI;
        if (Array.isArray(biData)) {
          biData.forEach((bi) => {
            isDisabledNeed = bi.isErrorExist ? false : !productData.isDisabledNeed && bi.risk == (productData.selectedRisk || "high");
            if (isDisabledNeed) {
              price += bi.isValidationExist ? 0 : Number(productData.selectedPrice);
            }
          });
        } else {
          isDisabledNeed = !productData.isDisabledNeed && !productData.isErrorExist;
          if (isDisabledNeed) {
            price += productData.isValidationExist ? 0 : Number(productData.selectedPrice);
          }
        }
      });
      list.push({
        need_unique_name: item.need_unique_name,
        need_title: item.need_title,
        price: price,
      });
    });
    return list;
  }

  async fetchAllData() {
    let response = await AuthStore.fetchJourneyData();
    this.journeyData = response.data;
    return response.data;
  }

  showErrorMsg(data) {
    let errors = [];
    data.InputValidationStatus.map((m) => {
      m.ErrorMessage.map((e) => {
        errors.push(e.value ? e.value : e.Value);
      });
    });
    let error = "";
    errors.map((msg, index) => (error = error + `${index + 1}) ${msg}, `));
    AuthStore.setErrorModal(error);
  }

  async emptyProductListData() {
    this.product_list_data = [];
  }

  async updateResponseStructure() {
    try {
      global.loading(true);
      this.isLoading = true;
      if (!this.journeyData) {
        await this.fetchAllData();
      }
      // console.log('Response is ', response.data)
      const result = await axios.get(`${APITypes.GET_RECOMMENDATION}${AuthStore.journeyId}/?flag=${localStorage.getItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM)}`);

      // For Criticare Plus
      // const result = flexiSavingsPlan;

      // For Smart life style
      // const result = smartLifeStyle;

      // for Cashflow Protection Plus
      // const result = cashflowProtectionPlus;

      // const result = gcap; // Hardcoded to gcap

      // For Weath Secure+
      // const result = wealthSecurePlus

      // const result = aip;
      // const result = premiumGuaranteedIncome;
      // for wealth gain+
      // const result = wealthGainPlus
      // const result = smartLifeStyle;

      // for Immediate Annuity Plan
      // const result = immediateAnnuityPlan
      // for income builder
      // const result = incomeBuilder;
      // const result = smartLifeStyle;

      // single pay endowment assurance plan
      // const result = singlePayEndowmentAssurancePlan;
      // For POS Saral Nivesh
      // const result = POSSaralNivesh;

      // For zindagi plus
      // const result = zindagiPlus;

      // For Forever pension
      // const result = ForeverPension;

      // For Saral pension
      // const result = SaralPension;

      // For Saral Jeevan Bima
      // const result = SaralJeevanBima;

      // For Saral pension
      // const result = SaralJeevanBima;

      // For Wealth Premier
      // const result = singlePayEndowmentAssurancePlan
      // For simply protect
      // const result = premiumGuaranteedIncome;

      // For Wealth Plus
      // const result = wealthPlus;

      // For Easy Pension
      // const result = easyPension;

      // for protection
      // const result = protection;

      // for protection
      // const result = wealthUltima;

      // const result = dhanLabh;

      // const result = tpp

      // const result = gss
      // const result = BetterInningsGuaranteedIncomeStar_GIS
      // const result = PosDoItYourselfGuaranteedIncomeStar_GIS
      if (result.status == "success") {
        let productData = this.formatProductResponseData(result);
        this.product_list_data = productData;
        // this.product_list_data = result.data
      } else {
        this.product_list_data = [];
      }
      this.isLoading = false;
      global.loading(false);
    } catch (error) {
      console.log("Error is ", error);
      this.isLoading = false;
      global.loading(false);
    }
  }

  async fetchBIData(productId, biRequestData, familyProductID) {
    global.loading(true);
    this.isLoading = true;

    var productListData = toJS(this.product_list_data);

    let response = await axios.post(APITypes.BI, biRequestData);

    if (response.status == 500) {
      this.isLoading = false;
      global.loading(false);
      return false;
    }
    let data = JSON.parse(response.data.JsonOutput);

    if (data.Status === "Fail") {
      this.showErrorMsg(data);
      let isTelemedicalValidation = false;
      data.InputValidationStatus.forEach((m) => {
        m.ErrorMessage.forEach((e) => {
          if (e.key == "EC1") {
            isTelemedicalValidation = false;
          } else if (e.key == "EC2") {
            isTelemedicalValidation = true;
          }
        });
      });
      productListData.forEach((item) => {
        item.data.forEach((value) => {
          if (!Array.isArray(value.BI)) {
            if (value.product_id == productId && value.family_product_id == familyProductID) {
              value.isValidationExist = !isTelemedicalValidation;
              value.selectedPrice = 0;
              value.totalPrice = 0;
            }
          } else {
            let riskProductData = value.BI.filter((item) => item.risk == value.selectedRisk)[0];
            if (riskProductData && riskProductData.product_id == productId && riskProductData.family_product_id == familyProductID) {
              riskProductData.isValidationExist = !isTelemedicalValidation;
              riskProductData.totalPrice = 0;
              value.selectedPrice = 0;
            }
          }
        });
      });
      this.product_list_data = productListData;
      this.isLoading = false;
      global.loading(false);
      return false;
    }

    let inputValidationStatus = [];
    inputValidationStatus = data.InputValidationStatus.filter((item, index) => index !== 0);

    inputValidationStatus.forEach((item) => {
      if (item.ProductName == "Payor Waiver Benefit Rider") {
        switch (biRequestData.PWB.toString()) {
          case "1":
            item.ProductNameWithOption = "Payor Waiver Benefit Rider Option 1 (On Death)";
            break;
          case "2":
            item.ProductNameWithOption = "Payor Waiver Benefit Rider Option 2 (CI or ATPD)";
            break;
          case "3":
            item.ProductNameWithOption = "Payor Waiver Benefit Rider Option 3 (CI or ATPD or Death)";
            break;
          default:
            break;
        }
      } else {
        item.ProductNameWithOption = item.ProductName;
      }
    });

    let biData = [];
    if (data && data.hasOwnProperty("BIJson2") && data?.BIJson2) {
      biData = JSON.parse(data.BIJson2);
    } else {
      biData = JSON.parse(data.BIJson);
    }
    let selectedPrice = 0;

    productListData.forEach((item) => {
      item.data.forEach((value) => {
        if (!Array.isArray(value.BI)) {
          if (value.product_id == productId && value.family_product_id == familyProductID) {
            // For single products
            value.biResponseCompleteData = biData;
            value.firstYearResponse = biData[0];
            value.parsedResponse = biData[biData.length - 1];
            value.parsedResponse.inputValidationStatus = inputValidationStatus;
            value.totalPrice = this.fetchProductPrice(value.product_name, value.firstYearResponse, value.parsedResponse, value);
            selectedPrice = value.totalPrice;
            value.quotationId = data.QuotationId;
            value.parsedResponse.productValidationData = data.InputValidationStatus[0];
            value.selectedPrice = selectedPrice;
            value.isValidationExist = false;
          }
        } else {
          let riskProductData = value.BI.filter((item) => item.risk == value.selectedRisk)[0];
          if (riskProductData && riskProductData.product_id == productId && riskProductData.family_product_id == familyProductID) {
            riskProductData.biResponseCompleteData = biData;
            riskProductData.firstYearResponse = biData[0];
            riskProductData.parsedResponse = biData[biData.length - 1];
            riskProductData.parsedResponse.inputValidationStatus = inputValidationStatus;
            selectedPrice = this.fetchProductPrice(riskProductData.product_name, riskProductData.firstYearResponse, riskProductData.parsedResponse, riskProductData);
            riskProductData.totalPrice = selectedPrice;
            riskProductData.quotationId = data.QuotationId;
            riskProductData.parsedResponse.productValidationData = data.InputValidationStatus[0];
            value.selectedPrice = selectedPrice;
            riskProductData.isValidationExist = false;
          }
        }
      });
    });
    this.product_list_data = productListData;
    this.isLoading = false;
    global.loading(false);
    return true;
  }

  deleteFamilyMember(familyData) {
    this.updateResponseStructure();
  }

  updateTabChangeData(needName, productID, tab) {
    global.loading(true);
    this.isLoading = true;
    let productIndex = toJS(this.product_list_data).findIndex((obj) => obj.need_unique_name === needName);

    let productObj = toJS(this.product_list_data)[productIndex];

    let memberIndex = productObj.data.findIndex((member) => member.id === productID);

    let member = productObj.data[memberIndex];
    member.tab = tab;

    productObj.data[memberIndex] = member;
    this.product_list_data[productIndex] = productObj;
    this.isLoading = false;
    global.loading(false);
  }

  async updateMembersFromNeeds(needName, selectedMembers) {
    try {
      selectedMembers = JSON.parse(JSON.stringify(selectedMembers));
      let productIndex = toJS(this.product_list_data).findIndex((obj) => obj.need_unique_name === needName);

      let productObj = toJS(this.product_list_data)[productIndex];

      const familyMembers = toJS(FamilyDetailsStore.familyMembers).filter((member) => {
        let memberID = "" + member.id;
        return Object.keys(selectedMembers).includes(memberID);
      });
      let finalDataArray = [];
      familyMembers.map((member) => {
        let filterMember = productObj.data.filter((m) => m.id == member.id)[0];
        if (filterMember) {
          if (selectedMembers[filterMember.id] != filterMember.isSelected) {
            let data = {
              ...member,
              is_selected: selectedMembers[member.id],
              need_unique_name: needName,
              family_id: member.id,
              action: "remove_insured",
              family_product_id: filterMember.product_id,
            };
            finalDataArray.push(data);
          }
        } else if (!filterMember && selectedMembers[member.id]) {
          let data = {
            ...member,
            is_selected: true,
            need_unique_name: needName,
            family_id: member.id,
            action: "",
            family_product_id: "",
          };
          finalDataArray.push(data);
        }
      });

      let result = await this.addRemoveAssured(finalDataArray);
      let newData = result && result ? result : {};
      let productsData = [];
      newData.map((item) => {
        if (item && item.data) {
          productsData.push(item);
        }
      });

      if (productsData.length !== 0) {
        productsData.map((item) => {
          productObj.data = [...productObj.data, item.data[0]];
        });
      }

      finalDataArray.map((member) => {
        if (!member.is_selected) {
          productObj.data.map(async (item, index) => {
            if (item.id == member.id) {
              delete productObj.data.splice(index, 1);
            }
          });
        }
      });

      this.product_list_data[productIndex] = productObj;
      this.product_list_data = [...toJS(this.product_list_data)];

      return;
    } catch (error) {
      console.log("ERROR >> ", error);
    }
  }

  async updateStoreRequestData(productId, request, familyProductID, key, val) {
    var productListData = toJS(this.product_list_data);
    productListData.forEach((item) => {
      item.data.forEach((value) => {
        if (productId) {
          request.risk_type = value.selectedRisk || "high";
          if (!Array.isArray(value.BI)) {
            // For single products
            if (value.product_id == productId && value.family_product_id == familyProductID) {
              request.family_product_id = value.family_product_id;
              value.bi_request = request;
            }
          } else {
            let riskProductData = value.BI.filter((item) => item.risk == value.selectedRisk)[0];

            if (riskProductData) {
              if (riskProductData.product_id == productId && riskProductData.family_product_id == familyProductID) {
                request.family_product_id = riskProductData.family_product_id;
                riskProductData.bi_request = request;
              }
            }

            this.updateFieldsKeyValue(value, key, val);
          }
        }
      });
    });
    this.product_list_data = productListData;

    let response = await this.fetchBIData(productId, request, familyProductID);

    this.resetUpdatedFieldsKeyValue();
    return response;
  }

  async fetchPremium(request) {
    global.loading(true);
    await axios.post(APITypes.GET_PREMIUM_API, request).then((res) => {
      global.loading(false);
    });
    global.loading(false);
  }

  resetFieldChangeEvent(familyProductId) {
    var productListData = toJS(this.product_list_data);
    productListData.forEach((item) => {
      item.data.forEach((value) => {
        if (value.BI.length > 0) {
          value.BI.map((tab) => {
            if (tab.fieldChanged && tab.risk === value.selectedRisk && tab.family_product_id === familyProductId) {
              tab.fieldChanged = false;
            }
          });
        } else {
          if (value.fieldChanged && value.family_product_id === familyProductId) {
            value.fieldChanged = false;
          }

          this.updateFieldsKeyValue(value, key, val);
        }
      });
    });
    this.product_list_data = productListData;
  }

  resetUpdatedFieldsKeyValue() {
    toJS(this.product_list_data).forEach((item) => {
      item.data.forEach((value) => {
        if (value.BI.length > 0) {
          value.BI.map((tab) => {
            if (tab.updatedValues) {
              delete tab.updatedValues;
            }
          });
        }
      });
    });
  }

  fetchPremium(request) {
    axios.post(APITypes.GET_PREMIUM_API, request).then((res) => {
      console.log("Response for premounm ?? ", res);
    });
  }

  resetFieldChangeEvent(familyProductId) {
    var productListData = toJS(this.product_list_data);
    productListData.forEach((item) => {
      item.data.forEach((value) => {
        if (value.BI.length > 0) {
          value.BI.map((tab) => {
            if (tab.fieldChanged && tab.risk === value.selectedRisk && tab.family_product_id === familyProductId) {
              tab.fieldChanged = false;
            }
          });
        } else {
          if (value.fieldChanged && value.family_product_id === familyProductId) {
            value.fieldChanged = false;
          }
        }
      });
    });
    this.product_list_data = productListData;
  }

  resetUpdatedFieldsKeyValue() {
    toJS(this.product_list_data).forEach((item) => {
      item.data.forEach((value) => {
        if (value.BI.length > 0) {
          value.BI.map((tab) => {
            if (tab.updatedValues) {
              delete tab.updatedValues;
            }
          });
        }
      });
    });
  }

  fetchBiRequest(productId, familyProductID) {
    let productData = {};
    let productListData = toJS(this.product_list_data);
    productListData.forEach((item) => {
      item.data.forEach((value) => {
        if (!Array.isArray(value.BI)) {
          // For single products
          if (value.product_id == productId && value.family_product_id == familyProductID) {
            productData.data = value;
          }
        } else {
          // For multiple products
          let riskProductData = value.BI.filter((item) => item.risk == value.selectedRisk)[0];
          if (riskProductData && riskProductData.product_id == productId && riskProductData.family_product_id == familyProductID) {
            productData.data = riskProductData;
          }
        }
      });
    });
    return productData;
  }

  fetchProductIndex(productId, productIdArray) {
    let selectedItemIndex;

    toJS(this.product_list_data).forEach((item, productIndex) => {
      item.data.forEach((value, index) => {
        if (!Array.isArray(value.BI)) {
          // For single products
          if (value.product_id === productId) {
            selectedItemIndex = index;
          }
        } else {
          // For multiple products
          let riskProductData = value.BI.filter((item) => item.risk == value.selectedRisk)[0];
          if (productIdArray && Array.isArray(productIdArray)) {
            if (riskProductData && productIdArray.some((product)=> riskProductData.product_id == product)) {
              selectedItemIndex = index;
            }
          } else {
            if (riskProductData && riskProductData.product_id == productId) {
              selectedItemIndex = index;
            }
          }
        }
      });
    });

    return selectedItemIndex;
  }

  async onNextBtnClick(props) {
    let finalData = [];
    let listData = toJS(this.product_list_data);
    let isProductSelected = false;
    isProductSelected = this.checkAllNeedDisable(listData);
    if (isProductSelected) {
      isProductSelected = this.checkSelectedProduct(listData);
    }

    if (isProductSelected) {
      listData.forEach((needData) => {
        let familyList = needData.data;
        familyList.forEach((memberData) => {
          let biData = memberData.BI;
          let productSelected = false;
          if (Array.isArray(biData)) {
            biData.forEach((bi) => {
              productSelected = bi.isErrorExist ? false : !memberData.isDisabledNeed && bi.risk == (memberData.selectedRisk || "high");
              let obj = {
                product_id: bi.product_id,
                is_selected: productSelected,
                quotation_id: bi.quotationId ? bi.quotationId : "",
              };
              finalData.push(obj);
            });
          } else {
            productSelected = memberData.isErrorExist ? false : true && !memberData.isDisabledNeed;
            let obj = {
              product_id: memberData.product_id,
              is_selected: productSelected,
              quotation_id: memberData.quotationId ? memberData.quotationId : "",
            };
            finalData.push(obj);
          }
        });
      });
      // const response = await axios.post(
      //   APITypes.QUOTATION_API + AuthStore.journeyId + "/",
      //   finalData
      // );
      // if (response.status.toLowerCase() === "success") {
      //   props.history.push(`/solution/${AuthStore.journeyId}`);
      //   return response.data;
      // } else {
      //   return [];
      // }
    }
  }

  async onChangeProductTab(risk, familyMemberId, needUniqueName,familyProductIdFromProductTab) {
    let familyProductId;
    let needData

    if(familyProductIdFromProductTab){

      needData = this.product_list_data.map((item) => {
       if (item.need_unique_name == needUniqueName) {
         item.data.map((familyMembers) => {
           if (familyMembers.id == familyMemberId && familyMembers.BI[0].family_product_id === familyProductIdFromProductTab) {
             let riskProductData = familyMembers.BI.filter((item) => item.risk == risk)[0];
             familyMembers.selectedRisk = risk;
             if (riskProductData) {
               familyProductId = riskProductData.family_product_id;
               familyMembers.selectedInvestmentTarget = riskProductData?.investment_target;
               familyMembers.selectedPrice = riskProductData?.totalPrice;
             }
           }
           return familyMembers;
         });
       }
       return item;
     });
      familyProductId = familyProductIdFromProductTab
    }else{
       needData = this.product_list_data.map((item) => {
      if (item.need_unique_name == needUniqueName) {
        item.data.map((familyMembers) => {
          if (familyMembers.id == familyMemberId) {
            let riskProductData = familyMembers.BI.filter((item) => item.risk == risk)[0];
            familyMembers.selectedRisk = risk;
            if (riskProductData) {
              familyProductId = riskProductData.family_product_id;
              familyMembers.selectedInvestmentTarget = riskProductData?.investment_target;
              familyMembers.selectedPrice = riskProductData?.totalPrice;
            }
          }
          return familyMembers;
        });
      }
      return item;
    });
    }

    this.product_list_data = needData;
    await axios.post(`${APITypes.SAVE_RISK_TYPE}`, { family_product_id: familyProductId, risk_type: risk });
  }

  fetchProductPrice(productName, productDetails, lastYearDetails, productData) {
    try {
      let price = "-";
      switch (productName) {
        case ProductConstants.ACTIVE_INCOME_PLAN:
        case ProductConstants.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
        case ProductConstants.ACTIVE_INCOME_PLAN_WITH_FIB:
        case ProductConstants.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
        case ProductConstants.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
          price = AIPUtils.getTotalPrice(productDetails);
          break;

        case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM:
        case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
        case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME:
        case ProductConstants.PREMIER_GUARANTEED_RETIREMENT_INCOME:
        case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
        case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
        case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
          price = PGIUtils.getTotalPrice(productData);
          break;

        case ProductConstants.SMART_LIFESTYLE:
        case ProductConstants.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
          price = smartLifeStyleUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.WEALTH_SECURE_PLUS:
        case ProductConstants.CHILD_VARIANT_WEALTH_SECURE_PLUS:
        case ProductConstants.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
          price = wealthSecurePlusUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.DHAN_LABH:
          price = dhanLabhUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.WEALTH_ULTIMA:
        case ProductConstants.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
        case ProductConstants.LITTLE_CHAMP_WEALTH_ULTIMA:
          price = wealthUltimaUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.WEALTH_GAIN_PLUS:
          price = wealthGainPlusUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.WEALTH_PLUS:
        case ProductConstants.RISING_STAR_WEALTH_PLUS:
          price = wealthPlusUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.CASH_FLOW_PROTECTION_PLUS:
          price = cashFlowProtectionPlusUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.WEALTH_PREMIER:
          price = wealthPremierUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.GCAP:
          price = gcapUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.IMMEDIATE_ANNUITY_PLAN:
          price = immediateAnnuityUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.POS_SARAL_NIVESH:
          price = posUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.INCOME_BUILDER:
        case ProductConstants.SECURED_INCOME_OPTION_INCOME_BUILDER:
          price = incomeBuilderUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.ZINDAGI_PLUS:
          price = zindagiPlusUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.SIMPLY_PROTECT:
          price = simplyProtectUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.CRITICARE_PLUS:
          price = criticarePlusUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.EASY_PENSION:
          price = easyPensionUtils.getTotalPrice(lastYearDetails);
          break;
        case ProductConstants.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
          price = singlePayUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.PROTECTION_REG_5_10_15PAY:
          price = protectionUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.FOREVER_PENSION:
        case ProductConstants.FOREVER_PENSION_IMMEDIATE_ANNUITY:
        case ProductConstants.FOREVER_PENSION_DEFERRED_ANNUITY:
        case ProductConstants.POS_FOREVER_PENSION:
          price = ForeverPensionUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.SARAL_PENSION:
          price = SaralPensionUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.SARAL_JEEVAN_BIMA:
          price = SaralJeevanBimaUtils.getTotalPrice(productDetails);
          break;
        case ProductConstants.TOTAL_PROTECT_PLUS:
          price = totalProtectPlusUtils.getTotalPrice(productDetails);
          break;

        case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM:
        case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME:
        case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
        case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
        case ProductConstants.POS_GUARANTEED_SAVINGS_STAR:
          price = gssUtils.getTotalPrice(productDetails);
          break;

        case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
        case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
        case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
        case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
        case ProductConstants.GUARANTEED_INCOME_STAR:
        case ProductConstants.GUARANTEED_INCOME_STAR_FIB:
        case ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:
        case ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
        case ProductConstants.POS_GUARANTEED_INCOME_STAR:
        case ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
          price = gisUtils.getTotalPrice(productDetails);
          break;

        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
        case ProductConstants.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
        case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
        case ProductConstants.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
          price = fspUtils.getTotalPrice(productDetails);;
          break;
      }

      return price;
    } catch (e) {
      return 0;
    }
  }

  async addRemoveAssured(data) {
    try {
      global.loading(true);
      let id = toJS(AuthStore.journeyId);
      const result = await axios.post(`${APITypes.ADD_REMOVE_ASSURED}${id}/`, data);
      result.data.forEach((item, index) => {
        if (!item.data) {
          delete result.data.splice(index, 1);
        }
      });
      let productData = this.formatProductResponseData(result);

      return productData;
    } catch (error) {
      console.log("ERROR >> ", error);
    } finally {
      global.loading(false);
    }
    return [];
  }

  formatProductResponseData(result, selectedRiskType) {
    let changeProductReqList = [];
    let responseDataIsArray = Array.isArray(result.data); // true
    const { family_product_details_list: totalFamilyProductsList } = toJS(AuthStore.journeyData);
    if (!responseDataIsArray) {
      result.data = [result.data];
    }

    let proposerIdList = []
    result.data.forEach((item) => {
      item.data.forEach((value) => {
        // Family memebers loop
        proposerIdList.push(value.proposer_details.id)

        if (!Array.isArray(value.BI)) {
          // For single products

          if (isObjectEmpty(value.BI)) return;

          if (value.BI.JsonOutput == "" || !value.BI.JsonOutput) {
            return;
          }
          let jsonOutputData = JSON.parse(value.BI.JsonOutput);
          let inputValidationStatus = [];
          if(jsonOutputData?.InputValidationStatus !== null) {
            inputValidationStatus = jsonOutputData.InputValidationStatus.filter((item, index) => index !== 0);
          } else{
            inputValidationStatus.push(jsonOutputData)
          }

          this.isLoading = false;
          let biData2 = [];
          if (jsonOutputData && jsonOutputData.hasOwnProperty("BIJson2") && jsonOutputData?.BIJson2) {
            biData2 = JSON.parse(jsonOutputData.BIJson2);
          } else {
            biData2 = JSON.parse(jsonOutputData.BIJson);
          }
          value.biResponseCompleteData = biData2;
          value.firstYearResponse = biData2 ? biData2[0] : {};
          value.parsedResponse = biData2 ? biData2[biData2.length - 1] : {};
          value.parsedResponse.inputValidationStatus = inputValidationStatus;
          value.isErrorExist = biData2 ? false : true;
          value.errorMessage = biData2 ? "" : this.fetchErrorMsg(jsonOutputData);
          value.selectedPrice = this.fetchProductPrice(value.product_name, value.firstYearResponse, value.parsedResponse, value);
          value.quotationId = jsonOutputData.QuotationId;
          value.parsedResponse.productValidationData = jsonOutputData.InputValidationStatus? jsonOutputData.InputValidationStatus[0] : [];
          const productData = totalFamilyProductsList.find((product) => product.id === value.family_product_id);
          value.isDisabledNeed = value.isSelected && item.is_removed;
          value.productDisabled = productData?.la_removed || false;
          value.isValidationExist = biData2 ? false : true;
          value.changeProductList = value.changeProductList || [];
          value.fieldChanged = false
          changeProductReqList.push({
            family_product_id: value.family_product_id, risk: "high", user_journey_id: toJS(AuthStore.journeyId), target_amount: value.selected_sum_Assured,
            recommended_cover: value.recommended_sum_assured,
            current_product_id: value.product_id,
            current_package_id: value.package_id
          });
        } else {
          // For multiple products to show in a tab
          const riskArray = value.BI.map(item => item.risk)

          if (!riskArray.includes('low')) {
            this.defaultSelectedRisk = 'medium'
          } else if (!riskArray.includes('low') && !riskArray.includes('medium')) {
            this.defaultSelectedRisk = 'high'
          }

          if(selectedRiskType)  this.defaultSelectedRisk = selectedRiskType
          value.selectedRisk = value.selectedRisk ? value.selectedRisk : this.defaultSelectedRisk;
          value.BI.forEach((productDetails) => {
            if (Object.keys(productDetails.BI).length == 0) {
              productDetails.parsedResponse = {};
            } else {
              if (isObjectEmpty(productDetails.BI)) return;

              if (productDetails.BI.JsonOutput == "" || !productDetails.BI.JsonOutput) {
                return;
              }
              let jsonOutputData = JSON.parse(productDetails.BI.JsonOutput);
              let inputValidationStatus = [];
              if(jsonOutputData?.InputValidationStatus !== null) {
                inputValidationStatus = jsonOutputData.InputValidationStatus.filter((item, index) => index !== 0);
              } else{
                inputValidationStatus.push(jsonOutputData)
              }
              this.isLoading = false;
              let biData2 = [];
              if (jsonOutputData && jsonOutputData.hasOwnProperty("BIJson2") && jsonOutputData?.BIJson2) {
                biData2 = JSON.parse(jsonOutputData.BIJson2);
              } else {
                biData2 = JSON.parse(jsonOutputData.BIJson);
              }
              productDetails.firstYearResponse = biData2 ? biData2[0] : {};
              productDetails.parsedResponse = biData2 ? biData2[biData2.length - 1] : {};
              productDetails.parsedResponse.inputValidationStatus = inputValidationStatus;
              productDetails.biResponseCompleteData = biData2;
              productDetails.isErrorExist = biData2 ? false : true;
              productDetails.errorMessage = biData2 ? "" : this.fetchErrorMsg(jsonOutputData);
              productDetails.investment_target = productDetails.bi_request.investment_target;
              productDetails.totalPrice = this.fetchProductPrice(productDetails.product_name, productDetails.firstYearResponse, productDetails.bi_request, productDetails);
              productDetails.quotationId = jsonOutputData.QuotationId;
              productDetails.parsedResponse.productValidationData = jsonOutputData.InputValidationStatus? jsonOutputData.InputValidationStatus[0] : [];
              const productData = totalFamilyProductsList.find((product) => product.id === productDetails.family_product_id);
              value.isDisabledNeed = value.isSelected && item.is_removed;
              value.productDisabled = productData?.la_removed || false;
              productDetails.isValidationExist = biData2 ? false : true;
              productDetails.changeProductList = productDetails.changeProductList || [];
              productDetails.fieldChanged = false
              changeProductReqList.push({
                family_product_id: productDetails.family_product_id, risk: productDetails.risk, user_journey_id: toJS(AuthStore.journeyId),
                target_amount: productDetails.selected_sum_Assured,
                recommended_cover: productDetails.recommended_sum_assured,
                current_product_id: productDetails.product_id,
                current_package_id: productDetails.package_id
              });
            }

            if (productDetails["risk"] == this.defaultSelectedRisk) {
              value.selectedPrice = this.fetchProductPrice(productDetails.product_name, productDetails.firstYearResponse, productDetails.parsedResponse, productDetails);
              value.selectedInvestmentTarget = productDetails.bi_request.investment_target;
            }
          });
        }
      });
    });


    proposerIdList = [...new Set(proposerIdList)]

    SolutionStore.setProposerList(proposerIdList)

    this.changeProductOnPageLoad(changeProductReqList);
    return result.data;
  }

  async changeProductOnPageLoad(changeProductReqList) {
    const promises = [];
    changeProductReqList.forEach(async (item) => {
      promises.push(axios.post(APITypes.FETCH_CHANGE_PRODUCT_LIST, item));
    });
    Promise.allSettled(promises).then((results) => results.forEach((result) => this.updateChangeProductListData(result)));
  }

  updateChangeProductListData(result) {
    let productListData = toJS(this.product_list_data);
    productListData.map((item) => {
      item.data.forEach((value) => {
        if (!Array.isArray(value.BI)) {
          // For single products
          if (isObjectEmpty(value.BI)) return;
          if (value.BI.JsonOutput == "") return;
          if (value.family_product_id === result.value.data[0]?.family_product_id) {
            var productList = result.value.data.filter((resultData) => resultData.plan_name !== value?.product_name)
            value.changeProductList = productList
          }
        } else {
          // For multiple products to show in a tab
          value.selectedRisk = value.selectedRisk ? value.selectedRisk : this.defaultSelectedRisk;
          value.BI.forEach((productDetails) => {
            if (Object.keys(productDetails.BI).length == 0) {
              productDetails.parsedResponse = {};
            } else {
              if (isObjectEmpty(productDetails.BI)) return;
              if (productDetails.BI.JsonOutput == "") return;
              if (productDetails.family_product_id === result.value.data[0]?.family_product_id && result.value.data[0]?.risk === productDetails.risk) {
                var productList = result.value.data.filter((resultData) => resultData.plan_name !== productDetails?.product_name)
                productDetails.changeProductList = productList;
              }
            }
          });
        }
      });
      return item;
    });

    this.product_list_data = productListData;
  }

  fetchErrorMsg(data) {
    let errors = [];
    data.InputValidationStatus?.map((m) => {
      m.ErrorMessage.map((e) => {
        errors.push(e.Value);
      });
    });
    if(data?.Status.toLowerCase() !== "success" && data?.Message) { errors.push(data.Message) }
    let error = "";
    errors.map((msg, index) => (error = error + `${index + 1}) ${msg}, `));
    return error;
  }

  updateBiRequest(productId, biRequest, familyProductId, key, val) {
    let productDataList = toJS(this.product_list_data);
    //global.isFieldChanged(productId, true)
    productDataList.forEach((item) => {
      item.data.forEach((value) => {
        if (!Array.isArray(value.BI)) {
          // For single products
          if (value.family_product_id == familyProductId) {
            value.fieldChanged = true;
            value.bi_request = biRequest;
          } else {
            value.fieldChanged = false;
          }
        } else {
          // For multiple products
          let riskProductData = value.BI.filter((item) => item.risk == value.selectedRisk)[0];
          if (riskProductData && riskProductData.family_product_id == familyProductId) {
            riskProductData.bi_request = biRequest;
            riskProductData.fieldChanged = true;
          } else {
            riskProductData.fieldChanged = false;
          }

          value = this.updateFieldsKeyValue(value, key, val);
        }
      });
    });

    this.product_list_data = productDataList;
  }

  async getPremium(requestBodyArr, productData) {
    // productData.fieldChanged = true
    let productDataList = toJS(this.product_list_data);
    productDataList.forEach((item) => {
      item.data.forEach((value) => {
        if (!Array.isArray(value.BI)) {
          // For single products
          if (value.family_product_id == productData.family_product_id) {
            value.fieldChanged = true;
            value.minimum_sum_assured = requestBodyArr[0].TargetAmount;
            value.maximum_sum_assured = requestBodyArr[1].TargetAmount;
            value.recommended_sum_assured = requestBodyArr[2].TargetAmount;
            value.selected_sum_Assured = requestBodyArr[3].TargetAmount;
          }
        } else {
          // For multiple products
          let riskProductData = value.BI.filter((item) => item.risk == value.selectedRisk)[0];
          if (riskProductData && riskProductData.family_product_id == productData.family_product_id) {
            riskProductData.fieldChanged = true;
            riskProductData.minimum_sum_assured = requestBodyArr[0].TargetAmount;
            riskProductData.maximum_sum_assured = requestBodyArr[1].TargetAmount;
            riskProductData.recommended_sum_assured = requestBodyArr[2].TargetAmount;
            riskProductData.selected_sum_Assured = requestBodyArr[3].TargetAmount;
          }
        }
      });
    });
    this.product_list_data = productDataList;
    let request = {
      family_product_id: productData.family_product_id,
      risk_type: productData.selectedRisk || "high",
      multi_target_premium_list: requestBodyArr,
    };

    global.loading(true);
    await axios.post(APITypes.GET_PREMIUM_API, request).then((res) => {
      // productData.fieldChanged = false
      // productData.premium = res.data[0].premium
      let productDataList = toJS(this.product_list_data);
      productDataList.forEach((item) => {
        item.data.forEach((value) => {
          if (!Array.isArray(value.BI)) {
            // For single produc
            if (value.family_product_id == productData.family_product_id) {
              value.fieldChanged = false;
              value.minPremium = res.data[0] ? res.data[0].premium : null;
              value.maxPremium = res.data[1] ? res.data[1].premium : null;
              value.recommendedPremium = res.data[2] ? res.data[2].premium : null;
              value.userSelectedPremium = res.data[3] ? res.data[3].premium : null;
            }
          } else {
            // For multiple products
            let riskProductData = value.BI.filter((item) => item.risk == value.selectedRisk)[0];
            if (riskProductData && riskProductData.family_product_id == productData.family_product_id) {
              riskProductData.fieldChanged = false;
              riskProductData.minPremium = res.data[0] ? res.data[0].premium : null;
              riskProductData.maxPremium = res.data[1] ? res.data[1].premium : null;
              riskProductData.recommendedPremium = res.data[2] ? res.data[2].premium : null;
              riskProductData.userSelectedPremium = res.data[3] ? res.data[3].premium : null;
            }
          }
        });
      });
      this.product_list_data = productDataList;
      global.loading(false);
    });
    global.loading(false);
  }

  updateFieldsKeyValue(value, key, val) {
    return value;
    // below code will update the product properties in all 3 tabs if selected in any 1 tab it is breaking ui in some cases like incomebuilder and AIP hence returned value from above for now.
    return key && val ? value.BI.map((tab) => {
      // if (tab.risk !== value.selectedRisk) {
        tab.updatedValues = {};
          tab.updatedValues[key] = key === "INPUT_MODE" ? val.frequency || val : val;
      // }
    }) : value;
  }

  checkAllNeedDisable(listData) {
    let count = 0;
    listData.map((needData) => {
      let familyList = needData.data;
      familyList.every((memberData) => {
        if (!memberData.isDisabledNeed) {
          count++;
          return false;
        }
        return true;
      });
    });

    if (count == 0) {
      AuthStore.setErrorModal("Please select atleast one need");
      return false;
    } else return true;
  }

  checkSelectedProduct(listData) {
    let memberCount = 0;
    let productCount = 0;
    let productNotAvailable = false;
    listData.map((needData) => {
      let familyList = needData.data;
      familyList.map((memberData) => {
        let biData = memberData.BI;
        if (Array.isArray(biData)) {
          biData.map((bi) => {
            if (bi.risk == (memberData.selectedRisk || "high") && !memberData.isDisabledNeed) {
              if (Object.keys(bi.BI).length == 0) {
                productNotAvailable = true;
              }
              if (bi.isErrorExist) {
                productCount++;
              }
              memberCount++;
            }
          });
        } else {
          if (!memberData.isDisabledNeed) {
            if (Object.keys(memberData.BI).length == 0) {
              productNotAvailable = true;
            }
            if (memberData.isErrorExist) {
              productCount++;
            }
            memberCount++;
          }
        }
      });
    });
    if (memberCount == productCount) {
      AuthStore.setErrorModal("There are some errors in the product, please revise the configuration");
      return false;
    } else if (productNotAvailable) {
      AuthStore.setErrorModal("Product is not available, please revise the configuration");
      return false;
    } else return true;
  }

  validateProduct(productId, isValidationExist) {
    let productListData = toJS(this.product_list_data);
    productListData.forEach((item) => {
      item.data.forEach((value) => {
        if (!Array.isArray(value.BI)) {
          if (value.product_id == productId) {
            value.isValidationExist = isValidationExist;
            value.selectedPrice = isValidationExist ? 0 : value.selectedPrice;
            value.totalPrice = isValidationExist ? 0 : value.totalPrice;
          }
        } else {
          let riskProductData = value.BI.find((item) => item.product_id == productId);
          if (riskProductData != undefined) {
            riskProductData.isValidationExist = isValidationExist;
            value.selectedPrice = isValidationExist ? 0 : value.selectedPrice;
            riskProductData.totalPrice = isValidationExist ? 0 : riskProductData.totalPrice;
          }
        }
      });
    });
    this.product_list_data = productListData;
  }

  // Get ELigible LA List
  getEligibleLaList = async (productID, needUniqueName) => {
    let journeyId = toJS(AuthStore.journeyId);

    if (productID === undefined || needUniqueName === undefined) {
      let laDetails = this.getChangeLaDetails();
      productID = laDetails.productID;
      needUniqueName = laDetails.needUniqueName;
    } else {
      this.setChangeLaDetails(productID, needUniqueName);
    }

    let selectedNeed = await fetchNeedDetailsBasedonNeedUniqueName(needUniqueName);
    const laListResponse = await axios.post(APITypes.GET_ELIGIBLE_LA_LIST, {
      journey_id: journeyId,
      need_id: selectedNeed.id,
    });

    this.changeLaDetails = {
      ...this.changeLaDetails,
      eligibleList: laListResponse.data,
    };

    // this.setState({ eligibleLaList: laListResponse.data })
    this.setFamilyMemberModalType(FamilyMemberModalTypeEnum.CHANGE_LA);
    this.setAddInsuredModalFunction(true);
    // this.setState({ productID: productID })
  };

  // Fetch family member list to populate the life assured sidebar
  fetchFamilyMemberListForLifeAssured = () => {
    let familyMemberData = [];

    switch (this.familyMemberModalType) {
      case FamilyMemberModalTypeEnum.CHANGE_LA:
        familyMemberData = this.changeLaDetails.eligibleList;
        break;

      case FamilyMemberModalTypeEnum.CHANGE_PROPOSER:
        familyMemberData = this.changeProposerDetails.eligibleList;
        // TODO: Handle for change proposer
        // familyMemberData = proposerList
        break;

      case FamilyMemberModalTypeEnum.ADD_INSURED:
      case FamilyMemberModalTypeEnum.ADD_NEED:
        familyMemberData = this.eligibleFamilyMembersList.eligibleList;
        break;
      default:
        familyMemberData = [];
        // TODO: Handle for default family member list
        break;
    }

    return familyMemberData;
  };

  //API call to remove need
  removeNeed = async (data, needUniqueName) => {
    const removeNeedResponse = await axios.post(APITypes.REMOVE_NEED, data);
    if (removeNeedResponse.status === "success") {
      let productList = this.product_list_data;
      let updatedProductList = [];
      productList.forEach((item) => {
        if (item.need_unique_name === needUniqueName) {
          item.data.map((productData) => {
            productData.isDisabledNeed = true;
          });
        }
      });
      this.product_list_data = productList;

      let journeyData = toJS(AuthStore.journeyData);
      journeyData.journey_need.forEach((item) => {
        if (item.need === data.need_id) {
          item.is_selected = true;
          item.is_removed = data.is_removed;
        }
      });
      AuthStore.setJourneyData(journeyData);
    }
  };

  // Api Call to change the Life Assured
  changeLA = async (data, hideModal, needType) => {
    const changedLADetails = this.changeLaDetails.eligibleList.find((item) => item.id === this.selectedFamilyMemberId);
    data.family_member_id = this.selectedFamilyMemberId;
    let productId = "";
    if (Array.isArray(this.selectedNeedCardData.BI)) {
      let riskProductData = this.selectedNeedCardData.BI.filter((item) => item.risk == this.selectedNeedCardData.selectedRisk)[0];
      productId = riskProductData.family_product_id;
    } else {
      productId = this.selectedNeedCardData.family_product_id;
    }
    data.family_product_id = productId;
    data.dob = moment(changedLADetails.dob).format("YYYY-MM-DD");
    data.monthly_income = changedLADetails.monthly_income;

    const familyMemberDetailObj = toJS(this.familyMemberDetailObj)

    if (Object.values(familyMemberDetailObj).indexOf(this.selectedFamilyMemberId.toString()) > -1 && needType === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY) {
      data.dob = moment(familyMemberDetailObj.dob).format("YYYY-MM-DD");
      data.monthly_income = familyMemberDetailObj.monthlyIncome
    }


    const changelaListResponse = await axios.post(APITypes.CHANGE_LIFE_ASSURED, data);
    console.log("changelaListResponse", changelaListResponse);
    if (changelaListResponse.data.status !== "error") {
      localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, false);
      this.setSingleProduct(this.changeLaDetails.productID[0], changelaListResponse, this.changeLaDetails.productID, this.selectedNeedCardData.selectedRisk);
      this.changeLaDetails = {};
      this.setFamilyMemberModalType(undefined);
      this.familyMemberModalType = undefined;
    }
    if (hideModal !== undefined) {
      hideModal();
    }
  };

  // Get eligible proposer List
  getEligibleProposerList = async (productIdList, needUniqueName, familyMemberId) => {
    this.setFamilyMemberModalType(FamilyMemberModalTypeEnum.CHANGE_PROPOSER);

    let journeyId = toJS(AuthStore.journeyId);

    if (productIdList === undefined || needUniqueName === undefined || familyMemberId === undefined) {
      let laDetails = this.getChangeProposerDetails();
      productIdList = laDetails.productID;
      needUniqueName = laDetails.needUniqueName;
      familyMemberId = laDetails.familyMemberId;
    } else {
      this.setProposerDetails(productIdList, needUniqueName, familyMemberId);
    }

    let selectedNeed = await fetchNeedDetailsBasedonNeedUniqueName(needUniqueName);

    const proposerListResponse = await axios.post(APITypes.GET_ELIGIBLE_PROPOSER_LIST, {
      journey_id: journeyId,
      need_id: selectedNeed.id,
      family_member_id: familyMemberId,
    });

    this.changeProposerDetails = {
      ...this.changeProposerDetails,
      eligibleList: proposerListResponse.data,
    };

    this.setAddInsuredModalFunction(true);
  };

  // Change Proposer
  changeProposer = async () => {
    let selectedNeed = await fetchNeedDetailsBasedonNeedUniqueName(this.changeProposerDetails.needUniqueName);
    let productId = "";
    let biRequestUpdateFamilyProductId = ''
    if (Array.isArray(this.selectedNeedCardData.BI)) {
      let riskProductData = this.selectedNeedCardData.BI.filter((item) => item.risk == this.selectedNeedCardData.selectedRisk)[0];
      productId = riskProductData.family_product_id;
    } else {
      productId = this.selectedNeedCardData.family_product_id;
    }
    const changeProposerRequestObj = {
      proposer_id: this.selectedFamilyMemberId,
      family_product_id: productId,
      need: selectedNeed.id,
    };
    this.setFamilyMemberModalType(undefined);
    const changeProposerResponse = await axios.post(APITypes.CHANGE_PROPOSER, changeProposerRequestObj);
    let familyDetailList = toJS(AuthStore.journeyData.family_details_list);
    let productListData = toJS(this.product_list_data);

    let familymemberDetails = familyDetailList.find((item) => item.id === changeProposerResponse.data.new_proposer_id);
    if (familymemberDetails) {
      productListData.forEach((item) => {
        item.data.forEach((itemData) => {
          let middleName = familymemberDetails.middle_name !== null ? " " +  familymemberDetails.middle_name + " " : " "
          if (!Array.isArray(itemData.BI)) {
            if (itemData.family_product_id === productId) {
              itemData.proposer_details = familymemberDetails;
              biRequestUpdateFamilyProductId = itemData.family_product_id
              itemData.bi_request.PROPOSER_AGE = moment().diff(moment(familymemberDetails.dob), "years")
              itemData.bi_request.PROPOSER_DOB = moment(familymemberDetails.dob).format("DD MMM YYYY")
              itemData.bi_request.PROPOSER_GENDER = familymemberDetails.gender.toLowerCase() === 'male' ? "M" : "F"
              itemData.bi_request.PROPOSER_NAME = familymemberDetails.first_name + middleName  + familymemberDetails.last_name
              itemData.bi_request.SameProposer = itemData.life_assured_details.id === familymemberDetails.id
              itemData.bi_request.risk_type= "high",	
              itemData.bi_request.family_product_id= itemData.family_product_id
            }
          } else {
            itemData.BI.forEach((value) => {
              if (value.family_product_id === productId) {
                itemData.proposer_details = familymemberDetails;
                biRequestUpdateFamilyProductId = value.family_product_id;
                value.bi_request.PROPOSER_AGE = moment().diff(moment(familymemberDetails.dob), "years")
                value.bi_request.PROPOSER_DOB = moment(familymemberDetails.dob).format("DD MMM YYYY")
                value.bi_request.PROPOSER_GENDER = familymemberDetails.gender.toLowerCase() === 'male' ? "M" : "F"
                value.bi_request.PROPOSER_NAME = familymemberDetails.first_name + middleName + familymemberDetails.last_name
                value.bi_request.SameProposer = itemData.life_assured_details.id === familymemberDetails.id
                value.bi_request.risk_type= value.risk,	
                value.bi_request.family_product_id= value.family_product_id
              }
            });
          }
        });
      });
    } else {
      // TODO: If change proposer applied for Save for my Children then use below code
    //   productListData.forEach((item) => {
    //     item.data.forEach((itemData) => {
    //       if (!Array.isArray(itemData.BI)) {
    //         if (itemData.family_product_id === productId) {
    //           itemData = changeProposerResponse.data.data[0];
    //         }
    //       } else {
    //         let isSameMember = itemData.BI.some((value) => value.family_product_id = productId);
    //         if (isSameMember) {
    //           itemData = changeProposerResponse.data.data[0]
    //         }
    //       }
    //     });
    //   });
    // }

    let updateProductListData
    let updatededProductList

      productListData.forEach((item,productListIndex) => {
        item.data.forEach((itemData,index) => {
          if (!Array.isArray(itemData.BI)) {
            if (itemData.family_product_id === productId) {
              itemData = changeProposerResponse.data.data[0];
            }
          } else {
            let isSameMember = itemData.BI.some((value) => value.family_product_id === productId);
            if (isSameMember) {
              let formattedChangeProductResponse = this.formatProductResponseData(changeProposerResponse)
              updateProductListData = formattedChangeProductResponse[0].data[0]
              let previousProductList = _.cloneDeep(productListData)

              if(previousProductList[productListIndex].need_title == 'Save for my Children'){
                previousProductList[productListIndex].data[index] = updateProductListData
                updatededProductList = previousProductList
              }
            }
          }
        });
      });

      productListData = updatededProductList
    }

    
    this.product_list_data = productListData;

    productListData.forEach((item) => {
      item.data.forEach((itemData) => {
        if (!Array.isArray(itemData.BI)) {
          if (itemData.family_product_id === biRequestUpdateFamilyProductId) {
            this.fetchBIData(itemData.product_id, itemData.bi_request, itemData.family_product_id)
          }
        } else {
          itemData.BI.forEach((value) => {
            if (value.family_product_id === biRequestUpdateFamilyProductId) {
             this.fetchBIData(value.product_id, value.bi_request, value.family_product_id)
            }
          });
        }
      });
    });
  }

  // Add Insured
  fetchInsuredMembers = async (needUniqueName) => {
    if (needUniqueName === undefined) {
      needUniqueName = this.addInsuredDetails.needUniqueName;
    }

    this.setSelectedNeed(needUniqueName);

    let journeyId = toJS(AuthStore.journeyId);
    let selectedNeed = await fetchNeedDetailsBasedonNeedUniqueName(needUniqueName);
    const eligibleUserResponse = await axios.post(APITypes.GET_ELIGIBLE_LA_LIST, {
      journey_id: journeyId,
      need_id: selectedNeed?.id,
    });

    this.addInsuredDetails = {
      needUniqueName: needUniqueName,
      eligibleList: eligibleUserResponse.data,
    };

    this.eligibleFamilyMembersList = {
      eligibleList: eligibleUserResponse.data,
    };

    this.setAddInsuredModalFunction(true);
  };

  // Add Need
  showInsuredModalOnAddingNeed = async (needUniqueName) => {
    this.setSelectedNeed(needUniqueName);
    await this.fetchInsuredMembers(needUniqueName);
    this.setAddInsuredModalFunction(true);
    this.setFamilyMemberModalType(FamilyMemberModalTypeEnum.ADD_NEED);
  };

  resetInsuredMemberDetails = async () => {
    switch (this.familyMemberModalType) {
      case FamilyMemberModalTypeEnum.ADD_INSURED:
      case FamilyMemberModalTypeEnum.ADD_NEED:
        await this.fetchInsuredMembers();
        break;

      case FamilyMemberModalTypeEnum.CHANGE_LA:
        await this.getEligibleLaList();
        break;

      case FamilyMemberModalTypeEnum.CHANGE_PROPOSER:
        await this.getEligibleProposerList();
        break;
    }

    this.fetchFamilyMemberListForLifeAssured();
    this.setAddInsuredModalFunction(false);
    this.setAddInsuredModalFunction(true);
  };

  // Api Call to Add need
  addNeedAPICall = async (data, needUniqueName, hideModal) => {
    const addNeedResponse = await axios.post(APITypes.ADD_NEED, data);

    let productList = this.product_list_data;

    if (addNeedResponse.data) {
      localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, false);
      productList.forEach((item) => {
        if (item.need_unique_name === needUniqueName) {
          item.data.map((productData) => {
            productData.isDisabledNeed = true;
          });
        } else {
          const isNeedExist = productList.some((singleNeed) => singleNeed.need_unique_name === needUniqueName);
          if (!isNeedExist) {
            let productData = this.formatProductResponseData(addNeedResponse);
            productList.splice(0, 0, productData[0]);
          }
        }
      });

      this.product_list_data = productList;
      let journeyData = toJS(AuthStore.journeyData);
      journeyData.journey_need.forEach((item) => {
        if (item.need === data.need_id) {
          item.is_selected = true;
          item.is_removed = false;
        }
      });
      AuthStore.setJourneyData(journeyData);
    }
    hideModal();
    this.product_list_data = productList;
    this.isCalculatorUsed = false;
  };

  // Reselect need api call
  onReselectNeed = async (needId) => {
    let journeyData = toJS(AuthStore.journeyData);
    const selectNeedObj = {
      journey_id: journeyData.journey_details.id,
      need_id: needId,
      is_selected: true,
      is_removed: false,
    };

    const selectNeedResponse = await axios.post(APITypes.SELECT_NEED, selectNeedObj);

    let needList = AuthStore.needMasterList;
    let need = needList.find((item) => item.id == needId);

    if (selectNeedResponse.status === "success") {
      let isDisabledNeed = false;
      let productListData = toJS(this.product_list_data);
      productListData.map((item) => {
        item.data.map((productData) => {
          if (item.need_title == need.name) {
            isDisabledNeed = !productData.isDisabledNeed;
            productData.isDisabledNeed = !productData.isDisabledNeed;
          }
        });
      });

      let journeyData = toJS(AuthStore.journeyData);
      journeyData.journey_need.forEach((item) => {
        if (item.need === need.id) {
          item.is_removed = !item.is_removed;
        }
      });
      AuthStore.setJourneyData(journeyData);
      this.product_list_data = productListData;
    }
  };

  // Reason List for Product Change
  fetchReasonList = async () => {
    const reasonList = await axios.get(APITypes.PRODUCT_CHANGE_REASONS);
    if (reasonList.status == "success") {
      return reasonList.data;
    }
  };

  fetchChangeProductList = async (familyData) => {
    // let changeProductList = ""
    // if (Array.isArray(familyData.BI)) {
    //   let riskProductData = familyData.BI.filter(
    //     (item) => item.risk == familyData.selectedRisk
    //   )[0];
    //   changeProductList = riskProductData.changeProductList
    // } else {
    //   changeProductList = familyData.changeProductList
    // }
    // let requestObj = {
    //   "family_product_id": productId,
    //   "user_journey_id": toJS(AuthStore.journeyId),
    //   "risk": this.defaultSelectedRisk,
    // }

    // const changeProductList = await axios.post(APITypes.FETCH_CHANGE_PRODUCT_LIST, requestObj)
    // if (changeProductList.status == 'success') {
    // this.changeProductList = changeProductList
    // }

    this.isReasonModalOpen = false;
    this.isChangeProductModalOpen = true;
  };

  onChangeProduct = async (selectedProduct, productData, needId) => {
    let needList = AuthStore.needMasterList;
    let changeProductDetails = toJS(this.changeProductData)
    let need = needList.find((item) => item.unique_name == changeProductDetails.cardKey);

    let package_id = "";
    let investment_target = "";
    // let recommended_amount = "";
    if (Array.isArray(changeProductDetails.data.BI)) {
      let riskProductData = changeProductDetails.data.BI.filter((item) => item.risk == changeProductDetails.data.selectedRisk)[0];
      package_id = riskProductData.package_id;
      investment_target = riskProductData.selected_sum_Assured;
    } else {
      package_id = changeProductDetails.data.package_id;
      investment_target = changeProductDetails.data.selected_sum_Assured;
    }

    let requestObj = {
      family_product_id: selectedProduct.family_product_id,
      new_target_amount: investment_target,
      recommended_amount: changeProductDetails.data.recommended_sum_assured,
      user_journey_id: toJS(AuthStore.journeyId),
      need_id: need.id,
      family_member_id: changeProductDetails.data.id,
      previous_product: package_id,
      new_product: selectedProduct.package_id,
      reason_for_change: this.selectedReason.id,
      other_reason_description: this.selectedReason.other_reason_description,
      risk_type: changeProductDetails.data.selectedRisk || "high"
    };

    const changeProductData = await axios.post(APITypes.CHANGE_PRODUCT, requestObj);

    if (changeProductData.status == "success") {
      localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, false);
      let productData = this.formatProductResponseData(changeProductData);
      let productList = this.product_list_data;
      productList.forEach((item) => {

        if (item.need_unique_name === changeProductDetails.cardKey) {
          item.data.map((product, productIndex) => {
            if (!Array.isArray(product.BI) && product.family_product_id === changeProductDetails.data.family_product_id) {
              item.data[productIndex] = productData[0].data[0];
            }

            if (Array.isArray(product.BI)) {
              product.BI.forEach((biData, biIndex) => {
                if (biData.risk === product.selectedRisk && biData.family_product_id === changeProductDetails.data.family_product_id) {
                  product.BI[biIndex] = productData[0].data[0].BI[0];
                }
              });
            }
          });
        }
      });
      this.product_list_data = productList;
    }
    this.isChangeProductModalOpen = false;
    this.selectedReason = {};
  };

  async onGenerateSolutionData() {
    const generatedSolutionData = await this.createSolutionData();
    if (generatedSolutionData && generatedSolutionData.data.length > 0) {
      // props.history.push({pathname: `/solution/${AuthStore.journeyId}`,
      //     state:{
      //         solutionId: solutionId,
      //         leadSpecificId: leadSpecificId
      //     }
      // });
      return generatedSolutionData;
    }
    return [];
  }
  async createSolutionData() {
    const response = await axios.get(APITypes.GENERATE_SOLUTION_DATA + AuthStore.journeyId + "/");
    return response;
  }

  async getRevisedRecoSaTermNeedApiCall(requestData) {
    const response = await axios.post(APITypes.GET_REVISED_RECO_SA_TERM_NEED, requestData);
    if(response.status.toLowerCase() === 'success') {
      let productDataList = toJS(this.product_list_data);
      productDataList.forEach((item) => {
        item.data.forEach((value) => {
          if (!Array.isArray(value.BI)) {
            // For single products
            if (value.family_product_id == response.data.family_product_id) {
              value.recommended_sum_assured = response.data.recommended_sum_assured;
              value.recommended_premium = response.data.recommended_premium;
              value.maximum_sum_assured = response.data.maximum_sum_assured
              value.maximum_premium = response.data.maximum_premium
              value.selected_sum_Assured = response.data.selected_sum_assured
              value.selected_premium = response.data.selected_premium
            }
          } else {
            // For multiple products
            let riskProductData = value.BI.filter((item) => item.risk == value.selectedRisk)[0];
            if (riskProductData && riskProductData.family_product_id == response.data.family_product_id) {
              riskProductData.recommended_sum_assured = response.data.recommended_sum_assured;
              riskProductData.recommended_premium = response.data.recommended_premium;
              riskProductData.maximum_sum_assured = response.data.maximum_sum_assured
              riskProductData.maximum_premium = response.data.maximum_premium
              riskProductData.selected_sum_Assured = response.data.selected_sum_assured
              riskProductData.selected_premium = response.data.selected_premium
            }
          }
        });
      });
      console.log("getRevisedRecoSaTermNeedApiCall", productDataList)
      this.product_list_data = productDataList;
    }
  }

  setChangeProductData = (productData) => {
    this.changeProductData = productData
  }

  updateSelectedTargetAmount = (productData, targetAmount) => {
    let productDataList = toJS(this.product_list_data);
    productDataList.forEach((item) => {
      item.data.forEach((value) => {
        if (!Array.isArray(value.BI)) {
          // For single products
          if (value.product_id == productData.product_id && value.family_product_id == productData.family_product_id) {
            value.selected_sum_Assured = targetAmount;
          }
        } else {
          // For multiple products
          let riskProductData = value.BI.filter((item) => item.risk == value.selectedRisk)[0];
          if (riskProductData && riskProductData.product_id == productData.product_id && riskProductData.family_product_id == productData.family_product_id) {
            riskProductData.selected_sum_Assured = targetAmount;
          }
        }
      });
    });
    this.product_list_data = productDataList
  }

  onChangeMaturityPayoutMode(isPayoutChange) {
    this.maturityPayOutModeChange = isPayoutChange
  }

  setRecalculateProductsSpecs = bool => this.recalculateProductsSpecs = bool
}

export default new OptimizationStore();
