// React Imports
import React from "react"

// Package Imports
import { Col, Row, Card, Image, Form } from "react-bootstrap"
import { observer } from "mobx-react"

// Project Imports
import { CustomCheckbox, BottomsheetDropdown, } from "components"
import { SaveForRetirementImg } from "constants/Images"
import CalculatorCardComponent from "components/ui/calculator-card-component/CalculatorCardComponent"
import CalculatorTypeEnum from "../CalculatorTypeEnum"
import * as Constants from 'constants/Constants'

// CSS imports
import "./SaveForMyRetirement.css"


const SaveForMyRetirement = (props) => {

  const { isSelected } = props
  const saveForRetirementForm = props.formController.$("save_for_retirement")
  let showTargetAmountDropdown = saveForRetirementForm.$("target_amount_dropdown").type === 'text'
  let showTargetAmountValue = saveForRetirementForm.$("target_amount_value").type === 'text'

  return (
    <div className="d-flex flex-column flex-lg-row position-relative">
      <div className={!isSelected ? "card-overlay" : null}></div>
      <Card className="border-0 save-for-my-retirement-card flex-grow-1">
        <Card.Body className='p-2 pb-3'>
          <div>
            <Row className='d-flex align-items-start'>
              <Col xs={9} className='pr-0'>
                <CustomCheckbox
                  isChecked={isSelected}
                  onChange={() => props.toggleNeedSelection(isSelected)}
                  id="saveForMyRetirement"
                  shape="rounded"
                  title={saveForRetirementForm.label}
                  titleStyle="text-primary font-weight-medium font-size-16 font-size-md-14  pl-2"
                  containerStyle="align-items-start"
                  checkmarkStyle="radio-margin-top"
                  mark="checkmark"
                  className="choose-Need-checkBox"
                />

                <div className='ml-4 pl-2'>
                  <p className="font-size-14 font-size-md-12 font-weight-light description-text-color mb-0 mt-2 mt-md-0">
                    To take care of my needs after retirement,
                  </p>

                  <div className="align-items-center d-flex mt-2 justify-content-between mt-md-1">
                    <div className="pl-0 mt-2 mt-lg-0 w-100">
                      <BottomsheetDropdown
                        disabled={!isSelected}
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey="label"
                        bottomsheetTitle={saveForRetirementForm.$("target_amount_type").extra.bottomsheetTitle}
                        menuList={saveForRetirementForm.$("target_amount_type").extra.optionList}
                        onSelect={saveForRetirementForm.$("target_amount_type").onChange}
                        value={saveForRetirementForm.$("target_amount_type").value}
                        showErrorMsg={saveForRetirementForm.$("target_amount_type").error !== null}
                        errorMsg={saveForRetirementForm.$("target_amount_type").error}
                      />
                    </div>
                  </div>

                  {showTargetAmountDropdown && (
                    <div className="align-items-center d-flex mb-2 justify-content-between mt-2">
                      <div className="drop-down-style ml-lg-0 w-100">
                        <BottomsheetDropdown
                          disabled={!isSelected}
                          dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                          menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                          menuItemStyle='d-flex justify-content-between align-items-center'
                          selectedLabelKey="label"
                          bottomsheetTitle={saveForRetirementForm.$("target_amount_dropdown").extra.bottomsheetTitle}
                          menuList={saveForRetirementForm.$("target_amount_dropdown").extra.optionList}
                          onSelect={saveForRetirementForm.$("target_amount_dropdown").onChange}
                          value={saveForRetirementForm.$("target_amount_dropdown").value}
                          showErrorMsg={saveForRetirementForm.$("target_amount_dropdown").error}
                          errorMsg={saveForRetirementForm.$("target_amount_dropdown").error}
                        />
                      </div>
                    </div>
                  )}

                  {showTargetAmountValue && (
                    <div className="align-items-center d-flex mb-2 justify-content-between py-2">
                      <div className="w-100">
                        <Form.Control
                          placeholder=''
                          type="number"
                          value={saveForRetirementForm.$("target_amount_value").value}
                          onChange={saveForRetirementForm.$("target_amount_value").onChange}
                          style={saveForRetirementForm.$("target_amount_value").error ? { border: "1px solid red" } : { border: "1px solid #DBE2EA" }}
                          className='monthly-income-field font-size-12 font-weight-light'
                        />
                        {saveForRetirementForm.$("target_amount_value").error && <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>{saveForRetirementForm.$("target_amount_value").error}</p>}
                        {saveForRetirementForm.$("is_value_updated").value && <p className='mb-0 font-weight-light font-size-12 align-items-start d-flex' style={{ color: 'green' }}>{"Values refreshed based on new data"}</p>}
                      </div>
                    </div>
                  )}

                </div>
              </Col>
              <Col xs={3} className='text-lg-right'>
                <Image
                  className="retirement-choose-card-img"
                  src={SaveForRetirementImg}
                  alt="Save for my retirement"
                />
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>

      <div className="save-for-retirement-or-container">
        <span className="save-for-retirement-or-text">OR</span>
      </div>

      <CalculatorCardComponent
        chooseNeedFormController = {props.formController}
        dateOfBirthFromChooseNeeds={props.dateOfBirthFromChooseNeeds}
        setDateOfBirthFromChooseNeeds={props.setDateOfBirthFromChooseNeeds}
        monthlyExpenseValuesFromChooseNeeds={props.monthlyExpenseValuesFromChooseNeeds}
        setMonthlyExpenseValuesFromChooseNeeds={props.setMonthlyExpenseValuesFromChooseNeeds}
        calculatorModalType={CalculatorTypeEnum.RETIREMENT}
        modalDescription={Constants.RETIREMENT_CALCULATOR_MODAL_DESCRIPTION}
        // title={<span>Retirement <br /> Planner</span>}
        title={<p className="font-size-14 font-size-md-12 mb-0" style={{lineHeight: 1}}>Retirement <br/>Planner</p>}
        chooseCalculatorWhen={saveForRetirementForm.$("target_amount_type").value}
        useValueCallback={props.useValueCallback}
        thumbNailImg={SaveForRetirementImg}
      />
    </div>
  )
}

export default observer(SaveForMyRetirement)
