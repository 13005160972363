import React, { Component } from "react"
import { Col, Row, Card, Modal } from "react-bootstrap"

import {
  CustomCheckbox,
  BottomsheetDropdown,
} from "../../../../../../../components"
import { InfoCircleIcon } from "constants/Images"

import "./additional-cards.css"
import { observer } from "mobx-react"
import UiButton from "components/ui/button/Button"

class AdditionalCards extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isAdditionalBenefitModalOpen: false,
      modalTitle: "",
      modalDescription: "",
    }
  }

  showBenefitModal = (title, description) => {
    this.setState({
      isAdditionalBenefitModalOpen: true,
      modalTitle: title,
      modalDescription: description,
    })
  }

  hideBenefitModal = () => {
    this.setState({ isAdditionalBenefitModalOpen: false })
  }

  render() {
    const { form, data } = this.props
    return (
      <>
        <div className="additional-cards">
          <p className="mb-2 pb-1 font-size-14 font-weight-medium rider-section-title">
            Additional Benefits
          </p>
          <Card className="border">
            <Card.Body>
              <Row
                className={`mt-3 ${
                  form.$("family-income").disabled ? "disable-card" : ""
                }`}
              >
                <Col className="col-8">
                  <div className="d-flex align-items-center">
                    <CustomCheckbox
                      shape="rounded"
                      title={form.$("family-income").label}
                      titleStyle="font-weight-medium font-size-14 pl-2 text-primary"
                      isChecked={form.$("family-income").value}
                      onChange={() =>
                        form
                          .$("family-income")
                          .extra.changeBenefitStatus(form, data, form, data)
                      }
                      mark="checkmark"
                      disabled={form.$("family-income").disabled}
                    />
                    <img
                      className={`mb-2 pl-2 ${
                        form.$("family-income").disabled
                          ? "inf0-icon-disable"
                          : ""
                      }`}
                      src={InfoCircleIcon}
                      alt="info"
                      onClick={() =>
                        this.showBenefitModal(
                          form.$("family-income").label,
                          form.$("family-income").extra.description
                        )
                      }
                    />
                  </div>
                </Col>
                <Col className="align-items-center col-4 col-border-left d-flex flex-column justify-content-center col-line-height">
                  {form.$("family-income").extra.sum_assured != "" ? (
                    <React.Fragment>
                      <div className="d-flex justify-content-end">
                        <p className="mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary">
                          {" "}
                          {form.$("family-income").extra.sum_assured == "-"
                            ? form.$("family-income").extra.sum_assured
                            : global.toINR(
                                form.$("family-income").extra.sum_assured
                              )}
                        </p>
                      </div>
                    </React.Fragment>
                  ) : null}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          className="rider-info-modal"
          show={this.state.isAdditionalBenefitModalOpen}
          keyboard={false}
        >
          <Modal.Body className="text-center text-primary-title-label-color px-4 pt-4 pb-0">
            <React.Fragment>
              <h3 className="font-weight-medium font-size-22 font-size-md-32 mb-3">
                {this.state.modalTitle}
              </h3>
              <p className="font-weight-normal font-size-20 font-size-md-20 mb-0">
                {this.state.modalDescription}
              </p>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer className="d-block p-4 border-0">
            <Row className="modal-footer-row">
              <React.Fragment>
                <Col xs="12">
                  <UiButton
                    onClick={this.hideBenefitModal}
                    className="font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0"
                    variant="secondary"
                    buttonText="OKAY"
                  />
                </Col>
              </React.Fragment>
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default observer(AdditionalCards)
