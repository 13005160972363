// React imports
import React from 'react'

// Package Imports
import { Card } from 'react-bootstrap';
import { observer } from 'mobx-react';

// Project Imports
import { TypeHead } from 'components';
import { LocationOrangeIcon } from 'constants/Images';

// CSS Imports
import './card-text-field-component.css'


const CardTextFieldComponent = (props) => {

  return (
    <Card.Body className='p-0'>
      <div className={`bg-white d-flex text-input pincode-text-input ${props.showErrorMsg !== '' ? 'location-container' : ''}`} >
        <img className='px-2 location-suffix-icon' src={LocationOrangeIcon} alt='location-orange-icon' />
        <TypeHead
          className='pincode-field'
          placeholder={props.placeholder}
          options={props.menuList}
          minLength={2}
          selected={props.value}
          onSearch={props.onSearch}
          onChange={props.onChange}
        />
      </div>
      {
        props.showErrorMsg && (
          <p className='text-danger mb-0 font-weight-light font-size-11 align-items-start d-flex'>{props.errorMsg}</p>
        )
      }
    </Card.Body>
  )
}

export default observer(CardTextFieldComponent);
