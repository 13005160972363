const observer = (formRef) => {

 formRef.$('monthly_emi').$('home_loan_emi').$('paid_years').observe({
     key: 'value',
     call: ({ field }) => {
         const paidYearValue = field.value.value;
         const totalYearValue = formRef.$('monthly_emi').$('home_loan_emi').$('total_years').value.value

         if (paidYearValue !== undefined) {
             let arraylength = 30 - paidYearValue
             let totalYearsRangeOptionList = Array.from({ length: arraylength }, (_, i) => {
                 return {
                     id: i + (paidYearValue + 1),
                     label: i + (paidYearValue + 1),
                     value: i + (paidYearValue + 1),
                 }
             })
             formRef.$('monthly_emi').$('home_loan_emi').$('total_years').set('extra', {
                 optionList: totalYearsRangeOptionList
             })

             if (paidYearValue < totalYearValue) return

             formRef.$('monthly_emi').$('home_loan_emi').$('total_years').value = {}

         }
     }
 })

 formRef.$('monthly_emi').$('car_loan_emi').$('paid_years').observe({
     key: 'value',
     call: ({ field }) => {

         const paidYearValue = field.value.value;
         const totalYearValue = formRef.$('monthly_emi').$('car_loan_emi').$('total_years').value.value

         if (paidYearValue !== undefined) {
             let arraylength = 30 - paidYearValue
             let totalYearsRangeOptionList = Array.from({ length: arraylength }, (_, i) => {
                 return {
                     id: i + (paidYearValue + 1),
                     label: i + (paidYearValue + 1),
                     value: i + (paidYearValue + 1),
                 }
             })
             formRef.$('monthly_emi').$('car_loan_emi').$('total_years').set('extra', {
                 optionList: totalYearsRangeOptionList
             })

             if (paidYearValue < totalYearValue) return

             formRef.$('monthly_emi').$('car_loan_emi').$('total_years').value = {}
         }
     }
 })

 formRef.$('monthly_emi').$('other_loan_emi').$('paid_years').observe({
     key: 'value',
     call: ({ field }) => {
         const paidYearValue = field.value.value;
         const totalYearValue = formRef.$('monthly_emi').$('other_loan_emi').$('total_years').value.value

         if (paidYearValue !== undefined) {
             let arraylength = 30 - paidYearValue
             let totalYearsRangeOptionList = Array.from({ length: arraylength }, (_, i) => {
                 return {
                     id: i + (paidYearValue + 1),
                     label: i + (paidYearValue + 1),
                     value: i + (paidYearValue + 1),
                 }
             })
             formRef.$('monthly_emi').$('other_loan_emi').$('total_years').set('extra', {
                 optionList: totalYearsRangeOptionList
             })

             if (paidYearValue < totalYearValue) return

             formRef.$('monthly_emi').$('other_loan_emi').$('total_years').value = {}
         }
     }
 })

 formRef.$('monthly_emi').$('personal_loan_emi').$('paid_years').observe({
     key: 'value',
     call: ({ field }) => {
         const paidYearValue = field.value.value;
         const totalYearValue = formRef.$('monthly_emi').$('personal_loan_emi').$('total_years').value.value

         if (paidYearValue !== undefined) {
             let arraylength = 30 - paidYearValue
             let totalYearsRangeOptionList = Array.from({ length: arraylength }, (_, i) => {
                 return {
                     id: i + (paidYearValue + 1),
                     label: i + (paidYearValue + 1),
                     value: i + (paidYearValue + 1),
                 }
             })
             formRef.$('monthly_emi').$('personal_loan_emi').$('total_years').set('extra', {
                 optionList: totalYearsRangeOptionList
             })

             if (paidYearValue < totalYearValue) return

             formRef.$('monthly_emi').$('personal_loan_emi').$('total_years').value = {}
         }
     }
 })


 formRef.$('members').$('self').$('gender').observe({
    key: 'value',
    call: ({ field }) => {
        
        let salutationList = formRef.$("members").$('spouse').$('salutation').extra.optionList
        
        const selfGender = formRef.$("members").$('self').$("gender").value.value
        const spouseGender = formRef.$("members").$('spouse').$("gender").value.value

        console.log('fieldValue',selfGender);

        if(spouseGender){

            let femaleSalutation = salutationList.find((item) => item.label === "Mrs.")
            let maleSalutation = salutationList.find((item) => item.label === "Mr.")

            switch (selfGender) {

                case 'Male':
                    formRef.$("members").$('self').$("salutation").value = maleSalutation
                    formRef.$("members").$('spouse').$("salutation").value = femaleSalutation
                    break;

                case 'Female':
                    formRef.$("members").$('self').$("salutation").value = femaleSalutation
                    formRef.$("members").$('spouse').$("salutation").value = maleSalutation
                    break;
            
                default:
                    break;
            }

        }else if(selfGender === 'Female'){
            let salutation = salutationList.find((item) => item.label === "Ms.")
            formRef.$("members").$('self').$("salutation").value = salutation
        }else if(selfGender === 'Male'){
            let salutation = salutationList.find((item) => item.label === "Mr.")
            formRef.$("members").$('self').$("salutation").value = salutation
            
        }
    }
})


formRef.$('members').$('spouse').$('gender').observe({
    key: 'value',
    call: ({ field }) => {
        
        let salutationList = formRef.$("members").$('spouse').$('salutation').extra.optionList
        
        const selfGender = formRef.$("members").$('self').$("gender").value.value
        const spouseGender = formRef.$("members").$('spouse').$("gender").value.value

        if(selfGender){

             let femaleSalutation = salutationList.find((item) => item.label === "Mrs.")
             let maleSalutation = salutationList.find((item) => item.label === "Mr.")


            switch (spouseGender) {

                case 'Male':
                    formRef.$("members").$('spouse').$("salutation").value = maleSalutation
                    formRef.$("members").$('self').$("salutation").value = femaleSalutation
                    break;

                case 'Female':
                    formRef.$("members").$('spouse').$("salutation").value = femaleSalutation
                    formRef.$("members").$('self').$("salutation").value = maleSalutation
                    break;
            
                default:
                    break;
            }

        }else if(spouseGender === 'Female'){
            let salutation = salutationList.find((item) => item.label === "Ms.")
            formRef.$("members").$('spouse').$("salutation").value = salutation
        }else if(spouseGender === 'Male'){
            let salutation = salutationList.find((item) => item.label === "Mr.")
            formRef.$("members").$('spouse').$("salutation").value = salutation
            
        }
    }
})

}

export default observer