// React Imports
import React, { useContext, useEffect, useState } from "react";

// Package Imports
import { Container, Row, Col } from "react-bootstrap";
import { observer } from "mobx-react";
import { toJS } from "mobx";

// Project Imports
import { TitleTextSubtextBottom } from "../../components";
import ChooseNeedsForm from "./components/form/choose-needs";
import ChooseNeedsFormController from "./components/form/choose-needs/controller";
import AuthStore from "modules/login/store/AuthStore";
import FormContext from "context/FormContext";
import savePragatiNAInboundData from "utils/savePragatiNAInboundData";

// CSS Imports
import "./choose-needs-and-set-targets.css";


const ChooseNeedsAndSetTargets = (props) => {
  const [formController, setFormController] = useState(undefined);
  const formContext = useContext(FormContext)

  useEffect(() => {
    if (AuthStore.journeyData !== undefined && formController == undefined) {
      let formController = new ChooseNeedsFormController();
      let journeyId = toJS(AuthStore.journeyId);
      let journeyData = toJS(AuthStore.journeyData);
      formController.setDefaults(journeyId, journeyData);
      formContext.setFormController(formController)
      setFormController(formController);
    }
  }, [AuthStore.journeyData, formController]);

  useEffect(() => {
      savePragatiNAInboundData(location)
  }, []);

  if (formController === undefined) return null;
  return (
    <Container className='mt-3 pt-1 pb-2 pb-md-4 choose-needs-and-set-targets margin-bottom-135'>
      <Row>
        <Col className='col-12 col-md-9 mb-3 mb-lg-0 mt-4 mt-lg-0'>
          <TitleTextSubtextBottom
            title={
              <span>
                Choose Needs <span className='font-weight-light'>&</span> Set Targets
              </span>
            }
            subText='The popular ones are already selected'
          />
        </Col>
      </Row>
      <ChooseNeedsForm formController={formController} {...props} />
    </Container>
  );
};

export default observer(ChooseNeedsAndSetTargets);
