
const ProtectionCalcultorObserver = (formRef) => {

    formRef.$('monthly_emi').$('home_loan_emi').$('paid_years').observe({
        key: 'value',
        call: ({ field }) => {
            const paidYearValue = field.value.value;
            const totalYearValue = formRef.$('monthly_emi').$('home_loan_emi').$('total_years').value.value

            if (paidYearValue !== undefined) {
                let arraylength = 30 - paidYearValue
                let totalYearsRangeOptionList = Array.from({ length: arraylength }, (_, i) => {
                    return {
                        id: i + (paidYearValue + 1),
                        label: i + (paidYearValue + 1),
                        value: i + (paidYearValue + 1),
                    }
                })
                formRef.$('monthly_emi').$('home_loan_emi').$('total_years').set('extra', {
                    optionList: totalYearsRangeOptionList
                })

                if (paidYearValue < totalYearValue) return

                formRef.$('monthly_emi').$('home_loan_emi').$('total_years').value = {}

            }
        }
    })

    formRef.$('monthly_emi').$('car_loan_emi').$('paid_years').observe({
        key: 'value',
        call: ({ field }) => {

            const paidYearValue = field.value.value;
            const totalYearValue = formRef.$('monthly_emi').$('car_loan_emi').$('total_years').value.value

            if (paidYearValue !== undefined) {
                let arraylength = 30 - paidYearValue
                let totalYearsRangeOptionList = Array.from({ length: arraylength }, (_, i) => {
                    return {
                        id: i + (paidYearValue + 1),
                        label: i + (paidYearValue + 1),
                        value: i + (paidYearValue + 1),
                    }
                })
                formRef.$('monthly_emi').$('car_loan_emi').$('total_years').set('extra', {
                    optionList: totalYearsRangeOptionList
                })

                if (paidYearValue < totalYearValue) return

                formRef.$('monthly_emi').$('car_loan_emi').$('total_years').value = {}
            }
        }
    })

    formRef.$('monthly_emi').$('other_loan_emi').$('paid_years').observe({
        key: 'value',
        call: ({ field }) => {
            const paidYearValue = field.value.value;
            const totalYearValue = formRef.$('monthly_emi').$('other_loan_emi').$('total_years').value.value

            if (paidYearValue !== undefined) {
                let arraylength = 30 - paidYearValue
                let totalYearsRangeOptionList = Array.from({ length: arraylength }, (_, i) => {
                    return {
                        id: i + (paidYearValue + 1),
                        label: i + (paidYearValue + 1),
                        value: i + (paidYearValue + 1),
                    }
                })
                formRef.$('monthly_emi').$('other_loan_emi').$('total_years').set('extra', {
                    optionList: totalYearsRangeOptionList
                })

                if (paidYearValue < totalYearValue) return

                formRef.$('monthly_emi').$('other_loan_emi').$('total_years').value = {}
            }
        }
    })

    formRef.$('monthly_emi').$('personal_loan_emi').$('paid_years').observe({
        key: 'value',
        call: ({ field }) => {
            const paidYearValue = field.value.value;
            const totalYearValue = formRef.$('monthly_emi').$('personal_loan_emi').$('total_years').value.value

            if (paidYearValue !== undefined) {
                let arraylength = 30 - paidYearValue
                let totalYearsRangeOptionList = Array.from({ length: arraylength }, (_, i) => {
                    return {
                        id: i + (paidYearValue + 1),
                        label: i + (paidYearValue + 1),
                        value: i + (paidYearValue + 1),
                    }
                })
                formRef.$('monthly_emi').$('personal_loan_emi').$('total_years').set('extra', {
                    optionList: totalYearsRangeOptionList
                })

                if (paidYearValue < totalYearValue) return

                formRef.$('monthly_emi').$('personal_loan_emi').$('total_years').value = {}
            }
        }
    })

}

export default ProtectionCalcultorObserver