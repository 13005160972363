const { Form } = require("mobx-react-form");

import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

import moment from "moment";

import setInitialDefaults from './initialDefault'
import formObserver from './observers';
import * as keys from "./keys";
import Utils from "./utils";
import FamilyDetailsStore from '../../../../family-details-form/store/FamilyDetailsStore';

class WealthPremierFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          rules: "required:string",
          disabled: false,
          extra: {
            optionList: [
              {
                id: '10',
                label: '10',
              },
            ],
          },
        },
        {
          name: "premium-term",
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            optionList: [
              {
                id: '1',
                label: '1',
              },
            ],
          },
        },
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "5",
                label: "Single",
              },
            ],
          },
        },
        {
          name: "life-cover-option",
          label: "Life Cover Option",
          placeholder: "",
          extra: {
            jointFieldDisable: false,
            optionList: [
              {
                id: "1",
                label: "Single Life",
              },
              {
                id: "2",
                label: "Joint Life",
              },
            ],
          },
        },
        {
          name: "second-life-assured-gender",
          label: "Second Life Assured Gender",
          value: "",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "M",
                label: "Male",
              },
              {
                id: "F",
                label: "Female",
              },
            ],
          },
        },
        {
          name: "second-life-assured-name",
          label: "Second Life Assured Name",
          value: "",
          placeholder: "",
          extra: {
            onBlur(form, productId, productdata) {
              let name = form.$("second-life-assured-name").value
              Utils.updateBi(keys.LA_NAME, name, productId, form, productdata)
            }
          },
        },
        {
          name: "second-life-assured-dob",
          label: "Second Life Assured DOB",
          value: "",
          placeholder: "",
          extra: {
            changeDob(form, value, productId, productdata) {
              let spouseDOB = value == '' ? FamilyDetailsStore.dateValue : value
              form.$("second-life-assured-dob").set("value", spouseDOB)

              let dob = moment(spouseDOB).format('DD MMM YYYY')
              let age = moment().diff(dob, 'years');
              let requestData = {
                age: age,
                dob: spouseDOB
              }

              Utils.updateBi(keys.LA_DOB, requestData, productId, form, productdata)
            }
          },
        },
        {
          name: "fund-strategy",
          label: "Select Investment",
          placeholder: "",
          extra: {
            showFundDistributionModal: false,
            setDistributionButtonText: "Set Distribution",
            async onSetDistribution(fundList, form, data) {
              let productId = data.data.product_id
              form.$("fund-strategy").set("extra", {
                ...form.$("fund-strategy").extra,
                showFundDistributionModal: false,
                fundList: fundList
              });
              await Utils.setDistribution(fundList, productId, form, data);
            },
            fundList: [
              {
                id: "EquityLargeCapFund",
                label: "Equity Large Cap Fund",
                checkedValue: false,
                key: keys.EQUITY_LARGE_CAP_FUND,
                value: 0,
              },
              {
                id: "EquityTop250Fund",
                label: "Equity Top 250 Fund",
                checkedValue: false,
                key: keys.EQUITY_TOP_20_FUND,
                value: 0,
              },
              {
                id: "EquityMidCapFund",
                label: "Equity Mid-Cap Fund",
                checkedValue: false,
                key: keys.EQUITY_MID_CAP_FUND,
                value: 0,
              },
              {
                id: "EquityBlueChipFund",
                label: "Equity Blue Chip Fund",
                checkedValue: false,
                key: keys.EQUITY_BLUE_CHIP_FUND,
                value: 0,
              },
              {
                id: "GILTFund",
                label: "GILT Fund",
                checkedValue: false,
                key: keys.GLIT_FUND,
                value: 0,
              },
              {
                id: "BondFund",
                label: "Bond Fund",
                checkedValue: false,
                key: keys.BOND_FUND,
                value: 0,
              },
              {
                id: "ManagedFund",
                label: "Managed Fund",
                checkedValue: false,
                key: keys.MANAGED_FUND,
                value: 0,
              },
              {
                id: "LongTermBondFund",
                label: "Long Term Bond Fund",
                checkedValue: false,
                key: keys.LONG_TERM_BOND_FUND,
                value: 0,
              },
            ],
            toggleFundDistributionModal(form, status, fundList, productId) {
              form.$("fund-strategy").set("extra", {
                ...form.$("fund-strategy").extra,
                showFundDistributionModal: status,
                fundList: fundList
              });

              // Trigger when modal will close
              if (!status) {
                Utils.updateBi(null, null, productId, form)
              }
            },
          },
        },
        {
          name: "premium",
          label: "Premium",
          value: "",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremiun(value, data, form) {
              // Utils.updateBi(keys.PREMIUM, value, data.data.product_id, form, data)
            }
          },
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data);
  }
}

export default WealthPremierFormController;