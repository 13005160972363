// Project Imports
import ProductNames from "constants/ProductNames";
import ProductPackageId from "constants/ProductPackageId";

const showMaturityBenefitAmount = function (data) {
  console.log("showMaturityBenefitAmount", data);

  if (!Array.isArray(data.BI)) {
    return false;
  } else {
    // For With tabs products

    // Current props data
    let selectedRiskData = data.BI.find((item) => item.risk === data.selectedRisk);

    switch (selectedRiskData?.product_id) {
      case ProductNames.premierGuranteedIncome:
        switch (selectedRiskData.package_id) {
          case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME:
          case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
          case ProductPackageId.PREMIER_GUARANTEED_RETIREMENT_INCOME:
            return false;

          default:
            return false;
        }

      case ProductNames.activeIncomePlan:
      case ProductNames.cashFlowProtectionPlus:
      case ProductNames.guaranteedIncomeStar:
        if (selectedRiskData?.bi_request?.LumpsumBenefit === 22) {
          return false;
        } else {
          return false;
        }

      case ProductNames.incomeBuilder:
        switch (selectedRiskData.package_id) {
          case ProductPackageId.INCOME_BUILDER:
            return false;

          case ProductPackageId.SECURED_INCOME_OPTION_INCOME_BUILDER:
            return false;

          default:
            return false;
        }
      case ProductNames.wealthUltima:
        // bi request.optionId3 == 5 then SWP else non SWP
        switch (selectedRiskData.package_id) {
          case ProductPackageId.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
            return false;
          default:
            return false;
        }

      case ProductNames.flexiSavingsPlan:
        switch (selectedRiskData.package_id) {
          case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
            return false

          case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
            if (Number(selectedRiskData?.bi_request?.AccrualOfSurvivalBenefits) === 12) {
              return false
            } else {
              return false
            }

          default:
            return false;
        }

      default:
        return false;
    }
  }
};

export default showMaturityBenefitAmount;
