import React, { Component } from "react"
import { Col, Row, Card, Modal } from "react-bootstrap"

import {
  CustomCheckbox,
  BottomsheetDropdown,
} from "../../../../../../../components"
import { InfoCircleIcon } from "constants/Images"

import "./additional-cards.css"
import { observer } from "mobx-react"
import UiButton from "components/ui/button/Button"

class AdditionalCards extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAdditionalBenefitModalOpen: false,
      modalTitle: "",
      modalDescription: "",
    }
  }

  showBenefitModal = (title, description) => {
    this.setState({
      isAdditionalBenefitModalOpen: true,
      modalTitle: title,
      modalDescription: description,
    })
  }

  hideBenefitModal = () => {
    this.setState({ isAdditionalBenefitModalOpen: false })
  }

  render() {
    const { form, data } = this.props

    let maritalStatus = form.$("marital-status").value.id
    return (
      <>
        <div className="additional-cards">
          <p className="mb-2 pb-1 font-size-14 font-weight-medium text-dark-grey">
            Additional Benefits
          </p>
          <Card className="border">
            <Card.Body>
              {/* 1st */}
              {!(form.$('better-half-benefit').disabled || maritalStatus === '1') && (
                <Row
                  className={` ${form.$("better-half-benefit").disabled ? "disable-card" : ""
                    }`}
                >
                  <Col className="col-9">
                    <div className="d-flex align-items-center">
                      <CustomCheckbox
                        shape="rounded"
                        title={form.$("better-half-benefit").label}
                        titleStyle="font-weight-medium font-size-14 pl-2 additional-benefits-cards-text"
                        isChecked={form.$("better-half-benefit").value}
                        onChange={() =>
                          form
                            .$("better-half-benefit")
                            .extra.changeBenefitStatus(form, data)
                        }
                        mark="checkmark"
                      />
                      <img
                        className={`pl-2 ${form.$("better-half-benefit").disabled
                          ? "inf0-icon-disable"
                          : ""
                          }`}
                        src={InfoCircleIcon}
                        alt="info"
                        onClick={() =>
                          this.showBenefitModal(
                            form.$("better-half-benefit").label,
                            form.$("better-half-benefit").extra.description
                          )
                        }
                      />
                    </div>
                  </Col>
                  <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                    {data?.data?.firstYearResponse?.BHB_MODAL_PREM ? (
                      <React.Fragment>
                        <div className="d-flex justify-content-end">
                          <p className="mb-0 font-weight-bold font-size-20 text-secondary">
                            {" "}
                            {global.toINR(data?.data?.firstYearResponse?.BHB_MODAL_PREM)}
                          </p>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </Col>
                </Row>
              )}
              <Row
                className={`mt-3 ${form.$("childs-future-protect-benefit").disabled ? "disable-card" : ""
                  }`}
              >
                <Col className="col-9">
                  <div className="d-flex align-items-center">
                    <CustomCheckbox
                      shape="rounded"
                      title={form.$("childs-future-protect-benefit").label}
                      titleStyle="font-weight-medium font-size-14 pl-2 additional-benefits-cards-text"
                      isChecked={form.$("childs-future-protect-benefit").value}
                      onChange={() =>
                        form
                          .$("childs-future-protect-benefit")
                          .extra.onChangechildBenefit(form, data)
                      }
                      mark="checkmark"
                    />
                    <img
                      className={`pl-2 ${form.$("childs-future-protect-benefit").disabled
                        ? "inf0-icon-disable"
                        : ""
                        }`}
                      src={InfoCircleIcon}
                      alt="info"
                      onClick={() =>
                        this.showBenefitModal(
                          form.$("childs-future-protect-benefit").label,
                          form.$("childs-future-protect-benefit").extra.description
                        )
                      }
                    />
                  </div>
                </Col>
                <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                  {data?.data?.firstYearResponse?.CHILD_MODAL_PREM ? (
                    <React.Fragment>
                      <div className="d-flex justify-content-end">
                        <p className="mb-0 font-weight-bold font-size-20 text-secondary">
                          {" "}
                          {global.toINR(data?.data?.firstYearResponse?.CHILD_MODAL_PREM)}
                        </p>
                      </div>
                    </React.Fragment>
                  ) : null}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          className="rider-info-modal"
          show={this.state.isAdditionalBenefitModalOpen}
          keyboard={false}
        >
          <Modal.Body className="text-center text-primary-title-label-color px-4 pt-4 pb-0">
            <React.Fragment>
              <h3 className="font-weight-medium font-size-22 font-size-md-32 mb-3">
                {this.state.modalTitle}
              </h3>
              <p className="font-weight-normal font-size-20 font-size-md-20 mb-0">
                {this.state.modalDescription}
              </p>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer className="d-block p-4 border-0">
            <Row className="modal-footer-row">
              <React.Fragment>
                <Col xs="12">
                  <UiButton
                    onClick={this.hideBenefitModal}
                    className="font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0"
                    variant="secondary"
                    buttonText="OKAY"
                  />
                </Col>
              </React.Fragment>
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default observer(AdditionalCards)
