const FormObserver = (formRef) => {

  // const memberObj = formRef.get()
  // const familyMemberList = [];

  //   for(const key in memberObj){
  //     familyMemberList.push(key)
  //   }  

  //   console.log('familyMemberList',formRef.get());

  //   familyMemberList.forEach((member)=>{

  //   formRef.$(member).$("same_as_mobile_number").observe({
  //     key: "disabled",
  //     call: ({ field, form }) => {

  //       let mobileNoValue = formRef.$(member).$("mobile_number").value
  //       let mobileCodeValue = formRef.$(member).$("mobile_number_code").value

        
  //       if (field.disabled && mobileNoValue) {
  //         formRef.$(member).$("whatsapp_mobile_number").value = mobileNoValue
  //         formRef.$(member).$("whatsapp_mobile_number_code").value = mobileCodeValue
  //       }else{
  //        formRef.$(member).$("whatsapp_mobile_number").value = ''
  //        formRef.$(member).$("whatsapp_mobile_number_code").value = ''
  //       }
  //     },
  //   })

  //   })

}

export default FormObserver
