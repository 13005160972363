// Project Imports
import Product from 'constants/ProductConstants';

const fetchChildBenefit = (solutionData) => {
    let childBenefitApplicable = false

    solutionData.coverage.forEach((coverage) => {
        coverage.life_assured_list.forEach(lifeAssured => {
            lifeAssured.product_details.forEach(obj => {
                switch (obj.product_name) {
                    case Product.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
                    case Product.SECURED_INCOME_OPTION_INCOME_BUILDER:
                    case Product.LITTLE_CHAMP_WEALTH_ULTIMA:
                    case Product.RISING_STAR_WEALTH_PLUS:
                    case Product.CHILD_VARIANT_WEALTH_SECURE_PLUS:
                    case Product.ACTIVE_INCOME_PLAN_WITH_FIB:
                    case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
                    case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
                    case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
                    case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
                    case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
                    case Product.GUARANTEED_INCOME_STAR_FIB:
                    case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
                    case Product.GUARANTEED_SAVINGS_STAR_INCOME_FIB:  
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:   
                    case Product.GUARANTEED_INCOME_STAR_FIB:   
                        childBenefitApplicable = true
                        break;
                }
            })
        })
    })

    let lifeCoverMsg = ''
    if (childBenefitApplicable) {
        lifeCoverMsg = 'In case of death of policyholder, future premiums are waived off and all benefits will continue to be paid.'
    }
    return lifeCoverMsg
}

export default fetchChildBenefit