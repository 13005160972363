import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'

import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import FamilyDetailsStore from '../../family-details-form/store/FamilyDetailsStore';

const ValidationErrorMessageShowModal = observer(class ValidationErrorMessageShowModal extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    onHide = () => {
        FamilyDetailsStore.showErrorMsg = ''
    }
    render() {
        const showErrorMsg = toJS(FamilyDetailsStore.showErrorMsg)
        return (
            <div>
                <Modal
                    size='md'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                    backdrop="static"
                    className='error-modal'
                    show={!!showErrorMsg}
                    onHide={this.onHide}
                    onExited={this.onHide}
                    keyboard={false}
                // animation={false}
                >
                    <Modal.Header closeButton className='border-0 shadow-sm'>
                        <Modal.Title className='font-size-md-18 font-size-16 text-primary-title-label-color'>
                            Error
            </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=''>
                        <div className=''>
                            <span className='font-weight-medium font-size-22 text-primary-title-label-color'>{showErrorMsg}</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' className='font-size-18 m-0 px-5' onClick={this.onHide}>
                            Ok
          </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
});

ValidationErrorMessageShowModal.defaultsProps = {}

ValidationErrorMessageShowModal.propTypes = {}

export default ValidationErrorMessageShowModal
