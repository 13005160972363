// Package Imports
import { toJS } from "mobx"
import moment from "moment"

// Project Imports
import axios from "utils/axios"
import * as APITypes from "constants/API"
import AuthStore from "modules/login/store/AuthStore"
import OptimizationStore from "modules/optimization/store/OptimizationStore"
import fetchNeedMaster from "utils/fetchNeedMasterData"
import FamilyMemberModalTypeEnum from "constants/familyMemberModalTypeEnum";
import StorageKeys from "constants/StorageKeys";
import submitEducationOccupationLocationData from "utils/submitEducationOccupationLocationData"
import NeedTypeEnum from "constants/NeedTypeEnum"

const submitDataToTheServer = async (formController, hideHealthPopUpModal) => {

  const dataToBeSubmitted = submitEducationOccupationLocationData(formController, OptimizationStore, AuthStore)

  // let response = await axios.post(
  //   APITypes.SAVE_FAMILY_DETAILS,
  //   dataToBeSubmitted
  // )

  // !calling the backend api Fetch BI Data based on provided recommendation
  let family = dataToBeSubmitted.family_list[0];
  const formFieldValues = formController.get()

  let masterData = await fetchNeedMaster()

  if (masterData.length <= 0) return

  console.log('OptimizationStore.selectedNeed', OptimizationStore.selectedNeed);

  let selectedNeedId = masterData.find((singleNeed) => singleNeed.unique_name === OptimizationStore.selectedNeed).id
  const payoutModeType = formFieldValues.recommendation.value.mode.toLowerCase()
  const targetAmountValue = formFieldValues.recommendation.value.id

  let recommendationReqDataToBeSubmitted = {
    "user_journey": toJS(AuthStore.journeyId),
    "family": OptimizationStore.selectedFamilyMemberId,
    "need": selectedNeedId,
    "target_amount": targetAmountValue,
    "target_amount_value": "",
    "payout_mode": payoutModeType,
    "is_selected": family.is_selected,
    "occupation_id": family.occupation,
    "education_id": family.education,
    "state": family.state,
    "office_name": family.office_name,
    "pin_code": family.pin_code,
  }
  switch (OptimizationStore.familyMemberModalType) {
    case FamilyMemberModalTypeEnum.CHANGE_LA:
      // props.changeLA()
      OptimizationStore.changeLA(recommendationReqDataToBeSubmitted, hideHealthPopUpModal,NeedTypeEnum.PROTECTION_FOR_MY_HEALTH)
      return

    case FamilyMemberModalTypeEnum.ADD_INSURED:
      let familyMemberData = OptimizationStore.fetchFamilyMemberListForLifeAssured().find((item) => item.id === OptimizationStore.selectedFamilyMemberId);
      const addInsuredObj = {
        family_member_id: familyMemberData.id,
        user_journey_id: toJS(AuthStore.journeyId),
        need_id: selectedNeedId,
        is_selected: family.is_selected,
        occupation_id: family.occupation,
        education_id: family.education,
        state: family.state,
        office_name: family.office_name,
        pin_code: family.pin_code,
        is_calculator_used: false,
        monthly_income: familyMemberData.monthly_income,
        dob: familyMemberData.dob,
        target_amount_id: targetAmountValue,
        target_amount_value: "",
        payout_mode: payoutModeType,
      };
      let responseData = await axios.post(APITypes.ADD_INSURED, addInsuredObj);

      if (responseData.data.status !== "error") {
        localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, false);
        OptimizationStore.addInsuredData(responseData.data, hideHealthPopUpModal);
      }

      return;
      break;

    case FamilyMemberModalTypeEnum.ADD_NEED:
      let journeyData = toJS(AuthStore.journeyData)
      let memberData = journeyData.family_details_list.find((member) => member.id === OptimizationStore.selectedFamilyMemberId)
      let addNeedObj = {
        "family_id": OptimizationStore.selectedFamilyMemberId,
        "journey_id": toJS(AuthStore.journeyId),
        "need_id": selectedNeedId,
        "payout_mode": formFieldValues.recommendation.value.mode.toLowerCase(),
        "target_amount": formFieldValues.recommendation.value.id,
        "target_value": "",
        "is_calculator_used": false,
        "is_selected": true,
        "is_removed": false,
        "occupation_id": family.occupation,
        "education_id": family.education,
        "state": family.state,
        "office_name": family.office_name,
        "pin_code": family.pin_code,
        "dob": memberData.dob,

      }
      OptimizationStore.addNeedAPICall(addNeedObj, OptimizationStore.selectedNeed, hideHealthPopUpModal)
      return
  }
  let responseDataArray = []

  let biResponse = await axios.post(
    APITypes.FETCH_BI_DATA_BASED_ON_PROVIDED_RECOMMENDATION,
    recommendationReqDataToBeSubmitted
  )

  responseDataArray.push(biResponse.data)

  hideHealthPopUpModal()

  OptimizationStore.addNeed({
    "data": responseDataArray
  })
}

const isValidFunction = async (formController) => {
  const validValue = formController
    .validate({ showErrors: true })
    .then(({ isValid }) => {
      if (!isValid) {
        console.log("Form errors are ", formController.errors())
      }
      return isValid
    })

  return validValue
}

export const onSet = async (formController, hideHealthPopUpModal) => {
  const valid = await isValidFunction(formController)

  if (valid) {
    submitDataToTheServer(formController, hideHealthPopUpModal)
  }
}
