//package imports
import { toJS } from "mobx"

// project imports
import OptimizationStore from "modules/optimization/store/OptimizationStore"
import * as APITypes from "constants/API"
import axios from "utils/axios"

export const fetchCashFlowTableData = async (productData) => {

  // let reqObj = toJS(OptimizationStore.fetchIndividualProductTimeLineReqParam)

  // if(familyProductIdForSingleTab){
  //   // console.log('familyProductIdForSingleTab',familyProductIdForSingleTab);
  //   reqObj = {"family_product_id" : familyProductIdForSingleTab,"risk_type" : 'high'}    
  // }
  let reqObj = {"family_product_id" : productData.data.family_product_id ,"risk_type" : productData.data.selectedRisk || 'high'}    

  try {
    const response = await axios.post(APITypes.FETCH_PRODUCT_TIME_LINE, reqObj)
    return response
  } catch (error) {
    console.log(error)
  }

}