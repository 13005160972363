// Package Imports
import { observable, action, makeObservable } from "mobx";
import { toJS } from "mobx";
const _ = require('lodash');

// Project Imports
import axios from "../../../utils/axios";
import * as APITypes from "../../../constants/API";
import AuthStore from "../../login/store/AuthStore";

class ChooseNeedsStore {
  lumpsumCoverList = [
    { id: 0, label: "5 to 10 Lacs", isSelected: false },
    { id: 1, label: "10 to 25 Lacs", isSelected: false },
    { id: 2, label: "25 to 50 Lacs", isSelected: false },
    { id: 3, label: "50 Lacs to 1 Cr", isSelected: false },
    { id: 4, label: "1 Cr to 5 Cr", sisSelected: false },
    { id: 5, label: "More than 5 Cr", isSelected: false },
  ];
  familyProtectionLumpsumList = [
    { id: 0, label: "50 Lacs", isSelected: false },
    { id: 1, label: "75 Lacs", isSelected: false },
    { id: 2, label: "1 Crore", isSelected: false },
    { id: 3, label: "2 Crore", isSelected: false },
    { id: 4, label: "5 Crore", sisSelected: false },
    { id: 5, label: "More than 5 Crore", isSelected: false },
  ];
  healthProtectionLumpsumList = [
    { id: 0, label: "5 Lacs", isSelected: false },
    { id: 1, label: "10 Lacs", isSelected: false },
    { id: 2, label: "15 Lacs", isSelected: false },
    { id: 3, label: "20 Lacs", isSelected: false },
    { id: 4, label: "25 Lacs", sisSelected: false },
    { id: 5, label: "More than 25 Lacs", isSelected: false },
  ];
  incomeCoverList = [
    { id: 0, label: "10,000 to 15,000 p.m", isSelected: false },
    { id: 1, label: "15,000 to 25,000 p.m.", isSelected: false },
    { id: 2, label: "25,000 to 50,000 p.m.", isSelected: false },
    { id: 3, label: "50,000 to 1 Lac p.m.", isSelected: false },
    { id: 4, label: "1 Lac to 2 Lacs p.m.", sisSelected: false },
    { id: 5, label: "More than 2 Lacs p.m.", isSelected: false },
  ];

  wouldNeedList = [
    { id: 1, label: "Lumpsum", isSelected: true, key: "lumpsum" },
    { id: 0, label: "Annual", isSelected: false, key: "annual_income" },
  ];

  ProtectionOfFmailyWouldNeedList = [
    { id: 0, label: "Recommend me an income amount", isSelected: true, key: "Recommend me an income amount" },
    { id: 1, label: "Recommend me a lumpsum amount", isSelected: false, key: "Recommend me a lumpsum amount" },
    { id: 2, label: "I would need an income of", isSelected: false, key: "annual_income" },
    { id: 3, label: "I would need a lumpsum of", isSelected: false, key: "lumpsum" },
  ];

  retirementWouldNeedList = [
    { id: 0, label: "I would need an icome of", isSelected: true, key: "annual_income" },
    { id: 1, label: "Recommend me an income amount", isSelected: false, key: "lumpsum" },
  ];

  selectedNeeds = {
    protectionForMyFamily: {
      id: "protectionForMyFamily",
      title: "Protection for my family",
      isSelected: true,
      monthlyIncome: "",
      needCover: {},
      amountOf: {},
      insuredMembers: [{ id: 1 }],
      action: "save",
      need_id: "1"
    },
    protectionForMyHealth: {
      id: "protectionForMyHealth",
      title: "Protection for my health",
      isSelected: false,
      amountOf: {},
      needCover: {},
      insuredMembers: [{ id: 1 }],
      action: "save",
      need_id: "2"
    },
    growMyMoney: {
      id: "growMyMoney",
      title: "Grow my money",
      isSelected: false,
      needCover: {},
      amountOf: {},
      insuredMembers: [],
      action: "save",
      need_id: "3"
    },
    saveForChildren: {
      id: "saveForChildren",
      title: "Save for children",
      isSelected: false,
      needCover: {},
      amountOf: {},
      insuredMembers: [],
      action: "save",
      need_id: "4"
    },
    saveForRetirement: {
      id: "saveForRetirement",
      title: "Save for retirement",
      isSelected: false,
      needCover: {},
      amountOf: {},
      insuredMembers: [],
      action: "save",
      need_id: "5"
    },
  };

  needList = [];
  updatedNeedList = [];
  isSelectedNeed = false;
  isFamilyNeedCoverSelect = false;
  isMonthlyIncomeAdded = false;
  isHealthNeedCoverSelect = false;
  isMoneyNeedCoverSelect = false;
  isMoneyAmountSelect = false;
  isFamilyAmountSelect = false;
  isChildNeedCoverSelect = false;
  isChildAmountSelect = false;
  isRetirementNeedCoverSelect = false;
  isRetirementAmountSelect = false;

  protectionCalculatorReqObj= {}
  chooseNeedFormControllerFromStore = {}
  isCalculatorErrorForFamilyProtection = false;
  isCalculatorErrorForRetirement = false;
  salutationList = [];
  isProtectionCalculatorUsed = false;
  isRetirementCalculatorUsed = false; 
  monthlyIncomeOfSelf = ''

  previouslySelectedNeedObject = {}

  onUpdateValue = async (key, value) => {
    let selectedNeeds = toJS(this.selectedNeeds);
    switch (key) {
      case "preNeedSelectionModal":
        value.action = this.updateAction(selectedNeeds.preNeedSelectionModal, value);
        selectedNeeds.preNeedSelectionModal = { ...selectedNeeds.preNeedSelectionModal, ...value };
        // if (!selectedNeeds.preNeedSelectionModal.isEdelweissStaff) {

        // }

        break;

      case "protectionForMyFamily":
        value.action = this.updateAction(selectedNeeds.protectionForMyFamily, value);
        selectedNeeds.protectionForMyFamily = { ...selectedNeeds.protectionForMyFamily, ...value };
        if (!selectedNeeds.protectionForMyFamily.isSelected) {
          selectedNeeds.protectionForMyFamily.needCover = {};
          selectedNeeds.protectionForMyFamily.amountOf = {};
        }

        this.isCalculatorErrorForFamilyProtection =  selectedNeeds.protectionForMyFamily.needCover.label !== "Recommend me an income amount" ||
          selectedNeeds.protectionForMyFamily.needCover.label !== "Recommend me a lumpsum amount" ? false : null;


        break;

      case "protectionForMyHealth":
        value.action = this.updateAction(selectedNeeds.protectionForMyHealth, value);
        selectedNeeds.protectionForMyHealth = { ...selectedNeeds.protectionForMyHealth, ...value };
        if (!selectedNeeds.protectionForMyHealth.isSelected) {
          selectedNeeds.protectionForMyHealth.amountOf = {};
        }
        this.isHealthNeedCoverSelect = false;

        break;

      case "growMyMoney":
        value.action = this.updateAction(selectedNeeds.growMyMoney, value);
        selectedNeeds.growMyMoney = { ...selectedNeeds.growMyMoney, ...value };
        if (!selectedNeeds.growMyMoney.isSelected) {
          selectedNeeds.growMyMoney.needCover = {};
          selectedNeeds.growMyMoney.amountOf = {};
        }

        break;

      case "saveForChildren":
        value.action = this.updateAction(selectedNeeds.saveForChildren, value);
        selectedNeeds.saveForChildren = { ...selectedNeeds.saveForChildren, ...value };
        if (!selectedNeeds.saveForChildren.isSelected) {
          selectedNeeds.saveForChildren.needCover = {};
          selectedNeeds.saveForChildren.amountOf = {};
        }

        break;

      case "saveForRetirement":
        value.action = this.updateAction(selectedNeeds.saveForRetirement, value);
        selectedNeeds.saveForRetirement = { ...selectedNeeds.saveForRetirement, ...value };
        this.isRetirementNeedCoverSelect = false;
        if (!selectedNeeds.saveForRetirement.isSelected) {
          selectedNeeds.saveForRetirement.needCover = {};
          selectedNeeds.saveForRetirement.amountOf = {};
        }

        this.isCalculatorErrorForRetirement = selectedNeeds.saveForRetirement.needCover.label !== "Recommend me an income amount" ? false : null;

        break;
    }
    this.selectedNeeds = selectedNeeds;
    await this.nextButtonEnableDisable();
  };

  constructor() {
    makeObservable(this, {
      lumpsumCoverList: observable,
      familyProtectionLumpsumList: observable,
      healthProtectionLumpsumList: observable,
      incomeCoverList: observable,
      wouldNeedList: observable,
      selectedNeeds: observable,
      needList: observable,
      updatedNeedList: observable,
      isSelectedNeed: observable,
      isFamilyNeedCoverSelect: observable,
      isMonthlyIncomeAdded: observable,
      isHealthNeedCoverSelect: observable,
      isMoneyNeedCoverSelect: observable,
      isMoneyAmountSelect: observable,
      isFamilyAmountSelect: observable,
      isChildNeedCoverSelect: observable,
      isChildAmountSelect: observable,
      isRetirementNeedCoverSelect: observable,
      isRetirementAmountSelect:observable,

      isCalculatorErrorForFamilyProtection: observable,
      isCalculatorErrorForRetirement:observable,
      salutationList: observable,

      previouslySelectedNeedObject:observable,
      protectionCalculatorReqObj:observable,

      chooseNeedFormControllerFromStore:observable,
      isProtectionCalculatorUsed:observable,
      isRetirementCalculatorUsed:observable,

      monthlyIncomeOfSelf:observable,

      onUpdateValue: action,
      onNextBtnClick: action,
      setSalutationList: action
    });
  }


  setMonthlyIncomeOfSelf = (value) => {
    this.monthlyIncomeOfSelf = value
  }

  setIsProtectionCalculatorUsed = (value) => {
    this.isProtectionCalculatorUsed = value
  }

  setIsRetirementCalculatorUsed = (value) => {
    this.isRetirementCalculatorUsed = value
  }

  setChooseNeedFormControllerFromStore = (value) => {
    let formController = _.cloneDeep(value)
    this.chooseNeedFormControllerFromStore = formController
  } 


  setProtectionCalculatorReqObj = (value) => {
    let requestObj = _.cloneDeep(value)
    this.protectionCalculatorReqObj = requestObj 
  }

  updateAction(object, value) {
    if (this.needList.length > 0) {
      return "update";
    } else {
      return "save";
    }
    // if (object.isSelected == true && value.isSelected == false) {
    //   action = 'delete';
    // } else if (object.needCover !== value.needCover) {
    //   action = 'update';
    // } else {
    //   action = object.action;
    // }
  }

  async saveNeedsData(data) {
    try {
      global.loading(true);

      let list = data;
      if (!list) {
        let selectedNeeds = toJS(this.selectedNeeds);

        list.push(this.fetchRequestData(selectedNeeds.protectionForMyFamily));
        list.push(this.fetchRequestData(selectedNeeds.protectionForMyHealth));
        list.push(this.fetchRequestData(selectedNeeds.growMyMoney));
        list.push(this.fetchRequestData(selectedNeeds.saveForChildren));
        list.push(this.fetchRequestData(selectedNeeds.saveForRetirement));
        this.updatedNeedList = list;
      }

      var response = await axios.post(APITypes.SAVE_NEED, list);

      if (response.data.status == "error") {
        return false;
      } else {
        if (AuthStore.journeyId == "") AuthStore.journeyId = response.data[0].journey_id;
        return true;
      }
    } catch (error) {
      return false;
    } finally {
      global.loading(false);
    }
  }

  fetchRequestData(data) {
    let sum = "";
    if (Object.keys(data.amountOf).length !== 0) {
      if (data.title !== "Protection for my health") {
        if (data.title == "Protection for my family") {
          let mode = Object.keys(data.needCover).length !== 0 ? data.needCover.key : "";
          if (mode == "lumpsum") {
            sum = data.amountOf.label;
          } else {
            sum = data.amountOf.label.toLowerCase();
          }
        } else {
          sum = data.amountOf.label.toLowerCase();
        }
      } else {
        sum = data.amountOf.label;
      }
    }

    const requestData = {
      product_type: data.title,
      payout_mode: Object.keys(data.needCover).length !== 0 ? data.needCover.key : "",
      sum: sum,
      years: 0,
      is_selected: data.isSelected,
    };

    if (this.needList.length > 0) requestData.id = data.updatedId;

    if (AuthStore.journeyId !== "") requestData.journey_id = parseInt(AuthStore.journeyId);

    return requestData;
  }

  async fetchAllData() {
    try {
      global.loading(true);

      var hlAgentData = {
        requestData: {
          context: {
            sourceSystem: "VFJBTlNDRU5E",
            processName: "fetchBranch",
            iibparms: "",
            creationDtTM: "",
            targetSystem: "SEVSQUxEQ01T",
            uniqueRef: "f2cf3e18-d2a6-49ff-820f-031a8a1d5786",
          },
          agentHierarchyRequest: {
            agentCode: "CR0A03",
            rmCode: "",
          },
        },
      };

      // await axios.post(APITypes.HL_AGENT, hlAgentData);

      var hlAgentEmployeeData = {
        requestData: {
          context: {
            sourceSystem: "VFJBTlNDRU5E",
            processName: "fetchBranch",
            iibparms: "",
            creationDtTM: "",
            targetSystem: "SEVSQUxEQ01T",
            uniqueRef: "f2cf3e18-d2a6-49ff-820f-031a8a1d5786",
          },
          branchDetails: {
            employeeId: "6087",
          },
        },
      };

      // await axios.post(APITypes.HL_EMPLOYEE, hlAgentEmployeeData);

      // var response = await AuthStore.fetchAllData();

      // global.loading(false);

      // if (response.status) {
      //   response.data.need_list.map((item) => {
      //     this.needList.push(item);
      //   });
      // }

      // if (toJS(this.needList).length > 0) {
      //   toJS(this.needList).map((item) => {
      //     if (item.product_type.toLowerCase() == "protection for my family") {
      //       this.fetchSelectedWouldNeed(this.selectedNeeds.protectionForMyFamily, item);
      //     }
      //     if (item.product_type.toLowerCase() == "protection for my health") {
      //       this.fetchSelectedWouldNeed(this.selectedNeeds.protectionForMyHealth, item);
      //     }
      //     if (item.product_type.toLowerCase() == "grow my money") {
      //       this.fetchSelectedWouldNeed(this.selectedNeeds.growMyMoney, item);
      //     }
      //     if (item.product_type.toLowerCase() == "save for children") {
      //       this.fetchSelectedWouldNeed(this.selectedNeeds.saveForChildren, item);
      //     }
      //     if (item.product_type.toLowerCase() == "save for retirement") {
      //       this.fetchSelectedWouldNeed(this.selectedNeeds.saveForRetirement, item);
      //     }
      //   });
      // }
      // await this.nextButtonEnableDisable();
    } catch (e) {
      global.loading(false);
    }
  }

  async onNextBtnClick(props) {
    let selectedNeeds = toJS(this.selectedNeeds);
    let list = [];

    if (selectedNeeds.protectionForMyFamily.isSelected) {
      // localStorage.setItem('need', '40012')
      localStorage.setItem("need", "40038");
      localStorage.setItem("need_title", "Protection for my family");
      localStorage.setItem("need_id", 3);
      localStorage.setItem("mode", selectedNeeds.protectionForMyFamily.needCover.id);
      localStorage.setItem("targetAmount", selectedNeeds.protectionForMyFamily.amountOf.id);
      this.isFamilyNeedCoverSelect = Object.keys(selectedNeeds.protectionForMyFamily.needCover).length == 0 ? true : false;
      this.isFamilyAmountSelect = Object.keys(selectedNeeds.protectionForMyFamily.amountOf).length == 0 ? true : false;
      this.isMonthlyIncomeAdded = Object.keys(selectedNeeds.protectionForMyFamily.monthlyIncome).length == 0 ? true : false;


      this.isCalculatorErrorForFamilyProtection = selectedNeeds.protectionForMyFamily.needCover.label === "Recommend me an income amount" ||
        selectedNeeds.protectionForMyFamily.needCover.label === "Recommend me a lumpsum amount" ? true : false;

    }

    if (!selectedNeeds.protectionForMyFamily.isSelected){
      this.isFamilyNeedCoverSelect = false;
      this.isFamilyAmountSelect = false;
      this.isMonthlyIncomeAdded = false;
    }


    if (selectedNeeds.protectionForMyHealth.isSelected) {
      localStorage.setItem("need", "40038");
      localStorage.setItem("need_title", "Protection for my Health");
      localStorage.setItem("need_id", 4);
      localStorage.setItem("mode", 1);
      localStorage.setItem("targetAmount", selectedNeeds.protectionForMyHealth.amountOf.id);
      this.isHealthNeedCoverSelect = Object.keys(selectedNeeds.protectionForMyHealth.amountOf).length == 0 ? true : false;
    }

    if (!selectedNeeds.protectionForMyHealth.isSelected){
      this.isHealthNeedCoverSelect = false;
    }


    if (selectedNeeds.growMyMoney.isSelected) {
      localStorage.setItem("need", "40035");
      localStorage.setItem("need_title", "Grow my money");
      localStorage.setItem("need_id", 0);
      localStorage.setItem("mode", selectedNeeds.growMyMoney.needCover.id);
      localStorage.setItem("targetAmount", selectedNeeds.growMyMoney.amountOf.id);
      this.isMoneyNeedCoverSelect = Object.keys(selectedNeeds.growMyMoney.needCover).length == 0 ? true : false;
      this.isMoneyAmountSelect = Object.keys(selectedNeeds.growMyMoney.amountOf).length == 0 ? true : false;
    }

    if (!selectedNeeds.growMyMoney.isSelected){
      this.isMoneyNeedCoverSelect = false;
      this.isMoneyAmountSelect = false;
    }

    if (selectedNeeds.saveForChildren.isSelected) {
      localStorage.setItem("need", "40009");
      localStorage.setItem("need_title", "Save for children");
      localStorage.setItem("need_id", 1);
      localStorage.setItem("mode", selectedNeeds.saveForChildren.needCover.id);
      localStorage.setItem("targetAmount", selectedNeeds.saveForChildren.amountOf.id);
      this.isChildNeedCoverSelect = Object.keys(selectedNeeds.saveForChildren.needCover).length == 0 ? true : false;
      this.isChildAmountSelect = Object.keys(selectedNeeds.saveForChildren.amountOf).length == 0 ? true : false;
    }

    if (!selectedNeeds.saveForChildren.isSelected){
      this.isChildNeedCoverSelect = false;
      this.isChildAmountSelect = false;
    }

    if (selectedNeeds.saveForRetirement.isSelected) {
      localStorage.setItem("need_title", "Save for my Retirement");
      localStorage.setItem("need_id", 2);
      localStorage.setItem("mode", 1);
      localStorage.setItem("targetAmount", selectedNeeds.saveForRetirement.amountOf.id);

      this.isRetirementAmountSelect = Object.keys(selectedNeeds.saveForRetirement.amountOf).length == 0 ? true : false;
      this.isRetirementNeedCoverSelect = Object.keys(selectedNeeds.saveForRetirement.needCover).length == 0 ? true : false;
      this.isCalculatorErrorForRetirement = selectedNeeds.saveForRetirement.needCover.label === "Recommend me an income amount" ? true : false;

      console.log(">>>>",selectedNeeds.saveForRetirement.needCover.label);
    }

    if (!selectedNeeds.saveForRetirement.isSelected){
      this.isRetirementAmountSelect = false;
      this.isRetirementNeedCoverSelect = false;
      this.isCalculatorErrorForRetirement = false
    }

    list.push(this.fetchRequestData(selectedNeeds.protectionForMyFamily));
    list.push(this.fetchRequestData(selectedNeeds.protectionForMyHealth));
    list.push(this.fetchRequestData(selectedNeeds.growMyMoney));
    list.push(this.fetchRequestData(selectedNeeds.saveForChildren));
    list.push(this.fetchRequestData(selectedNeeds.saveForRetirement));

    this.updatedNeedList = list;
    this.isSelectedNeed = list.length !== 0 ? false : true;

    if (
      !this.isSelectedNeed &&
      !this.isFamilyNeedCoverSelect &&
      !this.isMonthlyIncomeAdded &&
      !this.isHealthNeedCoverSelect &&
      !this.isMoneyNeedCoverSelect &&
      !this.isMoneyAmountSelect &&
      !this.isChildNeedCoverSelect &&
      !this.isChildAmountSelect &&
      !this.isRetirementNeedCoverSelect &&
      !this.isFamilyAmountSelect &&
      !this.isCalculatorErrorForFamilyProtection &&
      !this.isCalculatorErrorForRetirement

    ) {
      let needList = []
      let monthly_income = 0;
      let journey_id = parseInt(AuthStore.journeyId)

      if (selectedNeeds.protectionForMyFamily.isSelected) {
        monthly_income = selectedNeeds.protectionForMyFamily.monthlyIncome
        needList.push(
          {
            "need_id": "4",
            "target_amount": selectedNeeds.protectionForMyFamily.amountOf.id,
            "is_selected": selectedNeeds.protectionForMyFamily.isSelected,
            "is_calculator_used": false,
            "payout_mode": selectedNeeds.protectionForMyFamily.needCover.key == 'lumpsum' ? 'lumpsum' : 'annual_income',
            "id": ""
          }
        )
      }

      if (selectedNeeds.protectionForMyHealth.isSelected) {
        needList.push(
          {
            "need_id": "5",
            "target_amount": selectedNeeds.protectionForMyHealth.amountOf.id,
            "is_selected": selectedNeeds.protectionForMyHealth.isSelected,
            "is_calculator_used": false,
            "payout_mode": selectedNeeds.protectionForMyHealth.needCover.key == 'lumpsum' ? 'lumpsum' : 'annual_income',
            "id": ""
          }
        )
      }

      if (selectedNeeds.growMyMoney.isSelected) {
        needList.push(
          {
            "need_id": "1",
            "target_amount": selectedNeeds.growMyMoney.amountOf.id,
            "is_selected": selectedNeeds.growMyMoney.isSelected,
            "is_calculator_used": false,
            "payout_mode": selectedNeeds.growMyMoney.needCover.key == 'lumpsum' ? 'lumpsum' : 'annual_income',
            "id": ""
          }
        )
      }

      if (selectedNeeds.saveForChildren.isSelected) {
        needList.push(
          {
            "need_id": "2",
            "target_amount": selectedNeeds.saveForChildren.amountOf.id,
            "is_selected": selectedNeeds.saveForChildren.isSelected,
            "is_calculator_used": false,
            "payout_mode": selectedNeeds.saveForChildren.needCover.key == 'lumpsum' ? 'lumpsum' : 'annual_income',
            "id": ""
          }
        )
      }

      if (selectedNeeds.saveForRetirement.isSelected) {
        needList.push(
          {
            "need_id": "3",
            "target_amount": selectedNeeds.saveForRetirement.amountOf.id,
            "is_selected": selectedNeeds.saveForRetirement.isSelected,
            "is_calculator_used": false,
            "payout_mode": selectedNeeds.saveForRetirement.needCover.key == 'lumpsum' ? 'lumpsum' : 'annual_income',
            "id": ""
          }
        )
      }

      needList = needList.map(item => {
        item.target_amount = parseInt(item.target_amount) + 1;
        item.id = 0;
        return item
      })

      let dataToBeSubmitted = {
        "monthly_income": monthly_income,
        "journey_id": journey_id,
        "need_list": needList
      }

      console.log("Data to be submitted is ", dataToBeSubmitted)

      var response = await axios.post(APITypes.SAVE_NEED, dataToBeSubmitted);

      if (response.data.status != "error") {
        props.history.push(`/family-details/${AuthStore.journeyId}`);
      }
    }
  }

  closeModal() {
    this.isSelectedNeed = false;
  }

  setDataInLocalStorage(key, value) {
    localStorage.setItem(key, value);
  }

  nextButtonEnableDisable() {
    let selectedNeeds = toJS(this.selectedNeeds);
    let list = [];

    if (selectedNeeds.protectionForMyFamily.isSelected) {
      list.push(selectedNeeds.protectionForMyFamily);
    }
    if (selectedNeeds.protectionForMyHealth.isSelected) {
      list.push(selectedNeeds.protectionForMyHealth);
    }
    if (selectedNeeds.growMyMoney.isSelected) {
      list.push(selectedNeeds.growMyMoney);
    }
    if (selectedNeeds.saveForChildren.isSelected) {
      list.push(selectedNeeds.saveForChildren);
    }
    if (selectedNeeds.saveForRetirement.isSelected) {
      list.push(selectedNeeds.saveForRetirement);
    }
    this.isSelectedNeed = list.length !== 0 ? false : true;
  }

  fetchSelectedWouldNeed(data, needData) {
    data.isSelected = needData.is_selected;
    if (data.isSelected) {
      data.needCover =
        needData.payout_mode !== "" && toJS(this.wouldNeedList).filter((item) => item.key == needData.payout_mode)[0]
          ? toJS(this.wouldNeedList).filter((item) => item.key == needData.payout_mode)[0]
          : {};
    }
    data.updatedId = needData.id;
    if (data.isSelected && (data.title == "Grow my money" || data.title == "Save for children" || data.title == "Save for retirement")) {
      if (needData.sum !== "") {
        if (needData.payout_mode == "lumpsum") {
          data.amountOf = toJS(this.lumpsumCoverList).filter((item) => item.label.toLowerCase() == needData.sum)[0]
            ? toJS(this.lumpsumCoverList).filter((item) => item.label.toLowerCase() == needData.sum)[0]
            : {};
        } else {
          data.amountOf = toJS(this.incomeCoverList).filter((item) => item.label.toLowerCase() == needData.sum)[0]
            ? toJS(this.incomeCoverList).filter((item) => item.label.toLowerCase() == needData.sum)[0]
            : {};
        }
      } else {
        data.amountOf = {};
      }
    } else if (data.title == "Protection for my family" && data.isSelected) {
      if (needData.sum !== "") {
        if (needData.payout_mode == "lumpsum") {
          data.amountOf = toJS(this.familyProtectionLumpsumList).filter((item) => item.label == needData.sum)[0]
            ? toJS(this.familyProtectionLumpsumList).filter((item) => item.label == needData.sum)[0]
            : {};
        } else {
          data.amountOf = toJS(this.incomeCoverList).filter((item) => item.label.toLowerCase() == needData.sum)[0]
            ? toJS(this.incomeCoverList).filter((item) => item.label.toLowerCase() == needData.sum)[0]
            : {};
        }
      } else {
        data.amountOf = {};
      }
    } else if (data.title == "Protection for my health" && data.isSelected) {
      data.amountOf =
        needData.sum !== "" && toJS(this.healthProtectionLumpsumList).filter((item) => item.label == needData.sum)[0]
          ? toJS(this.healthProtectionLumpsumList).filter((item) => item.label == needData.sum)[0]
          : {};
    }
  }

  setSalutationList = async (salutationData) => {
    this.salutationList = salutationData
  }

}

export default new ChooseNeedsStore();
