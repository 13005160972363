
const chooseNeedInitialDropdownValues = {

 'saveForChildrenIncome':'1 Lac to 2 Lacs',
 'saveForChildrenLumpsum':'15 Lacs to 30 Lacs',
 'saveForRetirementIncome':'1 Lac to 3 Lacs',
 'growMyMoneyIncome':'1 Lac to 2 Lacs',
 'growMyMoneyLumpsum':'15 Lacs to 30 Lacs',
 'protectionForFamilyLumpsum':'1 Cr to 2 Cr',
 'protectionForHealthLumpsum':'10 Lacs to 20 Lacs'
}

export default chooseNeedInitialDropdownValues