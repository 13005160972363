// package imports
import dvr from "mobx-react-form/lib/validators/DVR"
import vjf from "mobx-react-form/lib/validators/VJF"
import { Form } from "mobx-react-form"
import validatorjs from "validatorjs"

import moment from "moment"

// project imports
import FamilyDetailsStore from "../../../../family-details-form/store/FamilyDetailsStore"
import setInitialDefaults from "./initialDefaults"
import formObserver from "./observers"
import Utils from "./utils"
import * as keys from "./keys"

class SaralPensionController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
      vjf: vjf(),
    }
  }

  setup() {
    return {
      fields: [
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "Select",
          extra: {
            options: [
              {id: "5",label: "Single"},
            ],
          },
        },
        {
          name: "source-of-purchase-price",
          label: "Source Of Purchase Price",
          placeholder: "Select",
          extra: {
            options: [
              { label: "Open Market", id: "1" },
              { label: "Proceeds of pension policy of ETLI Co Ltd", id: "2" },
              { label: "Proceeds of pension policy of other Insurer", id: "3" },
            ],
          },
        },
        {
          name: "annuity-option",
          label: "Annuity Option",
          placeholder: "Select",
          extra: {
            options: [
              {
                label:
                  "Life Annuity with Return of 100% of Purchase Price (ROP)",
                id: "1",
              },
              {
                label:
                  "Joint Life Last Survivor Annuity with Return of 100% of Purchase Price (ROP) on death of the last survivor",
                id: "2",
              },
            ],
          },
        },
        {
          name: "annuity-mode",
          label: " Annuity Mode",
          placeholder: "Select",
          extra: {
            options: [
              { label: "Yearly", id: "8" },
              { label: "Half-yearly", id: "9" },
              { label: "Quarterly", id: "10" },
              { label: "Monthly", id: "11" },
            ],
          },
        },

        {
          name: "secondary-annuitant-name",
          label: "Secondary Annuitant Name",
          value: "",
          disabled:false,
          extra: {
            onSecondaryAnnuitantNameChange(form, data) {
              let name = form.$('secondary-annuitant-name').value
              Utils.updateBi(keys.NAME_OF_THE_SECONDARY_ANNUITANT, name, data.data.product_id, form, data)
            }
          },
        },  
        {
          name: "secondary-annuitant-date-of-birth",
          label: "Secondary Annuitant Date of birth",
          value: "",
          disabled:false,
          extra: {
            changeDob(form, value, data) {
              form.$("secondary-annuitant-date-of-birth").set("value", value)
              let productId = data.data.product_id;
              const dateString = value
              Utils.updateBi(keys.DOB_NAME_OF_THE_SECONDARY_ANNUITANT, dateString, productId, form, data)
            }
          }
        },
        {
          name: "secondary-annuitant-gender",
          label: "Secondary Annuitant Gender",
          placeholder: "Select",
          disabled:false,
          extra: {
            options: [
              { label: "Male", id: "5" },
              { label: "Female", id: "6" },
              { label: "Transgender", id: "7" },
            ],
          },
        },
        {
         name: 'purchase-price-amount',
         label: 'Purchase Price Rs',
         value:"",
         extra: {
          onPurchasePriceAmtChange(e,form, data) {
            let amt = e.target.value
            let regex = /^(([1-9]\d*))?$/;
              if (regex.test(amt)) {
                form.$('purchase-price-amount').set('value',amt)
              }
          },
          onPurchasePriceAmtBlur(form, data) {
              let name = form.$('purchase-price-amount').value
              Utils.updateBi(keys.PURCHASE_PRICE, name, data.data.product_id, form, data)
            }
        }
        },
        // for death cover
        {
          name: "income",
          label: "Income",
          placeholder: "Income",
          value: "",
          extra: {
            calculateIncome(form, data) {
              let income = form.$("income").value
              Utils.updateBi(
                keys.INCOME,
                income,
                data.data.product_id,
                form,
                data
              )
            },
          },
          disabled: true,
        },
        {
          name: "premium-amt",
          label: "Premium",
          placeholder: "Premium",
          disabled: false,
          extra: {
            calculatePremium(form, value, data) {
              // Utils.updateBi(
              //   keys.PREMIUM,
              //   value,
              //   data.data.product_id,
              //   form,
              //   data
              // )
            },
          },
        },
      ],
    }
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data)
    // Observer fields
    formObserver(this, data)
  }
}

export default SaralPensionController
