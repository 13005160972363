import React, { Component } from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import { CustomCheckbox, BottomsheetDropdown } from '../../../components';
import FamilyProtectionStore from '../../../modules/family-protection/store/FamilyProtectionStore';

import { InfoArrowBlackImg } from 'constants/Images';

import './additional-cards.css';


class AdditionalCards extends Component {
  constructor(props) {
    super(props)

    this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
  }

  onCheckBoxChange(checkedValue, selectedObj) {
    this.props.onClick(checkedValue, selectedObj, this.props.data);
  }

  populateCardValues = () => {
    let values = []
    for (let i = 1; i < 100; i++) {
      values.push({
        id: i,
        label: i + ' L'
      })
    }

    values.push({
      id: 100,
      label: '1 CR'
    })

    return values;
  }

  onDropDownValueChanges = (value, index, data) => {
    FamilyProtectionStore.aipRiders[index].sum_assured = data.initial_amount * value.id
    FamilyProtectionStore.aipRiders[index].selected_value = value
    FamilyProtectionStore.fetchAIP()
  }

  render() {
    const { cardsList, cardTopText, checkedValue } = this.props;
    return (
      <div className='additional-cards'>
        <p className='mb-3 font-size-16 font-size-md-22 font-size-lg-24 font-weight-bold text-primary-title-label-color card-top-text'>{cardTopText}</p>
        <Card className='border-0 shadow'>
          <Card.Body>
            {(cardsList && cardsList.length > 0) && cardsList.map((value, index) => (
              <Row key={index} className={index > 0 ? 'mt-3' : ''}>
                <Col className='col-8'>
                  <div className='d-flex align-items-center'>
                    <CustomCheckbox shape='rounded' title={value.title} titleStyle='text-primary-title-label-color font-weight-normal card-top-title font-size-16 font-size-md-20 pl-2' isChecked={value.checkedValue} onChange={(checked) => this.onCheckBoxChange(checked, value)} mark='checkmark' />
                    <img className='mb-2 pl-2' src={InfoArrowBlackImg} alt='info' />
                  </div>
                  <p className='mb-0 text-description-text-color font-size-14 font-size-md-16 font-weight-300 sub-left-padding'>
                    {value.description}
                  </p>
                  {value.isDropdown &&
                    <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey='label'
                      bottomsheetTitle=''
                      menuList={this.populateCardValues()}
                      value={value.selected_value ? value.selected_value : {}}
                      onSelect={(e) => this.onDropDownValueChanges(e, index, value)}
                    />
                  }
                </Col>
                <Col className='align-items-center col-4 col-border-left d-flex flex-column justify-content-center col-line-height'>
                  {
                    value.sum_assured != '' ? <React.Fragment>
                      <div className='d-flex justify-content-end'>
                        <p className='mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary'><span>&#8377;</span> {value.sum_assured}</p>
                      </div>
                    </React.Fragment> :
                      <p className='mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color text-center'>
                        Not <br /> Selected
                      </p>
                  }
                </Col>
              </Row>
            ))}
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default AdditionalCards
