const FamilyMemberTypeEnum = {
    SELF: "SELF",
    SPOUSE: "SPOUSE",
    CHILD: "CHILD",
    GRAND_FATHER: "GRAND_FATHER",
    GRAND_MOTHER: "GRAND_MOTHER",
    ADULT_CHILD: "ADULT_CHILD",
    GRAND_CHILD: "GRAND_CHILD",
}

export default FamilyMemberTypeEnum;

