import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Row, Col } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { CustomCheckbox, BottomsheetDropdown, DeathCoverPriceComponent, AdditionalRiderBenifitsCard, Button as UiButton } from '../../../../../components'

import AmountCalculation from '../../family-protection-card/AmountCalculation';
import ChangeProductModal from '../../change-product-modal/ChangeProductModal';
import ChangeProductReasonModal from '../../change-product-reason-modal/ChangeProductReasonModal';
// import AdditionalRiderBenifitsCard from './AdditionalRiderBenifitsCard';
import AdditionalCards from './AdditionalCards';

import FamilyProtectionStore from '../../../store/FamilyProtectionStore'
import FamilyDetailsStore from '../../../../family-details-form/store/FamilyDetailsStore'

import moment from 'moment'

import DeathCoverGraphSlider from '../../family-protection-card/DeathCoverGraphSlider';
import CheckEligibilityModal from './check-eligibility-modal'
import SpouseDetailsModal from './SpouseDetailsModal'


import '../../family-protection-card/family-collapse-view.css'
import AuthStore from '../../../../login/store/AuthStore';

const ZindagiPlus = observer(class ZindagiPlus extends Component {
    constructor(props) {
        super(props);
        // let amountList = this.getCoverAmountList()
        this.state = {
            productID: '40038',
            isTobacoUser: false,
            selectedAdditionalCardValue: {},
            selectedAdditionalRiderCardValue: {},
            isCollapseOpen: false,
            disabled: false,
            showOverlay: false,
            premiumTerm: {},
            policyTerm: {},
            protectionForMyFamily: [],
            selectdReason: {},
            changeProductReasonModal: false,
            productReasonList: [
                { reason: 'Recommended product is not fitting the need.', value: 'noNeed' },
                { reason: 'There is a better product that can be offered.', value: 'anotherBetterProduct' },
                { reason: 'System is recommending a wrong product.', value: 'recommendWrongProduct' },
                { reason: 'Other reason.', value: 'otherReason' }
            ],
            isReason: false,
            changeProductModal: false,
            productList: [
                { title: 'Product 1' },
                { title: 'Product 2' },
                { title: 'Product 3' },
                { title: 'Product 4' },
                { title: 'Product 5' },
                { title: 'Product 6' },
                { title: 'Product 7' },
                { title: 'Product 8' },
                { title: 'Product 9' }
            ],
            pt: {
                id: 10,
                label: '10'
            },
            ppt: {
                id: 10,
                label: '10'
            },
            ptList: [],
            pptList: [],
            paymentFrequencyList: [
                { id: 4, label: 'Monthly' },
                { id: 3, label: 'Quarterly' },
                { id: 2, label: 'Half-Yearly' },
                { id: 1, label: 'Annually' }
            ],
            payoutOptionList: [
                { id: 16, label: 'Lumpsum' },
                { id: 17, label: 'Monthly income - Level' },
                { id: 18, label: 'Monthly income – Increasing' },
                { id: 19, label: 'Lumpsum Plus Monthly Income – Level' },
                { id: 20, label: 'Lumpsum Plus Monthly Income - increasing' }
            ],
            payoutPercentageList: this.payoutPercentageListGenerator(),
            payoutPeriodList: [
                { id: 21, label: '36' },
                { id: 22, label: '60' },
                { id: 23, label: '120' }
            ],
            planOptionList: [
                { id: 1, label: 'Life Cover With Level Sum Assured' },
                { id: 2, label: 'Life Cover With Decreasing Sum Assured' }
            ],
            planOption: { id: 1, label: 'Life Cover With Level Sum Assured' },
            paymentFrequency: { id: 4, label: 'Annually' },
            payoutOption: { id: 16, label: 'Lumpsum' },
            payoutPeriod: { id: 21, label: '36' },
            payoutPercentage: { id: 1, label: '1 %' },
            showEligibilityModal: false,
            eligibilityModalError: '',
            coverAmountList: this.getCoverAmountList(),
            coverAmountListForCLRnHCB: this.getCoverAmountListForCLRnHCB(),
            // isMarried: FamilyDetailsStore.isMarried,
            maritalStatusList: [
                { id: 1, label: 'Married' },
                { id: 2, label: 'Unmarried' }
            ],
            marrideStatusOption: { id: 2, label: 'Unmarried' },
            showSpouseDetailsModal: false,
            // reqParams: this.requestParams(),
            reqParams: {},
            addBenifits: this.additionalBenefit(),
            addRiders: this.additionalRiders(),
            isLoading: false,
            data: {},
            premiumAmount: '',
            pendingUpdateValues: null
        }
    }
    additionalRiders = () => {
        return [
            {
                'id': 1,
                'action': 'update',
                'brochure_link': 'https://www.edelweisstokio.in/riders/download/81-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
                'title': 'Accidental Death Benefit',
                'description': 'Provide enhanced financial security to your loved ones in case of death due to an accident.',
                'sum_assured': '',
                'initial_amount': '59',
                'is_selected': false,
                'one_time_fee': '22',
                'selected_value': { id: 1, label: '1 L' },
                'paramName': 'ADBRider',
                'showDropdown': true,
                'rider_name': 'ADB Rider'
            },
            {
                'id': 2,
                'action': 'update',
                'brochure_link': 'https://www.edelweisstokio.in/riders/download/82-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
                'title': 'Permanent Disability',
                'description': 'You will get a lump sum amount to manage your expenses in case an accidental disability has hindered your earning capacity.',
                'sum_assured': '',
                'initial_amount': '47',
                'is_selected': false,
                'one_time_fee': '22',
                'selected_value': { id: 1, label: '1 L' },
                'paramName': 'ATPDRider',
                'showDropdown': true,
                'rider_name': 'ATPD Rider'
            },
            {
                'id': 3,
                'action': 'update',
                'brochure_link': 'https://www.edelweisstokio.in/riders/download/22-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
                'title': 'Critical Illness Rider',
                'description': 'Upon diagnosis with one of the 12 specified Critical Illnesses, you will receive a pay out of the critical illness amount you’ve selected.',
                'sum_assured': '',
                'initial_amount': '677',
                'is_selected': false,
                'one_time_fee': '22',
                'selected_value': { id: 1, label: '1 L' },
                'paramName': 'CriticalIllnessRider',
                'showDropdown': true,
                'rider_name': 'Critical Illness Rider'
            },
            {
                'id': 4,
                'action': 'update',
                'brochure_link': 'https://www.edelweisstokio.in/riders/download/23-3?_ga=2.175776610.1125348727.1616483264-207121548.1616483264',
                'title': 'Hospital Cash Benefit',
                'description': 'It will help with daily expenses and take care of the costs involved in hospitalisation.',
                'sum_assured': '',
                'initial_amount': '2084',
                'is_selected': false,
                'one_time_fee': '22',
                'selected_value': { id: 1, label: '1 L' },
                'paramName': 'HospitalityCashBenefitRider',
                'showDropdown': true,
                'rider_name': 'Hospital Cash Benefit Rider'
            }
        ]
    }
    additionalBenefit = () => {
        return [
            {
                'id': 1,
                'action': 'update',
                'title': 'Better Half Benefit',
                'description': 'Option to get an additional life cover for your spouse.',
                'sum_assured': '-',
                'is_selected': false,
                'one_time_fee': '0',
                'isDisabled': false
            },
            {
                'id': 2,
                'action': 'update',
                'title': 'Waiver of Premium',
                'description': 'Premiums will be waived off if diagnosed with any one of the 35 listed critical illnesses.',
                'sum_assured': '',
                'is_selected': false,
                'one_time_fee': '0',
                'isDisabled': false
            },
            {
                'id': 3,
                'action': 'update',
                'title': 'Top Up Benefit',
                'description': 'Top-up Sum Assured is equal to Top-up Rate multiplied by Base Sum Assured.',
                'sum_assured': '',
                'is_selected': false,
                'one_time_fee': '0',
                'isDisabled': false,
                'selected_value': { id: 14, label: '5%' },
                'list_data': [
                    { id: 14, label: '5%' },
                    { id: 15, label: '10%' }
                ]
            },
            {
                'id': 4,
                'action': 'update',
                'title': 'Telemedical Option',
                'description': 'Get hassel-free life protection without the need of going through the full medical examination',
                'sum_assured': '',
                'is_selected': false,
                'one_time_fee': '1345',
                'isDisabled': false
            }
        ]
    }
    requestParams = () => {
        return {
            'LI_NAME': 'Rajesh Bhagat',
            'LI_ENTRY_AGE': '21',
            'LI_DOB': '01 May 2000',
            'LI_GENDER': 'M',
            'LI_STATE': '0',
            'PROPOSER_NAME': 'Rajesh Bhagat',
            'PROPOSER_AGE': '21',
            'PROPOSER_DOB': '01 May 2000',
            'PROPOSER_GENDER': 'M',
            'SameProposer': 'True',
            'COMPANY_STATE': '0',
            'GSTIN': '',
            'GSTIN_Number': '0',
            'INPUT_MODE': '1',
            'PR_ID': '40038',
            'PR_PT': '48',
            'PR_PPT': '48',
            'AGENT_ID': '',
            'LI_MOBILENO': '',
            'LI_EMAILID': '',
            'AgentName': '',
            'AgentLocation': '',
            'PR_ANNPREM': '',
            'PR_MI': '0',
            'PR_SA': '100000000',
            'PR_SAMF': '0',
            'PR_ModalPrem': '',
            'REQUESTSOURCE': '4',
            'category': '4',
            'Underwriting': '1',
            'LI_SMOKE': '0',
            'PR_CHANNEL': '5',
            'PlanOption': '1',
            'AdditionalBenefit': '5',
            'MaritalStatus': '10',
            'BetterHalfBenefit': '13',
            'SpouseName': '',
            'SpouseDOB': '',
            'SpouseGender': '',
            'DeathBenefitPayoutOption': '16',
            'SpouseTobaccoConsumer': '137',
            'ZP_PREFERREDLIST': '1',
            'ZP_OCCUPATION': 'SA',
            'ZP_EDUCATION': '5',
            'ZP_ANNINCOME': '100000000',
            'ZP_NATIONALITY': '1',
            'ADBRider': '',
            'ATPDRider': '',
            'CriticalIllnessRider': '',
            'HospitalityCashBenefitRider': '',
            'CashFlow': 'N',
            'PayoutPeriodMonths': '22',
            'Lumpsum': '28',
            'TopUpBenefit': '14'
        }
    }
    payoutPercentageListGenerator = () => {
        let list = []
        let value = 27
        for (let index = 1; index < 99; index++) {
            list.push({
                id: value,
                label: index + ' %'
            })
            value = value + 1
        }
        return list
    }
    componentDidMount() {
        let reqParams = this.state.reqParams
        let data = this.getParseBIData()

        reqParams = { ...data.bi_request }

        this.state.reqParams = reqParams

        this.setState({
            selectdReason: this.state.productReasonList[0],
            isLoading: true
        })
        this.generatePT()

        // console.log('COMPONNENT DID MOUNT >> ', data)
        if (Object.keys(data.BI).length == 0) {
            this.fetchData()
        } else {
            let additionalBenifits = this.state.addBenifits
            additionalBenifits.filter((item) => item.title == 'Waiver of Premium').map((premiumData) => {
                if (data.BI.WOP_MODAL_TAX_DISPLAY) {
                    premiumData.is_selected = true
                    premiumData.sum_assured = (data.BI.WOP_MODAL_TAX_DISPLAY || '').toString()
                }
            })
            additionalBenifits.filter((item) => item.title == 'Better Half Benefit').map((premiumData) => {
                if (data.BI.BH_MODAL_PREM) {
                    premiumData.is_selected = true
                    premiumData.sum_assured = (data.BI.BH_MODAL_PREM || '').toString()
                }
            })
            this.setState({ addBenifits: additionalBenifits, isLoading: false })
        }
        this.prefieldData()
    }

    prefieldData = () => {
        try {
            const { data } = this.props;
            const { bi_request } = data
            console.log('ZINDAGI PLUS PREFIELD DATA >> ', bi_request)
            if (bi_request) {
                let {
                    pt,
                    ppt,
                    ptList,
                    pptList,
                    paymentFrequency,
                    paymentFrequencyList,
                    payoutOption,
                    payoutOptionList,
                    payoutPeriod,
                    payoutPeriodList,
                    payoutPercentage,
                    payoutPercentageList,
                    planOption,
                    planOptionList
                } = this.state;
                let isTobaccoUser = bi_request.LI_SMOKE == '1'

                pt = ptList.filter(obj => obj.id == bi_request.PR_PT)[0] || pt
                ppt = pptList.filter(obj => obj.id == bi_request.PR_PT)[0] || ppt

                paymentFrequency = paymentFrequencyList.filter(obj => obj.id == parseInt(bi_request.INPUT_MODE))[0] || paymentFrequency

                payoutOption = payoutOptionList.filter(obj => obj.id == parseInt(bi_request.DeathBenefitPayoutOption))[0] || payoutOption

                payoutPeriod = payoutPeriodList.filter(obj => obj.id == parseInt(bi_request.PayoutPeriodMonths))[0] || payoutPeriod

                planOption = planOptionList.filter(obj => obj.id == parseInt(bi_request.PlanOption))[0] || planOption

                payoutPercentage = payoutPercentageList.filter(obj => obj.id == parseInt(bi_request.Lumpsum))[0] || payoutPercentage

                this.state.addBenifits.map(b => {
                    switch (b.title) {
                        case 'Better Half Benefit':
                            b.is_selected = bi_request.BetterHalfBenefit == '12'
                            break

                        case 'Top Up Benefit':
                            b.is_selected = bi_request.AdditionalBenefit == '6'
                            b.selected_value = b.list_data.filter((item) => bi_request.TopUpBenefit == item.id.toString())[0]
                            if (b.is_selected) {
                                this.state.addBenifits.map((item) => { if (item.title == 'Waiver of Premium') { item.isDisabled = true } })
                            }
                            break

                        case 'Waiver of Premium':
                            b.is_selected = bi_request.AdditionalBenefit == '8'
                            if (b.is_selected) {
                                this.state.addBenifits.map((item) => { if (item.title == 'Top Up Benefit') { item.isDisabled = true } })
                            }
                            break

                        case 'Telemedical Option':
                            b.is_selected = bi_request.Underwriting == '2'
                            break
                    }
                })

                this.state.addRiders.map(b => {
                    switch (b.paramName) {
                        case 'ADBRider':
                            b.is_selected = bi_request.ADBRider != ''
                            if (b.is_selected) {
                                b.selected_value = this.state.coverAmountList.filter(obj => obj.id == bi_request.ADBRider / 100000).length > 0 || b.selected_value
                            }
                            break

                        case 'ATPDRider':
                            b.is_selected = bi_request.ATPDRider != ''
                            if (b.is_selected) {
                                b.selected_value = this.state.coverAmountList.filter(obj => obj.id == bi_request.ATPDRider / 100000).length > 0 || b.selected_value
                            }
                            break

                        case 'CriticalIllnessRider':
                            b.is_selected = bi_request.CriticalIllnessRider != ''
                            if (b.is_selected) {
                                b.selected_value = this.state.coverAmountListForCLRnHCB.filter(obj => obj.id == bi_request.CriticalIllnessRider / 100000).length > 0 || b.selected_value
                            }
                            break

                        case 'HospitalityCashBenefitRider':
                            b.is_selected = bi_request.HospitalityCashBenefitRider != ''
                            if (b.is_selected) {
                                b.selected_value = this.state.coverAmountListForCLRnHCB.filter(obj => obj.id == bi_request.HospitalityCashBenefitRider / 100000).length > 0 || b.selected_value
                            }
                            break
                    }
                })

                console.log('ZINDAGI PLUS PREFIELD DATA >> ', pt, ppt, paymentFrequency, payoutOption, payoutPeriod, payoutPercentage)

                this.setState({ isTobaccoUser, pt, ppt, paymentFrequency, payoutOption, payoutPeriod, payoutPercentage, planOption })
            }
        } catch (error) {
            console.error('PREFIELD DATA ERROR >> ', error)
        }
    }

    setDefaultRequestParams = () => {
        const { data } = this.props;
        let familyMembers = toJS(FamilyDetailsStore.existingFamilyMember)
        console.log('SET DEFAULT PARAMS >> ', data, familyMembers)

        this.state.reqParams.LI_NAME = data.family_member_name
        this.state.reqParams.LI_ENTRY_AGE = `${data.age}`
        this.state.reqParams.LI_DOB = moment(data.dob).format('DD MMM YYYY')
        this.state.reqParams.LI_GENDER = data.gender == 'male' ? 'M' : 'F'

        switch (data.family_member_type) {
            case 'Self':
                this.state.reqParams.PROPOSER_NAME = data.family_member_name
                this.state.reqParams.PROPOSER_AGE = `${data.age}`
                this.state.reqParams.PROPOSER_DOB = moment(data.dob).format('DD MMM YYYY')
                this.state.reqParams.PROPOSER_GENDER = data.gender == 'male' ? 'M' : 'F'
                this.state.reqParams.SameProposer = 'True'
                let spouse = familyMembers.filter(member => member.family_member_type == 'Spouse')
                if (spouse.length > 0) {
                    spouse = spouse[0]
                    this.state.reqParams.SpouseName = spouse.family_member_name
                    this.state.reqParams.SpouseDOB = '' + moment().diff(spouse.dob, 'years')
                    this.state.reqParams.SpouseGender = spouse.gender == 'male' ? '134' : '135'
                    this.state.reqParams.MaritalStatus = '10'
                }
                break
            case 'Spouse':
                var self = familyMembers.filter(member => member.family_member_type == 'Self')
                if (self.length > 0) {
                    self = self[0]
                    this.state.reqParams.PROPOSER_NAME = self.family_member_name
                    this.state.reqParams.PROPOSER_AGE = `${self.age}`
                    this.state.reqParams.PROPOSER_DOB = moment(data.dob).format('DD MMM YYYY')
                    this.state.reqParams.PROPOSER_GENDER = self.gender == 'male' ? 'M' : 'F'
                    this.state.reqParams.SameProposer = 'False'

                    this.state.reqParams.SpouseName = self.family_member_name
                    this.state.reqParams.SpouseDOB = '' + moment().diff(self.dob, 'years')
                    this.state.reqParams.SpouseGender = self.gender == 'male' ? '134' : '135'
                }
                break
            case 'Child':
                var self = familyMembers.filter(member => member.family_member_type == 'Self')
                if (self.length > 0) {
                    self = self[0]
                    this.state.reqParams.PROPOSER_NAME = self.family_member_name
                    this.state.reqParams.PROPOSER_AGE = `${self.age}`
                    this.state.reqParams.PROPOSER_DOB = moment(data.dob).format('DD MMM YYYY')
                    this.state.reqParams.PROPOSER_GENDER = self.gender == 'male' ? 'M' : 'F'
                    this.state.reqParams.SameProposer = 'False'
                }
                this.state.reqParams.SpouseName = ''
                this.state.reqParams.SpouseDOB = ''
                this.state.reqParams.SpouseGender = ''

        }
    }

    onChangeReason = (e) => {
        // console.log('call selecedt reason>>', e);
        this.setState({ selectdReason: e });
    }
    onChangeProductReasonModalOnShow = () => {
        this.setState({ changeProductReasonModal: true })
    }

    onChangeProductReasonModalOnHide = () => {
        this.setState({ changeProductReasonModal: false })
    }
    onChangeProductModalOnShow = (value) => {
        if (!value) {
            this.setState({ changeProductModal: true, changeProductReasonModal: false })
        } else {
            this.setState({ changeProductReasonModal: true })
        }
    }
    onChangeProductModalOnHide = () => {
        this.setState({ changeProductModal: false })
    }

    hideOverLay = () => {
        setTimeout(() => {
            this.setState({ showOverlay: true })
        }, 2000)
    }

    // single option
    onAdditionalRiderCardOptionSelect = (checkedValue, selectedObj, data) => {

        console.log('Checked value is ', checkedValue)
        console.log('Selected object is ', selectedObj)
        console.log('Data is ', data)

        // const selectedOptionObj = this.state.selectedAdditionalRiderCardValue;

        // selectedOptionObj[selectedObj.id] = checkedValue;

        // this.setState({
        //     selectedAdditionalRiderCardValue: selectedOptionObj
        // });

        // const dataList = toJS(FamilyProtectionStore.dummy_product_list_data);
        // let dataObject = {};
        // dataList.map((additionalDetails) => {
        //     if (additionalDetails.id == data.id) {
        //         additionalDetails.rider_list.map((item) => {
        //             if (item.id == selectedObj.id) {
        //                 item.is_selected = checkedValue;
        //             }
        //             dataObject = additionalDetails;
        //         })
        //     }
        // })
        // FamilyProtectionStore.updateDetails(dataObject);

    }

    // multiselect option
    onAdditionalCardOptionSelect = async (checkedValue, selectedObj) => {

        // if (selectedObj.title != 'Telemedical Option') {
        //     this.state.addBenifits[selectedObj.id - 1].is_selected = checkedValue
        // }

        switch (selectedObj.title) {
            case 'Telemedical Option':
                if (checkedValue) {
                    this.setState({ showEligibilityModal: true })
                } else {
                    await this.dataChange('Underwriting', '1')

                    if (!this.state.showEligibilityModal) {
                        this.state.addBenifits[selectedObj.id - 1].is_selected = false
                    }
                }
                break;
            case 'Better Half Benefit':
                if (FamilyDetailsStore.isMarried || this.state.marrideStatusOption.id == 1) {
                    if (checkedValue) {
                        this.dataChange('betterHalfBenefit', '12')
                    } else {
                        this.dataChange('betterHalfBenefit', '13')
                    }
                    this.state.pendingUpdateValues = { key: 'betterHalfBenefit', value: { selectedObj, checkedValue } }
                    // this.state.addBenifits[selectedObj.id - 1].is_selected = checkedValue
                    // this.setState({ addBenifits: [...this.state.addBenifits] })
                }
                break;
            case 'Waiver of Premium':
                let isSumAssured = this.state.addBenifits.some((item) => (item.title == 'Decreasing Sum Assured' && item.is_selected))
                if (!isSumAssured) {
                    if (checkedValue) {
                        this.state.addBenifits.map((item) => { if (item.title == 'Top Up Benefit') { item.isDisabled = true } })
                        this.dataChange('AdditionalBenefit', '8')
                    } else {
                        this.state.addBenifits.map((item) => { if (item.title == 'Top Up Benefit') { item.isDisabled = false } })
                        this.dataChange('AdditionalBenefit', '5')
                    }
                    this.state.pendingUpdateValues = { key: 'AdditionalBenefit', value: { selectedObj, checkedValue } }
                    // this.state.addBenifits[selectedObj.id - 1].is_selected = checkedValue
                }
                break;
            case 'Top Up Benefit':
                if (!checkedValue) {
                    this.state.addBenifits.map((item) => { if (item.title == 'Waiver of Premium') { item.isDisabled = false } })
                    this.dataChange('AdditionalBenefit', '5')
                    this.dataChange('TopUpBenefit', selectedObj.list_data[0].id)
                } else {
                    this.state.addBenifits.map((item) => { if (item.title == 'Waiver of Premium') { item.isDisabled = true } })
                    this.dataChange('AdditionalBenefit', '6')
                    this.dataChange('TopUpBenefit', selectedObj.selected_value.id)
                }
                // this.state.addBenifits[selectedObj.id - 1].is_selected = checkedValue
                this.state.pendingUpdateValues = { key: 'TopUpBenefit', value: { selectedObj, checkedValue } }
                break;
        }
    }
    componentDidUpdate(prevProps) {
        if (!prevProps.disabled && this.props.disabled) {
            this.setState({ isCollapseOpen: false })
        }
    }

    tobaccoUserStatus = (value) => {
        let isTobacoUser = !this.state.isTobacoUser
        this.dataChange('isSmoking', isTobacoUser)
        this.setState({ isTobacoUser })
    }

    onPremiumChange = (e) => {
        // const data = this.props.data
        // let amount = e.target.value
        // data.premium_amount = amount;
        // FamilyProtectionStore.updateProductsDetails(this.props.cardKey, data);
        // let reqParams = this.state.reqParams
        // reqParams.PR_ANNPREM = e.target.value

        this.setState({ premiumAmount: e.target.value })
    }
    onRecalculateButtonClick = () => {
        // FamilyProtectionStore.getBIResponse()
        const data = this.props.data
        // console.log('RECALCULATE BTN CLICK >> ', data)
        this.dataChange('premium', this.state.premiumAmount)
    }

    generatePT = () => {
        let { data } = this.props
        let age = moment().diff(moment(data.dob, 'DD-MM-YYYY'), 'years');
        // console.log('AGE POLICY TERM >> ', age)
        let pt = []
        let maxAge = 80 - age
        for (let i = maxAge; i >= 10; i--) {
            pt.push({
                id: i,
                label: '' + i
            })
        }

        // pt.push({
        //   id: '80-@Li_entry_age',
        //   label: 80
        // })
        this.setState({
            pt: pt[0],
            ptList: pt
        })
        this.state.pt = pt[0]
        this.state.ptList = pt
        this.generatePPT(pt[0])
    }
    generatePPT = (age) => {
        let pt = []
        // let { data } = this.props
        // let age = moment().diff(moment(data.dob, 'DD-MM-YYYY'), 'years');
        // console.log('AGE POLICY TERM PPT >> ', age)
        // let maxAge = 80 - age
        // pt.push({
        //     id: maxAge,
        //     label: '' + maxAge
        // })

        pt.push(age)
        pt.push({
            id: 60,
            label: 'Pay Till 60'
        })

        this.setState({
            ppt: pt[0],
            pptList: pt
        })
        this.state.ppt = pt[0]
        this.state.pptList = pt
    }
    onPtChange = (value) => {
        this.dataChange('pt', value.id)
        this.setState({ pt: value })
        this.generatePPT(value)
    }

    onPptChange = (value) => {
        if (value.id <= 60 && this.state.pt.id > 15) {
            this.dataChange('ppt', value.id)
            this.setState({ ppt: value })
        }
    }

    onPaymentFreqChange = (value) => {
        this.setState({ paymentFrequency: value })
        this.dataChange('INPUT_MODE', value.id)
    }

    onPlanOptionChange = (value) => {
        this.setState({ planOption: value })
        this.dataChange('PlanOption', value.id)
    }
    onPayoutOptionChange = (value) => {
        this.setState({ payoutOption: value })
        this.state.reqParams.DeathBenefitPayoutOption = value.id
        // this.dataChange('PayoutOption', value.id)
    }
    onPayoutPeriodChange = (value) => {
        this.setState({ payoutPeriod: value })
        this.state.reqParams.PayoutPeriodMonths = value.id
        // this.dataChange('PayoutPeriod', value.id)
    }
    onPayoutPercentageChange = (value) => {
        this.setState({ payoutPercentage: value })
        this.state.reqParams.Lumpsum = value.id
        // this.dataChange('PayoutPercentage', value.id)
    }

    onCoverAmountChange = (value, index) => {
        // let riders = this.props.cardsList
        // riders[index].selected_value = value
        // riders[index].sum_assured = (value.id * riders[index].initial_amount)
        // FamilyProtectionStore.zindagiPlusRiders = riders
        // FamilyProtectionStore.getBIResponse()

        let riders = this.state.addRiders
        riders[index].selected_value = value
        // riders[index].sum_assured = (value.id * riders[index].initial_amount)
        this.state.addRiders = riders
        this.dataChange(riders[index].paramName, value)
    }
    onAddCoverAmountChange = (value, index) => {
        console.log('ON ADD COVER AMOUNT CHANGE >> ', value, index)
        let benifits = this.state.addBenifits
        benifits[index].selected_value = value
        // riders[index].sum_assured = (value.id * riders[index].initial_amount)
        this.state.addBenifits = benifits
        this.dataChange('TopUpBenefit', value.id)
    }

    toggleRider = (checkedValue, selectedObj, memberDetails, index) => {
        let riders = this.state.addRiders
        // let index = selectedObj.id - 1
        // riders[index].is_selected = checkedValue
        // this.setState({ addRiders: riders })
        this.dataChange(riders[index].paramName, checkedValue)
        this.state.pendingUpdateValues = { key: 'Riders', value: { selectedObj, checkedValue, index } }
    }

    getCoverAmountList = () => {
        let list = []
        for (let index = 0; index <= 100; index = index + 5) {
            if (index != 100) {
                list.push({
                    id: index == 0 ? 1 : index,
                    label: (index == 0 ? 1 : index) + ' L'
                })
            } else {
                list.push({
                    id: index,
                    label: 1 + ' Cr.'
                })
            }
        }
        return list
    }

    getCoverAmountListForCLRnHCB = () => {
        let list = []
        for (let index = 0; index <= 6; index = index + 1.5) {
            list.push({
                id: index == 0 ? 1 : index,
                label: (index == 0 ? 1 : index) + ' L'
            })
        }
        return list
    }

    onMaritalStatusChange = (value) => {
        this.setState({ marrideStatusOption: value })
        if (value.id == 1) {
            this.props.onAddInsured()
            this.props.onAddMember()
            setTimeout(() => {
                this.setState({ showSpouseDetailsModal: true })
            }, 1000);
        } else {
            this.setState({
                showSpouseDetailsModal: false
            })
            const data = {
                'SpouseGender': '',
                'SpouseName': '',
                'SpouseDOB': '',
                'MaritalStatus': '9'
            }
            this.dataChange('maritalStatus', data)
        }
    }

    onPendingUpdateValues = () => {
        let { key, value } = this.state.pendingUpdateValues !== null ? this.state.pendingUpdateValues : ''
        if (!key) {
            return
        }
        switch (key) {
            case 'betterHalfBenefit':
                this.state.addBenifits[value.selectedObj.id - 1].is_selected = value.checkedValue
                this.setState({ addBenifits: [...this.state.addBenifits] })
                break
            case 'AdditionalBenefit':
                this.state.addBenifits[value.selectedObj.id - 1].is_selected = value.checkedValue
                this.setState({ addBenifits: [...this.state.addBenifits] })
                break
            case 'TopUpBenefit':
                this.state.addBenifits[value.selectedObj.id - 1].is_selected = value.checkedValue
                this.setState({ addBenifits: [...this.state.addBenifits] })
                break
            case 'Riders':
                let riders = this.state.addRiders
                let index = value.index
                riders[index].is_selected = value.checkedValue
                this.setState({ addRiders: riders })
                break
        }
        this.state.pendingUpdateValues = null
    }

    fetchData = async () => {
        this.setDefaultRequestParams()
        const { showEligibilityModal, eligibilityModalError, reqParams } = this.state;
        const result = await FamilyProtectionStore.fetchZindagiPlus(reqParams)
        let dataObj = JSON.parse(result.JsonOutput);
        this.state.addRiders.map((riderData) => {
            try {
                let inputValidationStatus = dataObj.InputValidationStatus.filter((item) => item.ProductName == riderData.rider_name)
                console.log('RIDER FETCH AMOUNT >> ', inputValidationStatus)
                if (inputValidationStatus.length > 0) {
                    riderData.sum_assured = inputValidationStatus[0].ModalPremium.toString()
                }
            } catch (error) {
                console.log('RIDER ERROR >> ', error)
            }
        })
        console.log('ZINDAGI PLUS BI RESULT >> 1 ', result, dataObj)

        if (dataObj.Status == 'Fail') {
            // let error = dataObj.InputValidationStatus[0].ErrorMessage
            // if (error.length > 0) {
            //     error = error[0].value
            //     // this.isValidateTelemedical = error == 'Sorry, Zindagi Plus cannot be offered to the current profile'
            //     // if (this.isValidateTelemedical) {
            //     //     this.state.addBenifits[3].is_selected = false
            //     // }
            // } else {
            //     error = 'Please check your inputs'
            // }
            // console.log('Error is ', error)
            if (showEligibilityModal) {
                this.state.addBenifits[3].is_selected = false
                this.state.reqParams.Underwriting = '1'
                this.setState({ eligibilityModalError: 'error' })
            } else {
                FamilyProtectionStore.showErrorMsg(dataObj)
                // AuthStore.setErrorModal(error)
            }
        } else {
            const { cardKey, data } = this.props;
            if (dataObj && JSON.parse(dataObj.BIJson)) {
                dataObj = JSON.parse(dataObj.BIJson);
                dataObj = dataObj[dataObj.length - 1]
                console.log('ZINDAGI PLUS BI RESULT >> 2 ', result, dataObj)

                FamilyProtectionStore.updateBIResponseToData(cardKey, data.product_id, result)

                let additionalBenifits = this.state.addBenifits
                additionalBenifits.filter((item) => item.title == 'Waiver of Premium').map((premiumData) => premiumData.sum_assured = (dataObj.WOP_MODAL_TAX_DISPLAY || '').toString())
                additionalBenifits.filter((item) => item.title == 'Better Half Benefit').map((premiumData) => premiumData.sum_assured = (dataObj.BH_Modal_Prem || '').toString())
                additionalBenifits.filter((item) => item.title == 'Top Up Benefit').map((premiumData) => premiumData.sum_assured = (dataObj.TOPUP_PREMIUM || '').toString())
                if (showEligibilityModal) {
                    if (eligibilityModalError == 'success') {
                        this.setState({ eligibilityModalError: '', showEligibilityModal: false })
                    } else {
                        this.setState({ eligibilityModalError: 'success' })
                    }
                    additionalBenifits[3].is_selected = true
                }
                additionalBenifits = JSON.parse(JSON.stringify(additionalBenifits))
                this.setState({ addBenifits: additionalBenifits, isLoading: false })
                this.onPendingUpdateValues()
            } else {
                this.setState({ isLoading: false })
                FamilyProtectionStore.updateBIResponseToData(cardKey, data.product_id, result)
            }
        }
    }

    dataChange = (paramName, paramValue) => {
        switch (paramName) {
            case 'isSmoking':
                this.state.reqParams.LI_SMOKE = (paramValue == true) ? '1' : '0'
                break;
            case 'ppt':
                if (paramValue == 60) {
                    this.state.reqParams.PR_PPT = '60 - @Li_entry_age +1 '
                } else if (paramValue > this.state.reqParams.PR_PT) {
                    this.state.reqParams.PR_PPT = '' + this.state.reqParams.PR_PT
                } else {
                    this.state.reqParams.PR_PPT = '' + paramValue
                }
                break;
            case 'pt':
                this.state.reqParams.PR_PT = '' + paramValue
                this.state.reqParams.PR_PPT = '' + paramValue
                // if (paramValue > this.state.reqParams.PR_PPT) {
                // }
                break;
            case 'sumAssured':
                this.state.reqParams.PR_SA = paramValue
                break;
            case 'premium':
                if (this.state.premiumAmount) {
                    let amount = this.state.premiumAmount
                    let inputMode = parseInt(this.state.reqParams.INPUT_MODE)
                    console.log('INPUT MODE IN PREMIUM >> ', inputMode)
                    switch (inputMode) {
                        case 1:
                            this.state.reqParams.PR_ANNPREM = '' + amount * 1
                            break;
                        case 2:
                            this.state.reqParams.PR_ANNPREM = '' + amount * 2
                            break;
                        case 3:
                            this.state.reqParams.PR_ANNPREM = '' + amount * 4
                            break;
                        case 4:
                            this.state.reqParams.PR_ANNPREM = '' + amount * 12
                            break;
                    }
                }
                break;
            case 'eligibility':
                this.state.reqParams.ZP_NATIONALITY = paramValue.nationality
                this.state.reqParams.ZP_OCCUPATION = paramValue.occupation
                this.state.reqParams.ZP_EDUCATION = paramValue.education
                this.state.reqParams.Underwriting = '2'
                const income = toJS(FamilyDetailsStore.monthlyIncomeList).filter(income => income.label == this.props.data.family_monthly_income)[0]
                if (income) {
                    let midRange = 0
                    switch (income.id) {
                        case 0:
                            // midRange = 7500 * 12
                            break
                        case 1:
                            midRange = 7500 * 12
                            break
                        case 2:
                            midRange = 32500 * 12
                            break
                        case 3:
                            midRange = 75000 * 12
                            break
                        case 4:
                            midRange = 150000 * 12
                            break
                        case 5:
                            midRange = 200000 * 12
                            break
                    }
                    this.state.reqParams.ZP_ANNINCOME = midRange > 0 ? midRange : paramValue.income
                }

                break;
            case 'Underwriting':
                this.state.reqParams.Underwriting = paramValue
                break;
            case 'PlanOption':
                this.state.reqParams.PlanOption = paramValue
                break;
            case 'INPUT_MODE':
                this.state.reqParams.INPUT_MODE = '' + paramValue
                let amount = this.state.premiumAmount
                if (amount) {
                    let inputMode = paramValue
                    switch (inputMode) {
                        case 1:
                            this.state.reqParams.PR_ANNPREM = '' + amount * 1
                            break;
                        case 2:
                            this.state.reqParams.PR_ANNPREM = '' + amount * 2
                            break;
                        case 3:
                            this.state.reqParams.PR_ANNPREM = '' + amount * 4
                            break;
                        case 4:
                            this.state.reqParams.PR_ANNPREM = '' + amount * 12
                            break;
                    }
                }
                break;
            case 'betterHalfBenefit':
                this.state.reqParams.BetterHalfBenefit = paramValue
                break;
            case 'AdditionalBenefit':
                this.state.reqParams.AdditionalBenefit = paramValue
                break;
            case 'PayoutOption':
                if (paramValue != 19 || paramValue != 20) {
                    this.state.reqParams.Lumpsum = ''
                    this.state.reqParams.PayoutPeriodMonths = ''
                }
                this.state.reqParams.DeathBenefitPayoutOption = paramValue
                break;
            case 'PayoutPeriod':
                this.state.reqParams.PayoutPeriodMonths = paramValue
                break;
            case 'PayoutPercentage':
                this.state.reqParams.Lumpsum = paramValue
                break;

            case 'ADBRider':
                if (paramValue) {
                    this.state.reqParams.ADBRider = (this.state.addRiders[0].selected_value.id * 100000).toString()
                } else {
                    this.state.reqParams.ADBRider = ''
                }
                break;

            case 'ATPDRider':
                if (paramValue) {
                    this.state.reqParams.ATPDRider = (this.state.addRiders[1].selected_value.id * 100000).toString()
                } else {
                    this.state.reqParams.ATPDRider = ''
                }
                break

            case 'CriticalIllnessRider':
                if (paramValue) {
                    this.state.reqParams.CriticalIllnessRider = (this.state.addRiders[2].selected_value.id * 100000).toString()
                } else {
                    this.state.reqParams.CriticalIllnessRider = ''
                }
                break;

            case 'HospitalityCashBenefitRider':
                if (paramValue) {
                    this.state.reqParams.HospitalityCashBenefitRider = (this.state.addRiders[3].selected_value.id * 100000).toString()
                } else {
                    this.state.reqParams.HospitalityCashBenefitRider = ''
                }
                break;

            case 'TopUpBenefit':
                this.state.reqParams.TopUpBenefit = paramValue
                break;

            case 'maritalStatus':
                this.state.reqParams.MaritalStatus = paramValue.MaritalStatus
                this.state.reqParams.SpouseName = paramValue.SpouseName
                this.state.reqParams.SpouseDOB = paramValue.SpouseDOB
                this.state.reqParams.SpouseGender = paramValue.SpouseGender
                break;
        }

        this.fetchData()
    }

    getParseBIData = () => {
        let data = JSON.parse(JSON.stringify(this.props.data));
        // console.log('GET PARSE DATA >> ', data.BI)
        let BI = data.BI
        try {
            if (BI && BI.JsonOutput) {
                BI = JSON.parse(BI.JsonOutput);
                BI = JSON.parse(BI.BIJson);
                if (BI) {
                    BI = BI[BI.length - 1]
                }
            } else {
                BI = {}
            }
        } catch (error) {
            console.log('ERROR >> ', error)
            BI = {}
        }
        if (!BI) {
            BI = {}
        }
        data.BI = BI
        return data
    }

    getFinalAddBenifitsList = () => {
        const { data } = this.props;
        const { planOption } = this.state;
        let addBenifits = this.state.addBenifits

        let familyMembers = toJS(FamilyDetailsStore.existingFamilyMember)
        let isSpouse = familyMembers.some(member => member.family_member_type == 'Spouse' && member.is_selected)
        let isBHBHide = data.family_member_type != 'Spouse' && data.family_member_type != 'Self'

        if (!isSpouse || (isSpouse && isBHBHide)) {
            addBenifits = addBenifits.filter(member => member.title != 'Better Half Benefit')
        }
        if (planOption.id == 2) {
            addBenifits = addBenifits.filter(member => member.title != 'Waiver of Premium')
        }
        return addBenifits
    }

    getTotalPrice = () => {
        let totalPremium = 0;
        let data = this.getParseBIData()

        totalPremium += data.BI.TOTAL_PREM_TAX

        this.state.addRiders.map((item) => {
            if (item.is_selected) {
                totalPremium += Number(item.sum_assured)
            }
        })
        return totalPremium;
    }

    render() {
        const { paymentFrequency, payoutOption, paymentFrequencyList, payoutOptionList, payoutPercentageList, payoutPercentage, payoutPeriodList, payoutPeriod, productID, showEligibilityModal, isTobacoUser, addRiders, isLoading, ptList, pptList, planOptionList, eligibilityModalError } = this.state;
        const { tab, selectedTab } = this.props;

        let data = this.getParseBIData()

        let addBenifits = this.getFinalAddBenifitsList()

        let totalPrice = this.getTotalPrice()
        if (tab && selectedTab) {
            totalPrice = tab != selectedTab ? 0 : totalPrice
        }

        console.log('ZINDAGI PLUS RENDER >> ', data)

        return (
            <div className='family-collapase-view'>
                {isLoading ?
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> :
                    Object.keys(data.BI).length > 0 ?
                        <React.Fragment>
                            <Row className='mt-4'>
                                <Col xs={12} md={5}>
                                    <div className=''>
                                        <div className='d-flex align-items-center'>
                                            <div className=' p-md-3 d-flex flex-md-column align-items-center align-items-md-start w-100'>
                                                <span className='mr-3 font-size-16 font-size-md-18 font-size-lg-22 text-description-text-color'>Tobbacco user?</span>
                                                <div className=''>
                                                    <CustomCheckbox className='mr-2' isChecked={isTobacoUser} onChange={(item) => this.tobaccoUserStatus(item)} titleStyle='font-size-16 font-size-md-20 font-size-lg-24' shape='rounded' title='Yes' mark='checkmark' />
                                                    <CustomCheckbox className='ml-2' isChecked={!isTobacoUser} onChange={(item) => this.tobaccoUserStatus(item)} titleStyle='font-size-16 font-size-md-20 font-size-lg-24' shape='rounded' title='No' mark='checkmark' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                                <Col className='col-12'>
                                    <DeathCoverPriceComponent
                                        firstAmount={this.state.reqParams.PR_SA}
                                        secondAmount={this.getTotalPrice()}
                                        thirdAmount='980'
                                        showAdditionalText={false}
                                        showSecondColAmount={false}
                                        secondColBottomText={this.state.paymentFrequency.label}
                                        firstColTitleText='Death cover'
                                        secondColTitleText='Price' />
                                </Col>
                            </Row>

                            <div className='text-primary-title-label-color font-weight-bold font-size-16 mb-3 mt-4 d-md-none'>Maturity Benefits</div>
                            <Row className='mt-md-5'>
                                <Col md={12} className='col-12 mt-4 mt-md-0'>
                                    <div className="d-flex justify-content-between align-items-center">
                                        {/* <div className='text-description-text-color font-size-12 font-size-md-18 font-size-lg-20'>Underlying product : Zindagy Plus <UiButton variant='' onClick={this.onChangeProductReasonModalOnShow} buttonText='[Change]' className='px-2 py-1 font-size-12 text-primary font-size-md-16 font-weight-medium' /></div> */}
                                        {/* <div className='text-description-text-color font-size-12 font-size-md-18 font-size-lg-20'>Underlying product : {data.product_name || 'Zindagi Plus'} </div> */}
                                        <div className='shadow p-3 bg-white rounded my-3 w-50'>
                                            <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Plan Option</div>
                                            <BottomsheetDropdown
                                                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                                menuItemStyle='d-flex justify-content-between align-items-center'
                                                selectedLabelKey='label'
                                                bottomsheetTitle='Plan Option'
                                                menuList={planOptionList}
                                                value={this.state.planOption}
                                                onSelect={this.onPlanOptionChange}
                                            />
                                        </div>
                                    </div>
                                    <ChangeProductReasonModal
                                        selectedReasonVal={this.state.selectdReason}
                                        show={this.state.changeProductReasonModal}
                                        onSelectReason={this.onChangeReason}
                                        onHide={this.onChangeProductReasonModalOnHide}
                                        list={this.state.productReasonList}
                                        onSelectProductModalbtnClick={this.onChangeProductModalOnShow}
                                        isReason={this.state.isReason}
                                    />

                                    <ChangeProductModal
                                        show={this.state.changeProductModal}
                                        onSelectReason={() => { }}
                                        onHide={this.onChangeProductModalOnHide}
                                        list={this.state.productList}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded my-3'>
                                        <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Premium Paying Frequency</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Policy Term'
                                            menuList={paymentFrequencyList}
                                            value={paymentFrequency}
                                            onSelect={this.onPaymentFreqChange}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded my-3'>
                                        <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Payout Option</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Payout Option'
                                            menuList={payoutOptionList}
                                            value={payoutOption}
                                            onSelect={this.onPayoutOptionChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded my-3'>
                                        <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Payout Period</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Payout Period'
                                            menuList={payoutPeriodList}
                                            value={payoutPeriod}
                                            disabled={payoutOption.id <= 16}
                                            onSelect={this.onPayoutPeriodChange}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded my-3'>
                                        <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Payout Percentage</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Payout Percentage'
                                            menuList={payoutPercentageList}
                                            value={payoutPercentage}
                                            disabled={payoutOption.id <= 18}
                                            onSelect={this.onPayoutPercentageChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col md={12} className='col-12 mt-4 mt-md-0'>
                                    <DeathCoverGraphSlider
                                        saLabel='Sum Assured'
                                        productTitle='Zindagi Plus'
                                        openChangeReasonModal={this.onChangeProductReasonModalOnShow}
                                        onIncomeChange={() => { }}
                                        onPremiumChange={this.onPremiumChange}
                                        dataChange={this.dataChange}
                                        hideOverLay={this.hideOverLay}
                                        productID={productID}
                                        income_value={this.state.reqParams.PR_SA}
                                        premium_value={this.state.premiumAmount}
                                        showOverlay={this.state.showOverlay}
                                        onClick={this.onRecalculateButtonClick}
                                        data={data}
                                        isIncomeFieldDisable={true}
                                        isPremiumDisable={true}
                                        premiumFreq={paymentFrequency.label}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded my-3'>
                                        <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Policy Term</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Policy Term'
                                            menuList={ptList}
                                            value={this.state.pt}
                                            onSelect={this.onPtChange}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded my-3'>
                                        <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Premium Paying Term</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Premium Paying Term'
                                            menuList={pptList}
                                            value={this.state.ppt}
                                            onSelect={this.onPptChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            {
                                // !FamilyDetailsStore.isMarried &&
                                // <Row className='mt-4'>
                                //     <Col md={6} className='col-12'>
                                //         <div className='shadow p-3 bg-white rounded my-3'>
                                //             <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Marital Status</div>
                                //             <BottomsheetDropdown
                                //                 dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                //                 menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                //                 menuItemStyle='d-flex justify-content-between align-items-center'
                                //                 selectedLabelKey='label'
                                //                 bottomsheetTitle='Marital Status'
                                //                 menuList={this.state.maritalStatusList}
                                //                 value={this.state.marrideStatusOption}
                                //                 onSelect={this.onMaritalStatusChange}
                                //             />
                                //         </div>
                                //     </Col>
                                // </Row>
                            }
                            <SpouseDetailsModal show={this.state.showSpouseDetailsModal} onHideModal={() => this.setState({ showSpouseDetailsModal: false })} data={data} />
                            <Row className='mt-2'>
                                <Col md={6} className='col-12'>
                                    <CheckEligibilityModal isPremiumDisable={true} error={eligibilityModalError} dataChange={this.dataChange} premiumAmount={data.BI.SA} show={showEligibilityModal} onHideModal={() => this.setState({ showEligibilityModal: false, eligibilityModalError: '' })} data={data} />
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md={6} className='col-12'>
                                    <AdditionalCards
                                        productID={this.state.productID}
                                        cardsList={addBenifits}
                                        cardTopText='Additional Benefits'
                                        onClick={this.onAdditionalCardOptionSelect}
                                        checkedValue={this.state.selectedAdditionalCardValue}
                                        onAmountChange={this.onAddCoverAmountChange}
                                        data={data}
                                    />
                                </Col>
                                <Col md={6} className='col-12 mt-4 mt-md-0'>
                                    <AdditionalRiderBenifitsCard
                                        productID={this.state.productID}
                                        cardsList={addRiders}
                                        cardTopText='Additional Rider'
                                        onClick={this.onAdditionalRiderCardOptionSelect}
                                        checkedValue={this.state.selectedAdditionalRiderCardValue}
                                        data={data}
                                        toggleRider={this.toggleRider}
                                        onAmountChange={this.onCoverAmountChange}
                                        amountList={this.state.coverAmountList}
                                        amountListforCLRnHCB={this.state.coverAmountListForCLRnHCB}
                                    />
                                </Col>
                            </Row>
                            <AmountCalculation
                                cardKey={this.props.cardKey}
                                item={data}
                                totalPremium={data.BI.TOTAL_PREM_DISPLAY}
                                gst={data.BI.TAX_MP}
                                gst_percentage={(data.BI.TAX_RATE * 100)}
                                total_price={totalPrice}
                                riderList={addRiders}
                                riderTax={data.BI.RIDER_TOTAL_TAX}
                            />
                        </React.Fragment>
                        : <div>Data Not Found</div>

                }
            </div>
        );
    }
});

ZindagiPlus.defaultProps = {
    disabled: false
}

ZindagiPlus.propTypes = {
    data: PropTypes.object
}

export default ZindagiPlus;
