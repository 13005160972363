import React, { useEffect, useState } from "react";

import { observer } from "mobx-react";

import { Col, Row } from "react-bootstrap";

import AmountCalculation from "../../optimization-card/amount-calculation/AmountCalculation";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import { BottomsheetDropdown, ChangeProductSection } from "components";
import FundDistributionModal from "../../optimization-card/fundDistributionModal";
import Utils from "./utils";

const WealthGainPlus = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false)

  let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let firstYearResponse = productData.firstYearResponse;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])

  const onHideOverlay = () => {
    setShowOverlay(false)
  }

  return (
    <div className="save-my-children">
      <Row className="align-items-center mt-md-4">
        <Col xs={12} md={6} className="mt-3 mb-2 mb-md-0 mt-md-0">
          <ChangeProductSection
            productName={productData.product_name || "Wealth Gain +"}
            cardName={coverLabelBaseOnNeed}
            changeProductList={data?.data?.changeProductList}
            productData={data}
          />
        </Col>
        <Col md={6} className="col-12">
          <BottomsheetDropdown
            dropdownStyle="font-size-12 font-weight-light text-description-text-color"
            menuItemStyle="d-flex justify-content-between align-items-center"
            selectedLabelKey="label"
            bottomsheetTitle={form.$("payment-frequency").label}
            menuList={form.$("payment-frequency").extra.optionList}
            value={form.$("payment-frequency").value}
            onSelect={form.$("payment-frequency").onChange}
          />
        </Col>
      </Row>

      <DeathCoverGraphSlider
        productTitle="Active Income"
        openChangeReasonModal={false}
        onIncomeChange={() => { }}
        onPremiumChange={form.$("premium").onChange}
        hideOverLay={onHideOverlay}
        productID={productData.product_id}
        productData={data?.data}
        showOverlay={showOverlay}
        cardKey={data.cardKey}
        income_value=""
        premium_value={form.$("premium").value}
        onClick={() => form.$("premium").extra.calculatePremiun(form, data)}
        data=""
        isIncomeFieldDisable={form.$("premium").disabled}
        premiumFreq={form.$("payment-frequency").value.label}
        formRef={form}
      />
      <Row className="mt-2 mt-md-4">
        <Col md={6} className="col-12">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("policy-term").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle="font-size-12 font-weight-light text-description-text-color"
            menuItemStyle="d-flex justify-content-between align-items-center"
            selectedLabelKey="label"
            bottomsheetTitle={form.$("policy-term").label}
            menuList={form.$("policy-term").extra.optionList}
            value={form.$("policy-term").value}
            onSelect={form.$("policy-term").onChange}
            disabled=""
          />
        </Col>
        <Col md={6} className="col-12">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("premium-term").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle="font-size-12 font-weight-light text-description-text-color"
            menuItemStyle="d-flex justify-content-between align-items-center"
            selectedLabelKey="label"
            bottomsheetTitle={form.$("premium-term").label}
            menuList={form.$("premium-term").extra.optionList}
            value={form.$("premium-term").value}
            onSelect={form.$("premium-term").onChange}
          />
        </Col>
      </Row>
      <Row className="mt-2 mb-3 mt-md-4">
        <Col md={6} className="col-12 d-md-flex mt-2 mt-md-0">

          <div className={` ${form.$("fund-strategy").value.id !== 1 ? 'col px-0' : 'col-4 col-md-9'}`}>
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("fund-strategy").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle="font-size-12 font-weight-light text-description-text-color"
              menuItemStyle="d-flex justify-content-between align-items-center"
              selectedLabelKey="label"
              bottomsheetTitle={form.$("fund-strategy").label}
              menuList={form.$("fund-strategy").extra.optionList}
              value={form.$("fund-strategy").value}
              onSelect={form.$("fund-strategy").onChange}
              disabled=""
            />
          </div>

          {form.$("fund-strategy").value.id == 1 && (
            <React.Fragment>
              <div className="col-md-3">
                <span className="font-size-12 font-weight-light">Fund Distribution</span>
                <span
                  className="a cursor-pointer d-md-block font-size-14 text-primary font-weight-light mt-2"
                  onClick={() =>
                    form
                      .$("fund-strategy")
                      .extra.toggleFundDistributionModal(
                        form,
                        true,
                        form.$('fund-strategy').extra.fundList,
                        data.data.product_id
                      )
                  }
                >
                  {" "}
                  <u>Click to View</u>
                </span>
              </div>
            </React.Fragment>
          )}
        </Col>
      </Row>
      <FundDistributionModal
        formRef={form}
        show={form.$("fund-strategy").extra.showFundDistributionModal}
        data={data}
        fundList={form.$('fund-strategy').extra.fundList}
        submitButtonText={form.$('fund-strategy').extra.setDistributionButtonText}
        onHide={(fundList) =>
          form.$("fund-strategy").extra.toggleFundDistributionModal(form, false, fundList, data.data.product_id)
        }
        onSetDistribution={form.$('fund-strategy').extra.onSetDistribution}
      />
    </div>
  );
};

export default observer(WealthGainPlus);
