// Package Imports
import React from 'react'
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap'

// Project Imports
import './qms-details-page-tab-component.css'

function QmsDetailsPageTabComponent(props) {
    const { tabList, setSelectedTabIndex } = props;

    return (
        <Tabs defaultActiveKey={0} className='qms-tab-container flex-nowrap' >
            {tabList.map((item, index) => {

                return (
                    <Tab key={index} eventKey={index} title={
                        <>
                            <TabTitleComponent tabData= {item} />
                        </>
                    } />
                )
            })}

        </Tabs>
    )
};

const TabTitleComponent = (props) => {
    const { tabData } = props;
    return (
        <div className='d-flex flex-column align-items-center w-100 tab-titles-container'>
            <span className='font-size-16 tab-title'>{tabData.title}</span>
            {
                tabData.subTitle1 && 
                <p className='mb-0 font-size-10 tab-subtitle font-weight-light gray-color-111'>
                    {tabData.subTitle1} 
                    <span className='gray-color-555'>{` - ${tabData.subTitle2}`}</span>
                </p>
            }
            
        </div>
    )
}

QmsDetailsPageTabComponent.defaultProps = {
    tabList: [],
};

QmsDetailsPageTabComponent.propTypes = {
    setSelectedTabIndex: PropTypes.func.isRequired
}

export default QmsDetailsPageTabComponent
