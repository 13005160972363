import * as keys from './keys'
import OptimizationStore from '../../../store/OptimizationStore';
import Validator from './validator';

class Utils {
  getPolicyTerm = (age) => {
    let pt = [];
    for (let i = 5; i <= 25; i++) {
      pt.push({ id: i, label: "" + i });
    }

    let maxPt = 100 - Number(age)
    pt.push({ id: maxPt, label: 'Whole Life' })
    return pt;
  };

  getPremiumTermList = (pt, age) => {
    let maxPt = 100 - Number(age)
    let ppt = [];
    for (let i = 5; i <= maxPt; i++) {
      ppt.push({ id: i, label: "" + i });
    }
    return ppt;
  };

  updateBi = (key, value, productId, formRef, productData) => {

    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request

    switch (key) {

      case keys.FREQUENCY:
        biRequest[keys.PREMIUM] = value.premium
        biRequest[keys.FREQUENCY] = value.frequency
        break;

      case keys.PR_PT:
        biRequest[keys.PR_PPT] = value;
        biRequest[keys.PR_PT] = value;
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value
        break;

      case keys.POLICY_OPTION:
        biRequest[keys.POLICY_OPTION] = value;
        break;

      case keys.FUNDSTRATEGYID:
        biRequest[keys.FUNDSTRATEGYID] = value;
        break;

      case keys.SWP_OPTION:
        biRequest[keys.SWP_OPTION] = value;
        break;

      case keys.FUND_VALUE:
        biRequest[keys.FUND_VALUE] = value;
        break;

      case keys.SWP_FREQUENCY:
        biRequest[keys.SWP_FREQUENCY] = value;
        break;


      case keys.MANAGED_FUND:
        biRequest[keys.FUNDSTRATEGYID] = "1";
        biRequest[value.EQUITY_LARGE_CAP_FUND] = value[keys.EQUITY_LARGE_CAP_FUND];
        biRequest[keys.EQUITY_LARGE_CAP_FUND] = value[keys.EQUITY_LARGE_CAP_FUND];
        biRequest[keys.EQUITY_TOP_20_FUND] = value[keys.EQUITY_TOP_20_FUND];
        biRequest[keys.BOND_FUND] = value[keys.BOND_FUND];
        biRequest[keys.MANAGED_FUND] = value[keys.MANAGED_FUND];
        biRequest[keys.EQUITY_MID_CAP_FUND] = value[keys.EQUITY_MID_CAP_FUND];
        biRequest[keys.EQUITY_BLUE_CHIP_FUND] = value[keys.EQUITY_BLUE_CHIP_FUND];
        biRequest[keys.GLIT_FUND] = value[keys.GLIT_FUND];
        break;

      case keys.SWP_YEAR_PAYABLE:
        biRequest[keys.SWP_YEAR_PAYABLE] = value;
        break;
      case keys.SPOUSE_DOB:
        biRequest[keys.SPOUSE_DOB] = value.age;
        biRequest[keys.OSN_SPOUSE_DATE_OF_BIRTH] = value.dob;
        break;
      case keys.SPOUSE_GENDER:
        biRequest[keys.SPOUSE_GENDER] = value;
        break;
      case keys.SPOUSE_NAME:
        biRequest[keys.SPOUSE_NAME] = value;
        break;

    }
    biRequest["PR_SA"] = biRequest[keys.PREMIUM] * 10;
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return
    // }

  };

  getTotalPrice = (data) => {
    try {
      return data.ANN_PREM;
    } catch (e) {
      return 0;
    }
  };

  populateWithdrawlPercentage() {
    let withdrawlPercentage = []
    for (let i = 1; i <= 120; i++) {
      let value = 6
      withdrawlPercentage.push({
        id: (value + i),
        label: parseFloat(i / 10).toFixed(2) + ' %'
      })
    }

    return withdrawlPercentage;
  }

  getSWPPolicyYear() {
    let withdrawlYear = []
    for (let i = 10; i < 67; i++) {
      withdrawlYear.push({
        id: i,
        label: i + ' year'
      })
    }
    return withdrawlYear;
  }

  setDistribution(fundList, productId, form, data) {
    let fundObject = {}
    fundList.forEach(item => {
      let value = item.value || 0
      let fundKey = item.key
      fundObject[fundKey] = value

    });
    this.updateBi(keys.MANAGED_FUND, fundObject, productId, form, data)

  }

  fetchPolicyTimeline(element, biJson2Data) {
    let totalPW = biJson2Data.TOTALPW ? Math.round(biJson2Data.TOTALPW) : 0
    let data = {
      youPay: Math.round(element.ANN_PREM_YEARLY),
      youGet: Math.round(totalPW) + Math.round(biJson2Data.FINAL_FV),
      lifeCover: Math.round(biJson2Data.DEATH_BEN),
      isMilestone: Math.round(biJson2Data.FINAL_FV) !== 0,
      premiumAmt: Math.round(element.ANN_PREM_YEARLY)
    }
    return data
  }
}

export default new Utils();
