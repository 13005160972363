// React Imports
import React, { Component } from "react";

// Package Imports
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { Image, Modal, Row, Col } from "react-bootstrap";

// Project Imports
import * as Constants from "constants/Constants";
import { Button as UiButton } from "components";
import * as images from "constants/Images";
import PreNeedSelectionModalStore from "modules/pre-need-selection-modal/store/PreNeedSelectionModalStore";
import PreNeedSelectionModal from "modules/pre-need-selection-modal/components/PreNeedSelectionModal";
import SelectNeedModal from "modules/pre-need-selection-modal/components/SelectNeedModal";
import AuthStore from "modules/login/store/AuthStore";


import Breadcrumb from "../breadcrumb/Breadcrumb";
import StorageKeys from "constants/StorageKeys";
import CloseModal from "modules/optimization/components/closeModal/CloseModal";
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import SolutionStore from "modules/solution/store/SolutionStore";
import QuotationPreviewUtils from "modules/quotation-preview/utils";
import FormContext from "context/FormContext";

import CustomerCommunicationDetailModal from "modules/solution/customerCommunicationDetail/CustomerCommunicationDetails.js";

// CSS Imports
import "./header-analysis.css";

const HeaderAnalysis = observer(
  class HeaderAnalysis extends Component {
    constructor(props) {
      super(props);

      this.state = {
        showSaveSessionModal: false,
        saveSession: false,
        inputValue: "",
        fieldActive: false,
        customerName: "",
        mobileNo: "",
        whatsappNo: "",
        email: "",
        sameAsAbove: true,
        countryList: [
          { id: 1, label: "India", icon: images.IndiaFlagImg, value: "91", isSelected: true },
          { id: 2, label: "Australia", icon: images.AustraliaFlagImg, value: "61", isSelected: false },
          { id: 3, label: "Austria", icon: images.AustriaFlagImg, value: "43", isSelected: false },
        ],
        mobileNoSelectedCountry: { id: 1, label: "India", icon: images.IndiaFlagImg, value: "91", isSelected: true },
        whatsappNoSelectedCountry: { id: 1, label: "India", icon: images.IndiaFlagImg, value: "91", isSelected: true },
        nameErrorMsg: "",
        emailErrorMsg: "",
        mobileNoErrorMsg: "",
        whatsappNoErrorMsg: "",
        showBackArrow: false,
      };
      this.onBackArrowClick = this.onBackArrowClick.bind(this);
      this.onShowBackButton = this.onShowBackButton.bind(this);
      this.onCloseBtnClick = this.onCloseBtnClick.bind(this);
      // this.onSessionExpiredHideModal = this.onSessionExpiredHideModal.bind(this);
      // this.onLoginAgainClick = this.onLoginAgainClick.bind(this);
    }
    componentDidMount() {
      this.onShowBackButton();
    }

    componentDidUpdate(prevProps) {
      if (this.props.match.params.selectedPage !== prevProps.match.params.selectedPage) {
        this.onShowBackButton();
      }
    }

    onShowBackButton() {
      const selectedPage = this.props.match.params.selectedPage;
      if (selectedPage == "choose-needs") {
        this.setState({
          showBackArrow: false,
        });
      } else {
        this.setState({
          showBackArrow: true,
        });
      }
      this.setState({});
    }


    openSaveSessionModal = () => {
      this.setState({ showSaveSessionModal: true })
      SolutionStore.isCloseModalOpen = true
    }

    onSaveSessionShowModal = async () => {
      // TODO: NEed to handle the saving of data before redirecting to pragati.
      this.setState({ showSaveSessionModal: false });
      SolutionStore.customerDetailsModalToggleFunction(true)
    };
    onSaveSessionHideModal = () => {
      this.setState({ showSaveSessionModal: false });
      SolutionStore.isCloseModalOpen = false
    };

    onSaveHideModal = () => {
      this.setState({ showSaveSessionModal: false }),
        setTimeout(() => {
          this.setState({ saveSession: false });
        }, 150);
    };

    onSameAbove = (value) => {
      if (value) {
        const { mobileNo, mobileNoSelectedCountry } = this.state;
        this.setState({ whatsappNo: mobileNo, whatsappNoSelectedCountry: mobileNoSelectedCountry, sameAsAbove: value });
      } else {
        this.setState({ sameAsAbove: value });
      }
    };

    onMobileDropDownOptionClick = (value) => {
      this.setState({ mobileNoSelectedCountry: value });
      const { sameAsAbove } = this.state;
      if (sameAsAbove) {
        this.setState({ whatsappNoSelectedCountry: value });
      }
    };

    onWhatsappDropDownOptionClick = (value) => {
      this.setState({ whatsappNoSelectedCountry: value });
    };

    onBackArrowClick() {
      const selectedPage = this.props.match.params.selectedPage;
      let journeyId = "";
      if (this.props.match.params.journeyId !== undefined) {
        journeyId = this.props.match.params.journeyId;
      }
      switch (selectedPage) {
        case "choose-needs":
          this.props.history.push(`/choose-needs/${journeyId}`);
          break;
        case "family-details":
          this.props.history.push(`/choose-needs/${journeyId}`);
          break;
        case "family-protection":
          this.props.history.push(`/family-details/${journeyId}`);
          break;
        case "solution":
          // this.props.history.push(`/optimization/${journeyId}`)
          this.props.history.goBack();
          break;
        case "quotation-preview":
          this.props.history.push(`/share-quote/${journeyId}`);
          break;
        case "share-quote":
          this.props.history.push(`/solution/${journeyId}`);
          break;
        case "journey-complete":
          this.props.history.push(`/quotation-preview/${journeyId}`);
          break;
        case "customization":
          this.props.history.push(`/family-details/${journeyId}`);
          break;
        default:
          this.props.history.push(`/choose-needs/${journeyId}`);
          break;
      }
    }

    onUpdateValue = (key, value) => {
      PreNeedSelectionModalStore.onUpdateValue(key, value);
    };
   
    async onCloseBtnClick() {
      const selectedPage = this.props.match.params.selectedPage
      if(selectedPage == 'customization' || selectedPage == 'solution'){
      this.openSaveSessionModal()
      }else{
        let { formController, callbackFunctions } = this.context;
  
       const response = await formController.validate({ showErrors: true }).then(({ isValid }) => {
    
          if (isValid) {
            if (callbackFunctions.success !== undefined) {
              callbackFunctions.success(formController).then(() => {
                this.openSaveSessionModal()
              });
            }
          } else {
            console.log("Form errors are ", formController.errors());
          }
        });
      }
    }
  
    render() {
      const { countryList, mobileNoSelectedCountry, whatsappNoSelectedCountry } = this.state;
      let agentName = localStorage.getItem(StorageKeys.AGENT_NAME);
      let agentCode = localStorage.getItem(StorageKeys.AGENT_CODE);

      return (
        <header className='header-container'>
          <div className='header-outer-container py-3 py-lg-2 '>
            <div className='d-flex justtify-contnet-md-start align-items-center header-main-section'>
              <div className='d-flex align-items-center w-100'>
                <div className='pr-3 mr-3 py-2 d-none d-md-block border-right'>
                  <UiButton onClick={this.onCloseBtnClick} variant='' className='p-0 d-md-flex flex-row align-items-center'>
                    <Image width='12' height='12' src={images.CloseIconWhite} alt='close-icon' />
                    <span className='font-size-11 mt-1 mx-2'>Close</span>
                  </UiButton>
                  {/* <CloseModal/> */}
                </div>
                <div className='d-flex justify-content-lg-between align-items-center w-100'>
                  <div className='d-flex flex-column flex-lg-row align-items-lg-center align-items-start'>
                    <h1 className='text-center font-size-16 font-size-lg-16 font-weight-medium mb-0'>
                      Need Analysis
                      <span className='bi-accent pl-1'>BI</span>
                      <span className='px-1 font-size-16 font-size-lg-16 font-weight-light d-none d-lg-inline'>-</span>
                    </h1>

                    <PreNeedSelectionModal {...this.props} />
                    <SelectNeedModal {...this.props} />
                  </div>
                  <UiButton onClick={() => {}} variant='' className='px-1 px-md-3 py-1 text-white font-size-12 user-dropdown-btn d-none d-lg-block'>
                    <span>{`${agentName} - ${agentCode}`}</span>
                  </UiButton>
                </div>
              </div>
              <div className='d-md-none d-block'>
                <UiButton onClick={this.onCloseBtnClick} variant='' className='px-1'>
                  <Image width='15' height='15' src={images.CloseIconWhite} alt='close-icon'/>
                </UiButton>
              </div>
            

            </div>
          </div>

          {/* save session modal start */}
          <Modal
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            backdrop='static'
            className='save-session-modal'
            show={this.state.showSaveSessionModal}
            keyboard={false}
          >
            <Modal.Body className='text-center text-primary-title-label-color px-4 pt-4 pb-0'>
              {!this.state.saveSession && (
                <React.Fragment>
                  <Image className='img-fluid mb-4' src={images.SaveSession} alt='save-session' />
                  <h3 className='font-weight-medium font-size-22 font-size-md-32 mb-3'>The need analysis is not complete yet.</h3>
                  <p className='font-weight-normal font-size-20 font-size-md-24 mb-0'>Would you like to save the current need analysis session ?</p>
                </React.Fragment>
              )}
            </Modal.Body>
            <Modal.Footer className='d-block p-4 border-0'>
              <Row className='modal-footer-row'>
                {!this.state.saveSession && (
                  <React.Fragment>
                    <Col xs='12' md='6'>
                      <UiButton onClick={this.onSaveSessionShowModal} className='font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0' variant='secondary' buttonText='Save Session' />
                    </Col>
                    <Col xs='12' md='6'>
                      <UiButton onClick={this.onSaveSessionHideModal} className='font-size-medium font-size-16 px-2 w-100' variant='outline-secondary' buttonText='Don`t Save' />
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            </Modal.Footer>
          </Modal>
          <Breadcrumb {...this.props} updateErrorClass={this.props.updateErrorClass} />
                {SolutionStore.isCustomerDetailModalOpen && <CustomerCommunicationDetailModal />}          
        </header>
      );
    }
  }
);

HeaderAnalysis.contextType = FormContext;

export default HeaderAnalysis;
