import setFundAllocation from 'utils/setFundAllocation';
import Utils from './utils';


const initialDefaults = (formRef, data) => {
    let productData = data.data
    let biRequest = productData.bi_request;

    let frequency = biRequest.INPUT_MODE.toString()
    let fundStrategy = biRequest.FUNDSTRATEGYID
    let annualPremium = biRequest.PR_ANNPREM
    let fundList = formRef.$("fund-strategy").extra.fundList;
    let updatedFundList = [];

    let premiumAmount = ''
    switch (frequency) {
        case '1':
            premiumAmount = annualPremium;
            break;
        case '2':
            premiumAmount = Number(annualPremium) / 2;
            break;
        case '3':
            premiumAmount = Number(annualPremium) / 4;
            break;
        case '4':
            premiumAmount = Number(annualPremium) / 12;
            break;

        default:
            break;
    }
    formRef.$('premium').set('value', premiumAmount)

    let ptList = Utils.getPolicyTerm()
    formRef.$('policy-term').set('extra', {
        ...formRef.$('policy-term').extra,
        optionList: ptList
    })

    formRef.$('policy-term').value = {
        id: biRequest.PR_PT,
        label: biRequest.PR_PT
    }

    let pptList = Utils.getPremiumTermList(formRef.$('policy-term').value)


    formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        optionList: pptList
    })

    formRef.$('premium-term').value = {
        id: biRequest.PR_PPT,
        label: biRequest.PR_PPT
    }

    formRef.$('fund-strategy').value = {
        id: biRequest.FUNDSTRATEGYID,
        label: biRequest.FUNDSTRATEGYID
    }


    switch (frequency) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '2':
            formRef.$('payment-frequency').set('value', {
                id: '2',
                label: 'Semi-Annual'
            })
            break;
        case '3':
            formRef.$('payment-frequency').set('value', {
                id: '3',
                label: 'Quarterly'
            })
            break;
        case '4':
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }

    switch (fundStrategy) {
        case '1':
            formRef.$('fund-strategy').set('value', {
                id: '1',
                label: 'Self-managed Strategy'
            })
            break;
        case '2':
            formRef.$('fund-strategy').set('value', {
                id: '2',
                label: 'Life stage and duration based Strategy'
            })
            break;
        default:
            break;
    }

    updatedFundList = setFundAllocation(fundList, biRequest);
    formRef.$("fund-strategy").set('extra', {
        ...formRef.$('fund-strategy').extra,
        showFundDistributionModal: false,
        fundList: updatedFundList
    })

}

export default initialDefaults