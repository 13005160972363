// React Imports
import React, { Component } from "react";

// Package Imports
import { observer } from "mobx-react";

// Project Imports
import { Button as UiButton } from "components";
import FormContext from "context/FormContext";
import QmsDetailsBreadcrumb from "../qms-details-breadcrumb/QmsDetailsBreadcrumb";
import QmsDashboardBreadcrumb from "../qms-dashboard-breadcrumb/QmsDashboardBreadcrumb";
import QmsStorageKeys from "constants/QmsStorageKeys";

// CSS Imports
import "./qms-header.css";

const QmsHeader = observer(
  class QmsHeader extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {
      let agentName = localStorage.getItem(QmsStorageKeys.AGENT_NAME);
      let agentCode = localStorage.getItem(QmsStorageKeys.AGENT_CODE);

      let currentLocation = window.location.pathname
      return (
        <header className='header-container'>
          <div className='header-outer-container py-3 py-lg-3'>
            <div className='d-flex justtify-contnet-md-start align-items-center header-main-section'>
              <div className='d-flex align-items-center w-100'>
                <div className='d-flex justify-content-lg-between align-items-center w-100'>
                  <div className='d-flex flex-column flex-lg-row align-items-lg-center align-items-start'>
                    <h1 className='text-center font-size-16 font-size-lg-18 font-weight-medium mb-0 pl-1'>
                      Need Analysis
                      <span className='bi-accent pl-1'>BI</span>
                    </h1>
                  </div>
                  <UiButton onClick={() => { }} variant='' className='px-1 px-md-3 py-1 text-white font-size-12 user-dropdown-btn d-none d-lg-block'>
                    <span>{`${agentName} - ${agentCode}`}</span>
                  </UiButton>
                </div>
              </div>
            </div>
          </div>

          {currentLocation.includes("qms-dashboard") ?
            <QmsDashboardBreadcrumb {...this.props} />
            :
            <QmsDetailsBreadcrumb {...this.props} />}
        </header>
      );
    }
  }
);

QmsHeader.contextType = FormContext;

export default QmsHeader;
