// React Imports
import { useContext } from "react";
// Package Imports
import { Col, Image, Row } from "react-bootstrap";
import { Bar, BarChart, ReferenceLine, Tooltip, XAxis, LabelList } from "recharts";

// Project Imports
import * as images from "constants/Images";
import ChooseNeedFormContext from "context/ChooseNeedFormContext";
import moment from "moment";

const ProtectionCalculatedValue = (props) => {
  const { graphData, setShowCalculatedValue, calculatedData, targetAmountTypeValue, onUseTheValueClick } = props;
  const chooseNeedFormContext = useContext(ChooseNeedFormContext);

  let birthYear;
  if (location.pathname.includes("choose-needs")) {
    birthYear = moment(chooseNeedFormContext.formController.$("protection_for_my_family.dob").value).year();
  } else if (location.pathname.includes("customization")) {
    birthYear = moment(props.dateOfBirth).year();
  }

  const targetAmountType = targetAmountTypeValue.type;
  const { annual_income: annualIncome, lumpsum } = calculatedData;

  let recommendAmount = 0;

  if (targetAmountType === "lumpsum") {
    recommendAmount = lumpsum;
  } else {
    recommendAmount = lumpsum;
  }

  // if()

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let income = Number(payload[0].payload.income);
      let expense = Math.abs(payload[0].payload.expense);
      let emis = Math.abs(payload[0].payload.emis);

      return (
        <div
          className='custom-tooltip bg-white p-4'
          style={{
            whiteSpace: "nowrap",
            margin: " 0px",
            padding: "10px",
            backgroundColor: "rgb(255, 255, 255)",
            border: "1px solid rgb(204, 204, 204)",
          }}
        >
          {/* <p className="label">{payload[0].payload.name}</p> */}
          <p className='intro' style={{ color: "var(--bar-graph-income-color)" }}>
            Income: {income.toLocaleString("en-IN")}
          </p>
          <p className='intro' style={{ color: "var(--bar-graph-expense-color)" }}>
            Expenses: ({expense.toLocaleString("en-IN")})
          </p>
          <p className='intro' style={{ color: "var(--bar-graph-emis-color)" }}>
            EMIs: {emis == 0 ? emis.toLocaleString("en-IN") : `(${emis.toLocaleString("en-IN")})`}
          </p>
        </div>
      );
    }

    return null;
  };
  console.log("graphData::: ", graphData);
  return (
    <>
      <Col className='d-flex flex-column justify-content-between calculator-modal-top-second-col'>
        <Row className='mt-lg-5 pt-2'>
          <Col lg={5} className={`order-lg-2  ${recommendAmount == 0 ? 'd-flex align-items-center' : null }`}>
          {(recommendAmount !== 0) &&
            <p className='font-size-14 font-weight-medium calculated-screentitle-style mb-2 mb-lg-3'>
              We recommend the <span className='modal-label font-size-14'>Protection Amount</span> to be
            </p>
            }

            <div className='mb-0 mb-lg-3'>

                {recommendAmount === 0 ?  <span className='font-size-20 font-weight-bold '> You are already sufficiently covered</span> : 
              
                <>
              <span className='calculator-rupee font-size-38 font-size-lg-48'>&#8377;</span>
              <span className='font-size-48 font-weight-light modal-label'> {recommendAmount.toLocaleString("en-IN")}</span>
              </>
              }
            </div>

            {(recommendAmount !== 0) && 
            <div className='calculated-value-txt'>
              <p className='calculated-screentitle-style font-size-lg-14 font-size-12 font-weight-lg-medium font-weight-medium'>{`which covers,`}</p>
              <p className='calculated-screentitle-style font-size-16 font-size-lg-14 font-weight-lg-medium font-weight-medium mb-0'>{`the lifestyle expenses for ${
                60 - (moment().year() - birthYear)
              } years of the family`}</p>
              <p className='modal-label font-size-16 font-size-lg-14 font-weight-lg-medium font-weight-medium mb-0'>{`+`}</p>
              <p className='calculated-screentitle-style font-size-16 font-size-lg-14 font-weight-lg-medium font-weight-medium mb-0'>{`Savings that might be accrued`}</p>
              <p className='modal-label font-size-16 font-size-lg-14 font-weight-lg-medium font-weight-medium mb-0'>{`+`}</p>
              <p className='calculated-screentitle-style font-size-16 font-size-lg-14 font-weight-lg-medium font-weight-medium mb-0'>{`Liabilities to be taken care off`}</p>
            </div>
}
          </Col>
          <Col lg={7} className='mt-5 mt-lg-0'>
            <p className='font-size-14 font-weight-medium calculated-screentitle-style'>Considering the below monthly income & expenses profile plus savings growth rate, </p>
            <div className='d-flex justify-content-between justify-content-md-around justify-content-lg-between align-items-center'>
              <div className='d-none d-lg-flex '>
                <Image className='' src={images.BarGraphBorderLeft} alt='left-bracket' />
                <BarChart
                  width={128}
                  height={275}
                  data={graphData.data}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey='name' hide={true} />
                  <Tooltip content={customTooltip} />
                  <ReferenceLine y={0} stroke='#000' />
                  <Bar dataKey='income' fill='var(--bar-graph-income-color)'>
                    {Number(graphData.data[0].income) != 0 && <LabelList className="font-size-11" fill="white" angle="-90" position='insideStart' dataKey='incomeLabel'/>}
                  </Bar>
                  <Bar dataKey='expense' fill='var(--bar-graph-expense-color)'>
                    {Math.abs(graphData.data[0].expense) != 0 && <LabelList className="font-size-11" fill="white" angle="-90" position='insideStart'  dataKey='expensesLabel'/>}
                  </Bar>
                  <Bar dataKey='emis' fill='var(--bar-graph-emis-color)'>
                    {Math.abs(graphData.data[0].emis) != 0 && <LabelList className="font-size-11" fill="white" angle="-90" position='insideStart'  dataKey='emisLabel'/>}
                  </Bar>
                </BarChart>
                <Image className='' src={images.BarGraphBorderRight} alt='right-bracket' />
              </div>
              <div className='d-flex d-lg-none'>
                <Image className='' src={images.BarGraphBorderLeft} alt='left-bracket' />
                <BarChart
                  width={128}
                  height={242}
                  data={graphData.data}
                  margin={{
                    top: 5,
                    right: 5,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <XAxis dataKey='name' hide={true} />
                  <Tooltip />
                  <ReferenceLine y={0} stroke='#000' />
                  <Bar dataKey='income' fill='var(--bar-graph-income-color)' />
                  <Bar dataKey='expense' fill='var(--bar-graph-expense-color)' />
                  <Bar dataKey='emis' fill='var(--bar-graph-emis-color)' />
                </BarChart>
                <Image className='' src={images.BarGraphBorderRight} alt='right-bracket' />
              </div>
              <Image className='d-none d-lg-inline' width='200' src={images.SignalGraph} alt='signal-graph' />
              <Image className='d-inline d-lg-none' width='147' src={images.SignalGraph} alt='signal-graph' />
              <Image className='' src={images.CalculatorRightArrow} alt='right-arrow' />
            </div>
          </Col>
        </Row>
        <div className='button-container'>
          <Row className='mt-3 pt-2 justify-content-end mb-3'>
            <div className='col col-md-3'>
              <button className='btn btn- btn-lg modal-cta-btn modal-cta-btn-link py-1' onClick={() => setShowCalculatedValue(false)}>
                Recalculate
              </button>
            </div>
            <div className='col col-md-3' onClick={() => onUseTheValueClick(recommendAmount)}>
              <button className='btn btn-secondary btn-lg modal-cta-btn py-1'>Use the Value</button>
            </div>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default ProtectionCalculatedValue;
