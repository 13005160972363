import React, { Component } from 'react';
import Dropdown from '../dropdown/Dropdown';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment'
import FamilyDetailsStore from '../../../modules/family-details-form/store/FamilyDetailsStore'

import PropTypes from 'prop-types'

import './datepicker.css'

class DatePicker extends Component {
    constructor(props) {
        super(props)
        this.state = {
            days: [],
            months: [],
            years: [],
            selectedDate: {
                day: '',
                month: '',
                year: ''
            }
        }
    }
    calculateDays = (selectedMonth,currentDate) => {
        let { selectedDate, months } = this.state
        let { month, year } = selectedDate
        month = selectedMonth || month
        if (month) {
            let monthCount = months.indexOf(month)
            
            let days
            if(monthCount === -1){
                let months = moment.monthsShort('-MMM-');
                let InternalMonthCount = months.indexOf(month)
                days = Array.from(Array(moment({ month: InternalMonthCount, year }).daysInMonth()), (_, i) => i + 1)
            }else{
                days = Array.from(Array(moment({ month: monthCount, year }).daysInMonth()), (_, i) => i + 1)
            }

            if (year == moment().year() && monthCount == moment().month()) {
                let day = moment().date()
                days = days.slice(0, day)
            }

            if(this.props.minDate){ 
                // ! setting the minimum day for adult
                let currentYear = moment().year()
                let selectedYear = this.state.selectedDate.year

                let yearDifference = currentYear - selectedYear

                if(yearDifference == 18){
                    let day = moment().date()
                    this.setState({selectedDate: {...this.state.selectedDate,day:day - 1}})
                    days = days.slice(0, day) 

                    let months = moment.monthsShort('-MMM-');
                    let selectedMonth = months.indexOf(this.state.selectedDate.month) 
                    let currentMonth =    moment().month()
                    let selectedYear = this.state.selectedDate.year
                    
                    // console.log('month',selectedMonth,currentMonth);
                 
                    if(selectedMonth != currentMonth && (selectedMonth <= currentMonth)){
                        days = Array.from(Array(moment({ month: selectedMonth, selectedYear }).daysInMonth()), (_, i) => i + 1)
                    }
                }
            }

            if(this.props.maxDate){ 
                // ! setting the minimum day for child
                let currentYear = moment().year()
                let selectedYear = this.state.selectedDate.year
                let selectedMonth = this.state.selectedDate.month

                let yearDifference = currentYear - selectedYear

                if(yearDifference == 18){
                    let day = moment().date()
                    this.setState({selectedDate: {...this.state.selectedDate,day:day - 1}})
                    days = days.slice(day -1 , -1) 

                        let months = moment.monthsShort('-MMM-');
                        let selectedMonth = months.indexOf(this.state.selectedDate.month) 
                        let currentMonth =    moment().month()
                        let selectedYear = this.state.selectedDate.year
                        
                        console.log('month',selectedMonth,currentMonth);
                     
                        if(selectedMonth != currentMonth && (selectedMonth >= currentMonth)){
                            days = Array.from(Array(moment({ month: selectedMonth, selectedYear }).daysInMonth()), (_, i) => i + 1)
                        }
                }                
            }

            // console.log('DAYS >> ', days)
            let selectedDate = this.state.selectedDate
            if (!selectedDate.day || selectedDate.day > days[days.length - 1]) {
                if(currentDate!==-1){
                    selectedDate.day = days[currentDate]
                }else{
                    selectedDate.day = days[0]
                }
                // console.log('SELECTED DAY >> ', selectedDate)
            }
            this.state.selectedDate = selectedDate
            this.setState({
                days,
                selectedDate
            });
            FamilyDetailsStore.dateValue = moment({ date: `${selectedDate.day}`, months: `${monthCount}`, years: `${selectedDate.year}` }).toString()
        }
    }
    calculateMonths = (selectedYear, currentMonth,currentDate) => {
        let year = selectedYear || this.state.selectedDate.year
        if (year) {
            let months = moment.monthsShort('-MMM-');
            if (year == moment().year()) {
                months = months.slice(0, moment().month() + 1)
            }
            let selectedDate = this.state.selectedDate
            if (!selectedDate.month || months.length < 11) {
                if (currentMonth !==-1) {
                    selectedDate.month = months[currentMonth];
                } else {
                    selectedDate.month = months[0]
                }
            }

            if(this.props.minDate){
                let currentYear = moment().year()
                let selectedYear = this.state.selectedDate.year

                let yearDifference = currentYear - selectedYear

                if(yearDifference == 18){
                    months = months.slice(0, moment().month() + 1)
                }

            }

            if(this.props.maxDate){
                let currentYear = moment().year()
                let selectedYear = this.state.selectedDate.year

                let yearDifference = currentYear - selectedYear

                if(yearDifference == 18){
                    months = months.slice(moment().month())
                }
            }

            
            
            
            this.setState({
                months,
                selectedDate
            });
            this.state.months = months
            this.calculateDays(selectedDate.month,currentDate)
        

        }
    }
    calculateYears = () => {
        let { minDate, maxDate } = this.props
        let currentYear = ''
        let lastYear = ''
        let currentMonth = -1;
        let currentDate = -1;
        if (maxDate) {
            lastYear = moment(maxDate).year();
        } else {
            let yearDiff = moment().year()
            lastYear = moment().subtract(yearDiff - 1900, 'years').year();
        }
        if (minDate) {
            console.log('minDate for adult');
            currentYear = moment(minDate).year()
            currentMonth = moment(minDate).month() // get the month of given minDate
            currentDate = moment(minDate).date()-1; // moment return current date but our date array start with index 0 so need to -1 from given date
        } else if(maxDate){
            console.log('maxDate for child');
            currentYear = moment().year()
            currentMonth = moment().month() 
            currentDate = moment().date()-1
        }

        let yearList = []
        while (lastYear <= currentYear) {
            yearList.push(lastYear)
            lastYear++;
        }
        lastYear--;
        // console.log('YEAR >> ', yearList)
        let selectedDate = this.state.selectedDate
        if (selectedDate.year >= yearList[0] && selectedDate.year <= yearList[yearList.length - 1]) {
            lastYear = this.state.selectedDate.year
        }
        this.state.selectedDate.year = lastYear
        if (!selectedDate.year) {
            selectedDate.year = lastYear
        }
        this.setState({
            years: yearList.sort(function (a, b) { return b - a }),
            selectedDate
        })

        this.calculateMonths(lastYear, currentMonth,currentDate)
    }
    onSelect = (key, value) => {
        let selectedDate = this.state.selectedDate
        // console.log('SELECTED DATE .> ', selectedDate)
        switch (key) {
            case 'day':
                selectedDate.day = value
                break;
            case 'month':
                selectedDate.month = value
                this.calculateDays(value)
                break;
            case 'year':
                selectedDate.year = value
                this.calculateMonths(value)
                break;
        }

        this.state.selectedDate = selectedDate
        this.setState({ selectedDate })
        this.onChange()
    }
    onChange = () => {
        const { selectedDate, months } = this.state;
        const { onChange } = this.props;
        if (onChange) {
            const { day, month, year } = selectedDate;
            let monthCount = months.indexOf(month)
            let date = moment({ date: `${day}`, months: `${monthCount}`, years: `${year}` }).toString()
            onChange(date)
        }
    }
    preSelectedDate = () => {
        try {
            let isValid = moment(this.props.date, 'DD-MM-YYYY', true).isValid()
            let momentDate = isValid ? this.props.date : moment(this.props.date)
            let months = moment.monthsShort('-MMM-');
            let date = '';
            let selectedDate = '';

            if (isValid) {
                date = momentDate.split('-')
                selectedDate = {
                    day: date[0],
                    month: months[parseInt(date[1]) - 1],
                    year: date[2]
                }
            } else {
                selectedDate = {
                    day: momentDate.date(),
                    month: months[momentDate.month()],
                    year: momentDate.year()
                }
            }
            this.state.selectedDate = selectedDate
            this.setState({ selectedDate })
        } catch (error) { }
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.date !== prevProps.date) {
            this.preSelectedDate()
        }
        if (this.props.minDate !== prevProps.minDate) {
            this.calculateYears()
        }
    }
    componentDidMount() {
        this.preSelectedDate()
        this.calculateYears()
        this.onChange()
    }
    render() {
        const { days, months, years, selectedDate } = this.state;
        // console.log('MONTHS >> ', months, selectedDate)
        return (
            <div className='datepicker'>
                <Row>
                    <Col>
                        <Dropdown menuList={days} onSelect={(value) => this.onSelect('day', value)} value={selectedDate.day} />
                    </Col>
                    <Col>
                        <Dropdown menuList={months} onSelect={(value) => this.onSelect('month', value)} value={selectedDate.month} />
                    </Col>
                    <Col>
                        <Dropdown menuList={years} onSelect={(value) => this.onSelect('year', value)} value={selectedDate.year} />
                    </Col>
                </Row>
            </div>
        );
    }
}


DatePicker.defaultProp = {
    minDate: null
}

DatePicker.propType = {
    onChange: PropTypes.func,
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    minDate: PropTypes.string,
    maxDate: PropTypes.string
}

export default DatePicker;