// project imports
import axios from "utils/axios"
import * as APITypes from "constants/API"
import SolutionStore from "modules/solution/store/SolutionStore"
import { toJS } from "mobx"
import AuthStore from "modules/login/store/AuthStore"

class Utils {
  fetchDocument = async (solutionList) => {
    const response = await axios.post(APITypes.FETCH_MULTIPLE_DMS_DOCUMENT,
      {
        "solution_id": solutionList,
        "user_journey_id": Number(toJS(AuthStore.journeyId))
      }
    )
    return response.data
  }

  sendSolutionList = async () => {
    const dmsData = toJS(SolutionStore.dmsDocumentData)

    const requestObj = { "solution_list": null }
    const requestBodyArray = dmsData.map(singleData => singleData.solution_id)
    requestObj.solution_list = requestBodyArray
    console.log('dmsData', requestObj);

    const response = await axios.post(APITypes.SEND_PDF, requestObj)
    return response
  }
}

export default new Utils()
