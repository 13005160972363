// Project Imports
import checkIsValueEmpty from "utils/checkIsValueEmpty"


export function chooseNeedFieldValidation(formName) {
  return ({ field, form }) => {

    let currentFormDisableState = form.$(formName).disabled
    let currentFieldValue = field.value

    if (!currentFormDisableState && checkIsValueEmpty(currentFieldValue)) {
      return [false, "This field is mandatory"]
    } else {
      return [true, ""]
    }
  }
}

export function validateTargetAmount(formName) {
  return ({ field, form }) => {

    let currentFormDisabled = form.$(formName).disabled

    if (currentFormDisabled) {
      return [true, ""]
    }

    let isFieldVisible = field.type === 'text'
    let fieldValue = field.value

    if (isFieldVisible && checkIsValueEmpty(fieldValue)) {
      return [false, "This field is mandatory"]
    }

    return [true, ""]

  }
}

export function checkIfOneNeedIsSelected({ form }) {
  let familyProtection = form.$("protection_for_my_family").disabled
  let healthProtection = form.$("protection_for_my_health").disabled
  let moneyNeed = form.$("grow_my_money").disabled
  let childrenNeed = form.$("save_for_children").disabled
  let retirementNeed = form.$("save_for_retirement").disabled

  if (!familyProtection || !healthProtection || !moneyNeed || !childrenNeed || !retirementNeed) {
    return [true, ""]
  } else {
    return [false, "Please select atleast one need"]
  }
}

