import { FamilyIcon, CalenderIcon, IncomeIcon } from "constants/Images";

const FamilyMemberCardHeader = () => {
  return (

    <div className='px-2 py-2'>
      <div className='d-flex flex-wrap'>
        <div className='d-flex align-items-center  justify-content-between family-member-name-section'>
          <div className='d-flex'>
            <img src={FamilyIcon} alt='family-icon' className='add-family-member-card-top-img' />
            <p className='font-size-14 font-size-md-12 font-weight-light text-description-text-color ml-1 mb-0 ml-md-1'>Family Members</p>
          </div>
          <div className='d-none d-xl-flex align-items-center  other-details-section-gender'>
            <div className='d-flex align-items-center'>
              <img src={CalenderIcon} alt='calender-icon' className='add-family-member-card-top-img' />
              <p className='font-size-12 font-weight-light text-description-text-color ml-1 mb-0 ml-md-1'>Gender</p>
            </div>
          </div>
        </div>
        <div className='family-member-other-details-section d-none d-xl-flex'>
          {/* <div className='d-flex align-items-center  other-details-section-gender pr-2'>
            <div className='d-flex align-items-center'>
              <img src={CalenderIcon} alt='calender-icon' className='add-family-member-card-top-img' />
              <p className='font-size-12 font-weight-light text-description-text-color ml-1 mb-0 ml-md-1'>Gender</p>
            </div>
          </div> */}
          <div className='d-flex align-items-center other-details-section-dob pr-xl-2'>
            <div className='d-flex align-items-center'>
              <img src={CalenderIcon} alt='calender-icon' className='add-family-member-card-top-img' />
              <p className='font-size-12 font-weight-light text-description-text-color ml-1 mb-0 ml-md-1'>Date of Birth</p>
            </div>
          </div>

          <div className='d-flex align-items-center other-details-section-nationality pr-xl-2'>
            <div className='d-flex align-items-center'>
              <img src={IncomeIcon} alt='income-icon' className='add-family-member-card-top-img' />
              <p className='font-size-12 font-weight-light text-description-text-color ml-1 mb-0 ml-md-1'>Nationality</p>
            </div>
          </div>

          <div className='d-flex align-items-center other-details-section-monthlyincome pr-xl-2'>
            <div className='d-flex align-items-center'>
              <img src={IncomeIcon} alt='income-icon' className='add-family-member-card-top-img' />
              <p className='font-size-12 font-weight-light text-description-text-color ml-1 mb-0 ml-md-1'>Monthly Income</p>
            </div>
          </div>

          <div className='d-flex align-items-center other-details-section-current-life-cover'>
            <div className='d-flex align-items-center'>
              <img src={IncomeIcon} alt='income-icon' className='add-family-member-card-top-img' />
              <p className='font-size-12 font-weight-light text-description-text-color ml-1 mb-0 ml-md-1'>Current Life Cover</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default FamilyMemberCardHeader