// Project Imports
import moment from "moment";

// Package Imports
import Utils from "./utils";
import { CovidVaccinationStatusList } from 'constants/CovidVaccinationStatusList';

const initialDefaults = (formRef, data) => {
  let requestData = data.data.bi_request;
  let age = requestData.LI_ENTRY_AGE;
  let frequency = requestData.INPUT_MODE;
  let ptValue = requestData.PR_PT;
  let pptValue = requestData.PR_PPT;
  let laEducation = requestData.ZP_EDUCATION;
  let laOccupation = requestData.ZP_OCCUPATION;
  let underwritingStatus = requestData.Underwriting;
  let maritalStatus = requestData.MaritalStatus;
  let betterHalfBenefit = requestData.BetterHalfBenefit;
  let gender = requestData.SpouseGender;
  let planOption = requestData.PlanOption;
  let incomePayoutStartAge = requestData.LiveLongOptionIncomeStartAgeOption;
  let modeOfIncomePayout = requestData.LiveLongOptionIncomePayoutModeOption;
  let incomePayoutPercentage = requestData.LiveLongOptionIncomePayoutPercentOption;
  let childFutureProtectBenefit = requestData.ChildsFutureProtectBenefit;
  let additionalSa = requestData.ChildsFutureProtectBenefitSAPercentOption;
  let deathBenefitOption = requestData.DeathBenefitPayoutOption;
  let payoutPeriod = requestData.PayoutPeriodMonths;
  let payoutOption = requestData.PayoutOptions;
  let isSmoker = requestData.LI_SMOKE;

  let covidVaccinationStatus = CovidVaccinationStatusList.find((item) => item.id === data.data.covid_vaccination_status)
    formRef.$('covid-vaccination-status').set('value', covidVaccinationStatus)

  if (isSmoker === "1") {
    formRef.$("tobacco-user").set("value", {
      id: "1",
      label: "Yes",
    });
  } else {
    formRef.$("tobacco-user").set("value", {
      id: "0",
      label: "No",
    });
  }

  // Initial value of Policy Term
  if (ptValue) {
    // PT List
    let ptList = Utils.calculatePT(age);

    formRef.$("policy-term").set("extra", {
      ...formRef.$("policy-term").extra,
      optionList: ptList,
    });

    // PT Value
    let ptLabel = "";
    if (ptValue === "80 - @Li_entry_age") {
      ptLabel = "Upto Age 80";
    }
    if (ptValue === "85 - @Li_entry_age") {
      ptLabel = "Upto Age 85";
    }
    if (ptValue === "100 - @Li_entry_age") {
      ptLabel = "Upto Age 100";
    }

    if (Number(ptValue) > 10) {
      ptLabel = Number(ptValue);
    }

    formRef.$("policy-term").set("value", {
      id: ptValue,
      label: ptLabel,
    });
  }

  // Initial value of Premium Paying Term
  if (pptValue) {
    // PPT List
    let pptList = Utils.calculatePPT(ptValue, age);
    formRef.$("premium-term").set("extra", {
      ...formRef.$("premium-term").extra,
      optionList: pptList,
    });

    // PPT Value
    let pptLabel = "";
    if (pptValue === "80 - @Li_entry_age") {
      pptLabel = "Upto Age 80";
    } else if (pptValue === "85 - @Li_entry_age") {
      pptLabel = "Upto Age 85";
    } else if (pptValue === "100 - @Li_entry_age") {
      pptLabel = "Upto Age 100";
    } else {
      pptLabel = Number(pptValue);
    }

    formRef.$("premium-term").set("value", {
      id: pptValue,
      label: pptLabel,
    });

    // formRef.$("premium-term").set("disabled", true);

  }

  // Initial value of Premium Paying Frequency
  let frequencyLabel = "";
  switch (frequency) {
    case "1":
      frequencyLabel = "Annual";
      break;

    case "2":
      frequencyLabel = "Semi-Annual";
      break;

    case "3":
      frequencyLabel = "Quarterly";
      break;

    case "4":
      frequencyLabel = "Monthly";

      break;

    default:
      break;
  }
  formRef.$("payment-frequency").set("value", {
    id: frequency,
    label: frequencyLabel,
  });

  // Initial value of LA Annual income
  formRef.$("la-annual-income").set("value", requestData.ZP_ANNINCOME);

  // Initial value of LA Education
  let educationLabel = "";
  switch (Number(laEducation)) {
    case 1:
      educationLabel = "Below 10th";
      break;
    case 2:
      educationLabel = "10th Passed";
      break;
    case 3:
      educationLabel = "12th Passed";
      break;
    case 4:
      educationLabel = "Graduate";
      break;
    case 5:
      educationLabel = "Post Graduate";
      break;
    case 6:
      educationLabel = "Professional";
      break;
    case 7:
      educationLabel = "for Others";
      break;
    case 8:
      educationLabel = "Student";
      break;
    default:
      break;
  }
  formRef.$("la-education").set("value", {
    id: laEducation,
    label: educationLabel,
  });

  // Initial value of LA Occupation
  let occupationLabel = "";
  switch (laOccupation) {
    case "SA":
      occupationLabel = "Salaried";
      break;
    case "SM":
      occupationLabel = "Self Employed (Professional)";
      break;
    case "BM":
      occupationLabel = "Self Employed (Business)";
      break;
    case "A":
      occupationLabel = "Agriculture";
      break;
    case "H":
      occupationLabel = "Housewife";
      break;
    case "R":
      occupationLabel = "Retired";
      break;
    case "L":
      occupationLabel = "Labourer/Worker";
      break;
    default:
      break;
  }
  formRef.$("la-occupation").set("value", {
    id: laOccupation,
    label: occupationLabel,
  });

  // Initial value of Underwriting Status
  let underwritingStatusLabel = "";
  switch (Number(underwritingStatus)) {
    case 1:
      underwritingStatusLabel = "Medical";
      break;
    case 2:
      underwritingStatusLabel = "Non-Medical";
      break;
    default:
      break;
  }
  formRef.$("underwriting-status").set("value", {
    id: underwritingStatus,
    label: underwritingStatusLabel,
  });

  // Initial value of Marital Status
  let maritalStatusLabel = "";
  switch (Number(maritalStatus)) {
    case 1:
      maritalStatusLabel = "Single";
      break;
    case 2:
      maritalStatusLabel = "Married";
      break;
    default:
      break;
  }
  formRef.$("marital-status").set("value", {
    id: maritalStatus,
    label: maritalStatusLabel,
  });

  // Initial value of Better half benefit
  formRef.$("better-half-benefit").set("value", betterHalfBenefit === "14");

  // Initial value of Name of Spouse
  formRef.$("name-of-spouse").set("value", requestData.SpouseName ? requestData.SpouseName : "");

  // Initial value of Age of Spouse
  formRef.$("age-of-spouse").value = requestData.DisplaySpouseDOB;

  // Initial value of Gender of Spouse
  let genderLabel = "";
  switch (Number(gender)) {
    case 18:
      genderLabel = "Male";
      break;
    case 19:
      genderLabel = "Female";
      break;
    default:
      break;
  }
  formRef.$("gender-of-spouse").set("value", {
    id: gender,
    label: genderLabel,
  });

  // Initial value of Plan Option
  let planOptionLabel = "";
  switch (Number(planOption)) {
    case 3:
      planOptionLabel = "Life Cover Option";
      break;
    case 4:
      planOptionLabel = "Return of Premium Option";
      break;
    case 5:
      planOptionLabel = "Live Long Option";
      break;
    default:
      break;
  }
  formRef.$("plan-option").set("value", {
    id: planOption,
    label: planOptionLabel,
  });

  // Initial value of Live long option - Income Payout Start Age
  let startAgeLabel = "";
  switch (Number(incomePayoutStartAge)) {
    case 6:
      startAgeLabel = "60";
      break;
    case 7:
      startAgeLabel = "65";
      break;
    default:
      break;
  }
  formRef.$("income-payout-start-age").set("value", {
    id: incomePayoutStartAge,
    label: startAgeLabel,
  });

  // Initial value of Live long option - Mode of Income pay-out
  let incomePayoutModeLabel = "";
  switch (Number(modeOfIncomePayout)) {
    case 8:
      incomePayoutModeLabel = "Annual";
      break;
    case 9:
      incomePayoutModeLabel = "Semi-Annual";
      break;
    case 10:
      incomePayoutModeLabel = "Quarterly";
      break;
    case 11:
      incomePayoutModeLabel = "Monthly";
      break;
    default:
      break;
  }
  formRef.$("mode-of-income-pay-out").set("value", {
    id: modeOfIncomePayout,
    label: incomePayoutModeLabel,
  });

  // Initial value of Live long option - Payout%
  let incomePayoutPercentageList = Utils.fetchIncomePayoutPercentage(incomePayoutModeLabel);
  formRef.$("income-pay-out").set("extra", {
    ...formRef.$("income-pay-out").extra,
    optionList: incomePayoutPercentageList,
  });

  let incomePayout = {};
  switch (Number(modeOfIncomePayout)) {
    case 8:
      if (Number(incomePayoutPercentage) === 12) {
        incomePayout = { label: "1.25%", id: 12 };
      }

      if (Number(incomePayoutPercentage) === 13) {
        incomePayout = { label: "0.87%", id: 13 };
      }

      break;

    case 9:
      if (Number(incomePayoutPercentage) === 12) {
        incomePayout = { label: "0.61%", id: 12 };
      }

      if (Number(incomePayoutPercentage) === 13) {
        incomePayout = { label: "0.43%", id: 13 };
      }

      break;

    case 10:
      if (Number(incomePayoutPercentage) === 12) {
        incomePayout = { label: "0.30%", id: 12 };
      }

      if (Number(incomePayoutPercentage) === 13) {
        incomePayout = { label: "0.21%", id: 13 };
      }

      break;

    case 11:
      if (Number(incomePayoutPercentage) === 12) {
        incomePayout = { label: "0.10%", id: 12 };
      }

      if (Number(incomePayoutPercentage) === 13) {
        incomePayout = { label: "0.07%", id: 13 };
      }

      break;

    default:
      break;
  }
  formRef.$("income-pay-out").set("value", incomePayout);

  // Initial value of Childs Future Protect Benefit
  let childFutureProtectBenefitValue = false;
  switch (Number(childFutureProtectBenefit)) {
    case 20:
      childFutureProtectBenefitValue = true;
      break;
    case 21:
      childFutureProtectBenefitValue = false;
      break;
    default:
      break;
  }
  formRef.$("childs-future-protect-benefit").set("value", childFutureProtectBenefitValue);

  // Initial value of Child of Life Assured Age
  formRef.$("child-of-life-assured-age").set("value", requestData.ChildDOB ? moment(requestData.ChildDOB).format("DD-MM-YYYY") : "");

  // Initial value of Child Future Protect Benefit - Additional SA (as % of BSA)
  let additionalSaList = Utils.fetchAddionalSaList();
  formRef.$("additional-sa").set("extra", {
    ...formRef.$("additional-sa").extra,
    optionList: additionalSaList,
  });

  let additionalSaValue = additionalSa ? 10 * (Number(additionalSa) - 21) : "";

  formRef.$("additional-sa").set("value", additionalSaValue ? { id: additionalSa, label: additionalSaValue + "%" } : {});

  // Initial value of Death Benefit option
  let deathBenefitLabel = "";
  switch (Number(deathBenefitOption)) {
    case 33:
      deathBenefitLabel = "Lumpsum";
      break;
    case 34:
      deathBenefitLabel = "Monthly Income";
      break;
    case 35:
      deathBenefitLabel = "Lumpsum plus Monthly Income";
      break;
    default:
      break;
  }
  formRef.$("death-benefit-option").set("value", {
    id: deathBenefitOption,
    label: deathBenefitLabel,
  });

  // Initial value of Payout Period(Months)
  let payoutPeriodLabel = "";
  switch (Number(payoutPeriod)) {
    case 37:
      payoutPeriodLabel = "36";
      break;
    case 38:
      payoutPeriodLabel = "60";
      break;
    default:
      break;
  }
  formRef.$("payout-period").set("value", {
    id: payoutPeriod,
    label: payoutPeriodLabel,
  });

  // Initial value of Payout Option (%) - LumpSum
  let payoutOptionList = Utils.payoutOptionPercentage(deathBenefitLabel);
  formRef.$("payout-option").set("extra", {
    ...formRef.$("payout-option").extra,
    optionList: payoutOptionList,
  });

  let payoutOptionValue = {};
  let payoutOptionId = 51;
  switch (deathBenefitLabel) {
    case "Lumpsum":
      payoutOptionValue = { label: "100%", id: 39 };
      break;
    case "Monthly Income":
      payoutOptionValue = { label: "NA", id: 51 };
      break;
    case "Lumpsum plus Monthly Income":
      for (let i = 1; i <= 99; i++) {
        payoutOptionId += 1;
        if (payoutOptionId === payoutOption) {
          payoutOptionValue = { label: i + "%", id: payoutOptionId };
        }
      }
      break;
    default:
      break;
  }
  formRef.$("payout-option").set("value", payoutOptionValue);

  // Income
  formRef.$("income").set("value", requestData.PR_SA);

  let premiumAmount = "";
  switch (frequency) {
    case "1":
      premiumAmount = requestData.PR_ANNPREM;
      break;
    case "2":
      premiumAmount = Number(requestData.PR_ANNPREM) / 2;
      break;
    case "3":
      premiumAmount = Number(requestData.PR_ANNPREM) / 4;
      break;
    case "4":
      premiumAmount = Number(requestData.PR_ANNPREM) / 12;
      break;

    default:
      break;
  }

  formRef.$("premium").set("value", data?.data?.parsedResponse?.MODAL_PREM);

  // Riders
  if (requestData.ADBRider) {
    formRef.$("ADB").set("value", requestData.ADBRider !== "");
    if (requestData.ADBRider) {
      formRef.$("ADB-dropdown").set("value", requestData.ADBRider);
    }
  }

  if (requestData.ATPDRider) {
    formRef.$("ATPDRider").set("value", requestData.ATPDRider !== "");
    if (requestData.ATPDRider) {
      formRef.$("ATPDRider-dropdown").set("value", requestData.ATPDRider);
    }
  }
  if (requestData.WaiverOfPremiumRider) {
    formRef.$("WaiverOfPremiumRider").set("value", requestData.WaiverOfPremiumRider !== "");
  }
  if (requestData.CriticalIllnessRider) {
    formRef.$("CriticalIllnessRider").set("value", requestData.CriticalIllnessRider !== "");
    if (requestData.CriticalIllnessRider) {
      formRef.$("CriticalIllnessRider-dropdown").set("value", requestData.CriticalIllnessRider);
    }
  }
  if (requestData.HospitalityCashBenefitRider) {
    formRef.$("HospitalityCashBenefitRider").set("value", requestData.HospitalityCashBenefitRider !== "");
    if (requestData.HospitalityCashBenefitRider) {
      formRef.$("HospitalityCashBenefitRider-dropdown").set("value", requestData.HospitalityCashBenefitRider);
    }
  }
};

export default initialDefaults;
