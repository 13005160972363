import React, { useState, useEffect } from "react";

import { observer } from "mobx-react";
import { Col, Row } from "react-bootstrap";

import { BottomsheetDropdown, ChangeProductSection } from "components";
import AmountCalculation from "../../optimization-card/amount-calculation/AmountCalculation";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import AdditionalRiderBenifitsCard from "components/ui/additional-rider-benifits-card/AdditionalRiderBenifitsCard";
import Utils from './utils';
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import DeathBenefitAmount from "utils/optimization/deathBenefitAmount";

const SinglePayEndowmentAssurancePlan = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false)

  let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let biResponse = productData.parsedResponse;
  let firstYearResponse = productData.firstYearResponse;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])

  const onHideOverlay = () => {
    setShowOverlay(false)
  }

  return (
    <div className="save-my-children">
      <Row className="align-items-center mt-md-4">
        <Col xs={12} md={12}>
          <ChangeProductSection
            productName={productData.product_name}
            cardName={coverLabelBaseOnNeed}
            changeProductList={data?.data?.changeProductList}
            productData={data}
          />
        </Col>
      </Row>

      <DeathCoverGraphSlider
        productTitle="Single Pay Endowment Assurance Plan"
        openChangeReasonModal={false}
        onIncomeChange={() => { }}
        onPremiumChange={form.$("premium").onChange}
        productID=""
        productData={data?.data}
        income_value=""
        premium_value={form.$("premium").value}
        onClick={() =>
          form
            .$("premium")
            .extra.calculatePremiun(form, data)
        }
        data=""
        cardKey={data.cardKey}
        hideOverLay={onHideOverlay}
        showOverlay={showOverlay}
        isIncomeFieldDisable={form.$("premium").disabled}
        premiumFreq={form.$("payment-frequency").value.label}
        formRef={form}
        secondInputTextlabel="Single Premium"
      />
      <Row className='mt-3 ml-0'>
        <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{"Sum Assured on Death:"}</p>
        <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(productData)}</p>
      </Row>

      <Row className="mt-2">
        <Col md={6} className="col-12 mt-2">
          <div className=" p-3 bg-white">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("policy-term").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("policy-term").label}
              menuList={form.$("policy-term").extra.optionList}
              value={form.$("policy-term").value}
              onSelect={form.$("policy-term").onChange}
              disabled=""
            />
          </div>
        </Col>
        <Col md={6} className="col-12 mt-2">
          <div className=" p-3 bg-white ">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("premium-term").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("premium-term").label}
              menuList={form.$("premium-term").extra.optionList}
              value={form.$("premium-term").value}
              onSelect={form.$("premium-term").onChange}
            />
          </div>
        </Col>
      {/* </Row>

      <Row> */}
        {/* <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("payment-frequency").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("payment-frequency").label}
              menuList={form.$("payment-frequency").extra.optionList}
              value={form.$("payment-frequency").value}
              onSelect={form.$("payment-frequency").onChange}
            />
          </div>
        </Col> */}
        <Col md={6} className="col-12 mt-2">
          <div className=" p-3 bg-white">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("death-benefit-option").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("death-benefit-option").label}
              menuList={form.$("death-benefit-option").extra.optionList}
              value={form.$("death-benefit-option").value}
              onSelect={form.$("death-benefit-option").onChange}
            />
          </div>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={6} className="col-12 mt-4 mt-md-0">
          <AdditionalRiderBenifitsCard
            data={data}
            form={form}
            showCriticalIllness={true}
            showIncomeBenefit={true}
            showHospitalityCashBenefit={false}
            showWOP={false}
            showPWB={false}
          />
        </Col>
      </Row>
      {/* {firstYearResponse && (
        <AmountCalculation
          cardKey={data.cardKey}
          item={productData}
          totalPremium={firstYearResponse.ANN_PREM}
          gst={firstYearResponse.TAX_MP}
          gst_percentage={firstYearResponse.TAX_RATE * 100}
          total_price={Utils.getTotalPrice(firstYearResponse)}
          riderList={biResponse.inputValidationStatus}
          riderTax={Utils.getRiderTax(productData)}
        />
      )} */}
    </div>
  );
};

export default observer(SinglePayEndowmentAssurancePlan);
