// React Imports
import { useContext, useEffect, useState } from "react";

// Package Imports
import { Row, Col } from "react-bootstrap";
import moment from "moment";
import { observer } from "mobx-react";
import { toJS } from "mobx";

// Project Imports
import { DatepickerModal } from "components";
import OptionalDetailsCards from "modules/family-details-form/components/OptionalDetailsCards";
import { submitCalculatorValue } from '../form/retirement-calculator/utils'
import ChooseNeedFormContext from "context/ChooseNeedFormContext";
import RetirementFormController from "../form/retirement-calculator/conroller";
import AuthStore from "modules/login/store/AuthStore";
import FamilyMemberTypeEnum from "modules/family-details-form/FamilymemberTypeEnum";
import OptimizationStore from "modules/optimization/store/OptimizationStore";


const RetirementCalculator = ({
  setShowCalculatedValue, setCalculatedData, hideCalculatorModal,chooseNeedFormController}) => {

  const [formController, setFormController] = useState(undefined)
  const [errorClass, setErrorClass] = useState("")
  const chooseNeedFormContext = useContext(ChooseNeedFormContext)

  useEffect(() => {
    if (formController === undefined) {
    let currentLocation = window.location.pathname
      let formController = new RetirementFormController()
      let familymemberId
      let dob
      let selfFamilymemberId
      let membersExpenseList
      if(OptimizationStore.selectedFamilyMemberId !== undefined  && currentLocation.includes("customization")){
        let selfData = AuthStore.journeyData.family_details_list.find(item => item.family_member_type === FamilyMemberTypeEnum.SELF)

         let selfFamilymemberDetails = AuthStore.journeyData.family_details_list.find(item=>item.id === OptimizationStore.selectedFamilyMemberId)
         dob = selfFamilymemberDetails.dob ||selfData.dob
         familymemberId = selfFamilymemberDetails.id || selfData.id
         membersExpenseList = selfFamilymemberDetails.expense_list.length !== 0 ? selfFamilymemberDetails.expense_list : selfData.expense_list
      }else{
          dob = chooseNeedFormContext ? chooseNeedFormContext.formController.$("protection_for_my_family.dob").value : "",
          familymemberId = AuthStore.journeyData.family_details_list.find(item=>item.family_member_type === FamilyMemberTypeEnum.SELF).id
          membersExpenseList = AuthStore.journeyData.family_details_list.find(item=>item.family_member_type === FamilyMemberTypeEnum.SELF).expense_list
        }

      let expenseList = toJS(AuthStore.expenseList);
      let journeyDetails = toJS(AuthStore.journeyData)
      
      formController.setDefaults(
        expenseList,
        journeyDetails,
        dob,
        familymemberId,
        membersExpenseList
      )
      setFormController(formController)
    }
  }, [])

  let dobOnChangeCallback = () => {};

  if(chooseNeedFormContext !== undefined){
    dobOnChangeCallback = chooseNeedFormContext.formController.$("protection_for_my_family.dob").onChange
  }

  if (formController === undefined) return null

  return (
    <Col className='d-flex flex-column justify-content-between calculator-modal-top-second-col'>
      <Row>
        <Col md={12} className='d-none d-md-block'>
          <Row>
            <Col>
              <p className='calculator-text-Heading'>
                <strong>Enter Details</strong>
                <span className='calculator-text-light'> to Calculate the required</span>
                <strong> Retirement Income</strong>
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={4}>
          <p className='calculator-text-Heading font-size-14 mb-1 mt-1 mt-lg-0'>
            {formController.$("date_of_birth").label}
          </p>

          <DatepickerModal
            dropdownStyle='font-size-12'
            minDate={moment().subtract(18, "years").toString()}
            value={formController.$("date_of_birth").value}
            date={formController.$("date_of_birth").value}
            onChange={(e) => {
              dobOnChangeCallback(e)
              formController.$("date_of_birth").onChange(e)
            }}
            showErrorMsg={formController.$("date_of_birth").error}
            errorMsg={formController.$("date_of_birth").error}
            textStyle='font-size-12 font-weight-light'
          />
        </Col>
        <Col md={12}>
          <Row className='mt-4 mt-lg-3 pt-lg-2'>
            <Col xs={12} md={4}>
              <p className='calculator-text-Heading font-size-14 mb-1'>
                {formController.$("monthly_expense").label}
                <span className={`font-size-11 ${errorClass}`}> - Please fill at least one detail.</span>
              </p>
              <OptionalDetailsCards
                showNoOfYears={false}
                list={formController.$("monthly_expense").get()}
                onChange={(field_name, value) => {
                  formController.$("monthly_expense").$(field_name).value = value
                }}
                toggleDisableOption={(field_name, isDisable) => {
                  formController.$("monthly_expense").$(field_name).set('disabled', isDisable)
                  if (formController.$("monthly_expense").$(field_name).disabled) {
                    formController.$("monthly_expense").$(field_name).value = {}
                  }
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <div className='button-container'>
        <Row className='pt-4 justify-content-end pb-4'>
          <div className='col col-md-3'>
            <button className='btn btn- btn-lg modal-cta-btn modal-cta-btn-link py-1'
              onClick={hideCalculatorModal}
            >
              Cancel
            </button>
          </div>
          <div className='col col-md-3'
            onClick={() => submitCalculatorValue(formController, setShowCalculatedValue, setCalculatedData, setErrorClass,chooseNeedFormController)}
          >
            <button className='btn btn-secondary btn-lg modal-cta-btn py-1' type="submit">Calculate</button>
          </div>
        </Row>
      </div>
    </Col>
  )
}

export default observer(RetirementCalculator);