
class Utils {
  toggleDisabledState = (formRef, fieldName, value) => {
    // ! for Toggling the CheckBox

    formRef.$(fieldName).set("disabled", value)
  }
}

export default new Utils()
