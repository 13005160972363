// React import
import React from "react";

// Package Import
import PropTypes from "prop-types";
import { Image } from "react-bootstrap";

// Project Imports
import * as images from "constants/Images";

const ProductPremium = (props) => {
  const { productPremiumAmount, productPremiumGstPercent, productPremiumGstAmount, multiplier, showRiderPremium } = props;

  return (
    <div className={` ${showRiderPremium ? "total-premium-sub-container-width" : "total-premium-sub-container-width-when-no-rider"}  mb-3 mb-lg-0`}>
      <p className={`text-center font-size-12 font-weight-medium mb-0 total-premium-container-heading py-2 ${productPremiumGstAmount === "" ? " mb-1" : ""}`}>Product Premium</p>
      <div className='d-flex align-items-center justify-content-center'>
        <Image width='12' className='mx-1' src={images.OptimizationSquareBraketLeft} alt='back-arrow' />
        <div className='d-flex align-items-center'>
          <div className=''>
            <p className='text-center font-size-12 font-weight-medium total-premium-text-color mb-0'>Base Premium</p>
            <p className='text-center font-size-20 text-dark font-weight-medium my-0'>{global.toINR(productPremiumAmount)}</p>

            <p className={`text-center font-size-12 font-weight-medium total-premium-text-color my-0 ${productPremiumGstAmount !== "" ? "d-none" : "" } `}>{props.frequencyLabel}</p>
          </div>

          <div className={`${productPremiumGstAmount !== "" ? "" : "d-none"}`}>
            <div className='d-flex flex-column justify-content-between'>
              <p className='font-size-12 font-weight-medium text-dark text-center mx-3 pt-3'>+</p>
              <p className='text-center font-size-12 font-weight-medium total-premium-text-color my-0'>{props.frequencyLabel}</p>
            </div>
          </div>

          <div className={`${productPremiumGstAmount !== "" ? "" : "d-none"}`}>
            <p className='text-center font-size-12 font-weight-medium total-premium-text-color mb-0'>GST @ {productPremiumGstPercent}%</p>
            <p className='text-center font-size-20 text-dark font-weight-medium my-0'>{global.toINR(productPremiumGstAmount)}</p>
          </div>
        </div>

        <Image width='12' className='mx-1' src={images.OptimizationSquareBraketRight} alt='back-arrow' />

        <p className={`font-size-16 font-weight-medium ${multiplier !== "" ? "" : "d-none"}`}>
          {" "}
          <span className='font-size-12'>X</span> {multiplier}
        </p>
      </div>
    </div>
  );
};

ProductPremium.propTypes = {
  productPremiumAmount: PropTypes.number.isRequired,
  productPremiumGstPercent: PropTypes.string,
  productPremiumGstAmount: PropTypes.number,
  multiplier: PropTypes.string,
  showRiderPremium: PropTypes.bool,
};

export default ProductPremium;
