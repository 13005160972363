const { Form } = require("mobx-react-form");

import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import moment from 'moment'

import FamilyDetailsStore from '../../../../family-details-form/store/FamilyDetailsStore';
import setInitialDefaults from './initialDefaults'
import formObserver from './observers'

import Utils from './utils';
import * as keys from "./keys";

class ImmediateAnnuityPlanFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "premium-term",
          value: {},
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            optionList: [
              {
                id: 1,
                label: 1,
              },
            ],
          },
        },
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "5",
                label: "Single",
              },
            ],
          },
        },
        {
          name: "annuity-option",
          label: "Annuity Option",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Life Annuity with Return of Purchase Price",
              },
              {
                id: "2",
                label: "Life Annuity",
              },
              {
                id: "3",
                label: "Life Annuity Certain for 5 years",
              },
              {
                id: "4",
                label: "Life Annuity Certain for 10 years",
              },
              {
                id: "5",
                label: "Life Annuity Certain for 15 years",
              },
              {
                id: "6",
                label: "Life Annuity Certain for 20 years",
              },
              {
                id: "7",
                label: "Life Annuity Increasing at a simple rate of 3% per annum",
              },
              {
                id: "8",
                label: "Life Annuity Increasing at a simple rate of 5% per annum",
              },
              {
                id: "9",
                label: "Joint Life, Last Survivor",
              },
              {
                id: "10",
                label: "Joint Life, Last Survivor with 50% annuity",
              },
              {
                id: "11",
                label: "Joint Life, Last Survivor with Return of Purcahse Price on Last Death",
              },
            ],
          },
        },
        {
          name: "source-of-purchase-price",
          label: "Source of Purchase Price",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "15",
                label: "Proceeds of pension policy of ETLI Co Ltd",
              },
              {
                id: "16",
                label: "Proceeds of pension policy of other Insurer",
              },
              {
                id: "17",
                label: "Open Market",
              },
            ],
          },
        },
        {
          name: "frequency-of-annuity",
          label: "Frequency of Annuity",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "20",
                label: "Yearly",
              },
              {
                id: "21",
                label: "Half-Yearly",
              },
              {
                id: "22",
                label: "Quarterly",
              },
              {
                id: "23",
                label: "Monthly",
              },
            ],
          },
        },
        {
          name: "are-you-nominee",
          label: "Are you the Nominee ?",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "18",
                label: "Yes",
              },
              {
                id: "19",
                label: "No",
              },
            ],
          },
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremiun(form, data) {
              // let value = form.$('premium').value
              // Utils.updateBi(keys.PREMIUM, value, data, form, data)
            },
          },
        },
        {
          name: "name-of-the-secondary-annuitant",
          label: "Name of the Secondary Annuitant",
          value: "",
          placeholder: "",
          disabled: true,
          extra: {
            onBlur(form, data) {
              let productId = data.data.product_id;
              let name = form.$("name-of-the-secondary-annuitant").value
              Utils.updateBi(keys.NAME_OF_THE_SECONDARY_ANNUITANT, name, productId, form, data)
            }
          },
        },
        {
          name: "gender-secondary-annuitant",
          label: "Gender of Secondary Annuitant",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "13",
                label: "Male",
              },
              {
                id: "14",
                label: "Female",
              },
            ],
          },
        },
        {
          name: "dob-of-secondary-annuitant",
          label: "Date of Birth of Secondary Annuitant",
          placeholder: "",
          value: "",
          disabled: true,
          extra: {
            setDOBValue(form, dob, data) {
              let spouseDOB = dob == '' ? FamilyDetailsStore.dateValue : dob
              let value = moment(spouseDOB).format('YYYY MM DD');

              let productId = data.data.product_id;
              form.$("dob-of-secondary-annuitant").set("value", spouseDOB)
              let requestData = {
                annuitantDOB: value,
                dob: spouseDOB
              }

              Utils.updateBi(keys.DATE_OF_BIRTH_SECONDARY_ANNUITANT, requestData, productId, form, data)
            },
          },
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data);
  }
}

export default ImmediateAnnuityPlanFormController;
