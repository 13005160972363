import Utils from "./utils";
import * as keys from "./keys";

const FormObserver = (formRef, props) => {

  formRef.$("policy-term").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(keys.PR_PT, field.value.id, props.data.product_id, formRef, props);
    },
  });

  formRef.$('premium-term').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.PR_PPT, field.value.id.toString(), props.data.product_id, formRef, props)
    }
  })

  formRef.$("tobacco-user").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.LI_SMOKE,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("payment-frequency").observe({
    key: "value",
    call: ({ field }) => {    
      let sum_assured = formRef.$('sum_assured').value
      let frequency = field.value.id
      let data = {
        sum_assured: sum_assured,
        frequency: frequency
      }
      Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)
   
    },
  });

  formRef.$('ADB-dropdown').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.ADB_RIDER, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('ATPDRider-dropdown').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.ATPD_RIDER, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('CriticalIllnessRider-dropdown').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.Critical_ILLNESS_RIDER, field.value.id, props.data.product_id, formRef, props)
    }
  })
};

export default FormObserver;
