// Package Imports
const { Form } = require("mobx-react-form")
import yup from "mobx-react-form/lib/validators/YUP"
import vjf from "mobx-react-form/lib/validators/VJF"
import * as $pkg from "yup"
import validator from "validator"

// Project Imports
import formObserver from "./formObserver"
import setInitialDefaults from "./initialDefaults"

class AddInsuredRetirementFormController extends Form {
  plugins() {
    return {
      vjf: vjf(validator),
      yup: yup({
          package: $pkg,
          schema: (_yup) =>
          _yup.object().shape({
            journey_id: _yup.string().optional(),
            recommendation: _yup.object().shape({
              id: _yup.mixed().required("This field is mandatory")
            }),
            target_amount_dropdown: _yup.object().shape({
              id: _yup.mixed().required("This field is mandatory")
            }),
        }),
      })
    }
  }

  setup() {
    return {
      fields: [
        {
          name: "journey_id",
          type: "hidden",
        },
        {
          name: "recommendation",
          label: "Recommend Me",
          placeholder: "",
          value: {
            id: "2",
            label: "an Income amount",
          },
          extra: {
            optionList: [
            {
              id: "2",
              label: "an Income amount",
            },
            ],
            bottomSheetTitle: "Select an Option",
          },
        },
        {
          name: "target_amount_dropdown",
          label: "Set amount to",
          placeholder: "",
          value: {},
          extra: {
            optionList: [],
            bottomSheetTitle: "Select an Option",
          },
        },
      ],
    }
  }

  setDefaults(journeyData, journeyId) {
    setInitialDefaults(this, journeyData, journeyId)
    // Observer fields
    // formObserver(this)
  }
}

export default AddInsuredRetirementFormController
