// React Imports
import React, { Component } from 'react';

// Package Imports
import { Card } from 'react-bootstrap';
import { observer } from 'mobx-react';

// Project Imports
import { CustomCheckbox, BottomsheetDropdown } from 'components';

// CSS Imports
import './OptionalDetailsCards.css'

const OptionalDetailsCards = observer(class OptionalDetailsCards extends Component {

  onSelect = (value, obj, yearType = null) => {
    const { checkedValues } = this.props
    // console.log('checkedValues',toJS(checkedValues));
    const selectedValues = checkedValues
    if (yearType == 'paid') {
      selectedValues[obj.label_name].selectedPaidYear = value
    }
    else if (yearType == 'total') {
      selectedValues[obj.label_name].selectedTotalYear = value
    }
    else {
      selectedValues[obj.label_name].selectedExpense = value
    }
    this.props.onChange(selectedValues)
  }

  onCheckboxChange = (isChecked, value) => {
    const { checkedValues } = this.props
    const selectedValues = checkedValues
    if (isChecked) {
      selectedValues[value.label_name] = { ...value }
    } else {
      delete selectedValues[value.label_name]
    }
    this.props.onChange(selectedValues)
  }

  render() {

    let list = this.props.list

    return (
      <Card className='border-0  mt-2 w-100 card-custom-css'>
        <Card.Body className='px-0 py-2'>
          {Object.entries(list).map((item, index,) => {
            return (
              <React.Fragment key={index}>
                <div className='px-2 py-1 d-flex justify-content-between align-items-center'>
                  <CustomCheckbox
                    id={item[0]}
                    shape='rounded'
                    title={item[1].label}
                    containerStyle='col-7 mb-0 pl-0 pr-1 d-flex align-items-center'
                    titleStyle='font-weight-light font-size-16 font-size-lg-12 pl-1 details-title-text'
                    isChecked={!item[1].disabled}
                    onChange={() => { this.props.toggleDisableOption(item[0], !item[1].disabled) }}
                    mark='checkmark'
                  />
                   
                  <div className='col-5 pl-1 pr-0'>
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey='label'
                      bottomsheetTitle={item[1].extra.bottomSheetTitle}
                      menuList={item[1].extra.optionList}
                      onSelect={(value) => { this.props.onChange(item[0], value) }}
                      value={item[1].value}
                      disabled={item[1].disabled}
                      showErrorMsg={!item[1].disabled ? item[1].error : ""}
                      errorMsg={!item[1].disabled ? item[1].error : ""}
                    />
                  </div>
                </div>
                {Object.entries(list).length > (index + 1) &&
                  <hr className='hr-margin-top-bottom-8' />
                }
              </React.Fragment>
            )
          })}
        </Card.Body>
      </Card>
    )
  }
});

export default OptionalDetailsCards
