import React, { Component } from 'react';

import { observer } from 'mobx-react';

import { ErrorModal } from '../../components';
import AuthStore from '../login/store/AuthStore';


const AxiosErrorModal = observer(class AxiosErrorModal extends Component {
    constructor(props) {
        super(props)

        this.closeModal = this.closeModal.bind(this);
    }
    closeModal = async () => {
        await AuthStore.closeInvalidJourneyIdModal();
        if (AuthStore.axiosMsg.toLowerCase() == 'not found' && AuthStore.isUserLogin) {
            await this.props.history.push('/choose-needs');
        }
    }

    render() {
        let showErrorModal = AuthStore.axiosMsg?.split(',').join('') === 'No data Found' ? false : AuthStore.axiosMsg !== ''

        return (
            <ErrorModal
                onHideModal={this.closeModal}
                showModal={showErrorModal}
                title={AuthStore.axiosMsg}
                hideResumeSession={true}
            />

        );
    }
});

export default AxiosErrorModal;
