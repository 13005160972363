import React from 'react';


// Package Import
import { Image } from "react-bootstrap"

// Project Imports
import * as images from "constants/Images"

// css Imports
import './premium-and-rider-amount-component.css'

const PremiumAndRiderAmountComponent = (props) => {

  const { premiumType, premiumAmount, premiumGstPercent, premiumGstAmount, showValue, premiumMode, inputMode } = props


  const modeTypeFunction = (inputMode) => {

    let modeType = ''

    switch (parseInt(inputMode)) {

      case 1:
        modeType = 'per annum'
        break;

      case 2:
        modeType = 'per half year'
        break;

      case 3:
        modeType = 'per quarter'
        break;

      case 4:
        modeType = 'per month'
        break;

      default:
        break;
    }

    return modeType
  }

  return (
    <div className='premiumAndRider'>
      <div className="total-premium-sub-container-width mb-3 mb-lg-0">
        <p className={`text-center font-size-12 font-weight-medium mb-0 total-premium-container-heading pt-2 ${premiumGstAmount == "" && premiumGstAmount == 0 ? " mb-1" : ""}`}>
          {premiumType} <span className='font-size-12 font-weight-light'>({modeTypeFunction(inputMode)})</span>
        </p>
        <div className="d-flex align-items-center justify-content-center">
          <Image
            width="12"
            className="mx-1"
            src={images.OptimizationSquareBraketLeft}
            alt="back-arrow"
          />

          {showValue ?
            <div className="d-flex align-items-center">
              <div className="">
                <p className="text-center font-size-12 font-weight-medium total-premium-container-value-label mb-0">
                  Base Premium
                </p>
                <p className="text-center font-size-20 text-dark font-weight-medium my-0">
                  {global.toINR(premiumAmount)}
                </p>
                <p
                  className={`text-center font-size-12 font-weight-medium total-premium-container-value-label my-0 ${premiumGstAmount !== "" && premiumGstAmount !== 0 ? "d-none" : ""
                    } `}
                >
                  {props.frequencyLabel}
                </p>
              </div>

              {premiumGstAmount !== "" && premiumGstAmount !== 0 &&
                <>
                  <div className={`${premiumGstAmount !== "" && premiumGstAmount !== 0 ? "" : "d-none"}`}>
                    <div className="d-flex flex-column justify-content-between">
                      <p className="font-size-12 font-weight-medium text-dark text-center mx-3 pt-3">
                        +
                      </p>
                      <p className="text-center font-size-12 font-weight-medium total-premium-container-value-label my-0">
                        {props.frequencyLabel}
                      </p>
                    </div>
                  </div>

                  <div className={`${premiumGstAmount !== "" && premiumGstAmount !== 0 ? "" : "d-none"}`}>
                    <p className="text-center font-size-12 font-weight-medium total-premium-container-value-label mb-0">
                      GST @ {premiumGstPercent}%
                    </p>
                    <p className="text-center font-size-20 text-dark font-weight-medium my-0">
                      {global.toINR(premiumGstAmount)}
                    </p>
                  </div>
                </>
              }
            </div>
            :
            <div className="d-flex align-items-center">
              <p className='font-size-14 empty-rider text-center align-items-center mb-0'>No Rider Selected</p>
            </div>
          }
          <Image
            width="12"
            className="mx-1"
            src={images.OptimizationSquareBraketRight}
            alt="back-arrow"
          />
          {(inputMode === 4 && showValue) &&
            <p className={`font-size-16 font-weight-medium`}>
              {" "}
              <span className='font-size-12'>X</span> {2}
            </p>}
        </div>
      </div>
    </div>
  )
}

export default PremiumAndRiderAmountComponent