import OptimizationStore from 'modules/optimization/store/OptimizationStore';
import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    validatePremium = (form, data) => {
        // let premium = form.$('premium').value

        // if (premium < 2000) {
        //     this.errorMessage.push(errorMessageKeys.minimum_premium)
        // }
    }

    // validate PT and PPT
    validatePtPPt = (formRef) => {
        // let pt = formRef.$('policy-term').value.id.toString();
        // let ppt = formRef.$('premium-paying-term').value.id.toString();

        // if (pt !== ppt) {
        //     this.errorMessage.push(errorMessageKeys.pt_ppt);
        // }
    }
}
export default new Validator();