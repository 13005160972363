
// React Imports
import { NUMBER_REGEX } from 'constants/Constants';
import React,{useRef} from 'react';

// Package Imports
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

// CSS Imports
require('normalize.css/normalize.css');
require('styles/App.css');

const TypeHead = (props) => {

  const inputFieldRef = useRef();


  const filterBy = () => true;

  return (
    <AsyncTypeahead
      filterBy={filterBy}
      id="async-type-head"
      isLoading={false}
      labelKey="label"
      ref={inputFieldRef}
      minLength={props.minLength}
      onSearch={props.onSearch}
      options={props.menuList}
      placeholder={props.placeholder}
      onInputChange={(value) => {
        if(!NUMBER_REGEX.test(value)){
          inputFieldRef.current.clear()
        }
      }}
      className='textForm font-size-12 font-weight-light w-100 border-0'
      {...props}
    />
  );

};
export default TypeHead;
