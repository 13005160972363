// React Imports
import React from 'react';

// Package Imports
import { Card } from 'react-bootstrap';

// Project Imports
import { CustomCheckbox, BottomsheetDropdown } from 'components';

// CSS Imports
import './OptionalDetailsCards.css'

const EMIOptionalDetailsCards = (props) => {

  const { list } = props;

  return (
    <Card className='border-0  mt-2 w-100 card-custom-css'>
      <Card.Body className='px-0 py-2'>
        {Object.entries(list).map((item, index) => {

          return (
            <React.Fragment key={index}>
              <div className='px-2 pt-1 d-flex justify-content-between align-items-center'>
                <CustomCheckbox
                  id={item[0]}
                  shape='rounded'
                  title={item[1].label}
                  containerStyle='col-7 mb-0 pl-0 pr-1 d-flex align-items-center'
                  titleStyle='font-weight-light font-size-16 font-size-lg-12 pl-1 details-title-text'
                  isChecked={!item[1].disabled}
                  onChange={() => { props.toggleDisableOption(item[0], !item[1].disabled) }}
                  mark='checkmark'
                />
                <div className='col-5 pl-1 pr-0'>
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey='label'
                    bottomsheetTitle='Select an Option'
                    menuList={item[1].fields['option_range'].extra.optionList}
                    onSelect={(value) => { props.onChange(item[0], 'option_range', value) }}
                    value={item[1].fields['option_range'].value}
                    disabled={item[1].disabled}
                    showErrorMsg={item[1].fields['option_range'].error !== null}
                    errorMsg={item[1].fields['option_range'].error}
                  />
                </div>
              </div>
              <div className='px-2 d-flex justify-content-end align-items-center mt-1'>
                <p className='font-weight-300 font-size-12 ml-2 mb-0'>Paid</p>
                <div className='col-3 pl-1 pr-0 yrs-dropdown-col'>
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey='label'
                    bottomsheetTitle='Paid Years'
                    defaultLabel="Yrs"
                    menuList={item[1].fields['paid_years'].extra.optionList}
                    onSelect={(value) => { props.onChange(item[0], 'paid_years', value) }}
                    value={item[1].fields['paid_years'].value}
                    disabled={item[1].disabled}
                    showErrorMsg={item[1].fields['paid_years'].error !== null}
                    errorMsg={item[1].fields['paid_years'].error}
                  />
                </div>
                <p className='font-weight-300 font-size-12 pl-2 mb-0'>out of Total</p>
                <div className='col-3 pl-1 pr-0 yrs-dropdown-col'>
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey='label'
                    defaultLabel="Yrs"
                    bottomsheetTitle='Total Years'
                    menuList={item[1].fields['total_years'].extra.optionList}
                    onSelect={(value) => { props.onChange(item[0], 'total_years', value) }}
                    value={item[1].fields['total_years'].value}
                    disabled={item[1].disabled}
                    showErrorMsg={item[1].fields['total_years'].error !== null}
                    errorMsg={item[1].fields['total_years'].error}
                  />
                </div>
              </div>
              {Object.entries(list).length > (index + 1) &&
                <hr className='hr-margin-top-bottom-8' />
              }
            </React.Fragment>
          )
        })}
      </Card.Body>
    </Card>
  )
}


export default EMIOptionalDetailsCards
