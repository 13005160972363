import React, { Component } from "react"
import { Col, Row, Card } from "react-bootstrap"

import { CustomCheckbox, BottomsheetDropdown } from "../../../../../components"
import { InfoCircleIcon } from "constants/Images"

import "./additional-cards.css"

class AdditionalCards extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAdditionalBenefitModalOpen: false,
      modalTitle: "",
      modalDescription: "",
    }
  }

  showBenefitModal = (title, description) => {
    this.setState({
      isAdditionalBenefitModalOpen: true,
      modalTitle: title,
      modalDescription: description,
    })
  }

  hideBenefitModal = () => {
    this.setState({ isAdditionalBenefitModalOpen: false })
  }

  onCheckBoxChange = (checkedValue, selectedObj, isDisabled) => {
    if (!isDisabled) {
      this.props.onClick(checkedValue, selectedObj, this.props.data)
    }
  }

  isBetterHalfEnabled = () => {
    // let isMarried = this.props.data.
    // if ()
  }

  render() {
    const { cardsList, cardTopText, onAmountChange } = this.props
    // console.log('ADDITIONAL CARDS RENDER >> ', cardsList, (cardsList && cardsList.length > 0))
    return (
      <>
        <div className="additional-cards">
          <p className="mb-3 font-size-16 font-size-md-22 font-size-lg-24 font-weight-bold text-primary-title-label-color card-top-text">
            {cardTopText}
          </p>
          <Card className="border-0 shadow">
            <Card.Body>
              {cardsList &&
                cardsList.length > 0 &&
                cardsList.map((value, index) => {
                  // console.log('value.checkedValue', value, value.is_selected)

                  console.log("value.description", value.description)
                  return (
                    <Row
                      key={index}
                      className={`${index > 0 ? "mt-3" : ""} ${
                        value.isDisabled ? "disable-card" : ""
                      }`}
                    >
                      <Col className="col-8">
                        <div className="d-flex align-items-center">
                          <CustomCheckbox
                            shape="rounded"
                            title={value.title}
                            titleStyle="text-primary-title-label-color font-weight-normal card-top-title font-size-16 font-size-md-20 pl-2"
                            isChecked={value.is_selected}
                            onChange={(checked) =>
                              this.onCheckBoxChange(
                                checked,
                                value,
                                value.isDisabled
                              )
                            }
                            mark="checkmark"
                          />
                          <img
                            className={`mb-2 pl-2 ${
                              value.isDisabled ? "inf0-icon-disable" : ""
                            }`}
                            src={InfoCircleIcon}
                            alt="info"
                            onClick={() =>
                              this.showBenefitModal(
                                value.title,
                                value.description
                              )
                            }
                          />
                        </div>
                        {value.is_selected && value.list_data && (
                          <BottomsheetDropdown
                            dropdownStyle="font-size-lg-12 font-size-14 font-weight-light"
                            menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                            menuItemStyle="d-flex justify-content-between align-items-center"
                            selectedLabelKey="label"
                            bottomsheetTitle=""
                            menuList={value.list_data}
                            value={
                              value.selected_value ? value.selected_value : {}
                            }
                            onSelect={(value) => onAmountChange(value, index)}
                          />
                        )}
                      </Col>
                      <Col className="align-items-center col-4 col-border-left d-flex flex-column justify-content-center col-line-height">
                        {value.sum_assured != "" ? (
                          <React.Fragment>
                            <div className="d-flex justify-content-end">
                              <p className="mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary">
                                {" "}
                                {value.sum_assured == "-"
                                  ? value.sum_assured
                                  : global.toINR(value.sum_assured)}
                              </p>
                            </div>
                          </React.Fragment>
                        ) : null}
                      </Col>
                    </Row>
                  )
                })}
            </Card.Body>
          </Card>
        </div>

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          className="rider-info-modal"
          show={this.state.isAdditionalBenefitModalOpen}
          keyboard={false}
        >
          <Modal.Body className="text-center text-primary-title-label-color px-4 pt-4 pb-0">
            <React.Fragment>
              <h3 className="font-weight-medium font-size-22 font-size-md-32 mb-3">
                {this.state.modalTitle}
              </h3>
              <p className="font-weight-normal font-size-20 font-size-md-20 mb-0">
                {this.state.modalDescription}
              </p>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer className="d-block p-4 border-0">
            <Row className="modal-footer-row">
              <React.Fragment>
                <Col xs="12">
                  <UiButton
                    onClick={this.hideBenefitModal}
                    className="font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0"
                    variant="secondary"
                    buttonText="OKAY"
                  />
                </Col>
              </React.Fragment>
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default AdditionalCards
