import setFundAllocation from 'utils/setFundAllocation';
import Utils from './utils';

const initialDefaults = (formRef, data) => {
    let productData = data.data
    let biRequest = productData.bi_request;

    let frequency = biRequest.INPUT_MODE
    let fundStrategy = biRequest.FUNDSTRATEGYID
    let annualPremium = biRequest.PR_ANNPREM
    let policyOption = biRequest.LifeStageVariant
    let systematicWithdrawalOption = biRequest.SystematicWithdrawalOption
    let swpFrequency = biRequest.SWPFrequency
    let swpPolicyYearPayable = biRequest.PolicyYearfromwhichSWPpayable
    let swpPolicyYear = [];
    let fundList = formRef.$("fund-strategy").extra.fundList;
    let updatedFundList = [];

    let premiumAmount = ''
    switch (frequency) {
        case '1':
            premiumAmount = annualPremium;
            break;
        case '2':
            premiumAmount = Number(annualPremium) / 2;
            break;
        case '3':
            premiumAmount = Number(annualPremium) / 4;
            break;
        case '4':
            premiumAmount = Number(annualPremium) / 12;
            break;

        default:
            break;
    }
    formRef.$('premium').set('value', premiumAmount)

    let ptList = Utils.getPolicyTerm(biRequest.LI_ENTRY_AGE)
    formRef.$('policy-term').set('extra', {
        ...formRef.$('policy-term').extra,
        optionList: ptList
    })

    swpPolicyYear = Utils.getSWPPolicyYear()

    let year = swpPolicyYear.filter((item) => item.id == Number(swpPolicyYearPayable))[0]
    formRef.$('year-swp-payable').set('value', year)

    formRef.$('year-swp-payable').set('extra', {
        ...formRef.$('year-swp-payable').extra,
        optionList: swpPolicyYear
    })

    formRef.$('policy-term').value = {
        id: biRequest.PR_PT,
        label: biRequest.PR_PT == 100 - Number(biRequest.LI_ENTRY_AGE) ? 'Whole Life' : biRequest.PR_PT
    }

    let pptList = Utils.getPremiumTermList(formRef.$('policy-term').value, biRequest.LI_ENTRY_AGE)


    formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        optionList: pptList
    })

    formRef.$('premium-term').value = {
        id: biRequest.PR_PPT,
        label: biRequest.PR_PPT
    }

    let withdrawalYearList = Utils.populateWithdrawlPercentage()
    formRef.$('fund-value').set('extra', {
        ...formRef.$('fund-value').extra,
        optionList: withdrawalYearList
    })

    let withdrawalYear = withdrawalYearList.filter((item) => Number(biRequest.FundValuetobeWithdrawn) == item.id)[0]
    formRef.$('fund-value').set('value', withdrawalYear)

    switch (fundStrategy) {
        case '1':
            formRef.$('fund-strategy').set('value', {
                id: '1',
                label: 'Self-managed Strategy'
            })
            break;
        case '2':
            formRef.$('fund-strategy').set('value', {
                id: '2',
                label: 'Life stage and duration based Strategy'
            })
            break;

        default:
            break;
    }
    switch (policyOption) {
        case '1':
            formRef.$('policy-option').set('value', {
                id: '1',
                label: 'Base'
            })
            break;
        case '2':
            formRef.$('policy-option').set('value', {
                id: '2',
                label: 'Life Partner'
            })
            break;
        case '133':
            formRef.$('policy-option').set('value', {
                id: '133',
                label: 'Child (Individual Life)'
            })
            break;
        case '134':
            formRef.$('policy-option').set('value', {
                id: '134',
                label: 'Child (Joint Life)'
            })
            break;

        default:
            break;
    }

    switch (systematicWithdrawalOption) {
        case '5':
            formRef.$('systematic-withdrawal-option').set('value', {
                id: '5',
                label: 'Yes'
            })
            break;
        case '6':
            formRef.$('systematic-withdrawal-option').set('value', {
                id: '6',
                label: 'No'
            })
            break;
        default:
            break;
    }

    switch (frequency) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '2':
            formRef.$('payment-frequency').set('value', {
                id: '2',
                label: 'Half-Yearly'
            })
            break;
        case '3':
            formRef.$('payment-frequency').set('value', {
                id: '3',
                label: 'Quarterly'
            })
            break;
        case '4':
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }
    switch (swpFrequency) {
        case '117':
            formRef.$('swp-frequency').set('value', {
                id: '117',
                label: 'Annual'
            })
            break;
        case '118':
            formRef.$('swp-frequency').set('value', {
                id: '118',
                label: 'Semi-Annual'
            })
            break;
        case '119':
            formRef.$('swp-frequency').set('value', {
                id: '119',
                label: 'Quarterly'
            })
            break;
        case '120':
            formRef.$('swp-frequency').set('value', {
                id: '120',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }

    updatedFundList = setFundAllocation(fundList, biRequest);
    formRef.$("fund-strategy").set('extra', {
        ...formRef.$('fund-strategy').extra,
        showFundDistributionModal: false,
        fundList: updatedFundList
    })

    formRef.$('spouse-name').value = biRequest.SpouseName;

    switch (biRequest.SpouGender) {
        case '135':
            formRef.$('spouse-gender').set('value', {
                id: '135',
                label: 'Male',
                key: 'M'
            })
            break;
        case '136':
            formRef.$('spouse-gender').set('value', {
                id: '136',
                label: 'Female',
                key:'F'
            })
            break;


        default:
            break;
    }

    formRef.$('spouse-dob').value = biRequest.OSNSpouseDateOfBirth;

}

export default initialDefaults
