// Project Imports
import checkIsValueEmpty from "./checkIsValueEmpty"

const fetchDeselectedExpensesForEmi = (formController, expenseName) => {
    let deSelectedExpenseList = []

    let expense = formController.$("monthly_emi").default
    for (const expenseName in expense) {
        let isInitialEmpty = checkIsValueEmpty(expense[expenseName]['option_range'])

        if (!isInitialEmpty) {
            let isDisabled = formController.$("monthly_emi").$(expenseName).disabled

            if (isDisabled) {
                deSelectedExpenseList.push(expense[expenseName])
            }
        }
    }

    return deSelectedExpenseList.map((item) => item.option_range.journeyExpenseId)

}

export default fetchDeselectedExpensesForEmi