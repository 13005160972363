const calculateRiderPremium = (inputValidationStatus) => {
  let totalCalculatedValue = 0;
  let totalGstAmount = 0;

  if (inputValidationStatus === undefined) return totalCalculatedValue;

  inputValidationStatus.forEach((item) => {
    totalCalculatedValue += Math.round(item.ModalPremium);
    totalGstAmount += Math.round(item.Tax);
  });

  return { totalCalculatedValue, totalGstAmount };
};

export default calculateRiderPremium;
