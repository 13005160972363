import OptimizationStore from "../../../store/OptimizationStore"
import * as keys from "./keys"
import Validator from "./validator"
import calculateRiderPremium from "utils/calculateRiderPremium"
import calculateProductPremiumRelatedValues from "utils/calculateProductPremiumRelatedValues"
import isObjectEmpty from "utils/isObjectEmpty"
import ProductConstants from 'constants/ProductConstants';
import * as Constants from "constants/Constants"

class Utils {

  ptLogicBasedOnProduct = (formRef, productName) => {
    let IncomeStartOption = formRef.$("income-start-option").value
    let IncomeDuration = formRef.$("income-duration").value
    if (
      productName === ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB ||
      productName === ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED ||
      productName === ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS
    ) {

      if (!isObjectEmpty(IncomeStartOption) && !isObjectEmpty(IncomeDuration)) {
        let value = parseInt(IncomeStartOption.label) + parseInt(IncomeDuration.label) - 1
        formRef.$("PT").set("disabled", true)
        formRef.$("PT").set("value", { id: value, label: value })
      }
    }

    if (
      productName === ProductConstants.GUARANTEED_INCOME_STAR ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_FIB ||
      productName === ProductConstants.POS_GUARANTEED_INCOME_STAR) {
      if (!isObjectEmpty(IncomeStartOption) && !isObjectEmpty(IncomeDuration)) {
        formRef.$("PT").set("disabled", true)
        let incomeOption = formRef.$('income-option').value.id

        if (Number(incomeOption) === 6) {
          formRef.$("PT").set("value", { id: "40", label: "40" })
        } else {
          let pt = (Number(IncomeStartOption.label) + Number(IncomeDuration.label)) - 1
          formRef.$("PT").set("value", { id: pt, label: pt })
        }

      }
    }

    if (productName === ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED) {
      formRef.$("PT").set("disabled", true)
      formRef.$("PT").set("value", { label: "40", id: "40" })
    }

    return '0'
  }

  incomeStartOptionLogicBasedOnPpt = (formRef, productName) => {

    const pptValue = formRef.$("PPT").value.id
    let incomeOption = formRef.$("income-option").value.id.toString()

    if (productName === ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_FIB) {

      switch (pptValue) {

        case '5':
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '5', id: '8' },
              { label: '6', id: '9' },
              { label: '7', id: '10' },
              { label: '8', id: '11' },
              { label: '15', id: '18' }
            ],
          })
          formRef.$("income-start-option").set("value", { label: '5', id: '8' })
          break;

        case '8':
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' },
              { label: '9', id: '12' },
              { label: '10', id: '13' },
              { label: '11', id: '14' },
              { label: '18', id: '19' }
            ],
          })
          formRef.$("income-start-option").set("value", { label: '2', id: '7' })
          break;

        case '10':
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' },
              { label: '11', id: '14' },
              { label: '12', id: '15' },
              { label: '13', id: '16' },
              { label: '20', id: '20' }
            ],
          })
          formRef.$("income-start-option").set("value", { label: '2', id: '7' })
          break;

        case '12':
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' },
              { label: '13', id: '16' },
              { label: '14', id: '17' },
              { label: '15', id: '18' },
              { label: '22', id: '21' }
            ],
          })
          formRef.$("income-start-option").set("value", { label: '2', id: '7' })
          break;
        default:
          break;
      }
      if (incomeOption === '6') {
        if (pptValue === '12') {
          formRef.$('income-start-option').set('value', { label: '15', id: '18' })
        } else  {
          formRef.$('income-start-option').set('value', { label: '10', id: '13' })
        }
      }
    }

    if (productName === ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED) {

      if (pptValue === '5') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '5', id: '8' },
            { label: '6', id: '9' },
            { label: '7', id: '10' },
            { label: '8', id: '11' },
            { label: '15', id: '18' }
          ],
        })
        formRef.$("income-start-option").set("value", { label: '5', id: '8' })
      }

      if (pptValue === '8') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '9', id: '12' },
            { label: '10', id: '13' },
            { label: '11', id: '14' }
          ],
        })
        formRef.$("income-start-option").set("value", { label: '2', id: '7' })
      }

      if (pptValue === '10') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '11', id: '14' }
          ],
        })
        formRef.$("income-start-option").set("value", { label: '2', id: '7' })
      }

      if (pptValue === '12') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
          ],
        })
        formRef.$("income-start-option").set("value", { label: '2', id: '7' })
      }
    }

    if (productName === ProductConstants.POS_GUARANTEED_INCOME_STAR) {


      if (incomeOption === '5') {

        if (pptValue === '5') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '5', id: '8' },
              { label: '6', id: '9' },
              { label: '7', id: '10' },
              { label: '8', id: '11' },
              { label: '15', id: '18' }
            ],
          })
          formRef.$("income-start-option").set("value", { label: '5', id: '8' })
        }

        if (pptValue === '8') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' },
              { label: '9', id: '12' },
              { label: '10', id: '13' },
              { label: '11', id: '14' }
            ],
          })
          formRef.$("income-start-option").set("value", { label: '2', id: '7' })
        }

        if (pptValue === '10') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' },
              { label: '11', id: '14' }
            ],
          })
          formRef.$("income-start-option").set("value", { label: '2', id: '7' })
        }

        if (pptValue === '12') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' }
            ],
          })
          formRef.$("income-start-option").set("value", { label: '2', id: '7' })
        }
      }
    }

    if (productName === ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS) {

      if (pptValue === '8') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '9', id: '12' },
            { label: '10', id: '13' },
            { label: '11', id: '14' }
          ],
        })
        formRef.$("income-start-option").set("value", { label: '2', id: '7' })
      }

      if (pptValue === '10') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '11', id: '14' }
          ],
        })
        formRef.$("income-start-option").set("value", { label: '2', id: '7' })
      }

      if (pptValue === '12') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' }
          ],
        })
        formRef.$("income-start-option").set("value", { label: '2', id: '7' })
      }

    }

  }

  incomeDurationDropDownLogicBasedOnLumpsumOptionAndIncomeStartOption = (formRef,pptValue,productName,LumpsumBenefit,incomeStartOption,props) => {

    // incomeStartOption : 7 => 2, 8 => 5
    //lumpsunBenefit : 22 => yes, 23 => no

    const pptArray = new Set(['8','10','12'])


    if(productName === ProductConstants.GUARANTEED_INCOME_STAR){

        if(pptArray.has(pptValue)){

          if(LumpsumBenefit === '22' && (incomeStartOption === '7' || incomeStartOption === '8')){

            formRef.$("income-duration").set("extra", {
              ...formRef.$("income-duration").extra,
              optionList: [
                { label: '15', id: '25' },
                { label: '20', id: '26' },
                { label: '25', id: '27' },
                { label: '30', id: '28' },
                { label: '35', id: '29' }
      
              ],
            })
      
            let incomeDurationValue = formRef.$("income-duration").value.id
      
            if(incomeDurationValue === '24'){ // if value is 10
              formRef.$("income-duration").set("value", { label: '35', id: '29' })
              this.updateBi(keys.INCOME_DURATION, '29', props.data.product_id, formRef, props)
            }

          }
          
          if (LumpsumBenefit === '23' && incomeStartOption === '7'){

            formRef.$("income-duration").set("extra", {
              ...formRef.$("income-duration").extra,
              optionList: [
                { label: '20', id: '26' },
                { label: '25', id: '27' },
                { label: '30', id: '28' },
                { label: '35', id: '29' }
      
              ],
            })

            let incomeDurationValue = formRef.$("income-duration").value.id
      
            if(incomeDurationValue === '24' || incomeDurationValue === '25'){ // if value is 10 || 15
              formRef.$("income-duration").set("value", { label: '35', id: '29' })
              this.updateBi(keys.INCOME_DURATION, '29', props.data.product_id, formRef, props)
            }
          }
        }
    }

    // if(productName === ProductConstants.GUARANTEED_INCOME_STAR && LumpsumBenefit === '22' && incomeStartOption === '7'){
    //   formRef.$("income-duration").set("extra", {
    //     ...formRef.$("income-duration").extra,
    //     optionList: [
    //       { label: '15', id: '25' },
    //       { label: '20', id: '26' },
    //       { label: '25', id: '27' },
    //       { label: '30', id: '28' },
    //       { label: '35', id: '29' }

    //     ],
    //   })

    //   let incomeDurationValue = formRef.$("income-duration").value.id

    //   if(incomeDurationValue === '24'){ // if value is 10
    //     formRef.$("income-duration").set("value", { label: '35', id: '29' })
    //     this.updateBi(keys.INCOME_DURATION, '29', props.data.product_id, formRef, props)
    //   }

    // }

  }

  incomeDurationLogicBasedOnPpt = (formRef, productName,props) => {

    const pptValue = formRef.$("PPT").value.id

    if (productName === ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB) {

      formRef.$("income-duration").set("extra", {
        ...formRef.$("income-duration").extra,
        optionList: [
          { label: '2', id: '7' },
          { label: '5', id: '8' },
          { label: '9', id: '12' },
          { label: '10', id: '13' },
          { label: '11', id: '14' },
          { label: '18', id: '19' }
        ],
      })
    }

    if (productName === ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED) {


      if (pptValue === '5') {
        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '15', id: '25' },
            { label: '20', id: '26' },
            { label: '25', id: '27' },
            { label: '30', id: '28' },
            { label: '35', id: '29' }
          ],
        })
      } else {
        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '10', id: '24' },
            { label: '15', id: '25' },
            { label: '20', id: '26' },
            { label: '25', id: '27' },
            { label: '30', id: '28' },
            { label: '35', id: '29' }
          ],
        })
      }

    }

    if (productName === ProductConstants.GUARANTEED_INCOME_STAR ||
      productName === ProductConstants.GUARANTEED_INCOME_STAR_FIB) {

        let incomeOption = formRef.$('income-option')?.value.id?.toString() 

      if (incomeOption === '6') {
        formRef.$("income-duration").set("value", '')

        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          isVisible: false
        })
      }

      if (pptValue === '5') {
        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '15', id: '25' },
            { label: '20', id: '26' },
            { label: '25', id: '27' },
            { label: '30', id: '28' },
            { label: '35', id: '29' }
          ],
        })
      } else {
        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '10', id: '24' },
            { label: '15', id: '25' },
            { label: '20', id: '26' },
            { label: '25', id: '27' },
            { label: '30', id: '28' },
            { label: '35', id: '29' }
          ],
        })
      }

      let LumpsumBenefit = formRef.$("lump-sum-benefit").value.id.toString()
      let incomeStartOption = formRef.$("income-start-option").value.id.toString()

      this.incomeDurationDropDownLogicBasedOnLumpsumOptionAndIncomeStartOption(formRef,pptValue,productName,LumpsumBenefit,incomeStartOption,props)
    }

    if (productName === ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED) {

      formRef.$("income-duration").set("value", '')

      formRef.$("income-duration").set("extra", {
        ...formRef.$("income-duration").extra,
        isVisible: false
      })
    }

    if (productName === ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED) {

      let IncomeStartOption = formRef.$("income-start-option").value

      if (pptValue === '5') {
        formRef.$("income-duration").set("value", { label: '15', id: '25' })
        formRef.$("income-duration").set("disabled", true)
      }

      if ((pptValue === '8' || pptValue === '10' || pptValue === '12') && IncomeStartOption === '2') {
        formRef.$("income-duration").set("value", { label: '15', id: '25' })
        formRef.$("income-duration").set("disabled", true)
      }

      if ((pptValue === '8' || pptValue === '10') && (IncomeStartOption === '10' || IncomeStartOption === '11')) {
        formRef.$("income-duration").set("value", { label: '10', id: '24' })
        formRef.$("income-duration").set("disabled", true)
      }
    }

    if (productName === ProductConstants.POS_GUARANTEED_INCOME_STAR) {

      if (pptValue === '5') {
        formRef.$("income-duration").set("value", { label: '15', id: '25' })
        formRef.$("income-duration").set("disabled", true)
      }

      if ((pptValue === '8' || pptValue === '10' || pptValue === '12') && IncomeStartOption === '2') {
        formRef.$("income-duration").set("value", { label: '15', id: '25' })
        formRef.$("income-duration").set("disabled", true)
      }

      if ((pptValue === '8' || pptValue === '10' || pptValue === '12') && (IncomeStartOption === '5' || IncomeStartOption === '9')) {
        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '10', id: '24' },
            { label: '15', id: '25' }]
        })
      }

      if ((pptValue === '8' || pptValue === '10') && (IncomeStartOption === '10' || IncomeStartOption === '11')) {
        formRef.$("income-duration").set("value", { label: '10', id: '24' })
        formRef.$("income-duration").set("disabled", true)
      }

    }

    if (productName === ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS) {

      formRef.$("income-duration").set("extra", {
        ...formRef.$("income-duration").extra,
        optionList: [
          { label: '10', id: '24' },
          { label: '15', id: '25' },
          { label: '20', id: '26' },
          { label: '25', id: '27' },
          { label: '30', id: '28' },
          { label: '35', id: '29' }
        ]
      })
    }
  }


  incomeBenefitPayOutfrequencyBasedOnIncomeOption = (formRef, productName) => {
    
    let incomeOptionValue = formRef.$("income-option").value.id.toString()

    if (productName === ProductConstants.GUARANTEED_INCOME_STAR || productName === ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED || productName === ProductConstants.GUARANTEED_INCOME_STAR_FIB) {

      if (incomeOptionValue === '6') {
        formRef.$("income-benefit-pay-out-frequency").set('value', { label: 'Annual', id: '32' })
        formRef.$("income-benefit-pay-out-frequency").set("disabled", true)
      }else{
        formRef.$("income-benefit-pay-out-frequency").set("disabled", false)
      }
    }
  }

  incomeBenefitPayOutTypeBasedOnIncomeOption = (formRef, productName, ppt) => {
    let incomeOptionValue = formRef.$("income-option").value.id.toString()

    if ((productName === ProductConstants.GUARANTEED_INCOME_STAR) && (incomeOptionValue === '5')) {

      formRef.$('income-benefit-pay-out-type').set('value', '')

      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        isVisible: false
      })
    }

    if ((productName === ProductConstants.GUARANTEED_INCOME_STAR || productName === ProductConstants.GUARANTEED_INCOME_STAR_FIB) && (incomeOptionValue === '6')) {
      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        optionList: [
          { label: 'Level', id: '30' },
          { label: 'Increasing', id: '31' }]
        })

        formRef.$("income-benefit-pay-out-type").set("extra", {
          ...formRef.$("income-benefit-pay-out-type").extra,
          isVisible: true
        })
      formRef.$('income-benefit-pay-out-type').set('value', { label: 'Level', id: '30' })

      if (ppt === '12') {
        formRef.$('income-start-option').set('value', { label: '15', id: '18' })
      } else if (ppt === '8' || ppt === '10') {
        formRef.$('income-start-option').set('value', { label: '10', id: '13' })
      }

      formRef.$("income-start-option").set("disabled", true)
    }

    if (productName === ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED) {
      formRef.$("income-benefit-pay-out-type").set("value", { label: 'Level', id: '30' })
      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        isVisible: true
      })
      if (ppt === '12') {
        formRef.$('income-start-option').set('value', { label: '15', id: '18' })
      } else if (ppt === '8' || ppt === '10') {
        formRef.$('income-start-option').set('value', { label: '10', id: '13' })
      }

      formRef.$("income-start-option").set("disabled", true)
    }

    if (productName === ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED) {
      formRef.$("income-benefit-pay-out-type").set("value", '')
      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        isVisible: false
      })
    }

    if ((productName === ProductConstants.POS_GUARANTEED_INCOME_STAR) && (incomeOptionValue === '5')) {
      formRef.$("income-benefit-pay-out-type").set("value", '')
      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        isVisible: false
      })
    }

    if (productName === ProductConstants.POS_GUARANTEED_INCOME_STAR) {
      formRef.$("income-benefit-pay-out-type").set("value", '')
      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        isVisible: false
      })
    }


  }


  incomeBenefitDurationLogicBasedOnPpt = (pptValue, formRef, productName) => {
    if (productName === "Premier Guaranteed Income (Long Term Income)") {
      switch (pptValue) {
        case "5":
        case "8":
        case "10":
          formRef.$("income-benefit-payout-duration").set("extra", {
            ...formRef.$("income-benefit-payout-duration").extra,
            optionList: [
              {
                id: "15",
                label: "15",
              },
              {
                id: "20",
                label: "20",
              },
              {
                id: "25",
                label: "25",
              },
              {
                id: "30",
                label: "30",
              },
            ],
          })

          formRef.$("income-benefit-payout-duration").set("value", {
            id: "15",
            label: "15",
          })

          break

        case "12":
          formRef.$("income-benefit-payout-duration").set("extra", {
            ...formRef.$("income-benefit-payout-duration").extra,
            optionList: [
              {
                id: "15",
                label: "15",
              },
              {
                id: "20",
                label: "20",
              },
              {
                id: "25",
                label: "25",
              },
            ],
          })

          formRef.$("income-benefit-payout-duration").set("value", {
            id: "15",
            label: "15",
          })

          break

        default:
          break
      }
    }
  }

  prefillPtBasedOnpptAndIncomeBenefitPayoutDuration = (
    formRef,
    productName
  ) => {
    if (productName === "Premier Guaranteed Income (Long Term Income)") {
      const incomeBenefitPayoutDurationValue = formRef.$(
        "income-benefit-payout-duration"
      ).value.id
      const pptValue = formRef.$("PPT").value.id

      if (pptValue === "5") {
        switch (incomeBenefitPayoutDurationValue) {
          case "15":
            formRef.$("PT").set("value", {
              id: "22",
              label: "22",
            })
            break

          case "20":
            formRef.$("PT").set("value", {
              id: "27",
              label: "27",
            })
            break

          case "25":
            formRef.$("PT").set("value", {
              id: "32",
              label: "32",
            })
            break

          case "30":
            formRef.$("PT").set("value", {
              id: "37",
              label: "37",
            })
            break

          default:
            break
        }
      }

      if (pptValue === "8") {
        switch (incomeBenefitPayoutDurationValue) {
          case "15":
            formRef.$("PT").set("value", {
              id: "25",
              label: "25",
            })
            break

          case "20":
            formRef.$("PT").set("value", {
              id: "30",
              label: "30",
            })
            break

          case "25":
            formRef.$("PT").set("value", {
              id: "35",
              label: "35",
            })
            break

          case "30":
            formRef.$("PT").set("value", {
              id: "40",
              label: "40",
            })
            break

          default:
            break
        }
      }

      if (pptValue === "10") {
        switch (incomeBenefitPayoutDurationValue) {
          case "15":
            formRef.$("PT").set("value", {
              id: "27",
              label: "27",
            })
            break

          case "20":
            formRef.$("PT").set("value", {
              id: "32",
              label: "32",
            })
            break

          case "25":
            formRef.$("PT").set("value", {
              id: "37",
              label: "37",
            })
            break

          case "30":
            formRef.$("PT").set("value", {
              id: "42",
              label: "42",
            })
            break

          default:
            break
        }
      }

      if (pptValue === "12") {
        switch (incomeBenefitPayoutDurationValue) {
          case "15":
            formRef.$("PT").set("value", {
              id: "29",
              label: "29",
            })
            break

          case "20":
            formRef.$("PT").set("value", {
              id: "34",
              label: "34",
            })
            break

          case "25":
            formRef.$("PT").set("value", {
              id: "39",
              label: "39",
            })
            break

          default:
            break
        }
      }
    }
  }

  getIncomeBenefitOptions = (productName, ppt) => {
    let payoutList = []
    switch (productName) {
      case "Premier Guaranteed Income (Short Term Income)":
        payoutList = [{ id: ppt, label: ppt }]
        break

      case "Premier Guaranteed Income (Long Term Income)":
        payoutList.push({ id: 15, label: 15 })
        payoutList.push({ id: 20, label: 20 })
        payoutList.push({ id: 25, label: 25 })

        if (ppt === 5 && ppt === 8 && ppt === 10) {
          payoutList.push({ id: 30, label: 30 })
        }

        break

      case "Premier Guaranteed Income (Lump sum)":
        break

      case "Premier Guaranteed Income (Retirement Income)":
        payoutList.push({ id: 20, label: 20 })
        break

      default:
        break
    }
    return payoutList
  }

  getTotalPrice = (biResponse, biRequest) => {
    try {
      return Number(biResponse?.PREM_DISP)
    } catch (e) {
      return 0
    }
  }

  getRiderTax = (data) => {
    let totalRider = 0
    data?.parsedResponse?.inputValidationStatus?.map((item) => {
      totalRider += Number(item.Tax)
    })
    return totalRider
  }


  showRiderBasedOnProductName = (data) => {

    let productName = data.productName

    switch (productName) {

      case ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case ProductConstants.POS_GUARANTEED_INCOME_STAR:
      case ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
        return false
        break;

      default:
        return true
        break;
    }
  }

  updateBi = (key, value, productId, formRef, productData) => {
    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request

    switch (key) {
      case keys.PPT:
        biRequest[keys.PPT] = value.ppt
        biRequest[keys.INCOME_START_OPTION] = value.incomeStartOption
        break

      case keys.PT:
        biRequest[keys.PT] = "0"
        break

      case keys.INCOME_START_OPTION:
        biRequest[keys.PT] = "0"
        biRequest[keys.INCOME_START_OPTION] = value
        break

      case keys.INCOME_DURATION:
        biRequest[keys.PT] = "0"
        biRequest[keys.INCOME_DURATION] = value
        break

      case keys.LUMPSUM_BENEFIT:
        biRequest[keys.LUMPSUM_BENEFIT] = value
        break

      case keys.FREQUENCY:
        biRequest[keys.FREQUENCY] = value.frequency
        break

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value
        break

      case keys.INCOME_OPTION:
        biRequest[keys.PT] = "0"
        biRequest[keys.INCOME_START_OPTION] = value.incomeStartOption
        biRequest[keys.INCOME_BENEFIT_PAYOUT_FREQUENCY] = value.incomeFrequency
        biRequest[keys.INCOME_OPTION] = value.incomeOption
        biRequest[keys.INCOME_BENEFIT_PAYOUT_TYPE] = value.payoutType
        break

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value
        break

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = value
        } else {
          biRequest[keys.ADB_RIDER] = ""
        }
        break

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = value
        } else {
          biRequest[keys.ATPD_RIDER] = ""
        }
        break

      case keys.Critical_ILLNESS_RIDER:
        if (value) {
          biRequest[keys.Critical_ILLNESS_RIDER] = value
        } else {
          biRequest[keys.Critical_ILLNESS_RIDER] = ""
        }
        break

      case keys.INCOME_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.INCOME_BENEFIT_RIDER] = value
        } else {
          biRequest[keys.INCOME_BENEFIT_RIDER] = ""
        }
        break

      case keys.PWB_RIDER:
        if (value) {
          biRequest[keys.PWB_RIDER] = "1"
          biRequest.PWB = "" + value
        } else {
          biRequest[keys.PWB_RIDER] = ""
        }
        break

      case keys.HOSPITALITY_CASH_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.HOSPITALITY_CASH_BENEFIT_RIDER] = value
        } else {
          biRequest[keys.HOSPITALITY_CASH_BENEFIT_RIDER] = ""
        }
        break

      case keys.FAMILY_BENEFIT_INCOME_OPTION:
        biRequest[keys.FAMILY_BENEFIT_INCOME_OPTION] = value
        break

      case keys.INCOME_BENEFIT_PAYOUT_FREQUENCY:
        biRequest[keys.INCOME_BENEFIT_PAYOUT_FREQUENCY] = value
        break

      case keys.INCOME_BENEFIT_PAYOUT_TYPE:
        biRequest[keys.INCOME_BENEFIT_PAYOUT_TYPE] = value
        break

      case keys.INCOME_BENEFIT_DURATION:
        biRequest[keys.INCOME_BENEFIT_DURATION] = value
        break
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData)

    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value)
  }

  getAdbList = () => {
    let list = []
    for (let index = 0; index <= 100; index = index + 5) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + " L",
        })
      } else {
        list.push({
          id: index,
          label: 1 + " Cr.",
        })
      }
    }
    return list
  }

  getAtpdList = () => {
    return this.benefitAmountList(100000, 1000000)
  }

  getCriticalIllnessList = () => {
    return this.benefitAmountList(100000, 500000)
  }

  getIncomeBenefit = () => {
    let initialValue = 75000
    let values = []

    for (let i = 1; i <= 8; i++) {
      values.push({ id: i * initialValue, label: i * initialValue })
    }

    return values
  }

  benefitAmountList = (min, max) => {
    let values = []

    max = Math.round(max / 100000)
    for (let i = 1; i <= max; i++) {
      values.push({ id: i, label: i + " L" })
    }
    return values
  }
  
  newPolicyTimeLineData(element,inputString,index,productName){

    const {MODAL_PREM,INCOME_BENEFIT_FINAL,DB_G,INCOME_BENEFIT_PAYOUT_LARGE, MB_G, ANN_PREM_YEARLY} = element

    let incomeOption = inputString.find(item => item.Key === '@DISPLAY_PR_OPTION_2').Value

    const flexiblIncomeArray = [
      ProductConstants.POS_GUARANTEED_INCOME_STAR,
      ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED,
      ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS,
      ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED,
      ProductConstants.GUARANTEED_INCOME_STAR,
      ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS,
      ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB,
      ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB,
      ProductConstants.GUARANTEED_INCOME_STAR_FIB
    ]

    const largeIncomeBenefitArray = [
      ProductConstants.GUARANTEED_INCOME_STAR,
      ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED,
      ProductConstants.GUARANTEED_INCOME_STAR_FIB
    ]


    if(flexiblIncomeArray.includes(productName) &&  (incomeOption === 'Flexible Income')){

      let data = {
        youPay:Math.round(ANN_PREM_YEARLY),
        youGet:Math.round(INCOME_BENEFIT_FINAL) + Math.round(MB_G),
        lifeCover:Math.round(DB_G)
      }
      return data
    } 
    
    if(largeIncomeBenefitArray.includes(productName) &&  (incomeOption === 'Large income Benefit')){
      let data = {
        youPay:Math.round(ANN_PREM_YEARLY),
        youGet:Math.round(INCOME_BENEFIT_PAYOUT_LARGE) + Math.round(MB_G),
        lifeCover:Math.round(DB_G)
      }

      return data
    }
  }

  fetchIncomeStartOptionValue(incomeStartOption) {
    switch (incomeStartOption?.toString()) {

      case '7':
        return { label: '2', id: '7' }

      case '8':
        return { label: '5', id: '8' }

      case '9':
        return { label: '6', id: '9' }

      case '10':
        return { label: '7', id: '10' }

      case '11':
        return { label: '8', id: '11' }

      case '12':
        return { label: '9', id: '12' }

      case '13':
        return { label: '10', id: '13' }

      case '14':
        return { label: '11', id: '14' }

      case '15':
        return { label: '12', id: '15' }

      case '16':
        return { label: '13', id: '16' }

      case '17':
        return { label: '14', id: '17' }

      case '18':
        return { label: '15', id: '18' }

      case '19':
        return { label: '18', id: '19' }

      case '20':
        return { label: '20', id: '20' }

      case '21':
        return { label: '22', id: '21' }

      default :
        return { label: '', id: '' }

    }
  }

  fetchIncomeDuration(incomeDuration) {
    switch (incomeDuration?.toString()) {
      case '24':
        return 10

      case '25':
        return 15

      case '26':
        return 20

      case '27':
        return 25

      case '28':
        return 30

      case '29':
        return 35

      default :
        return ""
    }
  }
}

export default new Utils()
