// project imports
import Utils from "./utils"
import * as keys from "./keys"

const FormObserver = (formRef, props) => {

  formRef.$("payment-frequency").observe({
    key: "value",
    call: ({ field }) => {
      let premium = formRef.$("premium-amt").value
      let frequency = field.value.id
      let data = {
        premium: premium,
        frequency: frequency,
      }
      Utils.updateBi(
        keys.FREQUENCY,
        data,
        props.data.product_id,
        formRef,
        props
      )
    },
  })

  formRef.$("source-of-purchase-price").observe({
    key: "value",
    call: ({ field }) => {

      const sppValue = Number(field.value.id)

      if(sppValue === 4){
        // pran-number
        formRef.$("pran-number").set("extra", {
          ...formRef.$("pran-number").extra,
          visible: true,
        });
      }else{
        formRef.$("pran-number").set("value" , "")
        formRef.$("pran-number").set("extra", {
          ...formRef.$("pran-number").extra,
          visible: false,
        });
      }
      let requestData = {
        sourceOfPP: sppValue,
        pranNo : sppValue === 4 ? formRef.$("pran-number").value : ""
      }
      Utils.updateBi(
        keys.SOURCE_OF_PURCHASE_PRICE,
        requestData,
        props.data.product_id,
        formRef,
        props
      );
    },
  })

  formRef.$("annuity-benefit").observe({
    key: "value",
    call: ({ field,form }) => {

       let defermentPeriodValue = form.$("deferment-period").value.id;
        let annuityBenefitValue = field.value.id

      Utils.updateBi(
        keys.ANNUITY_BENEFIT,
        {defermentPeriodValue,annuityBenefitValue},
        props.data.product_id,
        formRef,
        props
      );
    },
  })

  formRef.$("life-status").observe({
    key: "value",
    call: ({ field,form }) => {
      let annuityOptionList= []

      if(field.value.id === '3'){

        // ! // ! disabling Name of the Secondary Annuitant, DOB of the Secondary Annuitant, Gender of the Secondary Annuitant

        form.$("secondary-annuitant-name").set("disabled",true)
        form.$("secondary-annuitant-date-of-birth").set("disabled",true);
        form.$("secondary-annuitant-gender").set("disabled",true);

        //! for setting the values of the default
        form.$('secondary-annuitant-name').set('value', '');
        form.$("secondary-annuitant-date-of-birth").set("value","");
        form.$("secondary-annuitant-gender").set("value","");

        // Immediate annuity
        if(Number(form.$("annuity-benefit").value.id) === 1) {
          annuityOptionList = [
            { label: "Life Annuity", id: "25" },
            {
            label: "Life Annuity with Return of Purchase Price on Death",
            id: "26",
            },
            {
            label:
                "Life Annuity with Return of Balance Purchase Price on Death",
            id: "27",
            },
            {
            label:
                "Life Annuity with Return of Purchase Price on Critical Illness (CI) or Accidental Total and Permanent Disability (ATPD) or Death",
            id: "28",
            },
          ]
        } else {
          annuityOptionList = [
            {
            label:
                "Deferred Annuity with Return of Purchase Price on Death (Single Life)",
            id: "31",
            },
            {
            label:
                "Deferred Annuity with Return of Purchase Price on Critical Illness (CI) or Accidental Total and Permanent Disability (ATPD) or Death (Single Life)",
            id: "32",
            }
          ]
        }

      }else{
        form.$("secondary-annuitant-name").set("disabled",false)
        form.$("secondary-annuitant-date-of-birth").set("disabled",false);
        form.$("secondary-annuitant-gender").set("disabled",false);

        // Immediate annuity
        if(Number(form.$("annuity-benefit").value.id) === 1) {
          annuityOptionList = [
            { label: "Life Annuity (Joint life, Last Survivor)", id: "29" },
            {
            label:
                "Life Annuity with Return of Purchase Price on Death (Joint life, Last Survivor)",
            id: "30",
            },
          ]
        } else {
          annuityOptionList = [
            {
              label:
                "Deferred Annuity with Return of Purchase Price on Death (Joint Life, Last Survivor)",
              id: "33",
            }
          ]
        }
      }
      formRef.$("annuity-option").set("extra", {
        ...formRef.$("annuity-option").extra,
        options: [...annuityOptionList],
      });

      formRef.$("annuity-option").set("value", annuityOptionList[0])
      let requestData = {
        annuityOption: annuityOptionList[0].id,
        lifeStatus: field.value.id
      }

      Utils.updateBi(
        keys.LIFE_STATUS,
        requestData,
        props.data.product_id,
        formRef,
        props
      );
    },
  })

  formRef.$("annuity-mode").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.ANNUITY_MODE,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  })

  formRef.$("deferment-period").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.DEFERMENT_PERIOD,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  })

  formRef.$("annuity-option").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.ANNUITY_OPTION,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  })

  formRef.$("secondary-annuitant-gender").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.GENDER_NAME_OF_THE_SECONDARY_ANNUITANT,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  })
}

export default FormObserver
