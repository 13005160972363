import { observable, makeObservable } from 'mobx';
import moment from 'moment';
import * as APITypes from "constants/API";
import QmsStorageKeys from 'constants/QmsStorageKeys';
import StorageKeys from 'constants/StorageKeys';
import axios from 'axios';

// !dummy Data

class QmsDetailsPageStore {
	seletedTabData = {};
	leadSpecificId = ""
	solutionId = ""
	showStartApplicationModal = false

	constructor() {
		makeObservable(this, {
			seletedTabData: observable,
			leadSpecificId: observable,
			solutionId: observable,
			showStartApplicationModal: observable
		});
	}

	updateSelectedTabData(data) {
		this.seletedTabData = data
	}

	saveAgentDetails(agentData) {
		if (agentData) {
			localStorage.setItem(StorageKeys.AUTH_TOKEN, agentData.authToken)
			localStorage.setItem(QmsStorageKeys.AGENT_ID, agentData.agent_id)
			localStorage.setItem(QmsStorageKeys.PRAGATI_HOME_URL, agentData.pragati_home_url)
			localStorage.setItem(QmsStorageKeys.PRAGATI_POST_CALL_END_POINT_URL, agentData.pragati_post_call_end_point_url)
			localStorage.setItem(QmsStorageKeys.AGENT_CODE, agentData.agent_code)
			localStorage.setItem(QmsStorageKeys.AGENT_NAME, agentData.agent_name)
			localStorage.setItem(QmsStorageKeys.LOGIN_CODE, agentData.login_code)
			localStorage.setItem(QmsStorageKeys.VENDOR, agentData.vendor)
			localStorage.setItem(QmsStorageKeys.LOGIN_AGENT_CODE, agentData.login_agent_code)
			localStorage.setItem(QmsStorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, agentData.first_time_optimization_page_load_param)
		}
	}

	fetchTabListData(list) {
		let tabList = []
		list.forEach((item, index) => {
			let previousVersionNumber = list.length-index;
			tabList.push({
				title: index == 0 ? "Latest Shared Version" : "Previous Version " + previousVersionNumber.toString(),
				subTitle1: `Soln ID:${item.solution_id} `,
				subTitle2: moment(item.pdf_shared_date).format("DD-MM-YYYY")
			})
		})
		return tabList
	}

	async startApplication(journeyId, solutionID) {
		// let generatedSolutionData = await this.onGenerateSolutionData(journeyId);
		// if (generatedSolutionData) {
		// 	this.solutionId = generatedSolutionData.data[0].solution_id;
		// 	this.leadSpecificId = generatedSolutionData.data[0].la_specific_ids_array[0];
		// }
		this.solutionId = solutionID;

		// const response = await axios.post(APITypes.NA_QUOTE_STATUS_UPDATE,
		// 	{
		// 		"solution_id": generatedSolutionData.data[0].solution_id,
		// 		"pragati_sso_token": localStorage.getItem(StorageKeys.AUTH_TOKEN),
		// 		"la_specific_id": null,
		// 		"quote_status": null
		// 	});

		// if (response.status.toLowerCase() === "success") {
		const response = await axios.post(APITypes.REFRESH_TOKEN);
		let refreshedToken = response.data.token;
		if (refreshedToken) {
			localStorage.setItem(StorageKeys.AUTH_TOKEN, refreshedToken);
		}
		let pragatiOutboundFormObj = document.getElementById("pragati-out-oubound-form-post-call");
		console.log("onPragatiOutboundClick formObj :: ", pragatiOutboundFormObj);
		this.onHideApplication();
		pragatiOutboundFormObj.submit();
		// }
	}

	async onGenerateSolutionData(journeyId) {
		const generatedSolutionData = await this.createSolutionData(journeyId);
		if (generatedSolutionData && generatedSolutionData.data.length > 0) {
			return generatedSolutionData;
		}
		return [];
	}
	async createSolutionData(journeyId) {
		const response = await axios.get(APITypes.GENERATE_SOLUTION_DATA + journeyId + "/");
		return response;
	}

	onStartApplicationClick() {
		this.showStartApplicationModal = true
	}

	onHideApplication() {
		this.showStartApplicationModal = false
	}

	async fetchPdfUrl(proposerData) {
		const response = await axios.post(APITypes.FETCH_SOLUTION_PDF_FROM_DMS,
			{
				"dms_id": proposerData.solution_dms_id
			});

		if (response.status === "success") {
			let proposerName = proposerData.proposer_salutation + " " + proposerData.proposer_first_name + " " + proposerData.proposer_middle_name + " " + proposerData.proposer_last_name
			this.downloadQuotePdf(response.data.pdf_url, proposerName, proposerData.solution_id)
		}
	}

	downloadQuotePdf(url, proposerName, solutionId) {
		fetch(url).then(r => r.blob()).then((blob) => {
			// Create blob link to download
			const url = window.URL.createObjectURL(
				new Blob([blob]),
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				`ETLI Solution Presentation (${proposerName}) – ${solutionId}.pdf`,
			);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
		});
	}

	fetchLaList(proposerData) {
		let laList = []
		proposerData?.coverage.forEach((coverageData) => {
			coverageData.life_assured_list.forEach((laData) => {
				let sameLA = laList.some((item) => item.la_family_member_id === laData.la_family_member_id)
				if (!sameLA) {
					laList.push(laData)
				} else {
					laList.forEach((item) => {
						if (item.la_family_member_id === laData.la_family_member_id) {
							item.product_details = [...item.product_details, ...laData.product_details]
						}
					})
				}
			})
		})
		return laList
	}
}

export default new QmsDetailsPageStore();
