// React Imports
import React from "react";

// Package Imports
import { Col, Row, Card, Image } from "react-bootstrap";
import { observer } from "mobx-react";

// Project Imports
import { CustomCheckbox, BottomsheetDropdown } from "components";
import { GrowMyMoneyImg } from "constants/Images";

// CSS Imports
import "./GrowMyMoney.css";


const GrowMyMoney = (props) => {

  const { isSelected } = props;
  const growMyMoneyForm = props.formController.$("grow_my_money")

  return (
    <Card className='border-0 grow-my-money-card h-100'>
      <div className={!isSelected ? "card-overlay" : null}></div>
      <Card.Body className='p-2 pb-4'>
        <div>
          <Row className='d-flex align-items-start'>
            <Col xs={9} className='pr-0'>
              <CustomCheckbox
                isChecked={isSelected}
                onChange={() => props.toggleNeedSelection(isSelected)}
                id='growMyMoney'
                shape='rounded'
                title={growMyMoneyForm.label}
                titleStyle='text-primary font-weight-medium font-size-16 font-size-md-14 pl-2'
                containerStyle='align-items-start'
                checkmarkStyle='radio-margin-top'
                mark='checkmark'
                className='choose-Need-checkBox'
              />

              <div className='ml-4 pl-2'>
                <div className='d-md-flex align-items-center justify-content-between'>
                  <span
                    className='font-size-14 font-size-md-12 font-weight-light description-text-color mb-0 mt-2 mt-md-0 need-label-width mr-2'
                  >
                    I would need
                  </span>
                  <div className='drop-down-style need-dropdown-width'>
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey='label'
                      bottomsheetTitle={growMyMoneyForm.$("target_amount_type").extra.bottomsheetTitle}
                      menuList={growMyMoneyForm.$("target_amount_type").extra.optionList}
                      onSelect={growMyMoneyForm.$("target_amount_type").onChange}
                      value={growMyMoneyForm.$("target_amount_type").value}
                      showErrorMsg={growMyMoneyForm.$("target_amount_type").error}
                      errorMsg={growMyMoneyForm.$("target_amount_type").error}
                      disabled={!isSelected}
                    />
                  </div>
                </div>
                <div className='d-md-flex align-items-center justify-content-between mt-lg-2 mt-2'>
                  <span className='font-size-14 font-size-md-12 font-weight-light description-text-color mb-0 mt-2 mt-md-0 w-25 mr-2'></span>
                  <div className='drop-down-style need-dropdown-width'>
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey='label'
                      bottomsheetTitle={growMyMoneyForm.$("target_amount_dropdown").extra.bottomsheetTitle}
                      menuList={growMyMoneyForm.$("target_amount_dropdown").extra.optionList}
                      onSelect={growMyMoneyForm.$("target_amount_dropdown").onChange}
                      value={growMyMoneyForm.$("target_amount_dropdown").value}
                      showErrorMsg={growMyMoneyForm.$("target_amount_dropdown").error}
                      errorMsg={growMyMoneyForm.$("target_amount_dropdown").error}
                      disabled={!isSelected}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={3} className='text-lg-right'>
              <Image className='money-choose-card-img' src={GrowMyMoneyImg} alt='grow my money' />
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
}

GrowMyMoney

export default observer(GrowMyMoney);
