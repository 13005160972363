// Project Imports
import incomeTypeEnum from "constants/incomeTypeEnum";
import fetchNeedMasterData from "utils/fetchNeedMasterData";
import NeedTypeEnum from "constants/NeedTypeEnum";

const FormObserver = async (formRef) => {
  let masterData = await fetchNeedMasterData()

  if (masterData.length <= 0) return

  let saveForChildren = masterData.find((item) => item.unique_name === NeedTypeEnum.SAVE_FOR_CHILDREN)

  formRef.$("target_amount_type").observe({
    key: "value",
    call: ({ form, field }) => {
      let type = field.value.mode;

      form.$("target_amount_dropdown").value = {}

      let incomeList = saveForChildren.target_amount.filter(item => item.mode === incomeTypeEnum.INCOME)

      let lumpsumList = saveForChildren.target_amount.filter(item => item.mode === incomeTypeEnum.LUMPSUM)

      if (type === incomeTypeEnum.INCOME) {
        form.$("target_amount_dropdown").set("extra", {
          ...form.$("target_amount_dropdown").extra,
          optionList: incomeList,
        })
      }

      if (type === incomeTypeEnum.LUMPSUM) {
        form.$("target_amount_dropdown").set("extra", {
          ...form.$("target_amount_dropdown").extra,
          optionList: lumpsumList,
        })
      }
    }
  });
}

export default FormObserver 