// React imports
import React from "react"

// Project Imports
import PremiumAndRiderAmountComponent from "modules/optimization/components/premium-and-rider-amount-component/PremiumAndRiderAmountComponent"
import SolutionScreenLADetails from "../solution-screen-LA-details/SolutionScreenLADetails"
import ProductConstants from "constants/ProductConstants"

const LaAndPremiumRiderComponent = (props) => {


  const {
    percentValue,
    productpremiumType,
    productPremiumMode,
    productPremiumAmount,
    productPremiumGstPercent,
    productPremiumGstAmount,

    riderPremiumType,
    riderPremiumMode,
    riderPremiumAmount,
    riderPremiumGstPercent,
    riderPremiumGstAmount,

    showRiderValue,

    LaName,
    productName,
    targetAmount,
    totalPremiumAmount,
    totalPremiumPayable, 
    inputMode
  } = props

  let productsWithNoRiderSection = [ProductConstants.EASY_PENSION,ProductConstants.FOREVER_PENSION_DEFERRED_ANNUITY,ProductConstants.FOREVER_PENSION_DEFERRED_ANNUITY,ProductConstants.SARAL_PENSION,ProductConstants.WEALTH_ULTIMA,ProductConstants.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA,ProductConstants.LITTLE_CHAMP_WEALTH_ULTIMA]

  productsWithNoRiderSection = new Set(productsWithNoRiderSection)

  return (
    <div>

     <div className="border-top pt-2">
      <SolutionScreenLADetails
        LaName={LaName}
        ProductName={productName}
        amount={totalPremiumAmount}
        percentValue={percentValue}
      />
     </div>

      <PremiumAndRiderAmountComponent
        premiumAmount={productPremiumAmount}
        premiumGstPercent={productPremiumGstPercent}
        premiumGstAmount={productPremiumGstAmount}
        premiumType={productpremiumType}
        premiumMode={productPremiumMode}
        showValue={true}
        inputMode={inputMode}
      />

      {
      !productsWithNoRiderSection.has(productName) &&
      <PremiumAndRiderAmountComponent
        premiumAmount={riderPremiumAmount}
        premiumGstPercent={riderPremiumGstPercent}
        premiumGstAmount={riderPremiumGstAmount}
        premiumType={riderPremiumType}
        premiumMode={riderPremiumMode}
        showValue={showRiderValue}
        inputMode={inputMode}
      />
      }
    </div>
  )
}

export default LaAndPremiumRiderComponent
