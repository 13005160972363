export default {
    "wealthUltima": "40009",
    "zindagiPlus": "40038",
    "activeIncomePlan": "40035",
        "simplyProtect": "40012",
    "wealthPlus": "40023",
    "wealthGainPlus": "40030",
    "wealthSecurePlus": "40031",
    "criticarePlus": "40014",
    "GCAP": "40007",
    "smartLifestyle": "40005",
    "dhanLabh": "40015",
    "cashFlowProtectionPlus": "40004",
    "incomeBuilder": "40003",
        "immediateAnnuityPlan": "40020",
    "wealthPremier": "40034",
    "easyPension": "40032",
    "singlePayEndowmentAssuarancePlan": "40019",
    "posSaralNivesh": "40042",
        "protection": "40011",
    "totalProtectPlus": "40044",
    "saralJeevanBima": "40039",
    "saralPension": "40041",
    "foreverPension": "40040",
    "premierGuranteedIncome": "40048",
    "guaranteedIncomeStar": "40046",
    "guaranteedSavingsStar": "40047",
    "flexiSavingsPlan": "40049"
}