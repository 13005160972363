// Project Imports
import ProductNames from "constants/ProductNames";
import ProductPackageId from "constants/ProductPackageId";

const maturityBenefitAmount = function (data) {
  console.log("maturityDeathBenefitAmount", data);

  if (!Array.isArray(data.BI)) {
    return 0;
  } else {
    // For With tabs products

    // Current props data
    let selectedRiskData = data.BI.find((item) => item.risk === data.selectedRisk);

    switch (selectedRiskData?.product_id) {
      case ProductNames.premierGuranteedIncome:
        switch (selectedRiskData.package_id) {
          case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME:
          case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
          case ProductPackageId.PREMIER_GUARANTEED_RETIREMENT_INCOME:
            return Math.round(selectedRiskData.parsedResponse.MATURITY_BEN);

          default:
            return 0;
        }

      case ProductNames.activeIncomePlan:
        return Math.round(selectedRiskData.parsedResponse.TOTAL_MB_G_BS_2);

      case ProductNames.cashFlowProtectionPlus:
        return Math.round(selectedRiskData.parsedResponse.SB_G);

      case ProductNames.incomeBuilder:
        switch (selectedRiskData.package_id) {
          case ProductPackageId.SECURED_INCOME_OPTION_INCOME_BUILDER:
            // let maturityBenefit = 0;
            // selectedRiskData?.biResponseCompleteData?.forEach((item) => {
            //   if (Number(item.INCOME_DISPLAY) != 0 && maturityBenefit === 0) maturityBenefit = Math.round(item.INCOME_DISPLAY);
            // });
            // return Math.round(selectedRiskData?.parsedResponse?.INCOME_DISPLAY) - maturityBenefit;
            return Math.round(selectedRiskData?.parsedResponse?.INCOME_DISPLAY);

          case ProductPackageId.INCOME_BUILDER:
          default:
            return 0;
        }

      case ProductNames.guaranteedIncomeStar:
        return Math.round(selectedRiskData?.parsedResponse?.MB_G);

      case ProductNames.wealthUltima:
        switch (selectedRiskData.package_id) {
          case ProductPackageId.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
            return Math.round(selectedRiskData?.parsedResponse?.FINAL_FV);

          default:
            return 0;
        }

      case ProductNames.flexiSavingsPlan:
        switch (selectedRiskData.package_id) {
          case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
            return Math.round(selectedRiskData?.parsedResponse?.MATURITY_BENEFIT_BS_2)

          case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
            if (Number(selectedRiskData?.bi_request?.AccrualOfSurvivalBenefits) === 12) {
              return Math.round(selectedRiskData?.parsedResponse?.MATURITY_BENEFIT_BS_2)
            } else {
              return 0
            }

          default:
            return 0;
        }

      default:
        return 0;
    }
  }
};

export default maturityBenefitAmount;
