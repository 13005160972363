// Project Imports
import checkIsValueEmpty from "./checkIsValueEmpty"

const fetchDeselectedExpenses = (formController, expenseName) => {
    let deSelectedExpenseList = []

    let expenseList = formController.$(expenseName).default
    for (const expense in expenseList) {
        let isInitialEmpty = checkIsValueEmpty(expenseList[expense])

        if (!isInitialEmpty) {
            let currentExpenseValue = formController.$(expenseName).$(expense).value

            let isCurrentlyNotSelected = checkIsValueEmpty(currentExpenseValue)

            if (isCurrentlyNotSelected) {
                deSelectedExpenseList.push(expenseList[expense])
            }
        }
    }

    return deSelectedExpenseList;
}

export default fetchDeselectedExpenses