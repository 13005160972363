// React Imports
import React, { useEffect, useState } from "react"

// Packge Imports
import { observer } from "mobx-react"
import { Modal } from "react-bootstrap"

// Project Imports
import { Button as UiButton } from "components"
import { FamilyIcon, CheckSecondaryImg, CloseIconBlack } from "constants/Images"
import FamilyMemberCardInModal from "./FamilyMemberCardInModal"
import FamilyMemberTypeEnum from "../FamilymemberTypeEnum"
import { addNewMember, selfAndSpouseGenderToggle, updateValue } from '../form/utils'

// CSS Imports
import "./add-member-card-modal.css"
import FamilyDetailsFormController from "../form/controller"
import AuthStore from "modules/login/store/AuthStore"
import { toJS } from "mobx"


const AddMemberCardModal = (props) => {

  const { show, onHide, formController, familymemberList } = props

  let selfMemberDetails = {}
  let spouseMemberDetails = {}
  let childMemberDetails = []
  let elderlyMemberDetails = []
  let adultChildDetails = []
  let grandChildDetails = []

  if(familymemberList.length === 0){
    return null
  }

  for (const [key, value] of Object.entries(familymemberList)) {
    if (key === "self") {
      selfMemberDetails = {
        key: key,
        value: value
      }
    }

    if (key === "spouse") {
      spouseMemberDetails = {
        key: key,
        value: value
      }
    }

    if (key.startsWith("child")) {
      childMemberDetails.push({
        key: key,
        value: value
      })
    }

    if (key == 'grand_father' || key == 'grand_mother') {
      elderlyMemberDetails.push({
        key: key,
        value: value
      })
    }

    if (key.startsWith("adult_child")) {
      adultChildDetails.push({
        key: key,
        value: value
      })
    }

    if (key.startsWith("grand_child")) {
      grandChildDetails.push({
        key: key,
        value: value
      })
    }

  }


  return (
    <div className="add-member-card-modal">
      <Modal
        className="date-picker-modal left-bottom-swipe-modal"
        show={show}
        onHide={onHide}
        keyboard={false}
        backdrop="static"
      >
        <Modal.Header
          closeButton={false}
          className="pb-0 mx-md-2 border-bottom-0"
        >
          <Modal.Title className="text-primary-title-label-color">
            <img
              src={FamilyIcon}
              alt="family-icon"
              className="add-family-member-card-modal-top-img"
            />
            <span style={{ color: "#DF6336", fontSize: "18px" }}>Family Profile</span>
          </Modal.Title>

          <div className="d-flex mt-2 pt-1">
            <UiButton variant='' className='d-inline-flex align-items-center p-0' onClick={() => props.onAddMember(onHide)}>
              <img src={CheckSecondaryImg} alt='checked-icon' className='fm-modal-btn-icon font-weight-bold' />
              <p className='mb-0 font-size-16 font-size-md-12 font-weight-bold text-secondary pl-1'>Done</p>
            </UiButton>
            <UiButton variant='' className='d-inline-flex align-items-center p-0 ml-2' onClick={() => onHide()}>
              <img src={CloseIconBlack} alt='close-icon' className='fm-modal-btn-icon font-weight-bold' />
              <p className='mb-0 font-size-16 font-size-md-12 font-weight-bold pl-1'>Cancel</p>
            </UiButton>

          </div>
        </Modal.Header>
        <Modal.Body className="px-0 overflow-auto py-2 add-family-member-card">
          <div>
            <FamilyMemberCardInModal
              familyMemberName={selfMemberDetails.key}
              data={selfMemberDetails.value}
              formController={formController}
              updateValues={updateValue}
            />

            <FamilyMemberCardInModal
              familyMemberName={spouseMemberDetails.key}
              data={spouseMemberDetails.value}
              formController={formController}
              updateValues={updateValue}
            />

            {
              childMemberDetails.map((item, index) => {
                return (
                  <FamilyMemberCardInModal
                    key={index}
                    familyMemberName={item.key}
                    data={item.value}
                    formController={formController}
                    updateValues={updateValue}
                  />
                )
              })
            }

            <div className="px-3 mx-md-2">
              <UiButton
                variant=""
                className="modal-add-child-btn d-flex justify-content-center align-items-center p-2 mt-3 text-secondary w-100 font-size-16 font-weight-medium"
                onClick={() => addNewMember(FamilyMemberTypeEnum.CHILD, formController)}
              >
                <span className="px-2 mr-2 bg-secondary text-white rounded-circle">
                  +
                </span>{" "}
                <span className="font-size-16 font-size-md-12">
                  ADD CHILD
                </span>
              </UiButton>
            </div>

            {
              elderlyMemberDetails.map((item, index) => {
                return (
                  <FamilyMemberCardInModal
                    key={index}
                    familyMemberName={item.key}
                    data={item.value}
                    formController={formController}
                    updateValues={updateValue}
                  />
                )
              })
            }

            {
              adultChildDetails.map((item, index) => {
                return (
                  <FamilyMemberCardInModal
                    key={index}
                    familyMemberName={item.key}
                    data={item.value}
                    formController={formController}
                    updateValues={updateValue}
                  />
                )
              })
            }
          </div>
          <div className="px-3 mx-md-2">
            <UiButton
              variant=""
              className="modal-add-child-btn d-flex justify-content-center align-items-center p-2 mt-3 text-secondary w-100 font-size-16 font-weight-medium"
              onClick={() => addNewMember(FamilyMemberTypeEnum.ADULT_CHILD, formController)}
            >
              <span className="px-2 mr-2 bg-secondary text-white rounded-circle">
                +
              </span>{" "}
              <span className="font-size-14 font-size-md-12">
                ADD ADULT CHILD
              </span>
            </UiButton>
          </div>

          {grandChildDetails.map((item, index) => {
            return (
              <FamilyMemberCardInModal
                key={index}
                familyMemberName={item.key}
                data={item.value}
                formController={formController}
                updateValues={updateValue}
              />
            )
          })
          }

          <div className="px-3 mx-md-2">
            <UiButton
              variant=""
              className="modal-add-child-btn d-flex justify-content-center align-items-center p-2 mt-3 text-secondary w-100 font-size-16 font-weight-medium"
              onClick={() => addNewMember(FamilyMemberTypeEnum.GRAND_CHILD, formController)}
            >
              <span className="px-2 mr-2 bg-secondary text-white rounded-circle">
                +
              </span>{" "}
              <span className="font-size-14 font-size-md-12">
                ADD GRAND CHILD
              </span>
            </UiButton>
          </div>
        </Modal.Body>

      </Modal>
    </div>
  )
}

export default observer(AddMemberCardModal)
