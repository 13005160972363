import React, { Component } from "react";
import { Button, Image } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import "./dropdown.css";

import DropDownComponent from "../dropdown/Dropdown";
import { CloseIconWhite, CheckSecondaryImg } from "constants/Images";

class BottomsheetDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
    };
  }

  onCloseIconClick = () => {
    this.setState({ showOverlay: false });
    document.body.classList.remove("modal-open");
  };

  onDropDownBtnClick = () => {
    const showOverlay = !this.state.showOverlay;
    this.setState((state) => ({ showOverlay }));

    if (showOverlay) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  };

  render() {
    const { bottomsheetTitle, dropdownStyle, menuItemStyle, menuItemFontStyle, menuList, onSelect, value, selectedLabelKey, disabled, type, defaultLabel, showErrorMsg, errorMsg, hideBottomsheet, ...props } = this.props;
    const { showOverlay } = this.state;

    // console.log('>>>>>fromComponent',additionalErrorMsg);

    return (
      <React.Fragment>
        <div className={`${!hideBottomsheet ? 'd-md-block d-none ' : 'd-block'} ${type != "icon" ? "w-100" : ""}`}>
          <DropDownComponent {...this.props} />
        </div>
        <div className={`${!hideBottomsheet ? 'd-md-none d-block' : 'd-none'} `}>
          <Dropdown show={showOverlay} className={`bottomsheet-dropdown d-inline-block  ${type != "icon" ? "w-100" : ""}`} onToggle={this.onDropDownBtnClick} {...props}>
            <div className={"dropdown-overlay"} />
            <Dropdown.Toggle
              variant=''
              id='dropdown-basic'
              className={`${dropdownStyle} bottomsheet-dropdown-toggle d-flex align-items-center justify-content-between p-2 rounded border ${showErrorMsg ? "validation-border" : ""}`}
              disabled={disabled}
            >
              {type == "icon" && <Image width='30' height='20' className='mr-1' src={value[selectedLabelKey] ? value[selectedLabelKey] : ""} />}
              {type != "icon" && <span className='btn-text-truncate'>{value[selectedLabelKey] ? value[selectedLabelKey] : defaultLabel}</span>}
            </Dropdown.Toggle>

            <Dropdown.Menu className='custom-menu-list' id='bottomsheet-dropdown-menu'>
              <div className='d-flex justify-content-between bg-white p-4 align-items-start dropdown-heading'>
                <h3 className='text-primary-title-label-color font-size-18 mb-0'>{bottomsheetTitle}</h3>
                <Button variant='' className='text-black py-0 px-1 shadow-none'>
                  <Image width='15' height='15' src={CloseIconWhite} alt='close-icon' onClick={this.onCloseIconClick} />
                </Button>
              </div>
              <div>
                {menuList.map((listValue, index) => {
                  return (
                    <React.Fragment key={index}>
                      {type == "icon" && (
                        <Dropdown.Item
                          className={`${menuItemStyle} bottomsheet-dropdown-menu-item font-size-14 font-size-md-16 ${value[selectedLabelKey] == listValue.icon ? "active" : ""}`}
                          key={listValue.id}
                          id={listValue.id}
                          onClick={() => {
                            onSelect(listValue);
                          }}
                        >
                          <div>
                            <Image src={listValue.icon} />
                            <span className='ml-2'>{listValue.label}</span>
                          </div>
                          <Image className='mx-1 bottomsheet-dropdown-menu-item-check-icon' src={CheckSecondaryImg} alt='check-icon' />
                        </Dropdown.Item>
                      )}
                      {type != "icon" && (
                        <Dropdown.Item
                          className={`${menuItemStyle} bottomsheet-dropdown-menu-item font-size-14 font-size-md-16 ${value[selectedLabelKey] == listValue.label ? "active" : ""}`}
                          key={listValue.id}
                          id={listValue.id}
                          onClick={() => {
                            onSelect(listValue);
                          }}
                        >
                          {listValue[selectedLabelKey]}
                          <Image className='mx-1 bottomsheet-dropdown-menu-item-check-icon' src={CheckSecondaryImg} alt='check-icon' />
                        </Dropdown.Item>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {showErrorMsg && <p className='text-danger mb-0 font-weight-light font-size-11 align-items-start d-flex'>
            {errorMsg || "Select a value"}
          </p>}
        </div>
      </React.Fragment>
    );
  }
}

BottomsheetDropdown.defaultProps = {
  defaultLabel: "Select",
  showErrorMsg: false,
  hideBottomsheet: false
};

export default BottomsheetDropdown;
