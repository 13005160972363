import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from './validator';

class Utils {
  updateBi(key, value, productId, form, productData) {

    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request
    switch (key) {
      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.FREQUENCY:
        biRequest[keys.FREQUENCY] = value;
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value;
        break;

      case keys.ANNUITY_OPTION:
        biRequest[keys.ANNUITY_OPTION] = value;
        break;

      case keys.SOURCE_OF_PURCHASE_PRICE:
        biRequest[keys.SOURCE_OF_PURCHASE_PRICE] = value;
        break;

      case keys.FREQUENCY_OF_ANNUITY:
        biRequest[keys.FREQUENCY_OF_ANNUITY] = value;
        break;

      case keys.DATE_OF_BIRTH_SECONDARY_ANNUITANT:
        biRequest[keys.DATE_OF_BIRTH_SECONDARY_ANNUITANT] = value.annuitantDOB;
        biRequest[keys.OSN_ANNUITANT_DATE_OF_BIRTH] = value.dob;
        break;

      case keys.ARE_YOU_NOMINEE:
        biRequest[keys.ARE_YOU_NOMINEE] = value;
        break;

      case keys.NAME_OF_THE_SECONDARY_ANNUITANT:
        biRequest[keys.NAME_OF_THE_SECONDARY_ANNUITANT] = value;
        break;

      case keys.GENDER_OF_THE_SECONDARY_ANNUITANT:
        biRequest[keys.GENDER_OF_THE_SECONDARY_ANNUITANT] = value;
        break;


      default:
        return;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(form, biData);
    // if (errorMessage.length > 0) {
    //   return
    // }

  }

  getTotalPrice = (data) => {
    try {
      return data.MODAL_PREM_TAX;
    } catch (e) {
      return 0;
    }
  };

  fetchPolicyTimeline(element) {
    let data = {
      youPay: Math.round(element.MODAL_PREM_TAX),
      youGet: Math.round(element.YEARLY_ANN_PAY),
      lifeCover: Math.round(element.DB_G),
      isMilestone: false,
      premiumAmt: Math.round(element.PPRICE)
    }
    return data
  }
}

export default new Utils();
