import React, { Component } from 'react'

import { Col, Row, Tab, Tabs, Card } from 'react-bootstrap'

import { toJS } from 'mobx'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react';

import { CircularProgressbar } from '../../../../components';
import SolutionBenefitsCard from '../solution-benefits-card/SolutionBenefitsCard';
import PolicyTimelineTable from '../policy-timeline-table/PolicyTimelineTable';
import UnderlyingCard from '../underlying-card/UnderlyingCard';
import SolutionStore from '../../store/SolutionStore';

import './family-protection-coverage-card.css'
import '../../solution.css'

const FamilyProtectionCoverageCard = observer(class FamilyProtectionCoverageCard extends Component {

  constructor(props) {
    super(props);
    this.state = {
      benefits_tags: []
    }
  }

  render() {
    const { list } = this.props;
    return (
      <Tabs defaultActiveKey='0' className='protection-coverage-tab-container  protection-tab-card-desktop mt-1 flex-nowrap' id='protection-coverage-tab'>
        {list.map((value, index) => {
          return (
            <Tab key={index} eventKey={index} className='' title={<DonutTabTitleComponent relation={value.family_member_type} name={value.family_member_name} />}>
              <div className='px-3 px-sm-0'>
                <div className='protection-coverage-card-row  protection-tab-card-desktop py-2 card-margin-left-right'>
                  <UserDetailsCard data={value} />
                  <div className='px-2 px-md-4'>
                    <Row>
                      <Col>
                        <h2 className='text-primary-title-label-color font-weight-bold font-size-16 font-size-lg-24 mb-3 mt-3 mt-md-4'>Coverage Title</h2>
                      </Col>
                    </Row>
                  </div>
                  <div className='mb-1 mb-md-0 mx-2 mx-md-0 pt-md-0 pt-2 coverage-card-shadow'>
                    <Row>
                      {value.productList.map((value, index) => (
                        <Col key={index} className='d-flex protection-coverage-card-col'>
                          <div className='d-flex flex-column justify-content-between p-lg-3 rounded-sm w-100 protection-coverage-card-body'>
                            <h4 className='text-primary-title-label-color font-size-16 font-size-lg-24 font-size-lg-28 font-weight-light text-center mb-md-2'>{value.title}</h4>
                            <div>
                              <CircularProgressbar
                                value={value.percentValue}
                                valuelabel='Covered'
                                isSingleImg={false}
                              />
                              <h4 className='d-none d-lg-flex text-primary-title-label-color font-size-14 font-size-lg-22 font-size-lg-26 font-weight-bold flex-column flex-lg-row mt-2 mt-md-3 justify-content-center align-items-center'><span><span className="font-size-18 font-weight-400">&#8377;</span>{value.ammount} </span><span className='font-size-14 font-size-lg-20 font-weight-400 text-gray'>  per year</span></h4>
                              <h4 className='d-lg-none text-primary-title-label-color font-size-14 font-size-lg-22 font-size-lg-26 font-weight-bold d-flex flex-column flex-lg-row mt-2 justify-content-center align-items-center'><span><span>&#8377;</span>{value.ammount} </span><span className='font-size-14 font-size-lg-20 font-weight-light text-gray'>Per year</span></h4>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </div>
                <Row className='solution-container mt-5 protection-coverage-card-row bg-color-card'>
                  <Col xs='12' lg='12' className='protection-coverage-card-col mb-4 mb-lg-5 d-flex flex-column'>
                    <h2 className='solution-title-text-color font-weight-bold font-size-16 font-size-lg-32 mb-3'>Solution Benefits</h2>
                    <div className=''>
                      <Row className='solution-mobile-bg-style'>
                        {value.solutionBenifits.filter((item) => item.showBenefit).map((obj, index) => {
                          return (
                            <Col key={index} className='col-md-6 col-12 d-flex'>
                              <div className='solution-desktop-bg-style d-inline-block w-100 px-sm-3 pt-3 pt-lg-4 pb-lg-1'>
                                <SolutionBenefitsCard cardType={obj} data={value} />
                              </div>
                            </Col>
                          )
                        })}
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <Row className='solution-container mt-4 protection-coverage-card-row bg-color-card'>
                <Col xs='12' lg='12' className='protection-coverage-card-col mb-4 mb-lg-5'>
                  <h2 className='solution-title-text-color font-weight-bold font-size-16 font-size-lg-32 mb-3 pl-3 pl-sm-0'>Policy Timeline</h2>
                  <PolicyTimelineTable data={value} />
                </Col>

                <Col xs='12' lg='6' className='protection-coverage-card-col mb-4 mb-lg-5 d-flex flex-column'>
                  <h2 className='solution-title-text-color font-weight-bold font-size-16 font-size-lg-32 mb-3 pl-3 pl-sm-0'>Underlying products</h2>
                  <div className='shadow rounded-sm bg-white d-inline-block w-100 px-3 px-lg-3 pt-3 pb-1 pt-lg-4 mb-lg-0'>
                    <UnderlyingCard list={value} />
                  </div>
                </Col>
              </Row>
            </Tab>
          )
        })}

      </Tabs>
    )
  }
});

const DonutTabTitleComponent = (props) => {
  const { relation, name } = props;
  return (
    <div className='d-flex flex-column align-items-center w-100 py-3 py-lg-4 px-2 tab-titles-container'>
      <h3 className='mb-1 font-size-16 font-size-lg-28 font-size-lg-32 font-weight-medium tab-title'>{relation}</h3>
      <p className='mb-0 tab-user-name font-size-12 font-size-lg-20 font-size-lg-24 tab-subtitle font-weight-300'>{name == '' ? '' : `(${name})`}</p>
    </div>
  )
}

const UserDetailsCard = (props) => {
  const { data } = props;
  return (
    <div className='px-2 px-md-4'>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <p className='mb-0 text-uppercase font-size-12 font-size-lg-20 text-description-text-color font-weight-md-300 font-weight-light'>{data.family_member_type}</p>
              <p className='mb-0 text-capitalize font-weight-bold font-size-18 font-size-lg-24 text-secondary'>{data.family_member_name}</p>
              <hr />
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex flex-column'>
                  <div>
                    <p className='mb-0 text-uppercase font-size-11 font-size-lg-20 text-description-text-color font-weight-md-300 font-weight-light'>GENDER</p>
                    <p className='mb-0 text-capitalize font-weight-normal font-weight-md-400 font-size-14 font-size-lg-24 text-secondary'>{data.gender}</p>
                  </div>
                  <div className='pt-4'>
                    <p className='mb-0 text-uppercase font-size-11 font-size-lg-20 text-description-text-color font-weight-md-300 font-weight-light'>BIRTH DATE</p>
                    <p className='mb-0 text-capitalize font-weight-normal font-weight-md-400 font-size-14 font-size-lg-24 text-secondary'>{data.dob}</p>
                  </div>
                </div>
                <div className='d-flex flex-column'>
                  <div>
                    <p className='mb-0 text-uppercase font-size-11 font-size-lg-20 text-description-text-color font-weight-md-300 font-weight-light'>MONTHLY INCOME</p>
                    <div className='d-flex'>
                      <p className='mb-0 text-capitalize font-weight-normal font-weight-md-400 font-size-14 font-size-lg-24 text-secondary'>{data.monthly_income}</p>
                    </div>
                  </div>
                  <div className='pt-4'>
                    <p className='mb-0 text-uppercase font-size-11 font-size-lg-20 text-description-text-color font-weight-md-300 font-weight-light'>STATUS</p>
                    <p className='mb-0 text-capitalize font-weight-normal font-weight-md-400 font-size-14 font-size-lg-24 text-secondary'>Insured</p>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

FamilyProtectionCoverageCard.propTypes = {
  list: PropTypes.array.isRequired
}

export default FamilyProtectionCoverageCard
