// React imports
import React,{useState} from "react"
import PropTypes from "prop-types"

// Package Imports
import { Col, Row, Card, Image, Form } from "react-bootstrap"

// Project Imports
import { CustomCheckbox } from "components"

const SelectNeedCard = (props) => {

  const {isSelected,toggleNeedSelection,label,image,uniqueName} = props


  return (
      <Card className="border-0 protection-of-my-family-need-card ">
       <div className={!isSelected ? "select-need-card-overlay" : null}></div>
        <Card.Body className="p-2">
             <div className="d-flex justify-content-between align-items-center">
                <CustomCheckbox
                  isChecked={isSelected}
                  onChange={() => toggleNeedSelection(isSelected)}
                  id={props.id}
                  shape='rounded'
                  title={label}
                  titleStyle='text-primary font-weight-medium font-size-16 font-size-lg-14 pl-2'
                  containerStyle='align-items-start'
                  checkmarkStyle='radio-margin-top'
                  mark='checkmark'
                  // disabled={true}
                  className='select-Need-checkBox'
                />
                <Col className='col-3 text-lg-right'>
                <Image className='select-need-card-img' src={image} />
              </Col>
              </div>
        </Card.Body>
      </Card>
  )
}

SelectNeedCard.propTypes = {
  // image:PropTypes.isRequired,
  label:PropTypes.string.isRequired,
  isSelected:PropTypes.bool.isRequired,
  toggleNeedSelection:PropTypes.func.isRequired,
  uniqueName:PropTypes.string.isRequired
}

export default SelectNeedCard
