// React Imports
import React, { useEffect, useState } from "react";

// package Imports
import { Form } from "react-bootstrap";

// Project Imports
import * as API from "constants/API";
import SPRM_ENUM from "constants/SPRM_constants";
import axios from "axios";
import { observer } from "mobx-react";
import Utils from "../utils";
import StorageKeys from "constants/StorageKeys";

const SprmDetailForm = ({ formController }) => {
  const [pragatiData, setPragatiData] = useState();

  useEffect(() => {
    let agent_id;

    const urlParams = new URLSearchParams(location.search);
    agent_id = urlParams.get(SPRM_ENUM.AGENT_ID)

    if (agent_id === undefined || agent_id === null) {
      agent_id = localStorage.getItem(StorageKeys.AGENT_ID, agent_id)
    }

    localStorage.setItem(StorageKeys.AGENT_ID, agent_id)

    const getPragatiNaInboundData = async () => {
      const response = await axios(API.PRAGATI_INBOUND_DATA + `?agent_id=${agent_id}`);
      console.log("PRAGATI_INBOUND_DATA ::::", response.data);
      setPragatiData(response.data);
      formController.setSpRMValue(response.data);
      localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, null);
      localStorage.setItem(StorageKeys.PRAGATI_HOME_URL, response.data.home_url)
      localStorage.setItem(StorageKeys.PRAGATI_POST_CALL_END_POINT_URL, response.data.post_call_end_point)
      localStorage.setItem(StorageKeys.LOGIN_CODE, response.data.login_code)
      localStorage.setItem(StorageKeys.VENDOR, response.data.vendor_code)
      localStorage.setItem(StorageKeys.SOURCE_FROM, "NA")
      localStorage.setItem(StorageKeys.LOGIN_AGENT_CODE, response.data.login_agent_code)
    };

    getPragatiNaInboundData();
  }, []);

  if (pragatiData === undefined) return null;

  let partnerSectionVisible = formController.$("partner_rm_code").extra.isVisible;
  let spCodeValue = formController.$("pfa_sp_code").value;
  let partnerRmCodeValue = formController.$("partner_rm_code").value;
  let rmCodeValue = formController.$("dmrm_code").value;

  return (
    <div className='mb-5'>
      <div className='row mt-4 align-items-center'>
        <div className='col-12 col-lg-6 mb-2'>
          <p className='pre-need-modal-label font-weight-medium'>{formController.$("pfa_sp_code").label}</p>

          <div className='d-flex align-items-center'>
            <Form.Group className='mb-0 flex-grow-1'>
              <Form.Control
                className='font-size-lg-12 font-size-14 font-weight-light'
                placeholder=''
                disabled={formController.$("pfa_sp_code").disabled}
                value={formController.$("pfa_sp_code").value}
                onChange={(onChangeEvent) => {
                  formController.$("pfa_sp_code").value = onChangeEvent.target.value.toUpperCase();
                  formController.$("pfa_sp_code").onChange;
                }}
                style={formController.$("pfa_sp_code").error ? { border: "1px solid red" } : null}
              />
              {formController.$("pfa_sp_code").error != null ? <p className='font-size-11 font-weight-light text-danger'>{formController.$("pfa_sp_code").error}</p> : null}
            </Form.Group>

            {formController.$("pfa_sp_code").extra.showBtn && (
              <div className=''>
                <button
                  type='submit'
                  disabled={!formController.$("pfa_sp_code").value}
                  className='btn btn-secondary ml-1 modal-cta-btn py-2 py-lg-1 font-size-14 font-size-md-10 font-weight-medium pre-need-search-btn'
                  onClick={() => Utils.checkSpValidationLogic(formController, pragatiData, spCodeValue)}
                >
                  Search
                </button>
              </div>
            )}
          </div>
        </div>

        <div className='col-12 col-lg-6 mb-2'>
          <p className='pre-need-modal-label font-weight-medium'>{formController.$("pfa_sp_name").label}</p>

          <div className='d-flex align-items-center'>
            <Form.Group className='mb-0 flex-grow-1'>
              <Form.Control
                className='font-size-lg-12 font-size-14 font-weight-light'
                placeholder=''
                disabled={formController.$("pfa_sp_name").disabled}
                value={formController.$("pfa_sp_name").value}
                onChange={formController.$("pfa_sp_name").onChange}
                style={formController.$("pfa_sp_name").error ? { border: "1px solid red" } : null}
              />
              {formController.$("pfa_sp_name").error != null ? <p className='font-size-11 font-weight-light text-danger'>{formController.$("pfa_sp_name").error}</p> : null}
            </Form.Group>
          </div>
        </div>

        <div className='col-12 col-lg-6 mb-2'>
          <p className='pre-need-modal-label font-weight-medium'>{formController.$("channel_name").label}</p>

          <div className='d-flex align-items-center'>
            <Form.Group className='mb-0 flex-grow-1'>
              <Form.Control
                className='font-size-lg-12 font-size-14 font-weight-light'
                placeholder=''
                disabled={formController.$("channel_name").disabled}
                value={formController.$("channel_name").value}
                onChange={formController.$("channel_name").onChange}
                style={formController.$("channel_name").error ? { border: "1px solid red" } : null}
              />
              {formController.$("channel_name").error != null ? <p className='font-size-11 font-weight-light text-danger'>{formController.$("channel_name").error}</p> : null}
            </Form.Group>
          </div>
        </div>

        <div className='col-12 col-lg-6 mb-2'>
          <p className='pre-need-modal-label font-weight-medium'>{formController.$("sales_branch").label}</p>

          <div className='d-flex align-items-center'>
            <Form.Group className='mb-0 flex-grow-1'>
              <Form.Control
                className='font-size-lg-12 font-size-14 font-weight-light'
                placeholder=''
                disabled={formController.$("sales_branch").disabled}
                value={formController.$("sales_branch").value}
                onChange={formController.$("sales_branch").onChange}
                style={formController.$("sales_branch").error ? { border: "1px solid red" } : null}
              />
              {formController.$("sales_branch").error != null ? <p className='font-size-11 font-weight-light text-danger'>{formController.$("sales_branch").error}</p> : null}
            </Form.Group>
          </div>
        </div>

        <div className='col-12 col-lg-6 mb-2'>
          <p className='pre-need-modal-label font-weight-medium'>{formController.$("dmrm_code").label}</p>

          <div className='d-flex align-items-center'>
            <Form.Group className='mb-0 flex-grow-1'>
              <Form.Control
                className='font-size-lg-12 font-size-14 font-weight-light'
                placeholder=''
                disabled={formController.$("dmrm_code").disabled}
                value={formController.$("dmrm_code").value}
                onChange={(onChangeEvent) => {
                  formController.$("dmrm_code").value = onChangeEvent.target.value.toUpperCase();
                  formController.$("dmrm_code").onChange;
                }}
                style={formController.$("dmrm_code").error ? { border: "1px solid red" } : null}
              />
              {formController.$("dmrm_code").error != null ? <p className='font-size-11 font-weight-light text-danger'>{formController.$("dmrm_code").error}</p> : null}
            </Form.Group>

            {formController.$("dmrm_code").extra.showBtn && (
              <div className='ml-1'>
                <button
                  type='submit'
                  disabled={!formController.$("dmrm_code").value}
                  className='btn btn-secondary btn-sm modal-cta-btn py-2 py-lg-1 font-size-14 font-size-md-10 font-weight-medium pre-need-search-btn'
                  onClick={() => Utils.checkRmValidationLogic(formController, pragatiData, rmCodeValue)}
                >
                  Search
                </button>
              </div>
            )}
          </div>
        </div>

        <div className='col-12 col-lg-6 mb-2'>
          <p className='pre-need-modal-label font-weight-medium'>{formController.$("dmrm_name").label}</p>

          <div className='d-flex align-items-center'>
            <Form.Group className='mb-0 flex-grow-1'>
              <Form.Control
                className='font-size-lg-12 font-size-14 font-weight-light'
                placeholder=''
                disabled={formController.$("dmrm_name").disabled}
                value={formController.$("dmrm_name").value}
                onChange={formController.$("dmrm_name").onChange}
                style={formController.$("dmrm_name").error ? { border: "1px solid red" } : null}
              />
              {formController.$("dmrm_name").error != null ? <p className='font-size-11 font-weight-light text-danger'>{formController.$("dmrm_name").error}</p> : null}
            </Form.Group>
          </div>
        </div>

        {partnerSectionVisible ? (
          <>
            <div className='col-12 col-lg-6 mb-2'>
              <p className='pre-need-modal-label font-weight-medium'>{formController.$("partner_rm_code").label}</p>

              <div className='d-flex align-items-center'>
                <Form.Group className='mb-0 flex-grow-1'>
                  <Form.Control
                    className='font-size-lg-12 font-size-14 font-weight-light'
                    placeholder=''
                    disabled={formController.$("partner_rm_code").disabled}
                    value={formController.$("partner_rm_code").value}
                    onChange={(onChangeEvent) => {
                      formController.$("partner_rm_code").value = onChangeEvent.target.value.toUpperCase();
                      formController.$("partner_rm_code").onChange;
                    }}
                    style={formController.$("partner_rm_code").error ? { border: "1px solid red" } : null}
                  />
                  {formController.$("partner_rm_code").error != null ? <p className='font-size-11 font-weight-light text-danger'>{formController.$("partner_rm_code").error}</p> : null}
                </Form.Group>

                {formController.$("partner_rm_code").extra.showBtn && (
                  <div className='ml-1'>
                    <button
                      type='submit'
                      disabled={!formController.$("partner_rm_code").value}
                      className='btn btn-secondary btn-sm modal-cta-btn py-2 py-lg-1 font-size-14 font-size-md-10 font-weight-medium pre-need-search-btn'
                      onClick={() => Utils.checkPartnerCodeEditable(formController, pragatiData, partnerRmCodeValue)}
                    >
                      Search
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className='col-12 col-lg-6 mb-2'>
              <p className='pre-need-modal-label font-weight-medium'>{formController.$("partner_branch_name").label}</p>

              <div className='d-flex align-items-center'>
                <Form.Group className='mb-0 flex-grow-1'>
                  <Form.Control
                    className='font-size-lg-12 font-size-14 font-weight-light'
                    placeholder=''
                    disabled={formController.$("partner_branch_name").disabled}
                    value={formController.$("partner_branch_name").value}
                    onChange={formController.$("partner_branch_name").onChange}
                    style={formController.$("partner_branch_name").error ? { border: "1px solid red" } : null}
                  />
                  {formController.$("partner_branch_name").error != null ? <p className='font-size-11 font-weight-light text-danger'>{formController.$("partner_branch_name").error}</p> : null}
                </Form.Group>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default observer(SprmDetailForm);
