// react imports
import React, { Component } from "react"

// package imports
import { Col, Container, Row } from "react-bootstrap"
import { observer } from "mobx-react"
import { toJS } from "mobx"

// project imports
import CurrentCoverageCard from "./components/current-coverage-card/CurrentCoverageCard"
import FamilyProtectionCoverageCard from "./components/family-protection-coverage-card/FamilyProtectionCoverageCard"
import SolutionStore from "./store/SolutionStore"
import ConsolidatedPremiumAmount from "./components/consolidated-premium-amount/ConsolidatedPremiumAmount"
import { CircularProgressbar, TabComponent } from "components"
import * as data from "./solution_screen_structure.js"
import SolutionBenefitsCard from "./components/solution-benefits-card/SolutionBenefitsCard"
import SolutionScreenSingleLaCard from "./components/solution-screen-single-la-card/SolutionScreenSingleLaCard"
import SolutionScreenMulipleLaCard from "./components/solution-screen-multiple-la-card/SolutionScreenMulipleLaCard"
import SolutionScreenLADetails from "./components/solution-screen-LA-details/SolutionScreenLADetails"
import PremiumAndRiderAmountComponent from "modules/optimization/components/premium-and-rider-amount-component/PremiumAndRiderAmountComponent"
import PolicyTimlineTable from "./components/policy-timeline-table/PolicyTimelineTable"
import UnderlyingCard from "./components/underlying-card/UnderlyingCard"
import * as Constants from "constants/Constants";
import Utils from './utils';

import solutionDataStructure from './solution-data'
import CustomerCommunicationDetailModal from "./customerCommunicationDetail/CustomerCommunicationDetails"
import ProductConstants from "constants/ProductConstants"
import savePragatiNAInboundData from "utils/savePragatiNAInboundData"

import './solution.css'

const Solution = observer(
  class Solution extends Component {
    constructor(props) {
      super(props)

      this.state = {
        // dataList: data.data,
        protectionValues: [
          { title: "Zindagi Plus", percentValue: "40", ammount: "5,000" },
          { title: "Wealth Ultima", percentValue: "75", ammount: "36,000" },
          { title: "Simply Protect", percentValue: "70", ammount: "12,000" },
        ],
        showConsolidatedPremiumModal: false,
        // tabList: [
        //   {
        //     label: "Self",
        //     value: "SELF",
        //     solutionBenifits: [
        //       {
        //         cardType: "death_cover",
        //         showBenefit: true,
        //         amount: "2 cr",
        //         year_count: "2",
        //       },
        //       {
        //         cardType: "additional_death_cover",
        //         showBenefit: true,
        //       },
        //       {
        //         cardType: "premium_waiver",
        //         showBenefit: true,
        //       },
        //       {
        //         cardType: "tax_saving",
        //         showBenefit: true,
        //       },
        //       {
        //         cardType: "early_return",
        //         showBenefit: true,
        //       },
        //       {
        //         cardType: "flexible_payout",
        //         showBenefit: true,
        //       },
        //     ],
        //   },
        //   {
        //     label: "Spouse",
        //     value: "SPOUSE",
        //   },
        // ],
        tabList: [],
        selectedTabIndex: "0",
        seletedTabData: {},
      }
      this.setSelectedTabIndex = this.setSelectedTabIndex.bind(this)
    }

    openConsolidatedPremiumModal = (e) => {
      this.setState({ showConsolidatedPremiumModal: true })
    }

    closeConsolidatedPremiumModal = (e) => {
      this.setState({ showConsolidatedPremiumModal: false })
    }

    async componentDidMount() {
      window.scrollTo(0, 0)
      await savePragatiNAInboundData(location)
      await SolutionStore.fetchSolutionData()
      this.setState({ seletedTabData: toJS(SolutionStore.user_list_data[0]), tabList: toJS(SolutionStore.user_list_data) })
      SolutionStore.setSeletedSolutionTabData(toJS(SolutionStore.user_list_data[0]))
    }

    setSelectedTabIndex(tabIndex) {
      this.setState({ selectedTabIndex: tabIndex })
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevState.selectedTabIndex != this.state.selectedTabIndex) {
        toJS(SolutionStore.user_list_data).forEach((item, index) => {
          if (index.toString() === this.state.selectedTabIndex) {
            this.setState({ seletedTabData: item })
            SolutionStore.setSeletedSolutionTabData(item)
          }
        })
      }
    }

    laNameFunction(singleLA) {



      let { la_salutation, la_name, la_family_member_type } = singleLA

      if (la_name) return la_name
      
      return la_family_member_type
    }

    render() {
      // const { showConsolidatedPremiumModal } = this.state
      // const dataList = toJS(SolutionStore.user_list_data)
      // const needDataList = toJS(SolutionStore.needDataList)
      // const totalPremium = toJS(SolutionStore.totalPremium)
      // const totalTax = toJS(SolutionStore.totalTax)

      let totalPayableAmount = 0

      const coverageArray = this.state.seletedTabData?.coverage

      let details

      if (coverageArray) {
        let solutionDetails = coverageArray.map((item) => {
          let needTitle = item.need_title;
          let laList = item.life_assured_list.map((singleLA) => {
            let productDetails = []
            singleLA.product_details.length > 0 && singleLA.product_details.forEach((item) => {
              let inputMode = item.data.InputString.find((item) => item.Key == "@INPUT_MODE")?.Value
              let riderBasePremium = Math.round(item.data.InputString.find((item) => item.Key == "@RD_TOTAL_PREM_WOTAX")?.Value) || 0
              let product = {
                productName: item.product_name,
                targetAmount: Utils.fetchTargetAMount(item.product_name, item.data.OutputJSON, item.data.InputString),
                totalPremiumAmount: Utils.fetchTargetPremiumAmount(item.product_name, riderBasePremium, item.data.OutputJSON, inputMode, item.data.InputString),
                recommendedAmount: item.recommended_sum_assured,
                basePremium: Math.round(Utils.fetchBasePremium(item.product_name, item.data.OutputJSON)),
                gstPercent: (Utils.fetchGstPercent(item.product_name, item.data.OutputJSON) * 100).toFixed(2),
                gstAmount: Math.round(Utils.fetchGstAmount(item.product_name, item.data.OutputJSON)),
                riderGstPercentage: Constants.RIDER_GST_PERCENT,
                riderGstAmount: Math.round(Utils.fetchRiderGSTAmount(item.product_name,item.data.OutputJSON,item.data.InputString)),
                riderBasePremium: riderBasePremium,
                inputMode: Utils.fetchInputMode(inputMode),
                totalPremiumPayable: Utils.fetchTotalPremiumPayable(item.product_name, item.data.OutputJSON[0][0]),
                inputMode: Number(inputMode)
              }

              let basePremium = product.basePremium;
              let gstAmount = product.gstAmount ? product.gstAmount : 0;
              let riderGstAmount = product.riderGstAmount ? product.riderGstAmount : 0;

              if (Number(inputMode) === 4) {
                totalPayableAmount = (totalPayableAmount + basePremium + gstAmount + riderBasePremium + riderGstAmount) * 2
              } else {
                totalPayableAmount = totalPayableAmount + basePremium + gstAmount + riderBasePremium + riderGstAmount
              }
              productDetails.push(product)
            })
            return { laName: this.laNameFunction(singleLA), productDetails: productDetails }
          })
          return { needTitle, laList }

        })

        details = solutionDetails
      }

      if (!details) return null

      // MODAL_PREM, TAX_MP,RIDER_MODAL_PREM, RIDER_TOTAL(rider GST)

      return (
        <Container className="solution-container">
          <Row>
            <CustomerCommunicationDetailModal {...this.props} />

            <Col xs="12">
              <p className="mb-0 text-primary font-size-18 font-weight-medium consolidated-solution">
                Consolidated Solution Offering
              </p>
              <p className="graph-grey-800-color font-size-12 consolidated-view font-weight-300">
                Consolidated view of the optimized solution.
              </p>
            </Col>

            <Col xs="12 solution-tabs">
              <TabComponent
                tabList={toJS(SolutionStore.user_list_data)}
                setSelectedTabIndex={this.setSelectedTabIndex}
              // children={<DComp selectedTabIndex={this.state.selectedTabIndex} tabList={this.state.tabList} />}
              />
            </Col>

            <Col xs="12">
              <CurrentCoverageCard
                selectedTabData={this.state.seletedTabData}
                totalPayableAmount={totalPayableAmount}
              />
            </Col>

            <Col xs="12" md="12" lg="12" className="mt-5 mx-auto">
              <p className="text-primary font-weight-bold font-size-16 font-size-lg-18 mb-3 pb-0 pl-4">
                Solution Coverage
              </p>



              {details.map((item) => {

                if (item.laList.length >= 2) {
                  return <SolutionScreenMulipleLaCard
                    CardName={item.needTitle}
                    detail={item}
                  />
                }
                return null

              })}

              <Row className="px-2 d-flex flex-column flex-lg-row  mt-2">
                {details.map((singleItem, index) => {
                  if (singleItem.laList.length < 2) {

                    return <SolutionScreenSingleLaCard
                      singleLaCard={true}
                      key={index}
                      CardName={singleItem.needTitle}
                      detail={singleItem}
                    />
                  }
                  return null
                })}
              </Row>


              {/* <SolutionScreenMulipleLaCard
                CardName="Grow my Money"
                percentValue="30"
                totalPercentValue="90"
              /> */}
            </Col>

            {/* <Col xs="12" md="5" lg="4" className="mt-3">
              <SolutionScreenSingleLaCard
                CardName="Grow my Money"
                percentValue="75"
              />
            </Col>

            <Col xs="12" md="5" lg="4" className="mt-3">
              <SolutionScreenSingleLaCard
                CardName="Grow my Money"
                percentValue="75"
              />
            </Col> */}

            {(this.state.seletedTabData?.solutionBenifits && this.state.seletedTabData?.solutionBenifits.length !== 0) &&
              <Col xs="12" className="mt-5 d-flex flex-column">
                <p className="text-primary font-weight-bold font-size-16 font-size-lg-18 mb-3 pb-0 pl-4">
                  Solution Benefits
                </p>
                <div className="solution-card-col">
                  <Row className="solution-mobile-bg-style">
                    {this.state.seletedTabData?.solutionBenifits?.map((obj, index) => {
                      return (
                        <Col key={index} className="col-md-6 col-12 d-flex">
                          <div className="solution-desktop-bg-style d-inline-block w-100 px-sm-3 pt-4 pt-lg-4 pb-lg-1">
                            <SolutionBenefitsCard
                              solutionData={obj}
                            />
                          </div>
                        </Col>
                      )
                    })}
                  </Row>
                </div>
              </Col>
            }

            {/* {this.state.seletedTabData.map((value) => ( */}
            <Col
              xs="12"
              lg="12"
              className="protection-coverage-card-col my-5 mb-lg-5"
            >
              <p className="text-primary font-weight-bold font-size-16 font-size-lg-18 mb-3 pb-0 pl-4">
                Solution Timeline
              </p>
              <PolicyTimlineTable data={this.state.seletedTabData || []} />
            </Col>
            {/* ))} */}

            <Col
              xs="12"
              lg="12"
              className="protection-coverage-card-col mb-4 mb-lg-5 d-flex flex-column"
            >
              <p className="text-primary font-weight-bold font-size-16 font-size-lg-18 mb-3 pb-0 pl-4">
                Underlying Products
              </p>
              <div className="under-lying-card-container bg-white d-inline-block w-100 px-3 px-lg-3 pt-3 pb-1 pt-lg-4 mb-lg-0">
                {this.state.seletedTabData?.productList?.map((value, index) => (
                  <UnderlyingCard key={index} productData={value} />
                ))}
              </div>
            </Col>
          </Row>
          {/* <ConsolidatedPremiumAmount showModal={showConsolidatedPremiumModal} onHide={this.closeConsolidatedPremiumModal} needDataList={needDataList} totalPremium={totalPremium} totalTax={totalTax}></ConsolidatedPremiumAmount> */}

          {/* <TabComponent
            tabList={this.state.tabList}
            setSelectedTabIndex={this.setSelectedTabIndex}
              // children={<DComp selectedTabIndex={this.state.selectedTabIndex} tabList={this.state.tabList} />}
              /> */}

        </Container>
      )
    }
  }
)

export default Solution
