import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import OptimizationStore from '../../../store/OptimizationStore';
import isTotalFundCountValid from 'utils/isTotalFundCountValid';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    // tru or false
    errorMessage = [];

    // Validate Minimum Premium
    minimumPremium = (form, data) => {
        // let frequency = form.$('payment-frequency').value.id
        // let premium = form.$('premium').value

        // switch (frequency) {
        //     case '1':
        //         if (premium < 48000) {
        //             this.errorMessage.push('Minimum Annual premium is 48,000')
        //         }
        //         break;

        //     case '2':
        //         if (premium < 36000) {
        //             this.errorMessage.push('Minimum Half-Yearly premium is 36,000')
        //         }
        //         break

        //     case '3':
        //         if (premium < 18000) {
        //             this.errorMessage.push('Minimum Quarterly premium is 18,000')
        //         }
        //         break;

        //     case '4':
        //         if (premium < 6000) {
        //             this.errorMessage.push('Minimum Monthly premium is 6,000')
        //         }
        //         break;
        // }
    }

    // Maximum Policy Term Validation
    maximumPTValidation = (form, data) => {
        // let age = parseInt(data.data.age);
        // let pt = form.$('policy-term').value.id;
        // let ppt = form.$('premium-term').value.id;

        // let maximumOption1Age = 70 - age;
        // if (ppt == 5 || ppt == 6) {
        //     if (pt > maximumOption1Age) {
        //         this.errorMessage.push('You can only select Option1 for this PT-PPT Combination')
        //     }
        // }
    }

    isLittleChampAvailable = (form, data) => {
        // let age = data.data.age;
        // let policyOption = form.$('policy-option').value.id;
        // let pt = parseInt(form.$('policy-term').value.id);
        // let ppt = parseInt(form.$('premium-term').value.id);
        // let isLittleChampSelected = form.$('little-champ-benefit').value.id == '3';
        // let maturityAge = age + pt
        // let option2Age = 100 - age;

        // if (policyOption == 1) {
        //     if (pt <= 30) {
        //         if (pt == ppt) {
        //             // regular pay
        //             if (maturityAge > 30 && isLittleChampSelected) {
        //                 this.errorMessage.push('Little Champ benefit is only available if Maturity Age is less than or equal to 30 years')
        //             }

        //         } else {
        //             // limited pay
        //             if (ppt > (30 - age) && isLittleChampSelected) {
        //                 this.errorMessage.push('You are not eligible for Little Champ Benefit, kindly revise the age')
        //             }
        //         }
        //     } else {
        //         if (pt == ppt) {
        //             // regular pay
        //             if (isLittleChampSelected) {
        //                 this.errorMessage.push('You cant select Little Champ Benefit, kindly revise the PT-PPT')
        //             }
        //         } else {
        //             // limited pay
        //             if (ppt > 30 - age && isLittleChampSelected) {
        //                 this.errorMessage.push('You cant select Little Champ Benefit, kindly revise the PT-PPT')
        //             }
        //         }
        //     }

        // } else {
        //     if (pt == option2Age) {
        //         if (pt == ppt) {
        //             // regular pay
        //             this.errorMessage.push('You are not eligible for Little Champ Benefit')
        //         } else {
        //             // limited pay
        //             if (ppt > (30 - age) && isLittleChampSelected) {
        //                 this.errorMessage.push('You are not eligible for Little Champ Benefit, kindly revise the age')
        //             }
        //         }
        //     }
        // }
    }

    // PT, PPT validation
    validatePPT = (formRef, data) => {
        // let age = data.data.bi_request.LI_ENTRY_AGE
        // let pt = formRef.$('policy-term').value.id;
        // let ppt = formRef.$('premium-term').value.id;
        // let option = formRef.$('policy-option').value.id;

        // if (option == 1) {
        //     if (pt == (100 - age)) {
        //         this.errorMessage.push(errorMessageKeys.normalPPT)
        //     }
        //     if (pt <= 30 && ppt > pt) {
        //         this.errorMessage.push(errorMessageKeys.normalPPT)
        //     }
        //     if (pt > 30) {
        //         if (ppt != pt && ppt > 30) {
        //             this.errorMessage.push(errorMessageKeys.normalPPT)
        //         }
        //     }
        // }

        // if (option == 2) {
        //     if (pt != (100 - age)) {
        //         this.errorMessage.push(errorMessageKeys.normalPPT)
        //     }

        //     if ((ppt < 7 || ppt > 30) && ppt != pt) {
        //         this.errorMessage.push(errorMessageKeys.normalPPT)
        //     }
        // }
    }

    // Sub Product Validation
    subProductValidation = (formRef, data) => {
        // let product_name = data.data.product_name;
        // let swp_option = formRef.$('systematic-withdrawal-option').value.id
        // let little_champ_option = formRef.$('little-champ-benefit').value.id
        // if (product_name == 'Systematic Withdrawal Plan (Wealth Ultima)') {
        //     if (swp_option != '5') {
        //         this.errorMessage.push(errorMessageKeys.sub_product_validation.SWP);
        //     }
        // }

        // if (product_name == 'Little Champ (Wealth Ultima)') {
        //     if (little_champ_option != 3) {
        //         this.errorMessage.push(errorMessageKeys.sub_product_validation.little_champ)
        //     }
        // }
    }

    // 100% Fund Allocation validation
    fundAllocationValidations = (formRef) => {
        // let fundStratergyElem = formRef.$("fund-strategy");
        // let fundsList = fundStratergyElem.extra.fundList;
        // let currentFundStratergy = fundStratergyElem.value.id;
        // let isCountValid = false;

        // // Return for Life Stage and duration based Strategy
        // if (currentFundStratergy != 1) return

        // isCountValid = isTotalFundCountValid(fundsList);

        // if (!isCountValid) {
        //     this.errorMessage.push("Total Fund allocation must be 100%")
        // }
    }

    validateDeathBenefitAmount = (formRef, data) =>{
    //   let bi_request_data = data.data.bi_request;
    //   let laAge = typeof(bi_request_data["LI_ENTRY_AGE"]) == "string" ? parseInt(bi_request_data["LI_ENTRY_AGE"]) : bi_request_data["LI_ENTRY_AGE"];
    //   let ptValue = typeof(bi_request_data["PR_PT"]) == "string" ? parseInt(bi_request_data["PR_PT"]) : bi_request_data["PR_PT"];
    //   let annualPremium = typeof(bi_request_data["PR_ANNPREM"]) == "string" ? parseInt(bi_request_data["PR_ANNPREM"]) : bi_request_data["PR_ANNPREM"];
    //   let minDeathBenefitMultiplyingFactor = Math.max(7,(ptValue/4));
    //   let minDeathBenefitAmount = minDeathBenefitMultiplyingFactor * annualPremium;
    //   let maxDeathBenefitAmount = 10 * annualPremium;
    //   let deathBenefitAmountValue = typeof(formRef.$('death-benefit-amount').value) == "string" ? parseInt(formRef.$('death-benefit-amount').value) : formRef.$('death-benefit-amount').value;
    //   if(laAge >= 45) {
    //     if(deathBenefitAmountValue < minDeathBenefitAmount){
    //       this.errorMessage.push(`Minimum Death Benfit amount should be ${minDeathBenefitAmount}`)
    //     }
    //     else if (deathBenefitAmountValue > maxDeathBenefitAmount){
    //       this.errorMessage.push(`Maximum Death Benfit amount should be ${maxDeathBenefitAmount}`)
    //     }
    //   }
    }
}
export default new Validator();