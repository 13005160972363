import calculateRiderPremium from "utils/calculateRiderPremium";
import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from "./validator";

class Utils {
  generatePT() {
    let pt = [];
    for (let i = 5; i <= 30; i++) {
      pt.push({ id: i, label: "" + i });
    }
    return pt;
  }

  generatePPT = () => {
    let ppt = [];
    for (let i = 5; i <= 30; i++) {
      ppt.push({ id: i, label: "" + i });
    }
    return ppt;
  };

  updateBi(key, value, productId, formRef, productData) {
    let biData = OptimizationStore.fetchBiRequest(productId, productData?.data?.family_product_id);
    let biRequest = biData.data.bi_request;

    switch (key) {
      case keys.FREQUENCY:
        biRequest.INPUT_MODE = value;
        break;

      case keys.PLAN_OPTION:
        biRequest.PlanOption = value;
        break;

      case keys.PR_PT:
        biRequest.PR_PT = value;
        biRequest.PR_PPT = value;
        break;

      case keys.PR_PPT:
        biRequest.PR_PPT = value;
        break;

      case keys.PREMIUM:
        biRequest.PR_ANNPREM = value;
        break;

      default:
        return;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return;
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value);
  }

  getTotalPrice = (biResponse) => {
    try {
      return Number(biResponse.MODAL_PREM_TAX);
    } catch (e) {
      return 0;
    }
  };

  fetchPolicyTimeline(element) {
    let data = {
      youPay: Math.round(element.ANN_PREM),
      youGet: 0,
      lifeCover: Math.round(element.CI_SA),
    };
    return data;
  }

}

export default new Utils();
