import React, { Component } from 'react';

import { Modal, Row, Col, Image } from 'react-bootstrap';
import { Button as UiButton } from '../../../components';

import { WarningIcon } from 'constants/Images';

import './warning-modal.css';

class WarningModal extends Component {
    render() {
        const { onHideModal, showModal, title, subTitle } = this.props;
        return (
            <Modal show={showModal} onHide={onHideModal} centered backdrop='static' className='warning-modal'>
                <Modal.Header closeButton className='border-bottom-0 pb-0'>
                </Modal.Header>
                <Modal.Body className='py-0 pl-4 pr-5'>
                    <React.Fragment>
                        <Row>
                            <Col className='col-md-11 col-11'>
                                <div className='d-flex align-items-center'>
                                    <Image className='warning-icon mt-md-1' src={WarningIcon} alt='share-icon' />
                                    <span className='padding-left-11 font-size-md-24 font-size-16 font-weight-bold text-primary-title-label-color'>{title}</span>
                                </div>

                                <div className='padding-left-38'><span className='font-size-md-20 font-size-16 font-weight-300 subtitle'>{subTitle}</span></div>
                                <UiButton className='margin-left-37 font-size-16 mt-4 w-100 padding-top-bottom-18' variant='secondary' buttonText='Okay' onClick={onHideModal} />
                            </Col>
                        </Row>
                    </React.Fragment>
                </Modal.Body>
            </Modal>
        );
    }
}

export default WarningModal;
