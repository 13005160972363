import React from 'react'
import { Button } from 'react-bootstrap'
import './next-button.css'

function NextButton(props) {
  const { onClick, buttonText, prefixIcon, ...prop  } = props;
  return (
    <Button type='button' className='font-weight-light font-size-14 font-size-lg-12 px-1 w-25 next-button' variant='secondary' onClick={ ()=> onClick() } {...prop}>
      {prefixIcon}
      {buttonText || 'Next'}
    </Button>
  )
}

export default NextButton
