import React, { Component } from 'react';
import { Col, Row, Card, Modal } from 'react-bootstrap';

import { CustomCheckbox, BottomsheetDropdown } from '../../../../../../../components';
import { InfoArrowBlackImg } from "constants/Images";

import './additional-cards.css';
import { observer } from 'mobx-react';
import UiButton from 'components/ui/button/Button';

class AdditionalCards extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAdditionalBenefitModalOpen: false,
      modalTitle: "",
      modalDescription: "",
    }
  }

  showBenefitModal = (title, description) => {
    this.setState({
      isAdditionalBenefitModalOpen: true,
      modalTitle: title,
      modalDescription: description,
    })
  }

  hideBenefitModal = () => {
    this.setState({ isAdditionalBenefitModalOpen: false })
  }

  render() {
    const { form, data } = this.props
    return (
      <>
      <div className='additional-cards'>
        <p className='mb-2 pb-1 font-size-14 font-weight-medium benefits-section-title'>Additional Benefits</p>
        <Card>
          <Card.Body>
            <Row
              
            >
              <Col className="col-9">
                <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                  <CustomCheckbox
                    shape="rounded"
                    title={form.$("family-income-benefit-option").label}
                    titleStyle=' font-weight-medium font-size-14 pl-2 text-primary'
                    isChecked={form.$("family-income-benefit-option").value}
                    onChange={() =>
                      form
                        .$("family-income-benefit-option")
                        .extra.changeBenefitStatus(form, data)
                    }
                    mark="checkmark"
                    disabled = {form.$("family-income-benefit-option").disabled}
                  />
                  <img
                    className={`pl-2 ${form.$("family-income-benefit-option").disabled
                      ? "inf0-icon-disable"
                      : ""
                      }`}
                    src={InfoArrowBlackImg}
                    alt="info"
                    onClick={() => this.showBenefitModal(form.$("family-income-benefit-option").label, form.$("family-income-benefit-option").extra.description)}
                  />
                </div>
                {form.$("family-income-benefit-option").value ?
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light mt-2'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey="label"
                    bottomsheetTitle=""
                    menuList={
                      form.$("family-income-benefit-option-dropdown").extra
                        .optionList
                    }
                    value={form.$("family-income-benefit-option-dropdown").value}
                    onSelect={
                      form.$("family-income-benefit-option-dropdown").onChange
                    }
                  />
                  : null
                }
              </Col>
              <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                {form.$("family-income-benefit-option").value ? (
                  <React.Fragment>
                    <div className="d-flex flex-column align-items-center">
                      <p className="mb-0 font-weight-300 font-size-20 rider-amount">
                        {/* {global.toINR(fetchRiderPremiumValue(data, form.$("family-income-benefit-option").extra.rider_name))} */}
                      </p>
                    </div>
                    <p className="mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color"></p>
                  </React.Fragment>
                ) : (
                  <p className="mb-0 font-weight-300 font-size-14 text-center description-text-color">
                    Not <br /> Selected
                  </p>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>

      <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          className="rider-info-modal"
          show={this.state.isAdditionalBenefitModalOpen}
          keyboard={false}
        >
          <Modal.Body className="text-center text-primary-title-label-color px-4 pt-4 pb-0">
            <React.Fragment>
              <h3 className="font-weight-medium font-size-22 font-size-md-32 mb-3">
                {this.state.modalTitle}
              </h3>
              <p className="font-weight-normal font-size-20 font-size-md-20 mb-0">
                {this.state.modalDescription}
              </p>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer className="d-block p-4 border-0">
            <Row className="modal-footer-row">
              <React.Fragment>
                <Col xs="12">
                  <UiButton
                    onClick={this.hideBenefitModal}
                    className="font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0"
                    variant="secondary"
                    buttonText="OKAY"
                  />
                </Col>
              </React.Fragment>
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default observer(AdditionalCards)
