
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {
  console.log("PROPS>>>>>>", props)


  formRef.$('payment-frequency').observe({
    key: 'value',
    call: ({ field }) => {
      let premium = formRef.$('premium').value
      let frequency = field.value.id
      let data = {
        premium: premium,
        frequency: frequency
      }
      Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)
    }
  })

  formRef.$('policy-term').observe({
    key: 'value',
    call: ({ field }) => {
      console.log('Value is ', field.value)
      Utils.updateBi(keys.PR_PT, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('premium-term').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.PR_PPT, field.value.id, props.data.product_id, formRef, props)
    }
  })

  // Addtional Rider

  formRef.$('ADB').observe({
    key: 'value',
    call: ({ field }) => {
      let adbValue = formRef.$('ADB-dropdown').value
      Utils.updateBi(keys.ADB_RIDER, adbValue, props.data.product_id, formRef, props)
    }
  })

  formRef.$('ATPDRider').observe({
    key: 'value',
    call: ({ field }) => {
      let atpdValue = formRef.$('ATPDRider-dropdown').value
      Utils.updateBi(keys.ATPD_RIDER, atpdValue, props.data.product_id, formRef, props)
    }
  })

  formRef.$('CriticalIllnessRider').observe({
    key: 'value',
    call: ({ field }) => {
      let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
      Utils.updateBi(keys.Critical_ILLNESS_RIDER, criticalIllness, props.data.product_id, formRef, props)
    }
  })

  formRef.$('IncomeBenefitRider').observe({
    key: 'value',
    call: ({ field }) => {
      let incomeBuilder = formRef.$('IncomeBenefitRider-dropdown').value
      Utils.updateBi(keys.INCOME_BENEFIT_RIDER, incomeBuilder, props.data.product_id, formRef, props)
    }
  })

  formRef.$('PayorWaiverBenefitRider-dropdown').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.PWB_RIDER, field.value.id, props.data.product_id, formRef, props)
    }
  })

}

export default FormObserver