import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { CustomRadioButton } from '../../../../components'

import './change-product-reason-modal.css'

import PropTypes from 'prop-types';
import OptimizationStore from "modules/optimization/store/OptimizationStore";

class ChangeProductReasonModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      reason: '',
      isReason: false,
      reasonList: [],
      selectedReasonVal: {}
    }
    this.onSelectProductModalbtnClick = this.onSelectProductModalbtnClick.bind(this)
  }

  async componentDidMount() {
    this.setState({ isReason: false })
    let reasonList = await OptimizationStore.fetchReasonList()
    this.setState({ reasonList: reasonList })
  }

  onSelectReason(selectedReason) {
    this.setState({ selectedReasonVal: selectedReason });
    OptimizationStore.selectedReason = selectedReason
  }

  onSelectProductModalbtnClick() {
    let selectedReason = this.state.selectedReasonVal
    selectedReason.other_reason_description = this.state.reason
    OptimizationStore.selectedReason = selectedReason

    if (this.state.selectedReasonVal.label == 'Other reason') {
      this.setState({ isReason: this.state.reason == '' ? true : false })
      if (this.state.reason !== '') {
        OptimizationStore.fetchChangeProductList(this.props.data)
      }
    } else {
      OptimizationStore.fetchChangeProductList(this.props.data)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.selectedReasonVal.label &&  this.state.selectedReasonVal.label && prevState.selectedReasonVal.label === this.state.selectedReasonVal.label) {
      this.setState({ selectedReasonVal: {} })
    }
  }
  render() {

    // const { selectedMembers } = this.state;
    let { show, onHide } = this.props;
    const { selectedReasonVal } = this.state
    // console.log('selected reson val>> ', selectedReasonVal);
    return (
      <div>

        <Modal
          className='change-product-reason-modal left-bottom-swipe-modal'
          show={show}
          backdrop='static'
          onHide={() => OptimizationStore.isReasonModalOpen = false}
          keyboard={false}
        >
          <Modal.Header closeButton className='border-0 shadow-sm'>
            <Modal.Title className='font-size-20 font-size-md-24 primary-title-label-color w-100 text-center align-self-center pl-5 font-weight-bold'>
              Change Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className=''>
            <h2 className='font-weight-400 primary-title-label-color font-size-20 font-size-md-24 change-product-reason-info-title mb-3'>Please select a reason for changing the current product.</h2>
            {this.state?.reasonList?.map((value, index) => (
              <React.Fragment key={index}>
                <CustomRadioButton
                  id={value.id}
                  shape='rounded'
                  title={value.label}
                  groupName='reasons'
                  containerStyle='mb-0 mt-3 w-100'
                  titleStyle='text-primary-title-label-color font-weight-light text-wrap font-size-18 font-size-md-18 pl-2'
                  checkmarkPosition='mt-1'
                  isChecked={selectedReasonVal?.id == value.id}
                  mark='checkmark'
                  onChange={() => this.onSelectReason(value)}
                />
                {/* {if(value.reason == 'otherreason'){
                  return <textarea rows='4'/>;
                }} */}
              </React.Fragment>
            ))}
            {selectedReasonVal?.label == 'Other reason' &&
              <textarea className={`other-reason-textarea mt-2 mt-md-3 text-primary-title-label-color font-size-14 font-size-18 font-weight-light ${(this.state.isReason && selectedReasonVal?.label == 'Other reason') ? 'validation-border' : ''}`} placeholder='Please type in your reasons....' rows='6' value={this.state.reason} onChange={e => this.setState({ reason: e.target.value })} />
            }
            {(this.state.isReason && selectedReasonVal?.label == 'Other reason') &&
              < p className='validation-msg mt-1 mb-0 font-size-14 align-items-start d-flex'>Please enter your response</p>
            }
          </Modal.Body>
          <Modal.Footer className='change-product-reason-modal-footer-btn-group'>
            <Button variant='secondary' className='font-size-18 ml-1'  disabled={Object.keys(selectedReasonVal).length === 0} onClick={this.onSelectProductModalbtnClick}>
              Select Product
            </Button>
            <Button variant='outline-secondary' className='font-size-18 mr-1' onClick={() => OptimizationStore.isReasonModalOpen = false}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

      </div >
    )
  }
}

ChangeProductReasonModal.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default ChangeProductReasonModal
