import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from "./validator";

class Utils {
  getPolicyTermList = () => {
    let ptList = []
    for (let i = 10; i <= 30; i++) {
      ptList.push({
        id: i,
        label: '' + i
      })
    }
    return ptList
  }
  getPremiumTermList = (pt, age) => {
    let premiumTermList = [
      {
        id: 1,
        label: "1"
      },
      {
        id: 5,
        label: "5"
      },
    ];
    for (let i = 10; i <= 30; i++) {
      premiumTermList.push({
        id: i,
        label: '' + i
      })
    }
    return premiumTermList;
  };

  getTotalPrice = (biResponse, biRequest) => {
    try {
      return Number(biResponse?.PREM_DISPLAY);
    } catch (e) {
      return 0;
    }
  };

  getRiderTax = (data) => {
    let totalRider = 0;
    data?.parsedResponse?.inputValidationStatus?.map((item) => {
      totalRider += Number(item.Tax);
    });
    return totalRider;
  };

  updateBi = (key, value, productId, formRef, productData) => {
    let biData = OptimizationStore.fetchBiRequest(productId)
    let biRequest = biData.data.bi_request

    switch (key) {
      case keys.LI_SMOKE:
        biRequest[keys.LI_SMOKE] = value;
        break;

      case keys.FREQUENCY:
        biRequest[keys.FREQUENCY] = value.frequency;
        break;

      case keys.Sum_Assured:
        biRequest[keys.Sum_Assured] = value;
        break;

      case keys.PR_PT:
        biRequest[keys.PR_PT] = value;
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value;
        break;

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = (value * 100000).toString();
        } else {
          biRequest[keys.ADB_RIDER] = "";
        }
        break;

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = (value * 100000).toString();
        } else {
          biRequest[keys.ATPD_RIDER] = "";
        }
        break;

      case keys.Critical_ILLNESS_RIDER:
        if (value) {
          biRequest[keys.Critical_ILLNESS_RIDER] = (value * 100000).toString();
        } else {
          biRequest[keys.Critical_ILLNESS_RIDER] = "";
        }
        break;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);
    // if (errorMessage.length > 0) {
    //   return;
    // }

  };

  getAdbList = () => {
    let list = [];
    for (let index = 0; index <= 100; index = index + 5) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + " L",
        });
      } else {
        list.push({
          id: index,
          label: 1 + " Cr.",
        });
      }
    }
    return list;
  };

  getAtpdList = () => {
    return this.benefitAmountList(100000, 1000000);
  };

  getCriticalIllnessList = () => {
    return this.benefitAmountList(100000, 500000);
  };

  benefitAmountList = (min, max) => {
    let values = [];

    max = Math.round(max / 100000);
    for (let i = 1; i <= max; i++) {
      values.push({ id: i, label: i + " L" });
    }
    return values;
  };

  fetchPolicyTimeline(element) {
    let data = {
      youPay: Math.round(element.MODAL_PREM_TAX),
      youGet: 0,
      lifeCover: Math.round(element.DB_G),
      isMilestone: false,
      premiumAmt: Math.round(element.MODAL_PREM)
    }
    return data
  }
}

export default new Utils();
