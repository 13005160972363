// Project Imports
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import ProductNames from "constants/ProductNames";
import ProductPackageId from "constants/ProductPackageId";
import { MONTHLY_MODAL_FACTOR,QUATERLY_MODAL_FACTOR,SEMI_ANNUAL_MODAL_FACTOR,ANNUAL_MODAL_FACTOR } from "constants/Constants";
import gisUtils from "modules/optimization/components/products/GIS/utils"

const prefillDeathCoverGraphSliderAllAmounts = (productData) => {
  console.log("prefillDeathCoverGraphSliderAllAmounts PRODUCTDATA", productData);

  let isTargetAmountUpdated = false;
  let recommendedTargetValue = 0;
  let targetValue = 0;
  let minTargetValue = 0;
  let maxTargetValue = 0;
  let selectedPremium = 0;

  // For Without tab products
  if (!Array.isArray(productData.BI)) {
    switch (productData.product_id) {
      case ProductNames.criticarePlus:
        targetValue = productData?.firstYearResponse?.CI_SA ? Math.round(productData?.firstYearResponse?.CI_SA) : productData?.bi_request?.PR_SA;
        selectedPremium = productData?.firstYearResponse?.MODAL_PREM ? Math.round(productData?.firstYearResponse?.MODAL_PREM) : productData?.bi_request?.PR_ANNPREM;
        recommendedTargetValue = productData?.recommended_sum_assured;
        minTargetValue = productData?.minimum_sum_assured;
        maxTargetValue = productData?.maximum_sum_assured;
        break;

      case ProductNames.totalProtectPlus:
        // ChildsFutureProtectBenefit	Pass ”20” for Yes; ”21” for No
        targetValue = productData?.firstYearResponse?.SUMASSURED_DEATH ? Math.round(productData?.firstYearResponse?.SUMASSURED_DEATH) : productData?.bi_request?.PR_SA;
        // if(productData?.bi_request?.ChildsFutureProtectBenefit == "20"){
        //   selectedPremium = productData?.firstYearResponse?.MODAL_PREM_1
        // }
        selectedPremium = productData?.firstYearResponse?.MODAL_PREM ? Math.round(productData?.firstYearResponse?.MODAL_PREM) : productData?.bi_request?.PR_ANNPREM;
        recommendedTargetValue = productData?.recommended_sum_assured;
        minTargetValue = productData?.minimum_sum_assured;
        maxTargetValue = productData?.maximum_sum_assured;
        break;

      default:
        targetValue = productData?.firstYearResponse?.SA ? Math.round(productData?.firstYearResponse?.SA) : productData?.bi_request?.PR_SA;
        selectedPremium = Math.round(productData?.firstYearResponse?.MODAL_PREM) ? Math.round(productData?.firstYearResponse?.MODAL_PREM) : Math.round(productData?.bi_request?.PR_ANNPREM);
        recommendedTargetValue = productData?.recommended_sum_assured;
        minTargetValue = productData?.minimum_sum_assured;
        maxTargetValue = productData?.maximum_sum_assured;
        break;
    }
  } else {
    // For With tabs products

    // Current props data
    let selectedRiskData = productData.BI.find((item) => item.risk === productData.selectedRisk);
    let lastYearResponseBiJson2 = selectedRiskData?.parsedResponse;

    if (!selectedRiskData?.isValidationExist) {
      switch (selectedRiskData?.product_id) {
        case ProductNames.easyPension:
          targetValue = lastYearResponseBiJson2?.PENSION_AMT_TVB ? Math.round(lastYearResponseBiJson2?.PENSION_AMT_TVB) : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM_DISP ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM_DISP) : selectedRiskData?.bi_request?.PR_ANNPREM;
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.foreverPension:
          targetValue = Math.round(selectedRiskData?.biResponseCompleteData.find(item => item.MODAL_ANNUITY_PAYOUT !== 0).MODAL_ANNUITY_PAYOUT)

          targetValue = targetValue ? targetValue : selectedRiskData?.bi_request?.TargetAmount;
          // switch (Number(selectedRiskData?.bi_request?.AnnuityMode)) {
          //   case 22:
          //     targetValue = Math.round(targetValue / 2)
          //     break;

          //   case 23:
          //     targetValue = Math.round(targetValue / 4)
          //     break;

          //   case 24:
          //     targetValue = Math.round(targetValue / 12)
          //     break;

          //   default:
          //     break;
          // }
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.saralPension:
          targetValue = selectedRiskData?.firstYearResponse?.SB_G ? Math.round(selectedRiskData?.firstYearResponse?.SB_G) : selectedRiskData?.bi_request?.TargetAmount;
          // switch (Number(selectedRiskData?.bi_request?.AnnuityMode)) {
          //   case 9:
          //     targetValue = Math.round(targetValue / 2)
          //     break;

          //   case 10:
          //     targetValue = Math.round(targetValue / 4)
          //     break;

          //   case 11:
          //     targetValue = Math.round(targetValue / 12)
          //     break;

          //   default:
          //     break;
          // }
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.wealthUltima:
          // bi request.optionId3 == 5 then SWP else non SWP
          if (Number(productData?.bi_request?.optionId3) == 5) {
            //SWP
            let payoutFrequencyValue = 1;
            if (selectedRiskData?.bi_request?.optionId5 == "117") {
              // Annual
              payoutFrequencyValue = 1;
            } else if (selectedRiskData?.bi_request?.optionId5 == "118") {
              // Semi-Annual
              payoutFrequencyValue = 2;
            } else if (selectedRiskData?.bi_request?.optionId5 == "119") {
              // Quarterly
              payoutFrequencyValue = 4;
            } else if (selectedRiskData?.bi_request?.optionId5 == "120") {
              // Monthly
              payoutFrequencyValue = 12;
            }
            let swpStartYear = productData?.bi_request?.optionValue6 - 1;
            let swpStartYearData = selectedRiskData?.biResponseCompleteData[swpStartYear];
            targetValue = swpStartYearData?.TOTALPW ? Math.round( Math.round(swpStartYearData?.TOTALPW) / payoutFrequencyValue) : selectedRiskData?.bi_request?.TargetAmount;
          } else {
            targetValue = lastYearResponseBiJson2?.FINAL_FV ? Math.round(lastYearResponseBiJson2?.FINAL_FV) : selectedRiskData?.bi_request?.TargetAmount;
          }

          let premium = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM)
          switch (selectedRiskData?.bi_request?.INPUT_MODE) {
            case '1':
              selectedPremium = premium
              break;

            case '2':
              selectedPremium = premium / 2
              break;

            case '3':
              selectedPremium = premium / 4
              break;

            case '4':
              selectedPremium = premium / 12
              break;

            default:
              break;
          }

          selectedPremium = selectedPremium ? Math.round(selectedPremium) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.premierGuranteedIncome:
          switch (selectedRiskData.package_id) {
            case ProductPackageId.PREMIER_GUARANTEED_INCOME_LUMPSUM:
            case ProductPackageId.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
              targetValue = selectedRiskData?.parsedResponse?.MATURITY_BEN ? Math.round(selectedRiskData?.parsedResponse?.MATURITY_BEN) : selectedRiskData?.bi_request?.TargetAmount;
              break;

            case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
            case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
            case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME:
            case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
            case ProductPackageId.PREMIER_GUARANTEED_RETIREMENT_INCOME:
              // IncomeBenefitPayoutFrequency	=>  1 for Annual; 2 for Semi-Annual; 3 for Quarterly; 4 for Monthly
              let incomeBenefitPayoutFrequencyValue = 1;
              if (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency == "1") {
                incomeBenefitPayoutFrequencyValue = 1;
              } else if (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency == "2") {
                incomeBenefitPayoutFrequencyValue = 2;
              } else if (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency == "3") {
                incomeBenefitPayoutFrequencyValue = 4;
              } else if (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency == "4") {
                incomeBenefitPayoutFrequencyValue = 12;
              }
              selectedRiskData?.biResponseCompleteData?.forEach((item) => {
                if (Number(item.INCOME_BEN_PAY_OUT) != 0 && targetValue === 0) targetValue = Math.round(Math.round(item.INCOME_BEN_PAY_OUT) / incomeBenefitPayoutFrequencyValue);
                // if (targetValue === 0) targetValue = Math.round(Math.round(item.INCOME_BEN_PAY_OUT));
              });
              // targetValue = Math.round(selectedRiskData?.firstYearResponse?.INCOME_BEN_PAY_OUT) / incomeBenefitPayoutFrequencyValue;
              break;

            default:
              targetValue = selectedRiskData?.bi_request?.TargetAmount;
              break;
          }
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.activeIncomePlan:
          // if (Number(selectedRiskData?.bi_request?.IncomeOption) === 3) {
          //   targetValue = selectedRiskData?.biResponseCompleteData?.[(selectedRiskData?.bi_request?.PR_PPT) + 1].TOTAL_SB_G_BS_2;
          // } else {
          //   selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
          //     if (Number(selectedRiskData?.bi_request?.PR_PPT) + 1 === index) targetValue = item.TOTAL_SB_G_BS_2;
          //   });
          // }
          selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(selectedRiskData?.bi_request?.PR_PPT) + 1 === index) targetValue = item.TOTAL_SB_G_BS_2;
          });
          targetValue = targetValue ? targetValue : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);

          // // INPUT_MODE	=>  1 for Annual; 2 for Semi-Annual; 3 for Quarterly; 4 for Monthly
          // switch (selectedRiskData?.bi_request?.INPUT_MODE) {
          //   case '1':
          //     selectedPremium = Number(selectedPremium) * ANNUAL_MODAL_FACTOR
          //     break;

          //   case '2':
          //     selectedPremium = Number(selectedPremium) * SEMI_ANNUAL_MODAL_FACTOR
          //     break;

          //   case '3':
          //     selectedPremium = Number(selectedPremium) * QUATERLY_MODAL_FACTOR
          //     break;

          //   case '4':
          //     selectedPremium = Number(selectedPremium) * MONTHLY_MODAL_FACTOR
          //     break;

          //   default:
          //     break;
          // }

          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.cashFlowProtectionPlus:
          selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) targetValue = item.TOTAL_SB_BS_2;
          });
          targetValue = targetValue ? targetValue : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.incomeBuilder:
          selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(item.PAYOUT_PERIOD) === 1) targetValue = Math.round(item.MODAL_INCOME);
          });
          // switch (selectedRiskData?.bi_request?.PayoutMode) {
          //   case '17':
          //     targetValue = Math.round(targetValue / 2)
          //     break;

          //   case '18':
          //     targetValue = Math.round(targetValue / 4)
          //     break;

          //   case '19':
          //     targetValue = Math.round(targetValue / 12)
          //     break;

          //   default:
          //     break;
          // }
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.guaranteedSavingsStar:
          if (Number(selectedRiskData?.bi_request?.MaturityBenefitOption) === 1) {
            targetValue = Math.round(selectedRiskData?.parsedResponse?.MB_G);
          } else {
            let maturityBenefit = 0;
            selectedRiskData?.biResponseCompleteData?.forEach((item) => {
              if (Number(item.MB_G) != 0 && maturityBenefit === 0) maturityBenefit = Math.round(item.MB_G);
            });
            targetValue = maturityBenefit;
          }
          targetValue = targetValue ? targetValue : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.GCAP:
          targetValue = lastYearResponseBiJson2?.MATURITY ? Math.round(lastYearResponseBiJson2?.MATURITY) : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.dhanLabh:
          targetValue = lastYearResponseBiJson2?.TOTAL_BEN ? Math.round(lastYearResponseBiJson2?.TOTAL_BEN) : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.smartLifestyle:
          targetValue = lastYearResponseBiJson2?.SB_NG_DIS_BS_2 ? Math.round(lastYearResponseBiJson2?.SB_NG_DIS_BS_2) : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.singlePayEndowmentAssuarancePlan:
          targetValue = lastYearResponseBiJson2?.SB_G ? Math.round(lastYearResponseBiJson2?.SB_G) : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.wealthPlus:
          targetValue = lastYearResponseBiJson2?.FINAL_FV ? Math.round(lastYearResponseBiJson2?.FINAL_FV) : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.PREMIUM ? Math.round(selectedRiskData?.firstYearResponse?.PREMIUM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.wealthPremier:
          targetValue = lastYearResponseBiJson2?.FINAL_FV ? Math.round(lastYearResponseBiJson2?.FINAL_FV) : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.guaranteedIncomeStar:
          let incomeStartOption = gisUtils.fetchIncomeStartOptionValue(selectedRiskData?.bi_request?.IncomeStartOption)

          if (Number(selectedRiskData?.bi_request?.IncomeOption) === 6) {
            selectedRiskData?.biResponseCompleteData?.forEach((item) => {
              if (Number(item.INCOME_BENEFIT_FINAL) != 0 && targetValue === 0) targetValue = Math.round(item.INCOME_BENEFIT_FINAL);
            });
          } else {
            selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
              if (Number(selectedRiskData?.bi_request?.PR_PPT) < index && Number(item.INCOME_BENEFIT_FINAL) != 0 && targetValue === 0) targetValue = Math.round(item.INCOME_BENEFIT_FINAL);
            });
            // targetValue = selectedRiskData?.biResponseCompleteData && Math.round(selectedRiskData?.biResponseCompleteData[(Number(selectedRiskData?.bi_request?.PR_PPT))]?.INCOME_BENEFIT_FINAL);
          }
          switch (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency) {
            case '33':
              targetValue = targetValue / 2
              break;

            case '34':
              targetValue = targetValue / 4
              break;

            case '35':
              targetValue = targetValue / 12
              break;

            default:
              break;
          }
          targetValue = targetValue ? targetValue : selectedRiskData?.bi_request?.investment_target;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.posSaralNivesh:
          targetValue = lastYearResponseBiJson2?.SB_G ? Math.round(lastYearResponseBiJson2?.SB_G) : selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        case ProductNames.flexiSavingsPlan:
          switch (selectedRiskData.package_id) {

            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
              let fspWithoutAccrualTargetAmtPostPPT = 0              
              selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) fspWithoutAccrualTargetAmtPostPPT = Math.round(item.TOTAL_SB_PAYOUT_BS_2);
              });
              targetValue = fspWithoutAccrualTargetAmtPostPPT ? fspWithoutAccrualTargetAmtPostPPT : selectedRiskData?.bi_request?.RegularIncomeReverse;
              break

            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
              let fspProWithoutAccrualTargetAmtPostPPT = 0
              if (Number(selectedRiskData?.bi_request?.AccrualOfSurvivalBenefits) === 12) {
                selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                  if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) fspProWithoutAccrualTargetAmtPostPPT = Math.round(item.TOTAL_SB_PAYOUT_BS_2);
                });
              } else {
                selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                  if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) fspProWithoutAccrualTargetAmtPostPPT = Math.round(item.TOTAL_SB_POST_PPT_BS_2);
                });
              }
              targetValue = fspProWithoutAccrualTargetAmtPostPPT ? fspProWithoutAccrualTargetAmtPostPPT : selectedRiskData?.bi_request?.TargetAmount;
              break

            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
              let fspWithAccrualTargetAmtPostPPT = 0
              selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) fspWithAccrualTargetAmtPostPPT = Math.round(item.TOTAL_SB_POST_PPT_BS_2);
              });
              targetValue = fspWithAccrualTargetAmtPostPPT ? fspWithAccrualTargetAmtPostPPT : selectedRiskData?.bi_request?.TargetAmount;
              break

            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
              let targetAmtFinal = 0;
              selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                if ((Number(selectedRiskData?.bi_request?.PR_PT)-1) === index) {
                  targetAmtFinal = Math.round(item.MATURITY_BENEFIT_BS_2);
                }
              });
              targetValue = targetAmtFinal ? Math.round(targetAmtFinal) : selectedRiskData?.bi_request?.TargetAmount;
              break;

            default:
              break;
          }
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;

        default:
          targetValue = selectedRiskData?.bi_request?.TargetAmount;
          selectedPremium = selectedRiskData?.firstYearResponse?.MODAL_PREM ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM) : Math.round(selectedRiskData?.bi_request?.PR_ANNPREM);
          recommendedTargetValue = selectedRiskData?.recommended_sum_assured;
          minTargetValue = selectedRiskData?.minimum_sum_assured;
          maxTargetValue = selectedRiskData?.maximum_sum_assured;
          break;
      }
    }
  }

  return { targetValue:Math.round(targetValue), selectedPremium: Math.round(selectedPremium), recommendedTargetValue: Math.round(recommendedTargetValue), minTargetValue, maxTargetValue };
};

export default prefillDeathCoverGraphSliderAllAmounts;
