
// React Imports
import React from "react"

// Package Imports
import { Col, Row, Card, Image } from "react-bootstrap"
import { observer } from "mobx-react"

// Project Imports
import {   CustomCheckbox,   BottomsheetDropdown} from "components"
import { SaveForChildrenImg } from "constants/Images"

// CSS Imports
import "./SaveForChildren.css"

const SaveForChildren = (props) => {

  const { isSelected } = props
  const saveForChildrenForm = props.formController.$("save_for_children")

  return (
    <Card className="border-0 save-for-children-card h-100">
      <div className={!isSelected ? "card-overlay" : null}></div>
      <Card.Body className='p-2 pb-4'>
        <div>
          <Row className="d-flex align-items-start">
            <Col xs={9} className='pr-0'>
              <CustomCheckbox
                isChecked={isSelected}
                onChange={() => props.toggleNeedSelection(isSelected)}
                id="saveForChildren"
                shape="rounded"
                title={saveForChildrenForm.label}
                titleStyle="text-primary font-weight-medium font-size-16 font-size-md-14 pl-2"
                containerStyle="align-items-start"
                checkmarkStyle="radio-margin-top"
                mark="checkmark"
                className="choose-Need-checkBox"
              />

              <div className="ml-4 pl-2">
                <div className="d-md-flex align-items-center justify-content-between">
                  <span className=" font-size-14 font-size-md-12 font-weight-light description-text-color mb-0 mt-2 mt-md-0 need-label-width mr-2">
                    I would need
                  </span>
                  <div className="drop-down-style need-dropdown-width">
                    <BottomsheetDropdown
                      disabled={!isSelected}
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey="label"
                      bottomsheetTitle={saveForChildrenForm.$("target_amount_type").extra.bottomsheetTitle}
                      menuList={saveForChildrenForm.$("target_amount_type").extra.optionList}
                      onSelect={saveForChildrenForm.$("target_amount_type").onChange}
                      value={saveForChildrenForm.$("target_amount_type").value}
                      showErrorMsg={saveForChildrenForm.$("target_amount_type").error}
                      errorMsg={saveForChildrenForm.$("target_amount_type").error}
                    />
                  </div>
                </div>
                <div className="d-md-flex align-items-center justify-content-between mt-lg-2 mt-2">
                  <span className="font-size-14 font-size-md-12 font-weight-light text-description-text-color mb-0 mt-2 mt-md-0 w-25 mr-2">
                  </span>
                  <div className="drop-down-style need-dropdown-width">
                    <BottomsheetDropdown
                      disabled={!isSelected}
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey="label"
                      bottomsheetTitle={saveForChildrenForm.$("target_amount_dropdown").extra.bottomsheetTitle}
                      menuList={saveForChildrenForm.$("target_amount_dropdown").extra.optionList}
                      onSelect={saveForChildrenForm.$("target_amount_dropdown").onChange}
                      value={saveForChildrenForm.$("target_amount_dropdown").value}
                      showErrorMsg={saveForChildrenForm.$("target_amount_dropdown").error}
                      errorMsg={saveForChildrenForm.$("target_amount_dropdown").error}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={3} className='text-lg-right'>
              <Image
                className="children-choose-card-img"
                src={SaveForChildrenImg}
                alt="save for children"
              />
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  )
}

export default observer(SaveForChildren)
