// React import
import React from "react"

// Package Import
import PropTypes from "prop-types"
import { Image } from "react-bootstrap"

// Project Imports
import * as images from "constants/Images"

const RiderPremium = (props) => {
  const { riderPremiumAmount, riderPremiumGstPercent, riderPremiumGstAmount, multiplier } =
    props

  return (
    <>
      <div className="d-flex align-items-center justify-content-center total-premium-icon-container-width mb-3 mb-lg-0">
        <Image
          width="23"
          className="mx-1"
          src={images.PlusIconGreyBold}
          alt="plus"
        />
      </div>

      <div className="total-premium-sub-container-width mb-3 mb-lg-0">
        <p className={`text-center font-size-12 font-weight-medium mb-0 total-premium-container-heading py-2 ${riderPremiumGstAmount == "" || riderPremiumGstAmount == 0 ? " mb-1" : ""}`}>
          Rider Premium
        </p>
        <div className="d-flex align-items-center justify-content-center">
          <Image
            width="12"
            className="mx-1"
            src={images.OptimizationSquareBraketLeft}
            alt="back-arrow"
          />
          <div className="d-flex align-items-center">
            <div className="">
              <p className="text-center font-size-12 font-weight-medium total-premium-text-color mb-0">
                Base Premium
              </p>
              <p className="text-center font-size-20 text-dark font-weight-medium my-0">
                {global.toINR(riderPremiumAmount)}
              </p>
              <p
                className={`text-center font-size-12 font-weight-medium total-premium-text-color my-0 ${riderPremiumGstAmount !== "" || riderPremiumGstAmount !== 0 ? "d-none" : ""
                  } `}
              >
                {props.frequencyLabel}
              </p>
            </div>

            <div className={`${riderPremiumGstAmount !== "" || riderPremiumGstAmount !== 0 ? "" : "d-none"}`}>
              <div className="d-flex flex-column justify-content-between">
                <p className="font-size-12 font-weight-medium text-dark text-center mx-3 pt-3">
                  +
                </p>
                <p className="text-center font-size-12 font-weight-medium total-premium-text-color my-0">
                  {props.frequencyLabel}
                </p>
              </div>
            </div>

            <div className={`${riderPremiumGstAmount !== "" || riderPremiumGstAmount !== 0 ? "" : "d-none"}`}>
              <p className="text-center font-size-12 font-weight-medium total-premium-text-color mb-0">
                GST @ {riderPremiumGstPercent}%
              </p>
              <p className="text-center font-size-20 text-dark font-weight-medium my-0">
                {global.toINR(riderPremiumGstAmount)}
              </p>
            </div>
          </div>

          <Image
            width="12"
            className="mx-1"
            src={images.OptimizationSquareBraketRight}
            alt="back-arrow"
          />

          <p className={`font-size-16 font-weight-medium ${multiplier !== "" ? "" : "d-none"}`}>
            {" "}
            <span className='font-size-12'>X</span> {multiplier}
          </p>

        </div>
      </div>
    </>
  )
}

RiderPremium.propTypes = {
  riderPremiumAmount: PropTypes.number,
  riderPremiumGstPercent: PropTypes.number,
  riderPremiumGstAmount: PropTypes.number,
}

export default RiderPremium
