import React, { Component } from 'react';
import { Form, Col, Row, Container, Alert } from 'react-bootstrap';
import UiButton from '../../../components/ui/button/Button';

import AuthStore from '../store/AuthStore';
import { Logo } from 'constants/Images';

class ForgotPswd extends Component {
  constructor() {
    super();
    this.state = {
      password: '',
      repeatPassword: '',
      error: '',
      validated: false
    };
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const paramsString = this.props.location.search;
    const params = new URLSearchParams(paramsString);
    const token = params.get('token');

    if (token) {
      this.setState({ token });
    }
  }

  async submit(event) {
    const { isSubmit } = this.state;
    if (isSubmit) return;

    this.setState({ isSubmit: true });

    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const { token, password, repeatPassword } = this.state;
      if (password !== repeatPassword) {
        this.setState({ error: 'Password not match', isSubmit: false });
        return;
      } else if (!token) {
        this.setState({ error: 'Reset link is not valid', isSubmit: false });
        return;
      } else {
        this.setState({ error: '' });
      }
      const result = await AuthStore.changePassword({ token, password });
      if (result) {
        this.props.history.replace('login');
        return;
      }
    }
    // this.props.history.push('dashboard')
    this.setState({ validated: true, isSubmit: false });
  }

  render() {
    const { validated, password, repeatPassword, error, isSubmit } = this.state;
    // console.log('PRAMS >> ', this.props, this.state.token);
    return (
      <div className='mainWrapper'>
        <Container>
          <Row className='marginTop20'>
            <Col lg={{ span: 6, offset: 3 }} xs={12} sm={12}>
              <div className='logoWrapper'>
                <img src={Logo} />
              </div>
              <h2 className='verifiedAccount'>Set New Password</h2>
            </Col>
          </Row>
          <Form noValidate validated={validated} onSubmit={this.submit}>
            <Row>
              <Col
                className='marginTop20'
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                xs={{ span: 10, offset: 1 }}
                sm={{ span: 10, offset: 1 }}
              >
                <Form.Group controlId='validationCustom01'>
                  <Form.Control
                    required
                    type='password'
                    placeholder='New Password'
                    value={password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                  <Form.Control.Feedback type='invalid'>
                    Enter Password!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                className='marginTop20'
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                xs={{ span: 10, offset: 1 }}
                sm={{ span: 10, offset: 1 }}
              >
                <Form.Group controlId='validationCustom02'>
                  <Form.Control
                    required
                    type='password'
                    placeholder='Repeat New Password'
                    value={repeatPassword}
                    onChange={e =>
                      this.setState({ repeatPassword: e.target.value })
                    }
                  />
                  <Form.Control.Feedback type='invalid'>
                    Enter Password!
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                xs={{ span: 10, offset: 1 }}
                sm={{ span: 10, offset: 1 }}
              >
                <UiButton
                  disabled={isSubmit}
                  buttonText={isSubmit ? 'Loading..' : 'Submit'}
                  className='UiButton'
                  buttonType='primary'
                  type='submit'
                />
              </Col>
              {/* <Col className='marginTop20 col-lg-offset-3 col-xs-12 col-sm-12' lg={{ span: 6 }} xs={12} sm={12}>
            <p className='marginTop20'>Or continue with <a href='#'>Google</a></p>
          </Col> */}
            </Row>
            {error && <Alert variant='danger'>{error}</Alert>}
          </Form>
        </Container>
      </div>
    );
  }
}

export default ForgotPswd;
