import AuthStore from '../../../../login/store/AuthStore';
import OptimizationStore from '../../../store/OptimizationStore';
import utils from './utils';
class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // !Systematic Withdrawal Validation
    systematicWithdrawalValidation = (formRef, productData) => {
        let withdrawalType = formRef.$('withdrawal').value
        let withdrawalStartYear = formRef.$('withdrawal-start-year').value
        let withdrawalEndYear = formRef.$('withdrawal-end-year').value
        let incomeStartYear = formRef.$('income-start-year').value.label
        let laAge = productData.data.bi_request.LI_ENTRY_AGE
        let pt = formRef.$('policy-term').value.id
        if (pt === '100-@LI_ENTRY_AGE') {
            pt = 100 - laAge
        }

        if (Number(productData.data.bi_request.PersonalisedOption) === 1) {
            if (withdrawalType === undefined || withdrawalType === "") {
                this.errorMessage.push("Please select withdrawal option.")
            }

            if (withdrawalStartYear === "") {
                this.errorMessage.push("Please select withdrawal start year.")
            }

            if (withdrawalEndYear === "") {
                this.errorMessage.push("Please select withdrawal end year.")
            }

            if (withdrawalEndYear && Number(incomeStartYear) > Number(withdrawalStartYear)) {
                this.errorMessage.push("Withdrawal start year cannot be less than income start year.")
                return
            }

            if (withdrawalType && withdrawalStartYear) {
                if (Number(pt) - Number(withdrawalType.id) < Number(withdrawalStartYear)) {
                    this.errorMessage.push(`Withdrawal start year cannot be greater than  ${Number(pt) - Number(withdrawalType.id)} year.`)
                    return
                }

                if (withdrawalEndYear) {
                    let endYearList = utils.swpEndYearList(Number(withdrawalType.id), Number(withdrawalStartYear), Number(pt))
                    if (!endYearList.some((item) => item === Number(withdrawalEndYear))) {
                        this.errorMessage.push(`Values of end year should be start year plus multiples of ${withdrawalType.id}.`)
                        return
                    }

                    if (Number(withdrawalEndYear) > Number(pt)) {
                        this.errorMessage.push('Withdrawal Stop Year cannot exceed the Policy Term.')
                        return
                    }
                }
            }
        }
    }

    // !Age based Validation
    ageBasedWithdrawalValidation = (formRef, productData) => {
        let ageBasedWithdrawal = formRef.$('age-based-withdrawal').extra.optionList
        let incomeStartYear = formRef.$('income-start-year').value.label
        let laAge = productData.data.bi_request.LI_ENTRY_AGE
        let pt = formRef.$('policy-term').value.id
        if (pt === '100-@LI_ENTRY_AGE') {
            pt = 100 - laAge
        }
        let valueNotEntered = false
        ageBasedWithdrawal.forEach((item) => {
            if (item === "" && !valueNotEntered) {
                valueNotEntered = true
            }

        })

        if (valueNotEntered && Number(productData.data.bi_request.PersonalisedOption) === 2) {
            this.errorMessage.push("Please enter Withdraw Age")
        }

        if (!valueNotEntered && Number(productData.data.bi_request.PersonalisedOption) === 2) {
            // First Withdraw age min max validation
            if (Number(ageBasedWithdrawal[0]) < Number(laAge) + Number(incomeStartYear) + 1) {
                this.errorMessage.push(`First Withdraw Age should be minimum ${Number(laAge) + Number(incomeStartYear) + 1}.`)
            }

            if (Number(ageBasedWithdrawal[0]) > Number(laAge) + Number(pt) - 1) {
                this.errorMessage.push(`First Withdraw Age should be maximum ${Number(laAge) + Number(pt) - 1}.`)
            }
            if (ageBasedWithdrawal.length > 1) {
                // Minimum difference
                let ageDiffGreaterThanTwo = false
                for (var i = 1; i < ageBasedWithdrawal.length; i++) {
                    if (ageBasedWithdrawal[i] - ageBasedWithdrawal[i - 1] < 2 && !ageDiffGreaterThanTwo) {
                        ageDiffGreaterThanTwo = true
                    }
                }
                if (ageDiffGreaterThanTwo) {
                    this.errorMessage.push(`Minimum difference between Two Withdraw Ages should be 2 years.`)
                }

                // Maximum difference
                if (ageBasedWithdrawal.some((item) => item > Number(laAge) + Number(pt) - 1)) {
                    this.errorMessage.push(`Withdraw Age should be maximum ${Number(laAge) + Number(pt) - 1}.`)
                }

                // Age Difference
                let isAscending = false;
                for (var i = 0; i <= ageBasedWithdrawal.length; i++) {
                    if (!isAscending) {
                        isAscending = Number(ageBasedWithdrawal[i]) > Number(ageBasedWithdrawal[i + 1]);
                    }
                }
                if (isAscending && ageBasedWithdrawal.length > 1) {
                    this.errorMessage.push('Second withdrawal age must be greater than first withdrawal age.')
                }
            }
        }
    }

    // !Income Age based Validation
    agePlusIncomeBasedWithdrawalValidation = (formRef, productData) => {
        let startAge = formRef.$('start-age').extra.optionList
        let endAge = formRef.$('end-age').extra.optionList
        let laAge = productData.data.bi_request.LI_ENTRY_AGE
        let incomeStartYear = formRef.$('income-start-year').value.label
        let pt = formRef.$('policy-term').value.id
        if (pt === '100-@LI_ENTRY_AGE') {
            pt = 100 - laAge
        }
        let valueNotEntered = false
        startAge.forEach((item) => {
            if (item === "" && !valueNotEntered) {
                valueNotEntered = true
            }
        })

        if (!valueNotEntered) {
            endAge.forEach((item) => {
                if (item === "" && !valueNotEntered) {
                    valueNotEntered = true
                }
            })
        }

        if (valueNotEntered && Number(productData.data.bi_request.PersonalisedOption) === 3) {
            this.errorMessage.push("Please enter Withdraw Age.")
        }

        if (!valueNotEntered && Number(productData.data.bi_request.PersonalisedOption) === 3) {
            // First start age min max validaton
            if (Number(startAge[0]) < Number(laAge) + Number(incomeStartYear) + 1) {
                this.errorMessage.push(`First Accrual Start Age should be minimum ${Number(laAge) + Number(incomeStartYear) + 1}.`)
            }

            if (Number(startAge[0]) > Number(laAge) + Number(pt) - 1) {
                this.errorMessage.push(`First Accrual Start Age should be maximum ${Number(laAge) + Number(pt) - 1}.`)
            }
            // First end age min max validaton
            if (Number(endAge[0]) < Number(startAge) + 1) {
                this.errorMessage.push(`First Accrual End Age should be minimum ${Number(startAge) + 1}.`)
            }

            if (Number(endAge[0]) > Number(laAge) + Number(pt)) {
                this.errorMessage.push(`First Accrual End Age should be maximum ${Number(laAge) + Number(pt)}.`)
            }

            // Minimum difference
            let startAgeMinDiff = false
            let startAgeMaxDiff = false
            for (var i = 1; i < startAge.length; i++) {
                if (Number(laAge) + Number(pt) - 1 < startAge[i] && !startAgeMaxDiff) {
                    this.errorMessage.push(`Maximum Accrual Start Age is ${Number(laAge) + Number(pt) - 1}.`)
                }
            }

            // Maximum difference
            let endAgeMinDiff = false
            let endAgeMaxDiff = false
            for (var i = 1; i < endAge.length; i++) {
                if (Number(laAge) + Number(pt) < endAge[i] && !endAgeMaxDiff) {
                    this.errorMessage.push(`Maximum Accrual End Age is ${Number(laAge) + Number(pt)}.`)
                }
            }
            for (var i = 0; i <= endAge.length; i++) {
                if (!endAgeMinDiff) {
                    endAgeMinDiff = Number(endAge[i]) > Number(endAge[i + 1]);
                }
            }

            for (var i = 0; i <= startAge.length; i++) {
                if (!startAgeMinDiff) {
                    startAgeMinDiff = Number(startAge[i]) > Number(startAge[i + 1]);
                }
            }

            if (endAgeMinDiff) {
                this.errorMessage.push(`Accrual End Age should be greater than previous Start Age.`)
                return
            }
            if (startAgeMinDiff) {
                this.errorMessage.push(`Accrual Start Age should be greater than previous End Age.`)
                return
            }
        }

    }

    // !Premium Amount
    minimumPremiumValidation = (formRef) => {
        let premium = formRef.$('premium').value
        let inputMode = formRef.$('payment-frequency').value.id

        if (Number(inputMode) === 1 && Number(premium) < 50000) {
            this.errorMessage.push("Minimum premium amount is 50000")
        }

        if (Number(inputMode) === 2 && Number(premium) < 25600) {
            this.errorMessage.push("Minimum premium amount is 25600")
        }

        if (Number(inputMode) === 3 && Number(premium) < 13300) {
            this.errorMessage.push("Minimum premium amount is 13300")
        }

        if (Number(inputMode) === 4 && Number(premium) < 4400) {
            this.errorMessage.push("Minimum premium amount is 4400")
        }
    }
}

export default new Validator();