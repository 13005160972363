const { Form } = require("mobx-react-form");

import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

import formObserver from './observers';
import setInitialDefaults from './initialDefaults';
import Utils from "./utils";
import * as keys from "./keys";
import * as Constants from 'constants/Constants'

class CashflowProtectionPlusFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Annual",
              },
              {
                id: "2",
                label: "Semi-Annual",
              },
              {
                id: "4",
                label: "Monthly",
              },
            ],
          },
        },
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          disabled: false,
          value: "",
          extra: {
            optionList: [
              {
                id: "100-@LI_ENTRY_AGE",
                label: "Upto Age 100",
              },
            ],
          },
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremiun(form, data) {

              // let premiumAmount = 0;
              // let inputMode = Number(form.$('payment-frequency').value.id)
              // let productId = data.data.product_id

              // let amount = Number(form.$('premium').value)
              // switch (inputMode) {
              //   case 1:
              //     premiumAmount = '' + amount * 1
              //     break;
              //   case 2:
              //     premiumAmount = '' + amount * 2
              //     break;
              //   case 3:
              //     premiumAmount = '' + amount * 4
              //     break;
              //   case 4:
              //     premiumAmount = '' + amount * 12
              //     break;
              // }
              // Utils.updateBi(keys.PREMIUM, premiumAmount, productId, form, data)
            },
          },
        },
        {
          name: "premium-term",
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            optionList: [
              {
                id: 10,
                label: 10,
              },
              {
                id: 15,
                label: 15,
              },
              {
                id: 20,
                label: 20,
              },
              {
                id: 25,
                label: 25,
              },
            ],
          },
        },
        {
          name: "ADB",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.ADB_RIDER_DESCRIPTION,
            rider_name: "ADB Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ADB").value;
              form.$("ADB").value = !value;
              if (form.$("ADB").value) {
                form
                  .$("ADB-dropdown")
                  .set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("ADB-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "ADB-dropdown",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountList(),
            onBlur(form, data) {
              let value = form.$("ADB-dropdown").value
              Utils.updateBi(keys.ADB_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "ATPDRider",
          label: "Permanent Disability",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.ATPD_RIDER_DESCRIPTION,
            paramName: "ATPDRider",
            rider_name: "ATPD Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ATPDRider").value;
              form.$("ATPDRider").value = !value;
              if (form.$("ATPDRider").value) {
                form.$("ATPDRider-dropdown").set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("ATPDRider-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "CriticalIllnessRider",
          label: "Critical Illness",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.CRITICAL_ILLNESS_RIDER_DESCRIPTION,
            paramName: "CriticalIllnessRider",
            rider_name: "Critical Illness Rider",
            changeRiderStatus(form, data) {
              let value = form.$("CriticalIllnessRider").value;
              form.$("CriticalIllnessRider").value = !value;

              if (form.$("CriticalIllnessRider").value) {
                form.$("CriticalIllnessRider-dropdown").set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("CriticalIllnessRider-dropdown").set("value", "");
              }
            },
          },
        },

        {
          name: "IncomeBenefitRider",
          label: "Income Benefit",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.INCOME_BENEFIT_RIDER_DESCRIPTION,
            paramName: "IncomeBenefitRider",
            rider_name: "Income Benefit Rider",
            changeRiderStatus(form, data) {
              let value = form.$("IncomeBenefitRider").value;
              form.$("IncomeBenefitRider").value = !value;

              if (form.$("IncomeBenefitRider").value) {
                form.$("IncomeBenefitRider-dropdown").set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("IncomeBenefitRider-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "WaiverOfPremiumRider",
          label: "Waiver Of Premium Rider",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.WOP_RIDER_DESCRIPTION,
            paramName: "WaiverOfPremiumRider",
            rider_name: "Waiver of Premium Rider",
            changeRiderStatus(form, data) {
              let value = form.$("WaiverOfPremiumRider").value;
              form.$("WaiverOfPremiumRider").value = !value;
            },
          },
        },
        {
          name: "PayorWaiverBenefitRider",
          label: "Payor Waiver Benefit Rider",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.PWB_RIDER_DESCRIPTION,
            paramName: "PayorWaiverBenefitRider",
            rider_name: "Payor Waiver Benefit Rider",
            changeRiderStatus(form, data) {
              let value = form.$("PayorWaiverBenefitRider").value;
              form.$("PayorWaiverBenefitRider").value = !value;

              if (form.$("PayorWaiverBenefitRider").value) {
                form
                  .$("PayorWaiverBenefitRider-dropdown")
                  .set(
                    "value",
                    form.$("PayorWaiverBenefitRider-dropdown").extra
                      .optionList[0]
                  );
              } else {
                form.$("PayorWaiverBenefitRider-dropdown").set("value", {});
              }
            },
          },
        },
        {
          name: "ATPDRider-dropdown",
          label: "Permanent Disability",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountList(),
            onBlur(form, data) {
              let value = form.$("ATPDRider-dropdown").value
              Utils.updateBi(keys.ATPD_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "CriticalIllnessRider-dropdown",
          label: "Critical Illness",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountListForCLRnHCB(),
            onBlur(form, data) {
              let value = form.$("CriticalIllnessRider-dropdown").value
              Utils.updateBi(keys.CRITICAL_ILLNESS_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "IncomeBenefitRider-dropdown",
          label: "Income Benefit",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountListForCLRnHCB(),
            onBlur(form, data) {
              let value = form.$("IncomeBenefitRider-dropdown").value
              Utils.updateBi(keys.INCOME_BENEFIT_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "PayorWaiverBenefitRider-dropdown",
          label: "Payor Waiver Benefit",
          placeholder: "",
          value: {},
          extra: {
            optionList: [
              {
                id: 1,
                label: "Option 1 (On Death)",
              },
              {
                id: 2,
                label: "Option 2 (CI or ATPD)",
              },
              {
                id: 3,
                label: "Option 3 (CI or ATPD or Death)",
              },
            ],
          },
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data);
  }
}

export default CashflowProtectionPlusFormController;
