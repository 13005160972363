import React from 'react'
import { Button, Image } from 'react-bootstrap'
import './calculator-button.css'

import * as images from '../../../constants/Images'

function CalculatorButton(props) {
  const { onClick } = props;
  return (
    <Button variant=''
      className='align-items-center btn calculator-btn d-flex px-0 py-0'
      onClick={ ()=> onClick() }
    >
      <Image className='calculator-btn-icon mr-3' src={images.CalculatorImg} alt='calculator-icon'/>
      <span className='text-secondary font-size-15 font-size-md-16 font-weight-medium'>Use Calculator</span>
    </Button>
  )
}

export default CalculatorButton;
