
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {

    formRef.$('PPT').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.prefillPtBasedOnpptAndIncomeBenefitPayoutDuration(formRef, props.productName)
            Utils.incomeBenefitDurationLogicBasedOnPpt(field.value.id, formRef, props.productName)
            Utils.ptLogicBasedOnPpt(field.value.id, formRef, props.productName)
            Utils.updateBi(keys.PPT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('PT').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('payment-frequency').observe({
        key: 'value',
        call: ({ field }) => {
            let premium = formRef.$("premium").value;
            let frequency = Number(field.value.id);

            let amount = Number(premium);
            let premiumAmount = "";
            switch (frequency) {
                case 1:
                case 5:
                    premiumAmount = "" + amount * 1;
                    break;
                case 2:
                    premiumAmount = "" + amount * 2;
                    break;
                case 3:
                    premiumAmount = "" + amount * 4;
                    break;
                case 4:
                    premiumAmount = "" + amount * 12;
                    break;
            }
            let data = {
                premium: premiumAmount,
                frequency: frequency,
            };
            Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)
        }
    })

    formRef.$('lump-sum-benefit').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.LUMPSUM_BENEFIT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    // formRef.$('family-income-benefit-option').observe({
    //     key: 'value',
    //     call: ({ field }) => {
    //         Utils.updateBi(keys.FAMILY_BENEFIT_INCOME_OPTION, field.value.id, props.data.product_id, formRef, props)
    //     }
    // })

    formRef.$('income-benefit-pay-out-frequency').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.INCOME_BENEFIT_PAYOUT_FREQUENCY, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('income-benefit-pay-out-type').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.INCOME_BENEFIT_PAYOUT_TYPE, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('income-benefit-payout-duration').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.prefillPtBasedOnpptAndIncomeBenefitPayoutDuration(formRef, props.productName)
            Utils.updateBi(keys.INCOME_BENEFIT_DURATION, field.value.id, props.data.product_id, formRef, props)
        }
    })


    formRef.$('ADB').observe({
        key: 'value',
        call: ({ field }) => {
            let adbValue = formRef.$('ADB-dropdown').value
            Utils.updateBi(keys.ADB_RIDER, adbValue, props.data.product_id, formRef, props)
        }
    })

    formRef.$('ATPDRider').observe({
        key: 'value',
        call: ({ field }) => {
            let atpdValue = formRef.$('ATPDRider-dropdown').value
            Utils.updateBi(keys.ATPD_RIDER, atpdValue, props.data.product_id, formRef, props)
        }
    })

    formRef.$('CriticalIllnessRider').observe({
        key: 'value',
        call: ({ field }) => {
            let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
            Utils.updateBi(keys.Critical_ILLNESS_RIDER, criticalIllness, props.data.product_id, formRef, props)
        }
    })


    formRef.$('HospitalityCashBenefitRider').observe({
        key: 'value',
        call: ({ field }) => {
            let hospitalityCashBenefit = formRef.$('HospitalityCashBenefitRider-dropdown').value
            Utils.updateBi(keys.HOSPITALITY_CASH_BENEFIT_RIDER, hospitalityCashBenefit, props.data.product_id, formRef, props)
        }
    })

    formRef.$('PayorWaiverBenefitRider-dropdown').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PWB_RIDER, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('family-income-benefit-option-dropdown').observe({
        key: 'value',
        call: ({ field }) => {
            if (formRef.$('family-income-benefit-option').value) {
                Utils.updateBi(keys.FAMILY_BENEFIT_INCOME_OPTION, field.value.id, props.data.product_id, formRef, props)
            }
        }
    })

}

export default FormObserver