// Project Imports
import ProductNames from "constants/ProductNames";

const survivalBenefitAmount = function (data) {
  console.log("survivalBenefitAmount", data);
  let survivalBenefit = 0;
  if (!Array.isArray(data.BI)) {
    return 0;
  } else {
    // For With tabs products

    let selectedRiskData = data.BI.find((item) => item.risk === data.selectedRisk);

    switch (selectedRiskData?.product_id) {
      case ProductNames.cashFlowProtectionPlus:
        selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
          if (Number(selectedRiskData?.bi_request?.PR_PPT)-1 === index) survivalBenefit = item.TOTAL_SB_BS_2;
        });
        return Math.round(survivalBenefit);

      default:
        return 0;
    }
  }
};

export default survivalBenefitAmount;
