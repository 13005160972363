// React Imports
import React, { useEffect, useState } from "react"

// Package Imports
import { Image } from "react-bootstrap"
import ModalBody from "react-bootstrap/ModalBody"
import { observer } from "mobx-react"
import PropTypes from "prop-types"
import { toJS } from "mobx"
import moment from "moment"

// project imports
import * as images from "constants/Images"
import { CALCULATOR_MODAL_DESCRIPTION } from "constants/Constants"
import { BottomsheetDropdown } from "components"
import CardTextFieldComponent from "modules/family-details-form/components/CardTextFieldComponent"
import { onRecommend, onSet, onLaunch } from "./utils"
import fetchPincode from "utils/fetchPinCode"
import AuthStore from "modules/login/store/AuthStore"
import AddInsuredProtectionForFamilyController from "./controller"
import { familyMemberAgeRelationNameFunction } from "utils/familyMemberAgeRelationFunction"

const ProtectionForFamilyModalForm = (props) => {
  const [formController, setFormCOntroller] = useState(undefined)

  useEffect(() => {
    let controller = new AddInsuredProtectionForFamilyController()
    let journeyData = toJS(AuthStore.journeyData)
    let journeyId = toJS(AuthStore.journeyId)
    controller.setDefaults(journeyData, journeyId)
    setFormCOntroller(controller)
  }, [])
  
  let singleFamilyMember = familyMemberAgeRelationNameFunction()

  const { hideProtectionModal, openProtectionCalculator,getFamilyMemberType } = props

  if (formController === undefined) return null

  return (
    <ModalBody className="ProtectionForFamilyModalForm add-insured-pop-up-modal-body">
      <section className="h-100">
        <article className=" add-insured-pop-up-modal-content">
          <div className="row justify-content-end">
            <span className="mr-2 cancel-btn-style">Cancel</span>
            <Image
              className="close-icon-class"
              src={images.CrossIcon}
              onClick={() => hideProtectionModal()}
            />
          </div>

          <div className="row">
            <p className="font-weight-medium label-color">
              {singleFamilyMember.memberName.length === 0 ? `${singleFamilyMember.relation}, ` : singleFamilyMember.memberName}
              <span className="member-age">{singleFamilyMember.age} yrs</span>
            </p>
          </div>

          <div className="row">
            <div className="col-12 col-md-4 mt-2 mt-md-0 pl-md-0">
              <p className="add-insured-modal-label font-weight-medium">
                {formController.$("occupation").label}
              </p>
              <div className="w-100">
                <BottomsheetDropdown
                  dropdownStyle='font-size-lg-12 font-size-14 font-weight-light bg-white'
                  menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                  menuItemStyle='d-flex justify-content-between align-items-center'
                  selectedLabelKey="label"
                  bottomsheetTitle={
                    formController.$("occupation").extra.bottomSheetTitle
                  }
                  menuList={formController.$("occupation").extra.optionList}
                  value={formController.$("occupation").value}
                  onSelect={formController.$("occupation").onChange}
                  showErrorMsg={formController.$("occupation").error !== null}
                  errorMsg={formController.$("occupation").error}
                  disabled={formController.$("occupation").disabled}
                />
              </div>
            </div>

            <div className="col-12 col-md-4 mt-2 mt-md-0">
              <p className="add-insured-modal-label font-weight-medium">
                {formController.$("education").label}
              </p>
              <div className="w-100">
                <BottomsheetDropdown
                  dropdownStyle="font-size-lg-12 font-size-14 font-weight-light bg-white"
                  menuItemStyle="d-flex justify-content-between align-items-center"
                  menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                  selectedLabelKey="label"
                  bottomsheetTitle={
                    formController.$("education").extra.bottomSheetTitle
                  }
                  menuList={formController.$("education").extra.optionList}
                  value={formController.$("education").value}
                  onSelect={formController.$("education").onChange}
                  showErrorMsg={formController.$("education").error !== null}
                  errorMsg={formController.$("education").error}
                  disabled={formController.$("education").disabled}
                />
              </div>
            </div>

            <div className="col-12 col-md-4 mt-2 mt-md-0 ">
              <p className="add-insured-modal-label font-weight-medium">
                {formController.$("location").label}
              </p>
              <div className="w-100">
                <CardTextFieldComponent
                  className="font-size-12 font-weight-light"
                  value={formController.$("location").value}
                  menuList={formController.$("location").extra.optionList}
                  placeholder={formController.$("location").placeholder}
                  onChange={formController.$("location").onChange}
                  showErrorMsg={formController.$("location").error !== null}
                  errorMsg={formController.$("location").error}
                  disabled={formController.$("location").disabled}
                  onSearch={async (value) =>
                    await fetchPincode(value, formController)
                  }
                />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <p className="font-weight-medium label-color text-center text-lg-left">
              Select an option for setting the cover amount
            </p>
          </div>

          <div className="row d-flex justify-content-between select-option-container">
            <section className="protection-calculator-section mb-4 mb-lg-0">
              <div className="">
                <div className="d-flex justify-content-center align-items-center">
                  <Image
                    className="calculator-modal-icon-style"
                    src={images.ProtectionForMyFamilyImg}
                  />
                  <p className="modal-text-heading font-size-16 font-weight-medium text-primary mb-0 mb-lg-1">
                    Protection <br />
                    Calculator
                  </p>
                </div>
                <div className="description font-size-10 font-weight-light d-lg-block text-center">
                  {CALCULATOR_MODAL_DESCRIPTION}
                </div>
                <div className="mt-4 text-center">
                  <button
                    className="modal-btn py-2 py-lg-1 font-size-14 font-weight-medium"
                    onClick={() =>
                      onLaunch(formController, openProtectionCalculator,getFamilyMemberType)
                    }
                  >
                    Launch
                  </button>
                </div>
              </div>
            </section>

            <section className="or-section mb-4 mb-lg-0">
              <div className="d-flex h-100 flex-column justify-content-center relative">
                <div className="seperator-div d-lg-none"></div>
                <div className="or-container mb-lg-4">
                  <span className="font-weight-medium font-size-14 text-white">
                    OR
                  </span>
                </div>
              </div>
            </section>

            <section className="recommend-section mb-4 mb-lg-0">
              <div className="d-flex h-100 flex-column justify-content-between">
                <div></div>
                <div className="">
                  <p className="modal-text-heading text-center font-size-16 font-weight-medium text-primary mt-md-5">
                    Recommend me a Protection Cover
                  </p>
                </div>

                <div className="mt-4 text-center">
                  <button
                    //disabled={true}
                    className="modal-btn py-2 py-lg-1 font-size-14 font-weight-medium recommend-btn"
                    onClick={() => onRecommend(formController, hideProtectionModal)}
                  >
                    Recommend
                  </button>
                </div>
              </div>
            </section>

            <section className="or-section mb-4 mb-lg-0">
              <div className="d-flex h-100 flex-column justify-content-center">
                <div className="seperator-div d-lg-none"></div>
                <div className="or-container mb-lg-4">
                  <span className="font-weight-medium font-size-14 text-white">
                    OR
                  </span>
                </div>
              </div>
            </section>

            <section className="amount-section">
              <div className="d-flex h-100 flex-column justify-content-between">
                <div>

                  {/* ! hidden for a purpose */}

                {/* <p className="modal-text-heading text-center font-size-16 font-weight-medium text-primary mb-2">
                {formController.$("target_amount_type_dropdown").label}
                </p>

                <div className="w-100 d-flex justify-content-center">
                  <BottomsheetDropdown
                    dropdownStyle="font-size-lg-12 font-size-14 font-weight-light bg-white"
                    menuItemStyle="d-flex justify-content-between align-items-center"
                    menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                    selectedLabelKey="label"
                    bottomsheetTitle={
                      formController.$("target_amount_type_dropdown").extra
                        .bottomSheetTitle
                    }
                    menuList={
                      formController.$("target_amount_type_dropdown").extra
                        .optionList
                    }
                    value={formController.$("target_amount_type_dropdown").value}
                    onSelect={
                      formController.$("target_amount_type_dropdown").onChange
                    }
                    showErrorMsg={
                      formController.$("target_amount_type_dropdown").error !==
                      null
                    }
                    errorMsg={
                      formController.$("target_amount_type_dropdown").error
                    }
                    disabled={
                      formController.$("target_amount_type_dropdown").disabled
                    }
                  />
                </div> */}
                  
                </div>
                <div className="mt-2 mt-lg-0">
                  <p className="modal-text-heading text-center font-size-16 font-weight-medium text-primary mb-2">
                    {formController.$("target_amount_dropdown").label}
                  </p>

                  <div className="w-100 d-flex justify-content-center">
                    <BottomsheetDropdown
                      dropdownStyle="font-size-lg-12 font-size-14 font-weight-light bg-white"
                      menuItemStyle="d-flex justify-content-between align-items-center"
                      menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                      selectedLabelKey="label"
                      bottomsheetTitle={
                        formController.$("target_amount_dropdown").extra
                          .bottomSheetTitle
                      }
                      menuList={
                        formController.$("target_amount_dropdown").extra
                          .optionList
                      }
                      value={formController.$("target_amount_dropdown").value}
                      onSelect={
                        formController.$("target_amount_dropdown").onChange
                      }
                      showErrorMsg={
                        formController.$("target_amount_dropdown").error !==
                        null
                      }
                      errorMsg={
                        formController.$("target_amount_dropdown").error
                      }
                      disabled={
                        formController.$("target_amount_dropdown").disabled
                      }
                    />
                  </div>
                </div>

                <div className="mt-4 text-center">
                  <button
                    className="modal-btn py-2 py-lg-1 font-size-14 font-weight-medium"
                    onClick={() => onSet(formController, hideProtectionModal)}
                  >
                    Set
                  </button>
                </div>
              </div>
            </section>
          </div>
        </article>
      </section>
    </ModalBody>
  )
}

ProtectionForFamilyModalForm.propTypes = {
  openProtectionCalculator: PropTypes.func.isRequired,
  hideProtectionModal: PropTypes.func.isRequired,
}

export default observer(ProtectionForFamilyModalForm)
