// react imports
import React from "react"

// package imports
import { Row, Col, Form } from "react-bootstrap"
import { observer } from "mobx-react"
import moment from "moment"
import {
  BottomsheetDropdown,
  DeathCoverPriceComponent,
  DatepickerModal,
  ChangeProductSection
} from "../../../../../components"

// project imports
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider"
import Utils from "./utils"
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed"
import DeathBenefitAmount from "utils/optimization/deathBenefitAmount";

// css imports
import "../../optimization-card/family-collapse-view.css"


const SaralPension = ({ form, data }) => {
  let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let biResponse = productData.parsedResponse
  let firstYearResponse = productData.firstYearResponse
  let biRequest = productData.bi_request
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)


  return (
    <div className="">
      {/* <Row className="mt-4 mb-3 pb-1">
        <Col xs={12} lg={7}>
          <DeathCoverPriceComponent
            firstAmount={data.data.firstYearResponse.SA}
            secondAmount={
              productData.isValidationExist
                ? 0
                : Utils.getTotalPrice(biResponse)
            }
            thirdAmount="980"
            showAdditionalText={false}
            showSecondColAmount={false}
            secondColBottomText={form.$("payment-frequency").value.label}
            firstColTitleText={coverLabelBaseOnNeed}
            secondColTitleText="Price"
          />
        </Col>
      </Row> */}
      <Row>
        <Col xs={12} md={4} className="mt-3 mt-md-0">
          <ChangeProductSection
            productName={data.product_name || "Saral Pension"}
            cardName={coverLabelBaseOnNeed}
            changeProductList={data?.data?.changeProductList}
            productData={data}
          />
        </Col>

        <Col xs={12} className="mt-2 mt-md-0">
          <DeathCoverGraphSlider
            productData={data?.data}
            saLabel="Income"
            productTitle="Saral Pension"
            openChangeReasonModal={() => { }}
            onIncomeChange={form.$("income").onChange}
            onPremiumChange={form.$("premium-amt").onChange}
            dataChange={() => { }}
            cardKey={data.cardKey}
            hideOverLay={() => { }}
            productID={""}
            income_value={form.$('income').value}
            premium_value={form.$("premium-amt").value}
            showOverlay={false}
            onClick={() =>
              form
                .$("premium-amt")
                .extra.calculatePremium(form, form.$("premium-amt").value, data)
            }
            data={""}
            isIncomeFieldDisable={false}
            isPremiumDisable={false}
            premiumFreq={form.$("payment-frequency").value.label}
            onIncomeBlur={() => { form.$("income").extra.calculateIncome(form, data) }}
            formRef={form}
            secondInputTextlabel="Single Premium"
          />
        </Col>
      </Row>
      <Row className='mt-3 ml-0'>
        <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{"Death Benefit:"}</p>
        <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(productData)}</p>
      </Row>

      <Row className="mt-3">
        {/* <Col xs={12} md={6} className="mb-3 mb-md-0">
          <p className="mb-2 font-size-12 font-weight-light ">
            {form.$("payment-frequency").label}
          </p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("payment-frequency").label}
            menuList={form.$("payment-frequency").extra.options}
            value={form.$("payment-frequency").value}
            onSelect={form.$("payment-frequency").onChange}
            disabled={true}
          />
        </Col> */}
        <Col  xs={12} md={6}>
          <div className="mb-2 font-size-12 font-weight-light ">
            {form.$("source-of-purchase-price").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("source-of-purchase-price").label}
            menuList={form.$("source-of-purchase-price").extra.options}
            value={form.$("source-of-purchase-price").value}
            onSelect={form.$("source-of-purchase-price").onChange}
            disabled={false}
          />
        </Col>

        <Col xs={12} md={6} className="mt-2 product-form">
          <div className="mb-2 font-size-12 font-weight-light ">
            {form.$("annuity-option").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("annuity-option").label}
            menuList={form.$("annuity-option").extra.options}
            value={form.$("annuity-option").value}
            onSelect={form.$("annuity-option").onChange}
            disabled={false}
          />
        </Col>

        <Col xs={12} md={6} className="mt-2">
          <div className="mb-2 font-size-12 font-weight-light ">
            {form.$("annuity-mode").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("annuity-mode").label}
            menuList={form.$("annuity-mode").extra.options}
            value={form.$("annuity-mode").value}
            onSelect={form.$("annuity-mode").onChange}
          />
        </Col>

        {form.$("annuity-option").value.id === "2" &&
          <>
            <Col xs={12} md={6} className="mt-2 product-form">
              <div className="mb-2 font-size-12 font-weight-light ">
                {form.$("secondary-annuitant-name").label}
              </div>
              <Form.Control
                className="font-size-12 font-weight-light text-black"
                placeholder="name"
                value={form.$("secondary-annuitant-name").value}
                onChange={form.$("secondary-annuitant-name").onChange}
                onBlur={() => form.$("secondary-annuitant-name").extra.onSecondaryAnnuitantNameChange(form, data)}
                disabled={form.$("secondary-annuitant-name").disabled}
              />
            </Col>

            <Col xs={12} md={6} className="mt-2 product-date-picker">
              <div className="mb-2 font-size-12 font-weight-light">
                {form.$("secondary-annuitant-date-of-birth").label}
              </div>
              <DatepickerModal
                plain
                value={form.$("secondary-annuitant-date-of-birth").value}
                textStyle='font-size-10 font-weight-light text-black text-nowrap overflow-hidden txt-overflow'
                onChange={(value) => form.$("secondary-annuitant-date-of-birth").extra.changeDob(form, value, data)}
                disabled={form.$("secondary-annuitant-date-of-birth").disabled}
                minDate={moment().subtract(18, 'years').toString()}
              />
            </Col>

            <Col xs={12} md={6} className="mt-2">
              <div className="mb-2 font-size-12 font-weight-light ">
                {form.$("secondary-annuitant-gender").label}
              </div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey="label"
                bottomsheetTitle={form.$("secondary-annuitant-gender").label}
                menuList={form.$("secondary-annuitant-gender").extra.options}
                value={form.$("secondary-annuitant-gender").value}
                onSelect={form.$("secondary-annuitant-gender").onChange}
                disabled={form.$("secondary-annuitant-gender").disabled}
              />
            </Col>
          </>
        }

        {/* <Col xs={12} md={6} className="mt-2 product-form">
          <div className="mb-2 font-size-12 font-weight-light ">
            {form.$("purchase-price-amount").label}
          </div>
          <Form.Control
            className="font-size-12 font-weight-light text-black"
            placeholder={form.$("purchase-price-amount").label}
            value={form.$("purchase-price-amount").value}
            onChange={(e)=> form.$('purchase-price-amount').extra.onPurchasePriceAmtChange(e,form, data)}
            onBlur={() => form.$('purchase-price-amount').extra.onPurchasePriceAmtBlur(form, data)}
            disabled={false}
          />
        </Col> */}
      </Row>

    </div>
  )
}

export default observer(SaralPension)
