// React Imports 
import React, { Component } from "react";

// Package Imports
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import AuthStore from "modules/login/store/AuthStore";

// Project Imports
import CustomerCommunicationForm from './form'
import customerCommunicationController from './form/controller'
import utils from "./form/utils";
import SolutionStore from "../store/SolutionStore"

// CSS Imports
import "./customer-communication-detail.css";

const CustomerCommunicationDetailModal = observer(
  class CustomerCommunicationDetailModal extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isOpen: false,
      };
    }

    async componentDidMount() {
      let controller = new customerCommunicationController()
      let journeyData = toJS(AuthStore.journeyData);
      let journeyId = toJS(AuthStore.journeyId);
      controller.setDefaults(journeyData, journeyId);


      this.setState({
        // isOpen: isOpen,
        formController: controller
      })
    }

    showCommunicationDetailModal = () => {
      this.setState({ isOpen: true });
    };

    hideCommunicationDetailModal = () => {
      this.setState({ isOpen: false });
      SolutionStore.customerDetailsModalToggleFunction(false)
    };


    successHandler = async (formValues, journeyId) => {
      let solutionList = toJS(SolutionStore.user_list_data).map((item) => { return item.solution_id })
      let status = await utils.onPreviewBtnCick(formValues)

      if(status == "success" && SolutionStore.isCloseModalOpen){
        global.loading(true)
        AuthStore.handleRedirectionToPragati()
      } else if (status == "success") {
        this.props.history.push(`/quotation-preview/${journeyId}`, { solutionList: solutionList });
        this.hideCommunicationDetailModal()
        return
      } else {
        this.hideCommunicationDetailModal()
      }
    }

    submitData = () => {
      this.state.formController.validate({ showErrors: true })
        .then(({ isValid }) => {
          let journeyId = toJS(AuthStore.journeyId);
          if (isValid) {
            let formValues = this.state.formController.values()
            this.successHandler(formValues, journeyId)
          } else {
            console.log("Form errors are ", this.state.formController.errors())
          }
        });
    }


    render() {
      return (
        <div className='customerCommunicationModalDiv d-flex align-items-center'>
          <Modal
            show={SolutionStore.isCustomerDetailModalOpen}
            backdrop="static"
            onHide={this.hideCommunicationDetailModal}
            as='section'
            className='welcomeScreeenModal'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            keyboard={false}
          >
            <ModalBody className='customer-communication-modal-body'>
              <section>
                <div className='row customer-communication-modal-content'>
                  <div className='customer-communication-modal-hero'>
                    <div className='mr-lg-2 pt-1 py-md-4 my-3 text-center text-lg-right'>
                      <p className='heading text-lg-right font-weight-medium font-size-18 font-size-md-18 mb-0'>Customer Communication</p>
                      {/* <p className='heading text-lg-right font-weight-medium font-size-18 font-size-md-18 mb-0'>Communication</p> */}
                      <p className='heading text-lg-right font-weight-medium font-size-18 font-size-md-18 mb-0'>
                        Details
                      </p>
                      <h1 className='communication-modal-subHeading font-size-12 text-lg-right d-lg-block'>
                        Share Consolidated Quotation
                      </h1>
                    </div>
                  </div>

                  <CustomerCommunicationForm
                    formController={this.state.formController}
                    hideModal={this.hideCommunicationDetailModal}
                    submitData={this.submitData}
                    {...this.props}
                  />

                </div>
              </section>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);

export default CustomerCommunicationDetailModal;
