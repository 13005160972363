import Utils from './utils';

const initialDefaults = (formRef, data) => {
    let requestData = data.data.bi_request
    let claimOption = requestData.PlanOption
    let ppf = requestData.INPUT_MODE
    let ptList = Utils.generatePT()
    let pptList = Utils.generatePPT()


    formRef.$('policy-term').set('extra', {
        ...formRef.$('policy-term').extra,
        optionList: ptList
    })

    formRef.$('premium-paying-term').set('extra', {
        ...formRef.$('premium-paying-term').extra,
        optionList: pptList
    })


    formRef.$('policy-term').set('value', {
        id: requestData.PR_PT,
        label: requestData.PR_PT
    })

    formRef.$('premium-paying-term').set('value', {
        id: requestData.PR_PPT,
        label: requestData.PR_PPT
    })

    switch (ppf) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;

        default:
            break;
    }

    switch (claimOption) {

        case '1':
            formRef.$('claim-option').set('value', {
                id: '1',
                label: 'Single Claim'
            })
            break;
        case '2':
            formRef.$('claim-option').set('value', {
                id: '2',
                label: 'Multi Claim'
            })
            break;
        default:
            break;
    }

    formRef.$('premium').set('value', data.data.firstYearResponse.MODAL_PREM)
    formRef.$('income').set('value', requestData.PR_SA)

}

export default initialDefaults