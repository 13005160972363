export const NUMBER_REGEX = /^[0-9\b]+$/;

export const CHARACTER_REGEX = /^[a-zA-Z \b]+$/;

export const MOBILE_NO_VALIDATION = /^([6-9]{1})([0-9]{9})/

export const PAN_NUMBER_REGEX = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;

export const EMAIL_REGEX = /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;

export const CALCULATOR_MODAL_DESCRIPTION =
  "This tool utilizes the details like monthly expenses, outstanding loan amounts, assets, savings etc. to calculate the inflation corrected amount required to maintain your family's lifestyle and secure their future.";

export const RETIREMENT_CALCULATOR_MODAL_DESCRIPTION =
  "This tool utilizes the monthly expenses to calculate the inflation corrected amount required to maintain your lifestyle after retirement.";

export const RIDERS_DEFAULT_SA = "100000";

// Riders Description
export const ADB_RIDER_DESCRIPTION = "The Edelweiss Tokio Life - Accidental Death Benefit Rider gives you extra protection. In case an accident claims your life, your family will get the selected accidental death cover amount in addition to the base life cover amount.";
export const ATPD_RIDER_DESCRIPTION = "If you opt for Edelweiss Tokio Life - Accidental Total and Permanent Disability Rider, you’ll get the cover amount you have selected in case an accident causes permanent disability. This takes care of all your immediate expenses such as medications, hospital bills and regular expenses such as EMIs, education fees etc. The base policy continues as usual.";
export const CRITICAL_ILLNESS_RIDER_DESCRIPTION = "Under Edelweiss Tokio Life - Critical Illness Rider, if you are diagnosed with one of the 12 specific critical illnesses, you will receive a payout of the critical illness cover you’ve selected. The policy will continue with the base life cover after critical illness claim paid. Term life insurance is for your family’s security but this rider will cover you financially against 12 listed critical illnesses.";
export const INCOME_BENEFIT_RIDER_DESCRIPTION = "Under Edelweiss Tokio Life - Income Benefit Rider ensures your family has continuity of regular income even in your absence.";
export const WOP_RIDER_DESCRIPTION = "Simple life insurance cover";
export const PWB_RIDER_DESCRIPTION = "Under Edelweiss Tokio Life - Payor Waiver Benefit, covers 35 critical illnesses. If you are diagnosed with any one of them all your future premiums will be waived off and your policy will continue as usual along with its benefits. So you want have to worry about paying premiums and focus on your health."
export const RIDER_GST_PERCENT = 18;
export const PRODUCT_GST_PERCENT = 18;

export const TOKEN_EXPIRY_TIME_IN_MINUTES = 19
// Below parameter is used to make Refresh token API call every 10 minutes 600000 ms = 10 Minutes
export const REFRESH_TOKEN_IN_MINUTES = 600000

export const CUSTOMIZATION_LOADER_MSG = "Calculating"

/// Modal Factor for Forward and Reverse Calculation

// for 8.8%
export const MONTHLY_MODAL_FACTOR = 0.088;
// for 26%
export const QUATERLY_MODAL_FACTOR = 0.26;
// for 51.2%
export const SEMI_ANNUAL_MODAL_FACTOR = 0.512;
// for 100%
export const ANNUAL_MODAL_FACTOR = 1;
