import 'core-js/fn/object/assign';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './modules/Main';

//Bootstrap scss Commented for temporary purpose
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/scss/custom-styleguide.scss'


// Render the main component into the dom
ReactDOM.render(<App />, document.getElementById('app'));
