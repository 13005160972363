import { makeObservable, observable } from "mobx";

import axios from "axios";
import * as APITypes from "constants/API";

class OptimizationStore {
    leadSpecificId = ""
    solutionId = ""
    selectedTabData = {}

    constructor() {
        makeObservable(this, {
            leadSpecificId: observable,
            solutionId: observable
        });
    }

    async startApplication(journeyId) {
        let generatedSolutionData = await this.onGenerateSolutionData(journeyId);
        if (generatedSolutionData) {
            this.solutionId = generatedSolutionData.data[0].solution_id;
            this.leadSpecificId = generatedSolutionData.data[0].la_specific_ids_array[0];
        }
        let pragatiOutboundFormObj = document.getElementById("pragati-out-oubound-form-post-call");
        console.log("onPragatiOutboundClick formObj :: ", pragatiOutboundFormObj);
        pragatiOutboundFormObj.submit();
    }

    async onGenerateSolutionData(journeyId) {
        const generatedSolutionData = await this.createSolutionData(journeyId);
        if (generatedSolutionData && generatedSolutionData.data.length > 0) {
            return generatedSolutionData;
        }
        return [];
    }
    async createSolutionData(journeyId) {
        const response = await axios.get(APITypes.GENERATE_SOLUTION_DATA + journeyId + "/");
        return response;
    }

    setSelectedTabData(tabData) {
        this.selectedTabData = tabData
    }
}

export default new OptimizationStore();
