import * as keys from "./keys";
import OptimizationStore from "../../../store/OptimizationStore";
import Validator from "./validator";
import calculateRiderPremium from "utils/calculateRiderPremium";
import calculateProductPremiumRelatedValues from "utils/calculateProductPremiumRelatedValues";
import * as Constants from "constants/Constants";

class Utils {
  getPolicyTerm = () => {
    let pt = [];
    for (let i = 6; i <= 12; i++) {
      if (i != 9 && i != 11) {
        pt.push({ id: i, label: "" + i });
      }
    }
    return pt;
  };

  generatePPT = () => {
    let ppt = [];
    ppt.push({ id: 1, label: "" + 1 });
    for (let i = 6; i <= 12; i++) {
      if (i != 9 && i != 11) {
        ppt.push({ id: i, label: "" + i });
      }
    }
    return ppt;
  };

  generatePPF = (pptValue) => {
    let pf = [];

    let ppfList = ["Annual", "Semi-Annual", "Quarterly", "Monthly", "Single"];

    if (pptValue && pptValue !== "1") {
      ppfList = ["Annual", "Semi-Annual", "Quarterly", "Monthly"];
    }

    for (let i = 0; i < ppfList.length; i++) {
      pf.push({ id: i + 1, label: ppfList[i] });
    }
    return pf;
  };

  getPayoutPeriodList = () => {
    let allowedYears = [6, 7, 8, 10, 12, 13, 15, 18, 20, 23];
    let payoutPeriodList = [];
    for (let i = 0; i < allowedYears.length; i++) {
      payoutPeriodList.push({
        id: 1 + i,
        label: allowedYears[i],
      });
    }
    return payoutPeriodList;
  };

  getCoverAmountList = () => {
    let list = [];
    for (let index = 0; index <= 100; index = index + 5) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + " L",
        });
      } else {
        list.push({
          id: index,
          label: 1 + " Cr.",
        });
      }
    }
    return list;
  };

  getCoverAmountListForCLRnHCB = () => {
    let list = [];
    for (let index = 0; index <= 6; index = index + 1.5) {
      list.push({
        id: index == 0 ? 1 : index,
        label: (index == 0 ? 1 : index) + " L",
        key: index * 100000,
      });
    }
    return list;
  };

  getTotalPrice = (data) => {
    try {
      return Number(data.TOTAL_PREM_TAX);
    } catch (e) {
      return 0;
    }
  };

  updateBi = async (key, value, productId, formRef, productData) => {
    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request;

    switch (key) {
      case keys.PR_PT:
        biRequest[keys.PR_PT] = value;
        break;
      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;
      case keys.FREQUENCY:
        let premiumAmount = 0;
        let amount = value.premium;
        switch (Number(value.frequency)) {
          case 1:
          case 5:
            premiumAmount = "" + amount * 1;
            break;
          case 2:
            premiumAmount = "" + amount * 2;
            break;
          case 3:
            premiumAmount = "" + amount * 4;
            break;
          case 4:
            premiumAmount = "" + amount * 12;
            break;
        }

        biRequest[keys.PREMIUM] = premiumAmount;
        biRequest[keys.FREQUENCY] = value.frequency;
        break;
      case keys.PAYOUT_PERIOD:
        biRequest[keys.PAYOUT_PERIOD] = value;
        break;
      case keys.OPTION_NAME:
        biRequest[keys.OPTION_NAME] = value;
        break;
      case keys.MATURITY_BENEFIT_OPTION:
        biRequest[keys.MATURITY_BENEFIT_OPTION] = value;
        break;
      case keys.PAYOUT_MODE:
        biRequest[keys.PAYOUT_MODE] = value;
        let targetAmount = Number(biRequest?.MODAL_INCOME);
        switch (value) {
          case "15":
          case 15:
              biRequest["MODAL_INCOME"] = Math.round(targetAmount / 1);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
          case "17":
          case 17:
              biRequest["MODAL_INCOME"] = Math.round(targetAmount / 2);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
          case "18":
          case 18:
              biRequest["MODAL_INCOME"] = Math.round(targetAmount / 4);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
          case "19":
          case 19:
              biRequest["MODAL_INCOME"] = Math.round(targetAmount / 12);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
        }
        break;
      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value;
        break;
      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value;
        break;

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = value;
        } else {
          biRequest[keys.ADB_RIDER] = "";
        }
        break;

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = value;
        } else {
          biRequest[keys.ATPD_RIDER] = "";
        }
        break;

      case keys.INCOME_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.INCOME_BENEFIT_RIDER] = value;
        } else {
          biRequest[keys.INCOME_BENEFIT_RIDER] = "";
        }
        break;

      case keys.PWB_RIDER:
        if (value) {
          biRequest[keys.PWB_RIDER] = "1";
          biRequest.PWB = "" + value;
        } else {
          biRequest[keys.PWB_RIDER] = "";
        }
        break;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value);
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return;
    // }
    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value);
  };

  getRiderTax = (data) => {
    let totalRider = 0;
    data?.parsedResponse?.inputValidationStatus?.map((item) => {
      totalRider += Number(item.Tax);
    });
    return totalRider;
  };

  // fetchPolicyTimeline(element, frequency, maturityBenefitOption, index, arrayLength) {
  //   let amount = Math.round(element.MODAL_PREM_TAX);
  //   let premiumAmount = ''
  //   switch (frequency.Value) {
  //     case '1':
  //     case '5':
  //       premiumAmount = '' + amount * 1
  //       break;
  //     case '2':
  //       premiumAmount = '' + amount * 2
  //       break;
  //     case '3':
  //       premiumAmount = '' + amount * 4
  //       break;
  //     case '4':
  //       premiumAmount = '' + amount * 12
  //       break;
  //   }

  //   let isRegularIncome = maturityBenefitOption.Value == '13'
  //   let youGet = 0;
  //   if (isRegularIncome) {
  //     if (arrayLength - 1 == index) {
  //       youGet = Math.round(element.INCOME_DISPLAY) + Math.round(element.INCOME_DISPLAY)
  //     } else {
  //       youGet = Math.round(element.INCOME_DISPLAY)
  //     }
  //   } else {
  //     youGet = Math.round(element.INCOME_DISPLAY) + Math.round(element.INCOME_DISPLAY)
  //   }
  //   let data = {
  //     youPay: Math.round(premiumAmount),
  //     youGet: youGet,
  //     lifeCover: Math.round(element.DB_G),
  //     isMilestone: Math.round(element.INCOME_DISPLAY) !== 0,
  //     premiumAmt: Math.round(element.MODAL_PREM_1),
  //   };
  //   return data;
  // }

  newPolicyTimeLineData(element, inputString, index) {
    const { MODAL_PREM, MODAL_INCOME, SA, DB_G, ANN_PREM_YEARLY, INCOME_DISPLAY } = element;

    let premiumAmount = 0;

    // let inputMode = parseInt(inputString.find(item => item.Key === '@INPUT_MODE').Value)

    // if (inputMode === 1) {
    //   premiumAmount = Math.round(MODAL_PREM) * Constants.ANNUAL_MODAL_FACTOR;
    // } else if (inputMode === 2) {
    //   premiumAmount = Math.round(MODAL_PREM) * Constants.SEMI_ANNUAL_MODAL_FACTOR;
    // } else if (inputMode === 3) {
    //   premiumAmount = Math.round(MODAL_PREM) * Constants.QUATERLY_MODAL_FACTOR;
    // } else if (inputMode === 4) {
    //   premiumAmount = Math.round(MODAL_PREM) * Constants.MONTHLY_MODAL_FACTOR;
    // }

    let data = {
      youPay: Math.round(ANN_PREM_YEARLY),
      youGet: Math.round(INCOME_DISPLAY),
      lifeCover: Math.round(DB_G),
    };

    return data;
  }

  setPPTValue(pt) {
    let ppt = " ";
    if (pt == 6 || pt == 8) {
      ppt = 1;
    } else {
      ppt = pt;
    }
    return { id: ppt, label: "" + ppt };
  }

  setPayoutPeriod = (pt) => {
    let allowedYears = [6, 7, 8, 10, 12, 13, 15, 18, 20, 23];
    for (let i = 0; i < allowedYears.length; i++) {
      if (pt == allowedYears[i]) {
        return {
          id: i + 1,
          label: allowedYears[i],
        };
      }
    }
  };
}

export default new Utils();
