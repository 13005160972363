// Package Imports
import { toJS } from "mobx";
import moment from "moment";

// Project Imports
import axios from "utils/axios";
import * as APITypes from 'constants/API';
import isObjectEmpty from "utils/isObjectEmpty";
import checkIsValueEmpty from "utils/checkIsValueEmpty";
import createNewObject from "utils/createNewObject";
import generateRandomString from "utils/generateRandomString";
import FamilyMemberFieldStructureObject from "./FamilyMemberFieldStructureObject";
import FamilyDetailsStore from "../store/FamilyDetailsStore";
import FamilyMemberTypeEnum from "../FamilymemberTypeEnum";
import FamilyMemberList from "./familyMembersList";
import FieldTypeEnum from "./FieldTypsEnum";
import familyMemberList from "./familyMembersList";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";
import AuthStore from "modules/login/store/AuthStore";
import fetchDeselectedExpenses from "utils/fetchDeselectedExpenses";
import addFamilyMemberFromUtils from "utils/addFamilyMember";
import genderTypeEnum from "constants/genderTypeEnum";
import genderValueEnum from "constants/genderValueEnum";
import fetchDeselectedExpensesForEmi from "utils/fetchDeselectedExpensesForEmi";

const checkIfFamilyMemberAreAdded = (membersList) => {

    let defaultSelectedOptionKeys = ["salutation", 'nationality', 'is_selected', 'family_member_type', 'gender', 'current_life_cover','monthly_income_dropdown_option','monthly_income_value']
    let selectedMemberList = [];

    for (const member in membersList) {

        for (const memberField in membersList[member]) {
            if (defaultSelectedOptionKeys.includes(memberField)) {
                continue
            }

            if (!checkIsValueEmpty(membersList[member][memberField])) {
                selectedMemberList.push(member)
                break;
            }
        }
    }

    selectedMemberList.push("self")

    return selectedMemberList
}

export const fetchSelectedMembers = (membersList, formController) => {
    let selectedMemberList = checkIfFamilyMemberAreAdded(membersList)
    for (let member in membersList) {
        let isSelected = false
        if (selectedMemberList.includes(member)) {
            isSelected = true
        }
        formController.$("members").$(member).extra.isSelected = isSelected
    }

    return selectedMemberList
}

const closeModal = (hideModal, formController) => {
    let journeyData = toJS(AuthStore.journeyData)
    reInitializeFamilyMemberData(journeyData, formController)
    hideModal()
}

export const reInitializeFamilyMemberData = (journeyData, formController) => {

    let lifeCoverList = FamilyDetailsStore.lifeCoverList
    let nationalMasterList = FamilyDetailsStore.nationalityList
    let salutationMasterList = FamilyDetailsStore.salutationList
    let monthlyIncomeMasterList = FamilyDetailsStore.monthlyIncomelist

    let selfDetails = formController.$('members').$('self').get()

    let selfDetailsObj = {}

    for (let key in selfDetails) {
        selfDetailsObj[key] = selfDetails[key].value
    }

    let familyFieldList = preFillUserFields(journeyData, lifeCoverList, nationalMasterList, salutationMasterList, monthlyIncomeMasterList)

    familyFieldList[0].fields.forEach((item) => {

        const {name,value} = item

        if(!item.hasOwnProperty('value') || checkIsValueEmpty(value)){
            item.value = selfDetailsObj[name]
        }
    })

    formController.$("members").del()

    formController.add(
        {
            name: "members",
            fields: familyFieldList
        }
    )
}

const addFamilyMember = async (formController, hideModal) => {

    addFamilyMemberFromUtils(
        formController,
        fetchSelectedMembers,
        submitDataToTheServer,
        AuthStore,
        reInitializeFamilyMemberData,
        hideModal
    )
}


const removeEmptyObject = (objectData) => {
    let itemdata = []
    for (const item in objectData) {
        if (!isObjectEmpty(objectData[item])) {
            itemdata.push(objectData[item])
        }
    }
    return createNewObject(itemdata)
}

const sendDataToServer = async (formController) => {
    let { isValid } = await formController.validate({ showErrors: true })

    if (isValid) {
        let { members, liabilities, monthly_expense, savings, monthly_emi } = formController.values()

        let selectedMonthlyExpense = removeEmptyObject(monthly_expense)
        let monthlyEmi = removeEmptyObject(monthly_emi)
        let selectedLiabilities = removeEmptyObject(liabilities)
        let selectedSavings = removeEmptyObject(savings)

        let expenseList = [
            ...selectedMonthlyExpense,
            ...selectedLiabilities,
            ...selectedSavings,
        ]

        let emiList = [];
        let monthlyEmiObject = { ...monthlyEmi };
        for (const selectedKey in monthlyEmiObject) {
            let objPrefix = monthlyEmiObject[selectedKey];
            let isDisabled = isObjectEmpty(monthlyEmiObject[selectedKey].option_range)

            if (!isDisabled) {
                emiList.push({
                    action: "save",
                    id: objPrefix.option_range.id,
                    expense_range_id: objPrefix.option_range.id,
                    expense_range_key: objPrefix.option_range.label,
                    expense_range_value: objPrefix.option_range.label,
                    paid_years: objPrefix.paid_years.value,
                    total_years: objPrefix.total_years.value,
                    user_journey: AuthStore.journeyId,
                });
            }
        }

        expenseList.map((item) => {
            item.action = 'save';
            item.expense_range_id = item.id
            item.expense_range_key = item.label
            item.expense_range_value = item.value
            return item
        })

        const selectedMemberList = fetchSelectedMembers(members, formController)

        await submitDataToTheServer(formController, selectedMemberList, expenseList, emiList)

        global.loading(false);

    } else {
        console.log("Error are ", formController.errors())
    }
}


const createFamilyMemberFieldData = (lifeCoverList, nationalMasterList, salutationMasterList, monthlyIncomeMasterList, familyProductData) => {

    let memberFieldsList = []

    let isProtectionNeedSelected = checkIsProtectionNeedSelected(familyProductData)

    FamilyMemberList.forEach((item) => {
        let memberFieldObject = generateMemberEmptyDataStrucutre(item, lifeCoverList, nationalMasterList, salutationMasterList, monthlyIncomeMasterList, isProtectionNeedSelected)
        memberFieldsList.push(memberFieldObject)
    })

    return memberFieldsList

}

export const submitDataToTheServer = async (formController, selectedMemberList, expenseList = [], emiList = []) => {

    global.loading(true);

    let values = formController.values();

    let data = []
    let familyMembersList = values.members

    for (const memberData in familyMembersList) {

        if (selectedMemberList.includes(memberData)) {
            data.push(familyMembersList[memberData])
        }
    }

    data.map(item => {

        if (!checkIsValueEmpty(item.salutation)) {
            item.salutation = item.salutation.id
        }

        if (!checkIsValueEmpty(item.nationality)) {
            item.nationality = item.nationality.id
        }

        if (!checkIsValueEmpty(item.monthly_income_dropdown_option)) {
            item.monthly_income_dropdown_option = item.monthly_income_dropdown_option.id
        }

        if (!checkIsValueEmpty(item.dob)) {
            item.dob = moment(item.dob).format('YYYY-MM-DD')
        }

        if (!checkIsValueEmpty(item.current_life_cover)) {
            item.current_life_cover = item.current_life_cover.id
        }

        if (!isObjectEmpty(item.gender)) {
            item.gender = item.gender.value
        }

        item.monthly_income = item.monthly_income_value
        item.is_selected = true

        item.first_name = capitalizeFirstLetter(item.first_name)
        item.middle_name = capitalizeFirstLetter(item.middle_name)
        item.last_name = capitalizeFirstLetter(item.last_name)

        for (const [key, value] of Object.entries(item)) {
            if (typeof (value) === 'string') {
                if (value === '') {
                    delete item[key]
                }

            }

            if (typeof (value) === 'object') {
                if (isObjectEmpty(value)) {
                    delete item[key]
                }
            }
        }

        return item
    })

    let monthleExpenseDeSelectedList = fetchDeselectedExpenses(formController, 'monthly_expense')
    let monthleSavingsDeSelectedList = fetchDeselectedExpenses(formController, 'liabilities')
    let currentSavingsDeSelectedList = fetchDeselectedExpenses(formController, 'savings')
    let emiDeSelectedList = fetchDeselectedExpensesForEmi(formController, 'monthly_emi')

    let deSelectedExpenseList = [
        ...monthleExpenseDeSelectedList,
        ...monthleSavingsDeSelectedList,
        ...currentSavingsDeSelectedList
    ]

    let deselectExpensesData = deSelectedExpenseList.map(item => item.journeyExpenseId)
    let selfData = data.find((item) => item.family_member_type === FamilyMemberTypeEnum.SELF)
    if (deselectExpensesData.length > 0) {
        await axios.post(
            APITypes.DESELECT_EXPENSE,
            {
                "family_member_id": selfData.id,
                "id": deselectExpensesData
            }
        )
    }

    if (emiDeSelectedList.length > 0) {
        await axios.post(
            APITypes.DESELECT_EMI,
            {
                "family_member_id": selfData.id.toString(),
                "id": emiDeSelectedList
            }
        )
    }

    let response = await axios.post(
        APITypes.SAVE_FAMILY_DETAILS,
        data = {
            journey_id: values.journey_id,
            family_list: data,
            expense_list: expenseList,
            emi_list: emiList
        }
    )

    return response;
}


const setFamilyMemberFieldValue = (fieldList, fieldName, fieldApiValue, fieldType = FieldTypeEnum.NORMAL) => {

    if (fieldApiValue == null) return

    switch (fieldType) {
        case FieldTypeEnum.GENDER_DROPDOWN:
            setDropDownOptionsListValue(fieldList, fieldName, fieldApiValue)
            break

        case FieldTypeEnum.MASTER_DATA:
            setMasterFieldValue(fieldList, fieldName, fieldApiValue)
            break

        default:
            fieldList.find(fieldObj => fieldObj.name == fieldName).value = fieldApiValue
            break
    }

}

const setMasterFieldValue = (fieldList, fieldName, fieldApiValue) => {
    let field = fieldList.find(fieldObj => fieldObj.name == fieldName)
    let selectedFieldValue = field.extra.optionList.find(fieldData => fieldData.id == fieldApiValue)
    if (selectedFieldValue !== undefined) {
        field.value = selectedFieldValue
    }
}

const setDropDownOptionsListValue = (fieldList, fieldName, fieldApiValue) => {
    let field = fieldList.find(fieldObj => fieldObj.name == fieldName)
    let selectedFieldValue = field.extra.optionList.find(fieldData => fieldData.value.toLowerCase() == fieldApiValue.toLowerCase())
    if (selectedFieldValue !== undefined) {
        field.value = selectedFieldValue
    }
}

const setIndividualFieldValue = (familyMemberApiData, field) => {

    setFamilyMemberFieldValue(field, 'id', familyMemberApiData.id)
    setFamilyMemberFieldValue(field, 'first_name', familyMemberApiData.first_name)
    setFamilyMemberFieldValue(field, 'middle_name', familyMemberApiData.middle_name)
    setFamilyMemberFieldValue(field, 'last_name', familyMemberApiData.last_name)
    setFamilyMemberFieldValue(field, 'dob', familyMemberApiData.dob)
    setFamilyMemberFieldValue(field, 'monthly_income_value', familyMemberApiData.monthly_income)
    setFamilyMemberFieldValue(field, 'gender', familyMemberApiData.gender, FieldTypeEnum.GENDER_DROPDOWN)
    setFamilyMemberFieldValue(field, 'nationality', familyMemberApiData.nationality, FieldTypeEnum.MASTER_DATA)
    setFamilyMemberFieldValue(field, 'current_life_cover', familyMemberApiData.current_life_cover, FieldTypeEnum.MASTER_DATA)
    setFamilyMemberFieldValue(field, 'salutation', familyMemberApiData.salutation, FieldTypeEnum.MASTER_DATA)
    setFamilyMemberFieldValue(field, 'monthly_income_dropdown_option', familyMemberApiData.monthly_income_dropdown_option, FieldTypeEnum.MASTER_DATA)

    // if (familyMemberApiData.monthly_income !== null) {
    //     field.find(fieldObj => fieldObj.name == 'monthly_income_value').type = 'text'
    //     field.find(fieldObj => fieldObj.name == 'monthly_income_dropdown_option').type = 'hidden'
    // } else {
    //     field.find(fieldObj => fieldObj.name == 'monthly_income_dropdown_option').type = 'text'
    //     field.find(fieldObj => fieldObj.name == 'monthly_income_value').type = 'hidden'
    // }
}

const checkIsProtectionNeedSelected = (familyProductDataList) => {
    let isProtectionNeedSelected = familyProductDataList?.some(
        (item) => {

            if (item.is_selected) {
                return item.need_unique_name === 'protection_for_my_family' || item.need_unique_name === 'protection_for_my_health'
            }

            return false
        })

    return isProtectionNeedSelected
}

const preFillFamilyDetails = (memberFieldObjectList, familyMemberList, lifeCoverList, nationalMasterList, salutationMasterList, monthlyIncomeMasterList, familyProductDataList) => {
    let selfDetails = familyMemberList?.find(member => member.family_member_type.toUpperCase() === FamilyMemberTypeEnum.SELF);
    let spouseDetails = familyMemberList?.find(member => member.family_member_type.toUpperCase() === FamilyMemberTypeEnum.SPOUSE);
    let childDetailsList = familyMemberList?.filter(member => member.family_member_type.toUpperCase() === FamilyMemberTypeEnum.CHILD);
    let adultChildDetailsList = familyMemberList?.filter(member => member.family_member_type.toUpperCase() === FamilyMemberTypeEnum.ADULT_CHILD);
    let grandChildDetailsList = familyMemberList?.filter(member => member.family_member_type.toUpperCase() === FamilyMemberTypeEnum.GRAND_CHILD);
    let grandFatherDetails = familyMemberList?.find(member => member.family_member_type.toUpperCase() === FamilyMemberTypeEnum.GRAND_FATHER);
    let grandMotherDetails = familyMemberList?.find(member => member.family_member_type.toUpperCase() === FamilyMemberTypeEnum.GRAND_MOTHER);

    let isProtectionNeedSelected = checkIsProtectionNeedSelected(familyProductDataList)

    if (childDetailsList?.length > 1) {
        let childData = FamilyMemberList?.find((member) => member.memberType === FamilyMemberTypeEnum.CHILD)
        for (let i = 0; i < childDetailsList.length - 1; i++) {
            let childMemberFieldObjectData = generateMemberEmptyDataStrucutre(childData, lifeCoverList, nationalMasterList, salutationMasterList, monthlyIncomeMasterList, isProtectionNeedSelected)
            childMemberFieldObjectData.name = childMemberFieldObjectData.name + (i + 1)
            memberFieldObjectList.push(childMemberFieldObjectData)
        }
    }

    if (adultChildDetailsList?.length > 1) {
        let adultChildData = FamilyMemberList.find((member) => member.memberType === FamilyMemberTypeEnum.ADULT_CHILD)
        for (let i = 0; i < childDetailsList.length - 1; i++) {
            let childMemberFieldObjectData = generateMemberEmptyDataStrucutre(adultChildData, lifeCoverList, nationalMasterList, salutationMasterList, monthlyIncomeMasterList, isProtectionNeedSelected)
            childMemberFieldObjectData.name = childMemberFieldObjectData.name + (i + 1)
            memberFieldObjectList.push(childMemberFieldObjectData)
        }
    }

    if (grandChildDetailsList?.length > 1) {
        let grandChildData = FamilyMemberList.find((member) => member.memberType === FamilyMemberTypeEnum.GRAND_CHILD)
        for (let i = 0; i < grandChildDetailsList.length - 1; i++) {
            let childMemberFieldObjectData = generateMemberEmptyDataStrucutre(grandChildData, lifeCoverList, nationalMasterList, salutationMasterList, monthlyIncomeMasterList, isProtectionNeedSelected)
            childMemberFieldObjectData.name = childMemberFieldObjectData.name + (i + 1)
            memberFieldObjectList.push(childMemberFieldObjectData)
        }
    }

    memberFieldObjectList.map(item => {

        if (item.name.toUpperCase() === FamilyMemberTypeEnum.SELF) {
            if (selfDetails !== undefined) {
                setIndividualFieldValue(selfDetails, item.fields)
            }
        }

        if (item.name.toUpperCase() === FamilyMemberTypeEnum.SPOUSE) {
            if (spouseDetails !== undefined) {
                item.extra.isSelected = true
                setIndividualFieldValue(spouseDetails, item.fields)
            }
        }

        if (item.name.toUpperCase().startsWith(FamilyMemberTypeEnum.CHILD)) {
            var currentChildElement = childDetailsList?.shift();
            if (currentChildElement !== undefined) {
                item.extra.isSelected = true
                setIndividualFieldValue(currentChildElement, item.fields)
            }
        }

        if (item.name.toUpperCase().startsWith(FamilyMemberTypeEnum.ADULT_CHILD)) {
            var currentChildElement = adultChildDetailsList?.shift();
            if (currentChildElement !== undefined) {
                item.extra.isSelected = true
                setIndividualFieldValue(currentChildElement, item.fields)
            }
        }

        if (item.name.toUpperCase().startsWith(FamilyMemberTypeEnum.GRAND_CHILD)) {
            var currentChildElement = grandChildDetailsList?.shift();
            if (currentChildElement !== undefined) {
                item.extra.isSelected = true
                setIndividualFieldValue(currentChildElement, item.fields)
            }
        }

        if (item.name.toUpperCase() === FamilyMemberTypeEnum.GRAND_FATHER) {
            if (grandFatherDetails !== undefined) {
                item.extra.isSelected = true
                setIndividualFieldValue(grandFatherDetails, item.fields)
            }
        }

        if (item.name.toUpperCase() === FamilyMemberTypeEnum.GRAND_MOTHER) {
            if (grandMotherDetails !== undefined) {
                item.extra.isSelected = true
                setIndividualFieldValue(grandMotherDetails, item.fields)
            }
        }

        return item
    })
}


const preFillUserFields = (journeyData, lifeCoverList, nationalMasterList, salutationMasterList, monthlyIncomeMasterList) => {

    let familyDetailsList = toJS(AuthStore.journeyData.family_details_list)

    let familyProductData = journeyData.family_product_details_list

    let familyFieldList = createFamilyMemberFieldData(lifeCoverList, nationalMasterList, salutationMasterList, monthlyIncomeMasterList, familyProductData)

    preFillFamilyDetails(
        familyFieldList,
        familyDetailsList,
        lifeCoverList,
        nationalMasterList,
        salutationMasterList,
        monthlyIncomeMasterList,
        familyProductData
    )

    return familyFieldList

}

const toggleDisableOptions = (formController, sectionName, fieldName, isDisabled) => {
    formController.$(sectionName).$(fieldName).set('disabled', isDisabled)
    if (formController.$(sectionName).$(fieldName).disabled) {
        formController.$(sectionName).$(fieldName).value = {}
        formController.$(sectionName).$(fieldName).resetValidation();
    }
}

const changeFieldValue = (formController, sectionName, fieldName, value) => {

    formController.$(sectionName).$(fieldName).value = value
    if(!checkIsValueEmpty(value)){
        formController.$(sectionName).$(fieldName).resetValidation();
    }
}

const generateMemberEmptyDataStrucutre = (familyObj, lifeCoverList, nationalMasterList, salutationMasterList, monthlyIncomeMasterList, isProtectionNeedSelected) => {
    let memberFieldObject = createNewObject(FamilyMemberFieldStructureObject)

    let journeyData = toJS(AuthStore.journeyData);
    let journeyList = toJS(AuthStore.journeyList)

    const selfJourneyTypeValue = journeyData.journey_details.journey_type
    const selfJourneyTypeOption = journeyList.find(singleJourney => singleJourney.id == selfJourneyTypeValue).label

    let selectedNation

    if (selfJourneyTypeOption == 'Individual (& Family)') {
        selectedNation = nationalMasterList.find(item => item.label === 'Indian')
    } else {
        selectedNation = nationalMasterList.find(item => item.label === 'NRI')
    }

    memberFieldObject.name = familyObj.name
    memberFieldObject.label = familyObj.label
    memberFieldObject.extra.isSelected = familyObj.defaultSelected
    memberFieldObject.fields.find(formField => formField.name === 'family_member_type').value = familyObj.memberType

    // set nationality master option list
    memberFieldObject.fields.find(formField => formField.name === 'nationality').extra.optionList = nationalMasterList
    memberFieldObject.fields.find(formField => formField.name === 'nationality').value = selectedNation

    // set life cover master option list
    memberFieldObject.fields.find(formField => formField.name === 'current_life_cover').extra.optionList = lifeCoverList

    // setting default value of cover to No cover
    memberFieldObject.fields.find(formField => formField.name === 'current_life_cover').value = lifeCoverList[0]

    // set Monthly Income Dropdown list
    memberFieldObject.fields.find(formField => formField.name === 'monthly_income_dropdown_option').extra.optionList = monthlyIncomeMasterList
    // memberFieldObject.fields.find(formField => formField.name === 'monthly_income_dropdown_option').value = monthlyIncomeMasterList[0]

    // set salutation master option list
    memberFieldObject.fields.find(formField => formField.name === 'salutation').extra.optionList = salutationMasterList
    memberFieldObject.fields.find(formField => formField.name === 'salutation').value = salutationMasterList[0]

    // set the default salution for spouse and grandmother
    if(familyObj.memberType === FamilyMemberTypeEnum.SPOUSE || familyObj.memberType === FamilyMemberTypeEnum.GRAND_MOTHER){
        memberFieldObject.fields.find(formField => formField.name === 'salutation').value = salutationMasterList[2]
    }

    // disbaled nationality dropdown for self
    if (familyObj.memberType === FamilyMemberTypeEnum.SELF){
        memberFieldObject.fields.find(formField => formField.name === 'nationality').disabled = true;
    }

    if (isProtectionNeedSelected) {
        memberFieldObject.fields.find(formField => formField.name === 'monthly_income_value').type = "text"
        memberFieldObject.fields.find(formField => formField.name === 'monthly_income_dropdown_option').type = "hidden"
    } else {
        memberFieldObject.fields.find(formField => formField.name === 'monthly_income_value').type = "hidden"
        memberFieldObject.fields.find(formField => formField.name === 'monthly_income_dropdown_option').type = "text"
    }

    // Hide Monthly income for Child & Grannd Child
    if (familyObj.memberType === FamilyMemberTypeEnum.CHILD || familyObj.memberType === FamilyMemberTypeEnum.GRAND_CHILD) {
        // memberFieldObject.fields.find(formField => formField.name === 'monthly_income_value').extra.visible = false
        // memberFieldObject.fields.find(formField => formField.name === 'monthly_income_dropdown_option').extra.visible = false

        memberFieldObject.fields.find(formField => formField.name === 'monthly_income_value').disabled = true;
        memberFieldObject.fields.find(formField => formField.name === 'monthly_income_value').value = 'No Income'

        memberFieldObject.fields.find(formField => formField.name === 'monthly_income_dropdown_option').disabled = true;
        memberFieldObject.fields.find(formField => formField.name === 'monthly_income_dropdown_option').value = monthlyIncomeMasterList[0]


        // Setting maximum 18 years of age in the calendar
        memberFieldObject.fields.find(formField => formField.name === 'dob').extra.maxDate = moment().subtract(18, 'years')
        memberFieldObject.fields.find(formField => formField.name === 'dob').extra.minDate = ""
    }

    // Set Default gender based on the structure defined in the family member list
    if (familyObj.gender !== undefined) {
        const genderField = memberFieldObject.fields.find(formField => formField.name === 'gender')
        const selectedGender = genderField.extra.optionList.find(
            genderOptions => genderOptions.value === familyObj.gender.defaultValue
        )
        genderField.value = selectedGender
        if (familyObj.gender.isDisable !== undefined) {
            genderField.disabled = familyObj.gender.isDisable
        }
    }

    return createNewObject(memberFieldObject)

}

const addNewMember = (memberType, formController) => {
    // member type will be FamilyMemberTypeEnum
    let familyData = familyMemberList.find((member) => member.memberType === memberType)

    let nationalMasterList = toJS(FamilyDetailsStore.nationalityList);
    let salutationMasterList = toJS(FamilyDetailsStore.salutationList);
    let lifeCoverList = toJS(FamilyDetailsStore.lifeCoverList);
    let journeyData = toJS(AuthStore.journeyData);
    let monthlyIncomeMasterList = FamilyDetailsStore.monthlyIncomelist

    let isProtectionNeedSelected = checkIsProtectionNeedSelected(journeyData.family_product_details_list)

    let familyMemberFieldObjectData = generateMemberEmptyDataStrucutre(
        familyData,
        lifeCoverList,
        nationalMasterList,
        salutationMasterList,
        monthlyIncomeMasterList,
        isProtectionNeedSelected
    )

    familyMemberFieldObjectData.name = familyMemberFieldObjectData.name + generateRandomString()

    formController.$("members").add(familyMemberFieldObjectData)

}

const selfAndSpouseGenderToggle = (formController, value, memberType, fieldName) => {

    switch (value) {
        case genderTypeEnum.MALE:
            formController.$("members").$(memberType).$(fieldName).value = genderValueEnum.FEMALE
            break;

        case genderTypeEnum.FEMALE:
            formController.$("members").$(memberType).$(fieldName).value = genderValueEnum.MALE
            break;

        default:
            break;
    }

}

const updateValue = (formController, familyMemberName, fieldName, value) => {

    let updatedValue = value

    let NameRegEx = new RegExp("^([A-Za-z'.]{1,30})$")

    if (fieldName == 'first_name' || fieldName == 'middle_name' || fieldName == 'last_name') {
        if (NameRegEx.test(updatedValue)) {
            updatedValue = updatedValue
        } else {
            updatedValue = formController.$("members").$(familyMemberName).$(fieldName).value
            if (updatedValue.length == 1) {
                updatedValue = ''
            }
        }

        if (updatedValue.length == 1) {
            updatedValue = updatedValue.toUpperCase()
        }
    }


    if (fieldName == 'gender') {

        let genderValue = updatedValue.value


        formController.$("members").$(familyMemberName).$(fieldName).value = updatedValue

        let selfGenderValue = formController.$("members").$('self').$('gender').value.value
        let spouseGenderValue = formController.$("members").$('spouse').$('gender').value.value


        if ((selfGenderValue && spouseGenderValue) && selfGenderValue === spouseGenderValue) {
            formController.$("members").$('spouse').$(fieldName).invalidate("Self And Spouse can't have same gender")
        } else {
            formController.$("members").$('spouse').$(fieldName).resetValidation()
        }

        setSalutation(genderValue, formController, familyMemberName, selfGenderValue, spouseGenderValue)

    } else {
        formController.$("members").$(familyMemberName).$(fieldName).value = updatedValue
    }

}

const setSalutation = (gender, formController, familyMemberName) => {
    let salutationList = formController.$("members").$('spouse').$('salutation').extra.optionList

    if (familyMemberName !== 'self' && familyMemberName !== 'spouse') {

        const familyMemberGender = ['child', 'adult_child', 'grand_child']

        if (gender === "Female" && familyMemberGender.includes(familyMemberName)) {
            let salutation = salutationList.find((item) => item.label === "Ms.")
            formController.$("members").$(familyMemberName).$("salutation").value = salutation
        } else {
            let salutation = salutationList.find((item) => item.label === "Mr.")
            formController.$("members").$(familyMemberName).$("salutation").value = salutation
        }
    }
}

const validationOtherFieldMembers = (formController) => {

    let ifValid = true

    const familyMemeberList = formController.$('members').get()
    const familyMemberValueList = []

    for (let key in familyMemeberList) {

        let { first_name, last_name, dob, gender } = familyMemeberList[key].value
        let valueObj = { memberType: key, first_name, last_name, dob, gender }
        familyMemberValueList.push(valueObj)
    }

    familyMemberValueList.forEach((memberObj) => {

        let { memberType, first_name, last_name, dob, gender } = memberObj

        if ((!checkIsValueEmpty(first_name) || !checkIsValueEmpty(last_name) || !checkIsValueEmpty(dob) || !checkIsValueEmpty(gender)) && (memberType != 'grand_father' || memberType != 'grand_mother')) {

            let journeyData = toJS(AuthStore.journeyData)
            let isProtectionNeedSelected = checkIsProtectionNeedSelected(journeyData.family_product_details_list)

            let fieldNameArray

            if(isProtectionNeedSelected){
                fieldNameArray = ['first_name', 'last_name', 'dob', 'gender','monthly_income_value']
            }else{
                fieldNameArray = ['first_name', 'last_name', 'dob', 'gender','monthly_income_dropdown_option']
            }

            fieldNameArray.forEach((fieldName) => {
                if (memberType != 'grand_father' && memberType != 'grand_mother') {
                    const isValueEmpty = checkIsValueEmpty(formController.$("members").$(memberType).$(fieldName).value)
                    if (isValueEmpty) {
                        formController.$("members").$(memberType).$(fieldName).invalidate("This Field is mandatory")
                        ifValid = false
                    }
                }
            })
        }
    })

    return ifValid
}

export { addFamilyMember, generateMemberEmptyDataStrucutre, addNewMember, preFillUserFields, sendDataToServer, closeModal, updateValue, selfAndSpouseGenderToggle, toggleDisableOptions, changeFieldValue, validationOtherFieldMembers }


