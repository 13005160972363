// Package Imports
import { toJS } from "mobx"

// Project Imports
import AuthStore from "modules/login/store/AuthStore"
import FamilyMemberTypeEnum from "modules/family-details-form/FamilymemberTypeEnum";

const createFieldObject = (formController, field_name, expense_type, selectedfamilymemberId,selectedExpenseList = []) => {

    expense_type.forEach((item) => {
        let expenseTypeObj = {}
        expenseTypeObj.label = item.label_name,
            expenseTypeObj.name = item.label_name.toLowerCase().replaceAll(" ", '_'),
            expenseTypeObj.value = {}
        expenseTypeObj.disabled = true
        expenseTypeObj.extra = {
            optionList: [],
            bottomSheetTitle: "Select an Option"
        }
        let optionList = []
        item.expense_range.forEach(expense_range_item => {
            optionList.push({
                id: expense_range_item.id,
                label: expense_range_item.value,
                value: expense_range_item.mid_value,
            })
        })
        expenseTypeObj.extra.optionList = optionList

        if (selectedExpenseList !== undefined) {

            let familyDetailList = toJS(AuthStore.journeyData.family_details_list)
            let selectedFamilyMemberDetail = familyDetailList.find((singleMember)=> singleMember.id === selectedfamilymemberId)
            let selectedExpenseOption 
            selectedExpenseOption = selectedExpenseList.find(expense => expense.expense_label === expenseTypeObj.label && expense.family_member_type === selectedFamilyMemberDetail.family_member_type  && expenseTypeObj.extra.optionList.some((item)=> item.id === expense.expense_range_id))
            
            if(selectedExpenseOption == undefined) {
            selectedExpenseOption = selectedExpenseList.find(expense => expense.expense_label === expenseTypeObj.label && expense.family_member_type === FamilyMemberTypeEnum.SELF  && expenseTypeObj.extra.optionList.some((item)=> item.id === expense.expense_range_id))
            }
            
            if (selectedExpenseOption !== undefined) {
                expenseTypeObj.value = {
                    id: selectedExpenseOption.expense_range_id,
                    label: selectedExpenseOption.expense_range_key,
                    value: selectedExpenseOption.value,
                    journeyExpenseId: selectedExpenseOption.id
                }
                expenseTypeObj.disabled = false
            }

        }

        formController.$(field_name).add(expenseTypeObj)
    })
}

const initialDefaults = (formController, expenseListData, journeyData, dateOfBirthFromChooseNeeds, familyMemberId, membersExpenseList) => {

    let monthlyExpense = expenseListData.find(item => item.name === 'Monthly Expense Details')

    createFieldObject(formController, 'monthly_expense', monthlyExpense.expense_label, familyMemberId, membersExpenseList)

    let selectedFamilyMember = journeyData.family_details_list.find((item)=> item.id === familyMemberId)

    console.log('selectedFamilyMemberDetail',familyMemberId);

    let selfFamilyMember = selectedFamilyMember

    if (familyMemberId !== undefined) {
        formController.$("family_member_id").value = String(familyMemberId)
    }

    if (dateOfBirthFromChooseNeeds !== undefined) {
        formController.$("date_of_birth").value = dateOfBirthFromChooseNeeds
    } else {
        formController.$("date_of_birth").value = selfFamilyMember.dob || ''
    }


}

export default initialDefaults