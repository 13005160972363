
// package imports
import ProductConstants from 'constants/ProductConstants';
import utils from './utils';

const initialDefaults = (formRef, data) => {
    let requestData = data.data.bi_request
    let planOption = Number(requestData.PlanOption)
    let ppt = requestData.PR_PPT
    let incomeStartYear = requestData.IncomeStartYear
    let pt = requestData.PR_PT
    let lifeCoverContinuationBenefit = requestData.LifeCoverContinuationBenefit
    let inputMode = requestData.INPUT_MODE
    let accrualOfSurvivalBenefits = requestData.AccrualOfSurvivalBenefits
    let personalisedOption = requestData.PersonalisedOption
    let withdrawal = requestData.PWInterval
    let withdrawalStartYear = requestData.PWStartYear
    let withdrawalEndYear = requestData.PWEndYear
    let ageBAsedWithdrawal = requestData.PWAge
    let startAge = requestData.PWStartAge;
    let endAge = requestData.PWEndAge;
    let liAge = Number(requestData.LI_ENTRY_AGE)

    // !Plan Option
    switch (planOption) {
        case 1:
            formRef.$('plan-option').set('value', { label: 'Flexi-Income Option', id: 1 })
            break;
        case 2:
            formRef.$('plan-option').set('value', { label: 'Flexi-Income PRO Option', id: 2 },)
            break;
        case 3:
            formRef.$('plan-option').set('value', { label: 'Large Sum Option', id: 3 })
            break;
        default:
            break;
    }

    // !PPT
    let pptList = utils.pptListBasedOnPlanOption(planOption)
    formRef.$('premium-term').set('extra', { ...formRef.$('premium-term').extra, optionList: pptList })
    formRef.$('premium-term').set('value', { label: ppt, id: ppt })

    // !Income Start Year
    let incomeStartYearList = utils.incomeStartYearOptionList(planOption, Number(ppt))
    formRef.$('income-start-year').set('extra', { ...formRef.$('income-start-year').extra, optionList: incomeStartYearList })

    formRef.$('income-start-year').set('extra', { ...formRef.$('income-start-year').extra, hide: planOption === 3 })

    switch (Number(incomeStartYear)) {
        case 4:
            formRef.$('income-start-year').set('value', { label: '2', id: 4 })
            break;
        case 5:
            formRef.$('income-start-year').set('value', { label: '5', id: 5 })
            break;
        case 6:
            formRef.$('income-start-year').set('value', { label: '9', id: 6 })
            break;
        case 7:
            formRef.$('income-start-year').set('value', { label: '11', id: 7 })
            break;
        case 8:
            formRef.$('income-start-year').set('value', { label: '13', id: 8 })
            break;
        case 9:
            formRef.$('income-start-year').set('value', { label: '16', id: 9 })
            break;
        default:
            break;
    }

    // !PT
    let ptList = utils.policyTermList(planOption, Number(ppt), liAge)
    formRef.$('policy-term').set('extra', { ...formRef.$('policy-term').extra, optionList: ptList })
    if (pt === '100-@LI_ENTRY_AGE') {
        formRef.$('policy-term').set('value', { label: 100 - liAge, id: '100-@LI_ENTRY_AGE' })
    } else {
        formRef.$('policy-term').set('value', { label: pt, id: pt })
    }

    // !Life Cover Continuation Benefit
    let ptValue = pt === '100-@LI_ENTRY_AGE' ? 100 - liAge : Number(pt)
    let isLifeCoverContinuationBenefitDisabled = utils.checkLifeCoverDisabled(Number(ppt), ptValue, liAge)
    switch (Number(lifeCoverContinuationBenefit)) {
        case 11:
            formRef.$('life-cover').set('value', { label: 'Yes', id: "11" })
            break;
        case 10:
            formRef.$('life-cover').set('value', { label: 'No', id: "10" },)
            break;
        default:
            break;
    }

    formRef.$('life-cover').set('disabled', isLifeCoverContinuationBenefitDisabled)

    // !Payment frequency
    switch (Number(inputMode)) {
        case 1:
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case 2:
            formRef.$('payment-frequency').set('value', {
                id: '2',
                label: 'Semi-Annual'
            })
            break;
        case 3:
            formRef.$('payment-frequency').set('value', {
                id: '3',
                label: 'Quarterly'
            })
            break;
        case 4:
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }

    // !Accrual Of Survival Benefits
    switch (Number(accrualOfSurvivalBenefits)) {
        case 12:
            formRef.$('accrual-of-survival-benefit').set('value', { label: 'No', id: "12" })
            formRef.$('personalize-plan').set('extra', { ...formRef.$('personalize-plan').extra, hide: true })
            break;
        case 13:
            formRef.$('accrual-of-survival-benefit').set('value', { label: 'Yes', id: "13" })
            formRef.$('personalize-plan').set('extra', { ...formRef.$('personalize-plan').extra, hide: false })
            break;
        default:
            break;
    }

    switch (data.productName) {
        case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
        case ProductConstants.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
        case ProductConstants.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
            formRef.$('accrual-of-survival-benefit').set('disabled', true)
            break;

        case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
            formRef.$('accrual-of-survival-benefit').set('disabled', true)
            break;

        default:
            break;
    }

    // !Please personalize your plan
    switch (Number(personalisedOption)) {
        case 1:
            formRef.$('personalize-plan').set('value', { label: 'Systematic Withdrawal', id: "1" })
            break;
        case 2:
            formRef.$('personalize-plan').set('value', { label: 'Age-Based Withdrawal', id: "2" })
            break;
        case 3:
            formRef.$('personalize-plan').set('value', { label: 'Income-Age Based Withdrawal', id: "3" })
            break;
        default:
            break;
    }

    // !Systematic Withdrawal
    switch (Number(withdrawal)) {
        case 3:
            formRef.$('withdrawal').set('value', { label: 'Every 3 Years', id: '3' })
            break;
        case 5:
            formRef.$('withdrawal').set('value', { label: 'Every 5 Years', id: '5' })
            break;
        case 10:
            formRef.$('withdrawal').set('value', { label: 'Every 10 Years', id: '10' })
            break;
        default:
            break;
    }

    // !Systematic Withdrawal Start Year
    formRef.$('withdrawal-start-year').set('value', withdrawalStartYear)

    // !Systematic Withdrawal End Year
    formRef.$('withdrawal-end-year').set('value', withdrawalEndYear)

    // !Age Based withdrawal
    let ageBasedWithdrawalList = ageBAsedWithdrawal === "" ? [""] : ageBAsedWithdrawal.split(",")
    formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, optionList: ageBasedWithdrawalList })

    // !Age plus income Based withdrawal
    let startAgeList = startAge === "" ? [""] : startAge.split(",")
    formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, optionList: startAgeList })

    let endAgeList = endAge === "" ? [""] : endAge.split(",")
    formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, optionList: endAgeList })

    // !Show hide handling on the basis of personalized plan
    switch (data.productName) {
        case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
        case ProductConstants.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
            formRef.$('withdrawal').set('extra', { ...formRef.$('withdrawal').extra, hide: true })
            formRef.$('withdrawal-start-year').set('extra', { ...formRef.$('withdrawal-start-year').extra, hide: true })
            formRef.$('withdrawal-end-year').set('extra', { ...formRef.$('withdrawal-end-year').extra, hide: true })

            formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: true })

            formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, hide: true })
            formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, hide: true })

            formRef.$('personalize-plan').set('disabled', true)
            break

        case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
            formRef.$('withdrawal').set('extra', { ...formRef.$('withdrawal').extra, hide: true })
            formRef.$('withdrawal-start-year').set('extra', { ...formRef.$('withdrawal-start-year').extra, hide: true })
            formRef.$('withdrawal-end-year').set('extra', { ...formRef.$('withdrawal-end-year').extra, hide: true })

            formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: true })

            formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, hide: false })
            formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, hide: false })

            formRef.$('personalize-plan').set('disabled', true)
            break;

        case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
            formRef.$('withdrawal').set('extra', { ...formRef.$('withdrawal').extra, hide: true })
            formRef.$('withdrawal-start-year').set('extra', { ...formRef.$('withdrawal-start-year').extra, hide: true })
            formRef.$('withdrawal-end-year').set('extra', { ...formRef.$('withdrawal-end-year').extra, hide: true })

            formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: true })

            formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, hide: true })
            formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, hide: true })

            formRef.$('personalize-plan').set('disabled', false)
            break;

        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
            formRef.$('withdrawal').set('extra', { ...formRef.$('withdrawal').extra, hide: true })
            formRef.$('withdrawal-start-year').set('extra', { ...formRef.$('withdrawal-start-year').extra, hide: true })
            formRef.$('withdrawal-end-year').set('extra', { ...formRef.$('withdrawal-end-year').extra, hide: true })

            formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: false })

            formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, hide: true })
            formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, hide: true })

            formRef.$('personalize-plan').set('disabled', true)
            break;

        case ProductConstants.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
        case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
            formRef.$('withdrawal').set('extra', { ...formRef.$('withdrawal').extra, hide: false })
            formRef.$('withdrawal-start-year').set('extra', { ...formRef.$('withdrawal-start-year').extra, hide: false })
            formRef.$('withdrawal-end-year').set('extra', { ...formRef.$('withdrawal-end-year').extra, hide: false })

            formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: true })

            formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, hide: true })
            formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, hide: true })

            formRef.$('personalize-plan').set('disabled', true)
            break;

        default:
            break;
    }

    // !Riders
    if (requestData.ADBRider) {
        formRef.$('ADB').set('value', requestData.ADBRider !== '')
        if (requestData.ADBRider) {
            formRef.$('ADB-dropdown').set('value', requestData.ADBRider)
        }
    }
    if (requestData.ATPDRider) {
        formRef.$('ATPDRider').set('value', requestData.ATPDRider !== '')
        if (requestData.ATPDRider) {
            formRef.$('ATPDRider-dropdown').set('value', requestData.ATPDRider)
        }
    }
    if (requestData.WaiverOfPremiumRider) {
        formRef.$('WaiverOfPremiumRider').set('value', requestData.WaiverOfPremiumRider !== '')
    }

    if (requestData.IncomeBenefitRider) {
        formRef.$('IncomeBenefitRider').set('value', requestData.IncomeBenefitRider !== '')
        if (requestData.IncomeBenefitRider) {
            formRef.$('IncomeBenefitRider-dropdown').set('value', requestData.IncomeBenefitRider)
        }
    }
    if (requestData.CriticalIllnessRider) {
        formRef.$('CriticalIllnessRider').set('value', requestData.CriticalIllnessRider !== '')
        if (requestData.CriticalIllnessRider) {
            formRef.$('CriticalIllnessRider-dropdown').set('value', requestData.CriticalIllnessRider)
        }
    }
    if (requestData.PayorWaiverBenefitRider) {
        formRef.$('PayorWaiverBenefitRider').set('value', requestData.PayorWaiverBenefitRider !== '')
        if (formRef.$('PayorWaiverBenefitRider').value) {
            switch (requestData.PWB) {
                case '1':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '1',
                        label: 'Option 1 (On Death)'
                    })
                    break;
                case '2':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '2',
                        label: 'Option 2 (CI or ATPD)'
                    })
                    break;
                case '3':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '3',
                        label: 'Option 3 (CI or ATPD or Death)'
                    })
                    break;
                default:
                    break;
            }
        }

        if (data.productName === ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL
            || data.productName === ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL
            || data.productName === ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL
            || data.productName === ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME
            || data.productName === ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO
            || data.productName === ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION
        ) {
            formRef.$('PayorWaiverBenefitRider').set('extra', { ...formRef.$('PayorWaiverBenefitRider').extra, disabled: true })
            formRef.$('ADB').set('extra', { ...formRef.$('ADB').extra, hide: true })
            formRef.$('ATPDRider').set('extra', { ...formRef.$('ATPDRider').extra, hide: true })
            formRef.$('CriticalIllnessRider').set('extra', { ...formRef.$('CriticalIllnessRider').extra, hide: true })
            formRef.$('IncomeBenefitRider').set('extra', { ...formRef.$('IncomeBenefitRider').extra, hide: true })
            formRef.$('WaiverOfPremiumRider').set('extra', { ...formRef.$('WaiverOfPremiumRider').extra, hide: true })
        } else {
            formRef.$('PayorWaiverBenefitRider').set('extra', { ...formRef.$('PayorWaiverBenefitRider').extra, disabled: false })
        }
    }

}

export default initialDefaults