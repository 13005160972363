import calculateRiderPremium from 'utils/calculateRiderPremium';
import OptimizationStore from '../../../store/OptimizationStore';
import * as keys from './keys';
import Validator from "./validator";
import * as Constants from "constants/Constants"

class Utils {
  getPolicyTerm = () => {
    let pt = [];
    for (let i = 10; i <= 20; i++) {
      pt.push({ id: i, label: "" + i });
    }
    return pt;
  };

  getPremiumTermList = (pt) => {
    let pptlist = [5, 7, 10, 12]
    let ppt = []

    pptlist.map((item) => {
      ppt.push({ id: item, label: '' + item })
    })

    return ppt;

  }

  updateBi(key, value, productId, formRef, productData) {

    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request
    switch (key) {
      case keys.PR_PT:
        biRequest[keys.PR_PT] = value;
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.FREQUENCY:
        let premiumAmount = 0;
        let amount = value.premium;
        switch (Number(value.frequency)) {
          case 1:
            premiumAmount = '' + amount * 1
            break;
          case 4:
            premiumAmount = '' + amount * 12
            break;
        }

        biRequest[keys.PREMIUM] = premiumAmount
        biRequest[keys.FREQUENCY] = value.frequency
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value
        break;

      default:
        return
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return
    // }


  }

  getTotalPrice = (data) => {
    try {
      return Number(data.MODAL_PREM_TAX);
    } catch (e) {
      return 0
    }
  }

  // fetchPolicyTimeline(element, frequency) {
  //   let amount = Math.round(element.MODAL_PREM);
  //   let premiumAmount = 0
  //   switch (frequency.Value) {
  //     case '1':
  //       premiumAmount = amount * Constants.ANNUAL_MODAL_FACTOR
  //       break
  //     case '4':
  //       premiumAmount = amount * Constants.MONTHLY_MODAL_FACTOR
  //       break;
  //   }
  //   let data = {
  //     youPay: Math.round(premiumAmount),
  //     youGet: Math.round(element.SB_G),
  //     lifeCover: Math.round(element.DB_G)
  //   }
  //   return data
  // }

  newPolicyTimeLineData(element, inputString, index, finalYearIndex){
    const {ANN_PREM_YEARLY, SB_G, DB_G} = element;

    let data = {
      youPay: Math.round(ANN_PREM_YEARLY),
      youGet: index == finalYearIndex ?  Math.round(SB_G): 0,
      lifeCover: Math.round(DB_G)
    }
    return data
  }


}

export default new Utils();
