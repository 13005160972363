import Utils from './utils';
import * as keys from './keys';
import ProductConstants from 'constants/ProductConstants';



const initialDefaults = (formRef, data) => {
	let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
	let biResponse = productData.parsedResponse;
	let biRequest = productData.bi_request;
	let firstYearResponse = productData.firstYearResponse;
	let LumpsumBenefit = biRequest.LumpsumBenefit.toString()
	let frequency = biRequest.INPUT_MODE.toString()
	let pt = biRequest.PR_PT
	let ppt = biRequest.PR_PPT
	let familyIncomeBenefitOption = biRequest.FamilyIncomeBenefitOption
	let incomeBenefitPayOutFrequency = biRequest.IncomeBenefitPayoutFrequency
	let incomeBenefitPayOutType = biRequest.IncomeBenefitPayoutType
	let incomeBenefitPayoutDuration = biRequest.IncomeBenefitPayoutDuration
	let annualisedPremium = biRequest.PR_ANNPREM == 0 ? firstYearResponse?.MODAL_PREM: biRequest.PR_ANNPREM;


	let wopRider = String(biRequest.WaiverOfPremiumRider)
	let adbRider = String(biRequest[keys.ADB_RIDER])
	let pptList = Utils.getPremiumTermList(biRequest.PR_PT, biRequest.LI_ENTRY_AGE)



	formRef.$('PT').value = { id: pt, label: pt };

	formRef.$('PPT').value = { id: ppt, label: ppt };

	switch (LumpsumBenefit) {

		case '7':
			formRef.$('lump-sum-benefit').set('value', {label: 'No', id: '7'})
			break;
		case '8':
			formRef.$('lump-sum-benefit').set('value', {label: 'Yes', id: '8'})
			break;

		default:
			break;

	}

	switch (frequency) {
		case '1':
			formRef.$('payment-frequency').set('value', {
				id: '1',
				label: 'Annual'
			})
			break;
		case '2':
			formRef.$('payment-frequency').set('value', {
				id: '2',
				label: 'Semi-Annual'
			})
			break;
		case '3':
			formRef.$('payment-frequency').set('value', {
				id: '3',
				label: 'Quarterly'
			})
			break;
		case '4':
			formRef.$('payment-frequency').set('value', {
				id: '4',
				label: 'Monthly'
			})
			break;

		default:
			break;
	}

	switch (Number(familyIncomeBenefitOption)) {

		case 3:
			formRef.$('family-income-benefit-option').set('value',false)
			break;
		case 4:
			formRef.$('family-income-benefit-option').set('value',true)

			formRef.$('family-income-benefit-option-dropdown').set('value', { label: 'Family Income Benefit on Critical Illness (CI) and Death', id: 4 })
			break;
		case 5:
			formRef.$('family-income-benefit-option').set('value',true)

			formRef.$('family-income-benefit-option-dropdown').set('value', { label: 'Family Income Benefit on Critical Illness (CI)', id: 5 })
			break;

		default:
			break;

	}

	switch (data.productName) {
		case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
		case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
		case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
			formRef.$('family-income-benefit-option').set('disabled', true)
			formRef.$("family-income-benefit-option").set("extra", {
				...formRef.$("family-income-benefit-option").extra,
				hide : false
			})
			break;

		default:
			formRef.$('family-income-benefit-option').set('disabled', false)
			formRef.$("family-income-benefit-option").set("extra", {
				...formRef.$("family-income-benefit-option").extra,
				hide : true
			})
			break
	}

	switch (incomeBenefitPayOutFrequency) {

		case '1':
			formRef.$('income-benefit-pay-out-frequency').set('value', { label: 'Annual', id: '1' })
			break;
		case '2':
			formRef.$('income-benefit-pay-out-frequency').set('value', { label: 'Semi-Annual', id: '2' })
			break;
		case '3':
			formRef.$('income-benefit-pay-out-frequency').set('value', { label: 'Quarterly', id: '3' })
			break;
		case '4':
			formRef.$('income-benefit-pay-out-frequency').set('value', { label: 'Monthly', id: '4' })
			break;

		default:
			break;

	}

	switch (incomeBenefitPayOutType) {

		case '5':
		case 5:
			formRef.$('income-benefit-pay-out-type').set('value', { label: 'Level', id: '5' })
			break;
		case '6':
		case 6:
			formRef.$('income-benefit-pay-out-type').set('value', { label: 'Increasing', id: '6' })
			break;


		default:
			break;

	}


	// let premiumAmount = ''
	// switch (frequency) {
	// 	case '1':
	// 		premiumAmount = annualisedPremium;
	// 		break;
	// 	case '2':
	// 		premiumAmount = Number(annualisedPremium) / 2;
	// 		break;
	// 	case '3':
	// 		premiumAmount = Number(annualisedPremium) / 4;
	// 		break;
	// 	case '4':
	// 		premiumAmount = Number(annualisedPremium) / 12;
	// 		break;

	// 	default:
	// 		break;
	// }

	// formRef.$('annualised-premium').set('value', premiumAmount)
	formRef.$('annualised-premium').set('value', annualisedPremium)



	switch (data.productName) {

		case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
		case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
		case ProductConstants.PREMIER_GUARANTEED_RETIREMENT_INCOME:
			formRef.$("lump-sum-benefit").set("extra", {
				...formRef.$("lump-sum-benefit").extra,
				hide :true
			})

		break;
			
		default:
			break;
		}





	switch (data.productName) {

		case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
		case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
			formRef.$('income-benefit-payout-duration').set('disabled', true)
			let durationList = Utils.getIncomeBenefitOptions(data.productName, ppt)
			formRef.$('income-benefit-pay-out-type').set('disabled', true)
			formRef.$("PPT").set("extra", {
				...formRef.$("PPT").extra,
				optionList: [
					{
						id: "8",
						label: "8",
					},
					{
						id: "10",
						label: "10",
					},
					{
						id: "12",
						label: "12",
					},
				],
			})
			
			formRef.$('PT').set('disabled', true)
			Utils.ptLogicBasedOnPpt(ppt, formRef, data.productName)
			formRef.$('income-benefit-payout-duration').set('value', durationList.find((item)=> item.id === incomeBenefitPayoutDuration.toString()))
			break;

		case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME:
		case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
			formRef.$('income-benefit-payout-duration').set('disabled', false)
			formRef.$('income-benefit-payout-duration').set('value', Utils.getIncomeBenefitOptions(data.productName, ppt)[0])
			formRef.$('income-benefit-pay-out-type').set('disabled', true)
			formRef.$('PT').set('disabled', true)
			Utils.incomeBenefitDurationLogicBasedOnPpt(ppt, formRef, data.productName)

			switch(Number(incomeBenefitPayoutDuration)) {
				case 13:
					formRef.$('income-benefit-payout-duration').set('value', {id: "13",label: "15"})
				break;

				case 14:
					formRef.$('income-benefit-payout-duration').set('value', {id: "14",label: "20"})
				break;
				
				case 15:
					formRef.$('income-benefit-payout-duration').set('value', {id: "15",label: "25"})
				break;
				
				case 16:
					formRef.$('income-benefit-payout-duration').set('value', {id: "16",label: "30"})
				break;
			}
			break;

		case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM:
		case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
			formRef.$('income-benefit-pay-out-type').set('disabled', true)
			Utils.ptLogicBasedOnPpt(ppt, formRef, data.productName)
			Utils.prefillPtBasedOnpptAndIncomeBenefitPayoutDuration(formRef, data.productName)
			
			formRef.$('income-benefit-payout-duration').set('disabled', true)
			formRef.$('income-benefit-payout-duration').set('value', '')

			formRef.$('lump-sum-benefit').set('disabled', true)
			formRef.$('lump-sum-benefit').set('value', '')

			formRef.$('income-benefit-payout-duration').set("extra", {
				...formRef.$("income-benefit-payout-duration").extra,
				hide: true})

			formRef.$('income-benefit-pay-out-frequency').set("extra", {
				...formRef.$("income-benefit-pay-out-frequency").extra,
				hide: true})

			formRef.$('income-benefit-pay-out-type').set("extra", {
				...formRef.$("income-benefit-pay-out-type").extra,
				hide: true})

			formRef.$('lump-sum-benefit').set("extra", {
				...formRef.$("lump-sum-benefit").extra,
				hide: true})
			break;

		case ProductConstants.PREMIER_GUARANTEED_RETIREMENT_INCOME:
			formRef.$('income-benefit-payout-duration').set('disabled', true)
			formRef.$('income-benefit-payout-duration').set('value', Utils.getIncomeBenefitOptions(data.productName, ppt)[0])

			formRef.$('PT').set('disabled', true)
			formRef.$('PT').set('value', {
				id: biRequest.PR_PT,
				label: "upto age 80"
			})
			break;

		default:
			break;

	}
	
	if (biRequest.ADBRider) {
		formRef.$('ADB').set('value', biRequest.ADBRider !== '')
		if (biRequest.ADBRider) {
			formRef.$('ADB-dropdown').set('value', biRequest.ADBRider)
		}
	}
	if (biRequest.ATPDRider) {
		formRef.$('ATPDRider').set('value', biRequest.ATPDRider !== '')
		if (biRequest.ATPDRider) {
			formRef.$('ATPDRider-dropdown').set('value', biRequest.ATPDRider)
		}
	}

	if (biRequest.WaiverOfPremiumRider) {
		formRef.$('WaiverOfPremiumRider').set('value', biRequest.WaiverOfPremiumRider !== '')
	}

	if (biRequest.HospitalityCashBenefitRider) {
		formRef.$('HospitalityCashBenefitRider').set('value', biRequest.HospitalityCashBenefitRider !== '')
		if (biRequest.HospitalityCashBenefitRider) {
			formRef.$('HospitalityCashBenefitRider-dropdown').set('value', biRequest.HospitalityCashBenefitRider)
		}
	}

	if (biRequest.CriticalIllnessRider) {
		formRef.$('CriticalIllnessRider').set('value', biRequest.CriticalIllnessRider !== '')
		if (formRef.$('CriticalIllnessRider').value) {
			formRef.$('CriticalIllnessRider-dropdown').set('value', biRequest.CriticalIllnessRider)
		}
	}

	if (biRequest.PayorWaiverBenefitRider) {
		formRef.$('PayorWaiverBenefitRider').set('value', biRequest.PayorWaiverBenefitRider !== '')
		if (formRef.$('PayorWaiverBenefitRider').value) {
			switch (biRequest.PWB) {
				case '1':
					formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
						id: '1',
						label: 'Option 1 (On Death)'
					})
					break;
				case '2':
					formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
						id: '2',
						label: 'Option 2 (CI or ATPD)'
					})
					break;
				case '3':
					formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
						id: '3',
						label: 'Option 3 (CI or ATPD or Death)'
					})
					break;
				default:
					break;
			}
		}
	}

}

export default initialDefaults