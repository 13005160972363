import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";

import { observer } from "mobx-react";

import Utils from "./utils";

import {
  CustomCheckbox,
  BottomsheetDropdown,
  DeathCoverPriceComponent,
  ChangeProductSection
} from "../../../../../components";
import AmountCalculation from "../../optimization-card/amount-calculation/AmountCalculation";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";

const SimplyProtect = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false)

  let productData = data.data;
  let biResponse = productData.parsedResponse;
  let firstYearResponse = productData.firstYearResponse;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])

  const onHideOverlay = () => {
    setShowOverlay(false)
  }

  return (
    <form>
      <div className="family-collapase-view">
        <Row className="mt-4">
          <Col xs={12} md={5}>
            <div className="">
              <div className="d-flex align-items-center">
                <div className=" p-md-3 d-flex flex-md-column align-items-center align-items-md-start w-100">
                  <span className="mr-3 font-size-12 font-size-md-14 font-size-lg-16 text-description-text-color">
                    Tobbacco user?
                  </span>
                  <div className="">
                    {form.$("tobaco-user").options.map((item, index) => {
                      return (
                        <CustomCheckbox
                          key={index}
                          className="mr-2"
                          isChecked={form.$("tobaco-user").value.id === item.id}
                          onChange={() => form.$("tobaco-user").onChange(item)}
                          titleStyle="font-size-12 font-size-md-14 font-size-lg-16"
                          shape="rounded"
                          title={item.label}
                          mark="checkmark"
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="d-md-block d-none d-md-block d-none">
                <div className="p-3 bg-white rounded">
                  <div className="mb-2 text-description-text-color font-size-12 font-weight-light font-size-md-14">
                    Death cover payout mode options
                  </div>
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey="label"
                    bottomsheetTitle="Select Plans"
                    menuList={form.$("payout-mode").extra.optionList}
                    value={form.$("payout-mode").value}
                    onSelect={form.$("payout-mode").onChange}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col className="col-12">
            <DeathCoverPriceComponent
              firstAmount={productData.bi_request.investment_target}
              secondAmount={data.data.isValidationExist ? 0 : data.data.parsedResponse.MODAL_PREM_TAX}
              thirdAmount="980"
              showAdditionalText={false}
              showSecondColAmount={false}
              secondColBottomText={form.$("payment-frequency").value.label}
              firstColTitleText={coverLabelBaseOnNeed}
              secondColTitleText="Price"
              cardKey={data.cardKey}
            />
          </Col>
        </Row>

        <div className="text-primary-title-label-color font-weight-bold font-size-16 mb-3 mt-4 d-md-none">
          Maturity Benefits
        </div>
        <Row className="mt-md-5">
          <Col xs={12} md={12} className="col-12 mt-4 mt-md-0">
            <ChangeProductSection
              productName="Simply Protect"
              cardName={coverLabelBaseOnNeed}
              changeProductList={productData.changeProductList}
              productData={data}
            />
          </Col>
          <Col xs={12} md={5}>
            <div className="shadow p-3 bg-white rounded d-md-none d-block">
              <div className="mb-2 text-description-text-color font-size-14 font-size-12 font-weight-light">
                Death cover payout mode options
              </div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey="label"
                bottomsheetTitle="Select Death Cover Payout Mode"
                menuList={form.$("payout-mode").extra.optionList}
                value={form.$("payout-mode").value}
                onSelect={form.$("payout-mode").onChange}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12} className="col-12 mt-4 mt-md-0">
            <DeathCoverGraphSlider
              saLabel="Income"
              productTitle=""
              income_value={data.data.parsedResponse.SA_D}
              onIncomeChange={() => { }}
              openChangeReasonModal={() => { }}
              onPremiumChange={form.$("premium").onChange}
              dataChange={() => { }}
              hideOverLay={onHideOverlay}
              productID={productData.product_id}
              productData={productData}
              showOverlay={showOverlay}
              data={""}
              cardKey={data.cardKey}
              isIncomeFieldDisable={true}
              isPremiumDisable={form.$("premium").disabled}
              premiumFreq={form.$("payment-frequency").value.label}
              premium_value={form.$("premium").value}
              onClick={() =>
                form.$("premium").extra.calculatePremium(form, data)
              }
              formRef={form}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6} className="col-12">
            <div className="shadow p-3 bg-white rounded my-3">
              <div className="mb-2 text-description-text-color font-size-12 font-weight-light font-size-md-14">
                {form.$("policy-term").label}
              </div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey="label"
                bottomsheetTitle={form.$("policy-term").label}
                menuList={form.$("policy-term").extra.optionList}
                value={form.$("policy-term").value}
                onSelect={form.$("policy-term").onChange}
              />
            </div>
          </Col>
          <Col md={6} className="col-12">
            <div className="shadow p-3 bg-white rounded my-3">
              <div className="mb-2 text-description-text-color font-size-12 font-weight-light font-size-md-14">
                {form.$("premium-paying-term").label}
              </div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey="label"
                bottomsheetTitle={form.$("premium-paying-term").label}
                menuList={form.$("premium-paying-term").extra.optionList}
                value={form.$("premium-paying-term").value}
                onSelect={form.$("premium-paying-term").onChange}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={4} className="col-12">
            <div className="shadow p-3 bg-white rounded my-3">
              <div className="mb-2 text-description-text-color font-size-12 font-weight-light font-size-md-14">
                {form.$("plan-option").label}
              </div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey="label"
                bottomsheetTitle={form.$("plan-option").label}
                menuList={form.$("plan-option").extra.optionList}
                value={form.$("plan-option").value}
                onSelect={form.$("plan-option").onChange}
              />
            </div>
          </Col>
          <Col md={4} className="col-12">
            <div className="shadow p-3 bg-white rounded my-3">
              <div className="mb-2 text-description-text-color font-size-12 font-weight-light font-size-md-14">
                {form.$("payment-frequency").label}
              </div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey="label"
                bottomsheetTitle={form.$("payment-frequency").label}
                menuList={form.$("payment-frequency").extra.optionList}
                value={form.$("payment-frequency").value}
                onSelect={form.$("payment-frequency").onChange}
              />
            </div>
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default observer(SimplyProtect);
