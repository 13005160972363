// react imports
import React, { Component } from "react"

// package imports
import { Container } from "react-bootstrap"
import { observer } from "mobx-react"
import moment from "moment"
import { toJS } from "mobx"

// project imports
import ProposerDetailsCard from "../components/proposer-details-card.js/ProposerDetailsCard"
// import qmsDashboardData from "./qms-dashboard-data"
import QmsStorageKeys from "constants/QmsStorageKeys"
import QmsDashboardPageStore from "./qms-dashboard-page-store"
import SPRM_ENUM from "constants/SPRM_constants";
import isObjectEmpty from "utils/isObjectEmpty";
import StorageKeys from "constants/StorageKeys"

import "./qms-dashboard-page.css"
import QmsSolutionStatus from "constants/QmsSolutionStatus"

const QmsDashboardPage = observer(
    class QmsDashboardPage extends Component {
        constructor(props) {
            super(props)
            this.state = {
                list: [1, 2]
            }
        }

        async componentDidMount() {
            let agent_id;
            const urlParams = new URLSearchParams(location.search);
            agent_id = urlParams.get(SPRM_ENUM.AGENT_ID)
            if (agent_id === undefined || agent_id === null)
                agent_id = localStorage.getItem(QmsStorageKeys.AGENT_ID, agent_id)
            await QmsDashboardPageStore.savePragatiInBoundData(agent_id)
        }

        showSingleCard(proposerList) {
            return proposerList.some((item)=> item.solution_list[0].solution_status_id !== QmsSolutionStatus.ANALYSIS_IN_PROGRESS ) 
        }

        render() {
            let qmsDashboardData = toJS(QmsDashboardPageStore.qmsListData)
            return (
                !isObjectEmpty(qmsDashboardData) ?
                    <Container className="qms-dashboard-page mt-3">
                        {qmsDashboardData?.all_journey_lists?.map((journeyListData, journeyListIndex) =>
                            <React.Fragment key={`all-journey-list-` + journeyListIndex }>
                                <p className="font-size-10 font-weight-medium date-filter">{journeyListData?.date ? moment(journeyListData?.date).format("DD-MM-YYYY") : "DD-MM-YYYY"}</p>
                                {journeyListData?.journey_list?.map((journeyData, journeyIndex) =>
                                    <div key={"journey-key-" + journeyData.journey_id + journeyIndex} className={`${journeyData.proposer_list.length > 1 && "parent pb-2"}`}>

                                        {!this.showSingleCard(journeyData?.proposer_list) ?
                                            <ProposerDetailsCard
                                                proposerDataList={journeyData?.proposer_list[0].product_details}
                                                proposerData={journeyData?.proposer_list[0]}
                                                proposerList={journeyData.proposer_list}
                                                proposerIndex={0}
                                                journeyData={journeyData}
                                                {...this.props}
                                            />
                                        : journeyData?.proposer_list?.map((proposerData, proposerIndex) =>
                                            <React.Fragment key={"proposer-key-" + proposerData.proposer_id + proposerIndex}>
                                                {journeyData.proposer_list.length > 1 && proposerIndex === 0 &&
                                                    <p className={`font-size-14 font-weight-medium client-name mb-4`}>{QmsDashboardPageStore.getFamilyMemberName(journeyData.journey_self_data, true, journeyData, false)} & Family - Journey ID:{journeyData.journey_id}</p>
                                                }

                                                <div className={`${journeyData.proposer_list.length > 1 && "child ml-5"}`}>
                                                    <ProposerDetailsCard
                                                        proposerDataList={proposerData.product_details}
                                                        proposerData={proposerData}
                                                        proposerList={journeyData.proposer_list}
                                                        proposerIndex={proposerIndex}
                                                        journeyData={journeyData}
                                                        {...this.props}
                                                    />

                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                )}
                            </React.Fragment>
                        )}

                    </Container>
                    : <div></div>
            )
        }
    }
)

export default QmsDashboardPage
