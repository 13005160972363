import React, { Component } from "react"
import { Card, Row, Col } from "react-bootstrap"

import "./current-coverage-card.css"

class CurrentCoverageCard extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  proposerName = (selectedTabData) => {

    let {proposer_salutation,tabTitle,family_member_type} = selectedTabData

    if(proposer_salutation.length === 0 && tabTitle.length === 0){
      return family_member_type
    }

    return `${proposer_salutation} ${tabTitle}`

  }

  render() {
    let { selectedTabData,totalPayableAmount } = this.props
    return (
      <Card className="congrats-card">
        <Card.Body>
          <Row>
            <Col
              xs="12"
              lg="7"
              className="d-md-flex flex-md-row flex-xs-column"
            >
              <p className="mb-0 font-weight-300 congrats-msg">
                Congratulations {this.proposerName(selectedTabData)} on your first step
                towards protecting your dreams & aspirations with Edelweiss
                Tokio Life Insurance.
              </p>
            </Col>
            <Col xs="12" lg="5" className="d-md-flex justify-content-end">
              <Row>
                <Col xs={12} className="value-container text-left">
                  <div className="mb-0 font-size-14 font-weight-300 first-year-premium ml-4">
                    Total Amount Payable
                  </div>
                  <div className="mb-0 font-size-14 font-weight-300">
                    <p className="font-size-28 font-weight-medium text-left text-secondary m-0 ml-4">
                      {global.toINR(totalPayableAmount)}
                    </p>
                  </div>
                  <span className="font-size-12 first-year-premium font-weight-300 ml-4">
                      {" "}
                      Incl. of taxes
                    </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}

export default CurrentCoverageCard
