// Package Imports
import moment from "moment"
import { toJS } from "mobx"

// Project Imports
import AddInsuredFamilyMemberStructure from "./AddInsuredFamilyMemberStructure"
import generateRandomString from "utils/generateRandomString"
import FamilyMemberTypeEnum from "modules/family-details-form/FamilymemberTypeEnum"
import FamilyDetailsStore from "modules/family-details-form/store/FamilyDetailsStore"
import createNewObject from "utils/createNewObject"
import FamilyMemberAttributesEnum from "constants/familyMemberAttributesEnum"
import fetchSalutationMaster from "utils/fetchSalutationMaster"
import { genderFunction } from "./utils"
import OptimizationStore from "modules/optimization/store/OptimizationStore";

const initialDefaults = async (formController, journeyId, familyMemberData) => {

  const familyMemberList = familyMemberData

  let salutionData
  if (FamilyDetailsStore.salutationList.length === 0) {
    salutionData = await fetchSalutationMaster()
  } else {
    salutionData = FamilyDetailsStore.salutationList
  }

  familyMemberList.forEach((item) => {
    const newFamilyMemberObject = createNewObject(AddInsuredFamilyMemberStructure)
    newFamilyMemberObject.name = item.family_member_type
    newFamilyMemberObject.label = item.family_member_type

    if (
      item.family_member_type === FamilyMemberTypeEnum.CHILD ||
      item.family_member_type === FamilyMemberTypeEnum.ADULT_CHILD ||
      item.family_member_type === FamilyMemberTypeEnum.GRAND_CHILD
    ) {
      newFamilyMemberObject.name =
        item.family_member_type + generateRandomString().replaceAll(" ", "_")
      newFamilyMemberObject.label =
        item.family_member_type + +generateRandomString().replaceAll(" ", "_")
    }

    newFamilyMemberObject.fields.forEach((member) => {
      switch (member.name) {
        case FamilyMemberAttributesEnum.SALUTATION:
          member.value = toJS(
            salutionData.filter(
              (singleData) => singleData.id === item.salutation
            )[0].label
          )
          break
        case FamilyMemberAttributesEnum.FIRST_NAME:
          member.value = item.first_name
          break
        case FamilyMemberAttributesEnum.MIDDLE_NAME:
          member.value = item.middle_name
          break
        case FamilyMemberAttributesEnum.LAST_NAME:
          member.value = item.last_name
          break
        case FamilyMemberAttributesEnum.GENDER:
          member.value = genderFunction(item.gender)
          break
        case FamilyMemberAttributesEnum.DOB:
          member.value = item.dob
          break
        case FamilyMemberAttributesEnum.AGE:
          member.value = moment().diff(item.dob, "years")
          break
        case FamilyMemberAttributesEnum.FMAILY_MEMBER_TYPE:
          member.value = item.family_member_type.replaceAll("_", " ")
          break
        case FamilyMemberAttributesEnum.FMAILY_MEMBER_ID:
          member.value = item.id
          break
        case FamilyMemberAttributesEnum.NAME:
          member.value = newFamilyMemberObject.name
          break
      }
    })

    let isMemberSelected = familyMemberList.some((item) => item.id === OptimizationStore.selectedNeedCardData.id)
    if (isMemberSelected) {
      if (item.id === OptimizationStore.selectedNeedCardData.id) {
        newFamilyMemberObject.fields.forEach((familyMember) => {
          if (familyMember.name === "isSelected") {
            familyMember.value = true
          }
        })
      }
    } else {
      if (newFamilyMemberObject.name === FamilyMemberTypeEnum.SELF) {
        newFamilyMemberObject.fields.forEach((familyMember) => {
          if (familyMember.name === "isSelected") {
            familyMember.value = true
          }
        })
      }
    }

    formController.$("members").add(newFamilyMemberObject)
  })
}

export default initialDefaults
