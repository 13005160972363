// Project Imports
import INCOME_TYPE_ENUM from "constants/incomeTypeEnum";
import chooseNeedInitialDropdownValues from "./chooseNeedDropDownInitialConstant";

const formObserver = (formController) => {
  formController.$("protection_for_my_family.target_amount_type").observe({
    key: "value",
    call: ({ form, field }) => {

      let type = field.value.mode;

      let lumpsumList = form.$("protection_for_my_family.target_amount_dropdown").extra.allOptionsList.filter(item => item.mode === INCOME_TYPE_ENUM.LUMPSUM)

      if (type === INCOME_TYPE_ENUM.LUMPSUM) {
        form.$("protection_for_my_family.target_amount_dropdown").set("extra", {
          ...form.$("protection_for_my_family.target_amount_dropdown").extra,
          optionList: lumpsumList,
        })

        form.$("protection_for_my_family.target_amount_dropdown").set("type", "text")

        const defaultTargetAmountValue = lumpsumList.find(item => item.label === chooseNeedInitialDropdownValues.protectionForFamilyLumpsum)

        form.$("protection_for_my_family.target_amount_dropdown").set("value",defaultTargetAmountValue)

      } else {

        form.$("protection_for_my_family.target_amount_value").set("type", "text")
        form.$("protection_for_my_family.target_amount_dropdown").set("type", "hidden")
      }
      form.$("protection_for_my_family.target_amount_value").set("type", "hidden")
      form.$("protection_for_my_family.target_amount_value").value = ""
    },
  });

  formController.$("grow_my_money.target_amount_type").observe({
    key: "value",
    call: ({ form, field }) => {
      let type = field.value.mode;

      form.$("grow_my_money.target_amount_dropdown").value = {}

      let incomeList = form.$("grow_my_money.target_amount_dropdown").extra.allOptionsList.filter(item => item.mode === INCOME_TYPE_ENUM.INCOME)

      let lumpsumList = form.$("grow_my_money.target_amount_dropdown").extra.allOptionsList.filter(item => item.mode === INCOME_TYPE_ENUM.LUMPSUM)

      if (type === INCOME_TYPE_ENUM.INCOME) {
        form.$("grow_my_money.target_amount_dropdown").set("extra", {
          ...form.$("grow_my_money.target_amount_dropdown").extra,
          optionList: incomeList,
        })

        const defaultTargetAmountValue = incomeList.find(item => item.label === chooseNeedInitialDropdownValues.growMyMoneyIncome)

        form.$("grow_my_money.target_amount_dropdown").set("value",defaultTargetAmountValue)

      }

      if (type === INCOME_TYPE_ENUM.LUMPSUM) {
        form.$("grow_my_money.target_amount_dropdown").set("extra", {
          ...form.$("grow_my_money.target_amount_dropdown").extra,
          optionList: lumpsumList,
        })
        const defaultTargetAmountValue = lumpsumList.find(item => item.label === chooseNeedInitialDropdownValues.growMyMoneyLumpsum)
  
        form.$("grow_my_money.target_amount_dropdown").set("value",defaultTargetAmountValue)
      }


    }
  });

  formController.$("save_for_children.target_amount_type").observe({
    key: "value",
    call: ({ form, field }) => {
      let type = field.value.mode;

      form.$("save_for_children.target_amount_dropdown").value = {}
      let incomeList = form.$("save_for_children.target_amount_dropdown").extra.allOptionsList.filter(item => item.mode === INCOME_TYPE_ENUM.INCOME)

      let lumpsumList = form.$("save_for_children.target_amount_dropdown").extra.allOptionsList.filter(item => item.mode === INCOME_TYPE_ENUM.LUMPSUM)

      if (type === INCOME_TYPE_ENUM.INCOME) {
        form.$("save_for_children.target_amount_dropdown").set("extra", {
          ...form.$("save_for_children.target_amount_dropdown").extra,
          optionList: incomeList,
        })

        const defaultTargetAmountValue = incomeList.find(item => item.label === chooseNeedInitialDropdownValues.saveForChildrenIncome)

        form.$("save_for_children.target_amount_dropdown").set("value",defaultTargetAmountValue)
      }
      if (type === INCOME_TYPE_ENUM.LUMPSUM) {
        form.$("save_for_children.target_amount_dropdown").set("extra", {
          ...form.$("save_for_children.target_amount_dropdown").extra,
          optionList: lumpsumList,
        })

        const defaultTargetAmountValue = lumpsumList.find(item => item.label === chooseNeedInitialDropdownValues.saveForChildrenLumpsum)

        form.$("save_for_children.target_amount_dropdown").set("value",defaultTargetAmountValue)

      }

    }
  });

  formController.$("save_for_retirement.target_amount_type").observe({
    key: "value",
    call: ({ form, field }) => {

      let type = field.value.mode;

      let incomeList = form.$("save_for_retirement.target_amount_dropdown").extra.optionList

      if (type === INCOME_TYPE_ENUM.INCOME) {
        form.$("save_for_retirement.target_amount_dropdown").set("extra", {
          ...form.$("save_for_retirement.target_amount_dropdown").extra,
          optionList: incomeList,
        })

        form.$("save_for_retirement.target_amount_dropdown").set("type", "text")

        const defaultTargetAmountValue = incomeList.find(item => item.label === chooseNeedInitialDropdownValues.saveForRetirementIncome)

        form.$("save_for_retirement.target_amount_dropdown").set("value",defaultTargetAmountValue)
        
      } else {
        form.$("save_for_retirement.target_amount_value").set("type", "text")
        form.$("save_for_retirement.target_amount_dropdown").set("type", "hidden")
      }
      form.$("save_for_retirement.target_amount_value").set("type", "hidden")
      form.$("save_for_retirement.target_amount_value").value = ""
    },
  });


};

export default formObserver;
