// Package Imports
import { action, makeObservable, observable, toJS } from "mobx";
import moment from "moment";

// Project Imports
import axios from "../../../utils/axios";
import * as APITypes from "../../../constants/API";
import AuthStore from "../../login/store/AuthStore";

class FamilyDetailsStore {
  nationalityList = []

  lifeCoverList = [];

  salutationList = [];

  genderlist = [];

  monthlyIncomelist = [];

  familyDetailMonthlyExpenseValueList = {}
  familyDetailsMonthlyExpenseErrorList = []

  familyDetailsEmiValueList = {}
  familyDetailsEmiErrorList = []

  familyDetailsSavingValueList = {}
  familyDetailsSavingErrorList = []

  savin

  constructor() {
    makeObservable(this, {
      updateNationalityList: action,
      updateLifeCoverList: action,
      updateSalutationList: action,
      updateGenderList: action,
      familyDetailsMonthlyExpenseErrorList:observable,
      familyDetailsSavingErrorList:observable,
    });
  }

  updateMonthExpenseValueList = (value) => {
    this.familyDetailMonthlyExpenseValueList = value
  }

  updateMonthlyExpenseErrorList = (value) => {
    this.familyDetailsMonthlyExpenseErrorList = value
  }

  updateMonthlyEmiValueList = (value) => {
    this.familyDetailsEmiValueList = value
  }

  updateMonthlyEmiErrorList = (value) => {
    this.familyDetailsEmiErrorList = value
  }


  updateSavingsValueList = (value) => {
    this.familyDetailsSavingValueList = value
  }

  updateSavingsErrorList = (value) => {
    this.familyDetailsSavingErrorList = value
  }

  updateNationalityList(updatedList) {
    this.nationalityList = updatedList
  }

  updateLifeCoverList(updatedList) {
    this.lifeCoverList = updatedList
  }

  updateSalutationList(updatedList) {
    this.salutationList = updatedList
  }

  updateGenderList(updatedList) {
    this.genderlist = updatedList
  }

  updateMonthlyIncomelist(updatedList){
    this.monthlyIncomelist = updatedList
  }

  async onNextBtnClick(props) {
    let familyMembers = toJS(this.familyMembers);
    let isSelected = familyMembers.some((item) => item.isSelected == true);
    this.isFamilyMemberSelected = !isSelected;
    let isSelfSelected = familyMembers.some((item) => item.isSelected && item.type.toLowerCase() == "self" && item.gender !== "" && item.dob !== "" && item.monthlyIncome !== "");
    if (!isSelfSelected) {
      this.showErrorMsg = "Please add self details";
      return;
    }
    if (!isSelected) {
      return;
    }

    familyMembers.forEach((item) => {
      if (item.isSelected && item.type !== "Child") {
        let age = moment().diff(moment(item.dob), "years");
        if (age < 18) {
          this.showErrorMsg = item.type + " age should be greater than 17 years";
          return;
        }
      }
    });

    this.isPrevInsurance = Object.keys(this.selectedPrevInsuranacneOptionValue).length == 0;

    const isMonthExpense = this.fetchExpenseSelected(this.optionalDetailsCardsList, this.selectedMonthlyOptionalDetailsCards);
    const isLiabilityExpense = this.fetchExpenseSelected(this.optionalLiabilityDetailsCardsList, this.selectedLiabilityOptionalDetailsCards);
    const isSavingExpense = this.fetchExpenseSelected(this.optionalSavingDetailsCardsList, this.selectedSavingOptionalDetailsCards);

    let familyDetails = false;
    const needList = toJS(this.needList);
    for (let index = 0; index < needList.length; index++) {
      const need = needList[index];
      let selectedFamily = [];
      if (!need.is_selected) continue;
      switch (need.need_unique_name) {
        case "protection_for_my_family":
          selectedFamily = familyMembers.filter((member) => member.isSelected);
          break;
        case "protection_for_my_health":
          break;
        case "grow_my_money":
          break;
        case "save_for_retirement":
          break;
        case "save_for_children":
          selectedFamily = familyMembers.filter((member) => member.isSelected && (member.type == "Self" || member.type == "Child"));
          if (selectedFamily.length <= 1) {
            this.showErrorMsg = "Please add atleast one child for Save for Children.";
          }
          break;
      }

      var familyData = familyMembers.filter((member) => member.type == "Spouse" || member.type == "Self");
      if (familyData.length > 1) {
        let isSameGender = familyData[0].gender == familyData[1].gender;
        if (isSameGender) {
          this.showErrorMsg = "Self and Spouse gender cannot be the same";
          break;
        }
      }

      if (this.showErrorMsg) {
        break;
      } else {
        let monthlyExp = Object.keys(toJS(this.selectedMonthlyOptionalDetailsCards));
        if (monthlyExp.length == 0) {
          this.showErrorMsg = `Please select ${monthlyExp == 0 ? '"Monthly Expense Details"' : ""}`;
          break;
        }
      }
    }

    this.locationErrorMsg = this.locationData.length == 0 ? "Enter valid location" : "";

    console.log("NEED LIST >> ", toJS(this.selectedMonthlyOptionalDetailsCards));
    if (!familyDetails && isSelected && !isMonthExpense && !isLiabilityExpense && !isSavingExpense && !this.showErrorMsg) {
      this.validate = false;
      var expenseListData = [];

      var monthList = this.expenseListData(toJS(this.selectedMonthlyOptionalDetailsCards), toJS(this.optionalDetailsCardsList), 1);
      var liabilityist = this.expenseListData(toJS(this.selectedLiabilityOptionalDetailsCards), toJS(this.optionalLiabilityDetailsCardsList), 2);
      var savingList = this.expenseListData(toJS(this.selectedSavingOptionalDetailsCards), toJS(this.optionalSavingDetailsCardsList), 3);

      monthList.map((item) => {
        expenseListData.push(item);
      });

      liabilityist.map((item) => {
        expenseListData.push(item);
      });

      savingList.map((item) => {
        expenseListData.push(item);
      });

      let selectedFamilyMembers = [];
      familyMembers.map((member) => {
        let isExistingMember = false;
        toJS(this.existingFamilyMember).map((eMember) => {
          if (member.type.toLowerCase() == eMember.family_member_type.toLowerCase()) {
            isExistingMember = true;
          }
        });
        if (isExistingMember || member.isSelected) {
          let object = {
            action: member.action || "save",
            user_journey: AuthStore.journeyId,
            family_member_type: member.type.toUpperCase(),
            gender: member.gender,
            dob: moment(member.dob).format("DD-MM-YYYY").toString() == "Invalid date" ? member.dob : moment(member.dob).format("DD-MM-YYYY").toString(),
            income_type: "-",
            monthly_income: member.monthlyIncome,
            is_selected: member.isSelected,
            first_name: member.first_name,
            middle_name: member.middle_name,
            last_name: member.last_name,
            family_member_name: member.name,
            current_life_cover: "1",
            salutation: "1",
            occupation: "1",
            education: "1",
            nationality: "India",
          };
          if (member.id) {
            object.id = member.id;
          }
          selectedFamilyMembers.push(object);
        }
      });
      let finalObj = {
        family_list: selectedFamilyMembers,
        expense_list: expenseListData,
        journey_id: AuthStore.journeyId
      };
      console.log("FInal object data is ", finalObj)
      const response = await axios.post(APITypes.SAVE_FAMILY_DETAILS, finalObj);
      if (response.status.toLowerCase() === "success") {
        // props.history.push(`/optimization/${AuthStore.journeyId}`);
        this.isDataSubmitted = true;
        return response.data;
      } else {
        return [];
      }
    } else {
      this.validate = true;
    }
  }

  closeModal() {
    this.isFamilyMemberSelected = false;
    this.isDataSubmitted = false;
  }

}

export default new FamilyDetailsStore();
