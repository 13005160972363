// Project Imports
import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessage';
import OptimizationStore from '../../../store/OptimizationStore';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Second Life Assured name validation
    validateSecondLifeAssuredName = (formRef) => {
        // let name = formRef.$('secondary-annuitant-name').value;
        // let annuityPlan = formRef.$('annuity-option').value.id;

        // if (annuityPlan === "1") {
        //     return
        // }
    
        // if (name.length == undefined || name.length == 0) {
        //     this.errorMessage.push(errorMessageKeys.spouse_name_required)
        // }

        // if (name.length > 20) {
        //     this.errorMessage.push(errorMessageKeys.spouse_name)
        // }
    }

    validateSecondLifeAssuredGender = (formRef) => {
        // let spouseGender = formRef.$('secondary-annuitant-gender').value;
        // let annuityPlan = formRef.$('annuity-option').value.id;
        // if (annuityPlan === "1") {
        //     return
        // }

        // if (spouseGender == undefined || spouseGender == '') {
        //     this.errorMessage.push(errorMessageKeys.spouse_gender_required)
        //     return;
        // }
    }

    // Second Life Assured DOB validation
    validateSecondLifeAssuredDob = (formRef) => {
        // let spouseDob = formRef.$('secondary-annuitant-date-of-birth').value;
        // let annuityPlan = formRef.$('annuity-option').value.id;
        // if (annuityPlan === "1") {
        //     return
        // }

        // if (spouseDob == undefined || spouseDob == '') {
        //     this.errorMessage.push(errorMessageKeys.spouse_dob_required)
        // }
    }


}
export default new Validator();