// React Imports
import React from "react";

// Package Imports
import { Col, Row, Card, Image } from "react-bootstrap";
import { observer } from "mobx-react";

// Project Imports
import { CustomCheckbox, BottomsheetDropdown } from "components";
import { ProtectionForMyHealthImg } from "constants/Images";

// CSS Imports
import "./ProtecttionOfMyHealth.css";

const ProtectionOfMyHealth = (props) => {
  const { showErrorMsg, errorMsg, isSelected, menuList, menuValue, menuOptionChange, label, formController } = props;

  const protectionOfMyHealth = formController.$("protection_for_my_health")

  return (
    <Card className='border-0 protection-my-health-card h-100'>
      <div className={!isSelected ? "card-overlay" : null}></div>
      <Card.Body className='p-2 pb-3'>
        <div>
          <Row className='d-flex align-items-start'>
            <Col xs={9} className='pr-0'>
              <CustomCheckbox
                isChecked={isSelected}
                onChange={() => props.toggleNeedSelection(isSelected)}
                id='protectionMyHealth'
                shape='rounded'
                title={label}
                titleStyle='text-primary font-weight-medium font-size-16 font-size-md-14 pl-2'
                containerStyle='align-items-start'
                checkmarkStyle='radio-margin-top'
                mark='checkmark'
                className='choose-Need-checkBox'
              />

              <div className='ml-4 pl-2'>
                <div className='align-items-center justify-content-between flex-md-column flex-row align-items-md-start ml-1'>
                  <p className='font-size-14 font-size-md-12 font-weight-light description-text-color mb-0'>
                    For my medical needs,
                    <br /> I would need
                  </p>
                  <div className='drop-down-style'>
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey='label'
                      bottomsheetTitle={protectionOfMyHealth.$("target_amount_dropdown").extra.bottomsheetTitle}
                      menuList={menuList}
                      onSelect={menuOptionChange}
                      value={menuValue}
                      disabled={!isSelected}
                      showErrorMsg={showErrorMsg}
                      errorMsg={errorMsg}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={3} className='text-lg-right'>
              <Image className='health-choose-card-img' src={ProtectionForMyHealthImg} alt='health protection' />
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};

export default observer(ProtectionOfMyHealth);
