// Project Imports
import ProductConstants from 'constants/ProductConstants';
import Product from 'constants/ProductConstants';

const fetchLifeCoverBenefit = (solutionData) => {
    let lifeCoverList = []
    const productWithNoLifeCover = []

    const listOfProductWithNoLifeCover = [Product.FOREVER_PENSION_IMMEDIATE_ANNUITY,Product.FOREVER_PENSION_DEFERRED_ANNUITY,Product.GUARANTEED_SAVINGS_STAR_LUMPSUM]

    solutionData.coverage.forEach((coverage) => {
        coverage.life_assured_list.forEach(lifeAssured => {
            lifeAssured.product_details.forEach(obj => {
                let productName = obj.product_name

                if(listOfProductWithNoLifeCover.includes(productName)){
                    productWithNoLifeCover.push(productName)
                }

                let isLifeCoverApplicable = productName !== Product.CRITICARE_PLUS &&
                    productName !== Product.EASY_PENSION &&
                    productName !== Product.SARAL_PENSION &&
                    productName !== Product.FOREVER_PENSION_DEFERRED_ANNUITY &&
                    productName !== Product.POS_FOREVER_PENSION

                if (isLifeCoverApplicable) {
                    let policyTerm = obj.data.InputString.find((item) => item.Key == "@PR_PT")?.Value
                    lifeCoverList.push({ policyTerm: Number(policyTerm), memberType:lifeAssured.la_family_member_type,laName: lifeAssured.la_name, productName: productName })
                }
            })
        })
    })

    let lifeCoverMsg = ""

    const ptList = lifeCoverList.map(object => { return object.policyTerm; });
    const maxPt = Math.max(...ptList);

    let maxPtList = []
    lifeCoverList.map((lifeCover) => {
        if (lifeCover.policyTerm === maxPt) {
            maxPtList.push(lifeCover)
        }
    })

    let maxData = maxPtList[maxPtList.length - 1]

    if (maxData) {
        if (maxData.productName === ProductConstants.CASH_FLOW_PROTECTION_PLUS) {
            lifeCoverMsg = "Life Cover till age 99"
        } else if(!productWithNoLifeCover.includes(Product.GUARANTEED_SAVINGS_STAR_LUMPSUM) && !productWithNoLifeCover.includes(Product.FOREVER_PENSION_IMMEDIATE_ANNUITY) && !productWithNoLifeCover.includes(Product.FOREVER_PENSION_DEFERRED_ANNUITY)){
            lifeCoverMsg = `Life Cover for ${maxData.policyTerm} years for ${maxData.laName || maxData.memberType}`
        }
    }
    return lifeCoverMsg
}

export default fetchLifeCoverBenefit