import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";

import { observer } from "mobx-react";

import { BottomsheetDropdown, ChangeProductSection } from "components";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import Utils from './utils';
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import DeathBenefitAmount from "utils/optimization/deathBenefitAmount";

const POSSaralNivesh = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false)

  let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let biResponse = productData.parsedResponse;
  let firstYearResponse = productData.firstYearResponse;
  let biRequest = productData.bi_request;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])

  const onHideOverlay = () => {
    setShowOverlay(false)
  }

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])


  return (
    <div className="save-my-children">
      <Row className="align-items-center mt-md-4">

        <Col xs={12} md={4} className="mt-3 mt-md-0">
          <ChangeProductSection
            productName={productData.product_name || "POS - Saral Nivesh"}
            cardName={coverLabelBaseOnNeed}
            changeProductList={data?.data?.changeProductList}
            productData={data}
          />
        </Col>
      </Row>
      <DeathCoverGraphSlider
        openChangeReasonModal={false}
        onIncomeChange={() => { }}
        onPremiumChange={form.$("premium").onChange}
        hideOverLay={onHideOverlay}
        productID={productData.product_id}
        productData={data?.data}
        showOverlay={showOverlay}
        cardKey={data.cardKey}
        income_value=""
        premium_value={form.$("premium").value}
        onClick={() => form.$("premium").extra.calculatePremiun(form, data)}
        data=""
        isIncomeFieldDisable={form.$("premium").disabled}
        premiumFreq={form.$("payment-frequency").value.label}
        formRef={form}
      />

      <Row className='mt-3 ml-0'>
        <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{"Sum Assured on Death:"}</p>
        <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(productData)}</p>
      </Row>
      
      <Row className="mt-2 mt-md-4">
        <Col md={6} className="col-12 mt-3">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("policy-term").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("policy-term").label}
            menuList={form.$("policy-term").extra.optionList}
            value={form.$("policy-term").value}
            onSelect={form.$("policy-term").onChange}
            disabled=""
          />

        </Col>
        <Col md={6} className="col-12 mt-3">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("premium-term").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("premium-term").label}
            menuList={form.$("premium-term").extra.optionList}
            value={form.$("premium-term").value}
            onSelect={form.$("premium-term").onChange}
          />

        </Col>
      {/* </Row>
      <Row className="my-3"> */}
        <Col md={4} className="col-12 mt-3">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("payment-frequency").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("payment-frequency").label}
            menuList={form.$("payment-frequency").extra.optionList}
            value={form.$("payment-frequency").value}
            onSelect={form.$("payment-frequency").onChange}
          />

        </Col>
      </Row>

    </div>
  );
};

export default observer(POSSaralNivesh);
