// Project Imports
import Product from 'constants/ProductConstants';

const fetchTaxBenefit = (solutionData) => {
    let taxBenefitApplicable = false

    solutionData.coverage.forEach((coverage) => {
        coverage.life_assured_list.forEach(lifeAssured => {
            lifeAssured.product_details.forEach(obj => {
                switch (obj.product_name) {
                    case Product.CASH_FLOW_PROTECTION_PLUS:
                    case Product.GCAP:
                    case Product.SMART_LIFESTYLE:
                    case Product.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
                    case Product.INCOME_BUILDER:
                    case Product.SECURED_INCOME_OPTION_INCOME_BUILDER:
                    case Product.POS_SARAL_NIVESH:
                    case Product.SARAL_JEEVAN_BIMA:
                    case Product.POS_SARAL_JEEVAN_BIMA:
                    case Product.ZINDAGI_PLUS:
                    case Product.ZINDAGI_PLUS:
                    case Product.RISING_STAR_WEALTH_PLUS:
                    case Product.WEALTH_GAIN_PLUS:
                    case Product.WEALTH_SECURE_PLUS:
                    case Product.CHILD_VARIANT_WEALTH_SECURE_PLUS:
                    case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
                    case Product.DHAN_LABH:
                    case Product.EASY_PENSION:
                    case Product.ACTIVE_INCOME_PLAN:
                    case Product.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
                    case Product.ACTIVE_INCOME_PLAN_WITH_FIB:
                    case Product.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
                    case Product.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
                    case Product.TOTAL_PROTECT_PLUS:
                    case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
                    case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME:
                    case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM:
                    case Product.PREMIER_GUARANTEED_RETIREMENT_INCOME:
                    case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
                    case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
                    case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
                    case Product.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:
                    case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED :
                    case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
                    case Product.GUARANTEED_INCOME_STAR :
                    case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB :
                    case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB :
                    case Product.GUARANTEED_INCOME_STAR_FIB:
                    case Product.POS_GUARANTEED_INCOME_STAR :
                    case Product.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
                    case Product.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
                    // case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM:
                    case Product.GUARANTEED_SAVINGS_STAR_INCOME:
                    case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
                    case Product.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
                    case Product.POS_GUARANTEED_SAVINGS_STAR :  
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
                    case Product.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
                    case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
                    case Product.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:  
                        if (!taxBenefitApplicable) taxBenefitApplicable = true
                        break;
                }
            })
        })
    })

    if (taxBenefitApplicable) {
        return "All premiums paid are tax deductable U/S 80C and all benefits are tax free U/S 10(10D)"
    }
    return ""
}

export default fetchTaxBenefit