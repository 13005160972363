// Package Imports
const { Form } = require("mobx-react-form");
import yup from 'mobx-react-form/lib/validators/YUP';
import vjf from 'mobx-react-form/lib/validators/VJF';
import * as $pkg from 'yup';

// Project Imports
import setInitialDefaults from './initialDefaults';
import {atLeastOneShouldBeSelected} from './validation'

class RetirementFormController extends Form {
    plugins() {
        return {
            vjf: vjf(),
            yup: yup({
                package: $pkg,
                schema: (_yup) =>
                    _yup.object().shape({
                        journey_id: _yup.string().optional(),
                        
                        family_member_id: _yup.string().optional(),

                        date_of_birth:_yup.string().required("This field is mandatory"),

                        monthly_expense:_yup.object({
                            household:_yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                            rent:_yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                            school_fee:_yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                            others:_yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            })
                        }),
                    }),
            })
        }
    }

    setup() {
        return {
            fields: [
                {
                    name: 'journey_id',
                    type: 'hidden'
                },
                {
                    name: 'family_member_id',
                    type: 'hidden'
                },
                {
                    name: "date_of_birth",
                    label: "Date of Birth",
                    placeholder: "",
                },
                {
                    name: "monthly_expense",
                    label: "Monthly Expenses",
                    validators: [atLeastOneShouldBeSelected()],
                    placeholder: "",
                    fields: []
                }
            ],
        };
    }

    setDefaults(expenseList, journeyData, dateOfBirthFromChooseNeeds, familyMemberId, membersExpenseList) {
        // Set forms initial default value
        setInitialDefaults(this, expenseList, journeyData, dateOfBirthFromChooseNeeds, familyMemberId, membersExpenseList)

        // Observer fields
        // formObserver(this, data)
    }

}

export default RetirementFormController