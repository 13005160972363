// React Imports
import React from "react"

// Package Imports
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal"
import { observer } from "mobx-react"

// project imports
import SaveForChildrenModalForm from "./save-for-children-modal-form";

// CSS Imports
import "./add-insured-save-for-children-pop-up-modal.css"

const AddInsuredSaveForChildrenPopUpModal = (props) => {
  
      return (
        <div className="add-insured-pop-up-div d-flex align-items-center">
          <Modal
            show={props.showSaveForMyChildren}
            backdrop="static"
            onHide={props.hideSaveForChilrenPopUpModal}
            as="section"
            className="add-insured-pop-up-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            
            <SaveForChildrenModalForm
              hideSaveForChilrenPopUpModal={props.hideSaveForChilrenPopUpModal}
            />

          </Modal>
        </div>
      )
  }

  AddInsuredSaveForChildrenPopUpModal.propTypes = {
    hideSaveForChilrenPopUpModal:PropTypes.func.isRequired
  };

export default observer(AddInsuredSaveForChildrenPopUpModal)
