// Project Imports
import ProductPackageId from "constants/ProductPackageId"

const deathCoverTargetAmountLabel = (productData, firstAmountLabel) => {
    try {
        if (productData) {
            switch (productData.package_id) {
                case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
                case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME:
                case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
                case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
                case ProductPackageId.PREMIER_GUARANTEED_RETIREMENT_INCOME:

                    let frequency = productData.bi_request["IncomeBenefitPayoutFrequency"]
                    switch (Number(frequency)) {
                        case 1:
                            return "per annum"

                        case 2:
                            return "per half year"

                        case 3:
                            return "per quarter"

                        case 4:
                            return "per month"
                    }

                default:
                    return firstAmountLabel
            }
        } else {
            return firstAmountLabel
        }
    } catch (e) {
        return firstAmountLabel
    }
}

export default deathCoverTargetAmountLabel