// Project Imports
import axios from "utils/axios";
import * as APITypes from "constants/API";
import isObjectEmpty from "utils/isObjectEmpty";
import fetchNeedMasterData from "utils/fetchNeedMasterData";
import NeedTypeEnum from "constants/NeedTypeEnum";
import OptimizationStore from "modules/optimization/store/OptimizationStore";


const initialDefaults = async (formController, journeyData, journeyId,) => {

    if (journeyId !== undefined) {
        formController.$("journey_id").value = journeyId.toString()
    }

    let masterData = await fetchNeedMasterData()

    if (masterData.length <= 0) return

    let saveForChildren = masterData.find((item) => item.unique_name === NeedTypeEnum.SAVE_FOR_CHILDREN)

    let selectedFamilyMember = journeyData.family_details_list.filter((item) => item.id === OptimizationStore.selectedFamilyMemberId)[0]
    let selfData = journeyData.family_details_list[0]

    let targetAmountOfSelectedFamilyMember = selectedFamilyMember?.family_product_details_list.find((item) => item.need_unique_name === NeedTypeEnum.SAVE_FOR_CHILDREN)
    let targetAmountOfSelf = selfData.family_product_details_list.find((item) => item.need_unique_name === NeedTypeEnum.SAVE_FOR_CHILDREN)

    let selectedTargetAmount = targetAmountOfSelectedFamilyMember?.target_amount || targetAmountOfSelf.target_amount
    let selectedPayoutMode = targetAmountOfSelectedFamilyMember?.payout_mode || targetAmountOfSelf.payout_mode

    formController.$("target_amount_type").set("extra", {
        ...formController.$("target_amount_type").extra,
        optionList: saveForChildren.mode_master
    })

    if (selectedTargetAmount) {
        let targetAmount = saveForChildren.target_amount.filter((item) => item.id === selectedTargetAmount)[0]
        formController.$("target_amount_dropdown").set("value", targetAmount)
    }

    if (selectedPayoutMode) {
        let payoutMode = saveForChildren.mode_master.filter((item) => item.mode.toLowerCase() === selectedPayoutMode)[0]
        formController.$("target_amount_type").set("value", payoutMode)

        let targetAmountList = saveForChildren.target_amount.filter((item) => item.mode.toLowerCase() === selectedPayoutMode.toLowerCase())
        formController.$("target_amount_dropdown").set("extra", {
            ...formController.$("target_amount_dropdown").extra,
            optionList: targetAmountList
        })
    }

}

export default initialDefaults