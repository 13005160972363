import React, { Component } from 'react'
import { Card, Image } from 'react-bootstrap'
import { Button as UiButton } from '../../components';
import ShareQuotesStore from '../share-quote-form/store/index'
import { JourneyCompleteGIF } from 'constants/Images';

import './journey-complete.css'
class JourneyComplete extends Component {
  // {this.props.location.state.customerName}
  componentDidMount() {
    window.scrollTo(0, 0)
  }

  redirectToFirstScreen = () => {
    this.props.history.push('/choose-needs')
  }

  render() {
    // const { customerName } = this.props.location.state
    return (
      <div className='container mt-4 mb-5 mt-md-4 pb-5 pb-md-0'>
        <Card className='shadow-sm border-0 my-3'>
          <Card.Body className='pt-0'>
            <div className='d-flex flex-column align-items-center text-primary-title-label-color text-center'>
              <Image className='journey-comlete-img' src={JourneyCompleteGIF} alt='JourneyCompleteGif' />
              <h1 className='font-weight-medium font-size-24 font-size-md-32'>Successful</h1>
              <h3 className='font-weight-normal font-size-16 font-size-md-24'>The quote was successfuly shared with</h3>
              <h2 className='font-weight-bold font-size-20 font-size-md-24 text-secondary'>{ShareQuotesStore.customerName !== '' ? ShareQuotesStore.customerName : 'Customer'}</h2>
              <p className='font-weight-normal font-size-14 font-size-md-20 mb-2'>Via</p>
              <h4 className='font-weight-medium font-size-20 font-size-md-24'>Email</h4>
              <h4 className='font-weight-medium font-size-20 font-size-md-24'>SMS</h4>
              <h4 className='font-weight-medium font-size-20 font-size-md-24'>Whatsapp</h4>
              <p className='font-weight-normal font-size-16 font-size-md-24'>The quote has been saved in the <br />
                “Leads“ section.
              </p>
              <div className='complete-journey-ok-btn-grp p-3 p-md-0'>
                <UiButton className='font-weight-medium font-size-16 mt-md-4 complete-journey-ok-btn' variant='secondary' buttonText='Okay' onClick={this.redirectToFirstScreen} />
              </div>

            </div>
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default JourneyComplete