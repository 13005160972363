// Project Imports
import isObjectEmpty from "utils/isObjectEmpty"
import fetchFamilyMasterData from "utils/fetchFamilyMasterData"
import fetchNeedMasterData from "utils/fetchNeedMasterData"
import NeedTypeEnum from "constants/NeedTypeEnum"
import initializeEducationOccupationFieldsForPopUpModal from "utils/initializeOccupationEducationFieldsForPopUpModal"
import preFillOccupationEducationLocationFields from "utils/preFillOccupationEducationLocationFieldsPopUpModal"
import OptimizationStore from "modules/optimization/store/OptimizationStore"



const initialDefaults = async (formController, journeyData, journeyId) => {
  let masterData = await fetchFamilyMasterData()
  // Initialise form values
  initializeEducationOccupationFieldsForPopUpModal(formController, masterData, journeyData)

  if (journeyId !== undefined) {
    formController.$("journey_id").value = journeyId.toString()
  }

  if (isObjectEmpty(journeyData)) {
    return
  }

  /*  preFilling the recommendation dropDown */
  let NeedmasterData = await fetchNeedMasterData()

  if (NeedmasterData.length <= 0) return

  let protectionForHealth = NeedmasterData.find((item) => item.unique_name === NeedTypeEnum.PROTECTION_FOR_MY_HEALTH)

  formController.$("recommendation").set("extra", {
    ...formController.$("recommendation").extra,
    optionList: protectionForHealth.target_amount
  })

  let selectedFamilyMember = journeyData.family_details_list.filter((item) => item.id === OptimizationStore.selectedFamilyMemberId)[0]
  let selfData = journeyData.family_details_list[0]

  let targetAmountOfSelectedFamilyMember = selectedFamilyMember?.family_product_details_list.find((item) => item.need_unique_name === NeedTypeEnum.PROTECTION_FOR_MY_HEALTH)
  let targetAmountOfSelf = selfData.family_product_details_list.find((item) => item.need_unique_name === NeedTypeEnum.PROTECTION_FOR_MY_HEALTH)

  let selectedTargetAmount = targetAmountOfSelectedFamilyMember?.target_amount || targetAmountOfSelf.target_amount

  if (selectedFamilyMember?.occupation && selectedFamilyMember?.education && selectedFamilyMember?.office_name) {
    setMemberData(selectedFamilyMember, formController, protectionForHealth, masterData, selectedTargetAmount)
  } else {
    let selfDetails = journeyData.family_details_list[0]
    setMemberData(selfDetails, formController, protectionForHealth, masterData, selectedTargetAmount)
  }

}

const setMemberData = async (selectedFamilyMember, formController, protectionForHealth, masterData, selectedTargetAmount) => {
  let occupation = masterData.occupationResponse.data.filter((item) => item.id === selectedFamilyMember.occupation)[0]
  if (occupation) formController.$("occupation").set("value", occupation)

  let education = masterData.educationResponse.data.filter((item) => item.id === selectedFamilyMember.education)[0]
  if (education) formController.$("education").set("value", education)
  formController.$("location").set("value",
    [
      {
        id: selectedFamilyMember.pin_code,
        label: selectedFamilyMember.office_name,
        location: selectedFamilyMember.state,
      },
    ]
  )

  let targetAmount = protectionForHealth.target_amount.filter((item) => item.id === selectedTargetAmount)[0]
  formController.$("recommendation").set("value", targetAmount)
}

export default initialDefaults