const { Form } = require("mobx-react-form");

import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

import setInitialDefaults from './initialDefaults'
import formObserver from './observers'
import Utils from './utils'
import * as keys from './keys'

class POSSaralNiveshFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          rules: "required:string",
          disabled: false,
          extra: {
            optionList: [],
          },
        },
        {
          name: "premium-term",
          value: {},
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            optionList: [],
          },
        },
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Annual",
              },
              {
                id: "4",
                label: "Monthly",
              },
            ],
          },
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremiun(form, data) {
              // let premiumAmount = 0;
              // let productId = data.data.product_id
              // let inputMode = Number(form.$('payment-frequency').value.id)

              // let amount = Number(form.$('premium').value)
              // switch (inputMode) {
              //   case 1:
              //     premiumAmount = '' + amount * 1
              //     break;
              //   case 2:
              //     premiumAmount = '' + amount * 2
              //     break;
              //   case 3:
              //     premiumAmount = '' + amount * 4
              //     break;
              //   case 4:
              //     premiumAmount = '' + amount * 12
              //     break;
              // }
              // Utils.updateBi(keys.PREMIUM, premiumAmount, productId, form, data)
            },
          },
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data);
  }
}

export default POSSaralNiveshFormController;
