// Package Imports
const { Form } = require("mobx-react-form");
import yup from 'mobx-react-form/lib/validators/YUP';
import validator from 'validator';
import vjf from 'mobx-react-form/lib/validators/VJF';
import * as $pkg from 'yup';

// Project Imports
import setInitialDefaults from './initialDefaults';
import { atLeastOneShouldBeSelected } from './validation'
import ProtectionCalculatorObserver from './ProtectionCalculatorObserver'
import isInteger from 'utils/validations/isInteger'

class ProtectionFormController extends Form {
    plugins() {
        return {
            vjf: vjf(validator),
            yup: yup({
                package: $pkg,
                schema: (_yup) =>
                    _yup.object().shape({
                        journey_id: _yup.string().optional(),
                        family_member_id: _yup.string().optional(),
                        monthly_income: _yup.string().matches(/^[0-9]*$/, 'Please enter only numbers').nullable().required("This field is mandatory"),
                        date_of_birth: _yup.string().nullable().required("This field is mandatory"),
                        monthly_expense: _yup.object({
                            household: _yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                            rent: _yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                            school_fee: _yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                            others: _yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            })
                        }),

                        monthly_emi: _yup.object({
                            home_loan_emi: _yup.object().shape({
                                option_range: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                                paid_years: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                                total_years: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                            }),
                            car_loan_emi: _yup.object().shape({
                                option_range: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                                paid_years: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                                total_years: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                            }),
                            personal_loan_emi: _yup.object().shape({
                                option_range: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                                paid_years: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                                total_years: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                            }),
                            other_loan_emi: _yup.object().shape({
                                option_range: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                                paid_years: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                                total_years: _yup.object().shape({
                                    id: _yup.mixed().required("This field is mandatory")
                                }),
                            })
                        }),

                        monthly_savings: _yup.object({
                            recurring_dep: _yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                            others: _yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                        }),

                        current_savings: _yup.object({
                            fixed_deposit: _yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                            mutual_funds: _yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                            insurance_policy: _yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                            others: _yup.object().shape({
                                id: _yup.mixed().required("This field is mandatory")
                            }),
                        }),

                        current_life_cover: _yup.object().shape({
                            id: _yup.mixed().required("This field is mandatory")
                        }),

                    }),
            })
        }
    }

    setup() {
        return {
            fields: [
                {
                    name: 'journey_id',
                    type: 'hidden'
                },
                {
                    name: 'family_member_id',
                    type: 'hidden'
                },
                {
                    name: "monthly_income",
                    label: "Monthly Income",
                    placeholder: "",
                    disabled: false,
                    validators: [isInteger,],
                    extra: {
                        optionList: [],
                    },
                },
                {
                    name: "date_of_birth",
                    label: "Date of Birth",
                    placeholder: "",
                },
                {
                    name: "monthly_expense",
                    label: "Monthly Expenses",
                    placeholder: "",
                    validators: [atLeastOneShouldBeSelected()],
                    fields: [],
                },

                {
                    name: "monthly_emi",
                    label: "Monthly EMIs, if any",
                    placeholder: "",
                    fields: [],
                    extra: {
                        paidYearsOptionList: [
                            {
                                id: "0",
                                label: "0",
                            },
                            {
                                id: "1",
                                label: "1",
                            },
                        ],
                        totalYearsOptionList: [
                            {
                                id: "1",
                                label: "1",
                            },
                            {
                                id: "2",
                                label: "2",
                            },
                        ],

                        paidYears: {},
                        totalYears: {},
                    }
                },
                {
                    name: "monthly_savings",
                    label: "Monthly Savings, if any",
                    placeholder: "",
                    fields: []
                },
                {
                    name: "current_savings",
                    label: "Current Savings Value, if any",
                    placeholder: "",
                    fields: []
                },
                {
                    name: "current_life_cover",
                    label: "Current Life Cover",
                    placeholder: "",
                    value: { id: 1, label: 'No Cover', status: true },
                    extra: {
                        bottomSheetTitle: "Select a Life Cover"
                    }
                }

            ],
        };
    }

    setDefaults(expenseList, lifeCoverDetails, journeyData, monthlyIncomeValue, dateOfBirthFromChooseNeeds, familyMemberId, membersExpenseList, emiList) {
        // Set forms initial default value
        setInitialDefaults(this, expenseList, lifeCoverDetails, journeyData, monthlyIncomeValue, dateOfBirthFromChooseNeeds, familyMemberId, membersExpenseList, emiList)

        // Observer fields
        ProtectionCalculatorObserver(this)
    }

}

export default ProtectionFormController