// React Imports
import React, { Component } from "react";

// Package Imports
import { observer } from "mobx-react";
import { Image } from "react-bootstrap";
import { toJS } from "mobx";
import moment from "moment";

// Project Imports
import FormContext from "context/FormContext";
import QmsDetailsPageStore from "modules/qms-flow/qms-details-page/QmsDetailsPageStore";
import * as images from "constants/Images";

// CSS Imports
import "./qms-details-breadcrumb.css";

class QmsBreadcrumb extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	onBackBtnClick() {
		this.props.history.push("/qms/qms-dashboard")
	}

	render() {
		let qmsData = toJS(QmsDetailsPageStore.seletedTabData)
		return (
			<div className='qms-breadcrumb pb-0 pt-0 px-1 w-100'>
				{/* 1st row */}
				<div className='d-flex align-items-center justify-content-between breadcrumb-content-container'>

					<div className='d-flex flex-column flex-md-row justify-content-between  py-2' style={{ maxWidth: "900px" }}>

						<div className='d-flex flex-wrap align-items-center' onClick={() => this.onBackBtnClick()}>
							<div className="mb-0 d-flex align-items-center font-size-12 font-weight-light my-0 mr-1 cursor-pointer" >
								<Image className="mr-2" src={images.QMSBackBtnIcon} alt='back-arrow' />
								<span>Back</span>
							</div>
						</div>

						<div className='d-none d-md-block border-right border-dark mx-md-4'></div>

						<p className='font-size-18 font-weight-medium mb-0 mr-1'>{qmsData?.proposer_salutation} {qmsData?.proposer_first_name} {qmsData?.proposer_middle_name} {qmsData?.proposer_last_name} [{qmsData?.family_member_type}]</p>

						<div className='d-none d-md-block border-right border-dark mx-md-4'></div>

						<div className='d-flex flex-wrap align-items-center'>
							<a href={`tel:${qmsData?.mobile_number}`} className="font-size-12 font-weight-400 mb-0 mr-1 mobile-number">{qmsData?.mobile_number}</a>
						</div>

						<div className='d-none d-md-block border-right border-dark mx-md-4'></div>

						<div className='d-flex flex-wrap align-items-center'>
							<a href={`mailto:${qmsData?.email}`} className="font-size-12 font-weight-400 mb-0 mr-1 mobile-number">{qmsData?.email}</a>
						</div>

					</div>

				</div>

				<div className='d-none d-md-block border-bottom '></div>

				{/* 2nd row */}
				<div className='d-flex align-items-center justify-content-between breadcrumb-content-container'>

					<div className='d-flex flex-column flex-md-row justify-content-between  py-2' style={{ maxWidth: "900px" }}>

						<div className='d-flex flex-wrap align-items-center'>
							<p className='font-size-12 font-weight-light mb-0 mr-1 gray-color-333'>Status: </p>
							<p className='font-size-18 font-weight-medium mb-0 mr-1 qms-status'>{qmsData?.solution_status}</p>
							<p className='font-size-12 font-weight-light mb-0 mr-1 gray-color-333'>on {qmsData?.solution_created_date ? moment(qmsData?.solution_created_date).format("DD-MM-YYYY") : qmsData?.solution_created_date} </p>
						</div>

						<div className='d-none d-md-block border-right border-dark mx-md-4'></div>

						<div className='d-flex flex-wrap align-items-center '>
							<p className='font-size-14 font-weight-medium gray-color-333 mb-0 mr-1'>Journey ID: {qmsData?.journey_id}</p>
						</div>

					</div>
				</div>
				<div className='d-none d-md-block border-bottom '></div>

			</div >
		);
	}
}

QmsBreadcrumb.contextType = FormContext;

export default observer(QmsBreadcrumb);
