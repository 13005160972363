import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import OptimizationStore from '../../../store/OptimizationStore';
import isTotalFundCountValid from 'utils/isTotalFundCountValid';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Entry Age validation
    entryAgeValidation = (form, productData) => {
        // let age = productData.data.bi_request.LI_ENTRY_AGE;
        // console.log("LIFE COVER OPTION", form)
        // let lifeCoverOption = form.$('life-cover-option').value.id;

        // switch (lifeCoverOption) {
        //     case '1':
        //         if (age < 8) {
        //             this.errorMessage.push(errorMessageKeys.life_cover_option.single_life_min_age)
        //         } else if (age > 40) {
        //             this.errorMessage.push(errorMessageKeys.life_cover_option.single_life_max_age)
        //         }
        //         break;

        //     case '2':
        //         if (age < 18) {
        //             this.errorMessage.push(errorMessageKeys.life_cover_option.joint_life_min_age)
        //         } else if (age > 65) {
        //             this.errorMessage.push(errorMessageKeys.life_cover_option.joint_life_max_age)
        //         }
        //         break
        // }
    }

    // Minimum Premium validation 
    minimumPremiumValidation = (form) => {
        // let premium = form.$('premium').value

        // if (Number(premium) < 1000000) {
        //     this.errorMessage.push(errorMessageKeys.minimum_premium)
        // }
    }

    // Life Insured Major Validation
    lifeInsuredValidation = (form, productData) => {
        // let age = productData.data.bi_request.LI_ENTRY_AGE;
        // let lifeCoverOption = form.$('life-cover-option').value.id
        // let sameProposer = productData.data.bi_request.SameProposer

        // if (lifeCoverOption == '1') {
        //     if (age >= 18 && !sameProposer) {
        //         this.errorMessage.push(errorMessageKeys.life_insured_validation)
        //     }
        // }
    }

    // Second Life Assured name validation
    validateSecondLifeAssuredName = (formRef) => {
        // let name = formRef.$('second-life-assured-name').value;
        // let lifeCoverOption = formRef.$('life-cover-option').value.id
        // if (lifeCoverOption == '1') {
        //     return
        // }

        // if (name.length == undefined || name.length == 0) {
        //     this.errorMessage.push(errorMessageKeys.spouse_name_required)
        // }

        // if (name.length > 20) {
        //     this.errorMessage.push(errorMessageKeys.spouse_name)
        // }
    }

    // Second Life Assured Gender validation
    validateSecondLifeAssuredGender = (formRef) => {
        // let spouseGender = formRef.$('second-life-assured-gender').value;
        // let lifeCoverOption = formRef.$('life-cover-option').value.id
        // if (lifeCoverOption == '1') {
        //     return
        // }

        // if (spouseGender == undefined || spouseGender == '') {
        //     this.errorMessage.push(errorMessageKeys.spouse_gender_required)
        //     return;
        // }
    }

    // Second Life Assured DOB validation
    validateSecondLifeAssuredDob = (formRef) => {
        // let spouseDob = formRef.$('second-life-assured-dob').value;
        // let lifeCoverOption = formRef.$('life-cover-option').value.id
        // if (lifeCoverOption == '1') {
        //     return
        // }

        // if (spouseDob == undefined || spouseDob == '') {
        //     this.errorMessage.push(errorMessageKeys.spouse_dob_required)
        // }
    }


    // 100% Fund Allocation validation
    fundAllocationValidations = (formRef) => {
        let fundsList = formRef.$("fund-strategy").extra.fundList;
        let isCountValid = false;

        isCountValid = isTotalFundCountValid(fundsList);

        if (!isCountValid) {
            this.errorMessage.push("Total Fund allocation must be 100%")
        }
    }

}
export default new Validator();