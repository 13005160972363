import React, { Component } from 'react';
import SliderComponent from 'rc-slider';
import 'rc-slider/assets/index.css';
import './slider.css'

import PropTypes from 'prop-types';
import { BarsSolidIcon } from 'constants/Images';

const { Handle } = SliderComponent;

const handle = (props) => {
    // eslint-disable-next-line no-unused-vars
    const { dragging, ...prop } = props
    return (
        <Handle {...prop}>
            <img className='slider-handle-image' src={BarsSolidIcon} alt='bar-icon' />
        </Handle>
    )
}
class Slider extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    onChange = (index) => {
        const { onChange } = this.props;
        if (onChange) {
            const { steps } = this.props;
            onChange(steps[index], index)
        }
    }
    getMarks = () => {
        let marks = {}
        const { steps } = this.props;
        if (steps) {
            for (let index = 0; index < steps.length; index++) {
                const element = steps[index];
                marks[index] = element
            }
        }
        return marks
    }
    render() {
        const { steps, defaultValue } = this.props;
        const max = steps.length - 1

        return (
            <div className='slider'>
                <SliderComponent
                    marks={this.getMarks()}
                    step={1}
                    defaultValue={defaultValue}
                    max={max}
                    handle={handle}
                    onChange={this.onChange}
                    trackStyle={{ backgroundColor: '#DF6336', zIndex: '2', height: '2px' }}
                    railStyle={{ backgroundColor: '#DADADA', zIndex: '2', height: '2px' }}
                    handleStyle={{ zIndex: '2' }}
                />
            </div>
        );
    }
}

Slider.defaultProps = {
    steps: [1, 2, 3, 4, 5]
}

Slider.propTypes = {
    steps: PropTypes.array.isRequired,
    onChange: PropTypes.func
}

export default Slider;