import Utils from './utils';

const initialDefaults = (formRef, data) => {
    let productData = data.data
    let biResponse = productData.parsedResponse;
    let biRequest = productData.bi_request;

    let frequency = biRequest.INPUT_MODE.toString()
    let option = biRequest.Option
    let premium = biRequest.PR_ANNPREM

    let ptList = Utils.getPolicyTerm()
    formRef.$('policy-term').set('extra', {
        ...formRef.$('policy-term').extra,
        optionList: ptList
    })

    formRef.$('policy-term').value = {
        id: biRequest.PR_PT,
        label: biRequest.PR_PT
    }

    let pptList = Utils.getPremiumTermList(formRef.$('policy-term').value)

    formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        optionList: pptList
    })

    formRef.$('premium-term').value = {
        id: biRequest.PR_PPT,
        label: biRequest.PR_PPT
    }

    switch (frequency) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '4':
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }

    let premiumAmount = ''
    switch (frequency) {
        case '1':
            premiumAmount = premium;
            break;
        case '4':
            premiumAmount = Number(premium) / 12;
            break;

        default:
            break;
    }

    formRef.$('premium').set('value', premiumAmount)

}

export default initialDefaults