// React Imports
import React, { Component } from "react"

// Package Imports
import { Image } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import ModalBody from "react-bootstrap/ModalBody"
import { Button as UiButton } from "components"

// Project Imports
import * as images from "constants/Images"
import {fetchCashFlowTableData} from "./utils"
import PolicyTimlineTable from "modules/solution/components/policy-timeline-table/PolicyTimelineTable"
import isObjectEmpty from "utils/isObjectEmpty"
import SolutionStore from "modules/solution/store/SolutionStore"

// CSS Imports
import "./cash-flow-table.css"

const CashFlowTableModal = class CashFlowTableModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      data: {policyTimeLineData:{}},
      needName: "",
    }
  }

  hideCashFlowTableModal = () => {
    this.setState({ isOpen: false })
  }

  showCashFlowTableModal = async () => {
    this.setState({ isOpen: true })
    const response = await fetchCashFlowTableData(this.props.productData)
    this.setState({needName: response?.data?.coverage[0]?.need_title});
    let policyTimeLineData = SolutionStore.getCoverageData(response.data)
    this.setState({data:{policyTimeLineData:policyTimeLineData}})
  }

  render() {

    return (
      <div className="cashFlowTableModalDiv d-flex align-items-center">
        <span
          className=" btn text-primary p-0 LA-btn font-size-14 font-size-md-12 font-weight-light"
          onClick={() => this.showCashFlowTableModal()}
        >
          [ View Cashflow Table ]
        </span>
        <Modal
          show={this.state.isOpen}
          backdrop="static"
          onHide={this.hideCashFlowTableModal}
          as="section"
          className="cashFlowTableModal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          keyboard={false}
        >

        <Modal.Header
          closeButton={false}
          className="pb-0 mx-md-2 border-bottom-0 cashFlow-table-header"
        >

          <div className="d-flex justify-content-between w-100">
            <div className="">
              <span className="font-size-12 font-weight-light text-primary">{this.state.needName}</span>
              <p className="font-size-16 font-weight-medium text-primary">{this.props.productName} - Cashflows</p>
            </div>


            <UiButton
              variant=""
              className="d-inline-flex align-self-start justify-content-end p-0 ml-2"
              onClick={() => this.hideCashFlowTableModal()}
            >
              <span className="mr-2 cancel-btn-style">Cancel</span>
              <Image
              className="close-icon-class"
              src={images.CrossIcon}
            />
            </UiButton>
          </div>
        </Modal.Header>



          <ModalBody className="cashFlowTableModalBody">
            <section>
             <div className="row cashFlowTableModalContent">
             {!isObjectEmpty(this.state.data.policyTimeLineData) && <PolicyTimlineTable data={this.state.data || []} />}
             </div>
            </section>
          </ModalBody>
        </Modal>
      </div>
    )
  }
}

export default CashFlowTableModal
