// React Imports
import React, { useEffect, useState } from "react"

// Package Imports
import { Image } from "react-bootstrap"
import ModalBody from "react-bootstrap/ModalBody"
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { toJS } from "mobx";


// project imports
import * as images from "constants/Images"
import { BottomsheetDropdown } from "components"
import { onSet } from "./utils"
import AuthStore from "modules/login/store/AuthStore";
import AddInsuredGrowMyMoneyController from "./controller";
import { familyMemberAgeRelationNameFunction } from "utils/familyMemberAgeRelationFunction";

const GrowMyMoneyModalForm = (props) => {
  const [formController, setFormController] = useState(undefined)
  
    useEffect(() => {
      let controller = new AddInsuredGrowMyMoneyController()
      let journeyData = toJS(AuthStore.journeyData)
      let journeyId = toJS(AuthStore.journeyId)
      controller.setDefaults(journeyData, journeyId)
      setFormController(controller)
    }, [])

    let singleFamilyMember = familyMemberAgeRelationNameFunction()

    const { hideGrowMyMoneyPopUpModal } = props

    if (formController === undefined) return null

  return (
    <div className="h-100">
      <ModalBody className="add-insured-pop-up-modal-body h-100">
        <section className="h-100">
          <article className=" add-insured-pop-up-modal-content">
            <div className="row justify-content-end">
              <span className="mr-2 cancel-btn-style">Cancel</span>
              <Image
                className="close-icon-class"
                src={images.CrossIcon}
                onClick={() => hideGrowMyMoneyPopUpModal()}
              />
            </div>

            <div className="row">
              <p className="font-weight-medium label-color">
              {singleFamilyMember.memberName.length === 0 ? `[${singleFamilyMember.relation}], ` : singleFamilyMember.memberName}
                <span className="member-age">{singleFamilyMember.age} yrs</span>
              </p>
            </div>

            <div className="row mt-5 align-items-start">
              <div className="col-12 col-md-1 mb-2 mb-md-0 font-weight-medium text-primary text-center text-lg-left pr-md-0 mt-md-1">
                {formController.$("target_amount_type").label}
              </div>

              <div className="col-12 col-md-3 mt-md-1">
                <div className="w-100">
                  <BottomsheetDropdown
                    dropdownStyle="font-size-lg-12 font-size-14 font-weight-light bg-white"
                    menuItemStyle="d-flex justify-content-between align-items-center"
                    menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                    selectedLabelKey="label"
                    bottomsheetTitle={
                      formController.$("target_amount_type").extra.bottomSheetTitle
                    }
                    menuList={
                      formController.$("target_amount_type").extra.optionList
                    }
                    value={formController.$("target_amount_type").value}
                    onSelect={formController.$("target_amount_type").onChange}
                    showErrorMsg={formController.$("target_amount_type").error !== null}
                    errorMsg={formController.$("target_amount_type").error}
                    disabled={formController.$("target_amount_type").disabled}
                  />
                </div>
              </div>

              <div className="col-12 col-md-2 mb-2 mb-md-0 font-weight-medium text-primary text-center text-lg-left pr-md-0 mt-2 mt-md-1">
                {formController.$("target_amount_dropdown").label}
              </div>

              <div className="col-12 col-md-3 mt-md-1">
                <div className="w-100">
                  <BottomsheetDropdown
                    dropdownStyle="font-size-lg-12 font-size-14 font-weight-light bg-white"
                    menuItemStyle="d-flex justify-content-between align-items-center"
                    menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                    selectedLabelKey="label"
                    bottomsheetTitle={
                      formController.$("target_amount_dropdown").extra.bottomSheetTitle
                    }
                    menuList={formController.$("target_amount_dropdown").extra.optionList}
                    value={formController.$("target_amount_dropdown").value}
                    onSelect={formController.$("target_amount_dropdown").onChange}
                    showErrorMsg={formController.$("target_amount_dropdown").error !==null}
                    errorMsg={formController.$("target_amount_dropdown").error}
                    disabled={formController.$("target_amount_dropdown").disabled}
                  />
                </div>
              </div>

              <div className="col-12 mt-4 mt-md-0 col-md-3 px-0">
                <div className="text-center">
                  <button
                    className="modal-btn font-size-14 font-weight-medium"
                    onClick={() => onSet(formController,hideGrowMyMoneyPopUpModal)}
                  >
                    Set
                  </button>
                </div>
              </div>
            </div>
          </article>
        </section>
      </ModalBody>
    </div>
  )
}

GrowMyMoneyModalForm.propTypes = {
  hideGrowMyMoneyPopUpModal:PropTypes.func.isRequired,
};

export default observer(GrowMyMoneyModalForm)
