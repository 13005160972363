
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {
  let biRequest = props.data.bi_request
  let age = biRequest.LI_ENTRY_AGE

  formRef.$('policy-term').observe({
    key: 'value',
    call: ({ field }) => {
      let currentPt = field.value
      let list = Utils.generatePPT(currentPt.id, biRequest.LI_ENTRY_AGE, formRef.$('policy-option').value.id)
     if(Number(formRef.$('policy-option').value.id) ===1){

       formRef.$('premium-term').set('extra', {
         ...formRef.$('premium-term').extra,
         options: list
       })
       formRef.$('premium-term').set('value',
         {
           id: currentPt.id,
           label: currentPt.label
         }
       )
     }
      let premium = formRef.$('premium').value
      let frequency = formRef.$('payment-frequency').value.id.toString()
      let amount = Number(premium)
      let premiumAmount = ''

      switch (frequency) {
        case '1':
          premiumAmount = '' + amount * 1
          break;
        case '2':
          premiumAmount = '' + amount * 2
          break;
        case '3':
          premiumAmount = '' + amount * 4
          break;
        case '4':
          premiumAmount = '' + amount * 12
          break;
      }

      let pr_sa = ''
      if (age <= 44) {
        let ptValue = field.value.id / 2
        pr_sa = ptValue > 10 ? ptValue * Number(premiumAmount) : 10 * Number(premiumAmount)
      } else {
        let ptValue = field.value.id / 4
        pr_sa = ptValue > 7 ? ptValue * Number(premiumAmount) : 7 * Number(premiumAmount)
      }

      let data = {
        premium: premiumAmount,
        frequency: frequency,
        sumAssured: pr_sa,
        pt: field.value.id
      }

      Utils.updateBi(keys.PR_PT, data, props.data.product_id, formRef, props)
    }
  })

  formRef.$('payment-frequency').observe({
    key: 'value',
    call: ({ field }) => {
      let premium = formRef.$('premium').value
      let frequency = field.value.id.toString()

      let amount = Number(premium)
      let premiumAmount = ''

      switch (frequency) {
        case '1':
          premiumAmount = '' + amount * 1
          break;
        case '2':
          premiumAmount = '' + amount * 2
          break;
        case '3':
          premiumAmount = '' + amount * 4
          break;
        case '4':
          premiumAmount = '' + amount * 12
          break;
      }

      let pr_sa = ''
      if (age <= 44) {
        let ptValue = formRef.$('policy-term').value.id / 2
        pr_sa = ptValue > 10 ? ptValue * Number(premiumAmount) : 10 * Number(premiumAmount)
      } else {
        let ptValue = formRef.$('policy-term').value.id / 4
        pr_sa = ptValue > 7 ? ptValue * Number(premiumAmount) : 7 * Number(premiumAmount)
      }

      let data = {
        premium: premiumAmount,
        frequency: frequency,
        sumAssured: pr_sa
      }

      Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)
    }
  })

  formRef.$('premium-term').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.PR_PPT, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('policy-option').observe({
    key: 'value',
    call: ({ field }) => {
      let ptList = Utils.generatePT(biRequest.LI_ENTRY_AGE, field.value.id)
      formRef.$('policy-term').set('extra', {
        ...formRef.$('policy-term').extra,
        options: ptList
      })

      formRef.$('policy-term').set('value', {
        id: ptList[0].id,
        label: ptList[0].label
      })

      let pptList = Utils.generatePPT(ptList[0].id, biRequest.LI_ENTRY_AGE, field.value.id)

      formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        options: pptList
      })

      formRef.$('premium-term').set('value', {
        id: ptList[0].id,
        label: ptList[0].label
      })

      let requestData = {
        pt: ptList[0].id,
        ppt: ptList[0].id,
        optionId: field.value.id
      }
      Utils.updateBi(keys.POLICY_OPTION, requestData, props.data.product_id, formRef, props)
    }
  })

  formRef.$('fund-strategy').observe({
    key: 'value',
    call: ({ field }) => {
      if (field.value.id == 1) {
        formRef.$('fund-strategy').set('extra', {
          ...formRef.$('fund-strategy').extra,
          showFundDistributionModal: true
        })
      } else {
        Utils.updateBi(keys.FUNDSTRATEGYID, field.value.id, props.data.product_id, formRef, props)
      }
    }
  })

  formRef.$('systematic-withdrawal-option').observe({
    key: 'value',
    call: ({ field }) => {
      if (field.value.id == '5') {
        let data = {
          percentage: '0.3',
          frequency: '117',
          year: '10',
          swp: field.value.id,
          optionId6: '121'
        }
        Utils.updateBi(keys.SWP_OPTION, data, props.data.product_id, formRef, props)
      } else {
        let data = {
          percentage: '',
          frequency: '',
          year: '',
          swp: field.value.id,
          optionId6: ''
        }
        Utils.updateBi(keys.SWP_OPTION, data, props.data.product_id, formRef, props)
      }
    }
  })

  formRef.$('fund-value').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.FUND_VALUE, field.value.id.toString(), props.data.product_id, formRef, props)
    }
  })

  formRef.$('swp-frequency').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.SWP_FREQUENCY, field.value.id, props.data.product_id, formRef, props)
    }
  })

  // formRef.$('withdrawal-year').observe({
  //   key: 'value',
  //   call: ({ field }) => {
  //     Utils.updateBi(keys.WITHDRAWL_YEAR, field.value.id.toString(), props.data.product_id, formRef, props)
  //   }
  // })

  formRef.$('little-champ-benefit').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.LITTLE_CHAMP, field.value.id, props.data.product_id, formRef, props)
    }
  })

}

export default FormObserver