export const PR_PPT = "PR_PPT";
export const FREQUENCY = "INPUT_MODE";
export const PREMIUM = "PR_ANNPREM";
export const ANNUITY_OPTION = "AnnuityOption"
export const SOURCE_OF_PURCHASE_PRICE = "SourceofPurchasePrice"
export const FREQUENCY_OF_ANNUITY = "FrequencyofAnnuity"
export const ARE_YOU_NOMINEE = "AreyoutheNominee"
export const DATE_OF_BIRTH_SECONDARY_ANNUITANT = "DateofBirthofSecondaryAnnuitant"
export const NAME_OF_THE_SECONDARY_ANNUITANT = "NameoftheSecondaryAnnuitant"
export const GENDER_OF_THE_SECONDARY_ANNUITANT = "GenderofSecondaryAnnuitant"
export const OSN_ANNUITANT_DATE_OF_BIRTH = 'OSNAnnuitantDateOfBirth';