import React, { Component } from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import { CustomCheckbox } from '../../../../../components';
import { InfoCircleIcon, RupeeSecondaryColorIcon } from 'constants/Images';

import '../../../../../components/ui/additional-cards/additional-cards.css';


class AdditionalCards extends Component {
    constructor(props) {
        super(props)

        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
    }

    onCheckBoxChange(checkedValue, selectedObj) {
        this.props.onClick(checkedValue, selectedObj, this.props.data);
    }

    render() {
        const { cardsList, cardTopText, checkedValue } = this.props;
        return (
            <div className='additional-cards'>
                <p className='mb-3 font-size-16 font-size-md-22 font-size-lg-24 font-weight-bold text-primary-title-label-color card-top-text'>{cardTopText}</p>
                <Card className='border-0 shadow'>
                    <Card.Body>
                        {(cardsList && cardsList.length > 0) && cardsList.map((value, index) => (
                            <Row key={index} className={index > 0 ? 'mt-3' : ''}>
                                <Col className='col-8'>
                                    <div className='d-flex align-items-center'>
                                        <CustomCheckbox shape='rounded' title={value.title} titleStyle='text-primary-title-label-color font-weight-normal card-top-title font-size-16 font-size-md-20 pl-2' isChecked={value.is_selected} onChange={(checked) => this.onCheckBoxChange(checked, value)} mark='checkmark' />
                                        <img className='mb-2 pl-2' src={InfoCircleIcon} alt='info' />
                                    </div>
                                    <p className='mb-0 text-description-text-color font-size-14 font-size-md-16 font-weight-300 sub-left-padding'>
                                        {value.description}
                                    </p>
                                </Col>
                                <Col className='align-items-center col-4 col-border-left d-flex flex-column justify-content-center col-line-height'>
                                    {
                                        value.sum_assured != '' ? <React.Fragment>
                                            <div className='d-flex justify-content-end'>
                                                <img src={RupeeSecondaryColorIcon} alt='rupee' />
                                                <p className='mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary'>{value.sum_assured}</p>
                                            </div>
                                        </React.Fragment> :
                                            <p className='mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color text-center'>
                                                Not <br /> Selected
                                            </p>
                                    }
                                </Col>
                            </Row>
                        ))}
                    </Card.Body>
                </Card>
            </div>
        )
    }
}

export default AdditionalCards
