import React from 'react';

import './pdf-viewer.css';

const ExternalPdfViewer = (props) => {

    const pdfUrl = props.match.params.url ? props.match.params.url : 'https://arxiv.org/pdf/quant-ph/0410100.pdf'

    return (
        <div className='pdf-viewer-container'>
            <iframe
                src={pdfUrl}
            />
        </div>
    )
}

export default ExternalPdfViewer;