
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {

    formRef.$('policy-term').observe({
        key: 'value',
        call: ({ field }) => {
            let currentPt = field.value
            let list = Utils.generatePPT(currentPt)
            formRef.$('premium-paying-term').set('extra', {
                ...formRef.$('premium-paying-term').extra,
                optionList: list
            })
            formRef.$('premium-paying-term').set('value', field.value)
            Utils.updateBi(keys.PR_PT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('payment-frequency').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.FREQUENCY, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('premium-paying-term').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PR_PPT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('claim-option').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PLAN_OPTION, field.value.id, props.data.product_id, formRef, props)
        }
    })

}

export default FormObserver