import ProductConstants from "constants/ProductConstants";
import ProductPackageId from "constants/ProductPackageId"

const fetchPremiumAmount = (selectedProductData, productData) => {
    
    let premiumAmount = selectedProductData?.firstYearResponse ? Math.round(selectedProductData?.firstYearResponse?.MODAL_PREM) : selectedProductData?.bi_request ? productData?.bi_request?.PR_ANNPREM : productData?.selectedPrices

    if(selectedProductData?.product_name === ProductConstants.EASY_PENSION){
        premiumAmount = Math.round(selectedProductData?.firstYearResponse?.ANN_PREM)
    }

    switch (selectedProductData?.package_id) {
        case ProductPackageId.WEALTH_ULTIMA:
        case ProductPackageId.LITTLE_CHAMP_WEALTH_ULTIMA:

            switch (selectedProductData?.bi_request?.INPUT_MODE) {
                case '1':
                    premiumAmount = premiumAmount;
                    break;
                case '2':
                    premiumAmount = premiumAmount / 2;
                    break;
                case '3':
                    premiumAmount = premiumAmount / 4;
                    break;
                case '4':
                    premiumAmount = premiumAmount / 12;
                    break;
        
                default:
                    break;
            }

            break;

        default:
            break;
    }

    return Math.round(premiumAmount)
}


export default fetchPremiumAmount