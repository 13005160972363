// React import
import React from "react"

// Package Import
import PropTypes from "prop-types"
import { Image } from "react-bootstrap"
// import ProductPremium from "./ProductPremium"
// import RiderPremium from "./RiderPremium"
import ProductPremium from "modules/choose-needs/components/total-premium/ProductPremium"
import RiderPremium from "modules/choose-needs/components/total-premium/RiderPremium"

// Project Imports
import * as images from "constants/Images"

const TotalPremium = (props) => {
  const {
    productPremiumAmount,
    productPremiumGstPercent,
    productPremiumGstAmount,
    multiplier,
    showRiderPremium,
    riderPremiumAmount,
    riderPremiumGstPercent,
    riderPremiumGstAmount,
    totalPayableAmount,
    frequencyLabel
  } = props
  return (
    <div>
      <article className="total-premium-container my-4">
        <div className="d-flex flex-column flex-lg-row align-item-center justify-content-between mb-3">
          {/* Product Premium */}
          <ProductPremium
            productPremiumAmount={productPremiumAmount}
            productPremiumGstPercent={productPremiumGstPercent}
            productPremiumGstAmount={productPremiumGstAmount}
            multiplier={multiplier}
            showRiderPremium={showRiderPremium}
            frequencyLabel={frequencyLabel}
          />

          {/* Rider Premium */}

          {showRiderPremium && (
            <RiderPremium
              riderPremiumAmount={riderPremiumAmount}
              riderPremiumGstPercent={riderPremiumGstPercent}
              riderPremiumGstAmount={riderPremiumGstAmount}
              frequencyLabel={frequencyLabel}
              multiplier={multiplier}
            />
          )}

          <div className="d-flex align-items-center justify-content-center total-premium-icon-container-width mb-3 mb-lg-0">
            <div className="d-flex flex-column mb-3 mb-lg-0">
              <Image
                width="20"
                className="mx-1"
                src={images.LateralLine}
                alt="lateral-line"
              />

              <Image
                width="20"
                className="mx-1 mt-2"
                src={images.LateralLine}
                alt="lateral-line"
              />
            </div>
          </div>

          <div
            className={`${showRiderPremium
                ? "total-premium-sub-container-width"
                : "total-premium-sub-container-width-when-no-rider"
              }  d-flex flex-column justify-content-center align-items-center align-items-lg-start`}
          >
            <div className="ml-lg-5">
              <p className="font-size-14 font-weight-medium text-primary mb-0">
                Total Amount Payable Now
              </p>
              <p className="font-size-28 font-weight-light text-center text-secondary m-0">
                {global.toINR(totalPayableAmount)}
              </p>
            </div>
          </div>
        </div>
      </article>
    </div>
  )
}

TotalPremium.propTypes = {
  productPremiumAmount: PropTypes.number.isRequired,
  productPremiumGstPercent: PropTypes.string,
  productPremiumGstAmount: PropTypes.number,
  multiplier: PropTypes.string,
  showRiderPremium: PropTypes.bool,
  riderPremiumAmount: PropTypes.number,
  riderPremiumGstPercent: PropTypes.number,
  riderPremiumGstAmount: PropTypes.number,
  totalPayableAmount: PropTypes.number.isRequired,
  frequencyLabel: PropTypes.string.isRequired
}

export default TotalPremium
