// Project Imports
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import ProductNames from "constants/ProductNames";
import ProductPackageId from "constants/ProductPackageId";
import gisUtils from "modules/optimization/components/products/GIS/utils"

const updateDeathCoverGraphSliderTargetAmount = (productData, previousProductData, targetAmount, premiumAmount) => {
  console.log("updateDeathCoverGraphSliderTargetAmount PRODUCTDATA", productData);
  let targetValue = targetAmount || 0;
  let isTargetAmountUpdated = false;
  let previousTargetValue = 0;

  let updatedPremiumAmount = premiumAmount || 0;
  let isPremiumAmountUpdated = false;

  // For Without tab products
  if (!Array.isArray(productData.BI)) {
    let currentParsedResponse = productData.firstYearResponse;
    let previousParsedResponse = previousProductData?.firstYearResponse;

    switch (productData.product_id) {
      case ProductNames.criticarePlus:
        if (currentParsedResponse.CI_SA != previousParsedResponse?.CI_SA) {
          targetValue = Math.round(currentParsedResponse.CI_SA);
          isTargetAmountUpdated = true;
        }
        if (currentParsedResponse.MODAL_PREM != previousParsedResponse?.MODAL_PREM) {
          updatedPremiumAmount = Math.round(currentParsedResponse.MODAL_PREM);
          isPremiumAmountUpdated = true;
        }
        break;

      case ProductNames.totalProtectPlus:
        if (currentParsedResponse.SUMASSURED_DEATH != previousParsedResponse?.SUMASSURED_DEATH) {
          targetValue = Math.round(currentParsedResponse.SUMASSURED_DEATH);
          isTargetAmountUpdated = true;
        }
        if (currentParsedResponse.MODAL_PREM != previousParsedResponse?.MODAL_PREM) {
          updatedPremiumAmount = Math.round(currentParsedResponse.MODAL_PREM);
          isPremiumAmountUpdated = true;
        }
        break;

      default:
        if (currentParsedResponse.SA != previousParsedResponse?.SA) {
          targetValue = Math.round(currentParsedResponse.SA);
          // if (productData.fieldChanged) {
          //   let request = {
          //     ...productData.bi_request,
          //     PR_SA: Math.round(currentParsedResponse.SA),
          //   };
          //   OptimizationStore.updateStoreRequestData(productData.product_id, request);
          // }
          isTargetAmountUpdated = true;
        }
        if (currentParsedResponse.MODAL_PREM != previousParsedResponse?.MODAL_PREM) {
          updatedPremiumAmount = Math.round(currentParsedResponse.MODAL_PREM);
          isPremiumAmountUpdated = true;
        }
        break;
    }
  }

  // For With tabs products
  else {
    // Previous props data
    let previousSelectedRiskData = previousProductData?.BI.find((item) => item.risk === previousProductData?.selectedRisk);
    let previousLastYearResponseBiJson2 = previousSelectedRiskData?.parsedResponse;

    // Current props data
    let selectedRiskData = productData.BI.find((item) => item.risk === productData.selectedRisk);
    let lastYearResponseBiJson2 = selectedRiskData?.parsedResponse;

    if (!selectedRiskData?.isValidationExist) {
      if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
        updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
        isPremiumAmountUpdated = true;
      }

      switch (selectedRiskData?.product_id) {
        case ProductNames.easyPension:
          if (lastYearResponseBiJson2?.PENSION_AMT_TVB != previousLastYearResponseBiJson2?.PENSION_AMT_TVB) {
            targetValue = Math.round(lastYearResponseBiJson2?.PENSION_AMT_TVB);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM_DISP != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM_DISP) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM_DISP);
            isPremiumAmountUpdated = true;
          }

          break;

        case ProductNames.foreverPension:
          let foreverPensionTargetAmt  = Math.round(selectedRiskData?.biResponseCompleteData.find(item => item.MODAL_ANNUITY_PAYOUT !== 0).MODAL_ANNUITY_PAYOUT)
          let foreverPensionPreviousTargetAmt  = Math.round(previousSelectedRiskData?.biResponseCompleteData.find(item => item.MODAL_ANNUITY_PAYOUT !== 0).MODAL_ANNUITY_PAYOUT)

          if (foreverPensionTargetAmt != foreverPensionPreviousTargetAmt) {
            // targetValue = Math.round(selectedRiskData?.firstYearResponse?.MODAL_ANNUITY_PAYOUT) ? Math.round(selectedRiskData?.firstYearResponse?.MODAL_ANNUITY_PAYOUT) : selectedRiskData?.bi_request?.TargetAmount;

            targetValue = foreverPensionTargetAmt

            // switch (Number(selectedRiskData?.bi_request?.AnnuityMode)) {
            //   case 22:
            //     targetValue = Math.round(targetValue / 2)
            //     break;
  
            //   case 23:
            //     targetValue = Math.round(targetValue / 4)
            //     break;
  
            //   case 24:
            //     targetValue = Math.round(targetValue / 12)
            //     break;
  
            //   default:
            //     break;
            // }
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.saralPension:
          if (selectedRiskData?.firstYearResponse?.SB_G != previousSelectedRiskData?.firstYearResponse?.SB_G) {
            targetValue = Math.round(selectedRiskData?.firstYearResponse?.SB_G);
            // switch (Number(selectedRiskData?.bi_request?.AnnuityMode)) {
            //   case 9:
            //     targetValue = Math.round(targetValue / 2)
            //     break;
  
            //   case 10:
            //     targetValue = Math.round(targetValue / 4)
            //     break;
  
            //   case 11:
            //     targetValue = Math.round(targetValue / 12)
            //     break;
  
            //   default:
            //     break;
            // }
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.wealthUltima:
          if (Number(selectedRiskData?.bi_request?.optionId3) == 5) {
            //SWP
            let payoutFrequencyValue = 1;
            if (selectedRiskData?.bi_request?.optionId5 == "117") {
              // Annual
              payoutFrequencyValue = 1;
            } else if (selectedRiskData?.bi_request?.optionId5 == "118") {
              // Semi-Annual
              payoutFrequencyValue = 2;
            } else if (selectedRiskData?.bi_request?.optionId5 == "119") {
              // Quarterly
              payoutFrequencyValue = 4;
            } else if (selectedRiskData?.bi_request?.optionId5 == "120") {
              // Monthly
              payoutFrequencyValue = 12;
            }
            if (lastYearResponseBiJson2?.TOTALPW != previousLastYearResponseBiJson2?.TOTALPW) {
              let swpStartYear = productData?.bi_request?.optionValue6 - 1;
              let swpStartYearData = selectedRiskData?.biResponseCompleteData[swpStartYear];
              targetValue = swpStartYearData?.TOTALPW ? Math.round(Math.round(swpStartYearData?.TOTALPW) / payoutFrequencyValue) : selectedRiskData?.bi_request?.TargetAmount;
              isTargetAmountUpdated = true;
            }
          } else {
            if (lastYearResponseBiJson2?.FINAL_FV != previousLastYearResponseBiJson2?.FINAL_FV) {
              targetValue = Math.round(lastYearResponseBiJson2?.FINAL_FV);
              isTargetAmountUpdated = true;
            }
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            let premium = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM)

            switch (selectedRiskData?.bi_request?.INPUT_MODE) {
              case '1':
                updatedPremiumAmount = premium
                break;

              case '2':
                updatedPremiumAmount = Math.round(premium / 2)
                break;

              case '3':
                updatedPremiumAmount = Math.round(premium / 4)
                break;

              case '4':
                updatedPremiumAmount = Math.round(premium / 12)
                break;

              default:
                break;
            }
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.premierGuranteedIncome:
          switch (selectedRiskData.package_id) {
            case ProductPackageId.PREMIER_GUARANTEED_INCOME_LUMPSUM:
            case ProductPackageId.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
              if (selectedRiskData?.parsedResponse?.MATURITY_BEN != previousSelectedRiskData?.parsedResponse?.MATURITY_BEN) {
                targetValue = Math.round(selectedRiskData?.parsedResponse.MATURITY_BEN);
                isTargetAmountUpdated = true;
              }
              if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
                updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
                isPremiumAmountUpdated = true;
              }
              break;

            case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
            case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
            case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME:
            case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
            case ProductPackageId.PREMIER_GUARANTEED_RETIREMENT_INCOME:
              targetValue = 0;
              let incomeBenefitPayoutFrequencyValue = 1;
              if (Number(selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency) == 1) {
                incomeBenefitPayoutFrequencyValue = 1;
              } else if (Number(selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency) == 2) {
                incomeBenefitPayoutFrequencyValue = 2;
              } else if (Number(selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency) == 3) {
                incomeBenefitPayoutFrequencyValue = 4;
              } else if (Number(selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency) == 4) {
                incomeBenefitPayoutFrequencyValue = 12;
              }
              selectedRiskData?.biResponseCompleteData?.forEach((item) => {
                if (Number(item.INCOME_BEN_PAY_OUT) != 0 && targetValue === 0) targetValue = Math.round(item.INCOME_BEN_PAY_OUT);
              });

              previousSelectedRiskData?.biResponseCompleteData?.forEach((item) => {
                if (Number(item.INCOME_BEN_PAY_OUT) != 0 && previousTargetValue === 0) previousTargetValue = Math.round(item.INCOME_BEN_PAY_OUT);
              });

              if (targetValue != previousTargetValue) {
                targetValue = Math.round(Math.round(targetValue) / incomeBenefitPayoutFrequencyValue);
                // targetValue = Math.round(targetValue);
                isTargetAmountUpdated = true;
              }
              if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
                updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
                isPremiumAmountUpdated = true;
              }
              break;

            default:
              targetValue = 0;
              break;
          }
          break;

        case ProductNames.activeIncomePlan:
          // if (Number(selectedRiskData?.bi_request?.IncomeOption) === 3) {
          //   targetValue = selectedRiskData?.biResponseCompleteData?.[1].TOTAL_SB_G_BS_2;
          // } else {
          //   selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
          //     if (Number(selectedRiskData?.bi_request?.PR_PPT) + 1 === index) targetValue = item.TOTAL_SB_G_BS_2;
          //   });
          // }
          selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) targetValue = item.TOTAL_SB_G_BS_2;
          });
          // if (Number(previousSelectedRiskData?.bi_request?.IncomeOption) === 3) {
          //   previousTargetValue = previousSelectedRiskData?.biResponseCompleteData?.[1].TOTAL_SB_G_BS_2;
          // } else {
          //   previousSelectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
          //     if (Number(previousSelectedRiskData?.bi_request?.PR_PPT) + 1 === index) previousTargetValue = item.TOTAL_SB_G_BS_2;
          //   });
          // }
          previousSelectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(previousSelectedRiskData?.bi_request?.PR_PPT) === index) previousTargetValue = item.TOTAL_SB_G_BS_2;
          });
          
          if (targetValue != previousTargetValue) {
            targetValue = Math.round(targetValue);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.cashFlowProtectionPlus:
          selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) targetValue = item.TOTAL_SB_BS_2;
          });

          previousSelectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(previousSelectedRiskData?.bi_request?.PR_PPT) === index) previousTargetValue = item.TOTAL_SB_BS_2;
          });

          if (targetValue != previousTargetValue) {
            targetValue = Math.round(targetValue);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.incomeBuilder:
          // if (selectedRiskData?.firstYearResponse?.MODAL_INCOME != previousSelectedRiskData?.firstYearResponse?.MODAL_INCOME) {
          //   targetValue = Math.round(selectedRiskData?.firstYearResponse.MODAL_INCOME);
          //   isTargetAmountUpdated = true;
          // }
          selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(item.PAYOUT_PERIOD) === 1) targetValue = item.MODAL_INCOME;
          });
          previousSelectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(item.PAYOUT_PERIOD) === 1) previousTargetValue = item.MODAL_INCOME;
          });
          if (targetValue != previousTargetValue) {
            // switch (selectedRiskData?.bi_request?.PayoutMode) {
            //   case '17':
            //     targetValue = Math.round(targetValue / 2)
            //     break;

            //   case '18':
            //     targetValue = Math.round(targetValue / 4)
            //     break;

            //   case '19':
            //     targetValue = Math.round(targetValue / 12)
            //     break;

            //   default:
            //     break;
            // }
            targetValue = Math.round(targetValue);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.guaranteedSavingsStar:
          if (Number(selectedRiskData?.bi_request?.MaturityBenefitOption) === 1) {
            targetValue = Math.round(selectedRiskData?.parsedResponse?.MB_G);
          } else {
            let maturityBenefit = 0;
            selectedRiskData?.biResponseCompleteData?.forEach((item) => {
              if (Number(item.MB_G) != 0 && maturityBenefit === 0) maturityBenefit = Math.round(item.MB_G);
            });
            targetValue = maturityBenefit;
          }

          if (Number(previousSelectedRiskData?.bi_request?.MaturityBenefitOption) === 1) {
            previousTargetValue = Math.round(previousSelectedRiskData?.parsedResponse?.MB_G);
          } else {
            let maturityBenefit = 0;
            previousSelectedRiskData?.biResponseCompleteData?.forEach((item) => {
              if (Number(item.MB_G) != 0 && maturityBenefit === 0) maturityBenefit = Math.round(item.MB_G);
            });
            previousTargetValue = maturityBenefit;
          }

          if (targetValue != previousTargetValue) {
            targetValue = Math.round(targetValue);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.guaranteedIncomeStar:
          let gisTargetAmount = 0
          let gisPreviousTargetAmount = 0
          if (Number(selectedRiskData?.bi_request?.IncomeOption) === 6) {
            selectedRiskData?.biResponseCompleteData?.forEach((item) => {
              if (Number(item.INCOME_BENEFIT_FINAL) != 0 && gisTargetAmount === 0) gisTargetAmount = Math.round(item.INCOME_BENEFIT_FINAL);
            });

            previousSelectedRiskData?.biResponseCompleteData?.forEach((item) => {
              if (Number(item.INCOME_BENEFIT_FINAL) != 0 && gisPreviousTargetAmount === 0) gisPreviousTargetAmount = Math.round(item.INCOME_BENEFIT_FINAL);
            });
          } else {
            selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
              if (Number(selectedRiskData?.bi_request?.PR_PPT) < index && Number(item.INCOME_BENEFIT_FINAL) != 0 && gisTargetAmount === 0) gisTargetAmount = Math.round(item.INCOME_BENEFIT_FINAL)
            });

            previousSelectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
              if (Number(previousSelectedRiskData?.bi_request?.PR_PPT) < index && Number(item.INCOME_BENEFIT_FINAL) != 0 && gisPreviousTargetAmount === 0) gisPreviousTargetAmount = Math.round(item.INCOME_BENEFIT_FINAL);
            });
          }
          // let incomeStartOption = gisUtils.fetchIncomeStartOptionValue(selectedRiskData?.bi_request?.IncomeStartOption)
          // let previousincomeStartOption = gisUtils.fetchIncomeStartOptionValue(previousSelectedRiskData?.bi_request?.IncomeStartOption)
          // targetValue = selectedRiskData?.biResponseCompleteData && Math.round(selectedRiskData?.biResponseCompleteData[(Number(selectedRiskData?.bi_request?.PR_PPT))]?.INCOME_BENEFIT_FINAL);
          // previousTargetValue = previousSelectedRiskData?.biResponseCompleteData && Math.round(previousSelectedRiskData?.biResponseCompleteData[(Number(previousSelectedRiskData?.bi_request?.PR_PPT))]?.INCOME_BENEFIT_FINAL);
          // selectedRiskData?.biResponseCompleteData?.forEach((item) => {
          //   if (Number(item.INCOME_BENEFIT_FINAL) != 0 && gisTargetAmount === 0) gisTargetAmount = Math.round(item.INCOME_BENEFIT_FINAL);
          // });

          // previousSelectedRiskData?.biResponseCompleteData?.forEach((item) => {
          //   if (Number(item.INCOME_BENEFIT_FINAL) != 0 && gisPreviousTargetAmount === 0) gisPreviousTargetAmount = Math.round(item.INCOME_BENEFIT_FINAL);
          // });
          targetValue = gisTargetAmount
          previousTargetValue = gisPreviousTargetAmount

          if (targetValue != previousTargetValue) {
            switch (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency) {
              case '33':
                targetValue = targetValue / 2
                break;

              case '34':
                targetValue = targetValue / 4
                break;

              case '35':
                targetValue = targetValue / 12
                break;

              default:
                break;
            }
            targetValue = Math.round(targetValue);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.GCAP:
          if (lastYearResponseBiJson2?.MATURITY != previousLastYearResponseBiJson2?.MATURITY) {
            targetValue = Math.round(lastYearResponseBiJson2?.MATURITY);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.dhanLabh:
          if (lastYearResponseBiJson2?.TOTAL_BEN != previousLastYearResponseBiJson2?.TOTAL_BEN) {
            targetValue = Math.round(lastYearResponseBiJson2?.TOTAL_BEN);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.smartLifestyle:
          if (lastYearResponseBiJson2?.SB_NG_DIS_BS_2 != previousLastYearResponseBiJson2?.SB_NG_DIS_BS_2) {
            targetValue = Math.round(lastYearResponseBiJson2?.SB_NG_DIS_BS_2);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.singlePayEndowmentAssuarancePlan:
          if (lastYearResponseBiJson2?.SB_G != previousLastYearResponseBiJson2?.SB_G) {
            targetValue = Math.round(lastYearResponseBiJson2?.SB_G);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.wealthPlus:
          if (lastYearResponseBiJson2?.FINAL_FV != previousLastYearResponseBiJson2?.FINAL_FV) {
            targetValue = Math.round(lastYearResponseBiJson2?.FINAL_FV);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.PREMIUM != previousSelectedRiskData?.firstYearResponse?.PREMIUM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.PREMIUM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.wealthPremier:
          if (lastYearResponseBiJson2?.FINAL_FV != previousLastYearResponseBiJson2?.FINAL_FV) {
            targetValue = Math.round(lastYearResponseBiJson2?.FINAL_FV);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.posSaralNivesh:
          if (lastYearResponseBiJson2?.SB_G != previousLastYearResponseBiJson2?.SB_G) {
            targetValue = Math.round(lastYearResponseBiJson2?.SB_G);
            isTargetAmountUpdated = true;
          }
          if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
            updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
            isPremiumAmountUpdated = true;
          }
          break;

        case ProductNames.flexiSavingsPlan:
          switch (selectedRiskData.package_id) {
            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
              
              let fspFlexiIncomeTargetValue = 0;
              let fspFlexiIncomePreviousTargetValue = 0;
              selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) fspFlexiIncomeTargetValue = Math.round(item.TOTAL_SB_PAYOUT_BS_2);
              });
              previousSelectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                if (Number(previousSelectedRiskData?.bi_request?.PR_PPT) === index) fspFlexiIncomePreviousTargetValue = Math.round(item.TOTAL_SB_PAYOUT_BS_2);
              });
              targetValue = fspFlexiIncomeTargetValue;
              previousTargetValue = fspFlexiIncomePreviousTargetValue;
              if (targetValue != previousTargetValue) {
                targetValue = Math.round(targetValue);
                isTargetAmountUpdated = true;
              }
              if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
                updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
                isPremiumAmountUpdated = true;
              }
              break

            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
              let fspProWithoutAccrualTargetAmtPostPPT = 0
              if (Number(selectedRiskData?.bi_request?.AccrualOfSurvivalBenefits) === 12) {
                selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                  if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) targetValue = Math.round(item.TOTAL_SB_PAYOUT_BS_2);
                });
                previousSelectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                  if (Number(previousSelectedRiskData?.bi_request?.PR_PPT) === index) previousTargetValue = Math.round(item.TOTAL_SB_PAYOUT_BS_2);
                });
              } else {
                selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                  if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) targetValue = Math.round(item.TOTAL_SB_POST_PPT_BS_2);
                });
                previousSelectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                  if (Number(previousSelectedRiskData?.bi_request?.PR_PPT) === index) previousTargetValue = Math.round(item.TOTAL_SB_POST_PPT_BS_2);
                });
              }
              if (targetValue != previousTargetValue) {
                targetValue = Math.round(targetValue);
                isTargetAmountUpdated = true;
              }
              if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
                updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
                isPremiumAmountUpdated = true;
              }
              break

            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
              selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                if (Number(selectedRiskData?.bi_request?.PR_PPT) === index) targetValue = Math.round(item.TOTAL_SB_POST_PPT_BS_2);
              });
              previousSelectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                if (Number(previousSelectedRiskData?.bi_request?.PR_PPT) === index) previousTargetValue = Math.round(item.TOTAL_SB_POST_PPT_BS_2);
              });
              if (targetValue != previousTargetValue) {
                targetValue = Math.round(targetValue);
                isTargetAmountUpdated = true;
              }
              if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
                updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
                isPremiumAmountUpdated = true;
              }
              break

            case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
            case ProductPackageId.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
              let targetAmtFinal = 0;
              let previousTargetAmtFinal = 0;
              selectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                if ((Number(selectedRiskData?.bi_request?.PR_PT)-1) === index) {
                  targetAmtFinal = Math.round(item.MATURITY_BENEFIT_BS_2);
                }
              });
              targetValue = targetAmtFinal;
              previousSelectedRiskData?.biResponseCompleteData?.forEach((item, index) => {
                if ((Number(previousSelectedRiskData?.bi_request?.PR_PT)-1) === index) {
                  previousTargetAmtFinal = Math.round(item.MATURITY_BENEFIT_BS_2);
                }
              });
              previousTargetValue = previousTargetAmtFinal;

              if (targetValue != previousTargetValue) {
                targetValue = Math.round(targetAmtFinal);
                isTargetAmountUpdated = true;
              }
              if (selectedRiskData?.firstYearResponse?.MODAL_PREM != previousSelectedRiskData?.firstYearResponse?.MODAL_PREM) {
                updatedPremiumAmount = Math.round(selectedRiskData?.firstYearResponse?.MODAL_PREM);
                isPremiumAmountUpdated = true;
              }
              break;

            default:
              break;
          }
          break;

        default:
          break;
      }
    }
  }

  return {
    targetValue: targetValue,
    isTargetAmountUpdated: isTargetAmountUpdated,
    premiumAmount: updatedPremiumAmount,
    isPremiumAmountUpdated: isPremiumAmountUpdated,
  };
};

export default updateDeathCoverGraphSliderTargetAmount;
