const { Form } = require("mobx-react-form");

import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

import setInitialDefaults from './initialDefaults';
import formObserver from './observers'
import Utils from "./utils";
import * as keys from "./keys";
import * as Constants from 'constants/Constants'


class SinglePayEndowmentAssurancePlanFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "5",
                label: "Single",
              },
            ],
          },
        },
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          disabled: false,
          value: "",
          extra: {
            optionList: [
              {
                id: "10",
                label: "10",
              },
              {
                id: "15",
                label: "15",
              },
            ],
          },
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremiun(form, data) {
              // let value = form.$('premium').value;
              // Utils.updateBi(keys.PREMIUM, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "premium-term",
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            optionList: [
              {
                id: 1,
                label: 1,
              },
            ],
          },
        },
        {
          name: "death-benefit-option",
          label: "Death Benefit Option",
          placeholder: "",
          extra: {
            optionList: [
              {
                id: 1,
                label: 'Option A',
              },
              {
                id: 2,
                label: 'Option B',
              },
            ],
          },
        },
        {
          name: "ADB",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.ADB_RIDER_DESCRIPTION,
            paramName: "ADBRider",
            rider_name: "ADB Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ADB").value;
              form.$("ADB").value = !value;

              if (form.$("ADB").value) {
                form
                  .$("ADB-dropdown")
                  .set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("ADB-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "ADB-dropdown",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountList(),
            onBlur(form, data) {
              let value = form.$("ADB-dropdown").value
              Utils.updateBi(keys.ADB_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "ATPDRider",
          label: "Permanent Disability",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.ATPD_RIDER_DESCRIPTION,
            paramName: "ATPDRider",
            rider_name: "ATPD Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ATPDRider").value;
              form.$("ATPDRider").value = !value;

              if (form.$("ATPDRider").value) {
                form
                  .$("ATPDRider-dropdown")
                  .set(
                    "value",
                    Constants.RIDERS_DEFAULT_SA
                  );
              } else {
                form.$("ATPDRider-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "CriticalIllnessRider",
          label: "Critical Illness",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.CRITICAL_ILLNESS_RIDER_DESCRIPTION,
            paramName: "CriticalIllnessRider",
            rider_name: "Critical Illness Rider",
            changeRiderStatus(form, data) {
              let value = form.$("CriticalIllnessRider").value;
              form.$("CriticalIllnessRider").value = !value;

              if (form.$("CriticalIllnessRider").value) {
                form
                  .$("CriticalIllnessRider-dropdown")
                  .set(
                    "value",
                    Constants.RIDERS_DEFAULT_SA
                  );
              } else {
                form.$("CriticalIllnessRider-dropdown").set("value", "");
              }
            },
          },
        },

        {
          name: "IncomeBenefitRider",
          label: "Income Benefit",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.INCOME_BENEFIT_RIDER_DESCRIPTION,
            paramName: "IncomeBenefitRider",
            rider_name: "Income Benefit Rider",
            changeRiderStatus(form, data) {
              let value = form.$("IncomeBenefitRider").value;
              form.$("IncomeBenefitRider").value = !value;

              if (form.$("IncomeBenefitRider").value) {
                form
                  .$("IncomeBenefitRider-dropdown")
                  .set(
                    "value",
                    Constants.RIDERS_DEFAULT_SA
                  );
              } else {
                form.$("IncomeBenefitRider-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "ATPDRider-dropdown",
          label: "Permanent Disability",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountList(),
            onBlur(form, data) {
              let value = form.$("ATPDRider-dropdown").value
              Utils.updateBi(keys.ATPD_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "CriticalIllnessRider-dropdown",
          label: "Critical Illness Rider",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountListForCLRnHCB(),
            onBlur(form, data) {
              let value = form.$("CriticalIllnessRider-dropdown").value
              Utils.updateBi(keys.CRITICAL_ILLNESS_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "IncomeBenefitRider-dropdown",
          label: "Income Benefit Rider",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountListForCLRnHCB(),
            onBlur(form, data) {
              let value = form.$("IncomeBenefitRider-dropdown").value
              Utils.updateBi(keys.INCOME_BENEFIT_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data);
  }
}

export default SinglePayEndowmentAssurancePlanFormController;
