// Package Imports
import { toJS } from "mobx"
import moment from "moment"

// Project Imports
import AuthStore from "modules/login/store/AuthStore"
import OptimizationStore from "modules/optimization/store/OptimizationStore"
import FamilyDetailsStore from "modules/family-details-form/store/FamilyDetailsStore"

export const familyMemberAgeRelationNameFunction = () => {
  let familyMemberList = toJS(AuthStore.journeyData).family_details_list
  let SelectedfamilyMember = familyMemberList.find(
    (singleMember) =>
      singleMember.id === OptimizationStore.selectedFamilyMemberId
  )

  if (SelectedfamilyMember === undefined)
    return { relation: "Relation", memberName: "Name,", age: "Age," }

  let {
    family_member_type: relation,
    dob,
  } = SelectedfamilyMember

 
  let calculatedAge = moment().diff(dob, "years")

  if(isNaN(calculatedAge)){
   calculatedAge = ""
  }

  let title = FamilyDetailsStore.salutationList.find(singleTitle => singleTitle.id === SelectedfamilyMember.salutation).label
  let fName = SelectedfamilyMember.first_name !== null ? SelectedfamilyMember.first_name : ""
  let mName = SelectedfamilyMember.middle_name !== null ? SelectedfamilyMember.middle_name : ""
  let lName = SelectedfamilyMember.last_name !== null ? SelectedfamilyMember.last_name : ""
  
  let memberName = `${fName}${mName}${lName}` 

  if(memberName !== ''){
    memberName = `${title} ${fName} ${mName} ${lName}, `
  }

  relation = relation.split('_').join(' ')

  return {
    relation,
    memberName: memberName,
    age: calculatedAge
  }
}
