import React, { Component } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import { CustomCheckbox } from '../../../../components'

import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import PropTypes from 'prop-types';
import moment from 'moment';

import './add-insured.css'

import FamilyDetailsStore from '../../../family-details-form/store/FamilyDetailsStore';
import FamilyProtectionStore from '../../store/FamilyProtectionStore';
import { PlusIconFilledIcon } from 'constants/Images';

const AddInsuredModal = observer(class AddInsuredModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedMembers: {}
    }
  }
  // multiselect option
  onAdditionalCardOptionSelect = (checkedValue, selectedObj) => {
    const selectedMembers = this.state.selectedMembers;

    selectedMembers[selectedObj.id] = checkedValue;

    this.setState({
      selectedMembers: selectedMembers
    });
  }
  onAddInsured = () => {
    const { cardKey } = this.props;
    FamilyProtectionStore.updateMembersFromNeeds(cardKey, this.state.selectedMembers)
    this.props.onAddInsured()
  }

  onExited = () => {
    // console.log('ON EXITED >> ')
  }

  updateSelectedMembers = () => {
    const { cardKey } = this.props;
    let productIndex = toJS(FamilyProtectionStore.product_list_data).findIndex(obj => obj.need_unique_name === cardKey)

    let productObj = toJS(FamilyProtectionStore.product_list_data)[productIndex]

    let alreadyAddedMembers = {}
    productObj.data.map(member => alreadyAddedMembers[member.id] = true)

    console.log('GET FINAL FAMILY MEMBER LIST >> ', alreadyAddedMembers)

    this.setState({ selectedMembers: alreadyAddedMembers })
  }

  componentDidMount() {
    this.updateSelectedMembers()
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.selectedData) != JSON.stringify(this.state.selectedData)) {
      this.updateSelectedMembers()
    }
  }

  render() {
    const { selectedMembers } = this.state;
    let { show, onAddMember, onHide } = this.props;
    // const familyMembers = this.getFinalFamilyMemberList()
    const familyMembers = toJS(FamilyDetailsStore.familyMembers)
    // console.log('ADD INSURED MODAL RENDER >> ', familyMembers)
    return (
      <div>
        {/* <Button variant='light' onClick={() => { this.handleAddInsuredModalOpen() }} role='button' className='title-button rounded-pill px-3 py-1'>
          <img color='red' className='mr-2' src={require('../../assets/images/icons/plus.svg')} />
          Add Insured
        </Button> */}
        <Modal
          className='add-insured-modal left-bottom-swipe-modal'
          show={show}
          onHide={onHide}
          onExited={this.onExited}
          // backdrop='static'
          keyboard={false}
        // animation={false}
        >
          <Modal.Header closeButton className='border-0 shadow-sm'>
            <Modal.Title className='font-size-md-18 font-size-16 text-primary-title-label-color'>
              Select Members
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className=''>
            {familyMembers.filter(member => member.isSelected).map((value, index) => (
              <Card key={index} className='mb-3 rounded-lg add-insuered-member-detail-card'>
                <Card.Body className='p-0'>
                  <CustomCheckbox
                    id={value.id}
                    shape='rounded'
                    title={`${value.typeLabel}, (${value.name}), (${value.gender == '' ? '' : value.gender}), (${value.dob == '' ? value.dob : moment(value.dob).format('DD-MM-YYYY') !== 'Invalid date' ? moment(value.dob).format('DD-MM-YYYY') : value.dob} )`}
                    containerStyle='mb-0 p-3 w-100'
                    titleStyle='text-primary-title-label-color font-weight-normal font-size-16 pl-2'
                    isChecked={selectedMembers[value.id] || false}
                    mark='checkmark'
                    onChange={(isChecked) => this.onAdditionalCardOptionSelect(isChecked, value)}
                  />
                </Card.Body>
              </Card>

            ))}
            <Button variant='' onClick={onAddMember} role='button' className='text-scondary w-100 text-center px-3 py-2 modal-add-member-btn font-size-16'>
              <img color='red' className='mr-2' src={PlusIconFilledIcon} alt='plus-icon' />
                ADD/EDIT MEMBER
              </Button>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='w-100 font-size-18 m-0' onClick={this.onAddInsured}>
              Add Insured
          </Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
});

AddInsuredModal.defaultsProps = {
  onHide: () => { }
}

AddInsuredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onAddMember: PropTypes.func.isRequired,
  onAddInsured: PropTypes.func.isRequired
}

export default AddInsuredModal
