import Utils from "./utils";
import * as keys from "./keys";

const FormObserver = (formRef, props) => {
  formRef.$("policy-term").observe({
    key: "value",
    call: ({ field }) => {

      const pptList = Utils.getPremiumTermList(field.value.id)

      formRef.$("premium-term").set("extra", {
        ...formRef.$("premium-term").extra,
        optionList: pptList,
      });

      formRef.$("premium-term").set("value", {
        id: field.value.id,
        label: field.value.id,
      });

      Utils.updateBi(
        keys.PR_PPT,
        field.value.id.toString(),
        props.data.product_id,
        formRef,
        props
      );

      Utils.updateBi(
        keys.PR_PT,
        field.value.id.toString(),
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("premium-term").observe({
    key: "value",
    call: ({ field }) => {

      const pptValue = field.value.id.toString()

      console.log('pptValueASDSDSADSA',pptValue);

      if(pptValue === '1'){
        formRef.$("payment-frequency").set("value", {
          id: "5",
          label: "Single",
        });

        formRef.$("payment-frequency").set("disabled", true);
      }else{
        formRef.$("payment-frequency").set("disabled", false);

        formRef.$("payment-frequency").set("value",               {
          id: "1",
          label: "Annual",
        });
      }


      Utils.updateBi(
        keys.PR_PPT,
        field.value.id.toString(),
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("annuity-option").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.ANNUITY_OPTION,
        field.value.id.toString(),
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("fund-strategy").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.FUNDSTRATEGY,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("payment-frequency").observe({
    key: "value",
    call: ({ field }) => {
      let premium = formRef.$("premium").value;
      let frequency = Number(field.value.id);

      let amount = Number(premium);
      let premiumAmount = "";
      switch (frequency) {
        case 1:
        case 5:
          premiumAmount = "" + amount * 1;
          break;
        case 2:
          premiumAmount = "" + amount * 2;
          break;
        case 3:
          premiumAmount = "" + amount * 4;
          break;
        case 4:
          premiumAmount = "" + amount * 12;
          break;
      }
      let data = {
        premium: premiumAmount,
        frequency: frequency,
      };
      Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props);
    },
  });
};

export default FormObserver;
