
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {


    formRef.$('plan-option').observe({
        key: 'value',
        call: ({ field }) => {   
            
           if(field.value.id === '2'){
            formRef.$('family-income-benefit').set('value',{label:'No',id: '3'})
           } 

           Utils.pptOptionListFunction(formRef,props.data.product_name,field.value.id) 

           Utils.updateBi(keys.PLAN_OPTION, field.value.id, props.data.product_id, formRef, props)

           // updating ppt value when updating plan-options
           let pptValue = formRef.$("PPT").value.id
           Utils.updateBi(keys.PPT, pptValue, props.data.product_id, formRef, props)

        }
    })

    formRef.$('PPT').observe({
        key: 'value',
        call: ({ field }) => {
         Utils.ptLogicBasedOnppt(props.data.product_name,formRef, field.value.id)   
         Utils.updateBi(keys.PPT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('PT').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('payment-frequency').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.FREQUENCY, field.value.id, props.data.product_id, formRef, props)
        }
    })

    // formRef.$('lump-sum-benefit').observe({
    //     key: 'value',
    //     call: ({ field }) => {
    //         Utils.updateBi(keys.LUMPSUM_BENEFIT, field.value.id, props.data.product_id, formRef, props)
    //     }
    // })

    formRef.$('family-income-benefit').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.FAMILY_BENEFIT_INCOME_OPTION, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('is-existing-Customer').observe({
        key: 'value',
        call: ({ field }) => {

            if(field.value.id === '2'){
                formRef.$('policy-no').set('extra', {
                    ...formRef.$('policy-no').extra,
                    isVisible:false
                   })
            }else{
                formRef.$('policy-no').set('extra', {
                    ...formRef.$('policy-no').extra,
                    isVisible:true
                   })
            }

            Utils.updateBi(keys.IS_EXISTING_CUSTOMER, field.value.id, props.data.product_id, formRef, props)
        }
    })

//     formRef.$('income-benefit-pay-out-frequency').observe({
//      key: 'value',
//      call: ({ field }) => {
//          Utils.updateBi(keys.INCOME_BENEFIT_PAYOUT_FREQUENCY, field.value.id, props.data.product_id, formRef, props)
//      }
//  })

//  formRef.$('income-benefit-pay-out-type').observe({
//   key: 'value',
//   call: ({ field }) => {
//       Utils.updateBi(keys.INCOME_BENEFIT_PAYOUT_TYPE, field.value.id, props.data.product_id, formRef, props)
//   }
// })

// formRef.$('income-benefit-payout-duration').observe({
//  key: 'value',
//  call: ({ field }) => {
//     Utils.prefillPtBasedOnpptAndIncomeBenefitPayoutDuration(formRef, props.productName)
//      Utils.updateBi(keys.INCOME_BENEFIT_DURATION, field.value.id, props.data.product_id, formRef, props)
//  }
// })


    formRef.$('ADB').observe({
        key: 'value',
        call: ({ field }) => {
            let adbValue = formRef.$('ADB-dropdown').value
            Utils.updateBi(keys.ADB_RIDER, adbValue, props.data.product_id, formRef, props)
        }
    })

    formRef.$('ATPDRider').observe({
        key: 'value',
        call: ({ field }) => {
            let atpdValue = formRef.$('ATPDRider-dropdown').value
            Utils.updateBi(keys.ATPD_RIDER, atpdValue, props.data.product_id, formRef, props)
        }
    })

    formRef.$('CriticalIllnessRider').observe({
        key: 'value',
        call: ({ field }) => {
            let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
            Utils.updateBi(keys.Critical_ILLNESS_RIDER, criticalIllness, props.data.product_id, formRef, props)
        }
    })


   formRef.$('HospitalityCashBenefitRider').observe({
    key: 'value',
    call: ({ field }) => {
    let hospitalityCashBenefit = formRef.$('HospitalityCashBenefitRider-dropdown').value
    Utils.updateBi(keys.HOSPITALITY_CASH_BENEFIT_RIDER, hospitalityCashBenefit, props.data.product_id, formRef, props)
    }
   })

    formRef.$('PayorWaiverBenefitRider-dropdown').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PWB_RIDER, field.value.id, props.data.product_id, formRef, props)
        }
    })

}

export default FormObserver