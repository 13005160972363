import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import './button.css';

class UiButton extends Component {
  render() {
    const { buttonText, ...prop } = this.props;
    return (
      <Button type='button' {...prop}>
        {/* {buttonText || 'Button'} */}
        {this.props.children ? this.props.children : buttonText || 'Button'}
      </Button>
    );
  }
}

export default UiButton;
