// Package Imports
const { Form } = require("mobx-react-form");
import yup from "mobx-react-form/lib/validators/YUP";
import vjf from "mobx-react-form/lib/validators/VJF";
import * as $pkg from "yup";
import validator from "validator";

// Project Imports
import formObserver from "./formObserver";
import { initialDefaults, setSpRMFieldValues } from "./initialDefaults";
import { validateEmployeeCode, validateExistingCustomerValues,validatePinCode } from "./validation";
import AuthStore from "modules/login/store/AuthStore";

class PreNeedFormController extends Form {
  plugins() {
    return {
      vjf: vjf(validator),
      yup: yup({
        package: $pkg,
        schema: (_yup) =>
          _yup.object().shape({
            journey_id: _yup.string().optional(),
            journey_type: _yup.object().shape({
              id: _yup.mixed().required("This field is mandatory"),
            }),
            // location: _yup.array().min(1, "This field is mandatory").required(),
            location: _yup.array().optional(),
            customer_type: _yup.string().required(),
            existing_customer_type_option_list: _yup.object().shape({
              id: _yup.mixed().optional(),
            }),

            existing_customer_value: _yup.string().optional(),
            category: _yup.object().shape({
              id: _yup.mixed().required("This field is mandatory"),
            }),
            employee_code: _yup.string().optional(),
            occupation: _yup.object().shape({
              id: _yup.mixed().required("This field is mandatory"),
            }),
            education: _yup.object().shape({
              id: _yup.mixed().required("This field is mandatory"),
            }),
            pfa_sp_code: _yup.string().optional(),
            pfa_sp_name: _yup.string().optional(),
            channel_name: _yup.string().optional(),
            sales_branch: _yup.string().optional(),
            dmrm_code: _yup.string().optional(),
            dmrm_name: _yup.string().optional(),
            partner_rm_code: _yup.string().optional(),
            partner_branch_name: _yup.string().optional(),
            is_sp_code_editable: _yup.mixed().optional(),
            proceed_btn: _yup.mixed().optional(),
            ulip_training_code: _yup.mixed().optional(),
            posp_code: _yup.mixed().optional(),
          }),
      }),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "journey_id",
          type: "hidden",
        },
        {
          name: "journey_type",
          label: "Set Customer Type",
          placeholder: "",
          disabled: false,
          value: {
            id: 1,
            label: "Individual (& Family)",
            status: true,
          },
          extra: {
            optionList: [],
            bottomSheetTitle: "Select Customer Type",
          },
        },
        {
          name: "location",
          label: "Location",
          placeholder: "Enter PIN Code",
          value: [],
          extra: {
            optionList: [],
          },
          validators: [validatePinCode],
        },
        {
          name: "customer_type",
          label: "",
          placeholder: "",
          disabled: true,
          value: "New Customer",
          extra: {
            optionList: [
              {
                id: "1",
                label: "New Customer",
                value: "New Customer",
              },
              // {
              //   id: "2",
              //   label: "Existing Customer",
              //   value: "Existing Customer",
              // },
            ],
          },
        },
        {
          name: "existing_customer_type_option_list",
          label: "",
          type: "number",
          placeholder: "Enter Policy No",
          disabled: false,
          value: {
            id: "4",
            label: "Policy No.",
          },
          extra: {
            optionList: [
              {
                id: "1",
                label: "Mobile No.",
              },
              {
                id: "2",
                label: "PAN No.",
              },
              {
                id: "3",
                label: "Customer ID",
              },
              {
                id: "4",
                label: "Policy No.",
              },
            ],
            bottomSheetTitle: "Select an Option",
          },
        },
        {
          name: "existing_customer_value",
          label: "Enter Policy No",
          disabled: false,
          value: "",
          extra: {},
          validators: [validateExistingCustomerValues],
        },
        {
          name: "category",
          label: "Category",
          placeholder: "",
          disabled: false,
          value: {},
          extra: {
            selectedOptionLabelKey: "label",
            optionList: [],
            bottomSheetTitle: "Select Category",
          },
        },
        {
          name: "employee_code",
          label: "Enter Employee Code",
          placeholder: "Enter Employee Code",
          disabled: false,
          value: "",
          validators: [validateEmployeeCode],
        },
        {
          name: "occupation",
          label: "Individual's Occupation",
          placeholder: "",
          extra: {
            selectedOptionLabelKey: "label",
            bottomSheetTitle: "Select Occupation",
            optionList: [],
          },
        },
        {
          name: "education",
          label: "Individual Education",
          placeholder: "",
          extra: {
            selectedOptionLabelKey: "label",
            bottomSheetTitle: "Select Education",
            optionList: [],
          },
        },
        {
          name: "pfa_sp_code",
          label: "PFA/SP Code",
          placeholder: "",
          disabled: false,
          value: "",
          extra: {
            showBtn: true,
          },
        },
        {
          name: "pfa_sp_name",
          label: "PFA/SP Name",
          placeholder: "",
          disabled: true,
          value: "",
          extra: {
            showBtn: false,
          },
        },
        {
          name: "channel_name",
          label: "Channel Name",
          placeholder: "",
          disabled: true,
          value: "",
          extra: {
            showBtn: false,
          },
        },
        {
          name: "sales_branch",
          label: "Sales Branch",
          placeholder: "",
          disabled: true,
          value: "",
          extra: {
            showBtn: false,
          },
        },
        {
          name: "dmrm_code",
          label: "DM/RM Code",
          placeholder: "",
          disabled: false,
          value: "",
          extra: {
            showBtn: true,
          },
        },
        {
          name: "dmrm_name",
          label: "DM/RM Name",
          placeholder: "",
          disabled: false,
          value: "",
          extra: {
            showBtn: false,
          },
        },
        {
          name: "partner_rm_code",
          label: "Partner RM Code",
          placeholder: "",
          disabled: false,
          value: "",
          extra: {
            showBtn: true,
            isVisible: false,
          },
        },
        {
          name: "partner_branch_name",
          label: "Partner Branch Name",
          placeholder: "",
          disabled: true,
          showBtn: false,
          value: "",
          extra: {
            showBtn: false,
          },
        },
        {
          name: "is_sp_code_editable",
          label: "",
        },
        {
          name: "proceed_btn",
          disabled: false,
        },
        {
          name: "ulip_training_code",
          label: ""
        },
        {
          name: "posp_code",
          label: ""
        },
      ],
    };
  }

  setDefaults(journeyData, journeyId) {
    initialDefaults(this, journeyData, journeyId);
    // Observer fields
    formObserver(this);
  }

  setSpRMValue(pragatiData) {
    console.log("Pragati data is ", pragatiData);
    AuthStore.setAgentdetails(pragatiData);
    setSpRMFieldValues(this, pragatiData);
  }
}

export default PreNeedFormController;
