// Project Imports
import NeedTypeEnum from "constants/NeedTypeEnum"

const fetchPremiumLabelBasedOnNeed = (need) => { 
         
     switch(need){
         case NeedTypeEnum.GROW_MY_MONEY:
         case NeedTypeEnum.SAVE_FOR_CHILDREN:
         case NeedTypeEnum.PROTECTION_FOR_MY_HEALTH: 
             return "Target Premium"
         break
       
         case NeedTypeEnum.SAVE_FOR_RETIREMENT:
         case NeedTypeEnum.PROTECTION_FOR_MY_FAMILY: 
             return "Recommended Premium"
         break
     }
}

export default fetchPremiumLabelBasedOnNeed