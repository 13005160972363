import React from 'react';

import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';

const propTypes = {
  onChangePage: PropTypes.func.isRequired,
  totalItemCount: PropTypes.number,
  initialPage: PropTypes.number,
  pageSize: PropTypes.number,
  selectedPage: PropTypes.number
};

const defaultProps = {
  initialPage: 1,
  pageSize: 5
};

class PaginationComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { pager: {} };
  }

  componentDidMount() {
    if (this.props.totalItemCount) {
      this.setPage(parseInt(this.props.selectedPage));
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedPage !== prevProps.selectedPage) {
      this.setPageChange(parseInt(this.props.selectedPage));
    }
  }
  setPage(page) {
    var { pageSize } = this.props;
    var pager = this.state.pager;

    if (page < 1 || page > pager.totalPages) {
      return;
    }

    pager = this.getPager(this.props.totalItemCount, page, pageSize);

    this.setState({ pager: pager });
  }

  setPageChange(page) {
    this.setPage(page);
    this.props.onChangePage(page);
  }

  getPager(totalItems, currentPage, pageSize) {
    currentPage = currentPage || 1;

    var totalPages = Math.ceil(totalItems / pageSize);

    var startPage, endPage;
    if (totalPages <= 10) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    var startIndex = (currentPage - 1) * pageSize;
    var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    var pages = [...Array(endPage + 1 - startPage).keys()].map(
      i => startPage + i
    );

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }

  render() {
    var pager = this.state.pager;
    // console.log('PAGER >> ', pager);
    if (!pager.pages || pager.pages.length <= 1) {
      return null;
    }

    return (
      <Pagination
        className='d-flex justify-content-center marginTop20'
        variant='outline-primary'
      >
        <Pagination.First
          className={pager.currentPage === 1 ? 'disabled' : ''}
          onClick={() => this.setPageChange(1)}
        >
          {'<<'}
        </Pagination.First>
        <Pagination.Prev
          className={pager.currentPage === 1 ? 'disabled' : ''}
          onClick={() => this.setPageChange(pager.currentPage - 1)}
        >
          {'<'}
        </Pagination.Prev>
        {pager.pages.map((page, index) => (
          <Pagination.Item
            key={index}
            className={pager.currentPage === page ? 'active' : ''}
            onClick={() => this.setPageChange(page)}
          >
            {page}
          </Pagination.Item>
        ))}
        <Pagination.Next
          className={pager.currentPage === pager.totalPages ? 'disabled' : ''}
          onClick={() => this.setPageChange(pager.currentPage + 1)}
        >
          {'>'}
        </Pagination.Next>
        <Pagination.Last
          className={pager.currentPage === pager.totalPages ? 'disabled' : ''}
          onClick={() => this.setPageChange(pager.totalPages)}
        >
          {'>>'}
        </Pagination.Last>
      </Pagination>
    );
  }
}

PaginationComponent.propTypes = propTypes;
PaginationComponent.defaultProps = defaultProps;
export default PaginationComponent;
