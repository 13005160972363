import Utils from "./utils";

const initialDefaults = (formRef, data) => {
  let productData = data.data;
  let biRequest = productData.bi_request;

  let frequency = biRequest.INPUT_MODE.toString();
  let annuityOption = biRequest.AnnuityOption.toString();
  let fundStrategy = biRequest.FUNDSTRATEGYID;

  let ptList = Utils.getPolicyTerm();
  formRef.$("policy-term").set("extra", {
    ...formRef.$("policy-term").extra,
    optionList: ptList,
  });

  formRef.$("policy-term").value = {
    id: biRequest.PR_PT,
    label: biRequest.PR_PT,
  };


  formRef.$('premium').set('value', data.data.firstYearResponse.MODAL_PREM)

  let pptList = Utils.getPremiumTermList(biRequest.PR_PT);
  formRef.$("premium-term").set("extra", {
    ...formRef.$("premium-term").extra,
    optionList: pptList,
  });

  formRef.$("premium-term").value = {
    id: biRequest.PR_PPT,
    label: biRequest.PR_PPT,
  };

  if(biRequest.PR_PPT === '1'){
    formRef.$("payment-frequency").set("value", {
      id: "5",
      label: "Single",
    });

    formRef.$("payment-frequency").set("disabled", true);
  }else{
    formRef.$("payment-frequency").set("disabled", false);
  }

  switch (frequency) {
    case "1":
      formRef.$("payment-frequency").set("value", {
        id: "1",
        label: "Annual",
      });
      break;
    case "2":
      formRef.$("payment-frequency").set("value", {
        id: "2",
        label: "Semi-Annual",
      });
      break;
    case "3":
      formRef.$("payment-frequency").set("value", {
        id: "3",
        label: "Quarterly",
      });
      break;
    case "4":
      formRef.$("payment-frequency").set("value", {
        id: "4",
        label: "Monthly",
      });
      break;
    case "5":
      formRef.$("payment-frequency").set("value", {
        id: "5",
        label: "Single",
      });
      break;

    default:
      break;
  }

  switch (annuityOption) {
    case "2":
      formRef.$("annuity-option").set("value", {
        id: "2",
        label: "Life Annuity with Return of Purchase Price",
      });
      break;
    case "3":
      formRef.$("annuity-option").set("value", {
        id: "3",
        label: "Life Annuity",
      });
      break;
    case "4":
      formRef.$("annuity-option").set("value", {
        id: "4",
        label: "Life Annuity Certain for 5 years",
      });
      break;
    case "5":
      formRef.$("annuity-option").set("value", {
        id: "5",
        label: "Life Annuity Certain for 10 years",
      });
      break;
    case "6":
      formRef.$("annuity-option").set("value", {
        id: "6",
        label: "Life Annuity Certain for 15 years",
      });
      break;
    case "7":
      formRef.$("annuity-option").set("value", {
        id: "7",
        label: "Life Annuity Certain for 20 years",
      });
      break;

    case "8":
      formRef.$("annuity-option").set("value", {
        id: "8",
        label: "Life Annuity increasing at a simple rate of 3% per annum",
      });
      break;
    case "9":
      formRef.$("annuity-option").set("value", {
        id: "9",
        label: "Life Annuity increasing at a simple rate of 5% per annum",
      });
      break;

    default:
      break;
  }

  switch (fundStrategy.toString()) {
    case "1":
      formRef.$("fund-strategy").set("value", {
        id: "1",
        label: "Aggressive",
      });
      break;
    case "2":
      formRef.$("fund-strategy").set("value", {
        id: "2",
        label: "Conservative",
      });
      break;

    default:
      break;
  }
};

export default initialDefaults;
