import React from "react";

function TitleTextSubtextRight(props) {
  return (
    <div className={`${props.titleTextSubtextStyle ? props.titleTextSubtextStyle : ""} title-text-subtext-right d-flex align-items-center mb-3 mb-xl-0`}>
      {/* <p className='font-size-22 font-size-md-26 font-size-lg-30 font-size-xl-32 text-primary font-weight-bold mb-0 mr-1'>{props.title}</p> */}
      <p className='font-size-14 text-primary font-weight-medium mb-0 mr-1'>{props.title}</p>
      {!props.hideSubText && (
        // <span className='font-size-16 font-size-md-24 font-size-lg-30 font-size-xl-32 font-weight-light text-description-text-color title-text-subtext-right-subtext'>{props.subText}</span>
        <span className='font-size-12 font-weight-light text-description-text-color title-text-subtext-right-subtext'>{props.subText}</span>
      )}
    </div>
  );
}

export default TitleTextSubtextRight;
