// Project Imports
import ProductNames from "constants/ProductNames";

const showSurvivalBenefitAmount = function (data) {
    console.log("showSurvivalBenefitAmount", data)

    if (!Array.isArray(data.BI)) {
        return false
    } else {
        // For With tabs products

        let selectedRiskData = data.BI.find((item) => item.risk === data.selectedRisk);

        switch (selectedRiskData?.product_id) {
            case ProductNames.cashFlowProtectionPlus:
                return true;

            default:
                return false;
        }
    }

};

export default showSurvivalBenefitAmount;
