import React, { useEffect, useState } from "react";

import { Row, Col, Form } from "react-bootstrap";
import { observer } from "mobx-react";
import moment from "moment";

import { CustomCheckbox, BottomsheetDropdown, DeathCoverPriceComponent, DatepickerModal, ChangeProductSection } from "../../../../../components";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import AdditionalCards from "./component/addtional-cards/AdditionalCards";
import AdditionalRiderBenifitsCard from "components/ui/additional-rider-benifits-card/AdditionalRiderBenifitsCard";
import CheckEligibilityModal from "./component/check-eligibility-modal/check-eligibility-modal";
import Utils from "./utils";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";

import "../../optimization-card/family-collapse-view.css";
import AddLifeCover from "../../addLifeCover/AddLifeCover";

const ZindagiPlus = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  let productData = data.data;
  let biResponse = productData.parsedResponse;
  let firstYearResponse = productData.firstYearResponse;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey);

  useEffect(() => {
    setShowOverlay(productData.fieldChanged);
  }, [productData.fieldChanged]);

  const onHideOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className=''>
      <Row className='mt-4 mb-3 pb-1'>
        <Col lg={7} className='col-12'>
          <DeathCoverPriceComponent
            firstAmount={firstYearResponse?.SA ? firstYearResponse.SA : productData.bi_request.PR_SA}
            secondAmount={firstYearResponse.MODAL_PREM}
            thirdAmount='980'
            showAdditionalText={false}
            showSecondColAmount={false}
            secondAmountLabel={form.$("payment-frequency").value.label}
            firstColTitleText={coverLabelBaseOnNeed}
            secondColTitleText='Price'
            cardKey={data.cardKey}
          />
        </Col>
      </Row>
      <Row className="align-items-center justify-content-md-between">
        <Col md={4} className='col-12 mt-2 mt-md-0 mb-2'>
          <p className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("covid-vaccination-status").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("covid-vaccination-status").label}
            menuList={form.$("covid-vaccination-status").extra.optionList}
            value={form.$("covid-vaccination-status").value}
            onSelect={(value) => form.$("covid-vaccination-status").extra.changeVaccinationStatus(form, value, data)}
          />
        </Col>
        <Col xs={12} md={3} className="mt-2">
          <p className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("tobacco-user").label}</p>
          {form.$("tobacco-user").options.map((item, index) => {
            return (
              <CustomCheckbox
                key={index}
                className='mr-4'
                isChecked={form.$("tobacco-user").value.id === item.id}
                onChange={() => form.$("tobacco-user").onChange(item)}
                titleStyle='font-size-14 font-weight-light total-premium-container-heading'
                shape='rounded'
                title={item.label}
                mark='checkmark'
              />
            );
          })}
        </Col>
        {/*<Col xs={6} md={4}>
           <AddLifeCover data={data} form={form} /> 
        </Col>*/}
        <Col xs={12} lg={4} className='px-auto mt-4'>
          <div className='d-flex flex-row'>
            <div className="col-4 px-0">
              <div className='mb-1 text-description-text-color font-size-12 font-weight-light'>{form.$("payout-option").label}</div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey='label'
                bottomsheetTitle={form.$("payout-option").label}
                menuList={form.$("payout-option").extra.optionList}
                value={form.$("payout-option").value}
                onSelect={(value) => form.$("payout-option").extra.onChangePayoutOption(value, form, data)}
                disabled=''
              />
            </div>
            {(form.$("payout-option").value?.id === "19" || form.$("payout-option").value?.id === "20") && (
              <div className='mb-3 col-5 col-lg-3 pl-1 pr-0'>
                <div className='mb-1 text-description-text-color font-size-12 font-weight-light'>{form.$("payout-percentage").label}</div>
                <BottomsheetDropdown
                  dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                  menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                  menuItemStyle='d-flex justify-content-between align-items-center'
                  selectedLabelKey='label'
                  bottomsheetTitle={form.$("payout-percentage").label}
                  menuList={form.$("payout-percentage").extra.optionList}
                  value={form.$("payout-percentage").value}
                  onSelect={(value) => form.$("payout-percentage").extra.onChangePayoutPercentage(value, form, data)}
                  disabled=''
                />
              </div>
            )}
            {(form.$("payout-option").value?.id === "17" || form.$("payout-option").value?.id === "18" || form.$("payout-option").value?.id === "19" || form.$("payout-option").value?.id === "20") && (
              <div className='mb-3 col-3 pl-1'>
                <div className='mb-1 text-description-text-color font-size-12 font-weight-light'>{form.$("payout-period").label}</div>
                <BottomsheetDropdown
                  dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                  menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                  menuItemStyle='d-flex justify-content-between align-items-center'
                  selectedLabelKey='label'
                  bottomsheetTitle={form.$("payout-period").label}
                  menuList={form.$("payout-period").extra.optionList}
                  value={form.$("payout-period").value}
                  onSelect={(value) => form.$("payout-period").extra.onChangePayoutPeriod(value, form, data)}
                  disabled=''
                />
              </div>
            )}
          </div>
        </Col>
        </Row>

        <Col xs={12} md={4} className='mt-3 mt-md-0'>
          <ChangeProductSection
            productName={productData.product_name || "Zindagi Plus"}
            familyProductId={productData.family_product_id}
            cardName={coverLabelBaseOnNeed}
            changeProductList={productData.changeProductList}
            productData={data}
          />
        </Col>

        <Col md={12} className='col-12 mt-2 mt-md-0'>
          <DeathCoverGraphSlider
            productData={productData}
            saLabel='Income'
            productTitle='Zindagi Plus'
            openChangeReasonModal={() => {}}
            onIncomeChange={form.$("income").onChange}
            onPremiumChange={form.$("premium-amt").onChange}
            dataChange={() => {}}
            hideOverLay={onHideOverlay}
            productID={productData.product_id}
            showOverlay={showOverlay}
            income_value={form.$("income").value}
            premium_value={form.$("premium-amt").value}
            cardKey={data.cardKey}
            onClick={() => form.$("premium-amt").extra.calculatePremiun(form, form.$("premium-amt").value, data)}
            data={productData}
            isIncomeFieldDisable={false}
            isPremiumDisable={true}
            premiumFreq={form.$("payment-frequency").value.label}
            onIncomeBlur={() => {
              form.$("income").extra.calculateIncome(form, data);
            }}
            formRef={form}
            recommendedPremium={productData?.recommended_premium}
            recommendedTargetValue={productData?.recommended_sum_assured}
          />
        </Col>
      {/* </Row> */}
      <div className=' py-2 py-md-2 my-3 mb-mb-2'>
        <Row>
          <Col md={6} className='col-12 mb-3 mb-md-0'>
            <p className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("policy-term").label}</p>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={form.$("policy-term").label}
              menuList={form.$("policy-term").extra.options}
              value={form.$("policy-term").value}
              onSelect={form.$("policy-term").onChange}
            />
          </Col>
          <Col md={6} className='col-12'>
            <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("premium-term").label}</div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={form.$("premium-term").label}
              menuList={form.$("premium-term").extra.options}
              value={form.$("premium-term").value}
              onSelect={form.$("premium-term").onChange}
              disabled={form.$("premium-term").disabled}
            />
          </Col>
        </Row>
      </div>
      
      <Row>
        <Col md={4} className='col-12'>
          <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("plan-option").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("plan-option").label}
            menuList={form.$("plan-option").extra.options}
            value={form.$("plan-option").value}
            onSelect={form.$("plan-option").onChange}
          />
        </Col>

        <Col md={4} className='col-12'>
          <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("payment-frequency").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("payment-frequency").label}
            menuList={form.$("payment-frequency").extra.options}
            value={form.$("payment-frequency").value}
            onSelect={form.$("payment-frequency").onChange}
          />
        </Col>

        <Col md={4} className='col-12'>
          <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("underwriting-status").label}</div>
          <BottomsheetDropdown
            ddropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("underwriting-status").label}
            menuList={form.$("underwriting-status").extra.optionList}
            value={form.$("underwriting-status").value}
            onSelect={form.$("underwriting-status").onChange}
            disabled={true}
          />
        </Col>
      
      {/* <Row className=''> */}
        <Col md={4} className='col-12'>
          <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("marital-status").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("marital-status").label}
            menuList={form.$("marital-status").extra.options}
            value={form.$("marital-status").value}
            onSelect={form.$("marital-status").onChange}
          />
        </Col>
        {form.$("better-half-benefit").value && (
          <>
            <Col md={4} className='col-12 mt-3'>
              <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("spouse-name").label}</div>
              <Form.Control
                className='font-size-12 font-weight-light text-black product-form-field'
                placeholder={form.$("spouse-name").label}
                value={form.$("spouse-name").value}
                // onChange={form.$("spouse-name").onChange}
                onChange={(e) => {

                  let updatedValue = e.target.value

                  let NameRegEx = new RegExp("^([A-Za-z'.]{1,30})$")

                  if (NameRegEx.test(updatedValue)) {
                    form.$("spouse-name").value = updatedValue
                      } else {
                        updatedValue = form.$("spouse-name").value
                        if (updatedValue.length == 1) {
                            updatedValue = ''
                        }
                     }
                      if (updatedValue.length == 1) {
                          updatedValue = updatedValue.toUpperCase()
                      }
                      form.$("spouse-name").value = updatedValue
                }}
                onBlur={() => form.$("spouse-name").extra.onSpouseNameChange(form, data)}
                disabled={form.$("marital-status").value.id == 9}
              />
            </Col>
            <Col md={4} className='col-12 mt-3'>
              <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("spouse-gender").label}</div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey='label'
                bottomsheetTitle={form.$("spouse-gender").label}
                menuList={form.$("spouse-gender").extra.optionList}
                value={form.$("spouse-gender").value}
                onSelect={form.$("spouse-gender").onChange}
                disabled={form.$("marital-status").value.id == 9}
              />
            </Col>
            <Col md={4} className='col-12 mt-3'>
              <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("spouse-dob").label}</div>
              <DatepickerModal
                plain
                value={form.$("spouse-dob").value}
                textStyle='font-size-12 font-weight-light text-black text-nowrap overflow-hidden txt-overflow'
                onChange={(value) => form.$("spouse-dob").extra.changeDob(form, value, data)}
                disabled={form.$("marital-status").value.id == 9}
                minDate={moment().subtract(18, "years").toString()}
              />
            </Col>

            <Col md={4} className='col-12 mt-3'>
          <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("spouse-tobacco-consumer").label}</div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={form.$("spouse-tobacco-consumer").label}
              menuList={form.$("spouse-tobacco-consumer").extra.optionList}
              value={form.$("spouse-tobacco-consumer").value}
              onSelect={form.$("spouse-tobacco-consumer").onChange}
            />
          </Col>
          </>
        )}
        <Col md={6} className='col-12'>
          <CheckEligibilityModal data={data} form={form} />
        </Col>
      </Row>
      <Row className='mt-5'>
        <Col md={6} className='col-12'>
          <AdditionalCards data={biResponse} form={form} productData={productData} />
        </Col>
        <Col md={6} className='col-12 mt-4 mt-md-0'>
          <AdditionalRiderBenifitsCard data={data} form={form} showCriticalIllness={true} showIncomeBenefit={false} showHospitalityCashBenefit={true} showWOP={false} showPWB={false} />
        </Col>
      </Row>
      <Row>
        <Col md={6} className='col-12'>
          <CheckEligibilityModal data={data} form={form} />
        </Col>
      </Row>
    </div>
  );
};

export default observer(ZindagiPlus);
