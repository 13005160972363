export const INCOME_OPTION = 'IncomeOption';
export const GURANTEED_INCOME = 'GuaranteedIncomeType';
export const FREQUENCY = 'INPUT_MODE';
export const PREMIUM = 'PR_ANNPREM';
export const MATURITY_AGE = 'PR_PT';
export const PR_PPT = 'PR_PPT';

export const FAMILY_INCOME_BENEFIT = 'FamilyIncomeBenefit';
export const WOP_RIDER = 'WaiverOfPremiumRider';
export const ADB_RIDER = 'ADBRider';
export const ATPD_RIDER = 'ATPDRider';
export const Critical_ILLNESS_RIDER = 'CriticalIllnessRider';
export const INCOME_BENEFIT_RIDER = 'IncomeBenefitRider';
export const PWB_RIDER = 'PayorWaiverBenefitRider';

