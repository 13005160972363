// React Imports 
import React, { Component } from "react";

// Package Imports
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { observer } from "mobx-react";

// CSS Imports
import "./customization-modal.css";

const CustomizationModal = observer(
  class CustomizationModal extends Component {
    constructor(props) {
      super(props);

      this.state = {};
    }

    render() {

       const journeyId = window.location.pathname.split('/').slice(-1)[0]

      return (
        <div className='closeModalDiv d-flex align-items-center'>
          <Modal
            show={this.props.show}
            backdrop="static"
            onHide={() => { }}
            as='section'
            className='closeModal'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            keyboard={false}
          >
            <ModalBody className='closeModalBody'>
              <section className="d-flex align-items-center justify-content-center h-100">
                <div className='row closeModalContent'>
                  <div className='mr-lg-2 py-4 my-3 text-center w-100 d-flex flex-column justify-content-between d-flex flex-column justify-content-between'>
                    <h1 className='heading text-center font-size-24'>
                      NEED ANALYSIS <span>BI</span>
                    </h1>
                    <div className="m-auto customization-loader"></div>
                    <p>{this.props.loaderMsg}</p>
                      <a className="customization-modal-cancel mt-5" href={`/${this.props.redirectPath}/${journeyId}`}>Cancel</a>
                  </div>
                </div>
              </section>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);

export default CustomizationModal;
