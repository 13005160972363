// project imports
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import Validator from "validatorjs";
import * as keys from "./keys";
import moment from "moment";
class Utils {
  defermentPeiodOptionList = (form, annuityBenefitValue) => {
    if (annuityBenefitValue === "1") {
      return form.$("deferment-period").set("extra", {
        ...form.$("deferment-period").extra,
        options: [{ label: "NA", id: "10" }],
      });
    } else {
      return form.$("deferment-period").set("extra", {
        ...form.$("deferment-period").extra,
        options: [
          { label: "1", id: "11" },
          { label: "2", id: "12" },
          { label: "3", id: "13" },
          { label: "4", id: "14" },
          { label: "5", id: "15" },
          { label: "6", id: "16" },
          { label: "7", id: "17" },
          { label: "8", id: "18" },
          { label: "9", id: "19" },
          { label: "10", id: "20" },
        ],
      });
    }
  };

  // ! get total price
  getTotalPrice = (biResponse) => {
    try {
      let totalPremium = 0;
      totalPremium += Number(biResponse.TOTAL_PREM_TAX);
      return totalPremium;
    } catch (e) {
      return 0;
    }
  };

  // ! update BI data
  updateBi = async (key, value, productId, form, productData) => {
    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request;

    switch (key) {
      case keys.FREQUENCY:
        biRequest[keys.FREQUENCY] = value;
        break;

      case keys.SOURCE_OF_PURCHASE_PRICE:
        biRequest[keys.PRAN_NUMBER] = value.pranNo;
        biRequest[keys.SOURCE_OF_PURCHASE_PRICE] = value.sourceOfPP;
        break;

      case keys.PRAN_NUMBER:
        biRequest[keys.PRAN_NUMBER] = value;
        break;

      case keys.ANNUITY_BENEFIT:
        biRequest[keys.ANNUITY_BENEFIT] = value.annuityBenefitValue;
        biRequest[keys.DEFERMENT_PERIOD] = value.defermentPeriodValue;
        break;

      case keys.LIFE_STATUS:
        biRequest[keys.LIFE_STATUS] = value.lifeStatus;
        biRequest[keys.ANNUITY_OPTION] = value.annuityOption;
        break;

      case keys.ANNUITY_MODE:
        biRequest[keys.ANNUITY_MODE] = value;
        let targetAmount = Number(biRequest?.TargetAmount);
        switch (value) {
          case "21":
          case 21:
              biRequest["TargetAmount"] = Math.round(targetAmount / 1);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
          case "22":
          case 22:
              biRequest["TargetAmount"] = Math.round(targetAmount / 2);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
          case "23":
          case 23:
              biRequest["TargetAmount"] = Math.round(targetAmount / 4);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
          case "24":
          case 24:
              biRequest["TargetAmount"] = Math.round(targetAmount / 12);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
        }
        break;

      case keys.PURCHASE_PRICE:
        biRequest[keys.PURCHASE_PRICE] = value;
        break;

      case keys.DEFERMENT_PERIOD:
        biRequest[keys.DEFERMENT_PERIOD] = value;
        break;

      case keys.ANNUITY_OPTION:
        biRequest[keys.ANNUITY_OPTION] = value;
        break;

      case keys.NAME_OF_THE_SECONDARY_ANNUITANT:
        biRequest[keys.NAME_OF_THE_SECONDARY_ANNUITANT] = value;
        break;

      case keys.DOB_NAME_OF_THE_SECONDARY_ANNUITANT:
        // ! for passing dob
        let dob = moment(value).format("DD MMM YYYY");
        biRequest[keys.DOB_NAME_OF_THE_SECONDARY_ANNUITANT] = dob;

        // !for passing age
        let age = moment().diff(moment(value, "DD MMM YYYY"), "years").toString();
        biRequest[keys.AGE_OF_THE_SECONDARY_ANNUITANT] = age;

        break;

      case keys.GENDER_NAME_OF_THE_SECONDARY_ANNUITANT:
        biRequest[keys.GENDER_NAME_OF_THE_SECONDARY_ANNUITANT] = value;
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value;
        break;

      case keys.INCOME:
        biRequest[keys.INCOME] = value;
        break;
      default:
        break;
    }

    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value);

    // let errorMessage = Validator.valid(form, biData);
    // if (errorMessage.length > 0) {
    //   return
    // }

    // let response = await OptimizationStore.updateStoreRequestData(
    //   productId,
    //   biRequest,
    //   key,
    //   value
    // )
    // return response
  };

  // fetchPolicyTimeline(element) {
  //   let data = {
  //     youPay: Math.round(element.MODAL_PREM_TAX) || 0,
  //     youGet: Math.round(element.YEARLY_ANN_PAY) || 0,
  //     lifeCover: Math.round(element.DB_G) || 0,
  //     isMilestone: false,
  //     premiumAmt: Math.round(element.PPRICE) || 0
  //   }
  //   return data
  // }

  newPolicyTimeLineData(element, inputString, index) {
    const { ANN_PREM_YEARLY, MODAL_ANNUITY_PAYOUT, DB_G } = element;
  
    const annuityMode = inputString.find(item => item.Key === '@PR_OPTION_7').Value
    
    const annuityModeObj = {'Yearly':1,'Half-Yearly':2,'Quarterly':4,'Monthly':12}

    // yearly:'21',Half-Yearly':'22',Quarterly:'23',Monthly:'24'
    
    let youGetValue = MODAL_ANNUITY_PAYOUT

    switch (annuityMode) {
      case '21':
        youGetValue = youGetValue * annuityModeObj['Yearly']
      break;

      case '22':
        youGetValue = youGetValue * annuityModeObj['Half-Yearly']
      break;

      case '23':
        youGetValue = youGetValue * annuityModeObj['Quarterly']
      break;

      case '24':
        youGetValue = youGetValue * annuityModeObj['Monthly']
      break;
    
      default:
        break;
    }

    let data = {
      youPay: Math.round(ANN_PREM_YEARLY),
      youGet: Math.round(youGetValue),
      lifeCover: Math.round(DB_G),
    };

    return data;
  }
}

export default new Utils();
