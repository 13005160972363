/* eslint-disable no-param-reassign */
import axios from 'axios';
import Header from '../constants/Headers';
import AuthStore from '../modules/login/store/AuthStore';

var numberOfAjaxCAllPending = 0;

axios.interceptors.request.use(
  async config => {
    numberOfAjaxCAllPending++;
    global.mainLoading(true)
    const token = Header.getSessionToken(config.url);
    const headers = await Header.getCommonHeadersWithToken(config.url)
    if (token) {
      config.headers = {
        ...headers,
        ...config.headers
      };
    } else {
      config.headers = { ...Header.getCommonHeaderParams(), ...config.headers };
    }
    config.timeout = 3600000;
    // eslint-disable-next-line max-len
    config.validateStatus = status =>
      (status >= 200 && status < 300) || (status >= 400 && status <= 500);

    // eslint-disable-next-line no-console
    // console.log('REQUEST >> ', config);
    return config;
  },
  error => {
    Promise.reject(error);
    // eslint-disable-next-line no-console
    // console.log('REQUEST ERROR >> ', error);
    // global.showToast('danger', error.toString());
    AuthStore.setErrorModal(error.toString());
    // AuthStore.isInvalidJourneyId = AuthStore.axiosMsg !== '' ? true : false;
  }
);
axios.interceptors.response.use(
  async response => {
    // eslint-disable-next-line no-console
    // console.log('RESPONSE >> ', response);
    if (response.status < 200 || response.status > 207) {
      let message = '';
      try {
        switch (response.status) {
          case 401:
            try {
              message = response.data.detail;
              response.data = { data: { ...response.data }, status: 'error' };
              // global.showToast('Token is expired. Please login again');
              AuthStore.setErrorModal('Token is expired. Please login again')
              // AuthStore.isInvalidJourneyId = AuthStore.axiosMsg !== '' ? true : false;
              await AuthStore.authentication.signout()
              // await AsyncStorage.setItem("userData", "");
              // await Header.setSessionToken("");
              // NavigationUtils.goToScreen(global.navigation, "Login", true);
            } catch (error) {
              // eslint-disable-next-line no-console
              // console.log('ERROR 401 >> ', error);
            }
            break;
          case 400:
            message = Object.values(response.data);
            response.data = { data: { ...response.data }, status: 'error' };
            break;
          case 404:
            message = response.statusText;
            response.data = { data: { ...response.data }, status: 'error' };
            break;
          default:
            if (response.message) {
              // eslint-disable-next-line prefer-destructuring
              message = response.message;
            } else {
              message = 'something went wrong';
            }
            response.data = { status: 'error' };
            break;
        }
        // eslint-disable-next-line no-empty
      } catch (error) {
        // eslint-disable-next-line no-console
        // console.log('ERROR RESPONSE >> ', error);
        numberOfAjaxCAllPending = numberOfAjaxCAllPending - 1;
        global.mainLoading(false)
      }
      // global.showToast('danger', message ? message.toString() : 'not found');
      numberOfAjaxCAllPending = numberOfAjaxCAllPending - 1;
      global.mainLoading(false)
      AuthStore.setErrorModal(message ? message.toString() : 'not found');
      // AuthStore.isInvalidJourneyId = AuthStore.axiosMsg !== '' ? true : false;
      // <ErrorModal
      //   onHideModal={AuthStore.closeInvalidJourneyIdModal}
      //   showModal={AuthStore.isInvalidJourneyId}
      //   title='Something Went Wrong!'
      //   hideResumeSession={true}
      // />
      return response;
    }
    if (response.status === 204) {
      response.data = { data: {}, status: 'success' };
      // eslint-disable-next-line no-prototype-builtins
    } else if (!response.data.hasOwnProperty('status')) {
      try {
        response.data = { data: response.data, status: 'success' };
        // eslint-disable-next-line no-empty
      } catch (error) { }
    } else {
      if (!response.data.status) {
        let message = '';
        try {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in response.data) {
            // eslint-disable-next-line no-prototype-builtins
            if (response.data.hasOwnProperty(key) && key !== 'status') {
              const element = response.data[key];
              message += `${key} : ${element}`;
            }
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          // console.log('Error >> ', e);
          // global.showToast('danger', message.toString());
          AuthStore.setErrorModal(message.toString());
          // AuthStore.isInvalidJourneyId = AuthStore.axiosMsg !== '' ? true : false;
        }
      }
      response.data = { data: response.data, status: 'success' };
    }
    numberOfAjaxCAllPending = numberOfAjaxCAllPending - 1;
    if (numberOfAjaxCAllPending <= 0)
      global.mainLoading(false)
    return response.data;
  },
  error => {
    numberOfAjaxCAllPending = numberOfAjaxCAllPending - 1;
    if (numberOfAjaxCAllPending <= 0)
      global.mainLoading(false)
    // eslint-disable-next-line no-console
    // console.log('RESPONSE ERROR >> ', error);
    // global.showToast('danger', error.toString());
    AuthStore.setErrorModal(error.toString());
    // AuthStore.isInvalidJourneyId = AuthStore.axiosMsg !== '' ? true : false;
    Promise.reject(error);
  }
);

export default axios;
