// react imports
import React from "react";

// package imports
import { Col, Row, Card } from "react-bootstrap";

// project imports
import QmsSolutionStatus from "constants/QmsSolutionStatus";
import ProposerDetailsCardForNAStatus from "./ProposerDetailsCardForNAStatus";
import ProposerDetailsCardForApplicationInProgress from "./ProposerDetailsCardForApplicationInProgress";

import "./proposer-details-card.css";

function ProposerDetailsCard(props) {
  const { proposerData, proposerIndex, journeyData } = props;

  return proposerData.solution_list.length > 0 ? (
    <div className={`proposer-details-card mb-3`}>
      <Row>
        <Col xs='12'>
          <Card className='shadow py-0'>
            <Card.Body>
              <Row className='align-items-center'>
                {proposerData.solution_list.map((solutionData, solutionIndex) =>
                  solutionData.solution_status_id !== QmsSolutionStatus.APPLICATION_IN_PROGRESS ? (
                    <React.Fragment key={"solution-id-" + solutionData.solution_id + solutionIndex}>
                      <ProposerDetailsCardForNAStatus
                        proposerData={proposerData}
                        solutionData={solutionData}
                        buttonId={solutionData.solution_id ? "solution-id-" + solutionData.solution_id : "button-id" + proposerData.proposer_id + "-" + solutionIndex}
                        statusId={solutionData.solution_id ? "status-id-" + solutionData.solution_id : "status-id" + proposerData.proposer_id + "-" + solutionIndex}
                        journeyData={journeyData}
                        {...props}
                      />
                    </React.Fragment>
                  ) : (
                    solutionData.la_list.map((laData, laIndex) =>
                      laData.quotation_id_list.map((quoteStatusData, quotationIndex) => 
                        <React.Fragment key={"quotaion-id-" + quoteStatusData.quote_id + quotationIndex}>
                          <ProposerDetailsCardForApplicationInProgress
                            proposerData={proposerData}
                            quoteStatusData={quoteStatusData}
                            buttonId={quoteStatusData.quote_id ? "quotation-id-" + quoteStatusData.quote_id : "button-id-" + solutionData.solution_id + "-" + quotationIndex}
                            statusId={quoteStatusData.quote_id ? "status-id-" + quoteStatusData.quote_id : "status-id-" + solutionData.solution_id + "-" + quotationIndex}
                            journeyData={journeyData}
                            proposerIndex={proposerIndex}
                            laIndex={laIndex}
                            quotationIndex={quotationIndex}
                            {...props}
                          />
                        </React.Fragment>
                      )
                    )
                  )
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  ) : null;
}

export default ProposerDetailsCard;
