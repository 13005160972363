import Utils from './utils';

const initialDefaults = (formRef, data) => {
    let requestData = data.data.bi_request
    let age = requestData.LI_ENTRY_AGE
    let isSmoker = requestData.LI_SMOKE
    let planOption = requestData.PlanOption
    let ptList = Utils.generateSimplyProtectPT()
    let frequency = requestData.INPUT_MODE
    let premium = requestData.PR_ANNPREM
    let mode = requestData.SumAssuredpayoutoption

    formRef.$('premium').value = premium;

    if (isSmoker === "1") {
        formRef.$('tobaco-user').set('value', {
            id: '1',
            label: 'Yes'
        })
    } else {
        formRef.$('tobaco-user').set('value', {
            id: '0',
            label: 'No'
        })
    }

    if (frequency === "1") {
        formRef.$('payment-frequency').set('value', {
            id: '1',
            label: 'Annual'
        })
    }

    formRef.$('policy-term').set('extra', {
        ...formRef.$('policy-term').extra,
        optionList: ptList
    })


    if (requestData.PR_PT == '80 - @Li_entry_age') {
        formRef.$('policy-term').set('value', {
            id: requestData.PR_PT,
            label: "upto age 80"
        })
    } else {
        formRef.$('policy-term').set('value', {
            id: requestData.PR_PT,
            label: requestData.PR_PT
        })
    }
    let pptList = Utils.calculatePPT()

    formRef.$('premium-paying-term').set('extra', {
        ...formRef.$('premium-paying-term').extra,
        optionList: pptList
    })

    if (requestData.PR_PPT == '80 - @Li_entry_age') {
        formRef.$('premium-paying-term').set('value', {
            id: requestData.PR_PPT,
            label: "upto age 80"
        })
    } else {
        formRef.$('premium-paying-term').set('value', {
            id: requestData.PR_PPT,
            label: requestData.PR_PPT
        })
    }

    switch (planOption) {

        case '1':
            formRef.$('plan-option').set('value', {
                id: '1',
                label: 'Life Cover'
            })
            break;
        case '2':
            formRef.$('plan-option').set('value', {
                id: '2',
                label: 'Life Cover + Waiver of Premium on Accidental Total & permanently Disablility'
            })
            break;
        case '5':
            formRef.$('plan-option').set('value', {
                id: '5',
                label: 'Life Cover + Accidental Death Benefit'
            })
            break;
        case '6':
            formRef.$('plan-option').set('value', {
                id: '6',
                label: 'Life Cover + Waiver of Premium on critical Illness'
            })
            break;
        default:
            break;
    }

    switch (mode) {

        case '8':
            formRef.$('payout-mode').set('value', {
                id: '8',
                label: 'Income Benefit'
            })
            break;
        case '7':
            formRef.$('payout-mode').set('value', {
                id: '7',
                label: 'Lumpsum'
            })
            break;
        default:
            break;
    }


}

export default initialDefaults