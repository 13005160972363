import React from 'react'
import { Card, Image } from 'react-bootstrap';
import * as images from 'constants/Images';

import './solution-benefits-card.css'

function SolutionBenefitsCard(props) {
  const { solutionData } = props;

  let key = solutionData.type
  let icon = ''
  let alt = ''
  let details = solutionData.benefit

  switch (key) {
    case 'life_cover':
      icon = images.DeathCoverIcon
      alt = 'life-cover-icon'
      break;

    case 'tax_benefit':
      icon = images.TaxSavingIcon
      alt = 'tax_saving-icon'
      break;

    case 'child_benefit':
      icon = images.FlexiblePayoutIcon
      alt = 'child-benefit-icon'
      break;

    case 'other_benefit':
      icon = images.PremiumWiverIcon
      alt = 'other-benefit-icon'
      break;

    case 'survival_benefit':
      icon = images.EarlyReturnIcon
      alt = 'early-return-icon'
  }
  return (
    <div className='solution-benefit-card d-flex mb-lg-2'>
      {/* <Card>
      <Card.Body className='d-flex align-items-center px-2 py-3 px-lg-3'> */}
      <Image src={icon} alt={alt} className='mr-2 mr-lg-3 benefit-icon' />
      {details}
      {/* </Card.Body>
    </Card> */}
    </div>
  )
}

export default SolutionBenefitsCard