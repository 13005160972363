import React, { useState, useEffect } from "react";

import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react";

import { BottomsheetDropdown, ChangeProductSection } from "components";
import AmountCalculation from '../../optimization-card/amount-calculation/AmountCalculation';
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import Utils from './utils';
import AdditionalCards from './components/addtional-cards/AdditionalCards';
import AdditionalRiderBenifitsCard from "components/ui/additional-rider-benifits-card/AdditionalRiderBenifitsCard";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import DeathBenefitAmount from "utils/optimization/deathBenefitAmount";

const ActiveIncomePlan = ({ form, data }) => {
    const [showOverlay, setShowOverlay] = useState(false)

    let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
    let biResponse = productData.parsedResponse;
    let firstYearResponse = productData.firstYearResponse;
    let biRequest = productData.bi_request;
    let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)
    let tabs = data.data.BI
    let updatedValues
    tabs.length > 0 && tabs.map(tab => {
        if (tab.risk === data.selectedTab) {
            updatedValues = tab.updatedValues ? tab.updatedValues : null
        }
    })

    useEffect(() => {
        setShowOverlay(productData.fieldChanged)
    }, [productData.fieldChanged])

    const onHideOverlay = () => {
        setShowOverlay(false)
    }

    return (
        <div className='save-my-children'>

            <Row className='align-items-center mt-md-4'>
                <Col xs={12} md={4} className="mt-3 mt-md-0">
                    <ChangeProductSection
                        productName={productData.product_name}
                        cardName={coverLabelBaseOnNeed}
                        changeProductList={data?.data?.changeProductList}
                        productData={data}
                    />
                </Col>
                {/* <Col md={6} className='col-12'>
                    <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                        <div className='mb-2 text-description-text-color font-size-14'>
                            {form.$('payout-mode').label}
                        </div>
                        <BottomsheetDropdown
                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemStyle='d-flex justify-content-between align-items-center'
                            selectedLabelKey='label'
                            bottomsheetTitle={form.$('payout-mode').label}
                            disabled={true}
                            menuList={form.$('payout-mode').extra.optionList}
                            value={form.$('payout-mode').extra.optionList}
                            onSelect={form.$('payout-mode').onChange}
                        />
                    </div>
                </Col> */}
            </Row>

            <DeathCoverGraphSlider
                productTitle='Active Income'
                openChangeReasonModal={false}
                onIncomeChange={() => { }}
                onPremiumChange={form.$('premium').onChange}
                productID={productData.product_id}
                productData={data?.data}
                income_value=""
                premium_value={form.$('premium').value}
                showOverlay={showOverlay}
                hideOverLay={onHideOverlay}
                onClick={() => form.$('premium').extra.calculatePremiun(form, productData.product_id, data)}
                data=""
                cardKey={data.cardKey}
                isIncomeFieldDisable={form.$('premium').disabled}
                premiumFreq={form.$('payment-frequency').value.label}
                formRef={form}
            />

            <Row className='mt-3 ml-0'>
                <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{"Sum Assured on Death:"}</p>
                <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(productData)}</p>
            </Row>

            <Row className='mt-2 mt-md-4'>

            <Col md={6} className='col-12 mt-3'>
                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('maturity-age').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('maturity-age').label}
                        menuList={form.$('maturity-age').extra.optionList}
                        value={form.$('maturity-age').value}
                        onSelect={form.$('maturity-age').onChange}
                        disabled={form.$('maturity-age').disabled}
                    />

                </Col>
                <Col md={6} className='col-12 mt-3'>
                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('premium-term').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('premium-term').label}
                        menuList={form.$('premium-term').extra.optionList}
                        value={form.$('premium-term').value}
                        onSelect={form.$('premium-term').onChange}
                    />
                </Col>
                <Col md={4} className='col-12 mt-3'>
                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('income-option').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('income-option').label}
                        menuList={form.$('income-option').extra.optionList}
                        value={form.$('income-option').value}
                        onSelect={form.$('income-option').onChange}
                    />
                </Col>
                <Col md={4} className='col-12 mt-3'>
                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('guranteed-income').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('guranteed-income').label}
                        menuList={form.$('guranteed-income').extra.optionList}
                        value={form.$('guranteed-income').value}
                        onSelect={form.$('guranteed-income').onChange}
                        disabled={form.$('guranteed-income').disabled}
                    />
                </Col>

                <Col md={4} className='col-12 mt-3'>
                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('payment-frequency').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('payment-frequency').label}
                        menuList={form.$('payment-frequency').extra.optionList}
                        value={form.$('payment-frequency').value}
                        onSelect={form.$('payment-frequency').onChange}
                    />
                </Col>
            {/* </Row>
            <Row className='mt-2 mt-md-4'> */}
            </Row>
            <Row className='mt-4'>
                {/* <Col md={6} className='col-12'>
                    <AdditionalCards
                        data={data}
                        form={form}
                    />
                </Col> */}
                <Col md={6} className='col-12 my-4 '>
                    <AdditionalRiderBenifitsCard
                        data={data}
                        form={form}
                        showCriticalIllness={true}
                        showIncomeBenefit={true}
                        showHospitalityCashBenefit={false}
                        showWOP={true}
                        showPWB={true}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default observer(ActiveIncomePlan);
