import React, { Component } from 'react';
import './modal.css';


import {
  Modal
} from 'react-bootstrap';

class ModalComponent extends Component {
  render() {
    const { title, body, footer, ...props } = this.props;
    return (
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Header closeButton>
          {title && <Modal.Title id='contained-modal-title-vcenter'>
            {title}
          </Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          {body}
        </Modal.Body>
        {footer && <Modal.Footer>
          {footer}
        </Modal.Footer>}
      </Modal>
    );
  }
}

export default ModalComponent;
