// Package Imports
import { toJS } from "mobx";

// Project Imports
import axios from "utils/axios";
import * as APITypes from "constants/API"
import checkIsValueEmpty from "utils/checkIsValueEmpty";
import ChooseNeedsStore from "modules/choose-needs/store/ChooseNeedsStore";
import fetchSalutationMaster from "utils/fetchSalutationMaster"
import AuthStore from "modules/login/store/AuthStore";
import chooseNeedInitialDropdownValues from "./chooseNeedDropDownInitialConstant";


const fetchNeedMaster = async () => {
    const needResponse = await axios.get(APITypes.NEED_MASTER)

    if (needResponse.data && needResponse.status == 'success') {
        return needResponse.data
    }
    return []
}

const preFillFormField = (formController, need_name, dataObj) => {

    let needDetails = toJS(AuthStore.journeyData.family_details_list).find((item) => item.family_member_type.toLowerCase() === 'self').family_product_details_list
    let selectedNeedData = needDetails.find((item)=> item.need_unique_name === need_name)
    if (dataObj === undefined){
        formController.$(need_name).set("disabled", true)
        return
    }
    if (selectedNeedData === undefined) return


    let targetAmount = selectedNeedData.target_amount
    let targetAmountValue = selectedNeedData.target_amount_value
    let isCalculatorUsed = selectedNeedData.is_calculator_used
    let payoutMode = selectedNeedData?.payout_mode
    let disabled = !selectedNeedData.is_selected
    let hasTargetAmountField = formController.$(need_name).has('target_amount_value')
    let hasCalculatorField = formController.$(need_name).has('calculator_used')

    let selectedPayoutMode = {}
    let selectedtargetAmount = {}
    let optionList = []


    if (payoutMode !== undefined && payoutMode !== null) {
        optionList = formController.$(need_name).$("target_amount_type").extra.optionList
        selectedPayoutMode = optionList.find((item) => item.mode.toLowerCase() == payoutMode)

        if (selectedPayoutMode !== undefined) {
            formController.$(need_name).$("target_amount_type").value = selectedPayoutMode
        }
    }

    if (targetAmount !== undefined && targetAmount !== null) {
        optionList = formController.$(need_name).$("target_amount_dropdown").extra.allOptionsList
        selectedtargetAmount = optionList.find((item) => item.id == targetAmount)
        if (selectedtargetAmount !== undefined) {
            formController.$(need_name).$("target_amount_dropdown").value = selectedtargetAmount
        }
    }

    if (!checkIsValueEmpty(targetAmountValue) && hasTargetAmountField && isCalculatorUsed) {
        formController.$(need_name).$("target_amount_value").value = targetAmountValue
        formController.$(need_name).$("target_amount_value").set("type", "text")
        formController.$(need_name).$("target_amount_dropdown").set("type", "hidden")
    }

    if (hasCalculatorField) {
        formController.$(need_name).$("calculator_used").value = isCalculatorUsed
    }
    formController.$(need_name).set("disabled", disabled)

        ChooseNeedsStore.previouslySelectedNeedObject = {
            ...ChooseNeedsStore.previouslySelectedNeedObject, 
            [need_name]:{target_amount_type:selectedPayoutMode,selectedtargetAmount:selectedtargetAmount}
        } 
}

const preFillFormData = (formController, journeyData) => {
    let needDetails = journeyData.journey_need

    let familyDetails = journeyData.family_details_list;

    if (familyDetails === undefined) return

    let selfDetails = familyDetails.find((item) => item.family_member_type.toLowerCase() == 'self');

    if (selfDetails?.monthly_income !== undefined) {
        formController.$("protection_for_my_family.monthly_income").value = selfDetails.monthly_income
    }

    if (selfDetails.dob !== undefined) {
        formController.$("protection_for_my_family.dob").value = selfDetails.dob
    }

    if (needDetails === undefined || needDetails.length <= 0) {
        return
    }

    let familyProtectionId = formController.$("protection_for_my_family.id").value
    let healthProtectionId = formController.$("protection_for_my_health.id").value
    let moneyGrowId = formController.$("grow_my_money.id").value
    let saveChildrenId = formController.$("save_for_children.id").value
    let saveRetirementId = formController.$("save_for_retirement.id").value

    let familyProtectionObj = needDetails.find(item => item.need == familyProtectionId )
    let healthProtectionObj = needDetails.find(item => item.need == healthProtectionId )
    let moneyGrowObj = needDetails.find(item => item.need == moneyGrowId )
    let saveChildredObj = needDetails.find(item => item.need == saveChildrenId )
    let saveRetirementObj = needDetails.find(item => item.need == saveRetirementId )

    preFillFormField(formController, 'protection_for_my_family', familyProtectionObj)
    preFillFormField(formController, 'protection_for_my_health', healthProtectionObj)
    preFillFormField(formController, 'grow_my_money', moneyGrowObj)
    preFillFormField(formController, 'save_for_children', saveChildredObj)
    preFillFormField(formController, 'save_for_retirement', saveRetirementObj)

}

const initialDefaults = async (formController, journeyId, journeyData) => {

    let masterData = await fetchNeedMaster()
    let salutation = await fetchSalutationMaster()
    ChooseNeedsStore.setSalutationList(salutation)

    if (masterData.length <= 0) return

    let growMyMoney = masterData.find((item) => item.unique_name === 'grow_my_money')

    let saveForChildren = masterData.find((item) => item.unique_name === 'save_for_children')

    let saveForRetirement = masterData.find((item) => item.unique_name === 'save_for_retirement')

    let protectionForMyFamily = masterData.find((item) => item.unique_name === 'protection_for_my_family')

    let protectionForMyHealth = masterData.find((item) => item.unique_name === 'protection_for_my_health')

    formController.$("journey_id").value = journeyId


    /* ! protection for my family target amount type dropdown data */

    formController.$("protection_for_my_family.target_amount_type").set("extra", {
        ...formController.$("protection_for_my_family.target_amount_type").extra,
        optionList: protectionForMyFamily.mode_master
    })

    /* ! protection for my family target amount type dropdown default value */

    formController.$("protection_for_my_family.target_amount_type").value = protectionForMyFamily.mode_master[0]

    formController.$("protection_for_my_family.target_amount_dropdown").set("extra", {
        ...formController.$("protection_for_my_family.target_amount_dropdown").extra,
        allOptionsList: protectionForMyFamily.target_amount
    })

    formController.$("protection_for_my_family.id").value = protectionForMyFamily.id

    formController.$("protection_for_my_health.target_amount_dropdown").set("extra", {
        ...formController.$("protection_for_my_health.target_amount_dropdown").extra,
        allOptionsList: protectionForMyHealth.target_amount,
        optionList: protectionForMyHealth.target_amount,
    })
    
    formController.$("protection_for_my_health.target_amount_dropdown").value = protectionForMyHealth.target_amount.find(item => item.label === chooseNeedInitialDropdownValues.protectionForHealthLumpsum)


    formController.$("protection_for_my_health.id").value = protectionForMyHealth.id

    /* ! grow my money target amount type dropdown data */

    formController.$("grow_my_money.target_amount_type").set("extra", {
        ...formController.$("grow_my_money.target_amount_type").extra,
        optionList: growMyMoney.mode_master
    })


    formController.$("grow_my_money.target_amount_dropdown").set("extra", {
        ...formController.$("grow_my_money.target_amount_dropdown").extra,
        allOptionsList: growMyMoney.target_amount
    })

    formController.$("grow_my_money.target_amount_type").value = formController.$("grow_my_money.target_amount_type").extra.optionList[1]

    formController.$("grow_my_money.target_amount_dropdown").value = growMyMoney.target_amount.find(item => item.label === chooseNeedInitialDropdownValues.growMyMoneyIncome)

    formController.$("grow_my_money.id").value = growMyMoney.id

    formController.$("save_for_children.target_amount_dropdown").set("extra", {
        ...formController.$("save_for_children.target_amount_dropdown").extra,
        allOptionsList: saveForChildren.target_amount
    })

    /* ! save for children target amount type dropdown data */

    formController.$("save_for_children.target_amount_type").set("extra", {
        ...formController.$("save_for_children.target_amount_type").extra,
        optionList: saveForChildren.mode_master
    })


    formController.$("save_for_children.target_amount_type").value = formController.$("save_for_children.target_amount_type").extra.optionList[1]

    formController.$("save_for_children.target_amount_dropdown").value = saveForChildren.target_amount.find(item => item.label === chooseNeedInitialDropdownValues.saveForChildrenIncome)

    formController.$("save_for_children.id").value = saveForChildren.id

    /* ! save for retirement target amount type dropdown data */

    formController.$("save_for_retirement.target_amount_type").set("extra", {
        ...formController.$("save_for_retirement.target_amount_type").extra,
        optionList: saveForRetirement.mode_master
    })

    /* ! protection for my family target amount type dropdown default value */
    formController.$("save_for_retirement.target_amount_type").value = saveForRetirement.mode_master[1]

    formController.$("save_for_retirement.target_amount_dropdown").value = saveForRetirement.target_amount.find(item => item.label === chooseNeedInitialDropdownValues.saveForRetirementIncome)

    formController.$("save_for_retirement.target_amount_dropdown").set("extra", {
        ...formController.$("save_for_retirement.target_amount_dropdown").extra,
        allOptionsList: saveForRetirement.target_amount,
        optionList: saveForRetirement.target_amount
    })

    formController.$("save_for_retirement.id").value = saveForRetirement.id

    preFillFormData(formController, journeyData)

}

export default initialDefaults