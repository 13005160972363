// field keys

export const INCOME = 'investment_target';
export const PREMIUM = 'PR_ANNPREM';
export const PR_PT = 'PR_PT';
export const PR_PPT = 'PR_PPT';
export const FREQUENCY = 'INPUT_MODE';
export const ZP_OCCUPATION = 'ZP_OCCUPATION';
export const ZP_SECONDARY_OCCUPATION = 'ZP_SECONDARY_OCCUPATION';
export const ZP_EDUCATION = 'ZP_EDUCATION';
export const ZP_ANNINCOME = 'ZP_ANNINCOME';
export const PR_SA = 'PR_SA';
