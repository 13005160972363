// Package Imports
const { Form } = require("mobx-react-form");
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

// Project Imports
import Utils from "./utils";
import formObserver from './observers';
import setInitialDefaults from './initialDefaults.js';
import * as Constants from 'constants/Constants';
import * as keys from './keys'

class GcapFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "plan-option",
          label: "Plan Option",
          placeholder: "",
          disabled: true,
          extra: {
            optionList: [
              { label: 'Flexi-Income Option', id: 1 },
              { label: 'Flexi-Income PRO Option', id: 2 },
              { label: 'Large Sum Option', id: 3 },
            ],
          },
        },
        {
          name: "premium-term",
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            optionList: [],
          },
        },
        {
          name: "income-start-year",
          label: "Income Start Year",
          placeholder: "",
          extra: {
            hide: false,
            optionList: [],
          },
        },
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          value: '',
          extra: {
            optionList: [],
          },
        },
        {
          name: "life-cover",
          label: "Life Cover Continuation Benefit",
          placeholder: "",
          disabled: false,
          extra: {
            optionList: [
              { label: 'Yes', id: "11" },
              { label: 'No', id: "10" }
            ],
          },
        },
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          extra: {
            optionList: [
              { label: 'Annual', id: "1" },
              { label: 'Semi-Annual', id: "2" },
              { label: 'Quarterly', id: "3" },
              { label: 'Monthly', id: "4" }
            ]
          },
        },
        {
          name: "accrual-of-survival-benefit",
          label: "Accrual Of Survival Benefits",
          placeholder: "",
          disabled: false,
          extra: {
            optionList: [
              { label: 'No', id: "12" },
              { label: 'Yes', id: "13" }
            ]
          },
        },
        {
          name: "personalize-plan",
          label: "Please personalize your plan",
          placeholder: "",
          disabled: false,
          extra: {
            hide: false,
            optionList: [
              { label: 'Systematic Withdrawal', id: "1" },
              { label: 'Age-Based Withdrawal', id: "2" },
              { label: 'Income-Age Based Withdrawal', id: "3" }
            ]
          },
        },

        // Systematic Withdrawal
        {
          name: "withdrawal",
          label: "Withdrawal",
          placeholder: "",
          disabled: false,
          extra: {
            hide: false,
            optionList: [
              { label: 'Every 3 Years', id: '3' },
              { label: 'Every 5 Years', id: '5' },
              { label: 'Every 10 Years', id: '10' }
            ]
          },
        },
        {
          name: "withdrawal-start-year",
          label: "Withdrawal Start Year",
          placeholder: "",
          disabled: false,
          extra: {
            hide: false,
            onChangeStartYear(value, form, data) {
              form.$('withdrawal-start-year').set('value', value)
              Utils.updateBi(keys.WITHDRAWAL_START_YEAR, value, data.data.product_id, form, data)
            }
          },
        },
        {
          name: "withdrawal-end-year",
          label: "Withdrawal End Year",
          placeholder: "",
          disabled: false,
          extra: {
            hide: false,
            onChangeEndYear(value, form, data) {
              form.$('withdrawal-end-year').set('value', value)
              Utils.updateBi(keys.WITHDRAWAL_STOP_YEAR, value, data.data.product_id, form, data)
            }
          },
        },
        // !Age-Based Withdrawal
        {
          name: "age-based-withdrawal",
          label: "Age-Based Withdrawal",
          placeholder: "",
          disabled: false,
          extra: {
            hide: false,
            optionList: [""],
            onChangeOfAgeBasedWithdrawal(value, listIndex, form, data) {
              let optionList = Utils.onChangeWithDrawal(value, 'age-based-withdrawal', listIndex, form)
              form.$('age-based-withdrawal').set('extra', { ...form.$('age-based-withdrawal').extra, optionList })
              Utils.updateBi(keys.AGE_BASED_WITHDRAWAL, optionList.toString(), data.data.product_id, form, data)
            }
          },
        },
        // !Income-Age Based Withdrawal
        {
          name: "start-age",
          label: "Start Age",
          placeholder: "",
          disabled: false,
          extra: {
            hide: false,
            optionList: [""],
            onChangeStartAge(value, listIndex, form, data) {
              let optionList = Utils.onChangeWithDrawal(value, 'start-age', listIndex, form)
              form.$('start-age').set('extra', { ...form.$('start-age').extra, optionList })
              Utils.updateBi(keys.START_AGE, optionList.toString(), data.data.product_id, form, data)
            }
          },
        },
        {
          name: "end-age",
          label: "End Age",
          placeholder: "",
          disabled: false,
          extra: {
            hide: false,
            optionList: [""],
            onChangeEndAge(value, listIndex, form, data) {
              let optionList = Utils.onChangeWithDrawal(value, 'end-age', listIndex, form)
              form.$('end-age').set('extra', { ...form.$('end-age').extra, optionList })
              Utils.updateBi(keys.END_AGE, optionList.toString(), data.data.product_id, form, data)
            }
          },
        },
        {
          name: "income",
          label: "Income",
          placeholder: "",
          disabled: true,
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremium(form, data) { },
          },
        },

        // Riders
        {
          name: "ADB",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: false,
          extra: {
            hide: false,
            description: Constants.ADB_RIDER_DESCRIPTION,
            paramName: "ADBRider",
            rider_name: "ADB Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ADB").value;
              form.$("ADB").value = !value;

              if (form.$("ADB").value) {
                form
                  .$("ADB-dropdown")
                  .set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("ADB-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "ADB-dropdown",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: "",
          extra: {
            optionList: [],
            onBlur(form, data) {
              let value = form.$("ADB-dropdown").value
              Utils.updateBi(keys.ADB_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "ATPDRider",
          label: "Permanent Disability",
          placeholder: "",
          value: false,
          extra: {
            hide: false,
            description: Constants.ATPD_RIDER_DESCRIPTION,
            paramName: "ATPDRider",
            rider_name: "ATPD Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ATPDRider").value;
              form.$("ATPDRider").value = !value;

              if (form.$("ATPDRider").value) {
                form
                  .$("ATPDRider-dropdown")
                  .set(
                    "value",
                    Constants.RIDERS_DEFAULT_SA
                  );
              } else {
                form.$("ATPDRider-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "ATPDRider-dropdown",
          label: "Permanent Disability",
          placeholder: "",
          value: "",
          extra: {
            optionList: [],
            onBlur(form, data) {
              let value = form.$("ATPDRider-dropdown").value
              Utils.updateBi(keys.ATPD_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: 'CriticalIllnessRider',
          label: 'Critical Illness',
          placeholder: '',
          value: false,
          extra: {
            hide: false,
            description: Constants.CRITICAL_ILLNESS_RIDER_DESCRIPTION,
            paramName: 'CriticalIllnessRider',
            rider_name: 'Critical Illness Rider',
            changeRiderStatus(form, data) {
              let value = form.$('CriticalIllnessRider').value
              form.$('CriticalIllnessRider').value = !value

              if (form.$('CriticalIllnessRider').value) {
                form.$('CriticalIllnessRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
              } else {
                form.$('CriticalIllnessRider-dropdown').set('value', "")
              }
            }
          }
        },
        {
          name: 'CriticalIllnessRider-dropdown',
          label: 'Critical Illness',
          placeholder: '',
          value: "",
          extra: {
            optionList: [],
            onBlur(form, data) {
              let value = form.$("CriticalIllnessRider-dropdown").value
              Utils.updateBi(keys.Critical_ILLNESS_RIDER, value, data.data.product_id, form, data)
            },
          }
        },
        {
          name: 'IncomeBenefitRider',
          label: 'Income Benefit',
          placeholder: '',
          value: false,
          extra: {
            hide: false,
            description: Constants.INCOME_BENEFIT_RIDER_DESCRIPTION,
            paramName: 'IncomeBenefitRider',
            rider_name: 'Income Benefit Rider',
            changeRiderStatus(form, data) {
              let value = form.$('IncomeBenefitRider').value
              form.$('IncomeBenefitRider').value = !value

              if (form.$('IncomeBenefitRider').value) {
                form.$('IncomeBenefitRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
              } else {
                form.$('IncomeBenefitRider-dropdown').set('value', "")
              }
            }
          }
        },
        {
          name: 'IncomeBenefitRider-dropdown',
          label: 'Income Benefit',
          placeholder: '',
          value: "",
          extra: {
            optionList: [],
            onBlur(form, data) {
              let value = form.$("IncomeBenefitRider-dropdown").value
              Utils.updateBi(keys.INCOME_BENEFIT_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: 'WaiverOfPremiumRider',
          label: 'Waiver of Premium',
          placeholder: '',
          value: false,
          extra: {
            hide: false,
            description: Constants.WOP_RIDER_DESCRIPTION,
            paramName: 'WaiverOfPremiumRider',
            rider_name: 'Waiver of Premium Rider',
            changeRiderStatus(form, data) {
              let value = form.$('WaiverOfPremiumRider').value
              form.$('WaiverOfPremiumRider').value = !value

              let productId = data.data.product_id
              let wopRiderValue = '';

              if (!value) {
                wopRiderValue = '1';
              }
              form.$('WaiverOfPremiumRider').value = !value

              Utils.updateBi(keys.WOP_RIDER, wopRiderValue, productId, form, data)
            }
          }
        },
        {
          name: 'WaiverOfPremiumRider-dropdown',
          label: 'Waiver of Premium',
          placeholder: '',
          value: {},
          extra: {
            optionList: []
          }
        },
        {
          name: 'PayorWaiverBenefitRider',
          label: 'Payor Waiver Benefit',
          placeholder: '',
          value: false,
          extra: {
            hide: false,
            disabled: false,
            description: Constants.PWB_RIDER_DESCRIPTION,
            paramName: 'PayorWaiverBenefitRider',
            rider_name: 'Payor Waiver Benefit Rider',
            changeRiderStatus(form, data) {
              let value = form.$('PayorWaiverBenefitRider').value
              form.$('PayorWaiverBenefitRider').value = !value

              if (form.$('PayorWaiverBenefitRider').value) {
                form.$('PayorWaiverBenefitRider-dropdown').set('value', form.$('PayorWaiverBenefitRider-dropdown').extra.optionList[0])
              } else {
                form.$('PayorWaiverBenefitRider-dropdown').set('value', {})
              }
            }
          }
        },
        {
          name: 'PayorWaiverBenefitRider-dropdown',
          label: 'Payor Waiver Benefit',
          placeholder: '',
          value: {},
          extra: {
            optionList: [
              {
                id: 1,
                label: 'Option 1 (On Death)'
              },
              {
                id: 2,
                label: 'Option 2 (CI or ATPD)'
              },
              {
                id: 3,
                label: 'Option 3 (CI or ATPD or Death)'
              }
            ]
          }
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data)

    // Observer fields
    formObserver(this, data)
  }
}

export default GcapFormController;
