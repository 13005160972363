import React, { useEffect, Component } from "react";

import moment from "moment";
import { toJS } from "mobx"
import { observer } from "mobx-react";
import AuthStore from "modules/login/store/AuthStore"

import { Collapse } from "../../../../components";
import ProductTabComponent from "../optimization-card/ProductTabComponent";
import AllProducts from "../optimization-card/all-products";
import { Image } from "react-bootstrap";
import * as images from "constants/Images";
import getFamilyMemberNameFromFamilyObject from "utils/getFamilyMemberNameFromFamilyObject";
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import TotalPremium from "modules/choose-needs/components/total-premium/TotalPremium";
import Utils from "./utils";
import NeedTypeEnum from "constants/NeedTypeEnum";
import ChangeProductReasonModal from 'modules/family-protection/components/change-product-reason-modal/ChangeProductReasonModal';
import ChangeProductModal from 'modules/family-protection/components/change-product-modal/ChangeProductModal';
import isObjectEmpty from "modules/choose-needs/components/form/retirement-calculator/isObjectEmpty";

const MemberCard = observer(
  class MemberCard extends Component {
    state = {
      productName: '',
      showModalBackDrop: false,
      riskType: '',
      showAddLABtn: false,
      openCollapse: false,
      familyProductID: '',
      isCollpaseOpen:true
    }

    toggleCollapse = (value) => {
      this.setState({isCollpaseOpen:value})
    }

    capitalize = (str) => {
      if (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
      return "";
    };

    componentDidMount() {
      const { data, open } = this.props;
      let removedData = this.fetchRemovedData()
      this.setState({ productName: data.product_name, isProductDisabled: removedData.laRemoved || removedData.isRemoved, showAddLABtn: removedData.laRemoved, openCollapse: open && !removedData.laRemoved });
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.disabled !== this.props.disabled || prevProps.showAddLA !== this.props.showAddLA || prevProps.open !== this.props.open || prevState.riskType !== this.state.riskType) {
        let removedData = this.fetchRemovedData()
        this.setState({ isProductDisabled: this.props.disabled, showAddLABtn: removedData.laRemoved, openCollapse: this.props.open && !removedData.laRemoved });

        if(removedData.laRemoved || this.props.isNeedDisabled){
          this.setState({isCollpaseOpen:false})
        }else{
          this.setState({isCollpaseOpen:true})
        }

      }
    }

    fetchRemovedData = () => {
      const { data } = this.props;
      const { family_product_details_list: totalFamilyProductsList, journey_need: journeyNeed } = toJS(AuthStore.journeyData)
      let laRemoved = false
      let isRemoved = false
      if (Array.isArray(data.BI) && this.state.riskType) {
        const productData = data.BI.find((item) => item.risk === this.state.riskType)
        const product = totalFamilyProductsList.find(product => product.id === productData.family_product_id)
        laRemoved = product && product?.la_removed && product?.la_removed === true
        isRemoved = product && journeyNeed.find(item => item.need === product?.need)?.is_removed || false
      } else {
        const product = totalFamilyProductsList.find(product => product.id === data.family_product_id)
        laRemoved = product && product?.la_removed && product?.la_removed === true
        isRemoved = product && journeyNeed.find(item => item.need === product?.need)?.is_removed || false
      }
      return { laRemoved: laRemoved, isRemoved: isRemoved }
    }

    getAge = (dob) => {
      let age = 0;
      if (dob) {
        age = moment().diff(moment(dob), "years");
      }
      return age;
    };

    showBackDropFunction = () => {
      this.setState({ showModalBackDrop: true })
    }

    hideBackDropFunction = () => {
      this.setState({ showModalBackDrop: false })
    }

    riskTypeFunction = (selectedTab) => {
      OptimizationStore.fetchIndividualProductTimeLineReqParam = { "family_product_id": this.state.familyProductID, "risk_type": selectedTab.split(' ')[0].toLowerCase() }
      this.setState({ riskType: selectedTab.split(' ')[0].toLowerCase() })
    }

    changeProposerDetailsDisabled() {
      let changeProductDisabled = false
      if(this.props.cardKey === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY) {
        changeProductDisabled = true
      } else if (this.props.cardKey === NeedTypeEnum.PROTECTION_FOR_MY_HEALTH) {
        changeProductDisabled =  ![5,7].includes(this.props.data.life_assured_details.occupation)
      }
      return changeProductDisabled
    }

    render() {
      const { index, disabled, open, onChange, data, cardKey, onAddMember, onAddInsured, getProposerList, showAddLA } = this.props;
      const { isProductDisabled, showAddLABtn, openCollapse } = this.state
      const age = this.getAge(data.dob);

      const proposerDetails = data.proposer_details;
      const lifeAssuredDetails = data.life_assured_details;
      let productName = data.product_name;
      let productId = data.product_id;
      let isErrorExist = data.isErrorExist
      let familyProductId = data.family_product_id;

      const proposerAge = this.getAge(proposerDetails.dob);
      const proposerName = getFamilyMemberNameFromFamilyObject(proposerDetails);
      const lifeAssuredName = getFamilyMemberNameFromFamilyObject(lifeAssuredDetails);

      let productIdArray;
      if (Array.isArray(this.props.data.BI)) {
        productIdArray = this.props.data.BI.map((singleProduct) => singleProduct.product_id);
      } else {
        productIdArray = [this.props.data.product_id];
      }

      let productData = data;
      let biResponse = productData.parsedResponse;
      let firstYearResponse = productData.firstYearResponse;
      let biRequest = productData.bi_request;
      let totalPremium = false;
      if (biRequest && firstYearResponse) {
        totalPremium = Utils.calcualateTotalPremiumComponentData(firstYearResponse, biResponse, biRequest, productName, productData);
      }

      // ! logic for total premium for tab 
      if (Array.isArray(productData.BI)) {

        // !data => parsedResponse,firstYearResponse (for zindagi plus)
        // !data => BI => [{parsedResponse,firstYearResponse},{parsedResponse,firstYearResponse},{parsedResponse,firstYearResponse}] (for wealthGainPlus)

        let RiskIndex
        // this risk index is not in sync wit bi response data of productData?.BI but is used for making total premium component show correct data.
        switch (this.state.riskType) {
          case 'low':
            RiskIndex = 0
            break;

          case 'medium':
            RiskIndex = 1
            break;

          case 'high':
            RiskIndex = 2
            break;

          default:
            break;
        }

        productName = productData?.BI[RiskIndex]?.product_name
        productId = productData?.BI[RiskIndex]?.product_id
        isErrorExist = productData?.BI[RiskIndex]?.isErrorExist
        familyProductId = productData?.BI[RiskIndex]?.family_product_id

        if(this.state.familyProductID !== familyProductId){
          this.setState((preState) => {
            if (preState.familyProductID !== familyProductId) {
              return { familyProductID: familyProductId }
            }
          })
        }

        const biResponse = productData?.BI[RiskIndex]?.parsedResponse
        const biRequest = productData?.BI[RiskIndex]?.bi_request
        const firstYearResponse = productData?.BI[RiskIndex]?.firstYearResponse

        totalPremium = Utils.calcualateTotalPremiumComponentData(firstYearResponse, biResponse, biRequest, productName, productData);
      }
      const { family_product_details_list: totalFamilyProductsList } = toJS(AuthStore.journeyData)
      //const laRemoved = totalFamilyProductsList.find(item => item.need_unique_name === cardKey).la_removed

      return (
        <>
          <Collapse
            key={index}
            isNeedDisabled={this.props.isNeedDisabled}
            keyIndex={index}
            toggleCollapse={this.toggleCollapse}
            showAddLA={showAddLABtn}
            disabled={isProductDisabled}
            open={this.state.isCollpaseOpen}
            onChange={onChange}
            hideBackDropFunction={!isProductDisabled}
            showModalBackDrop={isProductDisabled || this.state.showModalBackDrop}
            addLA={() => this.props.addLA(productName, familyProductId)}
            title={
              this.state.isCollpaseOpen ? (
                <div className='d-flex flex-column flex-md-row justify-content-between' style={{ maxWidth: "900px" }}>
                  <div className={`select-deselect-btn align-self-center ${disabled ? "text-gray" : "text-description-text-color"}`}>
                    <div className='d-flex flex-wrap align-items-center'>
                      <p className='font-size-14 mb-0 mr-1'>Life Assured:</p>
                      <p className={`font-weight-bold font-size-16 mr-md-1 mb-0 order-3 order-lg-1 ${disabled ? "text-gray" : "text-secondary"}`}>
                        {lifeAssuredName}
                        <span className='text-dark ml-1 mb-0'>
                          {age}
                          <span className='font-weight-light ml-1 mb-0'>yrs</span>
                        </span>
                      </p>
                      <button
                        className='btn text-primary p-0 LA-btn font-size-14 font-size-md-12 font-weight-light mr-2 order-1 order-lg-2'
                        onClick={() => { OptimizationStore.setSelectedNeed(cardKey); OptimizationStore.getEligibleLaList(productIdArray, cardKey); OptimizationStore.setSelectedNeedCardData(data) }}
                      >
                        [<Image width='12' className='mx-1' src={images.EditIconLink} alt='back-arrow' /> Change LA ]
                      </button>

                      <button
                        className='btn text-primary p-0 LA-btn font-size-14 font-size-md-12 font-weight-light order-2 order-lg-3'
                        onClick={() => this.props.removeLA(productName, familyProductId)}
                      >
                        [<Image width='10' className='mx-1' src={images.CloseIconLink} alt='back-arrow' />
                        Remove LA ]
                      </button>
                    </div>
                  </div>

                  <div className='d-none d-md-block border-right border-dark mx-md-4'></div>

                  <div className={`mt-3 mt-md-0 select-deselect-btn ${disabled ? "text-gray" : "text-description-text-color"}`}>
                    <div className='d-flex flex-wrap align-items-center'>
                      <p className='font-size-14 mr-1 mb-0'>Proposer:</p>
                      <p className={`font-weight-bold font-size-16 mr-1 mb-0 order-2 order-lg-1 ${disabled ? "text-gray" : "text-dark"}`}>
                        {proposerName}
                        <span className='text-dark'>
                          {" "}
                          {proposerAge}
                          <span className='font-weight-light ml-1'>yrs</span>
                        </span>
                      </p>

                      <button
                        className={`btn p-0 LA-btn font-size-12 font-weight-light mr-2 order-1 order-lg-2 ${this.changeProposerDetailsDisabled() ? 'text-gray' : 'text-primary'}`}
                        onClick={() => { OptimizationStore.getEligibleProposerList(productIdArray, cardKey, data.id); OptimizationStore.setSelectedNeedCardData(data); }}
                        disabled={this.changeProposerDetailsDisabled()}
                      >
                        [
                        <Image width='12' className='mx-1' src={images.EditIconLink} alt='back-arrow' />
                        Change Proposer ]
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='d-flex flex-column flex-md-row justify-content-between' style={{ maxWidth: "900px" }}>
                  <div className={`select-deselect-btn align-self-center ${disabled ? "text-gray" : "text-description-text-color"}`}>
                    <div className='d-flex flex-wrap align-items-center'>
                      <p className={`font-weight-bold font-size-16 mr-md-1 mb-0 order-3 order-lg-1 ${disabled ? "text-gray" : "text-secondary"}`}>
                        {lifeAssuredName}
                        <span className='text-dark ml-1 mb-0'>
                          {age}
                          <span className='font-weight-light ml-1 mb-0'>yrs</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )
            }
          >
            <React.Fragment>
              {!Array.isArray(data.BI) && productName ? (
                <div>
                  <AllProducts productName={productName} data={data} cardKey={cardKey} onAddMember={onAddMember} onAddInsured={onAddInsured} />
                </div>
              ) : data.BI.length > 0 ? (
                <ProductTabComponent data={data} cardKey={cardKey} onAddMember={onAddMember} onAddInsured={onAddInsured} showBackDropFunction={this.showBackDropFunction}
                  hideBackDropFunction={this.hideBackDropFunction}
                  showModalBackDrop={this.state.showModalBackDrop}
                  onTabSelected={this.riskTypeFunction}
                />
              ) : (
                <div className='max-cover-amount-msg'>No right match found. Kindly re-evaluate</div>
              )}
            </React.Fragment>
          </Collapse>
              

          {(totalPremium && !productData.isErrorExist && !isObjectEmpty(data.BI) && !isProductDisabled && this.state.isCollpaseOpen) && <TotalPremium {...totalPremium} />}

          <ChangeProductReasonModal
            show={OptimizationStore.isReasonModalOpen}
            data={data}
          />

          <ChangeProductModal
            show={OptimizationStore.isChangeProductModalOpen}
            data={data}
            cardKey={cardKey}
          />
        </>
      );
    }
  }
);

export default MemberCard;
