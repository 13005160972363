export default {
  ZINDAGI_PLUS: "Zindagi Plus",
  SIMPLY_PROTECT: "Simply Protect",
  ACTIVE_INCOME_PLAN: "Active Income Plan",
  THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN: "Three Generation Plan (Active Income Plan)",
  ACTIVE_INCOME_PLAN_WITH_FIB: "Active Income Plan with FIB",
  WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN: "Whole Life Active Income (Active Income Plan)",
  RETIRE_BEFIKER_ACTIVE_INCOME_PLAN: "Retire Befiker (Active Income Plan)",
  WEALTH_ULTIMA: "Wealth Ultima",
  SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA: "Systematic Withdrawal Plan (Wealth Ultima)",
  LITTLE_CHAMP_WEALTH_ULTIMA: "Little Champ (Wealth Ultima)",
  CRITICARE_PLUS: "Criticare+",
  SMART_LIFESTYLE: "Smart Lifestyle",
  FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE: "Family Protection Option (Smart Lifestyle)",
  GCAP: "GCAP",
  CASH_FLOW_PROTECTION_PLUS: "Cash Flow Protection plus",
  WEALTH_GAIN_PLUS: "Wealth Gain+",
  WEALTH_SECURE_PLUS: "Wealth Secure+",
  CHILD_VARIANT_WEALTH_SECURE_PLUS: "Child variant (Wealth Secure+)",
  SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS: "Systematic Withdrawal Plan (Wealth Secure+)",
  DHAN_LABH: "Dhan Labh",
  WEALTH_PLUS: "Wealth Plus",
  RISING_STAR_WEALTH_PLUS: "Rising Star (Wealth Plus)",
  INCOME_BUILDER: "Income Builder",
  SECURED_INCOME_OPTION_INCOME_BUILDER: "Secured Income Option (Income Builder)",
  IMMEDIATE_ANNUITY_PLAN: "Immediate Annuity Plan",
  EASY_PENSION: "Easy Pension",
  SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN: "Single pay endowment assuarance plan",
  POS_SARAL_NIVESH: "POS - Saral Nivesh",
  WEALTH_PREMIER: "Wealth Premier",
  PROTECTION_REG_5_10_15PAY: "Protection  - (Reg/5/10/15Pay)",
  SARAL_JEEVAN_BIMA: "Saral Jeevan Bima",
  POS_SARAL_JEEVAN_BIMA: "POS Saral Jeevan Bima",
  SARAL_PENSION: "Saral pension",
  FOREVER_PENSION: "Forever Pension",
  POS_FOREVER_PENSION: "POS Forever Pension",
  FOREVER_PENSION_DEFERRED_ANNUITY: "Forever Pension Deferred Annuity",
  FOREVER_PENSION_IMMEDIATE_ANNUITY: "Forever Pension Immediate Annuity",
  TOTAL_PROTECT_PLUS: "Total Protect Plus",
  PREMIER_GUARANTEED_SHORT_TERM_INCOME: "Premier Guaranteed Income(Short Term Income)",
  PREMIER_GUARANTEED_LONG_TERM_INCOME: "Premier Guaranteed Income(Long Term Income)",
  PREMIER_GUARANTEED_INCOME_LUMPSUM: "Premier Guaranteed Income(Lumpsum)",
  PREMIER_GUARANTEED_RETIREMENT_INCOME: "Premier Guaranteed Income(Retirement Income)",
  PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB: "Premier Guaranteed Income(Lumpsum)(FIB)",
  PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB: "Premier Guaranteed Income(Short Term Income)(FIB)",
  PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB: "Premier Guaranteed Income(Long Term Income)(FIB)",
  GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED: "Guaranteed Income STAR (Milestones Guaranteed)",
  GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED: "Guaranteed Income STAR (Cashback Guaranteed)",
  GUARANTEED_INCOME_STAR_BETTER_INNINGS: "Guaranteed Income STAR (Better Innings)",
  GUARANTEED_INCOME_STAR: "Guaranteed Income STAR",
  GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB: "Guaranteed Income STAR (Cashback Guaranteed) (FIB)",
  GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB: "Guaranteed Income STAR (Better Innings) (FIB)",
  GUARANTEED_INCOME_STAR_FIB: "Guaranteed Income STAR (FIB)",
  POS_GUARANTEED_INCOME_STAR: "POS Guaranteed Income STAR",
  POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED: "POS Guaranteed Income STAR (Cashback Guaranteed)",
  POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS: "POS Guaranteed Income STAR (Better Innings)",
  GUARANTEED_SAVINGS_STAR_LUMPSUM: "Guaranteed Savings STAR (Lumpsum)",
  GUARANTEED_SAVINGS_STAR_INCOME: "Guaranteed Savings STAR (Income)",
  GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB: "Guaranteed Savings STAR (Lumpsum FIB)",
  GUARANTEED_SAVINGS_STAR_INCOME_FIB: "Guaranteed Savings STAR (Income FIB)",
  POS_GUARANTEED_SAVINGS_STAR: "POS Guaranteed Savings STAR",
  FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL: "Flexi Savings Plan (Child Systematic withdrawal)",
  FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL: "Flexi Savings Plan (Child Age based withdrawal)",
  FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL: "Flexi Savings Plan (Child Age + Income based withdrawal)",
  FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME: "Flexi Savings Plan (Child Flexi-Income)",
  FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO: "Flexi Savings Plan (Child Flexi-Income Pro)",
  FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION: "Flexi Savings Plan (Child Large Sum Option)",
  FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL: "Flexi Savings Plan (Systematic withdrawal)",
  FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL: "Flexi Savings Plan (Age based withdrawal)",
  FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL: "Flexi Savings Plan (Age + Income based withdrawal)",
  FLEXI_SAVINGS_PLAN_FLEXI_INCOME: "Flexi Savings Plan (Flexi-Income)",
  FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO: "Flexi Savings Plan (Flexi-Income Pro)",
  FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION: "Flexi Savings Plan (Large Sum Option)",
};
