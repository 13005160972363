export default {
    'valid_plan_options': {
        'atpd': "Death Benefit Option which is chosen is invalid, Maximum PPT Ceasing Age is 70 years for the choosen Death Benefit Option",
        'criticalIllness': "Death Benefit Option which is chosen is invalid, Maximum PPT Ceasing Age is 70 years for the choosen Death Benefit Option",
        'adb':'Maximum Maturity Age is 70 years for the choosen Death Benefit Option'
    },
    'normalPt': {
        '10pay': 'For 10 pay, you can only select 15 to 40 years Policy Term',
        '15pay': 'For 15 pay, you can only select 20 to 40 years Policy Term',
        '20pay': 'For 20 pay, you can only select 25 to 40 years Policy Term',
        'otherpay': 'You can only select from 10 to 40 years policy term'
    },
    'uptoAge80': {
        '15pay': "You can't select 15 pay. Maximum entry age is 60 Years",
        '20pay': "You can't select 20 pay. Maximum entry age is 50 Years",
        'otherPay': 'You can only select 15 Pay or 20 Pay'
    },
    'minimumPremium': 'Minimum premium for simply protect is 3,000',
    'ptPpt': 'PTPPT combination could not be determined for given inputs'
}