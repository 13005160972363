import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";
import { observer } from "mobx-react";

import { BottomsheetDropdown, ChangeProductSection } from "components";
import AmountCalculation from "../../optimization-card/amount-calculation/AmountCalculation";
import DeathCoverGraphSlider from "modules/family-protection/components/family-protection-card/DeathCoverGraphSlider";

import Utils from "./utils";
import { CustomCheckbox } from "../../../../../components";
import AdditionalRiderBenifitsCard from "../protection/component/additional-riders/AdditionalRiderBenifitsCard";
import AddLifeCover from "../../addLifeCover/AddLifeCover";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";

const Protection = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false)

  let productData = data.data;
  let biResponse = productData.parsedResponse;
  let biRequest = productData.bi_request;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])

  const onHideOverlay = () => {
      setShowOverlay(false)
  }

  return (
    <div className="save-my-children">
      <Row className="align-items-center mt-md-4">
        <Col xs={12} md={6} className="col-12">
        <ChangeProductSection 
          productName={productData.product_name} 
          cardName={coverLabelBaseOnNeed}
          changeProductList={productData.changeProductList}
          productData={data}
        />
        </Col>
        <Col md={6} className="col-12">
          <div className="">
            <div className="d-flex align-items-center">
              <div className=" p-md-3 d-flex flex-md-column align-items-center align-items-md-start w-100">
                <span className="mr-3 font-size-12 font-weight-light font-size-md-14 font-size-lg-16 text-description-text-color">
                  {form.$("tobacco-user").label}
                </span>
                <div className="">
                  {form.$("tobacco-user").options.map((item, index) => {
                    return (
                      <CustomCheckbox
                        key={index}
                        className="mr-2"
                        isChecked={form.$("tobacco-user").value.id === item.id}
                        onChange={() => form.$("tobacco-user").onChange(item)}
                        titleStyle="font-size-12 font-size-md-14 font-size-lg-16"
                        shape="rounded"
                        title={item.label}
                        mark="checkmark"
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>

          <AddLifeCover form={form}/>

      {/* <DeathCoverGraphSlider
        productTitle="Active Income"
        openChangeReasonModal={false}
        onIncomeChange={() => {}}
        onPremiumChange={form.$("sum_assured").onChange}
        hideOverLay={() => {}}
        productID=""
        income_value=""
        premium_value={form.$("sum_assured").value}
        showOverlay=""
        secondInputTextlabel={form.$("sum_assured").label}
        onClick={() =>
          form
            .$("sum_assured")
            .extra.calculatePremiun(form, productData.product_id, data)
        }
        data=""
        isIncomeFieldDisable={form.$("sum_assured").disabled}
        premiumFreq={form.$("payment-frequency").value.label}
      /> */}

      <Row className="mt-2 mt-md-4">
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("policy-term").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("policy-term").label}
              menuList={form.$("policy-term").extra.optionList}
              value={form.$("policy-term").value}
              onSelect={form.$("policy-term").onChange}
              disabled=""
            />
          </div>
        </Col>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("premium-term").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("premium-term").label}
              menuList={form.$("premium-term").extra.optionList}
              value={form.$("premium-term").value}
              onSelect={form.$("premium-term").onChange}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2 mt-md-4">
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("payment-frequency").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("payment-frequency").label}
              menuList={form.$("payment-frequency").extra.optionList}
              value={form.$("payment-frequency").value}
              onSelect={form.$("payment-frequency").onChange}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6} className="col-12 mt-4 mt-md-0">
          <AdditionalRiderBenifitsCard data={data} form={form} />
        </Col>
      </Row>
      <AmountCalculation
        cardKey={data.cardKey}
        item={productData}
        gst={biResponse.TAX_MP}
        gst_percentage={biResponse.TAX_RATE * 100}
        total_price={Utils.getTotalPrice(biResponse, biRequest)}
        totalPremium={Number(biResponse?.MODAL_PREM) || 0}
        riderList={biResponse.inputValidationStatus}
        riderTax={Utils.getRiderTax(productData)}
      />
    </div>
  );
};

export default observer(Protection);
