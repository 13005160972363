
// Project Imports
import { MOBILE_NO_VALIDATION, PAN_NUMBER_REGEX } from "constants/Constants";
import CustomerTypeConstant from "constants/CustomerTypeConstant";

const FormObserver = (formRef) => {

  formRef.$('category').observe({
    key: 'value',
    call: ({ field }) => {
      formRef.$("employee_code").value = '';
      //formRef.$("education").value = {};
      //formRef.$("occupation").value = {};
    }
  })

  formRef.$('customer_type').observe({
    key: 'value',
    call: ({ field, form }) => {
      if(form.$("customer_type").value == CustomerTypeConstant.EXISTING_CUSTOMER){
        form.$("education").value = {};
        form.$("occupation").value = {};
      }else{ 
        let educationList = form.$("education").extra.optionList;          
        form.$("education").value = educationList[1];

        let occupationList = form.$("occupation").extra.optionList;          
        form.$("occupation").value = occupationList[0]; 
      }      
      form.$("location").value = [];
    }
  })

  formRef.$('existing_customer_type_option_list').observe({
    key: 'value',
    call: ({ field, form }) => {
      let currentValue = field.value.label
      let placeholderValue = "";

      let dataType = "text"

      switch (currentValue) {
        case 'Policy No.':
          placeholderValue = "Enter Policy Number"
          break
          
        case 'Mobile No.':
          placeholderValue = "Enter Mobile Number"
          dataType = "number"
          
          break

        case 'PAN No.':
          placeholderValue = "Enter PAN Number"
          break

        case 'Customer ID':
          placeholderValue = "Enter Customer ID"
          break

      }
      form.$("existing_customer_type_option_list").set("type", dataType)
      form.$("existing_customer_type_option_list").set("placeholder", placeholderValue)
      formRef.$("existing_customer_value").value = '';
    }
  })

  formRef.$('existing_customer_value').observe({
    key: 'value',
    call: ({ field, form }) => {

      let selectedOption = formRef.$('existing_customer_type_option_list').value.id

      let currentValue = field.value.toUpperCase();

      switch (selectedOption) {

        case "1": // ! FOR MOBILE NO VALIDATION

          const mobileNoValue = currentValue.replace(/[^0-9 ]/g, "")
          formRef.$("existing_customer_value").set('value',mobileNoValue)
  
          if(!(MOBILE_NO_VALIDATION.test(mobileNoValue))){
            formRef.$("existing_customer_value").invalidate('Please enter a valid mobile Number')
          }else if(currentValue.length > 10){
            formRef.$("existing_customer_value").invalidate('Please enter a valid mobile Number')
          }

        break

        case "2": // ! FOR PAN NO VALIDATION
          formRef.$("existing_customer_value").set('value',currentValue.replace(/[^a-zA-Z0-9 ]/g, ""))

          if(!(PAN_NUMBER_REGEX.test(currentValue))){
            formRef.$("existing_customer_value").invalidate('Please enter a valid Pan Number')
          }else if(currentValue.length > 10){
            formRef.$("existing_customer_value").invalidate('Please enter a valid Pan Number')
          }            

        break;
      
        default:
          break;
      }
      
    }
  })

}

export default FormObserver