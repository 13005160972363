import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from './validator';

class Utils {
  getPolicyTerm = () => {
    let pt = [];
    for (let i = 10; i <= 67; i++) {
      pt.push({ id: i, label: "" + i });
    }
    return pt;
  };

  getPremiumTermList = (pt) => {
    let pptlist = [
      {
        id: 1,
        label: 1,
      },
    ];
    for (let i = 5; i <= (pt - 5); i++) {
      pptlist.push({ id: i, label: "" + i });
    }
    pptlist.push({
      id: pt,
      label: pt,
    });

    return pptlist;
  };

  updateBi(key, value, productId, formRef, productData) {

    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request
    switch (key) {
      case keys.PR_PT:
        biRequest[keys.PR_PT] = value;
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.FREQUENCY:
        // biRequest[keys.PREMIUM] = value;
        biRequest[keys.FREQUENCY] = value.frequency;
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value;
        break;

      case keys.ANNUITY_OPTION:
        biRequest[keys.ANNUITY_OPTION] = value;
        break;

      case keys.FUNDSTRATEGY:
        // biRequest[keys.FUNDSTRATEGY] = value;
        biRequest[keys.FUNDSTRATEGY_ID] = value
        break;

      default:
        return;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value);
  }

  getTotalPrice = (data) => {
    try {
      return data.ANN_PREM;
    } catch (e) {
      return 0;
    }
  };

  // fetchPolicyTimeline(element, biJson2Data) {
  //   let data = {
  //     youPay: Math.round(element.ANN_PREM_YEARLY),
  //     youGet: Math.round(biJson2Data.FINAL_FV),
  //     lifeCover: Math.round(biJson2Data.DEATH_BEN),
  //     isMilestone: Math.round(biJson2Data.FINAL_FV) !== 0,
  //     premiumAmt: Math.round(element.MODAL_PREM)
  //   }
  //   return data
  // }

  newPolicyTimeLineData(element, inputString, index) {

    const { DEATH_BEN, ANN_PREM_YEARLY, PENSION_AMT_TVB } = element

    let data = {
      youPay: Math.round(ANN_PREM_YEARLY),
      youGet: Math.round(PENSION_AMT_TVB),
      lifeCover: Math.round(DEATH_BEN)
    }

    return data
  }
}

export default new Utils();
