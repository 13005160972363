const { Form } = require("mobx-react-form");

import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

import formObserver from './observers';
import setInitialDefaults from "./initialDefaults.js";
import * as keys from "./keys";
import Utils from "./utils";

class ProtectionFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "tobacco-user",
          label: "Tobacco Consumer?",
          type: "radio",
          options: [
            {
              id: "1",
              label: "Yes",
            },
            {
              id: "0",
              label: "No",
            },
          ],
        },
        {
          name: "policy-term",
          label: "Policy term",
          placeholder: "",
          rules: "required:string",
          disabled: false,
          extra: {
            optionList: [],
          },
        },
        {
          name: "premium-term",
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            optionList: [],
          },
        },
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Annual",
              },
              {
                id: "5",
                label: "Single",
              }
            ],
          },
        },
        {
          name: "income",
          label: "Income",
          placeholder: "",
          disabled: true,
        },
        {
          name: "sum_assured",
          label: "Sum Assured",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremiun(form, productId, data) {
              // let premiumAmount = 0;
              // let inputMode = Number(form.$("payment-frequency").value.id);

              // let amount = Number(form.$("sum_assured").value);
              // switch (inputMode) {
              //   case 1:
              //     premiumAmount = "" + amount * 1;
              //     break;
              //   case 2:
              //     premiumAmount = "" + amount * 2;
              //     break;
              //   case 3:
              //     premiumAmount = "" + amount * 4;
              //     break;
              //   case 4:
              //     premiumAmount = "" + amount * 12;
              //     break;
              // }
              // Utils.updateBi(
              //   keys.Sum_Assured,
              //   premiumAmount,
              //   productId,
              //   form,
              //   data
              // );
            },
          },
        },
        // Rider
        {
          name: "wop",
          label: "Waiver of Premium",
          placeholder: "",
          value: false,
          disabled: false,
          extra: {
            description: "Simple life insurance cove.",
            paramName: "WaiverOfPremiumRider",
            rider_name: "Waiver of Premium Rider",
            changeRiderStatus(form, data) {
              let value = form.$("wop").value;
              let productId = data.data.product_id;
              let wopRiderValue = "";

              if (value) {
                wopRiderValue = "1";
              }
              form.$("wop").value = !value;

              Utils.updateBi(
                keys.WOP_RIDER,
                wopRiderValue,
                productId,
                form,
                data
              );
            },
          },
        },
        {
          name: "ADB",
          label: "Accidental Death Benefit Rider",
          placeholder: "",
          value: false,
          extra: {
            description:
              "Provide enhanced financial security to your loved ones in case of death due to an accident.",
            paramName: "ADBRider",
            rider_name: "ADB Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ADB").value;
              form.$("ADB").value = !value;

              if (form.$("ADB").value) {
                form
                  .$("ADB-dropdown")
                  .set("value", form.$("ADB-dropdown").extra.optionList[0]);
              } else {
                form.$("ADB-dropdown").set("value", {});
              }
            },
          },
        },
        {
          name: "ADB-dropdown",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: {},
          extra: {
            optionList: Utils.getAdbList(),
          },
        },
        {
          name: "ATPDRider",
          label: "Permanent Disability",
          placeholder: "",
          value: false,
          extra: {
            description:
              "You will get a lump sum amount to manage your expenses in case an accidental disability has hindered your earning capacity.",
            paramName: "ATPDRider",
            rider_name: "ATPD Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ATPDRider").value;
              form.$("ATPDRider").value = !value;

              if (form.$("ATPDRider").value) {
                form
                  .$("ATPDRider-dropdown")
                  .set(
                    "value",
                    form.$("ATPDRider-dropdown").extra.optionList[0]
                  );
              } else {
                form.$("ATPDRider-dropdown").set("value", {});
              }
            },
          },
        },
        {
          name: "ATPDRider-dropdown",
          label: "Permanent Disability",
          placeholder: "",
          value: {},
          extra: {
            optionList: Utils.getAtpdList(),
          },
        },
        {
          name: "CriticalIllnessRider",
          label: "Critical Illness Rider",
          placeholder: "",
          value: false,
          extra: {
            description:
              "Upon diagnosis with one of the 12 specified Critical Illnesses, you will receive a pay out of the critical illness amount you’ve selected.",
            paramName: "CriticalIllnessRider",
            rider_name: "Critical Illness Rider",
            changeRiderStatus(form, data) {
              let value = form.$("CriticalIllnessRider").value;
              form.$("CriticalIllnessRider").value = !value;

              if (form.$("CriticalIllnessRider").value) {
                form
                  .$("CriticalIllnessRider-dropdown")
                  .set(
                    "value",
                    form.$("CriticalIllnessRider-dropdown").extra.optionList[0]
                  );
              } else {
                form.$("CriticalIllnessRider-dropdown").set("value", {});
              }
            },
          },
        },
        {
          name: "CriticalIllnessRider-dropdown",
          label: "Critical Illness Rider",
          placeholder: "",
          value: {},
          extra: {
            optionList: Utils.getCriticalIllnessList(),
          },
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data)
  }
}

export default ProtectionFormController;
