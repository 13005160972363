const { Form } = require("mobx-react-form");

import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

import setInitialDefaults from "./initialDefaults";
import formObserver from './observers'

import Utils from './utils';
import * as keys from './keys';

class EasyPensionFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          disabled:false,
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Annual",
              },
              {
                id: "2",
                label: "Semi-Annual",
              },
              {
                id: "3",
                label: "Quarterly",
              },
              {
                id: "4",
                label: "Monthly",
              },
              // {
              //   id: "5",
              //   label: "Single",
              // },
            ],
          },
        },
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          disabled: false,
          value: "",
          extra: {
            optionList: [],
          },
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremiun(form, data) {
              // let premiumAmount = 0;
              // let inputMode = Number(form.$('payment-frequency').value.id)

              // let amount = Number(form.$('premium').value)
              // switch (inputMode) {
              //   case 1:
              //     premiumAmount = '' + amount * 1
              //     break;
              //   case 2:
              //     premiumAmount = '' + amount * 2
              //     break;
              //   case 3:
              //     premiumAmount = '' + amount * 4
              //     break;
              //   case 4:
              //     premiumAmount = '' + amount * 12
              //     break;
              // }

              // Utils.updateBi(keys.PREMIUM, premiumAmount, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "premium-term",
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            optionList: [],
          },
        },
        {
          name: "annuity-option",
          label: "Annuity Option",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "2",
                label: "Life Annuity with Return of Purchase Price",
              },
              {
                id: "3",
                label: "Life Annuity",
              },
              {
                id: "4",
                label: "Life Annuity Certain for 5 years",
              },
              {
                id: "5",
                label: "Life Annuity Certain for 10 years",
              },
              {
                id: "6",
                label: "Life Annuity Certain for 15 years",
              },
              {
                id: "7",
                label: "Life Annuity Certain for 20 years",
              },
              {
                id: "8",
                label:
                  "Life Annuity increasing at a simple rate of 3% per annum",
              },
              {
                id: "9",
                label:
                  "Life Annuity increasing at a simple rate of 5% per annum",
              },
            ],
          },
        },
        {
          name: "fund-strategy",
          label: "Fund Strategy",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Aggressive",
              },
              {
                id: "2",
                label: "Conservative",
              },
            ],
          },
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data);
  }
}

export default EasyPensionFormController;
