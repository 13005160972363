// React Imports
import { useContext, useEffect, useState } from "react";

// Package Imports
import { observer } from "mobx-react";
import { Col, Row } from "react-bootstrap";

// Project Imports
import FormContext from "context/FormContext";
import GrowMyMoney from "../../grow-my-money/GrowMyMoney";
import ProtectionOfMyFamily from "../../protection-of-my-family/ProtectionOfMyFamily";
import ProtectionOfMyHealth from "../../protection-of-my-health/ProtectionOfMyHealth";
import SaveForChildren from "../../save-for-children/SaveForChildren";
import SaveForMyRetirement from "../../save-for-my-retirement/SaveForMyRetirement";
import Utils from "./utils";
import ChooseNeedFormContext from "context/ChooseNeedFormContext";

const ChooseNeedsForm = ({ formController, location }) => {
  const [dateOfBirthFromChooseNeeds, setDateOfBirthFromChooseNeeds] = useState(null)
  const [monthlyExpenseValuesFromChooseNeeds, setMonthlyExpenseValuesFromChooseNeeds] = useState({});

  const formContext = useContext(FormContext)

  useEffect(() => {
    formContext.setCallbackFunctions({
      success: Utils.submitDataToTheServer,
      error: undefined
    })
  }, [])

  return (
    <ChooseNeedFormContext.Provider value={formContext}>
      <>
        <p className='text-danger mb-0 font-weight-light font-size-11 align-items-start d-flex'>
          {formController.$("journey_id").error}
        </p>
        <div className='pl-lg-4 ml-lg-2'>

          <div className="position-relative  mt-lg-3">
            <div className='container-title-retirement font-size-14 font-weight-light'>Retirement</div>
            <Row className='retirement-container mb-3 mb-lg-0'>
              <Col className='col-12 col-lg-6 col'>
                <SaveForMyRetirement
                  dateOfBirthFromChooseNeeds={dateOfBirthFromChooseNeeds}
                  setDateOfBirthFromChooseNeeds={setDateOfBirthFromChooseNeeds}
                  monthlyExpenseValuesFromChooseNeeds={monthlyExpenseValuesFromChooseNeeds}
                  setMonthlyExpenseValuesFromChooseNeeds={setMonthlyExpenseValuesFromChooseNeeds}
                  isSelected={!formController.$("save_for_retirement").disabled}
                  toggleNeedSelection={(currentDisabledState) => Utils.toggleDisabledState(formController, "save_for_retirement", currentDisabledState)}
                  formController={formController}
                  disabled={false}
                  useValueCallback={(value) => Utils.updateTargetAmountValue(formController, "save_for_retirement", value)}
                />
              </Col>
            </Row>
          </div>

          <div className="position-relative  mt-lg-3">
            <div className='container-title-investment font-size-14 font-weight-light'>Investment</div>
            <Row className='investment-container mb-3 mb-lg-0'>
              <Col className='col-12 col-md-6 mt-3 mb-4 mb-md-0 mt-md-0'>
                <SaveForChildren
                  isSelected={!formController.$("save_for_children").disabled}
                  toggleNeedSelection={(currentDisabledState) => Utils.toggleDisabledState(formController, "save_for_children", currentDisabledState)}
                  formController={formController}
                />
              </Col>
              <Col className='col-12 col-md-6'>
                <GrowMyMoney
                  isSelected={!formController.$("grow_my_money").disabled}
                  toggleNeedSelection={(currentDisabledState) => Utils.toggleDisabledState(formController, "grow_my_money", currentDisabledState)}
                  formController={formController}
                />
              </Col>

            </Row>
          </div>


          <div className="position-relative mt-lg-3">
            <div className='container-title-protection font-size-14 font-weight-light'>Protection</div>
            <Row className='protection-container mb-3 mb-lg-0'>
              <Col className='col-12 col-lg-7'>
                <ProtectionOfMyFamily
                  dateOfBirthFromChooseNeeds={dateOfBirthFromChooseNeeds}
                  setDateOfBirthFromChooseNeeds={setDateOfBirthFromChooseNeeds}
                  monthlyExpenseValuesFromChooseNeeds={monthlyExpenseValuesFromChooseNeeds}
                  setMonthlyExpenseValuesFromChooseNeeds={setMonthlyExpenseValuesFromChooseNeeds}
                  isSelected={!formController.$("protection_for_my_family").disabled}
                  toggleNeedSelection={(currentDisabledState) => Utils.toggleDisabledState(formController, "protection_for_my_family", currentDisabledState)}
                  onMonthlyIncomeChange={formController.$("protection_for_my_family.monthly_income").onChange}
                  monthlyIncomeValue={formController.$("protection_for_my_family.monthly_income").value}
                  targetAmountTypeList={formController.$("protection_for_my_family.target_amount_type").extra.optionList}
                  onTargetAmountTypeChange={formController.$("protection_for_my_family.target_amount_type").onChange}
                  targetAmountTypeValue={formController.$("protection_for_my_family.target_amount_type").value}
                  formController={formController}
                  refreshValues={location.state && location.state.refreshValues}
                  menuList={formController.$("protection_for_my_family.target_amount_dropdown").extra.optionsList}
                  useValueCallback={(value) => Utils.updateTargetAmountValue(formController, "protection_for_my_family", value)}
                />
              </Col>

              <Col className='col-12 col-lg-4 mt-4 mt-md-0'>
                <ProtectionOfMyHealth
                  formController={formController}
                  bottomsheetTitle={formController.$("protection_for_my_health.target_amount_type").extra.bottomsheetTitle}
                  label={formController.$("protection_for_my_health").label}
                  isSelected={!formController.$("protection_for_my_health").disabled}
                  toggleNeedSelection={(currentDisabledState) => Utils.toggleDisabledState(formController, "protection_for_my_health", currentDisabledState)}
                  menuList={formController.$("protection_for_my_health.target_amount_dropdown").extra.optionList}
                  menuOptionChange={formController.$("protection_for_my_health.target_amount_dropdown").onChange}
                  menuValue={formController.$("protection_for_my_health.target_amount_dropdown").value}
                  showErrorMsg={formController.$("protection_for_my_health").$("target_amount_dropdown").error}
                  errorMsg={formController.$("protection_for_my_health").$("target_amount_dropdown").error}
                />
              </Col>
            </Row>
          </div>

        </div>
      </>
    </ChooseNeedFormContext.Provider>
  );
};

export default observer(ChooseNeedsForm);
