import Utils from './utils';
import ConstUtils from '../../../../../constants/utils';

const initialDefaults = (formRef, data) => {
    let requestData = data.data.bi_request
    let frequency = requestData?.INPUT_MODE?.toString()
    let optionName = requestData?.OptionName?.toString()
    let maturityBenefit = requestData?.MaturityBenefitOption?.toString()
    let payoutMode = requestData.PayoutMode
    let payoutPeriod = requestData.PayoutPeriodInYears
    let premium = requestData.PR_ANNPREM
    let payoutPeriodValue = {}
    let ptValue = {
        id: requestData.PR_PT,
        label: requestData.PR_PT
    }
    let payoutPeriodList = Utils.getPayoutPeriodList()
    let payoutModeValue = {};

    formRef.$('policy-term').set('value', ptValue)


    formRef.$('premium-term').set('value', {
        id: requestData.PR_PPT,
        label: requestData.PR_PPT
    })

    switch (frequency) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '2':
            formRef.$('payment-frequency').set('value', {
                id: '2',
                label: 'Semi-Annual'
            })
            break;
        case '3':
            formRef.$('payment-frequency').set('value', {
                id: '3',
                label: 'Quarterly'
            })
            break;
        case '4':
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;
        case '5':
            formRef.$('payment-frequency').set('value', {
                id: '5',
                label: 'Single'
            })
            break;

        default:
            break;
    }

    if (requestData.PR_PPT === '1') {
        formRef.$('payment-frequency').set('disabled', true)
        formRef.$('payment-frequency').set('value', {
            id: '5',
            label: 'Single'
        })


        formRef.$('payment-frequency').set("extra", {
            ...formRef.$('payment-frequency').extra,
            optionList: Utils.generatePPF(requestData.PR_PPT)
        })

    } else {
        formRef.$('payment-frequency').set('disabled', false)
        formRef.$('payment-frequency').set("extra", {
            ...formRef.$('payment-frequency').extra,
            optionList: Utils.generatePPF(requestData.PR_PPT)
        })
    }

    switch (optionName) {
        case '11':
            formRef.$('option-name').value = {
                id: '11',
                label: 'Base'
            }
            break;

        case '12':
            formRef.$('option-name').value = {
                id: '12',
                label: 'Secured Income'
            }
            break;
    }

    switch (maturityBenefit) {
        case '13':
            formRef.$('maturity-benefit-option').value = {
                id: 13,
                label: "Regular Income",
            }
            break;

        case '14':
            formRef.$('maturity-benefit-option').value = {
                id: 14,
                label: "Regular Income Plus Lumpsum",
            }
            break;
    }

    switch (payoutMode) {
        case '15':
            payoutModeValue = {
                id: "15",
                label: "Annual",
            }
            break;

        case '17':
            payoutModeValue = {
                id: "17",
                label: "Half-Yearly",
            }
            break;

        case '18':
            payoutModeValue = {
                id: "18",
                label: "Quarterly",
            }
            break;

        case '19':
            payoutModeValue = {
                id: "19",
                label: "Monthly",
            }
            break;
    }

    if (payoutPeriod) {
        payoutPeriodValue = payoutPeriodList.filter((item) => item.id == payoutPeriod)[0]
    }

    let premiumAmount = ''
    switch (frequency) {
        case '1':
        case '5':
            premiumAmount = premium;
            break;
        case '2':
            premiumAmount = Number(premium) / 2;
            break;
        case '3':
            premiumAmount = Number(premium) / 4;
            break;
        case '4':
            premiumAmount = Number(premium) / 12;
            break;

        default:
            break;
    }

    formRef.$('premium').set('value', premiumAmount)
    formRef.$('payout-mode').value = payoutModeValue;
    formRef.$('payout-period').value = payoutPeriodValue;

    if (requestData.ADBRider) {
        formRef.$('ADB').set('value', requestData.ADBRider !== '')
        if (requestData.ADBRider) {
            formRef.$('ADB-dropdown').set('value', requestData.ADBRider)
        }
    }
    if (requestData.ATPDRider) {
        formRef.$('ATPDRider').set('value', requestData.ATPDRider !== '')
        if (requestData.ATPDRider) {
            formRef.$('ATPDRider-dropdown').set('value', requestData.ATPDRider)
        }
    }
    if (requestData.WaiverOfPremiumRider) {
        formRef.$('WaiverOfPremiumRider').set('value', requestData.WaiverOfPremiumRider !== '')
    }

    if (requestData.IncomeBenefitRider) {
        formRef.$('IncomeBenefitRider').set('value', requestData.IncomeBenefitRider !== '')
        if (requestData.IncomeBenefitRider) {
            formRef.$('IncomeBenefitRider-dropdown').set('value', requestData.IncomeBenefitRider)
        }
    }

    if (requestData.PayorWaiverBenefitRider) {
        formRef.$('PayorWaiverBenefitRider').set('value', requestData.PayorWaiverBenefitRider !== '')
        if (formRef.$('PayorWaiverBenefitRider').value) {
            switch (requestData.PWB) {
                case '1':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '1',
                        label: 'Option 1 (On Death)'
                    })
                    break;
                case '2':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '2',
                        label: 'Option 2 (CI or ATPD)'
                    })
                    break;
                case '3':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '3',
                        label: 'Option 3 (CI or ATPD or Death)'
                    })
                    break;
                default:
                    break;
            }
        }
    }
}

export default initialDefaults
