// Project Imports
import fetchNeedMasterData from 'utils/fetchNeedMasterData'
import NeedTypeEnum from "constants/NeedTypeEnum";



const initialDefaults = async (formController, journeyId, journeyData,cardKey, productData) => {

if (journeyId !== undefined) {
    formController.$("journey_id").value = journeyId.toString()
}

 let masterData = await fetchNeedMasterData()

 if (masterData.length <= 0) return

 let growMyMoneyOptionList = masterData.find((item) => item.unique_name === NeedTypeEnum.GROW_MY_MONEY)
 let saveForRetirementOptionList = masterData.find((item) => item.unique_name === NeedTypeEnum.SAVE_FOR_RETIREMENT)
 let saveForChildrenOptionList = masterData.find((item) => item.unique_name === NeedTypeEnum.SAVE_FOR_CHILDREN)

 let optiionList

 switch (cardKey) {
  case NeedTypeEnum.SAVE_FOR_RETIREMENT:
   optiionList = saveForRetirementOptionList
   break;

  case NeedTypeEnum.GROW_MY_MONEY:
  optiionList = growMyMoneyOptionList
  break;

  case NeedTypeEnum.SAVE_FOR_CHILDREN:
  optiionList = saveForChildrenOptionList
  break;
 
  default:
   break;
 }

let familyProductId =  productData.BI.find((item)=> item.risk === productData?.selectedRisk)?.family_product_id

let payoutMode = journeyId.family_product_details_list.find((item)=>item.id === familyProductId)?.payout_mode

 formController.$("target_amount_dropdown").set("extra", {
     ...formController.$("target_amount_dropdown").extra,
     allOptionsList: optiionList.target_amount,
     need_id: optiionList.id,
     need_unique_name: optiionList.unique_name
 })

 formController.$("target_amount_type").set("extra", {
    ...formController.$("target_amount_type").extra,
    optionList: optiionList.mode_master
})

 let selectedMode = formController.$("target_amount_type").extra.optionList.find((item)=> item.mode.toLowerCase() === payoutMode )

 formController.$("target_amount_type").set("value",selectedMode )

}

export default initialDefaults