// project imports
import AuthStore from "modules/login/store/AuthStore";
import errorMessage from './errorMessage';
import OptimizationStore from "modules/optimization/store/OptimizationStore";

// Package Imports
import moment from 'moment';

class Validator {
 valid = (formRef, data) => {
     let error = '';
     let validatons = new Validation();
     let keys = Object.keys(validatons);
     for (let i = 0; i <= keys.length; i++) {
         if (validatons[keys[i]] instanceof Function) {
             validatons[keys[i]](formRef, data)
         }
     }

     if (validatons.errorMessage && validatons.errorMessage.length > 0) {
         OptimizationStore.validateProduct(data.data.product_id, true)
         validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
         AuthStore.setErrorModal(error)
     } else {
         OptimizationStore.validateProduct(data.data.product_id, false)
     }

     return validatons.errorMessage;

 }
}


class Validation {
    errorMessage = [];
    // validatePremium = (formRef) => {

    // }
}


export default new Validator();