import ProductNames from "constants/ProductNames";

const targetAmountRoundOffLogic = function (productId, targetAmount) {
  console.log("#$#$#$#$#$#$#$#$#$");
  console.log("targetAmountRoundOffLogic productId ::: ", productId);
  console.log("targetAmountRoundOffLogic targetAmount ::: ", targetAmount);
  console.log("#$#$#$#$#$#$#$#$#$");
  switch (productId) {
    case ProductNames.zindagiPlus:
      return roundOffTargetAmount(targetAmount, 1000);
    case ProductNames.totalProtectPlus:
      return roundOffTargetAmount(targetAmount, 1000);
    case ProductNames.saralJeevanBima:
      return roundOffTargetAmount(targetAmount, 50000);

    default:
      return targetAmount;
  }
};

const roundOffTargetAmount = (targetAmount, roundOffValue) => {
  let remainderValue = targetAmount % roundOffValue;
  let roundOffFinalAmount = targetAmount - remainderValue;

  return roundOffFinalAmount;
};

export default targetAmountRoundOffLogic;
