import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import OptimizationStore from '../../../store/OptimizationStore';


class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Minimum Annual Premium
    minimumAnnualPremium = (form, data) => {
        // let frequency = parseInt(form.$('payment-frequency').value.id);
        // let premium = parseInt(form.$('premium').value);

        // // premium = premium * frequency;

        // if (frequency == 1) {
        //     if (premium < 5000 || premium > 250000) {
        //         this.errorMessage.push(errorMessageKeys.minimumPremium.annual)
        //     }
        // } else {
        //     if (premium < 1000 || premium > 22000) {
        //         this.errorMessage.push(errorMessageKeys.minimumPremium.monthly)
        //     }
        // }
    }

    // PPT Validations
    validatePPT = (form) => {
        // let ppt = form.$('premium-term').value.id;
        // let pt = form.$('policy-term').value.id;

        // if (ppt == 12) {
        //     if (pt < 12 || pt > 20) {
        //         this.errorMessage.push(errorMessageKeys.pptValidation)
        //     }
        // }
    }

}
export default new Validator();