import React, { Component } from 'react';
class Temp extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <div className='container mt-5 px-3'>
                
            </div>
        );
    }
}

export default Temp;