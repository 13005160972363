// React Imports
import React, { useEffect, useState } from "react";

// Package Imports
import { toJS } from "mobx";

// Project Imports
import { Breadcrumb, HeaderAnalysis } from "components";
import AxiosErrorModal from "./axios-error-modal/AxiosErrorModal";
import AuthStore from "./login/store/AuthStore";
import Dashboard from './dashboard/components/Dashboard';
import FormContext from "context/FormContext";
import QmsHeader from "components/ui/qms-header/QmsHeader";
import QmsDashboard from "./dashboard/components/QmsDashboard";

const HeaderComponent = (props) => {

    const [formController, setFormController] = useState(undefined)
    const [isAddNeedModalOpen, setIsAddNeedModalOpen] = useState(false)
    const [callbackFunctions, setCallbackFunctions] = useState({
        success: undefined,
        error: undefined
    });
    const [errorClass, setErrorClass] = useState("")
    useEffect(() => {
        let journeyId = props.match.params.journeyId;
        AuthStore.fetchAllData(journeyId)
    }, [])

    let journeyData = toJS(AuthStore.journeyData);
    if (journeyData === undefined) {
        return null;
    }


    const updateErrorClass = (value) => {
        setErrorClass(value);
    }
    let currentLocation = window.location.pathname

    return (
        <FormContext.Provider value={{ formController, setFormController, callbackFunctions, setCallbackFunctions, isAddNeedModalOpen, setIsAddNeedModalOpen }}>
            {!currentLocation.includes("qms") ?
                <><HeaderAnalysis {...props} updateErrorClass={updateErrorClass} />
                    {/* <Breadcrumb {...props}  updateErrorClass={updateErrorClass}/> */}
                    <div className='pt-5 mt-2 mt-lg-0'>
                        <Dashboard {...props} errorClass={errorClass} />
                    </div>
                </> :
                <>
                    <QmsHeader {...props} updateErrorClass={updateErrorClass} />
                    <div className='pt-5 mt-2 mt-lg-0'>
                        <QmsDashboard {...props} errorClass={errorClass} />
                    </div>
                </>
            }
            <AxiosErrorModal {...props} />
        </FormContext.Provider>
    )
}

export default HeaderComponent;