import AuthStore from '../../../../login/store/AuthStore';
import errorMessagesKeys from './errorMessageKeys';
import OptimizationStore from '../../../store/OptimizationStore';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Entry Age validation for upto 80 PT option
    uptoAge80PtValidation = (formRef, data) => {
        let pt = formRef.$('policy-term').value.id;
        let ppt = formRef.$('premium-paying-term').value.id
        let age = data.data.bi_request.LI_ENTRY_AGE

        if (pt != '80 - @Li_entry_age') {
            return;
        }

        if (ppt == 15 && age > 60) {
            this.errorMessage.push(errorMessagesKeys.uptoAge80['15pay'])
        }

        if (ppt == 20 & age > 50) {
            this.errorMessage.push(errorMessagesKeys.uptoAge80['20pay'])
        }

        if (ppt == 15 || ppt == 20) {
            return;
        }

        // For Other ppt options
        if (age > 65) {
            this.errorMessage.push(errorMessagesKeys.uptoAge80.otherPay)
        }

    }

    // Entry Age validation for other pt
    otherPtAgeValidation = (formRef, data) => {
        let pt = formRef.$('policy-term').value.id;
        let ppt = formRef.$('premium-paying-term').value.id

        if (pt == '80 - @Li_entry_age') {
            return;
        }

        if (ppt == 10 && pt < 15 || pt > 40) {
            this.errorMessage.push(errorMessagesKeys.normalPt['10pay'])
        }

        if (ppt == 15 && pt < 20 || pt > 40) {
            this.errorMessage.push(errorMessagesKeys.normalPt['15pay'])
        }
        if (ppt == 20 && pt < 25 || pt > 40) {
            this.errorMessage.push(errorMessagesKeys.normalPt['20pay'])
        }

        if (ppt = 10 || ppt == 15 || ppt == 20) {
            return;
        }

        if (pt < 10 && pt > 40) {
            this.errorMessage.push(errorMessagesKeys.normalPt.otherpay)
        }

    }

    // Validate Plan Options
    validatePlanOptions = (formRef, data) => {
        let pt = parseInt(formRef.$('policy-term').value.id)
        let ppt = parseInt(formRef.$('premium-paying-term').value.id)
        let planOption = formRef.$('plan-option').value.id
        let age = parseInt(data.data.bi_request.LI_ENTRY_AGE)
        let totalAge = ppt + age;

        if (planOption == 2) {
            if (pt > 70) {
                this.errorMessage.push(errorMessagesKeys.valid_plan_options.adb);
            }
        }


        if (planOption == 5) {
            if (totalAge > 70) {
                this.errorMessage.push(errorMessagesKeys.valid_plan_options.atpd);
            }
        }

        if (planOption == 6) {
            if (totalAge > 70) {
                this.errorMessage.push(errorMessagesKeys.valid_plan_options.criticalIllness);
            }
        }

    }

    // Minimum premium validation
    validatePremium = (formRef, data) => {
        let premium = parseInt(formRef.$('premium').value)

        if (premium < 3000) {
            this.errorMessage.push(errorMessagesKeys.minimumPremium)
        }
    }

    // validate PT and PPT
    validatePtPPt = (formRef) => {
        let pt = formRef.$('policy-term').value.id;
        let ppt = formRef.$('premium-paying-term').value.id
        let dummyPPT = [1, 5, 10, 15, 20]
        let isValidPPT = false

        if (pt == ppt) {
            return
        }

        if (pt >= 25) {
            if (ppt > 20) {
                this.errorMessage.push(errorMessagesKeys.ptPpt)
                return;
            }
        }

        if (pt >= 20 && pt <= 24) {
            if (ppt > 15 || (ppt >= 11 && ppt <= 14)) {
                this.errorMessage.push(errorMessagesKeys.ptPpt)
                return;
            }
        }

        if (pt >= 16 && pt <= 19) {
            if (ppt > 10) {
                this.errorMessage.push(errorMessagesKeys.ptPpt)
                return;
            }
        }

        if (pt >= 11 && pt <= 14) {
            if (ppt > 5) {
                this.errorMessage.push(errorMessagesKeys.ptPpt)
                return;
            }
        }

        if (pt == '80 - @Li_entry_age') {
            dummyPPT.every((item) => {
                if (ppt == item) {
                    isValidPPT = true
                    return false
                }
                return true
            })

            if (isValidPPT) {
                return
            } else {
                this.errorMessage.push(errorMessagesKeys.ptPpt)
            }
        }
    }

}
export default new Validator();