import OptimizationStore from '../../../store/OptimizationStore';
import * as keys from './keys'
import Validator from './validator';
class Utils {
  getPolicyTerm = () => {
    let pt = [];
    for (let i = 10; i <= 20; i++) {
      pt.push({ id: i, label: "" + i });
    }
    return pt;
  };

  getPremiumTermList = (pt) => {
    let ppt = [];
    for (let i = 5; i <= 20; i++) {
      ppt.push({ id: i, label: "" + i });
    }
    return ppt;
  };

  updateBi = (key, value, productId, formRef, productData) => {

    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request

    switch (key) {

      case keys.FREQUENCY:
        let premiumAmount = 0;
        let amount = Number(value.premium);
        switch (Number(value.frequency)) {
          case 1:
            premiumAmount = '' + amount * 1
            break;
          case 2:
            premiumAmount = '' + amount * 2
            break;
          case 3:
            premiumAmount = '' + amount * 4
            break;
          case 4:
            premiumAmount = '' + amount * 12
            break;
        }

        biRequest[keys.PREMIUM] = premiumAmount
        biRequest[keys.FREQUENCY] = value.frequency
        break;

      case keys.PR_PT:
        biRequest[keys.PR_PT] = value;
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value
        break;

      case keys.FUNDSTRATEGYID:
        biRequest[keys.FUNDSTRATEGYID] = value;
        break;

      case keys.MANAGED_FUND:
        biRequest[keys.FUNDSTRATEGYID] = "1";
        biRequest[value.EQUITY_LARGE_CAP_FUND] = value[keys.EQUITY_LARGE_CAP_FUND];
        biRequest[keys.EQUITY_LARGE_CAP_FUND] = value[keys.EQUITY_LARGE_CAP_FUND];
        biRequest[keys.EQUITY_TOP_20_FUND] = value[keys.EQUITY_TOP_20_FUND];
        biRequest[keys.BOND_FUND] = value[keys.BOND_FUND];
        biRequest[keys.MANAGED_FUND] = value[keys.MANAGED_FUND];
        biRequest[keys.EQUITY_MID_CAP_FUND] = value[keys.EQUITY_MID_CAP_FUND];
        biRequest[keys.EQUITY_BLUE_CHIP_FUND] = value[keys.EQUITY_BLUE_CHIP_FUND];
        biRequest[keys.GLIT_FUND] = value[keys.GLIT_FUND];
        break;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return
    // }

  };

  getTotalPrice = (data) => {
    let totalPremium = 0;
    totalPremium += data.ANN_PREM;
    return totalPremium;
  };

  setDistribution(fundList, productId, form, data) {
    let fundObject = {}
    fundList.forEach(item => {
      let value = item.value || 0
      let fundKey = item.key
      fundObject[fundKey] = value

    });
    this.updateBi(keys.MANAGED_FUND, fundObject, productId, form, data)

  }

  fetchPolicyTimeline(element, biJson2Data) {
    let data = {
      youPay: Math.round(element.ANN_PREM),
      youGet: Math.round(biJson2Data.FINAL_FV),
      lifeCover: Math.round(biJson2Data.DEATH_BEN),
      isMilestone: Math.round(biJson2Data.FINAL_FV) !== 0,
      premiumAmt: Math.round(element.ANN_PREM)
    }
    return data
  }
}

export default new Utils();
