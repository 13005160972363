import React, { Component } from "react";
import { InputGroup, FormControl, Image, Col } from "react-bootstrap";

import { GraphSlider, Button as UiButton } from "../../../../components";
import FamilyProtectionStore from "../../store/FamilyProtectionStore";
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import * as images from "constants/Images";
import * as APITypes from "constants/API";
import axios from "utils/axios";

import PropTypes from "prop-types";

import "./death-cover-graph-slider.css";
import fetchErrorMessageBasedOnAmountAndPlan from "utils/fetchErrorMessageBasedOnAmountAndPlan";
import fetchRecommendedTargetLabelBasedOnNeed from "utils/fetchRecommendedTargetLabelBasedOnNeed";
import calculateProductPremiumRelatedValues from "utils/calculateProductPremiumRelatedValues";
class DeathCoverGraphSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      income: "",
      premium: "",
      isIncomeDisable: false,
      isPremiumDisable: false,
      minPremium: 0,
      recommendedTargetValue: 0,
      recommendedPremium: 0,
      recommendedTarget: 0,
      showValidation: false,
      premiumValue: this.props.premium_value,
      targetValue: this.props.productData.bi_request.investment_target
    };
    this.onRecalculateClick = this.onRecalculateClick.bind(this);
    this.updateIncomeValue = this.updateIncomeValue.bind(this);
    this.updatePremiumValue = this.updatePremiumValue.bind(this);
    this.blurEvent = this.blurEvent.bind(this);
    this.validateValue = this.validateValue.bind(this);
    this.updatePremiumValues = this.updatePremiumValues.bind(this);
    this.onSliderValueChange = this.onSliderValueChange.bind(this);
    this.updateRecommendedPremium = this.updateRecommendedPremium.bind(this);
    this.updateRecommendedTarget = this.updateRecommendedTarget.bind(this);
    this.onSliderOverlayHide = this.onSliderOverlayHide.bind(this);
    this.onTargetValueBlurEvent = this.onTargetValueBlurEvent.bind(this);
    this.onPremiumValueChange = this.onPremiumValueChange.bind(this);
    this.onTargetValueChange = this.onTargetValueChange.bind(this);
  }

  async componentDidMount() {
    const { income_value, premium_value } = this.props;
    this.setState({
      income: income_value,
      premium: premium_value,
    });
    this.updatePremiumValues(null);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.productData.fieldChanged != prevProps.productData.fieldChanged
    ) {
      this.setState({ showOverlay: this.props.productData.fieldChanged });
    }
  }

  onRecalculateClick = () => {
    this.setState({
      isIncomeDisable: false,
      isPremiumDisable: false,
    });
    this.props.onClick();
  };

  onPremiumValueChange = (e) => {
    let value = e.target.value;
    let regex = /^(([1-9]\d*))?$/;
    if (regex.test(value)) {
      this.setState({ premiumValue: e.target.value})
      this.props.onPremiumChange(e)
    }
  }

  onTargetValueChange = (e) => {
    let value = e.target.value;
    let regex = /^(([1-9]\d*))?$/;
    if (regex.test(value)) {
      this.setState({ targetValue: e.target.value})
      this.props.onIncomeChange(e)
    }
  }

  updateIncomeValue(e) {
    const data = this.props.data;
    this.setState({
      income: e.target.value,
      isPremiumDisable: true,
    });
    data.annual_price = e.target.value;
    // this.props.onClick();
    FamilyProtectionStore.updateDetails(data);
  }

  updatePremiumValue(e) {
    const data = this.props.data;
    this.setState({
      premium: e.target.value,
      isIncomeDisable: true,
    });
    // this.props.onClick();
    // data.premium_amount = e.target.value;
    // FamilyProtectionStore.updateDetails(data);
    this.props.onPremiumChange(e);
  }

  blurEvent(event) {
    const { minPremium, maxPremium } = this.state
    let value = event.target.value;
    if (value.length > 0) {
      let errorMessage = fetchErrorMessageBasedOnAmountAndPlan(this.props.cardKey, value, "premium", minPremium, maxPremium)
      if(errorMessage === ""){
        this.setState({ showValidation: false, premiumErrorMessage: null })
        if (this.props.onClick) {
          this.props.onClick();
        }
      }else{
        this.setState({ showValidation: true, premiumErrorMessage: errorMessage })
      }
    }
  }

  onTargetValueBlurEvent(event) {
    const { productData } = this.props
    const { recommendedTargetValue } = this.state
    let value = event.target.value;
    if (value.length > 0) {
      let errorMessage = fetchErrorMessageBasedOnAmountAndPlan(this.props.cardKey, value, "target", parseInt(productData.minimum_sum_assured), recommendedTargetValue * 0.4 + recommendedTargetValue)
      if(errorMessage === ""){
        this.setState({ showValidation: false, targetErrorMessage: null })
        if (this.props.onClick) {
          this.props.onClick();
        }
      }else{
        this.setState({ showValidation: true, targetErrorMessage: errorMessage })
      }
    }
  }

  validateValue(value, amount) {
    if (Number(value) <= amount) {
      this.setState({
        showValidation: true,
      });
    } else {
      this.setState({
        showValidation: false,
      });
      FamilyProtectionStore.dataChange(
        "premium",
        value,
        "",
        this.props.productID
      );
    }
    this.setState({
      minPremium: amount,
    });
  }

  updatePremiumValues = async (userSelected) => {
    this.setState({ showPreloader: true });
    const { productData } = this.props;
    this.setState({
      recommendedTargetValue: parseInt(productData.recommended_sum_assured),
    });
    this.setState({
      selectedTargetValue: parseInt(productData.bi_request.investment_target),
      targetValue: parseInt(productData.bi_request.investment_target)
    });
    if (productData) {
      let requestBodyArr = [];
      let targetAmount = userSelected
        ? userSelected
        : productData.bi_request.investment_target
          ? productData.bi_request.investment_target
          : productData.recommended_sum_assured;
      this.setState({
        selectedTargetValue: parseInt(targetAmount),
        targetValue: parseInt(targetAmount),
      });
      requestBodyArr.push({
        ...productData.bi_request,
        TargetAmount: productData.minimum_sum_assured,
        investment_target: targetAmount,
        PR_SA: productData.minimum_sum_assured,
      });
      requestBodyArr.push({
        ...productData.bi_request,
        TargetAmount: productData.maximum_sum_assured,
        investment_target: targetAmount,
        PR_SA: productData.maximum_sum_assured,
      });
      requestBodyArr.push({
        ...productData.bi_request,
        TargetAmount: productData.recommended_sum_assured,
        investment_target: targetAmount,
        PR_SA: productData.recommended_sum_assured,
      });
      requestBodyArr.push({
        ...productData.bi_request,
        TargetAmount: targetAmount,
        investment_target: targetAmount,
        PR_SA: targetAmount,
      });
      let request = {
        "family_product_id": productData.family_product_id,
        "risk_type": productData.selectedRisk || "high",
        "multi_target_premium_list": requestBodyArr
      }

      global.loading(true)
      await axios.post(APITypes.GET_PREMIUM_API, request).then((res) => {
        this.setState({ showPreloader: false });
        this.setState({ minPremium: res.data[0] ? res.data[0].premium : 0 });
        this.setState({ maxPremium: res.data[1] ? res.data[1].premium : 0 });
        this.setState({
          recommendedPremium: res.data[2] ? res.data[2].premium : 0,
        });
        this.setState({
          selectedPremium: res.data[3]
            ? res.data[3].premium
            : res.data[2]
              ? res.data[2].premium
              : 0,
        });

        this.updateRecommendedTarget(productData.recommended_sum_assured);
        this.updateRecommendedPremium(
          res.data[3] ? res.data[3].premium : res.data[2] ? res.data[2] : 0
        );

        let request = {
          ...productData.bi_request,
          investment_target: targetAmount,
        };
        OptimizationStore.updateStoreRequestData(
          productData.product_id,
          request
        );
        global.loading(false)
      });
      global.loading(false)
      OptimizationStore.resetFieldChangeEvent(this.props.productData.family_product_id);
    }
  };

  onSliderValueChange = (userSelected) => {
    this.updatePremiumValues(userSelected);
  };

  updateRecommendedPremium = (val) => {
    this.setState({ recommendedPremium: val });
  };

  updateRecommendedTarget = (val) => {
    this.setState({ recommendedTarget: val });
  };

  onSliderOverlayHide = () => {
    OptimizationStore.resetFieldChangeEvent(this.props.productData.family_product_id);
  };

  render() {
    const {
      income,
      premium,
      showValidation,
      showOverlay,
      showPreloader,
      minPremium,
      maxPremium,
      recommendedPremium,
      selectedPremium,
      recommendedTargetValue,
      selectedTargetValue,
    } = this.state;
    const {
      show_shadow = true,
      premiumFreq,
      productData,
      openChangeReasonModal,
      productID,
      hideOverLay,
      productTitle,
      onPremiumChange,
      onIncomeChange,
      premium_value,
      income_value,
      onClick,
      saLabel,
      secondInputTextlabel,
      incomePercent,
      lumpsumPercent,
      cardKey,
    } = this.props;
    return (
      <React.Fragment>
        {/* <button className='btn d-flex align-items-center text-secondary border-secondary btn font-weight-medium font-size-18 p-6 ml-auto' onClick={this.onRecalculateClick}>
                    Recalculate
                    <Image className='pl-2' src={require('../../../../assets/images/icons/recalculate-secondary-icon.svg')} alt='recalculate-icon'></Image>
                </button> */}
        {/* bg-white death-cover-graph-slider py-3 */}
        {!Array.isArray(productData.BI) && (
          <div className="text-description-text-color font-size-12 font-size-md-18 font-size-lg-20 mt-2">
            {/* Underlying product : {productTitle} <UiButton variant='' disabled={cardKey === "protection_for_my_health"} buttonText='[Change]' className='px-2 py-1 font-size-12 text-primary font-size-md-16 font-weight-medium cursor-pointer' /> */}
            {/* Underlying product : {productTitle} <UiButton variant='' onClick={openChangeReasonModal} buttonText='[Change]' className='px-2 py-1 font-size-12 text-primary font-size-md-16 font-weight-medium cursor-pointer' /> */}
          </div>
        )}
        <div
          className={`bg-white death-cover-graph-slider py-3 ${
            show_shadow && "shadow px-2 px-md-3 rounded mt-3"
            }`}
        >
          <div className="d-block d-md-flex align-items-center justify-content-between">
            <Col>
              <div className="d-flex align-items-center">
                <div className="font-size-12 font-size-md-14 font-weight-bold text-nowrap">
                  <Image
                    width="21"
                    className="mx-1"
                    src={images.UmbrellaIconBlack}
                    alt="UmbrellaIcon"
                  />
                  {fetchIncomeLabelBasedOnNeed(cardKey)}{" "}
                  <span
                    className="text-secondary font-weight-normal"
                    style={{ marginLeft: "10px", marginRight: "5px" }}
                  >
                    &#8377;
                  </span>
                </div>

                <FormControl
                  className="border-secondary text-secondary font-size-12 font-size-md-14 font-weight-normal"
                  required
                  style={{ height: "25px" }}
                  placeholder={fetchIncomeLabelBasedOnNeed(cardKey,productTitle)}
                  value={this.state.targetValue}
                  onChange={e => this.onTargetValueChange(e)}
                  onBlur={(e) => this.onTargetValueBlurEvent(e)}
                />
                <span
                  className="text-gray-opacity-49 font-weight-normal font-size-14"
                  style={{ marginLeft: "5px", width: "100%" }}
                >
                  {premiumFreq}
                </span>
              </div>
              {showValidation && this.state.targetErrorMessage !== "" && (
                <p className={cardKey !== "protection_for_my_family" ? "text-success mt-2 font-size-12" : "text-danger mt-2 font-size-12"}>
                  {/* Minimum Premium should be {this.state.minPremium} */}
                  {this.state.targetErrorMessage}
                </p>
              )}
            </Col>

            <div className="font-size-12 font-size-md-14 font-weight-bold text-right mt-3 mt-lg-0">
              {fetchRecommendedTargetLabelBasedOnNeed(cardKey)} :{" "}
              <span className="text-success font-weight-bold">
                {global.toINR(this.state.recommendedTarget)}
              </span>
            </div>
          </div>
          <GraphSlider
            steps={[5, 8, 9, 10, 78, 44, 52, 20]}
            minTargetValue={parseInt(productData.minimum_sum_assured)}
            recommendedTargetValue={recommendedTargetValue}
            selectedTargetValue={parseInt(productData.bi_request.investment_target)}
            onChange={this.onSliderValueChange}
            hideOverLay={this.onSliderOverlayHide}
            showPreloader={showPreloader}
            minPremium={parseInt(minPremium)}
            maxPremium={parseInt(maxPremium)}
            recommendedPremium={parseInt(recommendedPremium)}
            selectedPremium={parseInt(selectedPremium)}
            showOverlay={showOverlay}
          />
          <div className="d-block d-md-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center flex-wrap flex-sm-nowrap">
              <Col>
                <div className="d-flex align-items-center">
                  <div className="font-size-12 font-size-md-14 font-weight-bold text-nowrap">
                    <Image
                      width="21"
                      className="mx-1"
                      src={images.PriceTagIconBlack}
                      alt="UmbrellaIcon"
                    />
                    {`${secondInputTextlabel || "Modal Premium  "}`}{" "}
                    <span
                      className="text-secondary font-weight-normal"
                      style={{ marginLeft: "10px", marginRight: "5px" }}
                    >
                      &#8377;
                    </span>
                  </div>
                  <div className="d-block">
                    <FormControl
                      disabled={this.state.isPremiumDisable}
                      className="border-secondary text-secondary font-size-12 font-size-md-14 font-weight-normal"
                      required
                      placeholder="Modal Premium"
                      style={{ height: "25px" }}
                      value={this.state.premiumValue}
                      //value={premium_value}
                      //onChange={onPremiumChange}
                      onChange={e => this.onPremiumValueChange(e)}
                      //onBlur={onClick}
                      onBlur={(e) => this.blurEvent(e)}
                    />

                  </div>
                  <span
                    className="text-gray-opacity-49 font-weight-normal font-size-14"
                    style={{ marginLeft: "5px", width: "50%" }}
                  >
                    {premiumFreq}
                  </span>
                </div>
                {showValidation && this.state.premiumErrorMessage !== "" && (
                  <p className={cardKey !== "protection_for_my_family" ? "text-success mt-2 font-size-12" : "text-danger mt-2 font-size-12"}>
                    {/* Minimum Premium should be {this.state.minPremium} */}
                    {this.state.premiumErrorMessage}
                  </p>
                )}
              </Col>
              {/* <UiButton variant='' onClick={onClick} buttonText='Calculate' className='calculate-btn px-2 py-1 font-size-12 text-primary font-size-md-16 font-weight-medium cursor-pointer ml-auto ml-sm-0 mt-1 mt-sm-0' /> */}
            </div>
            <div className="font-size-12 font-size-md-14 font-weight-bold text-right mt-3 mt-lg-0">
              Recommended Premium :{" "}
              <span className="text-success font-weight-bold">
                {global.toINR(this.state.recommendedPremium)}{" " + calculateProductPremiumRelatedValues(productData.bi_request?.INPUT_MODE?.toString()).frequencyLabel}
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DeathCoverGraphSlider.propTypes = {
  onPremiumChange: PropTypes.func,
  onIncomeChange: PropTypes.func,
  premiumFreq: PropTypes.string,
};

DeathCoverGraphSlider.defaultProps = {
  isIncomeFieldDisable: false,
  premiumFreq: "",
};

export default DeathCoverGraphSlider;
