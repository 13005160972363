// React Imports
import React, { Component } from 'react';

// Package Imports
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom'

// Product Imports
import QmsDetailsPage from 'modules/qms-flow/qms-details-page/QmsDetailsPage';
import QmsDashboardPage from 'modules/qms-flow/qms-dashboard-page/QmsDashboardPage';
import ChooseNeedsAndSetTargets from 'modules/choose-needs/ChooseNeedsAndSetTargets';

import '../../../styles/typo.css';

const QmsDashboard = observer(class QmsDashboard extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <SelectedPage {...this.props} />
    );
  }
});

const SelectedPage = (props) => {
  const { selectedPage } = useParams();

  if (selectedPage === undefined) {
    return <ChooseNeedsAndSetTargets {...props} />
  }
  switch (selectedPage) {
    case 'qms-details':
      return <QmsDetailsPage {...props} />

    case 'qms-dashboard':
      return <QmsDashboardPage {...props} />

    default:
      return <div></div>
  }
}

export default QmsDashboard;
