
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {

  formRef.$('policy-term').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.PR_PT, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('payment-frequency').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.FREQUENCY, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('premium-term').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.PR_PPT, field.value.id, props.data.product_id, formRef, props)
    }
  })


  formRef.$('life-cover-option').observe({
    key: 'value',
    call: ({ field }) => {
      if (field.value.id == '1') {
        formRef.$("second-life-assured-dob").set("value", "")
        formRef.$("second-life-assured-name").set("value", "")
        formRef.$('second-life-assured-gender').set("value", "")
      }
      formRef.$('life-cover-option').set('extra', {
        ...formRef.$('life-cover-option').extra,
        jointFieldDisable: field.value.id == '1'
      })
      Utils.updateBi(keys.POLICY_OPTION, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('second-life-assured-gender').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.LA_GENDER, field.value.id, props.data.product_id, formRef, props)
    }
  })

}

export default FormObserver