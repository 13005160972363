// react imports
import React, { useEffect } from "react"

// package imports
import { Col, Row } from "react-bootstrap"

// project imports
import { NextButton } from "components"
import QmsSolutionStatus from "constants/QmsSolutionStatus"
import qmsDashboardPageStore from "modules/qms-flow/qms-dashboard-page/qms-dashboard-page-store"
import StorageKeys from "constants/StorageKeys"

import "./proposer-details-card.css"

function ProposerDetailsCardForNAStatus(props) {
    const { proposerData, solutionData, buttonId, statusId, journeyData } = props

    useEffect(() => {
        if (buttonId && solutionData.solution_status) {
            document.getElementById(buttonId).style.backgroundColor = solutionData.button_color
            document.getElementById(buttonId).style.borderColor = solutionData.button_color
            document.getElementById(buttonId).style.color = solutionData.button_text_color
        }

        if (statusId && solutionData.solution_status) {
            document.getElementById(statusId).style.color = solutionData.status_text_color
        }

    }, [solutionData.buttonId, solutionData.statusId, solutionData.solution_status])

    const onViewDetailsClick = () => {
        switch (solutionData.solution_status_id) {
            case QmsSolutionStatus.ANALYSIS_IN_PROGRESS:
                let routeName = fetchNARoutes()
                let agentId = localStorage.getItem(StorageKeys.AGENT_ID)
                let token = localStorage.getItem(StorageKeys.AUTH_TOKEN)

                if (routeName) {
                    props.history.push(`/${routeName}/${journeyData.journey_id}?agent_id=${agentId}&token=${token}`)
                } else {
                    props.history.push(`/${routeName}?agent_id=${agentId}&token=${token}`)
                }
                break;

            case QmsSolutionStatus.QUOTE_SHARED:
                props.history.push("/qms/qms-details", { proposerId: proposerData.proposer_id, journeyId: journeyData.journey_id })
                break;
        }
    }

    const fetchNARoutes = () => {
        let route = ""

        switch (journeyData.current_journey_screen) {
            case "choose_need":
                route = "choose-needs"
                break;

            case "family_details":
                route = "family-details"
                break;

            case "customization":
                route = "customization"
                break;

            case "solution":
                route = "solution"
                break;

            case "solution_preview":
                route = "quotation-preview"
                break;

            default:
                break
        }

        return route
    }

    return (
        <>
            <Col md="2" className="card-inner-padding">
                <p className="mb-0 font-size-14 font-weight-medium client-name">{qmsDashboardPageStore.getFamilyMemberName(proposerData, false, journeyData, true)}</p>
                {proposerData.mobile_number && <a href={`tel:${proposerData.mobile_number}`} className="font-size-12 font-weight-400 mb-0 mr-1 mobile-number">{proposerData.mobile_number}</a>}
            </Col>

            <div className='d-none d-md-block border-right mx-md-3'></div>
            <div className='d-block d-md-none border-bottom w-100 mx-md-3'></div>

            <Col md="3" className="card-inner-padding border-left">
                <div className="d-flex align-items-center">
                    <p className="mb-0 font-size-10 font-weight-400 subtitle mr-3">Status</p>
                    <div className='py-3 border-right'></div>
                    <div>
                        <p id={statusId} className={`mb-0 font-size-14 font-weight-medium ml-3`}>{solutionData.solution_status || '-'}</p>
                        {solutionData.solution_status_id == QmsSolutionStatus.QUOTE_SHARED && solutionData.solution_id && <p className="mb-0 font-size-10 font-weight-300 subtitle ml-3">Soln ID:{solutionData.solution_id || '-'}</p>}
                    </div>
                </div>
            </Col>

            <div className='d-none d-md-block border-right mx-md-3'></div>
            <div className='d-block d-md-none border-bottom w-100 mx-md-3'></div>

            <Col md="6" className="pr-md-1 card-inner-padding">
                <Row>
                    <Col md="6" className="pl-md-0">
                        <p className="mb-0 font-size-10 font-weight-400 subtitle">Next Actions</p>
                        <p className="mb-0 font-size-12 font-weight-400 subtitle">{solutionData.next_action_text || '-'}</p>
                    </Col>

                    <Col md="6" className="justify-content-md-end d-md-flex mt-4 mt-md-0">
                        {solutionData.solution_status && <NextButton
                            id={buttonId}
                            buttonText={solutionData.button_text}
                            className={`status-btn`}
                            onClick={() => onViewDetailsClick()}
                        />}
                    </Col>
                </Row>
            </Col>
            <div className=' border-bottom w-100 d-none d-md-block'></div>
        </>
    )
}

export default ProposerDetailsCardForNAStatus
