// Project Imports
import checkIsValueEmpty from "utils/checkIsValueEmpty"
import { PAN_NUMBER_REGEX } from 'constants/Constants'

export function validateEmployeeCode({ field, form }) {
    let selectedCategory = form.$("category").value.label
    let currentFieldValue = field.value
    let isValid = true

    if (selectedCategory === "Staff") {
        isValid = !checkIsValueEmpty(currentFieldValue)
    }

    return [isValid, "This field is mandatory"]
}

// function validateMobileNo(value, validator) {

//     if(value.length == 0){
//         return [false, "This field is mandatory"]
//     }

//     if (!validator.isInt(value.toString())) {
//         return [false, "Please enter only numbers"]
//     }

//     let firstCharacter = parseInt(value.charAt(0))

//     if (firstCharacter < 6) {
//         return [false, "First digit should be between 6 - 9 "]
//     }

//     if (value.length !== 10) {
//         return [false, "Please enter 10 digit mobile number"]
//     }

//     return [true, ""]
// }

// function validatePanNumber(value, validator) {

//     if(value.length == 0){
//         return [false, "This field is mandatory"]
//     }

//     if (!validator.isAlphanumeric(value)) {
//         return [false, "Please enter only alpha numeric"]
//     }

//     if (!PAN_NUMBER_REGEX.test(value)) {
//         return [false, "Please enter a valid Pan Number"]
//     }

//     return [true, ""]
// }

function validateCustomerId(value, validator) {
    if (validator.isEmpty(value)) {
        return [false, "This field is mandatory"]
    }

    return [true, ""]
}

function validatePolicyNumber(value, validator) {
    if (validator.isEmpty(value)) {
        return [false, "This field is mandatory"]
    }

    return [true, ""]
}

export function validatePinCode({ field, form, validator }) {

    let journeyTypeValue = form.$("journey_type").value.id

    let currentFieldValue = field.value
    if (checkIsValueEmpty(currentFieldValue) && journeyTypeValue == 1) {
        return [false, "This field is mandatory"]
    }

    return [true, ""]
}

export function validateExistingCustomerValues({ field, form, validator }) {

    let customerTypeOptionValue = form.$("existing_customer_type_option_list").value.id
    let customerType = form.$("customer_type").value
    let currentFieldValue = field.value

    if (customerType === 'New Customer') {
        return [true, ""]
    }

    switch (customerTypeOptionValue) {
        case "1":
            // mobile no
            // return validateMobileNo(currentFieldValue, validator)
            break;
        case "2":
            //pan no
            // return validatePanNumber(currentFieldValue, validator)
            break;
        case "3":
            return validateCustomerId(currentFieldValue, validator)
            // cusotmer id
            break;
        case "4":
            // policy no
            return validatePolicyNumber(currentFieldValue, validator)
            break;
    }

    return [true, ""]

}