// Project Imports
import ProductNames from "constants/ProductNames";
import ProductPackageId from "constants/ProductPackageId";

const DeathBenefitAmount = function (data, key) {
  console.log("DeathBenefitAmount", data);
  /**
   * TODO write logic to pass data and based on products return the death benefit value rounded off.
   */
  let deathBenefitAmt = 0;
  switch (data.product_id) {
    case ProductNames.easyPension:
    case ProductNames.wealthPlus:
    case ProductNames.wealthUltima:
    case ProductNames.wealthPremier:
      return  <span>&#8377; {Math.round(data.firstYearResponse.DEATH_BEN).toLocaleString('en-IN')}</span> ;

    case ProductNames.foreverPension:
    case ProductNames.saralPension:
    case ProductNames.posSaralNivesh:
    case ProductNames.guaranteedIncomeStar:
    case ProductNames.guaranteedSavingsStar:
      return <span>&#8377; {Math.round(data?.firstYearResponse?.DB_G).toLocaleString('en-IN')}</span> ;

    case ProductNames.premierGuranteedIncome:
      switch (data.package_id) {
        case ProductPackageId.PREMIER_GUARANTEED_INCOME_LUMPSUM:
        case ProductPackageId.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
        case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
        case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
        case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME:
        case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
        case ProductPackageId.PREMIER_GUARANTEED_RETIREMENT_INCOME:
          return <span>&#8377; {Math.round(data?.firstYearResponse?.DEATH_BENEFIT || 0).toLocaleString('en-IN')}</span> ;

        // case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
        // case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
        //   data?.biResponseCompleteData.forEach((item) => {
        //     if (Number(item.INCOME_BEN_PAY_OUT) != 0 && deathBenefitAmt === 0) deathBenefitAmt = item.INCOME_BEN_PAY_OUT;
        //   });
        //   return Math.round(deathBenefitAmt);

        default:
          return 0;
      }

    case ProductNames.activeIncomePlan:
    case ProductNames.GCAP:
    case ProductNames.flexiSavingsPlan:
      return <span>&#8377; {Math.round(data?.firstYearResponse?.SAD).toLocaleString('en-IN')}</span> ;

    case ProductNames.cashFlowProtectionPlus:
    case ProductNames.smartLifestyle:
      return <span>&#8377; {Math.round(data?.firstYearResponse?.DB_G_DISPLAY).toLocaleString('en-IN')}</span> ;

    case ProductNames.incomeBuilder:
    case ProductNames.dhanLabh:
    case ProductNames.singlePayEndowmentAssuarancePlan:
      return <span>&#8377; {Math.round(data?.firstYearResponse?.SA).toLocaleString('en-IN')}</span> ;

    default:
      return 0;
  }
};

export default DeathBenefitAmount;
