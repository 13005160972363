// Project Imports
import axios from "utils/axios";
import * as APITypes from "constants/API"
import AuthStore from "modules/login/store/AuthStore";
import checkIsValueEmpty from "utils/checkIsValueEmpty";
import NeedTypeEnum from 'constants/NeedTypeEnum'
import incomeTypeEnum from "constants/incomeTypeEnum";
import ChooseNeedsStore from "modules/choose-needs/store/ChooseNeedsStore";
import { toJS } from "mobx";
import OptimizationStore from "modules/optimization/store/OptimizationStore";

class Utils {

    toggleDisabledState = (formRef, fieldName, value) => {
        formRef.$(fieldName).set("disabled", value)

        const fieldValues = formRef.$(fieldName).get()

        if (value) {
            // formRef.$(fieldName).$('target_amount_dropdown').set("value", {})

            switch (fieldName) {
                case NeedTypeEnum.PROTECTION_FOR_MY_FAMILY:
                    /* resetting the monthly income */
                    formRef.$(fieldName).$('monthly_income').set("value", '')
                    /* resetting to the initial default*/
                    formRef.$(fieldName).$('target_amount_type').set("value", formRef.$("protection_for_my_family.target_amount_type").extra.optionList[0])
                    break;

                case NeedTypeEnum.SAVE_FOR_RETIREMENT:
                    /* resetting to the initial default*/
                    formRef.$(fieldName).$('target_amount_type').set("value", formRef.$("save_for_retirement.target_amount_type").extra.optionList[0])
                    break;
                default:
                    break;
            }

            for (const key in fieldValues) {
                formRef.$(fieldName).$(key).resetValidation()
            }
        }
        
        // let previouslySelectedValues = toJS(ChooseNeedsStore.previouslySelectedNeedObject)

        // const needList = Object.keys(previouslySelectedValues)

        // if(!value && needList.length !== 0){

        //     for (let needName in previouslySelectedValues){
        //         if(needName === fieldName){
        //             formRef.$(fieldName).$('target_amount_type').set("value", previouslySelectedValues[needName].target_amount_type)
        //             formRef.$(fieldName).$('target_amount_dropdown').set("value", previouslySelectedValues[needName].selectedtargetAmount)
        //         }
        //     }
        // }
    }

    updateTargetAmountValue = (formController, need_name, value) => {
        let targetAmountType = formController.$(need_name).$("target_amount_type").value.type
        let searchType = ''
        if (targetAmountType == 'recommend_income' || targetAmountType == 'income') {
            searchType = 'income'
        } else {
            searchType = 'lumpsum'
        }

        let selectedOption = formController.$(need_name).$("target_amount_type").extra.optionList.find((item) => item.type === searchType)

        if (need_name === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY) {
            let modeType = formController.$(need_name).$("target_amount_type").extra.optionList.find((item) => item.mode === "LUMPSUM")
            formController.$(need_name).$("target_amount_type").value = modeType
        }

        if (need_name === NeedTypeEnum.SAVE_FOR_RETIREMENT) {
            let modeType = formController.$(need_name).$("target_amount_type").extra.optionList.find((item) => item.mode === "INCOME")
            formController.$(need_name).$("target_amount_type").value = modeType
        }

        if (selectedOption !== undefined) {
            formController.$(need_name).$("target_amount_type").value = selectedOption
        }

        formController.$(need_name).$("target_amount_dropdown").set("type", "hidden")
        formController.$(need_name).$("target_amount_value").set("type", "text")
        formController.$(need_name).$("target_amount_value").value = value
        formController.$(need_name).$("calculator_used").value = true
    }

    createNeedList = (values, need_name, is_selected) => {
        let currentNeedValue = values[need_name]

        let payoutMode = null
        let calculatorUsed = false

        let targetAmountType = currentNeedValue.target_amount_type.mode
        if (targetAmountType !== undefined) {
            payoutMode = currentNeedValue.target_amount_type.mode
        }

        if (currentNeedValue.calculator_used !== undefined) {
            calculatorUsed = currentNeedValue.calculator_used
        }

        if (need_name === NeedTypeEnum.PROTECTION_FOR_MY_HEALTH) {
            payoutMode = incomeTypeEnum.LUMPSUM
        }

        return {
            need_id: currentNeedValue.id,
            target_amount: currentNeedValue.target_amount_dropdown?.id ? currentNeedValue.target_amount_dropdown.id : null,
            is_selected: is_selected,
            is_calculator_used: calculatorUsed,
            payout_mode: payoutMode,
            target_amount_value: currentNeedValue.target_amount_value ? currentNeedValue.target_amount_value : ""
        }
    }

    submitDataToTheServer = async (formController) => {
        let values = formController.values();
        let monthlyIncome = values.protection_for_my_family?.monthly_income ? values.protection_for_my_family?.monthly_income : ""

        let needList = []

        needList.push(
            this.createNeedList(
                values,
                'protection_for_my_family',
                !formController.$("protection_for_my_family").disabled
            )
        )

        needList.push(
            this.createNeedList(
                values,
                'protection_for_my_health',
                !formController.$("protection_for_my_health").disabled
            )
        )

        needList.push(
            this.createNeedList(
                values,
                'grow_my_money',
                !formController.$("grow_my_money").disabled
            )
        )

        needList.push(
            this.createNeedList(
                values,
                'save_for_children',
                !formController.$("save_for_children").disabled
            )
        )

        needList.push(
            this.createNeedList(
                values,
                'save_for_retirement',
                !formController.$("save_for_retirement").disabled
            )
        )

        let data = {
            "journey_id": AuthStore.journeyId,
            "need_list": needList,
        }

        if (!checkIsValueEmpty(monthlyIncome)) {
            data['monthly_income'] = monthlyIncome
        }
        
        // ! need_id: 4 : for protection for my family,
        // ! need_id: 3 for retirement calculator
        const FamilyAndRetirementNeedIdArray = [4,3]
        const needContainingCalculator = data.need_list.filter(item => FamilyAndRetirementNeedIdArray.includes(item.need_id))
        
        needContainingCalculator.forEach((item) => {

            const {need_id,is_calculator_used} = item

            if(need_id === 4){
                ChooseNeedsStore.setIsProtectionCalculatorUsed(is_calculator_used)
            }else if(need_id === 3){
                ChooseNeedsStore.setIsRetirementCalculatorUsed(is_calculator_used)
            }
        })

        const needResponse = await axios.post(APITypes.SAVE_NEED, data)

        await AuthStore.fetchJourneyData()

        if (needResponse.data && needResponse.status == 'success') {
            console.log("Data is ", needResponse.data)
        }

        console.log("Data is ", data)
    }

     updateCalculatorValues = async (monthlyIncomeValue,formController) => {

        const isProtectionCalculatorUsed = formController.$("protection_for_my_family").$("calculator_used").value
        let protectionCalculatorReqObj = toJS(OptimizationStore.protectionCalculatorReqestObject)
        
        if(isProtectionCalculatorUsed){
    
          let {data: {lumpsum},status} = await axios.post(APITypes.PROTECTION_CALCULATOR, {...protectionCalculatorReqObj,monthly_income: monthlyIncomeValue});
    
          this.updateTargetAmountValue(formController, "protection_for_my_family", lumpsum)
          formController.$("protection_for_my_family").$("is_value_updated").set('value',true)
    
          if (status == 'error') {
            return
          } else {
            AuthStore.fetchJourneyData()
          }
        }
      }

}


export default new Utils();