import ProductNames from "constants/ProductNames";
import ProductPackageId from "constants/ProductPackageId";
import Utils from "modules/optimization/components/products/wealth-ultima/utils";

const forwardNreverseCalculationBIRequestManager = function (bi_request_data, type, amount, package_id) {
  console.log("#$#$#$#$#$#$#$#$#$#$#$#$#$#$ forwardNreverseCalculationBIRequestManager Start #$#$#$#$#$#$#$#$#$#$#$#$#$#$");
  console.log("forwardNreverseCalculationBIRequestManager type :: ", type);
  console.log("forwardNreverseCalculationBIRequestManager amount :: ", amount);
  console.log("forwardNreverseCalculationBIRequestManager bi_request_data :: ", bi_request_data);
  console.log("#$#$#$#$#$#$#$#$#$#$#$#$#$#$ forwardNreverseCalculationBIRequestManager End #$#$#$#$#$#$#$#$#$#$#$#$#$#$");
  /// Note type will be either "target" or "premium"
  switch (bi_request_data["PR_ID"]) {
    case ProductNames.zindagiPlus:
      if (type === "target") {
        bi_request_data["PR_SA"] = amount.toString();
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      }
      break;
    case ProductNames.totalProtectPlus:
      if (type === "target") {
        bi_request_data["PR_SA"] = amount.toString();
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      }
      break;
    case ProductNames.saralJeevanBima:
      if (type === "target") {
        bi_request_data["PR_SA"] = amount.toString();
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      }
      break;
    case ProductNames.criticarePlus:
      if (type === "target") {
        bi_request_data["PR_SA"] = amount.toString();
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      }
      break;

    case ProductNames.foreverPension:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

    case ProductNames.easyPension:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

    case ProductNames.saralPension:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

    case ProductNames.wealthUltima:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        let prAnnPrem = 0;
        let inputMode = parseInt(bi_request_data["INPUT_MODE"]);
        if (inputMode === 1) {
          prAnnPrem = amount * 1;
        } else if (inputMode === 2) {
          prAnnPrem = amount * 2;
        } else if (inputMode === 3) {
          prAnnPrem = amount * 4;
        } else if (inputMode === 4) {
          prAnnPrem = amount * 12;
        }
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = Math.round(amount).toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
        bi_request_data = Utils.calculateDeathBenefitValueAndUpdateBIRequest(bi_request_data, amount, true);
      }
      break;

    case ProductNames.premierGuranteedIncome:
      if (type === "target") {

        switch (Number(bi_request_data["INCOME_BENEFIT_PAYOUT_FREQUENCY"])) {
          case 1:
          case 5:
            bi_request_data["TargetAmount"] = amount.toString();
            break;
          case 2:
            bi_request_data["TargetAmount"] = Math.round(Math.round(amount) * 2).toString();
            break;
          case 3:
            bi_request_data["TargetAmount"] = Math.round(Math.round(amount) * 4).toString();
            break;
          case 4:
            bi_request_data["TargetAmount"] = Math.round(Math.round(amount) * 12).toString();
            break;
        }
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        // bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

    case ProductNames.activeIncomePlan:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = ""
        bi_request_data["AnnualPayout"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
        bi_request_data["PR_ModalPrem"] = 0;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["PR_MI"] = 0;
        bi_request_data["AnnualPayout"] = 0;
        bi_request_data["PR_ModalPrem"] = amount.toString();
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

    case ProductNames.cashFlowProtectionPlus:
      if (type === "target") {
        bi_request_data["PR_SA"] = amount.toString();
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
        bi_request_data["MODAL_PREM"] = "";
      }
      break;

    case ProductNames.incomeBuilder:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 1;
        bi_request_data["MODAL_INCOME"] = amount.toString();
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
        bi_request_data["MODAL_INCOME"] = "";
      }
      break;

    case ProductNames.guaranteedSavingsStar:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["MODAL_INCOME"] = "";
        if (Number(bi_request_data["MaturityBenefitOption"]) === 1) {
          bi_request_data["LUMPSUM_AMOUNT"] = amount.toString();
          bi_request_data["EQUAL_ANNUAL_INST"] = "";
        } else {
          bi_request_data["EQUAL_ANNUAL_INST"] = amount.toString();
          bi_request_data["LUMPSUM_AMOUNT"] = "";
        }
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
        bi_request_data["MODAL_INCOME"] = "";
        bi_request_data["EQUAL_ANNUAL_INST"] = "";
        bi_request_data["LUMPSUM_AMOUNT"] = "";
      }
      break;

    case ProductNames.GCAP:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

    case ProductNames.dhanLabh:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

    case ProductNames.smartLifestyle:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 1;
        bi_request_data["SbNgDis"] = amount.toString();
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
        bi_request_data["SbNgDis"] = 0;
      }
      break;

    case ProductNames.singlePayEndowmentAssuarancePlan:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

    case ProductNames.wealthPlus:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

    case ProductNames.wealthPremier:
      if (type === "target") {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        if (Number(bi_request_data["LifeCoverOption"]) === 1) {
          bi_request_data["PR_SA"] = Math.round(1.25 * Number(amount));
        } else {
          bi_request_data["PR_SA"] = Math.round(10 * Number(amount));
        }
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

    case ProductNames.guaranteedIncomeStar:
      if (type === "target") {
        bi_request_data["PR_PT"] = 0;
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = ""
        bi_request_data["ModalIncomeBenefit"] = amount.toString();
        bi_request_data["ReverseCalcFlag"] = 1;
      } else {
        bi_request_data["PR_PT"] = 0;
        bi_request_data["PR_SA"] = 0;
        bi_request_data["PR_ANNPREM"] = amount.toString();
        bi_request_data["ModalIncomeBenefit"] = 0;
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      }
      break;

      case ProductNames.posSaralNivesh:
      if (type === "target") {
        bi_request_data["PR_SA"] = amount.toString();
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 0;
      } else {
        bi_request_data["PR_SA"] = 0;
        bi_request_data["ANN_PREM"] = amount.toString();
        bi_request_data["PR_ANNPREM"] = 0;
        bi_request_data["PR_MI"] = 0;
        bi_request_data["PR_ModalPrem"] = 0;
        bi_request_data["TargetAmount"] = "";
        bi_request_data["ReverseCalcFlag"] = 1;
      }
      break;

    case ProductNames.flexiSavingsPlan:
      switch (package_id) {
        case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
        case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
        case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
        case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
        case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
        case ProductPackageId.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
        case ProductPackageId.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
        case ProductPackageId.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
        case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
        case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
          if (type === "target") {
            bi_request_data["PR_SA"] = 0;
            bi_request_data["PR_ANNPREM"] = 0;
            bi_request_data["RegularIncomeReverse"] = amount.toString();
            bi_request_data["LumpsumReverse"] = 0;
          } else {
            bi_request_data["PR_SA"] = 0;
            bi_request_data["PR_ANNPREM"] = amount.toString();
            bi_request_data["RegularIncomeReverse"] = 0;
            bi_request_data["LumpsumReverse"] = 0;
          }
          break;

        case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
        case ProductPackageId.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
          if (type === "target") {
            bi_request_data["PR_SA"] = 0;
            bi_request_data["PR_ANNPREM"] = 0;
            bi_request_data["RegularIncomeReverse"] = 0;
            bi_request_data["LumpsumReverse"] = amount.toString();
          } else {
            bi_request_data["PR_SA"] = 0;
            bi_request_data["PR_ANNPREM"] = amount.toString();
            bi_request_data["RegularIncomeReverse"] = 0;
            bi_request_data["LumpsumReverse"] = 0;
          }
          break;
      }
      break;

    default:
      break;
  }
  return bi_request_data;
};

export default forwardNreverseCalculationBIRequestManager;
