// React Imports
import React, { Component } from "react"

// Package Imports
import Modal from "react-bootstrap/Modal"
import ModalBody from "react-bootstrap/ModalBody"
import { observer } from "mobx-react"

// project imports
import NeedForm  from "./addNeedModalForm"
import FormContext from "context/FormContext";

// CSS Imports
import "./add-need-modal.css"

const AddNeedModal = observer(
  class AddNeedModal extends Component {

    static contextType = FormContext

    constructor(props) {
      super(props)

      this.state = {
        formController: null,
      }
    }

    hideAddNeedModal = () => {
      this.context.setIsAddNeedModalOpen(false)
    }

    render() {

      return (
        <div className="addNeedModalDiv d-flex align-items-center">
          <Modal
            show={this.context.isAddNeedModalOpen}
            backdrop="static"
            onHide={this.hideAddNeedModal}
            as="section"
            className="addNeedModal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalBody className="addNeedModalBody pb-lg-5">
              <section className="h-100">
                <div className="row addNeedModalContent">
                  <NeedForm
                    hideModal={this.hideAddNeedModal}
                  />
                </div>
              </section>
            </ModalBody>
          </Modal>
        </div>
      )
    }
  }
)

export default AddNeedModal
