import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import Utils from '../../../../../constants/utils';
import OptimizationStore from '../../../store/OptimizationStore';
import fetchRiderValidation from 'utils/fetchRiderValidation'

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Minimum Premium validation 
    minimumPremiumValidation = (form) => {
        // let premium = form.$('premium').value

        // if (Number(premium) < 40000) {
        //     this.errorMessage.push(errorMessageKeys.minimum_premium)
        // }
    }

    // Minimum Entry Age of life insured validation
    minimumEntryAgeValidation = (form, productData) => {
        // let policyTerm = form.$('policy-term').value.id
        // let deathBenefit = form.$('death-benefit-option').value.id
        // let age = productData.data.bi_request.LI_ENTRY_AGE;

        // if (policyTerm == '10' && age < 8) {
        //     this.errorMessage.push(errorMessageKeys.minimum_age.death_benefit_for_pt_10)
        // }

        // if (policyTerm == '15' && age < 3) {
        //     this.errorMessage.push(errorMessageKeys.minimum_age.death_benefit_for_pt_15)
        // }
    }

    // Maxium Entry Age of life insured validation
    maximumEntryAgeValidation = (form, productData) => {
        // let policyTerm = form.$('policy-term').value.id
        // let deathBenefit = form.$('death-benefit-option').value.id
        // let age = productData.data.bi_request.LI_ENTRY_AGE;

        // if (policyTerm == '10') {
        //     if (deathBenefit == 1 && age > 40) {
        //         this.errorMessage.push(errorMessageKeys.maximum_age.option_a_pt_10)
        //     }

        //     if (deathBenefit == 2 && age > 70) {
        //         this.errorMessage.push(errorMessageKeys.maximum_age.option_b_pt_10)
        //     }

        // } else {
        //     if (deathBenefit == 1 && age > 35) {
        //         this.errorMessage.push(errorMessageKeys.maximum_age.option_a_pt_15)
        //     }

        //     if (deathBenefit == 2 && age > 65) {
        //         this.errorMessage.push(errorMessageKeys.maximum_age.option_b_pt_15)
        //     }
        // }
    }

    // rider sum assured validation
    riderSAValidation = (form, productData) => {
        // if (productData.data.firstYearResponse) {
        //     let sum_assured = productData.data.firstYearResponse.SA

        //     if (form.$('ADB').value) {
        //         var ADB_value = Utils.actualValue(form.$('ADB-dropdown').value)
        //         if (ADB_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }
        //     if (form.$('ATPDRider').value) {
        //         var ATPDRider_value = Utils.actualValue(form.$('ATPDRider-dropdown').value)
        //         if (ATPDRider_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }
        //     if (form.$('CriticalIllnessRider').value) {
        //         var CriticalIllnessRider_value = Utils.actualValue(form.$('CriticalIllnessRider-dropdown').value)
        //         if (CriticalIllnessRider_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }
        //     if (form.$('IncomeBenefitRider').value) {
        //         var IncomeBenefitRider_value = Utils.actualValue(form.$('IncomeBenefitRider-dropdown').value)
        //         if (IncomeBenefitRider_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }
        // }
    }

    ridersAgeValidation = (formRef, productData) => {
        // let age = productData.data.bi_request.LI_ENTRY_AGE
        // let adb = formRef.$('ADB').value
        // let atpd = formRef.$('ATPDRider').value
        // let criticalIllness = formRef.$('CriticalIllnessRider').value
        // let incomeBenefit = formRef.$('IncomeBenefitRider').value

        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // let isRiderSelected = (adb || atpd || criticalIllness || incomeBenefit);

        // if (!isRiderSelected) {
        //     return;
        // }

        // if (isMinimumAge) {
        //     this.errorMessage.push(errorMessageKeys.MINIMUM_RIDER_AGE)
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push(errorMessageKeys.MAXIMUM_RIDER_AGE)
        // }
    }

    // Riders Validation
    ridersValidation = (formRef, productData) => {
        // let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
        // let adb = formRef.$('ADB-dropdown').value
        // let atpd = formRef.$('ATPDRider-dropdown').value
        // let income = formRef.$('IncomeBenefitRider-dropdown').value

        // let adbValidation = fetchRiderValidation(adb, formRef.$('ADB-dropdown').label)
        // if (adbValidation) {
        //     this.errorMessage.push(adbValidation)
        // }

        // let atpdValidation = fetchRiderValidation(atpd, formRef.$('ATPDRider-dropdown').label)
        // if (atpdValidation) {
        //     this.errorMessage.push(atpdValidation)
        // }

        // let criticalIllnessValidation = fetchRiderValidation(criticalIllness, formRef.$('CriticalIllnessRider-dropdown').label)
        // if (criticalIllnessValidation) {
        //     this.errorMessage.push(criticalIllnessValidation)
        // }

        // let incomeValidation = fetchRiderValidation(income, formRef.$('IncomeBenefitRider-dropdown').label)
        // if (incomeValidation) {
        //     this.errorMessage.push(incomeValidation)
        // }
    }
}
export default new Validator();