export default {
    "MINIMUM_60_PAY_PT": 'Minimum Policy Term for Pay till 60 is 15 ',
    "MINIMUM_REGULAR_PAY_PT": 'Minimum Policy Term for Pay till 60 is 15 ',
    "SPOUSE_NAME": "Spouse Name should be less than 20 characters",
    "SPOUSE_GENDER": "Life Assured and Spouse Gender cannot be same",
    "rider": "Rider Sum Assured must be Less than Product\'s Sum Assured",
    "PT_PPT": "PTPPT combination could not be determined for given inputs",
    "SPOUSE_DETAILS_MANDATORY": "If marital status is Married, then you have to select Spouse Name, Gender & DOB",
    "BETTER_HALF_BENEFIT_VALIDATION": "Marital status should be married for Better Half Benefit",
    'MINIMUM_RIDER_AGE': "Minimum Entry age for riders is 18 years",
    "MAXIMUM_RIDER_AGE": "Maximum Entry age for riders is 65 years",
    "SPOUSE_NATIONALITY_VALIDATION": "We can\’t offer the Better Half Benefit because Spouse’s Nationality is Foreign National."
}