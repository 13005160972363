import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { CustomRadioButton } from '../../../../components'

import './change-product-modal.css'

import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import OptimizationStore from 'modules/optimization/store/OptimizationStore';

const ChangeProductModal = observer(
class ChangeProductModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedProduct: {}
    }
  }

  onChangeProduct(value) {
    this.setState({ selectedProduct: value })
  }

  onSubmitProduct() {
    OptimizationStore.onChangeProduct(this.state.selectedProduct, this.props.data, this.props.cardKey)
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.selectedProduct.plan_name &&  this.state.selectedProduct.plan_name && prevState.selectedProduct.plan_name === this.state.selectedProduct.plan_name) {
      this.setState({ selectedProduct: {} })
    }
  }
  render() {
    let { show, onHide } = this.props;
    return (
      OptimizationStore.changeProductList && <div>
        <Modal
          className='change-product-list-modal left-bottom-swipe-modal'
          show={show}
          onHide={() => OptimizationStore.isChangeProductModalOpen = false}
          keyboard={false}
        >
          <Modal.Header closeButton className='border-0 shadow-sm'>
            <Modal.Title className='font-weight-medium font-size-20 font-size-md-28 primary-title-label-color w-100 text-center align-self-center pl-5'>
              Select Product
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className=''>
            {OptimizationStore.changeProductList.map((value, index) => (
              <div className='p-3 modal-product-list-card' key={index}>
                <CustomRadioButton
                  id={value.id}
                  shape='rounded'
                  title={value.plan_name}
                  groupName='productlist'
                  containerStyle='my-0 w-100'
                  titleStyle='text-primary-title-label-color font-weight-normal text-wrap font-size-18 font-size-md-18 pl-2'
                  checkmarkPosition='mt-1'
                  isChecked={this.state.selectedProduct.id === value.id}
                  mark='checkmark'
                  onChange={() => this.onChangeProduct(value)}
                />
              </div>
            ))}

          </Modal.Body>
          <Modal.Footer className='change-product-reason-modal-footer-btn-group'>
            <Button variant='secondary' className='font-size-18 ml-1' disabled={Object.keys(this.state.selectedProduct).length === 0} onClick={() => this.onSubmitProduct()}>
              Change Product
            </Button>
            <Button variant='outline-secondary' className='font-size-18 mr-1' onClick={()=> OptimizationStore.isChangeProductModalOpen = false}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    )
  }
}
)

ChangeProductModal.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default ChangeProductModal
