import AuthStore from '../../../../login/store/AuthStore';
import errorMessagesKeys from './errorMessageKeys';
import Utils from '../../../../../constants/utils';
import OptimizationStore from '../../../store/OptimizationStore';
import fetchRiderValidation from 'utils/fetchRiderValidation'

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    // tru or false
    errorMessage = [];

    // Minimum Premium Validation
    minimalAnnualPremiumFor7Pay = (form) => {
        let frequency = form.$('payment-frequency').value.id
        let premium = form.$('premium').value
        let ppt = form.$('premium-term').value.id

        if (ppt != 7) {
            return
        }

        // switch (frequency) {
        //     case '1':
        //         if (premium < 7500) {
        //             this.errorMessage.push(errorMessagesKeys.minimum_annual_premium_7_pay.annually)
        //         }
        //         break;

        //     case '2':
        //         if (premium < 4000) {
        //             this.errorMessage.push(errorMessagesKeys.minimum_annual_premium_7_pay.half_yearly)
        //         }
        //         break

        //     case '3':
        //         if (premium < 2000) {
        //             this.errorMessage.push(errorMessagesKeys.minimum_annual_premium_7_pay.quaterly)
        //         }
        //         break;

        //     case '4':
        //         if (premium < 1000) {
        //             this.errorMessage.push(errorMessagesKeys.minimum_annual_premium_7_pay.monthly)
        //         }
        //         break;
        // }
    }

    minimalAnnualPremiumForOtherPay = (form) => {
        // let frequency = form.$('payment-frequency').value.id
        // let premium = form.$('premium').value
        // let ppt = form.$('premium-term').value.id

        // if (ppt == 7) {
        //     return
        // }

        // switch (frequency) {
        //     case '1':
        //         if (premium < 5000) {
        //             this.errorMessage.push(errorMessagesKeys.minimum_annual_premium_other_pay.annually)
        //         }
        //         break;

        //     case '2':
        //         if (premium < 3000) {
        //             this.errorMessage.push(errorMessagesKeys.minimum_annual_premium_other_pay.half_yearly)
        //         }
        //         break

        //     case '3':
        //         if (premium < 1500) {
        //             this.errorMessage.push(errorMessagesKeys.minimum_annual_premium_other_pay.quaterly)
        //         }
        //         break;

        //     case '4':
        //         if (premium < 1000) {
        //             this.errorMessage.push(errorMessagesKeys.minimum_annual_premium_other_pay.monthly)
        //         }
        //         break;
        // }
    }

    // rider sum assured validation
    riderSAValidation = (form, productData) => {
        // let sum_assured = productData.data.firstYearResponse.SA

        // if (form.$('ADB').value) {
        //     var ADB_value = Number(form.$('ADB-dropdown').value)
        //     if (ADB_value > sum_assured) {
        //         this.errorMessage.push(errorMessagesKeys.rider)
        //     }
        // }

        // if (form.$('ATPDRider').value) {
        //     var ATPDRider_value = Number(form.$('ATPDRider-dropdown').value)
        //     if (ATPDRider_value > sum_assured) {
        //         this.errorMessage.push(errorMessagesKeys.rider)
        //     }
        // }

        // if (form.$('CriticalIllnessRider').value) {
        //     var CriticalIllnessRider_value = Number(form.$('CriticalIllnessRider-dropdown').value)
        //     if (CriticalIllnessRider_value > sum_assured) {
        //         this.errorMessage.push(errorMessagesKeys.rider)
        //     }
        // }

        // if (form.$('IncomeBenefitRider').value) {
        //     var IncomeBenefitRider_value = Number(form.$('IncomeBenefitRider-dropdown').value)
        //     if (IncomeBenefitRider_value > sum_assured) {
        //         this.errorMessage.push(errorMessagesKeys.rider)
        //     }
        // }
    }

    ridersAgeValidation = (formRef, productData) => {
        // let age = productData.data.bi_request.LI_ENTRY_AGE
        // let adb = formRef.$('ADB').value
        // let atpd = formRef.$('ATPDRider').value
        // let criticalIllness = formRef.$('CriticalIllnessRider').value
        // let wop = formRef.$('WaiverOfPremiumRider').value
        // let incomeBenefit = formRef.$('IncomeBenefitRider').value

        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // let isRiderSelected = (wop || adb || atpd || criticalIllness || incomeBenefit);

        // if (!isRiderSelected) {
        //     return;
        // }

        // if (isMinimumAge) {
        //     this.errorMessage.push(errorMessagesKeys.MINIMUM_RIDER_AGE)
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push(errorMessagesKeys.MAXIMUM_RIDER_AGE)
        // }
    }

    pwbRidersAgeValidation = (formRef, productData) => {
        // let age = productData.data.bi_request.PROPOSER_AGE
        // let isSameProposer = productData.data.bi_request.SameProposer == true
        // let PWBRider = formRef.$('PayorWaiverBenefitRider').value

        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // if (!PWBRider) {
        //     return;
        // }

        // if (isSameProposer) {
        //     this.errorMessage.push(errorMessagesKeys.PWB_RIDER_VALIDATION)
        // }
        // if (isMinimumAge) {
        //     this.errorMessage.push(errorMessagesKeys.MINIMUM_RIDER_AGE)
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push(errorMessagesKeys.MAXIMUM_RIDER_AGE)
        // }
    }

    // Riders Validation
    ridersValidation = (formRef, productData) => {
        // let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
        // let adb = formRef.$('ADB-dropdown').value
        // let atpd = formRef.$('ATPDRider-dropdown').value
        // let income = formRef.$('IncomeBenefitRider-dropdown').value

        // let adbValidation = fetchRiderValidation(adb, formRef.$('ADB-dropdown').label)
        // if (adbValidation) {
        //     this.errorMessage.push(adbValidation)
        // }

        // let atpdValidation = fetchRiderValidation(atpd, formRef.$('ATPDRider-dropdown').label)
        // if (atpdValidation) {
        //     this.errorMessage.push(atpdValidation)
        // }

        // let criticalIllnessValidation = fetchRiderValidation(criticalIllness, formRef.$('CriticalIllnessRider-dropdown').label)
        // if (criticalIllnessValidation) {
        //     this.errorMessage.push(criticalIllnessValidation)
        // }

        // let incomeValidation = fetchRiderValidation(income, formRef.$('IncomeBenefitRider-dropdown').label)
        // if (incomeValidation) {
        //     this.errorMessage.push(incomeValidation)
        // }
    }
}
export default new Validator();