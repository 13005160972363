import React, { useState, useEffect } from "react"

import { Row, Col, Form } from "react-bootstrap"
import { observer } from "mobx-react"

import { BottomsheetDropdown, ChangeProductSection } from "components"
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import Utils from "./utils"
import AdditionalRiderBenifitsCard from "components/ui/additional-rider-benifits-card/AdditionalRiderBenifitsCard"
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed"
import { NUMBER_REGEX } from "constants/Constants"
import * as keys from "./keys"
import DeathBenefitAmount from "utils/optimization/deathBenefitAmount";

const GSS = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false)

  let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let biResponse = productData.parsedResponse
  let firstYearResponse = productData.firstYearResponse
  let biRequest = productData.bi_request
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)
  let tabs = data.data.BI
  let updatedValues
  tabs.length > 0 &&
    tabs.map((tab) => {
      if (tab.risk === data.selectedTab) {
        updatedValues = tab.updatedValues ? tab.updatedValues : null
      }
    })

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])

  const onHideOverlay = () => {
    setShowOverlay(false)
  }

  return (
    <div className="save-my-children">
      <Row className="align-items-center mt-md-4">
        <Col xs={12} md={6} className="mt-3 mt-md-0">
          <ChangeProductSection
            productName={
              productData.product_name
            }
            cardName={coverLabelBaseOnNeed}
            changeProductList={data?.data?.changeProductList}
            productData={data}
          />
        </Col>
      </Row>

      <DeathCoverGraphSlider
        productTitle={productData.product_name}
        openChangeReasonModal={false}
        onIncomeChange={() => { }}
        onPremiumChange={form.$("premium").onChange}
        productID={productData.product_id}
        productData={data?.data}
        income_value=""
        premium_value={form.$("premium").value}
        showOverlay={showOverlay}
        hideOverLay={onHideOverlay}
        onClick={() =>
          form
            .$("premium")
            .extra.calculatePremiun(form, productData.product_id, data)
        }
        data=""
        cardKey={data.cardKey}
        isIncomeFieldDisable={form.$("premium").disabled}
        premiumFreq={form.$("payment-frequency").value.label}
        formRef={form}
      />

      <Row className='mt-3 ml-0'>
        <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{"Sum Assured on Death:"}</p>
        <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(productData)}</p>
      </Row>

      <Row className="mt-2 mt-md-4">
        <Col md={6} className="col-12 mb-3">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("PT").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("PT").label}
            menuList={form.$("PT").extra.optionList}
            value={form.$("PT").value}
            onSelect={form.$("PT").onChange}
            disabled={form.$("PT").disabled}
          />
        </Col>

        <Col md={6} className="col-12 mb-3">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("PPT").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("PPT").label}
            menuList={form.$("PPT").extra.optionList}
            value={form.$("PPT").value}
            onSelect={form.$("PPT").onChange}
            disabled={form.$("PPT").disabled}
          />
        </Col>

        <Col md={4} className="col-12 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("plan-option").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("plan-option").label}
            menuList={form.$("plan-option").extra.optionList}
            value={form.$("plan-option").value}
            onSelect={form.$("plan-option").onChange}
            disabled={form.$("plan-option").disabled}
          />
        </Col>

        <Col md={4} className="col-12 mb-3">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("payment-frequency").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("payment-frequency").label}
            menuList={form.$("payment-frequency").extra.optionList}
            value={form.$("payment-frequency").value}
            onSelect={form.$("payment-frequency").onChange}
          />
        </Col>

        <Col md={4} className="col-12 mb-3">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("family-income-benefit").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("family-income-benefit").label}
            menuList={form.$("family-income-benefit").extra.optionList}
            value={form.$("family-income-benefit").value}
            onSelect={form.$("family-income-benefit").onChange}
            disabled={form.$("family-income-benefit").disabled}
          />
        </Col>

        <Col md={4} className="col-12 mb-3">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("maturity-benefit").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("maturity-benefit").label}
            menuList={form.$("maturity-benefit").extra.optionList}
            value={form.$("maturity-benefit").value}
            onSelect={form.$("maturity-benefit").onChange}
            disabled={form.$("maturity-benefit").disabled}
          />
        </Col>



        <Col md={4} className="col-12 mb-3">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("is-existing-Customer").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("is-existing-Customer").label}
            menuList={form.$("is-existing-Customer").extra.optionList}
            value={form.$("is-existing-Customer").value}
            onSelect={form.$("is-existing-Customer").onChange}
            disabled={form.$("is-existing-Customer").disabled}
          />
        </Col>

        {form.$("policy-no").extra.isVisible &&
          <Col md={4} className="col-12 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light ">
              {form.$("policy-no").label}
            </div>
            <Form.Control
              className="font-size-12 font-weight-light text-black product-form-field form-control"
              placeholder={form.$("policy-no").label}
              value={form.$("policy-no").value}
              onChange={(e) => { form.$('policy-no').set('value', e.target.value) }
              }
              disabled={form.$("policy-no").disabled}
            // onBlur={(e)=>Utils.updateBi(keys.POLICY_NO, e.target.value, productData.product_id, formRef)}
            />
          </Col>
        }

      </Row>

      <Row className="mt-4">
        <Col md={6} className="col-12 my-4 ">
          <AdditionalRiderBenifitsCard
            data={data}
            form={form}
            showCriticalIllness={true}
            showIncomeBenefit={false}
            showHospitalityCashBenefit={true}
            showWOP={true}
            showPWB={true}
          />
        </Col>
      </Row>
    </div>
  )
}

export default observer(GSS)
