
const initialDefaults = (formRef, data) => {
    let requestData = data.data.bi_request
    let ppf = requestData.INPUT_MODE?.toString();
    let sopp = requestData.SOURCE_OF_PP?.toString();
    let annuity_mode = requestData.AnnuityMode?.toString();
    let purchase_Price_amount = requestData.ModalPremium?.toString();
    let annuity_option = requestData.AnnuityOption?.toString();
    let secondary_annuitant_name = requestData.SecondaryAnnuitantName?.toString();
    let secondary_annuitant_date_of_birth = requestData.SecondaryAnnuitantDateofbirth?.toString();
    let secondary_annuitant_gender = requestData.SecondaryAnnuitantGender?.toString();
    let premium_amount = requestData.PR_ANNPREM?.toString();
    let income = requestData.investment_target?.toString();

    // ! payment-frequency:

    if(ppf === '5'){
        formRef.$('payment-frequency').set('value', { id: "5", label: "Single" })
    }

 // ! source-of-purchase-price:
    
 switch (sopp) {
  case '1':
      formRef.$('source-of-purchase-price').set('value', {label:'Open Market', id:'1'})
      break;
  case '2':
      formRef.$('source-of-purchase-price').set('value', {label:"Proceeds of pension policy of ETLI Co Ltd",id:"2"})
      break;
  case '3':
      formRef.$('source-of-purchase-price').set('value', {label:"Proceeds of pension policy of other Insurer", id:"3"})
      break;

  default:
      break;
}


// ! annuity-mode

switch (annuity_mode) {
 case '8':
     formRef.$('annuity-mode').set('value', {label:"Yearly",id:'8'})
     break;
 case '9':
     formRef.$('annuity-mode').set('value', {label:"Half-yearly",id:'9'})
     break;
 case '10':
  formRef.$('annuity-mode').set('value', {label:"Quarterly",id:'10'})
  break;
  case '11':
  formRef.$('annuity-mode').set('value', {label:"Monthly",id:'11'})
  break;    
 default:
     break;
}

// ! purchase-Price-amount
formRef.$('purchase-price-amount').set('value', purchase_Price_amount)


// ! annuity-option

switch (annuity_option) {
 case '1':
     formRef.$('annuity-option').set('value', {label:"Life Annuity with Return of 100% of Purchase Price (ROP)",id:"1"})
     break;
 case '2':
  formRef.$('annuity-option').set('value', {label:"Joint Life Last Survivor Annuity with Return of 100% of Purchase Price (ROP) on death of the last survivor",id:"2"})
  break;    
         
 default:
     break;
}

// ! secondary-annuitant-name
formRef.$('secondary-annuitant-name').set('value', secondary_annuitant_name)

// ! secondary-annuitant-date-of-birth
formRef.$('secondary-annuitant-date-of-birth').set('value', secondary_annuitant_date_of_birth)

// ! secondary-annuitant-gender
switch (secondary_annuitant_gender) {
 case '5':
     formRef.$('secondary-annuitant-gender').set('value', {label:"Male",id:'5'})
     break;
 case '6':
     formRef.$('secondary-annuitant-gender').set('value', {label:"Female",id:'6'})
     break;
 case '7':
  formRef.$('secondary-annuitant-gender').set('value', {label:"Transgender",id:'7'})
  break;    
 default:
     break;
}


// ! disabling Name of the Secondary Annuitant, DOB of the Secondary Annuitant, Gender of the Secondary Annuitant
if(annuity_option === '1'){
    formRef.$("secondary-annuitant-name").set("disabled",true)
    formRef.$("secondary-annuitant-date-of-birth").set("disabled",true);
    formRef.$("secondary-annuitant-gender").set("disabled",true);

    //! for setting the values of the default
    formRef.$('secondary-annuitant-name').set('value', '');
    formRef.$("secondary-annuitant-date-of-birth").set("value","");
    formRef.$("secondary-annuitant-gender").set("value","");
}



// ! premium-amount
formRef.$('premium-amt').set('value', premium_amount)

// ! income
formRef.$('income').set('value', income)

}

export default initialDefaults