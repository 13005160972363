
const initializeEducationOccupationFieldsForPopUpModal = (formController, masterData) => {
 let { occupationResponse, educationResponse } = masterData

 formController.$("occupation").set("extra", {
   ...formController.$("occupation").extra,
   optionList: occupationResponse.data,
 })

 formController.$("education").set("extra", {
   ...formController.$("education").extra,
   optionList: educationResponse.data,
 })

   formController.$("education").value = educationResponse.data.find((item) => item.label == "Graduate")
   formController.$("occupation").value = formController.$("occupation").extra.optionList[0]
}

 export default initializeEducationOccupationFieldsForPopUpModal