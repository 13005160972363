require('normalize.css/normalize.css');
require('styles/App.css');

import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { LocationOrangeIcon } from 'constants/Images';


import './textinput.css';

class TextInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      options: [
        {
          label: 'India'
        },
        {
          label: 'USA'
        }
      ],
      selectColor: []
    }
    this.setSelectColor = this.setSelectColor.bind(this);
  }

  setSelectColor(ele) {
    this.setState({ selectColor: ele })
  }

  render() {
    const { placeholder, ...props } = this.props;
    return (
      <div className='bg-white d-flex text-input'>
        <img className='px-3' src={LocationOrangeIcon} alt='location-orange-icon' />
        <Typeahead
          id='inlineFormInputGroup'
          onChange={(event) => this.setSelectColor(event)}
          options={this.state.options}
          placeholder={placeholder}
          selected={this.state.selectColor}
          className='textForm font-size-16 font-weight-normal w-100 border-0'
          {...props}
        />
      </div>
    );
  }
}

export default TextInput;
