// React imports
import React, { Component } from "react";

// Package imports
import { observer } from "mobx-react";
import { toJS } from "mobx";

// Project imports
import NeedCard from "./components/optimization-card/NeedCard";
import OptimizationStore from "./store/OptimizationStore";
import ValidationErrorMessageShowModal from "../family-details-form/components/ValidationErrorMessageShowModal";
import FormContext from "context/FormContext";
import AddInsuredModalComponent from "./components/add-insured-modal/AddInsuredModalComponent";
import FamilyDetailsFormController from "modules/family-details-form/form/controller";
import AuthStore from "modules/login/store/AuthStore";
import addFamilyMemberFromUtils from "utils/addFamilyMember";
import AddMemberCardModal from "modules/family-details-form/components/AddMemberCardModal";
import { fetchSelectedMembers, submitDataToTheServer, reInitializeFamilyMemberData } from "modules/family-details-form/form/utils";
import FamilyMemberModalTypeEnum from "constants/familyMemberModalTypeEnum";
import StorageKeys from "constants/StorageKeys";
import { NextButton, Button as UiButton } from "../../components";
import FamilyMemberTypeEnum from "modules/family-details-form/FamilymemberTypeEnum";
import savePragatiNAInboundData from "utils/savePragatiNAInboundData";
import ValidationMsgForRecalculate from "./components/validation-msg-for-recalculate/ValidationMsgForRecalculate";

// CSS imports
import "./optimization.css";
import { Container } from "react-bootstrap";

const Optimization = observer(
  class Optimization extends Component {
    constructor(props) {
      super(props);
      this.state = {
        familydetailsController: undefined,
        showFamilyMemberModal: false,
        showSelectAtleastOneNeed:false,
      };
    }

    static contextType = FormContext;

    showAddNeedModal = () => {
      this.context.setIsAddNeedModalOpen(true);
      OptimizationStore.setFamilyMemberModalType(FamilyMemberModalTypeEnum.ADD_NEED);
    };

    async componentDidMount() {
      global.isLoading(false);
      await savePragatiNAInboundData(location)
      OptimizationStore.isLoading = false;
      let action = this.props.history.action;
      window.scrollTo(0, 0);
      if (action != "POP") {
        OptimizationStore.emptyProductListData();
        OptimizationStore.updateResponseStructure();
      }
      let formController = new FamilyDetailsFormController();
      let journeyId = toJS(AuthStore.journeyId);
      let journeyData = toJS(AuthStore.journeyData);
      let expenseList = toJS(AuthStore.expenseList);
      let lifeCoverList = toJS(AuthStore.lifeCoverList);
      formController.setDefaults(journeyId, journeyData, expenseList, lifeCoverList);

      this.setState({ familydetailsController: formController });
    }

    onAddFamilyMember = (memberObjList) => {
      const selfDetail = memberObjList.find((obj) => obj.name === FamilyMemberTypeEnum.SELF);
      OptimizationStore.selfFamilyMemberDetail = selfDetail;

      const initialMemberList = memberObjList.map((eachMember) => eachMember.name)

      OptimizationStore.setFamilyMemberList(initialMemberList)
      this.setState({ showFamilyMemberModal: true });
    };

    addFamilyMember = async (formController, hideModal) => {
      if (OptimizationStore.familyMemberModalType !== undefined || OptimizationStore.familyMemberModalType !== "") {
        await addFamilyMemberFromUtils(formController, fetchSelectedMembers, submitDataToTheServer, AuthStore, reInitializeFamilyMemberData, hideModal);
      }

      OptimizationStore.resetInsuredMemberDetails();
    };

    hideFamilymemberModal = () => {
      this.setState({ showFamilyMemberModal: false });
    };

    addInsuredOnHide = () => {
      OptimizationStore.setAddInsuredModalFunction(false);
    };

    render() {
      let product_list_data = toJS(OptimizationStore.product_list_data);

      let familyDataList = [];
      if (this.state.familydetailsController !== undefined && this.state.familydetailsController.has("members")) {
        familyDataList = this.state.familydetailsController.$("members").get();
      }

      
      const listOfDisabledNeeds = product_list_data.map(needObj =>  needObj.data[0].isDisabledNeed)
       OptimizationStore.setListOfTotalNeeds(listOfDisabledNeeds)
      
      let showSelectAtLeastOneNeedErrorMsg = toJS(OptimizationStore.showSelectAtLeastOneNeed) 

      return (
        <div className='container family-protection mb-4 mt-2 overflow-hidden'>
          {product_list_data.length == 0 && !global.isLoading() ? (
            <div className='text-center'>
              <div className='spinner-border text-primary' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          ) : (
            <Container className="optimization-container">
              <div className='row position-r optimization-screen-top-index mb-md-5'>
                <div className='col-12 col-md-12 position-fixed bg-light-gray'>
                  <p className='text-primary font-weight-medium mb-0 mr-1 font-size-18'>
                    Solution Customization
                    <span onClick={this.showAddNeedModal} className='cursor-pointer link-color font-size-14 font-size-lg-12 font-weight-light ml-1'>
                      [Add a need]
                    </span>
                  </p>
                  <p className='mb-0 d-md-flex family-details-form-top-text font-size-12 font-weight-light mb-2'>Choose feature, fine tune solution</p>
                  {showSelectAtLeastOneNeedErrorMsg && <p className='mb-0 d-md-flex text-danger font-size-12 font-weight-light mb-2 font-red'>Please select atleast one need</p>}
                </div>
              </div>
              {product_list_data.map((product_details, index) => {
                return <NeedCard title={product_details.need_title} key={index} orderNumber={index + 1} cardKey={product_details.need_unique_name} data={product_details.data}/>;
              })}

              <AddInsuredModalComponent
                formController={this.state.familydetailsController}
                show={OptimizationStore.isAddInsuredModalOpen}
                onAddMember={this.onAddFamilyMember}
                onAddInsured={() => {}}
                onHide={this.addInsuredOnHide}
              />

              <AddMemberCardModal
                formController={this.state.familydetailsController}
                familymemberList={familyDataList}
                show={this.state.showFamilyMemberModal}
                onAddMember={() => this.addFamilyMember(this.state.familydetailsController, this.hideFamilymemberModal)}
                onHide={this.hideFamilymemberModal}
              />

              <ValidationErrorMessageShowModal />
              <ValidationMsgForRecalculate/>
            </Container>
          )}
        </div>
      );
    }
  }
);

export default Optimization;
