const { Form } = require("mobx-react-form");

import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

import formObserver from './observers';
import setInitialDefaults from './initialDefaults.js';
import * as keys from './keys';
import Utils from './utils';
import * as Constants from 'constants/Constants'

class AIPFormController extends Form {
    plugins() {
        return {
            dvr: dvr(validatorjs)
        }
    }

    setup() {
        return {
            fields: [
                {
                    name: "payout-mode",
                    label: "Payout Mode",
                    placeholder: "Please select death benefit options",
                    rules: "required:string",
                    disabled: false,
                    extra: {
                        optionList: [
                            {
                                id: '3',
                                label: 'Income'
                            },
                            {
                                id: '4',
                                label: 'Lumpsum'
                            }
                        ],
                    }
                },
                {
                    name: "income-option",
                    label: "Income Option",
                    placeholder: "Please enter your Premium",
                    rules: "required:number:min:18",
                    disabled: false,
                    extra: {
                        optionList: [
                            {
                                id: '3',
                                label: 'Early Income'
                            },
                            {
                                id: '4',
                                label: 'Deferred Income'
                            }
                        ],
                    }
                },
                {
                    name: "guranteed-income",
                    label: "Guaranteed Income",
                    placeholder: "",
                    disabled: false,
                    extra: {
                        isVisible: false,
                        optionList: [
                            {
                                id: '5',
                                label: 'Level'
                            },
                            {
                                id: '6',
                                label: 'Increasing'
                            }
                        ],
                    }
                },
                {
                    name: "payment-frequency",
                    label: "Premium Paying Frequency",
                    placeholder: "",
                    extra: {
                        isVisible: false,
                        optionList: [
                            {
                                id: '1',
                                label: 'Annual'
                            },
                            {
                                id: '2',
                                label: 'Semi-Annual'
                            },
                            {
                                id: '3',
                                label: 'Quarterly'
                            },
                            {
                                id: '4',
                                label: 'Monthly'
                            }
                        ],
                    }
                },
                {
                    name: "income",
                    label: "Income",
                    placeholder: "",
                    disabled: true
                },
                {
                    name: "premium",
                    label: "Premium",
                    placeholder: "",
                    disabled: true,
                    extra: {
                        calculatePremiun(form, productId, data) {
                            // let premiumAmount = 0;
                            // let inputMode = Number(form.$('payment-frequency').value.id)

                            // let amount = Number(form.$('premium').value)
                            // switch (inputMode) {
                            //     case 1:
                            //         premiumAmount = '' + amount * 1
                            //         break;
                            //     case 2:
                            //         premiumAmount = '' + amount * 2
                            //         break;
                            //     case 3:
                            //         premiumAmount = '' + amount * 4
                            //         break;
                            //     case 4:
                            //         premiumAmount = '' + amount * 12
                            //         break;
                            // }
                            // Utils.updateBi(keys.PREMIUM, premiumAmount, productId, form, data)
                        }
                    }
                },
                {
                    name: "maturity-age",
                    label: "Maturity Age",
                    placeholder: "",
                    disabled: false,
                    extra: {
                        optionList: [
                            {
                                id: "75-@li_entry_age",
                                label: "75 Years"
                            }, {
                                id: "85-@li_entry_age",
                                label: "85 Years"
                            }, {
                                id: "99-@li_entry_age",
                                label: "99 Years"
                            },
                        ],
                    }
                },
                {
                    name: "premium-term",
                    label: "Premium Paying Term",
                    placeholder: "",
                    extra: {
                        optionList: [],
                    }
                },
                //Additional Benefits
                {
                    name: 'family-income',
                    label: 'Family Income',
                    placeholder: '',
                    type: 'radio',
                    value: false,
                    disabled: false,
                    extra: {
                        description: 'In case of death or diagnosis of 12 covered Critical Illness, your family continues to receive the Income Benefit and Maturity Benefit without having to pay any future premiums. Also, your nominee will receive lumpsum in case of death.',
                        sum_assured: '',
                        changeBenefitStatus(form, data) {
                            let value = form.$('family-income').value
                            let productId = data.data.product_id
                            let familyIncomeValue = "1";
                            form.$('family-income').value = !value

                            if (value) {
                                familyIncomeValue = "2"
                            }

                            Utils.updateBi(keys.FAMILY_INCOME_BENEFIT, familyIncomeValue, productId, form, data)
                        }
                    },
                },
                // Rider
                {
                    name: 'WaiverOfPremiumRider',
                    label: 'Waiver of Premium',
                    placeholder: '',
                    value: false,
                    disabled: false,
                    extra: {
                        description: Constants.WOP_RIDER_DESCRIPTION,
                        paramName: 'WaiverOfPremiumRider',
                        rider_name: 'Waiver of Premium Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('WaiverOfPremiumRider').value
                            let productId = data.data.product_id
                            let wopRiderValue = '';

                            if (!value) {
                                wopRiderValue = '1';
                            }
                            form.$('WaiverOfPremiumRider').value = !value

                            Utils.updateBi(keys.WOP_RIDER, wopRiderValue, productId, form, data)
                        }
                    }
                },
                {
                    name: 'ADB',
                    label: 'Accidental Death Benefit',
                    placeholder: '',
                    value: false,
                    extra: {
                        description: Constants.ADB_RIDER_DESCRIPTION,
                        paramName: 'ADBRider',
                        rider_name: 'ADB Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('ADB').value
                            form.$('ADB').value = !value

                            if (form.$('ADB').value) {
                                form.$('ADB-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
                            } else {
                                form.$('ADB-dropdown').set('value', "")
                            }
                        }
                    }
                },
                {
                    name: 'ADB-dropdown',
                    label: 'Accidental Death Benefit',
                    placeholder: '',
                    value: "",
                    extra: {
                        optionList: Utils.getAdbList(),
                        onBlur(form, data) {
                            let value = form.$("ADB-dropdown").value
                            Utils.updateBi(keys.ADB_RIDER, value, data.data.product_id, form, data)
                        },
                    },
                },
                {
                    name: 'ATPDRider',
                    label: 'Permanent Disability',
                    placeholder: '',
                    value: false,
                    extra: {
                        description: Constants.ATPD_RIDER_DESCRIPTION,
                        paramName: 'ATPDRider',
                        rider_name: 'ATPD Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('ATPDRider').value
                            form.$('ATPDRider').value = !value

                            if (form.$('ATPDRider').value) {
                                form.$('ATPDRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
                            } else {
                                form.$('ATPDRider-dropdown').set('value', "")
                            }
                        }

                    }
                },
                {
                    name: 'ATPDRider-dropdown',
                    label: 'Permanent Disability',
                    placeholder: '',
                    value: "",
                    extra: {
                        optionList: Utils.getAtpdList(),
                        onBlur(form, data) {
                            let value = form.$("ATPDRider-dropdown").value
                            Utils.updateBi(keys.ATPD_RIDER, value, data.data.product_id, form, data)
                        },
                    }
                },
                {
                    name: 'CriticalIllnessRider',
                    label: 'Critical Illness',
                    placeholder: '',
                    value: false,
                    extra: {
                        description: Constants.CRITICAL_ILLNESS_RIDER_DESCRIPTION,
                        paramName: 'CriticalIllnessRider',
                        rider_name: 'Critical Illness Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('CriticalIllnessRider').value
                            form.$('CriticalIllnessRider').value = !value

                            if (form.$('CriticalIllnessRider').value) {
                                form.$('CriticalIllnessRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
                            } else {
                                form.$('CriticalIllnessRider-dropdown').set('value', "")
                            }
                        }
                    }
                },
                {
                    name: 'CriticalIllnessRider-dropdown',
                    label: 'Critical Illness',
                    placeholder: '',
                    value: "",
                    extra: {
                        optionList: Utils.getCriticalIllnessList(),
                        onBlur(form, data) {
                            let value = form.$("CriticalIllnessRider-dropdown").value
                            Utils.updateBi(keys.Critical_ILLNESS_RIDER, value, data.data.product_id, form, data)
                        },
                    }
                },
                {
                    name: 'IncomeBenefitRider',
                    label: 'Income Benefit',
                    placeholder: '',
                    value: false,
                    extra: {
                        description: Constants.INCOME_BENEFIT_RIDER_DESCRIPTION,
                        paramName: 'IncomeBenefitRider',
                        rider_name: 'Income Benefit Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('IncomeBenefitRider').value
                            form.$('IncomeBenefitRider').value = !value

                            if (form.$('IncomeBenefitRider').value) {
                                form.$('IncomeBenefitRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
                            } else {
                                form.$('IncomeBenefitRider-dropdown').set('value', "")
                            }
                        }
                    }
                },
                {
                    name: 'IncomeBenefitRider-dropdown',
                    label: 'Income Benefit',
                    placeholder: '',
                    value: "",
                    extra: {
                        optionList: Utils.getIncomeBenefit(),
                        onBlur(form, data) {
                            let value = form.$("IncomeBenefitRider-dropdown").value
                            Utils.updateBi(keys.INCOME_BENEFIT_RIDER, value, data.data.product_id, form, data)
                        },
                    }
                },
                {
                    name: 'PayorWaiverBenefitRider',
                    label: 'Payor Waivor Benefit',
                    placeholder: '',
                    value: false,
                    extra: {
                        description: Constants.PWB_RIDER_DESCRIPTION,
                        paramName: 'PayorWaiverBenefitRider',
                        rider_name: 'Payor Waiver Benefit Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('PayorWaiverBenefitRider').value
                            form.$('PayorWaiverBenefitRider').value = !value

                            if (form.$('PayorWaiverBenefitRider').value) {
                                form.$('PayorWaiverBenefitRider-dropdown').set('value', form.$('PayorWaiverBenefitRider-dropdown').extra.optionList[0])
                            } else {
                                form.$('PayorWaiverBenefitRider-dropdown').set('value', {})
                            }
                        }
                    }
                },
                {
                    name: 'PayorWaiverBenefitRider-dropdown',
                    label: 'Payor Waivor Benefit',
                    placeholder: '',
                    value: {},
                    extra: {
                        optionList: [
                            { id: 1, label: 'Option 1 (On Death)' },
                            { id: 2, label: 'Option 2 (CI or ATPD)' },
                            { id: 3, label: 'Option 3 (CI or ATPD or Death)' },
                        ]
                    }
                },

            ]
        }
    }

    setDefaults(data) {
        // Set forms initial default value
        setInitialDefaults(this, data)

        // Observer fields
        formObserver(this, data)
    }

}

export default AIPFormController;
