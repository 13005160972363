// Project Imports
import checkIsValueEmpty from "utils/checkIsValueEmpty";

export default function isInteger({ field, validator, }) {
    let defaultMsg = "Please use only numbers"

    const isEmpty = checkIsValueEmpty(field.value)

    if (isEmpty) {
        return [true, defaultMsg]
    }

    if (field.value == 0) {
        return [true]
    }
    const isIntegerOnly = validator.isInt(field.value.toString(), { min: 1});

    return [isIntegerOnly, defaultMsg];
}