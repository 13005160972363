// Package imports
import { observer } from "mobx-react"
import { Form } from "react-bootstrap"

// Project Imports
import CardTextFieldComponent from "modules/family-details-form/components/CardTextFieldComponent"
import { BottomsheetDropdown } from "components"
import Utils from "./utils"

import SingleFamilyDetail from "./SingleFamilyDetail"
import SolutionStore from "modules/solution/store/SolutionStore"

// import SprmDetailForm from "./SPRM/sprmDetailForm";

const CustomerCommunicationForm = ({
  formController,
  hideModal,
  submitData,
  ...remainingProps
}) => {

  const memberFields = formController?.get()

  const familyMembeNameList = [];

     for (const key in memberFields) {
    familyMembeNameList.push(key)
    }

  return (
    <div className="bg-white modal-field-section pb-3 d-flex flex-column flex-grow-1 justify-content-between p-4">
      
      <div className="customer-communication-detail-container flex-grow-1">
      {familyMembeNameList.map((name)=>{
        return <SingleFamilyDetail formController={formController} memberType={name}/>
      })}
      </div>
      

      <div className="row mt-5 mt-lg-4 mb-3">
        <div className="col-12 col-lg-7">
          <div className="row">
            <div className="col-12 col-lg-6 mb-3 mb-lg-0 pb-1 pb-lg-0 order-1 order-lg-1 pl-lg-1">
              <button
                className="btn btn-link btn-lg modal-cta-btn modal-cta-btn-link py-2 py-lg-1 font-size-14 font-weight-medium"
                // onClick={() => Utils.handleModalExit(remainingProps, hideModal)}
                onClick={hideModal}
              >
                Cancel
              </button>
            </div>
            <div className="col-12 col-lg-6 mb-3 mb-lg-0  order-0 order-lg-0 pr-lg-1">
              <button
                type="submit"
                // disabled={formController.$("proceed_btn").disabled}
                className="btn btn-secondary btn-lg modal-cta-btn py-2 py-lg-1 font-size-14 font-weight-medium"
                onClick={submitData}
              >
                {SolutionStore.isCloseModalOpen ? 'Save' : 'Preview'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(CustomerCommunicationForm)
