import setFundAllocation from 'utils/setFundAllocation';
import Utils from './utils';
import DeathBenefitAmount from 'utils/optimization/deathBenefitAmount';
import  PRODUCT from 'constants/ProductConstants'

const initialDefaults = (formRef, data) => {
    let wealthUltimaData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
    let productName = wealthUltimaData.product_name
    let biRequest = wealthUltimaData?.bi_request
    let firstYearResponse = wealthUltimaData.firstYearResponse;
    let age = biRequest.LI_ENTRY_AGE
    let ppf = biRequest.INPUT_MODE.toString()
    let option = biRequest?.optionId1?.toString()
    let ptList = Utils.generatePT(age, option)
    let littleChamp = productName === PRODUCT.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA ? '4'  : biRequest.optionId2
    let fundStrategy = biRequest.FUNDSTRATEGYID
    let annualPremium = firstYearResponse?.MODAL_PREM ? firstYearResponse?.MODAL_PREM: biRequest.PR_ANNPREM;
    let systematicWithdrawalOption = productName === PRODUCT.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA ? '5' :  biRequest.optionId3
    let swpFrequency = biRequest.optionId5
    let fundList = formRef.$("fund-strategy").extra.fundList;
    let updatedFundList = [];
    let deathBenefitAmountValue = DeathBenefitAmount(wealthUltimaData);

    if(productName === PRODUCT.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA){
        formRef.$('systematic-withdrawal-option').set('disabled', true)
    }

    formRef.$('little-champ-benefit').set('disabled', true) // little champ benefit should be disabled by default
    formRef.$('systematic-withdrawal-option').set('disabled', true) // systematic-withdrawal-option should be disabled by default

    formRef.$('death-benefit-amount').set('value', deathBenefitAmountValue)

    let premiumAmount = ''
    switch (ppf) {
        case '1':
            premiumAmount = Math.round(annualPremium);
            break;
        case '2':
            premiumAmount = Math.round(annualPremium) / 2;
            break;
        case '3':
            premiumAmount = Math.round(annualPremium) / 4;
            break;
        case '4':
            premiumAmount = Math.round(annualPremium) / 12;
            break;

        default:
            break;
    }
    formRef.$('premium').set('value', Math.round(premiumAmount))
    // formRef.$('premium').set('value', annualPremium)

    formRef.$('policy-term').set('extra', {
        ...formRef.$('policy-term').extra,
        options: ptList
    })

    formRef.$('policy-term').set('value', {
        id: biRequest.PR_PT,
        label: biRequest.PR_PT
    })

    let pptList = Utils.generatePPT(biRequest.PR_PT, age, option,productName)

    formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        options: pptList
    })

    formRef.$('premium-term').set('value', {
        id: biRequest.PR_PPT,
        label: biRequest.PR_PPT
    })

    switch (ppf) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '2':
            formRef.$('payment-frequency').set('value', {
                id: '2',
                label: 'Half-Yearly'
            })
            break;
        case '3':
            formRef.$('payment-frequency').set('value', {
                id: '3',
                label: 'Quarterly'
            })
            break;
        case '4':
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }

    switch (Number(option)) {
        case 1:
            formRef.$('policy-option').set('value', {
                id: 1,
                label: 'Option 1'
            })
            break;
        case 2:
            formRef.$('policy-option').set('value', {
                id: 2,
                label: 'Option 2'
            })
            break;

        default:
            break;
    }

    switch (littleChamp) {
        case '3':
            formRef.$('little-champ-benefit').set('value', {
                id: '3',
                label: 'Yes'
            })
            break;
        case '4':
            formRef.$('little-champ-benefit').set('value', {
                id: '4',
                label: 'No'
            })
            break;


        default:
            break;
    }

    switch (systematicWithdrawalOption) {
        case '5':
            formRef.$('systematic-withdrawal-option').set('value', {
                id: '5',
                label: 'Yes'
            })
            break;
        case '6':
            formRef.$('systematic-withdrawal-option').set('value', {
                id: '6',
                label: 'No'
            })
        default:
            break;
    }

    let withdrawalYearList = Utils.populateWithdrawlPercentage()
    formRef.$('fund-value').set('extra', {
        ...formRef.$('fund-value').extra,
        optionList: withdrawalYearList
    })

    let withdrawalYear = withdrawalYearList.filter(item => biRequest.optionId4 == item.id)[0] || {}

    formRef.$('fund-value').value = withdrawalYear

    let fundWithdrawlYearList = Utils.populateWithdrawlyear();

    let fundWithdrawlyear = fundWithdrawlYearList.filter(obj => obj.id == biRequest.optionValue6)[0] || {}

    formRef.$('withdrawal-year').value = fundWithdrawlyear.id

    switch (fundStrategy) {
        case '1':
            formRef.$('fund-strategy').set('value', {
                id: '1',
                label: 'Self-managed Strategy'
            })
            break;
        case '2':
            formRef.$('fund-strategy').set('value', {
                id: '2',
                label: 'Life stage and duration based Strategy'
            })
            break;
        case '3':
            formRef.$('fund-strategy').set('value', {
                id: '3',
                label: 'Profit Target based STP'
            })
            break;

        default:
            break;
    }

    switch (swpFrequency) {
        case '117':
            formRef.$('swp-frequency').set('value', {
                id: '117',
                label: 'Annual'
            })
            break;
        case '118':
            formRef.$('swp-frequency').set('value', {
                id: '118',
                label: 'Semi-Annual'
            })
            break;
        case '119':
            formRef.$('swp-frequency').set('value', {
                id: '119',
                label: 'Quarterly'
            })
            break;
        case '120':
            formRef.$('swp-frequency').set('value', {
                id: '120',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }

    updatedFundList = setFundAllocation(fundList, biRequest);
    formRef.$("fund-strategy").set('extra', {
        ...formRef.$('fund-strategy').extra,
        showFundDistributionModal: false,
        fundList: updatedFundList
    })

}

export default initialDefaults