// Package Imports
const _ = require('lodash');

// Used to create new object & also remove the reference of the object from which it is created
// Usefule for removing reference from the object

const createNewObject = (obj) => {

    if (obj === undefined) {
        return new Object();
    }

    return _.cloneDeep(obj);
}

export default createNewObject