import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from "./validator";

class Utils {
  getCoverAmountList = () => {
    let list = [];
    for (let index = 0; index <= 100; index = index + 5) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + " L",
        });
      } else {
        list.push({
          id: index,
          label: 1 + " Cr",
        });
      }
    }
    return list;
  };

  getCoverAmountListForCLRnHCB = () => {
    let list = [];
    for (let index = 0; index <= 6; index = index + 1.5) {
      list.push({
        id: index == 0 ? 1 : index,
        label: (index == 0 ? 1 : index) + " L",
        key: index * 100000,
      });
    }
    return list;
  };

  updateBi(key, value, productId, formRef, productData) {
    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request;
    switch (key) {
      case keys.PR_PT:
        biRequest.PR_PT = value;
        break;

      case keys.PR_PPT:
        biRequest.PR_PPT = value;
        break;

      case keys.FREQUENCY:
        biRequest.FREQUENCY = value;
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value;
        break;

      case keys.DEATH_BENEFIT_OPTION:
        biRequest[keys.DEATH_BENEFIT_OPTION] = value;
        break;

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = value;
        } else {
          biRequest[keys.ADB_RIDER] = "";
        }
        break;

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = value;
        } else {
          biRequest[keys.ATPD_RIDER] = "";
        }
        break;

      case keys.CRITICAL_ILLNESS_RIDER:
        if (value) {
          biRequest[keys.CRITICAL_ILLNESS_RIDER] = value;
        } else {
          biRequest[keys.CRITICAL_ILLNESS_RIDER] = "";
        }
        break;

      case keys.INCOME_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.INCOME_BENEFIT_RIDER] = value;
        } else {
          biRequest[keys.INCOME_BENEFIT_RIDER] = "";
        }
        break;

      default:
        return;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value);
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value);
  }

  getTotalPrice = (data) => {
    try {
      return Math.round(data.PREM_DISPLAY);
    } catch (e) {
      return 0;
    }
  };

  getRiderTax = (data) => {
    let totalRider = 0;
    data?.parsedResponse?.inputValidationStatus?.map((item) => {
      totalRider += Number(item.Tax);
    });
    return totalRider;
  };

  // fetchPolicyTimeline(element) {
  //   let data = {
  //     youPay: Math.round(element.MODAL_PREM_TAX),
  //     youGet: Math.round(element.SB_G),
  //     lifeCover: Math.round(element.SA_D),
  //     isMilestone: Math.round(element.SB_G) !== 0,
  //     premiumAmt: Math.round(element.MODAL_PREM)
  //   }
  //   return data
  // }

  newPolicyTimeLineData(element, inputString, index, finalYearIndex) {
    const { ANN_PREM_YEARLY, SB_G, SA } = element;

    let data = {
      youPay: Math.round(ANN_PREM_YEARLY),
      youGet: index == finalYearIndex ? Math.round(SB_G) : 0,
      lifeCover: Math.round(SA),
    };

    return data;
  }
}

export default new Utils();
