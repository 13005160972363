import incomeTypeEnum from "constants/incomeTypeEnum";


const FormObserver = (formRef) => {

 formRef.$("target_amount_type").observe({
  key: "value",
  call: ({ form, field }) => {
    let type = field.value.mode;

    form.$("target_amount_dropdown").value = {}

    let incomeList = form.$("target_amount_dropdown").extra.allOptionsList.filter(item => item.mode === incomeTypeEnum.INCOME)

    let lumpsumList = form.$("target_amount_dropdown").extra.allOptionsList.filter(item => item.mode === incomeTypeEnum.LUMPSUM)


    if (type === incomeTypeEnum.INCOME) {
      form.$("target_amount_dropdown").set("extra", {
        ...form.$("target_amount_dropdown").extra,
        optionList: incomeList,
      })
    }

    if (type === incomeTypeEnum.LUMPSUM) {
      form.$("target_amount_dropdown").set("extra", {
        ...form.$("target_amount_dropdown").extra,
        optionList: lumpsumList,
      })
    }
  }
});
}

export default FormObserver