// React Imports
import React,{useState,useEffect} from "react"

// Package Imports
import { Image } from "react-bootstrap"
import ModalBody from "react-bootstrap/ModalBody"
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { toJS } from "mobx";


// project imports
import * as images from "constants/Images"
import { BottomsheetDropdown } from "components"
import CardTextFieldComponent from "modules/family-details-form/components/CardTextFieldComponent"
import fetchPincode from "utils/fetchPinCode"
import { onSet } from "./utils"

import AuthStore from "modules/login/store/AuthStore";
import AddInsuredProtectionForHealthController from "./controller";
import { familyMemberAgeRelationNameFunction } from "utils/familyMemberAgeRelationFunction";


const ProtectionForHealthForm = (props) => {
  const [formController, setFormController] = useState(undefined)

  useEffect(() => {
    let controller = new AddInsuredProtectionForHealthController()
    let journeyData = toJS(AuthStore.journeyData)
    let journeyId = toJS(AuthStore.journeyId)
    controller.setDefaults(journeyData, journeyId)
    setFormController(controller)
  }, [])

  let singleFamilyMember = familyMemberAgeRelationNameFunction()

  const { hideHealthPopUpModal } = props

  if (formController === undefined) return null
  
  return (
    <div className="h-100">
      <ModalBody className="add-insured-pop-up-modal-body h-100">
        <section>
          <article className=" add-insured-pop-up-modal-content">
            <div className="row justify-content-end">
              <span className="mr-2 cancel-btn-style">Cancel</span>
              <Image
                className="close-icon-class"
                src={images.CrossIcon}
                onClick={() => hideHealthPopUpModal()}
              />
            </div>

            <div className="row">
              <p className="font-weight-medium label-color">
              {singleFamilyMember.memberName.length === 0 ? `[${singleFamilyMember.relation}], ` : singleFamilyMember.memberName}
                <span className="member-age">{singleFamilyMember.age} yrs</span>
              </p>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 mt-2 mt-md-0 pl-md-0">
                <p className="add-insured-modal-label font-weight-medium">
                  {formController.$("occupation").label}
                </p>
                <div className="w-100">
                  <BottomsheetDropdown
                    dropdownStyle="font-size-lg-12 font-size-14 font-weight-light bg-white"
                    menuItemStyle="d-flex justify-content-between align-items-center"
                    menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                    selectedLabelKey="label"
                    bottomsheetTitle={
                      formController.$("occupation").extra.bottomSheetTitle
                    }
                    menuList={formController.$("occupation").extra.optionList}
                    value={formController.$("occupation").value}
                    onSelect={formController.$("occupation").onChange}
                    showErrorMsg={formController.$("occupation").error !== null}
                    errorMsg={formController.$("occupation").error}
                    disabled={formController.$("occupation").disabled}
                  />
                </div>
              </div>

              <div className="col-12 col-md-4 mt-2 mt-md-0">
                <p className="add-insured-modal-label font-weight-medium">
                  {formController.$("education").label}
                </p>
                <div className="w-100">
                  <BottomsheetDropdown
                    dropdownStyle="font-size-lg-12 font-size-14 font-weight-light bg-white"
                    menuItemStyle="d-flex justify-content-between align-items-center"
                    menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                    selectedLabelKey="label"
                    bottomsheetTitle={
                      formController.$("education").extra.bottomSheetTitle
                    }
                    menuList={formController.$("education").extra.optionList}
                    value={formController.$("education").value}
                    onSelect={formController.$("education").onChange}
                    showErrorMsg={formController.$("education").error !== null}
                    errorMsg={formController.$("education").error}
                    disabled={formController.$("education").disabled}
                  />
                </div>
              </div>

              <div className="col-12 col-md-4 mt-2 mt-md-0 ">
                <p className="add-insured-modal-label font-weight-medium">
                  {formController.$("location").label}
                </p>
                <div className="w-100">
                  <CardTextFieldComponent
                    className="font-size-12 font-weight-light"
                    value={formController.$("location").value}
                    menuList={formController.$("location").extra.optionList}
                    placeholder={formController.$("location").placeholder}
                    onChange={formController.$("location").onChange}
                    showErrorMsg={formController.$("location").error !== null}
                    errorMsg={formController.$("location").error}
                    disabled={formController.$("location").disabled}
                    onSearch={async (value) =>
                      await fetchPincode(value, formController)
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row mt-5 align-items-center">
              <div className="col-12 col-md-4 mb-2 mb-md-0 font-weight-medium text-primary text-center text-lg-left">
                Select cover amount to
              </div>

              <div className="col-12 col-md-4">
                <div className="w-100">
                  <BottomsheetDropdown
                    dropdownStyle="font-size-lg-12 font-size-14 font-weight-light bg-white"
                    menuItemStyle="d-flex justify-content-between align-items-center"
                    menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                    selectedLabelKey="label"
                    bottomsheetTitle={
                      formController.$("recommendation").extra.bottomSheetTitle
                    }
                    menuList={
                      formController.$("recommendation").extra.optionList
                    }
                    value={formController.$("recommendation").value}
                    onSelect={formController.$("recommendation").onChange}
                    showErrorMsg={
                      formController.$("recommendation").error !== null
                    }
                    errorMsg={formController.$("recommendation").error}
                    disabled={formController.$("recommendation").disabled}
                  />
                </div>
              </div>

              <div className="col-12 mt-3 mt-md-0 col-md-4">
                <div className="text-center">
                  <button
                    className="modal-btn font-size-14 font-weight-medium"
                    onClick={() => onSet(formController,hideHealthPopUpModal)}
                  >
                    Set
                  </button>
                </div>
              </div>
            </div>
          </article>
        </section>
      </ModalBody>
    </div>
  )
}


ProtectionForHealthForm.propTypes = {
  hideHealthPopUpModal:PropTypes.func.isRequired,
};

export default observer(ProtectionForHealthForm)
