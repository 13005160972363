import React from 'react';

const ProductValidationMsg = (props) => {
    return (
        <div className='my-3 font-weight-400 font-size-16'>
             {/* <div className="text-description-text-color font-size-12 font-size-md-18 font-size-lg-20 mt-4">
                Underlying product :{props.product_name}
             </div> */}
            <div className="text-danger mt-2 mb-4">{props.msg}</div>
        </div>
    )
}

export default ProductValidationMsg;