// React Imports
import React from "react";

// Package Imports
import { Card, Form, Row, Col } from "react-bootstrap";
import { observer } from "mobx-react-lite";

// Project Imports
import { BottomsheetDropdown, DatepickerModal } from "components";
import { CalenderIcon, IncomeIcon } from "constants/Images";

// CSS Imports
import "./add-member-card-modal.css";
import { NUMBER_REGEX } from "constants/Constants";

const FamilyMemberCardInModal = (props) => {
  const memberfieldData = props.data.fields;
  let familyMemberName = props.familyMemberName;
  let updateValues = props.updateValues;
  const formController = props.formController;

  const showmonthlyIncome = memberfieldData.monthly_income_value.extra.visible;

  return (
    <Card className='border-0 add-family-member-card mx-md-2 mb-2 p-2'>
      <Card.Body className='p-2'>
        <div className='d-flex align-items-center justify-content-between'>
          <label className='mb-2 mb-md-0 d-inline-flex position-relative cursor-pointer custom-checkbox m-0'>
            <p className='mb-0 title font-weight-light font-size-16 font-size-lg-12 pl-1 details-title-text'>{props.data.label}</p>
          </label>

          <div className='d-flex min-width-150-px'>
            <p className='font-size-12 mt-2 mt-md-1 mb-0 mr-1'>{memberfieldData.nationality.label}</p>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={memberfieldData.nationality.extra.bottomSheetTitle}
              defaultLabel={memberfieldData.nationality.label}
              menuList={memberfieldData.nationality.extra.optionList}
              value={memberfieldData.nationality.value}
              onSelect={(value) => updateValues(formController, familyMemberName, "nationality", value)}
              showErrorMsg={memberfieldData.nationality.error != null}
              disabled={false}
            />
          </div>
        </div>
        <hr className='mt-1 mb-2' style={{ border: "none", height: "1px", background: "#DF6336" }} />
        <Row className='family-member-name-row pt-1'>
          <Col className='title-col'>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={memberfieldData.salutation.extra.bottomSheetTitle}
              defaultLabel=''
              menuList={memberfieldData.salutation.extra.optionList}
              onSelect={(value) => updateValues(formController, familyMemberName, "salutation", value)}
              value={memberfieldData.salutation.value}
              showErrorMsg={memberfieldData.salutation.error !== null}
              errorMsg={memberfieldData.salutation.error}
            />
          </Col>

          {/* firstName */}
          <Col className='name-field-col'>
            <Form.Group className='mb-0'>
              <Form.Control
                required
                placeholder={memberfieldData.first_name.placeholder}
                value={memberfieldData.first_name.value}
                onChange={(event) => updateValues(formController, familyMemberName, "first_name", event.target.value)}
                className={`${memberfieldData.first_name.error ? "validation-border font-size-12 font-weight-light" : "font-size-12 font-weight-light"}`}
              />
              {memberfieldData.first_name.error && <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>{memberfieldData.first_name.error}</p>}
            </Form.Group>
          </Col>

          {/* middleName */}
          <Col className='name-field-col'>
            <Form.Group className='mb-0'>
              <Form.Control
                placeholder={memberfieldData.middle_name.placeholder}
                value={memberfieldData.middle_name.value}
                onChange={(event) => updateValues(formController, familyMemberName, "middle_name", event.target.value)}
                className={`${memberfieldData.middle_name.error ? "validation-border font-size-12 font-weight-light" : "font-size-12 font-weight-light"}`}
              />
              {memberfieldData.middle_name.error && <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>{memberfieldData.middle_name.error}</p>}
            </Form.Group>
          </Col>

          {/* lastName */}
          <Col className='name-field-col'>
            <Form.Group className='mb-0'>
              <Form.Control
                placeholder={memberfieldData.last_name.placeholder}
                value={memberfieldData.last_name.value}
                onChange={(event) => updateValues(formController, familyMemberName, "last_name", event.target.value)}
                className={`${memberfieldData.last_name.error ? "validation-border font-size-12 font-weight-light" : "font-size-12 font-weight-light"}`}
              />
              {memberfieldData.last_name.error && <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>{memberfieldData.last_name.error}</p>}
            </Form.Group>
          </Col>
        </Row>
        <Row className='mt-2'>
          <Col xs={12} md={6} className='mt-2 mt-md-0 fm-modal-monthly-income-col'>
            <div className='d-flex'>
              <img src={CalenderIcon} alt='calender-icon' className='add-family-member-card-modal-top-img' />
              <p className='font-size-12 mb-2'>{memberfieldData.dob.label}</p>
            </div>
            <Form.Group className='mb-0'>
              <DatepickerModal
                dropdownStyle='font-size-12'
                minDate={memberfieldData.dob.extra.minDate}
                maxDate={memberfieldData.dob.extra.maxDate}
                value={memberfieldData.dob.value}
                date={memberfieldData.dob.value}
                onChange={(value) => updateValues(formController, familyMemberName, "dob", value)}
                showErrorMsg={memberfieldData.dob.error !== null}
                textStyle='font-size-12 font-weight-light'
                errorMsg={memberfieldData.dob.error}
              />
            </Form.Group>
          </Col>

          <Col xs={12} md={6} className='mt-3 mt-md-0'>
            <div className='d-flex'>
              <img src={CalenderIcon} alt='calender-icon' className='add-family-member-card-modal-top-img' />
              <p className='font-size-12 mb-2'>{memberfieldData.gender.label}</p>
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={memberfieldData.gender.extra.bottomSheetTitle}
              defaultLabel='Select'
              disabled={memberfieldData.gender.disabled}
              menuList={memberfieldData.gender.extra.optionList}
              onSelect={(value) => updateValues(formController, familyMemberName, "gender", value)}
              value={memberfieldData.gender.value}
              showErrorMsg={memberfieldData.gender.error !== null}
              errorMsg={memberfieldData.gender.error}
            />
          </Col>
        </Row>
        <hr className='my-2' style={{ border: "none", height: "1px", background: "#DBE2EA" }} />

        <Row className='mt-2'>
          <Col xs={12} md={6} className='mt-3 mt-md-0'>
            <div className='d-flex'>
              <img src={CalenderIcon} alt='calender-icon' className='add-family-member-card-modal-top-img' />
              <p className='font-size-12 mb-2'>{memberfieldData.current_life_cover.label}</p>
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={memberfieldData.current_life_cover.extra.bottomSheetTitle}
              menuList={memberfieldData.current_life_cover.extra.optionList}
              value={memberfieldData.current_life_cover.value}
              onSelect={(value) => updateValues(formController, familyMemberName, "current_life_cover", value)}
              showErrorMsg={memberfieldData.current_life_cover.error !== null}
              disabled={memberfieldData.current_life_cover.disabled}
              errorMsg={memberfieldData.current_life_cover.error}
            />
          </Col>

          <Col xs={12} md={6} className={`mt-2 mt-md-0 fm-modal-monthly-income-col ${showmonthlyIncome ? "visible" : "invisible"}`}>
            <div className='d-flex'>
              <img src={IncomeIcon} alt='calender-icon' className='add-family-member-card-modal-top-img' />
              <p className='font-size-12 mb-2'>{memberfieldData.monthly_income_value.label}</p>
            </div>
            {memberfieldData.monthly_income_value.type === "text" ? (
              <Form.Group className='mb-0'>
                <Form.Control
                  // type="number"
                  placeholder={memberfieldData.monthly_income_value.placeholder}
                  value={memberfieldData.monthly_income_value.value}
                  onChange={(event) => {
                    if (NUMBER_REGEX.test(event.target.value)) {
                      return updateValues(formController, familyMemberName, "monthly_income_value", event.target.value);
                    }
                    if (event.target.value.length <= 1) {
                      updateValues(formController, familyMemberName, "monthly_income_value", "");
                    }
                  }}
                  disabled={memberfieldData.monthly_income_value.disabled}
                  className={`${memberfieldData.monthly_income_value.error !== null ? "validation-border font-weight-light font-size-12 p-2" : "font-weight-light font-size-12 p-2"}`}
                />
                {memberfieldData.monthly_income_value.error && <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>{memberfieldData.monthly_income_value.error}</p>}
              </Form.Group>
            ) : (
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey='label'
                bottomsheetTitle={memberfieldData.monthly_income_dropdown_option.extra.bottomSheetTitle}
                defaultLabel='Select'
                menuList={memberfieldData.monthly_income_dropdown_option.extra.optionList}
                value={memberfieldData.monthly_income_dropdown_option.value}
                onSelect={(value) => updateValues(formController, familyMemberName, "monthly_income_dropdown_option", value)}
                showErrorMsg={memberfieldData.monthly_income_dropdown_option.error != null}
                errorMsg={memberfieldData.monthly_income_dropdown_option.error}
                disabled={memberfieldData.monthly_income_dropdown_option.disabled}
              />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default observer(FamilyMemberCardInModal);
