// React Imports
import React, { Component } from "react"

// package import
import PropTypes from "prop-types"
import { observer } from "mobx-react"
import { toJS } from "mobx"

// Project import
import MemberCard from "./MemberCard"
import { Button } from "../../../../components"

import AddMemberCardModal from "../../../family-details-form/components/AddMemberCardModal"
import OptimizationStore from "../../store/OptimizationStore"
import FamilyDetailsFormController from "modules/family-details-form/form/controller"
import AuthStore from "modules/login/store/AuthStore"
import CalculatorModalComponent from "components/ui/calculator-modal-component/CalculatorModalComponent"
import {
  ProtectionForMyFamilyImg,
  PlusIcon,
  SaveForRetirementImg,
} from "constants/Images"
import CalculatorTypeEnum from "modules/choose-needs/components/CalculatorTypeEnum"
import * as Constants from "constants/Constants"
import AddInsuredRetirementPopUpModal from "../add-insured-pop-up-modal/AddInsuredRetirementPopUpModal"
import AddInsuredSaveForChildrenPopUpModal from "../add-insured-pop-up-modal/AddInsuredSaveForChildrenPopUpModal"
import AddInsuredGrowMyMoneyPopUpModal from "../add-insured-pop-up-modal/AddInsuredGrowMyMoneyPopUpModal"
import AddInsuredProtectionPopUpModal from "../add-insured-pop-up-modal/AddInsuredProtectionPopUpModal"
import AddInsuredHealthPopUpModal from "../add-insured-pop-up-modal/AddInsuredHealthPopUpModal"

import AddInsuredModalComponent from '../add-insured-modal/AddInsuredModalComponent'
import NeedTypeEnum from "constants/NeedTypeEnum"

import addFamilyMemberFromUtils from "utils/addFamilyMember"
import { fetchSelectedMembers, submitDataToTheServer, reInitializeFamilyMemberData } from "modules/family-details-form/form/utils"
import { ProtectionForFamilyBiDataApiCall } from "../add-insured-pop-up-modal/family-protection-modal-form/utils"
import { RetirementBiDataApiCall } from "../add-insured-pop-up-modal/retirement-modal-form/utils"
import axios from "utils/axios"
import * as APITypes from "constants/API"
import FamilyMemberModalTypeEnum from "constants/familyMemberModalTypeEnum"
import fetchNeedMaster from "utils/fetchNeedMasterData"

// css import
import "./family-collapse-view.css"
import fetchNeedDetailsBasedonNeedUniqueName from "utils/fetchNeedDetailsBasedonNeedUniqueName"
import FamilyDetailsStore from "modules/family-details-form/store/FamilyDetailsStore"

const NeedCard = observer(
  class NeedCard extends Component {
    constructor(props) {
      super(props)
      this.state = {
        isCollapseOpen: true,
        disabled: false,
        showInsuredMemberModal: false,
        familydetailsController: undefined,
        selectedCollapseKey: [0],
        isAddInsuredPopUpModalOpen: false,
        isRetirementCalculatorOpen: false,
        isProtectionCalculatorOpen: false,
        familyMemberType: "self",
        familyMemberFullName:"",
        eligibleLaList: [],
        proposerList: [],
        productID: undefined,
        showAddLA: false,
        productDisabled: false,
        selectedNeed: null
      }
    }

    onAddInsured = async (e) => {
      OptimizationStore.setFamilyMemberModalType(FamilyMemberModalTypeEnum.ADD_INSURED)
      OptimizationStore.setSelectedNeed(this.props.cardKey)
      OptimizationStore.setAddInsuredModalFunction(true)
    }

    onCollapseChange = (value, keyIndex) => {
      const { disabled } = this.props
      if (!disabled) {
        if (value) {
          this.state.selectedCollapseKey.push(keyIndex)
        } else {
          this.state.selectedCollapseKey.map((item, index) => {
            if (keyIndex == item) {
              this.state.selectedCollapseKey.splice(index, 1)
              this.setState({ isCollapseOpen: value })
            }
          })
        }
      }
    }
    componentDidUpdate(prevProps, prevState) {
      if (!prevProps.disabled && this.props.disabled) {
        this.setState({ isCollapseOpen: false })
      }
    }

    componentDidMount() {
      this.getNeedDetails(this.props.cardKey)
    }

    // onAddInsuredFamily = () => {
    //   this.setState({ showInsuredMemberModal: false, isCollapseOpen: false })
    // }

    hideFamilyModal = () => {
      this.setState({ showAddFamilyMemberModal: false })
    }

    addFamilyMember = async (formController, hideModal) => {

      if (OptimizationStore.familyMemberModalType === FamilyMemberModalTypeEnum.CHANGE_PROPOSER) {
        this.getProposerList();
        this.setState({ showAddFamilyMemberModal: false })
        // return
      }

      await addFamilyMemberFromUtils(
        formController,
        fetchSelectedMembers,
        submitDataToTheServer,
        AuthStore,
        reInitializeFamilyMemberData,
        hideModal
      )

      if (OptimizationStore.familyMemberModalType === FamilyMemberModalTypeEnum.CHANGE_LA) {
        this.getEligibleLaList();
        // this.setState({ showAddFamilyMemberModal: false })
        // return
      }

    }

    hideFamilyMemberSideBarModal = async () => {
      if (OptimizationStore.familyMemberModalType === FamilyMemberModalTypeEnum.CHANGE_LA) {
        this.getEligibleLaList();
      }

      if (OptimizationStore.familyMemberModalType === FamilyMemberModalTypeEnum.CHANGE_PROPOSER) {
        this.getProposerList();
      }

      this.setState({ showAddFamilyMemberModal: false })
    }


    onAddFamilyMember = () => {
      this.setState({ showAddFamilyMemberModal: true })
    }

    // addInsuredOnHide = () => {
    //   OptimizationStore.setAddInsuredModalFunction(false)
    // }

    onRemoveInsured = (e, data) => {
      e.stopPropagation()
      OptimizationStore.deleteFamilyMember(data)
    }

    onSelectNeed = async (title, disabledNeed) => {
      let journeyId = toJS(AuthStore.journeyId)
      let journeyData = toJS(AuthStore.journeyData)
      const selectNeedObj = {
        "journey_id": journeyId,
        "need_id": journeyData.family_product_details_list.find(item => item.need_name === title).need,
        "is_selected": true,
        "is_removed": disabledNeed ? false : true
      }

      const selectNeedResponse = await axios.post(APITypes.SELECT_NEED, selectNeedObj)

      if (selectNeedResponse.status === "success") {
        let isDisabledNeed = false
        let productListData = toJS(OptimizationStore.product_list_data)
        productListData.map((item) => {
          item.data.map((productData) => {
            if (item.need_title == title) {
              isDisabledNeed = !productData.isDisabledNeed
              productData.isDisabledNeed = !productData.isDisabledNeed
            }
          })
        })

        let needList = AuthStore.needMasterList
        let need = needList.find((item) => item.name == title)
        let journeyData = toJS(AuthStore.journeyData)
        journeyData.journey_need.forEach((item) => {
          if (item.need === need.id) {
            item.is_removed = !item.is_removed
          }
        })
        AuthStore.setJourneyData(journeyData)

        OptimizationStore.product_list_data = productListData
        this.setState({
          disabled: isDisabledNeed,
          isCollapseOpen: !this.state.isCollapseOpen,
          selectedCollapseKey: [],
          is_removed: disabledNeed ? false : true,
          showAddLA: false
        })
      }
    }

    openProtectionCalculator = () => {
      this.setState({ isProtectionCalculatorOpen: true })
    }

    closeProtectionCalculator = () => {
      this.setState({ isProtectionCalculatorOpen: false })
    }

    openRetirementCalculator = () => {
      this.setState({ isRetirementCalculatorOpen: true })
    }

    closeRetirementCalculator = () => {
      this.setState({ isRetirementCalculatorOpen: false })
    }

    getFamilyMemberType = () => {
      let familyMemberID = OptimizationStore.selectedFamilyMemberId;
      let familyMemberList = toJS(AuthStore.journeyData).family_details_list
      let familyMember = familyMemberList.find((singleMember) => singleMember.id === familyMemberID)
      let familyMemberType = familyMember.family_member_type.split('_').join(' ') 

       let {salutation:salutation_id,first_name,middle_name,last_name} = familyMember

       let memberSalutation = FamilyDetailsStore.salutationList.find(singleSalutation => singleSalutation.id === salutation_id).label

      let fullName
      let name = ""

      const nameArray = [first_name,middle_name,last_name]

      nameArray.forEach((singleName) =>{
        if(!singleName) return
          name = name + " " + singleName 
      })

      if(name) {
        fullName = `${memberSalutation} ${name}`
      } else{
        fullName = ''
      }
      

       this.setState({ familyMemberType: familyMemberType, familyMemberFullName: fullName })
    }

    getEligibleLaList = async (productID, needUniqueName) => {
      // OptimizationStore.setFamilyMemberModalType(FamilyMemberModalTypeEnum.CHANGE_LA)
      // let journeyId = toJS(AuthStore.journeyId)

      // if (productID === undefined || needUniqueName === undefined) {
      //   let laDetails = OptimizationStore.getChangeLaDetails()
      //   productID = laDetails.productID
      //   needUniqueName = laDetails.needUniqueName
      // } else {
      //   OptimizationStore.setChangeLaDetails(productID, needUniqueName)
      // }

      // let selectedNeed = await fetchNeedDetailsBasedonNeedUniqueName(needUniqueName)
      // const laListResponse = await axios.post(APITypes.GET_ELIGIBLE_LA_LIST, {
      //   journey_id: journeyId,
      //   need_id: selectedNeed.id
      // })

      // this.setState({ eligibleLaList: laListResponse.data })
      // OptimizationStore.setAddInsuredModalFunction(true)
      // this.setState({ productID: productID })
    }

    getProposerList = async (productIdList, needUniqueName, familyMemberId) => {
      // OptimizationStore.setFamilyMemberModalType(FamilyMemberModalTypeEnum.CHANGE_PROPOSER)

      // let journeyId = toJS(AuthStore.journeyId)

      // if (productIdList === undefined || needUniqueName === undefined) {
      //   let laDetails = OptimizationStore.getChangeProposerDetails()
      //   productIdList = laDetails.productID
      //   needUniqueName = laDetails.needUniqueName
      //   familyMemberId = laDetails.familyMemberId
      // } else {
      //   OptimizationStore.setProposerDetails(productIdList, needUniqueName, familyMemberId)
      // }

      // let selectedNeed = await fetchNeedDetailsBasedonNeedUniqueName(needUniqueName)

      // const proposerListResponse = await axios.post(APITypes.GET_ELIGIBLE_PROPOSER_LIST, {
      //   journey_id: journeyId,
      //   need_id: selectedNeed.id,
      //   family_member_id: familyMemberId
      // })

      // this.setState({
      //   proposerList: proposerListResponse.data,
      //   productID: productIdList
      // })
      // OptimizationStore.setAddInsuredModalFunction(true)
    }

    addLA = async (productName, productID) => {
      const removeLaRequestObj = {
        "family_product_id": productID
      }
      const addlaListResponse = await axios.post(APITypes.RESELECT_LA, removeLaRequestObj)
      if (addlaListResponse) {
        let productDisabled = false
        let productListData = toJS(OptimizationStore.product_list_data)
        productListData.map((item) => {
          item.data.map((productData) => {
            if (!Array.isArray(productData.BI)) {
              if (productData.product_name === productName && productData.family_product_id === productID) {
                productDisabled = !productData.productDisabled
                productData.productDisabled = !productData.productDisabled
              }
            } else {
              productData.BI.forEach((value)=> {
                if(value.product_name === productName && value.family_product_id === productID) {
                  productDisabled = !productData.productDisabled
                  productData.productDisabled = !productData.productDisabled
                }
              })
            }
          })
        })

        let journeyData = toJS(AuthStore.journeyData)
        journeyData.family_product_details_list.forEach((item) => {
          if (item.id === productID) { item.la_removed = false }
        })
        AuthStore.setJourneyData(journeyData)

        OptimizationStore.product_list_data = productListData
        this.setState({
          productDisabled: productDisabled,
          isCollapseOpen: false,
          selectedCollapseKey: [],
          showAddLA: false
        })
      }
    }

    removeLA = async (productName, productID) => {
      const removeLaRequestObj = {
        "family_product_id": productID
      }
      const removelaListResponse = await axios.post(APITypes.REMOVE_LA, removeLaRequestObj)
      if (removelaListResponse) {
        let productDisabled = false
        let productListData = toJS(OptimizationStore.product_list_data)
        productListData.map((item) => {
          item.data.map((productData) => {
            if (!Array.isArray(productData.BI)) {
              if (productData.product_name === productName && productData.family_product_id === productID) {
                productDisabled = !productData.productDisabled
                productData.productDisabled = !productData.productDisabled
              }
            } else {
              productData.BI.forEach((value)=> {
                if(value.product_name === productName && value.family_product_id === productID) {
                  productDisabled = !productData.productDisabled
                  productData.productDisabled = !productData.productDisabled
                }
              })
            }
          })
        })

        let journeyData = toJS(AuthStore.journeyData)
        journeyData.family_product_details_list.forEach((item) => {
          if (item.id === productID) { item.la_removed = true }
        })
        AuthStore.setJourneyData(journeyData)

        OptimizationStore.product_list_data = productListData
        this.setState({
          productDisabled: productDisabled,
          isCollapseOpen: false,
          selectedCollapseKey: [],
          showAddLA: true
        })
      }
    }

    // changeLA = async () => {
    //   const changeLaRequestObj = {
    //     "family_member_id": OptimizationStore.selectedFamilyMemberId,
    //     "family_product_id": this.state.productID[0]
    //   }

    //   const changelaListResponse = await axios.post(APITypes.CHANGE_LA, changeLaRequestObj)
    //   console.log(changelaListResponse);
    //   OptimizationStore.setSingleProduct(this.state.productID[0], changelaListResponse)
    // }

    changeProposer = async () => {
      // const changeProposerRequestObj = {
      //   "family_member_id": OptimizationStore.selectedFamilyMemberId,
      //   "family_product_id": this.state.productID[0]
      // }
      // const changeProposerResponse = await axios.post(APITypes.CHANGE_PROPOSER, changeProposerRequestObj)
      // OptimizationStore.setSingleProduct(this.state.productID[0], changeProposerResponse)
    }

    getNeedDetails = async (cardKey) => {
      let selectedNeed = await fetchNeedDetailsBasedonNeedUniqueName(cardKey)
      const { journey_need: journeyNeed } = toJS(AuthStore.journeyData)
      const isRemoved = journeyNeed.find(item => item.need === selectedNeed.id)?.is_removed || false
      this.setState({ selectedNeed: selectedNeed, is_removed: isRemoved, isCollapseOpen: !isRemoved })
    }

    render() {
      const {
        isCollapseOpen,
        showAddFamilyMemberModal,
        selectedCollapseKey,
        onRemoveInsured,
        familydetailsController,
        familyMemberType,
        familyMemberFullName,
        showAddLA
      } = this.state

      const { data, title, cardKey, orderNumber } = this.props

      const showProtectionForFamilyModal = (OptimizationStore.selectedNeed === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY && OptimizationStore.selectedFamilyMemberId !== undefined)

      const showProtectionForHealthModal = (OptimizationStore.selectedNeed === NeedTypeEnum.PROTECTION_FOR_MY_HEALTH && OptimizationStore.selectedFamilyMemberId !== undefined)

      const showGrowMyMoneyModal = (OptimizationStore.selectedNeed === NeedTypeEnum.GROW_MY_MONEY && OptimizationStore.selectedFamilyMemberId !== undefined)

      const showSaveForMyChildren = (OptimizationStore.selectedNeed === NeedTypeEnum.SAVE_FOR_CHILDREN && OptimizationStore.selectedFamilyMemberId !== undefined)

      const showRetirementModal = (OptimizationStore.selectedNeed === NeedTypeEnum.SAVE_FOR_RETIREMENT && OptimizationStore.selectedFamilyMemberId !== undefined)

      return (
        <div className="family-collapase-view mt-5">
          <div style={ orderNumber == 1 ? {marginTop: "4.5rem"}: {}} className="d-flex justify-content-between align-items-center pb-4 pb-md-3 mb-md-1">
            <div className="d-md-flex d-block align-items-center">
              <span className="font-size-16 font-weight-medium text-header-style pr-md-1">
                <span className="text-secondary font-weight-normal pr-1">
                  {orderNumber}.
                </span>
                {title}
              </span>
              {/* {!data[0]?.isDisabledNeed && ( */}
              <div className="select-deselect-btn link-color">
                <Button
                  className="font-size-14 font-size-md-12 p-0"
                  variant="link"
                  buttonText={data[0]?.isDisabledNeed ? "Reselect" : "Deselect"}
                  onClick={() => this.onSelectNeed(title, data[0]?.isDisabledNeed)}
                />
              </div>
              {/* )} */}
            </div>
            <div>
              {!data[0]?.isDisabledNeed && (
                <Button
                  variant="light"
                  onClick={(e) =>{ OptimizationStore.fetchInsuredMembers(this.props.cardKey); OptimizationStore.setFamilyMemberModalType(FamilyMemberModalTypeEnum.ADD_INSURED);}}
                  role="button"
                  className="rounded title-button px-3 py-1 font-size-13 font-weight-normal add-insured-btn-style d-flex align-items-center"
                >
                  <img
                    color="black"
                    className="mr-2 mr-md-3 add-insured-img-style"
                    src={PlusIcon}
                    alt="plus-icon"
                  />
                  Add Assured
                </Button>
              )}
            </div>
          </div>
          {data.map((item, index) => {
            return (
              <MemberCard
                key={index}
                showAddLA={showAddLA}
                index={index}
                isNeedDisabled = {data[0]?.isDisabledNeed}
                disabled={item.isDisabledNeed || item.productDisabled}
                open={isCollapseOpen}
                onChange={this.onCollapseChange}
                selectedCollapseKey={selectedCollapseKey}
                onRemoveInsured={onRemoveInsured}
                data={item}
                cardKey={cardKey}
                onAddMember={this.onAddFamilyMember}
                onAddInsured={this.onAddInsured}
                // getEligibleLaList={this.getEligibleLaList}
                getProposerList={this.getProposerList}
                removeLA={this.removeLA}
                addLA={this.addLA}
              />
            )
          })}

          {/* <AddInsuredModalComponent
            formController={familydetailsController}
            showAddInsuredPopUpModal={this.showAddInsuredPopUpModal}
            showHealthPopUpModal={this.showHealthPopUpModal}
            showRetirementPopUpModal={this.showRetirementPopUpModal}
            showSaveForChilrenPopUpModal={this.showSaveForChilrenPopUpModal}
            showGrowMyMoneyPopUpModal={this.showGrowMyMoneyPopUpModal}
            cardKey={cardKey}
            show={OptimizationStore.isAddInsuredModalOpen}
            onAddMember={this.onAddFamilyMember}
            onAddInsured={this.onAddInsuredFamily}
            onHide={this.addInsuredOnHide}
            eligibleLaList={this.state.eligibleLaList}
            proposerList={this.state.proposerList}
            changeLA={this.changeLA}
            changeProposer={this.changeProposer}
          /> */}
          {/*
          <AddMemberCardModal
            formController={familydetailsController}
            familymemberList={familyData}
            show={showAddFamilyMemberModal}
            onAddMember={() => this.addFamilyMember(familydetailsController, this.hideFamilyModal)}
            onHide={() => this.hideFamilyMemberSideBarModal()}
          /> */}

          <AddInsuredHealthPopUpModal
            showProtectionForHealthModal={showProtectionForHealthModal}
            openProtectionCalculator={this.openProtectionCalculator}
            hideHealthPopUpModal={OptimizationStore.resetSelectedNeedAndMemberId}
          />

          <AddInsuredProtectionPopUpModal
            showProtectionForFamilyModal={showProtectionForFamilyModal}
            openProtectionCalculator={this.openProtectionCalculator}
            hideProtectionModal={OptimizationStore.resetSelectedNeedAndMemberId}
            getFamilyMemberType={this.getFamilyMemberType}
          />

          <AddInsuredRetirementPopUpModal
            showRetirementModal={showRetirementModal}
            openRetirementCalculator={this.openRetirementCalculator}
            hideRetirementPopUpModal={OptimizationStore.resetSelectedNeedAndMemberId}
            relation="Relation"
            memberName="Member Name"
            age="Age"
            getFamilyMemberType={this.getFamilyMemberType}
          />

          <AddInsuredSaveForChildrenPopUpModal
            showSaveForMyChildren={showSaveForMyChildren}
            openProtectionCalculator={this.openProtectionCalculator}
            hideSaveForChilrenPopUpModal={OptimizationStore.resetSelectedNeedAndMemberId}
            relation="Relation"
            memberName="Member Name"
            age="Age"
          />

          <AddInsuredGrowMyMoneyPopUpModal
            showGrowMyMoneyModal={showGrowMyMoneyModal}
            hideGrowMyMoneyPopUpModal={OptimizationStore.resetSelectedNeedAndMemberId}
          />

          <CalculatorModalComponent
            dateOfBirthFromChooseNeeds=""
            monthlyIncomeValue=""
            targetAmountTypeValue={{}}
            changeMonthlyIncomeCallback={() => { }}
            useValueCallback={RetirementBiDataApiCall}
            isCalculatorModalOpen={this.state.isRetirementCalculatorOpen}
            title={
              <span>
                Retirement <br />
                Calculator
              </span>
            }
            thumbNailImg={SaveForRetirementImg}
            calculatorModalType={CalculatorTypeEnum.RETIREMENT}
            modalDescription={Constants.RETIREMENT_CALCULATOR_MODAL_DESCRIPTION}
            memberType={familyMemberFullName? familyMemberFullName : familyMemberType}
            hideCalculatorModal={this.closeRetirementCalculator}
          />

          <CalculatorModalComponent
            dateOfBirthFromChooseNeeds=""
            monthlyIncomeValue=""
            targetAmountTypeValue={{}}
            changeMonthlyIncomeCallback={() => { }}
            useValueCallback={ProtectionForFamilyBiDataApiCall}
            isCalculatorModalOpen={this.state.isProtectionCalculatorOpen}
            title={
              <span>
                Protection <br />
                Calculator
              </span>
            }
            thumbNailImg={ProtectionForMyFamilyImg}
            calculatorModalType={CalculatorTypeEnum.PROTECTION}
            modalDescription={Constants.CALCULATOR_MODAL_DESCRIPTION}
            memberType={familyMemberFullName? familyMemberFullName : familyMemberType}
            hideCalculatorModal={this.closeProtectionCalculator}
          />
        </div>
      )
    }
  }
)

NeedCard.defaultProps = {
  disabled: false,
}

NeedCard.propTypes = {
  cardKey: PropTypes.string.isRequired,
  data: PropTypes.array,
  disabled: PropTypes.bool,
}

export default NeedCard
