export const PR_PT = 'PR_PT';
export const PR_PPT = 'PR_PPT';
export const FREQUENCY = 'INPUT_MODE';
export const PAYOUT_PERIOD = 'PayoutPeriodInYears';
export const OPTION_NAME = 'OptionName';
export const MATURITY_BENEFIT_OPTION = 'MaturityBenefitOption';
export const PAYOUT_MODE = 'PayoutMode';
export const PREMIUM = 'PR_ANNPREM';
export const WOP_RIDER = 'WaiverOfPremiumRider';
export const ADB_RIDER = 'ADBRider';
export const ATPD_RIDER = 'ATPDRider';
export const INCOME_BENEFIT_RIDER = 'IncomeBenefitRider';
export const PWB_RIDER = 'PayorWaiverBenefitRider';