// project imports
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import * as keys from "./keys";
import ProductConstants from "constants/ProductConstants";
class Utils {
  // ! ppt list funtion
  pptListBasedOnPlanOption(planOption) {
    let pptList = [
      { label: '8', id: 8 },
      { label: '10', id: 10 },
      { label: '12', id: 12 },
      { label: '15', id: 15 }
    ]

    if (planOption === 3) {
      pptList.splice(0, 0, { label: '5', id: 5 });
    }

    return pptList
  }
  // !incomeStartYear list function
  incomeStartYearOptionList(planOption, ppt) {
    let incomeStartYear = [
      { label: '2', id: 4 },
      { label: '5', id: 5 }
    ]

    if (planOption !== 3) {
      if (ppt === 8) { incomeStartYear.push({ label: '9', id: 6 }) }
      if (ppt === 10) { incomeStartYear.push({ label: '11', id: 7 }) }
      if (ppt === 12) { incomeStartYear.push({ label: '13', id: 8 }) }
      if (ppt === 15) { incomeStartYear.push({ label: '16', id: 9 }) }
    } else {
      incomeStartYear = []
    }
    return incomeStartYear
  }
  // !PT List
  policyTermList(planOption, ppt, age) {
    let ptList = []

    if (planOption === 3) {
      ptList = [
        { label: '20', id: 20 },
        { label: '25', id: 25 },
        { label: '30', id: 30 }
      ]

      if (ppt === 5 || ppt === 8) {
        ptList.splice(0, 0, { label: '10', id: 10 });
        ptList.splice(1, 0, { label: '15', id: 15 });
      }
      if (ppt === 10 || ppt === 12) {
        ptList.splice(0, 0, { label: '15', id: 15 });
      }

    } else {
      ptList = [
        { label: '30', id: 30 },
        { label: '40', id: 40 },
        { label: 100 - age, id: '100-@LI_ENTRY_AGE' }
      ]
    }
    return ptList
  }
  // !Life Cover Continuation Benefit
  checkLifeCoverDisabled(ppt, pt, age) {
    let isCoverDisabled = false
    if (ppt >= 10 && pt >= 20 && (age + pt <= 75)) {
      isCoverDisabled = false
    } else {
      isCoverDisabled = true
    }
    return isCoverDisabled
  }
  // ! update BI data
  updateBi = async (key, value, productId, form, productData) => {
    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request;
    let updatedValue = value
    switch (key) {
      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value.ppt
        biRequest[keys.INCOME_START_YEAR] = value.incomeStartYear;
        biRequest[keys.PR_PT] = value.pt;
        biRequest[keys.LIFE_COVER_CONTINUATION_BENEFIT] = value.lifeCoverContinuationBenefit
        updatedValue = value.ppt
        break;

      case keys.INCOME_START_YEAR:
        biRequest[keys.INCOME_START_YEAR] = value
        break;

      case keys.PR_PT:
        biRequest[keys.PR_PT] = value.pt;
        biRequest[keys.LIFE_COVER_CONTINUATION_BENEFIT] = value.lifeCoverContinuationBenefit
        updatedValue = value.pt
        break;

      case keys.LIFE_COVER_CONTINUATION_BENEFIT:
        biRequest[keys.LIFE_COVER_CONTINUATION_BENEFIT] = value
        break;

      case keys.FREQUENCY:
        biRequest[keys.FREQUENCY] = value
        break;

      case keys.ACCRUAL_OF_SURVIVAL_BENEFIT:
        biRequest[keys.ACCRUAL_OF_SURVIVAL_BENEFIT] = value.accrualOfSurvivalBenefit
        biRequest[keys.PERSONALISED_OPTION] = value.personalizePlan
        biRequest[keys.WITHDRAWAL] = value.systematicWithdrawal
        biRequest[keys.WITHDRAWAL_START_YEAR] = value.pwStartYear
        biRequest[keys.WITHDRAWAL_STOP_YEAR] = value.pwEndYear
        biRequest[keys.AGE_BASED_WITHDRAWAL] = value.pwAge
        biRequest[keys.START_AGE] = value.pwStartAge
        biRequest[keys.END_AGE] = value.pwEndAge
        updatedValue = value.accrualOfSurvivalBenefit
        break;

      case keys.PERSONALISED_OPTION:
        biRequest[keys.PERSONALISED_OPTION] = value.personalizePlan
        biRequest[keys.WITHDRAWAL] = value.systematicWithdrawal
        biRequest[keys.WITHDRAWAL_START_YEAR] = value.pwStartYear
        biRequest[keys.WITHDRAWAL_STOP_YEAR] = value.pwEndYear
        biRequest[keys.AGE_BASED_WITHDRAWAL] = value.pwAge
        biRequest[keys.START_AGE] = value.pwStartAge
        biRequest[keys.END_AGE] = value.pwEndAge
        updatedValue = value.personalizePlan
        break;

      case keys.WITHDRAWAL:
        biRequest[keys.WITHDRAWAL] = value
        break;

      case keys.WITHDRAWAL_START_YEAR:
        biRequest[keys.WITHDRAWAL_START_YEAR] = value
        break;

      case keys.WITHDRAWAL_STOP_YEAR:
        biRequest[keys.WITHDRAWAL_STOP_YEAR] = value
        break;

      case keys.AGE_BASED_WITHDRAWAL:
        biRequest[keys.AGE_BASED_WITHDRAWAL] = value
        break;

      case keys.START_AGE:
        biRequest[keys.START_AGE] = value
        break;

      case keys.END_AGE:
        biRequest[keys.END_AGE] = value
        break;

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value;
        break;

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = value;
        } else {
          biRequest[keys.ADB_RIDER] = "";
        }
        break;

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = value;
        } else {
          biRequest[keys.ATPD_RIDER] = "";
        }
        break;

      case keys.Critical_ILLNESS_RIDER:
        if (value) {
          biRequest[keys.Critical_ILLNESS_RIDER] = value;
        } else {
          biRequest[keys.Critical_ILLNESS_RIDER] = "";
        }
        break;

      case keys.INCOME_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.INCOME_BENEFIT_RIDER] = value;
        } else {
          biRequest[keys.INCOME_BENEFIT_RIDER] = "";
        }
        break;

      case keys.PWB_RIDER:
        if (value) {
          biRequest[keys.PWB_RIDER] = "1";
          biRequest.PWB = "" + value;
        } else {
          biRequest[keys.PWB_RIDER] = "";
        }
        break;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, updatedValue);
  };

  onChangeWithDrawal(value, key, listIndex, form) {
    let list = form.$(`${key}`).extra.optionList
    let optionList = []
    list.forEach((item, index) => {
      if (index === listIndex) {
        optionList.push(value)
      } else {
        optionList.push(item)
      }
    });
    return optionList
  }

  addMoreFields(form, key) {
    let valueNotEntered = false

    let optionList = form.$(`${key}`).extra.optionList
    optionList.forEach((item) => {
      if (item === "" && !valueNotEntered) {
        valueNotEntered = true
      }
    })

    if (!valueNotEntered) {
      optionList.push("");
      form.$(`${key}`).set('extra', { ...form.$(`${key}`).extra, optionList })
    }
  }

  onResetFields(form, key) {
    let optionList = [""]
    form.$(`${key}`).set('extra', {
      ...form.$(`${key}`).extra, optionList
    })
  }

  getTotalPrice = (biResponse) => {
    try {
      return Number(biResponse?.MODAL_PREM_TAX)
    } catch (e) {
      return 0
    }
  }


  newPolicyTimeLineData(element, inputString, index, productName) {
    let youGet = 0
    let lifeCover = 0
    let incomeStartYear = parseInt(inputString.find(item => item.Key === '@PR_OPTION_2').Value)
    let ppt = parseInt(inputString.find(item => item.Key === '@PR_PPT').Value)

    switch (productName) {
      case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
      case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
        youGet = Math.round(element.TOTAL_SB_PAYOUT_BS_2) + Math.round(element.MATURITY_BENEFIT_BS_2);
        lifeCover = Math.round(element.TOTAL_DB_BS_2)
        break;

      // case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
      // case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
      // case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      // case ProductConstants.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
      // case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
      // case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      //   youGet = Math.round(element.TOTAL_SB_PAYOUT_BS_2) + Math.round(element.MATURITY_BENEFIT_BS_2);
      //   break

      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
      case ProductConstants.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
        youGet = Math.round(element.MATURITY_BENEFIT_BS_2)
        lifeCover = Math.round(element.TOTAL_DB_BS_2)
        break;
    }

    let data = {
      youPay: Math.round(element.ANN_PREM_YEARLY),
      youGet: Math.round(youGet),
      lifeCover: Math.round(lifeCover),
    };
    return data;
  }

  swpEndYearList(withdrawalType, startYear, pt) {
    let endYearList = []
    let endYear = 0
    for (let i = 0; i <= pt; i++) {
      if (i === Number(withdrawalType) + Number(startYear)) {
        endYear = Number(withdrawalType) + Number(startYear)
        endYearList.push(Number(withdrawalType) + Number(startYear))
      }

      if (endYearList.length !== 0) {
        endYear += withdrawalType
        if (endYear <= pt) {
          endYearList.push(endYear)
        }
      }
    }

    return endYearList
  }

}

export default new Utils();
