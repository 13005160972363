import React, { Component } from 'react';
import { toJS } from "mobx";

import { observer } from 'mobx-react';

import ProductValidationMsg from '../optimization-card/ProductValidationMsg';
import SimplyProtect from '../products/simply-protect'
import SimplyProtectFormController from '../products/simply-protect/controller';
import ZindagiPlus from '../products/zindagi-plus'
import WealthPlus from '../products/wealth-plus'
import Gcap from '../products/gcap'
import DhanLabhPlus from '../products/dhan-labh-plus'
import DhanLabhPlusFormController from '../products/dhan-labh-plus/controller';
import ZindagiPlusFormController from '../products/zindagi-plus/controller';
import CriticarePlus from '../products/criticare-plus'
import CriticarePlusFormController from '../products/criticare-plus/controller';
import ActiveIncomePlan from '../products/active-income-plan'
import AIPFormController from '../products/active-income-plan/controller';
import SmartLifestyle from '../products/smart-life-style'
import SmartLifestyleFormController from '../products/smart-life-style/controller';
import WealthUltima from '../products/wealth-ultima'
import WealthUltimaFormController from '../products/wealth-ultima/controller';
import WealthPlusFormController from '../products/wealth-plus/controller'
import GcapFormController from '../products/gcap/controller';
import CashflowProtectionPlusFormController from '../products/cashflow-protection-plus/controller'
import CashflowProtectionPlus from '../products/cashflow-protection-plus'
import WealthGainPlusFormController from '../products/wealth-gain-plus/controller'
import WealthGainPlus from '../products/wealth-gain-plus/index'
import WealthSecurePlusFormController from '../products/wealth-secure-plus/controller'
import WealthSecurePlus from '../products/wealth-secure-plus'
import ImmediateAnnuityPlan from '../products/immediate-annuity-plan'
import ImmediateAnnuityPlanFormController from '../products/immediate-annuity-plan/controller'
import IncomeBuilderFormController from '../products/income-builder/controller'
import IncomeBuilder from '../products/income-builder'
import EasyPension from '../products/easy-pension'
import EasyPensionFormController from '../products/easy-pension/controller'
import SinglePayEndowmentAssurancePlan from '../products/single-pay-endowment-assurance-plan'
import SinglePayEndowmentAssurancePlanFormController from '../products/single-pay-endowment-assurance-plan/controller'
import POSSaralNiveshFormController from '../products/POS-saral-nivesh/controller'
import POSSaralNivesh from '../products/POS-saral-nivesh'
import WealthPremier from '../products/wealth-premier'
import WealthPremierFormController from '../products/wealth-premier/controller'
import Protection from '../products/protection'
import ProtectionFormController from '../products/protection/controller'

import ForeverPension from '../products/forever-pension';
import ForeverPensionFormController from '../products/forever-pension/controller';

import SaralPension from '../products/saral-pension';
import SaralPensionFormController from '../products/saral-pension/controller'

import TotalProtectPlus from '../products/total-protect-plus';
import TotalProtectPlusFormController from '../products/total-protect-plus/controller';

import SaralJeevanBima from '../products/saral-jeevan-bima';
import SaralJeevanBimaFormController from '../products/saral-jeevan-bima/controller';

import PremiumGuaranteedIncome from '../products/premium-guaranteed-income'
import PremierGuaranteedIncomeController from '../products/premium-guaranteed-income/controller'

import GuaranteedIncomeStarGis from '../products/GIS'
import GuaranteedIncomeStarGisController from '../products/GIS/controller'
import GssForm from '../products/gss'
import GssController from '../products/gss/controller'

import FlexiSavingsPlanController from '../products/flexi-savings-plan/controller'
import FlexiSavingsPlanForm from '../products/flexi-savings-plan'

import OptimizationStore from "../../store/OptimizationStore"
import ProductConstants from 'constants/ProductConstants';

const AllProducts = observer(class AllProducts extends Component {
    state = {
        product: ''
    }

    componentDidMount() {
        this.setState({
            product: ''
        })
        this.getParentProductName(this.props.productName)
    }

    componentDidUpdate(prevProps) {
        if (this.props.data.product_name != prevProps.data.product_name) {
            this.getParentProductName(this.props.data.product_name)
        }

        if (this.props.data.family_product_id != prevProps.data.family_product_id) {
            this.getParentProductName(this.props.data.product_name)
        }

        if (this.props.data.updatedValues != prevProps.data.updatedValues) {
            this.getParentProductName(this.props.data.product_name)
        }
    }

    getParentProductName = (productName) => {
        let product = ''
        let updatedValues = this.props.data.updatedValues
        if (updatedValues) {
            Object.keys(updatedValues).length > 0 && Object.keys(updatedValues).map(key => {
                this.props.data.bi_request[key] = updatedValues[key]
            })
        }
        switch (productName) {

            case ProductConstants.ZINDAGI_PLUS:
                const zindagiPlusFormController = new ZindagiPlusFormController()
                zindagiPlusFormController.setDefaults(this.props)
                product = <ZindagiPlus form={zindagiPlusFormController} data={this.props} />
                break

            case ProductConstants.FOREVER_PENSION:
            case ProductConstants.FOREVER_PENSION_IMMEDIATE_ANNUITY:
            case ProductConstants.FOREVER_PENSION_DEFERRED_ANNUITY:
            case ProductConstants.POS_FOREVER_PENSION:
                const foreverFormController = new ForeverPensionFormController()
                foreverFormController.setDefaults(this.props)
                product = <ForeverPension form={foreverFormController} data={this.props} />
                break

            case ProductConstants.SARAL_PENSION:
                const SaralFormController = new SaralPensionFormController()
                SaralFormController.setDefaults(this.props)
                product = <SaralPension form={SaralFormController} data={this.props} />
                break

            case ProductConstants.SARAL_JEEVAN_BIMA:
                ProductConstants.POS_SARAL_JEEVAN_BIMA
                const JeevanBimaFormController = new SaralJeevanBimaFormController()
                JeevanBimaFormController.setDefaults(this.props)
                product = <SaralJeevanBima form={JeevanBimaFormController} data={this.props} />
                break

            case ProductConstants.SIMPLY_PROTECT:
                const simplyProtectFormController = new SimplyProtectFormController()
                simplyProtectFormController.setDefaults(this.props)
                product = <SimplyProtect form={simplyProtectFormController} data={this.props} />
                break

            case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
            case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME:
            case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM:
            case ProductConstants.PREMIER_GUARANTEED_RETIREMENT_INCOME:
            case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
            case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
            case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
                const pgiFormController = new PremierGuaranteedIncomeController()
                pgiFormController.setDefaults(this.props)
                product = <PremiumGuaranteedIncome form={pgiFormController} data={this.props} />
                break

            case ProductConstants.ACTIVE_INCOME_PLAN:
            case ProductConstants.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
            case ProductConstants.ACTIVE_INCOME_PLAN_WITH_FIB:
            case ProductConstants.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
            case ProductConstants.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
                const aipFormController = new AIPFormController()
                aipFormController.setDefaults(this.props)
                product = <ActiveIncomePlan form={aipFormController} data={this.props} />
                break

            case ProductConstants.WEALTH_ULTIMA:
            case ProductConstants.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
            case ProductConstants.LITTLE_CHAMP_WEALTH_ULTIMA:
                const wealthUltimaController = new WealthUltimaFormController()
                wealthUltimaController.setDefaults(this.props)
                product = <WealthUltima form={wealthUltimaController} data={this.props} />
                break

            case ProductConstants.CRITICARE_PLUS:
                const criticarePlusFormController = new CriticarePlusFormController()
                criticarePlusFormController.setDefaults(this.props)
                product = <CriticarePlus form={criticarePlusFormController} data={this.props} />
                break

            case ProductConstants.SMART_LIFESTYLE:
            case ProductConstants.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
                const smartLifestyleFormController = new SmartLifestyleFormController()
                smartLifestyleFormController.setDefaults(this.props);
                product = <SmartLifestyle form={smartLifestyleFormController} data={this.props} />
                break;

            case ProductConstants.GCAP:
                const gcapFormController = new GcapFormController()
                gcapFormController.setDefaults(this.props)
                product = <Gcap form={gcapFormController} data={this.props} />
                break

            case ProductConstants.CASH_FLOW_PROTECTION_PLUS:
                const cashflowProtectionPlusFormController = new CashflowProtectionPlusFormController()
                cashflowProtectionPlusFormController.setDefaults(this.props)
                product = <CashflowProtectionPlus form={cashflowProtectionPlusFormController} data={this.props} />
                break

            case ProductConstants.WEALTH_GAIN_PLUS:
                const wealthGainPlusFormController = new WealthGainPlusFormController()
                wealthGainPlusFormController.setDefaults(this.props)
                product = <WealthGainPlus form={wealthGainPlusFormController} data={this.props} />
                break

            case ProductConstants.WEALTH_SECURE_PLUS:
            case ProductConstants.CHILD_VARIANT_WEALTH_SECURE_PLUS:
            case ProductConstants.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
                const wealthSecurePlusFormController = new WealthSecurePlusFormController()
                wealthSecurePlusFormController.setDefaults(this.props)
                product = <WealthSecurePlus form={wealthSecurePlusFormController} data={this.props} />
                break

            case ProductConstants.DHAN_LABH:
                const dhanLabhPlusFormController = new DhanLabhPlusFormController()
                dhanLabhPlusFormController.setDefaults(this.props)
                product = <DhanLabhPlus form={dhanLabhPlusFormController} data={this.props} />
                break

            case ProductConstants.WEALTH_PLUS:
            case ProductConstants.RISING_STAR_WEALTH_PLUS:
                const wealthPlusFormController = new WealthPlusFormController()
                wealthPlusFormController.setDefaults(this.props)
                product = <WealthPlus form={wealthPlusFormController} data={this.props} />
                break;

            case ProductConstants.INCOME_BUILDER:
            case ProductConstants.SECURED_INCOME_OPTION_INCOME_BUILDER:
                const incomeBuilderFormController = new IncomeBuilderFormController()
                incomeBuilderFormController.setDefaults(this.props)
                product = <IncomeBuilder form={incomeBuilderFormController} data={this.props} />
                break;

            case ProductConstants.IMMEDIATE_ANNUITY_PLAN:
                const immediateAnnuityPlanFormController = new ImmediateAnnuityPlanFormController()
                immediateAnnuityPlanFormController.setDefaults(this.props)
                product = <ImmediateAnnuityPlan form={immediateAnnuityPlanFormController} data={this.props} />
                break

            case ProductConstants.EASY_PENSION:
                const easyPensionFormController = new EasyPensionFormController()
                easyPensionFormController.setDefaults(this.props)
                product = <EasyPension form={easyPensionFormController} data={this.props} />
                break;

            case ProductConstants.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
                const singlePayEndowmentAssurancePlanFormController = new SinglePayEndowmentAssurancePlanFormController()
                singlePayEndowmentAssurancePlanFormController.setDefaults(this.props)
                product = <SinglePayEndowmentAssurancePlan form={singlePayEndowmentAssurancePlanFormController} data={this.props} />
                break;

            case ProductConstants.POS_SARAL_NIVESH:
                const posSaralNiveshFormController = new POSSaralNiveshFormController()
                posSaralNiveshFormController.setDefaults(this.props)
                product = <POSSaralNivesh form={posSaralNiveshFormController} data={this.props} />
                break

            case ProductConstants.WEALTH_PREMIER:
                const wealthPremierFormController = new WealthPremierFormController()
                wealthPremierFormController.setDefaults(this.props)
                product = <WealthPremier form={wealthPremierFormController} data={this.props} />
                break

            case 'Protection  - (Reg/5/10/15Pay)':
                const protectionFormController = new ProtectionFormController()
                protectionFormController.setDefaults(this.props)
                product = <Protection form={protectionFormController} data={this.props} />
                break

            case ProductConstants.TOTAL_PROTECT_PLUS:
                const totalProtectPlusFormController = new TotalProtectPlusFormController()
                totalProtectPlusFormController.setDefaults(this.props)
                product = <TotalProtectPlus form={totalProtectPlusFormController} data={this.props} />
                break


            case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
            case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
            case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
            case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
            case ProductConstants.GUARANTEED_INCOME_STAR:
            case ProductConstants.GUARANTEED_INCOME_STAR_FIB:
            case ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:
            case ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
            case ProductConstants.POS_GUARANTEED_INCOME_STAR:
            case ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
                const GisController = new GuaranteedIncomeStarGisController()
                GisController.setDefaults(this.props)
                product = <GuaranteedIncomeStarGis form={GisController} data={this.props} />
                break

            case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM:
            case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME:
            case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
            case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
            case ProductConstants.POS_GUARANTEED_SAVINGS_STAR:
                const GssFormController = new GssController()
                GssFormController.setDefaults(this.props)
                product = <GssForm form={GssFormController} data={this.props} />
                break

            case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
            case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
            case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
            case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
            case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
            case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
            case ProductConstants.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
            case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
            case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
            case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
            case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
            case ProductConstants.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
                const flexiSavingsPlan = new FlexiSavingsPlanController()
                flexiSavingsPlan.setDefaults(this.props)
                product = <FlexiSavingsPlanForm form={flexiSavingsPlan} data={this.props} />
                break
        }

        this.setState({ product: product });
        delete this.props.data.updatedValues
    }

    render() {
        return (
            <div>
                {
                    this.props.data.isErrorExist ?
                        <ProductValidationMsg
                            msg={this.props.data.errorMessage}
                            product_name={this.props.data.product_name}
                        /> :
                        this.state.product ?
                            <div>{React.cloneElement(this.state.product, { data: this.props })}</div>
                            :
                            <div className='max-cover-amount-msg'>No right match found. Kindly re-evaluate</div>
                }
            </div >
        );
    }
});

export default AllProducts;
