// React Imports
import React, { useEffect, useState } from "react";

// Package Imports
import { Col, Row, Card, Image } from "react-bootstrap";
import { toJS } from "mobx";
import { observer } from "mobx-react";

// Project Imports
import { CalculatorImg } from "constants/Images";

import ChooseNeedsStore from "modules/choose-needs/store/ChooseNeedsStore";
import CalculatorModalComponent from "../calculator-modal-component/CalculatorModalComponent";
import AuthStore from "modules/login/store/AuthStore";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

// CSS Imports
import "./CalculatorCardComponent.css";


const CalculatorCardComponent = observer((props) => {
  const [isCalculatorModalOpen, setIsCalculatorModalOpen] = useState(false);
  const [name, setName] = useState("");

  const showCalculatorModal = () => {
    setIsCalculatorModalOpen(true);
  };

  const hideCalculatorModal = () => {
    setIsCalculatorModalOpen(false);
  };

  const onUpdateValue = (key, value) => {
    ChooseNeedsStore.onUpdateValue(key, value);
  };

  useEffect(() => {
    if (isCalculatorModalOpen) {
      try {
        let familyDetails = toJS(AuthStore.journeyData.family_details_list)
        let selfData = familyDetails.find((item) => item.family_member_type.toLowerCase() === 'self')
        let memberSalutation = ChooseNeedsStore.salutationList.find(singleSalutation => singleSalutation.id === selfData.salutation).label
        let fullName
        let name = ""

        const nameArray = [selfData.first_name, selfData.middle_name, selfData.last_name]
        nameArray.forEach((singleName) => {
          if (!singleName) return
          name = name + " " + singleName
        })

        if (name) {
          fullName = `${memberSalutation} ${name}`
        } else {
          fullName = ''
        }

        setName(fullName ? fullName : `${capitalizeFirstLetter(selfData.family_member_type)}`)
      } catch (e) {
        setName("Self")
      }
    }
  }, [isCalculatorModalOpen])

  const { protectionForMyFamily } = toJS(ChooseNeedsStore.selectedNeeds);

  return (
    <>
      <Card className='calculator-card mt-n4 mt-lg-0 ml-lg-n4 cursor-pointer' onClick={showCalculatorModal}>
        <div className={props.isDisabled ? "card-overlay" : null}></div>
        <Card.Body className='p-3'>
          <Row className='align-items-center'>
            <Col className=' mx-auto '>
              <div>
                <p className='calculator-text-pre-Heading font-size-14 font-size-md-10 font-weight-light mb-0'>Launch</p>
                <p className='calculator-text-Heading font-weight-medium font-size-16 font-size-md-14 mb-0 text-wrap'>{props.title}</p>
              </div>
            </Col>
            <Row className='mt-lg-2 ml-0 mr-auto'>
              <Col xs='6'>
                <Image className='calculator-card-icon-style' src={CalculatorImg} alt='calculator-icon' />
              </Col>
            </Row>
          </Row>
        </Card.Body>
      </Card>
      {isCalculatorModalOpen && <CalculatorModalComponent
        chooseNeedFormController={props.chooseNeedFormController}
        dateOfBirthFromChooseNeeds={props.dateOfBirthFromChooseNeeds}
        setDateOfBirthFromChooseNeeds={props.setDateOfBirthFromChooseNeeds}
        monthlyExpenseValuesFromChooseNeeds={props.monthlyExpenseValuesFromChooseNeeds}
        setMonthlyExpenseValuesFromChooseNeeds={props.setMonthlyExpenseValuesFromChooseNeeds}
        targetAmountTypeValue={props.targetAmountTypeValue}
        monthlyIncomeValue={props.monthlyIncomeValue}
        calculatorModalType={props.calculatorModalType}
        showfieldsForProtectionSection={props.showfieldsForProtectionSection}
        modalHeadingTitle={props.modalHeadingTitle}
        modalDescription={props.modalDescription}
        thumbNailImg={props.thumbNailImg}
        title={props.title}
        isCalculatorModalOpen={isCalculatorModalOpen}
        hideCalculatorModal={hideCalculatorModal}
        memberType={name}
        monthlyIncome={protectionForMyFamily.monthlyIncome}
        sumAssuredAmount={120000}
        sumAssuredMode='monthly'
        useValueCallback={props.useValueCallback}
        onChange={(value) => onUpdateValue("protectionForMyFamily", value)}
      />}
    </>
  );
});

export default CalculatorCardComponent;
