// React Imports
import React, { Component } from 'react'

// Package Imports
import { Button, Modal, Row, Col } from 'react-bootstrap'
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

// Project Imports
import { CustomCheckbox } from 'components'


const FundDistributionModal = observer(class FundDistributionModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedMembers: {},
      fundList: [
        {
          id:'EquityLargeCapFund',
          label: 'Equity Large Cap Fund',
          checkedValue: false,
          key:'largeCap',
          value: 0
        },
        {
          id:'EquityTop250Fund',
          label: 'Equity Top 250 Fund',
          checkedValue: false,
          key:'top250',
          value: 0
        },
        {
          id:'EquityMidCapFund',
          label: 'Equity Mid-Cap Fund',
          checkedValue: false,
          key:'midCap',
          value: 0
        },
        {
          id:'EquityBlueChipFund',
          label: 'Equity Blue Chip Fund',
          checkedValue: false,
          key:'blueChip',
          value: 0
        },
        {
          id:'GILTFund',
          label: 'GILT Fund',
          checkedValue: false,
          key:'glitFund',
          value: 0
        },
        {
          id:'BondFund',
          label: 'Bond Fund',
          checkedValue: false,
          key:'bondFund',
          value: 0
        },
        {
          id:'ManagedFund',
          label: 'Managed Fund',
          checkedValue: false,
          key:'managedFund',
          value: 0
        }
      ],
      remainingFund: 100,
      totalFund: 0,
      isFormEditStart: false
    }
    this.onCheckBoxChange = this.onCheckBoxChange.bind(this)
    this.calculateFund = this.calculateFund.bind(this)
  }
  isNormalInteger = (str) => {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }
  onCheckBoxChange = (value, item, key) => {
    if (key == 'value' && value) {
      console.log(value)
      if (!this.isNormalInteger(value)) {
        return
      }
      this.setState({isFormEditStart: true})
    }
    item[key] = value
    this.setState({ fundList: this.state.fundList })
    this.calculateFund()
  }
  calculateFund = () => {
    var total = this.state.fundList.filter(item=>this.isNormalInteger(item.value)).map(item => parseInt(item.value)).reduce((a, b) => a + b, 0)
    this.setState({ remainingFund: 100 - total, totalFund: total })
  }
  onExited = () => {
    // console.log('ON EXITED >> ')
  }

  render() {
    const { selectedMembers, isFormEditStart, fundList, remainingFund, totalFund } = this.state;
    let { show, onAddMember, onHide } = this.props;
    // const familyMembers = toJS(FamilyDetailsStore.familyMembers)
    // console.log('ADD INSURED MODAL RENDER >> ', familyMembers)
    return (
      <div>
        {/* <Button variant='light' onClick={() => { this.handleFundDistributionModalOpen() }} role='button' className='title-button rounded-pill px-3 py-1'>
          <img color='red' className='mr-2' src={require('../../assets/images/icons/plus.svg')} />
          Add Insured
        </Button> */}
        <Modal
          size="xl"
          className='save-session-modal'
          show={show}
          onHide={onHide}
          onExited={this.onExited}
          // backdrop='static'
          keyboard={false}
        // animation={false}
        >
          <Modal.Header closeButton className='border-0 shadow-sm'>
            <Modal.Title className='font-size-16 text-header-style'>
              <div className="font-weight-normal text-primary">
                Self Managed Strategy - <strong>Fund Distribution</strong>
              </div>
              <small>Use this tool to discuss and diversify the investment. Select Funds and allocate the distribution %.</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className=''>

            <div className="d-flex align-items-center mb-2">
              <div>Remaining %</div>
              <div className="border p-1 mx-2"><span className="text-secondary">{remainingFund}</span><span className="text-muted">%</span></div>
              <div className="ml-2">
                Allocated %
              </div>
              <div className="border p-1 mx-2"><span className="text-success">{totalFund}</span><span className="text-muted">%</span></div>
              {isFormEditStart && totalFund > 100 && <div className="text-danger">[ Total allocation cannot be more than 100% ]</div>}
              {isFormEditStart && totalFund == 100 && <div className="text-success">[ Allocation Complete ]</div>}
              {isFormEditStart && totalFund < 100 && <div className="text-danger">[ Total allocation should be 100% ]</div>}
            </div>
            <Row>
              {fundList.map((item, index) => (
                <Col key={index} md={6} className="mb-3">
                  <Row>
                    <Col md={6} className='d-flex align-items-center'>
                      <CustomCheckbox shape='rounded' title={item.label} titleStyle='text-primary-title-label-color font-weight-normal card-top-title pl-2 py-2' isChecked={item.checkedValue} onChange={(checked) => this.onCheckBoxChange(checked, item, 'checkedValue')} mark='checkmark' />
                    </Col>
                    {item.checkedValue && <Col md={6}>
                      <div className="d-flex align-items-center">
                        <input type="number" className="w-25"
                          value={item.value}
                          onChange={e => {
                            let value = e.target.value
                            this.onCheckBoxChange(value, item, 'value')
                          }}
                          min="0"
                          max="100"
                        />
                        <div className="ml-2">
                          %  out of 100%
                        </div>
                      </div>
                    </Col>}
                  </Row>
                </Col>
              ))}
            </Row>
            <div className="">
              <Button variant='secondary' size="sm" className='font-size-14 m-0 py-1' onClick={() => { this.props.onSetDistribution(fundList) }} disabled={totalFund !== 100}>
                Set Distribution
              </Button>
              <Button variant='outline-secondary' size="sm" className='font-size-14 m-0 py-1 mx-2' onClick={onHide}>
                Cancel
              </Button>
            </div>
          </Modal.Body>
          {/* <Modal.Footer></Modal.Footer> */}
        </Modal>

      </div>
    )
  }
});

FundDistributionModal.defaultsProps = {
  onHide: () => { }
}

FundDistributionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSetDistribution: PropTypes.func.isRequired
}

export default FundDistributionModal
