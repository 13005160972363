import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import OptimizationStore from '../../../store/OptimizationStore';
import isTotalFundCountValid from 'utils/isTotalFundCountValid';
import ProductConstants from 'constants/ProductConstants';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    getTotalAmount = (premium, frequency) => {
        // let totamAmount = 0;
        // try {
        //     switch (frequency) {
        //         case '1':
        //             totamAmount = premium * 1;
        //             break;

        //         case '2':
        //             totamAmount = premium * 2;
        //             break;
        //         case '3':
        //             totamAmount = premium * 4;
        //             break;

        //         case '4':
        //             totamAmount = premium * 12;
        //             break;
        //     }
        //     return totamAmount;
        // } catch (error) {
        //     return 0;
        // }
    }

    // Minimum Premium Validation for less than 10 PPT
    premiumValidationForLessThan10PPT = (form, data) => {
        // let pt = Number(form.$('policy-term').value.id)
        // let ppt = Number(form.$('premium-term').value.id)
        // let premium = Number(form.$('premium').value);
        // let frequency = form.$('payment-frequency').value.id;
        // let totamAmount = this.getTotalAmount(premium, frequency);

        // if (ppt >= 10) {
        //     return;
        // }

        // if (frequency == '1') {
        //     // Annual Mode
        //     if (pt >= 10 && pt <= 14 && totamAmount < 48000) {
        //         this.errorMessage.push(errorMessageKeys.lessThan10PPT.annual['10-14PT'])
        //     }

        //     if (pt >= 15 && pt <= 20 && totamAmount < 36000) {
        //         this.errorMessage.push(errorMessageKeys.lessThan10PPT.annual['15-20PT'])
        //     }

        // } else {
        //     if (pt >= 10 && pt <= 14 && totamAmount < 60000) {
        //         this.errorMessage.push(errorMessageKeys.lessThan10PPT.other['10-14PT'])
        //     }

        //     if (pt >= 15 && pt <= 20 && totamAmount < 36000) {
        //         this.errorMessage.push(errorMessageKeys.lessThan10PPT.other['15-20PT'])
        //     }

        // }
    }

    // Minimum Premium Validation for greater than 10 PPT
    premiumValidationForGreaterThan10PPT = (form, data) => {
        // let pt = Number(form.$('policy-term').value.id)
        // let ppt = Number(form.$('premium-term').value.id)
        // let premium = Number(form.$('premium').value);
        // let frequency = form.$('payment-frequency').value.id;
        // let totamAmount = this.getTotalAmount(premium, frequency);

        // if (ppt < 10) {
        //     return;
        // }


        // if (pt >= 11 && pt <= 14 && totamAmount < 48000) {
        //     this.errorMessage.push(errorMessageKeys.greaterThan10PPT['11-14PT'])
        // }

        // if (pt >= 15 && pt <= 20 && totamAmount < 36000) {
        //     this.errorMessage.push(errorMessageKeys.greaterThan10PPT['15-20PT'])
        // }

    }

    risingStarBenefitEligible = (form, data) => {
        // let userAge = data.data.age;
        // let isRisingStarBenefit = form.$('rising-star-benefit').value.id;
        // let isBenefitSelected = isRisingStarBenefit == 3;

        // if (isBenefitSelected) {

        //     if (userAge < 1 || userAge > 17) {
        //         this.errorMessage.push(errorMessageKeys.risingStarAgeValidation)
        //     }
        // }
    }

    risingStarWealthPlusValidation = (form, data) => {
        // let product_name = data.data.product_name;
        // let isRisingStarBenefit = form.$('rising-star-benefit').value.id;
        // let isBenefitSelected = isRisingStarBenefit == 3;

        // if (product_name == ProductConstants.RISING_STAR_WEALTH_PLUS) {
        //     if (!isBenefitSelected) {
        //         this.errorMessage.push(errorMessageKeys.risingStarEligible)
        //     }
        // }
    }

    // pt, ppt validation
    validatePT = (form, data) => {
        // let pt = Number(form.$('policy-term').value.id)
        // let ppt = Number(form.$('premium-term').value.id)
        // if (ppt > pt) {
        //     this.errorMessage.push(errorMessageKeys.validPPT)
        // }
    }

    // age validation
    validatePT = (form, data) => {
        // let age = Number(data.data.bi_request.LI_ENTRY_AGE)
        // let pt = Number(form.$('policy-term').value.id)
        // let maturityAge = age + pt
        // if (age < 1) {
        //     this.errorMessage.push(errorMessageKeys.entryAgeValidation.minimumEntryAge)
        // }
        // if (age > 55) {
        //     this.errorMessage.push(errorMessageKeys.entryAgeValidation.maximumEntryAge)
        // }
        // if (maturityAge < 18) {
        //     this.errorMessage.push(errorMessageKeys.maturityAgeValidation.minimumEntryAge)
        // }
        // if (maturityAge > 70) {
        //     this.errorMessage.push(errorMessageKeys.maturityAgeValidation.maximumEntryAge)
        // }
    }

    // 100% Fund Allocation validation
    fundAllocationValidations = (formRef) => {
        // let fundStratergyElem = formRef.$("fund-strategy");
        // let fundsList = fundStratergyElem.extra.fundList;
        // let currentFundStratergy = fundStratergyElem.value.id;
        // let isCountValid = false;

        // // Return for Life Stage and duration based Strategy
        // if (currentFundStratergy != 1) return

        // isCountValid = isTotalFundCountValid(fundsList);

        // if (!isCountValid) {
        //     this.errorMessage.push("Total Fund allocation must be 100%")
        // }
    }


}
export default new Validator();