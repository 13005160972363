// Package Imports
import { toJS } from "mobx"


const submitEducationOccupationLocationData = (formController,OptimizationStore,AuthStore) => {

 /* getting pin code */
 let pinCodeData = toJS(formController.values().location)

 /* logic for getting family member Type  */
 const familyDetailsArray = toJS(AuthStore.journeyData.family_details_list)
 let selectedfamilyMember = familyDetailsArray.find(
   (item) => item.id === OptimizationStore.selectedFamilyMemberId
 )

 /* data to be submitted for post request */
 let dataToBeSubmitted = {
   journey_id: toJS(AuthStore.journeyId),
   family_list: [
     {
       id: OptimizationStore.selectedFamilyMemberId,
       occupation: formController.get().occupation.value.id,
       education: formController.get().education.value.id,
       pin_code: pinCodeData[0].id,
       office_name: pinCodeData[0].label,
       state: pinCodeData[0].location,
       is_selected: true,
       family_member_type: selectedfamilyMember.family_member_type,
     },
   ],
   expense_list: [],
 }

 return dataToBeSubmitted

}


export default submitEducationOccupationLocationData