import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from "./validator";

import * as Constants from "constants/Constants"

class Utils {
  getPolicyTerm = () => {
    let pt = [];
    for (let i = 10; i <= 20; i++) {
      pt.push({ id: i, label: "" + i });
    }
    return pt;
  };

  getPremiumTermList = (pt) => {
  
  const ptValue = Number(pt)

  let pptlist = [5, 7, 10, 12];

  if(ptValue < 15){
     pptlist = [5, 7];
  }

    let ppt = [];
    pptlist.map((item) => {
      ppt.push({ id: item, label: "" + item });
    });
    return ppt;
  };

  getCoverAmountList = () => {
    let list = [];
    for (let index = 0; index <= 100; index = index + 5) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + " L",
        });
      } else {
        list.push({
          id: index,
          label: 1 + " Cr",
        });
      }
    }
    return list;
  };

  getCoverAmountListForCLRnHCB = () => {
    let list = [];
    for (let index = 0; index <= 6; index = index + 1.5) {
      list.push({
        id: index == 0 ? 1 : index,
        label: (index == 0 ? 1 : index) + " L",
        key: index * 100000,
      });
    }
    return list;
  };

  getTotalPrice = (data) => {
    try {
      return Number(data.PREM_DISPLAY);
    } catch (e) {
      return 0;
    }
  };

  updateBi(key, value, productId, formRef, productData) {
let selectedRiskType = productData?.data?.selectedRisk;
let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request

    switch (key) {
      case keys.PR_PT:
        biRequest[keys.PR_PT] = value;
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.FREQUENCY:
        // let premiumAmount = 0;
        // let amount = value.premium;
        // switch (Number(value.frequency)) {
        //   case 1:
        //     premiumAmount = '' + amount * 1
        //     break;
        //   case 2:
        //     premiumAmount = '' + amount * 2
        //     break;
        //   case 3:
        //     premiumAmount = '' + amount * 4
        //     break;
        //   case 4:
        //     premiumAmount = '' + amount * 12
        //     break;
        // }

        // biRequest[keys.PREMIUM] = premiumAmount
        biRequest[keys.FREQUENCY] = value.frequency
        break;

      case keys.OPTION:
        biRequest[keys.OPTION] = value;
        break;

      // case keys.PREMIUM:
      //   biRequest[keys.PREMIUM] = value;
        break;

      case keys.ADB_RIDER:
        biRequest[keys.ADB_RIDER] = value;
        break;

      case keys.ATPD_RIDER:
        biRequest[keys.ATPD_RIDER] = value;
        break;

      case keys.CRITICAL_ILLNESS_RIDER:
        biRequest[keys.CRITICAL_ILLNESS_RIDER] = value;
        break;

      case keys.INCOME_BENEFIT_RIDER:
        biRequest[keys.INCOME_BENEFIT_RIDER] = value;
        break;

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value;
        break;

      case keys.PWB:
        if (value) {
          biRequest[keys.PWB_RIDER] = value;
        } else {
          biRequest[keys.PWB_RIDER] = "";
        }
        biRequest[keys.PWB] = value;
        break;

      default:
        return;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);
    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value);
  }

  fetchPolicyTimeline(element, frequency) {
    let amount = Math.round(element.MODAL_PREM_TAX);
    let premiumAmount = ''
    switch (frequency.Value) {
      case '1':
        premiumAmount = '' + amount * 1
        break;
      case '2':
        premiumAmount = '' + amount * 2
        break;
      case '3':
        premiumAmount = '' + amount * 4
        break;
      case '4':
        premiumAmount = '' + amount * 12
        break;
    }
    let data = {
      youPay: Math.round(premiumAmount),
      youGet: Math.round(element.SB_NG_DIS_BS_2),
      lifeCover: Math.round(element.DB_NG_DIS_BS_2),
      isMilestone: Math.round(element.SB_NG_DIS_BS_2) !== 0,
      premiumAmt: Math.round(element.ANN_PREM_YEARLY),
    };
    return data;
  }

  newPolicyTimeLineData(element,inputString,index, finalYearIndex) {

    const {MODAL_PREM,SB_NG_DIS_BS_2,DB_G_DISPLAY, DB_NG_DIS_BS_2, ANN_PREM_YEARLY} = element

    let premiumAmount = 0;

     let inputMode = parseInt(inputString.find(item => item.Key === '@INPUT_MODE').Value)

    //  if (inputMode === 1) {
    //    premiumAmount = Math.round(MODAL_PREM) * Constants.ANNUAL_MODAL_FACTOR;
    //  } else if (inputMode === 2) {
    //    premiumAmount = Math.round(MODAL_PREM) * Constants.SEMI_ANNUAL_MODAL_FACTOR;
    //  } else if (inputMode === 3) {
    //    premiumAmount = Math.round(MODAL_PREM) * Constants.QUATERLY_MODAL_FACTOR;
    //  } else if (inputMode === 4) {
    //    premiumAmount = Math.round(MODAL_PREM) * Constants.ANNUAL_MODAL_FACTOR;
    //  }

    let data = {
      youPay:Math.round(ANN_PREM_YEARLY),
      youGet: index == finalYearIndex ? Math.round(SB_NG_DIS_BS_2) : 0,
      lifeCover:Math.round(Number(DB_NG_DIS_BS_2))
    }

    return data
  }


  getRiderTax = (data) => {
    let totalRider = 0;
    data?.parsedResponse?.inputValidationStatus?.map((item) => {
      totalRider += Number(item.Tax);
    });
    return totalRider;
  };
}

export default new Utils();
