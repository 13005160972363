import React, { Component } from 'react'
import './circular-progresbar.css'
import { v4 as uuidv4 } from 'uuid';

class CircularProgressbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: uuidv4(),
      strokewidth: '',
      containerWidth: ''
    }
  }


    componentDidMount(props) {
    const { id } = this.state;
    const container = document.getElementById(id).children[0];
    let strokewidth = 26
    let containerWidth = 140
    if (container) {
      containerWidth = container.clientWidth;

      if (containerWidth === 140) {
        strokewidth = 26
      }
      else if (containerWidth === 90) {
        strokewidth = 18
      }else if (containerWidth === 118) {
        strokewidth = 15
      }else if (containerWidth === 273) {
        strokewidth = 50
      } else {
        strokewidth = 40
      }
    }
    this.setState({ strokewidth: strokewidth, containerWidth: containerWidth })
  }

  render() {
    const { value, valuelabel, isSingleImg,laName,size } = this.props;

    let customDoughNutWidth
    let smallStrokeWidth


    if(size == 'small'){
      customDoughNutWidth = 80
      smallStrokeWidth = 12
    }

    const doughNutTextClass = customDoughNutWidth ? 'donutchart-text-val-small' : 'donutchart-text-val';
    const doughNutPercentClass = customDoughNutWidth ? 'donutchart-text-percent-small' : 'donutchart-text-percent';
    const doughNutLabelClass = customDoughNutWidth ? 'donutchart-text-label-small' : 'donutchart-text-label';
    const yPosition = customDoughNutWidth ? '55' : '80';

  

    const halfsize = ( customDoughNutWidth ? customDoughNutWidth * 0.5 : this.state.containerWidth * 0.5);
    const radius = ( smallStrokeWidth ? halfsize - (smallStrokeWidth * 0.5)  : halfsize - (this.state.strokewidth * 0.5));
    const circumference = 2 * Math.PI * radius;
    const strokeval = ((this.props.value * circumference) / 100);
    const dashval = (strokeval + ' ' + circumference);

    const trackstyle = { strokeWidth: smallStrokeWidth ? smallStrokeWidth :  this.state.strokewidth };
    const indicatorstyle = { strokeWidth: smallStrokeWidth ? smallStrokeWidth :  this.state.strokewidth, strokeDasharray: dashval }
    const rotateval = 'rotate(-90 ' + halfsize + ',' + halfsize + ')';
    return (
      <div id={this.state.id} className='donutchart-container d-flex flex-column justify-content-center align-items-center'>
        <svg className={` ${isSingleImg ? 'single-img-style' : 'multi-img-style'} donutchart ${customDoughNutWidth && 'single-img-style-small'}`}>
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className='donutchart-track' />
          <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className='donutchart-indicator' />
          <text className='donutchart-text' x={halfsize} y={halfsize + 4} style={{ textAnchor: 'middle' }} >
            <tspan className= {doughNutTextClass}>{value}</tspan>
            <tspan className={doughNutPercentClass}>%</tspan>
            <tspan className={doughNutLabelClass}
             x={halfsize} 
             y={yPosition}>{valuelabel}
            </tspan>
          </text> 
        </svg>
        <p className={`font-weight-bold  ${!customDoughNutWidth? 'mt-2 font-size-16' : 'font-size-12' } doughnut-label`}>{laName ? `[${laName}]` : this.props.singleLaCard ? '' : ''} </p>
      </div>
    )
  }
}

CircularProgressbar.defaultProps = {
  value: 0,
  valuelabel: 'Covered'
}

export default CircularProgressbar
