// react imports
import React from "react";

// package imports
import { Row, Col, Form } from "react-bootstrap";
import { observer } from "mobx-react";

// project imports
import { BottomsheetDropdown, ChangeProductSection, AdditionalRiderBenifitsCard } from "components";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import UiButton from "components/ui/button/Button";
import utils from "./utils";
import DeathBenefitAmount from "utils/optimization/deathBenefitAmount";

// CSS imports
import "../../optimization-card/family-collapse-view.css";

const FlexiSavingsPlan = ({ form, data }) => {
  let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)

  return (
    <div className=''>
      <Row className="align-items-center mt-md-4">
        <Col xs={12} md={4} className='mt-3 mt-md-0'>
          <ChangeProductSection
            productName={productData.product_name}
            cardName={coverLabelBaseOnNeed}
            changeProductList={data?.data?.changeProductList || []}
            productData={data} />
        </Col>

        <Col xs={12} className='mt-2 mt-md-0'>
          <DeathCoverGraphSlider
            productData={data?.data}
            saLabel='Income'
            productTitle={productData.product_name}
            openChangeReasonModal={() => { }}
            onIncomeChange={form.$("income").onChange}
            onPremiumChange={form.$("premium").onChange}
            dataChange={() => { }}
            hideOverLay={() => { }}
            productID={productData.product_id}
            cardKey={data.cardKey}
            income_value={form.$("income").value}
            premium_value={form.$("premium").value}
            showOverlay={false}
            onClick={() => form.$("premium").extra.calculatePremium(form, form.$("premium").value, data)}
            data={""}
            isIncomeFieldDisable={false}
            isPremiumDisable={false}
            premiumFreq={form.$("payment-frequency").value.label}
            onIncomeBlur={() => { form.$("income").extra.calculateIncome(form, data); }}
            formRef={form}
          />
        </Col>
      </Row>

      <Row className='mt-3 ml-0'>
        <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{"Sum Assured on Death:"}</p>
        <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(productData)}</p>
      </Row>

      <Row className='mt-3'>
        <Col xs={12} md={6} className='mb-3'>
        <p className='mb-2 font-size-12 font-weight-light '>{form.$("policy-term").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("policy-term").label}
            menuList={form.$("policy-term").extra.optionList}
            value={form.$("policy-term").value}
            onSelect={form.$("policy-term").onChange}
          />
        </Col>

        <Col xs={12} md={6} className='mb-3'>
          <p className='mb-2 font-size-12 font-weight-light '>{form.$("premium-term").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("premium-term").label}
            menuList={form.$("premium-term").extra.optionList}
            value={form.$("premium-term").value}
            onSelect={form.$("premium-term").onChange}
          />
        </Col>

        {!form.$("income-start-year").extra.hide &&
          <Col xs={12} md={4} className='mb-3'>
            <p className='mb-2 font-size-12 font-weight-light '>{form.$("income-start-year").label}</p>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={form.$("income-start-year").label}
              menuList={form.$("income-start-year").extra.optionList}
              value={form.$("income-start-year").value}
              onSelect={form.$("income-start-year").onChange}
            />
          </Col>}

        <Col xs={12} md={4} className='mb-3'>
<p className='mb-2 font-size-12 font-weight-light '>{form.$("plan-option").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("plan-option").label}
            menuList={form.$("plan-option").extra.optionList}
            value={form.$("plan-option").value}
            onSelect={form.$("plan-option").onChange}
            disabled={form.$("plan-option").disabled}
          />
        </Col>

        <Col xs={12} md={4} className='mb-3'>
          <p className='mb-2 font-size-12 font-weight-light '>{form.$("life-cover").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("life-cover").label}
            menuList={form.$("life-cover").extra.optionList}
            value={form.$("life-cover").value}
            onSelect={form.$("life-cover").onChange}
            disabled={form.$("life-cover").disabled}
          />
        </Col>

        <Col xs={12} md={4} className='mb-3'>
          <p className='mb-2 font-size-12 font-weight-light '>{form.$("payment-frequency").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("payment-frequency").label}
            menuList={form.$("payment-frequency").extra.optionList}
            value={form.$("payment-frequency").value}
            onSelect={form.$("payment-frequency").onChange}
          />
        </Col>

        <Col xs={12} md={4} className='mb-3'>
          <p className='mb-2 font-size-12 font-weight-light '>{form.$("accrual-of-survival-benefit").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("accrual-of-survival-benefit").label}
            menuList={form.$("accrual-of-survival-benefit").extra.optionList}
            value={form.$("accrual-of-survival-benefit").value}
            onSelect={form.$("accrual-of-survival-benefit").onChange}
            disabled={form.$("accrual-of-survival-benefit").disabled}
          />
        </Col>

        {!form.$("personalize-plan").extra.hide &&
          <Col xs={12} md={4} className='mb-3'>
            <p className='mb-2 font-size-12 font-weight-light '>{form.$("personalize-plan").label}</p>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={form.$("personalize-plan").label}
              menuList={form.$("personalize-plan").extra.optionList}
              value={form.$("personalize-plan").value}
              onSelect={form.$("personalize-plan").onChange}
              disabled={form.$("personalize-plan").disabled}
            />
          </Col>
        }
      </Row>
      {/* !Systematic Withdrawal */}
      <Row>
        {!form.$("withdrawal").extra.hide &&
          <Col xs={12} md={4} className='mb-3'>
            <p className='mb-2 font-size-12 font-weight-light '>{form.$("withdrawal").label}</p>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={form.$("withdrawal").label}
              menuList={form.$("withdrawal").extra.optionList}
              value={form.$("withdrawal").value}
              onSelect={form.$("withdrawal").onChange}
            />
          </Col>}
        {!form.$("withdrawal-start-year").extra.hide &&
          <Col xs={12} md={4} className='mb-3'>
            <p className='mb-2 font-size-12 font-weight-light '>{form.$("withdrawal-start-year").label}</p>
            <Form.Control
              placeholder={form.$("withdrawal-start-year").label}
              value={form.$("withdrawal-start-year").value}
              onChange={form.$("withdrawal-start-year").onChange}
              onBlur={(event) => { form.$("withdrawal-start-year").extra.onChangeStartYear(event.target.value, form, data) }}
              disabled={form.$("withdrawal-start-year").disabled}
              className="font-size-12 font-weight-light text-black mt-2 product-form-field"
              type="number"
            />
          </Col>}
        {!form.$("withdrawal-end-year").extra.hide &&
          <Col xs={12} md={4} className='mb-3'>
            <p className='mb-2 font-size-12 font-weight-light '>{form.$("withdrawal-end-year").label}</p>
            <Form.Control
              placeholder={form.$("withdrawal-end-year").label}
              value={form.$("withdrawal-end-year").value}
              onChange={form.$("withdrawal-end-year").onChange}
              onBlur={(event) => { form.$("withdrawal-end-year").extra.onChangeEndYear(event.target.value, form, data) }}
              disabled={form.$("withdrawal-end-year").disabled}
              className="font-size-12 font-weight-light text-black mt-2 product-form-field"
              type="number"
            />
          </Col>}
      </Row>
      {/* !Age Based withdrawal */}
      {!form.$("age-based-withdrawal").extra.hide &&
        <>
          <Row>
            <Col xs={12} md={4} className='mb-3'>
              <p className='mb-2 font-size-12 font-weight-light '>{form.$("age-based-withdrawal").label}</p>
              {form.$("age-based-withdrawal").extra.optionList.map((item, listIndex) =>
                <Form.Control
                  placeholder={form.$("age-based-withdrawal").label}
                  value={item}
                  onChange={(event) => { form.$("age-based-withdrawal").extra.onChangeOfAgeBasedWithdrawal(event.target.value, listIndex, form, data) }}
                  disabled={form.$("age-based-withdrawal").disabled}
                  className="font-size-12 font-weight-light text-black mt-2 product-form-field"
                  type="number"
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col md={3} className='mb-3'>
              <UiButton
                onClick={() => { utils.addMoreFields(form, 'age-based-withdrawal') }}
                className='font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0 next-button'
                variant='secondary'
                buttonText='Add More'
              />
            </Col>
            <Col md={3} className='mb-3'>
              <UiButton
                onClick={() => { utils.onResetFields(form, 'age-based-withdrawal') }}
                className='font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0 next-button'
                variant='secondary'
                buttonText='Reset'
              />
            </Col>
          </Row>
        </>
      }

      {/* !Age Plus Income Based withdrawal */}
      {(!form.$("start-age").extra.hide && !form.$("end-age").extra.hide) &&
        <>
          <Row>
            <Col xs={12} md={4} className='mb-3'>
              <p className='mb-2 font-size-12 font-weight-light '>{form.$("start-age").label}</p>
              {form.$("start-age").extra.optionList.map((item, listIndex) =>
                <Form.Control
                  placeholder={form.$("start-age").label}
                  value={item}
                  onChange={(event) => { form.$("start-age").extra.onChangeStartAge(event.target.value, listIndex, form, data) }}
                  disabled={form.$("start-age").disabled}
                  className="font-size-12 font-weight-light text-black mt-2 product-form-field"
                  type="number"
                />
              )}
            </Col>
            <Col xs={12} md={4} className='mb-3'>
              <p className='mb-2 font-size-12 font-weight-light '>{form.$("end-age").label}</p>
              {form.$("end-age").extra.optionList.map((item, listIndex) =>
                <Form.Control
                  placeholder={form.$("end-age").label}
                  value={item}
                  onChange={(event) => { form.$("end-age").extra.onChangeEndAge(event.target.value, listIndex, form, data) }}
                  disabled={form.$("end-age").disabled}
                  className="font-size-12 font-weight-light text-black mt-2 product-form-field"
                  type="number"
                />
              )}
            </Col>
          </Row>

          <Row>
            <Col md={2} className='mb-3'>
              <UiButton
                onClick={() => {
                  utils.addMoreFields(form, 'start-age');
                  utils.addMoreFields(form, 'end-age');
                }}
                className='font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0 next-button'
                variant='secondary'
                buttonText='Add More'
              />
            </Col>
            <Col md={2} className='mb-3'>
              <UiButton
                onClick={() => { 
                  utils.onResetFields(form, 'start-age') 
                  utils.onResetFields(form, 'end-age') 

                }}
                className='font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0 next-button'
                variant='secondary'
                buttonText='Reset'
              />
            </Col>
          </Row>
        </>
      }

      <Row className="mt-4">
        <Col md={6} className="col-12 my-4 ">
          <AdditionalRiderBenifitsCard
            data={data}
            form={form}
            showCriticalIllness={!form.$("CriticalIllnessRider").extra.hide}
            showIncomeBenefit={!form.$("IncomeBenefitRider").extra.hide}
            showHospitalityCashBenefit={false}
            showWOP={!form.$("WaiverOfPremiumRider").extra.hide}
            showPWB={!form.$("PayorWaiverBenefitRider").extra.hide}
            showADB={!form.$("ADB").extra.hide}
            showATPD={!form.$("ATPDRider").extra.hide}
          />
        </Col>
      </Row>
    </div>
  );
};

export default observer(FlexiSavingsPlan);
