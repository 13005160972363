// React Imports
import React, { Component } from "react";

// Package Imports
import { observer } from "mobx-react";
import { Container, Image } from "react-bootstrap";
import moment from "moment";

// Project Imports
import AuthStore from "../../../modules/login/store/AuthStore";
import { NextButton, Button as UiButton } from "../../../components";
import ChooseNeedsStore from "../../../modules/choose-needs/store/ChooseNeedsStore";
import OptimizationStore from "../../../modules/optimization/store/OptimizationStore";
import { ShareIconImg } from "constants/Images";
import FormContext from "context/FormContext";
import * as APITypes from "constants/API"
import axios from "utils/axios";
import chooseNeedUtils from "modules/choose-needs/components/form/choose-needs/utils";
import { toJS } from "mobx";

import SolutionStore from '../../../modules/solution/store/SolutionStore'
import QuotationPreviewUtils from "modules/quotation-preview/utils";

// CSS Imports
import "./breadcrumb.css";
import StorageKeys from "constants/StorageKeys";
import FamilyDetailsStore from "modules/family-details-form/store/FamilyDetailsStore";
import checkIsValueEmpty from "utils/checkIsValueEmpty";
import { Link } from "react-router-dom";
import QuotationPreviewStore from "modules/quotation-preview/QuotationPreviewStore";


class Breadcrumb extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNextButton: false,
      showPreviousButton: false,
      activePage: "choose-needs",
      routeList: [
        {
          path: "/choose-needs",
          key: "choose-needs",
          label: "Choose Needs",
          flag: false,
        },
        {
          path: "/family-details",
          key: "family-details",
          label: "Family Profile",
          flag: false,
        },
        {
          path: "/customization",
          key: "customization",
          label: "Customization",
          flag: false,
        },
        {
          path: "/solution",
          key: "solution",
          label: "Solution Offering",
          flag: false,
        },
        {
          path: "/quotation-preview",
          key: "quotation-preview",
          label: "Preview & Share",
          flag: false,
        },
        {
          path: "/share-quote",
          key: "share-quote",
          label: "Share Quote",
          flag: false,
        },
        {
          path: "/journey-complete",
          key: "journey-complete",
          label: "Journey Complete",
          flag: false,
        },
      ],
      routeListForBreakCrumb : ['choose-needs','family-details','customization','solution','quotation-preview','share-quote','journey-complete'],

      chooseNeedFormController:{}
    };
    this.fetchList = this.fetchList.bind(this);
    this.onNextBtnClick = this.onNextBtnClick.bind(this);
    this.onPreviousClick = this.onPreviousClick.bind(this);
  }

  async componentDidMount() {
    this.fetchList();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.params.selectedPage !== prevProps.match.params.selectedPage) {
      this.fetchList();
    }
  }

  fetchList() {
    const selectedPage = this.props.match.params.selectedPage;
    this.state.routeList.map((item) => {
      item.flag = false;
    });
    if (selectedPage == undefined) {
      this.state.routeList[0].flag = true;
    } else {
      for (var i = 0; i < this.state.routeList.length; i++) {
        this.state.routeList[i].flag = true;
        if (this.state.routeList[i].key == selectedPage) {
          break;
        }
      }
      this.setState({
        activePage: selectedPage,
      });
    }

    if (selectedPage == "journey-complete") {
      this.setState({
        showNextButton: false,
      });
    } else {
      this.setState({
        showNextButton: true,
      });
    }

    if (selectedPage == "journey-complete" || selectedPage == "choose-needs") {
      this.setState({
        showPreviousButton: false,
      });
    } else {
      this.setState({
        showPreviousButton: true,
      });
    }
    this.setState({});
  }



  familyDetailsValidationForExpenseAndEmi(formController,isValid){

    let ifValid = isValid

    const monthlyExpenseValueList = toJS(FamilyDetailsStore.familyDetailMonthlyExpenseValueList)
        const monthlyExpenseErrorList = toJS(FamilyDetailsStore.familyDetailsMonthlyExpenseErrorList)

        const emiValueList = toJS(FamilyDetailsStore.familyDetailsEmiValueList)
        const emiErrorList = toJS(FamilyDetailsStore.familyDetailsEmiErrorList)

         const savingValueList = toJS(FamilyDetailsStore.familyDetailsSavingValueList)
         const savingsErrorList = toJS(FamilyDetailsStore.familyDetailsSavingErrorList)

         if(monthlyExpenseErrorList.length !== 0){
          monthlyExpenseErrorList.forEach((item) => {
            if(checkIsValueEmpty(monthlyExpenseValueList[item])){
              formController.$("monthly_expense").$(item).invalidate("This field is mandatory")
              ifValid = false
            }
          })
         }


         if(emiErrorList.length !== 0){
            emiErrorList.forEach((item) => {

              if(checkIsValueEmpty(emiValueList[item].option_range.value)){
                ifValid = false
                formController.$("monthly_emi").$(item).$("option_range").invalidate("This field is mandatory");
              }
              
              if(checkIsValueEmpty(emiValueList[item].paid_years.value)){
                ifValid = false
                formController.$("monthly_emi").$(item).$("paid_years").invalidate("This field is mandatory")
              }
              
              if(checkIsValueEmpty(emiValueList[item].total_years.value)){
                ifValid = false
                formController.$("monthly_emi").$(item).$("total_years").invalidate("This field is mandatory")
              }
            })
         }


         if(savingsErrorList.length !== 0){
          savingsErrorList.forEach((item) => {
            if(checkIsValueEmpty(savingValueList[item])){
              ifValid = false
              formController.$("savings").$(item).invalidate("This field is mandatory")
            }
          })
         }

         return ifValid

  }



  async onNextBtnClick(directedPage) {
    let selectedPage = this.props.match.params.selectedPage;
    let journeyId = "";
    if (this.props.match.params.journeyId !== undefined) {
      journeyId = this.props.match.params.journeyId;
    }

    if(directedPage){
      selectedPage = directedPage
    }

    if (selectedPage == 'customization') {

       let listOfDisabledNeeds = toJS(OptimizationStore.listOfTotalNeeds)
       let recalculateBtn = false 
       let productListData = toJS(OptimizationStore.product_list_data)
       productListData.forEach((item) => {
        item.data.forEach((value) => {
          if (value.BI.length > 0) {
            value.BI.map((tab) => {
              if (tab.fieldChanged && !recalculateBtn) recalculateBtn = true;
            });
          } else {
            if (value.fieldChanged && !recalculateBtn) recalculateBtn = true;
          }
        });
      })

       if(listOfDisabledNeeds.every(item => item === true)){
        OptimizationStore.setShowSelectAtLeastOneNeed(true)
       }else if(recalculateBtn) {
        OptimizationStore.setRecalculateProductsSpecs(true)
       }else{
        OptimizationStore.setShowSelectAtLeastOneNeed(false)
        const response = await OptimizationStore.onGenerateSolutionData()
        if (response.status == 'success') {
          this.props.history.push(`/solution/${journeyId}`);
        }
       }
    } else if (selectedPage == 'solution') {
      SolutionStore.customerDetailsModalToggleFunction(true)
      SolutionStore.isCloseModalOpen = false
    } else if (selectedPage == 'quotation-preview') {
      const pdfResponse = await QuotationPreviewUtils.sendSolutionList()
      if (pdfResponse.status === "success") {
        console.log("toJS(AuthStore.solutionData).solution_id ::: ",toJS(AuthStore.solutionData).solution_id)
        console.log("toJS(SolutionStore.seletedSolutionTabData).id ::: ",toJS(SolutionStore.seletedSolutionTabData).proposer_id)
        this.props.history.push(`/qms/qms-details`,
          {
            agent_details: {
              pragati_home_url: localStorage.getItem(StorageKeys.PRAGATI_HOME_URL),
              pragati_post_call_end_point_url: localStorage.getItem(StorageKeys.PRAGATI_POST_CALL_END_POINT_URL),
              authToken: localStorage.getItem(StorageKeys.AUTH_TOKEN),
              agent_id: localStorage.getItem(StorageKeys.AGENT_ID),
              agent_code: localStorage.getItem(StorageKeys.AGENT_CODE),
              agent_name: localStorage.getItem(StorageKeys.AGENT_NAME),
              login_code: localStorage.getItem(StorageKeys.LOGIN_CODE),
              vendor: localStorage.getItem(StorageKeys.VENDOR),
              source_from: localStorage.getItem(StorageKeys.SOURCE_FROM),
              login_agent_code: localStorage.getItem(StorageKeys.LOGIN_AGENT_CODE),
              first_time_optimization_page_load_param: localStorage.getItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM),
            },
            journeyId: journeyId,
            solutionId: toJS(AuthStore.solutionData).solution_id,
            proposerId: toJS(QuotationPreviewStore.selectedTabData).proposer_id
          });
      }
    } else {
      let { formController, callbackFunctions } = this.context;

      formController.validate({ showErrors: true }).then(({ isValid }) => {

        
         let ifValid

        if(selectedPage == 'family-details'){
          ifValid =  this.familyDetailsValidationForExpenseAndEmi(formController,isValid)
        }else{
          ifValid = isValid
        }
         

        if (ifValid) {
          if (callbackFunctions.success !== undefined) {
            callbackFunctions.success(formController).then(() => {
              this.proceedToNextScreen()
            });
          }
        } else {
          let monthly_expense_types = formController.errors()?.monthly_expense ? formController.errors()?.monthly_expense : false;
          Object.keys(monthly_expense_types).map((ExpenseName, i) => {
            monthly_expense_types[ExpenseName] == null ? this.props.updateErrorClass("") : this.props.updateErrorClass("text-danger")
          })
          console.log("Form errors are ", formController.errors());
          if (callbackFunctions.error !== undefined) {
            callbackFunctions.error(formController);
          }
        }
      });
    }
  }

  proceedToNextScreen() {
    const selectedPage = this.props.match.params.selectedPage
    const journeyId = this.props.match.params.journeyId;
    switch (selectedPage) {
      case 'choose-needs':
        let { formController } = this.context;
        this.setState({chooseNeedFormController:formController}) 
        ChooseNeedsStore.setChooseNeedFormControllerFromStore(formController)
        this.props.history.push(`/family-details/${journeyId}`);
        break;
      case 'family-details':

        setTimeout(async () => {
          const getAllDataResponse = await AuthStore.fetchJourneyData()
          this.updateCalculatorValue(getAllDataResponse, journeyId,this.state.chooseNeedFormController)
        }, 0);

        let optimizationScreenLoad = localStorage.getItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM)
        if (optimizationScreenLoad === null || optimizationScreenLoad === "null") {
          localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, true)
        } else {
          localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, false)
        }
        this.props.history.push(`/customization/${journeyId}`);
        break;
      case 'customization':
        // TODO :: Need to implement the route
        // this.props.history.push(`/solution/${journeyId}`);
        break;
      case 'solution':
        // TODO :: Need to implement the route
        break;
      case 'quotation-preview':
        this.props.history.push(`/journey-complete/${journeyId}`);
        break;
      case 'share-quote':
        // TODO :: Need to implement the route
        break;
      case 'journey-complete':
        // TODO :: Need to implement the route
        break
      default:
        break;
    }
  }


  updateCalculatorValue = async (getAllDataResponse, journeyId, IsChooseNeedFormController) => {

    let { formController } = this.context;
    
    if(IsChooseNeedFormController){
      formController = IsChooseNeedFormController
    }

    let { expense_list, user_emi, family_details_list } = getAllDataResponse.data

    const emiLabelList = ['Home Loan EMI', 'Car Loan EMI', 'Other Loan EMI', 'Personal Loan EMI']

    // ! remove emi expense from the expense list
    expense_list = expense_list.filter(item => !emiLabelList.includes(item.expense_label))

    // ! create expense array 
    expense_list = expense_list.map((item) => {
      const { expense_range_id, expense_range_key, expense_range_value } = item
      return { action: "save", id: expense_range_id, expense_range_id, expense_range_key, expense_range_value, user_journey: journeyId }
    })

    // ! create emi array
    let emi_list = user_emi.map((item) => {
      const { expense_range, expense_range_key, expense_range_value, paid_loan_tenure: paid_years, total_loan_tenure: total_years } = item
      return {
        action: "save",
        id: expense_range,
        expense_range_id: expense_range,
        expense_range_key,
        expense_range_value,
        user_journey: journeyId,
        paid_years,
        total_years
      }
    })

    let selfDetails = family_details_list.find(item => item.family_member_type === "SELF")

    let { id, dob, current_life_cover, monthly_income, user_journey } = selfDetails

    let protectionCalculatorReqObj = toJS(ChooseNeedsStore.protectionCalculatorReqObj)

    if (ChooseNeedsStore.isProtectionCalculatorUsed) {

      let dataToBeSubmitted = {
        ...protectionCalculatorReqObj,
        dob: moment(dob).format("YYYY-MM-DD"),
        current_life_cover,
        emi_list,
        expense_list,
        monthly_income,
        journey_id: user_journey,
        family_member_id: id,
      }

      var response = await axios.post(APITypes.PROTECTION_CALCULATOR, dataToBeSubmitted);

      if (response.status == 'error') {
        return
      } else {
        await AuthStore.fetchJourneyData()
        const chooseNeedController = formController

        chooseNeedUtils.updateTargetAmountValue(chooseNeedController, "protection_for_my_family", response.data.lumpsum)
        chooseNeedController.$("protection_for_my_family.is_value_updated").set("value", true)
      }
    }

    if (ChooseNeedsStore.isRetirementCalculatorUsed) {

      let dataToBeSubmitted = {
        ...protectionCalculatorReqObj,
        dob: moment(dob).format("YYYY-MM-DD"),
        current_life_cover,
        expense_list,
        monthly_income: "0",
        journey_id: user_journey,
        family_member_id: id,
      }

      await axios.post(APITypes.RETIREMENT_CALCULATOR, dataToBeSubmitted)

      if (response.status == 'error') {
        return
      } else {
        await AuthStore.fetchJourneyData()
        const chooseNeedController = formController

        chooseNeedUtils.updateTargetAmountValue(chooseNeedController, "save_for_retirement", response.data.annual_income)
        chooseNeedController.$("save_for_retirement.is_value_updated").set("value", true)
      }
    }
  }

  async onPreviousClick(directedPage) {
    
    let selectedPage = this.props.match.params.selectedPage;

    if(directedPage){

      if(directedPage === 'choose-needs'){
        selectedPage = 'family-details'
      }else if(directedPage === 'family-details'){
        selectedPage = "customization"
      }else if(directedPage === 'customization'){
        selectedPage = "solution"
      }else if(directedPage === 'solution'){
        selectedPage = "quotation-preview"
      } 
    }
    
    let journeyId = "";
    if (this.props.match.params.journeyId !== undefined) {
      journeyId = this.props.match.params.journeyId;
    }
    await AuthStore.fetchJourneyData()
    switch (selectedPage) {

      case "choose-needs":
        setTimeout(async () => {
          const getAllDataResponse = await AuthStore.fetchJourneyData()
          this.props.history.push(`/choose-needs/${journeyId}`);
          this.updateCalculatorValue(getAllDataResponse, journeyId)
        }, 0);
      break

      case "family-details":
        let { formController, callbackFunctions } = this.context;

        formController.validate({ showErrors: true }).then(({ isValid }) => {

          if (isValid) {
            if (callbackFunctions.success !== undefined) {
              callbackFunctions.success(formController).then(() => {
                setTimeout(async () => {
                  const getAllDataResponse = await AuthStore.fetchJourneyData()
                  this.props.history.push(`/choose-needs/${journeyId}`);
                  this.updateCalculatorValue(getAllDataResponse, journeyId)
                }, 0);
              })
            }
          } else {
            console.log("Form errors are ", formController.errors());
            if (callbackFunctions.error !== undefined) {
              callbackFunctions.error(formController);
            }
          }
        });
        // this.props.history.push(`/choose-needs/${journeyId}`);
        break;
      case "customization":
        this.props.history.push(`/family-details/${journeyId}`);
        break;
      case "solution":
        localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, false)
        this.props.history.goBack(`/customization/${journeyId}`);
        break;
      case "share-quote":
        // this.props.history.push(`/solution/${journeyId}`);
        break;
      case "quotation-preview":
        localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, false)
        // this.props.history.push(`/solution/${journeyId}`);
        this.props.history.goBack(`/solution/${journeyId}`);

        break;
      default:
        break;
    }
  }



   BreadcrumbFunction = (e) => {
    const selectedPage = this.props.match.params.selectedPage
  
    const currentPage = selectedPage
    const directedPage = e.currentTarget.name.slice(1)
    if(currentPage === 'solution' && directedPage === 'customization') {
      this.props.history.goBack(`/customization/${this.props.match.params.journeyId}`);
    } else if(currentPage === 'quotation-preview' && directedPage === 'customization'){
      this.props.history.go(-2);
    } else {
      if(this.state.routeListForBreakCrumb.indexOf(currentPage) > this.state.routeListForBreakCrumb.indexOf(directedPage)){
        this.onPreviousClick(directedPage)
      } else if (this.state.routeListForBreakCrumb.indexOf(currentPage) < this.state.routeListForBreakCrumb.indexOf(directedPage)){
        this.onNextBtnClick(directedPage)
      }
    }
    console.log('currentPage',currentPage,directedPage);    
};


  render() {
    let isExist = this.props.match.params.selectedPage == "customization" ? OptimizationStore.checkValidationExist : false;

    return (
      <div className='breadcrumb pb-0 pt-0 px-1 w-100'>
        <Container className="breadcrumb-container">
          <div className='d-flex align-items-center justify-content-between'>
            <div className=''>
              <ul className='nav nav-tabs border-white border-bottom-0' id='productTab' role='tablist'>
                {this.state.routeList
                  .filter((value, index) => index <= 4)
                  .map((item, index) => {
                    return (
                      <li key={item.key} className={`nav-item d-flex align-items-center cursor-pointer ${!item.flag ? "disable-li" : ""} `}>
                        <a
                          className={`nav-link font-size-12 font-weight-light ${item.flag ? "text-dark" : "text-gray"} active ${!item.flag ? "disable-li" : ""} ${this.state.activePage == item.key ? "tab-border-bottom-secondary" : ""
                            }`}
                          //style={{ color: item.flag ? "#333333" : }}
                          onClick={this.BreadcrumbFunction}
                          id='explore-tab'
                          data-toggle='tab'
                          name={item.path}
                          // href={`${item.path}/${AuthStore.journeyId}`}
                          role='tab'
                          aria-controls='explore'
                          aria-selected='true'
                        >
                          <span className={`breadcrumb-number ${this.state.activePage == item.key ? "boder-secondary" : "border-gray"}`}>{index + 1}</span>
                          <span className='d-none d-lg-inline ml-2'>{item.label}</span>
                        </a>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className='nextbtn '>
              <div className='btn-white-container d-flex justify-content-md-end py-2 p-md-0'>
                {this.state.showPreviousButton && (
                  <UiButton
                    className='mr-1 mr-md-3 font-weight-light font-size-14 font-size-md-12 px-1 w-100 next-button'
                    variant='outline-secondary'
                    buttonText={this.state.activePage == "solution" ? "Back" : this.state.activePage == "quotation-preview" ? "Cancel" : "Previous"}
                    onClick={() => this.onPreviousClick()}
                  />
                )}
                {this.state.showNextButton && (
                  <NextButton
                    disabled={ChooseNeedsStore.isSelectedNeed || isExist}
                    buttonText={
                      this.state.activePage == "quotation-preview" ? (
                        <ShareButtonTitleComponent />
                      ) : this.state.activePage == "customization" ? (
                        "Submit"
                      ) : this.state.activePage == "solution" ? (
                        "Preview"
                      ) : (
                        "Next"
                      )
                    }
                    onClick={() => this.onNextBtnClick()}
                  />
                )}
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

Breadcrumb.contextType = FormContext;

const ShareButtonTitleComponent = () => {
  return (
    <div className='d-flex flex-row align-items-center justify-content-center w-100'>
      <span>Share</span>
      <Image className='ml-2' src={ShareIconImg} alt='share-icon' />
    </div>
  );
};

export default observer(Breadcrumb);
