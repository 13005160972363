import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import moment from 'moment'

import { BottomsheetDropdown, AdditionalRiderBenifitsCard } from '../../../../../components'
import FamilyProtectionStore from '../../../store/FamilyProtectionStore';
import DeathCoverGraphSlider from '../../family-protection-card/DeathCoverGraphSlider';
import ChangeProductModal from '../../change-product-modal/ChangeProductModal';
import AmountCalculation from '../../save-my-children/AmountCalculation';
import ChangeProductReasonModal from '../../change-product-reason-modal/ChangeProductReasonModal';
import AuthStore from '../../../../login/store/AuthStore';

import AdditionalCards from './AdditionalCards';

import '../../save-my-children/save-my-children.css'

const ActiveIncome = observer(class ActiveIncome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productID: '40035',
            tobaccoUser: false,
            selectedAdditionalCardValue: {},
            selectedAdditionalRiderCardValue: {},
            maturityPayoutModeOptions: [
                {
                    id: 1,
                    label: 'Monthly Level'
                }
            ],
            selectedMaturityPayoutModeOptions: {},
            disabled: false,
            changeProductReasonModal: false,
            changeProductModal: false,
            selectdReason: {},
            showOverlay: false,
            isReason: false,
            ppt: {
                id: 10,
                label: '10 Years'
            },
            pt: {
                id: (75 - Number(this.getReqParams().LI_ENTRY_AGE)),
                label: '75 Years'
            },
            incomeOption: {
                id: 3,
                label: 'Early Income'
            },
            guaranteed: {
                id: 5,
                label: 'Level'
            },
            guaranteedList: [
                { id: 5, label: 'Level' },
                { id: 6, label: 'Increasing' }
            ],
            maturityAge: { id: 77, label: '75 Years' },
            ppf: { id: 4, label: 'Monthly' },
            isWaiverBenefitDisable: false,
            premiumAmount: '10000',
            changeProductReasonModal: false,
            changeProductModal: false,
            selectdReason: {},
            reqParams: this.getReqParams(),
            isLoading: false,
            ridersList: this.getRiders(),
            addBenifits: this.getBenifits(),
            payoutModeList: [
                { id: 1, label: 'Lumpsum' },
                { id: 2, label: 'Income' }
            ],
            payoutMode: { id: 2, label: 'Income' },
            pendingUpdateValues: null,
            disabled: {
                familyIncome: false,
                maturityAge: false
            }
        }
    }
    getReqParams = () => {
        return {
            'PR_ID': '40035',
            'CashFlow': 'N',
            'LI_STATE': '0',
            'PROPOSER_NAME': 'sad ads',
            'PROPOSER_AGE': '30',
            'PROPOSER_DOB': '10 Dec 1989',
            'PROPOSER_GENDER': 'M',
            'SameProposer': true,
            'COMPANY_STATE': '0',
            'GSTIN': '0',
            'GSTIN_Number': '0',
            'INPUT_MODE': '4',
            'PR_PT': '99-@li_entry_age',
            'PR_PPT': '12',
            'AGENT_ID': '0',
            'LI_MOBILENO': '9489849489',
            'LI_EMAILID': 'asdasd@sd.com',
            'PR_ANNPREM': '120000',
            'PR_MI': '0',
            'PR_SA': '',
            'PR_SAMF': '0',
            'PR_ModalPrem': 50000,
            'category': '12',
            'PR_CHANNEL': '1',
            'flat_rate': '0',
            'emr_rate': '1',
            'REQUESTSOURCE': '1',
            'ADBRider': '',
            'ATPDRider': '',
            'CriticalIllnessRider': '',
            'IncomeBenefitRider': '',
            'FamilyIncomeBenefit': '2',
            'IncomeOption': '4',
            'GuaranteedIncomeType': '5',
            'isPdfByte': 'Y',
            'WaiverOfPremiumRider': '',
            'PayorWaiverBenefitRider': '',
            'PWB': '',
            'LI_NAME': 'sad ads',
            'LI_ENTRY_AGE': '30',
            'LI_DOB': '10 Dec 1989',
            'LI_GENDER': 'M'
        }
    }
    incomeBenefitAmountList = (min, max) => {
        let values = []
        for (let i = 1; i < 8; i++) {
            let amount = min * i
            values.push({ id: amount, label: amount.toString() })
        }

        let lastAmount = max;
        values.push({ id: max, label: '' + lastAmount })
        return values;
    }

    // Critical Illness Rider 1L to 50L
    criticalIllnessAmountList = () => {
        let values = []
        for (let i = 1; i < 50; i++) {
            values.push({ id: i, label: i + ' L' })
        }
        return values;
    }
    // Accidental Death Benefit and Permanent Disability 1L to 1CR
    benefitAmountList = (min, max) => {
        let values = []
        // min = Math.round(min / 100000)
        max = Math.round(max / 100000)
        for (let i = 1; i <= max; i++) {
            values.push({ id: i, label: i + ' L' })
        }
        return values;
    }

    getBenifits = () => {
        return [
            {
                'id': 1,
                'action': 'update',
                'title': 'Family Income',
                'description': 'Loren ipsum Loren ipsum Loren ipsum Loren ipsum Loren ipsum Loren ipsum Loren ipsum Loren ipsum',
                'sum_assured': '-',
                'is_selected': false,
                'one_time_fee': '0',
                'isDisabled': false
            }
        ]
    }

    getRiders = () => {
        const { data } = this.props;

        let bi = this.getParseBIData().BI
        let maxRange = bi.SA - 1
        console.log('GET RIDERS >> ', maxRange)

        let age = moment().diff(data.dob, 'years');
        let isDisabledAll = age < 18 || age > 65
        return [
            {
                'id': 1,
                'action': 'update',
                'brochure_link': 'https://www.edelweisstokio.in/riders/download/23-3?_ga=2.175776610.1125348727.1616483264-207121548.1616483264',
                'title': 'Waiver of Premium Rider',
                'description': 'Simple life insurance cover',
                'sum_assured': '-',
                'initial_amount': '',
                'is_selected': false,
                'one_time_fee': '',
                'selected_value': {},
                'paramName': 'WaiverOfPremiumRider',
                'isDisable': isDisabledAll,
                'showDropdown': false,
                'rider_name': 'Waiver of Premium Rider',
                'amount_ist': null,
                'tax': ''
            },
            {
                'id': 2,
                'action': 'update',
                'brochure_link': 'https://www.edelweisstokio.in/riders/download/81-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
                'title': 'Accidental Death Benefit Rider',
                'description': 'The Edelweiss Tokio Life - Accidental Death Benefit Rider gives you extra protection. In case an accident claims your life, your family will get the selected accidental death cover amount in addition to the base life cover amount.',
                'sum_assured': '',
                'initial_amount': '119',
                'is_selected': true,
                'one_time_fee': '22',
                'selected_value': { id: 1, label: '1 L' },
                'paramName': 'ADBRider',
                'showDropdown': true,
                'isDisable': isDisabledAll,
                'rider_name': 'ADB Rider',
                'amount_list': this.benefitAmountList(10000, maxRange),
                'tax': ''
            },
            {
                'id': 3,
                'action': 'update',
                'brochure_link': 'https://www.edelweisstokio.in/riders/download/82-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
                'title': 'Accidental Total and Permanent Disability Rider',
                'description': 'If you opt for Edelweiss Tokio Life - Accidental Total and Permanent Disability Rider, you’ll get the cover amount you have selected in case an accident causes permanent disability. This takes care of all your immediate expenses such as medications, hospital bills and regular expenses such as EMIs, education fees etc. The base policy continues as usual.',
                'sum_assured': '',
                'initial_amount': '96',
                'is_selected': true,
                'one_time_fee': '22',
                'selected_value': { id: 1, label: '1 L' },
                'paramName': 'ATPDRider',
                'showDropdown': true,
                'isDisable': isDisabledAll,
                'rider_name': 'ATPD Rider',
                'amount_list': this.benefitAmountList(100000, maxRange),
                'tax': ''
            },
            {
                'id': 4,
                'action': 'update',
                'brochure_link': 'https://www.edelweisstokio.in/riders/download/22-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
                'title': 'Critical Illness Rider',
                'description': 'Under Edelweiss Tokio Life - Critical Illness Rider, if you are diagnosed with one of the 12 specific critical illnesses, you will receive a payout of the critical illness cover you’ve selected. The policy will continue with the base life cover after critical illness claim paid. Term life insurance is for your family’s security but this rider will cover you financially against 12 listed critical illnesses.',
                'sum_assured': '',
                'initial_amount': '2295',
                'is_selected': false,
                'one_time_fee': '22',
                'selected_value': { id: 1, label: '1 L' },
                'paramName': 'CriticalIllnessRider',
                'showDropdown': true,
                'isDisable': isDisabledAll,
                'rider_name': 'Critical Illness Rider',
                'amount_list': this.criticalIllnessAmountList(),
                'tax': ''
            },
            {
                'id': 5,
                'action': 'update',
                'brochure_link': 'https://www.edelweisstokio.in/riders/download/22-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
                'title': 'Income Benefit Rider',
                'description': 'Under Edelweiss Tokio Life - Income Benefit Rider ensures your family has continuity of regular income even in your absence.',
                'sum_assured': '',
                'initial_amount': '885',
                'is_selected': false,
                'one_time_fee': '22',
                'selected_value': { id: 75000, label: '75000' },
                'paramName': 'IncomeBenefitRider',
                'showDropdown': true,
                'isDisable': isDisabledAll,
                'rider_name': 'Income Benefit Rider',
                'amount_list': this.incomeBenefitAmountList(75000, maxRange),
                'tax': ''
            },
            {
                'id': 6,
                'action': 'update',
                'brochure_link': 'https://www.edelweisstokio.in/riders/download/22-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
                'title': 'Payor Waiver Benefit Rider',
                'description': 'Under Edelweiss Tokio Life - Income Benefit Rider ensures your family has continuity of regular income even in your absence.',
                'sum_assured': '',
                'initial_amount': '885',
                'is_selected': false,
                'one_time_fee': '22',
                'selected_value': { id: 1, label: 'On Death' },
                'paramName': 'PayorWaiverBenefitRider',
                'showDropdown': true,
                'isDisable': isDisabledAll || true,
                'rider_name': 'Payor Waiver Benefit Rider',
                'amount_list': [
                    { id: 1, label: 'On Death' },
                    { id: 2, label: 'CI or ATPD' },
                    { id: 3, label: 'CI or ATPD or Death' }
                ],
                'tax': ''
            }
        ]
    }
    onPolicyOptions = (value) => {
        let pptList = this.getPremiumTermList()
        let ppt = pptList[pptList.length - 1]
        this.dataChange('pt', value.id)
        this.setState({ pt: value, ppt })
    }
    onPremiumOptions = (value) => {
        this.dataChange('ppt', value.id)
        this.setState({ ppt: value })
    }
    onIncomeOption = (value) => {
        this.setState({ incomeOption: value })

        this.state.reqParams.GuaranteedIncomeType = '5'
        this.dataChange('incomeOption', value.id)

        let { guaranteedList } = this.state
        this.setState({ guaranteed: { ...guaranteedList[0] } })
    }
    onGuaranteed = (value) => {
        this.dataChange('guaranteedIncomeType', value.id)
        this.setState({ guaranteed: value })
    }
    onMaurityAge = (value) => {
        this.dataChange('maturityAge', value.id)
        this.setState({ maturityAge: value })
    }
    onPpfChange = (value) => {
        let premiumAmount = Number(this.state.premiumAmount)
        if (premiumAmount) {
            let inputMode = value.id
            switch (inputMode) {
                case 1:
                    this.state.reqParams.PR_ANNPREM = '' + premiumAmount * 1
                    break;
                case 2:
                    this.state.reqParams.PR_ANNPREM = '' + premiumAmount * 2
                    break;
                case 3:
                    this.state.reqParams.PR_ANNPREM = '' + premiumAmount * 4
                    break;
                case 4:
                    this.state.reqParams.PR_ANNPREM = '' + premiumAmount * 12
                    break;
            }
        }
        this.dataChange('ppf', value.id)
        this.setState({ ppf: value })
    }
    hideOverLay = () => {
        setTimeout(() => {
            this.setState({ showOverlay: false })
        }, 2000)
    }
    // single option
    onAdditionalRiderCardOptionSelect = (checkedValue, selectedObj, familyMember, index) => {
        let riderAmount = 0;
        let errorMsg = '';
        let aipRiders = JSON.parse(JSON.stringify(this.state.ridersList))
        aipRiders[index].is_selected = checkedValue
        aipRiders.map((item) => {
            if (item.is_selected) {
                if (item.paramName == 'IncomeBenefitRider') {
                    riderAmount += Number(item.selected_value.label)
                } else {
                    riderAmount += item.selected_value.id * 100000
                }
            }
        })

        if (riderAmount > Number(FamilyProtectionStore.aipResponseData.SA)) {
            errorMsg = 'Rider Sum Assured cannot be greater than Base Sum Assured';
            aipRiders[index].is_selected = false
            AuthStore.setErrorModal(errorMsg)
            return
        }

        // this.state.ridersList = aipRiders
        this.dataChange(aipRiders[index].paramName, checkedValue)
        this.state.pendingUpdateValues = { key: 'Riders', value: { selectedObj, checkedValue, index } }
    }
    // multiselect option
    componentDidMount() {

        // Note : Only disable for self , for other family members benefits available
        // let isSelfData = this.props.data.family_member_type == 'Self'
        // this.state.ridersList[0].isDisable = isSelfData;
        // this.state.ridersList[0].isDisable = true;

        // let guaranteedList = this.state.guaranteedList
        // if (this.state.incomeOption.id == 3) {
        //     guaranteedList.map((item, index) => {
        //         if (item.id == 6) {
        //             delete guaranteedList.splice(index, 1);
        //         }
        //     })
        // }

        let data = this.getParseBIData()

        this.state.reqParams = { ...data.bi_request }
        this.updateTargetValue(data.BI)


        this.setState({
            selectdReason: toJS(FamilyProtectionStore.productReasonList)[0],
            isLoading: true
        })

        // console.log('COMPONNENT DID MOUNT >> ', data)
        if (Object.keys(data.BI).length == 0) {
            this.fetchData()
        } else {
            this.setState({
                selectdReason: toJS(FamilyProtectionStore.productReasonList)[0],
                isLoading: false
            })
        }

        this.prefieldData()
        this.changesForSubProduct()
    }

    changesForSubProduct = () => {
        const { data } = this.props;
        let { disabled, pt } = this.state;

        console.log('CHANGES FOR SUB PRODUCT >> ', data, disabled)
        switch (data.product_name) {
            case 'POS - Saral Nivesh':
                break
            case 'Three Generation Plan (Active Income Plan)':
                pt = toJS(FamilyProtectionStore.policyTermList)[2]
                this.state.addBenifits.map(obj => {
                    if (obj.title == 'Family Income') {
                        obj.is_selected = false
                        obj.isDisabled = true
                    }
                })
                disabled.maturityAge = true
                this.state.ridersList.map(rider => rider.isDisable = true)
                this.setState({ disabled, pt })
                break
            case 'Retire Befiker (Active Income Plan)':
                pt = toJS(FamilyProtectionStore.policyTermList)[2]
                this.state.addBenifits.map(obj => {
                    if (obj.title == 'Family Income') {
                        obj.is_selected = false
                        obj.isDisabled = true
                    }
                })
                disabled.maturityAge = true
                this.setState({ disabled, pt })
                break
            case 'Active Income Plan with FIB':
                break
            case 'Whole Life Active Income (Active Income Plan)':
                pt = toJS(FamilyProtectionStore.policyTermList)[2]
                disabled.maturityAge = true
                this.setState({ disabled, pt })
                break
        }
    }

    prefieldData = () => {
        try {
            let data = toJS(this.state.reqParams)
            let { pt, ppt, ppf, incomeOption, maturityAge, guaranteed } = this.state;

            // console.log('AIP PREFIELD DATA >> ', data)

            const ptList = toJS(FamilyProtectionStore.policyTermList);
            pt = ptList.filter(obj => obj.id == data.PR_PT)[0] || pt

            const pptList = toJS(FamilyProtectionStore.premiumTermList);
            ppt = pptList.filter(obj => obj.id == data.PR_PPT)[0] || ppt

            const ppfList = toJS(FamilyProtectionStore.ppfList);
            ppf = ppfList.filter(obj => obj.id == parseInt(data.INPUT_MODE))[0] || ppf

            // const familyIncomeBenifitList = toJS(FamilyProtectionStore.familyIncomeBenefitList);
            // familyIncomeBenefit = familyIncomeBenifitList.filter(obj => obj.id == parseInt(data.FamilyIncomeBenefit))[0] || familyIncomeBenefit

            const incomeOptionList = toJS(FamilyProtectionStore.incomeOptionList);
            incomeOption = incomeOptionList.filter(obj => obj.id == parseInt(data.IncomeOption))[0] || incomeOption

            // const maturityAgeList = toJS(FamilyProtectionStore.maturityAgeList);
            // maturityAge = maturityAgeList.filter(obj => obj.id == parseInt(data.IncomeOption))[0] || maturityAge

            this.state.addBenifits.map(obj => {
                switch (obj.title) {
                    case 'Family Income':
                        obj.is_selected = data.FamilyIncomeBenefit == '1'
                        break
                }
            })

            const guaranteedList = this.state.guaranteedList
            guaranteed = guaranteedList.filter(obj => obj.id == parseInt(data.GuaranteedIncomeType))[0] || guaranteed

            this.setState({ pt, ppt, ppf, incomeOption, maturityAge, guaranteed })

            this.state.ridersList.map(b => {
                switch (b.paramName) {
                    case 'WaiverOfPremiumRider':
                        b.is_selected = data.WaiverOfPremiumRider != ''
                        if (b.is_selected) {
                            b.selected_value = b.amount_list.filter(obj => obj.id == data.WaiverOfPremiumRider / 100000).length > 0 || b.selected_value
                        }
                        break
                    case 'ADBRider':
                        b.is_selected = data.ADBRider != ''
                        if (b.is_selected) {
                            b.selected_value = b.amount_list.filter(obj => obj.id == data.ADBRider / 100000).length > 0 || b.selected_value
                        }
                        break
                    case 'ATPDRider':
                        b.is_selected = data.ATPDRider != ''
                        if (b.is_selected) {
                            b.selected_value = b.amount_list.filter(obj => obj.id == data.ATPDRider / 100000).length > 0 || b.selected_value
                        }
                        break
                    case 'CriticalIllnessRider':
                        b.is_selected = data.CriticalIllnessRider != ''
                        if (b.is_selected) {
                            b.selected_value = b.amount_list.filter(obj => obj.id == data.CriticalIllnessRider / 100000).length > 0 || b.selected_value
                        }
                        break
                    case 'IncomeBenefitRider':
                        b.is_selected = data.IncomeBenefitRider != ''
                        if (b.is_selected) {
                            b.selected_value = b.amount_list.filter(obj => obj.id == data.IncomeBenefitRider).length > 0 || b.selected_value
                        }
                        break
                    case 'PayorWaiverBenefitRider':
                        b.is_selected = data.PayorWaiverBenefitRider != ''
                        if (b.is_selected) {
                            b.selected_value = b.amount_list.filter(obj => obj.id == data.PWB).length > 0 || b.selected_value
                        }
                        break
                }
            })

            console.log('AIP PREFIELD DATA >> ', data)
        } catch (error) {
            console.error('ERROR >> ', error)
        }
    }

    onChangeProductReasonModalOnShow = () => {
        this.setState({ changeProductReasonModal: true })
    }

    onChangeProductReasonModalOnHide = () => {
        this.setState({ changeProductReasonModal: false })
    }
    onChangeProductModalOnShow = () => {
        this.setState({ changeProductModal: true, changeProductReasonModal: false })
    }

    onChangeProductModalOnHide = () => {
        this.setState({ changeProductModal: false })
    }
    onChangeReason = (e) => {
        this.setState({ selectdReason: e });
    }
    onPremiumChange = (e) => {
        const data = this.props.data
        let amount = e.target.value
        data.premium_amount = amount;

        this.setState({
            premiumAmount: amount
        })
        FamilyProtectionStore.updateProductsDetails(this.props.cardKey, data);
    }
    onAdditionalCardOptionSelect = async (checkedValue, selectedObj) => {
        switch (selectedObj.title) {
            case 'Family Income':
                this.dataChange('FamilyIncomeBenefit', checkedValue)
                this.state.pendingUpdateValues = { key: 'FamilyIncomeBenefit', value: { selectedObj, checkedValue } }
                break;
        }
    }

    onDropDownValueChanges = (value, index, data) => {
        let riderAmount = 0;
        let errorMsg = '';
        let aipRiders = this.state.ridersList
        aipRiders[index].selected_value = value

        aipRiders.map((item) => {
            if (item.is_selected) {
                if (item.paramName == 'IncomeBenefitRider') {
                    riderAmount += Number(item.selected_value.label)
                } else {
                    riderAmount += item.selected_value.id * 100000
                }
            }
        })

        if (riderAmount > Number(FamilyProtectionStore.aipResponseData.SA)) {
            errorMsg = 'Rider Sum Assured cannot be greater than Base Sum Assured';
            AuthStore.setErrorModal(errorMsg)
            return
        }

        this.state.ridersList = aipRiders
        if (aipRiders[index].paramName == 'PayorWaiverBenefitRider') {
            if (value) {
                this.dataChange(aipRiders[index].paramName, value)
            } else {
                this.dataChange(aipRiders[index].paramName, '')
            }
        } else {
            this.dataChange(aipRiders[index].paramName, value)
        }
    }

    onCheckBoxChange = (checkedValue, selectedObj) => {
        this.props.onClick(checkedValue, selectedObj, this.props.data);
    }

    onRecalculateButtonClick = () => {
        let amount = Number(this.state.premiumAmount)
        let premiumAmount = ''
        let inputMode = parseInt(FamilyProtectionStore.aipRequestData.INPUT_MODE)
        switch (inputMode) {
            case 1:
                premiumAmount = '' + amount * 1
                break;
            case 2:
                premiumAmount = '' + amount * 2
                break;
            case 3:
                premiumAmount = '' + amount * 4
                break;
            case 4:
                premiumAmount = '' + amount * 12
                break;
        }
        this.dataChange('premium', premiumAmount)
    }

    async dataChange(paramName, paramValue, productId) {
        // console.log('Product id is ', paramName)
        // console.log('Value is ', paramValue)

        switch (paramName) {
            case 'ppt':
                this.state.reqParams.PR_PPT = '' + paramValue
                break;

            case 'maturityAge':
            case 'pt':
                this.state.reqParams.PR_PT = '' + paramValue
                break;

            case 'ppf':
                this.state.reqParams.INPUT_MODE = '' + paramValue
                break;

            case 'FamilyIncomeBenefit':
                this.state.reqParams.FamilyIncomeBenefit = paramValue ? '1' : '2'
                break;

            case 'incomeOption':
                this.state.reqParams.IncomeOption = '' + paramValue
                break;

            case 'guaranteedIncomeType':
                this.state.reqParams.GuaranteedIncomeType = '' + paramValue
                break;

            case 'premium':
                this.state.reqParams.PR_ANNPREM = '' + paramValue
                break;

            case 'investForFamily':
                this.state.reqParams.LI_DOB = '' + paramValue.dob
                this.state.reqParams.LI_ENTRY_AGE = '' + paramValue.age
                this.state.reqParams.LI_GENDER = '' + paramValue.type
                this.state.reqParams.LI_NAME = '' + paramValue.name
                break;

            case 'investForSelf':
                this.state.reqParams.LI_DOB = this.state.reqParams.PROPOSER_DOB
                this.state.reqParams.LI_ENTRY_AGE = this.state.reqParams.PROPOSER_AGE
                this.state.reqParams.LI_GENDER = this.state.reqParams.PROPOSER_GENDER
                this.state.reqParams.LI_NAME = this.state.reqParams.PROPOSER_NAME
                break;

            case 'benefits':
                this.state.reqParams.FamilyIncomeBenefit = paramValue
                break;

            case 'WaiverOfPremiumRider':
                if (paramValue) {
                    this.state.reqParams.WaiverOfPremiumRider = '1'
                } else {
                    this.state.reqParams.WaiverOfPremiumRider = ''
                }
                break

            case 'ADBRider':
                if (paramValue) {
                    this.state.reqParams.ADBRider = (this.state.ridersList[1].selected_value.id * 100000).toString();
                } else {
                    this.state.reqParams.ADBRider = '';
                }
                break;

            case 'ATPDRider':
                if (paramValue) {
                    this.state.reqParams.ATPDRider = (this.state.ridersList[2].selected_value.id * 100000).toString();
                } else {
                    this.state.reqParams.ATPDRider = '';
                }
                break;

            case 'CriticalIllnessRider':
                if (paramValue) {
                    this.state.reqParams.CriticalIllnessRider = (this.state.ridersList[3].selected_value.id * 100000).toString();
                } else {
                    this.state.reqParams.CriticalIllnessRider = '';
                }
                break;

            case 'IncomeBenefitRider':
                if (paramValue) {
                    this.state.reqParams.IncomeBenefitRider = this.state.ridersList[4].selected_value.label
                } else {
                    this.state.reqParams.IncomeBenefitRider = '';
                }
                break;

            case 'PayorWaiverBenefitRider':
                if (paramValue) {
                    this.state.reqParams.PayorWaiverBenefitRider = '1'
                    this.state.reqParams.PWB = '' + this.state.ridersList[5].selected_value.id
                } else {
                    this.state.reqParams.PayorWaiverBenefitRider = '';
                }
                break;
        }
        await this.fetchData()
    }


    onPendingUpdateValues = () => {
        let { key, value } = this.state.pendingUpdateValues
        if (!key) {
            return
        }
        switch (key) {
            case 'Riders':
                let riders = this.state.ridersList
                let index = value.index
                riders[index].is_selected = value.checkedValue
                this.setState({ ridersList: riders })
                break
            case 'FamilyIncomeBenefit':
                this.state.addBenifits.map(obj => {
                    if (obj.title == value.selectedObj.title) {
                        obj.is_selected = value.checkedValue
                    }
                })
                break
        }
        this.state.pendingUpdateValues = null
    }

    onRevertPendingUpdateValues = () => {
        let { key, value } = this.state.pendingUpdateValues
        if (!key) {
            return
        }
        switch (key) {
            case 'FamilyIncomeBenefit':
                this.state.reqParams.FamilyIncomeBenefit = !value.checkedValue ? '2' : '1'
                break
        }
        this.state.pendingUpdateValues = null
    }

    fetchData = async () => {
        try {
            global.loading(true)
            const response = await FamilyProtectionStore.fetchAIP(this.state.reqParams)
            let data = JSON.parse(response.JsonOutput);
            console.log('AIP RESPONSE >> ', data)

            if (data.Status == 'Fail') {
                FamilyProtectionStore.showErrorMsg(data)
                this.onRevertPendingUpdateValues()
            } else {
                let jsonOutputData = data;
                console.log('BI Response data is ', data)
                if (data && JSON.parse(data.BIJson)) {

                    data = JSON.parse(data.BIJson);
                    FamilyProtectionStore.aipBIJsonResponseData = data
                    console.log('BI Response data is  >', data)

                    var biData = data[data.length - 1]
                    this.state.ridersList.map((riderData) => {
                        let inputValidationStatus = jsonOutputData.InputValidationStatus.filter((item) => item.ProductName == riderData.rider_name)
                        if (inputValidationStatus.length > 0) {
                            riderData.sum_assured = inputValidationStatus[0].ModalPremium.toString()
                            riderData.tax = inputValidationStatus[0].Tax.toString()
                        }
                    })

                    this.state.ridersList.filter((item) => item.title == 'WaiverOfPremiumRider' && item.is_selected).map((rider) => {
                        rider.sum_assured = biData.WOP_Modal_Prem
                        rider.tax = biData.WOP_TAX
                    })

                    this.updateTargetValue(data[data.length - 1])
                    this.onPendingUpdateValues()
                }

                this.setState({ isLoading: false })
                let dataObj = JSON.parse(JSON.stringify(this.props.data));
                let biUpdatedData = dataObj.BI.map(obj => {
                    if (obj.risk == this.props.tab) {
                        obj.BI = response
                    }
                    return obj
                })
                console.log('UPDATED BI UPDATED DATA >> ', biUpdatedData)

                FamilyProtectionStore.updateBIResponseToData(this.props.cardKey, this.props.data.product_id, biUpdatedData)
            }

            // console.log('aipResponseData is ', data[data.length - 1])
        } catch (error) {
            console.log('Error is ', error)
        } finally {

            global.loading(false)
        }
    }

    updateTargetValue = (data) => {
        if (this.props.onValueUpdate) {
            const targetAmount = this.state.reqParams.investment_target

            const ppfList = toJS(FamilyProtectionStore.ppfList);
            let frequency = ppfList.filter(obj => obj.id == parseInt(this.state.reqParams.INPUT_MODE))[0]

            let totalPrice = this.getTotalPrice()
            var price = totalPrice.totalPremium
            this.props.onValueUpdate({ targetAmount, frequency, price })
        }
    }

    getParseBIData = () => {
        let data = JSON.parse(JSON.stringify(this.props.data));
        let BI = data.BI
        try {
            if (BI && BI.JsonOutput) {
                BI = JSON.parse(BI.JsonOutput);
                // console.log('GET PARSE DATA >> ', BI)
                BI = JSON.parse(BI.BIJson);
                if (BI) {
                    BI = BI[BI.length - 1]
                }
            } else {
                BI = {}
            }
        } catch (error) {
            console.log('ERROR >> ', error)
            BI = {}
        }
        if (!BI) {
            BI = {}
        }
        data.BI = BI
        return data
    }

    getTotalPrice = () => {
        let totalPremium = 0;
        let data = this.getParseBIData()
        totalPremium += Number(this.state.reqParams.PR_ANNPREM) + data.BI.TAX_MP
        let totalRiderTax = 0
        this.state.ridersList.map((item) => {
            if (item.is_selected) {
                totalPremium += Number(item.sum_assured)
                totalRiderTax += Number(item.tax)
            }
        })

        totalPremium += totalRiderTax

        var amount = {
            totalPremium: totalPremium,
            totalRiderTax: totalRiderTax
        }
        return amount;
    }

    onPayoutMode = (value) => {
        this.setState({ payoutMode: value })
    }

    getGuaranteedList = () => {
        let { guaranteedList, incomeOption } = this.state
        let filterList = []
        switch (incomeOption.id) {
            case 3:
                filterList = guaranteedList.filter(obj => obj.id == 5)
                break
            case 4:
                filterList = guaranteedList
                break
        }
        return filterList
    }

    getRidersList = () => {
        const { data } = this.props;
        let { ridersList, addBenifits } = this.state
        let familyIncomeBenefit = addBenifits.filter(obj => obj.title == 'Family Income')[0]
        // Disabled 'Payor Waiver Benefit Rider' for Self
        ridersList.map(rider => {
            if (data.family_member_type == 'Self' && rider.paramName == 'WaiverOfPremiumRider') {
                rider.isDisable = true
            }
            if (familyIncomeBenefit.is_selected && rider.paramName == 'PayorWaiverBenefitRider') {
                rider.isDisable = false
            }
        })
        return ridersList
    }

    getPremiumTermList = () => {
        const { incomeOption, pt } = this.state;
        const { data } = this.props;

        let age = moment().diff(data.dob, 'years');
        const { addBenifits } = this.state;
        let familyIncomeBenefit = addBenifits.filter(obj => obj.title == 'Family Income')[0]

        let withFamilyIncome = familyIncomeBenefit.is_selected
        let earlyIncomeOption = incomeOption.id == 3

        let premiumTermList = toJS(FamilyProtectionStore.premiumTermList);

        let maturityAge = pt.id
        let diffAge = 0
        switch (maturityAge) {
            case '75-@li_entry_age':
                diffAge = 75 - age
                break;
            case '85-@li_entry_age':
                diffAge = 85 - age
                break;
            case '99-@li_entry_age':
                diffAge = 99 - age
                break;
        }
        premiumTermList = premiumTermList.filter(obj => obj.id < diffAge)

        // switch (maturityAge) {
        //     case '75-@li_entry_age':
        //         if (earlyIncomeOption) {
        //             if (withFamilyIncome) {
        //                 if (age > 40) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id > 5)
        //                 }
        //                 if (age > 40) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id < 8)
        //                 }
        //             } else {
        //                 if (age > 50) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id > 5)
        //                 }
        //                 if (age > 65) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id < 8)
        //                 }
        //             }
        //         } else {

        //         }
        //         break
        //     case '85-@li_entry_age':
        //         break
        //     case '99-@li_entry_age':
        //         if (earlyIncomeOption) {
        //             if (withFamilyIncome) {
        //                 if (age > 40) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id > 5)
        //                 }
        //                 if (age > 40) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id < 8)
        //                 }
        //             } else {
        //                 if (age > 50) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id > 5)
        //                 }
        //                 if (age > 65) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id < 8)
        //                 }
        //             }
        //         } else {
        //             if (withFamilyIncome) {
        //                 if (age > 40) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id > 5)
        //                 }
        //                 if (age > 40) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id < 8)
        //                 }
        //             } else {
        //                 if (age > 55) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id > 5)
        //                 }
        //                 if (age > 65) {
        //                     premiumTermList = premiumTermList.filter(obj => obj.id < 8)
        //                 }
        //             }
        //         }
        //         break
        // }
        // console.log('Premium Paying Term age >> ', age)
        // console.log('Premium Paying Term earlyIncomeOption >> ', earlyIncomeOption)
        // console.log('Premium Paying Term withFamilyIncome >> ', withFamilyIncome)
        // console.log('Premium Paying Term maturityAge >> ', maturityAge)
        // console.log('Premium Paying Term premiumTermList >> ', premiumTermList)
        return premiumTermList

    }

    getPolicyTermList = () => {
        const { data } = this.props;
        let policyTermList = toJS(FamilyProtectionStore.policyTermList);
        let age = moment().diff(data.dob, 'years');
        policyTermList = policyTermList.filter((obj, index) => {
            if (age > 75) {
                return index > 0
            } else if (age > 85) {
                return index > 1
            } else {
                return true
            }
        })

        return policyTermList
    }

    render() {
        const { disabled, addBenifits } = this.state;
        let data = this.getParseBIData()
        const premiumTermList = this.getPremiumTermList()
        const policyTermList = this.getPolicyTermList()

        const guaranteedList = this.getGuaranteedList()

        const ridersList = this.getRidersList()

        const { tab, selectedTab } = this.props;
        let totalAmount = this.getTotalPrice()
        let totalPrice = totalAmount.totalPremium
        if (tab && selectedTab) {
            totalPrice = tab != selectedTab ? 0 : totalPrice
        }

        // console.log('ACTIVE INCOME >> ', data)

        return (
            <div className='save-my-children'>

                <React.Fragment>
                    <Row className='align-items-center mt-md-4'>
                        <Col md={6} className='col-12'>
                            <div className='text-description-text-color font-size-12 font-size-md-18 font-size-lg-20 mt-2'>
                                Underlying Product :{this.props.data.product_name}
                            </div>
                        </Col>
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Payout Mode</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Select Mode'
                                    disabled={true}
                                    menuList={this.state.payoutModeList}
                                    value={this.state.payoutMode}
                                    onSelect={this.onPayoutMode}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-2 mt-md-4'>
                        {/* <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Family Income Benefit</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Select Family Income'
                                    menuList={FamilyProtectionStore.familyIncomeBenefitList}
                                    value={this.state.familyIncomeBenefit}
                                    onSelect={this.onFamilyIncomeBenefit}
                                    disabled={disabled.familyIncome}
                                />
                            </div>
                        </Col> */}
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Income Option</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Select Income Option'
                                    menuList={FamilyProtectionStore.incomeOptionList}
                                    value={this.state.incomeOption}
                                    onSelect={this.onIncomeOption}
                                />
                            </div>
                        </Col>
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Guaranteed Income</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Select Guaranteed Income'
                                    menuList={guaranteedList}
                                    value={this.state.guaranteed}
                                    onSelect={this.onGuaranteed}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-2 mt-md-4'>
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Premium Paying Frequency</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Select Premium Paying Frequency'
                                    onSelect={this.onPpfChange}
                                    menuList={FamilyProtectionStore.ppfList}
                                    value={this.state.ppf}
                                />
                            </div>
                        </Col>
                    </Row>

                    <ChangeProductReasonModal
                        selectedReasonVal={this.state.selectdReason}
                        show={this.state.changeProductReasonModal}
                        onSelectReason={this.onChangeReason}
                        onHide={this.onChangeProductReasonModalOnHide}
                        list={FamilyProtectionStore.productReasonList}
                        onSelectProductModalbtnClick={this.onChangeProductModalOnShow}
                    // isReason={props.isReason}
                    />

                    <ChangeProductModal
                        show={this.state.changeProductModal}
                        onSelectReason={() => { }}
                        onHide={this.onChangeProductModalOnHide}
                        list={FamilyProtectionStore.productList}
                    />

                    <DeathCoverGraphSlider
                        productTitle='Active Income'
                        openChangeReasonModal={this.onChangeProductReasonModalOnShow}
                        onIncomeChange={() => { }}
                        onPremiumChange={this.onPremiumChange}
                        productData={this.props.data}
                        hideOverLay={this.hideOverLay}
                        productID={this.state.productID}
                        income_value={data.annual_price}
                        premium_value={this.state.premiumAmount}
                        showOverlay={this.state.showOverlay}
                        onClick={this.onRecalculateButtonClick}
                        data={data}
                        isIncomeFieldDisable={true}
                        premiumFreq={this.state.ppf.label}
                    />
                    <Row className='mt-2 mt-md-4'>
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Maturity Age</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Select Mode'
                                    menuList={policyTermList}
                                    value={this.state.pt}
                                    onSelect={this.onPolicyOptions}
                                    disabled={disabled.maturityAge}
                                />
                            </div>
                        </Col>
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Premium Paying Term</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Select Mode'
                                    menuList={premiumTermList}
                                    value={this.state.ppt}
                                    onSelect={this.onPremiumOptions}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-4'>
                        <Col md={6} className='col-12'>
                            <AdditionalCards
                                productID={this.state.productID}
                                cardsList={addBenifits}
                                cardTopText='Additional Benefits'
                                onClick={this.onAdditionalCardOptionSelect}
                                onAmountChange={() => { }}
                                data={data}
                            />
                        </Col>
                        <Col md={6} className='col-12 mt-4 mt-md-0'>
                            <AdditionalRiderBenifitsCard
                                productID={this.state.productID}
                                cardsList={ridersList}
                                cardTopText='Additional Rider Availables'
                                data={data}
                                toggleRider={this.onAdditionalRiderCardOptionSelect}
                                onAmountChange={this.onDropDownValueChanges}
                            />
                        </Col>
                    </Row>
                    <AmountCalculation
                        cardKey={this.props.cardKey}
                        item={data}
                        gst={data.BI.TAX_MP}
                        gst_percentage={Number(data.BI.TAX_RATE * 100)}
                        totalPremium={Number(this.state.reqParams.PR_ANNPREM)}
                        total_price={totalPrice}
                        hideTotalPremium={true}
                        riderList={this.state.ridersList}
                        riderTax={totalAmount.totalRiderTax}
                    />
                </React.Fragment>
            </div>
        );
    }
});

ActiveIncome.defaultProps = {
    disabled: false
}

ActiveIncome.propTypes = {
    addInsured: PropTypes.func,
    data: PropTypes.object,
    disabled: PropTypes.bool
}

export default ActiveIncome;