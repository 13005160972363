
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {

    formRef.$('income-option').observe({
        key: 'value',
        call: ({ field }) => {
            if (Number(field.value.id) === 3) {
                formRef.$('guranteed-income').set('disabled', true)
                formRef.$('guranteed-income').set('value', { id: '5',label: 'Level'})
            } else {
                formRef.$('guranteed-income').set('disabled', false)
            }
            let guranteedIncome = formRef.$('guranteed-income').value.id
            let requestData = {
                incomeOption : field.value.id,
                guranteedIncome : guranteedIncome
            }
            Utils.updateBi(keys.INCOME_OPTION, requestData, props.data.product_id, formRef, props)
        }
    })

    formRef.$('guranteed-income').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.GURANTEED_INCOME, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('payment-frequency').observe({
        key: 'value',
        call: ({ field }) => {
            let premium = formRef.$('premium').value
            let frequency = field.value.id
            let data = {
                premium: premium,
                frequency: frequency
            }
            Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)
        }
    })

    formRef.$('maturity-age').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.MATURITY_AGE, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('premium-term').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PR_PPT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    ////

    formRef.$('ADB').observe({
        key: 'value',
        call: ({ field }) => {
            let adbValue = formRef.$('ADB-dropdown').value
            Utils.updateBi(keys.ADB_RIDER, adbValue, props.data.product_id, formRef, props)
        }
    })

    formRef.$('ATPDRider').observe({
        key: 'value',
        call: ({ field }) => {
            let atpdValue = formRef.$('ATPDRider-dropdown').value
            Utils.updateBi(keys.ATPD_RIDER, atpdValue, props.data.product_id, formRef, props)
        }
    })

    formRef.$('CriticalIllnessRider').observe({
        key: 'value',
        call: ({ field }) => {
            let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
            Utils.updateBi(keys.Critical_ILLNESS_RIDER, criticalIllness, props.data.product_id, formRef, props)
        }
    })

    formRef.$('IncomeBenefitRider').observe({
        key: 'value',
        call: ({ field }) => {
            let incomeBuilder = formRef.$('IncomeBenefitRider-dropdown').value
            Utils.updateBi(keys.INCOME_BENEFIT_RIDER, incomeBuilder, props.data.product_id, formRef, props)
        }
    })

    formRef.$('PayorWaiverBenefitRider-dropdown').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PWB_RIDER, field.value.id, props.data.product_id, formRef, props)
        }
    })

}

export default FormObserver