import React, { useEffect, useState } from "react";
import { Image, Row } from "react-bootstrap";
import { Range } from "rc-slider";
import "./graph-slider.css";

import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { RecalculateIcon } from "constants/Images";
import ProductNames from 'constants/ProductNames'
import convertAmountInWords from "utils/convertAmountInWords";
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import axios from "utils/axios";
import * as APITypes from "constants/API";

/**
 *
 * @param {minSumAssured} props : Slider Minimum value
 * @param {recommendedTargetValue} props : Slider Recommended Value
 * @param {selectedTargetValue} props : User Selected Default Value
 * @param {onChange} props : It will trigger when user change slider value
 * @param {hideOverLay} props : How disabled slider
 * @param {minPremium} props : Min Premium Value
 * @param {maxPremium} props : Max Premium Value
 * @param {recommendedPremium} props : Recommended Premium Value
 * @param {selectedPremium} props : Selected Premium Value
 * @param {showOverlay} props : Showing overlay
 * @param {showPreloader} props : Showing preloader when API is called
 */

const GraphSlider = ({
  minSumAssured,
  recommendedTargetValue,
  selectedTargetValue,
  onChange,
  hideOverLay,
  minPremium,
  maxPremium,
  recommendedPremium,
  selectedPremium,
  showOverlay,
  showPreloader,
  maximumSumAssured,
  biRequest,
  onRecalculate
}) => {
  const [id, setId] = useState(uuidv4);
  const [rangeValue, setRangeValue] = useState([
    minSumAssured,
    2000000000,
    3000000000,
  ]);
  const [rangeSelectedValue, setRangeSelectedValue] = useState([
    minSumAssured,
    2000000000,
    3000000000,
  ]);
  const [userSelectedValue, setUserSelectedValue] =
    useState(selectedTargetValue);
  const [maxValue, setMaxValue] = useState(0);
  const [minValue, setMinValue] = useState(minSumAssured);
  const [isLoading, setIsLoading] = useState(false);
  const [showActionsOverlay, setShowActionsOverlay] = useState(showOverlay);

  /**
   * @function useEffect
   * When initially loaded this component
   */
  useEffect(() => {
    updateLabelWithColor();
    updateMaxValue(true);
  }, []);

  useEffect(() => {
    updateLabelWithColor();
    updateMaxValue();
    setShowActionsOverlay(showOverlay);
  }, [minSumAssured, recommendedTargetValue, showOverlay, selectedTargetValue]);

  /**
   * @function onChange
   * Slider onChange handler
   */
  const onSliderChange = (e) => {
    if (!showPreloader) {
      let userSelected = rangeValue[2] == e[2] ? e[1] : e[2];
      let recommended = rangeValue[2];
      userSelected = userSelected || e[1];
      if (userSelected >= recommended) {
        setRangeSelectedValue([rangeValue[0], userSelected]);
        setUserSelectedValue(userSelected);
      } else {
        setRangeSelectedValue([rangeValue[0], userSelected, recommended]);
        setUserSelectedValue(userSelected);
      }
      // clearTimeout(isLoading);
      // setIsLoading(
      //   setTimeout(() => {
      //     /**
      //      * Prop onChange function trigger here
      //      */
      //     if (typeof onChange == "function") {
      //       onChange(userSelected);
      //     }
      //   }, 1000)
      // );
    }
  };

  const onAfterSliderChange = (e) => {
    let userSelected = rangeValue[2] == e[2] ? e[1] : e[2];
    let recommended = rangeValue[2];
    userSelected = userSelected || e[1];
    if (typeof onChange == "function") {
      onChange(userSelected);
    }
  }

  /**
   * @function updateLabelWithColor
   * Update slider marker color
   */
  const updateLabelWithColor = () => {
    try {
      const mainDiv = document.getElementById(id);
      const selectedLine = mainDiv.querySelectorAll(`span.rc-slider-dot`);
      for (let index = 0; index < selectedLine.length; index++) {
        const element = selectedLine[index];
        element.id = "rv-slider" + index;
        if (index == 0) {
          element.style.borderColor = "var(--graph-grey-700-color)";
          element.style.bottom = "-100px";
          element.style.height = "200px";
        } else if (index == 1 && userSelectedValue <= rangeValue[2]) {
          element.style.borderColor = "var(--graph-orange-color)";
          element.style.bottom = "-100px";
          element.style.height = "200px";
        } else if (index == 1 && userSelectedValue > rangeValue[2]) {
          element.style.borderColor = "var(--graph-light-green-color)";
          element.style.bottom = "-100px";
          element.style.height = "200px";
        } else if (index == 0 && userSelectedValue > rangeValue[2]) {
          element.style.borderColor = "var(--graph-grey-700-color)";
          element.style.bottom = "-100px";
          element.style.height = "200px";
        } else if (
          selectedLine.length == 4 &&
          index == 2 &&
          userSelectedValue < rangeValue[2]
        ) {
          element.style.borderColor = "var(--graph-light-green-color)";
          element.style.bottom = "-100px";
          element.style.height = "200px";
        } else if (
          selectedLine.length == 4 &&
          index == 2 &&
          userSelectedValue > rangeValue[2]
        ) {
          element.style.borderColor = "var(--graph-tomato-color)";
          element.style.bottom = "-100px";
          element.style.height = "200px";
        } else if (
          selectedLine.length == 3 &&
          index == 2 &&
          userSelectedValue > rangeValue[2]
        ) {
          element.style.borderColor = "var(--graph-tomato-color)";
          element.style.bottom = "-100px";
          element.style.height = "200px";
        } else {
          element.style.borderColor = "";
        }
      }
    } catch (error) {}
  };

  /**
   * @function updateMaxValue
   * Calculate Max value of slider
   */
  const updateMaxValue = () => {
    var min = minSumAssured || 0;
    var selectedTargetVal = parseInt(selectedTargetValue);
    var recommendedTargetVal = parseInt(recommendedTargetValue);
    var max = maximumSumAssured
    rangeValue[2] = recommendedTargetVal;
    rangeSelectedValue[2] = recommendedTargetVal;
    rangeValue[1] = selectedTargetVal;
    rangeValue[0] = min;
    rangeSelectedValue[1] = selectedTargetVal;

    setMinValue(parseInt(min));
    setMaxValue(max);
    setRangeValue(rangeValue);
    setRangeSelectedValue(rangeSelectedValue);
    setUserSelectedValue(selectedTargetVal);
  };

  const onRecalculateAction = (event) => {
    event.preventDefault();
    setShowActionsOverlay(false);
    /**
     * Prop onChange function trigger here
     */
    // if (typeof onChange == "function") {
    //   onChange(null);
    // }

    if(typeof onRecalculate == "function" ){
      onRecalculate()
    }
  };

  const hideActionsOverlay = () => {
    setShowActionsOverlay(false);
    hideOverLay();
  };

  const markHtml = (saVal, premiumVal, topMarkColor, bottomMarkColor) => {
    updateLabelWithColor();
    return (
      <>
        <span className="mark-label-SA" style={{ color: topMarkColor, fontSize: "10px" }}>
          {global.toINRForSlider(saVal)}
        </span>
        <span className="mark-label-premium" style={{ color: bottomMarkColor, fontSize: "10px" }}>
          {premiumVal ? global.toINRForSlider(premiumVal) : 0}
        </span>
      </>
    );
  };

  let recommendedVal = rangeValue[2];
  let markObj = {};
  const max = maxValue;
  const min = minValue;
  markObj[recommendedVal] = {
    label: markHtml(recommendedVal, recommendedPremium, "#000000", "green"),
  };
  markObj[selectedTargetValue] = {
    label: markHtml(selectedTargetValue, selectedPremium, "green", "#134093"),
  };
  markObj[min] = {
    label: markHtml(min, minPremium),
  };
  markObj[max] = {
    label: markHtml(max, maxPremium),
  };

  return (
    <React.Fragment>
      <div
        id={id}
        className="graph-slider d-flex align-items-center flex-column justify-content-between position-relative my-2 px-2 px-md-3"
      >
        <div className={`overlay ${!showPreloader && "d-none"}`}>
          <div
            className={`recalculate-overlay ${!showPreloader && "d-none"
              } font-size-md-24 font-size-18 font-weight-bold font-weight-medium`}
            onClick={hideOverLay}
          >
            {/* Updating <Image className='pl-2' src={RecalculateIcon} alt='share-icon' /> */}
          </div>
        </div>
        <div className={`overlay ${!showActionsOverlay && "d-none"}`}>
          <div
            className={`recalculate-overlay ${!showActionsOverlay && "d-none"
              } font-size-md-24 font-size-18 font-weight-bold font-weight-medium`}
          >
            <div className="">
              <Row className="mt-3 pt-2 mb-3">
                <div className="col col-md-6 text-center mb-2 mb-md-0">
                  <button
                    className="btn btn- btn-lg modal-cta-btn modal-cta-btn-link py-1 font-size-18"
                    style={{
                      width: "137px",
                      height: "40px",
                      backgroundColor: "#aaaaaa",
                    }}
                    onClick={hideActionsOverlay}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col col-md-6 text-center" onClick={onRecalculateAction}>
                  <button
                    className="btn btn-secondary btn-xl modal-cta-btn py-1 font-size-18"
                    style={{ width: "137px", height: "40px" }}
                  >
                    <Image
                      className="pl-2 mb-1"
                      src={RecalculateIcon}
                      alt="share-icon"
                    />{" "}
                    Recalculate
                  </button>
                </div>
              </Row>
            </div>
          </div>
        </div>
        <div className="range position-relative w-100">
          <Range
            count={2}
            step={1}
            max={max}
            min={min}
            marks={markObj}
            pushable={false}
            value={rangeSelectedValue}
            trackStyle={[
              { backgroundColor: "var(--graph-orange-color)", height: 10 },
              { backgroundColor: "#A8C381", height: 10 },
            ]}
            railStyle={{
              backgroundColor: "var(--graph-grey-300-color)",
              height: 10,
              border: "2px solid var(--graph-grey-800-color)",
              left: "1px",
              width: "calc(100% - 2px)",
            }}
            onChange={onSliderChange}
            onAfterChange={onAfterSliderChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

GraphSlider.defaultProps = {
  minSumAssured: 5000,
  recommendedTargetValue: 30000,
  selectedTargetValue: 15000,
  minPremium: 4000,
  maxPremium: 8000,
  recommendedPremium: 5000,
  selectedPremium: 4500,
};

GraphSlider.propTypes = {
  minSumAssured: PropTypes.number,
  recommendedTargetValue: PropTypes.number,
  selectedTargetValue: PropTypes.number,
  onChange: PropTypes.func,
  hideOverLay: PropTypes.func,
  minPremium: PropTypes.number,
  maxPremium: PropTypes.number,
  recommendedPremium: PropTypes.number,
  selectedPremium: PropTypes.number,
};

export default GraphSlider;
