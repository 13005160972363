import React, { Component } from 'react';

import { Modal, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import moment from 'moment';
import { Button as UiButton, BottomsheetDropdown, DatepickerModal, DropdownGender } from '../../../../../components';

import FamilyProtectionStore from '../../../store/FamilyProtectionStore'
import FamilyDetailsStore from '../../../../family-details-form/store/FamilyDetailsStore'

const SpouseDetailsModal = observer(class SpouseDetailsModal extends Component {
    submitDetails = () => {
        const { name, monthlyIncome, dob, gender } = this.state;

        if (Object.keys(monthlyIncome).length == 0 || !dob || Object.keys(gender).length == 0) {
            this.setState({ validation: true })
            return
        }
        this.setState({ validation: false })
        var years = moment().diff(moment(dob).format('YYYY-MM-DD'), 'years', false)
        const data = {
            'SpouseGender': gender == 'male' ? '134' : '135',
            'SpouseName': name,
            'SpouseDOB': years,
            'MaritalStatus': '10'
        }
        FamilyProtectionStore.dataChange('maritalStatus', data, '', this.state.productID)

        this.props.onHideModal()
    }

    onHideModal = () => {
        this.props.onHideModal()
    }

    render() {
        const { show } = this.props;
        return (
            <Modal
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                backdrop="static"
                className='error-modal'
                show={show}
                onHide={this.onHideModal}
                keyboard={false}
            >
                <Modal.Body className='px-4 pt-4 pb-0'>
                    <React.Fragment>
                        <div>
                            {/* <div className="text-right">
                                <UiButton onClick={this.onHideModal} className='transparent font-size-medium font-size-16 px-4 mb-2 mb-md-0' variant='light' buttonText='X' />
                            </div> */}
                            <p className="text-center font-weight-medium font-size-22 text-primary-title-label-color">Please Add Spouse details</p>
                        </div>
                    </React.Fragment>
                </Modal.Body>
                <Modal.Footer className='d-block p-md-4 border-0'>
                    <Row className=''>
                        <Col xs='12' md='12' className="text-center">
                            <UiButton onClick={this.onHideModal} className='font-size-medium font-size-16 px-4 mb-2 mb-md-0' variant='secondary' buttonText='Ok' />
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
});


SpouseDetailsModal.defaultProps = {
    onHideModal: () => { }
};

export default SpouseDetailsModal;
