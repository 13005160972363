// Package Imports
const { Form } = require("mobx-react-form");
import vjf from 'mobx-react-form/lib/validators/VJF';
import validator from 'validator';

// Project Imports
import setInitialDefaults from './initialDefaults';

class AddInsuredFormController extends Form {
  plugins() {
    return {
      vjf: vjf(validator)
    }
  }

  setup() {
    return {
      fields: [
        {
          name: "journey_id",
          value: "",
        },
        {
          name: "members",
          fields: [],
        },
      ],
    };
  }

  setDefaults(journeyId, familyMemberData) {
    // Set forms initial default value
    setInitialDefaults(this, journeyId, familyMemberData)
  }

}

export default AddInsuredFormController