// project imports
import OptimizationStore from "modules/optimization/store/OptimizationStore"
import Validator from "./validator"
import * as keys from "./keys"
class Utils {



 // ! generate PT
  generatePT = () => {
  let pt = [];
  for (let i = 5; i <= 40; i++) {
   pt.push({
    id: String(i),
    label: String(i)
  });
  }
  return pt;
};

 // ! generate PPT
 generatePPT = (PT_VALUE) => {

   let pr_ppt = [
    { id: "1", label: "1" },
    { id: "5", label: "5" },
    { id: "10", label: "10" }
  ];

  if (Number(PT_VALUE) < 10) {
    pr_ppt.pop();
  }

  if (Number(PT_VALUE) > 10) {
    pr_ppt.push({ id: String(PT_VALUE), label: String(PT_VALUE)});
  }

  return pr_ppt;
  
};

// ! generate PPT
// generatePPF = (pptValue) => {
//   let pf = [];

//   let ppfList = ["Annual", "Semi-Annual", "Monthly", "Single"];

//   if(pptValue && pptValue !== '1'){
//     ppfList = ["Annual", "Semi-Annual", "Monthly"];
//   }

//   for (let i = 0; i < ppfList.length; i++) {
//     pf.push({ id: i + 1, label: ppfList[i] });
//   }

//   return pf;
// };

generatePPF = (pptValue) => {

  let pf = [{id: "1",label: "Annual"},{id: "2",label: "Semi-Annual"},{id: "4",label: "Monthly"},{id: "5",label: "Single"}];

  if(pptValue && pptValue !== '1'){
    pf = [{id: "1",label: "Annual"},{id: "2",label: "Semi-Annual"},{id: "4",label: "Monthly"}];
  }

  return pf;
};


// ! get total price
getTotalPrice = (biResponse) => {
 try {
   let totalPremium = 0;
   totalPremium += Number(biResponse.TOTAL_PREM_TAX)
   return totalPremium
 } catch (e) {
   return 0;
 }
}
  

  // ! update BI data
  updateBi = async (key, value, productId, form, productData) => {
    let biData = OptimizationStore.fetchBiRequest(productId, productData?.data?.family_product_id)
    let biRequest = biData.data.bi_request

    switch (key) {

      case keys.FREQUENCY:
        biRequest[keys.FREQUENCY] = value.frequency
        break

      case keys.PR_PT:
      biRequest[keys.PR_PT] = value
      break

      case keys.PR_PPT:
      biRequest[keys.PR_PPT] = value.ppt
      biRequest[keys.FREQUENCY] = value.frequency
      break

      case keys.ZP_OCCUPATION:
       biRequest[keys.ZP_OCCUPATION] = value
       break

      case keys.ZP_SECONDARY_OCCUPATION:
       biRequest[keys.ZP_SECONDARY_OCCUPATION] = value
       break

      case keys.ZP_EDUCATION:
       biRequest[keys.ZP_EDUCATION] = value
       break
     
      case keys.ZP_ANNINCOME:
       biRequest[keys.ZP_ANNINCOME] = value
       break
       
       case keys.PR_SA:
        biRequest[keys.PR_SA] = value
        break 

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value
        break

      case keys.INCOME:
        biRequest[keys.INCOME] = value
        break
      default:
        break
    }

    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)

    // let errorMessage = Validator.valid(form, biData);
    // if (errorMessage.length > 0) {
    //   return
    // }

    // let response = await OptimizationStore.updateStoreRequestData(
    //   productId,
    //   biRequest,
    //   key,
    //   value
    // )
    // return response
  }

  fetchPolicyTimeline(element,inputString, frequency, payoutMode) {

    const inputMode = frequency.Value

    let youPay = 0

    switch (inputMode) {
      case "1":
        youPay = Math.round(element.MODAL_PREM * 1)
      break;

      case "2":
        youPay = Math.round(element.MODAL_PREM * 2)
      break;

      case "3":
        youPay = Math.round(element.MODAL_PREM * 4)
      break;

      case "4":
        youPay = Math.round(element.MODAL_PREM * 12)
      break;
    
      default:
        break;
    }


    let data = {
      youPay: Math.round(youPay),
      youGet: 0,
      lifeCover: Math.round(element.SA),
    };

    return data;
  }
}

export default new Utils()
