// React Imports
import React from "react";

// Project Imports
import { Form } from "react-bootstrap";
import { observer } from "mobx-react";

// Package Imports
import { BottomsheetDropdown, DatepickerModal } from "components";
import { CalenderIcon, IncomeIcon } from "constants/Images";
import checkIsValueEmpty from "utils/checkIsValueEmpty";

import { NUMBER_REGEX } from 'constants/Constants'

// CSS Imports
import "./add-family-member-card.css";


const AddFamilyMemberData = ({ member, updateValues, onAddNameClick,memberCount,formController }) => {

    let memberfieldData = member[1].fields

    let isFirstNameEntered = checkIsValueEmpty(memberfieldData.first_name.value)
    let isLastNameEntered = checkIsValueEmpty(memberfieldData.middle_name.value)
    let isMiddleNameEntered = checkIsValueEmpty(memberfieldData.last_name.value)

    let isNameEntered = false
    let name = ""

    if (!isFirstNameEntered || !isLastNameEntered || !isMiddleNameEntered) {
        isNameEntered = true
        name = memberCount +"." + " " + memberfieldData.salutation.value.label + " " + memberfieldData.first_name.value + " " + memberfieldData.middle_name.value + " " + memberfieldData.last_name.value
    }

    const showmonthlyIncome = memberfieldData.monthly_income_value.extra.visible

    
    return (
        <div className='px-3 px-xl-2 border-bottom mt-3 pb-3'>
            <div className='mt-2 d-flex flex-wrap'>
                <div className='family-member-name-section d-lg-flex flex-sm-column flex-lg-row justify-content-between'>
                    {/* <p
                        className='mt-1 mb-0 font-size-lg-12 font-size-14 font-weight-medium font-weight-lg-light text-secondary'
                    >
                        {
                            isNameEntered
                                ? name
                                : [member[1].label]
                        }
                        {
                            isNameEntered
                                ? null
                                : <span onClick={onAddNameClick}
                                    className="font-size-14 font-size-lg-12 font-weight-light text-description-text-color pl-1 cursor-pointer"
                                >
                                    {'TAP TO ADD NAME'}
                                </span>
                        }

                    </p> */}        

                                        <div className="mr-lg-2 mb-2 mb-lg-0">
                                            <div className="d-flex align-middle">

                                        <p className='mt-1 mb-0 mr-2 font-size-lg-12 font-size-14 font-weight-medium font-weight-lg-light text-secondary'>
                                        {memberCount}
                                        </p>

                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle={memberfieldData.salutation.extra.bottomSheetTitle}
                                            defaultLabel=''
                                            menuList={memberfieldData.salutation.extra.optionList}
                                            onSelect={(value) => updateValues(formController,member[0], "salutation", value)}
                                            value={memberfieldData.salutation.value}
                                            showErrorMsg={memberfieldData.salutation.error !== null}
                                            errorMsg={memberfieldData.salutation.error}
                                            />
                                            </div>
                                        </div>


                                        <div className="mb-2 mb-lg-0">
                                        <Form.Control
                                            placeholder={memberfieldData.first_name.placeholder}
                                            value={memberfieldData.first_name.value}
                                            onChange={(event) => updateValues(formController,member[0],"first_name", event.target.value)}
                                            disabled={memberfieldData.first_name.disabled}
                                            className={`${memberfieldData.first_name.error !== null? "validation-border font-weight-light font-size-12": "font-size-12 font-weight-light "} calculator-monthly-income-field`}
                                            style={{ border: "1px solid #797979" }}
                                        />
                                        {memberfieldData.first_name.error && (
                                            <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>
                                                {memberfieldData.first_name.error}
                                            </p>
                                        )}
                                        </div>

                                        <div className="mx-lg-2 mb-2 mb-lg-0">
                                        <Form.Control
                                            placeholder={memberfieldData.middle_name.placeholder}
                                            value={memberfieldData.middle_name.value}
                                            onChange={(event) => updateValues(formController,member[0],"middle_name", event.target.value)}
                                            disabled={memberfieldData.middle_name.disabled}
                                            className={`${memberfieldData.middle_name.error !== null? "validation-border font-weight-light font-size-12": "font-size-12 font-weight-light "} calculator-monthly-income-field`}
                                            style={{ border: "1px solid #797979" }}
                                        />
                                        {memberfieldData.middle_name.error && (
                                            <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>
                                                {memberfieldData.middle_name.error}
                                            </p>
                                        )}
                                        </div>

                                        <div className="mr-lg-2 mb-2 mb-lg-0">
                                        <Form.Control
                                            placeholder={memberfieldData.last_name.placeholder}
                                            value={memberfieldData.last_name.value}
                                            onChange={(event) => updateValues(formController,member[0],"last_name", event.target.value)}
                                            disabled={memberfieldData.last_name.disabled}
                                            className={`${memberfieldData.last_name.error !== null? "validation-border font-weight-light font-size-12": "font-size-12 font-weight-light "} calculator-monthly-income-field`}
                                            style={{ border: "1px solid #797979" }}
                                        />
                                        {memberfieldData.last_name.error && (
                                            <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>
                                                {memberfieldData.last_name.error}
                                            </p>
                                        )}
                                        </div>
                                    


                    <div className='other-details-section-gender d-flex flex-xs-column flex-lg-row'>
                        <img src={CalenderIcon} alt='calender-icon' className='add-family-member-card-top-img d-inline d-xl-none mr-1 mb-sm-1 mb-lg-0' />

                        <BottomsheetDropdown
                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemStyle='d-flex justify-content-between align-items-center'
                            selectedLabelKey='label'
                            bottomsheetTitle={memberfieldData.gender.extra.bottomSheetTitle}
                            defaultLabel='Select'
                            disabled={memberfieldData.gender.disabled}
                            menuList={memberfieldData.gender.extra.optionList}
                            onSelect={(value) => updateValues(formController,member[0], 'gender', value)}
                            value={memberfieldData.gender.value}
                            showErrorMsg={memberfieldData.gender.error !== null}
                            errorMsg={memberfieldData.gender.error}
                        />
                    </div>
                </div>
                <hr className='w-100 border-secondary-opacity-50 my-2 d-xl-none' />
                <div className='family-member-other-details-section justify-content-between'>
                    <div
                        className={
                            `${memberfieldData.gender.error !== null
                                ? "mt-3 mt-xl-0 other-details-section-dob pr-xl-2"
                                : "mt-3 mt-xl-0 other-details-section-dob pr-xl-2"
                            }`}
                    >
                        <div className='d-flex align-items-center d-xl-none mb-1 mb-lg-2'>
                            <img src={CalenderIcon} alt='calender-icon' className='add-family-member-card-top-img' />
                            <p className='font-size-14 font-size-md-12 font-weight-light text-description-text-color ml-1 mb-0 ml-md-3'>
                                {memberfieldData.dob.label}
                            </p>
                        </div>
                        <DatepickerModal
                            minDate={memberfieldData.dob.extra.minDate}
                            maxDate={memberfieldData.dob.extra.maxDate}
                            value={memberfieldData.dob.value}
                            date={memberfieldData.dob.value}
                            onChange={(value) => updateValues(formController,member[0], 'dob', value)}
                            showErrorMsg={memberfieldData.dob.error !== null}
                            textStyle='font-size-lg-12 font-size-14 font-weight-light'
                            errorMsg={memberfieldData.dob.error}
                        />
                    </div>
                    <div className='mt-3 mt-xl-0 other-details-section-nationality pr-xl-2'>
                        <div className='d-flex d-xl-none align-items-center  mb-1'>
                            <img src={IncomeIcon} alt='income-icon' className='add-family-member-card-top-img' />
                            <p className='font-size-14 font-size-md-12 font-weight-normal text-description-text-color ml-1 mb-0 ml-md-3'>
                                {memberfieldData.nationality.label}
                            </p>
                        </div>

                        <BottomsheetDropdown
                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemStyle='d-flex justify-content-between align-items-center'
                            selectedLabelKey='label'
                            bottomsheetTitle={memberfieldData.nationality.extra.bottomSheetTitle}
                            defaultLabel={memberfieldData.nationality.label}
                            menuList={memberfieldData.nationality.extra.optionList}
                            value={memberfieldData.nationality.value}
                            onSelect={(value) => updateValues(formController,member[0], 'nationality', value)}
                            showErrorMsg={memberfieldData.nationality.error != null}
                            disabled={false}
                        />
                    </div>
                    <div className={`mt-3 mt-xl-0 other-details-section-monthlyincome pr-xl-2
                     ${showmonthlyIncome
                            ? "visible"
                            : "invisible"
                        }
                    `}>
                        <div className='d-flex d-xl-none align-items-center mb-1'>
                            <img src={IncomeIcon} alt='income-icon' className='add-family-member-card-top-img' />
                            <p className='font-size-14 font-size-md-12 font-weight-normal text-description-text-color ml-1 mb-0 ml-md-3'>
                                {memberfieldData.monthly_income_value.label}
                            </p>
                        </div>
                        {
                            memberfieldData.monthly_income_value.type === 'text'
                                ? <>
                                    <Form.Control
                                        placeholder={memberfieldData.monthly_income_value.placeholder}
                                        value={memberfieldData.monthly_income_value.value}
                                        onChange={(event) => {
                                            
                                            if(NUMBER_REGEX.test(event.target.value)){
                                                return updateValues(formController,member[0], 'monthly_income_value', event.target.value)
                                              }
                                              if(event.target.value.length <= 1){
                                                return updateValues(formController,member[0], 'monthly_income_value', '')
                                              }
                                        }}
                                        disabled={memberfieldData.monthly_income_value.disabled}
                                        className={
                                            `${memberfieldData.monthly_income_value.error !== null
                                                ? "validation-border font-weight-light font-size-12"
                                                : "font-size-12 font-weight-light "
                                            } calculator-monthly-income-field`}
                                        style={{ border: "1px solid #797979" }}
                                    />
                                    {memberfieldData.monthly_income_value.error && (
                                        <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>
                                            {memberfieldData.monthly_income_value.error}
                                        </p>
                                    )}
                                </>
                                : <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle={memberfieldData.monthly_income_dropdown_option.extra.bottomSheetTitle}
                                    defaultLabel="Select"
                                    menuList={memberfieldData.monthly_income_dropdown_option.extra.optionList}
                                    value={memberfieldData.monthly_income_dropdown_option.value}
                                    onSelect={(value) => updateValues(formController,member[0], 'monthly_income_dropdown_option', value)}
                                    showErrorMsg={memberfieldData.monthly_income_dropdown_option.error != null}
                                    errorMsg={memberfieldData.monthly_income_dropdown_option.error}
                                    disabled={memberfieldData.monthly_income_dropdown_option.disabled}
                                />
                        }
                        <div>

                        </div>
                    </div>
                    <div className='mt-3 mt-xl-0 other-details-section-current-life-cover'>
                        <div className='d-flex d-xl-none align-items-center mb-1'>
                            <img src={IncomeIcon} alt='income-icon' className='add-family-member-card-top-img' />
                            <p className='font-size-14 font-size-md-12 font-weight-light text-description-text-color ml-1 mb-0 ml-md-3'>
                                {memberfieldData.current_life_cover.label}
                            </p>
                        </div>
                        <BottomsheetDropdown
                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemStyle='d-flex justify-content-between align-items-center'
                            selectedLabelKey='label'
                            bottomsheetTitle={memberfieldData.current_life_cover.extra.bottomSheetTitle}
                            menuList={memberfieldData.current_life_cover.extra.optionList}
                            value={memberfieldData.current_life_cover.value}
                            onSelect={(value) => updateValues(formController,member[0], 'current_life_cover', value)}
                            showErrorMsg={memberfieldData.current_life_cover.error !== null}
                            disabled={memberfieldData.current_life_cover.disabled}
                            errorMsg={memberfieldData.current_life_cover.error}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default observer(AddFamilyMemberData)