import React, { Component } from 'react'
import {
  CalculatorButton,
  Button as UiButton,
  NextButton,
  CustomCheckbox,
  BottomsheetDropdown,
  AdditionalCards,
  DropdownGender,
  DatePicker,
  DeathCoverPriceComponent,
  AddInsuredModal,
  CircularProgressbar,
  FloatingInputComponent
} from '../../components';
import { Col, Row, Tab, Tabs, Button } from 'react-bootstrap';

import ProtectionOfMyFamily from '../choose-needs/components/protection-of-my-family/ProtectionOfMyFamily';
import ProtectionOfMyHealth from '../choose-needs/components/protection-of-my-health/ProtectionOfMyHealth';
import GrowMyMoney from '../choose-needs/components/grow-my-money/GrowMyMoney';
import SaveForMyRetirement from '../choose-needs/components/save-for-my-retirement/SaveForMyRetirement';
import SaveForChildren from '../choose-needs/components/save-for-children/SaveForChildren';

import CurrentCoverageCard from '../solution/components/current-coverage-card/CurrentCoverageCard';
import FamilyProtectionCoverageCard from '../solution/components/family-protection-coverage-card/FamilyProtectionCoverageCard';

import './styleguide.css'
import SolutionBenefitsCard from '../solution/components/solution-benefits-card/SolutionBenefitsCard';
import PolicyTimelineTable from '../solution/components/policy-timeline-table/PolicyTimelineTable';
import ChangeProductReasonModal from '../family-protection/components/change-product-reason-modal/ChangeProductReasonModal';
import ChangeProductModal from '../family-protection/components/change-product-modal/ChangeProductModal';
class Styleguide extends Component {
  constructor(props) {
    super(props)

    this.state = {
      bottomsheetlist: [
        { id: 1, name: 'test', label: '50 lacs to 75 lacs', isSelected: false },
        { id: 2, name: 'test2', label: '50 lacs to 75 lacs lacs', isSelected: false },
        { id: 3, name: 'test3', label: '50 lacs to 75 lacs', isSelected: false },
        { id: 4, name: 'test4', label: '76 lacs to 85 lacs', isSelected: false }
      ],
      selectedOptionValue: 'Select',
      additionalCardsList: [
        {
          id: 1,
          title: 'Better Half Benefit',
          subText: 'Provides your spouse an additional live cover',
          amount: '630'
        },
        {
          id: 2,
          title: 'Critical Illness Waiver',
          subText: 'Lumpsum payout in case of loss of earning due to illness.',
          amount: ''
        }
      ],
      selectedAdditionalCardValue: {},
      additionalRiderCardsList: [
        {
          id: 1,
          title: 'Accidental death benefit',
          subText: 'Provides your spouse an additional live cover',
          amount: ''
        },
        {
          id: 2,
          title: 'Accidental total/perm.disability',
          subText: 'Lumpsum payout in case of loss of earning due to illness.',
          amount: ''
        }
      ],
      selectedAdditionalRiderCardValue: {},
      selectedOptionValue: '',
      selectedGender: { id: 1, label: 'M', genderValue: 'Male' },

      // protectionForMyFamily
      protectionForMyFamily: [{}],
      addProtectionForMyFamily: false,
      isProtectionForMyFamilySelect: false,
      addInsuredAvailMemberList: [
        { id: 1, relation:'Self', name: 'Ravi', age: '28', gender: 'Male' },
        { id: 2, relation:'Wife', name: 'Rajni', age: '26', gender: 'Female' },
        { id: 3, relation:'Child', name: 'Vijay', age: '3', gender: 'Other' },
        { id: 4, relation:'Father', name: 'Suryanarayan', age: '68', gender: 'Male' },
        { id: 5, relation:'Mother', name: 'Laxmibai', age: '62', gender: 'Female' }
      ],
      familyProtectionList: [
        {relation:'Self',
         name:'Ramu',
         protectionValues:[{ title:'Family Protection', percentValue: '40', ammount: '5,000' },
          { title:'Child Future', percentValue: '75', ammount: '36,000' },
          { title:'Wealth Creation', percentValue: '70', ammount: '12,000' }
          ]
        },
        {relation:'Spouse',
          name:'Sham',
          protectionValues:[{ title:'Family Protection', percentValue: '50', ammount: '4,000' },
          { title:'Child Future', percentValue: '60', ammount: '20,000' },
          { title:'Wealth Creation', percentValue: '65', ammount: '11,000' }]
        }
      ],
      solutionBenifitsList: [
        { cardType:'death_cover'},
        { cardType:'additional_death_cover'},
        { cardType:'premium_waiver'},
        { cardType:'tax_saving'},
        { cardType:'early_return'},
        { cardType:'flexible_payout'}
        // { cardType:'flex'}
      ],
      policyTimelineList: [
        { year:'2020', you_pay: '53,300', you_get: '53,000', life_cover: '2,40,00,000'},
        { year:'2020', you_pay: '53,300', you_get: '53,000', life_cover: '2,40,00,000'},
        { year:'2020', you_pay: '53,300', you_get: '53,000', life_cover: '2,40,00,000'},
        { year:'2020', you_pay: '53,300', you_get: '53,000', life_cover: '2,40,00,000'},
        { year:'2020', you_pay: '53,300', you_get: '53,000', life_cover: '2,40,00,000'},
        { year:'2020', you_pay: '53,300', you_get: '53,000', life_cover: '2,40,00,000'},
        { year:'2020', you_pay: '53,300', you_get: '53,000', life_cover: '2,40,00,000'},
        { year:'2020', you_pay: '53,300', you_get: '53,000', life_cover: '2,40,00,000'},
        { year:'2020', you_pay: '53,300', you_get: '53,000', life_cover: '2,40,00,000'}
      ],
      productReasonList: [
        { reason:'Recommended product is not fitting the need.', value: 'noNeed'},
        { reason:'There is a better product that can be offered.', value: 'anotherBetterProduct'},
        { reason:'System is recommending a wrong product.', value: 'recommendWrongProduct'},
        { reason:'Other reason.', value: 'otherReason'}
      ],
      productList: [
        { title:'Product 1'},
        { title:'Product 2'},
        { title:'Product 3'},
        { title:'Product 4'},
        { title:'Product 5'},
        { title:'Product 6'},
        { title:'Product 7'},
        { title:'Product 8'},
        { title:'Product 9'}
      ],
      isProtectionForMyFamilySelect: false,
      changeProductReasonModal: false,
      changeProductModal: false,
      selectdReason:{}
    }
    this.openCalulator = this.openCalulator.bind(this);
    this.clickNext = this.clickNext.bind(this);
    this.onDropDownOptionClick = this.onDropDownOptionClick.bind(this);
  }

  openCalulator() {
    console.log('open Calculator');
  }
  genderOnChange = (value) => {
    console.log('GENDER SELECTION >>', value);
    this.setState({ selectedGender: value })
  }

  // single option
  onAdditionalRiderCardOptionSelect = (checkedValue, selectedObj) => {
    const selectedOptionObj = this.state.selectedAdditionalRiderCardValue;

    selectedOptionObj[selectedObj.id] = checkedValue;

    Object.keys(selectedOptionObj).map((val, index) => {
      if (val == selectedObj.id) {
        selectedOptionObj[val] = checkedValue;
      } else {
        // selectedOptionObj[val] = false;
        delete selectedOptionObj[val];
      }
    });

    this.setState({
      selectedAdditionalRiderCardValue: selectedOptionObj
    });
  }

  // multiselect option
  onAdditionalCardOptionSelect = (checkedValue, selectedObj) => {
    const selectedOptionObj = this.state.selectedAdditionalCardValue;

    selectedOptionObj[selectedObj.id] = checkedValue;

    this.setState({
      selectedAdditionalCardValue: selectedOptionObj
    });
  }

  onDropDownOptionClick(value) {
    const list = this.state.bottomsheetlist.map(val => {
      if (value.id == val.id) {
        return Object.assign({}, val, { isSelected: true });
      } else {
        return Object.assign({}, val, { isSelected: false });
      }
    });
    this.setState({ bottomsheetlist: list, selectedOptionValue: value })
  }

  clickNext() {
    console.log('click Next');
  }

  onDatePickerChange = (date) => {
    console.log('SELECTED DATE >> ', date);
  }

  onChangeProductReasonModalOnShow = () => {
    this.setState({ changeProductReasonModal: true })
  }

  onChangeProductReasonModalOnHide = () => {
    this.setState({ changeProductReasonModal: false })
  }
  onChangeProductModalOnShow = () => {
    this.setState({ changeProductModal: true })
  }

  onChangeProductModalOnHide = () => {
    this.setState({ changeProductModal: false })
  }
  onChangeReason = (e) => {
    this.setState({selectdReason: e});
  }
  render() {
    const {solutionBenifitsList} = this.state;
    console.log('ENV >> ', process.env)
    return (
      <div className='p-3 p-md-5'>
        <Row className='mb-5'>
          <Col className='col-12'>
            <FloatingInputComponent
              // value={this.state.userId}
              // onChange={this.updateUserID}
              toplabeltext='Policy No. / Email / Mobile No.'
              inputtype='text'
              id='userId'
              textValue=''
              // errormessage={this.state.userIdErrorMsg}
              // isdisable={this.state.mobileFieldDisable.toString()}
            />
          </Col>
          <Col xs='12' className='mt-3'>
            <Button variant='light' onClick={this.onChangeProductReasonModalOnShow} role='button' className='title-button px-3 py-1 font-size-11 font-size-md-16 font-weight-medium'>[Reason]</Button>
            <ChangeProductReasonModal selectedReasonVal={this.state.selectdReason} show={this.state.changeProductReasonModal} onSelectReason={this.onChangeReason} onHide={this.onChangeProductReasonModalOnHide} list={this.state.productReasonList} />
            
          </Col>
          <Col xs='12' className='mt-3'>
            <Button variant='light' onClick={this.onChangeProductModalOnShow} role='button' className='title-button px-3 py-1 font-size-11 font-size-md-16 font-weight-medium'>[Change Product]</Button>
            <ChangeProductModal show={this.state.changeProductModal} onSelectReason={() => {}} onHide={this.onChangeProductModalOnHide} list={this.state.productList} />

            
          </Col>
          <Col xs='12' className='mt-3'>
            <AddInsuredModal show={false} onAddMember={()=> {}} onAddInsured={()=> {}} list={this.state.addInsuredAvailMemberList} />
          </Col>
          <Col className='col-12 mt-5'>
            <CircularProgressbar
              value={50}
              valuelabel='Covered'
              isSingleImg={true}
            />
          </Col>
          <Col className='col-4 mt-5'>
            <CircularProgressbar
              value={50}
              valuelabel='Covered'
              isSingleImg={false}
            />
          </Col>
          <Col className='col-4 mt-5'>
            <CircularProgressbar
              value={50}
              valuelabel='Covered'
              isSingleImg={false}
            />
          </Col>
          <Col className='col-4 mt-5'>
            <CircularProgressbar
              value={50}
              valuelabel='Covered'
              isSingleImg={false}
            />
          </Col>
          <Col xs='12' className='mt-3'>
            <CurrentCoverageCard ammount='62,617' percentValue='75' />
          </Col>
          <Col xs='12' className='mt-3'>
            <div className='shadow rounded bg-white'>
              <FamilyProtectionCoverageCard list={this.state.familyProtectionList} />
            </div>
          </Col>
          <Col xs='12' md='6' className='mt-3 d-flex'>
            <div className='shadow bg-white w-100 rounded-sm px-2 px-md-3 pt-3 pt-md-4 pb-md-1'>
              {solutionBenifitsList.map((value, index) => {
                return (
                  <SolutionBenefitsCard key={ index } cardType={ value.cardType } />
                )
              })}
            </div>
          </Col>
          <Col xs='12' className='mt-3'>
            <PolicyTimelineTable list={this.state.policyTimelineList}/>
          </Col>
          <Col xs='12' className='mt-3'>
            <Tabs defaultActiveKey='home' transition={false} id='noanim-tab-example'>
              <Tab eventKey='home' title='Home'>
                <div>home screen</div>
              </Tab>
              <Tab eventKey='profile' title='Profile'>
                <div>profile screen</div>
              </Tab>
            </Tabs>
          </Col>
        </Row>
        <Row className='mb-5'>
          <Col>
            <DatePicker date={'Fri Feb 15 2021 00:00:00 GMT+0530'} onChange={this.onDatePickerChange} />
          </Col>
        </Row>
        <CalculatorButton onClick={this.openCalulator} />
        <hr />
        <CustomCheckbox id='protectionMyFamily' shape='rounded' title='Protection for my family' titleStyle='text-primary font-weight-medium font-size-18 font-size-md-20 pl-2' />
        <CustomCheckbox id='growMyMoney' title='Grow my money' titleStyle='text-black font-weight-normal font-size-14 font-size-md-16 pl-2' />
        <div className='my-4'>
          <h3>Cards</h3>
          <div className='row'>
            <div className='col-12 col-md-5 mt-3 mt-md-0'>
              <ProtectionOfMyFamily value={true} needCover={{}} onChange={() => {}} />
            </div>
            <div className='col-12 col-md-5 mt-3 mt-md-0'>
              <ProtectionOfMyHealth value={true} needCover={{}} onChange={() => {}}  />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-5 mt-3'>
              <GrowMyMoney value={true} needCover={{}} amountOf={{}} onChange={() => {}} />
            </div>
            <div className='col-12 col-md-5 mt-3'>
              <SaveForChildren value={true} needCover={{}} amountOf={{}} onChange={() => {}} />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-5 mt-3'>
              <SaveForMyRetirement value={true} needCover={{}} onChange={() => {}} />
            </div>
          </div>
          <h3 className='mt-3'>Buttons</h3>
          <Row>
            <Col className='mb-3'>
              <NextButton onClick={this.clickNext} />
            </Col>
          </Row>
          <Row>
            <Col md='6'>
              <Row className='underlying-btns-row'>
                <Col xs='4'>
                  <UiButton className='font-size-14 font-size-md-16 px-1 w-100' variant='outline-secondary' buttonText='Simply protect' />
                </Col>
                <Col xs='4'>
                  <UiButton className='font-size-14 font-size-md-16 px-1 w-100' variant='primary' buttonText='Prospectus' />
                </Col>
                <Col xs='4'>
                  <UiButton className='font-size-14 font-size-md-16 px-1 w-100' variant='secondary' buttonText='View B.I' />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col xs='5' lg='3' xl='2'>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey='label'
                bottomsheetTitle='Select Plans'
                menuList={this.state.bottomsheetlist}
                onSelect={this.onDropDownOptionClick}
                value={this.state.selectedOptionValue} />
            </Col>
          </Row>
          <Row className='mt-2'>
            <Col xs='5' lg='3' xl='2'>
              <DropdownGender
                selectedGender={this.state.selectedGender}
                onChange={this.genderOnChange} dropdownStyle='font-size-12 font-size-md-16 px-2 py-1 font-weight-medium text-primary-title-label-color' menuItemStyle='' bottomsheetTitle='Your Gender' />
              {/* <DropdownGender dropdownStyle='font-size-12 font-size-md-16' menuItemStyle='d-flex justify-content-between align-items-center' bottomsheetTitle='Your Gender' menuList={this.state.genderlist} onOptionClick={this.onGenderOptionClick} selectedLabel={this.state.selectedGenderValue.label}/> */}
            </Col>
            <Col xs='3' lg='3' xl='2'>
              {/* <DropdownGender dropdownStyle='font-size-12 font-size-md-16 px-2 py-1 font-weight-medium text-primary-title-label-color' menuItemStyle='' bottomsheetTitle='Your Gender'/> */}
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col className='col-12 col-md-6'>
              <h4>Multi Select</h4>
              <AdditionalCards cardsList={this.state.additionalCardsList} cardTopText='Additional Benefits' onClick={this.onAdditionalCardOptionSelect} checkedValue={this.state.selectedAdditionalCardValue} />
            </Col>
            <Col className='col-12 col-md-6 mt-4 mt-md-0'>
              <h4>Single Select</h4>
              <AdditionalCards cardsList={this.state.additionalRiderCardsList} cardTopText='Additional Rider Availables' onClick={this.onAdditionalRiderCardOptionSelect} checkedValue={this.state.selectedAdditionalRiderCardValue} />
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col className='col-12 col-md-6'>
              <h4>Death cover and Price</h4>
              <DeathCoverPriceComponent firstAmount='2Cr' secondAmount='5000' thirdAmount='980' showAdditionalText={true} showSecondColAmount={true} secondColBottomText='Annual' firstColTitleText='Death cover' secondColTitleText='Price' />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default Styleguide;