// Project Imports
import fetchLifeCoverBenefit from "utils/fetchLifeCoverBenefit";
import fetchTaxBenefit from "utils/fetchTaxBenefit";
import fetchOtherBenefit from "utils/fetchOtherBenefit";
import fetchChildBenefit from "utils/fetchChildBenefit";
import fetchSurvivalBenefit from "utils/fetchSurvivalBenefit";
import Product from "constants/ProductConstants";
class Utils {
  fetchInputMode = (inputModeValue) => {
    let inputMode = "";
    switch (inputModeValue) {
      case "1":
        inputMode = "Annually";
        break;

      case "2":
        inputMode = "Semi – Annually";
        break;

      case "3":
        inputMode = "Quarterly";
        break;

      case "4":
        inputMode = "Monthly";
        break;
    }
    return inputMode;
  };

  fetchSolutionBenefitsList = (solutionData) => {
    let benefitList = [];

    let lifeCoverBenefit = fetchLifeCoverBenefit(solutionData);
    let taxBenefit = fetchTaxBenefit(solutionData);
    let otherBenefit = fetchOtherBenefit(solutionData);
    let childBenefit = fetchChildBenefit(solutionData);
    let survivalBenefit = fetchSurvivalBenefit(solutionData);

    if (lifeCoverBenefit) benefitList.push({ type: "life_cover", benefit: lifeCoverBenefit });

    if (taxBenefit) benefitList.push({ type: "tax_benefit", benefit: taxBenefit });

    if (childBenefit) benefitList.push({ type: "child_benefit", benefit: childBenefit });

    survivalBenefit.forEach((item) => {
      benefitList.push({ type: "survival_benefit", benefit: item });
    });

    otherBenefit.forEach((item) => {
      benefitList.push({ type: "other_benefit", benefit: item });
    });

    return benefitList;
  };

  fetchTotalPremiumPayable(productName, firstYearData) {
    switch (productName) {
      case Product.ZINDAGI_PLUS:
        return firstYearData.TOTAL_PREM_TAX_DISPLAY || 0;

      case Product.FOREVER_PENSION_DEFERRED_ANNUITY:
      case Product.FOREVER_PENSION_IMMEDIATE_ANNUITY:
      case Product.POS_FOREVER_PENSION:
      case Product.FOREVER_PENSION:
      case Product.SARAL_PENSION:
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM:
      case Product.PREMIER_GUARANTEED_RETIREMENT_INCOME:
      case Product.ACTIVE_INCOME_PLAN:
      case Product.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
      case Product.ACTIVE_INCOME_PLAN_WITH_FIB:
      case Product.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
      case Product.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
      case Product.INCOME_BUILDER:
      case Product.SECURED_INCOME_OPTION_INCOME_BUILDER:
        return firstYearData.TOTAL_PREM_TAX || 0;

      case Product.SIMPLY_PROTECT:
      case Product.CRITICARE_PLUS:
      case Product.IMMEDIATE_ANNUITY_PLAN:
      case Product.POS_SARAL_NIVESH:
      case Product.TOTAL_PROTECT_PLUS:
      case Product.SARAL_JEEVAN_BIMA:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
      case Product.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
        return firstYearData.MODAL_PREM_TAX || 0;

      case Product.WEALTH_ULTIMA:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
      case Product.LITTLE_CHAMP_WEALTH_ULTIMA:
      case Product.WEALTH_SECURE_PLUS:
      case Product.CHILD_VARIANT_WEALTH_SECURE_PLUS:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
      case Product.WEALTH_GAIN_PLUS:
      case Product.WEALTH_PLUS:
      case Product.RISING_STAR_WEALTH_PLUS:
        return firstYearData.ANN_PREM || 0;

      case Product.SMART_LIFESTYLE:
      case Product.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
      case Product.GCAP:
      case Product.DHAN_LABH:
      case Product.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
      case Product.PROTECTION_REG_5_10_15PAY:
        return firstYearData.PREM_DISPLAY || 0;

      case Product.CASH_FLOW_PROTECTION_PLUS:
        return firstYearData.TOTAL_MODALPREM_DISP || 0;

      case Product.EASY_PENSION:
        return firstYearData.ANN_PREM_YEARLY || 0;

      case Product.WEALTH_PREMIER:
        return firstYearData.LOAD_ANN_PREM || 0;

      default:
        return 0;
    }
  }

  fetchGstPercent = (product_name, OutputJSON) => {
    let gstPercent = 0;

    switch (product_name) {
      case Product.SARAL_JEEVAN_BIMA:
      case Product.POS_SARAL_JEEVAN_BIMA:
        gstPercent = 0.18;
        break;

      case Product.ZINDAGI_PLUS:
      case Product.SIMPLY_PROTECT:
      case Product.ACTIVE_INCOME_PLAN:
      case Product.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
      case Product.ACTIVE_INCOME_PLAN_WITH_FIB:
      case Product.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
      case Product.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
      case Product.WEALTH_ULTIMA:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
      case Product.LITTLE_CHAMP_WEALTH_ULTIMA:
      case Product.CRITICARE_PLUS:
      case Product.SMART_LIFESTYLE:
      case Product.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
      case Product.GCAP:
      case Product.CASH_FLOW_PROTECTION_PLUS:
      case Product.WEALTH_GAIN_PLUS:
      case Product.WEALTH_SECURE_PLUS:
      case Product.CHILD_VARIANT_WEALTH_SECURE_PLUS:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
      case Product.DHAN_LABH:
      case Product.WEALTH_PLUS:
      case Product.RISING_STAR_WEALTH_PLUS:
      case Product.INCOME_BUILDER:
      case Product.SECURED_INCOME_OPTION_INCOME_BUILDER:
      case Product.IMMEDIATE_ANNUITY_PLAN:
      case Product.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
      case Product.POS_SARAL_NIVESH:
      case Product.WEALTH_PREMIER:
      case Product.PROTECTION_REG_5_10_15PAY:
      case Product.SARAL_PENSION:
      case Product.FOREVER_PENSION:
      case Product.POS_FOREVER_PENSION:
      case Product.FOREVER_PENSION_DEFERRED_ANNUITY:
      case Product.FOREVER_PENSION_IMMEDIATE_ANNUITY:
      case Product.TOTAL_PROTECT_PLUS:
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM:
      case Product.PREMIER_GUARANTEED_RETIREMENT_INCOME:
      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
      case Product.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:
      case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case Product.GUARANTEED_INCOME_STAR:
      case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
      case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
      case Product.GUARANTEED_INCOME_STAR_FIB:
      case Product.POS_GUARANTEED_INCOME_STAR:
      case Product.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case Product.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM:
      case Product.GUARANTEED_SAVINGS_STAR_INCOME:
      case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
      case Product.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
      case Product.POS_GUARANTEED_SAVINGS_STAR:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
      case Product.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
        gstPercent = OutputJSON[0][0]?.TAX_RATE;
        break;

      default:
        break;
    }

    return gstPercent;
  };

  fetchGstAmount = (product_name, OutputJSON) => {
    let gstAmount = 0;

    switch (product_name) {
      case Product.SARAL_JEEVAN_BIMA:
      case Product.POS_SARAL_JEEVAN_BIMA:
      case Product.ZINDAGI_PLUS:
      case Product.SIMPLY_PROTECT:
      case Product.ACTIVE_INCOME_PLAN:
      case Product.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
      case Product.ACTIVE_INCOME_PLAN_WITH_FIB:
      case Product.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
      case Product.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
      case Product.WEALTH_ULTIMA:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
      case Product.LITTLE_CHAMP_WEALTH_ULTIMA:
      case Product.CRITICARE_PLUS:
      case Product.SMART_LIFESTYLE:
      case Product.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
      case Product.GCAP:
      case Product.CASH_FLOW_PROTECTION_PLUS:
      case Product.WEALTH_GAIN_PLUS:
      case Product.WEALTH_SECURE_PLUS:
      case Product.CHILD_VARIANT_WEALTH_SECURE_PLUS:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
      case Product.DHAN_LABH:
      case Product.WEALTH_PLUS:
      case Product.RISING_STAR_WEALTH_PLUS:
      case Product.INCOME_BUILDER:
      case Product.SECURED_INCOME_OPTION_INCOME_BUILDER:
      case Product.IMMEDIATE_ANNUITY_PLAN:
      case Product.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
      case Product.POS_SARAL_NIVESH:
      case Product.WEALTH_PREMIER:
      case Product.PROTECTION_REG_5_10_15PAY:
      case Product.SARAL_PENSION:
      case Product.FOREVER_PENSION:
      case Product.POS_FOREVER_PENSION:
      case Product.FOREVER_PENSION_DEFERRED_ANNUITY:
      case Product.FOREVER_PENSION_IMMEDIATE_ANNUITY:
      case Product.TOTAL_PROTECT_PLUS:
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM:
      case Product.PREMIER_GUARANTEED_RETIREMENT_INCOME:
      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
      case Product.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:
      case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case Product.GUARANTEED_INCOME_STAR:
      case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
      case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
      case Product.GUARANTEED_INCOME_STAR_FIB:
      case Product.POS_GUARANTEED_INCOME_STAR:
      case Product.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case Product.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM:
      case Product.GUARANTEED_SAVINGS_STAR_INCOME:
      case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
      case Product.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
      case Product.POS_GUARANTEED_SAVINGS_STAR:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
      case Product.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
        gstAmount = OutputJSON[0][0]?.TAX_MP ? Math.round(OutputJSON[0][0]?.TAX_MP) : 0
        // gstAmount = Math.round(OutputJSON[0][0]?.TAX_MP);
        break;

      default:
        break;
    }

    return gstAmount;
  };

  fetchTargetPremiumAmount = (product_name, riderBasePremium, OutputJSON, inputMode, inputString) => {
    let totalPremiumAmount = 0;
    let basePremium = 0;
    let premiumGstAmount = 0;
    let riderGstAmount = 0;
    let riderPremium = riderBasePremium;
    riderGstAmount = this.fetchRiderGSTAmount(product_name, OutputJSON, inputString);

    basePremium = this.fetchBasePremium(product_name, OutputJSON);
    premiumGstAmount = this.fetchGstAmount(product_name, OutputJSON);

    const amountArray = [basePremium, premiumGstAmount, riderPremium, riderGstAmount];

    amountArray.forEach((amt) => {
      if (amt) {
        totalPremiumAmount += Math.round(Number(amt));
      }
    });

    if (Number(inputMode) === 4) totalPremiumAmount = totalPremiumAmount * 2;

    return totalPremiumAmount;
  };

  fetchRiderGSTAmount = (product_name, OutputJSON, InputString) => {
    let riderAmount = 0;
    let adbRider = this.fetchInputStringOpt('@RD_TAX_50001', InputString)
    let atpdRider = this.fetchInputStringOpt('@RD_TAX_50002', InputString)
    let hospitalityRider = this.fetchInputStringOpt('@RD_TAX_50004', InputString)
    let criticalIllnessRider = this.fetchInputStringOpt('@RD_TAX_50003', InputString)
    let incomeBenefitRider = this.fetchInputStringOpt('@RD_TAX_50006', InputString)
    let waiverPremiumRider = this.fetchInputStringOpt('@RD_TAX_50007', InputString)
    let payorWaiverRider = this.fetchInputStringOpt('@RD_TAX_50008', InputString)

    switch (product_name) {
      case Product.SARAL_JEEVAN_BIMA:
      case Product.POS_SARAL_JEEVAN_BIMA:
      case Product.ZINDAGI_PLUS:
      case Product.SIMPLY_PROTECT:
      case Product.WEALTH_ULTIMA:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
      case Product.LITTLE_CHAMP_WEALTH_ULTIMA:
      case Product.CRITICARE_PLUS:
      // case Product.GCAP:
      // case Product.CASH_FLOW_PROTECTION_PLUS:
      case Product.WEALTH_GAIN_PLUS:
      case Product.WEALTH_SECURE_PLUS:
      case Product.CHILD_VARIANT_WEALTH_SECURE_PLUS:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
      case Product.DHAN_LABH:
      case Product.WEALTH_PLUS:
      case Product.RISING_STAR_WEALTH_PLUS:
      case Product.IMMEDIATE_ANNUITY_PLAN:
      case Product.EASY_PENSION:
      // case Product.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
      case Product.POS_SARAL_NIVESH:
      case Product.WEALTH_PREMIER:
      case Product.PROTECTION_REG_5_10_15PAY:
      case Product.SARAL_PENSION:
      case Product.FOREVER_PENSION:
      case Product.POS_FOREVER_PENSION:
      case Product.FOREVER_PENSION_DEFERRED_ANNUITY:
      case Product.FOREVER_PENSION_IMMEDIATE_ANNUITY:
      riderAmount = OutputJSON[0][0]?.RIDER_TOTAL_TAX;
      break;
      
      case Product.POS_GUARANTEED_INCOME_STAR:
      case Product.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case Product.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case Product.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:
      case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case Product.GUARANTEED_INCOME_STAR:
      case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
      case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
      case Product.GUARANTEED_INCOME_STAR_FIB:
      case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM:
      case Product.GUARANTEED_SAVINGS_STAR_INCOME:
      case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
      case Product.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
      case Product.POS_GUARANTEED_SAVINGS_STAR:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
      case Product.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM:
      case Product.PREMIER_GUARANTEED_RETIREMENT_INCOME:
      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
        riderAmount = OutputJSON[0][0]?.TOTAL_RIDER_TAX
        break;

      case Product.TOTAL_PROTECT_PLUS:
        riderAmount = OutputJSON[0][0].RIDER_TOTAL;
        break;

      case Product.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
      case Product.SMART_LIFESTYLE:
      case Product.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
      case Product.ACTIVE_INCOME_PLAN:
      case Product.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
      case Product.ACTIVE_INCOME_PLAN_WITH_FIB:
      case Product.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
      case Product.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
      case Product.INCOME_BUILDER:
      case Product.SECURED_INCOME_OPTION_INCOME_BUILDER:
      case Product.GCAP:
      case Product.CASH_FLOW_PROTECTION_PLUS:
        riderAmount += adbRider
        riderAmount += atpdRider
        riderAmount += criticalIllnessRider
        riderAmount += incomeBenefitRider
        riderAmount += waiverPremiumRider
        riderAmount += payorWaiverRider
        riderAmount += hospitalityRider

        break;

      default:
        break;
    }

    return Math.round(riderAmount);
  };

  fetchTargetAMount = (product_name, OutputJSON, InputString) => {
    let targetAmount = 0;
    let pt = InputString.find((item) => item.Key === "@PR_PT");

    switch (product_name) {
      case Product.SARAL_JEEVAN_BIMA:
      case Product.POS_SARAL_JEEVAN_BIMA:
      case Product.ZINDAGI_PLUS:
      case Product.SIMPLY_PROTECT:
      case Product.WEALTH_GAIN_PLUS:
      case Product.WEALTH_SECURE_PLUS:
      case Product.CHILD_VARIANT_WEALTH_SECURE_PLUS:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
      case Product.IMMEDIATE_ANNUITY_PLAN:
      case Product.PROTECTION_REG_5_10_15PAY:
        targetAmount = OutputJSON[0][0]?.SA;
        break;

      case Product.TOTAL_PROTECT_PLUS:
        targetAmount = OutputJSON[0][0]?.SUMASSURED_DEATH;
        break;

      case Product.EASY_PENSION:
        targetAmount = OutputJSON[0][OutputJSON[0].length - 1]?.PENSION_AMT_TVB;
        break;

      case Product.SARAL_PENSION:
        let annuityMode = InputString.find((item) => item.Key === "@PR_OPTION_5")?.Value;
        if (Number(annuityMode) === 8) {
          targetAmount = Math.round(OutputJSON[0][0]?.SB_G)
        } else if (Number(annuityMode) === 9) {
          targetAmount = Math.round(Math.round(OutputJSON[0][0]?.SB_G) * 2)
        } else if (Number(annuityMode) === 10) {
          targetAmount = Math.round(Math.round(OutputJSON[0][0]?.SB_G) * 4)
        } else {
          targetAmount = Math.round(Math.round(OutputJSON[0][0]?.SB_G) * 12)
        }
        break;

      case Product.FOREVER_PENSION:
      case Product.POS_FOREVER_PENSION:
      case Product.FOREVER_PENSION_DEFERRED_ANNUITY:
      case Product.FOREVER_PENSION_IMMEDIATE_ANNUITY:
        let annuityModeForeverPension = InputString.find((item) => item.Key === "@PR_OPTION_7")?.Value;
        let foreverPensionTargetAmt  = Math.round(OutputJSON[0].find(item => item.MODAL_ANNUITY_PAYOUT !== 0).MODAL_ANNUITY_PAYOUT)
        if (Number(annuityModeForeverPension) === 21) {
          targetAmount = Math.round(foreverPensionTargetAmt)
        } else if (Number(annuityModeForeverPension) === 22) {
          targetAmount = Math.round(Math.round(foreverPensionTargetAmt) * 2)
        } else if (Number(annuityModeForeverPension) === 23) {
          targetAmount = Math.round(Math.round(foreverPensionTargetAmt) * 4)
        } else {
          targetAmount = Math.round(Math.round(foreverPensionTargetAmt) * 12)
        }
        // targetAmount = OutputJSON[0][0]?.MODAL_ANNUITY_PAYOUT;
        break;

      case Product.WEALTH_ULTIMA:
      case Product.LITTLE_CHAMP_WEALTH_ULTIMA:
        targetAmount = OutputJSON[0][OutputJSON[0].length - 1]?.FINAL_FV;
        break;

      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
        // targetAmount = OutputJSON[0][1]?.TOTALPW;
         targetAmount = OutputJSON[0].find(item => item.TOTALPW !== 0)?.TOTALPW
        break;

      case Product.WEALTH_PLUS:
      case Product.RISING_STAR_WEALTH_PLUS:
        targetAmount = OutputJSON[1][OutputJSON[1].length - 1]?.FINAL_FV;
        break;

      case Product.WEALTH_PREMIER:
        targetAmount = OutputJSON[1][OutputJSON[1].length - 1]?.FINAL_FV;
        break;

      case Product.GCAP:
        targetAmount = OutputJSON[0][OutputJSON[0].length - 1]?.MATURITY;
        break;

      case Product.DHAN_LABH:
        targetAmount = OutputJSON[0][OutputJSON[0].length - 1]?.TOTAL_BEN;
        break;

      case Product.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
        targetAmount = OutputJSON[0][OutputJSON[0].length - 1]?.SB_G;
        break;

      case Product.CASH_FLOW_PROTECTION_PLUS:
        let cashFlowProtectionPPT = InputString.find((item, index) => item.Key === "@PR_PPT");
        targetAmount = OutputJSON[0][Number(cashFlowProtectionPPT.Value) + 1]?.TOTAL_SB_BS_2;
        break;

      case Product.INCOME_BUILDER:
      case Product.SECURED_INCOME_OPTION_INCOME_BUILDER:
        OutputJSON[0]?.forEach((item) => {
          if (Number(item.PAYOUT_PERIOD) == 1 && targetAmount === 0) targetAmount = Math.round(item.REGULAR_INCOME);
        });
        break;

      case Product.ACTIVE_INCOME_PLAN:
      case Product.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
      case Product.ACTIVE_INCOME_PLAN_WITH_FIB:
      case Product.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
      case Product.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
        let activeIncomePPT = InputString.find((item, index) => item.Key === "@PR_PPT");
        OutputJSON[0]?.forEach((item, index) => {
          if (Number(activeIncomePPT.Value) + 1 == index) targetAmount = Math.round(item.TOTAL_SB_G_BS_2);
        });
        break;

      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM:
      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
        targetAmount = Math.round(OutputJSON[0][OutputJSON[0].length - 1]?.MATURITY_BEN);
        break;
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
      case Product.PREMIER_GUARANTEED_RETIREMENT_INCOME:
        let incomeBenefitPayoutFrequencyValue = 1;

        let incFrequency = Number(InputString.find((item, index) => item.Key === "@INC_BEN_PAY_FREQ").Value);
        if (incFrequency == 1) {
          incomeBenefitPayoutFrequencyValue = 1;
        } else if (incFrequency == 2) {
          incomeBenefitPayoutFrequencyValue = 2;
        } else if (incFrequency == 3) {
          incomeBenefitPayoutFrequencyValue = 4;
        } else if (incFrequency == 4) {
          incomeBenefitPayoutFrequencyValue = 12;
        }
        OutputJSON[0]?.forEach((item) => {
          if (Number(item.INCOME_BEN_PAY_OUT) != 0 && targetAmount === 0) targetAmount = Math.round(item.INCOME_BEN_PAY_OUT);
        });
        break;
      // case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      // case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
      //   OutputJSON[0]?.forEach((item) => {
      //     if (Number(item.INCOME_BEN_PAY_OUT) != 0 && targetAmount === 0) targetAmount = Math.round(item.INCOME_BEN_PAY_OUT);
      //   });
      //   targetAmount = targetAmount + Math.round(OutputJSON[0][OutputJSON[0].length - 1]?.MATURITY_BEN);
      //   break;
      // case Product.PREMIER_GUARANTEED_RETIREMENT_INCOME:
      //   OutputJSON[0]?.forEach((item) => {
      //     if (Number(item.INCOME_BEN_PAY_OUT) != 0 && targetAmount === 0) targetAmount = Math.round(item.INCOME_BEN_PAY_OUT);
      //   });
      //   targetAmount = targetAmount + Math.round(OutputJSON[0][OutputJSON[0].length - 1]?.MATURITY_BEN);
      //   break;

      case Product.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:
      case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case Product.GUARANTEED_INCOME_STAR:
      case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
      case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
      case Product.GUARANTEED_INCOME_STAR_FIB:
      case Product.POS_GUARANTEED_INCOME_STAR:
      case Product.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case Product.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
        let gisOption = InputString.find((item, index) => item.Key === "@PR_OPTION_2");
        let gisPPT = InputString.find((item, index) => item.Key === "@PR_PPT");
        if (Number(gisOption.Value) == 6) {
          OutputJSON[0]?.forEach((item) => {
            if (Number(item.INCOME_BENEFIT_FINAL) != 0 && targetAmount === 0) targetAmount = Math.round(item.INCOME_BENEFIT_FINAL);
          });
        }
        else {
          targetAmount = Math.round(OutputJSON[0][Number(gisPPT.Value)].INCOME_BENEFIT_FINAL)
        }
        break;

      case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM:
      case Product.GUARANTEED_SAVINGS_STAR_INCOME:
      case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
      case Product.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
      case Product.POS_GUARANTEED_SAVINGS_STAR:
        let maturityBenefitOption = InputString.find((item, index) => item.Key === "@DISPLAY_PAYOUT_MODE");
        if (maturityBenefitOption?.Value == "Lumpsum") {
          targetAmount = OutputJSON[0][OutputJSON[0].length - 1]?.MB_G;
        } else {
          targetAmount = 0;
          OutputJSON[0]?.forEach((item) => {
            if (Number(item.MB_G) != 0 && targetAmount === 0) targetAmount = Math.round(item.MB_G);
          });
        }
        break;

      case Product.SMART_LIFESTYLE:
      case Product.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
        let smartLifeStylePPT = InputString.find((item, index) => item.Key === "@PR_PPT");
        let smartLifeStyleInputMode = InputString.find((item, index) => item.Key === "@INPUT_MODE");
        let inputAmount = 0;
        if (Number(smartLifeStyleInputMode.Value) === 1) {
          inputAmount = Math.round(Number(OutputJSON[0][0]?.MODAL_PREM)) * 1;
        } else if (Number(smartLifeStyleInputMode.Value) === 2) {
          inputAmount = Math.round(Number(OutputJSON[0][0]?.MODAL_PREM)) * 2;
        } else if (Number(smartLifeStyleInputMode.Value) === 3) {
          inputAmount = Math.round(Number(OutputJSON[0][0]?.MODAL_PREM)) * 4;
        } else if (Number(smartLifeStyleInputMode.Value) === 4) {
          inputAmount = Math.round(Number(OutputJSON[0][0]?.MODAL_PREM)) * 12;
        }
        targetAmount = Math.round(OutputJSON[0][OutputJSON[0].length - 1]?.SB_NG_DIS_BS_2);
        break;

      case Product.POS_SARAL_NIVESH:
        targetAmount = OutputJSON[0][OutputJSON[0].length - 1]?.SB_G;
        break;

      case Product.CRITICARE_PLUS:
        targetAmount = OutputJSON[0][0]?.CI_SA;
        break;

      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
        let fspPPT = InputString.find((item) => item.Key === "@PR_PPT");
        targetAmount = OutputJSON[0][Number(fspPPT.Value)]?.TOTAL_SB_PAYOUT_BS_2;
        break;

      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
        let fspChildPPT = InputString.find((item) => item.Key === "@PR_PPT");
        let accrualOfSurvivalBenefits = InputString.find((item) => item.Key === "@PR_OPTION_4");
        if (Number(accrualOfSurvivalBenefits) === 12) {
          targetAmount = OutputJSON[0][Number(fspChildPPT.Value)]?.TOTAL_SB_PAYOUT_BS_2;
        } else {
          targetAmount = OutputJSON[0][Number(fspChildPPT.Value)]?.TOTAL_SB_POST_PPT_BS_2;
        }
        break;

      case Product.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
        let fspAccrualBenefitPPT = InputString.find((item) => item.Key === "@PR_PPT");
        targetAmount = OutputJSON[0][Number(fspAccrualBenefitPPT.Value)]?.TOTAL_SB_POST_PPT_BS_2;
        break;

      case Product.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
      case Product.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
        targetAmount = OutputJSON[0][Number(pt.Value) - 1]?.MATURITY_BENEFIT_BS_2;
        break;

      default:
        break;
    }

    return targetAmount;
  };

  fetchBasePremium(product_name, OutputJSON) {
    let basePremium = 0;

    switch (product_name) {
      case Product.SARAL_JEEVAN_BIMA:
      case Product.POS_SARAL_JEEVAN_BIMA:
      case Product.ZINDAGI_PLUS:
      case Product.SIMPLY_PROTECT:
      case Product.ACTIVE_INCOME_PLAN:
      case Product.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
      case Product.ACTIVE_INCOME_PLAN_WITH_FIB:
      case Product.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
      case Product.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
      case Product.CRITICARE_PLUS:
      case Product.SMART_LIFESTYLE:
      case Product.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
      case Product.GCAP:
      case Product.CASH_FLOW_PROTECTION_PLUS:
      case Product.WEALTH_GAIN_PLUS:
      case Product.WEALTH_SECURE_PLUS:
      case Product.CHILD_VARIANT_WEALTH_SECURE_PLUS:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
      case Product.DHAN_LABH:
      case Product.RISING_STAR_WEALTH_PLUS:
      case Product.INCOME_BUILDER:
      case Product.SECURED_INCOME_OPTION_INCOME_BUILDER:
      case Product.IMMEDIATE_ANNUITY_PLAN:
      case Product.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
      case Product.POS_SARAL_NIVESH:
      case Product.WEALTH_PREMIER:
      case Product.PROTECTION_REG_5_10_15PAY:
      case Product.SARAL_PENSION:
      case Product.FOREVER_PENSION:
      case Product.POS_FOREVER_PENSION:
      case Product.FOREVER_PENSION_DEFERRED_ANNUITY:
      case Product.FOREVER_PENSION_IMMEDIATE_ANNUITY:
      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM:
      case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
      case Product.PREMIER_GUARANTEED_RETIREMENT_INCOME:
      case Product.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:
      case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case Product.GUARANTEED_INCOME_STAR:
      case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
      case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
      case Product.GUARANTEED_INCOME_STAR_FIB:
      case Product.POS_GUARANTEED_INCOME_STAR:
      case Product.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case Product.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM:
      case Product.GUARANTEED_SAVINGS_STAR_INCOME:
      case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
      case Product.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
      case Product.POS_GUARANTEED_SAVINGS_STAR:
      case Product.TOTAL_PROTECT_PLUS:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
      case Product.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
      case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
      case Product.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
        basePremium = Math.round(OutputJSON[0][0]?.MODAL_PREM);
        break;

      case Product.EASY_PENSION:
        basePremium = Math.round(OutputJSON[0][0]?.MODAL_PREM_DISP)
        break;

      case Product.WEALTH_ULTIMA:
      case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
      case Product.LITTLE_CHAMP_WEALTH_ULTIMA:
      case Product.WEALTH_PLUS:
        basePremium = Math.round(OutputJSON[0][0]?.PREMIUM)
        break;

      default:
        break;
    }

    return basePremium;

  }

  fetchInputStringOpt(key, inputString) {
    return Math.round(inputString?.filter((item) => item.Key == key)[0]?.Value) || 0
  }
}

export default new Utils();
