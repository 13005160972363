import React, { Component } from 'react';
import { Card } from 'react-bootstrap';

import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import FamilyProtectionStore from '../store/FamilyProtectionStore'

const PriceSummary = observer(class PriceSummary extends Component {
    state = {}
    getCalculateAmount = (dataObj) => {
        let data = []
        Object.keys(dataObj).map((cardKey) => {
            let tempDataObj = {}
            tempDataObj.price = 0
            Object.values(dataObj[cardKey]).map(price => tempDataObj.price += parseInt(price))
            switch (cardKey) {
                case 'protection_for_my_family':
                    tempDataObj.plan = 'Protection for my Family'
                    break
                case 'protection_for_my_health':
                    tempDataObj.plan = 'Protection for my Health'
                    break
                case 'grow_my_money':
                    tempDataObj.plan = 'Grow my Money'
                    break
                case 'save_for_children':
                    tempDataObj.plan = 'Save for children'
                    break
                case 'save_for_retirement':
                    tempDataObj.plan = 'Save for retirement'
                    break
            }
            data.push(tempDataObj)
        })
        return data

    }
    render() {
        console.log('PRICE SUMMARY >> ', toJS(FamilyProtectionStore.totalPriceData))

        let data = this.getCalculateAmount(toJS(FamilyProtectionStore.totalPriceData))
        let totalAmount = 0
        data.map(item => totalAmount += parseInt(item.price))
        return (
            <div>
                {totalAmount > 0 ?
                    <div className='mt-4 price-summary-margin-container px-3 px-md-0'>
                        <span className='font-size-20 font-weight-bold text-header-style'>Price Summary</span>
                        <Card className='top-border-light-grey my-3'>
                            <Card.Body className='pb-0'>
                                {
                                    data.map((item, index) => {
                                        return (
                                            <AmountCalculationRowItem
                                                key={index}
                                                title={item.plan}
                                                amount={item.price}
                                                titleStyle='font-size-18 font-size-md-24 font-size-lg-24'
                                                totalTextStyle={`text-description-text-color font-size-16 font-size-md-20 font-size-lg-20 font-weight-light ${data.length > (index + 1) && 'mb-4 mb-md-0'}`}
                                                amountStyle='font-size-16 font-size-md-24 font-size-lg-24'
                                                subText='Total price'
                                            />
                                        )
                                    })
                                }
                                <hr className='line my-3' />
                                <AmountCalculationRowItem amount={totalAmount} totalTextStyle='font-size-18 font-size-md-24 font-size-lg-24 text-primary-title-label-color' amountStyle='font-size-18 font-size-md-24 font-size-lg-28' subText='Grand Total' />
                                <hr className='line mt-3' />
                            </Card.Body>
                        </Card>
                    </div>
                    : null}
            </div>
        )
    }
});



const AmountCalculationRowItem = (props) => {
    const { title, amount, titleStyle, totalTextStyle, amountStyle, subText } = props;
    return (
        <div>
            {title ? <div className={`text-primary-title-label-color ${titleStyle}`}>{title}</div> : ''}
            <div className='d-flex justify-content-between'>
                <span className={`${totalTextStyle}`}>{subText}</span>
                <span className={`text-secondary font-weight-bold ${amountStyle}`}><span className='font-weight-medium'></span> {global.toINR(amount)}</span>
            </div>
        </div>
    )
}
export default PriceSummary;