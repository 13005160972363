const fetchIncomeFrequencyLabel = (need, productData) => {
    switch (productData?.payout_mode) {
      case "income":
          return " per annum"
     default:
      return ""
    }
}

export default fetchIncomeFrequencyLabel;
