import React, { Component } from 'react';

import { Col, Row, Card } from 'react-bootstrap';
import { observer } from 'mobx-react';

import { CustomCheckbox, BottomsheetDropdown } from '../../../../../../../components';
import { InfoArrowBlackImg } from 'constants/Images';

import './additional-rider-benifits-card.css';

const AdditionalRiderBenifitsCard = observer(class AdditionalRiderBenifitsCard extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    fetchSumAssureValue = (data, riderName) => {
        if (data.data.parsedResponse.inputValidationStatus && data.data.parsedResponse.inputValidationStatus.length > 0) {
            let opt = data.data.parsedResponse.inputValidationStatus.filter((item) => riderName == item.ProductName)
            if (opt.length > 0) {
                return data.data.isValidationExist ? 0 : opt[0].ModalPremium.toString()
            }
            return 0
        }
        return 0
    }

    render() {
        const { form, data } = this.props;
        return (
            <div className='additional-cards'>
                <p className='mb-3 font-size-16 font-size-md-22 font-size-lg-24 font-weight-bold text-primary-title-label-color card-top-text'>Additional Rider</p>
                <Card className='border-0 shadow'>
                    <Card.Body>
                        {/* 1 st */}
                        <Row className={` ${form.$('wop').disabled ? 'disable-card' : ''}`}>
                            <Col className='col-8'>
                                <div className='d-flex align-items-center'>
                                    <CustomCheckbox shape='rounded'
                                        title={form.$('wop').label}
                                        titleStyle=' font-weight-normal font-size-16 font-size-md-20 pl-2 text-primary-title-label-color card-top-title'
                                        isChecked={form.$('wop').value}
                                        onChange={() => form.$('wop').extra.changeRiderStatus(form, data)}
                                        mark='checkmark'
                                    />
                                    <img className={`mb-2 pl-2 ${form.$('wop').disabled ? 'inf0-icon-disable' : ''}`} src={InfoArrowBlackImg} alt='info' />
                                </div>
                                <p className='mb-0 font-size-14 font-size-md-16 font-weight-300 sub-left-padding text-description-text-color'>
                                    {form.$('wop').extra.description}
                                </p>
                            </Col>
                            <Col className='align-items-center col-4 col-border-left d-flex flex-column justify-content-center col-line-height'>
                                {
                                    form.$('wop').value ?
                                        <React.Fragment>
                                            <div className='d-flex justify-content-end'>
                                                <p className='mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary'>{this.fetchSumAssureValue(data, form.$('wop').extra.rider_name)}</p>
                                            </div>
                                            <p className='mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color'></p>
                                        </React.Fragment> :
                                        <p className='mb-0 font-weight-normal font-size-14 font-size-md-16 text-center text-description-text-color'>
                                            Not <br /> Selected
                                        </p>
                                }
                            </Col>
                        </Row>

                        {/* 2 nd */}
                        <Row className={` ${form.$('ADB').disabled ? 'disable-card' : ''}`}>
                            <Col className='col-8'>
                                <div className='d-flex align-items-center'>
                                    <CustomCheckbox shape='rounded'
                                        title={form.$('ADB').label}
                                        titleStyle=' font-weight-normal font-size-16 font-size-md-20 pl-2 text-primary-title-label-color card-top-title'
                                        isChecked={form.$('ADB').value}
                                        onChange={() => form.$('ADB').extra.changeRiderStatus(form, data)}
                                        mark='checkmark'
                                    />
                                    <img className={`mb-2 pl-2 ${form.$('ADB').disabled ? 'inf0-icon-disable' : ''}`} src={InfoArrowBlackImg} alt='info' />
                                </div>
                                <p className='mb-0 font-size-14 font-size-md-16 font-weight-300 sub-left-padding text-description-text-color'>
                                    {form.$('ADB').extra.description}
                                </p>
                                {(form.$('ADB').value) &&
                                    <BottomsheetDropdown
                                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                        menuItemStyle='d-flex justify-content-between align-items-center'
                                        selectedLabelKey='label'
                                        bottomsheetTitle=''
                                        menuList={form.$('ADB-dropdown').extra.optionList}
                                        value={form.$('ADB-dropdown').value}
                                        onSelect={form.$('ADB-dropdown').onChange}
                                    />}
                            </Col>
                            <Col className='align-items-center col-4 col-border-left d-flex flex-column justify-content-center col-line-height'>
                                {
                                    form.$('ADB').value ?
                                        <React.Fragment>
                                            <div className='d-flex justify-content-end'>
                                                <p className='mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary'>{this.fetchSumAssureValue(data, form.$('ADB').extra.rider_name)}</p>
                                            </div>
                                            <p className='mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color'></p>
                                        </React.Fragment> :
                                        <p className='mb-0 font-weight-normal font-size-14 font-size-md-16 text-center text-description-text-color'>
                                            Not <br /> Selected
                                        </p>
                                }
                            </Col>
                        </Row>

                        {/* 3 rd */}
                        <Row className={`mt-3 ${form.$('ATPDRider').disabled ? 'disable-card' : ''}`}>
                            <Col className='col-8'>
                                <div className='d-flex align-items-center'>
                                    <CustomCheckbox shape='rounded'
                                        title={form.$('ATPDRider').label}
                                        titleStyle=' font-weight-normal font-size-16 font-size-md-20 pl-2 text-primary-title-label-color card-top-title'
                                        isChecked={form.$('ATPDRider').value}
                                        onChange={() => form.$('ATPDRider').extra.changeRiderStatus(form, data)}
                                        mark='checkmark'
                                    />
                                    <img className={`mb-2 pl-2 ${form.$('ATPDRider').disabled ? 'inf0-icon-disable' : ''}`} src={InfoArrowBlackImg} alt='info' />
                                </div>
                                <p className='mb-0 font-size-14 font-size-md-16 font-weight-300 sub-left-padding text-description-text-color'>
                                    {form.$('ATPDRider').extra.description}
                                </p>
                                {(form.$('ATPDRider').value) &&
                                    <BottomsheetDropdown
                                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                        menuItemStyle='d-flex justify-content-between align-items-center'
                                        selectedLabelKey='label'
                                        bottomsheetTitle=''
                                        menuList={form.$('ATPDRider-dropdown').extra.optionList}
                                        value={form.$('ATPDRider-dropdown').value}
                                        onSelect={form.$('ATPDRider-dropdown').onChange}
                                    />}
                            </Col>
                            <Col className='align-items-center col-4 col-border-left d-flex flex-column justify-content-center col-line-height'>
                                {
                                    form.$('ATPDRider').value ?
                                        <React.Fragment>
                                            <div className='d-flex justify-content-end'>
                                                <p className='mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary'>{this.fetchSumAssureValue(data, form.$('ATPDRider').extra.rider_name)}</p>
                                            </div>
                                            <p className='mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color'></p>
                                        </React.Fragment> :
                                        <p className='mb-0 font-weight-normal font-size-14 font-size-md-16 text-center text-description-text-color'>
                                            Not <br /> selected
                                        </p>
                                }
                            </Col >
                        </Row >

                        {/* 4th */}
                        < Row className={`mt-3 ${form.$('CriticalIllnessRider').disabled ? 'disable-card' : ''}`}>
                            <Col className='col-8'>
                                <div className='d-flex align-items-center'>
                                    <CustomCheckbox shape='rounded'
                                        title={form.$('CriticalIllnessRider').label}
                                        titleStyle=' font-weight-normal font-size-16 font-size-md-20 pl-2 text-primary-title-label-color card-top-title'
                                        isChecked={form.$('CriticalIllnessRider').value}
                                        onChange={() => form.$('CriticalIllnessRider').extra.changeRiderStatus(form, data)}
                                        mark='checkmark'
                                    />
                                    <img className={`mb-2 pl-2 ${form.$('CriticalIllnessRider').disabled ? 'inf0-icon-disable' : ''}`} src={InfoArrowBlackImg} alt='info' />
                                </div>
                                <p className='mb-0 font-size-14 font-size-md-16 font-weight-300 sub-left-padding text-description-text-color'>
                                    {form.$('CriticalIllnessRider').extra.description}
                                </p>
                                {(form.$('CriticalIllnessRider').value) &&
                                    <BottomsheetDropdown
                                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                        menuItemStyle='d-flex justify-content-between align-items-center'
                                        selectedLabelKey='label'
                                        bottomsheetTitle=''
                                        menuList={form.$('CriticalIllnessRider-dropdown').extra.optionList}
                                        value={form.$('CriticalIllnessRider-dropdown').value}
                                        onSelect={form.$('CriticalIllnessRider-dropdown').onChange}
                                    />}
                            </Col>
                            <Col className='align-items-center col-4 col-border-left d-flex flex-column justify-content-center col-line-height'>
                                {
                                    form.$('CriticalIllnessRider').value ?
                                        <React.Fragment>
                                            <div className='d-flex justify-content-end'>
                                                <p className='mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary'>{this.fetchSumAssureValue(data, form.$('CriticalIllnessRider').extra.rider_name)}</p>
                                            </div>
                                            <p className='mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color'></p>
                                        </React.Fragment> :
                                        <p className='mb-0 font-weight-normal font-size-14 font-size-md-16 text-center text-description-text-color'>
                                            Not <br /> Selected
                                        </p >
                                }
                            </Col >
                        </Row >

                    </Card.Body >
                </Card >
            </div >
        )
    }
});

export default AdditionalRiderBenifitsCard
