import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from "./validator";
import calculateRiderPremium from "utils/calculateRiderPremium";
import calculateProductPremiumRelatedValues from "utils/calculateProductPremiumRelatedValues";
import ProductConstants from "constants/ProductConstants";
class Utils {

    pptOptionListFunction = (formRef,productName,planOption) => {

      if(productName === ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM && planOption === "2"){

        formRef.$("PPT").set("extra", {
          ...formRef.$("PPT").extra,
          optionList: [
            {label: '8', id: '8'},
            {label: '10', id: '10'}, 
           {label: '12', id: '12'},
          ],
        })
        formRef.$("PPT").set("value",{label: '8', id: '8'})
      }else{
        formRef.$("PPT").set("extra", {
          ...formRef.$("PPT").extra,
          optionList: [
            {label: '5', id: '5'},
            {label: '8', id: '8'},
            {label: '10', id: '10'}, 
           {label: '12', id: '12'},
          ],
        })
      }
    }

  ptLogicBasedOnppt = (productName, formRef, pptValue) => {
    switch (productName) {
      case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM:
      case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
      case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME:
      case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
        if (pptValue === "5" || pptValue === "8") {
          formRef.$("PT").set("extra", {
            ...formRef.$("PT").extra,
            optionList: [
              { label: "10", id: "10" },
              { label: "11", id: "11" },
              { label: "12", id: "12" },
              { label: "13", id: "13" },
              { label: "14", id: "14" },
              { label: "15", id: "15" },
              { label: "16", id: "16" },
              { label: "17", id: "17" },
              { label: "18", id: "18" },
              { label: "19", id: "19" },
              { label: "20", id: "20" },
              { label: "21", id: "21" },
              { label: "22", id: "22" },
              { label: "23", id: "23" },
              { label: "24", id: "24" },
              { label: "25", id: "25" },
              { label: "26", id: "26" },
              { label: "27", id: "27" },
              { label: "28", id: "28" },
              { label: "29", id: "29" },
              { label: "30", id: "30" },
              { label: "31", id: "31" },
              { label: "32", id: "32" },
              { label: "33", id: "33" },
              { label: "34", id: "34" },
              { label: "35", id: "35" },
              { label: "36", id: "36" },
              { label: "37", id: "37" },
              { label: "38", id: "38" },
              { label: "39", id: "39" },
              { label: "40", id: "40" },
            ],
          });
        } else if (pptValue === "10" || pptValue === "12") {
          formRef.$("PT").set("extra", {
            ...formRef.$("PT").extra,
            optionList: [
              { label: "15", id: "15" },
              { label: "16", id: "16" },
              { label: "17", id: "17" },
              { label: "18", id: "18" },
              { label: "19", id: "19" },
              { label: "20", id: "20" },
              { label: "21", id: "21" },
              { label: "22", id: "22" },
              { label: "23", id: "23" },
              { label: "24", id: "24" },
              { label: "25", id: "25" },
              { label: "26", id: "26" },
              { label: "27", id: "27" },
              { label: "28", id: "28" },
              { label: "29", id: "29" },
              { label: "30", id: "30" },
              { label: "31", id: "31" },
              { label: "32", id: "32" },
              { label: "33", id: "33" },
              { label: "34", id: "34" },
              { label: "35", id: "35" },
              { label: "36", id: "36" },
              { label: "37", id: "37" },
              { label: "38", id: "38" },
              { label: "39", id: "39" },
              { label: "40", id: "40" },
            ],
          });
        }

        break;

      // case ProductConstants.GUARANTEED_SAVINGS_STAR:
      case ProductConstants.POS_GUARANTEED_SAVINGS_STAR:
        if (pptValue === "5" || pptValue === "8") {
          formRef.$("PT").set("extra", {
            ...formRef.$("PT").extra,
            optionList: [
              { label: "10", id: "10" },
              { label: "11", id: "11" },
              { label: "12", id: "12" },
              { label: "13", id: "13" },
              { label: "14", id: "14" },
              { label: "15", id: "15" },
              { label: "16", id: "16" },
              { label: "17", id: "17" },
              { label: "18", id: "18" },
              { label: "19", id: "19" },
              { label: "20", id: "20" },
            ],
          });
        } else if (pptValue === "10" || pptValue === "12") {
          formRef.$("PT").set("extra", {
            ...formRef.$("PT").extra,
            optionList: [
              { label: "15", id: "15" },
              { label: "16", id: "16" },
              { label: "17", id: "17" },
              { label: "18", id: "18" },
              { label: "19", id: "19" },
              { label: "20", id: "20" },
            ],
          });
        }

        break;

      default:
        break;
    }

    // if (pptValue === "5" || pptValue === "8") {
    //   formRef.$("PT").set("extra", {
    //     ...formRef.$("PT").extra,
    //     optionList: [
    //       { label: "10", id: "10" },
    //       { label: "11", id: "11" },
    //       { label: "12", id: "12" },
    //       { label: "13", id: "13" },
    //       { label: "14", id: "14" },
    //       { label: "15", id: "15" },
    //       { label: "16", id: "16" },
    //       { label: "17", id: "17" },
    //       { label: "18", id: "18" },
    //       { label: "19", id: "19" },
    //       { label: "20", id: "20" },
    //     ],
    //   });
    // } else if (pptValue === "10" || pptValue === "12") {
    //   formRef.$("PT").set("extra", {
    //     ...formRef.$("PT").extra,
    //     optionList: [
    //       { label: "15", id: "15" },
    //       { label: "16", id: "16" },
    //       { label: "17", id: "17" },
    //       { label: "18", id: "18" },
    //       { label: "19", id: "19" },
    //       { label: "20", id: "20" },
    //     ],
    //   });
    // }
  };

  showLumpsumOrIncomeFieldBasedOnProductName = (productName, formRef) => {
    if (
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM ||
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB ||
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR ||
      productName === ProductConstants.POS_GUARANTEED_SAVINGS_STAR
    ) {
      formRef.$("lumpsum").set("extra", {
        ...formRef.$("lumpsum").extra,
        isVisible: true,
      });
      formRef.$("income").set("extra", {
        ...formRef.$("income").extra,
        isVisible: false,
      });
    }

    if (productName === ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME || productName === ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME_FIB) {
      formRef.$("lumpsum").set("extra", {
        ...formRef.$("lumpsum").extra,
        isVisible: false,
      });
      formRef.$("income").set("extra", {
        ...formRef.$("income").extra,
        isVisible: true,
      });
    }
  };

  familyIncomeBenefitLogicBasedOnProductName = (productName, formRef) => {
    if (
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM ||
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME ||
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR
    ) {
      formRef.$("family-income-benefit").set("value", { label: "No", id: "3" });
      formRef.$("family-income-benefit").set("disabled", true);
    } else if (
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB ||
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME_FIB ||
      productName === ProductConstants.POS_GUARANTEED_SAVINGS_STAR
    ) {
      formRef.$("family-income-benefit").set("extra", {
        ...formRef.$("family-income-benefit").extra,
        optionList: [
          { label: "No", id: "3" },
          { label: "Family Income Benefit on Critical Illness (CI) and Death", id: "4" },
          { label: "Family Income Benefit on Death", id: "6" },
        ],
      });
    }
  };

  maturityBenefitLogicBasedOnProductName = (productName, formRef) => {
    if (
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM ||
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB ||
      productName === ProductConstants.GUARANTEED_SAVINGS_STAR ||
      productName === ProductConstants.POS_GUARANTEED_SAVINGS_STAR
    ) {
      formRef.$("maturity-benefit").set("value", { label: "Lumpsum", id: "1" });
      formRef.$("maturity-benefit").set("disabled", true);
    }

    if (productName === ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME || productName === ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME_FIB) {
      formRef.$("maturity-benefit").set("value", { label: "Equal Annual Instalments", id: "2" });
      formRef.$("maturity-benefit").set("disabled", true);
    }
  };

  // getIncomeBenefitOptions = (productName, ppt) => {
  //   let payoutList = []
  //   switch (productName) {
  //     case "Premier Guaranteed Income (Short Term Income)":
  //       payoutList = [{ id: ppt, label: ppt }]
  //       break

  //     case "Premier Guaranteed Income (Long Term Income)":
  //       payoutList.push({ id: 15, label: 15 })
  //       payoutList.push({ id: 20, label: 20 })
  //       payoutList.push({ id: 25, label: 25 })

  //       if (ppt === 5 && ppt === 8 && ppt === 10) {
  //         payoutList.push({ id: 30, label: 30 })
  //       }

  //       break

  //     case "Premier Guaranteed Income (Lump sum)":
  //       break

  //     case "Premier Guaranteed Income (Retirement Income)":
  //       payoutList.push({ id: 20, label: 20 })
  //       break

  //     default:
  //       break
  //   }
  //   return payoutList
  // }

  getTotalPrice = (biResponse, biRequest) => {
    try {
      return Number(biResponse?.MODAL_PREM);
    } catch (e) {
      return 0;
    }
  };

  getRiderTax = (data) => {
    let totalRider = 0;
    data?.parsedResponse?.inputValidationStatus?.map((item) => {
      totalRider += Number(item.Tax);
    });
    return totalRider;
  };

  updateBi = (key, value, productId, formRef, productData) => {
    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request;

    switch (key) {
      case keys.PLAN_OPTION:
        biRequest[keys.PLAN_OPTION] = value;
        break;

      case keys.PPT:
        biRequest[keys.PPT] = value;
        break;

      case keys.PT:
        biRequest[keys.PT] = value;
        break;

      case keys.LUMPSUM_AMOUNT:
        biRequest[keys.LUMPSUM_AMOUNT] = value;
        break;

      case keys.EQUAL_ANNUAL_INSTALLMENT:
        biRequest[keys.EQUAL_ANNUAL_INSTALLMENT] = value;
        break;

      case keys.FREQUENCY:
        // let premiumAmount = 0
        // let amount = value.premium
        // switch (Number(value.frequency)) {
        //   case 1:
        //     premiumAmount = "" + amount * 1
        //     break
        //   case 2:
        //     premiumAmount = "" + amount * 2
        //     break
        //   case 3:
        //     premiumAmount = "" + amount * 4
        //     break
        //   case 4:
        //     premiumAmount = "" + amount * 12
        //     break
        // }

        // biRequest[keys.PREMIUM] = premiumAmount
        biRequest[keys.FREQUENCY] = value;
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value;
        break;

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value;
        break;

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value;
        break;

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = value;
        } else {
          biRequest[keys.ADB_RIDER] = "";
        }
        break;

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = value;
        } else {
          biRequest[keys.ATPD_RIDER] = "";
        }
        break;

      case keys.Critical_ILLNESS_RIDER:
        if (value) {
          biRequest[keys.Critical_ILLNESS_RIDER] = value;
        } else {
          biRequest[keys.Critical_ILLNESS_RIDER] = "";
        }
        break;

      case keys.INCOME_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.INCOME_BENEFIT_RIDER] = value;
        } else {
          biRequest[keys.INCOME_BENEFIT_RIDER] = "";
        }
        break;

      case keys.PWB_RIDER:
        if (value) {
          biRequest[keys.PWB_RIDER] = "1";
          biRequest.PWB = "" + value;
        } else {
          biRequest[keys.PWB_RIDER] = "";
        }
        break;

      case keys.HOSPITALITY_CASH_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.HOSPITALITY_CASH_BENEFIT_RIDER] = value;
        } else {
          biRequest[keys.HOSPITALITY_CASH_BENEFIT_RIDER] = "";
        }
        break;

      // case keys.FAMILY_BENEFIT_INCOME_OPTION:
      //   biRequest[keys.FAMILY_BENEFIT_INCOME_OPTION] = value
      //   break

      // case keys.INCOME_BENEFIT_PAYOUT_FREQUENCY:
      //   biRequest[keys.INCOME_BENEFIT_PAYOUT_FREQUENCY] = value
      //   break

      // case keys.INCOME_BENEFIT_PAYOUT_TYPE:
      //   biRequest[keys.INCOME_BENEFIT_PAYOUT_TYPE] = value
      //   break

      // case keys.INCOME_BENEFIT_DURATION:
      //   biRequest[keys.INCOME_BENEFIT_DURATION] = value
      //   break
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value);
    // let errorMessage = Validator.valid(formRef, biData)

    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value)
  };

  getAdbList = () => {
    let list = [];
    for (let index = 0; index <= 100; index = index + 5) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + " L",
        });
      } else {
        list.push({
          id: index,
          label: 1 + " Cr.",
        });
      }
    }
    return list;
  };

  getAtpdList = () => {
    return this.benefitAmountList(100000, 1000000);
  };

  getCriticalIllnessList = () => {
    return this.benefitAmountList(100000, 500000);
  };

  getIncomeBenefit = () => {
    let initialValue = 75000;
    let values = [];

    for (let i = 1; i <= 8; i++) {
      values.push({ id: i * initialValue, label: i * initialValue });
    }

    return values;
  };

  benefitAmountList = (min, max) => {
    let values = [];

    max = Math.round(max / 100000);
    for (let i = 1; i <= max; i++) {
      values.push({ id: i, label: i + " L" });
    }
    return values;
  };

  // fetchPolicyTimeline(element) {
  //   let data = {
  //     youPay: Math.round(element.ANN_PREM),
  //     youGet: Math.round(element.MB_G),
  //     lifeCover: Math.round(element.DB_G),
  //   };
  //   return data;
  // }

  newPolicyTimeLineData(element, inputString, index, productName) {
    const { MODAL_PREM, LUMPSUM_AMOUNT, DB_G, ANN_PREM_YEARLY, MB_G } = element;

    let maturityBenefitOption = inputString.find((item, index) => item.Key === "@DISPLAY_PAYOUT_MODE");
    if (maturityBenefitOption?.Value == "Lumpsum") {
      let data = {
        youPay: Math.round(ANN_PREM_YEARLY),
        youGet: Math.round(MB_G),
        lifeCover: Math.round(DB_G),
      };

      return data;
    } else {
      let data = {
        youPay: Math.round(ANN_PREM_YEARLY),
        youGet: Math.round(MB_G),
        lifeCover: Math.round(DB_G),
      };

      return data;
    }

    // const lumpsumProducts = [ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM, ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB, ProductConstants.POS_GUARANTEED_SAVINGS_STAR];

    // const incomeProducts = [ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME, ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME_FIB];

    // if(lumpsumProducts.includes(productName)){

    //   let premiumAmount = 0;

    //   let inputMode = parseInt(inputString.find(item => item.Key === '@INPUT_MODE').Value)

    //   if (inputMode === 1) {
    //     premiumAmount = Math.round(MODAL_PREM) * 1;
    //   } else if (inputMode === 2) {
    //     premiumAmount = Math.round(MODAL_PREM) * 2;
    //   } else if (inputMode === 3) {
    //     premiumAmount = Math.round(MODAL_PREM) * 4;
    //   } else if (inputMode === 4) {
    //     premiumAmount = Math.round(MODAL_PREM) * 12;
    //   }

    //   let data = {
    //     youPay:Math.round(premiumAmount),
    //     youGet:Math.round(LUMPSUM_AMOUNT),
    //     lifeCover:Math.round(DB_G)
    //   }

    //   return data

    //   }else if(incomeProducts.includes(productName)){

    //     let premiumAmount = 0;

    //     let inputMode = parseInt(inputString.find(item => item.Key === '@INPUT_MODE').Value)

    //     if (inputMode === 1) {
    //       premiumAmount = Math.round(MODAL_PREM) * 1;
    //     } else if (inputMode === 2) {
    //       premiumAmount = Math.round(MODAL_PREM) * 2;
    //     } else if (inputMode === 3) {
    //       premiumAmount = Math.round(MODAL_PREM) * 4;
    //     } else if (inputMode === 4) {
    //       premiumAmount = Math.round(MODAL_PREM) * 12;
    //     }

    //   let data = {
    //     youPay:Math.round(premiumAmount),
    //     youGet:Math.round(LUMPSUM_AMOUNT),
    //     lifeCover:Math.round(DB_G)
    //   }

    //   return data

    // }
  }
}

export default new Utils();
