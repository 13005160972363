// project imports
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import Validator from "./validator";
import * as keys from "./keys";

// package imports
import moment from "moment";
class Utils {


  // ! get total price
  getTotalPrice = (biResponse) => {
    try {
      let totalPremium = 0;
      totalPremium += Number(biResponse.TOTAL_PREM_TAX)
      return totalPremium
    } catch (e) {
      return 0;
    }
  }

  // ! update BI data
  updateBi = async (key, value, productId, form, productData) => {
    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request

    switch (key) {
      case keys.FREQUENCY:
        biRequest[keys.FREQUENCY] = value;
        break;

      case keys.SOURCE_OF_PURCHASE_PRICE:
        biRequest[keys.SOURCE_OF_PURCHASE_PRICE] = value;
        break;
      case keys.ANNUITY_MODE:
        biRequest[keys.ANNUITY_MODE] = value;
        break;

      case keys.PURCHASE_PRICE:
        biRequest[keys.PURCHASE_PRICE] = value;
        break;

      case keys.ANNUITY_OPTION:
        biRequest[keys.ANNUITY_OPTION] = value;
        if (value === "1") {
          biRequest[keys.NAME_OF_THE_SECONDARY_ANNUITANT] = "";
          biRequest[keys.DOB_NAME_OF_THE_SECONDARY_ANNUITANT] = ""
          biRequest[keys.AGE_OF_THE_SECONDARY_ANNUITANT] = ""
          biRequest[keys.GENDER_NAME_OF_THE_SECONDARY_ANNUITANT] = "";
        }
        break;

      case keys.NAME_OF_THE_SECONDARY_ANNUITANT:
        biRequest[keys.NAME_OF_THE_SECONDARY_ANNUITANT] = value;
        break;

      case keys.DOB_NAME_OF_THE_SECONDARY_ANNUITANT:

        // ! for passing dob
        let dob = moment(value).format("DD MMM YYYY")
        biRequest[keys.DOB_NAME_OF_THE_SECONDARY_ANNUITANT] = dob

        // !for passing age
        let age = moment().diff(moment(value), "years").toString()
        biRequest[keys.AGE_OF_THE_SECONDARY_ANNUITANT] = age
        break;

      case keys.GENDER_NAME_OF_THE_SECONDARY_ANNUITANT:
        biRequest[keys.GENDER_NAME_OF_THE_SECONDARY_ANNUITANT] = value;
        break;

      case keys.PREMIUM:
        console.log(value);
        biRequest[keys.PREMIUM] = value;
        break;

      case keys.INCOME:
        biRequest[keys.INCOME] = value;
        break;

      default:
        break;
    }

    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(form, biData);
    // if (errorMessage.length > 0) {
    //   return
    // }


  }

  // fetchPolicyTimeline(element) {
  //   let data = {
  //     youPay: Math.round(element.MODAL_PREM_TAX) || 0,
  //     youGet: Math.round(element.YEARLY_ANN_PAY) || 0,
  //     lifeCover: Math.round(element.DB_G) || 0,
  //     isMilestone: false,
  //     premiumAmt: Math.round(element.PPRICE) || 0
  //   }
  //   return data
  // }

  newPolicyTimeLineData(element,inputString,index) {

    const {PURCHASE_PRICE,SB_G,DB_G} = element
    let youGet = 0
    let annuityMode = parseInt(inputString.find((item) => item?.Key === "@PR_OPTION_5")?.Value);
    if (Number(annuityMode) === 8) {
      youGet = Math.round(SB_G)
    } else if (Number(annuityMode) === 9) {
      youGet = Math.round(Math.round(SB_G) * 2)
    } else if (Number(annuityMode) === 10) {
      youGet = Math.round(Math.round(SB_G) * 4)
    } else {
      youGet = Math.round(Math.round(SB_G) * 12)
    }
    let data = {
      youPay:Math.round(PURCHASE_PRICE),
      youGet:youGet,
      lifeCover:Math.round(DB_G)
    }

    return data

  }

}

export default new Utils()