// Package imports
import { Col, Row } from "react-bootstrap";

const RetirementcalculatedValue = ({ setShowCalculatedValue, calculatedData, onUseTheValueClick }) => {

  let recommendAmount = calculatedData.lumpsum

  return (
    <Col className='d-flex flex-column justify-content-between calculator-modal-top-second-col'>
      <div className='mt-lg-5 pt-2'>
        <p className='font-size-14 font-weight-medium calculated-screentitle-style'>
          We recommend the <span className='modal-label font-size-14'>Retirement Income</span> to be
        </p>

        <p>
          <span className='calculator-rupee font-size-38 font-size-lg-48'>&#8377;</span>
          <span className='font-size-48 font-weight-light modal-label'>{recommendAmount.toLocaleString("en-IN")}</span>
          <span className='font-size-lg-28 font-size-16 font-weight-lg-medium font-weight-light'>{` per annum`}</span>
        </p>
        <p className='calculated-screentitle-style font-size-14 font-weight-medium mb-0'>{`which covers,`}</p>
        <p className='calculated-screentitle-style font-size-14 font-weight-medium mb-0'>{`the lifestyle expenses for full life time after retirement age of 60.`}</p>
      </div>

      <div className='button-container'>
        <Row className='mt-3 pt-2 justify-content-end mb-3'>
          <div className='col col-md-3'>
            <button
              className='btn btn- btn-lg modal-cta-btn modal-cta-btn-link py-1'
              onClick={() => setShowCalculatedValue(false)}
            >
              Recalculate
            </button>
          </div>
          <div
            className='col col-md-3'
            onClick={() => onUseTheValueClick(recommendAmount)}
          >
            <button className='btn btn-secondary btn-lg modal-cta-btn py-1'>Use the Value</button>
          </div>
        </Row>
      </div>
    </Col>
  );
};

export default RetirementcalculatedValue;
