// Project Imports
import ProductNames from "constants/ProductNames";
import ProductPackageId from "constants/ProductPackageId";

const calculateTargetAmount = (targetAmount, biRequest, productData) => {
    let productId = productData.product_id
    let packageId = productData.package_id

    let updatedBiRequest = biRequest
    switch (productId) {
        case ProductNames.premierGuranteedIncome:
            switch (Number(updatedBiRequest.IncomeBenefitPayoutFrequency)) {
                case 1:
                case 5:
                    updatedBiRequest.TargetAmount = Math.round(targetAmount * 1);
                    break;
                case 2:
                    updatedBiRequest.TargetAmount = Math.round(targetAmount * 2);
                    break;
                case 3:
                    updatedBiRequest.TargetAmount = Math.round(targetAmount * 4);
                    break;
                case 4:
                    updatedBiRequest.TargetAmount = Math.round(targetAmount * 12);
                    break;
            }
            return updatedBiRequest

        case ProductNames.wealthUltima:
            switch (packageId) {
                case ProductPackageId.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
                    if (Number(updatedBiRequest.optionId5) === 117) {
                        updatedBiRequest.TargetAmount = Math.round(targetAmount * 1)
                    } else if (Number(updatedBiRequest.optionId5) === 118) {
                        updatedBiRequest.TargetAmount = Math.round(targetAmount * 2);
                    } else if (Number(updatedBiRequest.optionId5) === 119) {
                        updatedBiRequest.TargetAmount = Math.round(targetAmount * 4);
                    } else if (Number(updatedBiRequest.optionId5) === 120) {
                        updatedBiRequest.TargetAmount = Math.round(targetAmount * 12);
                    }
                    return updatedBiRequest

                default:
                    return updatedBiRequest
            }

        default:
            return updatedBiRequest
    }

};

export default calculateTargetAmount;
