import React, { Component } from 'react'
import { Card, Table } from 'react-bootstrap'

import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { InfoArrowBlackImg } from 'constants/Images';
import SolutionStore from '../../store/SolutionStore';
import NeedTypeEnum from 'constants/NeedTypeEnum';
import moment from 'moment'

import './policy-timeline-table.css'
import ProductConstants from 'constants/ProductConstants';
// import SolutionStore from "modules/solution/store/SolutionStore"


const PolicyTimlineTable = observer(class PolicyTimlineTable extends Component {
  state = {}

  fetchProductEndMessage=(productName) =>{
    switch(productName) {
      case ProductConstants.EASY_PENSION:
      case ProductConstants.FOREVER_PENSION:
      case ProductConstants.SARAL_PENSION:
      case ProductConstants.FOREVER_PENSION_DEFERRED_ANNUITY:
      case ProductConstants.FOREVER_PENSION_IMMEDIATE_ANNUITY:    
        return "continues till applicable Life Assured(s) is/are alive" 
        
      default:
        return "plan ends"   
    }
  }

  render() {
    const list = this.props.data.policyTimeLineData.yearObj
    let headerList = this.props.data.policyTimeLineData.headerList
    const laIdMap = this.props.data.policyTimeLineData.laIdMap

    let showYouGet = true
    const needType = headerList[0]
    let columnforLAList = [];
    let columnforHAList = [];
    list.forEach((element,index) => {
      element.laList.forEach((key,laIndex)=>{
        if(columnforLAList.indexOf(laIndex) == -1){
          columnforLAList.push(laIndex);
        }
      })
      element.haList.forEach((key,haIndex)=>{
        if(columnforHAList.indexOf(haIndex) == -1){
          columnforHAList.push(haIndex);
        }
      })
    });


    if(needType === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY || needType === NeedTypeEnum.PROTECTION_FOR_MY_HEALTH){
      
      if(list.some((obj)=> obj.youGetTotal !== 0)){
        headerList = ["Year", "You Pay", "-", "You Get",headerList[headerList.length -1]]
        showYouGet = true  
      }else{
        showYouGet = false
        headerList = headerList.slice(1)
      }
    }
  
    return (
      <Card className='px-3 py-2 policy-timeline-table-card'>
        <Table className='w-100 policy-timeline-table mb-0 rounded'>
          <thead className='policy-timeline-header'>
            <tr>
              {headerList.map((item, index) =>
                item.indexOf('la_') > -1 ?
                  <td key={index} className='font-size-12 year-label font-weight-bold '>Life Cover<br /> [{laIdMap[item.replace('la_', '')]}]</td> :
                  item.indexOf('ha_') > -1 ?
                    <td key={index} className='font-size-12 year-label font-weight-bold '>Health Cover<br /> [{laIdMap[item.replace('ha_', '')]}]</td> :
                    <td key={index} className='font-size-12 year-label font-weight-bold '>{item}</td>
              )}
            </tr>
          </thead>
          <tbody className='policy-timeline-table-body'>
            {list.map((value, index) => {
              return (
                <tr key={index} className='table-row policy-timeline-table-row'>
                  <td className='font-size-14 border border-dark'>
                    {value.year}
                  </td>
                  <td className={`font-size-14 text-primary ${index == list.length - 1 ? 'policy-table-custom-border' : ""}`}>
                    <div className={`${index == list.length - 1 ? '' : "policy-table-custom-border"}`}>
                      {value.youPayTotal === 0 ? "" : global.toINR(value.youPayTotal, true)}
                    </div>
                  </td>

                    <td className={`font-size-14 text-primary ${index == list.length - 1 ? 'policy-table-custom-border' : ""}`}>
                    <div className={`${index == list.length - 1 ? '' : ""}`}>
                      {value.productName.map((name, productIndex) => (name.totalYear - 1 === index && index === value.yearIndex) && (<div key={productIndex}>{name.productName} {this.fetchProductEndMessage(name.productName)}</div>))}
                    </div>
                  </td>

                  {showYouGet && 
                  <>
                  <td className={`showYouGet font-size-14 text-primary ${index == list.length - 1 ? 'policy-table-custom-border' : ""}`}>
                    <div className={` ${index == list.length - 1 ? '' : "policy-table-custom-border"}`}>
                      {value.youGetTotal === 0 ? "" : global.toINR(value.youGetTotal, true)}
                    </div>
                  </td>
                  </>
                  }
                  {columnforLAList.map((singleLA, index) => {
                    
                    return <td key={index} className={`laList font-size-14 text-primary ${index == list.length - 1 ? 'policy-table-custom-border' : ""}`}>
                      <div className={` ${index == list.length - 1 ? '' : "policy-table-custom-border"}`}>
                        {value.laList[singleLA] === 0 ? "" : global.toINR(value.laList[singleLA], true)}
                      </div>
                    </td>
                  })}

                  {columnforHAList.map((singleHA, index) => {
                    return <td key={index} className={`haList font-size-14 text-primary ${index == list.length - 1 ? 'policy-table-custom-border' : ""}`}>
                      <div className={` ${index == list.length - 1 ? '' : "policy-table-custom-border"}`}>
                        {value.haList[singleHA] === 0 ? "" : global.toINR(value.haList[singleHA], true)}
                      </div>
                    </td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </Table>
        <p className='text-left my-3 font-size-14 text-secondary'>*This table excludes underwriting extra premium, frequency loadings on premiums, riders premium and GST, if any.</p>
      </Card>
    );
  }
});

export default PolicyTimlineTable;