import Utils from "./utils";
import * as keys from "./keys";


const FormObserver = (formRef, props) => {
  formRef.$("premium-term").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.PR_PPT,
        field.value.id.toString(),
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("payment-frequency").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.FREQUENCY,
        field.value.id.toString(),
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("annuity-option").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.ANNUITY_OPTION,
        field.value.id.toString(),
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("source-of-purchase-price").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.SOURCE_OF_PURCHASE_PRICE,
        field.value.id.toString(),
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("frequency-of-annuity").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.FREQUENCY_OF_ANNUITY,
        field.value.id.toString(),
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("are-you-nominee").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.ARE_YOU_NOMINEE,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("gender-secondary-annuitant").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.GENDER_OF_THE_SECONDARY_ANNUITANT,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });
};

export default FormObserver;
