
// ! dummmy data used for tab 

const qmsDashboardData = {
	"agent_id": 6,
	"all_journey_lists": [
		{
			"date": "2022-07-11",
			"journey_list": [
				{
					"journey_id": 409,
					"current_journey_screen": "choose_need",
					"proposer_list": [
						{
							"proposer_id": 554,
							"proposer_member_type": "SELF",
							"gender": "male",
							"dob": "1990-07-28",
							"is_selected": true,
							"first_name": "Pranav",
							"middle_name": "C",
							"last_name": "Kode",
							"monthly_income": "65432",
							"nationality": "Indian",
							"current_life_cover": "0",
							"salutation": "Mr.",
							"employee_code": "",
							"email": null,
							"whatsapp_number": null,
							"mobile_number": null,
							"whatsapp_same_as_mobile": null,
							"mobile_country_code": null,
							"whatsapp_country_code": null,
							"monthly_income_dropdown_option": "12500",
							"solution_list": [
								{
									"solution_id": 22,
									"solution_status_id": "application_in_progress",
									"solution_status": "Application In Progress",
									"next_action_text": "",
									"button_text": "",
									"button_color": "#70B603",
									"button_text_color": "#000000",
									"status_text_color": "#70B603",
									"la_list": [
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_status_list": [
												{
													"quote_id": "123",
													"status": "Proposal Completed",
													"status_id": "proposal_completed",
													"policy_number": "policy_number",
													"application_number": "application_number",
													"next_action_text": "next_action_text",
													"button_text": "button_text",
													"button_color": "#70B603",
													"button_text_color": "#FFFFFF",
													"status_text_color": "#70B603"
												},
												{
													"quote_id": "",
													"status": "Lead Generated",
													"status_id": "lead_generated",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												}
											]
										},
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_status_list": [
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												},
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												}
											]
										}
									]
								}
							]
						}

					]
				},
				{
					"journey_id": 435,
					"proposer_list": [
						{
							"proposer_id": 554,
							"proposer_member_type": "SELF",
							"gender": "male",
							"dob": "1990-07-28",
							"is_selected": true,
							"first_name": "Pranav",
							"middle_name": "C",
							"last_name": "Kode",
							"monthly_income": "65432",
							"nationality": "Indian",
							"current_life_cover": "0",
							"salutation": "Mr.",
							"employee_code": "",
							"email": null,
							"whatsapp_number": null,
							"mobile_number": null,
							"whatsapp_same_as_mobile": null,
							"mobile_country_code": null,
							"whatsapp_country_code": null,
							"monthly_income_dropdown_option": "12500",
							"solution_list": [
								{
									"solution_id": 22,
									"solution_status_id": "application_in_progress",
									"solution_status": "Application In Progress",
									"next_action_text": "",
									"button_text": "",
									"button_color": "",
									"la_list": [
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_status_list": [
												{
													"quote_id": "123",
													"status": "status",
													"status_id": "status_id",
													"policy_number": "policy_number",
													"application_number": "application_number",
													"next_action_text": "next_action_text",
													"button_text": "button_text",
													"button_color": "#70B603"
												},
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												}
											]
										},
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_status_list": [
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												},
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												}
											]
										}
									]
								}
							]
						},
						{
							"proposer_id": 554,
							"proposer_member_type": "SELF",
							"gender": "male",
							"dob": "1990-07-28",
							"is_selected": true,
							"first_name": "Pranav",
							"middle_name": "C",
							"last_name": "Kode",
							"monthly_income": "65432",
							"nationality": "Indian",
							"current_life_cover": "0",
							"salutation": "Mr.",
							"employee_code": "",
							"email": null,
							"whatsapp_number": null,
							"mobile_number": null,
							"whatsapp_same_as_mobile": null,
							"mobile_country_code": null,
							"whatsapp_country_code": null,
							"monthly_income_dropdown_option": "12500",
							"solution_list": [
								{
									"solution_id": 22,
									"solution_status": "Quote shared",
									"next_action_text": "",
									"button_text": "",
									"button_color": "",
									"la_list": [
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_id_list": [
												{
													"quotation_status": [
														{
															"quote_id": "",
															"status_id": "",
															"status": "",
															"status_id": "",
															"policy_number": "",
															"application_number": "",
															"next_action_text": "",
															"button_text": "",
															"button_color": ""
														}
													]
												}
											]
										}
									]
								}
							]
						}
					]
				},
				{
					"journey_id": 435,
					"proposer_list": [
						{
							"proposer_id": 554,
							"proposer_member_type": "SELF",
							"gender": "male",
							"dob": "1990-07-28",
							"is_selected": true,
							"first_name": "Pranav",
							"middle_name": "C",
							"last_name": "Kode",
							"monthly_income": "65432",
							"nationality": "Indian",
							"current_life_cover": "0",
							"salutation": "Mr.",
							"employee_code": "",
							"email": null,
							"whatsapp_number": null,
							"mobile_number": null,
							"whatsapp_same_as_mobile": null,
							"mobile_country_code": null,
							"whatsapp_country_code": null,
							"monthly_income_dropdown_option": "12500",
							"solution_list": [
								{
									"solution_id": 22,
									"solution_status_id": "",
									"solution_status": "",
									"next_action_text": "",
									"button_text": "",
									"button_color": "",
									"button_text_color":"#000000",
									"status_text_color":"#70B603",
									"la_list": [
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_status_list": [
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												}
											]
										}
									]
								}
							]
						},
						{
							"proposer_id": 554,
							"proposer_member_type": "SELF",
							"gender": "male",
							"dob": "1990-07-28",
							"is_selected": true,
							"first_name": "Pranav",
							"middle_name": "C",
							"last_name": "Kode",
							"monthly_income": "65432",
							"nationality": "Indian",
							"current_life_cover": "0",
							"salutation": "Mr.",
							"employee_code": "",
							"email": null,
							"whatsapp_number": null,
							"mobile_number": null,
							"whatsapp_same_as_mobile": null,
							"mobile_country_code": null,
							"whatsapp_country_code": null,
							"monthly_income_dropdown_option": "12500",
							"solution_list": [
								{
									"solution_id": 22,
									"solution_status": "Quote shared",
									"next_action_text": "",
									"button_text": "",
									"button_color": "",
									"la_list": [
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_id_list": [
												{
													"quotation_status": [
														{
															"quote_id": "",
															"status": "",
															"status_id": "",
															"policy_number": "",
															"application_number": "",
															"next_action_text": "",
															"button_text": "",
															"button_color": ""
														}
													]
												}
											]
										}
									]
								}
							]
						}
					]
				},
			]
		},
		{
			"date": "2022-07-12",
			"journey_list": [
				{
					"journey_id": 409,
					"proposer_list": [
						{
							"proposer_id": 554,
							"proposer_member_type": "SELF",
							"gender": "male",
							"dob": "1990-07-28",
							"is_selected": true,
							"first_name": "Pranav",
							"middle_name": "C",
							"last_name": "Kode",
							"monthly_income": "65432",
							"nationality": "Indian",
							"current_life_cover": "0",
							"salutation": "Mr.",
							"employee_code": "",
							"email": null,
							"whatsapp_number": null,
							"mobile_number": null,
							"whatsapp_same_as_mobile": null,
							"mobile_country_code": null,
							"whatsapp_country_code": null,
							"monthly_income_dropdown_option": "12500",
							"solution_list": [
								{
									"solution_id": 22,
									"solution_status_id": "application_in_progress",
									"solution_status": "Application In Progress",
									"next_action_text": "",
									"button_text": "",
									"button_color": "",
									"la_list": [
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_status_list": [
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												},
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												}
											]
										},
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_status_list": [
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												},
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												}
											]
										}
									]
								}
							]
						}

					]
				},
				{
					"journey_id": 435,
					"proposer_list": [
						{
							"proposer_id": 554,
							"proposer_member_type": "SELF",
							"gender": "male",
							"dob": "1990-07-28",
							"is_selected": true,
							"first_name": "Pranav",
							"middle_name": "C",
							"last_name": "Kode",
							"monthly_income": "65432",
							"nationality": "Indian",
							"current_life_cover": "0",
							"salutation": "Mr.",
							"employee_code": "",
							"email": null,
							"whatsapp_number": null,
							"mobile_number": null,
							"whatsapp_same_as_mobile": null,
							"mobile_country_code": null,
							"whatsapp_country_code": null,
							"monthly_income_dropdown_option": "12500",
							"solution_list": [
								{
									"solution_id": 22,
									"solution_status_id": "application_in_progress",
									"solution_status": "Application In Progress",
									"next_action_text": "",
									"button_text": "",
									"button_color": "",
									"la_list": [
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_status_list": [
												{
													"quote_id": "124",
													"status": "status",
													"status_id": "status_id",
													"policy_number": "policy_number",
													"application_number": "application_number",
													"next_action_text": "next_action_text",
													"button_text": "button_text",
													"button_color": "#70B603"
												},
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												}
											]
										},
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_status_list": [
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												},
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												}
											]
										}
									]
								}
							]
						},
						{
							"proposer_id": 554,
							"proposer_member_type": "SELF",
							"gender": "male",
							"dob": "1990-07-28",
							"is_selected": true,
							"first_name": "Pranav",
							"middle_name": "C",
							"last_name": "Kode",
							"monthly_income": "65432",
							"nationality": "Indian",
							"current_life_cover": "0",
							"salutation": "Mr.",
							"employee_code": "",
							"email": null,
							"whatsapp_number": null,
							"mobile_number": null,
							"whatsapp_same_as_mobile": null,
							"mobile_country_code": null,
							"whatsapp_country_code": null,
							"monthly_income_dropdown_option": "12500",
							"solution_list": [
								{
									"solution_id": 22,
									"solution_status": "Quote shared",
									"next_action_text": "",
									"button_text": "",
									"button_color": "",
									"la_list": [
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_id_list": [
												{
													"quotation_status": [
														{
															"quote_id": "",
															"status_id": "",
															"status": "",
															"status_id": "",
															"policy_number": "",
															"application_number": "",
															"next_action_text": "",
															"button_text": "",
															"button_color": ""
														}
													]
												}
											]
										}
									]
								}
							]
						}
					]
				},
				{
					"journey_id": 435,
					"proposer_list": [
						{
							"proposer_id": 554,
							"proposer_member_type": "SELF",
							"gender": "male",
							"dob": "1990-07-28",
							"is_selected": true,
							"first_name": "Pranav",
							"middle_name": "C",
							"last_name": "Kode",
							"monthly_income": "65432",
							"nationality": "Indian",
							"current_life_cover": "0",
							"salutation": "Mr.",
							"employee_code": "",
							"email": null,
							"whatsapp_number": null,
							"mobile_number": null,
							"whatsapp_same_as_mobile": null,
							"mobile_country_code": null,
							"whatsapp_country_code": null,
							"monthly_income_dropdown_option": "12500",
							"solution_list": [
								{
									"solution_id": 22,
									"solution_status_id": "application_in_progress",
									"solution_status": "Application In Progress",
									"next_action_text": "",
									"button_text": "",
									"button_color": "",
									"la_list": [
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_status_list": [
												{
													"quote_id": "",
													"status": "",
													"status_id": "",
													"policy_number": "",
													"application_number": "",
													"next_action_text": "",
													"button_text": "",
													"button_color": ""
												}
											]
										}
									]
								}
							]
						},
						{
							"proposer_id": 554,
							"proposer_member_type": "SELF",
							"gender": "male",
							"dob": "1990-07-28",
							"is_selected": true,
							"first_name": "Pranav",
							"middle_name": "C",
							"last_name": "Kode",
							"monthly_income": "65432",
							"nationality": "Indian",
							"current_life_cover": "0",
							"salutation": "Mr.",
							"employee_code": "",
							"email": null,
							"whatsapp_number": null,
							"mobile_number": null,
							"whatsapp_same_as_mobile": null,
							"mobile_country_code": null,
							"whatsapp_country_code": null,
							"monthly_income_dropdown_option": "12500",
							"solution_list": [
								{
									"solution_id": 22,
									"solution_status": "Quote shared",
									"next_action_text": "",
									"button_text": "",
									"button_color": "",
									"la_list": [
										{
											"la_id": 554,
											"proposer_member_type": "SELF",
											"gender": "male",
											"dob": "1990-07-28",
											"is_selected": true,
											"first_name": "Pranav",
											"middle_name": "C",
											"last_name": "Kode",
											"monthly_income": "65432",
											"nationality": "Indian",
											"current_life_cover": "0",
											"salutation": "Mr.",
											"employee_code": "",
											"email": null,
											"whatsapp_number": null,
											"mobile_number": null,
											"whatsapp_same_as_mobile": null,
											"mobile_country_code": null,
											"whatsapp_country_code": null,
											"monthly_income_dropdown_option": "12500",
											"quotation_id_list": [
												{
													"quotation_status": [
														{
															"quote_id": "",
															"status": "",
															"status_id": "",
															"policy_number": "",
															"application_number": "",
															"next_action_text": "",
															"button_text": "",
															"button_color": ""
														}
													]
												}
											]
										}
									]
								}
							]
						}
					]
				},
			]
		}
	]
}

export default qmsDashboardData;



