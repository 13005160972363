
import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from './validator';
import calculateRiderPremium from "utils/calculateRiderPremium";
import calculateProductPremiumRelatedValues from "utils/calculateProductPremiumRelatedValues";

class Utils {
  payoutPercentageListGenerator = () => {
    let list = [];
    let value = 27;
    for (let index = 1; index <= 99; index++) {
      list.push({
        id: value,
        label: index + " %",
      });
      value = value + 1;
    }
    return list;
  };

  generatePT = (age) => {
    let pt = [];
    let maxAge = 80 - Number(age);
    for (let i = maxAge; i >= 10; i--) {
      pt.push({
        id: i,
        label: "" + i,
      });
    }

    return pt;
  };

  generatePPT = (age) => {
    let ppt = [];
    let maxAge = 80 - Number(age);

    for (let i = 10; i <= maxAge; i++) {
      ppt.push({ id: i, label: "" + i })
    }
    ppt.push({
      id: "60 - @Li_entry_age +1",
      label: "Pay Till 60",
    });
    return ppt;
  };

  getTotalPrice = (biResponse) => {
    try {
      let totalPremium = 0;
      //totalPremium += Number(biResponse.MODAL_PREM)
      totalPremium += Number(biResponse.TOTAL_PREM_TAX_DISPLAY)
      return totalPremium
    } catch (e) {
      return 0;
    }
  }

  updateBi = async (key, value, productId, formRef, productData) => {
    let biData = OptimizationStore.fetchBiRequest(productId, productData?.data?.family_product_id)
    let biRequest = biData.data.bi_request
    switch (key) {
      case keys.MARITAL_STATUS:
        biRequest[keys.MARITAL_STATUS] = value;
        if (value == '9') {
          biRequest[keys.SPOUSE_NAME] = "";
          biRequest[keys.SPOUSE_DOB] = "";
          biRequest[keys.SPOUSE_GENDER] = "";
          biRequest[keys.DISPLAY_SPOUSE_DOB] = "";
          biRequest[keys.BETTER_HALF_BENEFIT] = "13";
        }
        break;

      case keys.LI_SMOKE:
        biRequest[keys.LI_SMOKE] = value;
        break;

      case keys.PR_PPT:
        if (value == 60) {
          biRequest[keys.PR_PPT] = "60 - @Li_entry_age +1";
        } else if (value > biRequest[keys.PR_PT]) {
          biRequest[keys.PR_PPT] = biRequest[keys.PR_PT];
        } else {
          biRequest[keys.PR_PPT] = value;
        }
        break;

      case keys.PR_PT:
        biRequest[keys.PR_PT] = value;
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.PR_SA:
        biRequest[keys.PR_SA] = value;
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value;
        break;

      case keys.ELIGIBILITY:
        biRequest[keys.ZP_NATIONALITY] = value.nationality;
        biRequest[keys.ZP_OCCUPATION] = value.occupation;
        biRequest[keys.ZP_EDUCATION] = value.education;
        biRequest[keys.ZP_ANNINCOME] = value.income;
        biRequest[keys.UNDERWRITING] = "2";
        break;

      case keys.TELEMEDICAL:
        biRequest[keys.UNDERWRITING] = "1";
        break;

      case keys.PLAN_OPTION:
        biRequest[keys.PLAN_OPTION] = value;
        break;

      case keys.FREQUENCY:
        let premiumAmount = 0;
        let amount = value.premium;
        switch (Number(value.frequency)) {
          case 1:
            premiumAmount = '' + amount * 1
            break;
          case 2:
            premiumAmount = '' + amount * 2
            break;
          case 3:
            premiumAmount = '' + amount * 4
            break;
          case 4:
            premiumAmount = '' + amount * 12
            break;
        }
        biRequest[keys.PREMIUM] = premiumAmount;
        biRequest[keys.FREQUENCY] = value.frequency;
        break;

      case keys.BETTER_HALF_BENEFIT:
        biRequest[keys.BETTER_HALF_BENEFIT] = value;
        break;

      case keys.ADDTIONAL_BENEFIT:
        biRequest[keys.ADDTIONAL_BENEFIT] = value.additionalBenefit;
        biRequest[keys.TOP_UP_BENEFIT] = value.topUp;
        break;

      case keys.PAYOUT_OPTION:
        biRequest[keys.PAYOUT_PERCENTAGE] = value.lumpsum;
        biRequest[keys.PAYOUT_PERIOD] = value.payoutPeriod;
        biRequest[keys.PAYOUT_OPTION] = value.payoutOption.toString();
        break;

      case keys.PAYOUT_PERIOD:
        biRequest[keys.PAYOUT_PERIOD] = value;
        break;

      case keys.PAYOUT_PERCENTAGE:
        biRequest[keys.PAYOUT_PERCENTAGE] = value;
        break;

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = value;
        } else {
          biRequest[keys.ADB_RIDER] = "";
        }
        break;

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = value;
        } else {
          biRequest[keys.ATPD_RIDER] = "";
        }
        break;

      case keys.Critical_ILLNESS_RIDER:
        if (value) {
          biRequest[keys.Critical_ILLNESS_RIDER] = value;
        } else {
          biRequest[keys.Critical_ILLNESS_RIDER] = "";
        }
        break;

      case keys.HOSPITALITY_CASH_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.HOSPITALITY_CASH_BENEFIT_RIDER] = value;
        } else {
          biRequest[keys.HOSPITALITY_CASH_BENEFIT_RIDER] = "";
        }
        break;

      case keys.TOP_UP_BENEFIT:
        biRequest[keys.TOP_UP_BENEFIT] = value;
        break;

      case keys.SPOUSE_DOB:
        biRequest[keys.SPOUSE_DOB] = value.age.toString();
        biRequest[keys.OSN_SPOUSE_DATE_OF_BIRTH] = value.dob;
        biRequest[keys.DISPLAY_SPOUSE_DOB] = value.dob;
        break;
      case keys.SPOUSE_GENDER:
        biRequest[keys.SPOUSE_GENDER] = value;
        break;
      case keys.SPOUSE_NAME:
        biRequest[keys.SPOUSE_NAME] = value;
        break;
      case keys.INCOME:
        biRequest[keys.INCOME] = value;
        break;
        
        case keys.SPOUSE_TOBACCO_CONSUMER:
          biRequest[keys.SPOUSE_TOBACCO_CONSUMER] = value
        break;  
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);
    // if (errorMessage.length > 0) {
    //   return
    // }

    // let response = await OptimizationStore.updateStoreRequestData(
    //   productId,
    //   biRequest, key, value
    // );
    // return response;
  };

  getCoverAmountList = () => {
    let list = [];
    for (let index = 0; index <= 100; index = index + 5) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + " L",
        });
      } else {
        list.push({
          id: index,
          label: 1 + " Cr.",
        });
      }
    }
    return list;
  };

  getCoverAmountListForCLRnHCB = () => {
    let list = [];
    for (let index = 0; index <= 6; index = index + 1.5) {
      list.push({
        id: index == 0 ? 1 : index,
        label: (index == 0 ? 1 : index) + " L",
      });
    }
    return list;
  };

  fetchPolicyTimeline(element,inputString ,frequency, payoutMode) {

    let incomeType = parseInt(inputString.find((item) => item.Key === "@PR_OPTION_9").Value);

    let lifeCover = 0

    switch (incomeType) {
      case 17:
      case 18:
        // Monthly Income and Monthly Income increasing
        lifeCover = Math.round(element.SA_D) 
      break;

      case 19:
        // 19 for Lumpsum Plus Monthly Income – Level;
        lifeCover = Math.round( Math.round(element.lumpsum2) + Math.round(element.total_milumpsum))
      break;

      case 20:
        // 20 for Lumpsum Plus Monthly Income - increasing
        lifeCover = Math.round( Math.round(element.lumpsum2) + Math.round(element.TotalMI_Incr))
      break;
    
      default:
        lifeCover =  Math.round(element.DB_G)
        break;
    }

    let data = {
      youPay: Math.round(element.ANN_PREM_YEARLY_DIS),
      youGet: 0,
      lifeCover: lifeCover,
    };

    return data;
  }
}

export default new Utils();
