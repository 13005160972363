
import React, { Component } from 'react'
import { Image } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'
import './dropdown.css'

import PropTypes from 'prop-types'
import { CheckSecondaryImg } from 'constants/Images';

class DropdownComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showOverlay: false
    }

    this.onCloseIconClick = this.onCloseIconClick.bind(this);
    this.onDropDownBtnClick = this.onDropDownBtnClick.bind(this);
  }

  onCloseIconClick = () => {
    this.setState({ showOverlay: false })
  }

  onDropDownBtnClick = () => {
    this.setState((state) => ({ showOverlay: !state.showOverlay }))
  }

  render() {
    const { dropdownStyle, menuItemStyle, menuItemFontStyle, menuStyle, menuList, onSelect, value, selectedLabelKey, disabled, type, defaultLabel, showErrorMsg,errorMsg } = this.props;
    const { showOverlay } = this.state;
    return (
      <div className={`dropdown ${type != 'icon' ? 'w-100' : ''}`}>
        <Dropdown show={showOverlay} className={`d-inline-block ${type != 'icon' ? 'w-100' : ''}`} onToggle={this.onDropDownBtnClick}>
          <Dropdown.Toggle variant='' id='dropdown-basic' className={[dropdownStyle, `dropdown-desktop dropdown-toggle d-flex align-items-center justify-content-between p-2 ${showErrorMsg ? 'validation-border' : ''}`]} disabled={disabled}>
            {type == 'icon' &&
              <Image width='30' height='20' className='mr-1' src={value[selectedLabelKey] ? value[selectedLabelKey] : ''} />
            }
            {type != 'icon' &&
              <React.Fragment>
                {selectedLabelKey ? <span className='btn-text-truncate'>{value[selectedLabelKey] ? value[selectedLabelKey] : defaultLabel}</span> : <span className='btn-text-truncate'>{value ? value : defaultLabel}</span>}
              </React.Fragment>
            }
          </Dropdown.Toggle>

          <Dropdown.Menu className={['menu-list', menuStyle]} id='dropdown-menu'>
            {menuList.map((item, index) => {
              let activeItem = selectedLabelKey ? (value[selectedLabelKey] == item[selectedLabelKey] ? 'active' : '') : (value == item ? 'active' : '')
              return (
                <Dropdown.Item id='dropdown-menu-item' className={`${menuItemStyle} dropdown-menu-item ${activeItem} ${menuItemFontStyle? menuItemFontStyle: 'font-size-14 font-size-md-16'}`} key={index} onClick={() => {
                  onSelect(item);
                }}>
                  {type == 'icon' &&
                    <div>
                      <Image src={item.icon} />
                      <span className='ml-2'>{item.label}</span>
                    </div>
                  }
                  {type != 'icon' &&
                    <React.Fragment>
                      {selectedLabelKey ? item[selectedLabelKey] : item}
                    </React.Fragment>
                  }
                  <Image className='mx-1 dropdown-menu-item-check-icon' src={CheckSecondaryImg} alt='check-icon' />
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
        {showErrorMsg && <p className='text-danger mb-0 font-weight-light font-size-11 align-items-start d-flex'>
            {errorMsg || "Select a value"}
            </p>}
      </div>
    )
  }
}

DropdownComponent.defaultProps = {
  defaultLabel: 'select',
  showErrorMsg: false
}

DropdownComponent.propType = {
  menuList: PropTypes.array.isRequired,
  selectedLabelKey: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.object, PropTypes.string]),
  defaultLabel: PropTypes.string
}

export default DropdownComponent;