// react imports
import React, { useEffect } from "react"

// package imports
import { Col, Row } from "react-bootstrap"

// project imports
import { NextButton } from "components"
import * as Constants from "constants/Constants";
import QmsQuoteStatus from "constants/QmsQuoteStatus"
import qmsDashboardPageStore from "modules/qms-flow/qms-dashboard-page/qms-dashboard-page-store"

import "./proposer-details-card.css"
import QmsStorageKeys from "constants/QmsStorageKeys";
import StorageKeys from "constants/StorageKeys";

const policyResumeUrl = process.env.REACT_APP_QMS_TO_PRAGATI_POLICY_RESUME_URL
const policyAppTrackerUrl = process.env.REACT_APP_QMS_TO_PRAGATI_POLICY_APP_TRACKER_URL
const qmsDashboardUrl = process.env.REACT_APP_QMS_DASHBOARD_URL

function ProposerDetailsCardForApplicationInProgress(props) {
    const { proposerData, proposerIndex, laIndex, quotationIndex, quoteStatusData, buttonId, statusId, journeyData } = props

    useEffect(() => {
        if (buttonId && quoteStatusData.status) {
            document.getElementById(buttonId).style.backgroundColor = quoteStatusData.button_color
            document.getElementById(buttonId).style.borderColor = quoteStatusData.button_color
            document.getElementById(buttonId).style.color = quoteStatusData.button_text_color
        }

        if (statusId && quoteStatusData.status) {
            document.getElementById(statusId).style.color = quoteStatusData.status_text_color
        }

    }, [quoteStatusData.buttonId, quoteStatusData.solutionId, quoteStatusData.status])

    const onViewDetailsClick = () => {
        let quotationStatusFormObj = document.getElementById(`quotation-status-form-post-call-${statusId}`);
        console.log("quotationStatusFormObj formObj :: ", quotationStatusFormObj);
        quotationStatusFormObj.submit();
    }

    return (
        <>
            <Col md="2" className="card-inner-padding">
                {(laIndex === 0 && proposerIndex === 0 && quotationIndex === 0) &&
                    <>
                        <p className="mb-0 font-size-14 font-weight-medium client-name">{qmsDashboardPageStore.getFamilyMemberName(journeyData.proposerData, false, journeyData, true)}</p>
                        {proposerData.mobile_number && <a href={`tel:${proposerData.mobile_number}`} className="font-size-12 font-weight-400 mb-0 mr-1 mobile-number">{proposerData.mobile_number}</a>}
                    </>
                }
            </Col>

            <div className='d-none d-md-block border-right mx-md-3'></div>
            <div className='d-block d-md-none border-bottom w-100 mx-md-3'></div>

            <Col md="3" className="card-inner-padding border-left">
                <div className="d-flex align-items-center">
                    <p className="mb-0 font-size-10 font-weight-400 subtitle mr-3">Status</p>
                    <div className='py-3 border-right'></div>
                    <div>
                        <p id={statusId} className={`mb-0 font-size-14 font-weight-medium ml-3`}>{quoteStatusData.status || '-'}</p>
                       { quoteStatusData.application_number && <p className="mb-0 font-size-10 font-weight-300 subtitle ml-3">App ID:{quoteStatusData.application_number || '-'}</p>}
                    </div>
                </div>
            </Col>

            <div className='d-none d-md-block border-right mx-md-3'></div>
            <div className='d-block d-md-none border-bottom w-100 mx-md-3'></div>

            <Col md="6" className="pr-md-1 card-inner-padding">
                <Row>
                    <Col md="6" className="pl-md-0">
                        <p className="mb-0 font-size-10 font-weight-400 subtitle">Next Actions</p>
                        <p className="mb-0 font-size-12 font-weight-400 subtitle">{quoteStatusData.next_action_text || '-'}</p>
                    </Col>

                    <Col md="6" className="justify-content-md-end d-md-flex mt-4 mt-md-0">
                        {quoteStatusData.status && <NextButton
                            id={buttonId}
                            buttonText={quoteStatusData.button_text}
                            className={`status-btn`}
                            onClick={() => onViewDetailsClick()}
                        />}
                    </Col>
                </Row>
            </Col>
            <div className=' border-bottom w-100 d-none d-md-block'></div>

            {quoteStatusData.status_id !== QmsQuoteStatus.PROPOSAL_COMPLETED ? (
                <div>
                    <form className='d-none' id={`quotation-status-form-post-call-${statusId}`} action={policyResumeUrl} method={"POST"}>
                        <input type='text' name='Pragati_Token' value={localStorage.getItem(StorageKeys.AUTH_TOKEN)} />
                        <input type='text' name='LoginCode' value={localStorage.getItem(QmsStorageKeys.LOGIN_CODE)} />
                        <input type='text' name='Vendor' value={localStorage.getItem(QmsStorageKeys.VENDOR)} />
                        <input type='text' name='SourceFrom' value={"QMS"} />
                        <input type='text' name='Policy_number' value={quoteStatusData.policy_number} />
                        <input type='text' name='Application_number' value={quoteStatusData.application_number} />
                        <button type='submit'>Submit</button>
                    </form>
                </div>
            ) : (
                <div></div>
            )}

            {quoteStatusData.status_id === QmsQuoteStatus.PROPOSAL_COMPLETED ? (
                <div>
                    <form className='d-none' id={`quotation-status-form-post-call-${statusId}`} action={policyAppTrackerUrl} method={"POST"}>
                        <input type='text' name='Policy_number' value={quoteStatusData.policy_number} />
                        <input type='text' name='Application_number' value={quoteStatusData.application_number} />
                        <input type='text' name='DOB' value={proposerData.dob} />
                        <input type='text' name='Reverse_URL' value={`${qmsDashboardUrl}?agent_id=${QmsStorageKeys.AGENT_ID}&token=${StorageKeys.AUTH_TOKEN}`} />
                        <input type='text' name='SourceFrom' value={"QMS"} />
                        <input type='text' name='Pragati_Token' value={localStorage.getItem(StorageKeys.AUTH_TOKEN)} />
                        <input type='text' name='LoginCode' value={localStorage.getItem(QmsStorageKeys.LOGIN_CODE)} />
                        <input type='text' name='Vendor' value={"ETLI"} />
                        <button type='submit'>Submit</button>
                    </form>
                </div>
            ) : (
                <div></div>
            )}
        </>
    )
}

export default ProposerDetailsCardForApplicationInProgress
