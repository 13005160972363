// React Imports
import React, { useContext, useEffect, useState } from "react";

// Package Imports
import { Row, Col } from "react-bootstrap";
import { toJS } from "mobx";
import { observer } from "mobx-react";

// Project Imports
import { TitleTextSubtextRight } from "components";
import FamilyDetailsForm from "./form/index";
import FamilyDetailsFormController from "./form/controller";
import AuthStore from "modules/login/store/AuthStore";
import FormContext from "context/FormContext";
import { sendDataToServer } from "./form/utils";
import formObserver from "./form/observer";
import savePragatiNAInboundData from "utils/savePragatiNAInboundData";

// CSS Imports
import "./components/add-family-member-card.css";
import "./family-details-form.css";

const FamilyDetailsPage = ({errorClass}) => {
  const [formController, setFormController] = useState(undefined);
  const formContext = useContext(FormContext);
  
  useEffect(() => {
    if (AuthStore.journeyData !== undefined && formController == undefined) {
      let formController = new FamilyDetailsFormController();
      let journeyId = toJS(AuthStore.journeyId);
      let journeyData = toJS(AuthStore.journeyData);
      let expenseList = toJS(AuthStore.expenseList);
      let lifeCoverList = toJS(AuthStore.lifeCoverList);


      const emiValueCallBack = (expenseName,lastEmiType) =>{

        if(expenseName ==lastEmiType){
          formController.$("show_emi_fields").set("value", true)
          formObserver(formController)
        }else{
          formController.$("show_emi_fields").set("value", false)
        }

      }

      formController.setDefaults(journeyId, journeyData, expenseList, lifeCoverList,emiValueCallBack);
      formContext.setFormController(formController);
      formContext.setCallbackFunctions({
        success: sendDataToServer,
        error: undefined,
      });
      setFormController(formController);
    }
    
  }, [AuthStore.journeyData, formController]);

  useEffect(() => {
     savePragatiNAInboundData(location)
  }, []);

  if (formController === undefined) return null;

  return (
    <main className='my-3 family-details-form container'>
      <Row>
        <Col className='col-12 col-md-9'>
          <p className='text-primary font-weight-medium mb-0 mr-1 font-size-18 mt-5 mt-md-0'>Family Profile</p>
          <p className='mb-0 d-md-flex family-details-form-top-text font-size-12 font-weight-light mb-2'>
            Fill in the family details to get custom solutions. More the data, better the customization.
          </p>
          <TitleTextSubtextRight title='Family Details' subText='( Mandatory )' />
        </Col>
        <FamilyDetailsForm formController={formController} errorClass={errorClass}/>
      </Row>
    </main>
  );
};

export default observer(FamilyDetailsPage);
