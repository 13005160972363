import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import Utils from '../../../../../constants/utils';
import OptimizationStore from '../../../store/OptimizationStore';
import fetchRiderValidation from 'utils/fetchRiderValidation'


class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Minimum Premium Validation
    validatePremium = (formRef) => {
        // let premium = formRef.$('premium').value;
        // let frequency = formRef.$('payment-frequency').value.id;
        // switch (frequency) {
        //     case '1':
        //         if (premium < 15000) {
        //             this.errorMessage.push('Minimum Annual premium is 15000')
        //         }
        //         break;

        //     case '2':
        //         if (premium < 8000) {
        //             this.errorMessage.push('Minimum Half-Yearly premium is 8000')
        //         }
        //         break

        //     case '3':
        //         if (premium < 4000) {
        //             this.errorMessage.push('Minimum Quarterly premium is 4000')
        //         }
        //         break;

        //     case '4':
        //         if (premium < 1500) {
        //             this.errorMessage.push('Minimum Monthly premium is 1500')
        //         }
        //         break;
        // }
    }

    // PPT Validations
    validatePPT = (formRef) => {
        // let pt = formRef.$('policy-term').value.id;
        // let ppt = formRef.$('premium-term').value.id;
        // if (pt == 10) {
        //     if (ppt != 5 && ppt != 7) {
        //         this.errorMessage.push('For Policy term of 10 years, only 5 pay & 7 pay are allowed')
        //     }
        // }
    }

    // Validation based on riders
    ridersAgeValidation = (formRef, productData) => {
        // let age = productData.data.bi_request.LI_ENTRY_AGE
        // let wop = formRef.$('WaiverOfPremiumRider').value
        // let adb = formRef.$('ADB').value
        // let atpd = formRef.$('ATPDRider').value
        // let criticalIllness = formRef.$('CriticalIllnessRider').value
        // let incomeBenefit = formRef.$('IncomeBenefitRider').value
        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // let isRiderSelected = (wop || adb || atpd || criticalIllness || incomeBenefit);

        // if (!isRiderSelected) {
        //     return;
        // }

        // if (isMinimumAge) {
        //     this.errorMessage.push('Minimum Entry age for riders is 18 years')
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push('Maximum Entry age for riders is 65 years')
        // }
    }

    // rider sum assured validation
    riderSAValidation = (form, productData) => {
        // if(productData.data.firstYearResponse) {
        //     let sum_assured = productData.data.firstYearResponse.SA
    
        //     if (form.$('ADB').value) {
        //         var ADB_value = Utils.actualValue(form.$('ADB-dropdown').value)
        //         if (ADB_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }
    
        //     if (form.$('ATPDRider').value) {
        //         var ATPDRider_value = Utils.actualValue(form.$('ATPDRider-dropdown').value)
        //         if (ATPDRider_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }
    
        //     if (form.$('CriticalIllnessRider').value) {
        //         var CriticalIllnessRider_value = Utils.actualValue(form.$('CriticalIllnessRider-dropdown').value)
        //         if (CriticalIllnessRider_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }
    
        //     if (form.$('IncomeBenefitRider').value) {
        //         var IncomeBenefitRider_value = form.$('IncomeBenefitRider-dropdown').value.id
        //         if (IncomeBenefitRider_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }
        // }
    }

    //Validation based on PWB rider
    pwbRidersAgeValidation = (formRef, productData) => {
        // let age = productData.data.bi_request.PROPOSER_AGE
        // let isSameProposer = productData.data.bi_request.SameProposer == true
        // let PWBRider = formRef.$('PayorWaiverBenefitRider').value
        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // if (!PWBRider) {
        //     return;
        // }

        // if (isSameProposer) {
        //     this.errorMessage.push(errorMessageKeys.PWB_RIDER_VALIDATION)
        // }
        // if (isMinimumAge) {
        //     this.errorMessage.push(errorMessageKeys.MINIMUM_RIDER_AGE)
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push(errorMessageKeys.MAXIMUM_RIDER_AGE)
        // }
    }

    // Riders Validation
    ridersValidation = (formRef, productData) => {
        // let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
        // let adb = formRef.$('ADB-dropdown').value
        // let atpd = formRef.$('ATPDRider-dropdown').value
        // let income = formRef.$('IncomeBenefitRider-dropdown').value

        // let adbValidation = fetchRiderValidation(adb, formRef.$('ADB-dropdown').label)
        // if (adbValidation) {
        //     this.errorMessage.push(adbValidation)
        // }

        // let atpdValidation = fetchRiderValidation(atpd, formRef.$('ATPDRider-dropdown').label)
        // if (atpdValidation) {
        //     this.errorMessage.push(atpdValidation)
        // }

        // let criticalIllnessValidation = fetchRiderValidation(criticalIllness, formRef.$('CriticalIllnessRider-dropdown').label)
        // if (criticalIllnessValidation) {
        //     this.errorMessage.push(criticalIllnessValidation)
        // }

        // let incomeValidation = fetchRiderValidation(income, formRef.$('IncomeBenefitRider-dropdown').label)
        // if (incomeValidation) {
        //     this.errorMessage.push(incomeValidation)
        // }
    }

}
export default new Validator();