
import Utils from './utils';
import * as keys from './keys';
import isObjectEmpty from 'utils/isObjectEmpty';

const FormObserver = (formRef, props) => {

    
    formRef.$('PPT').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.incomeStartOptionLogicBasedOnPpt(formRef, props.productName)
            Utils.incomeDurationLogicBasedOnPpt(formRef, props.productName,props)
            let requestData = {
                ppt : field.value.id,
                incomeStartOption: formRef.$("income-start-option").value.id
            }
         Utils.updateBi(keys.PPT, requestData, props.data.product_id, formRef, props)
        }
    })


    formRef.$('income-start-option').observe({
        key: 'value',
        call: ({ field }) => {
                let value = Utils.ptLogicBasedOnProduct(formRef,props.productName)
                if(value){

                const pptValue = formRef.$("PPT").value.id.toString()
                const LumpsumBenefitValue = formRef.$("lump-sum-benefit").value.id.toString()    
                Utils.incomeDurationDropDownLogicBasedOnLumpsumOptionAndIncomeStartOption(formRef,pptValue,props.productName,LumpsumBenefitValue,field.value.id,props)

                    Utils.updateBi(keys.INCOME_START_OPTION, field.value.id, props.data.product_id, formRef, props)
                }
        }
    })

    formRef.$('income-duration').observe({
        key: 'value',
        call: ({ field }) => {
            let value = Utils.ptLogicBasedOnProduct(formRef,props.productName)
            if(value){
                Utils.updateBi(keys.INCOME_DURATION, field.value.id, props.data.product_id, formRef, props)
            }
        }
    })

    formRef.$('income-option').observe({
        key: 'value',
        call: ({ field }) => {
            let payoutType = ""
                Utils.ptLogicBasedOnProduct(formRef,props.productName)
                Utils.incomeBenefitPayOutfrequencyBasedOnIncomeOption(formRef,props.productName)
                Utils.incomeBenefitPayOutTypeBasedOnIncomeOption(formRef,props.productName, formRef.$('PPT').value.id)
                if (field.value.id.toString()=== '5') {
                    formRef.$("income-start-option").set("disabled", false)
                    formRef.$("income-benefit-pay-out-type").set("extra", {
                        ...formRef.$("income-benefit-pay-out-type").extra,
                        isVisible: false
                    })
                } else {
                    formRef.$("income-start-option").set("disabled", true)
                    formRef.$("income-benefit-pay-out-type").set("extra", {
                        ...formRef.$("income-benefit-pay-out-type").extra,
                        isVisible: true
                    })
                    formRef.$("income-benefit-pay-out-type").set("value", formRef.$("income-benefit-pay-out-type").extra.optionList[0])
                    payoutType = formRef.$("income-benefit-pay-out-type").extra.optionList[0].id
                }
                let requestData = {
                    incomeStartOption : formRef.$('income-start-option').value.id,
                    incomeFrequency: formRef.$('income-benefit-pay-out-frequency').value.id,
                    incomeOption: field.value.id, 
                    payoutType: payoutType
                }
                Utils.updateBi(keys.INCOME_OPTION, requestData, props.data.product_id, formRef, props)
        }
    })

    formRef.$('payment-frequency').observe({
        key: 'value',
        call: ({ field }) => {
            let premium = formRef.$("premium").value;
            let frequency = Number(field.value.id);

            let data = {
              premium: premium,
              frequency: frequency,
            };
            Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)
        }
    })

    formRef.$('lump-sum-benefit').observe({
        key: 'value',
        call: ({ field }) => {

            const pptValue = formRef.$("PPT").value.id.toString()
            const incomeStartOption = formRef.$("income-start-option").value.id.toString()

            Utils.incomeDurationDropDownLogicBasedOnLumpsumOptionAndIncomeStartOption(formRef,pptValue,props.productName,field.value.id,incomeStartOption,props)
            Utils.updateBi(keys.LUMPSUM_BENEFIT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('income-benefit-pay-out-frequency').observe({
     key: 'value',
     call: ({ field }) => {
         Utils.updateBi(keys.INCOME_BENEFIT_PAYOUT_FREQUENCY, field.value.id, props.data.product_id, formRef, props)
     }
 })

 formRef.$('income-benefit-pay-out-type').observe({
  key: 'value',
  call: ({ field }) => {
      Utils.updateBi(keys.INCOME_BENEFIT_PAYOUT_TYPE, field.value.id, props.data.product_id, formRef, props)
  }
})

// formRef.$('income-benefit-payout-duration').observe({
//  key: 'value',
//  call: ({ field }) => {
//     Utils.prefillPtBasedOnpptAndIncomeBenefitPayoutDuration(formRef, props.productName)
//      Utils.updateBi(keys.INCOME_BENEFIT_DURATION, field.value.id, props.data.product_id, formRef, props)
//  }
// })


    formRef.$('ADB').observe({
        key: 'value',
        call: ({ field }) => {
            let adbValue = formRef.$('ADB-dropdown').value
            Utils.updateBi(keys.ADB_RIDER, adbValue, props.data.product_id, formRef, props)
        }
    })

    formRef.$('ATPDRider').observe({
        key: 'value',
        call: ({ field }) => {
            let atpdValue = formRef.$('ATPDRider-dropdown').value
            Utils.updateBi(keys.ATPD_RIDER, atpdValue, props.data.product_id, formRef, props)
        }
    })

    formRef.$('CriticalIllnessRider').observe({
        key: 'value',
        call: ({ field }) => {
            let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
            Utils.updateBi(keys.Critical_ILLNESS_RIDER, criticalIllness, props.data.product_id, formRef, props)
        }
    })

   
  formRef.$('HospitalityCashBenefitRider').observe({
    key: 'value',
    call: ({ field }) => {
      let hospitalityCashBenefit = formRef.$('HospitalityCashBenefitRider-dropdown').value
      Utils.updateBi(keys.HOSPITALITY_CASH_BENEFIT_RIDER, hospitalityCashBenefit, props.data.product_id, formRef, props)
    }
  })

    formRef.$('PayorWaiverBenefitRider-dropdown').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PWB_RIDER, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('family-income-benefit-option-dropdown').observe({
        key: 'value',
        call: ({ field }) => {
            if (formRef.$('family-income-benefit-option').value) {
                Utils.updateBi(keys.FAMILY_BENEFIT_INCOME_OPTION, field.value.id, props.data.product_id, formRef, props)
            }
        }
    })
}

export default FormObserver