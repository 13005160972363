import React, { useState, useEffect } from "react";

import { Row, Col, Form } from "react-bootstrap";
import { observer } from "mobx-react";

import { BottomsheetDropdown, ChangeProductSection } from "components";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import Utils from './utils';
import AdditionalRiderBenifitsCard from "components/ui/additional-rider-benifits-card/AdditionalRiderBenifitsCard";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import DeathBenefitAmount from "utils/optimization/deathBenefitAmount";
import ProductConstants from "constants/ProductConstants";
import AdditionalCards from "./components/additional-cards/AdditionalCards";
const PremiumGuaranteedIncome = ({ form, data }) => {
    const [showOverlay, setShowOverlay] = useState(false)

    let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
    let biResponse = productData.parsedResponse;
    let firstYearResponse = productData.firstYearResponse;
    let biRequest = productData.bi_request;
    let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)
    let tabs = data.data.BI
    let updatedValues
    tabs.length > 0 && tabs.map(tab => {
        if (tab.risk === data.selectedTab) {
            updatedValues = tab.updatedValues ? tab.updatedValues : null
        }
    })

    useEffect(() => {
        setShowOverlay(productData.fieldChanged)
    }, [productData.fieldChanged])

    const onHideOverlay = () => {
        setShowOverlay(false)
    }

    return (
        <div className='save-my-children'>

            <Row className='align-items-center mt-md-4'>

                <Col xs={12} md={6} className="mt-3 mt-md-0">
                    <ChangeProductSection
                        productName={productData.product_name || "Premier Guaranteed Income"}
                        cardName={coverLabelBaseOnNeed}
                        changeProductList={data?.data?.changeProductList}
                        productData={data}
                    />
                </Col>
            </Row>

            <DeathCoverGraphSlider
                productTitle='Active Income'
                openChangeReasonModal={false}
                onIncomeChange={() => { }}
                onPremiumChange={form.$('premium').onChange}
                productID={productData.product_id}
                productData={data?.data}
                income_value=""
                premium_value={form.$('premium').value}
                showOverlay={showOverlay}
                hideOverLay={onHideOverlay}
                onClick={() => form.$('premium').extra.calculatePremiun(form, productData.product_id, data)}
                data=""
                cardKey={data.cardKey}
                isIncomeFieldDisable={form.$('premium').disabled}
                premiumFreq={form.$('payment-frequency').value.label}
                formRef= {form}
            />
            <Row className='mt-3 ml-0'>
                <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{"Sum Assured on Death:"}</p>
                <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(productData)}</p>
            </Row>

            <Row className='mt-2 mt-md-4'>
                <Col md={6} className='col-12 mb-3'>

                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('PPT').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('PPT').label}
                        menuList={form.$('PPT').extra.optionList}
                        value={form.$('PPT').value}
                        onSelect={form.$('PPT').onChange}
                        disabled={form.$('PPT').disabled}
                    />

                </Col>
                <Col md={6} className='col-12 mb-3'>

                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('PT').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('PT').label}
                        menuList={form.$('PT').extra.optionList}
                        value={form.$('PT').value}
                        onSelect={form.$('PT').onChange}
                        disabled={form.$('PT').disabled}
                    />

                </Col>

                {!form.$('income-benefit-payout-duration').extra.hide
                    && <Col md={4} className='col-12 mb-3'>

                        <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                            {form.$('income-benefit-payout-duration').label}
                        </div>
                        <BottomsheetDropdown
                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemStyle='d-flex justify-content-between align-items-center'
                            selectedLabelKey='label'
                            bottomsheetTitle={form.$('income-benefit-payout-duration').label}
                            menuList={form.$('income-benefit-payout-duration').extra.optionList}
                            value={form.$('income-benefit-payout-duration').value}
                            onSelect={form.$('income-benefit-payout-duration').onChange}
                            disabled={form.$('income-benefit-payout-duration').disabled}
                        />

                    </Col>}
            {/* </Row> */}

            {/* <Row className='mt-2 mt-md-4'> */}
                {!form.$('lump-sum-benefit').extra.hide
                    && <Col md={4} className='col-12 mb-3'>

                        <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                            {form.$('lump-sum-benefit').label}
                        </div>
                        <BottomsheetDropdown
                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                            menuItemStyle='d-flex justify-content-between align-items-center'
                            selectedLabelKey='label'
                            bottomsheetTitle={form.$('lump-sum-benefit').label}
                            menuList={form.$('lump-sum-benefit').extra.optionList}
                            value={form.$('lump-sum-benefit').value}
                            onSelect={form.$('lump-sum-benefit').onChange}
                            disabled={form.$('lump-sum-benefit').disabled}
                        />

                    </Col>}
                <Col md={4} className='col-12 mb-3'>

                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('payment-frequency').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('payment-frequency').label}
                        menuList={form.$('payment-frequency').extra.optionList}
                        value={form.$('payment-frequency').value}
                        onSelect={form.$('payment-frequency').onChange}
                    />

                </Col>

                {/* <Col md={6} className='col-12 mb-3'>

                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('family-income-benefit-option').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('family-income-benefit-option').label}
                        menuList={form.$('family-income-benefit-option').extra.optionList}
                        value={form.$('family-income-benefit-option').value}
                        onSelect={form.$('family-income-benefit-option').onChange}
                        disabled={form.$('family-income-benefit-option').disabled}
                    />

                </Col> */}

                {!form.$('income-benefit-pay-out-frequency').extra.hide && 
                <Col md={4} className='col-12 mb-3'>

                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('income-benefit-pay-out-frequency').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('income-benefit-pay-out-frequency').label}
                        menuList={form.$('income-benefit-pay-out-frequency').extra.optionList}
                        value={form.$('income-benefit-pay-out-frequency').value}
                        onSelect={form.$('income-benefit-pay-out-frequency').onChange}
                    />

                </Col>}

               {!form.$('income-benefit-pay-out-type').extra.hide && <Col md={4} className='col-12 mb-3'>

                    <div className='mb-2 text-description-text-color font-size-12 font-weight-light'>
                        {form.$('income-benefit-pay-out-type').label}
                    </div>
                    <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle={form.$('income-benefit-pay-out-type').label}
                        menuList={form.$('income-benefit-pay-out-type').extra.optionList}
                        value={form.$('income-benefit-pay-out-type').value}
                        onSelect={form.$('income-benefit-pay-out-type').onChange}
                        disabled={form.$('income-benefit-pay-out-type').disabled}
                    />

                </Col>}

            </Row>
            <Row className='mt-4'>
               { !form.$('family-income-benefit-option').extra.hide &&
                <Col md={6} className='col-12 my-4 '>
                    <AdditionalCards
                        data={data}
                        form={form}
                    />
                </Col>}
                <Col md={6} className='col-12 my-4 '>
                    <AdditionalRiderBenifitsCard
                        data={data}
                        form={form}
                        showCriticalIllness={true}
                        showIncomeBenefit={false}
                        showHospitalityCashBenefit={true}
                        showWOP={true}
                        showPWB={true}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default observer(PremiumGuaranteedIncome);
