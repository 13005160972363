import moment from "moment";
import { toJS } from "mobx";

import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from "./validator";
import ProductConstants from "constants/ProductConstants";

class Utils {
  payoutPercentageListGenerator = () => {
    let list = [];
    let value = 27;
    for (let index = 1; index < 99; index++) {
      list.push({
        id: value,
        label: index + " %",
      });
      value = value + 1;
    }
    return list;
  };

  generatePT = (userAge, optionId) => {
    const maximumAge = 70;
    let ptValues = [];
    let dropDownValues = maximumAge - userAge;
    if (Number(optionId) === 1) {
      for (let i = 10; i <= dropDownValues; i++) {
        ptValues.push({
          id: i,
          label: i,
        });
      }
    } else {
      ptValues.push({
        id: 100 - userAge,
        label: 100 - userAge,
      });
    }
    return ptValues;
  };

  generatePPT = (maxPtValue, userAge, optionId,productName) => {
    let pptValues = [];
    if (Number(optionId) === 1) {
      if (Number(maxPtValue) > 30) {
        for (let i = 5; i <= 30; i++) {
          pptValues.push({
            id: i,
            label: i,
          });
        }

        pptValues.push({
          id: maxPtValue,
          label: maxPtValue,
        });

      } else {
        for (let i = 5; i <= Number(maxPtValue); i++) {
          pptValues.push({
            id: i,
            label: i,
          });
        }
      }
    } else {
      const initialIndex = productName === ProductConstants.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA ? 10 : 7;
        
      for (let i = initialIndex; i <= 30; i++) {
        pptValues.push({
          id: i,
          label: i,
        });

      }
      pptValues.push({
        id: 100 - userAge,
        label: 100 - userAge,
      });
    }
    return pptValues;
  };

  getTotalPrice = (data) => {
    try {
      return data.ANN_PREM;
    } catch (e) {
      return 0;
    }
  };

  updateBi = (key, value, productId, formRef, productData) => {
    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];

    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request;

    switch (key) {
      case keys.FREQUENCY:
        // biRequest[keys.PREMIUM] = value.premium;
        // biRequest[keys.PR_SA] = value.sumAssured;
        biRequest[keys.FREQUENCY] = value.frequency;
        break;

      case keys.PR_PT:
        // biRequest[keys.PR_PPT] = value.pt;
        biRequest[keys.PR_PT] = value.pt;
        // biRequest[keys.PREMIUM] = value.premium;
        // biRequest[keys.PR_SA] = value.sumAssured;
        // biRequest[keys.FREQUENCY] = value.frequency;
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      // case keys.PREMIUM:
      //   biRequest[keys.PREMIUM] = value.premium;
      //   biRequest[keys.PR_SA] = value.sumAssured;
      //   break;

      case keys.POLICY_OPTION:
        biRequest[keys.PR_PPT] = value.ppt;
        biRequest[keys.PR_PT] = value.pt;
        biRequest[keys.POLICY_OPTION] = value.optionId;
        break;

      case keys.FUNDSTRATEGYID:
        biRequest[keys.FUNDSTRATEGYID] = value;
        break;

      case keys.SWP_OPTION:
        biRequest[keys.SWP_OPTION] = value.swp;
        biRequest[keys.FUND_VALUE] = value.percentage;
        biRequest[keys.WITHDRAWL_YEAR] = value;
        biRequest[keys.SWP_FREQUENCY] = value.frequency;
        biRequest[keys.OPTION_ID_6] = value.optionId6;
        break;

      case keys.FUND_VALUE:
        biRequest[keys.FUND_VALUE] = value;
        break;

      case keys.WITHDRAWL_YEAR:
        biRequest[keys.WITHDRAWL_YEAR] = value;
        break;

      case keys.SWP_FREQUENCY:
        biRequest[keys.SWP_FREQUENCY] = value;
        let targetAmount = Number(biRequest?.TargetAmount);
        switch (value) {
          case "117":
          case 117:
              biRequest["TargetAmount"] = Math.round(targetAmount / 1);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
          case "118":
          case 118:
              biRequest["TargetAmount"] = Math.round(targetAmount / 2);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
          case "119":
          case 119:
              biRequest["TargetAmount"] = Math.round(targetAmount / 4);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
          case "120":
          case 120:
              biRequest["TargetAmount"] = Math.round(targetAmount / 12);
              biRequest["ReverseCalcFlag"] = 1;
              biRequest[keys.PREMIUM] = 0;
              break;
        }
        break;

      case keys.LITTLE_CHAMP:
        biRequest[keys.LITTLE_CHAMP] = value;
        break;

      case keys.MANAGED_FUND:
        biRequest[keys.FUNDSTRATEGYID] = "1";
        biRequest[value.EQUITY_LARGE_CAP_FUND] = value[keys.EQUITY_LARGE_CAP_FUND];
        biRequest[keys.EQUITY_LARGE_CAP_FUND] = value[keys.EQUITY_LARGE_CAP_FUND];
        biRequest[keys.EQUITY_TOP_20_FUND] = value[keys.EQUITY_TOP_20_FUND];
        biRequest[keys.BOND_FUND] = value[keys.BOND_FUND];
        biRequest[keys.MANAGED_FUND] = value[keys.MANAGED_FUND];
        biRequest[keys.EQUITY_MID_CAP_FUND] = value[keys.EQUITY_MID_CAP_FUND];
        biRequest[keys.EQUITY_BLUE_CHIP_FUND] = value[keys.EQUITY_BLUE_CHIP_FUND];
        biRequest[keys.GLIT_FUND] = value[keys.GLIT_FUND];
        break;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value);
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value)
  };

  populateWithdrawlPercentage() {
    let withdrawlPercentage = [];
    let count = 7;
    let percentValue = 0.1;
    console.log("initial percentvalue :: ", percentValue.toFixed(2));
    for (let i = parseFloat(percentValue); i <= 12; i += parseFloat(percentValue)) {
      // console.log("print index ::: ", parseFloat(i.toFixed(2)));
      // console.log("print count ::: ", count);
      withdrawlPercentage.push({
        id: count,
        label: i.toFixed(2) + " %",
      });
      if (count == 116) {
        count = count + 6;
      } else {
        count = count + 1;
      }
    }
    // for (let i = 1; i <= 120; i++) {
    //   withdrawlPercentage.push({
    //     id: (i / 10),
    //     label: parseFloat(i / 10).toFixed(2) + ' %'
    //   })
    // }
    return withdrawlPercentage;
  }

  populateWithdrawlyear() {
    let withdrawlYear = [];
    for (let i = 10; i < 67; i++) {
      withdrawlYear.push({
        id: i,
        label: i + " year",
      });
    }
    return withdrawlYear;
  }

  setDistribution(fundList, productId, form, data) {
    let fundObject = {};
    fundList.forEach((item) => {
      let value = item.value || 0;
      let fundKey = item.key;
      fundObject[fundKey] = value;
    });
    this.updateBi(keys.MANAGED_FUND, fundObject, productId, form, data);
  }

  // fetchPolicyTimeline(element, biJson2Data) {
  //   let totalPW = biJson2Data.TOTAL_PW ? Math.round(biJson2Data.TOTAL_PW) : 0
  //   let data = {
  //     youPay: Math.round(element.ANN_PREM_YEARLY),
  //     youGet: totalPW + Math.round(element.FINAL_FV),
  //     lifeCover: Math.round(biJson2Data.DEATH_BEN),
  //     isMilestone: Math.round(element.FINAL_FV) !== 0,
  //     premiumAmt: Math.round(element.ANN_PREM),
  //   };
  //   return data;
  // }

  newPolicyTimeLineData(element, inputString, index, lastItemIndex) {
    let data = {};
    let isSWP = parseInt(inputString.find((item) => item.Key === "@DISPLAY_PR_OPTION_3").Value);
    if (isSWP == "No") {
      const { MODAL_PREM, DEATH_BEN, FV_BEFORE_GA, FINAL_FV } = element;
      data = {
        youPay: Math.round(MODAL_PREM),
        youGet: Math.round(FINAL_FV),
        lifeCover: Math.round(DEATH_BEN),
      };
    } else {
      const { MODAL_PREM, DEATH_BEN, FV_BEFORE_GA, FINAL_FV, TOTALPW } = element;
      data = {
        youPay: Math.round(MODAL_PREM),
        youGet: Math.round(TOTALPW) + (index == lastItemIndex ? Math.round(FINAL_FV) : 0),
        lifeCover: Math.round(DEATH_BEN),
      };
    }
    return data;
  }

  calculateDeathBenefitValueAndUpdateBIRequest(bi_request_data, amount, calculateDBValue) {
    console.log("bi_request_data[LI_ENTRY_AGE]", bi_request_data["LI_ENTRY_AGE"]);
    console.log("bi_request_data[PR_PT]", bi_request_data["PR_PT"]);
    /**
     * Calculate the Death Benefit _[PR_SA] using the following formula and then pass it to the BI Engine:
     * Sum Assured for LA age below 45: Higher of (10, Policy Term/2)*Annual Premium
     * Sum Assured for LA age 45 and above: 10*Annual Premium
     * */
    if (calculateDBValue == true) {
      let deathBenfitValue = 0;
      let laAge = typeof bi_request_data["LI_ENTRY_AGE"] == "string" ? parseInt(bi_request_data["LI_ENTRY_AGE"]) : bi_request_data["LI_ENTRY_AGE"];
      let ptValue = typeof bi_request_data["PR_PT"] == "string" ? parseInt(bi_request_data["PR_PT"]) : bi_request_data["PR_PT"];
      let optionId = Number(bi_request_data["optionId1"]) 
      if (laAge < 45) {
        if(optionId === 1) {
          let multiplyingfactor = Math.max(10, ptValue / 2);
          deathBenfitValue = multiplyingfactor * amount;
        } else {
          let option2PtValue =  70 - laAge
          let Option2multiplyingfactor = Math.max(10, option2PtValue / 2);
          deathBenfitValue = Option2multiplyingfactor * amount;
        }
      } else {
        deathBenfitValue = 10 * amount;
      }
      bi_request_data["PR_SA"] = Math.round(deathBenfitValue).toString();
    } else {
      /**
       * Call validator to check below logic
       * In case of LA Age 45 and above, the Death Benefit field should be shown as an editable field on Customization
       * screen where the minimum Sum Assured allowed should be Higher of (7, ‘Policy Term/4’)*Annual Premium and maximum
       * Sum Assured allowed should be 10*Annual Premium.
       *
       * In case, the application user edits the Sum Assured on the Customization screen, the Need Analysis should pass the
       * entered value to BI Engine to fetch corresponding Modal Premium and Target Amount.
       * function written in validator to check it will get trigger on recalculate button
       *
       */

      bi_request_data["PR_SA"] = Math.round(amount).toString();
    }
    return bi_request_data;
  }
}

export default new Utils();
