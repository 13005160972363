// Package Imports
import { toJS } from "mobx"

// Project Imports
import AuthStore from "modules/login/store/AuthStore"
import FamilyMemberTypeEnum from "modules/family-details-form/FamilymemberTypeEnum";

const createEmiSectionFieldObject = (formController, fieldName, expenseName, expenseRange, selectedEmiList) => {

    let selectedExpenseOption = selectedEmiList.find(expense => expense.expense_label.toLowerCase().replaceAll(" ", '_') === expenseName)

    // Option Ranges
    let fieldObject = {}
    fieldObject.label = "option-range"
    fieldObject.name = "option_range"
    fieldObject.disabled = true

    let optionList = []
    expenseRange.forEach(expense_range_item => {
        optionList.push({
            id: expense_range_item.id,
            label: expense_range_item.value,
            value: expense_range_item.mid_value,
        })
    })
    fieldObject.extra = {
        optionList: optionList
    }
    fieldObject.value = {}

    if (selectedExpenseOption !== undefined) {
        fieldObject.value = {
            id: selectedExpenseOption.expense_range,
            label: selectedExpenseOption.expense_range_key,
            value: selectedExpenseOption.value,
            journeyExpenseId: selectedExpenseOption.id
        }
    }

    formController.$(fieldName).$(expenseName).add(fieldObject)

    // Paid Years
    fieldObject = {}

    fieldObject.label = "Paid"
    fieldObject.name = "paid_years"
    fieldObject.disabled = true

    let paidYearsRangeOptionList = Array.from({ length: 30 }, (_, i) => {
        return {
            id: i,
            label: "" + i,
            value: i,
        }
    })

    fieldObject.extra = {
        optionList: paidYearsRangeOptionList
    }
    fieldObject.value = {}

    if (selectedExpenseOption !== undefined) {
        fieldObject.value = {
            id: selectedExpenseOption.paid_loan_tenure,
            label: selectedExpenseOption.paid_loan_tenure,
            value: selectedExpenseOption.paid_loan_tenure,
        }
    }

    formController.$(fieldName).$(expenseName).add(fieldObject)

    // Total years
    fieldObject = {}

    fieldObject.label = "Total"
    fieldObject.name = "total_years"
    fieldObject.disabled = true

    let totalYearsRangeOptionList = Array.from({ length: 30 }, (_, i) => {
        return {
            id: i + 1,
            label: i + 1,
            value: i + 1,
        }
    })

    fieldObject.extra = {
        optionList: totalYearsRangeOptionList
    }

    fieldObject.value = {}

    if (selectedExpenseOption !== undefined) {
        fieldObject.value = {
            id: selectedExpenseOption.total_loan_tenure,
            label: selectedExpenseOption.total_loan_tenure,
            value: selectedExpenseOption.total_loan_tenure,
        }
    }

    formController.$(fieldName).$(expenseName).add(fieldObject)
}


const createFieldObject = (formController, field_name, expense_type, selectedExpenseList,selectedfamilymemberId ,selectedEmiList = []) => {

    expense_type.forEach((item) => {
        let expenseTypeObj = {}
        expenseTypeObj.label = item.label_name
        expenseTypeObj.name = item.label_name.toLowerCase().replaceAll(" ", '_')

        expenseTypeObj.value = {}
        expenseTypeObj.disabled = true
        expenseTypeObj.extra = {
            optionList: [],
            bottomSheetTitle: "Select an Option"
        }
        let optionList = []
        item.expense_range.forEach(expense_range_item => {
            optionList.push({
                id: expense_range_item.id,
                label: expense_range_item.value,
                value: expense_range_item.mid_value,
            })
        })
        expenseTypeObj.extra.optionList = optionList

        if (selectedExpenseList !== undefined) {

            let familyDetailList = toJS(AuthStore.journeyData.family_details_list)
            let selectedFamilyMemberDetail = familyDetailList.find((singleMember) => singleMember.id === selectedfamilymemberId)
            let selectedExpenseOption
            selectedExpenseOption = selectedExpenseList.find(expense => expense.expense_label === expenseTypeObj.label && expense.family_member_type === selectedFamilyMemberDetail.family_member_type && expenseTypeObj.extra.optionList.some((item) => item.id === expense.expense_range_id))

            if (selectedExpenseOption == undefined) {
                selectedExpenseOption = selectedExpenseList.find(expense => expense.expense_label === expenseTypeObj.label && expense.family_member_type === FamilyMemberTypeEnum.SELF && expenseTypeObj.extra.optionList.some((item) => item.id === expense.expense_range_id))
            }
            if (selectedExpenseOption !== undefined) {
                expenseTypeObj.value = {
                    id: selectedExpenseOption.expense_range_id,
                    label: selectedExpenseOption.expense_range_key,
                    value: selectedExpenseOption.value,
                    journeyExpenseId: selectedExpenseOption.id
                }
                expenseTypeObj.disabled = false
            }
        }

        if (field_name === "monthly_emi") {

            let selectedEmiOption = selectedEmiList.find(expense => expense.expense_label === expenseTypeObj.label)

            selectedEmiList.forEach(expense => {
                expense.expense_label === expenseTypeObj.label
            })

            if (selectedEmiOption !== undefined) {
                expenseTypeObj.disabled = false
            }

            formController.$(field_name).add(expenseTypeObj)

            createEmiSectionFieldObject(formController, field_name, expenseTypeObj.name, item.expense_range, selectedEmiList)

        } else {
            formController.$(field_name).add(expenseTypeObj)
        }

    })

}

const initialDefaults = (formController, expenseListData, lifeCoverList, journeyData, monthlyIncome, dobValue, familymemberId, membersExpenseList, emiList) => {

    let monthlyExpense = expenseListData.find(item => item.name === 'Monthly Expense Details')
    let investmentDetails = expenseListData.find(item => item.name === 'Savings & Investments')
    let monthlyEMIDetails = expenseListData.find(item => item.name === 'Monthly EMI')
    let currentSavingsDetails = expenseListData.find(item => item.name === 'Current Savings')

    createFieldObject(formController, 'monthly_expense', monthlyExpense.expense_label, membersExpenseList, familymemberId)
    createFieldObject(formController, 'monthly_emi', monthlyEMIDetails.expense_label, membersExpenseList, familymemberId, emiList)
    createFieldObject(formController, 'monthly_savings', currentSavingsDetails.expense_label, membersExpenseList, familymemberId)
    createFieldObject(formController, 'current_savings', investmentDetails.expense_label, membersExpenseList, familymemberId)

    formController.$('current_life_cover').set('extra', {
        ...formController.$('current_life_cover').extra,
        optionList: lifeCoverList,
    })

    if (journeyData.family_details_list === undefined || journeyData.family_details_list.length <= 0) return

    let selectedFamilyMember = journeyData.family_details_list.find((item) => item.id === familymemberId)

    let selfFamilyMember = selectedFamilyMember

    if (selfFamilyMember === undefined) return

    let selectedLifeCover = lifeCoverList.find(item => item.id == selfFamilyMember.current_life_cover)

    if (selectedLifeCover !== undefined) {
        formController.$('current_life_cover').value = selectedLifeCover
    }

    formController.$("journey_id").value = journeyData.journey_details.id.toString()
    if (familymemberId !== undefined) {
        formController.$("family_member_id").value = String(familymemberId)
    }

    if (monthlyIncome !== undefined) {
        formController.$("monthly_income").value = monthlyIncome
    } else {
        formController.$("monthly_income").value = selfFamilyMember.monthly_income
    }

    if (dobValue !== undefined) {
        formController.$("date_of_birth").value = dobValue
    } else {
        formController.$("date_of_birth").value = selfFamilyMember.dob
    }

}

export default initialDefaults
