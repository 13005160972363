export const GURANTEED_INCOME = 'GuaranteedIncomeType';

// export const MATURITY_AGE = 'PR_PT';
// export const PR_PPT = 'PR_PPT';

export const FAMILY_INCOME_BENEFIT = 'FamilyIncomeBenefit';
export const WOP_RIDER = 'WaiverOfPremiumRider';
export const ADB_RIDER = 'ADBRider';
export const ATPD_RIDER = 'ATPDRider';
export const Critical_ILLNESS_RIDER = 'CriticalIllnessRider';
export const HOSPITALITY_CASH_BENEFIT_RIDER = 'HospitalityCashBenefitRider';
export const INCOME_BENEFIT_RIDER = 'IncomeBenefitRider';
export const PWB_RIDER = 'PayorWaiverBenefitRider';


export const PPT = 'PR_PPT'
export const PT = 'PR_PT'
export const LUMPSUM_BENEFIT = 'LumpsumBenefit'
export const FREQUENCY = 'INPUT_MODE';
export const FAMILY_BENEFIT_INCOME_OPTION = 'FamilyIncomeBenefitOption';
export const INCOME_BENEFIT_PAYOUT_FREQUENCY = 'IncomeBenefitPayoutFrequency';
export const INCOME_BENEFIT_PAYOUT_TYPE = 'IncomeBenefitPayoutType';
export const INCOME_BENEFIT_DURATION = 'IncomeBenefitPayoutDuration';
export const PREMIUM = 'PR_ANNPREM';

export const INCOME_START_OPTION = 'IncomeStartOption'
export const INCOME_DURATION = 'IncomeDuration'
export const INCOME_OPTION = 'IncomeOption';

