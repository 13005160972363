import React, { Component } from 'react';

import { Modal, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Button as UiButton, BottomsheetDropdown } from '../../../../../components';

import FamilyProtectionStore from '../../../store/FamilyProtectionStore'

const CheckEligibilityModal = observer(class CheckEligibilityModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            productID: '40038',
            nationalityList: [
                { id: 1, label: 'Indian' },
                { id: 2, label: 'NRI' },
                { id: 3, label: 'FNIO/PIO' },
                { id: 4, label: 'other' }
            ],
            occupationList: [
                { id: 'SA', label: 'Salaried' },
                { id: 'SM', label: 'Self Employed (Professional)' },
                { id: 'BM', label: 'Self Employed (Business)' },
                { id: 'A', label: 'Agriculture' },
                { id: 'H', label: 'HouseWife' },
                { id: 'R', label: 'Retired' },
                { id: 'L', label: 'Labourer/Worker' }
            ],
            educationList: [
                { id: 5, label: 'Post Graduate' },
                { id: 4, label: 'Graduate' },
                { id: 3, label: '12th' },
                { id: 2, label: '10th' },
                { id: 1, label: 'Below 10th' },
                { id: 6, label: 'Professional' },
                { id: 8, label: 'Student' },
                { id: 7, label: 'Others' }
            ],
            nationality: { id: 1, label: 'Indian' },
            occupation: '',
            education: '',
            income: '',
            validation: false
        }
    }
    onNationalityChange = (value) => {
        this.setState({ nationality: value })
    }
    onOccupationChange = (value) => {
        this.setState({ occupation: value })
    }
    onEducationChange = (value) => {
        this.setState({ education: value })
    }
    onIncomeChange = (e) => {
        let value = e.target.value
        this.setState({ income: value })
    }
    onCheckEligibility = () => {
        const { nationality, occupation, education, income } = this.state;
        // console.log('CHECK ELIGIBILITY >> ')
        if (!nationality || !occupation || !education || !income) {
            this.setState({ validation: true })
            return
        }
        this.setState({ validation: false })
        let data = {
            nationality: nationality.id,
            occupation: occupation.id,
            education: education.id,
            income
        }
        this.props.dataChange('eligibility', data)
        // this.props.onHideModal()

        const dataList = FamilyProtectionStore.zindagiPlusBenefits
        dataList[3].is_selected = true
    }
    onProceed = () => {
        this.props.dataChange('Underwriting', '2')
        // this.props.onHideModal()
    }

    componentDidUpdate(prevProps) {
        if (this.props.premiumAmount !== prevProps.premiumAmount) {
            this.setState({ income: this.props.premiumAmount })
        }
        // this.setState({ income: this.props.premiumAmount })
    }

    componentDidMount() {
        this.setState({ income: this.props.premiumAmount })
    }

    onHideModal = () => {
        this.props.onHideModal()
        // this.setState({
        //     nationality: '',
        //     occupation: '',
        //     education: '',
        //     income: ''
        // })
    }

    render() {
        const { nationalityList, occupationList, educationList, nationality, occupation, education, income, validation } = this.state;
        const { show, error } = this.props;
        return (
            <Modal
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                backdrop="static"
                className='error-modal'
                show={show}
                onHide={this.onHideModal}
                keyboard={false}
            >
                <Modal.Body className='px-4 pt-4 pb-0'>
                    <React.Fragment>
                        <div className=''>
                            <div className="text-right">
                                <UiButton onClick={this.onHideModal} className='transparent font-size-medium font-size-16 px-4 mb-2 mb-md-0' variant='light' buttonText='X' />
                            </div>
                            <Row>
                                <Col xs='12' md="6" lg='4'>
                                    <div className='p-3 bg-white rounded'>
                                        <div className='mb-2 text-description-text-color text-left font-size-12'>Your Nationality</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Your Nationality'
                                            menuList={nationalityList}
                                            value={nationality}
                                            onSelect={this.onNationalityChange}
                                            showErrorMsg={validation && !nationality}
                                        />
                                    </div>
                                </Col>
                                <Col xs='12' md="6" lg='4'>
                                    <div className='p-3 bg-white rounded'>
                                        <div className='mb-2 text-description-text-color text-left font-size-12'>Your Occupation</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Your Occupation'
                                            menuList={occupationList}
                                            value={occupation}
                                            onSelect={this.onOccupationChange}
                                            showErrorMsg={validation && !occupation}
                                        />
                                    </div>
                                </Col>
                                <Col xs='12' md="6" lg='4'>
                                    <div className='p-3 bg-white rounded'>
                                        <div className='mb-2 text-description-text-color text-left font-size-12'>Your Education</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Your Education'
                                            menuList={educationList}
                                            value={education}
                                            onSelect={this.onEducationChange}
                                            showErrorMsg={validation && !education}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs='12' md="6" lg='4'>
                                    <div className='d-block'>
                                        <div className='ml-3 text-description-text-color text-left font-size-12'>Your Annual Income</div>
                                        <InputGroup disabled={this.props.isPremiumDisable} className='m-0 ml-3 w-auto'>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text className='text-secondary border-primary border-left-0 border-right-0 border-top-0 font-size-14 font-size-md-16 pr-0'>&#8377;</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl
                                                disabled={this.props.isPremiumDisable}
                                                className='border-primary border-left-0 border-right-0 border-top-0 text-secondary font-size-14 font-size-md-16 font-weight-bold'
                                                required
                                                placeholder='Annual Income'
                                                value={this.props.premiumAmount}
                                                onChange={this.onIncomeChange}
                                            />
                                        </InputGroup >
                                        {validation && !income &&
                                            <p className='text-danger ml-3 font-size-14 text-left'>
                                                Please enter annual income
                                            </p>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                </Modal.Body>
                <Modal.Footer className='d-block p-md-4 border-0'>
                    <Row className=''>
                        <Col xs='12' md='12' className="text-center">
                            {error == 'error' &&
                                <div>
                                    <p className="text-danger text-center">User is Not eligible for Tele-Medical</p>
                                    <UiButton onClick={this.onHideModal} className='font-size-medium font-size-16 px-4 mb-2 mb-md-0' variant='secondary' buttonText='Ok' />
                                </div>
                            }
                            {error == 'success' &&
                                <div>
                                    <p className="text-danger text-center">You are elegible for tele medical.</p>
                                    <UiButton onClick={this.onProceed} className='font-size-medium font-size-16 px-4 mb-2 mb-md-0' variant='secondary' buttonText='Proceed' />
                                </div>
                            }
                            {
                                !error &&
                                <UiButton onClick={this.onCheckEligibility} className='font-size-medium font-size-16 px-4 mb-2 mb-md-0' variant='secondary' buttonText='CHECK ELIGIBILITY' />
                            }
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
});


CheckEligibilityModal.defaultProps = {
    hideResumeSession: true,
    onHideModal: () => { },
    title: '',
    subTitle: ''
};

export default CheckEligibilityModal;
