import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import Utils from '../../../../../constants/utils';
import OptimizationStore from '../../../store/OptimizationStore';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // rider sum assured validation
    riderSAValidation = (form, productData) => {
        let sum_assured = productData.data.firstYearResponse.SA

        if (form.$('ADB').value) {
            var ADB_value = Utils.actualValue(form.$('ADB-dropdown').value)
            if (ADB_value > sum_assured) {
                this.errorMessage.push(errorMessageKeys.rider)
            }
        }

        if (form.$('ATPDRider').value) {
            var ATPDRider_value = Utils.actualValue(form.$('ATPDRider-dropdown').value)
            if (ATPDRider_value > sum_assured) {
                this.errorMessage.push(errorMessageKeys.rider)
            }
        }

        if (form.$('CriticalIllnessRider').value) {
            var CriticalIllnessRider_value = Utils.actualValue(form.$('CriticalIllnessRider-dropdown').value)
            if (CriticalIllnessRider_value > sum_assured) {
                this.errorMessage.push(errorMessageKeys.rider)
            }
        }
    }

    ridersAgeValidation = (formRef, productData) => {
        let age = productData.data.bi_request.LI_ENTRY_AGE
        let adb = formRef.$('ADB').value
        let atpd = formRef.$('ATPDRider').value
        let criticalIllness = formRef.$('CriticalIllnessRider').value
        let wop = formRef.$('wop').value

        let isMinimumAge = (age < 18)
        let isMaximumAge = (age > 65)

        let isRiderSelected = (adb || atpd || criticalIllness || wop);

        if (!isRiderSelected) {
            return;
        }

        if (isMinimumAge) {
            this.errorMessage.push(errorMessageKeys.MINIMUM_RIDER_AGE)
        }
        if (isMaximumAge) {
            this.errorMessage.push(errorMessageKeys.MAXIMUM_RIDER_AGE)
        }
    }

}
export default new Validator();