// React Imports
import React from "react"

// Project Imports
import { BottomsheetDropdown } from "components"

import "./add-life-cover.css"

const AddLifeCover = ({ form, data }) => {
  return (
    <div className={`add-life-cover-container d-flex mb-3`}>
      <div className={`cover-pay-out-container text-nowrap`}>
        <p className="font-weight-light font-size-12 mb-1">{form.$("payout-option").label}</p>
        <BottomsheetDropdown
          dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
          menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
          menuItemStyle='d-flex justify-content-between align-items-center'
          selectedLabelKey="label"
          bottomsheetTitle={form.$("payout-option").label}
          menuList={form.$("payout-option").extra.options}
          value={form.$("payout-option").value}
          onSelect={(value) => { form.$("payout-option").extra.selectPayoutOption(form, value, data) }}
        />
      </div>

      {!form.$("payout-percentage").extra.hide &&
        <div className={`income-period-container income-period-container ml-2 text-nowrap`}>
          <p className=" font-weight-light font-size-12 mb-1">{form.$("payout-percentage").label}</p>
          <div className="d-flex align-items-center">
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("payout-percentage").label}
              menuList={form.$("payout-percentage").extra.options}
              value={form.$("payout-percentage").value}
              onSelect={(value) => { form.$("payout-percentage").extra.selectPayoutPercentage(form, value, data) }}
            />

          </div>
        </div>
      }

      {!form.$("payout-period").extra.hide &&
        <div className={`lumpsum-container lumpsum-percent-container ml-2 text-nowrap`}>
          <p className=" font-weight-light font-size-12 mb-1">{form.$("payout-period").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("payout-period").label}
            menuList={form.$("payout-period").extra.options}
            value={form.$("payout-period").value}
            onSelect={(value) => { form.$("payout-period").extra.selectPayoutPeriod(form, value, data) }}
          />
        </div>
      }

    </div>
  )
}

export default AddLifeCover
