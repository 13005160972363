// Project Imports
import ProductNames from "constants/ProductNames";
import ProductPackageId from "constants/ProductPackageId";
const fetchPremiumAmountLabel = (productData) => {
  // For Without tab products
  let premiumAmountLabel = "per annum";
  if (!Array.isArray(productData.BI)) {
    switch (productData.product_id) {
      case ProductNames.criticarePlus:
      case ProductNames.totalProtectPlus:
      case ProductNames.zindagiPlus:
      case ProductNames.saralJeevanBima:
        if (Number(productData?.bi_request?.INPUT_MODE) === 1) {
          premiumAmountLabel = "per annum";
        } else if (Number(productData?.bi_request?.INPUT_MODE) === 2) {
          premiumAmountLabel = "per half year";
        } else if (Number(productData?.bi_request?.INPUT_MODE) === 3) {
          premiumAmountLabel = "per quarter";
        } else {
          premiumAmountLabel = "per month";
        }
        return premiumAmountLabel;
      default:
        return premiumAmountLabel;
    }
  }
  // For With tabs products
  else {
    let selectedRiskData = productData.BI.find((item) => item.risk === productData.selectedRisk);
    switch (selectedRiskData?.product_id) {
      case ProductNames.activeIncomePlan:
      case ProductNames.easyPension:
      case ProductNames.foreverPension:
      case ProductNames.premierGuranteedIncome:
      case ProductNames.cashFlowProtectionPlus:
      case ProductNames.wealthSecurePlus: 
      case ProductNames.guaranteedSavingsStar:
      case ProductNames.guaranteedIncomeStar:
      case ProductNames.wealthUltima:
      case ProductNames.incomeBuilder:
      case ProductNames.saralPension:
      case ProductNames.GCAP:
      case ProductNames.dhanLabh:
      case ProductNames.smartLifestyle:
      case ProductNames.singlePayEndowmentAssuarancePlan:
      case ProductNames.wealthPlus:
      case ProductNames.wealthPremier:
      case ProductNames.wealthGainPlus:
      case ProductNames.posSaralNivesh:
      case ProductNames.flexiSavingsPlan:
        if (Number(selectedRiskData?.bi_request?.INPUT_MODE) === 1) {
          premiumAmountLabel = "per annum";
        } else if (Number(selectedRiskData?.bi_request?.INPUT_MODE) === 2) {
          premiumAmountLabel = "per half year";
        } else if (Number(selectedRiskData?.bi_request?.INPUT_MODE) === 3) {
          premiumAmountLabel = "per quarter";
        } else if (Number(selectedRiskData?.bi_request?.INPUT_MODE) === 4) {
          premiumAmountLabel = "per month";
        } else if (Number(selectedRiskData?.bi_request?.INPUT_MODE) === 5) {
          premiumAmountLabel = "(single)";
        }
        return premiumAmountLabel;
      default:
        return premiumAmountLabel;

    }
  }
};

export default fetchPremiumAmountLabel;
