// Project Imports
import checkIsValueEmpty from "utils/checkIsValueEmpty"
import { EMAIL_REGEX } from 'constants/Constants'



function validateMobileNo(value, validator) {

  if(value === 0){
    return [false, "This field is mandatory"]
  }  

 if(value.length == 0){
     return [false, "This field is mandatory"]
 }

 if (!validator.isInt(value.toString())) {
     return [false, "Please enter only numbers"]
 }

 let firstCharacter = parseInt(value.charAt(0))

 if (firstCharacter < 6) {
     return [false, "First digit should be between 6 - 9 "]
 }

 if (value.length !== 10) {
     return [false, "Please enter 10 digit mobile number"]
 }

 return [true, ""]
}


function validateEmail(value, validator) {

 if (validator.isEmpty(value)) {
  return [false, "This field is mandatory"]
}

 if (!EMAIL_REGEX.test(value)) {
     return [false, "Please enter a valid Email"]
 }

 return [true, ""]
}

function validateName(value, validator) {

 if (validator.isEmpty(value)) {
  return [false, "This field is mandatory"]
}
 return [true, ""]
}


export function validatedSalutation({ field, form, validator }){

    let currentFieldValue = field.value

    if(checkIsValueEmpty(currentFieldValue)){
        return [false, "This field is mandatory"]
    }
    return [true, ""]
}



export function validationForEmailAndMobileNumber({ field, form, validator }) {

 let currentFieldName = field.name
 let currentFieldValue = field.value

 switch (currentFieldName) {
     case "mobile_number":
    //  case "whatsapp_mobile_number":
         // mobile no
         return validateMobileNo(currentFieldValue, validator)
     case "email":
         //email
         return validateEmail(currentFieldValue, validator)
         break;
 }

 return [true, ""]

}

export function validationForName({field, form, validator}){

 let currentFieldValue = field.value

   return validateName(currentFieldValue, validator)

}

export function validationForCountryCode({field, form, validator}) {

    let currentFieldValue = field.value

    if (currentFieldValue.length > 2 || currentFieldValue.length <= 0) {
     return [false, "enter valid country code"]
   }
    return [true, ""]
   }