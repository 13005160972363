import OptimizationStore from "modules/optimization/store/OptimizationStore";
import CashFlowTableModal from "../cash-flow-table/CashFlowTable";

export default function ChangeProductSection(props) {
    return (
        <div>
            <p className="mb-2 font-size-12 font-weight-light total-premium-container-heading">Underlying Product</p>
            <div className="d-flex align-items-center ">
                <p className='mb-0 font-size-14 text-primary font-weight-medium'>{props.productName}</p>
            </div>
            <div className="d-flex mb-2">
                <CashFlowTableModal productName={props.productName} familyProductId={props.familyProductId} cardName={props.cardName} productData={props.productData} />
                <span
                    className={`ml-2 btn p-0 LA-btn font-size-14 font-size-md-12 font-weight-light ${props.changeProductList === undefined || props.changeProductList?.length === 0 ? 'text-gray-opacity-49' : 'text-primary'}`}
                    onClick={() => {
                        props.changeProductList === undefined || props.changeProductList?.length === 0 ?
                            OptimizationStore.isReasonModalOpen = false : OptimizationStore.isReasonModalOpen = true
                        OptimizationStore.changeProductList = [...props.changeProductList]
                        OptimizationStore.setChangeProductData(props.productData)
                    }}
                > [ Change Product ]
                </span>
            </div>
        </div>
    )
}