// Project Imports

import enableFunction from "./needSelectedDeSelectedFunction"

const FormObserver = (formRef) => {

  
  formRef.$("protection_for_my_family").observe({
    key: "disabled",
    call: ({ field }) => {
      enableFunction(formRef)
    },
  })

  formRef.$("protection_for_my_health").observe({
    key: "disabled",
    call: ({ field }) => {
      enableFunction(formRef)
    },
  })

  formRef.$("grow_my_money").observe({
    key: "disabled",
    call: ({ field }) => {
      enableFunction(formRef)
    },
  })

  formRef.$("save_for_children").observe({
    key: "disabled",
    call: ({ field }) => {
      enableFunction(formRef)
    },
  })

  formRef.$("save_for_retirement").observe({
    key: "disabled",
    call: ({ field }) => {
      enableFunction(formRef)
    },
  })
}

export default FormObserver
