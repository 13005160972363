
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {
    console.log("PROPS>>>>>>", props)

    formRef.$('policy-term').observe({
        key: 'value',
        call: ({ field }) => {
            let currentPt = field.value
            let list = Utils.calculatePPT(currentPt)
            formRef.$('premium-paying-term').set('extra', {
                ...formRef.$('premium-paying-term').extra,
                optionList: list
            })
            formRef.$('premium-paying-term').set('value', field.value)
            Utils.updateBi(keys.PR_PT, currentPt.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('tobaco-user').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.LI_SMOKE, field.value.id, props.data.product_id, formRef, props)
        }
    })


    formRef.$('payout-mode').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PAYOUT_MODE, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('premium-paying-term').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PR_PPT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('plan-option').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PLAN_OPTION, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('payment-frequency').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.INPUT_MODE, field.value.id, props.data.product_id, formRef, props)
        }
    })


}

export default FormObserver