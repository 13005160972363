// react imports
import React from "react";

// package imports
import { Row, Col, Form } from "react-bootstrap";
import { observer } from "mobx-react";
import moment from "moment";
import { BottomsheetDropdown, DeathCoverPriceComponent, DatepickerModal, ChangeProductSection } from "../../../../../components";

// project imports
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import Utils from "./utils";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";

// css imports
import "../../optimization-card/family-collapse-view.css";

const SaralJeevanBima = ({ form, data }) => {
  let productData = data.data;
  let biResponse = productData.parsedResponse;
  let firstYearResponse = productData.firstYearResponse;
  let biRequest = productData.bi_request;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey);

  return (
    <div className=''>
      <Row className='mt-4 mb-3 pb-1'>
        <Col xs={12} lg={7}>
          <DeathCoverPriceComponent
            firstAmount={biResponse?.SA ? biResponse.SA : productData.bi_request.PR_SA}
            secondAmount={biResponse.MODAL_PREM}
            thirdAmount='980'
            showAdditionalText={false}
            showSecondColAmount={false}
            secondColBottomText={form.$("payment-frequency").value.label}
            firstColTitleText={coverLabelBaseOnNeed}
            secondColTitleText='Price'
          />
        </Col>
      </Row>
      <Row>
        <Col md={3} className='col-12 mt-2 mt-md-0 mb-2'>
          <p className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("covid-vaccination-status").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("covid-vaccination-status").label}
            menuList={form.$("covid-vaccination-status").extra.optionList}
            value={form.$("covid-vaccination-status").value}
            onSelect={(value) => form.$("covid-vaccination-status").extra.changeVaccinationStatus(form, value, data)}
          />
        </Col>

        <Col xs={12} md={4} className='mt-3 mt-md-0'>
          <ChangeProductSection
            productName={productData.product_name || "Saral Jeevan Bima"}
            familyProductId={productData.family_product_id}
            cardName={coverLabelBaseOnNeed}
            changeProductList={productData.changeProductList}
            productData={data}
          />
        </Col>

        <Col xs={12} className='mt-2 mt-md-0'>
          <DeathCoverGraphSlider
            productData={productData}
            saLabel='Income'
            cardKey={data.cardKey}
            productTitle='Saral Jeevan Bima'
            openChangeReasonModal={() => {}}
            onIncomeChange={form.$("income").onChange}
            onPremiumChange={form.$("premium-amt").onChange}
            dataChange={() => {}}
            hideOverLay={() => {}}
            productID={""}
            income_value={form.$("income").value}
            premium_value={form.$("premium-amt").value}
            showOverlay={false}
            onClick={() => form.$("premium-amt").extra.calculatePremiun(form, form.$("premium-amt").value, data)}
            data={""}
            isIncomeFieldDisable={false}
            isPremiumDisable={false}
            premiumFreq={form.$("payment-frequency").value.label}
            onIncomeBlur={() => {
              form.$("income").extra.calculateIncome(form, data);
            }}
            formRef={form}
            recommendedPremium={productData?.recommended_premium}
            recommendedTargetValue={productData?.recommended_sum_assured}
          />
        </Col>
      </Row>

      <Row className='mt-3'>

        <Col xs={12} md={6}>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("policy-term").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("policy-term").label}
            menuList={form.$("policy-term").extra.options}
            value={form.$("policy-term").value}
            onSelect={form.$("policy-term").onChange}
            disabled={false}
          />
        </Col>

        <Col xs={12} md={6} className='mt-2 product-form'>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("premium-paying-term").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("premium-paying-term").label}
            menuList={Utils.generatePPT(form.$("policy-term").value.id, form.$("payment-frequency").value.id)}
            value={form.$("premium-paying-term").value}
            onSelect={form.$("premium-paying-term").onChange}
            disabled={false}
          />
        </Col>

        <Col xs={12} md={6} className='mb-3 mb-md-0'>
          <p className='mb-2 font-size-12 font-weight-light '>{form.$("payment-frequency").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("payment-frequency").label}
            menuList={form.$("payment-frequency").extra.options}
            value={form.$("payment-frequency").value}
            onSelect={form.$("payment-frequency").onChange}
            disabled={form.$("payment-frequency").disabled}
          />
        </Col>

        {/* <Col xs={12} md={6} className="mt-2">
          <div className="mb-2 font-size-12 font-weight-light ">
            {form.$("occupation").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("occupation").label}
            menuList={form.$("occupation").extra.options}
            value={form.$("occupation").value}
            onSelect={form.$("occupation").onChange}
          />
        </Col> */}

        {/* <Col xs={12} md={6} className="mt-2 product-form">
          <div className="mb-2 font-size-12 font-weight-light ">
            {form.$("annual-income-of-proposer/parent/spouse").label}
          </div>
          <Form.Control
            className="font-size-12 font-weight-light text-black"
            placeholder="annual income"
            value={form.$("annual-income-of-proposer/parent/spouse").value}
            onChange={(e)=> form.$("annual-income-of-proposer/parent/spouse").extra.annualIncomeChange(e,form,data)}
            onBlur={() => form.$('annual-income-of-proposer/parent/spouse').extra.annualIncomeBlur(form, data)}
            disabled={false}
          />
        </Col> */}

        {/* <Col xs={12} md={6} className="mt-2">
          <div className="mb-2 font-size-12 font-weight-light ">
            {form.$("education").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("education").label}
            menuList={form.$("education").extra.options}
            value={form.$("education").value}
            onSelect={form.$("education").onChange}
            disabled={false}
          />
        </Col> */}

        {/* <Col xs={12} md={6} className="mt-2">
          <div className="mb-2 font-size-12 font-weight-light ">
            {form.$("education").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("education").label}
            menuList={form.$("education").extra.options}
            value={form.$("education").value}
            onSelect={form.$("education").onChange}
            disabled={false}
          />
        </Col> */}

        {/* <Col xs={12} md={6} className="mt-2 product-form">
          <div className="mb-2 font-size-12 font-weight-light ">
            {form.$("sum-assured").label}
          </div>
          <Form.Control
            className="font-size-12 font-weight-light text-black"
            placeholder={form.$("sum-assured").label}
            value={form.$("sum-assured").value}
            onChange={(e)=> form.$("sum-assured").extra.sumAssuredAmtChange(e,form,data)}
            onBlur={() => form.$('sum-assured').extra.sumAssuredAmtBlur(form, data)}
            disabled={false}
          />
        </Col> */}
      </Row>
    </div>
  );
};

export default observer(SaralJeevanBima);
