// Project Imports
import Product from 'constants/ProductConstants';
import ProductNames from 'constants/ProductNames';

const fetchSurvivalBenefit = (solutionData) => {

    let survivalBenefitApplicable = false
    let payoutModeList = []
    let lumpsumPtList = []
    let incomePtList = []
    let swpAndIncomeStartYear = []
    let benefitList = []
    let gisProductIncomeStartOptionArray = []
    let gssProductIncomeStartOptionArray = []
    let productPTPPTArray = []
    let pgiIncomeStartYear = []
    let pgiIncomeEndYear = []
    let pgiLumpsumEndYear = []

    // Pass 7 for 2
    // Pass 8 for 5
    // Pass 9 for 6
    // Pass 10 for 7
    // Pass 11 for 8
    // Pass 12 for 9
    // Pass 13 for 10
    // Pass 14 for 11
    // Pass 15 for 12
    // Pass 16 for 13
    // Pass 17 for 14
    // Pass 18 for 15
    // Pass 20 for 20
    // Pass 21 for 22

    // for end data use PT
    // for gss startyear = PT, end year PT + 5
    solutionData.coverage.forEach((coverage) => {
        coverage.life_assured_list.forEach(lifeAssured => {
            lifeAssured.product_details.length > 0 && lifeAssured.product_details.forEach(obj => {
                let productID = obj.data.InputString.find((item) => item.Key === '@PR_ID')?.Value
                let productName = obj.product_name;
                let pt = obj.data.InputString.find((item) => item.Key === '@PR_PT')?.Value
                let ppt = obj.data.InputString.find((item) => item.Key === '@PR_PPT')?.Value
                let swpStartYear = obj.data.InputString.find((item) => item.Key === '@OPTION_VALUE_6')?.Value
                let incomeOption = obj.data.InputString.find((item) => item.Key === '@PR_OPTION_2')?.Value
                let payoutDuration = obj.data.InputString.find((item) => item.Key === '@DISPLAY_PR_OPTION_4')?.Value

                let outputJSON = obj.data.OutputJSON;
                let finalOutputJSon = []
                if (ProductNames.wealthUltima === productID) {
                    finalOutputJSon = outputJSON[0];
                } else {
                    finalOutputJSon = outputJSON[1].length > 0 ? outputJSON[1] : outputJSON[0];
                }

                let gisIncomeStartOption = obj.data.InputString.find((item) => item.Key === '@DISPLAY_PR_OPTION_3')?.Value
                let incomeStartYear = obj.data.InputString.find((item) => item.Key === '@PR_OPTION_2')?.Value
                let productPTPPTObj = {
                    productID,
                    productName,
                    pt,
                    ppt
                }
                productPTPPTArray.push(productPTPPTObj)
                switch (obj.product_name) {
                    // PT PPT
                    case Product.CASH_FLOW_PROTECTION_PLUS:
                    // case Product.GCAP:
                    // case Product.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
                    // case Product.SMART_LIFESTYLE:
                    // case Product.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
                    case Product.INCOME_BUILDER:
                    case Product.SECURED_INCOME_OPTION_INCOME_BUILDER:
                    // case Product.POS_SARAL_NIVESH:
                    case Product.WEALTH_ULTIMA:
                    case Product.LITTLE_CHAMP_WEALTH_ULTIMA:
                    case Product.WEALTH_PLUS:
                    case Product.RISING_STAR_WEALTH_PLUS:
                    case Product.WEALTH_GAIN_PLUS:
                    case Product.WEALTH_SECURE_PLUS:
                    // case Product.DHAN_LABH:
                    case Product.EASY_PENSION:
                    case Product.WEALTH_PREMIER:
                        survivalBenefitApplicable = true
                        if (obj.payout_mode === 'lumpsum') lumpsumPtList.push(Number(pt))
                        if (obj.payout_mode === 'income') incomePtList.push(Number(pt))
                        break;

                    // Guaranteed Income for life
                    case Product.SARAL_PENSION:
                    case Product.FOREVER_PENSION_IMMEDIATE_ANNUITY:
                    case Product.FOREVER_PENSION_DEFERRED_ANNUITY:
                    case Product.POS_FOREVER_PENSION:
                        if (!benefitList.some((item) => item === 'Guaranteed Income for life'))
                            benefitList.push('Guaranteed Income for life')
                        break;

                    // SWP start year
                    case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
                    case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
                    case Product.CHILD_VARIANT_WEALTH_SECURE_PLUS:
                        swpAndIncomeStartYear.push(Number(swpStartYear))
                        break;

                    // Income Start
                    case Product.ACTIVE_INCOME_PLAN:
                    case Product.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
                    case Product.ACTIVE_INCOME_PLAN_WITH_FIB:
                    case Product.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
                    case Product.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
                        if (incomeOption === '3') swpAndIncomeStartYear.push(2)

                        if (incomeOption === '4') swpAndIncomeStartYear.push(Number(ppt)+1)
                        break;

                    case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM:
                    case Product.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
                        pgiLumpsumEndYear.push(pt)
                        break;

                    case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
                    case Product.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
                    case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME:
                    case Product.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
                    case Product.PREMIER_GUARANTEED_RETIREMENT_INCOME:
                        let youGetYear = 0
                        finalOutputJSon.forEach((item) => { if (item.INCOME_BEN_PAY_OUT !== 0 && youGetYear === 0) { youGetYear = item.POLICYYEAR } })
                        pgiIncomeStartYear.push(youGetYear)
                        pgiIncomeEndYear.push(pt)
                        break;

                    case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
                    case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
                    case Product.GUARANTEED_INCOME_STAR:
                    case Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
                    case Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
                    case Product.GUARANTEED_INCOME_STAR_FIB:
                    case Product.POS_GUARANTEED_INCOME_STAR:
                    case Product.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
                    case Product.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:

                        gisProductIncomeStartOptionArray.push(gisIncomeStartOption)

                        break;

                    case Product.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:

                        switch (gisIncomeStartOption) {

                            case 7:
                                benefitList.push(`Get guaranteed income every 5 years from ${2} till ${pt}`)
                                break;

                            case 8:
                                benefitList.push(`Get guaranteed income every 5 years from ${5} till ${pt}`)
                                break;

                            case 9:
                                benefitList.push(`Get guaranteed income every 5 years from ${6} till ${pt}`)
                                break;

                            case 10:
                                benefitList.push(`Get guaranteed income every 5 years from ${7} till ${pt}`)
                                break;

                            case 11:
                                benefitList.push(`Get guaranteed income every 5 years from ${8} till ${pt}`)
                                break;

                            case 12:
                                benefitList.push(`Get guaranteed income every 5 years from ${9} till ${pt}`)
                                break;

                            case 13:
                                benefitList.push(`Get guaranteed income every 5 years from ${10} till ${pt}`)
                                break;

                            case 14:
                                benefitList.push(`Get guaranteed income every 5 years from ${11} till ${pt}`)
                                break;

                            case 15:
                                benefitList.push(`Get guaranteed income every 5 years from ${12} till ${pt}`)
                                break;

                            case 16:
                                benefitList.push(`Get guaranteed income every 5 years from ${13} till ${pt}`)
                                break;

                            case 17:
                                benefitList.push(`Get guaranteed income every 5 years from ${17} till ${pt}`)
                                break;

                            case 18:
                                benefitList.push(`Get guaranteed income every 5 years from ${15} till ${pt}`)
                                break;

                            case 20:
                                benefitList.push(`Get guaranteed income every 5 years from ${20} till ${pt}`)
                                break;

                            case 21:
                                benefitList.push(`Get guaranteed income every 5 years from ${21} till ${pt}`)
                                break;

                            default:
                                break;
                        }
                        break;

                    case Product.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
                    case Product.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
                    case Product.POS_SARAL_NIVESH:
                    case Product.DHAN_LABH:
                    case Product.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
                    case Product.GCAP:
                        benefitList.push(`Get guaranteed lumpsum amount on ${pt}th year`)
                        break;

                    case Product.GUARANTEED_SAVINGS_STAR_INCOME:
                    case Product.POS_GUARANTEED_SAVINGS_STAR:

                        gssProductIncomeStartOptionArray.push(pt)

                        break;

                    case Product.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
                    case Product.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
                    case Product.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
                        if (incomeStartYear === '4') swpAndIncomeStartYear.push(2)
                        if (incomeStartYear === '5') swpAndIncomeStartYear.push(5)
                        if (incomeStartYear === '6') swpAndIncomeStartYear.push(9)
                        if (incomeStartYear === '7') swpAndIncomeStartYear.push(11)
                        if (incomeStartYear === '8') swpAndIncomeStartYear.push(13)
                        if (incomeStartYear === '9') swpAndIncomeStartYear.push(16)
                        break;

                    case Product.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
                    case Product.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
                    case Product.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
                    case Product.SMART_LIFESTYLE:
                        survivalBenefitApplicable = true
                        lumpsumPtList.push(Number(pt))
                        break;


                    default:
                        survivalBenefitApplicable = false
                        break;
                }

                if (survivalBenefitApplicable) payoutModeList.push(obj.payout_mode)
            })
        })
    })

    const minIncomeStartOptionForGis = Math.min(...gisProductIncomeStartOptionArray)
    const minStartOptionForGss = Math.min(...gssProductIncomeStartOptionArray)
    let minIncomeStartYear = Math.min(...pgiIncomeStartYear);

    let maxLumpsumEndYear = Math.max(...pgiLumpsumEndYear)
    let maxIncomeEndYear = Math.max(...pgiIncomeEndYear);

    const minPt = Math.min(...incomePtList);
    const modeCount = {};
    payoutModeList.forEach(element => { modeCount[element] = (modeCount[element] || 0) + 1; });

    let showGuaranteedBenefit = productPTPPTArray.some((item) => item.productID !== ProductNames.premierGuranteedIncome)

    if (showGuaranteedBenefit) {
        if (payoutModeList.some((item) => item === "lumpsum"))
            benefitList.push(`Get lumpsum amount on ${lumpsumPtList.map(lumpsum => { return lumpsum + 'th ' })} year.`)

        if (payoutModeList.some((item) => item === "income")){

            const productArray = productPTPPTArray.map(item => item.productName)
            if(productArray.length === 1 && (productArray.includes(Product.INCOME_BUILDER) || productArray.includes(Product.SECURED_INCOME_OPTION_INCOME_BUILDER))){
                benefitList.push(`Guaranteed Income starting from ${minPt + 1} year`)    
            }else if(productArray.includes(Product.EASY_PENSION)){
                benefitList.push(`Create a retirement corpus on ${yearSuffixLabel(Number(minPt))} year.`)
            }else{
                benefitList.push(`Regular Income starting from ${minPt} year.`)
            }
        }
    }

    const minStartYear = Math.min(...swpAndIncomeStartYear);

    if (checkInfitity(minStartYear)) {
        benefitList.push(`Get regular income from ${yearSuffixLabel(minStartYear)} year`)
    }

    let gisPT = 0
    if (checkInfitity(minIncomeStartOptionForGis)) {
        gisPT = productPTPPTArray.find((item) => (item.productName === Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED) ||
            (item.productName === Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS) ||
            (item.productName === Product.GUARANTEED_INCOME_STAR) ||
            (item.productName === Product.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB) ||
            (item.productName === Product.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB) ||
            (item.productName === Product.GUARANTEED_INCOME_STAR_FIB) ||
            (item.productName === Product.POS_GUARANTEED_INCOME_STAR) ||
            (item.productName === Product.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED) ||
            (item.productName === Product.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS)).pt
        // benefitList.push(`Get guaranteed income from ${minIncomeStartOptionForGis} till ${gisPT}`)
    }

    // if (checkInfitity(minStartOptionForGss)) {
    // benefitList.push(`Get guaranteed income from ${minStartOptionForGss} till ${minStartOptionForGss + 5}`)
    // }

    // For PGI INCOME Products, GSS, GIS
    let minIncomeList = []
    let maxIncomeList = []

    if (checkInfitity(minIncomeStartOptionForGis)) minIncomeList.push(minIncomeStartOptionForGis)
    if (checkInfitity(minStartOptionForGss)) minIncomeList.push(minStartOptionForGss)
    if (checkInfitity(minIncomeStartYear)) minIncomeList.push(minIncomeStartYear)


    if (checkInfitity(gisPT)) maxIncomeList.push(gisPT)
    if (checkInfitity(minStartOptionForGss)) maxIncomeList.push(minStartOptionForGss + 5)
    if (checkInfitity(maxIncomeEndYear)) maxIncomeList.push(maxIncomeEndYear)

    let minIncome = Math.min(...minIncomeList)
    let maxIncome = Math.max(...maxIncomeList)

    if (checkInfitity(minIncome)) {
        benefitList.push(`Get guaranteed income from ${minIncome}th year till ${maxIncome}th year`)
    }

    let maxLumpsumList = []
    if (checkInfitity(maxLumpsumEndYear)) maxLumpsumList.push(maxLumpsumEndYear)

    let maxLumpsum = Math.max(...maxLumpsumList)
    if (checkInfitity(maxLumpsum)) benefitList.push(`Get guaranteed amount on ${maxLumpsum}th year`)

    return benefitList
}

const checkInfitity = (value) => {
    return value !== Infinity && value !== -Infinity
}

const yearSuffixLabel = (year) => {
    if (year == 1) {
        return year + "st";
    }
    if (year == 2) {
        return year + "nd";
    }
    if (year == 3) {
        return year + "rd";
    }
    return year + "th";
}

export default fetchSurvivalBenefit