import React, { useEffect, useState } from "react";

import { Col, Row, Form } from "react-bootstrap";

import moment from "moment";
import { observer } from "mobx-react";

import { BottomsheetDropdown, DatepickerModal, ChangeProductSection } from "components";
import AmountCalculation from "../../optimization-card/amount-calculation/AmountCalculation";
import DeathCoverGraphSlider from "modules/family-protection/components/family-protection-card/DeathCoverGraphSlider";
import FundDistributionModal from "../../optimization-card/fundDistributionModal";
import Utils from './utils';
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";

const WealthSecurePlus = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false)

  let productData = data.data;
  let firstYearResponse = productData.firstYearResponse;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])

  const onHideOverlay = () => {
    setShowOverlay(false)
  }

  return (
    <div className="save-my-children">
      <Row className="align-items-center mt-md-4">

        <Col xs={12} md={6} className="mt-3 mt-md-0">
          <ChangeProductSection
            productName={productData.product_name || "Wealth Secure+"}
            cardName={coverLabelBaseOnNeed}
            changeProductList={productData.changeProductList}
            productData={data}
          />
        </Col>

        <Col md={6} className="col-12">
          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("payment-frequency").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("payment-frequency").label}
            menuList={form.$("payment-frequency").extra.optionList}
            value={form.$("payment-frequency").value}
            onSelect={form.$("payment-frequency").onChange}
          />

        </Col>
      </Row>
      <Row className="mt-2 mt-md-4">
        <Col md={6} className="col-12">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("systematic-withdrawal-option").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("systematic-withdrawal-option").label}
            menuList={form.$("systematic-withdrawal-option").extra.optionList}
            value={form.$("systematic-withdrawal-option").value}
            onSelect={form.$("systematic-withdrawal-option").onChange}
            disabled=""
          />

        </Col>

        <Col md={6} className="col-12">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("fund-value").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("fund-value").label}
            menuList={form.$("fund-value").extra.optionList}
            value={form.$("fund-value").value}
            onSelect={form.$("fund-value").onChange}
            disabled={form.$("systematic-withdrawal-option").value.id == 6}
          />

        </Col>
      </Row>
      <Row className="mt-2 mt-md-4">
        <Col md={6} className="col-12">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("swp-frequency").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("swp-frequency").label}
            menuList={form.$("swp-frequency").extra.optionList}
            value={form.$("swp-frequency").value}
            onSelect={form.$("swp-frequency").onChange}
            disabled={form.$("systematic-withdrawal-option").value.id == 6}
          />

        </Col>
        <Col md={6} className="col-12">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("year-swp-payable").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("year-swp-payable").label}
            menuList={form.$("year-swp-payable").extra.optionList}
            value={form.$("year-swp-payable").value}
            onSelect={form.$("year-swp-payable").onChange}
            disabled={form.$("systematic-withdrawal-option").value.id == 6}
          />

        </Col>
      </Row>

      <DeathCoverGraphSlider
        productTitle="Active Income"
        openChangeReasonModal={false}
        onIncomeChange={() => { }}
        onPremiumChange={form.$("premium").onChange}
        hideOverLay={onHideOverlay}
        productID={productData.product_id}
        productData={productData}
        showOverlay={showOverlay}
        cardKey={data.cardKey}
        income_value=""
        premium_value={form.$("premium").value}
        onClick={() => form.$("premium").extra.calculatePremiun(form, data)}
        data=""
        isIncomeFieldDisable={form.$("premium").disabled}
        premiumFreq={form.$("payment-frequency").value.label}
        formRef={form}
      />
      <Row className="mt-2 mt-md-4">
        <Col md={6} className="col-12">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("policy-term").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("policy-term").label}
            menuList={form.$("policy-term").extra.optionList}
            value={form.$("policy-term").value}
            onSelect={form.$("policy-term").onChange}
            disabled=""
          />

        </Col>
        <Col md={6} className="col-12">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("premium-term").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("premium-term").label}
            menuList={form.$("premium-term").extra.optionList}
            value={form.$("premium-term").value}
            onSelect={form.$("premium-term").onChange}
          />

        </Col>
      </Row>
      <Row className="mt-2 mt-md-4 mb-3">
        <Col md={6} className="col-12">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("policy-option").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("policy-option").label}
            menuList={form.$("policy-option").extra.optionList}
            value={form.$("policy-option").value}
            onSelect={form.$("policy-option").onChange}
            disabled=""
          />

        </Col>
        <Col md={6} className="col-12 d-md-flex mt-2 mt-md-0">

          <div className={` ${form.$("fund-strategy").value.id !== 1 ? 'col px-0' : 'col-4 col-md-9'}`}>
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("fund-strategy").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("fund-strategy").label}
              menuList={form.$("fund-strategy").extra.optionList}
              value={form.$("fund-strategy").value}
              onSelect={form.$("fund-strategy").onChange}
              disabled=""
            />
          </div>

          {form.$("fund-strategy").value.id == 1 && (
            <React.Fragment>
              <div className="col-md-3">
                <span className="font-size-12 font-weight-light">Fund Distribution</span>
                <span
                  className="a cursor-pointer d-md-block font-size-14 text-primary font-weight-light mt-2"
                  onClick={() =>
                    form
                      .$("fund-strategy")
                      .extra.toggleFundDistributionModal(
                        form,
                        true,
                        form.$('fund-strategy').extra.fundList,
                        data.data.product_id
                      )
                  }
                >
                  {" "}
                  <u>Click to View</u>
                </span>
              </div>
            </React.Fragment>
          )}
        </Col>
        <Col md={6} className="col-12 mt-2 mt-md-4">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("spouse-name").label}
          </div>

          <Form.Control
            className="font-size-12 font-weight-light text-black product-form-field"
            placeholder={form.$("spouse-name").label}
            value={form.$("spouse-name").value}
            onChange={form.$('spouse-name').onChange}
            onBlur={() => form.$('spouse-name').extra.onSpouseNameChange(form, data)}
            disabled={form.$('policy-option').value.id == 1 || form.$('policy-option').value.id == 133}
          />

        </Col>
        <Col md={6} className="col-12 mt-2 mt-md-4">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("spouse-gender").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("spouse-gender").label}
            menuList={form.$("spouse-gender").extra.optionList}
            value={form.$("spouse-gender").value}
            onSelect={form.$("spouse-gender").onChange}
            disabled={form.$('policy-option').value.id == 1 || form.$('policy-option').value.id == 133}
          />

        </Col>
        <Col md={6} className="col-12 mt-2 mt-md-4">

          <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
            {form.$("spouse-dob").label}
          </div>

          <DatepickerModal
            plain
            date={form.$("spouse-dob").value}
            textStyle='font-size-12 font-weight-light text-black text-nowrap overflow-hidden txt-overflow'
            onChange={(value) => form.$("spouse-dob").extra.changeDob(form, value, data)}
            disabled={form.$('policy-option').value.id == 1 || form.$('policy-option').value.id == 133}
            minDate={moment().subtract(18, 'years').toString()}
          />

        </Col>

      </Row>
      <FundDistributionModal
        formRef={form}
        show={form.$("fund-strategy").extra.showFundDistributionModal}
        data={data}
        fundList={form.$('fund-strategy').extra.fundList}
        submitButtonText={form.$('fund-strategy').extra.setDistributionButtonText}
        onHide={(fundList) => {
          form.$("fund-strategy").extra.toggleFundDistributionModal(form, false, fundList, data.data.product_id)
        }}
        onSetDistribution={form.$('fund-strategy').extra.onSetDistribution}
      />

    </div>
  );
};

export default observer(WealthSecurePlus);
