import dvr from "mobx-react-form/lib/validators/DVR";
import vjf from "mobx-react-form/lib/validators/VJF";

import { Form } from "mobx-react-form";
import validatorjs from "validatorjs";

import formObserver from "./observers";
import setInitialDefaults from "./initialDefaults.js";
import Utils from "./utils";
import * as keys from "./keys";

class SimplyProtectFormController extends Form {
  plugins() {
    console.log("plugins is called");
    return {
      dvr: dvr(validatorjs),
      vjf: vjf(),
    };
  }

  setup() {
    console.log("Setup is called");
    return {
      fields: [
        {
          name: "tobaco-user",
          label: "Tobacco Consumer?",
          type: "radio",
          options: [
            {
              id: "1",
              label: "Yes",
            },
            {
              id: "0",
              label: "No",
            },
          ],
        },
        {
          name: "payout-mode",
          label: "Death cover payout mode options",
          placeholder: "Please select death benefit options",
          rules: "required:string",
          disabled: false,
          extra: {
            optionList: [
              {
                id: "8",
                label: "Income Benefit",
              },
              {
                id: "7",
                label: "Lumpsum",
              },
            ],
          },
        },
        {
          name: "plan-option",
          label: "Plan Option",
          placeholder: "Please select your plan option",
          rules: "required:number",
          extra: {
            optionList: [
              {
                id: "1",
                label: "Life Cover",
              },
              {
                id: "2",
                label: "Life Cover + Accidental Death Benefit"
              },
              {
                id: "5",
                label: "Life Cover + Waiver of Premium on Accidental Total & permanently Disablility",

              },
              {
                id: "6",
                label: "Life Cover + Waiver of Premium on critical Illness",
              },
            ],
          },
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "Please enter your Premium",
          disabled: false,
          extra: {
            calculatePremium: (form, data) => {
              // let premium = form.$("premium").value;
              // Utils.updateBi(keys.PR_ANNPREM, premium, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [],
          },
        },
        {
          name: "premium-paying-term",
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [],
          },
        },
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Annual",
              },
            ],
          },
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data);
  }
}

export default SimplyProtectFormController;
