// React Imports
import React from "react"

// Package Imports
import PropTypes from "prop-types"
import Modal from "react-bootstrap/Modal"
import { observer } from "mobx-react"
import { toJS } from "mobx"

// project imports
import ProtectionForHealthModalForm from "./health-protection-modal-form"

// CSS Imports
import "./add-insured-health-pop-up-modal.css"

const AddInsuredHealthPopUpModal = (props) => {
  
  return (
    <div className="add-insured-pop-up-div d-flex align-items-center">
      <Modal
        show={props.showProtectionForHealthModal}
        backdrop="static"
        onHide={props.hideHealthPopUpModal}
        as="section"
        className="add-insured-pop-up-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <ProtectionForHealthModalForm
          hideHealthPopUpModal={props.hideHealthPopUpModal}
        />
      </Modal>
    </div>
  )
}

AddInsuredHealthPopUpModal.propTypes = {
  showProtectionForHealthModal:PropTypes.bool,
  hideHealthPopUpModal:PropTypes.func.isRequired,
}

export default observer(AddInsuredHealthPopUpModal)
