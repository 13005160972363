// Package Imports
import moment from "moment"

// Project Imports
import isInteger from "utils/validations/isInteger"
import { isName } from "utils/validations/isName"
import { validateSelfDetails } from "./validation"

// Dummy obejct to prefill all the family members field
const FamilyMemberFieldStructureObject = {
    name: "",
    label: "",
    placeholder: "",
    extra: {
        isSelected: false
    },
    fields: [
        {
            name: 'id',
            value: '',
            type: 'hidden'
        },
        {
            name: "salutation",
            value: {},
            error: "Please fill the details",
            validators: [validateSelfDetails],
            extra: {
                bottomSheetTitle: "Select Salutation",
                optionList: [
                ]
            }
        },
        {
            name: "first_name",
            type: "text",
            placeholder: "First Name",
            // validators: [isName]
            validators: [validateSelfDetails]
        },
        {
            name: "middle_name",
            type: "text",
            placeholder: "Middle Name",
            // validators: [isName]
        },
        {
            name: "last_name",
            type: "text",
            placeholder: "Last Name",
            // validators: [isName]
            validators: [validateSelfDetails]
        },
        {
            name: "gender",
            label: "Gender",
            validators: [validateSelfDetails],
            value: {},
            extra: {
                bottomSheetTitle: 'Select Gender',
                optionList: [
                    { id: 1, label: 'Male', value: 'Male' },
                    { id: 2, label: 'Female', value: 'Female' }
                ]
            }
        },
        {
            name: "dob",
            label: "Date of Birth",
            validators: [validateSelfDetails],
            extra: {
                // calculate moment object
                minDate: moment().subtract(18, 'years'),
                maxDate: ""
            }
        },
        {
            name: "nationality",
            label: "Nationality",
            placeholder: "Nationality",
            validators: [validateSelfDetails],
            value: {},
            extra: {
                bottomSheetTitle: "Select Nationality",
                optionList: [
                ]
            }
        },
        {
            name: "monthly_income_dropdown_option",
            type: "hidden",
            validators: [validateSelfDetails],
            extra: {
                bottomSheetTitle: "Select Monthly Income",
            }
        },
        {
            name: "monthly_income_value",
            label: "Monthly Income",
            placeholder: "Enter Monthly Income",
            type: "text",
            validators: [isInteger, validateSelfDetails],
            extra: {
                visible: true
            }
        },
        {
            name: "family_member_type",
            label: "",
            type: "text"
        },
        {
            name: "current_life_cover",
            label: "Current Life Cover",
            value: {},
            validators: [validateSelfDetails],
            extra: {
                bottomSheetTitle: "Select Current Life Cover",
                optionList: []
            }
        }
    ]
}


export default FamilyMemberFieldStructureObject