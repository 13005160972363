// Project Imports
import ProductNames from "constants/ProductNames"

const checkTargetAmountGreaterThanRecommendedAmount = (targetAmount, recoAmount, biRequest, productId) => {
    let isTargetAmtGreaterThanRecoAmt = false
    let isValueBetween25LakhAnd50Lakh = false
    let updatedTargetAmount = typeof (targetAmount) === "string" ? parseInt(targetAmount) : targetAmount
    let recommendedAmount = typeof (recoAmount) === "string" ? parseInt(recoAmount) : recoAmount

    switch (productId) {
        case ProductNames.zindagiPlus:
        case ProductNames.totalProtectPlus:
        case ProductNames.saralJeevanBima:
            if (Math.round(updatedTargetAmount) > Math.round(recommendedAmount)) {
                isTargetAmtGreaterThanRecoAmt = true
                updatedTargetAmount = recommendedAmount

                biRequest["PR_SA"] = Math.round(updatedTargetAmount).toString();
                biRequest["PR_ANNPREM"] = 0;
                biRequest["TargetAmount"] = "";
                biRequest["ReverseCalcFlag"] = 0;
            } else {
                if ((Math.round(updatedTargetAmount) > 2500000 && Math.round(updatedTargetAmount) < 5000000 ) &&  Math.round(recommendedAmount) <= 2500000) {
                    isValueBetween25LakhAnd50Lakh = true
                    updatedTargetAmount = recommendedAmount
    
                    biRequest["PR_SA"] = Math.round(updatedTargetAmount).toString();
                    biRequest["PR_ANNPREM"] = 0;
                    biRequest["TargetAmount"] = "";
                    biRequest["ReverseCalcFlag"] = 0;
                }
    
                if ((Math.round(updatedTargetAmount) > 2500000 && Math.round(updatedTargetAmount) < 5000000) &&  Math.round(recommendedAmount) >= 5000000) {
                    isValueBetween25LakhAnd50Lakh = true
                    updatedTargetAmount = 5000000
    
                    biRequest["PR_SA"] = Math.round(updatedTargetAmount).toString();
                    biRequest["PR_ANNPREM"] = 0;
                    biRequest["TargetAmount"] = "";
                    biRequest["ReverseCalcFlag"] = 0;
                }
            }
            break;
            
        default:
            break
    }

    return {
        isTargetAmtGreaterThanRecoAmt: isTargetAmtGreaterThanRecoAmt,
        updatedTargetAmount: updatedTargetAmount,
        isValueBetween25LakhAnd50Lakh:isValueBetween25LakhAnd50Lakh,
        biRequest: biRequest
    }
}

export default checkTargetAmountGreaterThanRecommendedAmount