
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {
    formRef.$('policy-term').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PR_PT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('premium-term').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.PR_PPT, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('payment-frequency').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.FREQUENCY, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('death-benefit-option').observe({
        key: 'value',
        call: ({ field }) => {
            Utils.updateBi(keys.DEATH_BENEFIT_OPTION, field.value.id, props.data.product_id, formRef, props)
        }
    })

    formRef.$('ADB').observe({
        key: 'value',
        call: ({ field }) => {
            let adbValue = formRef.$('ADB-dropdown').value
            Utils.updateBi(keys.ADB_RIDER, adbValue, props.data.product_id, formRef, props)
        }
    })

    formRef.$('ATPDRider').observe({
        key: 'value',
        call: ({ field }) => {
            let atpdValue = formRef.$('ATPDRider-dropdown').value
            Utils.updateBi(keys.ATPD_RIDER, atpdValue, props.data.product_id, formRef, props)
        }
    })

    formRef.$('CriticalIllnessRider').observe({
        key: 'value',
        call: ({ field }) => {
            let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
            Utils.updateBi(keys.CRITICAL_ILLNESS_RIDER, criticalIllness, props.data.product_id, formRef, props)
        }
    })

    formRef.$('IncomeBenefitRider').observe({
        key: 'value',
        call: ({ field }) => {
            let incomeBenefit = formRef.$('IncomeBenefitRider-dropdown').value
            Utils.updateBi(keys.INCOME_BENEFIT_RIDER, incomeBenefit, props.data.product_id, formRef, props)
        }
    })
}

export default FormObserver