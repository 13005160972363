// Project Imports
import ProductNames from "constants/ProductNames";
import getAnnualizedPremium from "utils/optimization/GetAnnualizedPremium";

const calculateInputModalPremium = (modalPremium, productData) => {
  console.log("calculateInputModalPremium", productData);
  let premiumAmount = 0;

  // For Without tab products
  if (!Array.isArray(productData.BI)) {
    switch (productData.product_id) {
      case ProductNames.criticarePlus:
      case ProductNames.zindagiPlus:
      case ProductNames.saralJeevanBima:
      case ProductNames.totalProtectPlus:
        premiumAmount = Math.round(modalPremium);
        break;

      default:
        break;
    }
  }

  // For With tabs products
  else {
    let selectedRiskData = productData.BI.find((item) => item.risk === productData.selectedRisk);

    switch (selectedRiskData?.product_id) {
      case ProductNames.easyPension:
      case ProductNames.wealthPlus:
      case ProductNames.wealthUltima:
      case ProductNames.wealthSecurePlus:
        switch (Number(selectedRiskData.bi_request.INPUT_MODE)) {
          case 1:
          case 5:
            premiumAmount = modalPremium;
            break;
          case 2:
            premiumAmount = Number(modalPremium) * 2;
            break;
          case 3:
            premiumAmount = Number(modalPremium) * 4;
            break;
          case 4:
            premiumAmount = Number(modalPremium) * 12;
            break;

          default:
            premiumAmount = modalPremium;
            break;
        }

        break;
      case ProductNames.foreverPension:
      case ProductNames.saralPension:
      case ProductNames.singlePayEndowmentAssuarancePlan:
      case ProductNames.wealthPremier:
      case ProductNames.activeIncomePlan:
        premiumAmount = modalPremium;
        break;

      case ProductNames.premierGuranteedIncome:
      case ProductNames.incomeBuilder:
      case ProductNames.guaranteedIncomeStar:
      case ProductNames.GCAP:
      case ProductNames.dhanLabh:
      case ProductNames.smartLifestyle:
      case ProductNames.posSaralNivesh:
      // case ProductNames.activeIncomePlan:
      case ProductNames.cashFlowProtectionPlus:
      case ProductNames.guaranteedSavingsStar:
      case ProductNames.immediateAnnuityPlan:
      case ProductNames.flexiSavingsPlan:
        premiumAmount = getAnnualizedPremium(modalPremium, Number(selectedRiskData.bi_request.INPUT_MODE))
    }
  }

  return Math.round(premiumAmount);
};

export default calculateInputModalPremium;
