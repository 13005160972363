// React Import
import React from 'react';

// Package Imports
import { Row, Col, Card } from 'react-bootstrap';
import { observer } from 'mobx-react';

// Project Imports
import OptionalDetailsCards from '../components/OptionalDetailsCards';
import { Button as UiButton } from "components";
import AddMemberCardModal from "../components/AddMemberCardModal";
import FamilyMemberCardHeader from '../components/FamilyMemberCardHeader';
import AddFamilyMemberData from '../components/AddFamilyMemberData';
import ValidationErrorMessageShowModal from "../components/ValidationErrorMessageShowModal";
import { AddIcon } from "constants/Images";
import { addFamilyMember, closeModal } from './utils';
import EMIOptionalDetailsCards from '../components/EMIOptionalDetailsCard';
import { updateValue,toggleDisableOptions,changeFieldValue } from '../form/utils'

// CSS Imports
import "../components/add-family-member-card.css";
import '../family-details-form.css';
import isObjectEmpty from 'utils/isObjectEmpty';
import { useEffect } from 'react';


const FamilyDetailsForm = ({ formController, errorClass }) => {

  function setShowFamilymemberModal(shouldShowModal) {
    formController.$("show_add_family_member_modal").value = shouldShowModal;
  }

  if (!formController.has("members")) {
    return null
  }

  let familyData = formController.$("members").get()
  const showFamilyMemberModal = formController.$("show_add_family_member_modal").value;


   const listOfSelectedFamilyMembers = Object.entries(familyData).filter((item) => item[1].extra.isSelected)

  return (

    
    <Col className='col-12 mb-5'>

      <Card className='border-0 add-family-member-card mt-md-2'>
        <Card.Body className='px-0 pb-0 py-0 border family-member-main-card' 
        >

          <FamilyMemberCardHeader />
          <hr className='m-0' />

          {listOfSelectedFamilyMembers.map((item, index) => {
              return <AddFamilyMemberData
                key={index}
                memberCount={index + 1} // since index starts from 0, therefore added 1 
                member={item}
                formController={formController}
                updateValues={updateValue}
                onAddNameClick={() => setShowFamilymemberModal(true)}
              />;
          })}

          <div 
          className='bottom-bg-color p-3 add-member-section'>
            <UiButton variant='' className='d-inline-flex align-items-center p-0' onClick={() => setShowFamilymemberModal(true)}>
              <img src={AddIcon} alt='add-icon' className='font-weight-medium' />
              <p className='mb-0 font-size-12 font-weight-medium text-secondary pl-2'>Add/Edit Family Members</p>
            </UiButton>
          </div>
        </Card.Body>
          <AddMemberCardModal
            formController={formController}
            familymemberList={familyData}
            show={showFamilyMemberModal}
            onAddMember={(hideModal) => addFamilyMember(formController, hideModal)}
            onHide={() => closeModal(() => setShowFamilymemberModal(false), formController)}
          />
          <ValidationErrorMessageShowModal />
      </Card>

      <Row className='optional-card-row mt-5'>
        <Col className='col-12 col-md-6 col-lg-4 order-lg-1 order-md-1 order-sm-1'>
          <p className='font-size-14 text-primary font-weight-medium mb-0 mt-4'>
            Monthly Expense Details
            {/* <span className={`font-size-11 ${errorClass}`}> - Please fill at least one detail</span> */}
          </p>
        </Col>
        <Col className='col-12 col-md-6 col-lg-4 order-lg-4 order-md-3 order-sm-2 d-flex'>
          <OptionalDetailsCards
            list={formController.$("monthly_expense").get()}
            onChange={(field_name, value) => {
              changeFieldValue(formController, 'monthly_expense', field_name, value)
            }}
            toggleDisableOption={
              (field_name, isDisabled) => toggleDisableOptions(formController, 'monthly_expense', field_name, isDisabled)
            }
          />
        </Col>
        <Col className='col-12 col-md-6 col-lg-4 order-lg-2 order-md-2 order-sm-3'>
          <p className='font-size-14 text-primary font-weight-medium mb-0 mt-4'>Monthly EMIs, if any</p>
        </Col>
        <Col className='col-12 col-md-6 col-lg-4 order-lg-5 order-md-4 order-sm-4 d-flex'>
          {formController.$("show_emi_fields").value == true &&
            <EMIOptionalDetailsCards
              list={!isObjectEmpty(formController.$("monthly_emi").get()) && formController.$("monthly_emi").get()}
              onChange={(field_name, nestedFieldName, value) => {
                formController.$("monthly_emi").$(field_name).$(nestedFieldName).value = value;
              }}
              toggleDisableOption={(field_name, isDisable) => {
                formController.$("monthly_emi").$(field_name).set("disabled", isDisable);
                formController.$("monthly_emi").$(field_name).$("option_range").set("disabled", isDisable);
                formController.$("monthly_emi").$(field_name).$("paid_years").set("disabled", isDisable);
                formController.$("monthly_emi").$(field_name).$("total_years").set("disabled", isDisable);
                formController.$("monthly_emi").$(field_name).$("option_range").resetValidation();
                formController.$("monthly_emi").$(field_name).$("option_range").set("value", {})
                formController.$("monthly_emi").$(field_name).$("paid_years").resetValidation();
                formController.$("monthly_emi").$(field_name).$("paid_years").set("value", {})
                formController.$("monthly_emi").$(field_name).$("total_years").resetValidation();
                formController.$("monthly_emi").$(field_name).$("total_years").set("value", {})
              }}
            />
          }
        </Col>
        <Col className='col-12 col-md-12 col-lg-4 order-lg-3 order-md-5 order-sm-5'>
          <p className='font-size-14 text-primary font-weight-medium mb-0 mt-4'>Savings & Investment Details, If any</p>
        </Col>
        <Col className='col-12 col-md-6 col-lg-4 last-card-bottom-spacing order-lg-6 order-md-6 order-sm-6 d-flex'>
          <OptionalDetailsCards
            list={formController.$("savings").get()}
            onChange={(field_name, value) => {
              changeFieldValue(formController, 'savings', field_name, value)
            }}
            toggleDisableOption={
              (field_name, isDisabled) => toggleDisableOptions(formController, 'savings', field_name, isDisabled)
            }
          />
        </Col>
      </Row>
    </Col>

  )
}

export default observer(FamilyDetailsForm)