import React, { useEffect, useState } from "react";

import { Row, Col, Form } from "react-bootstrap";

import { observer } from "mobx-react";

import {
  BottomsheetDropdown,
  ChangeProductSection
} from "../../../../../components";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import AmountCalculation from "../../optimization-card/amount-calculation/AmountCalculation";
import Utils from "./utils";
import FundDistributionModal from "../../optimization-card/fundDistributionModal";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import DeathBenefitAmount from "utils/optimization/deathBenefitAmount";

import "../../optimization-card/family-collapse-view.css";

const WealthUltima = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false)

  let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let firstYearResponse = productData.firstYearResponse;
  let biRequest = productData.bi_request;
  let laAge = typeof biRequest["LI_ENTRY_AGE"] == "string" ? parseInt(biRequest["LI_ENTRY_AGE"]) : biRequest["LI_ENTRY_AGE"];
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)
  form.$('death-benefit-amount').set('value', Math.round(productData?.firstYearResponse?.DEATH_BEN))

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])

  const onHideOverlay = () => {
    setShowOverlay(false)
  }


  return (
    <React.Fragment>
      <Row className="mt-md-5">
        <Col xs={12} md={6} className="mt-3 mt-md-0">
          <ChangeProductSection 
            productName={productData.product_name || "Wealth-Ultima"} 
            cardName={coverLabelBaseOnNeed}
            changeProductList={data?.data?.changeProductList}
            productData={data}
          />
        </Col>
      </Row>
      <Row className="">
        <Col md={12} className="col-12 mt-4 mt-md-0">
          <DeathCoverGraphSlider
            productTitle={productData.product_name}
            openChangeReasonModal={false}
            onIncomeChange={() => { }}
            onPremiumChange={form.$("premium").onChange}
            hideOverLay={onHideOverlay}
            productID={productData.product_id}
            productData={data?.data}
            showOverlay={showOverlay}
            cardKey={data.cardKey}
            income_value=""
            premium_value={form.$("premium").value}
            onClick={() => form.$("premium").extra.calculatePremiun(form, productData.product_id, biRequest.LI_ENTRY_AGE, form, data)}
            data=""
            isIncomeFieldDisable={form.$("premium").disabled}
            isPremiumDisable={true}
            premiumFreq={form.$("payment-frequency").value.label}
            formRef={form}
          />
        </Col>
      </Row>
      {
        laAge < 45 ?
        <Row className='mt-3 ml-0'>
          <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{form.$("death-benefit-amount").label}</p>
          <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(productData)}</p>
        </Row>
        :
        <Row className='mt-3 ml-0'>
          <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{form.$("death-benefit-amount").label}</p>
          {/* <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(data)}</p> */}
          <Form.Control
            className='font-size-12 font-weight-light text-black product-form-field'
            placeholder={form.$("death-benefit-amount").placeholder}
            value={form.$("death-benefit-amount").value}
            onChange={form.$("death-benefit-amount").onChange}
            onBlur={() => form.$("death-benefit-amount").extra.onDeathBenefitAmountChange(form, data)}
            // disabled={form.$("marital-status").value.id == 9}
          />
        </Row>
      }
      
      <Row className="">
        <Col md={6} className="col-12 mt-3">
          <div className="mb-2 font-size-12 font-weight-light total-premium-container-heading">
            {form.$("policy-term").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("policy-term").label}
            menuList={form.$("policy-term").extra.options}
            value={form.$("policy-term").value}
            onSelect={form.$("policy-term").onChange}
          />
        </Col>
        <Col md={6} className="col-12 mt-3">
          <div className="mb-2 font-size-12 font-weight-light total-premium-container-heading">
            {form.$("premium-term").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("premium-term").label}
            menuList={form.$("premium-term").extra.options}
            value={form.$("premium-term").value}
            onSelect={form.$("premium-term").onChange}
          />
        </Col>
      </Row>
      <Row className="">
        <Col md={4} className="col-12 mt-3">
          <div className="mb-2 font-size-12 font-weight-light total-premium-container-heading">
            {form.$("policy-option").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("policy-option").label}
            menuList={form.$("policy-option").extra.options}
            value={form.$("policy-option").value}
            onSelect={form.$("policy-option").onChange}
          />
        </Col>
        <Col md={4} className="col-12 mt-3">
          <div className="mb-2 font-size-12 font-weight-light total-premium-container-heading">
            {form.$("payment-frequency").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("payment-frequency").label}
            menuList={form.$("payment-frequency").extra.options}
            value={form.$("payment-frequency").value}
            onSelect={form.$("payment-frequency").onChange}
          />
        </Col>
      {/* </Row> */}
      {/* <Row className=""> */}
        <Col md={4} className="col-12 mt-3">
          <div className="mb-2 font-size-12 font-weight-light total-premium-container-heading">
            {form.$("little-champ-benefit").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("little-champ-benefit").label}
            menuList={form.$("little-champ-benefit").extra.options}
            value={form.$("little-champ-benefit").value}
            onSelect={form.$("little-champ-benefit").onChange}
            disabled={form.$("little-champ-benefit").disabled}
          />
        </Col>
        <Col md={4} className="col-12 d-md-flex mt-3">

            <div className={` ${form.$("fund-strategy").value.id !== 1 ? 'col px-0' :'col-4 col-md-9'}`}>
            <div className="mb-2 total-premium-container-heading font-size-12 font-weight-light">
              {form.$("fund-strategy").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("fund-strategy").label}
              menuList={form.$("fund-strategy").extra.optionList}
              value={form.$("fund-strategy").value}
              onSelect={form.$("fund-strategy").onChange}
            />
            </div>
          
          {form.$("fund-strategy").value.id == 1 && (
            <React.Fragment>
              <div className="col-md-3">
                <span className="font-size-12 font-weight-light">Fund Distribution</span>
                <span
                  className="a cursor-pointer d-md-block font-size-14 text-primary font-weight-light mt-2"
                  onClick={() =>
                    form
                      .$("fund-strategy")
                      .extra.toggleFundDistributionModal(
                        form,
                        true,
                        form.$('fund-strategy').extra.fundList,
                        data.data.product_id
                      )
                  }
                >
                  {" "}
                  <u>Click to View</u>
                </span>
              </div>
            </React.Fragment>
          )}
        </Col>

      {/* </Row> */}
      {/* <Row className="mb-md-3"> */}

        <Col md={4} className="col-12 my-3">
          <div className="mb-2 font-size-12 font-weight-light total-premium-container-heading">
            {form.$("systematic-withdrawal-option").label}
          </div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey="label"
            bottomsheetTitle={form.$("systematic-withdrawal-option").label}
            menuList={form.$("systematic-withdrawal-option").extra.options}
            value={form.$("systematic-withdrawal-option").value}
            onSelect={form.$("systematic-withdrawal-option").onChange}
            disabled={form.$("systematic-withdrawal-option").disabled}
          />
        </Col>
        {form.$("systematic-withdrawal-option").value.id == '5' &&
          <>
            <Col md={4} className="col-12 mt-3">
              <div className="mb-2 font-size-12 font-weight-light total-premium-container-heading">
                {form.$("fund-value").label}
              </div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey="label"
                bottomsheetTitle={form.$("fund-value").label}
                menuList={form.$("fund-value").extra.options}
                value={form.$("fund-value").value}
                onSelect={form.$("fund-value").onChange}
                disabled={form.$("systematic-withdrawal-option").value.id != '5'}
              />
            </Col>
            <Col md={4} className="col-12  mt-3">
              <div className="mb-2 font-size-12 font-weight-light total-premium-container-heading">
                {form.$("swp-frequency").label}
              </div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey="label"
                bottomsheetTitle={form.$("swp-frequency").label}
                menuList={form.$("swp-frequency").extra.optionList}
                value={form.$("swp-frequency").value}
                onSelect={form.$("swp-frequency").onChange}
                disabled={form.$("systematic-withdrawal-option").value.id != '5'}
              />
            </Col>
            {/* <Col md={6} className="col-12 mt-3">
              <div className="mb-2 font-size-12 font-weight-light total-premium-container-heading">
                {form.$("withdrawal-year").label}
              </div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey="label"
                bottomsheetTitle={form.$("withdrawal-year").label}
                menuList={form.$("withdrawal-year").extra.optionList}
                value={form.$("withdrawal-year").value}
                onSelect={form.$("withdrawal-year").onChange}
                disabled={form.$("systematic-withdrawal-option").value.id != '5'}
              />
            </Col> */}

            <Col md={4} className='col-12 mt-3'>
              <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("withdrawal-year").label}</div>
              <Form.Control
                className='font-size-12 font-weight-light text-black product-form-field'
                placeholder={form.$("withdrawal-year").label}
                type={form.$("withdrawal-year").type}
                value={form.$("withdrawal-year").value}
                onChange={form.$("withdrawal-year").onChange}
                onBlur={() => form.$("withdrawal-year").extra.onWithDrawalYearChange(form, data)}
                // disabled={}
              />
            </Col>
          </>
        }

      </Row>
      <FundDistributionModal
        formRef={form}
        show={form.$("fund-strategy").extra.showFundDistributionModal}
        data={data}
        fundList={form.$('fund-strategy').extra.fundList}
        submitButtonText={form.$('fund-strategy').extra.setDistributionButtonText}
        onHide={(fundList) => {
          form.$("fund-strategy").extra.toggleFundDistributionModal(form, false, fundList, data.data.product_id,data.data)
        }}
        onSetDistribution={form.$('fund-strategy').extra.onSetDistribution}
      />
    </React.Fragment>
  );
};

export default observer(WealthUltima);
