import React from "react"

// package improrts
import { Card } from "react-bootstrap"

// Project Imports
import { CircularProgressbar } from "components"
import LaAndPremiumRiderComponent from "../la-and-premium-rider-component/LaAndPremiumRiderComponent"

// css improrts
import "./solution-screen-single-la-card.css"

const SolutionScreenSingleLaCard = ({ CardName, percentValue, detail,singleLaCard }) => {
  const fetchPercentage = () => {
    let percentValue = 0
    let totalTargetAmount = 0;
    let totalRecommendedAmount = 0;
    detail.laList[0].productDetails.forEach((item) => {
      totalTargetAmount += Math.round((Number(item.targetAmount))); 
      totalRecommendedAmount += Math.round((Number(item.recommendedAmount)));
    })
    percentValue = Math.round((Number(totalTargetAmount) / Number(totalRecommendedAmount)) * 100)
    return percentValue > 100 ? 100 : percentValue
  }

  const noOfProducts = detail.laList[0].productDetails.length

  return (
    <div className={`col-12 ${noOfProducts == 2 ? 'col-lg-7' : noOfProducts >=3 ? 'col-lg-12': 'col-lg-4'} mx-auto`}>
    <Card className="solution-screen-single-la-card mt-3">
      <Card.Body>
        <p className="text-center font-size-18 font-weight-medium la-card-title">
          {CardName}
        </p>

        <div className="d-none d-md-block">
          <CircularProgressbar
            singleLaCard={singleLaCard}
            value={fetchPercentage()}
            valuelabel="covered"
            isSingleImg={true}
            laName={""}
            size="" // pass small for smaller doughnut chart and empty string for default doughnut
          />
        </div>
        <div className="d-flex flex-column flex-lg-row">
        {detail.laList.map((item) => {
          return item.productDetails.map((singleProduct, index) => {
            return (
              <div key={index} className="border border-top-0 px-0 pb-3 col ">
                <LaAndPremiumRiderComponent
                  productpremiumType="Product Premium"
                  productPremiumMode="per annum"
                  productPremiumAmount={singleProduct.basePremium}
                  productPremiumGstPercent={singleProduct.gstPercent}
                  productPremiumGstAmount={singleProduct.gstAmount}
                  riderPremiumType="Rider"
                  // riderPremiumMode={singleProduct.inputMode}
                  riderPremiumMode="per annum"
                  riderPremiumAmount={singleProduct.riderBasePremium}
                  riderPremiumGstPercent={singleProduct.riderGstPercentage}
                  riderPremiumGstAmount={singleProduct.riderGstAmount}
                  showRiderValue={singleProduct.riderBasePremium !== 0}
                  LaName={item.laName}
                  productName={singleProduct.productName}
                  totalPremiumAmount={singleProduct.totalPremiumAmount}
                  totalPremiumPayable={singleProduct.totalPremiumPayable}
                  inputMode={singleProduct.inputMode}
                  percentValue={fetchPercentage()}
                />
              </div>
            )
          })
        })
        }
        </div>
        {/* <LaAndPremiumRiderComponent
          percentValue={percentValue}
          productpremiumType="Product"
          productPremiumMode="Lumpsum"
          productPremiumAmount={1000}
          productPremiumGstPercent={18}
          productPremiumGstAmount={450}
          riderPremiumType="Rider"
          riderPremiumMode="per annum"
          riderPremiumAmount={468}
          riderPremiumGstPercent={18}
          riderPremiumGstAmount={84.24}
          showRiderValue={false}
          productName={detail.productName}
          targetAmount={detail.targetAmount}
        /> */}
      </Card.Body>
    </Card>
    </div>
  )
}

export default SolutionScreenSingleLaCard
