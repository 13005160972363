import Utils from './utils';
import ConstUtils from '../../../../../constants/utils';

const initialDefaults = (formRef, data) => {
    let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
    let biResponse = productData.parsedResponse;
    let firstYearResponse = productData.firstYearResponse;
    let biRequest = productData.bi_request;

    let frequency = biRequest.INPUT_MODE?.toString()
    let option = biRequest.Option?.toString()
    let premium = biRequest.PR_ANNPREM == 0 ? firstYearResponse?.MODAL_PREM: biRequest.PR_ANNPREM;

    let ptList = Utils.getPolicyTerm()
    formRef.$('policy-term').set('extra', {
        ...formRef.$('policy-term').extra,
        optionList: ptList
    })

    formRef.$('policy-term').value = {
        id: biRequest.PR_PT,
        label: biRequest.PR_PT
    }

    // let premiumAmount = ''
    // switch (frequency) {
    //     case '1':
    //         premiumAmount = premium;
    //         break;
    //     case '2':
    //         premiumAmount = Number(premium) / 2;
    //         break;
    //     case '3':
    //         premiumAmount = Number(premium) / 4;
    //         break;
    //     case '4':
    //         premiumAmount = Number(premium) / 12;
    //         break;

    //     default:
    //         break;
    // }

    // formRef.$('premium').set('value', premiumAmount);
    formRef.$('premium').set('value', premium);

    let pptList = Utils.getPremiumTermList(formRef.$('policy-term').value)


    formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        optionList: pptList
    })

    formRef.$('premium-term').value = {
        id: biRequest.PR_PPT,
        label: biRequest.PR_PPT
    }


    switch (frequency) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '2':
            formRef.$('payment-frequency').set('value', {
                id: '2',
                label: 'Semi-Annual'
            })
            break;
        case '3':
            formRef.$('payment-frequency').set('value', {
                id: '3',
                label: 'Quarterly'
            })
            break;
        case '4':
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }

    switch (option) {
        case '1':
            formRef.$('option').set('value', {
                id: '1',
                label: 'Base Option'
            })
            break;
        case '2':
            formRef.$('option').set('value', {
                id: '2',
                label: 'Family Protection Option'
            })
            break;
        default:
            break;
    }

    if (biRequest.ADBRider) {
        formRef.$('ADB').set('value', biRequest.ADBRider !== '')
        if (biRequest.ADBRider) {
            formRef.$('ADB-dropdown').set('value', biRequest.ADBRider)
        }
    }
    if (biRequest.ATPDRider) {
        formRef.$('ATPDRider').set('value', biRequest.ATPDRider !== '')
        if (biRequest.ATPDRider) {
            formRef.$('ATPDRider-dropdown').set('value', biRequest.ATPDRider)
        }
    }
    if (biRequest.WaiverOfPremiumRider) {
        formRef.$('WaiverOfPremiumRider').set('value', biRequest.WaiverOfPremiumRider !== '')
    }

    if (biRequest.IncomeBenefitRider) {
        formRef.$('IncomeBenefitRider').set('value', biRequest.IncomeBenefitRider !== '')
        if (biRequest.IncomeBenefitRider) {
            formRef.$('IncomeBenefitRider-dropdown').set('value', biRequest.IncomeBenefitRider)
        }
    }
    if (biRequest.CriticalIllnessRider) {
        formRef.$('CriticalIllnessRider').set('value', biRequest.CriticalIllnessRider !== '')
        if (biRequest.CriticalIllnessRider) {
            formRef.$('CriticalIllnessRider-dropdown').set('value', biRequest.CriticalIllnessRider)
        }
    }
    if (biRequest.PayorWaiverBenefitRider) {
        formRef.$('PayorWaiverBenefitRider').set('value', biRequest.PayorWaiverBenefitRider !== '')
        if (formRef.$('PayorWaiverBenefitRider').value) {
            switch (biRequest.PWB.toString()) {
                case '1':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '1',
                        label: 'Option 1 (On Death)'
                    })
                    break;
                case '2':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '2',
                        label: 'Option 2 (CI or ATPD)'
                    })
                    break;
                case '3':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '3',
                        label: 'Option 3 (CI or ATPD or Death)'
                    })
                    break;
                default:
                    break;
            }
        }
    }
}

export default initialDefaults