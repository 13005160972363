import dvr from 'mobx-react-form/lib/validators/DVR';
import vjf from 'mobx-react-form/lib/validators/VJF';

import { Form } from 'mobx-react-form';
import validatorjs from 'validatorjs';

import moment from 'moment';

import FamilyDetailsStore from '../../../../family-details-form/store/FamilyDetailsStore';
import setInitialDefaults from './initialDefaults';
import formObserver from './observers';
import Utils from './utils';
import * as keys from './keys';
import * as Constants from 'constants/Constants';
import { CovidVaccinationStatusList } from 'constants/CovidVaccinationStatusList';
import OptimizationStore from 'modules/optimization/store/OptimizationStore';
class ZindagiPlusSampleController extends Form {

  plugins() {
    console.log('plugins is called')
    return {
      dvr: dvr(validatorjs),
      vjf: vjf()
    }
  }

  setup() {
    return {
      fields: [
        {
          name: 'tobacco-user',
          label: 'Tobacco Consumer?',
          type: 'radio',
          options: [
            {
              id: '1',
              label: 'Yes'
            },
            {
              id: '0',
              label: 'No'
            }
          ]
        },
        {
          name: 'plan-option',
          label: 'Plan Option',
          placeholder: 'Plan Option',
          disabled: false,
          extra: {
            options: [
              { id: '1', label: 'Life Cover With Level Sum Assured' },
              { id: '2', label: 'Life Cover With Decreasing Sum Assured' }
            ]
          }
        },
        {
          name: 'payment-frequency',
          label: 'Premium Paying Frequency',
          placeholder: 'Premium Paying Frequency',
          disabled: false,
          extra: {
            options: [
              { id: 4, label: 'Monthly' },
              { id: 3, label: 'Quarterly' },
              { id: 2, label: 'Half-Yearly' },
              { id: 1, label: 'Annual' }
            ]
          }
        },
        {
          name: 'payout-option',
          label: 'Cover payout mode',
          placeholder: 'Cover payout mode',
          disabled: false,
          extra: {
            optionList: [
              {
                id: "16",
                label: "Lumpsum",
              },
              {
                id: "17",
                label: "Monthly income – Level",
              },
              {
                id: "18",
                label: "Monthly income – Increasing",
              },
              {
                id: "19",
                label: "Lumpsum Plus Monthly Income – Level",
              },
              {
                id: "20",
                label: "Lumpsum Plus Monthly Income - increasing",
              },
            ],
            onChangePayoutOption(value, formRef, data) {

              let payoutPeriodValue = {}
              let payoutPercentage = {}

              formRef.$("payout-option").set("value", value)

              if (value.id === "16") {
                payoutPeriodValue = {}
                payoutPercentage = {}

                formRef.$("payout-period").set("extra", {
                  ...formRef.$("payout-period").extra,
                  hide: true
                })
                formRef.$("payout-period").set("value", payoutPeriodValue)

                formRef.$("payout-percentage").set("extra", {
                  ...formRef.$("payout-percentage").extra,
                  hide: true
                })
                formRef.$("payout-percentage").set("value", payoutPercentage)
              }

              if (value.id === "17" || value.id === "18") {
                payoutPeriodValue = { id: 21, label: '36' }
                payoutPercentage = {}

                formRef.$("payout-period").set("extra", {
                  ...formRef.$("payout-period").extra,
                  hide: true
                })
                formRef.$("payout-period").set("value", payoutPeriodValue)

                formRef.$("payout-percentage").set("extra", {
                  ...formRef.$("payout-percentage").extra,
                  hide: false
                })
                formRef.$("payout-percentage").set("value", payoutPercentage)
              }

              if (value.id === "19" || value.id === "20") {
                payoutPeriodValue = { id: 21, label: '36' }
                payoutPercentage = { id: 27, label: '1 %' }
                // payoutPeriodValue = {}
                // payoutPercentage = {}

                formRef.$("payout-period").set("extra", {
                  ...formRef.$("payout-period").extra,
                  hide: true
                })
                formRef.$("payout-period").set("value", payoutPeriodValue)

                formRef.$("payout-percentage").set("extra", {
                  ...formRef.$("payout-percentage").extra,
                  hide: true
                })
                formRef.$("payout-percentage").set("value", payoutPercentage)
              }

              let payoutData = {
                payoutOption: value.id,
                lumpsum: payoutPercentage?.id ? payoutPercentage.id : "",
                payoutPeriod: payoutPeriodValue?.id ? payoutPeriodValue.id : ""
              }

              Utils.updateBi(
                keys.PAYOUT_OPTION,
                payoutData,
                data.data.product_id,
                formRef,
                data
              );
            }
          }
        },
        {
          name: 'payout-period',
          label: 'Income Period',
          placeholder: 'Income Period',
          disabled: false,
          extra: {
            hide: true,
            optionList: [
              { id: 21, label: '36' },
              { id: 22, label: '60' },
              { id: 23, label: '120' }
            ],
            onChangePayoutPeriod(value, formRef, data) {
              formRef.$("payout-period").set("value", value)

              Utils.updateBi(
                keys.PAYOUT_PERIOD,
                value.id,
                data.data.product_id,
                formRef,
                data
              );
            }
          }
        },
        {
          name: 'payout-percentage',
          label: 'Lumpsum %',
          placeholder: 'Payout Percentage',
          disabled: false,
          extra: {
            hide: true,
            optionList: Utils.payoutPercentageListGenerator(),
            onChangePayoutPercentage(value, formRef, data) {
              formRef.$("payout-percentage").set("value", value)

              Utils.updateBi(
                keys.PAYOUT_PERCENTAGE,
                value.id,
                data.data.product_id,
                formRef,
                data
              );
            }
          }
        },
        {
          name: 'policy-term',
          label: 'Policy Term',
          placeholder: 'Policy Term',
          disabled: false,
          extra: {
            options: []
          }
        },
        {
          name: 'premium-term',
          label: 'Premium Paying Term',
          placeholder: 'Premium Paying Term',
          disabled: false,
          extra: {
            options: []
          }
        },
        {
          name: 'premium-amt',
          label: 'Premium',
          placeholder: 'Premium',
          disabled: false,
          extra: {
            calculatePremiun(form, value, data) {
              // let premiumAmount = 0;
              // let inputMode = Number(form.$('payment-frequency').value.id)

              // let amount = Number(value)
              // switch (inputMode) {
              //   case 1:
              //     premiumAmount = '' + amount * 1
              //     break;
              //   case 2:
              //     premiumAmount = '' + amount * 2
              //     break;
              //   case 3:
              //     premiumAmount = '' + amount * 4
              //     break;
              //   case 4:
              //     premiumAmount = '' + amount * 12
              //     break;
              // }
              // Utils.updateBi(keys.PREMIUM, premiumAmount, data.data.product_id, form, data)
            }
          }
        },
        {
          name: "underwriting-status",
          label: "Underwriting status",
          placeholder: "Underwriting status",
          disabled: false,
          value: { label: "Medical", id: 1 },
          extra: {
            optionList: [
              { label: "Medical", id: 1 },
              { label: "Non-Medical", id: 2 },
            ],
          },
        },
        {
          name: 'marital-status',
          label: 'Marital Status',
          placeholder: '',
          disabled: false,
          extra: {
            options: [
              { id: '9', label: 'Single' },
              { id: '10', label: 'Married' }
            ]
          }
        },
        {
          name: "spouse-tobacco-consumer",
          label: "spouse Tobacco Consumer",
          placeholder: "Underwriting status",
          disabled: true,
          value: { label: "No", id: 137 },
          extra: {
            optionList: [
              { label: "Yes", id: 138 },
              { label: "No", id: 137 },
            ],
          },
        },
        {
          name: "spouse-name",
          label: "Spouse Name",
          placeholder: "",
          extra: {
            onSpouseNameChange(form, data) {
              let name = form.$('spouse-name').value
              Utils.updateBi(keys.SPOUSE_NAME, name, data.data.product_id, form, data)
            }
          }
        },
        {
          name: "spouse-gender",
          label: "Spouse Gender",
          placeholder: "",
          extra: {
            optionList: [
              {
                id: '134',
                label: 'Male',
                value: 'M'
              },
              {
                id: '135',
                label: 'Female',
                value: 'F'
              }
            ],
          },
        },
        {
          name: "spouse-dob",
          label: "Spouse Date of Birth",
          placeholder: "",
          extra: {
            changeDob(form, value, data) {
              let productId = data.data.product_id;
              let spouseDOB = value == '' ? FamilyDetailsStore.dateValue : value
              form.$("spouse-dob").set("value", spouseDOB)
              let dob = moment(spouseDOB).year()
              let currentYear = moment().year()
              let age = currentYear - dob;
              let requestata = {
                age: age,
                dob: moment(spouseDOB).format("DD MMM YYYY")
              }
              Utils.updateBi(keys.SPOUSE_DOB, requestata, productId, form, data)
            }
          },
        },
        {
          name: "covid-vaccination-status",
          label: "Covid Vaccination Status",
          placeholder: "Covid Vaccination Status",
          value: {},
          extra: {
            changeVaccinationStatus(form, value, data) {
              let oldVaccinationStatus = form.$("covid-vaccination-status").value.id
              form.$("covid-vaccination-status").value = value
              if (oldVaccinationStatus != value.id) {
                let requestData = {
                  covid_vaccination_status: value.id,
                  family_product_id: data.data.family_product_id,
                  product_id: data.data.product_id,
                  package_id: data.data.package_id
                }
                OptimizationStore.getRevisedRecoSaTermNeedApiCall(requestData)
              }
            },
            optionList: CovidVaccinationStatusList

          },
        },
        //Additional Benefits
        {
          name: 'better-half-benefit',
          label: 'Better Half Benefit',
          placeholder: '',
          type: 'radio',
          value: '',
          extra: {
            description: 'Option to get an additional life cover for your spouse.',
            sum_assured: '',
            changeBenefitStatus(form, data) {
              let value = form.$('better-half-benefit').value
              form.$('better-half-benefit').value = !value
            }
          },
          disabled: false,
        },
        {
          name: 'WaiverOfPremiumRider',
          label: 'Waiver of Premium',
          placeholder: '',
          type: 'radio',
          value: false,
          extra: {
            description: Constants.WOP_RIDER_DESCRIPTION,
            sum_assured: '',
            changeBenefitStatus(form, data) {
              let value = form.$('WaiverOfPremiumRider').value
              form.$('WaiverOfPremiumRider').value = !value
              if (form.$('top-up-benefit').value == true) {
                form.$('top-up-benefit').value = !form.$('WaiverOfPremiumRider').value
              }
            }

          },
          disabled: false,

        },
        {
          name: 'top-up-benefit',
          label: 'Top Up Benefit',
          placeholder: '',
          type: 'radio',
          value: false,
          extra: {
            description: 'Top-up Sum Assured is equal to Top-up Rate multiplied by Base Sum Assured.',
            sum_assured: '',
            changeBenefitStatus(form, data) {
              let value = form.$('top-up-benefit').value
              form.$('top-up-benefit').value = !value
              if (form.$('WaiverOfPremiumRider').value == true) {
                form.$('WaiverOfPremiumRider').value = !form.$('top-up-benefit').value
              }


            }
          },
          disabled: false,
        },
        {
          name: 'telemedical-option',
          label: 'Telemedical Option',
          placeholder: '',
          type: 'radio',
          value: '',
          extra: {
            description: 'Get hassel-free life protection without the need of going through the full medical examination',
            sum_assured: '',
            changeBenefitStatus(form) {
              let value = form.$('telemedical-option').value
              form.$('telemedical-option').value = !value
              if (!value) {
                form.$('check-eligibility').set('extra', {
                  ...form.$('check-eligibility').extra,
                  hide: false
                })
              }
            }
          },
          disabled: false,
        },
        {
          name: 'top-up-benefit-dropdown',
          label: 'Top Up Benefit',
          placeholder: '',
          value: {},
          extra: {
            optionList: [
              {
                label: '5%',
                id: '14'
              },
              {
                label: '10%',
                id: '15'
              }
            ]
          },
          disabled: false,
        },

        // Rider

        {
          name: 'ADB',
          label: 'Accidental Death Benefit',
          placeholder: '',
          value: false,
          extra: {
            description: Constants.ADB_RIDER_DESCRIPTION,
            paramName: 'ADBRider',
            rider_name: 'ADB Rider',
            changeRiderStatus(form, data) {
              let value = form.$('ADB').value
              form.$('ADB').value = !value

              if (form.$('ADB').value) {
                form.$('ADB-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
              } else {
                form.$('ADB-dropdown').set('value', "")
              }
            }
          }
        },
        {
          name: 'ATPDRider',
          label: 'Accidental Total/Perm. Disability',
          placeholder: '',
          value: false,
          extra: {
            description: Constants.ATPD_RIDER_DESCRIPTION,
            paramName: 'ATPDRider',
            rider_name: 'ATPD Rider',
            changeRiderStatus(form, data) {
              let value = form.$('ATPDRider').value
              form.$('ATPDRider').value = !value

              if (form.$('ATPDRider').value) {
                form.$('ATPDRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
              } else {
                form.$('ATPDRider-dropdown').set('value', "")
              }
            }

          }
        },
        {
          name: 'CriticalIllnessRider',
          label: 'Critical Illness',
          placeholder: '',
          value: false,
          extra: {
            description: Constants.CRITICAL_ILLNESS_RIDER_DESCRIPTION,
            paramName: 'CriticalIllnessRider',
            rider_name: 'Critical Illness Rider',
            changeRiderStatus(form, data) {
              let value = form.$('CriticalIllnessRider').value
              form.$('CriticalIllnessRider').value = !value

              if (form.$('CriticalIllnessRider').value) {
                form.$('CriticalIllnessRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
              } else {
                form.$('CriticalIllnessRider-dropdown').set('value', "")
              }
            }
          }
        },

        {
          name: 'HospitalityCashBenefitRider',
          label: 'Hospital Cash Benefit',
          placeholder: '',
          value: false,
          extra: {
            description: '',
            paramName: 'HospitalityCashBenefitRider',
            rider_name: 'Hospital Cash Benefit Rider',
            changeRiderStatus(form, data) {
              let value = form.$('HospitalityCashBenefitRider').value
              form.$('HospitalityCashBenefitRider').value = !value

              if (form.$('HospitalityCashBenefitRider').value) {
                form.$('HospitalityCashBenefitRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
              } else {
                form.$('HospitalityCashBenefitRider-dropdown').set('value', "")
              }

            }

          }
        },
        /////////// DROPDOWN
        {
          name: 'ADB-dropdown',
          label: 'Accidental Death Benefit',
          placeholder: '',
          value: "",
          extra: {
            optionList: Utils.getCoverAmountList(),
            onBlur(form, data) {
              let value = form.$("ADB-dropdown").value
              Utils.updateBi(keys.ADB_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: 'ATPDRider-dropdown',
          label: 'Permanent Disability',
          placeholder: '',
          value: "",
          extra: {
            optionList: Utils.getCoverAmountList(),
            onBlur(form, data) {
              let value = form.$("ATPDRider-dropdown").value
              Utils.updateBi(keys.ATPD_RIDER, value, data.data.product_id, form, data)
            },
          }
        },
        {
          name: 'CriticalIllnessRider-dropdown',
          label: 'Critical Illness',
          placeholder: '',
          value: "",
          extra: {
            optionList: Utils.getCoverAmountListForCLRnHCB(),
            onBlur(form, data) {
              let value = form.$("CriticalIllnessRider-dropdown").value
              Utils.updateBi(keys.Critical_ILLNESS_RIDER, value, data.data.product_id, form, data)
            },
          }
        },
        {
          name: 'HospitalityCashBenefitRider-dropdown',
          label: 'Hospital Cash Benefit',
          placeholder: '',
          value: "",
          extra: {
            optionList: Utils.getCoverAmountListForCLRnHCB(),
            onBlur(form, data) {
              let value = form.$("HospitalityCashBenefitRider-dropdown").value
              Utils.updateBi(keys.HOSPITALITY_CASH_BENEFIT_RIDER, value, data.data.product_id, form, data)
            },
          }
        },

        /// Eligibility Modal
        {
          name: 'nationality',
          label: 'Your Nationality',
          placeholder: 'Your Nationality',
          value: '',
          extra: {
            optionList: [
              { id: 1, label: 'Indian' },
              { id: 2, label: 'NRI' },
              { id: 3, label: 'FNIO/PIO' },
              { id: 4, label: 'other' }
            ]
          }
        },
        {
          name: 'occupation',
          label: 'Your Occupation',
          placeholder: 'Your Occupation',
          value: '',
          extra: {
            optionList: [
              { id: 'SA', label: 'Salaried' },
              { id: 'SM', label: 'Self Employed (Professional)' },
              { id: 'BM', label: 'Self Employed (Business)' },
              { id: 'A', label: 'Agriculture' },
              { id: 'H', label: 'HouseWife' },
              { id: 'R', label: 'Retired' },
              { id: 'L', label: 'Labourer/Worker' }
            ]
          },
        },
        {
          name: 'education',
          label: 'Your Education',
          placeholder: 'Your Education',
          value: '',
          extra: {
            optionList: [
              { id: 5, label: 'Post Graduate' },
              { id: 4, label: 'Graduate' },
              { id: 3, label: '12th' },
              { id: 2, label: '10th' },
              { id: 1, label: 'Below 10th' },
              { id: 6, label: 'Professional' },
              { id: 8, label: 'Student' },
              { id: 7, label: 'Others' }
            ]
          },
        },
        {
          name: 'annual-income',
          label: 'Your Annual Income',
          placeholder: 'Your Annual Income',
          value: '',
          extra: {
          },
          disabled: true
        },
        {
          name: 'check-eligibility',
          label: 'CHECK ELIGIBILITY',
          placeholder: '',
          extra: {
            validate: false,
            hide: true,
            onCheckEligibility: async (form, data) => {
              let nationality = form.$('nationality').value
              let occupation = form.$('occupation').value
              let education = form.$('education').value
              let income = form.$('annual-income').value
              if (!nationality || !occupation || !education || !income) {
                form.$('check-eligibility').set('extra', {
                  ...form.$('check-eligibility').extra,
                  validate: true
                })
                return
              }
              form.$('check-eligibility').set('extra', {
                ...form.$('check-eligibility').extra,
                validate: false
              })
              let value = {
                data: data,
                nationality: nationality.id,
                occupation: occupation.id,
                education: education.id,
                income: income
              }
              form.$('check-eligibility').set('extra', {
                ...form.$('check-eligibility').extra,
                hide: true
              })
              let status = await Utils.updateBi(keys.ELIGIBILITY, value, data.data.product_id, form, data)
              form.$('telemedical-option').value = status;
            },

            hideModal(form) {
              form.$('check-eligibility').set('extra', {
                ...form.$('check-eligibility').extra,
                hide: true
              })
              form.$('telemedical-option').value = false
            }
          }
        },
        {
          name: 'income',
          label: 'Income',
          placeholder: 'Income',
          value: '',
          extra: {
            calculateIncome(form, data) {
              let income = form.$('income').value
              Utils.updateBi(keys.INCOME, income, data.data.product_id, form, data)
            }
          },
          disabled: true
        },
      ]
    }
  }


  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data)

    // Observer fields
    formObserver(this, data)
  }
}

export default ZindagiPlusSampleController;