// Package Imports
import { toJS } from "mobx";

// Project Imports
import axios from "utils/axios";
import * as APITypes from "constants/API";
import AuthStore from "modules/login/store/AuthStore";
import StorageKeys from "constants/StorageKeys";

let validSubSubChannelCodeList = ["MW", "CI", "CR", "CE", "CN", "CF"];
let hlValidStatusCode = [200, 202, 203];
let vendorBancaList = ["CSB", "FINCARE", "VKGB"];
class Utils {
  submitDataToTheSrver = async (formValues, successCallbackFunction, errorCallbackFunction, newJourney) => {
    // Do data manipultation and form the proper request structure
    // Make the api call

    let agent_id = await localStorage.getItem(StorageKeys.AGENT_ID);

    let pinCodeData = toJS(formValues.location);
    let dataToBeSubmitted = {
      journey_type: formValues.journey_type.id,
      pin_code: pinCodeData[0]?.id,
      office_name: pinCodeData[0]?.label,
      state: pinCodeData[0]?.location,
      customer_type: formValues.customer_type === "New Customer" ? "1" : "2",
      category: formValues.category.id,
      employee_code: formValues.employee_code,
      occupation: formValues.occupation.id,
      education: formValues.education.id,
      journey_id: newJourney ? null : formValues.journey_id,
      sales_details: {
        sp_code: formValues.pfa_sp_code,
        sp_name: formValues.pfa_sp_name,
        channel_name: formValues.channel_name,
        sales_branch: formValues.sales_branch,
        rm_code: formValues.dmrm_code,
        rm_name: formValues.dmrm_name,
        partner_rm_code: formValues.partner_rm_code,
        partner_branch_name: formValues.partner_branch_name,
        posp_training: formValues.posp_code,
        ulip_training: formValues.ulip_training_code,
      },
      agent_id: agent_id,
    };

    let response = await axios.post(APITypes.SAVE_PRE_NEED_DATA, dataToBeSubmitted);
    AuthStore.setJourneyId(response.data.journey_id);

    if (response.status == "success") {
      if (successCallbackFunction !== undefined) {
        successCallbackFunction(response);
      }
    } else {
      if (errorCallbackFunction !== undefined) {
        errorCallbackFunction();
      }
    }
  };

  placeHolderFunction = (Inputlabel) => {
    if (Inputlabel == "Mobile No.") {
      return "1234567890";
    }
    if (Inputlabel == "PAN No.") {
      return "ABCDE1234Z";
    }
    if (Inputlabel == "Customer ID") {
      return "customerID";
    }
    if (Inputlabel == "Customer ID") {
      return "customer ID";
    }
    if (Inputlabel == "Policy No") {
      return "Policy No";
    }
  };

  async fetchPincode(pinCode, form) {

    if (pinCode.charAt(0) == 0) {
      form.$("location").resetValidation();
      form.$("location").invalidate("First number should be non zero");
      form.$("location").set("extra", {
        optionList: [],
      });
      return;
    } else if (pinCode.length >= 5) {
      form.$("location").resetValidation();
      form.$("location").invalidate("Enter valid PIN Code");
    } else if (pinCode.charAt(0) !== 0) {
      form.$("location").resetValidation();
    }

    let regex = /^[1-9][0-9]{5}$/;
    if (regex.test(pinCode)) {
      form.$("location").resetValidation();

      var options = [];
      try {
        let parsedValue = parseInt(pinCode);

        if (parsedValue === NaN) return;
        const response = await axios.get(`${APITypes.GET_PINCODE}?pinCode=${pinCode}`);

        if (response.data[0].city === undefined) {
          return options;
        }
        options.push({
          id: response.data[0].pinCode,
          label: response.data[0].city,
          location: response.data[0].state,
        });
        global.mainLoading(false)
      } catch (error) {
        console.log("Error is ", error);
        form.$("location").invalidate("No matches found");
      } finally {
        form.$("location").set("extra", {
          optionList: options,
        });
        global.mainLoading(false)
        return options;
      }
    } else {
      form.$("location").set("extra", {
        optionList: [],
      });
      form.$("location").invalidate("Enter valid PIN Code");
      global.mainLoading(false)
    }
  }

  handleModalExit = (props, hideModal) => {
    AuthStore.handleRedirectionToPragati();
    hideModal();
  };

  checkSpValidationLogic = async (formController, pragatiData, updatedValue) => {
    let { login_code, login_agent_code, sub_channel_code, sub_sub_channel_code, user_level, employee_code, channel_code } = pragatiData;

    let spCode = login_agent_code;
    if (updatedValue !== undefined) {
      spCode = updatedValue.toUpperCase();
      // await this.fetchHLDataAndSetValue(formController, spCode, pragatiData);
      await this.fetchHLDataAndSetValue(formController, spCode, pragatiData);
    }

    let partner_rm_code = login_agent_code;

    let spCodePrefix = spCode.substring(0, 2);

    let isSubSubChannelCodeValid = validSubSubChannelCodeList.includes(sub_sub_channel_code);

    if (isSubSubChannelCodeValid) {
      if (spCodePrefix === sub_sub_channel_code) {
        // let data = await AuthStore.getHLAgentApp(spCode);
        // let responseData = data.response_data
        // call hl response function
        await this.fetchHLDataAndSetValue(formController, spCode, pragatiData);
      } else {
        alert("SP Code should start with " + sub_sub_channel_code);
        formController.$("proceed_btn").set("disabled", true);
        return;
      }
    }

    // await this.fetchHLDataAndSetValue(formController, spCode, pragatiData);
    // formController.$("proceed_btn").set("disabled", false);
  };

  checkRmCodeEditable = (formController, pragatiData) => {
    let { login_agent_code, user_level, employee_code, channel_code, sub_channel_code, sub_sub_channel_code } = pragatiData;

    let isRmCodeEditable = "";

    if ((user_level == "1" && employee_code === "B90094") || validSubSubChannelCodeList.includes(sub_sub_channel_code) || (channel_code === "CB" && sub_channel_code === "B")) {
      isRmCodeEditable = true;

      if (user_level !== "1" || validSubSubChannelCodeList.includes(sub_sub_channel_code) || (channel_code === "CB" && sub_sub_channel_code === "B")) {
        // call hl agent details
        this.fetchHLDataAndSetValue(formController, login_agent_code, pragatiData);
      }
    } else if (user_level !== "1" && employee_code === "B90094") {
      isRmCodeEditable = false;
      // call hl agent details
      this.fetchHLDataAndSetValue(formController, login_agent_code, pragatiData);
    } else {
      isRmCodeEditable = false;
    }

    formController.$("dmrm_code").set("disabled", !isRmCodeEditable);
    formController.$("dmrm_name").set("disabled", !isRmCodeEditable);

    formController.$("dmrm_code").set("extra", {
      ...formController.$("dmrm_code").extra,
      showBtn: isRmCodeEditable,
    });
    formController.$("dmrm_name").set("extra", {
      ...formController.$("dmrm_code").extra,
      showBtn: isRmCodeEditable,
    });
  };

  checkRmValidationLogic = async (formController, pragatiData, rmCodeValue) => {
    let { user_level, employee_code, channel_code, sub_channel_code, sub_sub_channel_code, login_agent_code } = pragatiData;

    let rmCode = login_agent_code;
    let rmCodePrefix = rmCode.substring(0, 2);
    let rmCodeValidation = false;

    if (rmCodeValue != undefined) {
      rmCode = rmCodeValue;
      rmCodePrefix = rmCode.substring(0, 2);
    }

    if (user_level === "1" && employee_code === "B90094") {
      if (rmCodePrefix === "B6" || rmCodePrefix === "B8") {
        rmCodeValidation = true;
      } else {
        alert("RM Code should start with B6 or B8");
        formController.$("proceed_btn").set("disabled", true);
        rmCodeValidation = false;
        return;
      }
    } else if (validSubSubChannelCodeList.includes(sub_sub_channel_code) || (channel_code === "CB" && sub_channel_code === "B")) {
      if (rmCodePrefix === sub_sub_channel_code) {
        rmCodeValidation = true;
      } else {
        alert("RM Code should start with " + sub_sub_channel_code);
        rmCodeValidation = false;
        formController.$("proceed_btn").set("disabled", true);
        return;
      }
    }

    if (rmCodeValidation) {
      // call hl agent ddetails api
      this.fetchHLDataAndSetValue(formController, rmCode, pragatiData, rmCodeValidation);
    }
    formController.$("proceed_btn").set("disabled", false);
  };

  checkPartnerCodeEditable = (formController, pragatiData, updatedValue) => {
    let { vendor_code, user_role, channel_code, login_code } = pragatiData;

    let partnerRmCodeEditable = false;
    let isPartnerRMDetailsVisible = false;

    if (channel_code === "BANCA" || vendor_code === "CSB" || vendor_code === "FINCARE" || vendor_code === "VKGB") {
      isPartnerRMDetailsVisible = true;
      // partner rm details will be visible
      if (user_role === "ETLIRM") {
        // partner rm code is editable
        partnerRmCodeEditable = true;
      } else {
        // partner rm code is not editable
        partnerRmCodeEditable = false;
      }

      formController.$("partner_rm_code").set("disabled", !partnerRmCodeEditable);
    }

    formController.$("partner_rm_code").set("extra", {
      ...formController.$("partner_rm_code").extra,
      showBtn: partnerRmCodeEditable,
      isVisible: isPartnerRMDetailsVisible,
    });

    // if (partnerRmCodeEditable) {
    //     this.searchPartnerRmCode(formController, pragatiData)
    // }
    if (updatedValue !== undefined) {
      let partnerRMCode = updatedValue.toUpperCase();
      // this.fetchHLDataAndSetValue(formController, spCode, pragatiData)
      this.searchPartnerRmCode(formController, pragatiData, partnerRMCode);
    }
  };

  searchPartnerRmCode = async (formController, pragatiData, partnerRMCode) => {
    let { vendor_code, login_code } = pragatiData;

    await this.fetchBancaDetailsAndSetValue(formController, partnerRMCode, vendor_code, login_code, "");
  };

  fetchHLDataAndSetValue = async (formController, spCode, pragatiData, fromRMValidation) => {
    let { login_code, login_agent_code, sub_channel_code, sub_sub_channel_code, user_level, employee_code, channel_code, vendor_code } = pragatiData;

    let spName, channelName, salesBranch, channelCode, ulipTraining, spDesignationCode, rmCode, rmName, pospCode;

    let spResponse = await AuthStore.getHLAgentApp(spCode);

    if (spResponse.response_data.agentHierarchyResponse == null) {
      alert("Agent details not found");
      formController.$("proceed_btn").set("disabled", true);
      return;
    }

    let responseData = spResponse.response_data;
    let agentHierarchyResponse = responseData.agentHierarchyResponse;

    if (hlValidStatusCode.includes(responseData.context.response_code) && agentHierarchyResponse.substatus_name.toLowerCase() != "impending" && responseData !== null) {
      sub_channel_code = agentHierarchyResponse.subchannel_code;
      spCode = agentHierarchyResponse.agent_code;
      spName = agentHierarchyResponse.agent_details.agent_name;
      channelName = agentHierarchyResponse.subchannel_name;
      salesBranch = agentHierarchyResponse.branch_name;
      channelCode = agentHierarchyResponse.channel_code;
      ulipTraining = agentHierarchyResponse.agent_details.ulip_training;
      spDesignationCode = agentHierarchyResponse.agent_details.designation_code;
      pospCode = agentHierarchyResponse.agent_details.designation_code; // posp code param to be updated after confirming from client.
      formController.$("ulip_training_code").value = ulipTraining;
      formController.$("posp_code").value = pospCode;

      if (agentHierarchyResponse.reporting_hierarchy_details && agentHierarchyResponse.reporting_hierarchy_details.length > 0) {
        let reportingHierarchyDetails = agentHierarchyResponse.reporting_hierarchy_details[0];
        let hierarchyFillers = reportingHierarchyDetails.hierarchy_fillers[0];
        rmCode = hierarchyFillers?.reporting_agent_code;
        rmName = hierarchyFillers?.reporting_agent_name;
      }

      if (vendorBancaList.includes(vendor_code)) {
        await this.fetchBancaDetailsAndSetValue(formController, login_code, vendor_code, login_code, "");
      }
    } else if (agentHierarchyResponse.substatus_name.toLowerCase() === "impending" && responseData !== null) {
      // show alert Agent is Suspended. Connect with DOPS
      alert("Agent is suspended. Connect with DOPS");
      formController.$("proceed_btn").set("disabled", true);
    } else {
      // show alert(Details not found)
      alert("Details not found");
      formController.$("proceed_btn").set("disabled", true);
    }
    AuthStore.agentName = spName;
    AuthStore.agentCode = spCode;
    await localStorage.setItem(StorageKeys.AGENT_NAME, spName);
    await localStorage.setItem(StorageKeys.AGENT_CODE, spCode);

    if (!fromRMValidation) {
      formController.$("pfa_sp_name").value = spName;
      formController.$("channel_name").value = channelName;
      formController.$("sales_branch").value = salesBranch;
      // Set response of HL Agent Details
      if ((user_level == "1" && employee_code == "B90094") || validSubSubChannelCodeList.includes(sub_sub_channel_code) || (channel_code == "CB" && sub_channel_code == "B")) {
        // set rm code & name as blank and rm code is editable
        formController.$("dmrm_code").value = "";
        formController.$("dmrm_name").value = "";
        formController.$("dmrm_name").set("disabled", true);
        // formController.$("pfa_sp_code").set("disabled", false)
      } else if (user_level != "1" && employee_code == "B90094") {
      } else {
        // get rm code & name from HL Agent Details
        if (rmCode && rmName) {
          formController.$("dmrm_code").value = rmCode;
          formController.$("dmrm_name").value = rmName;
        }
      }
    } else {
      formController.$("dmrm_name").value = spName;
      formController.$("dmrm_name").set("disabled", true);
    }

    if (!formController.$("pfa_sp_name").value || !formController.$("dmrm_code").value) {
      formController.$("proceed_btn").set("disabled", true);
    }
  };

  fetchBancaDetailsAndSetValue = async (formController, loginCode, vendorCode, logginCode, loginUniqueId) => {
    let bancaResponse = await AuthStore.getBancaPartnerDetails(loginCode, vendorCode, logginCode, loginUniqueId);

    let bancaresponseData = bancaResponse.data;

    let rmCode = bancaresponseData.RM_Code;
    let rmName = bancaresponseData.RM_Name;
    let partnerBranchName = bancaresponseData.Partner_Branch_Name;
    let partnerRMCode = loginCode;

    if (partnerRMCode !== null || partnerRMCode !== "") {
      // get partner rm code & autopopulate in partner rm code field and field is uneditable
      formController.$("partner_rm_code").value = loginCode;
      // formController.$("partner_rm_code").set("disabled", true);
    } else {
      formController.$("partner_rm_code").set("disabled", false);
      // partner rm code is editable
    }

    formController.$("partner_branch_name").value = partnerBranchName;
    formController.$("dmrm_name").value = rmName;
    formController.$("dmrm_code").value = rmCode;
    // formController.$("partner_rm_code").value = loginCode
  };
}

export default new Utils();
