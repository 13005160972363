import React, { Component } from 'react'
import { Button, Modal } from 'react-bootstrap'

import { observer } from 'mobx-react';
import OptimizationStore from '../../store/OptimizationStore';

// css Imports
import './validation-msg-for-recalculate.css'

const ValidationMsgForRecalculate = observer(class ValidationMsgForRecalculate extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    onHide = () => {
        OptimizationStore.setRecalculateProductsSpecs(false)
    }
    render() {

        return (
            <div className='validation-for-recalculate-msg'>
                <Modal
                    size='md'
                    aria-labelledby='contained-modal-title-vcenter'
                    centered
                    backdrop="static"
                    className='error-modal'
                    show={OptimizationStore.recalculateProductsSpecs}
                    onHide={this.onHide}
                    onExited={this.onHide}
                    keyboard={false}
                >
                    <Modal.Body className=''>
                        <div className='recalculate-modal-body'>
                            <span className='font-weight-medium font-size-22 text-primary-title-label-color'>Please click on recalculate before proceeding</span>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='recalculate-modal-footer'>
                        <Button variant='secondary' className='font-size-18 m-0 px-5' onClick={this.onHide}>
                            Ok
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    }
});

ValidationMsgForRecalculate.defaultsProps = {}

ValidationMsgForRecalculate.propTypes = {}

export default ValidationMsgForRecalculate
