// React imports
import { useContext, useEffect, useState } from "react";

// Package Imports
import { observer } from "mobx-react";
import { Col, Row, Form, Card } from "react-bootstrap";
import moment from "moment";
import { toJS } from "mobx";

// Project Imports
import { BottomsheetDropdown, DatepickerModal } from "components";
import OptionalDetailsCards from "modules/family-details-form/components/OptionalDetailsCards";
import EMIOptionalDetailsCards from "modules/family-details-form/components/EMIOptionalDetailsCard";
import { changeFieldValue, toggleDisableOptions, submitCalculatorValue } from "../form/protection-calculator/utils";
import ChooseNeedFormContext from "context/ChooseNeedFormContext";
import ProtectionFormController from "../form/protection-calculator/controller";
import AuthStore from "modules/login/store/AuthStore";
import FamilyMemberTypeEnum from "modules/family-details-form/FamilymemberTypeEnum";
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import { NUMBER_REGEX } from "constants/Constants";

const ProtectionCalculator = ({ setGraphData, setCalculatedData, setShowCalculatedValue, hideCalculatorModal,setDateOfBirth,chooseNeedFormController }) => {

  const [formController, setFormController] = useState(undefined)
  const [errorClass, setErrorClass] = useState("")
  const chooseNeedFormContext = useContext(ChooseNeedFormContext)

  useEffect(() => {
    if (formController === undefined) {
      let currentLocation = window.location.pathname
      let formController = new ProtectionFormController()
      let familymemberId
      let monthlyIncomeValue
      let dob
      let membersExpenseList
      let emiList
      if (OptimizationStore.selectedFamilyMemberId !== undefined && currentLocation.includes("customization")) {
        let selfData = AuthStore.journeyData.family_details_list.find(item => item.family_member_type === FamilyMemberTypeEnum.SELF)

        let selfFamilymemberDetails = AuthStore.journeyData.family_details_list.find(item => item.id === OptimizationStore.selectedFamilyMemberId)
        monthlyIncomeValue = selfFamilymemberDetails.monthly_income || selfData.monthly_income
        dob = selfFamilymemberDetails.dob || selfData.dob
        familymemberId = selfFamilymemberDetails.id || selfData.id
        membersExpenseList = selfFamilymemberDetails.expense_list.length !== 0 ? selfFamilymemberDetails.expense_list : selfData.expense_list
        emiList = selfFamilymemberDetails.user_emi.length !== 0 ? selfFamilymemberDetails.user_emi : selfData.user_emi
      } else {
        monthlyIncomeValue = chooseNeedFormContext ? chooseNeedFormContext.formController.$("protection_for_my_family.monthly_income").value : "",
          dob = chooseNeedFormContext ? chooseNeedFormContext.formController.$("protection_for_my_family.dob").value : "",
          familymemberId = AuthStore.journeyData.family_details_list.find(item => item.family_member_type === FamilyMemberTypeEnum.SELF).id
        membersExpenseList = AuthStore.journeyData.family_details_list.find(item => item.family_member_type === FamilyMemberTypeEnum.SELF).expense_list
        emiList = AuthStore.journeyData.family_details_list.find(item => item.family_member_type === FamilyMemberTypeEnum.SELF).user_emi
      }

      let expenseList = toJS(AuthStore.expenseList);
      let journeyDetails = toJS(AuthStore.journeyData)
      let lifeCoverDetails = toJS(AuthStore.lifeCoverList)
      formController.setDefaults(
        expenseList,
        lifeCoverDetails,
        journeyDetails,
        monthlyIncomeValue,
        dob,
        familymemberId,
        membersExpenseList,
        emiList
      )
      setFormController(formController)

      if(setDateOfBirth){
        setDateOfBirth(formController.$("date_of_birth").value)
      }
    }
  }, [])

  let monthlyIncomeOnChangeCallback = () => { };
  let dobOnChangeCallback = () => { };

  if (chooseNeedFormContext !== undefined) {
    monthlyIncomeOnChangeCallback = chooseNeedFormContext.formController.$("protection_for_my_family.monthly_income").onChange
    dobOnChangeCallback = chooseNeedFormContext.formController.$("protection_for_my_family.dob").onChange
  }

  if (formController === undefined) return null

  return (
    <Col className='d-flex flex-column justify-content-between calculator-modal-top-second-col'>
      <Row>
        <Col xs={12} className="d-lg-block">
          <Row>
            <Col>
              <p className='font-size-16 font-weight-medium text-primary'>
                <span>Enter Details</span>
                <span className='font-weight-light'> to Calculate the required</span>
                <span> Protection Amount</span>
              </p>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          <Row className='calculator-main-columns-row'>
            <Col xs={6} lg={4}>
              <p className='font-weight-medium font-size-14 text-primary mb-1 mt-1 mt-lg-0'>
                {formController.$("monthly_income").label}
              </p>
              <Form.Group className='mb-0'>
                <Form.Control
                  placeholder='Monthly Income'
                  className='calculator-monthly-income-field font-size-12 font-weight-light'
                  required
                  value={formController.$("monthly_income").value}
                  onChange={(e) => {
                    if(NUMBER_REGEX.test(e.target.value)){
                      formController.$("monthly_income").value = e.target.value
                      return monthlyIncomeOnChangeCallback(e)
                    }
                    if(e.target.value.length <= 1){
                      formController.$("monthly_income").value = ""
                    }
                  }}
                  // type="number"
                  style={formController.$("monthly_income").error ? { border: "1px solid red" } : { border: "1px solid var(--graph-grey-800-color)" }}
                />
                {formController.$("monthly_income").error && <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>{formController.$("monthly_income").error}</p>}
              </Form.Group>
            </Col>

            <Col xs={6} lg={4}>
              <p className='font-weight-medium font-size-14 text-primary mb-1 mt-1 mt-lg-0'>{formController.$("date_of_birth").label}</p>
              <DatepickerModal
                minDate={moment().subtract(18, "years").toString()}
                value={formController.$("date_of_birth").value}
                date={formController.$("date_of_birth").value}
                onChange={(e) => {
                  dobOnChangeCallback(e)
                  formController.$("date_of_birth").onChange(e)
                }}
                showErrorMsg={formController.$("date_of_birth").error}
                errorMsg={formController.$("date_of_birth").error}
                textStyle='font-size-lg-12 font-size-14 font-weight-light'
              />
            </Col>
          </Row>
        </Col>

        <Col md={12}>
          <Row className='mt-4 calculator-main-columns-row'>
            <Col xs={12} lg={4} className="d-flex flex-column flex-column-reverse flex-lg-column">

              <div className="">
                <p
                  className='calculator-text-Heading font-size-14 mb-1'>
                  {formController.$("monthly_expense").label}
                  <span className={`font-size-11 ${errorClass}`}> - Please fill at least one detail.</span>
                </p>

                <OptionalDetailsCards
                  list={formController.$("monthly_expense").get()}
                  onChange={(field_name, value) => {
                    changeFieldValue(formController, "monthly_expense", field_name, value);
                  }}
                  toggleDisableOption={(field_name, isDisabled) => toggleDisableOptions(formController, "monthly_expense", field_name, isDisabled)}
                />
              </div>
              <article className="mb-3 mb-lg-0">
                <p className='calculator-text-Heading font-size-14 mb-1  mt-4 mt-lg-3'>{formController.$("current_life_cover").label}</p>
                <Card className='card-text-field-component current-Life_cover-Card mt-2 mt-lg-0'>
                  <Card.Body className='p-3'>
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey='label'
                      bottomsheetTitle={formController.$("current_life_cover").extra.bottomSheetTitle}
                      menuList={formController.$("current_life_cover").extra.optionList}
                      value={formController.$("current_life_cover").value}
                      onSelect={formController.$("current_life_cover").onChange}
                      showErrorMsg={formController.$("current_life_cover").error !== null}
                      errorMsg={formController.$("current_life_cover").error}
                      disabled={false}
                    />
                  </Card.Body>
                </Card>
              </article>
            </Col>

            <Col xs={12} lg={4}>
              <p className='calculator-text-Heading font-size-14 mt-4 mt-lg-0 mb-1'>{formController.$("monthly_emi").label}</p>
              <EMIOptionalDetailsCards
                list={formController.$("monthly_emi").get()}
                onChange={(field_name, nestedFieldName, value) => {
                  formController.$("monthly_emi").$(field_name).$(nestedFieldName).value = value;
                }}
                toggleDisableOption={(field_name, isDisable) => {
                  formController.$("monthly_emi").$(field_name).set("disabled", isDisable);
                  formController.$("monthly_emi").$(field_name).$("option_range").set("disabled", isDisable);
                  formController.$("monthly_emi").$(field_name).$("paid_years").set("disabled", isDisable);
                  formController.$("monthly_emi").$(field_name).$("total_years").set("disabled", isDisable);
                  formController.$("monthly_emi").$(field_name).$("option_range").resetValidation();
                  formController.$("monthly_emi").$(field_name).$("paid_years").resetValidation();;
                  formController.$("monthly_emi").$(field_name).$("total_years").resetValidation();
                }}
              />
            </Col>

            <Col xs={12} md={4}>
              <p className='calculator-text-Heading font-size-14 mt-4 mt-lg-0 mb-1'>{formController.$("monthly_savings").label}</p>
              <OptionalDetailsCards
                showNoOfYears={false}
                list={formController.$("monthly_savings").get()}
                onChange={(field_name, value) => {
                  changeFieldValue(formController, "monthly_savings", field_name, value);
                }}
                toggleDisableOption={(field_name, isDisabled) => toggleDisableOptions(formController, "monthly_savings", field_name, isDisabled)}
              />

              <p className='calculator-text-Heading font-size-14 mt-4 mt-lg-3 pt-lg-1 mb-1'>{formController.$("current_savings").label}</p>
              <OptionalDetailsCards
                showNoOfYears={false}
                list={formController.$("current_savings").get()}
                onChange={(field_name, value) => {
                  changeFieldValue(formController, "current_savings", field_name, value);
                }}
                toggleDisableOption={(field_name, isDisabled) => toggleDisableOptions(formController, "current_savings", field_name, isDisabled)}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div className='button-container'>

        <Row className='mt-3 pt-2 justify-content-end mb-3'>
          <div className='col col-md-3'>
            <button
              className='btn btn-lg modal-cta-btn modal-cta-btn-link py-1'
              onClick={hideCalculatorModal}
            >Cancel</button>
          </div>
          <div className='col col-md-3'
            onClick={() => submitCalculatorValue(
              formController,
              setGraphData,
              setCalculatedData,
              setShowCalculatedValue,
              setErrorClass,
              chooseNeedFormController
            )}
          >
            <button className='btn btn-secondary btn-lg modal-cta-btn py-1' type='submit'>
              Calculate
            </button>
          </div>
        </Row>
      </div>
    </Col>
  );
};

export default observer(ProtectionCalculator);
