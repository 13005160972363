
import { observable, makeObservable } from 'mobx';

import AuthStore from '../../login/store/AuthStore';

class ShareQuotesStore {
    customerName = '';
    mobileNo = '';
    whatsappNo = '';
    email = '';
    nameErrorMsg = '';
    emailErrorMsg = '';
    mobileNoErrorMsg = '';
    whatsappNoErrorMsg = '';
    emailRegex = new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
    nameRegex = new RegExp(/^[A-Za-z0-9 ]+$/);

    constructor() {
        makeObservable(this, {
            customerName: observable,
            mobileNo: observable,
            whatsappNo: observable,
            email: observable,
            nameErrorMsg: observable,
            emailErrorMsg: observable,
            mobileNoErrorMsg: observable,
            whatsappNoErrorMsg: observable
        });
    }

    onPreviewtBtnClick(props) {
        this.nameErrorMsg = '';
        this.emailErrorMsg = '';
        this.mobileNoErrorMsg = '';
        this.whatsappNoErrorMsg = '';

        if (this.customerName == '') {
            this.nameErrorMsg = 'Please enter Customer Name'

        } else if (!this.nameRegex.test(this.customerName)) {
            this.nameErrorMsg = 'Special Characters not accepted'
        }

        if (!this.emailRegex.test(this.email)) {
            this.emailErrorMsg = 'Please enter correct email'
        }

        if (this.mobileNo.length !== 10) {
            this.mobileNoErrorMsg = 'Please enter correct number'
        }

        if (this.whatsappNo.length !== 10) {
            this.whatsappNoErrorMsg = 'Please enter correct number'
        }
        if (this.customerName !== '' &&
            this.nameRegex.test(this.customerName) &&
            this.email !== '' &&
            this.emailRegex.test(this.email) &&
            this.mobileNo !== '' &&
            this.mobileNo.length == 10 &&
            this.whatsappNo !== '' &&
            this.whatsappNo.length == 10) {
            // this.props.history.push(`/quotation-preview/${AuthStore.journeyId}`, { customerName: this.state.customerName })
            props.history.push(`/quotation-preview/${AuthStore.journeyId}`)
        }
    }

    resetForm() {
        this.customerName = '';
        this.mobileNo = '';
        this.whatsappNo = '';
        this.email = '';
        this.nameErrorMsg = '';
        this.emailErrorMsg = '';
        this.mobileNoErrorMsg = '';
        this.whatsappNoErrorMsg = '';
    }
}

export default new ShareQuotesStore();
