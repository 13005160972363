import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import OptimizationStore from '../../../store/OptimizationStore';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Minimum Premium Validation
    validatePremium = (formRef) => {
        let premium = Number(formRef.$('premium').value);
        let frequency = formRef.$('payment-frequency').value.id;
        // switch (frequency) {
        //     case '1':
        //         if (premium < 15000) {
        //             this.errorMessage.push(errorMessageKeys.premium.annual)
        //         }
        //         break;

        //     case '2':
        //         if (premium < 8000) {
        //             this.errorMessage.push(errorMessageKeys.premium.semi_annual)
        //         }
        //         break

        //     case '3':
        //         if (premium < 4000) {
        //             this.errorMessage.push(errorMessageKeys.premium.quaterly)
        //         }
        //         break;

        //     case '4':
        //         if (premium < 1500) {
        //             this.errorMessage.push(errorMessageKeys.premium.monthly)
        //         }
        //         break;
        //     case '5':
        //         if (premium < 45000) {
        //             this.errorMessage.push(errorMessageKeys.premium.single_pay)
        //         }
        //         break;
        // }
    }

    //PT, PPT validation
    validatePT = (formRef) => {
        // let pt = formRef.$('policy-term').value.id;
        // let ppt = formRef.$('premium-term').value.id;
        // if (ppt > (pt - 5) && ppt != pt) {
        //     this.errorMessage.push(errorMessageKeys.validPPT)
        // }

    }

}
export default new Validator();