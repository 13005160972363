import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import Utils from '../../../../../constants/utils';
import OptimizationStore from '../../../store/OptimizationStore';
import fetchRiderValidation from 'utils/fetchRiderValidation'
import ProductConstants from 'constants/ProductConstants';
class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Validate PPT
    validatePPT = (form) => {
        // let pt = form.$('policy-term').value.id
        // let ppt = form.$('premium-term').value.id


        // if (ppt == 10 || ppt == 12) {
        //     if (pt < 15) {
        //         this.errorMessage.push(errorMessageKeys.minimum_ppt)
        //     }
        // }

    }

    // Minimum Premium Validation for 5 pay & 7 pay
    minimalPremiumFor5Pay7Pay = (form) => {
        // let frequency = form.$('payment-frequency').value.id
        // let premium = form.$('premium').value
        // let ppt = form.$('premium-term').value.id
        // premium = parseInt(premium) * parseInt(frequency);

        // if (ppt > 7) {
        //     return;
        // }

        // switch (frequency) {
        //     case '1':
        //         if (premium < 36000) {
        //             this.errorMessage.push(errorMessageKeys.minimum_premium_5_7_pay.annualy)
        //         }
        //         break;

        //     case '2':
        //         if (premium < 72000) {
        //             this.errorMessage.push(errorMessageKeys.minimum_premium_5_7_pay.half_yearly)
        //         }
        //         break

        //     case '3':
        //         if (premium < 72000) {
        //             this.errorMessage.push(errorMessageKeys.minimum_premium_5_7_pay.quaterly)
        //         }
        //         break;

        //     case '4':
        //         if (premium < 72000) {
        //             this.errorMessage.push(errorMessageKeys.minimum_premium_5_7_pay.monthly)
        //         }
        //         break;
        // }
    }

    // Minimum Premium Validation for 10 pay & 12 pay
    minimalAnnualPremiumFor10Pay12Pay = (form) => {
        // let frequency = form.$('payment-frequency').value.id
        // let premium = form.$('premium').value
        // let ppt = form.$('premium-term').value.id
        // premium = parseInt(premium) * parseInt(frequency);

        // if (ppt < 7) {
        //     return;
        // }
        // switch (frequency) {
        //     case '1':
        //         if (premium < 24000) {
        //             this.errorMessage.push(errorMessageKeys.minimum_premium_10_12_pay.annualy)
        //         }
        //         break;

        //     case '2':
        //         if (premium < 48000) {
        //             this.errorMessage.push(errorMessageKeys.minimum_premium_10_12_pay.half_yearly)
        //         }
        //         break

        //     case '3':
        //         if (premium < 48000) {
        //             this.errorMessage.push(errorMessageKeys.minimum_premium_10_12_pay.quaterly)
        //         }
        //         break;

        //     case '4':
        //         if (premium < 48000) {
        //             this.errorMessage.push(errorMessageKeys.minimum_premium_10_12_pay.monthly)
        //         }
        //         break;
        // }
    }

    // Family Protection Sub Product Validation
    subProductValidation = (form, data) => {
        let product_name = data.data.product_name;
        let option = form.$('option').value.id
        if (product_name == ProductConstants.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE) {
            if (option != '2') {
                this.errorMessage.push(errorMessageKeys.sub_product_validation);
            }
        }
    }

    // Family Protection Option validation
    familyProtectionOption = (form, data) => {
        // let age = data.data.bi_request.LI_ENTRY_AGE
        // let option = form.$('option').value.id
        // if (option == 1 && age < 1) {
        //     this.errorMessage.push(errorMessageKeys.option_validation.base_option_minimum)
        // }
        // if (option == 1 && age > 50) {
        //     this.errorMessage.push(errorMessageKeys.option_validation.base_option_maximum)
        // }
        // if (option == 2 && age < 18) {
        //     this.errorMessage.push(errorMessageKeys.option_validation.family_protection_option_minimum)
        // }
        // if (option == 2 && age > 40) {
        //     this.errorMessage.push(errorMessageKeys.option_validation.family_protection_option_maximum)
        // }
    }

    // rider sum assured validation
    riderSAValidation = (form, productData) => {
        // let sum_assured = productData.data.firstYearResponse.SA

        // if (form.$('ADB').value) {
        //     var ADB_value = Number(form.$('ADB-dropdown').value)
        //     if (ADB_value > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }

        // if (form.$('ATPDRider').value) {
        //     var ATPDRider_value = Number(form.$('ATPDRider-dropdown').value)
        //     if (ATPDRider_value > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }

        // if (form.$('CriticalIllnessRider').value) {
        //     var CriticalIllnessRider_value = Number(form.$('CriticalIllnessRider-dropdown').value)
        //     if (CriticalIllnessRider_value > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }

        // if (form.$('IncomeBenefitRider').value) {
        //     var IncomeBenefitRider_value = Number(form.$('IncomeBenefitRider-dropdown').value)
        //     if (IncomeBenefitRider_value > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }
    }

    // Validation based on riders
    ridersAgeValidation = (formRef, productData) => {
        // let age = productData.data.bi_request.LI_ENTRY_AGE
        // let wop = formRef.$('WaiverOfPremiumRider').value
        // let adb = formRef.$('ADB').value
        // let atpd = formRef.$('ATPDRider').value
        // let criticalIllness = formRef.$('CriticalIllnessRider').value
        // let incomeBenefit = formRef.$('IncomeBenefitRider').value

        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // let isRiderSelected = (wop || adb || atpd || criticalIllness || incomeBenefit);

        // if (!isRiderSelected) {
        //     return;
        // }

        // if (isMinimumAge) {
        //     this.errorMessage.push(errorMessageKeys.MINIMUM_RIDER_AGE)
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push(errorMessageKeys.MAXIMUM_RIDER_AGE)
        // }
    }

    // Validation based on PWB rider
    pwbRiderAgeValidation = (formRef, productData) => {
        // let age = productData.data.bi_request.PROPOSER_AGE
        // let isSameProposer = productData.data.bi_request.SameProposer == true
        // let PWBRider = formRef.$('PayorWaiverBenefitRider').value

        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // if (!PWBRider) {
        //     return;
        // }

        // if (isSameProposer) {
        //     this.errorMessage.push(errorMessageKeys.PWB_RIDER_VALIDATION)
        // }
        // if (isMinimumAge) {
        //     this.errorMessage.push(errorMessageKeys.MINIMUM_RIDER_AGE)
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push(errorMessageKeys.MAXIMUM_RIDER_AGE)
        // }
    }

    // Riders Validation
    ridersValidation = (formRef, productData) => {
        // let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
        // let adb = formRef.$('ADB-dropdown').value
        // let atpd = formRef.$('ATPDRider-dropdown').value
        // let income = formRef.$('IncomeBenefitRider-dropdown').value

        // let adbValidation = fetchRiderValidation(adb, formRef.$('ADB-dropdown').label)
        // if (adbValidation) {
        //     this.errorMessage.push(adbValidation)
        // }

        // let atpdValidation = fetchRiderValidation(atpd, formRef.$('ATPDRider-dropdown').label)
        // if (atpdValidation) {
        //     this.errorMessage.push(atpdValidation)
        // }

        // let criticalIllnessValidation = fetchRiderValidation(criticalIllness, formRef.$('CriticalIllnessRider-dropdown').label)
        // if (criticalIllnessValidation) {
        //     this.errorMessage.push(criticalIllnessValidation)
        // }

        // let incomeValidation = fetchRiderValidation(income, formRef.$('IncomeBenefitRider-dropdown').label)
        // if (incomeValidation) {
        //     this.errorMessage.push(incomeValidation)
        // }
    }

}
export default new Validator();