import {  validationForEmailAndMobileNumber,validationForName,validatedSalutation,validationForCountryCode } from "./validation";
// Dummy obejct to prefill all the family members field

const CustomerCommunicationFieldStructureObject = {
 
 name: "",
 label: "",
 disabled: false,
 fields: [
  {
   name: 'id',
   value: '',
   type: 'hidden'
  },
  {
    name: "salutation",
    value: {},
    validators: [validatedSalutation],
    label: "salutation",
    extra: {
        bottomSheetTitle: "Select",
        selectedOptionLabelKey: "label",
        optionList: [
        ]
    }
},
   {
     name: "first_name",
     type: "text",
     placeholder: "First Name",
     value: "",
     validators: [validationForName],
   },
   {
    name: "middle_name",
    type: "text",
    placeholder: "Middle Name",
    value: "",
    // validators: [validationForName],
  },
  {
   name: "last_name",
   type: "text",
   placeholder: "Last Name",
   value: "",
   validators: [validationForName],
 },
 {
  name: "mobile_number_code",
  placeholder: "91",
  value: 91,
  validators: [validationForCountryCode],
},
{
 name: "mobile_number",
 placeholder: "Enter Mobile No.",
 value: "",
 validators: [validationForEmailAndMobileNumber],
},
{
name: "email",
type: "text",
placeholder: "Enter Email Address",
value: "",
validators: [validationForEmailAndMobileNumber],
},
{
name: "whatsapp_mobile_number_code",
placeholder: "91",
value: 91,
// validators: [validationForCountryCode],
},
{
name: "whatsapp_mobile_number",
placeholder: "Enter Mobile No.",
value: "",
validators: [validationForEmailAndMobileNumber],
},
{
name: "same_as_mobile_number",
label: "same as above",
disabled: false,
value:false,
},
 ],
}

export default CustomerCommunicationFieldStructureObject
