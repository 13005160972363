// Project Imports
import axios from "utils/axios";
import * as APITypes from "constants/API";
import isObjectEmpty from "utils/isObjectEmpty";
import Utils from "./utils";

const fetchMastersData = async () => {
  const occupationRequest = axios.get(APITypes.OCCUPATION_MASTER);
  const educationRequest = axios.get(APITypes.EDUCATION_MASTER);
  const categoryRequest = axios.get(APITypes.CATEGORY_MASTER);
  const journeyTypeRequest = axios.get(APITypes.JOURNEY_MASTER);

  return axios.all([occupationRequest, educationRequest, categoryRequest, journeyTypeRequest]).then(
    axios.spread((...responses) => {
      return {
        occupationResponse: responses[0],
        educationResponse: responses[1],
        categoryResponse: responses[2],
        journeyTypeResponse: responses[3],
      };
    })
  );
};

const prefillMasterData = (formController, masterData) => {
  let { occupationResponse, educationResponse, categoryResponse, journeyTypeResponse } = masterData;

  let selectedEducation = educationResponse.data.find((item) => item.label == "Graduate");

  let selectedCategory = categoryResponse.data.find((item) => item.label == "Others");

  let selectedOccupation = occupationResponse.data.find((item) => item.label === "Salaried");

  formController.$("journey_type").set("extra", {
    ...formController.$("journey_type").extra,
    optionList: journeyTypeResponse.data,
  });

  formController.$("occupation").set("extra", {
    ...formController.$("occupation").extra,
    optionList: occupationResponse.data,
  });

  formController.$("category").set("extra", {
    ...formController.$("category").extra,
    optionList: categoryResponse.data,
  });

  formController.$("education").set("extra", {
    ...formController.$("education").extra,
    optionList: educationResponse.data,
  });

  if (selectedCategory !== undefined) {
    formController.$("category").value = selectedCategory;
  }

  if (selectedEducation !== undefined) {
    formController.$("education").value = selectedEducation;
  }

  if (selectedOccupation !== undefined) {
    formController.$("occupation").value = selectedOccupation;
  }
};

const preFillFormvalues = async (formController, journeyData, journeyId, masterData) => {
  let { journey_details, family_details_list } = journeyData;
  let { occupationResponse, educationResponse, categoryResponse, journeyTypeResponse } = masterData;
  let selfFamilyMember = family_details_list.find((family_member) => (family_member.family_member_type = "SELF"));

  let categoryValue;
  let journeyTypeValue;

  if (journey_details !== undefined) {
    journeyTypeValue = journeyTypeResponse.data.find((item) => item.id === journey_details.journey_type);
    formController.$("journey_type").value = journeyTypeValue;

    categoryValue = categoryResponse.data.find((item) => item.id === journey_details.category);
    formController.$("category").value = categoryValue;
  }

  if (journeyId !== undefined) {
    formController.$("journey_id").value = journeyId;
  }

  if (selfFamilyMember !== undefined) {
    let selectedEducation = educationResponse.data.find((item) => item.id === selfFamilyMember.education);
    let selectedOccupation = occupationResponse.data.find((item) => item.id === selfFamilyMember.occupation);

    let pinCodeValue = [
      {
        id: selfFamilyMember.pin_code,
        label: selfFamilyMember.office_name,
        location: selfFamilyMember.state,
      },
    ];

    if (selfFamilyMember.education !== undefined) {
      formController.$("education").value = selectedEducation;
    }

    if (selfFamilyMember.occupation !== undefined) {
      formController.$("occupation").value = selectedOccupation;
    }

    formController.$("location").value = pinCodeValue;

    formController.$("location").set("extra", {
      optionList: pinCodeValue,
    });
  }
};

const initialDefaults = async (formController, journeyData, journeyId) => {
  let masterData = await fetchMastersData();
  prefillMasterData(formController, masterData, journeyData);
  // Initialise form values

  if (journeyId !== undefined) {
    formController.$("journey_id").value = journeyId;
  }

  if (isObjectEmpty(journeyData)) {
    return;
  }
  preFillFormvalues(formController, journeyData, journeyId, masterData);
};

const setSpRMFieldValues = async (formController, pragatiData) => {
  let { vendor_code, login_agent_code, user_level, user_role } = pragatiData;

  let isSpCodeEditable = "";

  let spCode = "";

  user_level = parseInt(user_level);

  let availableVendorCode = ["CSB", "FINCARE", "VKGB"];

  let validVendorCode = availableVendorCode.includes(vendor_code);

  if (vendor_code === "EBL" || user_level == 1 || (validVendorCode && user_role != "ETLIRM")) {
    // SP Code =  Call HL Agent details for SP Code
    spCode = login_agent_code;
    formController.$("pfa_sp_code").value = spCode?.toUpperCase();

    await Utils.checkSpValidationLogic(formController, pragatiData);
    await Utils.fetchHLDataAndSetValue(formController, spCode, pragatiData);

    if (validVendorCode) {
      if (user_role == "SP") {
        isSpCodeEditable = false;
      } else if (user_role == "ETLIRM") {
        isSpCodeEditable = true;
      } else {
        isSpCodeEditable = true;
      }
    } else {
      isSpCodeEditable = false;
    }
  } else {
    isSpCodeEditable = true;
  }
  if (spCode === null || spCode === "") { isSpCodeEditable = false }
  console.log("IS sp editable ", isSpCodeEditable);

  formController.$("pfa_sp_code").set("disabled", !isSpCodeEditable);
  formController.$("pfa_sp_code").set("extra", {
    ...formController.$("pfa_sp_code").extra,
    showBtn: isSpCodeEditable,
  });

  // await Utils.checkSpValidationLogic(formController, pragatiData);
  await Utils.checkPartnerCodeEditable(formController, pragatiData);

  await Utils.checkRmCodeEditable(formController, pragatiData);

  await Utils.checkRmValidationLogic(formController, pragatiData);

};

export { initialDefaults, setSpRMFieldValues };
