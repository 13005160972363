// Package Imports
const { Form } = require("mobx-react-form")
import yup from "mobx-react-form/lib/validators/YUP"
import vjf from "mobx-react-form/lib/validators/VJF"
import * as $pkg from "yup"
import validator from "validator"

// Project Imports
import formObserver from "./formObserver"
import setInitialDefaults from "./initialDefaults"

class AddNeedFormController extends Form {
  plugins() {
    return {
      vjf: vjf(validator),
    }
  }

  setup() {
    return {
      fields: [
        {
          name: "initial_selected",
          value: [],
        },
        {
          name:"final_selected",
          value:""
        },
        {
          name: "enable_proceed",
          value: false,
        },
        {
          name: "journey_id",
          type: "hidden",
        },
        {
          name: "member_id",
          type: "hidden",
        },
        {
          name: "protection_for_my_family",
          label: "Protection for my Family",
          disabled: true,
          fields: [
            {
              name: "id",
              type: "hidden",
            },
          ],
        },
        {
          name: "protection_for_my_health",
          label: "Protection for my Health",
          disabled: true,
          fields: [
            {
              name: "id",
              type: "hidden",
            },
          ],
        },
        {
          name: "grow_my_money",
          label: "Grow my Money",
          disabled: true,
          fields: [
            {
              name: "id",
              type: "hidden",
            },
          ],
        },
        {
          name: "save_for_children",
          label: "Save for Children",
          disabled: true,
          fields: [
            {
              name: "id",
              type: "hidden",
            },
          ],
        },
        {
          name: "save_for_retirement",
          label: "Save for Retirement",
          disabled: true,
          fields: [
            {
              name: "id",
              type: "hidden",
            },
          ],
        },
      ],
    }
  }

  setDefaults(journeyData, journeyId) {
    setInitialDefaults(this, journeyData, journeyId)
    // Observer fields
    formObserver(this)
  }
}

export default AddNeedFormController
