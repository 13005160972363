// Product Constants
import * as Constants from "constants/Constants"

const getAnnualizedPremium = function (premium, premiumFrequency) {
  let annualizedPremium = 0;
  let modalPremium = typeof(premium) === 'number' ? premium : parseInt(premium);
  switch (premiumFrequency) {
    case 4:
        annualizedPremium =  modalPremium / Constants.MONTHLY_MODAL_FACTOR;
      break;

    case 3:
      annualizedPremium =  modalPremium / Constants.QUATERLY_MODAL_FACTOR;
      break;

    case 2:
      annualizedPremium =  modalPremium / Constants.SEMI_ANNUAL_MODAL_FACTOR;
      break;
    case 1:
      annualizedPremium =  modalPremium / Constants.ANNUAL_MODAL_FACTOR;
      break;
      default:
      annualizedPremium =  modalPremium / Constants.ANNUAL_MODAL_FACTOR;
      break;
  }
  return Math.round(annualizedPremium);
};

export default getAnnualizedPremium;
