
// Dummy obejct to prefill all the family members field
const AddInsuredFamilyMemberStructure = {
    name: "",
    label: "",
    placeholder: "",
    fields: [
        {
            name: 'id',
            value: '',
            type: 'hidden'
        },
        {
            name: "name",
            value: "",
        },
        {
            name: "salutation",
            value: "",
        },
        {
            name: "first_name",
            type: "text",
            value:""
        },
        {
            name: "middle_name",
            type: "text",
            value:""
        },
        {
            name: "last_name",
            type: "text",
            value:""
        },
        {
            name: "gender",
            value: "",
        },
        {
            name: "dob",
            value:""
        },
        {
            name: "age",
            value:""
        },
        {
            name: "family_member_type",
            value:""
        },
        {
            name: "isSelected",
            value:false
        },
    ]
}


export default AddInsuredFamilyMemberStructure