
// package imports
import ProductConstants from "constants/ProductConstants";
import moment from "moment";

const initialDefaults = (formRef, data) => {
    let productName = data.productName
    let requestData = data.data.bi_request
    let ppf = requestData.INPUT_MODE?.toString();
    let sopp = requestData.SOURCE_OF_PP?.toString();
    let prn_number = requestData.PRAN_NUMBER?.toString();
    let annuity_benefit = requestData?.AnnuityBenefit?.toString();
    let life_status = requestData.LifeStatus?.toString();
    let annuity_mode = requestData?.AnnuityMode?.toString();
    let purchase_Price_amount = requestData.ModalPremium?.toString();
    let deferment_period = requestData.DefermentPeriod?.toString();
    let annuity_option = requestData.AnnuityOption?.toString();
    let secondary_annuitant_name = requestData.SecondaryAnnuitantName?.toString();
    let secondary_annuitant_date_of_birth = requestData.SecondaryAnnuitantDateofbirth;
    let secondary_annuitant_gender = requestData.SecondaryAnnuitantGender?.toString();
    let premium_amount = requestData.PR_ANNPREM == 0 ? data?.data?.firstYearResponse?.MODAL_PREM : requestData.PR_ANNPREM;
    let income = requestData.investment_target?.toString();

// ! payment-frequency:

if(ppf === '5'){
    formRef.$('payment-frequency').set('value', { id: "5", label: "Single" })
}

// ! source-of-purchase-price:

switch (sopp) {
case '1':
    formRef.$('source-of-purchase-price').set('value', {label:'Open Market', id:'1'})

    formRef.$("pran-number").set("extra", {
        ...formRef.$("pran-number").extra,
        visible: false,
      });
    break;
case '2':
    formRef.$('source-of-purchase-price').set('value', {label:"Proceeds of pension policy of ETLI Co Ltd",id:"2"})
    break;
case '3':
    formRef.$('source-of-purchase-price').set('value', {label:"Proceeds of pension policy of other Insurer", id:"3"})
    break;
case '4':
    formRef.$('source-of-purchase-price').set('value', {label:"Proceeds of NPS (National Pension Scheme )",id:"4"})
    
    formRef.$("pran-number").set("extra", {
        ...formRef.$("pran-number").extra,
        visible: true,
      });
    break;

default:
    break;
}

// ! pran-number
formRef.$('pran-number').set('value', prn_number)

// ! annuity-benefit


if(ProductConstants.FOREVER_PENSION_DEFERRED_ANNUITY ||ProductConstants.FOREVER_PENSION_IMMEDIATE_ANNUITY){
    formRef.$('annuity-benefit').set('disabled', true)
}

switch (annuity_benefit) {
 case '1':
     formRef.$('annuity-benefit').set('value', {label:"Immediate Annuity",id:'1'})
     break;
 case '2':
     formRef.$('annuity-benefit').set('value', {label:"Deferred Annuity", id:'2'})
     break;
 default:
     break;
}

if(annuity_benefit === '1'){
    formRef.$("deferment-period").set("value",{ label: "NA", id: "10" })

    formRef.$("deferment-period").set("extra", {
        ...formRef.$("deferment-period").extra,
        isVisible: false,
      });
}

// ! life-status

switch (life_status) {
 case '3':
     formRef.$('life-status').set('value', {label:"Single Life",id:'3'})
     break;
 case '4':
     formRef.$('life-status').set('value', {label:"Joint Life",id:'4'})
     break;
 default:
     break;
}

// ! annuity-mode

switch (annuity_mode) {
 case '21':
     formRef.$('annuity-mode').set('value', {label:"Yearly",id:'21'})
     break;
 case '22':
     formRef.$('annuity-mode').set('value', {label:"Half-yearly",id:'22'})
     break;
 case '23':
  formRef.$('annuity-mode').set('value', {label:"Quarterly",id:'23'})
  break;
  case '24':
  formRef.$('annuity-mode').set('value', {label:"Monthly",id:'24'})
  break;    
 default:
     break;
}

// ! purchase-Price-amount
formRef.$('purchase-Price-amount').set('value', purchase_Price_amount)

// ! deferment-period


switch (deferment_period) {
 case '10':
     formRef.$('deferment-period').set('value', {label:'NA',id:'10'})
     break;
 case '11':
     formRef.$('deferment-period').set('value', {label:"1",id:'11'})
     break;
 case '12':
  formRef.$('deferment-period').set('value', {label:"2",id:'12'})
  break;
 case '13':
  formRef.$('deferment-period').set('value', {label:"3",id:'13'})
  break;
 case '14':
  formRef.$('deferment-period').set('value', {label:"4",id:'14'})
  break;
 case '15':
  formRef.$('deferment-period').set('value', {label:"5",id:'15'})
  break;
 case '16':
  formRef.$('deferment-period').set('value', {label:"6",id:'16'})
  break;
 case '17':
  formRef.$('deferment-period').set('value', {label:"7",id:'17'})
  break;
 case '18':
 formRef.$('deferment-period').set('value', {label:"8",id:'18'})
 break;
 case '19':
 formRef.$('deferment-period').set('value', {label:"9",id:'19'})
 break;
 case '20':
 formRef.$('deferment-period').set('value', {label:"10",id:'20'})
 break;        
 default:
     break;
}

if(productName === ProductConstants.FOREVER_PENSION_DEFERRED_ANNUITY){
    formRef.$('deferment-period').set('disabled', false)
}

// ! annuity-option
// Single Life
let annuityOptionList= []
if(Number(life_status) === 3) {
    // Immediate Annuity
    if(Number(annuity_benefit) === 1) {
        annuityOptionList = [
            { label: "Life Annuity", id: "25" },
            {
            label: "Life Annuity with Return of Purchase Price on Death",
            id: "26",
            },
            {
            label:
                "Life Annuity with Return of Balance Purchase Price on Death",
            id: "27",
            },
            {
            label:
                "Life Annuity with Return of Purchase Price on Critical Illness (CI) or Accidental Total and Permanent Disability (ATPD) or Death",
            id: "28",
            },
        ]
    } else {
        annuityOptionList = [
            {
            label:
                "Deferred Annuity with Return of Purchase Price on Death (Single Life)",
            id: "31",
            },
            {
            label:
                "Deferred Annuity with Return of Purchase Price on Critical Illness (CI) or Accidental Total and Permanent Disability (ATPD) or Death (Single Life)",
            id: "32",
            }
        ]
    }

} else {
    // Immediate Annuity
    if(Number(annuity_benefit) === 1) {
        annuityOptionList = [
            { label: "Life Annuity (Joint life, Last Survivor)", id: "29" },
            {
            label:
                "Life Annuity with Return of Purchase Price on Death (Joint life, Last Survivor)",
            id: "30",
            },
        ]
    } else {
        annuityOptionList = [
            {
                label:
                  "Deferred Annuity with Return of Purchase Price on Death (Joint Life, Last Survivor)",
                id: "33",
              }
        ]
    }
}

formRef.$("annuity-option").set("extra", {
    ...formRef.$("annuity-option").extra,
    options: [...annuityOptionList],
});

switch (annuity_option) {
 case '25':
     formRef.$('annuity-option').set('value', {label:"Life Annuity",id:"25"})
     break;
 case '26':
     formRef.$('annuity-option').set('value', {label:"Life Annuity with Return of Purchase Price on Death",id:"26"})
     break;
 case '27':
  formRef.$('annuity-option').set('value', {label:"Life Annuity with Return of Balance Purchase Price on Death",id:"27"})
  break;
 case '28':
  formRef.$('annuity-option').set('value', {label:"Life Annuity with Return of Purchase Price on Critical Illness (CI) or Accidental Total and Permanent Disability (ATPD) or Death",id:"28"})
  break;
 case '29':
  formRef.$('annuity-option').set('value', {label:"Life Annuity (Joint life, Last Survivor)",id:"29"})
  break;
 case '30':
  formRef.$('annuity-option').set('value', {label:"Life Annuity with Return of Purchase Price on Death (Joint life, Last Survivor)",id:"30"})
  break;
 case '31':
  formRef.$('annuity-option').set('value', {label:"Deferred Annuity with Return of Purchase Price on Death (Single Life)",id:"31"})
  break;
 case '32':
  formRef.$('annuity-option').set('value', {label:"Deferred Annuity with Return of Purchase Price on Critical Illness (CI) or Accidental Total and Permanent Disability (ATPD) or Death (Single Life)",id:"32"})
  break;   
 case '33':
  formRef.$('annuity-option').set('value', {label:"Deferred Annuity with Return of Purchase Price on Death (Joint Life, Last Survivor)",id:"33"}) 
 default:
     break;
}

// ! secondary-annuitant-name
formRef.$('secondary-annuitant-name').set('value', secondary_annuitant_name)

// ! secondary-annuitant-date-of-birth
formRef.$('secondary-annuitant-date-of-birth').set('value', secondary_annuitant_date_of_birth)

// ! secondary-annuitant-gender
switch (secondary_annuitant_gender) {
 case '8':
     formRef.$('secondary-annuitant-gender').set('value', {label:"Male",id:'8'})
     break;
 case '9':
     formRef.$('secondary-annuitant-gender').set('value', {label:"Female",id:'9'})
     break;
//  case '7':
//   formRef.$('secondary-annuitant-gender').set('value', {label:"Transgender",id:'7'})
//   break;    
 default:
     break;
}

// ! disabling Name of the Secondary Annuitant, DOB of the Secondary Annuitant, Gender of the Secondary Annuitant
if(life_status === '3'){
    //! for diabling the fields
    formRef.$("secondary-annuitant-name").set("disabled",true)
    formRef.$("secondary-annuitant-date-of-birth").set("disabled",true);
    formRef.$("secondary-annuitant-gender").set("disabled",true);

    //! for setting the values of the to null
    formRef.$('secondary-annuitant-name').set('value', '');
    formRef.$("secondary-annuitant-date-of-birth").set("value","");
    formRef.$("secondary-annuitant-gender").set("value","");
}

// ! premium-amount
formRef.$('premium-amt').set('value', premium_amount)

// ! income
formRef.$('income').set('value', income)

}

export default initialDefaults