// project imports
import Utils from "./utils"
import * as keys from "./keys"

const FormObserver = (formRef, props) => {

  formRef.$("payment-frequency").observe({
    key: "value",
    call: ({ field }) => {
      let premium = formRef.$("premium-amt").value
      let frequency = field.value.id
      let data = {
        premium: premium,
        frequency: frequency,
      }
      Utils.updateBi(
        keys.FREQUENCY,
        data,
        props.data.product_id,
        formRef,
        props
      )
    },
  })


  formRef.$("policy-term").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.PR_PT,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      )
    },
  })

  formRef.$("premium-paying-term").observe({
    key: "value",
    call: ({ field }) => {
      let frequency = ""
      if (field.value.id === "1") {
        frequency = "5"
        formRef.$('payment-frequency').set("value", { id: frequency, label: "Single" })
        formRef.$('payment-frequency').set("disabled", true)
      } else {
        let ppf = formRef.$('payment-frequency').value.id
        frequency = "4"
        if (ppf === "1") {
          formRef.$('payment-frequency').set("value", { id: frequency, label: 'Monthly' })
        }
        formRef.$('payment-frequency').set("disabled", false)
      }

      if(field.value.id !== "1"){
        formRef.$('payment-frequency').set("value", Utils.generatePPF(field.value.id)[0])

        formRef.$('payment-frequency').set("extra", {
          ...formRef.$('payment-frequency').extra,
          options: Utils.generatePPF(field.value.id)
      })
      }

      let data = {
        ppt: field.value.id,
        frequency: frequency,
      }
      Utils.updateBi(
        keys.PR_PPT,
        data,
        props.data.product_id,
        formRef,
        props
      )
    },
  })


  formRef.$("occupation").observe({
    key: "value",
    call: ({ field }) => {

      Utils.updateBi(
        keys.ZP_OCCUPATION,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      )
    },
  })

  formRef.$("occupation-of-parent-spouse").observe({
    key: "value",
    call: ({ field }) => {

      Utils.updateBi(
        keys.ZP_SECONDARY_OCCUPATION,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      )
    },
  })

  formRef.$("education").observe({
    key: "value",
    call: ({ field }) => {

      Utils.updateBi(
        keys.ZP_EDUCATION,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      )
    }

  })


}

export default FormObserver