// Package Imports
import React from 'react'
import PropTypes from 'prop-types';
import { Tab, Tabs } from 'react-bootstrap'

// Project Imports
import './tab-component.css'

function TabComponent(props) {
    const { tabList, setSelectedTabIndex, children } = props;

    
    const proposerName = (tabList) => {
        let {proposer_salutation,family_member_name,family_member_type} = tabList
        
        if(!family_member_name) return `${family_member_type}`

        return `${proposer_salutation} ${family_member_name}` 
    
      }



    return (
        <Tabs defaultActiveKey={0} className='tab-container flex-nowrap' onSelect={(tabIndex) => setSelectedTabIndex(tabIndex)}>
            {tabList.map((item, index) => {
                const tabTitle = proposerName(item)
                return (
                    <Tab key={index} eventKey={index} title={<TabTitleComponent title={tabTitle } />}/>
                )
            })}

        </Tabs>
    )
};

const TabTitleComponent = (props) => {
    const { title } = props;
    return (
        <div className='d-flex flex-column align-items-center w-100 tab-titles-container'>
            <span className='font-size-16 tab-title'>{title}</span>
        </div>
    )
}

TabComponent.defaultProps = {
    tabList: [],
};

TabComponent.propTypes = {
    setSelectedTabIndex: PropTypes.func.isRequired
}

export default TabComponent
