import { AddIcon } from "constants/Images"
import React, { Component } from "react"
import { Card, Table, Image } from "react-bootstrap"
import * as images from "constants/Images"
import Utils from 'constants/utils'
import deathCoverTargetAmountLabel from "utils/deathCoverTargetAmountLabel"
import "./death-cover-price-component.css"

class DeathCoverPriceComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const {
      firstAmount,
      firstAmountLabel,
      secondAmountLabel,
      secondAmount,
      thirdAmount,
      showAdditionalText,
      showSecondColAmount,
      secondColBottomText,
      firstColTitleText,
      secondColTitleText,
      cardKey,
      productData
    } = this.props
    return (
      <div>
      
      <section className="d-lg-flex justify-content-between mt-3">
        <div className="d-flex w-100 w-lg-50 border " style={{ height: "41px" }}>
          <div className="bg-primary custom-width-for-tagret-amount-label text-light font-size-12 d-flex align-items-center px-2">
            <Image
              width="21"
              className="mx-1"
              src={images.UmbrellaIcon}
              alt="UmbrellaIcon"
            />
            <span className="font-weight-medium">{firstColTitleText}</span>
          </div>
          <div className="d-flex align-items-center mx-auto font-weight-medium ">
          <div className="d-flex align-items-center">
            <div className="">
              {firstAmount == '-' ? firstAmount : global.toINR(firstAmount)}
            </div>
            <div className="font-weight-light font-size-12 text-center ml-2">
              {deathCoverTargetAmountLabel(productData, firstAmountLabel)}
            </div>
            </div> 
            </div>
        </div>

        <div className="d-flex w-100 w-lg-50 mt-2 mt-lg-0 border" style={{ height: "41px" }}>
          <div className="bg-secondary text-light font-size-12 d-flex align-items-center px-2">
            <Image
              width="17"
              className="mx-1"
              src={images.priceTagIcon}
              alt="back-arrow"
            />
               <span className="font-weight-medium">{secondColTitleText}</span>
          </div>
          <div className="d-flex align-items-center mx-auto font-weight-medium ">
            <div className="d-flex align-items-center">
              <div className="">
              {global.toINR(secondAmount)}
              </div>
              <div className="font-weight-light font-size-12 text-center ml-2">
              {secondAmountLabel}
            </div>
            </div>
            {/*<Image
              width="14"
              className="mx-3"
              src={images.PluseIconBold}
              alt="RupeeSecondaryColorIcon"
            />
             <div>
              {global.toINR(thirdAmount)}
              <div className="font-weight-light font-size-12 text-center mt-0">
                one time
              </div>
            </div> */}
          </div>
        </div>
      </section>
      </div>
    )
  }
}

export default DeathCoverPriceComponent
