// Project Imports
import SPRM_ENUM from "constants/SPRM_constants";
import StorageKeys from "constants/StorageKeys";
import * as API from "constants/API";
import axios from "axios";
import QmsStorageKeys from "constants/QmsStorageKeys";

const savePragatiNAInboundData = (location) => {
    let agent_id;

    const urlParams = new URLSearchParams(location.search);
    agent_id = urlParams.get(SPRM_ENUM.AGENT_ID)

    if (agent_id) {

        localStorage.setItem(StorageKeys.AGENT_ID, agent_id)

        const getPragatiNaInboundData = async () => {
            const response = await axios(API.PRAGATI_INBOUND_DATA + `?agent_id=${agent_id}`);
            console.log("PRAGATI_INBOUND_DATA ::::", response.data);
            localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, true);
            localStorage.setItem(StorageKeys.PRAGATI_HOME_URL, response.data.home_url)
            localStorage.setItem(StorageKeys.PRAGATI_POST_CALL_END_POINT_URL, response.data.post_call_end_point)
            localStorage.setItem(StorageKeys.START_APPLICATION_END_POINT_URL, response.data.start_app_end_point)
            localStorage.setItem(QmsStorageKeys.START_APPLICATION_END_POINT_URL, response.data.start_app_end_point)
            localStorage.setItem(StorageKeys.LOGIN_CODE, response.data.login_code)
            localStorage.setItem(StorageKeys.VENDOR, response.data.vendor_code)
            localStorage.setItem(StorageKeys.SOURCE_FROM, "NA")
            localStorage.setItem(StorageKeys.LOGIN_AGENT_CODE, response.data.login_agent_code)
        };

        getPragatiNaInboundData();
    }

}

export default savePragatiNAInboundData