export const PR_PT = 'PR_PT';
export const PR_PPT = 'PR_PPT';
export const FREQUENCY = 'INPUT_MODE';
export const PLAN_OPTION = 'PlanOption';
export const PR_ANNPREM = 'PR_ANNPREM';
export const INCOME_START_YEAR = 'IncomeStartYear';
export const LIFE_COVER_CONTINUATION_BENEFIT = 'LifeCoverContinuationBenefit';
export const ACCRUAL_OF_SURVIVAL_BENEFIT = 'AccrualOfSurvivalBenefits';
export const PERSONALISED_OPTION = 'PersonalisedOption';
export const WITHDRAWAL = 'PWInterval';
export const WITHDRAWAL_START_YEAR = 'PWStartYear';
export const WITHDRAWAL_STOP_YEAR = 'PWEndYear';
export const AGE_BASED_WITHDRAWAL = 'PWAge';
export const START_AGE = 'PWStartAge';
export const END_AGE = 'PWEndAge';

export const WOP_RIDER = 'WaiverOfPremiumRider';
export const ADB_RIDER = 'ADBRider';
export const ATPD_RIDER = 'ATPDRider';
export const Critical_ILLNESS_RIDER = 'CriticalIllnessRider';
export const INCOME_BENEFIT_RIDER = 'IncomeBenefitRider';
export const PWB_RIDER = 'PayorWaiverBenefitRider';
export const PWB = "PWB";

