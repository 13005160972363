
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {

  formRef.$('policy-term').observe({
    key: 'value',
    call: ({ field }) => {
      let currentPt = field.value
      let list = Utils.getPremiumTermList(currentPt)
      formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        optionList: list
      })
      formRef.$('premium-term').set('value', currentPt)
      Utils.updateBi(keys.PR_PT, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('payment-frequency').observe({
    key: 'value',
    call: ({ field }) => {
      let premium = formRef.$('premium').value
      let frequency = field.value.id
      let data = {
        premium: premium,
        frequency: frequency
      }
      Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)
    }
  })

  formRef.$('premium-term').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.PR_PPT, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('fund-strategy').observe({
    key: 'value',
    call: ({ field }) => {
      if (field.value.id == 1) {
        formRef.$('fund-strategy').set('extra', {
          ...formRef.$('fund-strategy').extra,
          showFundDistributionModal: true
        })
      } else {
        Utils.updateBi(keys.FUNDSTRATEGYID, field.value.id, props.data.product_id, formRef, props)
      }
    }
  })
}

export default FormObserver