import FamilyDetailsStore from "modules/family-details-form/store/FamilyDetailsStore";

function getFamilyMemberNameFromFamilyObject(familyObjectFromApi) {
  const salutionList = FamilyDetailsStore.salutationList;

  try {
    const { salutation: salutation_id, first_name, last_name, middle_name, family_member_type } = familyObjectFromApi;

    let userPropoerName = "";
    if (first_name !== null) {
      
      if (first_name !== null) {
        userPropoerName += first_name + " ";
      }

      if (middle_name !== null) {
        userPropoerName += middle_name + " ";
      }

      if (last_name !== null) {
        userPropoerName += last_name;
      }

      userPropoerName.trim();
    }

    if (userPropoerName === "") {
      return `[${family_member_type}],`;
    }

    if (salutation_id !== null) {
      const memberSalution = salutionList.find((item) => item.id == salutation_id);
      return `${memberSalution.label} ${userPropoerName} ,`;
    }
  } catch (err) {
    return "";
  }
}

export default getFamilyMemberNameFromFamilyObject;
