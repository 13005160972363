// Package Imports
import moment from "moment";

// Project Imports
import axios from "utils/axios";
import * as APITypes from "constants/API"
import AuthStore from "modules/login/store/AuthStore";
import fetchDeselectedExpenses from "utils/fetchDeselectedExpenses";
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import { toJS } from "mobx";
import chooseNeedUtils from '../choose-needs/utils'

const saveRetirementCalculator = async (dataToBeSubmitted,chooseNeedFormController) => {
  dataToBeSubmitted["monthly_income"] = 0
  var response = await axios.post(APITypes.RETIREMENT_CALCULATOR, dataToBeSubmitted);


  const protectionForFamilyTargetAmount = chooseNeedFormController?.get().protection_for_my_family.fields.target_amount_value.value

  let currentLocation = window.location.pathname

  if(protectionForFamilyTargetAmount !== "" && currentLocation.includes("choose-needs")){
    const {dob,expense_list} = dataToBeSubmitted
    
    const requestBodyData = {...toJS(OptimizationStore.protectionCalculatorReqestObject),dob,expense_list}
      let {data: {lumpsum}} = await axios.post(APITypes.PROTECTION_CALCULATOR, requestBodyData);
      chooseNeedUtils.updateTargetAmountValue(chooseNeedFormController, "protection_for_my_family", lumpsum)
      
      chooseNeedFormController.$("protection_for_my_family.is_value_updated").set("value",true)
      chooseNeedFormController.$("save_for_retirement.is_value_updated").set("value",false)
  }

  return response
}

const submitCalculatorValue = (formController, setShowCalculatedValue, setCalculatedData, setErrorClass,chooseNeedFormController) => {

  formController.validate({ showErrors: true })
    .then(async ({ isValid }) => {
      
      let monthly_expense_types = formController.errors().monthly_expense;
       Object.keys(monthly_expense_types).map((ExpenseName, i) => {
        monthly_expense_types[ExpenseName] == null ? setErrorClass("") : setErrorClass("text-danger") 
      })

      if (isValid) {

        const { monthly_expense: monthlyExpense, date_of_birth: dob,  family_member_id: familymemberId } = formController.values();

        let expenseList = [];

        for (const selectedKey in monthlyExpense) {

          const { id, label } = monthlyExpense[selectedKey];

          if (id && label) {
            expenseList.push({
              action: "save",
              id: monthlyExpense[selectedKey].id,
              expense_range_id: monthlyExpense[selectedKey].id,
              expense_range_key: monthlyExpense[selectedKey].label,
              expense_range_value: monthlyExpense[selectedKey].label,
              user_journey: AuthStore.journeyId,
            });
          }
        }

        let data = {
          "monthly_income": "0",
          "dob": moment(dob).format("YYYY-MM-DD"),
          "current_life_cover": "1",
          "expense_list": [...expenseList],
          "journey_id": AuthStore.journeyId,
          "family_member_id": familymemberId
        }

        let deSelectedExpenseList = fetchDeselectedExpenses(formController, 'monthly_expense')
        let deselectExpensesData = deSelectedExpenseList.map(item => item.journeyExpenseId)

        await axios.post(
          APITypes.DESELECT_EXPENSE,
          {
            "family_member_id": familymemberId,
            "id": deselectExpensesData
          }
        )

        const response = saveRetirementCalculator(data,chooseNeedFormController);

        response.then((responseData) => {
          if (responseData.status == 'error') {
            return
          } else {
            AuthStore.fetchJourneyData()
            setCalculatedData(responseData.data)
            setShowCalculatedValue(true);
          }
        })
      }
    });
}

export { submitCalculatorValue };