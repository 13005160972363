import Utils from './utils';
import ConstUtils from '../../../../../constants/utils';

const initialDefaults = (formRef, data) => {
    let requestData = data.data.bi_request
    let dob = requestData.LI_DOB
    let frequency = requestData.INPUT_MODE;
    let payoutMode = requestData.PayoutOptions;
    let pt = requestData.PR_PT;
    let ppt = requestData.PR_PPT;

    if (payoutMode == '1') {
        formRef.$('payout-option').value = {
            id: "1",
            label: "Annually",
        }
    } else {
        formRef.$('payout-option').value = {
            id: "2",
            label: "Lumpsum",
        }
    }

    switch (frequency) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '2':
            formRef.$('payment-frequency').set('value', {
                id: '2',
                label: 'Semi-Annual'
            })
            break;
        case '3':
            formRef.$('payment-frequency').set('value', {
                id: '3',
                label: 'Quarterly'
            })
            break;
        case '4':
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }

    formRef.$('policy-term').set('value', {
        id: pt,
        label: pt
    })

    formRef.$('premium-term').set('value', {
        id: ppt,
        label: ppt
    })

    Utils.pptValueBasedOnPt(pt,formRef)

    let premiumAmount = requestData.PR_ANNPREM == 0 ? data?.data?.firstYearResponse?.MODAL_PREM : requestData.PR_ANNPREM
    // switch (frequency) {
    //     case '1':
    //         premiumAmount = requestData.PR_ANNPREM;
    //         break;
    //     case '2':
    //         premiumAmount = Number(requestData.PR_ANNPREM) / 2;
    //         break;
    //     case '3':
    //         premiumAmount = Number(requestData.PR_ANNPREM) / 4;
    //         break;
    //     case '4':
    //         premiumAmount = Number(requestData.PR_ANNPREM) / 12;
    //         break;

    //     default:
    //         break;
    // }

    formRef.$('premium').set('value', premiumAmount)

    if (requestData.ADBRider) {
        formRef.$('ADB').set('value', requestData.ADBRider !== '')
        if (requestData.ADBRider) {
            formRef.$('ADB-dropdown').set('value', requestData.ADBRider)
        }
    }
    if (requestData.ATPDRider) {
        formRef.$('ATPDRider').set('value', requestData.ATPDRider !== '')
        if (requestData.ATPDRider) {
            formRef.$('ATPDRider-dropdown').set('value', requestData.ATPDRider)
        }
    }
    if (requestData.WaiverOfPremiumRider) {
        formRef.$('WaiverOfPremiumRider').set('value', requestData.WaiverOfPremiumRider !== '')
    }

    if (requestData.IncomeBenefitRider) {
        formRef.$('IncomeBenefitRider').set('value', requestData.IncomeBenefitRider !== '')
        if (requestData.IncomeBenefitRider) {
            formRef.$('IncomeBenefitRider-dropdown').set('value', requestData.IncomeBenefitRider)
        }
    }
    if (requestData.CriticalIllnessRider) {
        formRef.$('CriticalIllnessRider').set('value', requestData.CriticalIllnessRider !== '')
        if (requestData.CriticalIllnessRider) {
            formRef.$('CriticalIllnessRider-dropdown').set('value', requestData.CriticalIllnessRider)
        }
    }
    if (requestData.PayorWaiverBenefitRider) {
        formRef.$('PayorWaiverBenefitRider').set('value', requestData.PayorWaiverBenefitRider !== '')
        if (formRef.$('PayorWaiverBenefitRider').value) {
            switch (requestData.PWB) {
                case '1':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '1',
                        label: 'Option 1 (On Death)'
                    })
                    break;
                case '2':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '2',
                        label: 'Option 2 (CI or ATPD)'
                    })
                    break;
                case '3':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '3',
                        label: 'Option 3 (CI or ATPD or Death)'
                    })
                    break;
                default:
                    break;
            }
        }
    }

}

export default initialDefaults