// Package Imports
import { toJS } from "mobx"
import {validationOtherFieldMembers} from '../../src/modules/family-details-form/form/utils'


const addFamilyMember = async (
 formController,
 fetchSelectedMembers,
 submitDataToTheServer,
 AuthStore,
 reInitializeFamilyMemberData,
 hideModal) => {
  let { isValid } = await formController.validate({ showErrors: true })

        const ifValid = validationOtherFieldMembers(formController)

        if (ifValid) {
        let { members } = formController.values()

        const selectedMemberList = fetchSelectedMembers(members, formController)

        const response = await submitDataToTheServer(formController, selectedMemberList)
        await AuthStore.fetchJourneyData()
        if(hideModal !== undefined){
            hideModal()
        }
        const journeyData = toJS(AuthStore.journeyData)
        console.log("Response data is ", response.data)
        reInitializeFamilyMemberData(journeyData, formController)

    } else {
        console.log("Error are ", formController.errors())
    }
}

export default addFamilyMember
