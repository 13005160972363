import React, { Component } from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import { CustomCheckbox, BottomsheetDropdown } from '../../../../../../../components';
import { InfoArrowBlackImg } from "constants/Images";

import './additional-cards.css';
import { observer } from 'mobx-react';

class AdditionalCards extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { form, data } = this.props
    return (
      <div className='additional-cards'>
        <p className='mb-2 pb-1 font-size-14 font-weight-medium benefits-section-title'>Additional Benefits</p>
        <Card>
          <Card.Body>
            <Row
              
            >
              <Col className="col-9">
                <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                  <CustomCheckbox
                    shape="rounded"
                    title={form.$("family-income-benefit-option").label}
                    titleStyle=' font-weight-medium font-size-14 pl-2 text-primary'
                    isChecked={form.$("family-income-benefit-option").value}
                    onChange={() =>
                      form
                        .$("family-income-benefit-option")
                        .extra.changeBenefitStatus(form, data)
                    }
                    mark="checkmark"
                    disabled = {form.$("family-income-benefit-option").disabled}
                  />
                  <img
                    className={`pl-2 ${form.$("family-income-benefit-option").disabled
                      ? "inf0-icon-disable"
                      : ""
                      }`}
                    src={InfoArrowBlackImg}
                    alt="info"
                    onClick={() => showDescriptionModal(form.$("family-income-benefit-option").label, form.$("family-income-benefit-option").extra.description)}
                  />
                </div>
                {form.$("family-income-benefit-option").value ?
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light mt-2'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey="label"
                    bottomsheetTitle=""
                    menuList={
                      form.$("family-income-benefit-option-dropdown").extra
                        .optionList
                    }
                    value={form.$("family-income-benefit-option-dropdown").value}
                    onSelect={
                      form.$("family-income-benefit-option-dropdown").onChange
                    }
                  />
                  : null
                }
              </Col>
              <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                {form.$("family-income-benefit-option").value ? (
                  <React.Fragment>
                    <div className="d-flex flex-column align-items-center">
                      <p className="mb-0 font-weight-300 font-size-20 rider-amount">
                        {/* {global.toINR(fetchRiderPremiumValue(data, form.$("family-income-benefit-option").extra.rider_name))} */}
                      </p>
                    </div>
                    <p className="mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color"></p>
                  </React.Fragment>
                ) : (
                  <p className="mb-0 font-weight-300 font-size-14 text-center description-text-color">
                    Not <br /> Selected
                  </p>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default observer(AdditionalCards)
