// react imports
import React, { Component } from "react"
import { toJS } from "mobx";

// package Imports
import { Tabs, Tab, Row, Col } from "react-bootstrap"
import PropTypes from "prop-types"
import { observer } from "mobx-react"

// project imports
import { DeathCoverPriceComponent } from "../../../../components"
import OptimizationStore from "../../store/OptimizationStore"
import AllProducts from "./all-products"
import AddLifeCover from "../addLifeCover/AddLifeCover"
import TitleComponent from "./TitleComponent"
import MaturityPayOutModal from "components/ui/maturity-pay-out-modal/MaturityPayOutModeModal"
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import updateDeathCoverGraphSliderTargetAmount from "utils/forward-reverse-methods/updateDeathCoverGrapSliderTargetAmount";
import fetchTargetAmountLabel from "utils/fetchTargetAmountLabel"
import fetchPremiumAmountLabel from "utils/fetchPremiumAmountLabel"
import fetchPremiumAmount from "utils/fetchPremiumAmount";
const ProductTabComponent = observer(
  class ProductTabComponent extends Component {
    constructor(props) {
      super(props)
      this.state = {
        responseData: {},
        amountData: {},
        targetAmount: 0,
        frequency: "",
      }
    }

    componentDidMount() {
      const { data } = this.props
      const BI = data.BI
      const key = data.selectedRisk ? data.selectedRisk : "low"
      OptimizationStore.onChangeProductTab(key, data.id, this.props.cardKey)
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevState.key != this.state.key) {
        const { cardKey, data } = this.props
        let needName = cardKey
        let id = data.id
        let tab = this.state.key
        OptimizationStore.updateTabChangeData(needName, id, tab)
      }
    }

    onValueUpdate = (data, key) => {
      let amountData = this.state.amountData
      amountData[key] = data

      this.setState({ targetAmount: data.targetAmount, amountData })
    }

    onPriceChange = (price, key) => {
      let existingPrice = this.state.price
      existingPrice[key] = price
      this.setState({
        price: existingPrice,
      })
    }

    getTitle = (key) => {
      let title = ""
      switch (key) {
        case "low":
          title = "Guaranteed"
          break
        case "medium":
          title = "Participatory"
          break
        case "high":
          title = "Unit Linked"
          break
      }
      return title
    }

    onChangeProductTab = (risk, familyMemberId, cardKey,familyProductId) => {
      OptimizationStore.onChangeProductTab(risk, familyMemberId, cardKey,familyProductId)
    }

    render() {
      const { amountData } = this.state
      const { data, cardKey } = this.props
      const BI = data.BI
      const key = data.selectedRisk
      const selectedData = BI.find((item) => item.risk === key);
      const familyProductId = BI[0].family_product_id 
      let selectedRiskTargetAmount = 0
      selectedRiskTargetAmount = updateDeathCoverGraphSliderTargetAmount(data)
      return (
        <div className="investment-strategy">
          <Row>
            <Col md={9} className="col-12 mt-3 mt-md-0">
              <DeathCoverPriceComponent
                firstAmount={selectedRiskTargetAmount.targetValue}
                firstAmountLabel = {fetchTargetAmountLabel(data)}
                secondAmountLabel = {fetchPremiumAmountLabel(data)}
                secondAmount={fetchPremiumAmount(selectedData, data)}
                thirdAmount=""
                showAdditionalText={false}
                showSecondColAmount={false}
                secondColBottomText={
                  (amountData[key] && amountData[key].frequency.label) ||
                  ""
                }
                firstColTitleText={fetchIncomeLabelBasedOnNeed(this.props.cardKey, '', data)}
                secondColTitleText="Price"
                cardKey={this.props.cardKey}
                productData = {selectedData}
              />
            </Col>
          </Row>
          {cardKey !== "save_for_retirement" && (
            <>
              <p className="font-size-12 font-weight-light mb-0 mt-3">Maturity Payout Mode</p>
              <Row>
                <Col md={5} className="col-12 mt-3 mt-md-0">
                  <MaturityPayOutModal cardkey={this.props.cardKey}
                    familyMemberId={data.id}
                    data={data}
                    showModalBackDrop={this.props.showModalBackDrop}
                    showBackDropFunction={this.props.showBackDropFunction}
                    hideBackDropFunction={this.props.hideBackDropFunction}
                  />
                </Col>
              </Row>
            </>
          )}

          <p className="font-size-12 mt-3 mb-0 ml-2">Investment Strategy <span className="investment-strategy-heading">[PLEASE SELECT ONE]</span></p>
          <div className="border rounded mt-3">
            <Tabs
              activeKey={data.selectedRisk}
              onSelect={(key) =>
                this.onChangeProductTab(key, data.id, this.props.cardKey,familyProductId)
              }
              className="nav-fill flex-column flex-lg-row"
            >
              {BI.reverse().map((plan, index) => {
                let selectedProductData = BI.find((item)=> item.risk === data.selectedRisk)
                console.log("BI.reverse() index :: ",index)
                console.log("BI.reverse() plan :: ",plan)
                console.log("BI.reverse() selected key :: ",key)
                if (plan.product_name === "") {
                  return
                }
                
                return (
                  <Tab
                    key={index}
                    className="px-2"
                    eventKey={plan.risk}
                    disabled={Object.keys(plan.BI).length == 0}
                    title={
                      <TitleComponent
                        data={{ ...data, ...plan }}
                        isSelected={key == plan.risk}
                        risk={plan.risk}
                        title={this.getTitle(plan.risk)}
                        amount={plan.totalPrice}
                        onTabSelected={this.props.onTabSelected}
                      />
                    }
                  >
                    <AllProducts
                      tab={plan.risk}
                      selectedTab={key}
                      {...this.props}
                      data={{ ...plan, ...data }}
                      productName={plan.product_name}
                      onValueUpdate={(value) => {
                        console.log("Value updating ?? ", value)
                        this.onValueUpdate(value, plan.risk)
                      }
                      }
                    />
                  </Tab>
                )
              })}
            </Tabs>
          </div>
        </div>
      )
    }
  }
)

ProductTabComponent.defaultProps = {
  disabled: false,
}

ProductTabComponent.propTypes = {
  addInsured: PropTypes.func,
  data: PropTypes.object,
  disabled: PropTypes.bool,
}

export default ProductTabComponent
