
// keys for fields
export const INCOME = 'investment_target';
export const PREMIUM = 'PR_ANNPREM';
export const FREQUENCY = 'INPUT_MODE';
export const SOURCE_OF_PURCHASE_PRICE = 'SOURCE_OF_PP';
export const PRAN_NUMBER = 'PRAN_NUMBER';
export const ANNUITY_BENEFIT = 'AnnuityBenefit';
export const LIFE_STATUS = 'LifeStatus';
export const ANNUITY_MODE = 'AnnuityMode';
export const PURCHASE_PRICE = 'ModalPremium';
export const DEFERMENT_PERIOD = 'DefermentPeriod';
export const ANNUITY_OPTION = 'AnnuityOption';
export const NAME_OF_THE_SECONDARY_ANNUITANT = 'SecondaryAnnuitantName';
export const DOB_NAME_OF_THE_SECONDARY_ANNUITANT = 'SecondaryAnnuitantDateofbirth';
export const AGE_OF_THE_SECONDARY_ANNUITANT = 'SecondaryAnnuitantAge'
export const  GENDER_NAME_OF_THE_SECONDARY_ANNUITANT = 'SecondaryAnnuitantGender';

