import React from 'react';
import PropTypes from 'prop-types';
import './amount-calculation.css';

const AmountCalculation = (props) => {
    return (
        <div className='mt-4 mt-md-5 payment-layout'>
            {!props.hideTotalPremium &&
                <React.Fragment>
                    <AmountCalculationRowItem title='Total Premium' amount={props.total_without_gst || props.totalPremium} titleStyle='font-size-14 font-size-md-16' amountStyle='font-size-14 font-size-md-16' {...props} />
                    <AmountCalculationRowItem title={`GST@${props.gst_percentage}%`} amount={props.gst} titleStyle='font-size-14 font-size-md-16' amountStyle='font-size-14 font-size-md-16' {...props} />
                </React.Fragment>
            }
            {(props.riderList && props.riderList.length > 0) && props.riderList.map((value, index) => (
                <AmountCalculationRowItem key={index} title={value.ProductNameWithOption} amount={Math.round(value.ModalPremium)} titleStyle='font-size-14 font-size-md-16' amountStyle='font-size-14 font-size-md-16 font-weight-bold' {...props} />
            ))}
            {(props.riderTax !== undefined && props.riderTax !== null && props.riderTax !== 0) &&
                <AmountCalculationRowItem title='Rider Tax' amount={props.riderTax} titleStyle='font-size-14 font-size-md-16' amountStyle='font-size-14 font-size-md-16' {...props} />
            }
            <hr className='line my-3' />
            <AmountCalculationRowItem premiumFreq={props.premiumFreq} title='Total Price' amount={props.total_price} titleStyle='font-size-14 font-size-md-16' amountStyle='font-size-14 font-size-md-16' {...props} />
            <hr className='line mt-3' />
        </div>
    )
}

const AmountCalculationRowItem = (props) => {
    const { title, amount, titleStyle, amountStyle, premiumFreq, item } = props;
    return (
        <div className='d-flex justify-content-between align-items-center'>
            <span className={`text-primary font-weight-medium title-row ${titleStyle}`}>{title} {premiumFreq && <span className='text-gray-opacity-49 font-weight-normal'>( {premiumFreq} )</span>}</span>
            <span className={`text-secondary font-weight-bold amount-row ${amountStyle}`}> {item.isValidationExist ? 0 : global.toINR(amount)} </span>
        </div>
    )
}

AmountCalculation.defaultProps = {
    totalPremium: '0',
    gst: '0',
    gst_percentage: '',
    total_price: '0',
    riderList: [],
    hideTotalPremium: false,
}

AmountCalculation.propTypes = {
    totalPremium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gst: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gst_percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default AmountCalculation;