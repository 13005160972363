// Project Imports
import FamilyMemberTypeEnum from "../FamilymemberTypeEnum"

const familyMemberList = [
    {
        "name": "self",
        "defaultSelected": true,
        "label": "Self",
        "memberType": FamilyMemberTypeEnum.SELF
    },
    {
        "name": "spouse",
        "defaultSelected": false,
        "label": "Spouse",
        "memberType": FamilyMemberTypeEnum.SPOUSE,
    },
    {
        "name": "child",
        "defaultSelected": false,
        "label": "Child",
        "memberType": FamilyMemberTypeEnum.CHILD
    },
    {
        "name": "grand_mother",
        "defaultSelected": false,
        "label": "Grandmother",
        "memberType": FamilyMemberTypeEnum.GRAND_MOTHER,
        "gender": {
            "isDisable": true,
            "defaultValue": "Female"
        }
    },
    {
        "name": "grand_father",
        "defaultSelected": false,
        "label": "Grandfather",
        "memberType": FamilyMemberTypeEnum.GRAND_FATHER,
        "gender": {
            "isDisable": true,
            "defaultValue": "Male"
        }
    },
    {
        "name": "adult_child",
        "defaultSelected": false,
        "label": " Adult Child",
        "memberType": FamilyMemberTypeEnum.ADULT_CHILD
    },
    {
        "name": "grand_child",
        "defaultSelected": false,
        "label": "Grandchild",
        "memberType": FamilyMemberTypeEnum.GRAND_CHILD
    },
]

export default familyMemberList