const calculateProductPremiumRelatedValues = (paymentFrequency, totalPremium) => {
  let frequencyLabel = "";
  let multiplier = "";

  switch (paymentFrequency) {
    case "1":
      frequencyLabel = "per annum";
      break;

    case "2":
      frequencyLabel = "per half year";
      break;

    case "3":
      frequencyLabel = "per quarter";
      break;

    case "4":
      multiplier = "2";
      totalPremium = totalPremium * 2;
      frequencyLabel = "per month";
      break;
    default:
      frequencyLabel = "";
      break;
  }

  return { frequencyLabel, multiplier, totalPremium };
};

export default calculateProductPremiumRelatedValues;
