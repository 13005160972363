// Package Imports
import { toJS } from "mobx"

// Project Imports
import isObjectEmpty from "utils/isObjectEmpty"
import fetchFamilyMasterData from "utils/fetchFamilyMasterData"
import fetchNeedMasterData from "utils/fetchNeedMasterData"
import NeedTypeEnum from "constants/NeedTypeEnum"
import OptimizationStore from "modules/optimization/store/OptimizationStore"
import initializeEducationOccupationFieldsForPopUpModal from "utils/initializeOccupationEducationFieldsForPopUpModal"
import preFillOccupationEducationLocationFields from "utils/preFillOccupationEducationLocationFieldsPopUpModal"
import AuthStore from "modules/login/store/AuthStore"
import fetchNeedMaster from "utils/fetchNeedMasterData"
import INCOME_TYPE_ENUM from 'constants/incomeTypeEnum'

const initialDefaults = async (formController, journeyData, journeyId) => {
  let NeedMasterData = await fetchNeedMasterData()

  let protectionForMyFamily = NeedMasterData.find(
    (item) => item.unique_name === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY
  )

  // ! for setting the target_amount_type_dropdown default option

  let protectionForMyFamilyTargetAmountTypeOptionList = protectionForMyFamily.mode_master.filter((singleOption) => !singleOption.mode.startsWith("RECOMMENDED"))

  formController.$("target_amount_type_dropdown").set("extra", {
    ...formController.$("target_amount_dropdown").extra,
    optionList: protectionForMyFamilyTargetAmountTypeOptionList,
  })

  formController.$("target_amount_type_dropdown").value =
    protectionForMyFamilyTargetAmountTypeOptionList[1];

  let productData = journeyData.family_product_details_list.filter((item) => item.need === protectionForMyFamily.id)[0]

  // ! for lumpsum
  let lumpsumList = protectionForMyFamily.target_amount.filter((singleAmountOption) => singleAmountOption.mode === INCOME_TYPE_ENUM.LUMPSUM)

  formController.$("target_amount_dropdown").set("extra", {
    ...formController.$("target_amount_dropdown").extra,
    optionList: lumpsumList,
  })


  // !Initialise form values
  let masterData = await fetchFamilyMasterData()
  initializeEducationOccupationFieldsForPopUpModal(
    formController,
    masterData,
    journeyData
  )

  if (journeyId !== undefined) {
    formController.$("journey_id").value = journeyId.toString()
  }

  if (isObjectEmpty(journeyData)) {
    return
  }

  let selectedFamilyMember = journeyData.family_details_list.filter((item) => item.id === OptimizationStore.selectedFamilyMemberId)[0]
  let selfData = journeyData.family_details_list[0]

  let targetAmountOfSelectedFamilyMember = selectedFamilyMember?.family_product_details_list.find((item) => item.need_unique_name === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY)
  let targetAmountOfSelf = selfData.family_product_details_list.find((item) => item.need_unique_name === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY)

  let selectedTargetAmount = targetAmountOfSelectedFamilyMember?.target_amount || targetAmountOfSelf?.target_amount

  if (selectedFamilyMember?.occupation && selectedFamilyMember?.education && selectedFamilyMember?.office_name) {
    setMemberData(selectedFamilyMember, formController, protectionForMyFamily, masterData, selectedTargetAmount)
  } else {
    let selfDetails = journeyData.family_details_list[0]
    setMemberData(selfDetails, formController, protectionForMyFamily, masterData, selectedTargetAmount)
  }

  // ! for Prefilling recommend, setAmount DropDown

  const { family_product_details_list: totalFamilyProductsList } = toJS(AuthStore.journeyData)

  const targetAmount = totalFamilyProductsList.find(
    (singleProduct) => {
      const { family: FamilyMemberId, is_selected: isSelected, need_unique_name: NeedUniqueName, need: needId } = singleProduct

      if (
        FamilyMemberId === OptimizationStore.selectedFamilyMemberId &&
        NeedUniqueName === OptimizationStore.selectedNeed &&
        isSelected === true
      ) {
        return singleProduct.target_amount_value
      }
    }
  )


  if (targetAmount !== undefined) {
    const NeedMasterData = await fetchNeedMaster();

    let filteredSelectedNeed = NeedMasterData.find((singleNeed) => singleNeed.unique_name === OptimizationStore.selectedNeed);
    filteredSelectedNeed = filteredSelectedNeed.target_amount.find((item) => item.mid_value === targetAmount);

    if (filteredSelectedNeed !== undefined) {
      formController.$("target_amount_dropdown").value = filteredSelectedNeed
    }

  }

}

const setMemberData = async (selectedFamilyMember, formController, protectionForMyFamily, masterData, selectedTargetAmount) => {
  let occupation = masterData.occupationResponse.data.filter((item) => item.id === selectedFamilyMember.occupation)[0]
  if (occupation) formController.$("occupation").set("value", occupation)

  let education = masterData.educationResponse.data.filter((item) => item.id === selectedFamilyMember.education)[0]
  if (education) formController.$("education").set("value", education)
  formController.$("location").set("value",
    [
      {
        id: selectedFamilyMember.pin_code,
        label: selectedFamilyMember.office_name,
        location: selectedFamilyMember.state,
      },
    ]
  )

  let targetAmount = protectionForMyFamily.target_amount.filter((item) => item.id === selectedTargetAmount)[0]
  if (targetAmount) formController.$("target_amount_dropdown").set("value", targetAmount)
  
}
export default initialDefaults
