import AuthStore from '../../../../login/store/AuthStore';
import errorMessage from './errorMessage';
import OptimizationStore from '../../../store/OptimizationStore';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    // true or false
    errorMessage = [];

    // Minimum Premium Validation
    minimalAnnualPremium = (form) => {
        let frequency = form.$('payment-frequency').value.id.toString()
        let premium = form.$('premium-amt').value

        switch (frequency) {
            case '1':
                if (premium < 1206) {
                    this.errorMessage.push(errorMessage.MINIMUM_ANNUAL_PREMIUM)
                }
                break;

            case '2':
                if (premium < 617) {
                    this.errorMessage.push(errorMessage.MINIMUM_HALF_YEARLY_PREMIUM)
                }
                break

            case '4':
                if (premium < 106) {
                    this.errorMessage.push(errorMessage.MINIMUM_MONTHLY_PREMIUM)
                }
                break;
        }
    }
    // Validate PT PPT
    validatePtPPt = (form) => {
        let ppt = form.$('premium-paying-term').value.id
        let ppf = form.$('payment-frequency').value.id
        if (ppf !== "5") {
            return
        }
        if (ppf === "5" && ppt !== "1") {
            this.errorMessage.push(errorMessage.PPT_VALIDATION)
        }
    };
}
export default new Validator();