// React Imports 
import React, { Component } from "react";

// Package Imports
import { Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import AuthStore from "modules/login/store/AuthStore";
import * as images from "constants/Images";
import { Button as UiButton } from "components";
// Project Imports

// CSS Imports
import "./close-modal.css";

const CloseModal = observer(
  class CloseModal extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isOpen: false,
      };
    }

    showCloseModal = () => {
      this.setState({ isOpen: true });
    };

    hideCloseModal = () => {
      this.setState({ isOpen: false });
    };


    submitData = () => {
      console.log('submitted');
    }


    render() {

      return (
        <div className='closeModalDiv d-flex align-items-center'>
          <UiButton onClick={this.showCloseModal} variant='' className='p-0 d-md-flex flex-row align-items-center'>
                    <Image width='12' height='12' src={images.CloseIconWhite} alt='close-icon' />
                    <span className='font-size-11 mt-1 mx-2'>Close</span>
                  </UiButton>
          <Modal
            show={this.state.isOpen}
            backdrop="static"
            onHide={this.hideCloseModal} 
            as='section'
            className='closeModal'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            keyboard={false}
          >
            <ModalBody className='closeModalBody'>
              <section>
                <div className='row closeModalContent'>
                   <div className='mr-lg-2 py-4 my-3 text-center w-100 d-flex flex-column justify-content-between'>
                     <h1 className='heading text-center'>
                       NEED ANALYSIS <span>BI</span>
                     </h1>
                     <div className="btn-container w-100">
                     <button className="modal-cta-btn-link border-0" onClick={this.hideCloseModal}>Cancel</button>
                     </div>
                   </div>
                </div>
              </section>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
);

export default CloseModal;
