import utils from "./utils";
import { CovidVaccinationStatusList } from 'constants/CovidVaccinationStatusList';

const initialDefaults = (formRef, data) => {
  let requestData = data.data.bi_request
  let ppf = requestData.INPUT_MODE?.toString()
  let pr_pt = requestData.PR_PT?.toString();
  let pr_ppt = requestData.PR_PPT?.toString();
  let zp_occuption = requestData.ZP_OCCUPATION?.toString()
  let zp_secondary_occupation = requestData.ZP_SECONDARY_OCCUPATION?.toString()
  let annual_income_of_proposer = requestData.ZP_ANNINCOME?.toString()
  let zp_education = requestData.ZP_EDUCATION?.toString()
  let sum_assured = requestData.PR_SA?.toString();
  let premium_amount = data.data.firstYearResponse.MODAL_PREM
  let income = requestData.PR_SA

  let covidVaccinationStatus = CovidVaccinationStatusList.find((item) => item.id === data.data.covid_vaccination_status)
  formRef.$('covid-vaccination-status').set('value', covidVaccinationStatus)

  // ! payment-frequency:
  switch (ppf) {
    case "1":
      formRef.$("payment-frequency").set("value", {
        id: "1",
        label: "Annual",
      })
      break

    case "2":
      formRef.$("payment-frequency").set("value", {
        id: "2",
        label: "Semi-Annual",
      })
      break

    case "4":
      formRef.$("payment-frequency").set("value", {
        id: "4",
        label: "Monthly",
      })
      break

    case "5":
      formRef.$("payment-frequency").set("value", {
        id: "5",
        label: "Single",
      })
      break

    default:
      break
  }


  // ! PR_PT
  if (pr_pt) {
    formRef.$("policy-term").set("value", { label: pr_pt, id: pr_pt })
  }

  // ! PR_PPT
  if (pr_ppt) {
    formRef.$("premium-paying-term").set("value", { label: pr_ppt, id: pr_ppt })
  }


  if (pr_ppt === '1') {
    formRef.$('payment-frequency').set('disabled', true)
    formRef.$('payment-frequency').set('value', {
      id: '5',
      label: 'Single'
    })
  } else {
    formRef.$('payment-frequency').set('disabled', false)
    formRef.$('payment-frequency').set("extra", {
      ...formRef.$('payment-frequency').extra,
      options: utils.generatePPF(requestData.PR_PPT)
    })
  }

  // ! occupation
  switch (zp_occuption) {
    case "SA":
      formRef.$("occupation").set("value", { label: "Salaried", id: "SA" })
      break

    case "SM":
      formRef
        .$("occupation")
        .set("value", { label: "Self Employed (Professional)", id: "SM" })
      break

    case "BM":
      formRef
        .$("occupation")
        .set("value", { label: "Self Employed (Business)", id: "BM" })
      break

    case "A":
      formRef.$("occupation").set("value", { label: "Agriculture", id: "A" })
      break

    case "H":
      formRef.$("occupation").set("value", { label: "Housewife", id: "H" })
      break

    case "R":
      formRef.$("occupation").set("value", { label: "Retired", id: "R" })
      break

    case "ST":
      formRef.$("occupation").set("value", { label: "Student", id: "ST" })
      break

    case "LWO":
      formRef
        .$("occupation")
        .set("value", { label: "Labourer/Worker/Others", id: "LWO" })
      break

    default:
      break
  }

  // ! secondary-occupation
  switch (zp_secondary_occupation) {
    case "SA":
      formRef
        .$("occupation-of-parent-spouse")
        .set("value", { label: "Salaried", id: "SA" })
      break

    case "SM":
      formRef
        .$("occupation-of-parent-spouse")
        .set("value", { label: "Self Employed (Professional)", id: "SM" })
      break

    case "BM":
      formRef
        .$("occupation-of-parent-spouse")
        .set("value", { label: "Self Employed (Business)", id: "BM" })
      break

    case "A":
      formRef
        .$("occupation-of-parent-spouse")
        .set("value", { label: "Agriculture", id: "A" })
      break

    case "H":
      formRef
        .$("occupation-of-parent-spouse")
        .set("value", { label: "Housewife", id: "H" })
      break

    case "R":
      formRef
        .$("occupation-of-parent-spouse")
        .set("value", { label: "Retired", id: "R" })
      break

    case "ST":
      formRef
        .$("occupation-of-parent-spouse")
        .set("value", { label: "Student", id: "ST" })
      break

    case "LWO":
      formRef
        .$("occupation-of-parent-spouse")
        .set("value", { label: "Labourer/Worker/Others", id: "LWO" })
      break

    default:
      break
  }

  // ! annual income of proposer/parent/spouse
  formRef.$("annual-income-of-proposer/parent/spouse").set("value", annual_income_of_proposer)

  //! education
  switch (zp_education) {
    case "1":
      formRef.$("education").set("value", { label: "Below 10th", id: "1" })
      break

    case "2":
      formRef.$("education").set("value", { label: "10th Passed", id: "2" })
      break

    case "3":
      formRef.$("education").set("value", { label: "12th Passed", id: "3" })
      break

    case "4":
      formRef.$("education").set("value", { label: "Graduate", id: "4" })
      break

    case "5":
      formRef.$("education").set("value", { label: "Post Graduate", id: "5" })
      break

    case "6":
      formRef.$("education").set("value", { label: "Professional", id: "6" })
      break

    default:
      break
  }

  // ! sum-assured
  formRef.$("sum-assured").set("value", sum_assured)

  // ! premium-amount
  formRef.$("premium-amt").set("value", premium_amount)

  // ! income
  formRef.$("income").set("value", income)
}

export default initialDefaults
