import React, { useEffect, useState } from "react";

import { Row, Col } from "react-bootstrap";

import { observer } from "mobx-react";

import { BottomsheetDropdown, DeathCoverPriceComponent, ChangeProductSection } from "components";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import OptimizationStore from "modules/optimization/store/OptimizationStore";

const CriticarePlus = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false);

  let productData = data.data;
  let biResponse = productData.parsedResponse;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey);

  useEffect(() => {
    setShowOverlay(productData.fieldChanged);
  }, [productData.fieldChanged]);

  const onHideOverlay = () => {
    setShowOverlay(false);
  };

  function onChangeProductClick(obj) {
    OptimizationStore.isReasonModalOpen = true;
  }
  return (
    <form>
      <div className='family-collapase-view'>
        <Row className='mt-3'>
          <Col md={7} className='col-12'>
            <DeathCoverPriceComponent
              firstAmount={productData.firstYearResponse?.CI_SA ? productData.firstYearResponse?.CI_SA : productData.bi_request.PR_SA}
              //secondAmount={data.data.isValidationExist ? 0 : data.data.parsedResponse.MODAL_PREM_TAX}
              // secondAmount={biResponse.MODAL_PREM}
              secondAmount={biResponse?.MODAL_PREM ? Math.round(biResponse?.MODAL_PREM) : productData?.bi_request ? Math.round(productData?.bi_request?.PR_ANNPREM) : Math.round(data.selectedPrice)}
              thirdAmount='980'
              showAdditionalText={false}
              showSecondColAmount={false}
              secondColBottomText={form.$("payment-frequency").value.label}
              firstColTitleText={coverLabelBaseOnNeed}
              secondColTitleText='Price'
              cardKey={data.cardKey}
            />
          </Col>
        </Row>

        <div className='text-primary-title-label-color font-weight-bold font-size-16 mb-3 mt-4 d-md-none'>Maturity Benefits</div>
        <Row className='mt-3'>
          <Col xs={12} md={12} className='mt-3 mt-md-0'>
            <ChangeProductSection
              productName={productData.product_name}
              familyProductId={productData.family_product_id}
              cardName={coverLabelBaseOnNeed}
              changeProductList={productData.changeProductList}
              productData={data}
            />
          </Col>

          <Col md={12} className='col-12 mt-4 mt-md-0'>
            <DeathCoverGraphSlider
              saLabel='Income'
              productTitle=''
              income_value={form.$("income").value}
              premium_value={form.$("premium").value}
              onIncomeChange={() => {}}
              openChangeReasonModal={() => {}}
              onPremiumChange={form.$("premium").onChange}
              dataChange={() => {}}
              hideOverLay={onHideOverlay}
              productID={productData.product_id}
              productData={productData}
              showOverlay={showOverlay}
              onClick={() => form.$("premium").extra.calculatePremium(form, data)}
              data={""}
              isIncomeFieldDisable={form.$("income").disabled}
              isPremiumDisable={true}
              premiumFreq={form.$("payment-frequency").value.label}
              cardKey={data.cardKey}
              formRef={form}
            />
          </Col>
        </Row>
        {/* <div className='px-2 py-2 py-md-2 my-3 mb-md-4'> */}
          <Row className="mt-3">
            <Col md={6} className='col-12 mt-3'>
              <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("policy-term").label}</div>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey='label'
                bottomsheetTitle={form.$("policy-term").label}
                menuList={form.$("policy-term").extra.optionList}
                value={form.$("policy-term").value}
                onSelect={form.$("policy-term").onChange}
              />
            </Col>
            <Col md={6} className='col-12 mt-3'>
              <p className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("premium-paying-term").label}</p>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey='label'
                bottomsheetTitle={form.$("premium-paying-term").label}
                menuList={form.$("premium-paying-term").extra.optionList}
                value={form.$("premium-paying-term").value}
                onSelect={form.$("premium-paying-term").onChange}
                disabled={form.$("premium-paying-term").disabled}
              />
            </Col>
          {/* </Row> */}
        {/* </div> */}
        {/* <Row className='mb-3'> */}
          <Col md={4} className='col-12 mt-3'>
            <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("payment-frequency").label}</div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={form.$("payment-frequency").label}
              menuList={form.$("payment-frequency").extra.optionList}
              value={form.$("payment-frequency").value}
              disabled={form.$("payment-frequency").disabled}
              onSelect={form.$("payment-frequency").onChange}
            />
          </Col>
          <Col md={4} className='col-12 mt-3'>
            <div className='mb-2 font-size-12 font-weight-light total-premium-container-heading'>{form.$("claim-option").label}</div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey='label'
              bottomsheetTitle={form.$("claim-option").label}
              menuList={form.$("claim-option").extra.optionList}
              value={form.$("claim-option").value}
              onSelect={form.$("claim-option").onChange}
            />
          </Col>
        </Row>
      </div>
    </form>
  );
};
export default observer(CriticarePlus);
