import Utils from './utils';
import * as keys from './keys'

const FormObserver = (formRef, props) => {
  formRef.$('policy-term').observe({
    key: 'value',
    call: ({ field }) => {

      const ptValue = Number(field.value.id)
      const pptValue = Number(formRef.$('premium-term').value.id)

      let pptlist = [{id: '5', label: '5'},{id: '7', label: '7'}, {id: '10', label: '10'}, {id: '12', label: '12'}];

      if(ptValue < 15){
        pptlist = [{id: '5', label: '5'},{id: '7', label: '7'}];

        if(pptValue > 7){
          formRef.$('premium-term').set('value',{id: '5', label: '5'})
        }
      }

      formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        optionList: pptlist
    })

      Utils.updateBi(keys.PR_PT, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('premium-term').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.PR_PPT, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('payment-frequency').observe({
    key: 'value',
    call: ({ field }) => {
      let premium = formRef.$('premium').value
      let frequency = field.value.id
      let data = {
        premium: premium,
        frequency: frequency
      }
      Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)
    }
  })

  formRef.$('option').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.OPTION, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('ADB').observe({
    key: 'value',
    call: ({ field }) => {
      let adbValue = formRef.$('ADB-dropdown').value
      Utils.updateBi(keys.ADB_RIDER, adbValue, props.data.product_id, formRef, props)
    }
  })

  formRef.$('ATPDRider').observe({
    key: 'value',
    call: ({ field }) => {
      let atpdValue = formRef.$('ATPDRider-dropdown').value
      Utils.updateBi(keys.ATPD_RIDER, atpdValue, props.data.product_id, formRef, props)
    }
  })

  formRef.$('CriticalIllnessRider').observe({
    key: 'value',
    call: ({ field }) => {
      let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
      Utils.updateBi(keys.CRITICAL_ILLNESS_RIDER, criticalIllness, props.data.product_id, formRef, props)
    }
  })

  formRef.$('IncomeBenefitRider').observe({
    key: 'value',
    call: ({ field }) => {
      let incomeBuilder = formRef.$('IncomeBenefitRider-dropdown').value
      Utils.updateBi(keys.INCOME_BENEFIT_RIDER, incomeBuilder, props.data.product_id, formRef, props)
    }
  })

  formRef.$('WaiverOfPremiumRider').observe({
    key: 'value',
    call: ({ field }) => {
      let value = '';
      if (field.value) {
        value = "1"
      }

      Utils.updateBi(keys.WOP_RIDER, value, props.data.product_id, formRef, props)
    }
  })

  formRef.$('PayorWaiverBenefitRider-dropdown').observe({
    key: 'value',
    call: ({ field }) => {
      console.log('Dropdown Value is ', field.value)
      Utils.updateBi(keys.PWB, field.value.id, props.data.product_id, formRef, props)
    }
  })


}

export default FormObserver