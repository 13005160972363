import OptimizationStore from '../../../store/OptimizationStore';
import * as keys from './keys';
import Validator from './validator';
class Utils {

  calculatePT(age) {
    let ptList = []

    ptList.push({ id: '80-@LI_ENTRY_AGE', label: "Upto Age 80" });
    ptList.push({ id: '85-@LI_ENTRY_AGE', label: "Upto Age 85" });
    ptList.push({ id: '100-@LI_ENTRY_AGE', label: "Upto Age 100" });

    let ptOption = 100 - age

    if (ptOption > 10) {
      for (let i = 10; i <= ptOption; i++) {
        ptList.push({ id: i, label: "" + i });
      }
    }

    return ptList
  }

  calculatePPT(ptId, age) {
    let ptOption = 100 - age
    let pptList = []
    if (ptId === '80-@LI_ENTRY_AGE') {
      pptList = [{ id: '80-@LI_ENTRY_AGE', label: "Upto Age 80" }]
    }

    if (ptId === '85-@LI_ENTRY_AGE') {
      pptList = [{ id: '85-@LI_ENTRY_AGE', label: "Upto Age 85" }]
    }

    if (ptId === '100-@LI_ENTRY_AGE') {
      pptList = [{ id: '100-@LI_ENTRY_AGE', label: "Upto Age 100" }]
    }

    if (ptId >= 10 && age <= ptOption) {
      pptList.push({ id: 5, label: "5" })
      pptList.push({ id: 7, label: "7" })

      if (ptId > 14 && ptId <= 19) {
        pptList.push({ id: 10, label: "10" })
      }

      if (ptId > 19 && ptId <= 24) {
        pptList.push({ id: 10, label: "10" })
        pptList.push({ id: 15, label: "15" })
      }

      if (ptId > 24) {
        pptList.push({ id: 10, label: "10" })
        pptList.push({ id: 15, label: "15" })
        pptList.push({ id: 20, label: "20" })
      }
      pptList.push({ id: ptId, label: `${ptId}` })

    } else {
      pptList.push({ id: 5, label: "5" })
      pptList.push({ id: 7, label: "7" })
      pptList.push({ id: 10, label: "10" })
      pptList.push({ id: 15, label: "15" })
      pptList.push({ id: 20, label: "20" })
    }

    return pptList
  }

  fetchIncomePayoutPercentage(mode) {
    let incomePayoutList = []
    switch (mode) {
      case 'Annual':
        incomePayoutList = [{ label: '1.25%', id: 12 }, { label: '0.87%', id: 13 }];
        break;

      case 'Semi-Annual':
        incomePayoutList = [{ label: '0.61%', id: 12 }, { label: '0.43%', id: 13 }];
        break;

      case 'Quarterly':
        incomePayoutList = [{ label: '0.30%', id: 12 }, { label: '0.21%', id: 13 }];
        break;

      case 'Monthly':
        incomePayoutList = [{ label: '0.10%', id: 12 }, { label: '0.07%', id: 13 }];
        break;
    }

    return incomePayoutList
  }

  fetchAddionalSaList() {
    let initialId = 22
    let additionalSaList = [{ id: initialId, label: 10 + '%' }]

    for (let i = 1; i <= 10; i++) {
      if(i !== 1){
        if (i == 2) {
          initialId += 2
        } else {
          initialId += 1
        }
        let saLabel = 10 * i
        additionalSaList.push({ id: initialId, label: saLabel + '%' })
      }
    }

    return additionalSaList
  }

  payoutOptionPercentage(deathBenefitOption) {
    let optionList = []
    let initialId = 51

    if (deathBenefitOption === "Lumpsum") {
      optionList = [{ label: '100%', id: 39 }]
    }

    if (deathBenefitOption === "Monthly Income") {
      optionList = [{ label: 'NA', id: 51 }]

    }

    if (deathBenefitOption === "Lumpsum plus Monthly Income") {
      for (let i = 1; i <= 99; i++) {
        initialId += 1

        optionList.push({ label: i + '%', id: initialId })
      }
    }
    return optionList
  }

  updateBi = (key, value, productId, formRef, productData) => {
    let biData = OptimizationStore.fetchBiRequest(productId, productData?.data?.family_product_id)
    let biRequest = biData.data.bi_request

    switch (key) {
      case keys.PR_PT:
        biRequest[keys.PR_PT] = value.pt
        biRequest[keys.PR_PPT] = value.ppt
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value
        break;

      case keys.FREQUENCY:
        let premiumAmount = 0;
        let amount = value.premium;
        switch (Number(value.frequency)) {
          case 1:
            premiumAmount = '' + amount * 1
            break;
          case 2:
            premiumAmount = '' + amount * 2
            break;
          case 3:
            premiumAmount = '' + amount * 4
            break;
          case 4:
            premiumAmount = '' + amount * 12
            break;
        }

        biRequest[keys.PREMIUM] = premiumAmount
        biRequest[keys.FREQUENCY] = value.frequency
        break;

      case keys.MARITAL_STATUS:

        biRequest[keys.MARITAL_STATUS] = value.maritalStatus
        biRequest[keys.BETTER_HALF_BENEFIT] = value.betterHalfBenefit
        biRequest[keys.SPOUSE_NAME] = value.spouseName
        biRequest[keys.SPOUSE_GENDER] = value.spouseGender
        biRequest[keys.SPOUSE_AGE] = value.spouseAge
        biRequest[keys.SPOUSE_DOB] = value.spouseDOB
        break;

      case keys.CHILD_FUTURE_PROTECT_BENEFIT:
        biRequest[keys.CHILD_FUTURE_PROTECT_BENEFIT] = value.childBenefitData
        biRequest[keys.ADDITIONAL_SA] = value.additionalSA
        biRequest[keys.CHILD_DOB] = value.lifeAssuredDob
        biRequest[keys.CHILD_ENTRY_AGE] = value.lifeAssuredAge
        break;

      case keys.CHILD_DOB:
        biRequest[keys.CHILD_DOB] = value.lifeAssuredDob
        biRequest[keys.CHILD_ENTRY_AGE] = value.lifeAssuredAge
        break;

      case keys.ADDITIONAL_SA:
        biRequest[keys.ADDITIONAL_SA] = value
        break;

      case keys.DEATH_BENEFIT_PAYOUT_OPTION:
        biRequest[keys.DEATH_BENEFIT_PAYOUT_OPTION] = value.deathBenefitOption
        biRequest[keys.PAYOUT_OPTIONS] = value.payoutOption
        biRequest[keys.PAYOUT_PERIOD] = value.payoutPeriod
        break;

      case keys.PAYOUT_OPTIONS:
        biRequest[keys.PAYOUT_OPTIONS] = value
        break;

      case keys.PAYOUT_PERIOD:
        biRequest[keys.PAYOUT_PERIOD] = value
        break;

      case keys.UNDERWRITING:
        biRequest[keys.UNDERWRITING] = value
        break;

      case keys.PLAN_OPTION:
        biRequest[keys.PLAN_OPTION] = value

        if (value !== 5) {
          biRequest[keys.LIVE_LONG_OPTION_INCOME_START_AGE_OPTION] = ""
          biRequest[keys.LIVE_LONG_OPTION_INCOME_PAYOUT_MODE_OPTION] = ""
          biRequest[keys.LIVE_LONG_OPTION_INCOME_PAYOUT_PERCENTAGE_OPTION] = ""
        }
        break;

      case keys.LIVE_LONG_OPTION_INCOME_START_AGE_OPTION:
        biRequest[keys.LIVE_LONG_OPTION_INCOME_START_AGE_OPTION] = value
        break;

      case keys.LIVE_LONG_OPTION_INCOME_PAYOUT_MODE_OPTION:
        biRequest[keys.LIVE_LONG_OPTION_INCOME_PAYOUT_MODE_OPTION] = value
        break;

      case keys.LIVE_LONG_OPTION_INCOME_PAYOUT_PERCENTAGE_OPTION:
        biRequest[keys.LIVE_LONG_OPTION_INCOME_PAYOUT_PERCENTAGE_OPTION] = value
        break;

      case keys.BETTER_HALF_BENEFIT:

        biRequest[keys.BETTER_HALF_BENEFIT] = value.betterHalfBenefit
        biRequest[keys.SPOUSE_NAME] = value.spouseName
        biRequest[keys.SPOUSE_GENDER] = value.spouseGender
        biRequest[keys.SPOUSE_AGE] = value.spouseAge
        biRequest[keys.SPOUSE_DOB] = value.spouseDOB
        break;

      case keys.SPOUSE_NAME:
        biRequest[keys.SPOUSE_NAME] = value
        break;

      case keys.SPOUSE_GENDER:
        biRequest[keys.SPOUSE_GENDER] = value
        break;

        case keys.SPOUSE_TOBACCO_CONSUMER:
          biRequest[keys.SPOUSE_TOBACCO_CONSUMER] = value
          break;

      case keys.SPOUSE_DOB:
        biRequest[keys.SPOUSE_AGE] = value.age
        biRequest[keys.SPOUSE_DOB] = value.dob
        biRequest[keys.DISPLAY_SPOUSE_DOB] = value.dob
        break;

      case keys.LI_SMOKE:
        biRequest[keys.LI_SMOKE] = value;
        break;

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value;
        break;

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = value;
        } else {
          biRequest[keys.ADB_RIDER] = "";
        }
        break;

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = value;
        } else {
          biRequest[keys.ATPD_RIDER] = "";
        }
        break;

      case keys.Critical_ILLNESS_RIDER:
        if (value) {
          biRequest[keys.Critical_ILLNESS_RIDER] = value;
        } else {
          biRequest[keys.Critical_ILLNESS_RIDER] = "";
        }
        break;

      case keys.HOSPITALITY_CASH_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.HOSPITALITY_CASH_BENEFIT_RIDER] = value;
        } else {
          biRequest[keys.HOSPITALITY_CASH_BENEFIT_RIDER] = "";
        }
        break;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value)
  }

  getTotalPrice = (data) => {
    try {
      return Math.round(data.PREM_DISPLAY)
    } catch (e) {
      return 0
    }
  }

  // getRiderTax = (data) => {
  //   let totalRider = 0;
  //   data?.parsedResponse?.inputValidationStatus?.map((item) => {
  //     totalRider += Number(item.Tax)
  //   })
  //   return totalRider;
  // }

  fetchPolicyTimeline(element,inputString ,frequency, payoutMode) {

    let incomeType = parseInt(inputString.find((item) => item.Key === "@PR_OPTION_13").Value);
    let PR_OPTION_2_VALUE = parseInt(inputString.find((item) => item.Key === "@PR_OPTION_2").Value);

    let lifeCover = 0
    let youGet = 0

    // for life cover
    switch (incomeType) {
      case 35:
        //Lumpsum plus Monthly Income
        lifeCover = Math.round(Math.round(element.TOTAL_DB_MONTHLY) + Math.round(element.DB_LUMPSUM)) 
      break;

      case 33:
        // Lumpsum
        lifeCover = element.SUMASSURED_DEATH
      break;

      case 34:
        // Monthly Income
        lifeCover = element.TOTAL_DB_MONTHLY
      break;
    
      default:
        break;
    }

    // for you_get
    switch (PR_OPTION_2_VALUE) {
      case 4:
        youGet = element.MB_G 
        
        break;

        case 5:
          youGet = element.INCOME_PAYOUT 
          
          break;
    
      default:
        break;
    }

    let data = {
      youPay: Math.round(element.TOTAL_PREM_AFTER_FYP),
      youGet: youGet,
      lifeCover: Math.round(lifeCover),
    };

    return data;
  }

  getBaseSAIfChildFutureProtectBenefitEnabled(targetSA, selectedChildFutureBenefitPercentOptionId) {
    let targetSumAssured = typeof (targetSA) === 'number' ? targetSA : parseInt(targetSA)
    let additionalSAList = this.fetchAddionalSaList();
    let childFPBPercentObj = additionalSAList.filter(obj => obj.id === selectedChildFutureBenefitPercentOptionId)[0]
    let childFPBPercentValStr = childFPBPercentObj.label
    let childFPBPercentRemovePercent = childFPBPercentValStr.replace("%", "")
    let selectedChildFutureBenefitSAPercentValue = parseInt(childFPBPercentRemovePercent)
    let baseSA = (targetSumAssured * 100) / (100 + selectedChildFutureBenefitSAPercentValue)
    return Math.round(baseSA)
  }
}

export default new Utils();
