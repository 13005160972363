import React, { useEffect, useState } from "react";

import { Col, Row, Form } from "react-bootstrap";

import { observer } from "mobx-react";
import moment from "moment";


import AmountCalculation from "../../optimization-card/amount-calculation/AmountCalculation";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import { BottomsheetDropdown, DatepickerModal, ChangeProductSection } from "components";
import FundDistributionModal from "../../optimization-card/fundDistributionModal";
import Utils from "./utils";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import DeathBenefitAmount from "utils/optimization/deathBenefitAmount";

const WealthPremier = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false)

  let productData =  data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let firstYearResponse = productData.firstYearResponse;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])

  const onHideOverlay = () => {
    setShowOverlay(false)
  }

  return (
    <div className="save-my-children">
      <Row className="align-items-center mt-md-4">
        <Col xs={12} md={6} className="col-12">
          <ChangeProductSection
            productName={productData.product_name}
            cardName={coverLabelBaseOnNeed}
            changeProductList={data?.data?.changeProductList}
            productData={data}
          />
        </Col>
      </Row>

      <DeathCoverGraphSlider
        productTitle="Active Income"
        openChangeReasonModal={false}
        onIncomeChange={() => { }}
        onPremiumChange={form.$("premium").onChange}
        hideOverLay={onHideOverlay}
        productID={productData.product_id}
        productData={data?.data}
        showOverlay={showOverlay}
        income_value=""
        premium_value={form.$("premium").value}
        cardKey={data.cardKey}
        onClick={() =>
          form
            .$("premium")
            .extra.calculatePremiun(form.$("premium").value, data, form)
        }
        data=""
        isIncomeFieldDisable={form.$("premium").disabled}
        premiumFreq={form.$("payment-frequency").value.label}
        formRef={form}
        secondInputTextlabel="Single Premium"
      />

      <Row className='mt-3 ml-0'>
        <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{"Death Benefit :"}</p>
        <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(productData)}</p>
      </Row>

      <Row className="mt-2 mt-md-4">
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("policy-term").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("policy-term").label}
              menuList={form.$("policy-term").extra.optionList}
              value={form.$("policy-term").value}
              onSelect={form.$("policy-term").onChange}
              disabled=""
            />
          </div>
        </Col>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("premium-term").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("premium-term").label}
              menuList={form.$("premium-term").extra.optionList}
              value={form.$("premium-term").value}
              onSelect={form.$("premium-term").onChange}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2 mt-md-4">
        {/* <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("payment-frequency").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("payment-frequency").label}
              menuList={form.$("payment-frequency").extra.optionList}
              value={form.$("payment-frequency").value}
              onSelect={form.$("payment-frequency").onChange}
            />
          </div>
        </Col> */}
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("life-cover-option").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("life-cover-option").label}
              menuList={form.$("life-cover-option").extra.optionList}
              value={form.$("life-cover-option").value}
              onSelect={form.$("life-cover-option").onChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("second-life-assured-name").label}
            </div>
            <Form.Control
              placeholder={form.$("second-life-assured-name").label}
              value={form.$("second-life-assured-name").value}
              onChange={form.$("second-life-assured-name").onChange}
              onBlur={() =>
                form
                  .$("second-life-assured-name")
                  .extra.onBlur(form, productData.product_id, data)
              }
              disabled={form.$("life-cover-option").extra.jointFieldDisable}
            />
          </div>
        </Col>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("second-life-assured-gender").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("second-life-assured-gender").label}
              menuList={form.$("second-life-assured-gender").extra.optionList}
              value={form.$("second-life-assured-gender").value}
              onSelect={form.$("second-life-assured-gender").onChange}
              disabled={form.$("life-cover-option").extra.jointFieldDisable}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("second-life-assured-dob").label}
            </div>
            <DatepickerModal
              plain
              date={form.$("second-life-assured-dob").value}
              textStyle="font-size-12 font-weight-light font-size-md-16 font-weight-normal text-nowrap overflow-hidden txt-overflow"
              onChange={(value) =>
                form
                  .$("second-life-assured-dob")
                  .extra.changeDob(form, value, productData.product_id, data)
              }
              disabled={form.$("life-cover-option").extra.jointFieldDisable}
              minDate={moment().subtract(18, "years").toString()}
            />
          </div>
        </Col>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("fund-strategy").label}
            </div>
            {
              <React.Fragment>
                <div className="text-description-text-color border p-2">
                  <span
                    className="a cursor-pointer"
                    onClick={() =>
                      form
                        .$("fund-strategy")
                        .extra.toggleFundDistributionModal(
                          form,
                          true,
                          form.$('fund-strategy').extra.fundList,
                          data.data.product_id
                        )
                    }
                  >
                    {form.$("fund-strategy").label}
                  </span>
                </div>
              </React.Fragment>
            }
          </div>
        </Col>
      </Row>
      <FundDistributionModal
        formRef={form}
        show={form.$("fund-strategy").extra.showFundDistributionModal}
        data={data}
        fundList={form.$('fund-strategy').extra.fundList}
        submitButtonText={form.$('fund-strategy').extra.setDistributionButtonText}
        onHide={(fundList) =>
          form.$("fund-strategy").extra.toggleFundDistributionModal(form, false, fundList, data.data.product_id)
        }
        onSetDistribution={form.$('fund-strategy').extra.onSetDistribution}
      />
      <AmountCalculation
        cardKey={data.cardKey}
        item={productData}
        hideTotalPremium={true}
        total_price={Utils.getTotalPrice(firstYearResponse)}
      />
    </div>
  );
};

export default observer(WealthPremier);
