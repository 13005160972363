
// React imports
import React from 'react'

// package imports
import { Form } from "react-bootstrap"

// project imports
import Utils from './utils'
import { BottomsheetDropdown } from 'components'

const SingleFamilyDetail = ({formController,memberType}) => {

  return (
   <div className="row">
   <div className="col-12">
     <p className="communication-modal-heading font-size-16 font-size-md-12 font-weight-medium mb-1">
       {formController.$(memberType).label}
     </p>
     <hr className="bg-dark mt-1 " />
   </div>


     <div className="col-12 col-md-7 mb-2">

       <p className="communication-modal-sub-heading font-size-14 font-size-md-10 font-weight-medium mb-1">
         Name
       </p>

       <div className="d-md-flex">

        <div className="mr-md-2 mb-3 mb-md-0">
        <BottomsheetDropdown
          dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
          menuItemStyle='d-flex justify-content-between align-items-center ml-md-2'
          menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
          selectedLabelKey={formController.$(memberType).$("salutation").extra.selectedOptionLabelKey}
          bottomsheetTitle={formController.$(memberType).$("salutation").extra.bottomSheetTitle}
          menuList={formController.$(memberType).$("salutation").extra.optionList}
          value={formController.$(memberType).$("salutation").value}
          onSelect={formController.$(memberType).$("salutation").onChange}
          showErrorMsg={formController.$(memberType).$("salutation").error !== null}
          errorMsg={formController.$(memberType).$("salutation").error}
          disabled={formController.$(memberType).$("salutation").disabled}
        />
        </div>

         <Form.Group className="mb-0">
           <Form.Control
             type={formController.$(memberType).$("first_name").type}
             className="font-size-lg-12 font-size-14 font-weight-light existing-customer-value"
             placeholder={formController.$(memberType).$("first_name").placeholder}
             value={formController.$(memberType).$("first_name").value}
             onChange={
               (event) => Utils.updateValues(formController,memberType,"first_name", event.target.value)}
           />
           {formController.$(memberType).$("first_name").error != null ? (
           <p className='font-size-11 font-weight-light text-danger'>{formController.$(memberType).$("first_name").error}</p>
                 ) : null}
         </Form.Group>

         <Form.Group className="mb-0 mt-2 mt-md-0 ml-md-2 ">
           <Form.Control
             type={formController.$(memberType).$("middle_name").type}
             className="font-size-lg-12 font-size-14 font-weight-light existing-customer-value"
             placeholder={formController.$(memberType).$("middle_name").placeholder}
             value={formController.$(memberType).$("middle_name").value}
             onChange={
               (event) => Utils.updateValues(formController,memberType,"middle_name", event.target.value)}
           />
           {formController.$(memberType).$("middle_name").error != null ? (
           <p className='font-size-11 font-weight-light text-danger'>{formController.$(memberType).$("middle_name").error}</p>
                 ) : null}
         </Form.Group>

         <Form.Group className="mb-0 mt-2 mt-md-0 ml-md-2">
           <Form.Control
             type={formController.$(memberType).$("last_name").type}
             className="font-size-lg-12 font-size-14 font-weight-light existing-customer-value"
             placeholder={formController.$(memberType).$("last_name").placeholder}
             value={formController.$(memberType).$("last_name").value}
             onChange={
               (event) => Utils.updateValues(formController,memberType,"last_name", event.target.value)}
           />
           {formController.$(memberType).$("last_name").error != null ? (
           <p className='font-size-11 font-weight-light text-danger'>{formController.$(memberType).$("last_name").error}</p>
                 ) : null}
         </Form.Group>
       </div>
     </div>

    <div className="col-12 col-md-5 mb-2 mt-3 mt-md-0">
    <p className="communication-modal-sub-heading font-size-14 font-size-md-10 font-weight-medium mb-1">
     Mobile Number
    </p>

    <div className="d-flex">
    <Form.Group className="mb-0 w-25">
      <Form.Control
        className="font-size-lg-12 font-size-14 font-weight-light existing-customer-value"
        placeholder={formController.$(memberType).$("mobile_number_code").placeholder}
        value={formController.$(memberType).$("mobile_number_code").value}
        onChange={(event) => Utils.mobileNumberOnChange(formController,memberType,"mobile_number_code", event.target.value)}
      />
       {formController.$(memberType).$("mobile_number_code").error != null ? (
       <p className='font-size-11 font-weight-light text-danger'>{formController.$(memberType).$("mobile_number_code").error}</p>
                 ) : null}
      </Form.Group>

      <Form.Group className="mb-0 ml-1 w-75">
      <Form.Control
        className="font-size-lg-12 font-size-14 font-weight-light existing-customer-value"
        placeholder={formController.$(memberType).$("mobile_number").placeholder}
        value={formController.$(memberType).$("mobile_number").value}
        onChange={(event) => Utils.mobileNumberOnChange(formController,memberType,"mobile_number", event.target.value)}
      />
       {formController.$(memberType).$("mobile_number").error != null ? (
       <p className='font-size-11 font-weight-light text-danger'>{formController.$(memberType).$("mobile_number").error}</p>
                 ) : null}
      </Form.Group>
      </div> 
    </div>


    <div className="col-12 col-md-7 my-2">
    <p className="communication-modal-sub-heading font-size-14 font-size-md-10 font-weight-medium mb-1">
     Email
    </p>

    <div className="">
    <Form.Group className="mb-0">
      <Form.Control
        type={formController.$(memberType).$("email").type}
        className="font-size-lg-12 font-size-14 font-weight-light existing-customer-value"
        placeholder={formController.$(memberType).$("email").placeholder}
        value={formController.$(memberType).$("email").value}
        onChange={formController.$(memberType).$("email").onChange}
      />
       {formController.$(memberType).$("email").error != null ? (
       <p className='font-size-11 font-weight-light text-danger'>{formController.$(memberType).$("email").error}</p>
                 ) : null}
      </Form.Group>
      </div> 
    </div>

    <div className="col-12 col-md-5 my-2">
     <div className="d-flex justify-content-between">
      <p className="communication-modal-sub-heading font-size-14 font-size-md-10 font-weight-medium mb-1 mr-2">
      Whatsapp Number
      </p>

      <div className="d-flex">
      <input
         type="checkbox"
         checked={formController.$(memberType).$("same_as_mobile_number").value}
         onChange={() => Utils.togglePhoneNumberCheckbox(formController,memberType,"same_as_mobile_number",formController.$(memberType).$("same_as_mobile_number").value)}
       />
       <p className="font-size-12 font-size-md-10 ml-2 mb-0 same-as-above-checkbox font-weight-light"> Same as above.</p>
      </div>
      
     </div>

    <div className="d-flex">
    <Form.Group className="mb-0 w-25">
      <Form.Control
        className="font-size-lg-12 font-size-14 font-weight-light existing-customer-value"
        placeholder={formController.$(memberType).$("whatsapp_mobile_number_code").placeholder}
        value={formController.$(memberType).$("whatsapp_mobile_number_code").value}
        onChange={(event) => Utils.mobileNumberOnChange(formController,memberType,"whatsapp_mobile_number_code", event.target.value)}
      />
       {formController.$(memberType).$("whatsapp_mobile_number_code").error != null ? (
       <p className='font-size-11 font-weight-light text-danger'>{formController.$(memberType).$("whatsapp_mobile_number_code").error}</p>
                 ) : null}
      </Form.Group>

      <Form.Group className="mb-0 ml-1 w-75">
      <Form.Control
        type={formController.$(memberType).$("whatsapp_mobile_number").type}
        className="font-size-lg-12 font-size-14 font-weight-light existing-customer-value"
        placeholder={formController.$(memberType).$("whatsapp_mobile_number").placeholder}
        value={formController.$(memberType).$("whatsapp_mobile_number").value}
        onChange={(event) => Utils.mobileNumberOnChange(formController,memberType,"whatsapp_mobile_number", event.target.value)}
      />
       {formController.$(memberType).$("whatsapp_mobile_number").error != null ? (
       <p className='font-size-11 font-weight-light text-danger'>{formController.$(memberType).$("whatsapp_mobile_number").error}</p>
                 ) : null}
      </Form.Group>
      </div> 
    </div>
 </div>
  )
}

export default SingleFamilyDetail