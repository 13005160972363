// Project Imports
import fetchNeedMaster from "./fetchNeedMasterData"

const fetchNeedDetailsBasedonNeedUniqueName = async (needUniqueName) => {
    let needMaster = await fetchNeedMaster()

    let selectedNeed = needMaster.find(item => item.unique_name === needUniqueName)
    return selectedNeed

}

export default fetchNeedDetailsBasedonNeedUniqueName