// React Imports
import { useState } from 'react'

// Project Imports
import ProtectionCalculatedValue from '../../calculators/ProtectionCalculatedValue'
import ProtectionCalculator from '../../calculators/ProtectionCalculator'

const ProtectionCalculatorForm = (props) => {

  const [showCalculatedValue, setShowCalculatedValue] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [calculatedData, setCalculatedData] = useState({})
  const [dateOfBirth,setDateOfBirth] = useState()

  return (
    <>
      {!showCalculatedValue ? (
        <ProtectionCalculator
          chooseNeedFormController={props.chooseNeedFormController}
          setMonthlyExpenseValuesFromChooseNeeds={props.setMonthlyExpenseValuesFromChooseNeeds}
          setGraphData={setGraphData}
          setCalculatedData={setCalculatedData}
          setShowCalculatedValue={setShowCalculatedValue}
          hideCalculatorModal={props.hideCalculatorModal}
          setDateOfBirth={setDateOfBirth}
        />
      ) : (
        < ProtectionCalculatedValue
          graphData={graphData}
          setShowCalculatedValue={setShowCalculatedValue}
          calculatedData={calculatedData}
          targetAmountTypeValue={props.targetAmountTypeValue}
          hideCalculatorModal={props.hideCalculatorModal}
          onUseTheValueClick={props.onUseTheValueClick}
          dateOfBirth={dateOfBirth}
        />
      )}
    </>
  )
}

export default ProtectionCalculatorForm
