// Package Imports
const { Form } = require("mobx-react-form")
import yup from "mobx-react-form/lib/validators/YUP"
import vjf from "mobx-react-form/lib/validators/VJF"
import * as $pkg from "yup"
import validator from "validator"

// Project Imports
import formObserver from "./formObserver"
import setInitialDefaults from "./initialDefaults"

class AddInsuredProtectionForFamilyController extends Form {
  plugins() {
    return {
      vjf: vjf(validator),
      yup: yup({
          package: $pkg,
          schema: (_yup) =>
          _yup.object().shape({
            journey_id: _yup.string().optional(),
            occupation: _yup.object().shape({
                id: _yup.mixed().required("This field is mandatory")
            }),
            education: _yup.object().shape({
              id: _yup.mixed().required("This field is mandatory")
            }),
            location: _yup.array().min(1, "This field is mandatory").required(),
            // recommendation: _yup.object().optional(),
            target_amount_type_dropdown: _yup.object().shape({
              id: _yup.mixed().required("This field is mandatory")
            }),
            target_amount_dropdown: _yup.object().shape({
              id: _yup.mixed().optional()
            }),
        }),
      })
    }
  }

  

  setup() {
    return {
      fields: [
        {
          name: "journey_id",
          type: "hidden",
        },
        {
          name: "occupation",
          label: "Occupation",
          placeholder: "",
          value: {},
          extra: {
            optionList: [],
            bottomSheetTitle: "occupation",
          },
        },
        {
          name: "education",
          label: "Education",
          placeholder: "",
          value: {},
          extra: {
            optionList: [],
            bottomSheetTitle: "education",
          },
        },
        {
          name: "location",
          label: "Location",
          placeholder: "Type PIN Code",
          value: [],
          extra: {
            optionList: [],
            bottomSheetTitle: "Select a Location",
          },
        },
        // {
        //   name: "recommendation",
        //   label: "Recommend me a Protection Cover",
        //   placeholder: "",
        //   value: {
        //     id: "1",
        //     label: "a Lumpsum amount",
        //   },
        //   extra: {
        //     optionList: [
        //       {
        //         id: "1",
        //         label: "a Lumpsum amount",
        //         type: "lumpsum",
        //       },
        //     ],
        //     bottomSheetTitle: "Select an Option",
        //   },
        // },
        {
          name: "target_amount_type_dropdown",
          label: "Set",
          placeholder: "",
          value: {},
          extra: {
            optionList: [],
          },
        },
        {
          name: "target_amount_dropdown",
          label: "Set amount to",
          placeholder: "",
          value: {},
          extra: {
            optionList: [],
          },
        },
      ],
    }
  }

  setDefaults(journeyData, journeyId) {
    setInitialDefaults(this, journeyData, journeyId)
    // Observer fields
    formObserver(this)
  }
}

export default AddInsuredProtectionForFamilyController
