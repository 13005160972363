const fetchErrorMessageBasedOnAmountAndPlan = (need, value, amountType, min, max) => {
    switch(need){
        // case "protection_for_my_family":
        //     if(parseInt(value) > parseInt(max)){
        //         return "Maximum eligible cover amount as per your profile is Rs. " + max
        //     }else if(parseInt(value) < parseInt(min)){
        //         return "Minimum eligible cover amount as per your profile is Rs. " + min
        //     }
        //     break
        case "grow_my_money":
        case "save_for_my_children":
        // case "protection_for_my_health":
        case "retirement":
            // commented for now as discussed with tanvi on 27/07/2022 will be enabled once min max logic will be implemented
            // if(amountType === "premium"){
            //     if(parseInt(value) > parseInt(max)){
            //         return "Please enter a new premium amount"
            //     }else if(parseInt(value) < parseInt(min)){
            //         return "Please enter a new premium amount"
            //     }
            // }else{
            //     if(parseInt(value) > parseInt(max)){
            //         return "Please enter a new target amount"
            //     }else if(parseInt(value) < parseInt(min)){
            //         return "Please enter a new target amount"
            //     }
            // }
            break
    }
    return ""

}

export default fetchErrorMessageBasedOnAmountAndPlan