import React, { Component } from "react";

import { Col, Row } from "react-bootstrap";
import { Button as UiButton } from "../../../../components";

import ProductConstants from "constants/ProductConstants";

import zindagiPlusPdf from "assets/prospectus-pdf/Zindagi-Plus-Brochure.pdf";
import criticarePdf from "assets/prospectus-pdf/CritiCare-Plus-Brochure.pdf";
import totalProtectPlusPdf from "assets/prospectus-pdf/TPP-Brochure.pdf";
import saralJeevanBimaPdf from "assets/prospectus-pdf/Saral-Jeevan-Bima-Brochure.pdf";
import guaranteedSavingsStarPdf from "assets/prospectus-pdf/Guaranteed-Saving-Star-Brochure.pdf";
import foreverPensionPdf from "assets/prospectus-pdf/Forever-Pension-Brochure.pdf";
import easyPensionPdf from "assets/prospectus-pdf/Easy-Pension-Brochure.pdf";
import saralPensionPdf from "assets/prospectus-pdf/Saral-Pension-Brochure.pdf";
import premierGuaranteedIncomePdf from "assets/prospectus-pdf/PGI-Brochure.pdf";
import activeIncomePdf from "assets/prospectus-pdf/Active-Income-Brochure.pdf";
import incomeBuilderPdf from "assets/prospectus-pdf/Income-Builder-Brochure.pdf";
import guaranteedIncomeStarPdf from "assets/prospectus-pdf/Guaranteed-Income-Star-Brochure.pdf";
import gcapPdf from "assets/prospectus-pdf/GCAP-Brochure.pdf";
import dhanLabhPdf from "assets/prospectus-pdf/Dhan-Labh-Brochure.pdf";
import smartLifeStylePdf from "assets/prospectus-pdf/Smart-Lifestyle-Brochure.pdf";
import singlePayEndowmentPdf from "assets/prospectus-pdf/Single-Pay-Endowment-Assurance-Plan-Brochure.pdf";
import wealthPlusPdf from "assets/prospectus-pdf/Wealth-Plus-Brochure.pdf";
import wealthPremierPdf from "assets/prospectus-pdf/Wealth-Premier-Brochure.pdf";
import wealthUltimaPdf from "assets/prospectus-pdf/Wealth-Ultima-Brochure.pdf";
import posSaralNiveshPdf from "assets/prospectus-pdf/POS-Saral-Nivesh-Brochure.pdf";
import fspPdf from "assets/prospectus-pdf/Flexi-Savings-Plan-Brochure.pdf";

import "./underlying-card.css";

class UnderlyingCard extends Component {
  state = {};
  onOpenFile = (data) => {
    console.log("ON VIEW BI CLICKED >> ", data);
    let inputData = data.inputString;
    if (inputData && inputData.length > 0) {
      let file = inputData.filter((obj) => obj.Key == "@BI_PDF_PATH")[0];
      console.log("ON VIEW BI CLICKED >> FILE >> ", inputData, file);
      if (file) {
        window.open(file.Value, "_blank");
      }
    }
  };

  viewPdf = (data) => {
    let productName = data.title;

    switch (productName) {
      case ProductConstants.TOTAL_PROTECT_PLUS:
        window.open(totalProtectPlusPdf);
        break;

      case ProductConstants.CRITICARE_PLUS:
        window.open(criticarePdf);
        break;

      case ProductConstants.ZINDAGI_PLUS:
        window.open(zindagiPlusPdf);
        break;

      case ProductConstants.SARAL_JEEVAN_BIMA:
      case ProductConstants.POS_SARAL_JEEVAN_BIMA:
        window.open(saralJeevanBimaPdf);
        break;

      case ProductConstants.FOREVER_PENSION:
      case ProductConstants.FOREVER_PENSION_IMMEDIATE_ANNUITY:
      case ProductConstants.FOREVER_PENSION_DEFERRED_ANNUITY:
      case ProductConstants.POS_FOREVER_PENSION:
        window.open(foreverPensionPdf);
        break;

      case ProductConstants.EASY_PENSION:
        window.open(easyPensionPdf);
        break;

      case ProductConstants.SARAL_PENSION:
        window.open(saralPensionPdf);
        break;

      case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM:
      case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
      case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
      case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
      case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
      case ProductConstants.PREMIER_GUARANTEED_RETIREMENT_INCOME:
        window.open(premierGuaranteedIncomePdf);
        break;

      case ProductConstants.ACTIVE_INCOME_PLAN:
      case ProductConstants.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
      case ProductConstants.ACTIVE_INCOME_PLAN_WITH_FIB:
      case ProductConstants.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
      case ProductConstants.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
        window.open(activeIncomePdf);
        break;

      case ProductConstants.INCOME_BUILDER:
      case ProductConstants.SECURED_INCOME_OPTION_INCOME_BUILDER:
        window.open(incomeBuilderPdf);
        break;

      case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM:
      case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME:
      case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
      case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
      case ProductConstants.POS_GUARANTEED_SAVINGS_STAR:
        window.open(guaranteedSavingsStarPdf);
        break;

      case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
      case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
      case ProductConstants.GUARANTEED_INCOME_STAR:
      case ProductConstants.GUARANTEED_INCOME_STAR_FIB:
      case ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:
      case ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case ProductConstants.POS_GUARANTEED_INCOME_STAR:
      case ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
        window.open(guaranteedIncomeStarPdf);
        break;

      case ProductConstants.GCAP:
        window.open(gcapPdf);
        break;

      case ProductConstants.DHAN_LABH:
        window.open(dhanLabhPdf);
        break;

      case ProductConstants.SMART_LIFESTYLE:
      case ProductConstants.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
        window.open(smartLifeStylePdf);
        break;

      case ProductConstants.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
        window.open(singlePayEndowmentPdf);
        break;

      case ProductConstants.WEALTH_PLUS:
      case ProductConstants.RISING_STAR_WEALTH_PLUS:
        window.open(wealthPlusPdf);
        break;

      case ProductConstants.WEALTH_PREMIER:
        window.open(wealthPremierPdf);
        break;

      case ProductConstants.WEALTH_ULTIMA:
      case ProductConstants.LITTLE_CHAMP_WEALTH_ULTIMA:
      case ProductConstants.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
        window.open(wealthUltimaPdf);
        break;

      case ProductConstants.POS_SARAL_NIVESH:
        window.open(posSaralNiveshPdf);
        break;

      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
      case ProductConstants.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
      case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
      case ProductConstants.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
        window.open(fspPdf);
        break;

      default:
        break;
    }
  };

  render() {
    let { productData } = this.props;
    return (
      <React.Fragment>
        <div className='underlying-btns-row align-items-center mb-lg-4 mb-0 bottom-margin-12 d-flex'>
          <div className='under-lying-product-name flex-grow-1'>
            <label className='font-size-14 font-size-lg-14 font-weight-medium w-100 text-left mb-0 label'>{productData.title}</label>
          </div>

          <UiButton className='font-size-14 font-size-lg-12 ml-2 font-weight-light font-weight-lg-medium' variant='primary' buttonText='Prospectus' onClick={() => this.viewPdf(productData)} />

          <UiButton className='font-size-14 font-size-lg-12 ml-1 font-weight-light font-weight-lg-medium' variant='secondary' buttonText='View BI' onClick={() => this.onOpenFile(productData)} />
        </div>
      </React.Fragment>
    );
  }
}

export default UnderlyingCard;
