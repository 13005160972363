export default {
  PRAGATI_HOME_URL: "pragati_home_url",
  PRAGATI_POST_CALL_END_POINT_URL: "pragati_post_call_end_point_url",
  AUTH_TOKEN: "authToken",
  AGENT_ID: "agent_id",
  AGENT_CODE: "agent_code",
  AGENT_NAME: "agent_name",
  LOGIN_CODE: "login_code",
  VENDOR: "vendor",
  SOURCE_FROM: "source_from",
  LOGIN_AGENT_CODE: "login_agent_code",
  FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM: "first_time_optimization_page_load_param",
  START_APPLICATION_END_POINT_URL: "start_application_end_point_url"
};
