import React, { Component } from 'react';
import { DatepickerModal, CustomCheckbox, BottomsheetDropdown, DeathCoverPriceComponent, Button, Button as UiButton, AdditionalRiderBenifitsCard } from '../../../../../components'
import { Row, Col, Form, Tabs, Tab } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment'
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import AmountCalculation from '../../save-my-children/AmountCalculation';
import FamilyProtectionStore from '../../../store/FamilyProtectionStore';
import FamilyDetailsStore from '../../../../family-details-form/store/FamilyDetailsStore';
import DeathCoverGraphSlider from '../../family-protection-card/DeathCoverGraphSlider';
import ChangeProductModal from '../../../components/change-product-modal/ChangeProductModal';
import ChangeProductReasonModal from '../../../components/change-product-reason-modal/ChangeProductReasonModal';
import FundDistributionModal from './FundDistributionModal';

import './../../save-my-children/investment-strategy.css'
import '../../save-my-children/save-my-children.css'

const WealthUltimaProductCard = observer(class WealthUltimaProductCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 'high',
            productID: '40009',
            selectedAdditionalCardValue: {},
            selectedAdditionalRiderCardValue: {},
            fundList: [
                {
                    id: 'EquityLargeCapFund',
                    label: 'Equity Large Cap Fund',
                    checkedValue: false,
                    key: 'largeCap',
                    value: 0
                },
                {
                    id: 'EquityTop250Fund',
                    label: 'Equity Top 250 Fund',
                    checkedValue: false,
                    key: 'top250',
                    value: 0
                },
                {
                    id: 'EquityMidCapFund',
                    label: 'Equity Mid-Cap Fund',
                    checkedValue: false,
                    key: 'midCap',
                    value: 0
                },
                {
                    id: 'EquityBlueChipFund',
                    label: 'Equity Blue Chip Fund',
                    checkedValue: false,
                    key: 'blueChip',
                    value: 0
                },
                {
                    id: 'GILTFund',
                    label: 'GILT Fund',
                    checkedValue: false,
                    key: 'glitFund',
                    value: 0
                },
                {
                    id: 'BondFund',
                    label: 'Bond Fund',
                    checkedValue: false,
                    key: 'bondFund',
                    value: 0
                },
                {
                    id: 'ManagedFund',
                    label: 'Managed Fund',
                    checkedValue: false,
                    key: 'managedFund',
                    value: 0
                }
            ],
            disabled: false,
            changeProductReasonModal: false,
            changeProductModal: false,
            selectdReason: {},
            selectedCollapseKey: [0],
            showOverlay: false,
            showInsuredMemberModal: false,
            showAddFamilyMemberModal: false,
            isReason: false,
            ppt: {},
            pt: {},
            ptList: [],
            pptList: [],
            ppfList: [
                {
                    id: 1,
                    label: 'Annual'
                }, {
                    id: 2,
                    label: 'Semi-Annual'
                }, {
                    id: 3,
                    label: 'Quarterly'
                }, {
                    id: 4,
                    label: 'Monthly'
                }
            ],
            ppf: {},
            investFor: {
                id: 1,
                label: 'Myself'
            },
            swp: {
                id: 6,
                label: 'No'
            },
            swpList: [{
                id: 5,
                label: 'Yes'
            },
            {
                id: 6,
                label: 'No'
            }
            ],
            isSwpDisable: false,
            showInvestForChild: false,
            isInvestFor: false,
            name: '',
            dob: '',
            type: '',
            showNameError: false,
            showDobError: false,
            dobErrorMsg: '',
            fundWithdrawlPercentage: '',
            fundWithdrawlPercentageList: this.populateWithdrawlPercentage(),
            fundWithdrawlyear: '',
            fundWithdrawlYearList: this.populateWithdrawlyear(),
            fundWithdrawlFrequencyList: [
                {
                    id: '117',
                    label: 'Annual'
                },
                {
                    id: '118',
                    label: 'Semi-Annual'
                },
                {
                    id: '119',
                    label: 'Quarterly'
                },
                {
                    id: '120',
                    label: 'Monthly'
                }
            ],
            fundWithdrawlFrequency: '',
            investmentStratergy: '',
            investmentStratergyList: [
                {
                    id: 1,
                    label: 'Self-Managed'
                },
                {
                    id: 2,
                    label: 'Life Stage & Duration'
                },
                {
                    id: 3,
                    label: 'Based Strategy or Profit Based Strategy'
                }
            ],
            largeCapFundValue: '',
            top250Value: '',
            bondFundValue: '',
            midCapValue: '',
            managedFundValue: '',
            glitFundValue: '',
            blueChipValue: '',
            additional_benefits_list: [],
            showFundDistributionModal: false,
            hideBenefitSection: false,
            premiumAmount: '10000',
            changeProductReasonModal: false,
            changeProductModal: false,
            selectdReason: {},
            littleChamp: {
                id: '4',
                label: 'No'
            },
            littleChampBenefit: [{
                id: '3',
                label: 'Yes'
            }, {
                id: '4',
                label: 'No'
            }],
            reqParams: this.getReqParams(),
            disableSwp: false,
            disableBenefit: false
        }
        this.investForStatus = this.investForStatus.bind(this)
        this.onInvestForUpdate = this.onInvestForUpdate.bind(this)
        this.submitInvestForDetails = this.submitInvestForDetails.bind(this)
        this.onFundWithdrawlYearChange = this.onFundWithdrawlYearChange.bind(this)
        this.onFundWithdrawlFrquencyChange = this.onFundWithdrawlFrquencyChange.bind(this)
        this.onWithdrawlPercentageChange = this.onWithdrawlPercentageChange.bind(this)
        this.onInvestmentStratergyChange = this.onInvestmentStratergyChange.bind(this)
        this.onFundValueChange = this.onFundValueChange.bind(this)
        this.openFundDistributionModal = this.openFundDistributionModal.bind(this)
        this.onSetDistribution = this.onSetDistribution.bind(this)

    }

    getReqParams = () => {
        return {
            'AGENT_ID': 'TW9001',
            'BondFund': '0',
            'CashFlow': 'Y',
            'category': '11',
            'COMPANY_STATE': '0',
            'emr_rate': '1.00',
            'EquityBlueChipFund': '0',
            'EquityLargeCapFund': '100',
            'EquityMidCapFund': '0',
            'EquityTop250Fund': '0',
            'flat_rate': '0',
            'FUNDSTRATEGYID': '1',
            'GILTFund': '0',
            'GSTIN': '0',
            'GSTIN_Number': '0',
            'INPUT_MODE': '4',
            'INTEREST_RATES': '0.1337121745330434',
            'IS_STAFF': '2',
            'isPdfByte': 'Y',
            'LI_DOB': '11 Nov 1991',
            'LI_EMAILID': 'sdafg@asdgf.in',
            'LI_ENTRY_AGE': 29,
            'LI_GENDER': 'M',
            'LI_MOBILENO': '8547102398',
            'LI_NAME': 'f f',
            'LI_STATE': '0',
            'ManagedFund': '0',
            'MaxType': '1',
            'NSAP_FLAG': '0',
            'optionId1': '2',
            'optionId2': '4',
            'optionId3': '6',
            'optionId4': '',
            'optionId5': '',
            'optionId6': '121',
            'optionValue1': '',
            'optionValue2': '',
            'optionValue3': '',
            'optionValue4': '',
            'optionValue5': '',
            'optionValue6': '',
            'PR_ANNPREM': '120000',
            // 'PR_ANNPREM': '72000',
            'PR_CHANNEL': '1',
            'PR_ID': '40009',
            'PR_MI': '0',
            'PR_ModalPrem': '',
            'PR_PPT': 7,
            'PR_PT': '30',
            'PR_SA': '1800000',
            // 'PR_SA': '1476000',
            'PR_SAMF': '0',
            'ProductType': 'ULIP',
            'PROPOSER_AGE': '',
            'PROPOSER_DOB': '11 Nov 1990',
            'PROPOSER_GENDER': 'M',
            'PROPOSER_NAME': 'f f',
            'REQUESTSOURCE': '1',
            'ResponseType': 'object',
            'SameProposer': true
        }
    }

    generatePt(userAge) {
        const maximumAge = 70
        let ptValues = []

        // let userAge = this.calculateUserAge(dob)
        let dropDownValues = maximumAge - userAge

        for (let i = 10; i <= dropDownValues; i++) {
            ptValues.push({
                'id': i,
                'label': i
            });
        }

        ptValues.push({
            'id': 100 - userAge,
            'label': (100 - userAge) + ' (Upto age 100)'
        })
        return ptValues.reverse()
    }

    generatePPt(maxPtValue) {

        let minPPT = 100 - Number(this.state.reqParams.LI_ENTRY_AGE) == maxPtValue.id ? 7 : 5

        let maxPPt = maxPtValue.id > 30 ? 30 : maxPtValue.id
        let pptValues = []

        for (let i = minPPT; i <= maxPPt; i++) {
            pptValues.push({
                'id': i,
                'label': i
            });
        }

        if (maxPtValue.id > 30) {
            pptValues.push({
                'id': maxPtValue.id,
                'label': maxPtValue.id + ' (regular pay)'
            })
        }

        return pptValues

    }

    calculateMaxPt(dob) {
        const maximumAge = 70

        let userAge = this.calculateUserAge(dob)
        return maximumAge - userAge

    }

    calculateUserAge(dob) {
        let userYear = moment(dob, 'M MMM YYYY').year()
        let currentYear = moment().year()
        let userAge = currentYear - userYear
        return userAge
    }

    handlePptBasedOnPt(ptValue) {
        const maximumAllowedValue = 30
        let ppt = []

        let initialValue = 5;
        let maximumValue = maximumAllowedValue;

        if (ptValue < maximumAllowedValue) {
            maximumValue = ptValue
        } else {
            initialValue = 7
        }

        for (let i = initialValue; i <= maximumValue; i++) {
            ppt.push({
                'id': i,
                'label': i
            })
        }

        if (ptValue > maximumAllowedValue) {
            ppt.push({
                id: ptValue,
                label: ptValue + ' (regular pay)'
            })
        }

        this.setState({
            pptList: ppt
        })


    }

    onAddInsured = (e) => {
        e.stopPropagation();
        this.setState({ showInsuredMemberModal: true })
    }
    onPtChange = (value) => {
        const { ppf } = this.state

        let frequency = ''
        if (ppf.id == 1) {
            frequency = 1
        } else if (ppf.id == 2) {
            frequency = 2
        } else if (ppf.id == 3) {
            frequency = 4
        } else if (ppf.id == 4) {
            frequency = 12
        }

        let pr_sa = ''
        let premiumAmount = Number(frequency) * Number(this.state.premiumAmount)

        if (this.props.data.age <= 44) {
            let ptValue = value.id / 2
            pr_sa = ptValue > 10 ? ptValue * premiumAmount : 10 * premiumAmount
        } else {
            let ptValue = value.id / 4
            pr_sa = ptValue > 7 ? ptValue * premiumAmount : 7 * premiumAmount
        }
        let maxPPt = 70 - this.state.reqParams.LI_ENTRY_AGE

        let isOpion1 = maxPPt > value.id

        let dummyPPTList = this.generatePPt(value);

        let pptData = dummyPPTList.filter((item) => item.id == value.id)[0]
        let ppt = ''
        if (pptData.id > 30) {
            ppt = {
                id: 30,
                label: '30'
            }
        } else {
            ppt = pptData
        }
        this.state.reqParams.PR_PPT = value.id;
        this.state.reqParams.optionId1 = isOpion1 ? '1' : '2'
        this.state.reqParams.PR_ANNPREM = '' + premiumAmount
        this.state.reqParams.PR_SA = '' + pr_sa


        this.dataChange('pt', value.id)
        this.setState({ pt: value, ppt: ppt })

        this.handlePptBasedOnPt(value.id)

    }
    onPPTChangeOptions = (value) => {
        this.setState({ ppt: value })
        this.dataChange('ppt', value.id)
    }
    onPpfChange = (value) => {
        let frequency = ''
        if (value.id == 1) {
            frequency = 1
        } else if (value.id == 2) {
            frequency = 2
        } else if (value.id == 3) {
            frequency = 4
        } else if (value.id == 4) {
            frequency = 12
        }

        let pr_sa = ''
        let premiumAmount = Number(frequency) * Number(this.state.premiumAmount)

        if (this.props.data.age <= 44) {
            let ptValue = this.state.pt.id / 2
            pr_sa = ptValue > 10 ? ptValue * premiumAmount : 10 * premiumAmount
        } else {
            let ptValue = this.state.pt.id / 4
            pr_sa = ptValue > 7 ? ptValue * premiumAmount : 7 * premiumAmount
        }

        this.state.reqParams.PR_ANNPREM = '' + premiumAmount
        this.state.reqParams.PR_SA = '' + pr_sa

        this.dataChange('ppf', value.id)
        // this.dataChange('premium', this.state.reqParams.PR_ANNPREM)
        this.setState({ ppf: value })

    }

    onSwpChange = (value) => {
        this.state.reqParams.optionId4 = '0.3'
        this.state.reqParams.optionId5 = '117'
        this.state.reqParams.optionValue6 = '10'
        this.dataChange('swp', value.id)
        this.setState({ swp: value })
    }

    onInvestingForChange = (value) => {
        this.setState({
            investFor: value,
            type: '',
            name: '',
            dob: '',
            showNameError: false,
            showDobError: false
        })
        if (value.id == 2) {
            this.setState({
                showInvestForChild: true
            })
        } else {
            this.setState({
                showInvestForChild: false
            })
        }
        if (value.id == 1) {
            this.dataChange('investForSelf', value.id)
        }
    }
    investForStatus(type) {
        this.isInvestFor = !this.isInvestFor
        this.setState({
            isInvestFor: !this.isInvestFor,
            type: type,
            name: '',
            dob: '',
            showNameError: false,
            showDobError: false
        })
    }
    onInvestForUpdate(key, value) {
        if (key == 'name') {
            this.setState({ name: value, showNameError: false })
        } else {
            const date = value == '' ? FamilyDetailsStore.dateValue : value
            this.setState({ dob: date, showDobError: false })
        }

    }
    submitInvestForDetails() {
        var years = moment().diff(moment(this.state.dob).format('YYYY-MM-DD'), 'years', false)
        if (this.state.name == '') {
            this.setState({
                showNameError: true
            })
        }

        if (this.state.dob == '') {
            this.setState({
                showDobError: true,
                dobErrorMsg: 'Select a value'
            })
        } else if (years > 17) {
            this.setState({
                showDobError: true,
                dobErrorMsg: 'Age should be between 0 to 17.'
            })
        }

        if (this.state.name !== '' && this.state.dob !== '' && years <= 17) {
            var dateFormat = moment(this.state.dob).format('DD MMM YYYY')
            const data = {
                'type': this.state.type == 'son' ? 'M' : 'F',
                'name': this.state.name,
                'dob': dateFormat,
                'age': years
            }
            this.dataChange('investForFamily', data)
        }
    }
    hideOverLay = () => {
        setTimeout(() => {
            this.setState({ showOverlay: false })
        }, 2000)
    }
    // single option
    onAdditionalRiderCardOptionSelect = (checkedValue, selectedObj, data) => {
        const selectedOptionObj = this.state.selectedAdditionalRiderCardValue;

        selectedOptionObj[selectedObj.id] = checkedValue;

        this.setState({
            selectedAdditionalRiderCardValue: selectedOptionObj
        });

        const dataList = toJS(FamilyProtectionStore.dummy_product_list_data);
        let dataObject = {};
        dataList.map((additionalDetails) => {
            if (additionalDetails.id == data.id) {
                additionalDetails.rider_list.map((item) => {
                    if (item.id == selectedObj.id) {
                        item.is_selected = checkedValue;
                    }
                    dataObject = additionalDetails;
                })
            }
        })
        FamilyProtectionStore.updateDetails(dataObject);
    }
    onSetDistribution = (fundList) => {
        fundList.forEach(fundList => {
            fundList.value = fundList.value || 0
            this.dataChange(fundList.key, fundList.value, '', true)
        });
        this.dataChange('', '', '', false).then(() => {
            this.openFundDistributionModal(false)
        })
    }
    componentDidMount() {
        let hideBenefitSection = this.props.data.family_member_type == 'Child';
        let isChildSelected = false;

        let isSwpDisable = false
        let littleChamp = {}

        let data = this.getParseBIData()

        this.state.reqParams = { ...data.bi_request }
        this.updateTargetValue(data.BI)

        if (this.props.data.family_member_type == 'Child' && this.props.data.age < 18) {
            if (this.props.data.product_name == 'Little Champ (Wealth Ultima)' && this.props.cardKey == 'save_for_children') {
                isChildSelected = true
                littleChamp = {
                    id: '3',
                    label: 'Yes'
                };
            } else {
                isChildSelected = false
                littleChamp = {
                    id: '4',
                    label: 'No'
                };
            }

        } else if (this.props.data.family_member_type == 'Child' && this.props.data.age > 18) {
            isChildSelected = true,
                littleChamp = {
                    id: '4',
                    label: 'No'
                }
        }

        if (this.props.data.product_name == 'Systematic Withdrawal Plan (Wealth Ultima)') {
            let swp = { id: 5, label: 'Yes' }
            let fundWithdrawlPercentageList = this.populateWithdrawlPercentage()
            let fundWithdrawlPercentage = fundWithdrawlPercentageList.filter(obj => obj.id == data.bi_request.optionId4)[0] || this.state.fundWithdrawlPercentage

            let fundWithdrawlYearList = this.populateWithdrawlyear();

            let fundWithdrawlyear = fundWithdrawlYearList.filter(obj => obj.id == data.bi_request.optionValue6)[0] || this.state.fundWithdrawlyear

            let fundWithdrawlFrequency = this.state.fundWithdrawlFrequencyList.filter(obj => obj.id == data.bi_request.optionId5)[0] || this.state.fundWithdrawlFrequency

            this.setState({
                swp: swp,
                isSwpDisable: true,
                disableSwp: true,
                fundWithdrawlPercentage: fundWithdrawlPercentage,
                fundWithdrawlyear: fundWithdrawlyear,
                fundWithdrawlFrequency: fundWithdrawlFrequency
            })
        }


        this.setState({
            selectdReason: FamilyProtectionStore.productReasonList[0],
            hideBenefitSection: hideBenefitSection,
            disableBenefit: isChildSelected,
            isSwpDisable: isSwpDisable,
            isLoading: true,
            littleChamp: littleChamp
        })
        this.prefieldData();
    }

    prefieldData = () => {
        try {
            const bi_request = this.state.reqParams
            if (bi_request) {
                let {
                    ppf,
                    ppfList,
                    investmentStratergy,
                    investmentStratergyList,
                    ppt,
                    pt
                } = this.state;

                let ptList = this.generatePt(bi_request.LI_ENTRY_AGE)
                pt = ptList.filter(obj => obj.id == bi_request.PR_PT)[0] || pt
                let pptList = this.generatePPt(pt)
                ppt = pptList.filter(obj => obj.id == bi_request.PR_PPT)[0] || ppt

                ppf = ppfList.filter(obj => obj.id == parseInt(bi_request.INPUT_MODE))[0] || ppf
                investmentStratergy = investmentStratergyList.filter(obj => obj.id == parseInt(bi_request.FUNDSTRATEGYID))[0] || investmentStratergy

                this.setState({ ppf, investmentStratergy, pt, ppt })
            }
        } catch (error) {
            console.error('PREFIELD DATA ERROR >> ', error)
        }
    }

    onRecalculateButtonClick = () => {
        let amount = Number(this.state.premiumAmount)
        let premiumAmount = ''
        let inputMode = parseInt(this.state.reqParams.INPUT_MODE)
        switch (inputMode) {
            case 1:
                premiumAmount = '' + amount * 1
                break;
            case 2:
                premiumAmount = '' + amount * 2
                break;
            case 3:
                premiumAmount = '' + amount * 4
                break;
            case 4:
                premiumAmount = '' + amount * 12
                break;
        }

        let pr_sa = ''
        if (this.props.data.age <= 44) {
            let ptValue = this.state.pt.id / 2
            pr_sa = ptValue > 10 ? ptValue * premiumAmount : 10 * premiumAmount
        } else {
            let ptValue = this.state.pt.id / 4
            pr_sa = ptValue > 7 ? ptValue * premiumAmount : 7 * premiumAmount
        }

        this.state.reqParams.PR_SA = '' + pr_sa
        this.dataChange('premium', premiumAmount)
    }


    populateWithdrawlPercentage() {
        let withdrawlPercentage = []
        for (let i = 1; i <= 120; i++) {
            withdrawlPercentage.push({
                id: (i / 10),
                label: parseFloat(i / 10).toFixed(2) + ' %'
            })
        }

        return withdrawlPercentage;
    }

    onWithdrawlPercentageChange(value) {
        this.dataChange('withdrawPercentage', value.id)
        this.setState({ fundWithdrawlPercentage: value })
    }

    onInvestmentStratergyChange(value) {
        this.dataChange('investmentStratergy', value.id)
        this.state.fundList.forEach(fundList => {
            fundList.value = 0
            this.dataChange(fundList.key, fundList.value, '', true)
        });
        this.setState({ investmentStratergy: value })
    }

    onFundWithdrawlFrquencyChange(value) {
        this.dataChange('withdrawFrequency', value.id)
        this.setState({ fundWithdrawlFrequency: value })
    }

    populateWithdrawlyear() {
        let withdrawlYear = []
        for (let i = 10; i < 67; i++) {
            withdrawlYear.push({
                id: i,
                label: i + ' year'
            })
        }
        return withdrawlYear;
    }

    onFundWithdrawlYearChange(value) {
        this.dataChange('withdrawYear', value.id)
        this.setState({ fundWithdrawlyear: value })
    }

    onFundValueChange(event, fundName) {
        let value = event.target.value;

        switch (fundName) {
            case 'large_cap':
                this.dataChange('largeCap', value)
                this.setState({
                    largeCapFundValue: value
                })
                break;
            case 'top_250':
                this.dataChange('top250', value)
                this.setState({
                    top250Value: value
                })
                break;
            case 'bond_fund':
                this.dataChange('bondFund', value)
                this.setState({
                    bondFundValue: value
                })
                break;
            case 'mid_cap':
                this.dataChange('midCap', value)
                this.setState({
                    midCapValue: value
                })
                break;
            case 'managed_fund':
                this.dataChange('managedFund', value)
                this.setState({
                    managedFundValue: value
                })
                break;
            case 'gilt_fund':
                this.dataChange('glitFund', value)
                this.setState({
                    glitFundValue: value
                })
                break;
            case 'blue_chip':
                this.dataChange('blueChip', value)
                this.setState({
                    blueChipValue: value
                })
                break;
        }
    }


    onPremiumChange = (e) => {
        const data = this.props.data
        let amount = e.target.value
        console.log('PREMIUM AMOUNT >> ', amount)
        data.premium_amount = amount;
        this.setState({
            premiumAmount: amount
        })
        // FamilyProtectionStore.updateProductsDetails(this.props.cardKey, data);

        // _.debounce(() => {
        //     console.log('DEBOUNCE CALLED >> ', amount)
        //     // this.dataChange('sumAssured', amount, '', this.props.productID)
        // }, 2000);

        // apiCalled()
    }

    openFundDistributionModal = (status) => {
        status = status == undefined ? true : status
        this.setState({ showFundDistributionModal: status });
    }

    onAdditionalCardOptionSelect = async (checkedValue, selectedObj) => {
        this.setState({ littleChamp: checkedValue })

        if (checkedValue.id == '3') {
            this.dataChange('littleChampBenefit', '3')
        } else {
            this.dataChange('littleChampBenefit', '4')
        }

    }

    onChangeProductReasonModalOnShow = () => {
        this.setState({ changeProductReasonModal: true })
    }

    onChangeProductReasonModalOnHide = () => {
        this.setState({ changeProductReasonModal: false })
    }
    onChangeProductModalOnShow = () => {
        this.setState({ changeProductModal: true, changeProductReasonModal: false })
    }

    onChangeProductModalOnHide = () => {
        this.setState({ changeProductModal: false })
    }
    onChangeReason = (e) => {
        this.setState({ selectdReason: e });
    }

    async dataChange(paramName, paramValue, productId, stopAPICall) {
        // console.log('Product id is ', paramName)
        // console.log('Name is ', paramName)
        // console.log('Value is ', paramValue)

        switch (paramName) {
            case 'ppt':
                this.state.reqParams.PR_PPT = '' + paramValue
                break;

            case 'pt':
                this.state.reqParams.PR_PT = '' + paramValue
                break;

            case 'ppf':
                this.state.reqParams.INPUT_MODE = '' + paramValue
                break;

            case 'familyIncomeBenefit':
                this.state.reqParams.FamilyIncomeBenefit = '' + paramValue
                break;

            case 'incomeOption':
                this.state.reqParams.IncomeOption = '' + paramValue
                break;

            case 'guaranteedIncomeType':
                this.state.reqParams.GuaranteedIncomeType = '' + paramValue
                break;

            case 'premium':
                let inputMode = this.state.reqParams.INPUT_MODE;
                let frequency = ''
                if (inputMode == 1) {
                    frequency = 1
                } else if (inputMode == 2) {
                    frequency = 2
                } else if (inputMode == 3) {
                    frequency = 4
                } else if (inputMode == 4) {
                    frequency = 12
                }

                let pr_sa = ''
                let premiumAmount = Number(frequency) * Number(paramValue)

                let ptValue = Number(this.state.reqParams.PR_PT) / 2

                pr_sa = ptValue > 10 ? ptValue * premiumAmount : 10 * premiumAmount

                this.state.reqParams.PR_ANNPREM = '' + premiumAmount
                this.state.reqParams.PR_SA = '' + pr_sa
                break;

            case 'investForFamily':
                this.state.reqParams.LI_DOB = '' + paramValue.dob
                this.state.reqParams.LI_ENTRY_AGE = '' + paramValue.age
                this.state.reqParams.LI_GENDER = '' + paramValue.type
                this.state.reqParams.LI_NAME = '' + paramValue.name
                break;

            case 'investForSelf':
                this.state.reqParams.LI_DOB = this.state.reqParams.PROPOSER_DOB
                this.state.reqParams.LI_ENTRY_AGE = this.state.reqParams.PROPOSER_AGE
                this.state.reqParams.LI_GENDER = this.state.reqParams.PROPOSER_GENDER
                this.state.reqParams.LI_NAME = this.state.reqParams.PROPOSER_NAME
                break;

            case 'swp':
                this.state.reqParams.optionId3 = paramValue.toString()
                if (paramValue == 6) {
                    this.state.reqParams.optionId4 = ''
                    this.state.reqParams.optionId5 = ''
                    this.state.reqParams.optionValue6 = ''
                }
                break;

            case 'withdrawPercentage':
                this.state.reqParams.optionId4 = paramValue.toString()
                break;

            case 'withdrawFrequency':
                this.state.reqParams.optionId5 = paramValue.toString()
                break;

            case 'withdrawYear':
                this.state.reqParams.optionId6 = '121'
                this.state.reqParams.optionValue6 = paramValue.toString()
                break;

            case 'investmentStratergy':
                this.state.reqParams.FUNDSTRATEGYID = paramValue;
                break

            case 'littleChampBenefit':
                this.state.reqParams.optionId2 = paramValue;
                break;

            case 'largeCap':
                this.state.reqParams.EquityLargeCapFund = paramValue;
                break;
            case 'top250':
                this.state.reqParams.EquityTop250Fund = paramValue;
                break;
            case 'bondFund':
                this.state.reqParams.BondFund = paramValue;
                break;
            case 'midCap':
                this.state.reqParams.EquityMidCapFund = paramValue;
                break;
            case 'managedFund':
                this.state.reqParams.ManagedFund = paramValue;
                break;
            case 'glitFund':
                this.state.reqParams.GILTFund = paramValue;
                break;
            case 'blueChip':
                this.state.reqParams.EquityBlueChipFund = paramValue;
                break;

        }
        console.log('DATA CHANGED >> ', paramValue, stopAPICall)
        if (stopAPICall) {
            return
        }
        this.fetchData()
    }

    fetchData = async () => {
        try {
            global.loading(true)
            const response = await FamilyProtectionStore.fetchWealthUltima(this.state.reqParams)
            console.log('WEALTH ULTIMA API CALLED >> ')

            let data = JSON.parse(response.JsonOutput);

            if (data.Status == 'Fail') {
                FamilyProtectionStore.showErrorMsg(data)
            }

            let biJson = JSON.parse(data.BIJson2);
            data = JSON.parse(data.BIJson);
            console.log('WEALTH ULTIMA RESPONSE >> ', data[data.length - 1], biJson[data.length - 1])
            data = data[data.length - 1]
            data.BI2 = biJson[biJson.length - 1]

            this.updateTargetValue(data)

            let dataObj = JSON.parse(JSON.stringify(this.props.data));
            let biUpdatedData = dataObj.BI.map(obj => {
                if (obj.risk == this.props.tab) {
                    obj.BI = response
                }
                return obj
            })

            FamilyProtectionStore.updateBIResponseToData(this.props.cardKey, this.props.data.product_id, biUpdatedData)

        } catch (error) {
            console.log('Error is ', error)
        } finally {
            global.loading(false)
        }
    }

    updateTargetValue = (data) => {
        if (this.props.onValueUpdate) {
            const targetAmount = this.state.reqParams.investment_target

            const ppfList = toJS(FamilyProtectionStore.ppfList);
            let frequency = ppfList.filter(obj => obj.id == parseInt(this.state.reqParams.INPUT_MODE))[0]

            let price = this.state.reqParams.PR_ANNPREM

            this.props.onValueUpdate({ targetAmount, frequency, price })
        }
    }

    getParseBIData = () => {
        let data = JSON.parse(JSON.stringify(this.props.data));
        let BI = data.BI
        try {
            if (BI && BI.JsonOutput) {
                BI = JSON.parse(BI.JsonOutput);
                let BI2 = JSON.parse(BI.BIJson2);
                BI = JSON.parse(BI.BIJson);
                if (BI) {
                    BI = BI[BI.length - 1]
                    BI.BI2 = BI2[BI2.length - 1]
                }
            } else {
                BI = {}
            }
        } catch (error) {
            console.log('ERROR >> ', error)
            BI = {}
        }
        if (!BI) {
            BI = {}
        }
        // console.log('GET PARSE BI DATA >> ', BI)
        data.BI = BI
        return data
    }

     getTotalPrice = () => {
        let totalPremium = this.state.reqParams.PR_ANNPREM;
        const {tab, selectedTab} = this.props;
        if (tab && selectedTab) {
            return tab != selectedTab ? 0 : totalPremium
        }

        return totalPremium;
    }


    render() {
        // const investmentTarget = FamilyProtectionStore.wealthUltimaResponseBIJson2.SA;
        const { showFundDistributionModal } = this.state;
        const data = this.getParseBIData()
        // console.log('WEALTH ULTIMA RENDER >> ', data, data.BI.SURRENDER_BENEFIT)
        return (
            <div className='save-my-children'>
                <React.Fragment>
                    <div className='text-description-text-color font-size-12 font-size-md-18 font-size-lg-20 mt-2'>
                        {/* Underlying product : Wealth Ultima <UiButton variant='' onClick={this.onChangeProductReasonModalOnShow} buttonText='[Change]' className='px-2 py-1 font-size-12 text-primary font-size-md-16 font-weight-medium cursor-pointer' /> */}
                                Underlying Product : {this.props.data.product_name}
                    </div>

                    <ChangeProductReasonModal
                        selectedReasonVal={this.state.selectdReason}
                        show={this.state.changeProductReasonModal}
                        onSelectReason={this.onChangeReason}
                        onHide={this.onChangeProductReasonModalOnHide}
                        list={FamilyProtectionStore.productReasonList}
                        onSelectProductModalbtnClick={this.onChangeProductModalOnShow}
                    // isReason={props.isReason}
                    />
                    <ChangeProductModal
                        show={this.state.changeProductModal}
                        onSelectReason={() => { }}
                        onHide={this.onChangeProductModalOnHide}
                        list={FamilyProtectionStore.productList}
                    />
                    <Row>
                        <Col>
                            <DeathCoverGraphSlider
                                income_value={data.annual_price}
                                premium_value={this.state.premiumAmount}
                                show_shadow={true}
                                productData={this.props.data}
                                showOverlay={this.state.showOverlay}
                                onClick={this.onRecalculateButtonClick}
                                data={data}
                                openChangeReasonModal={this.onChangeProductReasonModalOnShow}
                                hideOverLay={this.hideOverLay}
                                onPremiumChange={this.onPremiumChange}
                                isIncomeFieldDisable={true}
                                productID={this.state.productID}
                                showReasonModal={this.state.changeProductReasonModal}
                                premiumFreq={this.state.ppf.label}
                            />
                        </Col>
                    </Row>

                    <Row className='mt-2 mt-md-4'>
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Policy Term</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Policy Term'
                                    menuList={this.generatePt(this.state.reqParams.LI_ENTRY_AGE)}
                                    value={this.state.pt}
                                    onSelect={this.onPtChange}
                                // showErrorMsg={FamilyProtectionStore.isChildPolicyTerm}
                                />
                            </div>
                        </Col>
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Premium Paying Term</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Premium Paying Term'
                                    menuList={this.generatePPt(this.state.pt)}
                                    value={this.state.ppt}
                                    onSelect={this.onPPTChangeOptions}
                                // showErrorMsg={FamilyProtectionStore.isChildPolicyTerm}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row className='mt-2 mt-md-4'>
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Frequency</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Select Frequency'
                                    onSelect={this.onPpfChange}
                                    menuList={FamilyProtectionStore.ppfList}
                                    value={this.state.ppf}
                                // showErrorMsg={FamilyProtectionStore.isChildPremiumTerm}
                                />
                            </div>
                        </Col>
                        {/* <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                        <div className='mb-2 text-description-text-color font-size-14'>Investing For</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Select Investing For'
                                            onSelect={this.onInvestingForChange}
                                            menuList={FamilyProtectionStore.investingForList}
                                            value={this.state.investFor}
                                        // showErrorMsg={FamilyProtectionStore.isChildPremiumTerm}
                                        />
                                    </div>
                                </Col> */}
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Investment Stratergy</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Investment Stratergy'
                                    menuList={this.state.investmentStratergyList}
                                    value={this.state.investmentStratergy}
                                    onSelect={this.onInvestmentStratergyChange}
                                />
                            </div>
                            {this.state.investmentStratergy.id == 1 && <React.Fragment>
                                <div>
                                    <span>Fund Distribution</span><span className="a cursor-pointer" onClick={this.openFundDistributionModal}> Click to View</span>
                                </div>
                            </React.Fragment>
                            }
                        </Col>
                    </Row>
                    {this.state.showInvestForChild &&
                        <Row className='mt-2 mt-md-4'>
                            <Col md={12} className='col-12'>
                                <span className='mr-3 font-size-16 font-size-md-18 font-size-lg-22 text-description-text-color'>Investing For</span>
                                <div className='d-flex flex-md-column align-items-center align-items-md-start w-100'>
                                    <span className='mr-3 font-size-12 font-size-md-16 font-size-lg-20 text-description-text-color mt-3'>Who do you want to invest for?</span>
                                    <div className='mt-3'>
                                        <CustomCheckbox className='mr-2' isChecked={this.isInvestFor} onChange={() => this.investForStatus('son')} titleStyle='font-size-16 font-size-md-20 font-size-lg-24' shape='rounded' title='Son' mark='checkmark' />
                                        <CustomCheckbox className='ml-2' isChecked={!this.isInvestFor} onChange={() => this.investForStatus('daughter')} titleStyle='font-size-16 font-size-md-20 font-size-lg-24' shape='rounded' title='Daughter' mark='checkmark' />
                                    </div>
                                </div>
                                <Row className='mt-2 mt-md-4'>
                                    <Col md={6} className='col-12'>
                                        <Form.Group className='mb-0 textfield-border' controlId='exampleForm.ControlInput1'>
                                            <Form.Control
                                                required
                                                placeholder='Enter Name'
                                                value={this.state.name}
                                                // onChange={(e) => onUpdate(member, 'name', e.target.value)}
                                                onChange={e => {
                                                    let value = e.target.value
                                                    let regex = /^[a-zA-Z\s]*$/
                                                    if (regex.test(value)) {
                                                        this.onInvestForUpdate('name', value)
                                                    }
                                                }}
                                                className={`${this.state.showNameError ? 'validation-border' : ''}`}
                                            />
                                        </Form.Group>
                                        {this.state.showNameError && <p className='text-danger mb-0 font-weight-medium font-size-14 align-items-start d-flex'>Enter Name</p>}
                                    </Col>
                                    <Col md={6} className='col-12 mt-3 mt-md-0'>
                                        <DatepickerModal
                                            date={this.state.dob}
                                            textStyle='font-size-14 font-size-md-20 font-weight-normal text-nowrap overflow-hidden txt-overflow'
                                            onChange={(value) => this.onInvestForUpdate('dob', value)}
                                            showErrorMsg={this.state.showDobError}
                                            errorMsg={this.state.dobErrorMsg}
                                        />
                                    </Col>
                                </Row>
                                <UiButton
                                    className='font-size-14 font-size-md-20 py-1 px-4 mt-3'
                                    buttonText='Submit'
                                    variant='secondary'
                                    onClick={this.submitInvestForDetails}
                                />
                            </Col>

                        </Row>
                    }

                    <Row className='mt-2 mt-md-4'>
                        <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>SWP</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='SWP'
                                    menuList={this.state.swpList}
                                    value={this.state.swp}
                                    onSelect={this.onSwpChange}
                                    disabled={this.state.disableSwp}
                                />
                            </div>
                        </Col>
                        {this.state.hideBenefitSection && <Col md={6} className='col-12'>
                            <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                <div className='mb-2 text-description-text-color font-size-14'>Little Champ Benefit</div>
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey='label'
                                    bottomsheetTitle='Little Champ'
                                    menuList={this.state.littleChampBenefit}
                                    value={this.state.littleChamp}
                                    onSelect={this.onAdditionalCardOptionSelect}
                                    disabled={this.state.disableBenefit}
                                />
                            </div>
                        </Col>}

                        {this.state.swp.id == 5 &&
                            <React.Fragment>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                        <div className='mb-2 text-description-text-color font-size-14'>Withdrawl %</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Select Withdrawl %'
                                            onSelect={this.onWithdrawlPercentageChange}
                                            menuList={this.state.fundWithdrawlPercentageList}
                                            value={this.state.fundWithdrawlPercentage}
                                        // showErrorMsg={FamilyProtectionStore.isChildPremiumTerm}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                        <div className='mb-2 text-description-text-color font-size-14'>Withdrawl Year</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Select Withdrawl Year'
                                            onSelect={this.onFundWithdrawlYearChange}
                                            menuList={this.state.fundWithdrawlYearList}
                                            value={this.state.fundWithdrawlyear}
                                        // showErrorMsg={FamilyProtectionStore.isChildPremiumTerm}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded mt-4 mb-3'>
                                        <div className='mb-2 text-description-text-color font-size-14'>Fund Withdrawl Frequency</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Select Fund Withdrawl Frequency'
                                            onSelect={this.onFundWithdrawlFrquencyChange}
                                            menuList={this.state.fundWithdrawlFrequencyList}
                                            value={this.state.fundWithdrawlFrequency}
                                        // showErrorMsg={FamilyProtectionStore.isChildPremiumTerm}
                                        />
                                    </div>
                                </Col>
                            </React.Fragment>
                        }


                    </Row>

                    {/* {!this.state.hideBenefitSection && <Row className='mt-4'>
                                <Col md={6} className='col-12'>
                                    <AdditionalCards
                                        productID={this.state.productID}
                                        cardsList={FamilyProtectionStore.wealthUltimaBenefits}
                                        cardTopText='Additional Benefits'
                                        onClick={this.onAdditionalCardOptionSelect}
                                        // checkedValue={}
                                        data={data} />
                                </Col>
                            </Row>} */}
                    {/* <AdditionalRiderBenifitsCard productID={this.state.productID} cardsList={addRiders} cardTopText='Additional Rider' onClick={this.onAdditionalRiderCardOptionSelect} checkedValue={this.state.selectedAdditionalRiderCardValue} data={data} toggleRider={this.toggleRider} onAmountChange={this.onCoverAmountChange} amountList={this.state.coverAmountList} /> */}
                    {/* TODO check GST parameter */}
                    <AmountCalculation
                        cardKey={this.props.cardKey}
                        item={data}
                        totalPremium=''
                        gst=''
                        total_price={this.getTotalPrice()}
                        gst_percentage={data.BI.TAX_RATE * 100}
                        hideTotalPremium={true}
                        subTitle={this.state.ppf.label}
                    />
                </React.Fragment>
                <FundDistributionModal show={showFundDistributionModal} onSetDistribution={(data) => { this.onSetDistribution(data) }} onHide={() => { this.openFundDistributionModal(false) }} />
            </div>
        );
    }
});

WealthUltimaProductCard.defaultProps = {
    disabled: false
}

WealthUltimaProductCard.propTypes = {
    addInsured: PropTypes.func,
    data: PropTypes.object,
    disabled: PropTypes.bool
}

export default WealthUltimaProductCard;