export default {
    "minimum_ppt": 'For 10 pay & 12 pay, you have to choose policy term from 15 to 20',
    "minimum_premium_5_7_pay": {
        'annualy': 'Minimum Annual premium is 36,000',
        'half_yearly': 'Minimum Half-Yearly premium is 72,000',
        'quaterly': 'Minimum Quarterly premium is 72,000',
        'monthly': 'Minimum Monthly premium is 72,000',
    },
    "minimum_premium_10_12_pay": {
        'annualy': 'Minimum Annual premium is 24,000',
        'half_yearly': 'Minimum Half-Yearly premium is 48,000',
        'quaterly': 'Minimum Quarterly premium is 48,000',
        'monthly': 'Minimum Monthly premium is 48,000',
    },
    "sub_product_validation": "You can only choose the Family Protection Option for this product",
    "rider": "Rider Sum Assured must be Less than Product\'s Sum Assured",
    "option_validation": {
        "base_option_minimum": "Minimum entry age for Smart Lifestyle is 1 year. Kindly revise the age.",
        "base_option_maximum": "Maximum entry age for Smart Lifestyle is 50 years. Kindly revise the age.",
        "family_protection_option_minimum": "Minimum entry age for Smart Lifestyle is 18 years. Kindly revise the age.",
        "family_protection_option_maximum": "Maximum entry age for Smart Lifestyle is 40 years. Kindly revise the age."
    },
    'MINIMUM_RIDER_AGE': "Minimum Entry age for riders is 18 years",
    "MAXIMUM_RIDER_AGE": "Maximum Entry age for riders is 65 years",
    "PWB_RIDER_VALIDATION": "Proposer should be different from Life assured for Payor waiver benefit rider",
}