import Utils from "./utils"
import * as keys from "./keys"

const FormObserver = (formRef, props) => {

  formRef.$("payment-frequency").observe({
    key: "value",
    call: ({ field }) => {
      let premium = formRef.$("premium-amt").value
      let frequency = field.value.id
      let data = {
        premium: premium,
        frequency: frequency,
      }
      Utils.updateBi(
        keys.FREQUENCY,
        data,
        props.data.product_id,
        formRef,
        props
      )
    },
  })

  formRef.$("source-of-purchase-price").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.SOURCE_OF_PURCHASE_PRICE,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  })


  formRef.$("annuity-mode").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.ANNUITY_MODE,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  })

  formRef.$("annuity-option").observe({
    key: "value",
    call: ({ field,form }) => {

    // ! // ! disabling Name  of the Secondary Annuitant, DOB of the Secondary Annuitant, Gender of the Secondary Annuitant
    if(field.value.id === '1'){

    form.$("secondary-annuitant-name").set("disabled",true)
    form.$("secondary-annuitant-date-of-birth").set("disabled",true);
    form.$("secondary-annuitant-gender").set("disabled",true);

    //! for setting the values of the default
    form.$('secondary-annuitant-name').set('value', '');
    form.$("secondary-annuitant-date-of-birth").set("value","");
    form.$("secondary-annuitant-gender").set("value","");
  }else{
    form.$("secondary-annuitant-name").set("disabled",false)
    form.$("secondary-annuitant-date-of-birth").set("disabled",false);
    form.$("secondary-annuitant-gender").set("disabled",false);
  }
      
      Utils.updateBi(
        keys.ANNUITY_OPTION,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  })

  formRef.$("secondary-annuitant-gender").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.GENDER_NAME_OF_THE_SECONDARY_ANNUITANT,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  })
}

export default FormObserver