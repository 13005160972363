// Project Imports
import ProductNames from "constants/ProductNames";
import ProductPackageId from "constants/ProductPackageId";
const fetchTargetAmountLabel = (productData) => {
  // For Without tab products
  let targetAmountLabel = "";
  if (!Array.isArray(productData.BI)) return "";
  // For With tabs products
  else {
    let selectedRiskData = productData.BI.find((item) => item.risk === productData.selectedRisk);
    switch (selectedRiskData?.product_id) {
      case ProductNames.activeIncomePlan:
      case ProductNames.easyPension:
        return "per annum";

      case ProductNames.foreverPension:
        if (Number(selectedRiskData?.bi_request?.AnnuityMode) === 21) {
          targetAmountLabel = "per annum";
        } else if (Number(selectedRiskData?.bi_request?.AnnuityMode) === 22) {
          targetAmountLabel = "per half year";
        } else if (Number(selectedRiskData?.bi_request?.AnnuityMode) === 23) {
          targetAmountLabel = "per quarter";
        } else {
          targetAmountLabel = "per month";
        }
        return targetAmountLabel;

      case ProductNames.premierGuranteedIncome:
        switch (selectedRiskData.package_id) {
          case ProductPackageId.PREMIER_GUARANTEED_INCOME_LUMPSUM:
          case ProductPackageId.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
            return ""

          case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
          case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
          case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME:
          case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
          case ProductPackageId.PREMIER_GUARANTEED_RETIREMENT_INCOME:
            if (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency === "1") {
              targetAmountLabel = "per annum";
            } else if (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency === "2") {
              targetAmountLabel = "per half year";
            } else if (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency === "3") {
              targetAmountLabel = "per quarter";
            } else {
              targetAmountLabel = "per month";
            }
            return targetAmountLabel

          default:
            return ""
        }

        case ProductNames.wealthSecurePlus:  
          return targetAmountLabel;

        // if (Number(selectedRiskData?.bi_request?.INPUT_MODE) === 1) {
          //   targetAmountLabel = "per annum";
          // } else if (Number(selectedRiskData?.bi_request?.INPUT_MODE) === 2) {
            //   targetAmountLabel = "per half year";
            // } else if (Number(selectedRiskData?.bi_request?.INPUT_MODE) === 3) {
              //   targetAmountLabel = "per quarter";
              // } else {
                //   targetAmountLabel = "per month";
                // }
        case ProductNames.cashFlowProtectionPlus:
          return "per annum";

      case ProductNames.guaranteedSavingsStar:
        switch (selectedRiskData.package_id) {
          case ProductPackageId.GUARANTEED_SAVINGS_STAR_INCOME:
          case ProductPackageId.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
            return "per annum";

          default:
            return ""
        }

      case ProductNames.guaranteedIncomeStar:
        if (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency === "32") {
          targetAmountLabel = "per annum";
        } else if (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency === "33") {
          targetAmountLabel = "per half year";
        } else if (selectedRiskData?.bi_request?.IncomeBenefitPayoutFrequency === "34") {
          targetAmountLabel = "per quarter";
        } else {
          targetAmountLabel = "per month";
        }
        return targetAmountLabel

      case ProductNames.wealthUltima:
        if (selectedRiskData?.bi_request?.optionId3 == '5') {
          // SWP yes
          // SWP frequency
          if(selectedRiskData?.bi_request?.optionId5 == "117"){
            // Annual
            targetAmountLabel = "per annum";
          }
          else if(selectedRiskData?.bi_request?.optionId5 == "118"){
            // Semi-Annual
            targetAmountLabel = "per half year";
          }
          else if(selectedRiskData?.bi_request?.optionId5 == "119"){
            // Quarterly
            targetAmountLabel = "per quarter";
          }
          else if(selectedRiskData?.bi_request?.optionId5 == "120"){
            // Monthly
            targetAmountLabel = "per month";
          }

          return targetAmountLabel
        }

      case ProductNames.incomeBuilder:

        if(selectedRiskData?.bi_request?.PayoutMode == '15'){
          // Annual
          targetAmountLabel = "per annum";
        }else if(selectedRiskData?.bi_request?.PayoutMode == '17'){
          // Semi-Annual
          targetAmountLabel = "per half year";
        }else if(selectedRiskData?.bi_request?.PayoutMode == '18'){
          // Quarterly
          targetAmountLabel = "per quarter";
        }else if(selectedRiskData?.bi_request?.PayoutMode == '19'){
          // Monthly
          targetAmountLabel = "per month";
        }
        return targetAmountLabel


        case ProductNames.saralPension:
        if (selectedRiskData?.bi_request?.AnnuityMode === '8') {
          targetAmountLabel = "per annum";
        } else if (selectedRiskData?.bi_request?.AnnuityMode === '9') {
          targetAmountLabel = "per half year";
        } else if (selectedRiskData?.bi_request?.AnnuityMode === '10') {
          targetAmountLabel = "per quarter";
        } else if (selectedRiskData?.bi_request?.AnnuityMode === '11') {
          targetAmountLabel = "per month";
        }
        return targetAmountLabel;
              
      case ProductNames.flexiSavingsPlan:
        switch (selectedRiskData.package_id) {
          case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
          case ProductPackageId.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
            return  targetAmountLabel = "per annum"
        }

      default:
        return "";
    }
  }
};

export default fetchTargetAmountLabel;
