import dvr from "mobx-react-form/lib/validators/DVR";
import vjf from "mobx-react-form/lib/validators/VJF";

import { Form } from "mobx-react-form";
import validatorjs from "validatorjs";

import setInitialDefaults from "./initialDefaults";
import formObserver from "./observers";
import Utils from "./utils";
import * as keys from "./keys";

class WealthUltimaController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
      vjf: vjf(),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "Premium Paying Frequency",
          disabled: false,
          extra: {
            options: [
              { id: 4, label: "Monthly" },
              { id: 3, label: "Quarterly" },
              { id: 2, label: "Half-Yearly" },
              { id: 1, label: "Annual" },
            ],
          },
        },
        {
          name: "payout-percentage",
          label: "Payout Percentage",
          placeholder: "Payout Percentage",
          disabled: false,
          extra: {
            options: [],
          },
        },
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "Policy Term",
          disabled: false,
          extra: {
            options: [],
          },
        },
        {
          name: "premium-term",
          label: "Premium Paying Term",
          placeholder: "Premium Paying Term",
          disabled: false,
          extra: {
            options: [],
          },
        },
        {
          name: "investment-stratergy",
          label: "Investment  Stratergy",
          placeholder: "Investment  Stratergy",
          disabled: false,
          extra: {
            options: [],
            showDistributionModal: false
          },
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "Premium",
          disabled: false,
          extra: {
            calculatePremiun(form, productId, age, formRef, productData) {
              // let premiumAmount = 0;
              // let inputMode = Number(form.$('payment-frequency').value.id)
              // let pt = form.$('policy-term').value.id
              // let amount = Number(form.$('premium').value)
              // switch (inputMode) {
              //   case 1:
              //     premiumAmount = '' + amount * 1
              //     break;
              //   case 2:
              //     premiumAmount = '' + amount * 2
              //     break;
              //   case 3:
              //     premiumAmount = '' + amount * 4
              //     break;
              //   case 4:
              //     premiumAmount = '' + amount * 12
              //     break;
              // }
              // let pr_sa = ''
              // if (age <= 44) {
              //   let ptValue = pt / 2
              //   pr_sa = ptValue > 10 ? ptValue * Number(premiumAmount) : 10 * Number(premiumAmount)
              // } else {
              //   let ptValue = pt / 4
              //   pr_sa = ptValue > 7 ? ptValue * Number(premiumAmount) : 7 * Number(premiumAmount)
              // }

              // let data = {
              //   premium: premiumAmount,
              //   sumAssured: pr_sa
              // }

              // Utils.updateBi(keys.PREMIUM, data, productId, formRef, productData)
            },
          },
        },
        {
          name: "policy-option",
          label: "Policy Option",
          placeholder: "",
          disabled: false,
          extra: {
            options: [
              { id: 1, label: "Option 1" },
              { id: 2, label: "Option 2" },
            ],
          },
        },
        {
          name: "little-champ-benefit",
          label: "Little Champ Benefit",
          placeholder: "",
          disabled: false,
          extra: {
            options: [
              { id: '3', label: "Yes" },
              { id: '4', label: "No" },
            ],
          },
        },
        {
          name: "systematic-withdrawal-option",
          label: "Systematic Withdrawal Option",
          placeholder: "",
          disabled: false,
          extra: {
            options: [
              { id: '5', label: "Yes" },
              { id: '6', label: "No" },
            ],
          },
        },
        {
          name: "fund-value",
          label: "% of Fund Value (per annum) to be Withdrawn",
          placeholder: "",
          disabled: false,
          extra: {
            options: Utils.populateWithdrawlPercentage(),
          },
        },
        {
          name: "fund-strategy",
          label: "Fund Strategy",
          placeholder: "",
          extra: {
            showFundDistributionModal: false,
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Self-managed Strategy",
              },
              {
                id: "2",
                label: "Life stage and duration based Strategy",
              },
              {
                id: '3',
                label: 'Profit Target based STP'
              }
            ],
            setDistributionButtonText: "Set Distribution",
            async onSetDistribution(fundList, form, data) {
              let productId = data.data.product_id
              form.$('fund-strategy').set('extra', {
                ...form.$('fund-strategy').extra,
                showFundDistributionModal: false,
                fundList: fundList
              })
              await Utils.setDistribution(fundList, productId, form, data);
            },
            fundList: [
              {
                id: "EquityLargeCapFund",
                label: "Equity Large Cap Fund",
                checkedValue: false,
                key: keys.EQUITY_LARGE_CAP_FUND,
                value: 0,
              },
              {
                id: "EquityTop250Fund",
                label: "Equity Top 250 Fund",
                checkedValue: false,
                key: keys.EQUITY_TOP_20_FUND,
                value: 0,
              },
              {
                id: "EquityMidCapFund",
                label: "Equity Mid-Cap Fund",
                checkedValue: false,
                key: keys.EQUITY_MID_CAP_FUND,
                value: 0,
              },
              {
                id: "EquityBlueChipFund",
                label: "Equity Blue Chip Fund",
                checkedValue: false,
                key: keys.EQUITY_BLUE_CHIP_FUND,
                value: 0,
              },
              {
                id: "GILTFund",
                label: "GILT Fund",
                checkedValue: false,
                key: keys.GLIT_FUND,
                value: 0,
              },
              {
                id: "BondFund",
                label: "Bond Fund",
                checkedValue: false,
                key: keys.BOND_FUND,
                value: 0,
              },
              {
                id: "ManagedFund",
                label: "Managed Fund",
                checkedValue: false,
                key: keys.MANAGED_FUND,
                value: 0,
              },
            ],
            toggleFundDistributionModal(form, status, fundList, productId,data) {
              form.$('fund-strategy').set('extra', {
                ...form.$('fund-strategy').extra,
                showFundDistributionModal: status,
                fundList: fundList
              })

              // Trigger when modal will close
              if (!status) {
                Utils.updateBi(keys.FUNDSTRATEGYID, "1", productId, form,data)
              }
            },
          },
        },
        {
          name: "swp-frequency",
          label: "SWP Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "117",
                label: "Annual",
              },
              {
                id: "118",
                label: "Semi-Annual",
              },
              {
                id: "119",
                label: "Quarterly",
              },
              {
                id: "120",
                label: "Monthly",
              },
            ],
          },
        },
        {
          name: "withdrawal-year",
          label: "Policy Year from which SWP payable",
          placeholder: "",
          type:'number',
          extra: {
            isVisible: false,
            onWithDrawalYearChange(form, data){
              let year = form.$('withdrawal-year').value
              Utils.updateBi(keys.WITHDRAWL_YEAR, year, data.data.product_id, form, data)
            }
          },
        },
        {
          name: "death-benefit-amount",
          label: "Death Benefit:",
          placeholder: "Death Benefit Amount",
          extra: {
            onDeathBenefitAmountChange(form, data) {
              let deathBenefitValue = form.$('death-benefit-amount').value;
              Utils.updateBi(keys.PR_SA, deathBenefitValue, data.data.product_id, form, data);
            }
          }
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data)
    // Observer fields
    formObserver(this, data)
  }
}

export default WealthUltimaController;
