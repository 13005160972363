
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {
  console.log("FFF PROPS>>>>>>", props)

  formRef.$('payment-frequency').observe({
    key: 'value',
    call: ({ field }) => {
      let premium = formRef.$('premium').value
      let frequency = field.value.id
      let data = {
        premium: premium,
        frequency: frequency
      }
      Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)
    }
  })



  // formRef.$('policy-term').observe({
  //   key: 'value',
  //   call: ({ field }) => {

  //     const ptValue = field.value.id;

  //     if(ptValue > 20){
  //       formRef.$('premium-term').value = {id: 20, label: '20'}
  //     }else if (ptValue > 10 && ptValue <= 20){
  //       formRef.$('premium-term').value = {id: 10, label: '10'}
  //     }else if (ptValue == 10){
  //       formRef.$('premium-term').value = {id: 7, label: '7'}
  //     } 

  //     Utils.updateBi(keys.PR_PT, ptValue, props.data.product_id, formRef, props)
  //   }
  // })

  formRef.$('gender-of-spouse').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.SPOUSE_GENDER, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('spouse-tobacco-consumer').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.SPOUSE_TOBACCO_CONSUMER, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$("tobacco-user").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.LI_SMOKE,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });


  formRef.$('ADB').observe({
    key: 'value',
    call: ({ field }) => {
      let adbValue = formRef.$('ADB-dropdown').value
      Utils.updateBi(keys.ADB_RIDER, adbValue, props.data.product_id, formRef, props)
    }
  })

  formRef.$('ATPDRider').observe({
    key: 'value',
    call: ({ field }) => {
      let atpdValue = formRef.$('ATPDRider-dropdown').value
      Utils.updateBi(keys.ATPD_RIDER, atpdValue, props.data.product_id, formRef, props)
    }
  })

  formRef.$('CriticalIllnessRider').observe({
    key: 'value',
    call: ({ field }) => {
      let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
      Utils.updateBi(keys.Critical_ILLNESS_RIDER, criticalIllness, props.data.product_id, formRef, props)
    }
  })

  formRef.$('HospitalityCashBenefitRider').observe({
    key: 'value',
    call: ({ field }) => {
      let hospitalityCashBenefit = formRef.$('HospitalityCashBenefitRider-dropdown').value
      Utils.updateBi(keys.HOSPITALITY_CASH_BENEFIT_RIDER, hospitalityCashBenefit, props.data.product_id, formRef, props)
    }
  })

  formRef.$("plan-option").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(
        keys.PLAN_OPTION,
        field.value.id,
        props.data.product_id,
        formRef,
        props
      );
    },
  });

  formRef.$("underwriting-status").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(keys.UNDERWRITING, field.value.id, props.data.product_id, formRef, props
      );
    },
  });

  formRef.$("income-payout-start-age").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(keys.LIVE_LONG_OPTION_INCOME_START_AGE_OPTION, field.value.id, props.data.product_id, formRef, props
      );
    },
  });

  formRef.$("mode-of-income-pay-out").observe({
    key: "value",
    call: ({ field }) => {
      let incomePayoutModeLabel = "";
      switch (Number(field.value.id)) {
        case 8:
          incomePayoutModeLabel = "Annual";
          break;
        case 9:
          incomePayoutModeLabel = "Semi-Annual";
          break;
        case 10:
          incomePayoutModeLabel = "Quarterly";
          break;
        case 11:
          incomePayoutModeLabel = "Monthly";
          break;
        default:
          break;
      }

      let incomePayoutPercentageList = Utils.fetchIncomePayoutPercentage(incomePayoutModeLabel);
      formRef.$("income-pay-out").set("extra", {
        ...formRef.$("income-pay-out").extra,
        optionList: incomePayoutPercentageList,
      });

      Utils.updateBi(keys.LIVE_LONG_OPTION_INCOME_PAYOUT_MODE_OPTION, field.value.id, props.data.product_id, formRef, props);
    },
  });

  formRef.$("income-pay-out").observe({
    key: "value",
    call: ({ field }) => {
      Utils.updateBi(keys.LIVE_LONG_OPTION_INCOME_PAYOUT_PERCENTAGE_OPTION, field.value.id, props.data.product_id, formRef, props
      );
    },
  });

}

export default FormObserver