// Project Imports
import Product from 'constants/ProductConstants';

const fetchOtherBenefit = (solutionData) => {
    let lifeCoverList = []

    solutionData.coverage.forEach((coverage) => {
        coverage.life_assured_list.forEach(lifeAssured => {
            let laName = lifeAssured.la_name
            let memberType = lifeAssured.la_family_member_type
            lifeAssured.product_details.forEach(obj => {
                let sumAssured = obj.data.InputString.find((item) => item.Key == '@PR_SA')?.Value
                let adbRider = fetchInputStringOpt('@RD_YN_50001', obj.data.InputString) === 'YES'
                let atpdRider = fetchInputStringOpt('@RD_YN_50002', obj.data.InputString) === 'YES'
                let criticalIllnessRider = fetchInputStringOpt('@RD_YN_50003', obj.data.InputString) === 'YES'
                let incomeBenefitRider = fetchInputStringOpt('@RD_YN_50006', obj.data.InputString) === 'YES'
                let waiverPremiumRider = fetchInputStringOpt('@RD_YN_50007', obj.data.InputString) === 'YES'
                let payorWaiverRider = fetchInputStringOpt('@RD_YN_50008', obj.data.InputString) === 'YES'
                let hospitalCashBenefitRider = fetchInputStringForHospitalCashBenefitOpt('@RD_SA_50004', obj.data.InputString) ? true : false

                let benefitMessage = ""
                switch (obj.product_name) {
                    case Product.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
                        lifeCoverList.push('Pay only once')
                        break;

                    case Product.SMART_LIFESTYLE:
                        lifeCoverList.push('Get benefit of increasing Sum Assured')
                        break;

                    case Product.CRITICARE_PLUS:
                        lifeCoverList.push(`Coverage of Rs. ${Number(sumAssured).toLocaleString('en-IN')} for ${laName || memberType} in case of 17 Critical Illnesses`)
                        break;

                    case Product.WEALTH_ULTIMA:
                    case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
                    case Product.LITTLE_CHAMP_WEALTH_ULTIMA:
                    case Product.WEALTH_GAIN_PLUS:
                    case Product.WEALTH_SECURE_PLUS:
                    case Product.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS:
                    case Product.CHILD_VARIANT_WEALTH_SECURE_PLUS:
                        benefitMessage = 'Multiple funds to choose from'
                        if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                            lifeCoverList.push(benefitMessage)
                        break;

                    case Product.WEALTH_PLUS:
                    case Product.RISING_STAR_WEALTH_PLUS:
                        benefitMessage = 'Multiple funds to choose from. No upfront charges.'
                        if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                            lifeCoverList.push(benefitMessage)
                        break;

                    case Product.EASY_PENSION:
                        lifeCoverList.push('Use the retirement corpus to buy lifelong annuity')
                        break;

                    case Product.WEALTH_PREMIER:
                        lifeCoverList.push('Multiple funds to choose from. Pay only once. Get your spouse covered in the same plan.')
                        break;

                    case Product.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
                        lifeCoverList.push('Covers the entire lifespan of your child.')
                        break;

                    case Product.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
                    case Product.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
                        benefitMessage = 'Customizable cashflows as per your needs'
                        if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                            lifeCoverList.push(benefitMessage)
                        break;
                    
                }


                if (adbRider) {
                let adbSA = fetchInputStringOpt('@RD_SA_50001', obj.data.InputString)
                adbSA = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', currencyDisplay: 'name' }).format(adbSA).split('.')[0];
                    benefitMessage = `Get additional ${adbSA} in case of accidental death`
                    if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                        lifeCoverList.push(benefitMessage)
                }

                if (atpdRider) {
                let atpdSA = fetchInputStringOpt('@RD_SA_50002', obj.data.InputString)
                atpdSA = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', currencyDisplay: 'name' }).format(atpdSA).split('.')[0];    
                    benefitMessage = `Get additional ${atpdSA} in case of accidental death or disability.`
                    if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                        lifeCoverList.push(benefitMessage)
                }

                if (criticalIllnessRider) {
                    let criticalSA = fetchInputStringOpt('@RD_SA_50003', obj.data.InputString)
                    criticalSA = new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', currencyDisplay: 'name' }).format(criticalSA).split('.')[0];
                    benefitMessage = `Coverage of Rs. ${criticalSA} in case of 12 Critical Illnesses`
                    if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                        lifeCoverList.push(benefitMessage)
                }

                if (incomeBenefitRider) {
                    let incomeSA = fetchInputStringOpt('@RD_SA_50006', obj.data.InputString)
                    benefitMessage = `In case of death of ${laName || memberType}, ₹ ${incomeSA * 0.01} will be paid for 150 months`
                    if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                        lifeCoverList.push(benefitMessage)
                }

                if (waiverPremiumRider) {
                    benefitMessage = `Future premiums are waived off in case of ${solutionData.family_member_name} is diagnosed with 12 covered Critical Illnesses`
                    if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                        lifeCoverList.push(benefitMessage)
                }

                if (hospitalCashBenefitRider) {
                    benefitMessage = `Get additional daily cash benefit in case of hospitalisation.`
                    if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                        lifeCoverList.push(benefitMessage)
                }

                if (payorWaiverRider) {
                    if (fetchInputStringOpt('@RD_YN_OPTION_1_50008_1', obj.data.InputString) === 'YES') {
                        benefitMessage = `Future premiums are waived off in case of death of ${solutionData.family_member_name}`
                        if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                            lifeCoverList.push(benefitMessage)
                    }

                    if (fetchInputStringOpt('@RD_YN_OPTION_1_50008_2', obj.data.InputString) === 'YES') {
                        benefitMessage = `Future premiums are waived off in case ${solutionData.family_member_name} is diagnosed with 12 covered Critical Illnesses or Accidental total permanent disability`
                        if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                            lifeCoverList.push(benefitMessage)
                    }

                    if (fetchInputStringOpt('@RD_YN_OPTION_1_50008_3', obj.data.InputString) === 'YES') {
                        benefitMessage = `Future premiums are waived off in case of death of ${solutionData.family_member_name} or if ${solutionData.family_member_name} is diagnosed with 12 covered Critical Illnesses or Accidental total permanent disability`
                        if (!checkBenefitAvailable(benefitMessage, lifeCoverList))
                            lifeCoverList.push(benefitMessage)
                    }
                }
            })
        })
    })

    return lifeCoverList
}

const fetchInputStringOpt = (key, inputString) => {
    return inputString.filter((item) => item.Key == key)[0]?.Value || 'NO'
}

const fetchInputStringForHospitalCashBenefitOpt = (key, inputString) => {
    return inputString.filter((item) => item.Key == key)[0]?.Value
}

const checkBenefitAvailable = (benefit, coverList) => {
    return coverList.some((item) => item === benefit)
}

export default fetchOtherBenefit