// package imports
import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import moment from 'moment'

// project imports
import { DatePicker } from '../../index'
import './datepicker-modal.css'
import { Button as UiButton } from "components"
import {
  CalendarIcon,
  CalenderIcon,
  CheckSecondaryImg,
  CloseIconBlack
} from 'constants/Images'

class DatepickerModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      selectedDate: '',
      updatedDate: '',
    }
  }
  handleDatePickerOpenModal() {
    this.setState({ show: true })
    // this.setState({prevSelectedDate:this.state.selectedDate})

  }

  handleDatePickerCloseModal() {
    this.setState({ updatedDate: this.state.selectedDate })
    this.setState({ show: false })
  }

  onDatePickerChange = (date) => {
    this.setState({ updatedDate: date })
  }
  onUpdateDatePicker = () => {
    let { updatedDate, selectedDate } = this.state
    this.setState({ selectedDate: updatedDate == '' ? selectedDate : updatedDate, show: false })
    this.props.onChange(this.state.updatedDate)
  }
  preSelectedDate = () => {
    try {
      let selectedDate = this.props.date
      this.setState({ selectedDate })
    } catch (error) { }
  }
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.date !== prevProps.date) {
      this.preSelectedDate()
    }
  }
  componentDidMount() {
    this.preSelectedDate()
  }
  render() {
    // console.log('DATE PICKER MODAL RENDER >> ', typeof this.state.selectedDate)
    const { selectedDate } = this.state
    const { textStyle, plain, showErrorMsg, errorMsg, disabled } = this.props;
    const dateFormat = 'DD-MM-YYYY'
    const validateDate = moment(selectedDate, 'DD-MM-YYYY', true).isValid()
    // const date = selectedDate == '' ? dateFormat : validateDate ? selectedDate : moment(selectedDate).format(dateFormat)

    const date = !this.props.value ? dateFormat : validateDate ? this.props.value : moment(this.props.value).format(dateFormat)

    return (
      <>
        <Button variant='' disabled={disabled} className={`font-size-12 font-weight-medium p-1 btn date-picker-btn d-flex align-items-center justify-content-between w-100 text-primary-title-label-color bg-white rounded ${plain ? 'border-sm' : ''} ${plain && showErrorMsg ? 'validate' : ''} ${showErrorMsg ? 'validate' : ''}`} onClick={() => { this.handleDatePickerOpenModal() }}>
          <span className={textStyle || ''}>{date}</span>
          {plain ? '' : <img className='d-none d-md-block' width='15' height='15' src={CalendarIcon} alt='calendar-icon' />}
        </Button>
        {showErrorMsg && <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>{errorMsg ? errorMsg : 'Select a value'}</p>}
        <Modal
          className='date-picker-modal left-bottom-swipe-modal'
          show={this.state.show}
          onHide={() => { this.handleDatePickerCloseModal() }}
          // backdrop='static'
          keyboard={false}
        // animation={false}
        >
          <Modal.Header closeButton={false} className='pb-0 border-bottom-0'>
            <Modal.Title className='font-size-20 text-primary-title-label-color'>
              <img
                src={CalenderIcon}
                alt="family-icon"
                className="add-family-member-card-modal-top-img mb-1"
              />
              <span style={{ color: "#DF6336", fontSize: "18px" }}>Date Picker</span>
            </Modal.Title>
            <div className="d-flex mt-1">
              <UiButton variant='' className='d-inline-flex align-items-center p-0' onClick={() => { this.onUpdateDatePicker() }}>
                <img src={CheckSecondaryImg} alt='checked-icon' className='fm-modal-btn-icon font-weight-bold' />
                <p className='mb-0 font-size-16 font-size-md-12 font-weight-bold text-secondary pl-1'>Done</p>
              </UiButton>
              <UiButton variant='' className='d-inline-flex align-items-center p-0 ml-2' onClick={() => {this.setState({ show: false })}}>
                <img src={CloseIconBlack} alt='close-icon' className='fm-modal-btn-icon font-weight-bold' />
                <p className='mb-0 font-size-16 font-size-md-12 font-weight-bold pl-1'>Cancel</p>
              </UiButton>

            </div>
          </Modal.Header>
          <Modal.Body className='pt-0'>
            <p className='modal-date-picker-subtext font-size-16 mt-2'>Adjust the date of your birth</p>
            <DatePicker minDate={this.props.minDate} maxDate={this.props.maxDate} date={this.state.selectedDate} onChange={this.onDatePickerChange} />
          </Modal.Body>
          {/* <Modal.Footer>
            <Button variant='secondary' className='w-100 font-size-18 m-0' onClick={() => { this.onUpdateDatePicker() }}>
              Done
            </Button>
          </Modal.Footer> */}
        </Modal>
      </>
    )
  }
}

DatepickerModal.defaultProps = {
  showErrorMsg: false,
  disabled: false
}

export default DatepickerModal
