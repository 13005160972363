// react imports
import React from "react";

// package imports
import { Row, Col, Form } from "react-bootstrap";
import { observer } from "mobx-react";
import moment from "moment";

import { CustomCheckbox, BottomsheetDropdown, DeathCoverPriceComponent, DatepickerModal, ChangeProductSection } from "../../../../../components";

// project imports
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import Utils from "./utils";
import DeathBenefitAmount from "utils/optimization/deathBenefitAmount";

// css imports
import "../../optimization-card/family-collapse-view.css";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";

const ForeverPension = ({ form, data }) => {
  
  let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let biResponse = productData.parsedResponse;
  let firstYearResponse = productData.firstYearResponse;
  let biRequest = productData.bi_request;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey);

  return (
    <div className=''>
      {/* <Row className='mt-4 mb-3 pb-1'>
        <Col lg={7} className='col-12'>
          <DeathCoverPriceComponent
            firstAmount={firstYearResponse?.SA}
            secondAmount={productData.isValidationExist ? 0 : Utils.getTotalPrice(biResponse)}
            thirdAmount='980'
            showAdditionalText={false}
            showSecondColAmount={false}
            secondColBottomText={form.$("payment-frequency").value.label}
            firstColTitleText={coverLabelBaseOnNeed}
            secondColTitleText='Price'
          />
        </Col>
      </Row> */}
      <Row className="align-items-center mt-md-4">
        <Col xs={12} md={4} className='mt-3 mt-md-0'>
          <ChangeProductSection 
            productName={productData.product_name || "Forever Pension"} 
            cardName={coverLabelBaseOnNeed} 
            changeProductList={data?.data?.changeProductList} 
            productData={data} />
        </Col>

        <Col xs={12} className='mt-2 mt-md-0'>
          <DeathCoverGraphSlider
            productData={data?.data}
            saLabel='Income'
            productTitle='Forever Pension'
            openChangeReasonModal={() => { }}
            onIncomeChange={form.$("income").onChange}
            onPremiumChange={form.$("premium-amt").onChange}
            dataChange={() => { }}
            hideOverLay={() => { }}
            productID={""}
            cardKey={data.cardKey}
            income_value={form.$("income").value}
            premium_value={form.$("premium-amt").value}
            showOverlay={false}
            onClick={() => form.$("premium-amt").extra.calculatePremium(form, form.$("premium-amt").value, data)}
            data={""}
            isIncomeFieldDisable={false}
            isPremiumDisable={false}
            premiumFreq={form.$("payment-frequency").value.label}
            onIncomeBlur={() => {
              form.$("income").extra.calculateIncome(form, data);
            }}
            formRef={form}
            secondInputTextlabel="Single Premium"
          />
        </Col>
      </Row>
      <Row className='mt-3 ml-0'>
        <p className='graph-label mb-2 font-size-12 font-size-md-14 font-weight-bold'>{"Death Benefit:"}</p>
        <p className='graph-label pl-1 font-size-12 font-size-md-14 font-weight-bold'>{DeathBenefitAmount(productData)}</p>
      </Row>

      <Row className='mt-3'>
        {/* <Col xs={12} md={6} className='mb-3 mb-md-0'>
          <p className='mb-2 font-size-12 font-weight-light '>{form.$("payment-frequency").label}</p>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("payment-frequency").label}
            menuList={form.$("payment-frequency").extra.options}
            value={form.$("payment-frequency").value}
            onSelect={form.$("payment-frequency").onChange}
            disabled={false}
          />
        </Col> */}
        <Col xs={12} md={6} className='mt-3'>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("source-of-purchase-price").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("source-of-purchase-price").label}
            menuList={form.$("source-of-purchase-price").extra.options}
            value={form.$("source-of-purchase-price").value}
            onSelect={form.$("source-of-purchase-price").onChange}
            disabled={false}
          />
        </Col>

        {form.$("pran-number").extra.visible &&
        <Col xs={12} md={6} className=' product-form mt-3'>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("pran-number").label}</div>
          <Form.Control
            className='font-size-12 font-weight-light text-black'
            placeholder={form.$("pran-number").label}
            value={form.$("pran-number").value}
            onChange={(e) => form.$("pran-number").extra.onPranNoChange(e, form, data)}
            onBlur={() => form.$("pran-number").extra.onPranNoBlur(form, data)}
            disabled={false}
          />
        </Col>
         } 

        <Col xs={12} md={6} className='mt-3'>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("annuity-benefit").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("annuity-benefit").label}
            menuList={form.$("annuity-benefit").extra.options}
            value={form.$("annuity-benefit").value}
            onSelect={(e) => form.$("annuity-benefit").extra.enableDefermentperiodAndChangeOptionList(e, form, data)}
            disabled={form.$("annuity-benefit").disabled}
          />
        </Col>

        <Col xs={12} md={6} className='mt-3'>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("life-status").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("life-status").label}
            menuList={form.$("life-status").extra.options}
            value={form.$("life-status").value}
            onSelect={form.$("life-status").onChange}
            disabled={false}
          />
        </Col>
        <Col xs={12} md={6} className='mt-3'>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("annuity-mode").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("annuity-mode").label}
            menuList={form.$("annuity-mode").extra.options}
            value={form.$("annuity-mode").value}
            onSelect={form.$("annuity-mode").onChange}
          />
        </Col>
        {/* <Col xs={12} md={6} className="mt-3 product-form">
          <div className="mb-2 font-size-12 font-weight-light ">
            {form.$("purchase-Price-amount").label}
          </div>
          <Form.Control
            className="font-size-12 font-weight-light text-black"
            placeholder={form.$("purchase-Price-amount").label}
            value={form.$("purchase-Price-amount").value}
            onChange={(e) => form.$('purchase-Price-amount').extra.onPurchasePriceAmtChange(e, form, data)}
            onBlur={() => form.$('purchase-Price-amount').extra.onPurchasePriceAmtBlur(form, data)}
            disabled={false}
          />
        </Col> */}
        <Col xs={12} md={6} className='mt-3'>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("annuity-option").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("annuity-option").label}
            menuList={form.$("annuity-option").extra.options}
            value={form.$("annuity-option").value}
            onSelect={form.$("annuity-option").onChange}
            disabled={false}
          />
        </Col>
        {form.$("deferment-period").extra.isVisible && 
        <Col xs={12} md={6} className='mt-3'>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("deferment-period").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("deferment-period").label}
            menuList={form.$("deferment-period").extra.options}
            value={form.$("deferment-period").value}
            onSelect={form.$("deferment-period").onChange}
            disabled={form.$("deferment-period").disabled}
          />
        </Col>
         }
        <Col xs={12} md={6} className=' product-form mt-3'>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("secondary-annuitant-name").label}</div>
          <Form.Control
            className='font-size-12 font-weight-light text-black'
            placeholder='name'
            value={form.$("secondary-annuitant-name").value}
            onChange={form.$("secondary-annuitant-name").onChange}
            onBlur={() => form.$("secondary-annuitant-name").extra.onSecondaryAnnuitantNameChange(form, data)}
            disabled={form.$("secondary-annuitant-name").disabled}
          />
        </Col>
        <Col xs={12} md={6} className=' product-date-picker mt-3'>
          <div className='mb-2 font-size-12 font-weight-light'>{form.$("secondary-annuitant-date-of-birth").label}</div>
          <DatepickerModal
            plain
            value={form.$("secondary-annuitant-date-of-birth").value}
            textStyle='font-size-10 font-weight-light text-black text-nowrap overflow-hidden txt-overflow'
            onChange={(value) => form.$("secondary-annuitant-date-of-birth").extra.changeDob(form, value, data)}
            disabled={form.$("secondary-annuitant-date-of-birth").disabled}
            minDate={moment().subtract(18, "years").toString()}
          />
        </Col>
        <Col xs={12} md={6} className='mt-3'>
          <div className='mb-2 font-size-12 font-weight-light '>{form.$("secondary-annuitant-gender").label}</div>
          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            selectedLabelKey='label'
            bottomsheetTitle={form.$("secondary-annuitant-gender").label}
            menuList={form.$("secondary-annuitant-gender").extra.options}
            value={form.$("secondary-annuitant-gender").value}
            onSelect={form.$("secondary-annuitant-gender").onChange}
            disabled={form.$("secondary-annuitant-gender").disabled}
          />
        </Col>
      </Row>
    </div>
  );
};

export default observer(ForeverPension);
