import React, { Component } from 'react';

import { Tabs, Tab, Row, Col } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import { CustomCheckbox, DeathCoverPriceComponent } from '../../../../components';
import FamilyProtectionStore from '../../store/FamilyProtectionStore';


import AllProducts from '../products/all-products/all-products'
import { GrowGreenIcon, EscalationYellowIcon, EscalationRedIcon } from 'constants/Images';

const ProductTabComponent = observer(class ProductTabComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            key: 'high',
            responseData: {},
            amountData: {},
            targetAmount: 0,
            frequency: ''
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.key != this.state.key) {
            const { cardKey, data } = this.props;
            let needName = cardKey
            let id = data.id
            let tab = this.state.key
            FamilyProtectionStore.updateTabChangeData(needName, id, tab)
        }
    }

    onValueUpdate = (data, key) => {
        let amountData = this.state.amountData
        amountData[key] = data

        this.setState({ targetAmount: data.targetAmount, amountData })
    }

    onPriceChange = (price, key) => {
        let existingPrice = this.state.price
        existingPrice[key] = price
        this.setState({
            price: existingPrice
        })
    }

    getTitle = (key) => {
        let title = ''
        switch (key) {
            case 'low':
                title = 'Guaranteed'
                break
            case 'medium':
                title = 'Participatory'
                break
            case 'high':
                title = 'Unit Linked'
                break
        }
        return title
    }

    render() {
        const { key, amountData } = this.state;
        const { data } = this.props;
        // const investmentTarget = responseData.SB_G + responseData.CASH_BONUS_BS_2 + responseData.TOTAL_MB_G_BS_2

        const BI = JSON.parse(JSON.stringify(data.BI))
        return (
            <div className='investment-strategy'>
                <Row>
                    <Col md={9} className='col-12 mt-3 mt-md-4 mt-md-0'>
                        <DeathCoverPriceComponent firstAmount={amountData[key] && amountData[key].targetAmount || 0} secondAmount={amountData[key] && amountData[key].price || 0} thirdAmount='' showAdditionalText={false} showSecondColAmount={false} secondColBottomText={amountData[key] && amountData[key].frequency.label || ''} firstColTitleText='Investment Target ' secondColTitleText='Price' />
                    </Col>
                </Row>
                <div className='border rounded mt-3'>
                    <Tabs
                        activeKey={key}
                        onSelect={(key) => this.setState({ key })}
                        className='nav-fill flex-nowrap'
                    >
                        {
                            BI.reverse().map((plan, index) => {
                                // console.log('TAB COMPONENT >>  plan >> ', plan)
                                return (
                                    <Tab
                                        key={index}
                                        className='px-2'
                                        eventKey={plan.risk}
                                        disabled={Object.keys(plan.BI).length == 0}
                                        title={
                                            <TitleComponent
                                                isSelected={key == plan.risk}
                                                risk={plan.risk}
                                                title={this.getTitle(plan.risk)}
                                                amount={global.toINR(amountData[plan.risk] && amountData[plan.risk].price || 0)}
                                            />
                                        }
                                    >
                                        <AllProducts
                                            tab={plan.risk}
                                            selectedTab={key}
                                            {...this.props}
                                            data={{ ...data, ...plan }}
                                            productName={plan.product_name}
                                            onValueUpdate={(value) => this.onValueUpdate(value, plan.risk)}
                                        />
                                    </Tab>
                                )
                            })
                        }
                    </Tabs>
                </div>
            </div>
        );
    }
});

const TitleComponent = (props) => {
    const { isSelected, risk, title, amount } = props;
    let riskFactor = 'text-success'
    let riskTitle = 'Low Risk'
    let riskImg = ''
    switch (risk) {
        case 'low':
            riskFactor = 'text-success'
            riskTitle = 'Low Risk'
            riskImg = GrowGreenIcon;
            break;
        case 'medium':
            riskFactor = 'mid-risk'
            riskTitle = 'Medium Risk'
            riskImg = EscalationYellowIcon;
            break;
        case 'high':
            riskFactor = 'text-danger'
            riskTitle = 'High Risk'
            riskImg = EscalationRedIcon;
            break;


    }
    return (
        <div className='text-left text-black'>
            <div className={`tab-line ${isSelected ? 'active' : ''}  mb-3`}></div>
            <CustomCheckbox
                isChecked={isSelected}
                onChange={() => { }}
                shape='rounded'
                title={isSelected ? 'Selected' : 'Tap to select'}
                titleStyle={`font-size-11 font-size-20 font-size-lg-24 pl-1 mt-1 ${isSelected ? 'text-black ' : 'title-info'}`}
                containerStyle='align-items-start align-items-md-center'
                mark='checkmark'
            />
            <div className='text-primary font-weight-medium font-size-15 font-size-md-26 font-size-lg-32'>{title}</div>
            <div className='d-flex flex-wrap flex-column flex-lg-row-reverse justify-content-between font-size-15 font-size-md-18 font-size-lg-24
font-size-11 font-size-md-18 font-size-lg-22'>
                <div className={`d-flex align-items-center ${riskFactor}`}>{riskImg && <img id='risk-img' src={riskImg} alt={riskTitle} className='mr-1'></img>}<span>{riskTitle}</span></div>
                <div className=''>{amount} <span className='text-description-text-color'>per year</span></div>
            </div>
        </div>
    )
}

ProductTabComponent.defaultProps = {
    disabled: false
}

ProductTabComponent.propTypes = {
    addInsured: PropTypes.func,
    data: PropTypes.object,
    disabled: PropTypes.bool
}

export default ProductTabComponent;