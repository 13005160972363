const setFundAllocation = (fundList, biRequest) => {

    fundList = fundList.map((fund) => {
        fund.value = biRequest[fund.key];
        if (fund.value != '' && fund.value != 0) {
            fund.checkedValue = true;
        }
        return fund;
    })

    return fundList;

}

export default setFundAllocation