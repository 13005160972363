// React Imports
import React, { useState } from "react";

// Package Imports

// Project Imports
import AxiosErrorModal from "./axios-error-modal/AxiosErrorModal";
import FormContext from "context/FormContext";
import QmsDashboard from "./dashboard/components/QmsDashboard";
import QmsHeader from "components/ui/qms-header/QmsHeader";

const QmsHeaderComponent = (props) => {

    const [formController, setFormController] = useState(undefined)
    const [callbackFunctions, setCallbackFunctions] = useState({
        success: undefined,
        error: undefined
    });
    const [errorClass, setErrorClass] = useState("")

    const updateErrorClass = (value) => {
        setErrorClass(value);
    }

    return (
        <FormContext.Provider value={{ formController, setFormController, callbackFunctions, setCallbackFunctions }}>
            <QmsHeader {...props} updateErrorClass={updateErrorClass} />
            <div className='pt-5 mt-2 mt-lg-0'>
                <QmsDashboard {...props} errorClass={errorClass} />
            </div>
            <AxiosErrorModal {...props} />
        </FormContext.Provider>
    )
}

export default QmsHeaderComponent;