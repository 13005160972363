// Package Imports
import { toJS } from "mobx";
import moment from "moment";

// Project Imports
import axios from "utils/axios";
import * as APITypes from "constants/API";
import AuthStore from "modules/login/store/AuthStore";
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import incomeTypeEnum from "constants/incomeTypeEnum";
import fetchNeedMaster from "utils/fetchNeedMasterData";
import submitEducationOccupationLocationData from "utils/submitEducationOccupationLocationData";
import FamilyMemberModalTypeEnum from "constants/familyMemberModalTypeEnum";
import StorageKeys from "constants/StorageKeys";
import NeedTypeEnum from "constants/NeedTypeEnum";
import checkIsValueEmpty from "utils/checkIsValueEmpty";

const submitDataToTheServer = async (formController, hideProtectionModal, coverAmountType) => {
  //const dataToBeSubmitted = submitEducationOccupationLocationData(formController,OptimizationStore,AuthStore)
  // let response = await axios.post(
  //   APITypes.SAVE_FAMILY_DETAILS,
  //   dataToBeSubmitted
  // )

  // !calling the backend api Fetch BI Data based on provided recommendation
  const formFieldValues = formController.get();
  const targetAmountMidValue = formFieldValues.target_amount_dropdown.value.mid_value;

  ProtectionForFamilyBiDataApiCall(targetAmountMidValue, hideProtectionModal, formController, coverAmountType);
};

const isValidFunction = async (formController) => {
  const validValue = formController.validate({ showErrors: true }).then(({ isValid }) => {
    if (!isValid) {
      console.log("Form errors are ", formController.errors());
    }
    return isValid;
  });

  return validValue;
};

export const onRecommend = async (formController, hideProtectionModal) => {
  const valid = await isValidFunction(formController);

  if (valid) {
    OptimizationStore.isCalculatorUsed = false
    submitDataToTheServer(formController, hideProtectionModal, "recommend");
    //hideProtectionModal()
  }
};

export const onSet = async (formController, hideProtectionModal) => {

  let valid = await isValidFunction(formController);

  let isTargetAmountValueEmpty = checkIsValueEmpty(formController.get().target_amount_dropdown.value)

  if(isTargetAmountValueEmpty){
    formController.$("target_amount_dropdown").invalidate("This field is mandatory")
    valid = false
  }

  if (valid) {
    OptimizationStore.isCalculatorUsed = false
    submitDataToTheServer(formController, hideProtectionModal);
  }
};

export const onLaunch = async (formController, openProtectionCalculator, getFamilyMemberType) => {
  const valid = await isValidFunction(formController);
  OptimizationStore.needFormController = formController
  if (valid) {
    openProtectionCalculator();
    getFamilyMemberType();
  }
};

export const ProtectionForFamilyBiDataApiCall = async (targetAmountValue, hideModal = OptimizationStore.resetSelectedNeedAndMemberId, formController, coverAmountType) => {
  let needFormController = formController ? formController : OptimizationStore.needFormController
  let masterData = await fetchNeedMaster();

  if (masterData.length <= 0) return;
  let selectedNeedId = masterData.find((singleNeed) => singleNeed.unique_name === OptimizationStore.selectedNeed).id;

  let recommendationReqDataToBeSubmitted = {
    user_journey: toJS(AuthStore.journeyId),
    family: OptimizationStore.selectedFamilyMemberId,
    need: selectedNeedId,
    target_amount_value: targetAmountValue,
    payout_mode: incomeTypeEnum.LUMPSUM,
  };
  let family = {};
  let formFieldValues = {};
  if (needFormController) {
    const dataToBeSubmitted = submitEducationOccupationLocationData(needFormController, OptimizationStore, AuthStore);
    family = dataToBeSubmitted.family_list[0];
    formFieldValues = needFormController.get();
  }
  let targetAmount = formFieldValues?.target_amount_dropdown?.value?.id ? formFieldValues.target_amount_dropdown.value.id : ""
  switch (OptimizationStore.familyMemberModalType) {
    case FamilyMemberModalTypeEnum.CHANGE_LA:
      // props.changeLA()
      // const dataToBeSubmitted = submitEducationOccupationLocationData(formController,OptimizationStore,AuthStore)
      // const family = dataToBeSubmitted.family_list[0]
      // const formFieldValues = formController.get()
      const changeLAObj = {
        is_selected: family.is_selected,
        occupation_id: family.occupation,
        education_id: family.education,
        state: family.state,
        office_name: family.office_name,
        pin_code: family.pin_code,
        target_amount: !OptimizationStore.isCalculatorUsed ? targetAmount || null : null,
        target_amount_value: OptimizationStore.isCalculatorUsed ? targetAmountValue : "",
        payout_mode:  !OptimizationStore.isCalculatorUsed && targetAmount === "" ? "RECOMMENDED_LUMPSUM" : "lumpsum",
      };
      // let response = await axios.post(
      //   APITypes.CHANGE_LIFE_ASSURED,
      //   obj
      // )
      OptimizationStore.changeLA(changeLAObj, hideModal,NeedTypeEnum.PROTECTION_FOR_MY_FAMILY);
      return;
      break;
    case FamilyMemberModalTypeEnum.ADD_INSURED:
      let familyMemberData = OptimizationStore.fetchFamilyMemberListForLifeAssured().find((item) => item.id === OptimizationStore.selectedFamilyMemberId);

      const addInsuredObj = {
        family_member_id: familyMemberData.id,
        user_journey_id: toJS(AuthStore.journeyId),
        need_id: selectedNeedId,
        is_selected: family.is_selected,
        occupation_id: family.occupation,
        education_id: family.education,
        state: family.state,
        office_name: family.office_name,
        pin_code: family.pin_code,
        is_calculator_used: OptimizationStore.isCalculatorUsed,
        monthly_income: familyMemberData.monthly_income,
        dob: familyMemberData.dob,
        target_amount_id: !OptimizationStore.isCalculatorUsed ? targetAmount || null : null,
        target_amount_value: OptimizationStore.isCalculatorUsed ? targetAmountValue : "",
        payout_mode: !OptimizationStore.isCalculatorUsed && targetAmount === "" ? "RECOMMENDED_LUMPSUM" :  "lumpsum",
      };
      let responseData = await axios.post(APITypes.ADD_INSURED, addInsuredObj);

      if (responseData.data.status !== "error") {
        localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, false);
        OptimizationStore.addInsuredData(responseData.data, hideModal);
      }

      //OptimizationStore.changeLA(addInsuredObj)
      return;
      break;

    case FamilyMemberModalTypeEnum.ADD_NEED:
      let journeyData = toJS(AuthStore.journeyData)
      let memberData = journeyData.family_details_list.find((member) => member.id === OptimizationStore.selectedFamilyMemberId)
      let addNeedObj = {
        "family_id": OptimizationStore.selectedFamilyMemberId,
        "journey_id": toJS(AuthStore.journeyId),
        "need_id": selectedNeedId,
        "payout_mode": !OptimizationStore.isCalculatorUsed && targetAmount === "" ? "RECOMMENDED_LUMPSUM" :   incomeTypeEnum.LUMPSUM.toLowerCase(),
        "target_amount": !OptimizationStore.isCalculatorUsed ? targetAmount || null : null,
        "target_value": OptimizationStore.isCalculatorUsed ? targetAmountValue : "",
        "is_calculator_used": OptimizationStore.isCalculatorUsed,
        "is_selected": true,
        "is_removed": false,
        "occupation_id": family.occupation,
        "education_id": family.education,
        "state": family.state,
        "office_name": family.office_name,
        "pin_code": family.pin_code,
        "dob": memberData.dob,
      }
      OptimizationStore.addNeedAPICall(addNeedObj, OptimizationStore.selectedNeed, hideModal)
      return

  }
  let responseDataArray = [];
  let biResponse = await axios.post(APITypes.FETCH_BI_DATA_BASED_ON_PROVIDED_RECOMMENDATION, recommendationReqDataToBeSubmitted);

  responseDataArray.push(biResponse.data);

  if (hideModal !== undefined) {
    hideModal();
  }

  OptimizationStore.addNeed({
    data: responseDataArray,
  });
};
