export default {
    ZINDAGI_PLUS: "400381",
    SIMPLY_PROTECT: "400121",
    ACTIVE_INCOME_PLAN: "400351",
    THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN: "400352",
    ACTIVE_INCOME_PLAN_WITH_FIB: "400355",
    WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN: "400353",
    RETIRE_BEFIKER_ACTIVE_INCOME_PLAN: "400354",
    WEALTH_ULTIMA: "400091",
    SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA: "400092",
    LITTLE_CHAMP_WEALTH_ULTIMA: "400093",
    CRITICARE_PLUS: "400141",
    SMART_LIFESTYLE: "400051",
    FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE: "400052",
    GCAP: "400071",
    CASH_FLOW_PROTECTION_PLUS: "400041",
    WEALTH_GAIN_PLUS: "400301",
    WEALTH_SECURE_PLUS: "400311",
    CHILD_VARIANT_WEALTH_SECURE_PLUS: "400313",
    SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_SECURE_PLUS: "400312",
    DHAN_LABH: "400151",
    WEALTH_PLUS: "400231",
    RISING_STAR_WEALTH_PLUS: "400232",
    INCOME_BUILDER: "400031",
    SECURED_INCOME_OPTION_INCOME_BUILDER: "400032",
    // IMMEDIATE_ANNUITY_PLAN: "Immediate Annuity Plan",
    EASY_PENSION: "400321",
    SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN: "400191",
    POS_SARAL_NIVESH: "400421",
    WEALTH_PREMIER: "400341",
    // PROTECTION_REG_5_10_15PAY: "Protection  - (Reg/5/10/15Pay)",
    SARAL_JEEVAN_BIMA: "400391",
    POS_SARAL_JEEVAN_BIMA: "400392",
    SARAL_PENSION: "400411",
    // FOREVER_PENSION: "Forever Pension",
    POS_FOREVER_PENSION: "400403",
    FOREVER_PENSION_DEFERRED_ANNUITY: "400402",
    FOREVER_PENSION_IMMEDIATE_ANNUITY: "400401",
    TOTAL_PROTECT_PLUS: "400441",
    PREMIER_GUARANTEED_SHORT_TERM_INCOME: "400482",
    PREMIER_GUARANTEED_LONG_TERM_INCOME: "400483",
    PREMIER_GUARANTEED_INCOME_LUMPSUM: "400481",
    PREMIER_GUARANTEED_RETIREMENT_INCOME: "400484",
    PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB: "400485",
    PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB: "400486",
    PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB: "400487",
    GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED: "400461",
    GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED: "400462",
    GUARANTEED_INCOME_STAR_BETTER_INNINGS: "400463",
    GUARANTEED_INCOME_STAR: "400464",
    GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB: "400465",
    GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB: "400466",
    GUARANTEED_INCOME_STAR_FIB: "400467",
    POS_GUARANTEED_INCOME_STAR: "400468",
    POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED: "400469",
    POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS: "4004610",
    GUARANTEED_SAVINGS_STAR_LUMPSUM: "400471",
    GUARANTEED_SAVINGS_STAR_INCOME: "400472",
    GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB: "400473",
    GUARANTEED_SAVINGS_STAR_INCOME_FIB: "400474",
    GUARANTEED_SAVINGS_STAR: "400475",
    FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL: "400491",
    FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL: "400492",
    FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL: "400493",
    FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME: "400494",
    FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO: "400495",
    FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION: "400496",
    FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL: "400497",
    FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL: "400498",
    FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL: "400499",
    FLEXI_SAVINGS_PLAN_FLEXI_INCOME: "4004910",
    FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO: "4004911",
    FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION: "4004912",
};
