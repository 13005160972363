import ProductConstants from "constants/ProductConstants"

const childFutureProtectBenefitFunction = (productData) => {

 // PayoutOptions :  (39 for lumpsum), (51 for monthlyIncome), (52 for lumpsum + income)

 const {product_name,bi_request:{ChildEntryAge,PayoutOptions,ChildsFutureProtectBenefitSAPercentOption},biResponseCompleteData} = productData



   switch (product_name) {
    case ProductConstants.TOTAL_PROTECT_PLUS:

     if(ChildEntryAge?.length !== 0 && parseInt(PayoutOptions) === 51 && ChildsFutureProtectBenefitSAPercentOption?.length !== 0){ // (51 for monthlyIncome)

      return <p className="text-success mb-0 pl-3 mt-2 font-size-12">{`Life cover of Rs. ${Math.round(biResponseCompleteData[0].SUMASSURED_DEATH)} for first ${ 25 - ChildEntryAge } years and Rs. ${Math.round(biResponseCompleteData[25 - ChildEntryAge + 1 ].TOTAL_DB_MONTHLY)} year thereafter.`}
      </p>
      
   
     }

     if(ChildEntryAge?.length !== 0 && parseInt(PayoutOptions) === 39 && ChildsFutureProtectBenefitSAPercentOption?.length !== 0){ // (39 for lumpsum)

      return <p className="text-success mb-0 pl-3 mt-2 font-size-12">{`Life cover of Rs. ${Math.round(biResponseCompleteData[0].SUMASSURED_DEATH)} for first ${ 25 - ChildEntryAge } years and Rs. ${Math.round(biResponseCompleteData[25 - ChildEntryAge + 1].SUMASSURED_DEATH)} year thereafter.`}
      </p>
   
     }

     if(ChildEntryAge?.length !== 0 && parseInt(PayoutOptions) === 52 && ChildsFutureProtectBenefitSAPercentOption?.length !== 0){ // (52 for lumpsum + income)

      return <p className="text-success mb-0 pl-3 mt-2 font-size-12">{`Life cover of Rs. ${Math.round(biResponseCompleteData[0].SUMASSURED_DEATH)} for first ${ 25 - ChildEntryAge } years and Rs. ${Math.round(biResponseCompleteData[25 - ChildEntryAge + 1].DB_LUMPSUM + biResponseCompleteData[25 - ChildEntryAge + 1].TOTAL_DB_MONTHLY)} year thereafter.`}
      </p>
   
     }
     
     break;
   
    default:
     break;
   }

 
}

export default childFutureProtectBenefitFunction