const initialDefaults = (formRef, data) => {
    let productData = data.data
    let biResponse = productData.parsedResponse;
    let biRequest = productData.bi_request;

    let frequency = biRequest.INPUT_MODE
    let premium = biRequest.PR_SA

    formRef.$('policy-term').value = {
        id: biRequest.PR_PT,
        label: 'Upto Age 100'
    }

    formRef.$('premium').value = premium;

    formRef.$('premium-term').value = {
        id: biRequest.PR_PPT,
        label: biRequest.PR_PPT
    }

    switch (frequency) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '2':
            formRef.$('payment-frequency').set('value', {
                id: '2',
                label: 'Semi-Annual'
            })
            break;
        case '4':
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;

        default:
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
    }

    if (biRequest.ADBRider) {
        formRef.$('ADB').set('value', biRequest.ADBRider !== '')
        if (biRequest.ADBRider) {
            formRef.$('ADB-dropdown').set('value', biRequest.ADBRider)
        }
    }

    if (biRequest.ATPDRider) {
        formRef.$('ATPDRider').set('value', biRequest.ATPDRider !== '')
        if (biRequest.ATPDRider) {
            formRef.$('ATPDRider-dropdown').set('value', biRequest.ATPDRider)
        }
    }

    if (biRequest.CriticalIllnessRider) {
        formRef.$('CriticalIllnessRider').set('value', biRequest.CriticalIllnessRider !== '')
        if (biRequest.CriticalIllnessRider) {
            formRef.$('CriticalIllnessRider-dropdown').set('value', biRequest.CriticalIllnessRider)
        }
    }

    if (biRequest.IncomeBenefitRider) {
        formRef.$('IncomeBenefitRider').set('value', biRequest.IncomeBenefitRider !== '')
        if (biRequest.IncomeBenefitRider) {
            formRef.$('IncomeBenefitRider-dropdown').set('value', biRequest.IncomeBenefitRider)
        }
    }

    if (biRequest.WaiverOfPremiumRider) {
        formRef.$('WaiverOfPremiumRider').set('value', biRequest.WaiverOfPremiumRider !== '')
    }

    if (biRequest.PayorWaiverBenefitRider) {
        formRef.$('PayorWaiverBenefitRider').set('value', biRequest.PayorWaiverBenefitRider !== '')
        if (formRef.$('PayorWaiverBenefitRider').value) {
            switch (biRequest.PWB) {
                case '1':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '1',
                        label: 'Option 1 (On Death)'
                    })
                    break;
                case '2':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '2',
                        label: 'Option 2 (CI or ATPD)'
                    })
                    break;
                case '3':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '3',
                        label: 'Option 3 (CI or ATPD or Death)'
                    })
                    break;


                default:
                    break;
            }
        }
    }


}

export default initialDefaults