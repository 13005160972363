import React, { Component } from "react";
import { InputGroup, FormControl, Image, Col, Row } from "react-bootstrap";

import { GraphSlider, Button as UiButton } from "../../../../components";
import OptimizationStore from "../../store/OptimizationStore";
import PropTypes from "prop-types";
import axios from "utils/axios";
import * as images from "constants/Images";
import * as APITypes from "constants/API";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";
import incomeTypeEnum from "constants/incomeTypeEnum";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

import Utils from "constants/utils";

import "./death-cover-graph-slider.css";
import fetchErrorMessageBasedOnAmountAndPlan from "utils/fetchErrorMessageBasedOnAmountAndPlan";
import fetchRecommendedTargetLabelBasedOnNeed from "utils/fetchRecommendedTargetLabelBasedOnNeed";
import calculateProductPremiumRelatedValues from "utils/calculateProductPremiumRelatedValues";
import forwardNreverseCalculationBIRequestManager from "utils/forward-reverse-methods/forwardNreverseCalculationBIRequestManager";
import targetAmountRoundOffLogic from "utils/optimization/TargetAmountRoundOffLogic";
import ProductNames from "constants/ProductNames";
import TppUtils from "../products/total-protect-plus/utils";
import * as TppKeys from "../products/total-protect-plus/keys";
import fetchPremiumLabelBasedOnNeed from "utils/fetchPremiumLabelBasedOnNeed";
import updateDeathCoverGraphSliderTargetAmount from "utils/forward-reverse-methods/updateDeathCoverGrapSliderTargetAmount";
import calculateInputModalPremium from "utils/forward-reverse-methods/calculateInputModalPremium";
import prefillDeathCoverGraphSliderAllAmounts from "utils/forward-reverse-methods/prefillDeathCoverGraphSliderAllAmounts";
import ProductConstants from "constants/ProductConstants";

import maturityBenefitAmount from "utils/optimization/maturityBenefitAmount";
import ProductPackageId from "constants/ProductPackageId";
import showMaturityBenefitAmount from "utils/optimization/showMaturityBenefitAmount";
import showSurvivalBenefitAmount from "utils/optimization/showSurvivalBenefitAmount";
import survivalBenefitAmount from "utils/optimization/survivalBenefitAmount";

import zpValidator from "../products/zindagi-plus/validator";
import aipValidator from "../products/active-income-plan/validator";
import cashFlowProtectionPlusValidator from "../products/cashflow-protection-plus/validator";
import criticarePlusValidator from "../products/criticare-plus/validator";
import dhanLabhValidator from "../products/dhan-labh-plus/validator";
import easyPensionValidator from "../products/easy-pension/validator";
import foreverPensionValidator from "../products/forever-pension/validator";
import gcapValidator from "../products/gcap/validator";
import gisValidator from "../products/GIS/validator";
import gssValidator from "../products/gss/validator";
import immediateAnnuityPlanValidator from "../products/immediate-annuity-plan/validator";
import incomeBuilderValidator from "../products/income-builder/validator";
import saralNiveshValidator from "../products/POS-saral-nivesh/validator";
import pgiValidator from "../products/premium-guaranteed-income/validator";
import sjbValidator from "../products/saral-jeevan-bima/validator";
import saralPensionValidator from "../products/saral-pension/validator";
import simplyProtectValidator from "../products/simply-protect/validator";
import singlePayEndowmentAssurancePlanValidator from "../products/single-pay-endowment-assurance-plan/validator";
import smartLifestyleValidator from "../products/smart-life-style/validator";
import totalProtectPlusValidator from "../products/total-protect-plus/validator";
import wealthGainPlusValidator from "../products/wealth-gain-plus/validator";
import wealthPlusValidator from "../products/wealth-plus/validator";
import wealthPremierValidator from "../products/wealth-premier/validator";
import wealthSecureValidator from "../products/wealth-secure-plus/validator";
import wealthUltimaValidator from "../products/wealth-ultima/validator";
import fspValidator from "../products/flexi-savings-plan/validator";
import checkTargetAmountGreaterThanRecommendedAmount from "utils/optimization/checkTargetAmountGreaterThanRecommendedAmount";
import fetchTargetAmountLabel from "utils/fetchTargetAmountLabel";
import childFutureProtectBenefitFunction from "../products/total-protect-plus/childFutureProtectBenefitFunction";
import checkProductWoringBasedOnPremiumAmount from "utils/forward-reverse-methods/checkProductWoringBasedOnPremiumAmount";
import { RecalculateIcon } from "constants/Images";
import fetchIncomeFrequencyLabel from "utils/fetchIncomeFrequencyLabel"
import calculateTargetAmount from "utils/forward-reverse-methods/calculateTargetAmount";
class DeathCoverGraphSlider extends Component {
  constructor(props) {
    super(props);
    console.log("constructor");
    this.state = {
      income: "",
      premium: "",
      isIncomeDisable: false,
      isPremiumDisable: false,
      minPremium: 0,
      recommendedTargetValue: 0,
      recommendedPremium: 0,
      selectedPremium: this.props.premium_value,
      recommendedTarget: 0,
      showValidation: false,
      targetValue: this.props.productData.bi_request.investment_target,
      minTargetValue: 0,
      maxTargetValue: 0,
      maxPremium: 0,
      targetSaMoreThanRecoSa: false,
      isValueBetween25LakhAnd50Lakh:false,
      premiumAmountChanged: false,
      recommendedPremiumFrequency: "",
      premiumOrTargetAmtChanged : ""
    };

    this.updateIncomeValue = this.updateIncomeValue.bind(this);
    this.updatePremiumValue = this.updatePremiumValue.bind(this);
    this.onPremiumValueBlurEvent = this.onPremiumValueBlurEvent.bind(this);
    this.validateValue = this.validateValue.bind(this);
    this.updatePremiumValues = this.updatePremiumValues.bind(this);
    this.onSliderValueChange = this.onSliderValueChange.bind(this);
    this.updateRecommendedPremium = this.updateRecommendedPremium.bind(this);
    this.updateRecommendedTarget = this.updateRecommendedTarget.bind(this);
    this.onSliderOverlayHide = this.onSliderOverlayHide.bind(this);
    this.onTargetValueBlurEvent = this.onTargetValueBlurEvent.bind(this);
    this.onPremiumValueChange = this.onPremiumValueChange.bind(this);
    this.onTargetValueChange = this.onTargetValueChange.bind(this);
    this.onRecalculate = this.onRecalculate.bind(this);
  }

  async componentDidMount() {
    const { income_value, premium_value } = this.props;
    this.setState({
      income: income_value,
      premium: premium_value,
    });
    let { targetValue, selectedPremium, recommendedTargetValue, minTargetValue, maxTargetValue } = prefillDeathCoverGraphSliderAllAmounts(this.props.productData);
    this.setState({
      selectedTargetValue: targetValue,
      targetValue,
      selectedPremium,
      recommendedTargetValue,
      recommendedTarget: recommendedTargetValue,
      minTargetValue,
      maxTargetValue,
      targetSaMoreThanRecoSa: this.props.productData.target_sa_more_than_reco_sa
    });
    console.log("componentDidMount selectedPremium ::: ", selectedPremium);
    this.updatePremiumValues(null);
  }

  componentDidUpdate(prevProps) {
    console.log("\n#$#$#$#$#$#$#$####$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$#$###$#$#$#$#$")
    console.log("componentDidUpdate this.props.productData ::: ", this.props.productData);
    console.log("componentDidUpdate prevProps.productData ::: ", prevProps.productData);
    console.log("componentDidUpdate Premium amount ::: ", this.state.selectedPremium);
    console.log("componentDidUpdate this.props.productData.firstYearResponse.MODAL_PREM ::: ", Math.round(this.props?.productData?.firstYearResponse?.MODAL_PREM));
    console.log("componentDidUpdate this.props.productData.family_product_id  ::: ", this.props.productData.family_product_id);
    console.log("componentDidUpdate this.props.productData.family_member_name  ::: ", this.props.productData.family_member_name);
    console.log("componentDidUpdate this.props.productData.selected_sum_Assured  ::: ", this.props.productData.selected_sum_Assured);
    if (this.props.productData.fieldChanged != prevProps.productData.fieldChanged) {
      this.setState({ showOverlay: this.props.productData.fieldChanged });
    }
    // if (this.props.productData.fieldChanged && this.props.productData.parsedResponse.MODAL_PREM != prevProps.productData.parsedResponse.MODAL_PREM) {
    // if(Math.round(this.props?.productData?.firstYearResponse?.MODAL_PREM) != Math.round(this.state.selectedPremium) && !this.state.premiumAmountChanged){
    // if (Math.round(this.props?.productData?.firstYearResponse?.MODAL_PREM) != Math.round(this.state.selectedPremium) && !this.state.premiumAmountChanged) {
    //   this.setState({ selectedPremium: Math.round(this.props.productData.firstYearResponse.MODAL_PREM) });
    // }
    // if ( this.props?.productData?.firstYearResponse?.MODAL_PREM != prevProps?.productData?.firstYearResponse?.MODAL_PREM) {
    //   this.setState({ selectedPremium: Math.round(this.props.productData.firstYearResponse.MODAL_PREM) });

    // let targetAmountData = checkTargetAmountGreaterThanRecommendedAmount(this.props.productData.firstYearResponse.SA, this.state.recommendedTargetValue, this.props.productData.bi_request, this.props.productData.product_id)
    // if (targetAmountData.isTargetAmtGreaterThanRecoAmt) {
    //   this.setState({ targetSaMoreThanRecoSa: targetAmountData.isTargetAmtGreaterThanRecoAmt })

    //   OptimizationStore.updateStoreRequestData(this.props.productData.product_id, targetAmountData.biRequest);

    // }
    // if (this.props.productData.fieldChanged) {
    //   let request = {
    //     ...this.props.productData.bi_request,
    //     PR_ANNPREM: this.props.productData.firstYearResponse.MODAL_PREM,
    //   };
    //   OptimizationStore.updateStoreRequestData(this.props.productData.product_id, request);
    // }
    // }

    // Update target amount and selected premium amount based on updated props.productdata
    if(!this.props.productData.fieldChanged){
    let updatedTargetValue = updateDeathCoverGraphSliderTargetAmount(this.props.productData, prevProps.productData, this.state.targetValue, this.state.selectedPremium);
    if (updatedTargetValue.isTargetAmountUpdated) {
      this.setState({ targetValue: updatedTargetValue.targetValue });
      OptimizationStore.updateSelectedTargetAmount(this.props.productData, updatedTargetValue.targetValue)
    }

    if (!this.state.premiumAmountChanged && updatedTargetValue.isPremiumAmountUpdated) {
      this.setState({ selectedPremium: updatedTargetValue.premiumAmount });
    }
    if (updatedTargetValue.isPremiumAmountUpdated) {
      this.setState({ selectedPremium: updatedTargetValue.premiumAmount });

      // Target amount greater than recommended amount for term need
      let targetAmountData = checkTargetAmountGreaterThanRecommendedAmount(this.props.productData?.firstYearResponse?.SA, this.props.productData.recommended_sum_assured, this.props.productData.bi_request, this.props.productData.product_id)
      if (targetAmountData.isTargetAmtGreaterThanRecoAmt) {
        this.setState({ targetSaMoreThanRecoSa: targetAmountData.isTargetAmtGreaterThanRecoAmt })

        OptimizationStore.updateStoreRequestData(this.props.productData.product_id, targetAmountData.biRequest, this.props.productData.family_product_id);
      }

      if(targetAmountData.isValueBetween25LakhAnd50Lakh){
        this.setState({ isValueBetween25LakhAnd50Lakh: targetAmountData.isValueBetween25LakhAnd50Lakh }) 
        OptimizationStore.updateStoreRequestData(this.props.productData.product_id, targetAmountData.biRequest, this.props.productData.family_product_id);
      }
    }}

    if (this.props?.productData?.recommended_premium && this.props?.productData?.recommended_premium != prevProps?.productData?.recommended_premium) {
      this.setState({ recommendedPremium: this.props.productData?.recommended_premium });
    }
    if (this.props?.productData?.recommended_sum_assured && this.props?.productData?.recommended_sum_assured != prevProps?.productData?.recommended_sum_assured) {
      this.setState({ recommendedTargetValue: this.props.productData?.recommended_sum_assured, recommendedTarget: this.props.productData?.recommended_sum_assured });
    }
    if (this.props?.productData?.maximum_sum_assured && this.props?.productData?.maximum_sum_assured != prevProps?.productData?.maximum_sum_assured) {
      this.setState({ maxTargetValue: this.props.productData?.maximum_sum_assured });
    }
    if (this.props?.productData?.maximum_premium && this.props?.productData?.maximum_premium != prevProps?.productData?.maximum_premium) {
      this.setState({ maxPremium: this.props.productData?.maximum_premium });
    }
    this.updateAmountBasedOnVaccinationStatus(prevProps)
    // if(this.props.productData.product_id === ProductNames.criticarePlus){
    //   if (this.props.productData.parsedResponse.CI_SA != prevProps.productData.parsedResponse.CI_SA) {
    //     this.setState({ targetValue: Math.round(this.props.productData.parsedResponse.CI_SA) });
    //     if (this.props.productData.fieldChanged) {
    //       let request = {
    //         ...this.props.productData.bi_request,
    //         PR_SA: Math.round(this.props.productData.parsedResponse.CI_SA),
    //       };
    //       OptimizationStore.updateStoreRequestData(this.props.productData.product_id, request);
    //     }
    //   }
    // } else {
    //   if (this.props.productData.parsedResponse.SA != prevProps.productData.parsedResponse.SA) {
    //     this.setState({ targetValue: this.props.productData.parsedResponse.SA });
    //     if (this.props.productData.fieldChanged) {
    //       let request = {
    //         ...this.props.productData.bi_request,
    //         PR_SA: this.props.productData.parsedResponse.SA,
    //       };
    //       OptimizationStore.updateStoreRequestData(this.props.productData.product_id, request);
    //     }
    //   }
    // }
  }

  updateAmountBasedOnVaccinationStatus(prevProps) {
    switch (this.props.productData.product_id) {
      case ProductNames.zindagiPlus:
      case ProductNames.saralJeevanBima:
      case ProductNames.totalProtectPlus:
        if (this.props?.productData?.selected_sum_Assured && this.props?.productData?.selected_sum_Assured != prevProps?.productData?.selected_sum_Assured) {
          this.setState({ targetValue: this.props.productData?.selected_sum_Assured });
        }
        if (this.props?.productData?.selected_premium && this.props?.productData?.selected_premium != prevProps?.productData?.selected_premium) {
          this.setState({ selectedPremium: this.props.productData?.selected_premium });
        }
        break;
      default:
        break;
    }
  }

  updateIncomeValue(e) {
    const data = this.props.data;
    this.setState({
      income: e.target.value,
      isPremiumDisable: true,
    });
    data.annual_price = e.target.value;
    // this.props.onClick();
    OptimizationStore.updateDetails(data);
  }

  updatePremiumValue(e) {
    const data = this.props.data;
    this.setState({
      premium: e.target.value,
      isIncomeDisable: true,
    });
    // this.props.onClick();
    // data.premium_amount = e.target.value;
    // OptimizationStore.updateDetails(data);
    this.props.onPremiumChange(e);
  }

  // blurEvent(event) {
  //   let value = event.target.value;
  //   if (this.props.onClick) {
  //     this.props.onClick();
  //   }
  //   if (value.length > 0) {
  //     OptimizationStore.dataChange("premium", value, "", this.props.productID);
  //   }
  // }
  onPremiumValueChange = (e) => {
    let value = e.target.value;
    let regex = /^(([1-9]\d*))?$/;
    console.log("onPremiumValueChange value ::: ", value);
    console.log("onPremiumValueChange value ::: ", typeof value);
    let premiumTxtValue = typeof value == "number" ? value : parseInt(value);
    if (regex.test(value)) {
      console.log("onPremiumValueChange  regex test pass value ::: ", premiumTxtValue);
      this.setState({ selectedPremium: value, premiumAmountChanged: true });
      this.props.onPremiumChange(e);
    }
  };

  onTargetValueChange = (e) => {
    let value = e.target.value;
    let regex = /^(([1-9]\d*))?$/;
    if (regex.test(value)) {
      let targetTxtValue = typeof value == "number" ? value : parseInt(value);
      // this.setState({ targetValue: targetTxtValue });
      this.setState({ targetValue: value });
      this.props.onIncomeChange(e);
    }
  };

  async onPremiumValueBlurEvent(event) {
    console.log("onPremiumValueBlurEvent");
    const { minPremium, maxPremium } = this.state;
    let value = event.target.value;
    let premiumBlurTxtValue = typeof value == "number" ? value : parseInt(value);
    if (value.length > 0) {
      let premiumRoundOffValue = calculateInputModalPremium(premiumBlurTxtValue, this.props.productData);
      this.setState({ selectedPremium: premiumBlurTxtValue, premiumOrTargetAmtChanged: 'premium' });
      let modifiedBIRequestFwdReverse = forwardNreverseCalculationBIRequestManager(this.props.productData.bi_request, "premium", premiumRoundOffValue, this.props.productData.package_id);
      let errorMessage = fetchErrorMessageBasedOnAmountAndPlan(this.props.cardKey, value, "premium", minPremium, maxPremium);
      if (errorMessage === "") {
        this.setState({ showValidation: false, premiumErrorMessage: null });
        await OptimizationStore.updateBiRequest(this.props.productData.product_id, modifiedBIRequestFwdReverse, this.props.productData.family_product_id)

        if (this.props.onClick) {
          this.props.onClick();
        }
      } else {
        this.setState({ showValidation: true, premiumErrorMessage: errorMessage });
      }
    }
  }

  // onTargetValueBlurEvent(event,onIncomeBlur) {
  //   const { productData } = this.props
  //   const { recommendedTargetValue } = this.state

  async onTargetValueBlurEvent(event) {
    const { productData } = this.props;
    const { recommendedTargetValue } = this.state;
    let value = event.target.value;
    if (value.length > 0) {
      console.log("#$#$#$#$#$#$#$#$#$");
      console.log("on target amount blur this.props.productData.product_id ::: ", this.props.productData.product_id);
      console.log("on target amount blur value ::: ", value);
      console.log("#$#$#$#$#$#$#$#$#$");

      let targetAmountData = checkTargetAmountGreaterThanRecommendedAmount(value, recommendedTargetValue, this.props.productData.bi_request, this.props.productData.product_id)
      let targetAmountRoundOffValue = targetAmountRoundOffLogic(this.props.productData.product_id, targetAmountData.updatedTargetAmount);
      this.setState({ targetValue: Math.round(targetAmountRoundOffValue), targetSaMoreThanRecoSa: targetAmountData.isTargetAmtGreaterThanRecoAmt, isValueBetween25LakhAnd50Lakh: targetAmountData.isValueBetween25LakhAnd50Lakh , premiumOrTargetAmtChanged: 'target'});
      let userSelectedTargetAmountValue = targetAmountRoundOffValue;
      // if (this.props.productData.product_id === ProductNames.totalProtectPlus && this.props.productData.bi_request[TppKeys.CHILD_FUTURE_PROTECT_BENEFIT] === 20) {
      //   userSelectedTargetAmountValue = TppUtils.getBaseSAIfChildFutureProtectBenefitEnabled(userSelectedTargetAmountValue, this.props.productData.bi_request[TppKeys.ADDITIONAL_SA]);
      //   this.tppChildFutureBenefitBaseSACalculation()
      // }
      let errorMessage = fetchErrorMessageBasedOnAmountAndPlan(
        this.props.cardKey,
        userSelectedTargetAmountValue,
        "target",
        parseInt(productData.minimum_sum_assured),
        parseInt(productData.recommended_sum_assured)
      );
      if (errorMessage === "") {
        let request = {
          ...this.props.productData.bi_request,
          PR_SA: value,
        };
        console.log("#$#$#$#$#$#$#$#$#$");
        console.log("on target amount blur targetamountvalue ::: ", userSelectedTargetAmountValue);
        console.log("#$#$#$#$#$#$#$#$#$");
        let modifiedBIRequestFwdReverse = forwardNreverseCalculationBIRequestManager(request, "target", userSelectedTargetAmountValue, this.props.productData.package_id);
        console.log("on target amount blur modifiedBIRequestFwdReverse ::: ", modifiedBIRequestFwdReverse);
        await OptimizationStore.updateBiRequest(this.props.productData.product_id, modifiedBIRequestFwdReverse, this.props.productData.family_product_id)
        OptimizationStore.updateSelectedTargetAmount(this.props.productData,userSelectedTargetAmountValue )
        if (this.props.onClick) {
          this.props.onClick();
        }
      } else {
        this.setState({ showValidation: true, targetErrorMessage: errorMessage });
      }
    }
    // onIncomeBlur()
  }

  async tppChildFutureBenefitBaseSACalculation() {
    let baseSaAmount = this.state.targetValue
    if (this.props.productData.product_id === ProductNames.totalProtectPlus && this.props.productData.bi_request[TppKeys.CHILD_FUTURE_PROTECT_BENEFIT] === 20) {
      baseSaAmount = TppUtils.getBaseSAIfChildFutureProtectBenefitEnabled(baseSaAmount, this.props.productData.bi_request[TppKeys.ADDITIONAL_SA]);
    }
    let baseSaAmountRoundOffValue = targetAmountRoundOffLogic(this.props.productData.product_id, baseSaAmount);
    let modifiedBIRequestFwdReverse = forwardNreverseCalculationBIRequestManager(this.props.productData.bi_request, "target", baseSaAmountRoundOffValue, this.props.productData.package_id);
    await OptimizationStore.updateBiRequest(this.props.productData.product_id, modifiedBIRequestFwdReverse, this.props.productData.family_product_id) 
  }

  async onRecalculate() {
    let isValidationSuccess = this.checkValidationOfProduct(this.props.productData.product_id, this.props.productData.family_product_id);
    if (isValidationSuccess) {
      // this.tppChildFutureBenefitBaseSACalculation()
      OptimizationStore.resetFieldChangeEvent(this.props.productData.family_product_id);
      if(this.state.premiumOrTargetAmtChanged === "premium") {
        let premiumRoundOffValue = calculateInputModalPremium(this.state.selectedPremium, this.props.productData);
        let modifiedBIRequestFwdReverse = checkProductWoringBasedOnPremiumAmount(this.props.productData.bi_request, premiumRoundOffValue);
        await OptimizationStore.updateStoreRequestData(this.props.productData.product_id, modifiedBIRequestFwdReverse, this.props.productData.family_product_id);
      } else {
        if (this.props.productData.product_id === ProductNames.totalProtectPlus && this.props.productData.bi_request[TppKeys.CHILD_FUTURE_PROTECT_BENEFIT] === 20) {
          this.tppChildFutureBenefitBaseSACalculation()
        }
        let biRequest = calculateTargetAmount(Math.round(this.state.targetValue), this.props.productData.bi_request, this.props.productData)
        OptimizationStore.resetFieldChangeEvent(this.props.productData.family_product_id);
        await OptimizationStore.updateStoreRequestData(this.props.productData.product_id, biRequest, this.props.productData.family_product_id);
      }
      this.setState({premiumAmountChanged: false});
    } else {
      OptimizationStore.resetFieldChangeEvent(this.props.productData.family_product_id);
    }
  }

  checkValidationOfProduct(productId, familyProductID) {
    let biRequestData = OptimizationStore.fetchBiRequest(productId, familyProductID);
    let errorMessage = [];
    switch (productId) {
      case ProductNames.zindagiPlus:
        errorMessage = zpValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.activeIncomePlan:
        errorMessage = aipValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.cashFlowProtectionPlus:
        errorMessage = cashFlowProtectionPlusValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.criticarePlus:
        errorMessage = criticarePlusValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.dhanLabh:
        errorMessage = dhanLabhValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.easyPension:
        errorMessage = easyPensionValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.foreverPension:
        errorMessage = foreverPensionValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.GCAP:
        errorMessage = gcapValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.guaranteedIncomeStar:
        errorMessage = gisValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.guaranteedSavingsStar:
        // errorMessage = gssValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.immediateAnnuityPlan:
        errorMessage = immediateAnnuityPlanValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.incomeBuilder:
        errorMessage = incomeBuilderValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.posSaralNivesh:
        errorMessage = saralNiveshValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.premierGuranteedIncome:
        errorMessage = pgiValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.saralJeevanBima:
        errorMessage = sjbValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.saralPension:
        errorMessage = saralPensionValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.simplyProtect:
        errorMessage = simplyProtectValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.singlePayEndowmentAssuarancePlan:
        errorMessage = singlePayEndowmentAssurancePlanValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.smartLifestyle:
        errorMessage = smartLifestyleValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.totalProtectPlus:
        errorMessage = totalProtectPlusValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.wealthGainPlus:
        errorMessage = wealthGainPlusValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.wealthPlus:
        errorMessage = wealthPlusValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.wealthPremier:
        errorMessage = wealthPremierValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.wealthSecurePlus:
        errorMessage = wealthSecureValidator.valid(this.props.formRef, biRequestData);
        break;

      case ProductNames.wealthUltima:
        errorMessage = wealthUltimaValidator.valid(this.props.formRef, biRequestData);
        break;

      // case ProductNames.flexiSavingsPlan:
      //   errorMessage = fspValidator.valid(this.props.formRef, biRequestData);
      //   break;  
    }

    if (errorMessage.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  validateValue(value, amount) {
    if (Number(value) <= amount) {
      this.setState({
        showValidation: true,
      });
    } else {
      this.setState({
        showValidation: false,
      });
      OptimizationStore.dataChange("premium", value, "", this.props.productID);
    }
    this.setState({
      minPremium: amount,
    });
  }

  updatePremiumValues = async (userSelected) => {
    console.log("updatePremiumValues");
    /* this.setState({ showPreloader: true }); */
    const { productData } = this.props;
    this.setState({
      recommendedTargetValue: parseInt(productData.recommended_sum_assured),
      recommendedTarget: parseInt(productData.recommended_sum_assured),
    });
    /* if (productData) {
      let requestBodyArr = []; */
      // let targetAmount = userSelected ? userSelected : this.state.targetValue;

      // this.setState({
      //   selectedTargetValue: parseInt(targetAmount),
      //   targetValue: parseInt(targetAmount),
      // });
      // let minSumAssuredRequest = forwardNreverseCalculationBIRequestManager(productData.bi_request, "target", productData.minimum_sum_assured);
      // requestBodyArr.push({
      //   ...minSumAssuredRequest,
      // });
      // requestBodyArr.push({
      //   ...productData.bi_request,
      //   TargetAmount: productData.minimum_sum_assured,
      //   investment_target: targetAmount,
      //   PR_SA: productData.minimum_sum_assured,
      // });
      // let maxSumAssuredRequest = forwardNreverseCalculationBIRequestManager(productData.bi_request, "target", productData.maximum_sum_assured);
      // requestBodyArr.push({
      //   ...maxSumAssuredRequest,
      // });
      // requestBodyArr.push({
      //   ...productData.bi_request,
      //   TargetAmount: productData.maximum_sum_assured,
      //   investment_target: targetAmount,
      //   PR_SA: productData.maximum_sum_assured,
      // });
      //** Commented below recommended SA code as per Business requirement */
/*       let recommendedSumAssuredRequest = forwardNreverseCalculationBIRequestManager(productData.bi_request, "target", productData.recommended_sum_assured);
      requestBodyArr.push({
        ...recommendedSumAssuredRequest,
      }); */
      //** Commented above recommended SA code as per Business requirement */
      // requestBodyArr.push({
      //   ...productData.bi_request,
      //   TargetAmount: productData.recommended_sum_assured,
      //   investment_target: targetAmount,
      //   PR_SA: productData.recommended_sum_assured,
      // });
      // let userSelectedTargetAmountRequest = forwardNreverseCalculationBIRequestManager(productData.bi_request, "target", targetAmount);
      // requestBodyArr.push({
      //   ...userSelectedTargetAmountRequest,
      // });
      // requestBodyArr.push({
      //   ...productData.bi_request,
      //   TargetAmount: targetAmount,
      //   investment_target: targetAmount,
      //   PR_SA: targetAmount,
      // });
      /* global.loading(true);
      let request = {
        family_product_id: productData.family_product_id,
        risk_type: productData.selectedRisk || "high",
        multi_target_premium_list: requestBodyArr,
      };
      if ((this.props?.productData?.maximum_premium === undefined && this.props?.productData?.recommended_premium === undefined) && !OptimizationStore.maturityPayOutModeChange) {
        await axios.post(APITypes.GET_PREMIUM_API, request).then((res) => {
          if (res?.data?.status !== "error") {
            this.setState({ showPreloader: false });
            // this.setState({ minPremium: res.data[0] ? res.data[0].premium : 0 });
            // this.setState({ maxPremium: res.data[1] ? res.data[1].premium : 0 });
            // this.setState({
            //   recommendedPremium: res.data[2] ? res.data[2].premium : 0,
            // });
            this.setState({
              recommendedPremium: res.data[0] ? res.data[0].premium : 0,
              recommendedPremiumFrequency: calculateProductPremiumRelatedValues(request?.multi_target_premium_list[0]?.INPUT_MODE?.toString()).frequencyLabel
            });

            console.log("recommended sa and premium res.data[0]", res.data[0]);
            // this.setState({
            //   selectedPremium: res.data[3] ? res.data[3].premium : res.data[2] ? res.data[2].premium : 0,
            // });

            this.updateRecommendedTarget(productData.recommended_sum_assured);
            // this.updateRecommendedPremium(
            //   res.data[3] ? res.data[3].premium : res.data[2] ? res.data[2].premium : 0
            // );

            // let request = {
            //   ...productData.bi_request,
            //   PR_SA: targetAmount,
            //   investment_target: targetAmount,
            //   PR_ANNPREM: res.data[3].premium,
            // };
            // OptimizationStore.updateStoreRequestData(productData.product_id, request);
          }
          global.loading(false);
        });
      }
      global.loading(false);
      OptimizationStore.resetFieldChangeEvent(this.props.productData.family_product_id); 
    }*/
  };

  onSliderValueChange = (userSelected) => {
    this.updatePremiumValues(userSelected);
  };

  updateRecommendedPremium = (val) => {
    this.setState({ recommendedPremium: val });
  };

  updateRecommendedTarget = (val) => {
    this.setState({ recommendedTarget: val });
  };

  onSliderOverlayHide = () => {
    OptimizationStore.resetFieldChangeEvent(this.props.productData.family_product_id);
  };

  getDeathBenefitPayoutComponent(productData) {
    if (productData.product_name === ProductConstants.ZINDAGI_PLUS) {
      //DeathBenefitPayoutOption = 16 for Lumpsum; 17 for Monthly income – Level; 18 for Monthly income – Increasing; 19 for Lumpsum Plus Monthly Income – Level; 20 for Lumpsum Plus Monthly Income - increasing

      return (
        <React.Fragment>
          {(productData.bi_request.DeathBenefitPayoutOption.toString() === "19" || productData.bi_request.DeathBenefitPayoutOption.toString() === "20") && (
            <div className='font-size-12 mb-0' style={{ marginLeft: "20px" }}>
              <Row>
                <p className='mb-0' style={{ fontSize: "35px", alignSelf: "center", opacity: "0.5" }}>
                  [
                </p>
                <div className={"d-flex flex-column px-2"} style={{ alignSelf: "center" }}>
                  <div className='d-flex align-items-center'>
                    <span>
                      <span className='font-weight-bold'>Income</span>
                      {` (${productData.bi_request.DeathBenefitPayoutOption.toString() === "19" ? productData.firstYearResponse.MILevelPercent : productData.firstYearResponse.Miincrpercent}): `}
                      <span className='font-weight-bold'>
                        &#8377;&nbsp;
                        {productData.bi_request.DeathBenefitPayoutOption.toString() === "19"
                          ?  Math.round(productData.firstYearResponse.MILevelLumpsum).toLocaleString('en-IN')
                          :  Math.round(productData.firstYearResponse.MI_IncrLumpsum).toLocaleString('en-IN')}
                      </span>
                      {` per month`}
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <span>
                      <span className='font-weight-bold'>Lumpsum</span>
                      {` (${productData.bi_request.DeathBenefitPayoutOption.toString() === "19" ? productData.firstYearResponse.LUMPSUMP_DIS : productData.firstYearResponse.LUMPSUMP_DIS}): `}
                      <span className='font-weight-bold'>
                      &#8377;&nbsp;
                        {productData.bi_request.DeathBenefitPayoutOption.toString() === "19" ? Math.round(productData.firstYearResponse.LPM_level).toLocaleString('en-IN') : Math.round(productData.firstYearResponse.LPM_level).toLocaleString('en-IN')}
                      </span>
                    </span>
                  </div>
                </div>
                <p className='mb-0' style={{ fontSize: "35px", alignSelf: "center", opacity: "0.5" }}>
                  ]
                </p>
              </Row>
            </div>
          )}

          {(productData.bi_request.DeathBenefitPayoutOption.toString() === "17" || productData.bi_request.DeathBenefitPayoutOption.toString() === "18") && (
            <div className='font-size-12' style={{ marginLeft: "20px" }}>
              <Row>
                <div className='d-flex align-items-center font-weight-medium font-size-12'>
                  <span>[ &#8377; {` ${productData.bi_request.DeathBenefitPayoutOption.toString() === "17" ? Math.round(productData.firstYearResponse.MILevelLumpsum).toLocaleString('en-IN') : Math.round(productData.firstYearResponse.MI_IncrLumpsum).toLocaleString('en-IN')
                    } per month `}]</span>
                </div>
              </Row>
            </div>
          )}
        </React.Fragment>
      );
    } else if (productData.product_name === ProductConstants.TOTAL_PROTECT_PLUS) {
      //DeathBenefitPayoutOption = ”33” for Lumpsum; ”34” for Monthly Income; ”35” for Lumpsum plus Monthly Income

      return (
        <React.Fragment>
          {productData.bi_request.DeathBenefitPayoutOption.toString() === "35" && (
            <div className='font-size-12 mb-0' style={{ marginLeft: "20px" }}>
              <Row>
                <p className='mb-0' style={{ fontSize: "35px", alignSelf: "center", opacity: "0.5" }}>
                  [
                </p>
                <div className={"d-flex flex-column px-2"} style={{ alignSelf: "center" }}>
                  <div className='d-flex align-items-center'>
                    <span>
                      <span className='font-weight-bold'>Income</span>
                      {` (${productData.parsedResponse.PERCENTAGE_DISPLAY_2}): `}
                      <span className='font-weight-bold'> &#8377; {Math.round(productData.firstYearResponse.DB_MONTHLY).toLocaleString('en-IN')}</span>
                      {` per month`}
                    </span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <span>
                      <span className='font-weight-bold'>Lumpsum</span>
                      {` (${productData.parsedResponse.PERCENTAGE_DISPLAY_1}): `}
                      <span className='font-weight-bold'> &#8377; {productData.firstYearResponse.DB_LUMPSUM.toLocaleString('en-IN')}</span>
                    </span>
                  </div>
                </div>
                <p className='mb-0' style={{ fontSize: "35px", alignSelf: "center", opacity: "0.5" }}>
                  ]
                </p>
              </Row>
            </div>
          )}

          {productData.bi_request.DeathBenefitPayoutOption.toString() === "34" && (
            <div className='font-size-12' style={{ marginLeft: "20px" }}>
              <Row>
                <div className='d-flex align-items-center font-weight-medium font-size-12'>
                  <span>[&#8377; {`${Math.round(productData.firstYearResponse.DB_MONTHLY).toLocaleString('en-IN')} per month `}]</span>
                </div>
              </Row>
            </div>
          )}
        </React.Fragment>
      );
    } else {
      return <div></div>;
    }
  }

  render() {
    const { income, premium, showValidation, showOverlay, showPreloader, minPremium, maxPremium, recommendedPremium, selectedPremium, recommendedTargetValue, selectedTargetValue, targetValue } = this.state;
    console.log("DeathCoverGraphSlider Render STATE", this.state);
    const {
      show_shadow = true,
      premiumFreq,
      productData,
      productID,
      hideOverLay,
      productTitle,
      onPremiumChange,
      onIncomeChange,
      premium_value,
      income_value,
      onClick,
      saLabel,
      secondInputTextlabel,
      onIncomeBlur,
      cardKey,
    } = this.props;

    console.log("DeathCoverGraphSlider Render  productData", this.props.productData);
    return (
      <React.Fragment>
        {/* <button className='btn d-flex align-items-center text-secondary border-secondary btn font-weight-medium font-size-18 p-6 ml-auto' onClick={this.onRecalculateClick}>
                    Recalculate
                    <Image className='pl-2' src={require('../../../../assets/images/icons/recalculate-secondary-icon.svg')} alt='recalculate-icon'></Image>
                </button> */}
        {/* bg-white death-cover-graph-slider py-3 */}
        {/* {!Array.isArray(productData.BI) && (
          <div className="text-description-text-color font-size-12 font-size-md-18 font-size-lg-20 mt-2">
            Underlying produ : {productTitle} <UiButton variant='' disabled={cardKey === "protection_for_my_health"} buttonText='[Change]' className='px-2 py-1 font-size-12 text-primary font-size-md-16 font-weight-medium cursor-pointer' />
            Underlying product : {productTitle} <UiButton variant='' onClick={openChangeReasonModal} buttonText='[Change]' className='px-2 py-1 font-size-12 text-primary font-size-md-16 font-weight-medium cursor-pointer' />
          </div>
        )} */}
        <div className={`bg-white death-cover-graph-slider py-3 ${show_shadow && "border rounded"}`}>
          <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
            <div className='d-lg-flex align-items-center pl-2'>
              <div className='font-size-12 font-size-md-14 font-weight-bold text-description-text-color text-nowrap'>
                <Image width='21' className='mx-1' src={images.UmbrellaIconBlack} alt='UmbrellaIcon' />
                <span className='graph-label'>{fetchIncomeLabelBasedOnNeed(cardKey, '', productData)} </span>
                <span className='text-secondary font-weight-normal' style={{ marginLeft: "10px", marginRight: "5px" }}>
                  &#8377;
                </span>
              </div>

              <FormControl
                disabled={false}
                className='border-secondary text-secondary font-size-12 font-size-md-14 font-weight-normal'
                required
                style={{ height: "25px", width: "auto" }}
                placeholder={fetchIncomeLabelBasedOnNeed(cardKey)}
                value={this.state.targetValue}
                onChange={(e) => this.onTargetValueChange(e)}
                onBlur={(e) => this.onTargetValueBlurEvent(e)}
              />
              {showValidation && this.state.targetErrorMessage !== "" && (
                <p className={cardKey !== "protection_for_my_family" ? "text-success mb-0 pl-2 font-size-12" : "text-danger mt-2 font-size-12"}>
                  {/* Minimum Premium should be {this.state.minPremium} */}
                  {this.state.targetErrorMessage}
                </p>
              )}
              {
                <div className="font-weight-light font-size-12 text-center ml-2">
                  {fetchTargetAmountLabel(productData)}
                </div>
              }
              <div className="">
              {this.getDeathBenefitPayoutComponent(productData)}
              </div>
              {showMaturityBenefitAmount(productData) && (
                <div className='font-size-12 font-size-md-14 text-description-text-color text-right mt-3 mt-lg-0 pr-2 pr-md-3'>
                  <span className='graph-label'> &nbsp; + Maturity Benefit : </span>
                  <span className='rupee-sign'> {global.toINR(maturityBenefitAmount(productData))} </span>
                </div>
              )}
              {showSurvivalBenefitAmount(productData) && (
                <div className='font-size-12 font-size-md-14 text-description-text-color text-right mt-3 mt-lg-0 pr-2 pr-md-3'>
                  <span className='graph-label'> &nbsp;+ Total Survival Benefit @8% p.a. : </span>
                  <span className='rupee-sign'> {global.toINR(survivalBenefitAmount(productData))} </span>
                </div>
              )}
            </div>

            <div className='font-size-12 font-size-md-14 text-description-text-color text-right mt-3 mt-lg-0 pr-2 pr-md-3'>
              <span className='graph-label'> {fetchRecommendedTargetLabelBasedOnNeed(cardKey)} : </span>
              <span className='rupee-sign'>
                {global.toINR(this.state.recommendedTarget)}
                {fetchIncomeFrequencyLabel(cardKey, productData)}
              </span>
            </div>
          </div>
          {this.state.targetSaMoreThanRecoSa && (
            <p className='font-size-12 pl-3 mt-1 max-cover-amount-msg'>As per your profile, you are eligible for a maximum cover of Rs. {this.state.recommendedTarget}.</p>
          )}
          {this.state.isValueBetween25LakhAnd50Lakh && (
            <p className='font-size-12 pl-3 mt-1 max-cover-amount-msg'>Sum Assured between 25,00,000 and 50,00,000 is not available</p>
          )}
          {!showOverlay && childFutureProtectBenefitFunction(productData)}
          <hr className='mb-0' />
          {/* <GraphSlider
            steps={[5, 8, 9, 10, 78, 44, 52, 20]}
            minSumAssured={parseInt(productData.minimum_sum_assured)}
            recommendedTargetValue={recommendedTargetValue}
            selectedTargetValue={targetValue}
            onChange={this.onSliderValueChange}
            hideOverLay={this.onSliderOverlayHide}
            showPreloader={showPreloader}
            minPremium={minPremium}
            maxPremium={maxPremium}
            recommendedPremium={recommendedPremium}
            selectedPremium={selectedPremium}
            showOverlay={showOverlay}
            biRequest={productData.bi_request}
            maximumSumAssured={this.state.maxTargetValue}
            onRecalculate={this.onRecalculate}
          /> */}
          <hr className='mt-0' />
          <div className='d-block d-md-flex align-items-center justify-content-between'>
            <div className='d-flex align-items-center flex-wrap flex-sm-nowrap'>
              <Col>
                <div className='d-flex align-items-center'>
                  <div className='font-size-12 font-size-md-14 font-weight-bold text-description-text-color text-nowrap'>
                    <Image width='21' className='mx-1' src={images.PriceTagIconBlack} alt='UmbrellaIcon' />
                    <span className='graph-label'> {`${secondInputTextlabel || "Modal Premium  "}`} </span>
                    <span className='text-secondary font-weight-normal' style={{ marginLeft: "10px", marginRight: "5px" }}>
                      &#8377;
                    </span>
                  </div>
                  <div className='d-block'>
                    <FormControl
                      disabled={false}
                      className='border-secondary text-secondary font-size-12 font-size-md-14 font-weight-normal'
                      required
                      placeholder='Premium'
                      style={{ height: "25px" }}
                      value={this.state.selectedPremium}
                      //onChange={onPremiumChange}
                      onChange={(e) => this.onPremiumValueChange(e)}
                      //onBlur={onClick}
                      onBlur={(e) => this.onPremiumValueBlurEvent(e)}
                    />
                  </div>
                  <span className='font-weight-light font-size-12 text-center ml-2'>{Utils.premiumAmountLabelFunction(premiumFreq, cardKey)}</span>
                </div>
                {showValidation && this.state.premiumErrorMessage !== "" && (
                  <p className={cardKey !== "protection_for_my_family" ? "text-success mt-2 font-size-12" : "text-danger mt-2 font-size-12"}>
                    {/* Minimum Premium should be {this.state.minPremium} */}
                    {this.state.premiumErrorMessage}
                  </p>
                )}
              </Col>
              {showOverlay &&
                // <div className="col col-md-6 text-center" onClick={onRecalculateAction}>
                <button
                  className="btn btn-secondary btn-xl modal-cta-btn py-1 font-size-18"
                  style={{ width: "137px", height: "40px" }} onClick={this.onRecalculate}
                >
                  <Image
                    className="pl-2 mb-1"
                    src={RecalculateIcon}
                    alt="share-icon"
                  />{" "}
                  Recalculate
                </button>
              // </div>
              }
              {/* <UiButton
                variant=""
                onClick={onClick}
                buttonText="Calculate"
                className="calculate-btn px-2 py-1 font-size-12 text-primary font-size-md-16 font-weight-medium cursor-pointer"
              /> */}
            </div>
            {/* <div className='font-size-12 font-size-md-14 text-desrecommended-textcription-text-color text-right mt-3 mt-lg-0 mt-lg-0 pr-2 pr-md-3'>
              <span className='graph-label'>{fetchPremiumLabelBasedOnNeed(cardKey)}</span>
              <span className='rupee-sign'>
                {global.toINR(this.state.recommendedPremium)} */}
                {/* {" " + calculateProductPremiumRelatedValues(productData.bi_request?.INPUT_MODE?.toString()).frequencyLabel} */}
                {/* {" " + this.state.recommendedPremiumFrequency}
              </span>
            </div> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

DeathCoverGraphSlider.propTypes = {
  onPremiumChange: PropTypes.func,
  onIncomeChange: PropTypes.func,
  premiumFreq: PropTypes.string,
};

DeathCoverGraphSlider.defaultProps = {
  isIncomeFieldDisable: false,
  premiumFreq: "",
};

export default DeathCoverGraphSlider;
