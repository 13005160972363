import { observable, toJS, makeObservable } from "mobx";
import moment from "moment";

import AuthStore from "../../login/store/AuthStore";
import axios from "../../../utils/axios";
import * as APITypes from "../../../constants/API";
import WealthPlusUtils from "../../optimization/components/products/wealth-plus/utils";
import WealthGainPlusUtils from "../../optimization/components/products/wealth-gain-plus/utils";
import WealthSecurePlusUtils from "../../optimization/components/products/wealth-secure-plus/utils";
import GcapUtils from "../../optimization/components/products/gcap/utils";
import SmartLifeStyleUtils from "../../optimization/components/products/smart-life-style/utils";
import DhanLabhUtils from "../../optimization/components/products/dhan-labh-plus/utils";
import CashFlowUtils from "../../optimization/components/products/cashflow-protection-plus/utils";
import IncomeBuilderUtils from "../../optimization/components/products/income-builder/utils";
import ImmediateAnnuityPlanUtils from "../../optimization/components/products/immediate-annuity-plan/utils";
import POSSaralNiveshUtils from "../../optimization/components/products/POS-saral-nivesh/utils";
import EasyPensionUtils from "../../optimization/components/products/easy-pension/utils";
import SinglePayEndowmentAssurancePlanUtils from "../../optimization/components/products/single-pay-endowment-assurance-plan/utils";
import WealthPremierUtils from "../../optimization/components/products/wealth-premier/utils";
import ProtectionUtils from "../../optimization/components/products/protection/utils";
import CriticarePlusUtils from "../../optimization/components/products/criticare-plus/utils";
import WealthUltimaUtils from "../../optimization/components/products/wealth-ultima/utils";
import SimplyProtectUtils from "../../optimization/components/products/simply-protect/utils";
import ZindagiPlusUtils from "../../optimization/components/products/zindagi-plus/utils";
import TotalProtectPlusUtils from "../../optimization/components/products/total-protect-plus/utils";
import SaralJeevanBimaUtils from "../../optimization/components/products/saral-jeevan-bima/utils";
import SaralPensionUtils from "../../optimization/components/products/saral-pension/utils";
import ForeverPensionUtils from "../../optimization/components/products/forever-pension/utils";
import PGIUtils from "../../optimization/components/products/premium-guaranteed-income/utils";
import AIPUtils from "../../optimization/components/products/active-income-plan/utils";
import GISUtils from "../../optimization/components/products/GIS/utils";
import GSSUtils from "../../optimization/components/products/gss/utils";
import FSPUtils from "../../optimization/components/products/flexi-savings-plan/utils";

import ProductNames from "../../../constants/ProductNames";
import Utils from "modules/solution/utils";

// !dummy Data
import dummySolutionDataStructure from "../solution-data";
import NeedTypeEnum from "constants/NeedTypeEnum";

class SolutionStore {
  solutionBenifits = [
    { cardType: "flexible_payout", showBenefit: false },
    { cardType: "early_return", showBenefit: false },
    { cardType: "death_cover", showBenefit: false },
    { cardType: "premium_waiver", showBenefit: false },
    { cardType: "tax_saving", showBenefit: true },
  ];
  policyTimeLine = [];

  user_list_data = [];
  totalCoverageData = {};
  needDataList = [];
  totalPremium = 0;
  totalTax = 0;
  headerList = [];
  isCustomerDetailModalOpen = false;
  isCloseModalOpen = false;
  dmsDocumentData = [];
  solutionApiData = [];
  seletedSolutionTabData = [];
  proposerIdList = [];

  constructor() {
    makeObservable(this, {
      solutionBenifits: observable,
      policyTimeLine: observable,
      isCustomerDetailModalOpen: observable,
      dmsDocumentData: observable,
      solutionApiData: observable,
      isCloseModalOpen: observable,
      seletedSolutionTabData: observable,
      proposerIdList: observable,
    });
  }

  customerDetailsModalToggleFunction = (bool) => {
    this.isCustomerDetailModalOpen = bool;
  };

  onNextBtnClick(props) {
    props.history.push(`/share-quote/${AuthStore.journeyId}`);
  }

  setProposerList(value) {
    this.proposerIdList = value
  }

  async fetchSolutionData(proposerId) {
    try {
      global.loading(true); 
      let response = []
      
      if (proposerId) {
        response = await axios.post(APITypes.GET_QMS_LEAD_DETAILS, { "proposer_id": proposerId });
      } else {
        response = await axios.get(APITypes.SOLUTION_API + AuthStore.journeyId + '/');
      }

      let solutionData = toJS(response.data);
      const proposerIdListFromTheResponse = solutionData.map(item => item.id);
      this.setProposerList(proposerIdListFromTheResponse)
      // this.proposerIdList = proposerIdListFromTheResponse
      // this.solutionApiData = toJS(response.data)
      // var solutionData = toJS(dummySolutionDataStructure)
      const needData = [];
      let totalPremium = 0;
      let totalTax = 0;
      solutionData.forEach((item) => {
        // let output_data = this.fetchCoverage(item)
        item.coverage.forEach((subitem) => {
          var filteredNeed = "";
          if (needData.length != 0) {
            var ifSelectedNeed = needData.some((need) => need.need_name == subitem.need_name);
            if (!ifSelectedNeed) {
              filteredNeed = subitem.need_name;
            }
          } else {
            filteredNeed = subitem.need_name;
          }
          if (filteredNeed != "") {
            needData.push({
              need_name: filteredNeed,
              family_member: [],
            });
          }
          // needData.forEach((need) => {

          //     var family_member_details = {
          //         "name": item.family_member_name,
          //         "type": item.family_member_type
          //     }
          //     if (need.need_name == subitem.need_name) {
          //         var isProductAvailable = output_data.filter((output) => output.title == subitem.product_details.product_name)[0]
          //         if (isProductAvailable) {
          //             totalPremium += isProductAvailable.ammount;
          //             totalTax += subitem.product_details.data.OutputJSON[0][0].TAX_MP;
          //             family_member_details.premium = isProductAvailable.ammount
          //             family_member_details.product_name = isProductAvailable.title
          //             family_member_details.tax = subitem.product_details.data.OutputJSON[0][0].TAX_MP
          //         }

          //         need.family_member.push(family_member_details)
          //     }
          // })
        });
      });
      solutionData.forEach((item) => {
        item.solutionBenifits = Utils.fetchSolutionBenefitsList(item);
        item.policyTimeLineData = this.getCoverageData(item);
        console.log("item.policyTimeLineData", item.policyTimeLineData);
        item.productList = this.fetchCoverage(item);
        item.tabTitle = item.family_member_name || item.family_member_type;
      });
      this.needDataList = needData;
      this.totalPremium = totalPremium;
      this.totalTax = Math.round(totalTax);
      this.user_list_data = solutionData;
      this.generateTotalCoverage(solutionData);
      global.loading(false);
    } catch (e) {
      // global.loading(false)
      console.log('fetchSolutionData catch error ::: ', e)
    }
  }

  getCoverageData = (value) => {

    const needName = value.coverage[0].need_name

    const headerlistFunction = (needName) => {
      if(needName === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY || needName === NeedTypeEnum.PROTECTION_FOR_MY_HEALTH){
        return [needName,"Year", "You Pay","-",]
      }else{
        return ["Year", "You Pay", "-", "You Get"]
      }
    }

    try {
      this.policyTimeLine = [];
      let wavierPremiumAmt = "";

      const data = value;
      let benefits = data.solutionBenifits;

      let dd = data;
      let mainDataList = [];
      let headerList = headerlistFunction(needName)
      let laListHeader = [];
      let haListHeader = [];
      let laIdMap = {};
      data.coverage.forEach((primaryDataObj) => {
        console.log("primaryDataObj.need_title", primaryDataObj.need_title);
        primaryDataObj.life_assured_list.forEach((lifeAssured) => {
          console.log("--primaryDataObj.need_title", primaryDataObj.need_title);
          console.log("lifeAssured.la_family_member_id", lifeAssured.la_family_member_id);

          switch (primaryDataObj.need_name) {
            case "protection_for_my_health":
              if (haListHeader.indexOf(lifeAssured.la_family_member_id) == -1) {
                haListHeader.push(lifeAssured.la_family_member_id);
              }
              break;
            default:
              if (laListHeader.indexOf(lifeAssured.la_family_member_id) == -1) {
                laListHeader.push(lifeAssured.la_family_member_id);
              }
              break;
          }
          laIdMap[lifeAssured.la_family_member_id] = lifeAssured.la_name;
          let dataList = [];
          lifeAssured.product_details.forEach((obj) => {
            let productName = obj.product_name;
            let outputJSON = obj.data.OutputJSON;
            let inputString = obj.data.InputString;
            let frequency = this.fetchInputStringOpt("@INPUT_MODE", inputString);
            let productId = this.fetchInputStringOpt("@PR_ID", inputString).Value;
            let premiumTerm = Number(this.fetchInputStringOpt("@PR_PPT", inputString).Value);
            let policyTerm = Number(this.fetchInputStringOpt("@PR_PT", inputString).Value);
            let createdData = obj.solution_timestamp;
            let createdYear = moment(createdData).year();
            let createdMonth = moment(1 + moment(createdData).month(), "M").format("MMM");
            let monthValidTill = moment(+moment(createdData).month(), "M").format("MMM");

            let year = `${createdMonth}'${Number(moment(createdYear.toString()).format("YY"))} - ${monthValidTill}'${Number(moment(createdYear.toString()).format("YY")) + 1}`;
            if (outputJSON.length > 0) {
              let finalOutputJSon = []
              if(ProductNames.wealthUltima === productId || ProductNames.easyPension === productId) {
                finalOutputJSon = outputJSON[0];
              } else {
                finalOutputJSon = outputJSON[1].length > 0 ? outputJSON[1] : outputJSON[0];
              }
              let biJson2 = outputJSON[1];

              for (let index = 0; index < finalOutputJSon.length; index++) {
                const element = finalOutputJSon[index];
                let biJson2Data = biJson2[index];
                let timelineObj = {};
                let youPay = "";
                let youGet = "";
                let lifeCover = "";
                let isMilestone = false;
                let premiumAmt = 0;

                let wopRider = this.fetchInputStringOpt("@RD_YN_50007", inputString);
                switch (productId) {
                  case ProductNames.activeIncomePlan:
                    let frequencyAIP = this.fetchInputStringOpt("@INPUT_MODE", inputString);
                    let amountAIP = Math.round(element.MODAL_PREM_TAX);
                    // timelineObj = AIPUtils.fetchPolicyTimeline(element, frequencyAIP, amountAIP);
                    timelineObj = AIPUtils.newPolicyTimeLineData(element, inputString, index);
                    break;

                  case ProductNames.zindagiPlus:
                    let payoutMode = this.fetchInputStringOpt("@DISPLAY_PR_OPTION_9", inputString);
                    timelineObj = ZindagiPlusUtils.fetchPolicyTimeline(element, inputString,frequency, payoutMode);
                    if (wavierPremiumAmt == "") {
                      wavierPremiumAmt = element.SA;
                    }
                    break;

                  case ProductNames.simplyProtect:
                    let payoutModeSP = this.fetchInputStringOpt("@DISPLAY_PR_OPTION_2", inputString);
                    let planOption = this.fetchInputStringOpt("@PR_OPTION_1", inputString);
                    let isWOPSelect = planOption.Value == "5" || planOption.Value == "6" ? "yes" : "no";
                    timelineObj = SimplyProtectUtils.fetchPolicyTimeline(element, payoutModeSP);
                    if (wavierPremiumAmt == "") {
                      wavierPremiumAmt = element.SA_D;
                    }
                    break;

                  case ProductNames.wealthUltima:
                    let littleChamp = this.fetchInputStringOpt("@PR_OPTION_2", inputString);
                    // timelineObj = WealthUltimaUtils.fetchPolicyTimeline(element, biJson2Data);
                    timelineObj = WealthUltimaUtils.newPolicyTimeLineData(element, inputString, index, finalOutputJSon.length - 1);
                    break;

                  case ProductNames.criticarePlus:
                    timelineObj = CriticarePlusUtils.fetchPolicyTimeline(element);
                    break;

                  case ProductNames.wealthPlus:
                    let risingStar = this.fetchInputStringOpt("@PR_OPTION_1", inputString);
                    // timelineObj = WealthPlusUtils.fetchPolicyTimeline(element, biJson2Data);
                    timelineObj = WealthPlusUtils.newPolicyTimeLineData(element, inputString, index);
                    break;

                  case ProductNames.wealthGainPlus:
                    timelineObj = WealthGainPlusUtils.fetchPolicyTimeline(element, biJson2Data);
                    break;

                  case ProductNames.wealthSecurePlus:
                    timelineObj = WealthSecurePlusUtils.fetchPolicyTimeline(element, biJson2Data);
                    break;

                  case ProductNames.GCAP:
                    // timelineObj = GcapUtils.fetchPolicyTimeline(element, frequency);
                    timelineObj = GcapUtils.newPolicyTimeLineData(element, inputString, index, policyTerm);
                    break;

                  case ProductNames.smartLifestyle:
                    // timelineObj = SmartLifeStyleUtils.fetchPolicyTimeline(element, frequency);
                    timelineObj = SmartLifeStyleUtils.newPolicyTimeLineData(element, inputString, index, finalOutputJSon.length - 1);
                    break;

                  case ProductNames.dhanLabh:
                    // timelineObj = DhanLabhUtils.fetchPolicyTimeline(element, frequency);
                    timelineObj = DhanLabhUtils.newPolicyTimeLineData(element, inputString, index, finalOutputJSon.length - 1);
                    break;

                  case ProductNames.cashFlowProtectionPlus:
                    // timelineObj = CashFlowUtils.fetchPolicyTimeline(element, frequency);
                    timelineObj = CashFlowUtils.newPolicyTimeLineData(element, inputString, index, finalOutputJSon.length - 1);
                    break;

                  case ProductNames.incomeBuilder:
                    // let maturityBenefitOption = this.fetchInputStringOpt('@PR_OPTION_3', inputString)
                    // timelineObj = IncomeBuilderUtils.fetchPolicyTimeline(element, frequency, maturityBenefitOption, index, finalOutputJSon.length);

                    timelineObj = IncomeBuilderUtils.newPolicyTimeLineData(element, inputString, index);
                    break;

                  case ProductNames.immediateAnnuityPlan:
                    timelineObj = ImmediateAnnuityPlanUtils.fetchPolicyTimeline(element);
                    break;

                  case ProductNames.posSaralNivesh:
                    // timelineObj = POSSaralNiveshUtils.fetchPolicyTimeline(element, frequency);
                    timelineObj = POSSaralNiveshUtils.newPolicyTimeLineData(element, inputString, index, finalOutputJSon.length - 1);
                    break;

                  case ProductNames.wealthPremier:
                    // timelineObj = WealthPremierUtils.fetchPolicyTimeline(element, biJson2Data);
                    timelineObj = WealthPremierUtils.newPolicyTimeLineData(element, inputString, index, finalOutputJSon.length - 1);
                    break;

                  case ProductNames.easyPension:
                    // timelineObj = EasyPensionUtils.fetchPolicyTimeline(element, biJson2Data);
                    timelineObj = EasyPensionUtils.newPolicyTimeLineData(element, inputString, index);
                    break;

                  case ProductNames.singlePayEndowmentAssuarancePlan:
                    // timelineObj = SinglePayEndowmentAssurancePlanUtils.fetchPolicyTimeline(element);
                    timelineObj = SinglePayEndowmentAssurancePlanUtils.newPolicyTimeLineData(element, inputString, index, finalOutputJSon.length - 1);
                    break;

                  case ProductNames.totalProtectPlus:
                    let protectPlusPayoutMode = this.fetchInputStringOpt("@DISPLAY_PR_OPTION_9", inputString);
                    timelineObj = TotalProtectPlusUtils.fetchPolicyTimeline(element,inputString, frequency, protectPlusPayoutMode);
                    break;

                  case ProductNames.saralJeevanBima:
                    let jeevanBimaPayoutMode = this.fetchInputStringOpt("@DISPLAY_PR_OPTION_9", inputString);
                    timelineObj = SaralJeevanBimaUtils.fetchPolicyTimeline(element,inputString, frequency, jeevanBimaPayoutMode);
                    break;

                  case ProductNames.saralPension:
                    // timelineObj = SaralPensionUtils.fetchPolicyTimeline(element);
                    timelineObj = SaralPensionUtils.newPolicyTimeLineData(element, inputString, index);
                    break;

                  case ProductNames.foreverPension:
                    // timelineObj = ForeverPensionUtils.fetchPolicyTimeline(element);
                    timelineObj = ForeverPensionUtils.newPolicyTimeLineData(element, inputString, index);
                    break;

                  case ProductNames.premierGuranteedIncome:
                    let frequencyPGI = this.fetchInputStringOpt("@PR_PPT", inputString);
                    let amountPGI = Math.round(element.MODAL_PREM_TAX);
                    // timelineObj = PGIUtils.fetchPolicyTimeline(element, frequencyPGI, amountPGI);
                    timelineObj = PGIUtils.newPolicyTimeLineData(element, inputString, index, productName, finalOutputJSon.length - 1);
                    break;

                  case ProductNames.protection:
                    timelineObj = ProtectionUtils.fetchPolicyTimeline(element);
                    if (wavierPremiumAmt == "") {
                      wavierPremiumAmt = element.DB_G;
                    }
                    break;

                  case ProductNames.guaranteedIncomeStar:
                    // timelineObj = GISUtils.fetchPolicyTimeline(element);
                    timelineObj = GISUtils.newPolicyTimeLineData(element, inputString, index, productName);
                    break;

                  case ProductNames.guaranteedSavingsStar:
                    // timelineObj = GSSUtils.fetchPolicyTimeline(element);
                    timelineObj = GSSUtils.newPolicyTimeLineData(element, inputString, index, productName);
                    break;

                  case ProductNames.flexiSavingsPlan:
                    // timelineObj = GSSUtils.fetchPolicyTimeline(element);
                    timelineObj = FSPUtils.newPolicyTimeLineData(element, inputString, index, productName);
                    break;  
                }

                youPay = index + 1 <= premiumTerm ? timelineObj.youPay : 0;


                if(productId === ProductNames.saralPension || productId === ProductNames.wealthUltima || productId === ProductNames.GCAP){
                  youPay =  timelineObj.youPay;
                }
                youGet = timelineObj.youGet;
                lifeCover = timelineObj.lifeCover;
                isMilestone = timelineObj.isMilestone;
                premiumAmt = timelineObj.premiumAmt;

                // Found existing yeardata from dataList
                let existingYearData = null;
                for (let index = 0; index < dataList.length; index++) {
                  const obj = dataList[index];
                  if (obj.year == createdYear) {
                    existingYearData = {
                      index,
                      data: obj,
                    };
                    break;
                  }
                }
                if (existingYearData) {
                  const { data, index } = existingYearData;
                  youPay = youPay + Math.round(data.you_pay);
                  youGet = youGet + Math.round(data.you_get);
                  lifeCover = lifeCover + Math.round(data.life_cover);
                  premiumAmt = lifeCover + Math.round(data.premium_amt);

                  let dataObj = {
                    month: moment().month(),
                    year: data.year,
                    you_pay: youPay,
                    you_get: youGet,
                    life_cover: lifeCover,
                    is_milestone: isMilestone,
                    premium_amt: premiumAmt,
                    yearIndex: index,
                    totalYear: finalOutputJSon.length,
                    productName: { productName: obj.product_name, totalYear: finalOutputJSon.length },
                  };
                  dataList[index] = dataObj;
                } else {
                  let dataObj = {
                    month: moment().month(),
                    year,
                    you_pay: youPay,
                    you_get: youGet,
                    life_cover: lifeCover,
                    is_milestone: isMilestone,
                    premium_amt: premiumAmt,
                    yearIndex: index,
                    totalYear: finalOutputJSon.length,
                    productName: { productName: obj.product_name, totalYear: finalOutputJSon.length },
                  };
                  dataList.push(dataObj);
                }
                year = `${createdMonth}'${Number(moment(createdYear.toString()).format("YY")) + (index + 1)} - ${monthValidTill}'${Number(moment(createdYear.toString()).format("YY")) + (index + 2)}`;
              }
              if (benefits) {
                this.getEarlyReturnBenefit(productId, finalOutputJSon, benefits);
              }
            }
          });
          mainDataList.push({ id: lifeAssured.la_family_member_id, data: dataList, need_name: primaryDataObj.need_name });
        });
      });
      var yearObj = {};
      mainDataList.forEach((mainDataitem, mainDataIndex) => {
        console.log("mainDataitem", mainDataitem);
        mainDataitem.data.length > 0 &&
          mainDataitem.data.forEach((subItem) => {
            if (!yearObj[subItem.year]) {
              yearObj[subItem.year] = {};
            }
            if (!yearObj[subItem.year][mainDataitem.id]) {
              yearObj[subItem.year][mainDataitem.id] = [];
            }
            yearObj[subItem.year][mainDataitem.id].push({ need_name: mainDataitem.need_name, ...subItem });
          });
      });
      Object.keys(yearObj).forEach((key) => {
        yearObj[key].youPayTotal = 0;
        yearObj[key].youGetTotal = 0;
        yearObj[key].year = key;
        yearObj[key].laList = [];
        yearObj[key].haList = [];
        yearObj[key].yearIndex = 0;
        yearObj[key].totalYear = 0;
        yearObj[key].productName = [];
        Object.keys(yearObj[key]).forEach((childKey) => {
          if (/[0-9]+/g.test(childKey)) {
            yearObj[key][childKey].forEach((need) => {
              switch (need.need_name) {
                case "protection_for_my_health":
                  let haCoverAmount = 0
                  yearObj[key][childKey]?.forEach((item) => { if (item.need_name === NeedTypeEnum.PROTECTION_FOR_MY_HEALTH) { haCoverAmount += item.life_cover } })
                  yearObj[key].haList[childKey] = haCoverAmount;
                  break;
                default:
                  let coverAmount = 0
                  yearObj[key][childKey]?.forEach((item) => { if (item.need_name !== NeedTypeEnum.PROTECTION_FOR_MY_HEALTH) { coverAmount += item.life_cover } })
                  yearObj[key].laList[childKey] = coverAmount;
                  break;
              }
              yearObj[key].youPayTotal += need.you_pay || 0;
              yearObj[key].youGetTotal += need.you_get || 0;
              yearObj[key].yearIndex = need.yearIndex || 0;
              yearObj[key].totalYear = need.totalYear || 0;
              if (!yearObj[key].productName.some((item) => item.productName === need.productName.productName && item.totalYear === need.productName.totalYear)) {
                yearObj[key].productName.push({ productName: need.productName.productName, totalYear: need.productName.totalYear });
              }
            });
          }
        });
      });
      yearObj = Object.values(yearObj);
      console.log("headerList", headerList);
      console.log("laIdMap", laIdMap);
      // if (wavierPremiumAmt !== '') {
      //     let wavierData = { yearCount: dataList.length, wavierPremiumAmt: wavierPremiumAmt }
      //     this.setSoltionBenefits('death_cover', wavierData, benefits)
      // }
      var dataList = [];
      console.log("dataList", dataList);
      laListHeader.forEach((item) => {
        headerList.push("la_" + item);
      });
      haListHeader.forEach((item) => {
        headerList.push("ha_" + item);
      });
      return { yearObj: yearObj, headerList: headerList, laIdMap: laIdMap };
    } catch (error) {
      console.error(error);
    }
    return dataList;
  };

  fetchInputStringOpt(key, inputString) {
    return inputString.filter((item) => item.Key == key)[0];
  }

  setSoltionBenefits(key, value, benefits) {
    benefits.forEach((item) => {
      if (item.cardType == key) {
        switch (key) {
          case "flexible_payout":
            if (!item.showBenefit) {
              item.showBenefit = value ? true : false;
            }
            break;
          case "early_return":
            item.showBenefit = value.showBenefit;
            item.year = value.year;
            break;
          case "death_cover":
            item.showBenefit = true;
            item.amount = value.wavierPremiumAmt;
            item.year_count = value.yearCount;
            break;
          case "premium_waiver":
            if (!item.showBenefit) {
              item.showBenefit = value.toLowerCase() == "yes";
            }
            break;
          case "tax_saving":
            item.showBenefit = true;
            break;
        }
      }
    });
  }

  generateTotalCoverage(solutionData) {
    let premiumAmt = 0;
    let tax = 0;

    solutionData.forEach((item) => {
      item.coverage.forEach((coverage) => {
        let inputString = coverage?.product_details?.data?.InputString;
        let productId = this.fetchInputStringOpt("@PR_ID", inputString).Value;

        var outputJson = coverage.product_details.data.OutputJSON;
        let finalOutputJSon = [];
        finalOutputJSon = outputJson[0][0];
        switch (productId) {
          case ProductNames.activeIncomePlan:
          case ProductNames.zindagiPlus:
          case ProductNames.criticarePlus:
          case ProductNames.smartLifestyle:
          case ProductNames.posSaralNivesh:
          case ProductNames.dhanLabh:
          case ProductNames.cashFlowProtectionPlus:
          case ProductNames.singlePayEndowmentAssuarancePlan:
          case ProductNames.flexiSavingsPlan:
            premiumAmt += Math.round(finalOutputJSon.MODAL_PREM);
            tax += Math.round(finalOutputJSon.TAX_MP);
            break;

          case ProductNames.simplyProtect:
            premiumAmt += Math.round(finalOutputJSon.BASE_PREM);
            tax += Math.round(finalOutputJSon.TAX_MP);
            break;

          case ProductNames.wealthUltima:
          case ProductNames.wealthPlus:
          case ProductNames.wealthSecurePlus:
            premiumAmt += Math.round(finalOutputJSon.PREMIUM);
            break;

          case ProductNames.wealthGainPlus:
          case ProductNames.wealthPremier:
          case ProductNames.easyPension:
            premiumAmt += Math.round(finalOutputJSon.MODAL_PREM);
            break;

          case ProductNames.GCAP:
          case ProductNames.incomeBuilder:
            premiumAmt += Math.round(finalOutputJSon.MODAL_PREM_1);
            tax += Math.round(finalOutputJSon.TAX_MP);
            break;

          case ProductNames.immediateAnnuityPlan:
            premiumAmt += Math.round(finalOutputJSon.PPRICE);
            tax += Math.round(finalOutputJSon.TAX_MP);
            break;

          case ProductNames.protection:
            premiumAmt += Math.round(finalOutputJSon.MODAL_PREM);
            tax += Math.round(finalOutputJSon.TAX_MP);
            break;
        }
      });
    });

    this.totalCoverageData = {
      premiumAmt: premiumAmt,
      tax: tax,
    };
  }

  fetchCoverage(data) {
    let productList = [];
    let listData = [];
    data.coverage.forEach((coverage) => {
      coverage.life_assured_list.forEach((lifeAssured) => {
        lifeAssured.product_details.forEach((obj) => {
          let premiumAmt = 0;
          let productName = obj.product_name;
          let inputString = obj.data.InputString;
          let productId = this.fetchInputStringOpt("@PR_ID", inputString).Value;

          var outputJson = obj.data.OutputJSON;
          let finalOutputJSon = [];
          finalOutputJSon = outputJson[0][0];
          switch (productId) {
            case ProductNames.activeIncomePlan:
            case ProductNames.zindagiPlus:
            case ProductNames.criticarePlus:
            case ProductNames.smartLifestyle:
            case ProductNames.posSaralNivesh:
            case ProductNames.dhanLabh:
            case ProductNames.cashFlowProtectionPlus:
            case ProductNames.singlePayEndowmentAssuarancePlan:
            case ProductNames.flexiSavingsPlan:
              premiumAmt += Math.round(finalOutputJSon.MODAL_PREM);
              break;

            case ProductNames.simplyProtect:
              premiumAmt += Math.round(finalOutputJSon.BASE_PREM);
              break;

            case ProductNames.wealthUltima:
            case ProductNames.wealthPlus:
            case ProductNames.wealthSecurePlus:
              premiumAmt += Math.round(finalOutputJSon.PREMIUM);
              break;

            case ProductNames.wealthGainPlus:
            case ProductNames.wealthPremier:
            case ProductNames.easyPension:
            case ProductNames.protection:
              premiumAmt += Math.round(finalOutputJSon.MODAL_PREM);
              break;

            case ProductNames.GCAP:
            case ProductNames.incomeBuilder:
              premiumAmt += Math.round(finalOutputJSon.MODAL_PREM_1);
              break;

            case ProductNames.immediateAnnuityPlan:
              premiumAmt += Math.round(finalOutputJSon.PPRICE);
              break;
          }
          productList.push({
            premiumAmt: premiumAmt,
            productName: productName,
            inputString: inputString,
          });
        });
      });
    });
    productList.forEach((item) => {
      let obj = { title: item.productName, percentValue: "40", ammount: item.premiumAmt, inputString: item.inputString };
      listData.push(obj);
    });

    return listData;
  }

  getEarlyReturnBenefit(productId, finalOutputJSon, benefits) {
    let data = { showBenefit: "", year: "" };
    for (var i = 0; i <= 5; i++) {
      if (productId == ProductNames.activeIncomePlan) {
        if (finalOutputJSon[i].TOTAL_SB_G_BS_2 !== 0) {
          data.showBenefit = finalOutputJSon[i].TOTAL_SB_G_BS_2 !== 0;
          data.year = this.ordinalSuffix(finalOutputJSon[i].POLICYYEAR);
          this.setSoltionBenefits("early_return", data, benefits);
          break;
        }
      }

      if (productId == ProductNames.immediateAnnuityPlan) {
        data.showBenefit = finalOutputJSon[i].YEARLY_ANN_PAY !== 0;
        data.year = this.ordinalSuffix(finalOutputJSon[i].POLICYYEAR);
        this.setSoltionBenefits("early_return", data, benefits);
        break;
      }
    }
  }

  ordinalSuffix(year) {
    if (year == 1) {
      return year + "st";
    }
    if (year == 2) {
      return year + "nd";
    }
    if (year == 3) {
      return year + "rd";
    }
    return year + "th";
  }

  setSeletedSolutionTabData(data) {
  
    this.seletedSolutionTabData = data
  }
}

export default new SolutionStore();
