
import Utils from './utils';
import * as keys from './keys';

const FormObserver = (formRef, props) => {

  let biRequest = props.data.bi_request;
  formRef.$('policy-term').observe({
    key: 'value',
    call: ({ field }) => {
      let currentPt = field.value
      let list = Utils.getPremiumTermList(currentPt, biRequest.LI_ENTRY_AGE)
      formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        optionList: list
      })
      formRef.$('premium-term').set('value', {
        id: currentPt.id,
        label: "" + currentPt.id
      })
      Utils.updateBi(keys.PR_PT, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('payment-frequency').observe({
    key: 'value',
    call: ({ field }) => {
      let premium = formRef.$('premium').value
      let frequency = field.value.id.toString()

      let amount = Number(premium)
      let premiumAmount = 0
      switch (frequency) {
        case "1":
          premiumAmount += '' + amount * 1
          break;
        case "2":
          premiumAmount += '' + amount * 2
          break;
        case "3":
          premiumAmount = '' + amount * 4
          break;
        case "4":
          premiumAmount = '' + amount * 12
          break;
      }
      let data = {
        premium: premiumAmount,
        frequency: frequency
      }
      Utils.updateBi(keys.FREQUENCY, data, props.data.product_id, formRef, props)
    }
  })

  formRef.$('premium-term').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.PR_PPT, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('policy-option').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.POLICY_OPTION, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('fund-strategy').observe({
    key: 'value',
    call: ({ field }) => {
      if (field.value.id == 1) {
        formRef.$('fund-strategy').set('extra', {
          ...formRef.$('fund-strategy').extra,
          showFundDistributionModal: true
        })
      } else {
        Utils.updateBi(keys.FUNDSTRATEGYID, field.value.id, props.data.product_id, formRef, props)
      }
    }
  })

  formRef.$('systematic-withdrawal-option').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.SWP_OPTION, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('fund-value').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.FUND_VALUE, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('swp-frequency').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.SWP_FREQUENCY, field.value.id, props.data.product_id, formRef, props)
    }
  })


  formRef.$('year-swp-payable').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.SWP_YEAR_PAYABLE, field.value.id, props.data.product_id, formRef, props)
    }
  })

  formRef.$('spouse-gender').observe({
    key: 'value',
    call: ({ field }) => {
      Utils.updateBi(keys.SPOUSE_GENDER, field.value.id, props.data.product_id, formRef, props)
    }
  })

}

export default FormObserver