const isObjectEmpty = (obj) => {

  if(Array.isArray(obj)){
    // check for empty array, since array is also an object
    return false
}

  return  Object.keys(obj).length === 0 && obj.constructor === Object
}

export default isObjectEmpty;