import { observable, action, makeObservable } from 'mobx';

import axios from '../../../utils/axios';
import * as APITypes from '../../../constants/API';
import AuthStore from '../../login/store/AuthStore';
import { toJS } from 'mobx';

import { v4 as uuidv4 } from 'uuid';

import FamilyDetailsStore from '../../family-details-form/store/FamilyDetailsStore';

class FamilyProtectionStore {
  product_list_data = [];

  // @observable dummy_product_list_data = [
  //   {
  //     'id': 3,
  //     'action': 'update',
  //     'title': localStorage.getItem('need_title'),
  //     'isSelected': true,
  //     'death_cover': '10101010',
  //     'annual_price': '10101010',
  //     'one_time_price': '10101010',
  //     'death_cover_payout_mode': 'test',
  //     'death_cover_price': '10101010',
  //     'premium_term': 'test',
  //     'policy_term': 'test',
  //     'recommended_price': '10101010',
  //     'premium_amount': '10101010',
  //     'gst': '10101010',
  //     'gst_percentage': '16',
  //     'total_price': '10101010',
  //     'saving_risk_name': 'test',
  //     'saving_risk_type': 'test',
  //     'saving_risk_amount': '10101010',
  //     'saving_payout_mode': 'test',
  //     'saving_payout_period': '3 years',
  //     'variation_type': 'test',
  //     'is_tobacco_user': false,
  //     'family_member_details': {
  //       'family_member_type': 'mother',
  //       'gender': 'female',
  //       'family_member_name': 'savitri',
  //       'dob': '20-02-2000',
  //       'age': 28,
  //       'is_selected': true
  //     },
  //     'additional_benefits_list': [
  //       {
  //         'id': 3,
  //         'action': 'update',
  //         'benefit_name': 'Accidental death benefit',
  //         'benefit_description': 'benefit dsecription',
  //         'sum_assured': '10000',
  //         'is_selected': true,
  //         'one_time_fee': '22'
  //       },
  //       {
  //         'id': 4,
  //         'action': 'update',
  //         'benefit_name': 'Accidental total/perm.disability',
  //         'benefit_description': 'Lumpsum payout in case of loss of earning due to illness.',
  //         'sum_assured': '233',
  //         'is_selected': true,
  //         'one_time_fee': '1345'
  //       }
  //     ],
  //     'rider_list': [
  //       {
  //         'id': 2,
  //         'action': 'update',
  //         'rider_name': 'rider test',
  //         'rider_description': 'desc',
  //         'sum_assured': '10000',
  //         'one_time_fee': '22'
  //       }
  //     ],
  //     'user_journey': '1'
  //   }
  // ]


  // ZINDAGI PLUS DUMMY DATA
  dummy_product_list_data = [
    {
      'id': uuidv4(),
      'action': 'update',
      'title': localStorage.getItem('need_title'),
      // 'title': 'protection for my family',
      'product_id': '40012',
      'isSelected': true,
      'death_cover': '10101010',
      'annual_price': '100000',
      'one_time_price': '10101010',
      'death_cover_payout_mode': 'test',
      'death_cover_price': '10101010',
      'premium_term': 'test',
      'policy_term': 'test',
      'recommended_price': '10101010',
      'premium_amount': '10101010',
      'gst': '10101010',
      'gst_percentage': '16',
      'total_price': '10101010',
      'saving_risk_name': 'test',
      'saving_risk_type': 'test',
      'saving_risk_amount': '10101010',
      'saving_payout_mode': 'test',
      'saving_payout_period': '3 years',
      'variation_type': 'test',
      'is_tobacco_user': false,
      'family_member_details': {
        'family_member_type': 'self',
        'gender': 'male',
        'family_member_name': 'Rajesh',
        'dob': '20-02-1991',
        'age': 28,
        'is_selected': true
      },
      'additional_benefits_list': [
        {
          'id': 3,
          'action': 'update',
          'benefit_name': 'Accidental death benefit',
          'benefit_description': 'benefit dsecription',
          'sum_assured': '10000',
          'is_selected': true,
          'one_time_fee': '22'
        },
        {
          'id': 4,
          'action': 'update',
          'benefit_name': 'Accidental total/perm.disability',
          'benefit_description': 'Lumpsum payout in case of loss of earning due to illness.',
          'sum_assured': '233',
          'is_selected': true,
          'one_time_fee': '1345'
        }
      ],
      'rider_list': [
        {
          'id': 2,
          'action': 'update',
          'rider_name': 'rider test',
          'rider_description': 'desc',
          'sum_assured': '10000',
          'one_time_fee': '22'
        }
      ],
      'user_journey': '1'
    }
    // {
    //   'id': uuidv4(),
    //   'action': 'update',
    //   // 'title': localStorage.getItem('need_title'),
    //   'title': 'protection for my health',
    //   'product_id': '40035',
    //   'isSelected': true,
    //   'death_cover': '10101010',
    //   'annual_price': '10101010',
    //   'one_time_price': '10101010',
    //   'death_cover_payout_mode': 'test',
    //   'death_cover_price': '10101010',
    //   'premium_term': 'test',
    //   'policy_term': 'test',
    //   'recommended_price': '10101010',
    //   'premium_amount': '10101010',
    //   'gst': '10101010',
    //   'gst_percentage': '16',
    //   'total_price': '10101010',
    //   'saving_risk_name': 'test',
    //   'saving_risk_type': 'test',
    //   'saving_risk_amount': '10101010',
    //   'saving_payout_mode': 'test',
    //   'saving_payout_period': '3 years',
    //   'variation_type': 'test',
    //   'is_tobacco_user': false,
    //   'family_member_details': {
    //     'family_member_type': 'self',
    //     'gender': 'male',
    //     'family_member_name': 'Rajesh',
    //     'dob': '20-02-1991',
    //     'age': 28,
    //     'is_selected': true
    //   },
    //   'additional_benefits_list': [
    //     {
    //       'id': 3,
    //       'action': 'update',
    //       'benefit_name': 'Accidental death benefit',
    //       'benefit_description': 'benefit dsecription',
    //       'sum_assured': '10000',
    //       'is_selected': true,
    //       'one_time_fee': '22'
    //     },
    //     {
    //       'id': 4,
    //       'action': 'update',
    //       'benefit_name': 'Accidental total/perm.disability',
    //       'benefit_description': 'Lumpsum payout in case of loss of earning due to illness.',
    //       'sum_assured': '233',
    //       'is_selected': true,
    //       'one_time_fee': '1345'
    //     }
    //   ],
    //   'rider_list': [
    //     {
    //       'id': 2,
    //       'action': 'update',
    //       'rider_name': 'rider test',
    //       'rider_description': 'desc',
    //       'sum_assured': '10000',
    //       'one_time_fee': '22'
    //     }
    //   ],
    //   'user_journey': '1'
    // },
    // {
    //   'id': uuidv4(),
    //   'action': 'update',
    //   // 'title': localStorage.getItem('need_title'),
    //   'title': 'grow my money',
    //   'product_id': '40009',
    //   'isSelected': true,
    //   'death_cover': '10101010',
    //   'annual_price': '10101010',
    //   'one_time_price': '10101010',
    //   'death_cover_payout_mode': 'test',
    //   'death_cover_price': '10101010',
    //   'premium_term': 'test',
    //   'policy_term': 'test',
    //   'recommended_price': '10101010',
    //   'premium_amount': '10101010',
    //   'gst': '10101010',
    //   'gst_percentage': '16',
    //   'total_price': '10101010',
    //   'saving_risk_name': 'test',
    //   'saving_risk_type': 'test',
    //   'saving_risk_amount': '10101010',
    //   'saving_payout_mode': 'test',
    //   'saving_payout_period': '3 years',
    //   'variation_type': 'test',
    //   'is_tobacco_user': false,
    //   'family_member_details': {
    //     'family_member_type': 'self',
    //     'gender': 'male',
    //     'family_member_name': 'Rajesh',
    //     'dob': '20-02-1991',
    //     'age': 28,
    //     'is_selected': true
    //   },
    //   'additional_benefits_list': [
    //     {
    //       'id': 3,
    //       'action': 'update',
    //       'benefit_name': 'Accidental death benefit',
    //       'benefit_description': 'benefit dsecription',
    //       'sum_assured': '10000',
    //       'is_selected': true,
    //       'one_time_fee': '22'
    //     },
    //     {
    //       'id': 4,
    //       'action': 'update',
    //       'benefit_name': 'Accidental total/perm.disability',
    //       'benefit_description': 'Lumpsum payout in case of loss of earning due to illness.',
    //       'sum_assured': '233',
    //       'is_selected': true,
    //       'one_time_fee': '1345'
    //     }
    //   ],
    //   'rider_list': [
    //     {
    //       'id': 2,
    //       'action': 'update',
    //       'rider_name': 'rider test',
    //       'rider_description': 'desc',
    //       'sum_assured': '10000',
    //       'one_time_fee': '22'
    //     }
    //   ],
    //   'user_journey': '1'
    // },
    // {
    //   'id': uuidv4(),
    //   'action': 'update',
    //   // 'title': localStorage.getItem('need_title'),
    //   'title': 'protection for my family',
    //   'product_id': '40038',
    //   'isSelected': true,
    //   'death_cover': '10101010',
    //   'annual_price': '10101010',
    //   'one_time_price': '10101010',
    //   'death_cover_payout_mode': 'test',
    //   'death_cover_price': '10101010',
    //   'premium_term': 'test',
    //   'policy_term': 'test',
    //   'recommended_price': '10101010',
    //   'premium_amount': '5000000',
    //   'gst': '10101010',
    //   'gst_percentage': '16',
    //   'total_price': '10101010',
    //   'saving_risk_name': 'test',
    //   'saving_risk_type': 'test',
    //   'saving_risk_amount': '10101010',
    //   'saving_payout_mode': 'test',
    //   'saving_payout_period': '3 years',
    //   'variation_type': 'test',
    //   'is_tobacco_user': false,
    //   'family_member_details': {
    //     'family_member_type': 'self',
    //     'gender': 'male',
    //     'family_member_name': 'Rajesh',
    //     'dob': '20-02-1991',
    //     'age': 28,
    //     'is_selected': true
    //   },
    //   'additional_benefits_list': [
    //     {
    //       'id': uuidv4(),
    //       'action': 'update',
    //       'benefit_name': 'Better Half Benefit',
    //       'benefit_description': 'Option to get an additional life cover for your spouse.',
    //       'sum_assured': '30',
    //       'is_selected': false,
    //       'one_time_fee': '0'
    //     },
    //     {
    //       'id': uuidv4(),
    //       'action': 'update',
    //       'benefit_name': 'Waiver of Premium',
    //       'benefit_description': 'Premiums will be waived off if diagnosed with any one of the 35 listed critical illnesses.',
    //       'sum_assured': '55',
    //       'is_selected': false,
    //       'one_time_fee': '0'
    //     },
    //     {
    //       'id': uuidv4(),
    //       'action': 'update',
    //       'benefit_name': 'Decreasing Sum Assured',
    //       'benefit_description': 'Option to reduce your sum assured by 50% after reaching the age of 60 years.',
    //       'sum_assured': '',
    //       'is_selected': false,
    //       'one_time_fee': '1345'
    //     },
    //     {
    //       'id': uuidv4(),
    //       'action': 'update',
    //       'benefit_name': 'Telemedical Option',
    //       'benefit_description': 'Get hassel-free life protection without the need of going through the full medical examination',
    //       'sum_assured': '',
    //       'is_selected': false,
    //       'one_time_fee': '1345'
    //     },
    //   ],
    //   'rider_list': [
    //     {
    //       'id': uuidv4(),
    //       'action': 'update',
    //       'brochure_link': 'https://www.edelweisstokio.in/riders/download/81-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
    //       'rider_name': 'Accidental Death Benefit',
    //       'rider_description': 'Provide enhanced financial security to your loved ones in case of death due to an accident.',
    //       'sum_assured': '59',
    //       'is_selected': false,
    //       'one_time_fee': '22'
    //     },
    //     {
    //       'id': uuidv4(),
    //       'action': 'update',
    //       'brochure_link': 'https://www.edelweisstokio.in/riders/download/82-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
    //       'rider_name': 'Permanent Disability',
    //       'rider_description': 'You will get a lump sum amount to manage your expenses in case an accidental disability has hindered your earning capacity.',
    //       'sum_assured': '47',
    //       'is_selected': false,
    //       'one_time_fee': '22'
    //     },
    //     {
    //       'id': uuidv4(),
    //       'action': 'update',
    //       'brochure_link': 'https://www.edelweisstokio.in/riders/download/22-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
    //       'rider_name': 'Critical Illness Rider',
    //       'rider_description': 'Upon diagnosis with one of the 12 specified Critical Illnesses, you will receive a pay out of the critical illness amount you’ve selected.',
    //       'sum_assured': '677',
    //       'is_selected': false,
    //       'one_time_fee': '22'
    //     },
    //     {
    //       'id': uuidv4(),
    //       'action': 'update',
    //       'brochure_link': 'https://www.edelweisstokio.in/riders/download/23-3?_ga=2.175776610.1125348727.1616483264-207121548.1616483264',
    //       'rider_name': 'Hospital Cash Benefit',
    //       'rider_description': 'It will help with daily expenses and take care of the costs involved in hospitalisation.',
    //       'sum_assured': '2084',
    //       'is_selected': false,
    //       'one_time_fee': '22'
    //     }
    //   ],
    //   'user_journey': '1'
    // }
  ];
  wealthUltimaBenefits = [
    {
      'id': 1,
      'action': 'update',
      'title': 'Little Champ Benefit',
      'description': '',
      'sum_assured': '-',
      'is_selected': false,
      'one_time_fee': '0',
      'isDisabled': false
    }
  ];

  zindagiPlusBenefits = [
    {
      'id': 1,
      'action': 'update',
      'title': 'Better Half Benefit',
      'description': 'Option to get an additional life cover for your spouse.',
      'sum_assured': '-',
      'is_selected': false,
      'one_time_fee': '0',
      'isDisabled': false
    },
    {
      'id': 2,
      'action': 'update',
      'title': 'Waiver of Premium',
      'description': 'Premiums will be waived off if diagnosed with any one of the 35 listed critical illnesses.',
      'sum_assured': '',
      'is_selected': false,
      'one_time_fee': '0',
      'isDisabled': false
    },
    {
      'id': 3,
      'action': 'update',
      'title': 'Decreasing Sum Assured',
      'description': 'Option to reduce your sum assured by 50% after reaching the age of 60 years.',
      'sum_assured': '',
      'is_selected': false,
      'one_time_fee': '1345',
      'isDisabled': false
    },
    {
      'id': 4,
      'action': 'update',
      'title': 'Telemedical Option',
      'description': 'Get hassel-free life protection without the need of going through the full medical examination',
      'sum_assured': '',
      'is_selected': false,
      'one_time_fee': '1345',
      'isDisabled': false
    }
  ];


  zindagiPlusRiders = [
    {
      'id': 1,
      'action': 'update',
      'brochure_link': 'https://www.edelweisstokio.in/riders/download/81-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
      'title': 'Accidental Death Benefit',
      'description': 'Provide enhanced financial security to your loved ones in case of death due to an accident.',
      'sum_assured': '',
      'initial_amount': '59',
      'is_selected': false,
      'one_time_fee': '22',
      'selected_value': { id: 1, label: '1 L' },
      'paramName': 'ADBRider',
      'showDropdown': true,
      'rider_name': 'ADB Rider'
    },
    {
      'id': 2,
      'action': 'update',
      'brochure_link': 'https://www.edelweisstokio.in/riders/download/82-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
      'title': 'Permanent Disability',
      'description': 'You will get a lump sum amount to manage your expenses in case an accidental disability has hindered your earning capacity.',
      'sum_assured': '',
      'initial_amount': '47',
      'is_selected': false,
      'one_time_fee': '22',
      'selected_value': { id: 1, label: '1 L' },
      'paramName': 'ATPDRider',
      'showDropdown': true,
      'rider_name': 'ATPD Rider'
    },
    {
      'id': 3,
      'action': 'update',
      'brochure_link': 'https://www.edelweisstokio.in/riders/download/22-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
      'title': 'Critical Illness Rider',
      'description': 'Upon diagnosis with one of the 12 specified Critical Illnesses, you will receive a pay out of the critical illness amount you’ve selected.',
      'sum_assured': '',
      'initial_amount': '677',
      'is_selected': false,
      'one_time_fee': '22',
      'selected_value': { id: 1, label: '1 L' },
      'paramName': 'CriticalIllnessRider',
      'showDropdown': true,
      'rider_name': 'Critical Illness Rider'
    },
    {
      'id': 4,
      'action': 'update',
      'brochure_link': 'https://www.edelweisstokio.in/riders/download/23-3?_ga=2.175776610.1125348727.1616483264-207121548.1616483264',
      'title': 'Hospital Cash Benefit',
      'description': 'It will help with daily expenses and take care of the costs involved in hospitalisation.',
      'sum_assured': '',
      'initial_amount': '2084',
      'is_selected': false,
      'one_time_fee': '22',
      'selected_value': { id: 1, label: '1 L' },
      'paramName': 'HospitalityCashBenefitRider',
      'showDropdown': true,
      'rider_name': 'Hospital Cash Benefit Rider'
    }
  ];

  aipRiders = [

    {
      'id': 1,
      'action': 'update',
      'brochure_link': 'https://www.edelweisstokio.in/riders/download/23-3?_ga=2.175776610.1125348727.1616483264-207121548.1616483264',
      'title': 'Waiver of Premium',
      'description': 'Simple life insurance cover',
      'sum_assured': '-',
      'initial_amount': '2084',
      'is_selected': false,
      'one_time_fee': '22',
      'selected_value': { id: 1, label: '1 L' },
      'paramName': 'WaiverOfPremiumRider',
      'isDisable': false,
      'showDropdown': false,
      'rider_name': '',
      'amount_ist': []
    },
    {
      'id': 2,
      'action': 'update',
      'brochure_link': 'https://www.edelweisstokio.in/riders/download/81-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
      'title': 'Accidental Death Benefit',
      'description': 'The Edelweiss Tokio Life - Accidental Death Benefit Rider gives you extra protection. In case an accident claims your life, your family will get the selected accidental death cover amount in addition to the base life cover amount.',
      'sum_assured': '',
      'initial_amount': '119',
      'is_selected': false,
      'one_time_fee': '22',
      'selected_value': { id: 1, label: '1 L' },
      'paramName': 'ADBRider',
      'showDropdown': true,
      'isDisable': false,
      'rider_name': 'ADB Rider',
      'amount_list': this.benefitAmountList()
    },
    {
      'id': 3,
      'action': 'update',
      'brochure_link': 'https://www.edelweisstokio.in/riders/download/82-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
      'title': 'Accidental Total and Permanent Disability Rider',
      'description': 'If you opt for Edelweiss Tokio Life - Accidental Total and Permanent Disability Rider, you’ll get the cover amount you have selected in case an accident causes permanent disability. This takes care of all your immediate expenses such as medications, hospital bills and regular expenses such as EMIs, education fees etc. The base policy continues as usual.',
      'sum_assured': '',
      'initial_amount': '96',
      'is_selected': false,
      'one_time_fee': '22',
      'selected_value': { id: 1, label: '1 L' },
      'paramName': 'ATPDRider',
      'showDropdown': true,
      'isDisable': false,
      'rider_name': 'ATPD Rider',
      'amount_list': this.benefitAmountList()
    },
    {
      'id': 4,
      'action': 'update',
      'brochure_link': 'https://www.edelweisstokio.in/riders/download/22-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
      'title': 'Critical Illness Rider',
      'description': 'Under Edelweiss Tokio Life - Critical Illness Rider, if you are diagnosed with one of the 12 specific critical illnesses, you will receive a payout of the critical illness cover you’ve selected. The policy will continue with the base life cover after critical illness claim paid. Term life insurance is for your family’s security but this rider will cover you financially against 12 listed critical illnesses.',
      'sum_assured': '',
      'initial_amount': '2295',
      'is_selected': false,
      'one_time_fee': '22',
      'selected_value': { id: 1, label: '1 L' },
      'paramName': 'CriticalIllnessRider',
      'showDropdown': true,
      'isDisable': false,
      'rider_name': 'Critical Illness Rider',
      'amount_list': this.criticalIllnessAmountList()
    },
    {
      'id': 5,
      'action': 'update',
      'brochure_link': 'https://www.edelweisstokio.in/riders/download/22-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
      'title': 'Income Benefit',
      'description': 'Under Edelweiss Tokio Life - Income Benefit Rider ensures your family has continuity of regular income even in your absence.',
      'sum_assured': '',
      'initial_amount': '885',
      'is_selected': false,
      'one_time_fee': '22',
      'selected_value': { id: 75000, label: '75000' },
      'paramName': 'IncomeBenefitRider',
      'showDropdown': true,
      'isDisable': false,
      'rider_name': 'Income Benefit Rider',
      'amount_list': this.incomeBenefitAmountList()
    },
    {
      'id': 6,
      'action': 'update',
      'brochure_link': 'https://www.edelweisstokio.in/riders/download/22-3?_ga=2.183241446.1125348727.1616483264-207121548.1616483264',
      'title': 'Payor Waiver Benefit Rider',
      'description': 'Under Edelweiss Tokio Life - Income Benefit Rider ensures your family has continuity of regular income even in your absence.',
      'sum_assured': '',
      'initial_amount': '885',
      'is_selected': false,
      'one_time_fee': '22',
      'selected_value': { id: 1, label: 'On Death' },
      'paramName': 'PayorWaiverBenefitRider',
      'showDropdown': true,
      'isDisable': false,
      'rider_name': 'Payor Waiver Benefit Rider',
      'amount_list': [
        { id: 1, label: 'On Death' },
        { id: 2, label: 'CI or ATPD' },
        { id: 3, label: 'CI or ATPD or Death' }
      ]
    }
  ];

  dummy_children_data = [
    {
      'id': 2,
      'action': 'update',
      'title': 'save for children',
      'isSelected': true,
      'death_cover': '2cr',
      'annual_price': '5000',
      'one_time_price': '980',
      'death_cover_payout_mode': 'test',
      'death_cover_price': '10101010',
      'premium_term': 'test',
      'policy_term': 'test',
      'recommended_price': '10101010',
      'premium_amount': '10101010',
      'gst': '10101010',
      'gst_percentage': '16',
      'total_price': '10101010',
      'saving_risk_name': 'test',
      'saving_risk_type': 'test',
      'saving_risk_amount': '10101010',
      'saving_payout_mode': 'test',
      'saving_payout_period': '3 years',
      'variation_type': 'test',
      'is_tobacco_user': false,
      'family_member_details': {
        'family_member_type': 'son',
        'gender': 'male',
        'family_member_name': 'vikas',
        'dob': '20-02-1993',
        'age': 28,
        'is_selected': true
      },
      'additional_benefits_list': [
        {
          'id': 1,
          'action': 'update',
          'benefit_name': 'Better Half Benefit',
          'benefit_description': 'Provides your spouse an additional live cover',
          'sum_assured': '10000',
          'is_selected': true,
          'one_time_fee': '22'
        },
        {
          'id': 2,
          'action': 'update',
          'benefit_name': 'Critical Illness Waiver',
          'benefit_description': 'Lumpsum payout in case of loss of earning due to illness.',
          'sum_assured': '',
          'is_selected': false,
          'one_time_fee': '1345'
        }
      ],
      'rider_list': [
        {
          'id': 1,
          'is_selected': false,
          'action': 'update',
          'rider_name': 'rider test',
          'rider_description': 'desc',
          'sum_assured': '10000',
          'one_time_fee': '22'
        }
      ],
      'user_journey': '1'
    }

  ]

  simplyProtectRequestData = {
    'PR_ID': '40012',
    'CashFlow': 'N',
    'LI_NAME': 'Ankur Singh',
    'LI_ENTRY_AGE': '21',
    'LI_DOB': '28 Dec 1998',
    'LI_GENDER': 'M',
    'LI_STATE': '0',
    'PROPOSER_NAME': 'Ankur Singh',
    'PROPOSER_AGE': '21',
    'PROPOSER_DOB': '28 Dec 1998',
    'PROPOSER_GENDER': 'M',
    'SameProposer': 'True',
    'COMPANY_STATE': '0',
    'GSTIN': '0',
    'GSTIN_Number': '0',
    'INPUT_MODE': '1',
    'category': '1',
    'PR_CHANNEL': '5',
    'isPdfByte': 'Y',
    'AGENT_ID': '0',
    'LI_MOBILENO': '0',
    'LI_EMAILID': '0',
    'PR_ANNPREM': '',
    'PR_MI': '0',
    'PR_SA': '50000',
    'PR_SAMF': '0',
    'PR_ModalPrem': '',
    'REQUESTSOURCE': '4',
    'emr_rate': '1',
    'flat_rate': '0',
    'PlanOption': '2',

    'LI_SMOKE': '1',
    'PR_PT': '10',
    'PR_PPT': '10',
    'SumAssuredpayoutoption': '7'

  }

  zindagiPlusRequestData = {
    'LI_NAME': 'Rajesh Bhagat',
    'LI_ENTRY_AGE': '21',
    'LI_DOB': '01 May 2000',
    'LI_GENDER': 'M',
    'LI_STATE': '0',
    'PROPOSER_NAME': 'Rajesh Bhagat',
    'PROPOSER_AGE': '21',
    'PROPOSER_DOB': '01 May 2000',
    'PROPOSER_GENDER': 'M',
    'SameProposer': 'True',
    'COMPANY_STATE': '0',
    'GSTIN': '',
    'GSTIN_Number': '0',
    'INPUT_MODE': '1',
    'PR_ID': '40038',
    'PR_PT': '48',
    'PR_PPT': '48',
    'AGENT_ID': '',
    'LI_MOBILENO': '',
    'LI_EMAILID': '',
    'AgentName': '',
    'AgentLocation': '',
    'PR_ANNPREM': '',
    'PR_MI': '0',
    'PR_SA': '100000000',
    'PR_SAMF': '0',
    'PR_ModalPrem': '',
    'REQUESTSOURCE': '4',
    'category': '4',
    'Underwriting': '1',
    'LI_SMOKE': '0',
    'PR_CHANNEL': '5',
    'PlanOption': '1',
    'AdditionalBenefit': '5',
    'MaritalStatus': '10',
    'BetterHalfBenefit': '12',
    'SpouseName': 'dd asd',
    'SpouseDOB': '20',
    'SpouseGender': '135',
    'DeathBenefitPayoutOption': '16',
    'SpouseTobaccoConsumer': '137',
    'ZP_PREFERREDLIST': '1',
    'ZP_OCCUPATION': 'SA',
    'ZP_EDUCATION': '5',
    'ZP_ANNINCOME': '2000000',
    'ZP_NATIONALITY': '1',
    'ADBRider': '',
    'ATPDRider': '',
    'CriticalIllnessRider': '',
    'HospitalityCashBenefitRider': '',
    'CashFlow': 'N',
    'PayoutPeriodMonths': '22',
    'Lumpsum': '28',
    'TopUpBenefit': '14'
  }

  aipRequestData = {
    'PR_ID': '40035',
    'CashFlow': 'N',
    'LI_STATE': '0',
    'PROPOSER_NAME': 'sad ads',
    'PROPOSER_AGE': '30',
    'PROPOSER_DOB': '10 Dec 1989',
    'PROPOSER_GENDER': 'M',
    'SameProposer': true,
    'COMPANY_STATE': '0',
    'GSTIN': '0',
    'GSTIN_Number': '0',
    'INPUT_MODE': '4',
    'PR_PT': '99-@li_entry_age',
    'PR_PPT': '12',
    'AGENT_ID': '0',
    'LI_MOBILENO': '9489849489',
    'LI_EMAILID': 'asdasd@sd.com',
    'PR_ANNPREM': '120000',
    'PR_MI': '0',
    'PR_SA': ' ',
    'PR_SAMF': '0',
    'PR_ModalPrem': 50000,
    'category': '12',
    'PR_CHANNEL': '1',
    'flat_rate': '0',
    'emr_rate': '1',
    'REQUESTSOURCE': '1',
    'ADBRider': '',
    'ATPDRider': '',
    'CriticalIllnessRider': '',
    'IncomeBenefitRider': '',
    'FamilyIncomeBenefit': '2',
    'IncomeOption': '4',
    'GuaranteedIncomeType': '5',
    'isPdfByte': 'Y',
    'WaiverOfPremiumRider': '',
    'PayorWaiverBenefitRider': '',
    'PWB': '',
    'LI_NAME': 'sad ads',
    'LI_ENTRY_AGE': '30',
    'LI_DOB': '10 Dec 1989',
    'LI_GENDER': 'M'
  }

  wealthUltimaRequestData = {
    'AGENT_ID': 'TW9001',
    'BondFund': '0',
    'CashFlow': 'Y',
    'category': '11',
    'COMPANY_STATE': '0',
    'emr_rate': '1.00',
    'EquityBlueChipFund': '0',
    'EquityLargeCapFund': '100',
    'EquityMidCapFund': '0',
    'EquityTop250Fund': '0',
    'flat_rate': '0',
    'FUNDSTRATEGYID': '1',
    'GILTFund': '0',
    'GSTIN': '0',
    'GSTIN_Number': '0',
    'INPUT_MODE': '4',
    'INTEREST_RATES': '0.1337121745330434',
    'IS_STAFF': '2',
    'isPdfByte': 'Y',
    'LI_DOB': '11 Nov 1991',
    'LI_EMAILID': 'sdafg@asdgf.in',
    'LI_ENTRY_AGE': 29,
    'LI_GENDER': 'M',
    'LI_MOBILENO': '8547102398',
    'LI_NAME': 'f f',
    'LI_STATE': '0',
    'ManagedFund': '0',
    'MaxType': '1',
    'NSAP_FLAG': '0',
    'optionId1': '2',
    'optionId2': '4',
    'optionId3': '6',
    'optionId4': '',
    'optionId5': '',
    'optionId6': '121',
    'optionValue1': '',
    'optionValue2': '',
    'optionValue3': '',
    'optionValue4': '',
    'optionValue5': '',
    'optionValue6': '',
    'PR_ANNPREM': '120000',
    // 'PR_ANNPREM': '72000',
    'PR_CHANNEL': '1',
    'PR_ID': '40009',
    'PR_MI': '0',
    'PR_ModalPrem': '',
    'PR_PPT': 7,
    'PR_PT': '30',
    'PR_SA': '1800000',
    // 'PR_SA': '1476000',
    'PR_SAMF': '0',
    'ProductType': 'ULIP',
    'PROPOSER_AGE': '',
    'PROPOSER_DOB': '11 Nov 1990',
    'PROPOSER_GENDER': 'M',
    'PROPOSER_NAME': 'f f',
    'REQUESTSOURCE': '1',
    'ResponseType': 'object',
    'SameProposer': true
  }

  simplyProtectResponseData = {};

  zindagiPlusResponseData = {};

  aipResponseData = {};

  aipBIJsonResponseData = [];

  wealthUltimaResponseData = {};

  deathCoverPayoutModeList = [
    {
      id: '8',
      label: 'Income Benefit'
    },
    {
      id: '7',
      label: 'Lumpsum'
    }
  ];
  premiumTermList = [
    {
      id: 5,
      label: '5 Years'
    },
    {
      id: 8,
      label: '8 Years'
    },
    {
      id: 10,
      label: '10 Years'
    },
    {
      id: 12,
      label: '12 Years'
    }
  ];
  policyTermList = [
    {
      id: '75-@li_entry_age',
      label: '75 Years'
    },
    {
      id: '85-@li_entry_age',
      label: '85 Years'
    },
    {
      id: '99-@li_entry_age',
      label: '99 Years'
    }
  ];
  ppfList = [
    {
      id: 4,
      label: 'Monthly'
    },
    {
      id: 3,
      label: 'Quarterly'
    },
    {
      id: 2,
      label: 'Semi - Annual'
    },
    {
      id: 1,
      label: 'Annual'
    }
  ];
  familyIncomeBenefitList = [
    {
      id: 1,
      label: 'Yes'
    },
    {
      id: 2,
      label: 'No'
    }
  ];
  incomeOptionList = [
    {
      id: 3,
      label: 'Early Income'
    },
    {
      id: 4,
      label: 'Deferred Income'
    }
  ];
  maturityAgeList = [
    {
      id: 75,
      label: '75 Years'
    },
    {
      id: 85,
      label: '85 Years'
    },
    {
      id: 99,
      label: '99 Years'
    }
  ];
  investingForList = [
    {
      id: 1,
      label: 'Myself'
    },
    {
      id: 2,
      label: 'Family'
    }
  ];
  priceSummary = '';
  protectionForMyFamily = [];
  protectionForMyHealth = [];
  growMyMoney = [];
  saveForChildren = [];
  saveForRetirement = [];

  selectedMaturityPayoutModeOptions = {};
  isMaturityPayoutMode = false;
  childPremiumTerm = {};
  isChildPremiumTerm = false;
  childPolicyTerm = {};
  isChildPolicyTerm = false;

  familyPremiumTerm = {};
  isFamilyPremiumTerm = false;
  familyPolicyTerm = {};
  isFamilyPolicyTerm = false;

  totalPriceData = {};
  journeyData = null;
  isValidateTelemedical = false;
  simplyProtectPlanOptions = [
    {
      id: '1',
      label: 'Life Cover'
    },
    {
      id: '2',
      label: 'Life Cover + Accidental Death Benefit'
    },
    {
      id: '5',
      label: 'Life Cover + Waiver of Premium on Accidental Total & Permanent Disability'
    },
    {
      id: '6',
      label: 'Life Cover + Waiver of Premium on Critical Illness'
    }
  ];

  productReasonList = [
    { reason: 'Recommended product is not fitting the need.', value: 'noNeed' },
    { reason: 'There is a better product that can be offered.', value: 'anotherBetterProduct' },
    { reason: 'System is recommending a wrong product.', value: 'recommendWrongProduct' },
    { reason: 'Other reason.', value: 'otherReason' }
  ];

  productList = [
    { title: 'Product 1' },
    { title: 'Product 2' },
    { title: 'Product 3' },
    { title: 'Product 4' },
    { title: 'Product 5' },
    { title: 'Product 6' },
    { title: 'Product 7' },
    { title: 'Product 8' },
    { title: 'Product 9' }
  ];

  wealthUltimaResponseBIJson2 = [];


  constructor() {
    makeObservable(this, {
      product_list_data: observable,
      dummy_product_list_data: observable,
      wealthUltimaBenefits: observable,
      zindagiPlusBenefits: observable,
      zindagiPlusRiders: observable,
      aipRiders: observable,
      simplyProtectResponseData: observable,
      zindagiPlusResponseData: observable,
      aipResponseData: observable,
      aipBIJsonResponseData: observable,
      wealthUltimaResponseData: observable,
      deathCoverPayoutModeList: observable,
      premiumTermList: observable,
      policyTermList: observable,
      ppfList: observable,
      familyIncomeBenefitList: observable,
      incomeOptionList: observable,
      maturityAgeList: observable,
      investingForList: observable,
      priceSummary: observable,
      protectionForMyFamily: observable,
      protectionForMyHealth: observable,
      growMyMoney: observable,
      saveForChildren: observable,
      saveForRetirement: observable,
      selectedMaturityPayoutModeOptions: observable,
      isMaturityPayoutMode: observable,
      childPremiumTerm: observable,
      isChildPremiumTerm: observable,
      childPolicyTerm: observable,
      isChildPolicyTerm: observable,
      familyPremiumTerm: observable,
      isFamilyPremiumTerm: observable,
      familyPolicyTerm: observable,
      isFamilyPolicyTerm: observable,
      totalPriceData: observable,
      journeyData: observable,
      isValidateTelemedical: observable,
      simplyProtectPlanOptions: observable,
      productReasonList: observable,
      productList: observable,
      wealthUltimaResponseBIJson2: observable,
      addRemoveAssured: action,
      getBIResponse: action,
      fetchSimplyProtect: action,
      fetchZindagiPlus: action,
      updateMembersFromNeeds: action,
      fetchAIP: action,
      fetchWealthUltima: action
    });
  }


  showErrorMsg(data) {
    let errors = []
    data.InputValidationStatus.map(m => {
      m.ErrorMessage.map(e => {
        errors.push(e.value)
      })
    })
    let error = ''
    errors.map((msg, index) => error = error + `${index + 1}) ${msg}, `)
    AuthStore.setErrorModal(error)
  }

  async addRemoveAssured(data) {
    try {
      global.loading(true)
      let id = toJS(AuthStore.journeyId)
      const result = await axios.post(`${APITypes.ADD_REMOVE_ASSURED}${id}/`, data)
      // console.log('API >> ADD REMOVE ASSURED >> ', result)

      return result.data

    } catch (error) {
      console.log('ERROR >> ', error)
    } finally {
      global.loading(false)
    }
    return []
  }

  async getBIResponse(data) {
    console.log('GET BI RESPONSE >> ', data)
    try {
      if (AuthStore.journeyId !== '') {
        this.priceSummary = ''
        global.loading(true)

        // var needId = localStorage.getItem('need_id')
        // var mode = localStorage.getItem('mode')
        // var targetAmount = localStorage.getItem('targetAmount')
        // var monthlyIncome = localStorage.getItem('monthlyIncome')

        var needId = data.needId
        var mode = data.mode
        var targetAmount = data.targetAmount
        var monthlyIncome = data.monthlyIncome

        var recommendationResponse = await axios.get(`${APITypes.GET_RECOMMENDATION}?need=${needId}&mode=${mode}&targetAmount=${targetAmount}&monthlyIncome=${monthlyIncome}`)

        var response = await axios.get(`${APITypes.GET_FAMILY_PRODUCT_DETAILS}?user_journey_id=${AuthStore.journeyId}`)

        var productName = recommendationResponse.data.high.name;

        console.log('getBIResponse >> ', response)

        let productResponseData = {}
        if (productName == 'Simply Protect') {
          await this.fetchSimplyProtect();
        } else if (productName == 'Active Income Plan') {
          // await this.fetchAIP();
        } else if (productName == 'Zindagi Plus') {
          // var spouseData = toJS(FamilyDetailsStore.existingFamilyMember).filter((member) => member.family_member_type.toLowerCase() == 'spouse');
          // if (spouseData.length > 0) {
          //   this.zindagiPlusRequestData.MaritalStatus = '10'
          //   this.zindagiPlusRequestData.SpouseName = spouseData[0].family_member_name
          //   this.zindagiPlusRequestData.SpouseDOB = spouseData[0].age
          //   this.zindagiPlusRequestData.SpouseGender = spouseData[0].gender == 'male' ? '134' : '135'
          // }
          // productResponseData = await this.fetchZindagiPlus();
        } else if (productName == 'Wealth Ultima') {
          //   await this.fetchWealthUltima();
        } else if (productName == 'Systematic Withdrawal Plan (Wealth Ultima)') {
          //   productName = 'Wealth Ultima'
          //   await this.fetchWealthUltima();
        } else {
          // await this.fetchAIP();
          // productName = 'Active Income Plan'
        }

        if (response.data.data && response.data.data.detail !== undefined) {
          return {
            'status': false
          }
        } else {

          // this.updateResponseStructure();
          if (productName == 'Simply Protect' || productName == 'Active Income Plan' || productName == 'Zindagi Plus' || productName == 'Wealth Ultima') {
            return {
              'status': true,
              // 'data': response.data
              'data': { ...productResponseData, productName }
            }
          } else {

            this.product_list_data = this.dummy_children_data
            return {
              'status': true,
              // 'data': response.data
              'data': this.dummy_children_data
            }
          }
        }
      }
    } catch (error) {
      // global.loading(false)
      return {
        'status': false
      }
    } finally {
      global.loading(false)
    }
  }

  async fetchSimplyProtect(reqParams) {
    try {
      global.loading(true)

      const response = await axios.post(APITypes.BI, reqParams)

      let data = JSON.parse(response.data.JsonOutput);


      if (data.Status == 'Fail') {
        // let error = data.InputValidationStatus[0].ErrorMessage[0].value
        // console.log('Error is ', error)
        // AuthStore.setErrorModal(error)
        this.showErrorMsg(data)
      }


      data = JSON.parse(data.BIJson);
      this.simplyProtectResponseData = data[data.length - 1]
      this.simplyProtectRequestData['PR_ANNPREM'] = this.simplyProtectResponseData.MODAL_PREM_TAX
      console.log('SIMPLY PROTECT RESPONSE >> ', toJS(this.simplyProtectResponseData))
      this.priceSummary = {
        'need_title': localStorage.getItem('need_title'),
        'total': this.simplyProtectResponseData.MODAL_PREM_TAX
      }

      return response.data

    } catch (error) {
      // console.log('ERROR >> ', error)
    } finally {
      global.loading(false)
    }
    return {}
  }

  async fetchZindagiPlus(requestParams) {
    try {
      global.loading(true)

      const response = await axios.post(APITypes.BI, requestParams)
      // let data = JSON.parse(response.data.JsonOutput);

      // if (data.Status == 'Fail') {
      //   let error = data.InputValidationStatus[0].ErrorMessage
      //   if (error.length > 0) {
      //     error = error[0].value
      //     this.isValidateTelemedical = error == 'Sorry, Zindagi Plus cannot be offered to the current profile'
      //     if (this.isValidateTelemedical) {
      //       this.zindagiPlusBenefits[3].is_selected = false
      //     }
      //   } else {
      //     error = 'Please check your inputs'
      //   }
      //   // console.log('Error is ', error)
      //   AuthStore.setErrorModal(error)
      // }

      // let jsonOutputData = data;
      // data = JSON.parse(data.BIJson);
      // console.log('ZINDAGI PLUS BI RESULT ACTION >> ', data)

      // this.zindagiPlusResponseData = data[data.length - 1]

      // TODO: SET IN ZINDAGI PLUS PRODUCT FILE
      // this.zindagiPlusRiders.map((riderData) => {
      //   let inputValidationStatus = jsonOutputData.InputValidationStatus.filter((item) => item.ProductName == riderData.rider_name)
      //   riderData.sum_assured = inputValidationStatus[0].ModalPremium.toString()
      // })
      return response.data

    } catch (error) {
      console.log('ERROR >> ', error)
    } finally {
      global.loading(false)
    }
    return {}
  }

  async fetchAllData() {
    let response = await AuthStore.fetchAllData();
    this.journeyData = response.data
    return response.data
  }

  updateTabChangeData(needName, productID, tab) {
    let productIndex = toJS(this.product_list_data).findIndex(obj => obj.need_unique_name === needName)

    let productObj = toJS(this.product_list_data)[productIndex]

    let memberIndex = productObj.data.findIndex(member => member.id === productID)

    let member = productObj.data[memberIndex]
    member.tab = tab
    console.log('UPDATE TAB CHANGES DATA >> ', member)

    productObj.data[memberIndex] = member

    this.product_list_data[productIndex] = productObj
  }

  updateBIResponseToData(needName, productID, resData) {
    let productIndex = toJS(this.product_list_data).findIndex(obj => obj.need_unique_name === needName)

    let productObj = toJS(this.product_list_data)[productIndex]

    let memberIndex = productObj.data.findIndex(member => member.product_id === productID)
    let member = productObj.data[memberIndex]

    member.BI = resData || {}
    productObj.data[memberIndex] = member

    this.product_list_data[productIndex] = productObj
    // console.log('UPDATE BI RESPONSE TO DATA >> END', toJS(this.product_list_data))
  }

  async updateMembersFromNeeds(needName, selectedMembers) {
    try {
      selectedMembers = JSON.parse(JSON.stringify(selectedMembers))
      let productIndex = toJS(this.product_list_data).findIndex(obj => obj.need_unique_name === needName)

      let productObj = toJS(this.product_list_data)[productIndex]

      const familyMembers = toJS(FamilyDetailsStore.familyMembers).filter(member => {
        let memberID = '' + member.id
        return Object.keys(selectedMembers).includes(memberID)
      })
      let finalDataArray = []
      familyMembers.map(member => {
        let filterMember = productObj.data.filter(m => m.id == member.id)[0]
        if (filterMember) {
          if (selectedMembers[filterMember.id] != filterMember.isSelected) {
            let data = {
              ...member,
              'is_selected': selectedMembers[member.id],
              'need_unique_name': needName,
              'family_id': member.id,
              'action': 'remove_insured',
              'family_product_id': filterMember.product_id
            }
            finalDataArray.push(data)
          }
        } else if (!filterMember && selectedMembers[member.id]) {
          let data = {
            ...member,
            'is_selected': true,
            'need_unique_name': needName,
            'family_id': member.id,
            'action': '',
            'family_product_id': ''
          }
          finalDataArray.push(data)
        }
      })

      console.log('ADD ASSURED DATA >> ', needName, selectedMembers, finalDataArray)

      let result = await this.addRemoveAssured(finalDataArray)
      console.log('ADD ASSURED RESULT >> ', result)
      let newData = result && result ? result : {}
      console.log('ADD ASSURED RESULT >> ', newData)
      let productsData = []
      newData.map((item) => {
        if (item && item.data) {
          productsData.push(item)
        }
      })

      if (productsData.length !== 0) {
        productsData.map((item) => {
          productObj.data = [...productObj.data, item.data[0]]
        })
      }

      finalDataArray.map((member) => {
        if (!member.is_selected) {
          productObj.data.map(async (item, index) => {
            if (item.id == member.id) {
              console.log('REMOVE MEMBER FROM DATA >> ', member, item)
              delete productObj.data.splice(index, 1);
              let totalPriceData = toJS(this.totalPriceData)
              await new Promise((resolve, reject) => {
                Object.keys(totalPriceData[member.need_unique_name]).map(obj => {
                  if (obj.includes(item.id)) {
                    let needData = totalPriceData[member.need_unique_name]
                    delete needData[obj]
                    totalPriceData[member.need_unique_name] = { ...needData }
                    console.log('REMOVE MEMBER FROM DATA >> ', obj, needData, totalPriceData)
                  }
                  resolve()
                })
              })
              this.totalPriceData = toJS(totalPriceData)
              console.log('REMOVE MEMBER FROM DATA 2 >> ', toJS(this.totalPriceData))
            }
          })
        }
      })

      // REMOVE DATA FROM PRICE SUMMARY DATA OBJECT


      this.product_list_data[productIndex] = productObj
      this.product_list_data = [...toJS(this.product_list_data)]




      // OLD CODE 11-04-2021

      // let members = productObj.data.filter(member => {
      //   if (selectedMembers[member.id]) {
      //     delete selectedMembers[member.id]
      //     return true
      //   }
      //   return false
      // })

      // let members1 = toJS(FamilyDetailsStore.familyMembers).filter(member => selectedMembers[member.id])

      // members1.map(member => {
      //   member.product_id = uuidv4()
      //   member.BI = {}
      //   member.age = member.age
      //   member.dob = member.dob
      //   member.family_member_name = member.name
      //   member.family_member_type = member.type
      //   member.gender = member.gender
      //   member.id = member.id
      //   member.is_selected = member.isSelected
      //   member.family_monthly_income = member.monthlyIncome
      // })

      // console.log('UPDATED MEMBERS LIST >> ', selectedMembers, members, members1)

      // productObj.data = [...members, ...members1]

      // this.product_list_data[productIndex] = productObj
      // this.product_list_data = [...toJS(this.product_list_data)]

      console.log('FINAL UPDATED MEMEBRS >> ', toJS(this.product_list_data), needName)
      return
    } catch (error) {
      console.error('ERROR >> ', error)
    }

  }

  async updateResponseStructure() {
    // this.product_list_data = []
    try {
      global.loading(true)
      let data = ''
      if (!this.journeyData) {
        data = await this.fetchAllData()
      } else {
        data = this.journeyData
      }
      const result = await axios.get(`${APITypes.GET_RECOMMENDATION}${AuthStore.journeyId}`)

      if (result.status == 'success') {
        this.product_list_data = result.data
      } else {
        this.product_list_data = []
      }

      global.loading(false)
    } catch (error) {
      global.loading(false)
      // console.log('error', error);
    }
  }

  async dataChange(paramName, paramValue, productId, productCode, stopAPICall) {
    // console.log('DATA CHANGES >> ', paramName, paramValue, productCode)
    switch (productCode) {

      case '40009':
        await this.updateWealthUltimaData(paramName, paramValue, productId, stopAPICall);
        break;
    }
  }





  /******************** AIP PRODUCT CODE START *******************/

  async fetchAIP(reqParams) {
    global.loading(true)


    try {
      const response = await axios.post(APITypes.BI, reqParams)
      // let data = JSON.parse(response.data.JsonOutput);

      // if (data.Status == 'Fail') {
      //   let error = data.InputValidationStatus[0].ErrorMessage
      //   // [0].value
      //   if (error.length > 0) {
      //     error = error[0].value
      //   } else {
      //     error = 'Please check your inputs'
      //   }
      //   console.log('Error is ', error)
      //   AuthStore.setErrorModal(error)
      // }
      // let jsonOutputData = data;
      // console.log('BI Response data is ', data)
      // data = JSON.parse(data.BIJson);
      // console.log('BI Response data is  >', data)


      // this.aipResponseData = data[data.length - 1]
      // console.log('BI Response data is  >', toJS(this.aipResponseData))

      // this.aipRiders.map((riderData) => {
      //   let inputValidationStatus = jsonOutputData.InputValidationStatus.filter((item) => item.ProductName == riderData.rider_name)
      //   if (inputValidationStatus.length > 0) {
      //     riderData.sum_assured = inputValidationStatus[0].ModalPremium.toString()
      //   }
      // })

      // this.priceSummary = {
      //   'need_title': localStorage.getItem('need_title'),
      //   'total': this.aipResponseData.TOTAL_PREM_TAX
      // }

      return response.data

      // console.log('aipResponseData is ', data[data.length - 1])
    } catch (error) {
      console.log('Error is ', error)
    } finally {

      global.loading(false)
    }


  }


  /***********************AIP PRODUCT END */

  /******************** WEALTH ULTIMA PRODUCT CODE START *******************/

  async fetchWealthUltima(reqParams) {
    global.loading(true)

    try {

      const response = await axios.post(APITypes.BI, reqParams)

      // let data = JSON.parse(response.data.JsonOutput);
      // let modalPremium = data.InputValidationStatus[0].ModalPremium

      // if (data.Status == 'Fail') {
      //   let error = data.InputValidationStatus[0].ErrorMessage[0].value
      //   console.log('Error is ', error)
      //   AuthStore.setErrorModal(error)
      // }

      // // console.log('wealthUltimaResponseData is ', data)
      // let biJson = JSON.parse(data.BIJson2);
      // data = JSON.parse(data.BIJson);
      // // console.log('wealthUltimaResponseData is ', data[data.length - 1])

      // this.wealthUltimaResponseData = data[data.length - 1]
      // this.wealthUltimaResponseBIJson2 = biJson[data.length - 1]
      // this.wealthUltimaResponseData.ModalPremium = modalPremium

      // this.priceSummary = {
      //   'need_title': localStorage.getItem('need_title'),
      //   'total': this.wealthUltimaResponseData.modalPremium
      // }

      return response.data

    } catch (error) {
      console.log('Error is ', error)
    } finally {
      global.loading(false)
    }

    return {}
  }



  updateParam(list, type) {
    let updatedList = []
    if (list != undefined) {
      updatedList = list.map(function (item) {
        if (type == 'additional') {
          item.title = item.benefit_name
          item.description = item.benefit_description
        } else {
          item.title = item.rider_name
          item.description = item.rider_description
        }
        return item
      });
    }
    return updatedList;
  }

  updateDetails(item) {
    // console.log('UPDATE DETAILS >> ', item)
    const dummyList = toJS(this.dummy_product_list_data);
    dummyList.map((product, index) => {
      if (item.id == product.id) {
        dummyList[index] = { ...item }
      }
    })
    this.dummy_product_list_data = []
    this.dummy_product_list_data = dummyList;
  }

  updateProductsDetails(key, item) {
    let dummyList = this.product_list_data
    for (let index = 0; index < dummyList.length; index++) {
      const element = dummyList[index];
      if (element[key]) {
        element[key].map((product, i) => {
          if (item.id == product.id) {
            element[key][i] = { ...item }
          }
          // console.log('ELEMENT >> ', element, key, i, item)
        })
        dummyList[index] = element
        break
      }
    }
    // console.log('UPDATE DETAILS >> ', item, dummyList)
    this.product_list_data = []
    this.product_list_data = dummyList;
  }

  updateFamilyMember(data, key) {
    const dummy_data = {
      'id': uuidv4(),
      'action': 'update',
      'title': key,
      'isSelected': true,
      'death_cover': '',
      'annual_price': '',
      'one_time_price': '10101010',
      'death_cover_payout_mode': 'test',
      'death_cover_price': '10101010',
      'premium_term': 'test',
      'policy_term': 'test',
      'recommended_price': '10101010',
      'premium_amount': '',
      'gst': '10101010',
      'gst_percentage': '16',
      'total_price': '10101010',
      'saving_risk_name': 'test',
      'saving_risk_type': 'test',
      'saving_risk_amount': '10101010',
      'saving_payout_mode': 'test',
      'saving_payout_period': '3 years',
      'variation_type': 'test',
      'is_tobacco_user': true,
      'family_member_details': data,
      'additional_benefits_list': [
        {
          'id': uuidv4(),
          'action': 'update',
          'benefit_name': 'Better Half Benefit',
          'benefit_description': 'Provides your spouse an additional live cover',
          'sum_assured': '10000',
          'is_selected': true,
          'one_time_fee': '22'
        },
        {
          'id': uuidv4(),
          'action': 'update',
          'benefit_name': 'Critical Illness Waiver',
          'benefit_description': 'Lumpsum payout in case of loss of earning due to illness.',
          'sum_assured': '',
          'is_selected': false,
          'one_time_fee': '1345'
        }
      ],
      'rider_list': [
        {
          'id': uuidv4(),
          'is_selected': false,
          'action': 'update',
          'rider_name': 'rider test',
          'rider_description': 'desc',
          'sum_assured': '10000',
          'one_time_fee': '22'
        },
        {
          'id': uuidv4(),
          'is_selected': false,
          'action': 'update',
          'rider_name': 'rider test1',
          'rider_description': 'desc',
          'sum_assured': '10000',
          'one_time_fee': '22'
        }
      ],
      'user_journey': '1'
    }
    const list = toJS(this.dummy_product_list_data);
    list.push(dummy_data);

    this.dummy_product_list_data = []
    this.dummy_product_list_data = list
    this.updateResponseStructure();
  }

  deleteFamilyMember(familyData) {
    const dummyList = toJS(this.dummy_product_list_data);
    dummyList.map((item, index) => {
      if (familyData.id == item.id) {
        delete dummyList.splice(index, 1);
      }
    })
    this.dummy_product_list_data = []
    this.dummy_product_list_data = dummyList;
    this.updateResponseStructure();
  }

  onMaturityPayoutModeOptions = (value) => {
    this.selectedMaturityPayoutModeOptions = value
  }

  getQuotationID(data) {
    try {
      let jsonOutputData = JSON.parse(data.JsonOutput);
      return jsonOutputData.QuotationId
    } catch (error) { }
    return ''
  }

  async onNextBtnClick(props) {

    let finalData = []

    let listData = toJS(this.product_list_data)
    listData.map(needData => {
      let familyList = needData.data
      familyList.map(memberData => {
        let biData = memberData.BI
        if (Array.isArray(biData)) {
          biData.map(bi => {
            let obj = {
              product_id: bi.product_id,
              is_selected: bi.risk == (memberData.tab || 'high'),
              quotation_id: this.getQuotationID(bi.BI)
            }
            finalData.push(obj)
          })
        } else {
          let obj = {
            product_id: memberData.product_id,
            is_selected: true,
            quotation_id: this.getQuotationID(biData)
          }
          finalData.push(obj)
        }
      })
    })
    console.log('SUBMIT OPTIMIZATION DATA >> ', finalData)

    // TODO: Pending API integration to save product details
    const response = await axios.post(APITypes.QUOTATION_API + AuthStore.journeyId + '/', finalData);
    if (response.status.toLowerCase() === 'success') {
      // this.fetchAllData()
      props.history.push(`/solution/${AuthStore.journeyId}`)
      return response.data
    } else {
      return []
    }
  }

  // Accidental Death Benefit and Permanent Disability 1L to 1CR
  benefitAmountList() {
    let values = []
    for (let i = 1; i < 100; i++) {
      values.push({
        id: i,
        label: i + ' L'
      })
    }

    values.push({
      id: 100,
      label: '1 CR'
    })

    return values;
  }

  // Critical Illness Rider 1L to 50L
  criticalIllnessAmountList() {
    let values = []
    for (let i = 1; i < 50; i++) {
      values.push({
        id: i,
        label: i + ' L'
      })
    }
    return values;
  }

  // Income Benefit = 75K to 5,50,000 L ==== diff 75K
  incomeBenefitAmountList() {
    let values = []
    for (let i = 1; i < 8; i++) {
      let amount = 75000 * i
      values.push({
        id: amount,
        label: amount.toString()
      })
    }

    let lastAmount = 550000;
    values.push({
      id: 550000,
      label: lastAmount.toString()
    })
    return values;
  }
}

export default new FamilyProtectionStore();
