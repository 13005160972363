import React from 'react'
import './custom-radio-button.css'

import PropTypes from 'prop-types'

function CustomRadioButton(props) {
  const { id, isChecked, onChange, shape, mark, title, titleStyle, containerStyle, checkmarkPosition, groupName } = props;
  return (
    <label className={`${containerStyle} d-inline-flex position-relative align-items-start cursor-pointer custom-checkbox m-0`}>
      <input id={id} type='radio' className='hidden-radio' name={groupName} checked={isChecked} onChange={(event) => onChange(event.target.checked)} />
      <span  className={`${shape == 'rounded' ? 'rounded' : ''} ${mark == 'checkmark' ? 'mark-icon' : ''} checkmark ${checkmarkPosition}`}></span>
      <p className={`${titleStyle} mb-0 title`}>{title}</p>
    </label>
  )
}

CustomRadioButton.propTypes = {
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  groupName: PropTypes.string,
  titleStyle: PropTypes.string,
  shape: PropTypes.string,
  mark: PropTypes.string,
  className: PropTypes.string
}

export default CustomRadioButton
