import axios from "utils/axios"
import * as APITypes from "constants/API"
import { NUMBER_REGEX } from "constants/Constants"

class Utils {
  togglePhoneNumberCheckbox = (
    formController,
    memberType,
    fieldName,
    value
  ) => {
    formController.$(memberType).$(fieldName).set("value", !value)

    let mobileNoValue = formController.$(memberType).$("mobile_number").value
    let mobileCodeValue = formController.$(memberType).$("mobile_number_code").value

    if(value === false && mobileNoValue){
      formController.$(memberType).$("whatsapp_mobile_number").value = mobileNoValue
      formController.$(memberType).$("whatsapp_mobile_number_code").value = mobileCodeValue
    }else{
      formController.$(memberType).$("whatsapp_mobile_number").value = ''
      formController.$(memberType).$("whatsapp_mobile_number_code").value = ''
    }
  }

  updateValues = (formController, member, fieldName, value) => {
    let updatedValue = value
    let NameRegEx = new RegExp("^([A-Za-z]{1,30})$")

    if (
      fieldName == "first_name" ||
      fieldName == "middle_name" ||
      fieldName == "last_name"
    ) {
      if (NameRegEx.test(updatedValue)) {
        updatedValue = updatedValue
      } else {
        updatedValue = formController.$(member).$(fieldName).value
        if (updatedValue.length == 1) {
          updatedValue = ""
        }
      }

      if (updatedValue.length == 1) {
        updatedValue = updatedValue.toUpperCase()
      }
    }

    formController.$(member).$(fieldName).value = updatedValue
  }

  onPreviewBtnCick = async (formValues) => {
    const dataArray = []

    let familyValues = []

    for (let key in formValues) {
      familyValues.push(formValues[key])
    }

    familyValues.forEach((member) => {
      const {
        id: proposer_id,
        salutation,
        first_name,
        middle_name,
        last_name,
        email,
        mobile_number_code:mobile_country_code,
        mobile_number: mobile_no,
        same_as_mobile_number:mobile_whatsapp_same,
        whatsapp_mobile_number_code:whatsapp_country_code,
        whatsapp_mobile_number: whatsapp_no,
      } = member

      let memberObj = {
        proposer_id,
        salutation:salutation.id,
        first_name,
        middle_name,
        last_name,
        email,
        mobile_country_code,
        mobile_no,
        mobile_whatsapp_same,
        whatsapp_country_code,
        whatsapp_no,
      }

      dataArray.push(memberObj)
    })

    const dataToBeSubmitted = {
      proposer_list: dataArray,
    }



    let response = await axios.post(
      APITypes.SAVE_COMMUNICATION_DETAILS,
      dataToBeSubmitted
    )

    return response.status
  }

  mobileNumberOnChange = (formController,memberType,fieldType,value) => {
    if(NUMBER_REGEX.test(value)){
      formController.$(memberType).$(fieldType).value = value
      return
    }
    if(value.length <= 1){
      formController.$(memberType).$(fieldType).value = ""
    }
  }


}

export default new Utils()
