// React Imports
import { useState } from 'react'

// Package Imports

// Project Imports
import RetirementCalculator from '../../calculators/RetirementCalculator'
import RetirementcalculatedValue from '../../calculators/RetirementCalculatedValue'

const RetirementCalculatorForm = (props) => {

  const [showCalculatedValue, setShowCalculatedValue] = useState(false);
  const [calculatedData, setCalculatedData] = useState({})
  
  return (
    <>
      {!showCalculatedValue ? (
        <RetirementCalculator
        chooseNeedFormController={props.chooseNeedFormController}
          monthlyExpenseValuesFromChooseNeeds={props.monthlyExpenseValuesFromChooseNeeds}
          setCalculatedData={setCalculatedData}
          setShowCalculatedValue={setShowCalculatedValue}
          hideCalculatorModal={props.hideCalculatorModal}
        />
      ) : (
        <RetirementcalculatedValue
          setShowCalculatedValue={setShowCalculatedValue}
          calculatedData={calculatedData}
          hideCalculatorModal={props.hideCalculatorModal}
          onUseTheValueClick={props.onUseTheValueClick}
        />
      )}
    </>
  )
}

export default RetirementCalculatorForm
