// Package Imports
import axios from "utils/axios";
import { toJS } from "mobx";
import * as APITypes from 'constants/API';
import { preFillUserFields } from "./utils";
import FamilyDetailsStore from "../store/FamilyDetailsStore";
import fetchSalutationMaster from 'utils/fetchSalutationMaster'
import AuthStore from "modules/login/store/AuthStore";

const createEmiSectionFieldObject = (formController, fieldName, expenseName, expenseRange, selectedEmiList, emiValueCallBack, lastEmiType) => {


    let selectedExpenseOption = selectedEmiList.find(expense => expense.expense_label.toLowerCase().replaceAll(" ", '_') === expenseName)


    // Option Ranges
    let fieldObject = {}
    fieldObject.label = "option-range"
    fieldObject.name = "option_range"
    fieldObject.disabled = true

    let optionList = []
    expenseRange.forEach(expense_range_item => {
        optionList.push({
            id: expense_range_item.id,
            label: expense_range_item.value,
            value: expense_range_item.mid_value,
        })
    })
    fieldObject.extra = {
        optionList: optionList
    }
    fieldObject.value = {}

    if (selectedExpenseOption !== undefined) {
        fieldObject.value = {
            id: selectedExpenseOption.expense_range,
            label: selectedExpenseOption.expense_range_key,
            value: selectedExpenseOption.value,
            journeyExpenseId: selectedExpenseOption.id
        }
    }

    formController.$(fieldName).$(expenseName).add(fieldObject)


    // Paid Years
    fieldObject = {}

    fieldObject.label = "Paid"
    fieldObject.name = "paid_years"
    fieldObject.disabled = true

    let paidYearsRangeOptionList = Array.from({ length: 30 }, (_, i) => {
        return {
            id: i,
            label: "" + i,
            value: i,
        }
    })

    fieldObject.extra = {
        optionList: paidYearsRangeOptionList
    }
    fieldObject.value = {}

    if (selectedExpenseOption !== undefined) {
        fieldObject.value = {
            id: selectedExpenseOption.paid_loan_tenure,
            label: selectedExpenseOption.paid_loan_tenure,
            value: selectedExpenseOption.paid_loan_tenure,
        }
    }

    formController.$(fieldName).$(expenseName).add(fieldObject)

    // Total years
    fieldObject = {}

    fieldObject.label = "Total"
    fieldObject.name = "total_years"
    fieldObject.disabled = true

    let totalYearsRangeOptionList = Array.from({ length: 30 }, (_, i) => {
        return {
            id: i + 1,
            label: i + 1,
            value: i + 1,
        }
    })

    fieldObject.extra = {
        optionList: totalYearsRangeOptionList
    }

    fieldObject.value = {}

    if (selectedExpenseOption !== undefined) {
        fieldObject.value = {
            id: selectedExpenseOption.total_loan_tenure,
            label: selectedExpenseOption.total_loan_tenure,
            value: selectedExpenseOption.total_loan_tenure,
        }
    }

    formController.$(fieldName).$(expenseName).add(fieldObject)

    /*  in the below function, if the expenseName and the lastEmiType is same, then it implieas that the initialValues are done loading, once it is done loading, we can set the  show_emi_fields to true which will render the emi filds onto the screen also set the formObserver in the emiValueCallBack function */

    if(emiValueCallBack){ //// TODO check this logic just fixed it for temp purpose

        emiValueCallBack(expenseName,lastEmiType)
    }

}



const createExpenseFieldObject = (formController, field_name, expense_type, selectedExpenseList,selectedEmiList = [],emiValueCallBack,lastEmiType) => {
    expense_type.forEach((item) => {
        let expenseTypeObj = {}
        expenseTypeObj.label = item.label_name,
            expenseTypeObj.disabled = true,
            expenseTypeObj.name = item.label_name.toLowerCase().replaceAll(" ", '_'),
            expenseTypeObj.value = {}
        expenseTypeObj.extra = {
            optionList: [],
            bottomSheetTitle: "Select an Option"
        }
        let optionList = []
        item.expense_range.forEach(expense_range_item => {
            optionList.push({
                id: expense_range_item.id,
                label: expense_range_item.value,
                value: expense_range_item.mid_value,
            })
        })
        expenseTypeObj.extra.optionList = optionList

        let selectedExpenseOption = selectedExpenseList?.find(expense => expense.expense_label === expenseTypeObj.label && expenseTypeObj.extra.optionList.some((item)=> item.id === expense.expense_range_id))

        if (selectedExpenseOption !== undefined) {
            expenseTypeObj.value = {
                id: selectedExpenseOption.expense_range_id,
                label: selectedExpenseOption.expense_range_key,
                journeyExpenseId: selectedExpenseOption.id
            }
            expenseTypeObj.disabled = false
        }

        formController.$(field_name).add(expenseTypeObj)

        if (field_name === "monthly_emi") {

            let selectedEmiOption = selectedEmiList.find(expense => expense.expense_label === expenseTypeObj.label)

            selectedEmiList.forEach(expense => {
                expense.expense_label === expenseTypeObj.label
            })

            if (selectedEmiOption !== undefined) {
                expenseTypeObj.disabled = false
            }

            formController.$(field_name).add(expenseTypeObj)

            createEmiSectionFieldObject(formController, field_name, expenseTypeObj.name, item.expense_range, selectedEmiList,emiValueCallBack,lastEmiType)

        } else {
            formController.$(field_name).add(expenseTypeObj)
        }
    })
}

const fetchNationalityMaster = async () => {
    const response = await axios.get(APITypes.NATIONALITY_MASTER)
    return response.data
}

const monthlyIncomeMaster = async () => {
    const response = await axios.get(APITypes.MONTHLY_INCOME_MASTER)
    return response.data
}

const initialDefaults = async (formController, journeyId, journeyData, expenseList, lifeCoverList,emiValueCallBack) => {

    let nationalMasterList = await fetchNationalityMaster();
    let salutationMasterList = await fetchSalutationMaster();
    let monthlyIncomeMasterList = await monthlyIncomeMaster();

    FamilyDetailsStore.updateNationalityList(nationalMasterList);
    FamilyDetailsStore.updateLifeCoverList(lifeCoverList);
    FamilyDetailsStore.updateSalutationList(salutationMasterList);
    FamilyDetailsStore.updateMonthlyIncomelist(monthlyIncomeMasterList);

    let familyFieldList = preFillUserFields(
        journeyData,
        lifeCoverList,
        nationalMasterList,
        salutationMasterList,
        monthlyIncomeMasterList
    )

    familyFieldList.forEach(item => formController.$("members").add(item))

    let monthlyExpense = expenseList.find(item => item.name === 'Monthly Expense Details')
    let liabilitiesDetails = expenseList.find(item => item.name === 'Liability Details')
    let currentSavingsDetails = expenseList.find(item => item.name === 'Savings & Investments')
    let monthlyEMIDetails = expenseList.find(item => item.name === 'Monthly EMI')

    let lastEmiType = monthlyEMIDetails.expense_label[monthlyEMIDetails.expense_label.length -1].label_name
    lastEmiType = lastEmiType.toLowerCase().replaceAll(" ", '_')

    formController.$("journey_id").value = journeyId

    let familyDetails = toJS(AuthStore.journeyData.family_details_list)     
    let updatedJourneyData = toJS(AuthStore.journeyData)
    let selfsExpenseList = familyDetails.find((item) => item.family_member_type.toLowerCase() === 'self').expense_list
    let selfsEmiList = familyDetails.find((item) => item.family_member_type.toLowerCase() === 'self').user_emi
    if(updatedJourneyData)
    createExpenseFieldObject(formController, 'monthly_expense', monthlyExpense.expense_label, selfsExpenseList)
    createExpenseFieldObject(formController, 'liabilities', liabilitiesDetails.expense_label, selfsExpenseList)
    createExpenseFieldObject(formController, 'savings', currentSavingsDetails.expense_label, selfsExpenseList)
    createExpenseFieldObject(formController, 'monthly_emi', monthlyEMIDetails.expense_label, selfsExpenseList,selfsEmiList,emiValueCallBack,lastEmiType)

}

export default initialDefaults