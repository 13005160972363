// Package Imports
import React, { useState } from "react";

import { Col, Row, Card, Form, Modal } from "react-bootstrap";
import { observer } from "mobx-react";

import PropTypes from 'prop-types'

// Project Imports
import { CustomCheckbox, BottomsheetDropdown } from "components";
import { InfoArrowBlackImg } from "constants/Images";

import "./additional-rider-benifits-card.css";
import UiButton from "../button/Button";

const AdditionalRiderBenifitsCard = observer((props) => {

    const [isDescriptionModalOpen, setisDescriptionModalOpen] = useState(false);
    const [modalDescription,setModalDescription] = useState('')
    const [modalTitle,setModalTitle] = useState('')

    const { form, data, showWOP, showCriticalIllness, showIncomeBenefit, showHospitalityCashBenefit, showPWB, showATPD, showADB } = props;

    const showDescriptionModal = (title,discription) => {
        setModalDescription(discription)
        setModalTitle(title)
        setisDescriptionModalOpen(true)
    }

    const hideDescriptionModal = () => {
        setisDescriptionModalOpen(false)
    }

    const fetchRiderPremiumValue = (data, riderName) => {
        if (data?.data?.parsedResponse?.inputValidationStatus && data?.data?.parsedResponse?.inputValidationStatus?.length > 0) {
            let opt = data.data.parsedResponse.inputValidationStatus.filter((item) => riderName == item.ProductName)
            if (opt.length > 0) {
                return data.data.isValidationExist ? 0 : opt[0].ModalPremium.toString()
            }
            return 0
        }
        return 0
    };
    return (
        <>
        <div className="additional-rider-cards">
            <p className="mb-2 pb-1 font-size-14 font-weight-medium rider-section-title">
                Additional Rider Available
            </p>
            <Card>
                <Card.Body>
                    {/* WOP Rider */}
                    {showWOP && <Row
                        className={`${form.$("WaiverOfPremiumRider").disabled ? "disable-card" : ""
                            } ${form.$("WaiverOfPremiumRider").value? 'mb-4':null}`}
                    >
                        <Col className="col-9">
                            <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                                <CustomCheckbox
                                    shape="rounded"
                                    title={form.$("WaiverOfPremiumRider").label}
                                    titleStyle=' font-weight-medium font-size-14 pl-2 text-primary'
                                    isChecked={form.$("WaiverOfPremiumRider").value}
                                    onChange={() =>{
                                        form.$("WaiverOfPremiumRider").extra.changeRiderStatus(form, data)
                                    }
                                        
                                    }
                                    mark="checkmark"
                                />

                                <img
                                    className={`pl-2 ${form.$("WaiverOfPremiumRider").disabled
                                        ? "inf0-icon-disable"
                                        : ""
                                        }`}
                                    src={InfoArrowBlackImg}
                                    alt="info"
                                    onClick={() => showDescriptionModal(form.$("WaiverOfPremiumRider").label,form.$("WaiverOfPremiumRider").extra.description)}
                                />
                            </div>
                        </Col>
                        <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                            {form.$("WaiverOfPremiumRider").value ? (
                                <React.Fragment>
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="mb-0 font-weight-300 font-size-20 rider-amount">
                                            {global.toINR(fetchRiderPremiumValue(data, form.$("WaiverOfPremiumRider").extra.rider_name))}
                                        </p>

                                    </div>
                                    <p className="mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color"></p>
                                </React.Fragment>
                            ) : (
                                <p className="mb-0 font-weight-300 font-size-14 text-center description-text-color">
                                    Not <br /> Selected
                                </p>
                            )}
                        </Col>
                    </Row>}

                    {/* ADB Rider */}
                    {showADB && <Row
                        className={`${form.$("ADB").disabled ? "disable-card" : ""
                            }`}
                    >
                        <Col className="col-9">
                            <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                                <CustomCheckbox
                                    shape="rounded"
                                    title={form.$("ADB").label}
                                    titleStyle=' font-weight-medium font-size-14 pl-2 text-primary'
                                    isChecked={form.$("ADB").value}
                                    onChange={() =>{
                                        form.$("ADB").extra.changeRiderStatus(form, data)
                                    }
                                    }
                                    mark="checkmark"
                                />
                                <img
                                    className={`pl-2 ${form.$("ADB").disabled
                                        ? "inf0-icon-disable"
                                        : ""
                                        }`}
                                    src={InfoArrowBlackImg}
                                    alt="info"
                                    onClick={()=>showDescriptionModal(form.$("ADB").label,form.$("ADB").extra.description)}
                                />
                            </div>
                            {form.$("ADB").value ?
                                <div>
                                    <span className="font-size-12 font-weight-400 description-text-color text-field">Sum Assured</span>
                                    <Form.Control
                                        required
                                        placeholder='Sum Assured'
                                        value={form.$("ADB-dropdown").value}
                                        onChange={form.$("ADB-dropdown").onChange}
                                        onBlur={() => form.$("ADB-dropdown").extra.onBlur(form, data)}
                                        className='monthly-income-field font-size-12 font-weight-light text-field'
                                    />
                                </div>
                                : null
                            }
                        </Col>
                        <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                            {form.$("ADB").value ? (
                                <React.Fragment>
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="mb-0 font-weight-300 font-size-20 rider-amount">
                                            {global.toINR(fetchRiderPremiumValue(data, form.$("ADB").extra.rider_name))}
                                        </p>
                                    </div>
                                    <p className="mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color"></p>
                                </React.Fragment>
                            ) : (
                                <p className="mb-0 font-weight-300 font-size-14 text-center description-text-color">
                                    Not <br /> Selected
                                </p>
                            )}
                        </Col>
                    </Row>}

                    {/* ATPD Rider */}
                    {showATPD && <Row
                        className={`mt-3 ${form.$("ATPDRider").disabled ? "disable-card" : ""
                            }`}
                    >
                        <Col className="col-9">
                            <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                                <CustomCheckbox
                                    shape="rounded"
                                    title={form.$("ATPDRider").label}
                                    titleStyle=' font-weight-medium font-size-14 pl-2 text-primary'
                                    isChecked={form.$("ATPDRider").value}
                                    onChange={() =>
                                        form.$("ATPDRider").extra.changeRiderStatus(form, data)
                                    }
                                    mark="checkmark"
                                />
                                <img
                                    className={`pl-2 ${form.$("ATPDRider").disabled
                                        ? "inf0-icon-disable"
                                        : ""
                                        }`}
                                    src={InfoArrowBlackImg}
                                    alt="info"
                                    onClick={()=>showDescriptionModal(form.$("ATPDRider").label,form.$("ATPDRider").extra.description)}
                                />
                            </div>
                            {form.$("ATPDRider").value ?
                                <div>
                                    <span className="font-size-12 font-weight-400 description-text-color text-field">Sum Assured</span>
                                    <Form.Control
                                        required
                                        placeholder='Sum Assured'
                                        value={form.$("ATPDRider-dropdown").value}
                                        onChange={form.$("ATPDRider-dropdown").onChange}
                                        onBlur={() => form.$("ATPDRider-dropdown").extra.onBlur(form, data)}
                                        className='monthly-income-field font-size-12 font-weight-light text-field'
                                    />
                                </div>
                                : null
                            }
                        </Col>
                        <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                            {form.$("ATPDRider").value ? (
                                <React.Fragment>
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="mb-0 font-weight-300 font-size-20 rider-amount">
                                            {global.toINR(fetchRiderPremiumValue(data, form.$("ATPDRider").extra.rider_name))}
                                        </p>
                                    </div>
                                    <p className="mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color"></p>
                                </React.Fragment>
                            ) : (
                                <p className="mb-0 font-weight-300 font-size-14 text-center description-text-color">
                                    Not <br /> Selected
                                </p>
                            )}
                        </Col>
                    </Row>}

                    {/* CriticalIllness Rider */}
                    {showCriticalIllness && <Row
                        className={`mt-3 ${form.$("CriticalIllnessRider").disabled ? "disable-card" : ""
                            }`}
                    >
                        <Col className="col-9">
                            <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                                <CustomCheckbox
                                    shape="rounded"
                                    title={form.$("CriticalIllnessRider").label}
                                    titleStyle=' font-weight-medium font-size-14 pl-2 text-primary'
                                    isChecked={form.$("CriticalIllnessRider").value}
                                    onChange={() =>
                                        form
                                            .$("CriticalIllnessRider")
                                            .extra.changeRiderStatus(form, data)
                                    }
                                    mark="checkmark"
                                />
                                <img
                                    className={`pl-2 ${form.$("CriticalIllnessRider").disabled
                                        ? "inf0-icon-disable"
                                        : ""
                                        }`}
                                    src={InfoArrowBlackImg}
                                    alt="info"
                                    onClick={()=>showDescriptionModal(form.$("CriticalIllnessRider").label,form.$("CriticalIllnessRider").extra.description)}
                                />
                            </div>
                            {form.$("CriticalIllnessRider").value ?
                                <div>
                                    <span className="font-size-12 font-weight-400 description-text-color text-field">Sum Assured</span>
                                    <Form.Control
                                        required
                                        placeholder='Sum Assured'
                                        value={form.$("CriticalIllnessRider-dropdown").value}
                                        onChange={form.$("CriticalIllnessRider-dropdown").onChange}
                                        onBlur={() => form.$("CriticalIllnessRider-dropdown").extra.onBlur(form, data)}
                                        className='monthly-income-field font-size-12 font-weight-light text-field'
                                    />
                                </div>
                                : null
                            }
                        </Col>
                        <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                            {form.$("CriticalIllnessRider").value ? (
                                <React.Fragment>
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="mb-0 font-weight-300 font-size-20 rider-amount">
                                            {global.toINR(fetchRiderPremiumValue(data, form.$("CriticalIllnessRider").extra.rider_name))}
                                        </p>
                                    </div>
                                    <p className="mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color"></p>
                                </React.Fragment>
                            ) : (
                                <p className="mb-0 font-weight-300 font-size-14 text-center description-text-color">
                                    Not <br /> Selected
                                </p>
                            )}
                        </Col>
                    </Row>}

                    {/* IncomeBenefit Rider */}
                    {showIncomeBenefit && <Row
                        className={`mt-3 ${form.$("IncomeBenefitRider").disabled ? "disable-card" : ""
                            }`}
                    >
                        <Col className="col-9">
                            <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                                <CustomCheckbox
                                    shape="rounded"
                                    title={form.$("IncomeBenefitRider").label}
                                    titleStyle=' font-weight-medium font-size-14 pl-2 text-primary'
                                    isChecked={form.$("IncomeBenefitRider").value}
                                    onChange={() =>
                                        form
                                            .$("IncomeBenefitRider")
                                            .extra.changeRiderStatus(form, data)
                                    }
                                    mark="checkmark"
                                />
                                <img
                                    className={`pl-2 ${form.$("IncomeBenefitRider").disabled
                                        ? "inf0-icon-disable"
                                        : ""
                                        }`}
                                    src={InfoArrowBlackImg}
                                    alt="info"
                                    onClick={()=>showDescriptionModal(form.$("IncomeBenefitRider").label,form.$("IncomeBenefitRider").extra.description)}
                                />
                            </div>
                            {form.$("IncomeBenefitRider").value ?
                                <div>
                                    <span className="font-size-12 font-weight-400 description-text-color text-field">Sum Assured</span>
                                    <Form.Control
                                        required
                                        placeholder='Sum Assured'
                                        value={form.$("IncomeBenefitRider-dropdown").value}
                                        onChange={form.$("IncomeBenefitRider-dropdown").onChange}
                                        onBlur={() => form.$("IncomeBenefitRider-dropdown").extra.onBlur(form, data)}
                                        className='monthly-income-field font-size-12 font-weight-light text-field'
                                    />
                                </div>
                                : null
                            }
                        </Col>
                        <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                            {form.$("IncomeBenefitRider").value ? (
                                <React.Fragment>
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="mb-0 font-weight-300 font-size-20 rider-amount">
                                            {global.toINR(fetchRiderPremiumValue(data, form.$("IncomeBenefitRider").extra.rider_name))}
                                        </p>
                                    </div>
                                    <p className="mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color"></p>
                                </React.Fragment>
                            ) : (
                                <p className="mb-0 font-weight-300 font-size-14 text-center description-text-color">
                                    Not <br /> Selected
                                </p>
                            )}
                        </Col>
                    </Row>}

                    {/*  Hospitality CashBenefit Rider */}
                    {showHospitalityCashBenefit && <Row
                        className={`mt-3 ${form.$("HospitalityCashBenefitRider").disabled ? "disable-card" : ""
                            }`}
                    >
                        <Col className="col-9">
                            <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                                <CustomCheckbox
                                    shape="rounded"
                                    title={form.$("HospitalityCashBenefitRider").label}
                                    titleStyle=' font-weight-medium font-size-14 pl-2 text-primary'
                                    isChecked={form.$("HospitalityCashBenefitRider").value}
                                    onChange={() =>
                                        form
                                            .$("HospitalityCashBenefitRider")
                                            .extra.changeRiderStatus(form, data)
                                    }
                                    mark="checkmark"
                                />
                                <img
                                    className={`pl-2 ${form.$("HospitalityCashBenefitRider").disabled
                                        ? "inf0-icon-disable"
                                        : ""
                                        }`}
                                    src={InfoArrowBlackImg}
                                    alt="info"
                                    onClick={()=>showDescriptionModal(form.$("HospitalityCashBenefitRider").label,form.$("HospitalityCashBenefitRider").label,form.$("HospitalityCashBenefitRider").extra.description)}
                                />
                            </div>
                            {form.$("HospitalityCashBenefitRider").value ?
                                <div>
                                    <span className="font-size-12 font-weight-400 description-text-color text-field">Sum Assured</span>
                                    <Form.Control
                                        required
                                        placeholder='Sum Assured'
                                        value={form.$("HospitalityCashBenefitRider-dropdown").value}
                                        onChange={form.$("HospitalityCashBenefitRider-dropdown").onChange}
                                        onBlur={() => form.$("HospitalityCashBenefitRider-dropdown").extra.onBlur(form, data)}
                                        className='monthly-income-field font-size-12 font-weight-light text-field'
                                    />
                                </div>
                                : null
                            }
                        </Col>
                        <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                            {form.$("HospitalityCashBenefitRider").value ? (
                                <React.Fragment>
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="mb-0 font-weight-300 font-size-20 rider-amount">
                                            {global.toINR(fetchRiderPremiumValue(data, form.$("HospitalityCashBenefitRider").extra.rider_name))}
                                        </p>
                                    </div>
                                    <p className="mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color"></p>
                                </React.Fragment>
                            ) : (
                                <p className="mb-0 font-weight-300 font-size-14 text-center description-text-color">
                                    Not <br /> Selected
                                </p>
                            )}
                        </Col>
                    </Row>}

                    {/* PayorWaiverBenefit Rider */}
                    {showPWB && <Row
                        className={`mt-3 ${form.$("PayorWaiverBenefitRider").disabled
                            ? "disable-card"
                            : ""
                            }`}
                    >
                        <Col className="col-9">
                            <div className="d-flex align-items-center justify-content-between justify-content-md-start">
                                <CustomCheckbox
                                    shape="rounded"
                                    title={form.$("PayorWaiverBenefitRider").label}
                                    titleStyle=' font-weight-medium font-size-14 pl-2 text-primary'
                                    isChecked={form.$("PayorWaiverBenefitRider").value}
                                    onChange={() =>
                                        form
                                            .$("PayorWaiverBenefitRider")
                                            .extra.changeRiderStatus(form, data)
                                    }
                                    mark="checkmark"
                                    disabled={form.$("PayorWaiverBenefitRider")?.extra?.disabled || false}      
                                />
                                <img
                                    className={`pl-2 ${form.$("PayorWaiverBenefitRider").disabled
                                        ? "inf0-icon-disable"
                                        : ""
                                        }`}
                                    src={InfoArrowBlackImg}
                                    alt="info"
                                    onClick={()=>showDescriptionModal(form.$("PayorWaiverBenefitRider").label,form.$("PayorWaiverBenefitRider").extra.description)}
                                />
                            </div>
                            {form.$("PayorWaiverBenefitRider").value ?
                                <BottomsheetDropdown
                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                    selectedLabelKey="label"
                                    bottomsheetTitle=""
                                    menuList={
                                        form.$("PayorWaiverBenefitRider-dropdown").extra
                                            .optionList
                                    }
                                    value={form.$("PayorWaiverBenefitRider-dropdown").value}
                                    onSelect={
                                        form.$("PayorWaiverBenefitRider-dropdown").onChange
                                    }
                                />
                                : null
                            }
                        </Col>
                        <Col className="align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height">
                            {form.$("PayorWaiverBenefitRider").value ? (
                                <React.Fragment>
                                    <div className="d-flex flex-column align-items-center">
                                        <p className="mb-0 font-weight-300 font-size-20 rider-amount">
                                            {global.toINR(fetchRiderPremiumValue(data, form.$("PayorWaiverBenefitRider").extra.rider_name))}
                                        </p>
                                    </div>
                                    <p className="mb-0 font-weight-normal font-size-14 font-size-md-16 text-description-text-color"></p>
                                </React.Fragment>
                            ) : (
                                <p className="mb-0 font-weight-300 font-size-14 text-center description-text-color">
                                    Not <br /> Selected
                                </p>
                            )}
                        </Col>
                    </Row>}

                </Card.Body>
            </Card>
        </div >
        
        <Modal
            size='md'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            backdrop='static'
            className='rider-info-modal'
            show={isDescriptionModalOpen}
            keyboard={false}
          >
            <Modal.Body className='text-center text-primary-title-label-color px-4 pt-4 pb-0'>
              
                <React.Fragment>
                  <h3 className='font-weight-medium font-size-22 font-size-md-32 mb-3'>{modalTitle}</h3>
                  <p className='font-weight-normal font-size-20 font-size-md-20 mb-0'>{modalDescription}</p>
                </React.Fragment>
              
            </Modal.Body>
            <Modal.Footer className='d-block p-4 border-0'>
              <Row className='modal-footer-row'>
                
                  <React.Fragment>
                    <Col xs='12' >
                      <UiButton onClick={hideDescriptionModal} className='font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0' variant='secondary' buttonText='OKAY' />
                    </Col>
                  </React.Fragment>

              </Row>
            </Modal.Footer>
          </Modal>
        </>
        

    );
});

AdditionalRiderBenifitsCard.defaultProps = {
    showCriticalIllness: false,
    showIncomeBenefit: false,
    showHospitalityCashBenefit: false,
    showWOP: false,
    showPWB: false,
    showATPD: true,
    showADB: true
}

AdditionalRiderBenifitsCard.propTypes = {
    showCriticalIllness: PropTypes.bool,
    showIncomeBenefit: PropTypes.bool,
    showHospitalityCashBenefit: PropTypes.bool,
    showWOP: PropTypes.bool,
    showPWB: PropTypes.bool,
    showATPD: PropTypes.bool,
    showADB: PropTypes.bool
}


export default AdditionalRiderBenifitsCard
