// Package Imports
import { toJS } from "mobx";

// Project Imports
import axios from "utils/axios";
import * as APITypes from "constants/API";
import isObjectEmpty from "utils/isObjectEmpty";
import fetchNeedMasterData from 'utils/fetchNeedMasterData'
import NeedTypeEnum from "constants/NeedTypeEnum";
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import fetchNeedMaster from "utils/fetchNeedMasterData";
import AuthStore from "modules/login/store/AuthStore";



const initialDefaults = async (formController, journeyData, journeyId) => {

if (journeyId !== undefined) {
formController.$("journey_id").value = journeyId.toString()
}

 let NeedMasterData = await fetchNeedMasterData();

 let saveForRetirement = NeedMasterData.find((item) => item.unique_name === NeedTypeEnum.SAVE_FOR_RETIREMENT)
  let selectedFamilyMember = journeyData.family_details_list.filter((item) => item.id === OptimizationStore.selectedFamilyMemberId)[0]
  let selfData = journeyData.family_details_list[0]

  let targetAmountOfSelectedFamilyMember = selectedFamilyMember?.family_product_details_list.find((item) => item.need_unique_name === NeedTypeEnum.SAVE_FOR_RETIREMENT)
  let targetAmountOfSelf = selfData.family_product_details_list.find((item) => item.need_unique_name === NeedTypeEnum.SAVE_FOR_RETIREMENT)

  let selectedTargetAmount = targetAmountOfSelectedFamilyMember?.target_amount || targetAmountOfSelf.target_amount

 formController.$("target_amount_dropdown").set("extra", {
    ...formController.$("target_amount_dropdown").extra,
    optionList: saveForRetirement.target_amount
})

  if (selectedTargetAmount?.target_amount) {
    let targetAmount = saveForRetirement.target_amount.filter((item) => item.id === selectedTargetAmount.target_amount)[0]
    formController.$("target_amount_dropdown").set("value", targetAmount)
  } else {
    formController.$("target_amount_dropdown").value = saveForRetirement.target_amount[0]
  }

 if (isObjectEmpty(journeyData)) {
     return
 }

}

export default initialDefaults