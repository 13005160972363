import React from 'react'
import './custom-checkbox.css'

import PropTypes from 'prop-types'

function CustomCheckbox(props) {
  const { id, isChecked, onChange, shape, mark, title, titleStyle, className, containerStyle, checkmarkStyle, disabled,customTitle } = props;
  return (
    <label className={`${className} ${containerStyle} d-inline-flex position-relative cursor-pointer custom-checkbox m-0 align-items-center`}>
      <input disabled={disabled} id={id} type='checkbox' className='hidden-checkbox' checked={isChecked} onChange={(event) => onChange(event.target.checked)} />
      <span className={`${shape == 'rounded' ? 'rounded' : 'square'} ${checkmarkStyle ? checkmarkStyle : ''} ${mark == 'checkmark' ? 'mark-icon' : ''} checkmark`}></span>
      {customTitle?customTitle:
      <div className={`mb-0 title ${titleStyle}`}>{title}</div>
}
    </label>
  )
}
CustomCheckbox.defaultProps = {
  className: '',
  containerStyle: 'align-items-center',
  disabled: false
};

CustomCheckbox.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  titleStyle: PropTypes.string,
  shape: PropTypes.string,
  mark: PropTypes.string,
  className: PropTypes.string
}

export default CustomCheckbox