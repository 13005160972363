// Package Imports
import axios from "utils/axios"
import * as APITypes from "constants/API"


const fetchNeedMaster = async () => {
  const needResponse = await axios.get(APITypes.NEED_MASTER)

  if (needResponse.data && needResponse.status == "success") {
    return needResponse.data
  }
  return []
}

const preFillFormField = (formController, need_name, dataObj) => {

  if (dataObj === undefined) return

  let disabled = !dataObj.is_selected

  formController.$(need_name).set("disabled", disabled)
}

const preFillFormData = (formController, journeyData) => {
  let needDetails = journeyData.journey_need

  if (needDetails === undefined || needDetails.length <= 0) {
    return
  }

  let familyProtectionId = formController.$("protection_for_my_family.id").value
  let healthProtectionId = formController.$("protection_for_my_health.id").value
  let moneyGrowId = formController.$("grow_my_money.id").value
  let saveChildrenId = formController.$("save_for_children.id").value
  let saveRetirementId = formController.$("save_for_retirement.id").value

  let familyProtectionObj = needDetails.find(
    (item) => item.need == familyProtectionId && item.is_selected === true && !item.is_removed
  )
  let healthProtectionObj = needDetails.find(
    (item) => item.need == healthProtectionId && item.is_selected === true && !item.is_removed
  )
  let moneyGrowObj = needDetails.find((item) => item.need == moneyGrowId && item.is_selected === true && !item.is_removed)
  let saveChildredObj = needDetails.find((item) => item.need == saveChildrenId && item.is_selected === true && !item.is_removed)
  let saveRetirementObj = needDetails.find(
    (item) => item.need == saveRetirementId && item.is_selected === true && !item.is_removed
  )

  preFillFormField(
    formController,
    "protection_for_my_family",
    familyProtectionObj
  )
  preFillFormField(
    formController,
    "protection_for_my_health",
    healthProtectionObj
  )
  preFillFormField(formController, "grow_my_money", moneyGrowObj)
  preFillFormField(formController, "save_for_children", saveChildredObj)
  preFillFormField(formController, "save_for_retirement", saveRetirementObj)

  //   initial values from the api for the addNeedCard

  const initialSelectedObject = needDetails.filter((item) => {
    if (item.is_selected === true && !item.is_removed) {
      return { need_unique_name: item.need_unique_name, is_selected: item.is_selected }
    }
  })

  formController.$("initial_selected").value = initialSelectedObject
}

const initialDefaults = async (formController, journeyId, journeyData) => {
  let masterData = await fetchNeedMaster()

  if (masterData.length <= 0) return

  let growMyMoney = masterData.find(
    (item) => item.unique_name === "grow_my_money"
  )

  let saveForChildren = masterData.find(
    (item) => item.unique_name === "save_for_children"
  )

  let saveForRetirement = masterData.find(
    (item) => item.unique_name === "save_for_retirement"
  )

  let protectionForMyFamily = masterData.find(
    (item) => item.unique_name === "protection_for_my_family"
  )

  let protectionForMyHealth = masterData.find(
    (item) => item.unique_name === "protection_for_my_health"
  )

  formController.$("journey_id").value = journeyId

  formController.$("protection_for_my_family.id").value =
    protectionForMyFamily.id

  formController.$("protection_for_my_health.id").value =
    protectionForMyHealth.id

  formController.$("grow_my_money.id").value = growMyMoney.id

  formController.$("save_for_children.id").value = saveForChildren.id

  formController.$("save_for_retirement.id").value = saveForRetirement.id

  preFillFormData(formController, journeyData)
  
  formController.$("enable_proceed").set("value",false)
}

export default initialDefaults
