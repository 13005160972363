import moment from "moment";
import Utils from "./utils";
import * as keys from "./keys";
import ProductConstants from "constants/ProductConstants";

const initialDefaults = (formRef, data) => {
  let productName = data?.data?.product_name;
  let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let biResponse = productData.parsedResponse;
  let firstYearResponse = productData.firstYearResponse;
  let biRequest = productData.bi_request;
  let LumpsumBenefit = biRequest.LumpsumBenefit;
  let frequency = biRequest.INPUT_MODE;
  let pt = biRequest.PR_PT;
  let ppt = biRequest.PR_PPT;
  let familyIncomeBenefitOption = biRequest.FamilyIncomeBenefitOption;
  let incomeBenefitPayOutFrequency = biRequest.IncomeBenefitPayoutFrequency;
  let incomeBenefitPayOutType = biRequest.IncomeBenefitPayoutType;
  let incomeBenefitPayoutDuration = biRequest.IncomeBenefitPayoutDuration;
  let annualisedPremium = biRequest.PR_ANNPREM ? firstYearResponse?.MODAL_PREM: biRequest.PR_ANNPREM;

  // let wopRider = String(biRequest.WaiverOfPremiumRider)
  // let adbRider = String(biRequest[keys.ADB_RIDER])
  // let pptList = Utils.getPremiumTermList(biRequest.PR_PT, biRequest.LI_ENTRY_AGE)

  let planOption = biRequest.PlanOption;
  let familyIncomeBenefit = biRequest.FamilyIncomeBenefitOption;
  let maturityBenefit = biRequest.MaturityBenefitOption;
  let equalAnunualInstallments = biRequest.EQUAL_ANNUAL_INST;
  let lumpsumAmount = biRequest.LUMPSUM_AMOUNT;
  let policyNo = biRequest.policyNo;

  formRef.$("is-existing-Customer").set("value", { label: "No", id: "2" });


  let isExistingCustomerValue = formRef.$("is-existing-Customer").value.id

  if(isExistingCustomerValue === '2'){
    formRef.$('policy-no').set('extra', {
      ...formRef.$('policy-no').extra,
      isVisible:false
     })
  }
  
  formRef.$("policy-no").set("disabled", true);

  formRef.$("PPT").value = { id: ppt, label: ppt };

  formRef.$("PT").value = { id: pt, label: pt };

  Utils.pptOptionListFunction(formRef,productName,planOption)

  let ptList = Utils.ptLogicBasedOnppt(data.data.product_name, formRef, ppt.toString())

  switch (planOption) {
    case "1":
      formRef.$("plan-option").set("value", { label: "Base", id: "1" });
      break;

    case "2":
      formRef.$("plan-option").set("value", { label: "Enhanced Cover", id: "2" });
      break;

    default:
      break;
  }

  switch (familyIncomeBenefit) {
    case "3":
      formRef.$("family-income-benefit").set("value", { label: "No", id: "3" });
      break;
    case "4":
      formRef.$("family-income-benefit").set("value", { label: "Family Income Benefit on Critical Illness (CI) and Death", id: "4" });
      break;
    case "6":
      formRef.$("family-income-benefit").set("value", { label: "Family Income Benefit on Death", id: "6" });
      break;

    default:
      // formRef.$('family-income-benefit').set('value', {label:'No',id: '3'})
      break;
  }

  switch (frequency) {
    case "1":
      formRef.$("payment-frequency").set("value", {
        id: "1",
        label: "Annual",
      });
      break;
    case "2":
      formRef.$("payment-frequency").set("value", {
        id: "2",
        label: "Semi-Annual",
      });
      break;
    case "3":
      formRef.$("payment-frequency").set("value", {
        id: "3",
        label: "Quarterly",
      });
      break;
    case "4":
      formRef.$("payment-frequency").set("value", {
        id: "4",
        label: "Monthly",
      });
      break;

    default:
      break;
  }

  switch (maturityBenefit) {
    case "1":
      formRef.$("maturity-benefit").set("value", { label: "Lumpsum", id: "1" });
      break;

    case "2":
      formRef.$("maturity-benefit").set("value", { label: "Equal Annual Instalments", id: "2" });
      break;

    default:
      break;
  }

  // if(equalAnunualInstallments){
  //  formRef.$('equal-annual-instalments').value = equalAnunualInstallments
  // }

  // if(lumpsumAmount){
  //  formRef.$('lumpsum').value = lumpsumAmount
  // }

  //  switch (familyIncomeBenefitOption) {

  //   case '3':
  //    formRef.$('family-income-benefit-option').set('value', {label: 'No', id: '3'})
  //       break;
  //   case '4':
  //    formRef.$('family-income-benefit-option').set('value', {label: 'Family Income Benefit on Critical Illness (CI) and Death', id: 4})
  //       break;
  //   case '5':
  //    formRef.$('family-income-benefit-option').set('value', {label: 'Family Income Benefit on Critical Illness (CI)', id: 5})
  //       break;

  //   default:
  //       break;

  // }

  // switch (incomeBenefitPayOutFrequency) {

  //  case '1':
  //   formRef.$('income-benefit-pay-out-frequency').set('value', {label: 'Annual', id: '1'})
  //      break;
  //  case '2':
  //   formRef.$('income-benefit-pay-out-frequency').set('value',{label: 'Semi-Annual', id: '2'})
  //      break;
  //  case '3':
  //   formRef.$('income-benefit-pay-out-frequency').set('value', {label: 'Quarterly', id: '3'})
  //      break;
  //  case '4':
  // formRef.$('income-benefit-pay-out-frequency').set('value', {label: 'Monthly', id: '4'})
  //  break;

  //  default:
  //      break;

  // }

  // switch (incomeBenefitPayOutType) {

  //  case '5':
  //   formRef.$('income-benefit-pay-out-type').set('value', {label: 'Level', id: '5'})
  //      break;
  //  case '6':
  //   formRef.$('income-benefit-pay-out-type').set('value',{label: 'Increasing', id: '6'})
  //      break;

  //  default:
  //      break;

  // }

  // let premiumAmount = ''
  // switch (frequency) {
  //     case '1':
  //         premiumAmount = annualisedPremium;
  //         break;
  //     case '2':
  //         premiumAmount = Number(annualisedPremium) / 2;
  //         break;
  //     case '3':
  //         premiumAmount = Number(annualisedPremium) / 4;
  //         break;
  //     case '4':
  //         premiumAmount = Number(annualisedPremium) / 12;
  //         break;

  //     default:
  //         break;
  // }

  // formRef.$('annualised-premium').set('value', premiumAmount)







  
  
  if(productName === ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM){
  
    let LaDob = data.data.dob 
    let laAge = moment().diff(moment(LaDob), "years")
    
    if(laAge > 50){
      formRef.$("plan-option").set("value", { label: "Base", id: "1" })
      formRef.$("plan-option").set("disabled",true)
    }
}


  
  if(productName.includes('FIB')){
    formRef.$("plan-option").set("disabled",true)
  }

  formRef.$("plan-option").set("extra", {
    ...formRef.$("plan-option").extra,
    optionList: [
      { label: "Base", id: "1" },
      { label: "Enhanced Cover", id: "2" },
    ],
  });

  formRef.$("PPT").set("extra", {
    ...formRef.$("PPT").extra,
    optionList: [
      { label: "5", id: "5" },
      { label: "8", id: "8" },
      { label: "10", id: "10" },
      { label: "12", id: "12" },
    ],
  });

  formRef.$("payment-frequency").set("extra", {
    ...formRef.$("payment-frequency").extra,
    optionList: [
      { label: "Annual", id: "1" },
      { label: "Semi-Annual", id: "2" },
      { label: "Quarterly", id: "3" },
      { label: "Monthly", id: "4" },
    ],
  });

  Utils.familyIncomeBenefitLogicBasedOnProductName(data.productName, formRef);
  Utils.ptLogicBasedOnppt(data.productName, formRef, ppt.id);
  Utils.maturityBenefitLogicBasedOnProductName(data.productName, formRef);

  // switch (data.productName) {

  //    case 'Guaranteed Savings STAR (Lumpsum)':
  //     case 'Guaranteed Savings STAR (Income)':

  //    Utils.ptLogicBasedOnppt(data.productName,formRef, ppt.id)

  //    break;

  //     case 'POS Guaranteed Savings Star (GSS)':

  //      Utils.ptLogicBasedOnppt(data.productName,formRef, ppt.id)

  //     break;

  //    default:

  //      break;

  // }

  // formRef.$('ADB').set('value', biRequest.ADBRider !== '')
  // if (biRequest.ADBRider) {
  //     formRef.$('ADB-dropdown').set('value', biRequest.ADBRider)
  // }

  // formRef.$('ATPDRider').set('value', biRequest.ATPDRider !== '')
  // if (biRequest.ATPDRider) {
  //     formRef.$('ATPDRider-dropdown').set('value', biRequest.ATPDRider)
  // }

  // formRef.$('WaiverOfPremiumRider').set('value', biRequest.WaiverOfPremiumRider !== '')

  // formRef.$('HospitalityCashBenefitRider').set('value', biRequest.HospitalityCashBenefitRider !== '')
  // if (biRequest.HospitalityCashBenefitRider) {
  //     formRef.$('HospitalityCashBenefitRider-dropdown').set('value', biRequest.HospitalityCashBenefitRider)
  // }

  // formRef.$('CriticalIllnessRider').set('value', biRequest.CriticalIllnessRider !== '')
  // if (formRef.$('CriticalIllnessRider').value) {
  //     formRef.$('CriticalIllnessRider-dropdown').set('value', biRequest.CriticalIllnessRider)
  // }

  // formRef.$('PayorWaiverBenefitRider').set('value', biRequest.PayorWaiverBenefitRider !== '')
  // if (formRef.$('PayorWaiverBenefitRider').value) {
  //     switch (biRequest.PWB) {
  //         case '1':
  //             formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
  //                 id: '1',
  //                 label: 'Option 1 (On Death)'
  //             })
  //             break;
  //         case '2':
  //             formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
  //                 id: '2',
  //                 label: 'Option 2 (CI or ATPD)'
  //             })
  //             break;
  //         case '3':
  //             formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
  //                 id: '3',
  //                 label: 'Option 3 (CI or ATPD or Death)'
  //             })
  //             break;
  //         default:
  //             break;
  //     }
  // }

  // Rider
  if (biRequest.ADBRider) {
		formRef.$('ADB').set('value', biRequest.ADBRider !== '')
		if (biRequest.ADBRider) {
			formRef.$('ADB-dropdown').set('value', biRequest.ADBRider)
		}
	}
	if (biRequest.ATPDRider) {
		formRef.$('ATPDRider').set('value', biRequest.ATPDRider !== '')
		if (biRequest.ATPDRider) {
			formRef.$('ATPDRider-dropdown').set('value', biRequest.ATPDRider)
		}
	}

	if (biRequest.WaiverOfPremiumRider) {
		formRef.$('WaiverOfPremiumRider').set('value', biRequest.WaiverOfPremiumRider !== '')
	}

	if (biRequest.HospitalityCashBenefitRider) {
		formRef.$('HospitalityCashBenefitRider').set('value', biRequest.HospitalityCashBenefitRider !== '')
		if (biRequest.HospitalityCashBenefitRider) {
			formRef.$('HospitalityCashBenefitRider-dropdown').set('value', biRequest.HospitalityCashBenefitRider)
		}
	}

	if (biRequest.CriticalIllnessRider) {
		formRef.$('CriticalIllnessRider').set('value', biRequest.CriticalIllnessRider !== '')
		if (formRef.$('CriticalIllnessRider').value) {
			formRef.$('CriticalIllnessRider-dropdown').set('value', biRequest.CriticalIllnessRider)
		}
	}

	if (biRequest.PayorWaiverBenefitRider) {
		formRef.$('PayorWaiverBenefitRider').set('value', biRequest.PayorWaiverBenefitRider !== '')
		if (formRef.$('PayorWaiverBenefitRider').value) {
			switch (biRequest.PWB) {
				case '1':
					formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
						id: '1',
						label: 'Option 1 (On Death)'
					})
					break;
				case '2':
					formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
						id: '2',
						label: 'Option 2 (CI or ATPD)'
					})
					break;
				case '3':
					formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
						id: '3',
						label: 'Option 3 (CI or ATPD or Death)'
					})
					break;
				default:
					break;
			}
		}
	}
};

export default initialDefaults;
