import ProductNames from "constants/ProductNames";
import Utils from "modules/optimization/components/products/wealth-ultima/utils";

const checkProductWoringBasedOnPremiumAmount = function (bi_request_data, amount) {
    switch (bi_request_data["PR_ID"]) {
        // PPF
        case ProductNames.easyPension:
        case ProductNames.wealthPlus:
        case ProductNames.premierGuranteedIncome:
        case ProductNames.incomeBuilder:
        case ProductNames.guaranteedIncomeStar:
        case ProductNames.GCAP:
        case ProductNames.dhanLabh:
        case ProductNames.smartLifestyle:
        case ProductNames.guaranteedSavingsStar:
        case ProductNames.flexiSavingsPlan:
            bi_request_data["PR_ANNPREM"] = amount.toString();
            break

        case ProductNames.wealthUltima:
            bi_request_data = Utils.calculateDeathBenefitValueAndUpdateBIRequest(bi_request_data, amount, true);
            bi_request_data["PR_ANNPREM"] = amount.toString();
            break

        case ProductNames.posSaralNivesh:
            bi_request_data["ANN_PREM"] = amount.toString();
            break

        case ProductNames.activeIncomePlan:
            bi_request_data["PR_ModalPrem"] = amount.toString();
            break

        case ProductNames.cashFlowProtectionPlus:
            bi_request_data["TargetAmount"] = amount.toString();
            break


        default:
            break;
    }
    return bi_request_data;
};

export default checkProductWoringBasedOnPremiumAmount;
