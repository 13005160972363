export const LI_SMOKE = 'LI_SMOKE';
export const PAYOUT_OPTION = 'DeathBenefitPayoutOption';
export const PAYOUT_PERIOD = 'PayoutPeriodMonths';
export const PAYOUT_PERCENTAGE = 'Lumpsum';
export const PLAN_OPTION = 'PlanOption';
export const PR_PT = 'PR_PT';
export const PR_PPT = 'PR_PPT';
export const PR_SA = 'PR_SA';
export const PREMIUM = 'PR_ANNPREM';
export const FREQUENCY = 'INPUT_MODE';
export const TELEMEDICAL = 'telemedical';

export const BETTER_HALF_BENEFIT = 'BetterHalfBenefit';
export const SPOUSE_TOBACCO_CONSUMER = 'SpouseTobaccoConsumer'
export const ADDTIONAL_BENEFIT = 'AdditionalBenefit';
export const TOP_UP_BENEFIT = 'TopUpBenefit';
export const ADB_RIDER = 'ADBRider';
export const ATPD_RIDER = 'ATPDRider';
export const Critical_ILLNESS_RIDER = 'CriticalIllnessRider';
export const HOSPITALITY_CASH_BENEFIT_RIDER = 'HospitalityCashBenefitRider';
export const ELIGIBILITY = 'eligibilty';
export const MARITAL_STATUS = 'MaritalStatus';
export const SPOUSE_DOB = 'SpouseDOB';
export const SPOUSE_NAME = 'SpouseName';
export const SPOUSE_GENDER = 'SpouseGender';
export const INCOME = 'investment_target';
export const ZP_NATIONALITY = 'ZP_NATIONALITY';
export const ZP_OCCUPATION = 'ZP_OCCUPATION';
export const ZP_EDUCATION = 'ZP_EDUCATION';
export const ZP_ANNINCOME = 'ZP_ANNINCOME';
export const UNDERWRITING = 'Underwriting';
export const OSN_SPOUSE_DATE_OF_BIRTH = 'OSNSpouseDateOfBirth';
export const DISPLAY_SPOUSE_DOB = 'DisplaySpouseDOB';





