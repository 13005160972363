import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

import NeedCard from './components/family-protection-card/NeedCard'
import SaveMyChildrenCard from './components/save-my-children/SaveMyChildrenCard'
import PriceSummary from './components/PriceSummary';
import FamilyProtectionStore from '../family-protection/store/FamilyProtectionStore';

import './family-protection.css'

const FamilyProtection = observer(class FamilyProtection extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    componentDidMount() {
        window.scrollTo(0, 0)
        // const product_list_data = FamilyProtectionStore.getBIResponse(this.props.cardKey)
        FamilyProtectionStore.updateResponseStructure(this.props.cardKey)
    }

    render() {
        const product_list_data = toJS(FamilyProtectionStore.product_list_data);
        // console.log('PRODUCT LIST DATA >> ', product_list_data)

        return (
            <div className='container family-protection mb-4 mt-2'>
                {product_list_data.length == 0 && !global.isLoading()?
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                    : product_list_data.map((product_details, index) => {
                        // console.log('PROTECTION CARD LIST DATA >> ', product_details)
                        let cardKey = product_details.need_unique_name
                        // console.log('Product details is ', product_details)
                        let title = ''
                        switch (cardKey) {
                            case 'protection_for_my_family':
                                title = 'Protection For My Family'
                                break
                            case 'protection_for_my_health':
                                // title = 'Protection For My Health'
                                break
                            case 'grow_my_money':
                                title = 'Grow My Money'
                                break
                            case 'save_for_retirement':
                                title = 'Save for Retirement'
                                break
                            case 'save_for_children':
                                title = 'Save for Children'
                                break
                        }
                        if (title) {
                            return (
                                <NeedCard
                                    title={title}
                                    key={index}
                                    cardKey={cardKey}
                                    data={product_details.data}
                                />
                            )
                        }
                    })
                }
                <PriceSummary />
            </div>
        );
    }
});

export default FamilyProtection;