
// Project Imports
import axios from "utils/axios";
import * as APITypes from "constants/API";

const fetchFamilyMasterData = async () => {
 const occupationRequest = axios.get(APITypes.OCCUPATION_MASTER)
 const educationRequest = axios.get(APITypes.EDUCATION_MASTER)
 
 return axios.all([occupationRequest, educationRequest])
     .then(axios.spread((...responses) => {
         return {
             occupationResponse: responses[0],
             educationResponse: responses[1],
         }
     }))
}

export default fetchFamilyMasterData