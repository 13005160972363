// Package Imports
const { Form } = require("mobx-react-form");
import vjf from "mobx-react-form/lib/validators/VJF";
import validator from "validator";

// Project Imports
import setInitialDefaults from "./initialDefaults";
import formObserver from "./observer";
import { monthlyExpenseValidation,savingsAndInvestmentDetailValidation,monthlyemiValidation } from "./validation";



class FamilyDetailsFormController extends Form {
  plugins() {
    return {
      vjf: vjf(validator),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "journey_id",
          value: "",
        },
        {
          name: "members",
          fields: [],
        },
        {
          name: "monthly_expense",
          label: "Monthly Expense",
          placeholder: "",
          validators: [monthlyExpenseValidation],
          fields: [],
        },
        {
          name: "liabilities",
          label: "Liability Details",
          placeholder: "",
          validators: [monthlyemiValidation],
          fields: [],
        },
        {
          name: "monthly_emi",
          label: "Monthly EMIs, if anys",
          placeholder: "",
          fields: [],
          extra: {
              paidYearsOptionList: [
                  {
                      id: "0",
                      label: "0",
                  },
                  {
                      id: "1",
                      label: "1",
                  },
              ],
              totalYearsOptionList: [
                  {
                      id: "1",
                      label: "1",
                  },
                  {
                      id: "2",
                      label: "2",
                  },
              ],

              paidYears: {},
              totalYears: {},
          }
      },
        {
          name: "savings",
          label: "Savings & Investment, if any",
          placeholder: "",
          validators: [savingsAndInvestmentDetailValidation],
          fields: [],
        },
        {
          name: "show_add_family_member_modal",
          value: false,
        },
        {
          name:'show_emi_fields',
          value:false
        }
      ],
    };
  }

  setDefaults(journeyId, journeyData, expenseList, lifeCoverList,emiValueCallBack) {
    // Set forms initial default value
    setInitialDefaults(this, journeyId, journeyData, expenseList, lifeCoverList,emiValueCallBack);

    // Observer fields
    // formObserver(this)
  }
}

export default FamilyDetailsFormController;
