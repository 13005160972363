import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import Utils from '../../../../../constants/utils';
import OptimizationStore from '../../../store/OptimizationStore';
import fetchRiderValidation from 'utils/fetchRiderValidation'

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // PPT Validations
    validatePPT = (formRef, data) => {
        // let pt = formRef.$('policy-term').value.id;
        // let ppt = formRef.$('premium-term').value.id;
        // if (pt == 6 || pt == 8) {
        //     if (ppt != 1) {
        //         this.errorMessage.push(errorMessageKeys.ppt.for_6_or_8)
        //     }
        // }
        // if (pt == 7) {
        //     if (ppt != 7) {
        //         this.errorMessage.push(errorMessageKeys.ppt.for_7)
        //     }
        // }
        // if (pt == 10) {
        //     if (ppt != 1 && ppt != 10) {
        //         this.errorMessage.push(errorMessageKeys.ppt.for_10)
        //     }
        // }
        // if (pt == 12) {
        //     if (ppt != 1 && ppt != 12) {
        //         this.errorMessage.push(errorMessageKeys.ppt.for_12)
        //     }
        // }
    }

    // Minimum Entry Age Validation
    minimumEntryAge = (formRef, productData) => {
        // let pt = formRef.$('policy-term').value.id;
        // let optionName = formRef.$('option-name').value.id;
        // let age = productData.data.bi_request.LI_ENTRY_AGE

        // if (optionName == 11) {
        //     if (pt == 6 && age < 12) {
        //         this.errorMessage.push(errorMessageKeys.minimum_age.base_option_pt_6)
        //     }

        //     if (pt == 7 && age < 11) {
        //         this.errorMessage.push(errorMessageKeys.minimum_age.base_option_pt_7)
        //     }

        //     if (pt == 8 && age < 10) {
        //         this.errorMessage.push(errorMessageKeys.minimum_age.base_option_pt_8)
        //     }

        //     if (pt == 10 && age < 8) {
        //         this.errorMessage.push(errorMessageKeys.minimum_age.base_option_pt_10)
        //     }

        //     if (pt == 12 && age < 6) {
        //         this.errorMessage.push(errorMessageKeys.minimum_age.base_option_pt_12)
        //     }
        // } else {
        //     if (age < 18) {
        //         this.errorMessage.push(errorMessageKeys.minimum_age.secured_income_minimum_age)
        //     }
        // }
    }

    // Maximum Entry Age Validation
    maximumEntryAge = (formRef, productData) => {
        // let optionName = formRef.$('option-name').value.id;
        // let age = productData.data.bi_request.LI_ENTRY_AGE

        // if (optionName == 11) {
        //     if (age > 55) {
        //         this.errorMessage.push(errorMessageKeys.maximum_age.base_option_max_age)
        //     }
        // } else {
        //     if (age > 50) {
        //         this.errorMessage.push(errorMessageKeys.maximum_age.secured_income_max_age)
        //     }
        // }
    }

    // Minimum Premium Validations

    minimumPremiumValidation = (formRef) => {
        // let premium = formRef.$('premium').value
        // let ppt = formRef.$('premium-term').value.id
        // let frequency = formRef.$('payment-frequency').value.id

        // if (ppt == 1 || frequency == 5) {
        //     // Single Pay
        //     if (premium < 100000) {
        //         this.errorMessage.push(errorMessageKeys.premium.single)
        //     }
        // } else {
        //     // Regular Pay
        //     if (premium < 30000) {
        //         this.errorMessage.push(errorMessageKeys.premium.regular)
        //     }
        // }
    }

    // Payout Period Validation for Single Premium
    payoutPeriodValidationForSinglePremium = (formRef, productData) => {
        // let pt = formRef.$('policy-term').value.id
        // let ppt = formRef.$('premium-term').value.id
        // let payoutPeriod = formRef.$('payout-period').value.label

        // if (ppt != 1) return;
        // if (pt != payoutPeriod) {
        //     this.errorMessage.push(errorMessageKeys.payoutPeriod)
        // }
    }

    // Payout Period Validation for Regular Premium
    payoutPeriodValidationForRegularPremium = (formRef) => {
        // let pt = formRef.$('policy-term').value.id
        // let ppt = formRef.$('premium-term').value.id
        // let payoutPeriod = formRef.$('payout-period').value.label

        // let sevenPtPeriods = [7, 13, 18, 23]
        // let tenPtPeriods = [10, 15, 20]
        // let twelvePtPeriods = [12, 18]
        // let isValidCombination = true;

        // if (ppt == 1) return;
        // if (pt == 7) {
        //     isValidCombination = sevenPtPeriods.some((item) => item == payoutPeriod);
        // }

        // if (pt == 10) {
        //     isValidCombination = tenPtPeriods.some((item) => item == payoutPeriod);
        // }

        // if (pt == 12) {
        //     isValidCombination = twelvePtPeriods.some((item) => item == payoutPeriod);
        // }
        // if (!isValidCombination) {
        //     this.errorMessage.push(errorMessageKeys.payoutPeriod)
        // }
    }

    // Sub product Validations
    subProductValidation = (formRef, productData) => {
        // let productName = productData.data.product_name;
        // let option = formRef.$('option-name').value.id;
        // let isSecuredIncomeOption = option == 12;
        // let ppt = formRef.$('premium-term').value.id

        // if (productName == 'Secured Income Option (Income Builder)' && !isSecuredIncomeOption) {
        //     this.errorMessage.push(errorMessageKeys.securedIncome)
        // }

        // if (ppt == 1 && productName == 'Secured Income Option (Income Builder)') {
        //     this.errorMessage.push(errorMessageKeys.securedIncomeSinglePay)
        // }
    }

    // rider sum assured validation
    riderSAValidation = (formRef, productData) => {
        // let sum_assured = productData.data.firstYearResponse.SA

        // if (formRef.$('ADB').value) {
        //     let adbValue = Number(formRef.$('ADB-dropdown').value)
        //     if (adbValue > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }

        // if (formRef.$('ATPDRider').value) {
        //     let atpdRiderValue = Number(formRef.$('ATPDRider-dropdown').value)
        //     if (atpdRiderValue > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }

        // if (formRef.$('IncomeBenefitRider').value) {
        //     let incomeBenefitRiderValue = Number(formRef.$('IncomeBenefitRider-dropdown').value)
        //     if (incomeBenefitRiderValue > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }
    }

    // Frequency Validation
    frequencyValidation = (formRef) => {
        // let ppt = formRef.$("premium-term").value.id;
        // let frequency = formRef.$("payment-frequency").value.id

        // if (ppt == 1 && frequency != 5) {
        //     this.errorMessage.push(errorMessageKeys.singlePay);
        // }
    }

    ridersAgeValidation = (formRef, productData) => {
        // let age = productData.data.bi_request.LI_ENTRY_AGE
        // let adb = formRef.$('ADB').value
        // let atpd = formRef.$('ATPDRider').value
        // let wop = formRef.$('WaiverOfPremiumRider').value
        // let incomeBenefit = formRef.$('IncomeBenefitRider').value

        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // let isRiderSelected = (wop || adb || atpd || incomeBenefit);

        // if (!isRiderSelected) {
        //     return;
        // }

        // if (isMinimumAge) {
        //     this.errorMessage.push(errorMessageKeys.MINIMUM_RIDER_AGE)
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push(errorMessageKeys.MAXIMUM_RIDER_AGE)
        // }
    }

    pwbRiderAgeValidation = (formRef, productData) => {
        // let age = productData.data.bi_request.PROPOSER_AGE
        // let isSameProposer = productData.data.bi_request.SameProposer == true
        // let PWBRider = formRef.$('PayorWaiverBenefitRider').value

        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // if (!PWBRider) {
        //     return;
        // }

        // if (isSameProposer) {
        //     this.errorMessage.push(errorMessageKeys.PWB_RIDER_VALIDATION)
        // }
        // if (isMinimumAge) {
        //     this.errorMessage.push(errorMessageKeys.MINIMUM_RIDER_AGE)
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push(errorMessageKeys.MAXIMUM_RIDER_AGE)
        // }
    }

    // Riders Validation
    ridersValidation = (formRef, productData) => {
        // let adb = formRef.$('ADB-dropdown').value
        // let atpd = formRef.$('ATPDRider-dropdown').value
        // let income = formRef.$('IncomeBenefitRider-dropdown').value

        // let adbValidation = fetchRiderValidation(adb, formRef.$('ADB-dropdown').label)
        // if (adbValidation) {
        //     this.errorMessage.push(adbValidation)
        // }

        // let atpdValidation = fetchRiderValidation(atpd, formRef.$('ATPDRider-dropdown').label)
        // if (atpdValidation) {
        //     this.errorMessage.push(atpdValidation)
        // }

        // let incomeValidation = fetchRiderValidation(income, formRef.$('IncomeBenefitRider-dropdown').label)
        // if (incomeValidation) {
        //     this.errorMessage.push(incomeValidation)
        // }
    }
}

export default new Validator();