import setFundAllocation from "utils/setFundAllocation";

const initialDefaults = (formRef, data) => {
    let productData = data.data;
    let biRequest = productData.bi_request;

    let frequency = biRequest.INPUT_MODE
    let annualPremium = biRequest.PR_ANNPREM
    let policyOption = biRequest.LifeCoverOption
    let SecondLifeAssuredName = biRequest.SecondLifeAssuredName
    let SecondLifeAssuredGender = biRequest.SecondLifeAssuredGender
    let fundList = formRef.$("fund-strategy").extra.fundList;
    let updatedFundList = [];

    formRef.$('second-life-assured-name').value = SecondLifeAssuredName;
    switch (SecondLifeAssuredGender) {
        case '4':
            formRef.$('second-life-assured-gender').set('value', {
                id: 'M',
                label: 'Male'
            })
            break;
        case '5':
            formRef.$('second-life-assured-gender').set('value', {
                id: 'F',
                label: 'Female'
            })
            break;


        default:
            break;
    }

    formRef.$('premium').value = annualPremium;
    formRef.$('second-life-assured-dob').value = biRequest.OSNSpouseDateOfBirth;

    formRef.$('policy-term').value = {
        id: biRequest.PR_PT,
        label: biRequest.PR_PT
    }

    formRef.$('premium-term').value = {
        id: biRequest.PR_PPT,
        label: biRequest.PR_PPT
    }

    switch (policyOption) {
        case '1':
            formRef.$('life-cover-option').set('value', {
                id: '1',
                label: 'Single Life'
            })
            break;
        case '2':
            formRef.$('life-cover-option').set('value', {
                id: '2',
                label: 'Joint Life'
            })
            break;


        default:
            break;
    }

    formRef.$('life-cover-option').set('extra', {
        ...formRef.$('life-cover-option').extra,
        jointFieldDisable: policyOption == '1'
    })

    switch (frequency) {
        case '5':
            formRef.$('payment-frequency').set('value', {
                id: '5',
                label: 'Single'
            })
            break;

        default:
            break;
    }

    updatedFundList = setFundAllocation(fundList, biRequest);
    formRef.$("fund-strategy").set('extra', {
        ...formRef.$('fund-strategy').extra,
        showFundDistributionModal: false,
        fundList: updatedFundList
    })

}

export default initialDefaults
