const { Form } = require("mobx-react-form");

import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

import setInitialDefaults from "./initialDefault";
import formObserver from "./observers";
import Utils from "./utils";
import * as keys from './keys';
import * as Constants from 'constants/Constants';

class IncomeBuilderFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          disabled: false,
          extra: {
            optionList: Utils.getPolicyTerm(),
          },
        },
        {
          name: "premium-term",
          value: {},
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            optionList: Utils.generatePPT(),
          },
        },
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          disabled: false,
          extra: {
            isVisible: false,
            optionList: Utils.generatePPF(),
          },
        },
        {
          name: "payout-period",
          value: {},
          label: "Payout Period (Years)",
          placeholder: "",
          extra: {
            optionList: Utils.getPayoutPeriodList(),
          },
        },
        {
          name: "option-name",
          value: {},
          label: "Option Name",
          placeholder: "",
          extra: {
            optionList: [
              {
                id: '11',
                label: "Base",
              },
              {
                id: '12',
                label: "Secured Income",
              },
            ],
          },
        },
        {
          name: "maturity-benefit-option",
          value: {},
          label: "Maturity Benefit Option",
          placeholder: "",
          extra: {
            optionList: [
              {
                id: '13',
                label: "Regular Income",
              },
              {
                id: '14',
                label: "Regular Income Plus Lumpsum",
              },
            ],
          },
        },
        {
          name: "payout-mode",
          label: "Payout Mode",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "15",
                label: "Annual",
              },
              {
                id: "17",
                label: "Half-Yearly",
              },
              {
                id: "18",
                label: "Quarterly",
              },
              {
                id: "19",
                label: "Monthly",
              },
            ],
          },
        },
        {
          name: "ADB",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.ADB_RIDER_DESCRIPTION,
            paramName: "ADBRider",
            rider_name: "ADB Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ADB").value;
              form.$("ADB").value = !value;

              if (form.$("ADB").value) {
                form
                  .$("ADB-dropdown")
                  .set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("ADB-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "ADB-dropdown",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountList(),
            onBlur(form, data) {
              let value = form.$("ADB-dropdown").value
              Utils.updateBi(keys.ADB_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "ATPDRider",
          label: "Permanent Disability",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.ATPD_RIDER_DESCRIPTION,
            paramName: "ATPDRider",
            rider_name: "ATPD Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ATPDRider").value;
              form.$("ATPDRider").value = !value;

              if (form.$("ATPDRider").value) {
                form
                  .$("ATPDRider-dropdown")
                  .set(
                    "value",
                    Constants.RIDERS_DEFAULT_SA
                  );
              } else {
                form.$("ATPDRider-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "IncomeBenefitRider",
          label: "Income Benefit",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.INCOME_BENEFIT_RIDER_DESCRIPTION,
            paramName: "IncomeBenefitRider",
            rider_name: "Income Benefit Rider",
            changeRiderStatus(form, data) {
              let value = form.$("IncomeBenefitRider").value;
              form.$("IncomeBenefitRider").value = !value;

              if (form.$("IncomeBenefitRider").value) {
                form
                  .$("IncomeBenefitRider-dropdown")
                  .set(
                    "value",
                    Constants.RIDERS_DEFAULT_SA
                  );
              } else {
                form.$("IncomeBenefitRider-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "WaiverOfPremiumRider",
          label: "Waiver Of Premium",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.WOP_RIDER_DESCRIPTION,
            paramName: "WaiverOfPremiumRider",
            rider_name: "Waiver of Premium Rider",
            changeRiderStatus(form, data) {
              let value = form.$("WaiverOfPremiumRider").value;
              form.$("WaiverOfPremiumRider").value = !value;
            },
          },
        },
        {
          name: "PayorWaiverBenefitRider",
          label: "Payor Waiver Benefit",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.PWB_RIDER_DESCRIPTION,
            paramName: "PayorWaiverBenefitRider",
            rider_name: "Payor Waiver Benefit Rider",
            changeRiderStatus(form, data) {
              let value = form.$("PayorWaiverBenefitRider").value;
              form.$("PayorWaiverBenefitRider").value = !value;

              if (form.$("PayorWaiverBenefitRider").value) {
                form
                  .$("PayorWaiverBenefitRider-dropdown")
                  .set(
                    "value",
                    form.$("PayorWaiverBenefitRider-dropdown").extra
                      .optionList[0]
                  );
              } else {
                form.$("PayorWaiverBenefitRider-dropdown").set("value", {});
              }
            },
          },
        },
        {
          name: "ATPDRider-dropdown",
          label: "Permanent Disability",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountList(),
            onBlur(form, data) {
              let value = form.$("ATPDRider-dropdown").value
              Utils.updateBi(keys.ATPD_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "IncomeBenefitRider-dropdown",
          label: "Income Benefit Rider",
          placeholder: "",
          value: "",
          extra: {
            optionList: Utils.getCoverAmountListForCLRnHCB(),
            onBlur(form, data) {
              let value = form.$("IncomeBenefitRider-dropdown").value
              Utils.updateBi(keys.INCOME_BENEFIT_RIDER, value, data.data.product_id, form, data)
            },
          },
        },
        {
          name: "PayorWaiverBenefitRider-dropdown",
          label: "Payor Waiver Benefit Rider",
          placeholder: "",
          value: {},
          extra: {
            optionList: [
              {
                id: '1',
                label: "Option 1 (On Death)",
              },
              {
                id: '2',
                label: "Option 2 (CI or ATPD)",
              },
              {
                id: '3',
                label: "Option 3 (CI or ATPD or Death)",
              },
            ],
          },
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremium(form, data) {

              // let premiumAmount = 0;
              // let inputMode = Number(form.$('payment-frequency').value.id)

              // let amount = Number(form.$('premium').value)
              // switch (inputMode) {
              //   case 1:
              //   case 5:
              //     premiumAmount = '' + amount * 1
              //     break;
              //   case 2:
              //     premiumAmount = '' + amount * 2
              //     break;
              //   case 3:
              //     premiumAmount = '' + amount * 4
              //     break;
              //   case 4:
              //     premiumAmount = '' + amount * 12
              //     break;
              // }
              // Utils.updateBi(keys.PREMIUM, premiumAmount, data.data.product_id, form, data)
            },
          },
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data);
  }
}

export default IncomeBuilderFormController;
