const isTotalFundCountValid = (fundsList) => {
    let totalFundCount = 0;

    fundsList.forEach(fund => {
        let parsedValue = parseInt(fund.value);
        if (isNaN(parsedValue)) {
            parsedValue = 0;
        }
        totalFundCount += parsedValue;
    })

    return totalFundCount == 100;

}

export default isTotalFundCountValid