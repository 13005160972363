// react imports
import React, { Component } from "react"

// package imports
import { Col, Container, Row, Image } from "react-bootstrap"
import { observer } from "mobx-react"
import { toJS } from "mobx"
import moment from "moment"

// project imports
import QmsDetailsPageTabComponent from "modules/qms-flow/components/qms-details-page-tab-component/QmsDetailsPageTabComponent"
import CurrentCoverageCard from "modules/solution/components/current-coverage-card/CurrentCoverageCard"
import QmsDetailsPageStore from "./QmsDetailsPageStore"
import SolutionBenefitsCard from "modules/solution/components/solution-benefits-card/SolutionBenefitsCard"
import SolutionScreenSingleLaCard from "modules/solution/components/solution-screen-single-la-card/SolutionScreenSingleLaCard"
import SolutionScreenMulipleLaCard from "modules/solution/components/solution-screen-multiple-la-card/SolutionScreenMulipleLaCard"
import PolicyTimlineTable from "modules/solution/components/policy-timeline-table/PolicyTimelineTable"
import UnderlyingCard from "modules/solution/components/underlying-card/UnderlyingCard"
import * as Constants from "constants/Constants";
import Utils from 'modules/solution/utils';
import ProductConstants from "constants/ProductConstants"
import { NextButton } from "components"
import * as images from "constants/Images";
import StartApplicationModal from "../start-application-modal/StartApplicationModal"
import QmsStorageKeys from "constants/QmsStorageKeys"
import SolutionStore from "modules/solution/store/SolutionStore"
import StorageKeys from "constants/StorageKeys"

import "modules/solution/solution.css"

const QmsDetailsPage = observer(
	class QmsDetailsPage extends Component {
		constructor(props) {
			super(props)
			this.state = {
				tabList: [],
				selectedTabIndex: "0",
				seletedTabData: {},
				showStartApplicationModal: false
			}
			this.setSelectedTabIndex = this.setSelectedTabIndex.bind(this)
		}

		async componentDidMount() {
			window.scrollTo(0, 0)
			let agentData = this.props.location.state?.agent_details
			let proposerId = this.props.location.state?.proposerId
			await QmsDetailsPageStore.saveAgentDetails(agentData)
			await SolutionStore.fetchSolutionData(proposerId)
			QmsDetailsPageStore.updateSelectedTabData(toJS(SolutionStore.user_list_data[0]))
			this.setState({ seletedTabData: toJS(SolutionStore.user_list_data[0]), tabList: toJS(SolutionStore.user_list_data) })
			QmsDetailsPageStore.solutionId = toJS(SolutionStore.user_list_data[0]).solution_id
		}

		setSelectedTabIndex(tabIndex) {
			this.setState({ selectedTabIndex: tabIndex })
		}

		componentDidUpdate(prevProps, prevState) {
			if (prevState.selectedTabIndex != this.state.selectedTabIndex) {
				toJS(SolutionStore.user_list_data).forEach((item, index) => {
					if (index.toString() === this.state.selectedTabIndex) {
						this.setState({ seletedTabData: item })
						QmsDetailsPageStore.updateSelectedTabData(item)
						QmsDetailsPageStore.solutionId = item.solution_id
					}
				})
			}
		}

		laNameFunction(singleLA) {
			let { la_salutation, la_name, la_family_member_type } = singleLA
			// if (la_salutation && la_name) { return `${la_salutation} ${la_name}` }
			if (la_name) { return `${la_name}` }
			return la_family_member_type
		}

		// downloadClick() {
		// 	fetch("https://etli-uat-dms-files.s3.amazonaws.com/solutionId/164/ETLI_Solution_Presentation_Rasik__H__S_-_164_12064629072022.pdf?response-content-type=application%2Fpdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAY23LODZ35FP6F4LB%2F20220729%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220729T063654Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRjBEAiAuEojORQ%2FXWH8BwTvO6OkbGL4Z3npTD7%2BjakwbGWx%2B5wIgWgn89c1YoF67HtqGnlXkEbPeFBRMFZYp0YCF5duDAu8q%2FQIIEBADGgw2MDc0MjYzMjIwMzkiDNdF%2Fr%2FeLf1%2Fk1sPJyraAr998mEtSxMEqUw%2FAvke3scMSiUUbnPf5yN9qZMcD7Gfv6K1aN%2F%2BrMTTNj%2FQouTU5yzykYOGRfrV5Qbw3ILNlNDJnMGXI2syhDrGyiux9HPtKLYYrMbrnrKHsrGeP%2FuK1sc%2Fpf7QrtvuUwA%2BBHKaxYkbAPHznl4VFXWS40gAISSamUb17m6d2FtdX98CI90vP2EEkcinFY7tYh1ahg6tYb14lsKsyzDUOSJEdm1InpPIbmN5m8BVsLi4%2BYMK9G7SWIVBEXIx4pOfD1zWsHp9Dy%2Bo3gO0swGwoxhs7j67Vk3d0uYFUfbk5pcWE932JinoLyD3rbfzcG2%2FojL%2B8%2FUQLBTAvrjvT9nhf1x8%2B9yYjLP2ssK3IUfqUt6Jlu5qS6fDAzT83GvXr8%2Bm7CMjmkITpRhYsS%2F%2FYj3Kymp2MX%2FGoTrFvKI4cAQ9zynNDTGrwPDKp2Kf6QuJZPyEYUAwg4COlwY6nwG1ficsh4O6PjV78mhH2W3ezUDJ2%2Fs0snuniCr0bvn7Kj3ero9HUcEJu3s2y4B3rn6MCUwDd91A213MnISKPwrn8U9KBPFokd6troM6Bh9n0teQ7EwJ94kDtmSi0JQsJ%2FQjDMGcrZvuC2eio0Uljn6Yv5rdbWPbhmjKuRBnQb8WiahUQS0LSEMBSJvB9PTOM2dkY712wk1AHu1sgj7EIA0%3D&X-Amz-Signature=09832bf84161c752b85ccbd3a1be8f2e3777334abd28c27b33533ba86840335e").then(r => r.blob()).then((blob) => {
		// 		// Create blob link to download
		// 		const url = window.URL.createObjectURL(
		// 			new Blob([blob]),
		// 		);
		// 		const link = document.createElement('a');
		// 		link.href = url;
		// 		link.setAttribute(
		// 			'download',
		// 			`FileName.pdf`,
		// 		);
		// 		// Append to html link element page
		// 		document.body.appendChild(link);
		// 		// Start download
		// 		link.click();
		// 		// Clean up and remove the link
		// 		link.parentNode.removeChild(link);
		// 	});;
		// }
		onEditQuote() {
			localStorage.setItem(StorageKeys.FIRST_TIME_OPTIMIZATION_PAGE_LOAD_PARAM, false);
			this.props.history.push(`/customization/${this.props.location.state?.journeyId}`) 
		}
		render() {
			console.log("QmsDetailsPageStore.solutionId ::: ", toJS(QmsDetailsPageStore.solutionId));
			console.log("localStorage.getItem(QmsStorageKeys.START_APPLICATION_END_POINT_URL) ::: ", localStorage.getItem(QmsStorageKeys.START_APPLICATION_END_POINT_URL));
			console.log("localStorage.getItem(QmsStorageKeys.LOGIN_AGENT_CODE) ::: ", localStorage.getItem(QmsStorageKeys.LOGIN_AGENT_CODE));
			let totalPayableAmount = 0
			const coverageArray = this.state.seletedTabData?.coverage
			let details
			
			if (coverageArray) {
			  let solutionDetails = coverageArray.map((item) => {
				let needTitle = item.need_title;
				let laList = item.life_assured_list.map((singleLA) => {
				  let productDetails = []
				  singleLA.product_details.length > 0 && singleLA.product_details.forEach((item) => {
					let inputMode = item.data.InputString.find((item) => item.Key == "@INPUT_MODE")?.Value
					let riderBasePremium = Math.round(item.data.InputString.find((item) => item.Key == "@RD_TOTAL_PREM_WOTAX")?.Value) || 0
					let product = {
					  productName: item.product_name,
					  targetAmount: Utils.fetchTargetAMount(item.product_name, item.data.OutputJSON, item.data.InputString),
					  totalPremiumAmount: Utils.fetchTargetPremiumAmount(item.product_name, riderBasePremium, item.data.OutputJSON, inputMode, item.data.InputString),
					  recommendedAmount: item.recommended_sum_assured,
					  basePremium: Math.round(Utils.fetchBasePremium(item.product_name, item.data.OutputJSON)),
					  gstPercent: Utils.fetchGstPercent(item.product_name, item.data.OutputJSON) * 100,
					  gstAmount: Math.round(Utils.fetchGstAmount(item.product_name, item.data.OutputJSON)),
					  riderGstPercentage: Constants.RIDER_GST_PERCENT,
					  riderGstAmount: Math.round(Utils.fetchRiderGSTAmount(item.product_name,item.data.OutputJSON,item.data.InputString)),
					  riderBasePremium: riderBasePremium,
					  inputMode: Utils.fetchInputMode(inputMode),
					  totalPremiumPayable: Utils.fetchTotalPremiumPayable(item.product_name, item.data.OutputJSON[0][0]),
					  inputMode: Number(inputMode)
					}
	  
					let basePremium = product.basePremium;
					let gstAmount = product.gstAmount ? product.gstAmount : 0;
					let riderGstAmount = product.riderGstAmount ? product.riderGstAmount : 0;
	  
					if (Number(inputMode) === 4) {
					  totalPayableAmount = (totalPayableAmount + basePremium + gstAmount + riderBasePremium + riderGstAmount) * 2
					} else {
					  totalPayableAmount = totalPayableAmount + basePremium + gstAmount + riderBasePremium + riderGstAmount
					}
					productDetails.push(product)
				  })
				  return { laName: this.laNameFunction(singleLA), productDetails: productDetails }
				})
				return { needTitle, laList }
	  
			  })
	  
			  details = solutionDetails
			}
			if (!details) return null	  

			let journeyId = this.props.location.state?.journeyId
			let proposerList = QmsDetailsPageStore.fetchTabListData(toJS(SolutionStore.user_list_data))
			let qmsDetailsData = toJS(SolutionStore.user_list_data)
			// let url = "https://etli-uat-dms-files.s3.amazonaws.com/solutionId/164/ETLI_Solution_Presentation_Rasik__H__S_-_164_12314429072022.pdf?response-content-type=application%2Fpdf&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAY23LODZ3TAEEF672%2F20220729%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20220729T070152Z&X-Amz-Expires=300&X-Amz-SignedHeaders=host&X-Amz-Security-Token=IQoJb3JpZ2luX2VjELf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCmFwLXNvdXRoLTEiRjBEAiBvqNpVfsWKCDTZTFVKoM7rU69BAH4UZMOSxnPELeXeWgIgCWgK9UIwuAxf%2BmxVXF1Jzp9rjlLYFSIKAk%2BE8mWYQfEq%2FQIIEBADGgw2MDc0MjYzMjIwMzkiDCCf4K1t3ONG3ojFnSraAmOK5AegCd4hzx21bVQKPI13QX%2BxH4cOT%2F%2BcfgL5uEHzqrsASauGCfSh0l8Nm0q48WYFu5EIREvku3jq3jHZSF2Fh2r8kgyC1AkHeA7f%2Btg5%2FcF9QCkyOiC3QCBtMtn%2BPxs6SP4m36mP7wZnvCvz%2B198G9CttmglYB1mZYsPBzYpNSnpYdIiDw0SsS24r1u7Vgzj6WwPuqlV3n3mR%2BSJTKv8vwz8TewSgAuE7lz5v46MzfZXOObuOV2PEmhOhStY3fll4tYbHSHUuCheyuzFgLBG7RoCGGiTFh9Pcj%2BbfIFnYNoffc0iG5O07nHwm4EXDRMBUYFPwjlA5oSPBHeBgboBo6f66juyuENPe8uBswkkVpfasoyQFD2NG%2FjAqwA5yZrXKeF5Rypby%2F0WjiXJMwCiOoBKA93oyDIBMbZslJw7CXW%2B4vNxHt8V4k2oJWveUT6W9VigyMSfVOow3YuOlwY6nwEgcXgTiXqQ3xNPcKKdxblrgY0QRtAv9DlnzFnE9U9Cpw0TUSCZHMjX6%2BQ4QO9yzphMd5%2Bx9ajxTeCqIPhH9Aku96UZr4Ux45GPUQaG34gKNKkAzqFI0Ii0M9I%2FvgUTQ1YSOZRnG0rmn2%2BaIANBs%2BAQ1Shm33rbR8KrVywVyxI%2BrzDo2a%2FajB91PIYDqpz%2FJsp5bpusBkmh5UAHSA9LcG0%3D&X-Amz-Signature=265f7c0cf11b9a48efdb7acbcfd91d3432b68cc66fc5b15b59fc265a9bdf8ef5"
			return (
				<Container className="solution-container qms-details-page">
					<Row>
						<Col xs="12">
							<QmsDetailsPageTabComponent
								tabList={proposerList}
								setSelectedTabIndex={this.setSelectedTabIndex}
							/>
						</Col>

						<Col xs="12">
							<Row className="mt-3">
								<Col md="6" xs="12">
									<div className="d-flex">
										<div className="w-50 edit-quote">
											<NextButton
												buttonText="Edit Quote"
												size="sm"
												prefixIcon={<Image width='12' height='12' className="mr-2" src={images.QMSEditQuoteIcon} alt='back-arrow' />}
												onClick={() => this.onEditQuote()}
											/>
										</div>
										<div className="ml-3 w-100 start-application ">
											<NextButton buttonText="Start Application" onClick={() => QmsDetailsPageStore.onStartApplicationClick()} />
										</div>
									</div>
								</Col>
								<Col md="6" xs="12" className="align-items-center d-flex justify-content-md-end ">
									<div className="font-size-14 font-weight-400 mb-0 mr-1 download-pdf mt-md-0 mt-2 d-flex align-items-center" onClick={() => QmsDetailsPageStore.fetchPdfUrl(qmsDetailsData[0])}>
										<Image  className="mr-2" src={images.QMSPDFIcon} alt='back-arrow' />
										<span>Download Quote pdf</span>
									</div>
								</Col>
							</Row>
							<div className='border-bottom border-dark my-3'></div>
						</Col>
						<Col xs="12">
							<div className="d-flex">
								{this.state.seletedTabData?.solution_id && <p className="font-size-14 font-weight-bold mb-0 mr-1">Soln ID:{this.state.seletedTabData?.solution_id || '-'}</p>}
								<p className="font-size-14 mb-0">shared on {this.state.seletedTabData.pdf_shared_date ? moment(this.state.seletedTabData.pdf_shared_date).format("DD-MM-YYYY") : this.state.seletedTabData.pdf_shared_date}</p>
							</div>
						</Col>

						<Col xs="12">
							<CurrentCoverageCard
								selectedTabData={this.state.seletedTabData}
								totalPayableAmount={totalPayableAmount}
							/>
						</Col>

						<Col xs="12" md="12" lg="12" className="mt-5 mx-auto">
							<p className="text-primary font-weight-bold font-size-16 font-size-lg-18 mb-3 pb-0 pl-4">
								Solution Coverage
							</p>

							{details.map((item) => {

								if (item.laList.length >= 2) {
									return <SolutionScreenMulipleLaCard
										CardName={item.needTitle}
										detail={item}
									/>
								}
								return null

							})}

							<div className="row px-2 d-flex flex-column flex-lg-row  mt-2">
								{details.map((singleItem, index) => {
									if (singleItem.laList.length < 2) {

										return <SolutionScreenSingleLaCard
											key={index}
											CardName={singleItem.needTitle}
											detail={singleItem}
										/>
									}
									return null
								})}
							</div>
						</Col>

						{this.state.seletedTabData?.solutionBenifits &&
							<Col xs="12" className="mt-5 d-flex flex-column">
								<p className="text-primary font-weight-bold font-size-16 font-size-lg-18 mb-3 pb-0 pl-4">
									Solution Benefits
								</p>
								<div className="solution-card-col">
									<Row className="solution-mobile-bg-style">
										{this.state.seletedTabData?.solutionBenifits?.map((obj, index) => {
											return (
												<Col key={index} className="col-md-6 col-12 d-flex">
													<div className="solution-desktop-bg-style d-inline-block w-100 px-sm-3 pt-4 pt-lg-4 pb-lg-1">
														<SolutionBenefitsCard
															solutionData={obj}
														/>
													</div>
												</Col>
											)
										})}
									</Row>
								</div>
							</Col>
						}

						<Col
							xs="12"
							lg="12"
							className="protection-coverage-card-col my-5 mb-lg-5">
							<p className="text-primary font-weight-bold font-size-16 font-size-lg-18 mb-3 pb-0 pl-4">
								Solution Timeline
							</p>
							<PolicyTimlineTable data={this.state.seletedTabData || []} />
						</Col>

						<Col
							xs="12"
							lg="12"
							className="protection-coverage-card-col mb-4 mb-lg-5 d-flex flex-column"
						>
							<p className="text-primary font-weight-bold font-size-16 font-size-lg-18 mb-3 pb-0 pl-4">
								Underlying products
							</p>
							<div className="under-lying-card-container bg-white d-inline-block w-100 px-3 px-lg-3 pt-3 pb-1 pt-lg-4 mb-lg-0">
								{this.state.seletedTabData?.productList?.map((value, index) => (
									<UnderlyingCard key={index} productData={value} />
								))}
							</div>
						</Col>
					</Row>
					<StartApplicationModal
						journeyId={this.props.location.state?.journeyId}
						solutionId={QmsDetailsPageStore.solutionId}
					/>
					{localStorage.getItem(QmsStorageKeys.LOGIN_AGENT_CODE) ? (
						<div>
							<form className='d-none' id='pragati-out-oubound-form-post-call' action={localStorage.getItem(QmsStorageKeys.START_APPLICATION_END_POINT_URL)} method={"POST"}>
								<input type='text' name='Pragati_Token' value={localStorage.getItem(StorageKeys.AUTH_TOKEN)} />
								<input type='text' name='LoginCode' value={localStorage.getItem(QmsStorageKeys.LOGIN_CODE)} />
								<input type='text' name='Vendor' value={localStorage.getItem(QmsStorageKeys.VENDOR)} />
								<input type='text' name='SourceFrom' value={localStorage.getItem(QmsStorageKeys.SOURCE_FROM)} />
								<input type='text' name='LoginAgentCode' value={localStorage.getItem(QmsStorageKeys.LOGIN_AGENT_CODE)} />
								<input type='text' name='Solution_Id' value={QmsDetailsPageStore.solutionId ? QmsDetailsPageStore.solutionId : ""} />
								<input type='text' name='LA_Specific_Id' value={""} />
								<button type='submit'>Submit</button>
							</form>
						</div>
					) : (
						<div></div>
					)}
				</Container>
			)
		}
	}
)

export default QmsDetailsPage
