import React, { Component } from 'react';

import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import { Collapse } from '../../../../components'

// import PropTypes from 'prop-types';

import FamilyProtectionStore from '../../store/FamilyProtectionStore';
import ChooseNeedsStore from '../../../choose-needs/store/ChooseNeedsStore';
import FamilyDetailsStore from '../../../family-details-form/store/FamilyDetailsStore';

import ProductTabComponent from '../product-tab-component/ProductTabComponent'

import AllProducts from '../products/all-products/all-products'

import moment from 'moment'

const MemberCard = observer(class MemberCard extends Component {
    state = {
        productName: ''
    }

    capitalize = (str) => {
        if (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }
        return ''
    }

    componentDidMount() {
        const { data } = this.props;
        // console.log('MEMBER CARD DID MOUNT >> ', data)
        if (data.product_name) {
            this.setState({ productName: data.product_name })
        }
    }

    getAge = (dob) => {
        let age = 0;
        if (dob) {
            var dateFormat = dob.split('-')
            age = moment().diff(moment(dateFormat[2] + '-' + dateFormat[1] + '-' + dateFormat[0]), 'years');
        }
        return age
    }

    render() {
        const { productName } = this.state;
        const { index, disabled, open, onChange, data, cardKey, onAddMember, onAddInsured } = this.props;
        const age = this.getAge(data.dob);
        return (
            <Collapse
                key={index}
                keyIndex={index}
                disabled={disabled}
                open={open || index == 0}
                onChange={onChange}
                title={
                    <div className={`w-100 mr-2 mr-md-4 select-deselect-btn ${disabled ? 'text-gray' : 'text-description-text-color'}`}>
                        <span className='font-size-16 font-size-md-24'>Insured <span className={`font-weight-bold ${disabled ? 'text-gray' : 'text-secondary'}`}> {this.capitalize(data.family_member_type)}, {age} years old</span></span>
                        {/* {(selectedCollapseKey.includes(index)) &&
                            <div className='remove-insured'>
                                <Button
                                    className='font-weight-medium font-size-14 font-size-23 py-0 px-0'
                                    variant='link'
                                    buttonText='Remove Insured'
                                    role='button'
                                    onClick={(e) => onRemoveInsured(e, data)}
                                />
                            </div>
                        } */}
                    </div>
                }>
                <React.Fragment>
                    {
                        productName ?
                            <div>
                                <AllProducts productName={productName} data={data} cardKey={cardKey} onAddMember={onAddMember} onAddInsured={onAddInsured} />
                            </div>
                            :
                            data.BI.length > 0 ?
                                <ProductTabComponent data={data} cardKey={cardKey} onAddMember={onAddMember} onAddInsured={onAddInsured} />
                                :
                                <div>No right match found. Kindly re-evaluate</div>
                    }
                </React.Fragment>
            </Collapse>
        );
    }
});

export default MemberCard;