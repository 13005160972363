import { observable, action, makeObservable } from "mobx";
import { toJS } from "mobx";
import axios from "../../../utils/axios";
import * as APITypes from "../../../constants/API";

class PreNeedSelectionModalStore {
  customerTypeList = [];

  existingCustomerDetailList = [
    { id: 1, label: "PAN No.", isSelected: false, key: "PAN No." },
    { id: 0, label: "Mobile No.", isSelected: true, key: "Mobile No." },
    { id: 2, label: "Customer ID", isSelected: false, key: "Customer ID" },
    { id: 3, label: "Policy No.", isSelected: false, key: "Policy No." },
  ];

  individualOccupationList = [];

  individualEducationList = [];

  categoryList = [];

  preNeedSelectionModal = {
    id: "preNeedSelectionModal",
    title: "Pre Need Selection Modal",
    customerType: {},
    customer: "new customer",
    existingCustomerDetail: {},
    existingCustomerName: "",
    individualOccupation: {},
    individualEducation: {},
    location: [],
    category: {},
    employeeCode: "",
  };

  isCustomerTypeSelect = false;
  isCategorySelect = false;
  isEmployeeCode = false;
  isExistingCustomerDetailSelect = false;
  isExistingCustomerName = false;
  isIndividualOccupationSelect = false;
  isIndividualEducationSelect = false;

  locationErrorMsg = "";
  locationData = [];

  constructor() {
    makeObservable(this, {
      preNeedSelectionModal: observable,
      locationErrorMsg: observable,
      locationData: observable,
      isCustomerTypeSelect: observable,
      isExistingCustomerDetailSelect: observable,
      isExistingCustomerName: observable,
      isIndividualOccupationSelect: observable,
      isIndividualEducationSelect: observable,
      isCategorySelect: observable,
      isEmployeeCode: observable,
      onUpdateValue: action,
      onPreNeedProceedBtnClick: action,
      fetchMasterData: action
    });
  }


  updateLocation(data) {
    this.locationData = data;
    this.locationErrorMsg = data.length == 0 || (data.id && data.id.length !== 6) ? "Enter valid location" : "";
  }

  onUpdateValue = async (key, value) => {
    let preNeedSelectionModal = toJS(this.preNeedSelectionModal);
    switch (key) {
      case "preNeedSelectionModal":
        value.action = "save";
        this.preNeedSelectionModal = { ...preNeedSelectionModal, ...value };
        break;
    }
    if (preNeedSelectionModal?.category?.label !== "Staff") {
      this.isEmployeeCode = false;
    }

  };

  updateAction(object, value) {
    if (this.needList.length > 0) {
      return "update";
    } else {
      return "save";
    }
    // if (object.isSelected == true && value.isSelected == false) {
    //   action = 'delete';
    // } else if (object.needCover !== value.needCover) {
    //   action = 'update';
    // } else {
    //   action = object.action;
    // }
  }

  onPreNeedProceedBtnClick = async () => {
    let preNeedSelectionModal = toJS(this.preNeedSelectionModal);

    this.isCustomerTypeSelect = Object.keys(preNeedSelectionModal.customerType).length == 0 ? true : false;
    this.isExistingCustomerDetailSelect = Object.keys(preNeedSelectionModal.existingCustomerDetail).length == 0 ? true : false;
    this.isExistingCustomerName = Object.keys(preNeedSelectionModal.existingCustomerName).length == 0 ? true : false;
    this.isIndividualOccupationSelect = Object.keys(preNeedSelectionModal.individualOccupation).length == 0 ? true : false;
    this.isIndividualEducationSelect = Object.keys(preNeedSelectionModal.individualEducation).length == 0 ? true : false;
    this.isCategorySelect = Object.keys(preNeedSelectionModal.category).length == 0 ? true : false;
    this.isEmployeeCode = Object.keys(preNeedSelectionModal.employeeCode).length == 0 ? true : false;
    this.locationErrorMsg = this.locationData.length == 0 ? "Enter valid location" : "";

    if(!this.isCustomerTypeSelect &&
      !this.isIndividualOccupationSelect &&
      !this.isIndividualEducationSelect){

        // console.log('proceed');
        let locationData = toJS(this.locationData)[0]
    
        let dataToBeSubmitted = {
          "journey_type": preNeedSelectionModal.customerType.id,
          "pin_code": locationData?.id,
          "office_name": locationData?.label,
          "state": locationData?.location,
          "customer_type": 1,
          "category": preNeedSelectionModal.category.id,
          "employee_code": preNeedSelectionModal.customerType.id,
          "occupation": preNeedSelectionModal.individualOccupation.id,
          "education": preNeedSelectionModal.individualEducation.id,
          "journey_id": ""
        }
    
        let respponse = await axios.post(
          APITypes.SAVE_PRE_NEED_DATA,
          dataToBeSubmitted
        )
    
        return respponse.data.journey_id

    }


  };

  fetchMasterData = () => {
    global.loading(true);

    const occupationRequest = axios.get(APITypes.OCCUPATION_MASTER)
    const educationRequest = axios.get(APITypes.EDUCATION_MASTER)
    const categoryRequest = axios.get(APITypes.CATEGORY_MASTER)
    const journeyTypeRequest = axios.get(APITypes.JOURNEY_MASTER)

    axios.all([occupationRequest, educationRequest, categoryRequest, journeyTypeRequest])
      .then(axios.spread((...responses) => {
        const occupationResponse = responses[0]
        const educationResponse = responses[1]
        const categoryResponse = responses[2]
        const journeyTypeResponse = responses[3]

        this.individualOccupationList = occupationResponse.data
        this.individualEducationList = educationResponse.data
        this.categoryList = categoryResponse.data
        this.customerTypeList = journeyTypeResponse.data

      }))
    global.loading(false);
  }
}
export default new PreNeedSelectionModalStore();
