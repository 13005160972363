import React, { Component } from 'react';
import {  Collapse } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { Image } from "react-bootstrap";
import * as images from "constants/Images";

import './collapse.css'
import { CollapseIcon, ExpandIcon } from 'constants/Images';

class CollapseComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }
    onChange = (e) => {
        const { disabled } = this.props;
        if (!disabled) {
            const open = !this.state.open;
            this.setState({ open })
            this.props.onChange(open, this.props.keyIndex)
            this.props.toggleCollapse(open)
        }
    }
    componentDidMount() {
        const open = this.props.open
        this.setState({ open: open })
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.disabled && this.props.disabled) {
            this.setState({ open: false })
        }
        if (prevProps.open !== this.props.open){
            this.setState({ open: this.props.open })
        }
    }
    render() {
        const { open } = this.state;
        const { title, titleText, titleStyle,showModalBackDrop, showAddLA, addLA } = this.props;
        return (
            <div className='shadow p-md-3 px-2 px-md-0 py-3 mb-4 bg-white rounded collapse-container'>
                {showModalBackDrop && 
                    <div className='maturity-payoutmode-overlay'>{showAddLA && (
                        <div style={{ width: '100%', height: '100%', backgroundColor: "rgba(0, 0, 0, 0.5)", textAlign: "center", paddingTop: "20px"}}>
                            <button
                                className='btn add-LA-button p-0 LA-btn font-size-16 font-size-md-14 font-weight-bold order-2 order-lg-3'
                                onClick={addLA}
                                disabled={this.props.isNeedDisabled}
                            >
                                [ Reselect Life Assured ]
                            </button>
                        </div>  
                      )}
                    </div>}
                <div className='d-flex align-items-md-center align-items-top justify-content-between' aria-controls='example-collapse-text' role='button'>
                    {title ? title : <div className={titleStyle}>{titleText}</div>}
                    {open ?
                        <div className='text-center align-self-start' onClick={(e) => this.onChange(e)}>
                            <img className='collapse-arrow' src={CollapseIcon} alt='up-arrow' />
                            {/* <div><span className='text-primary font-size-11 font-size-md-12 font-weight-md-400'>Collapse</span></div> */}
                        </div>
                        :
                        <div className='text-center' onClick={(e) => this.onChange(e)}>
                            <img className='collapse-arrow' src={ExpandIcon} alt='up-arrow' />
                            {/* <div><span className='text-primary font-size-11 font-size-md-12 font-weight-md-400'>Expand</span></div> */}
                        </div>
                    }
                </div>
                <Collapse in={open}>
                    <div id='example-collapse-text'>
                        {this.props.children ? this.props.children : ''}
                    </div>
                </Collapse>
            </div>
        );
    }
}
CollapseComponent.defaultProps = {
    titleStyle: '',
    titleText: '',
    title: 'Open',
    onChange: () => { },
    open: false,
    disabled: false,
    keyIndex: ''
}
CollapseComponent.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    titleText: PropTypes.string,
    titleStyle: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
}

export default CollapseComponent;