// React Imports
import React from "react"

// Project Imports
import ModalBody from "react-bootstrap/ModalBody"
import BottomsheetDropdown from "components/ui/bottomsheet-dropdown/BottomsheetDropdown"
import { observer } from "mobx-react";
import { Card } from "react-bootstrap";

const MaturityPayOutModalForm = (props) => {

  const {formController,targetAmountType,closeModal,submitData} = props;
   
  return (
    <Card className="maturity-payout-mode-card">
          <div className="MaturityPayOutModalContent">
            <div className="text-center">
              <p className="font-size-14 font-weight-medium text-primary text-center my-2">
                Set Target
              </p>

              <p className="font-size-14 font-weight-light sub-heading-text  text-center mb-1">
              {`${targetAmountType} amount of`}
            </p>
              <div className="row col-9 mx-auto">
                <div className="col">
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey="label"
                    bottomsheetTitle="Maturity Payout Mode"
                    menuList={formController.$("target_amount_dropdown").extra.optionList}
                    value={formController.$("target_amount_dropdown").value}
                    onSelect={formController.$("target_amount_dropdown").onChange}
                    showErrorMsg={formController.$("target_amount_dropdown").error !== null}
                    errorMsg={formController.$("target_amount_dropdown").error}
                    disabled={false}

                  />
                </div>
              </div>

              <div className="mx-auto my-3 maturity-payout-mode-btn-container d-flex justify-content-around">
                <button
                  type="submit"
                  className="btn btn-lg py-2 cancel-btn py-lg-1 font-size-12  font-weight-medium"
                  onClick={closeModal}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className="btn btn-secondary cta-btn btn-lg py-2 py-lg-1 font-size-12  font-weight-medium"
                  onClick={submitData}
                >
                  Set
                </button>
              </div>
            </div>
          </div>
        </Card>
  )
}

export default observer(MaturityPayOutModalForm)
