import React, { Component } from 'react';

import { observer } from 'mobx-react';

import { Modal, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { Button as UiButton, BottomsheetDropdown } from '../../../../../../../components';

import './check-eligibily-modal.css';

const CheckEligibilityModal = observer(class CheckEligibilityModal extends Component {

  render() {
    const { data, form } = this.props;
    return (
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop="static"
        className='error-modal'
        show={!form.$('check-eligibility').extra.hide}
        keyboard={false}
      >
        <Modal.Body className='px-4 pt-4 pb-0'>
          <React.Fragment>
            <div className='check-eligibility-modal'>
              <div className="text-right">
                <UiButton onClick={() => form.$('check-eligibility').extra.hideModal(form)} className='transparent font-size-medium font-size-16 px-4 mb-2 mb-md-0' variant='light' buttonText='X' />
              </div>
              <Row>
                <Col xs='12' md="6" lg='4'>
                  <div className='p-3 bg-white rounded'>
                    <div className='mb-2 text-description-text-color text-left font-size-12'>{form.$('nationality').label}</div>
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey='label'
                      bottomsheetTitle={form.$('nationality').label}
                      menuList={form.$('nationality').extra.optionList}
                      value={form.$('nationality').value}
                      onSelect={form.$('nationality').onChange}
                      showErrorMsg={!form.$('nationality').value && form.$('check-eligibility').extra.validate}
                    />
                  </div>
                </Col>
                <Col xs='12' md="6" lg='4'>
                  <div className='p-3 bg-white rounded'>
                    <div className='mb-2 text-description-text-color text-left font-size-12'>{form.$('occupation').label}</div>
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey='label'
                      bottomsheetTitle={form.$('occupation').label}
                      menuList={form.$('occupation').extra.optionList}
                      value={form.$('occupation').value}
                      onSelect={form.$('occupation').onChange}
                      showErrorMsg={!form.$('occupation').value && form.$('check-eligibility').extra.validate}
                    />
                  </div>
                </Col>
                <Col xs='12' md="6" lg='4'>
                  <div className='p-3 bg-white rounded'>
                    <div className='mb-2 text-description-text-color text-left font-size-12'>{form.$('education').label}</div>
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey='label'
                      bottomsheetTitle={form.$('education').label}
                      menuList={form.$('education').extra.optionList}
                      value={form.$('education').value}
                      onSelect={form.$('education').onChange}
                      showErrorMsg={!form.$('education').value && form.$('check-eligibility').extra.validate}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs='12' md="6" lg='4'>
                  <div className='d-block'>
                    <div className='ml-3 text-description-text-color text-left font-size-12'>{form.$('annual-income').label}</div>
                    <InputGroup disabled={form.$('annual-income').disabled} className='m-0 ml-3 w-auto'>
                      <InputGroup.Prepend>
                        <InputGroup.Text className='text-secondary border-primary border-left-0 border-right-0 border-top-0 font-size-14 font-size-md-16 pr-0'>&#8377;
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        className='border-primary border-left-0 border-right-0 border-top-0 text-secondary font-size-14 font-size-md-16 font-weight-bold'
                        required
                        placeholder={form.$('annual-income').label}
                        value={form.$('annual-income').value}
                        onChange={form.$('annual-income').onChange}
                      />
                    </InputGroup >
                  </div>
                </Col>
              </Row>
            </div>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer className='d-block p-md-4 border-0'>
          <Row className=''>
            <Col xs='12' md='12' className="text-center">
              <UiButton
                onClick={() => form.$('check-eligibility').extra.onCheckEligibility(form, data)}
                className='font-size-medium font-size-16 px-4 mb-2 mb-md-0' variant='secondary'
                buttonText={form.$('check-eligibility').label}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
});

export default CheckEligibilityModal;
