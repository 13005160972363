export const BASE_URL = process.env.REACT_APP_NA_BASE_API_URL;

// Need Analysis API
export const SAVE_SESSION = `${BASE_URL}/save-session-data/`;
// export const SAVE_NEED = `${BASE_URL}/save-need/`;
export const FETCH_EXPENSE_LIST = `${BASE_URL}/expense-list/`;
export const FETCH_ALL_DATA = `${BASE_URL}/get-all-data/`;
export const SAVE_FAMILY_PRODUCT_DETAILS = `${BASE_URL}/save-family-product-details/`;
export const SAVE_FAMILY_DETAILS = `${BASE_URL}/save-family-details/`;
export const SAVE_PRODUCT_CHANGE_DETAILS = `${BASE_URL}/save-product-change-details/`;
export const GET_FAMILY_PRODUCT_DETAILS = `${BASE_URL}/get-family-product-details/`;
//export const GET_RECOMMENDATION = `${BASE_URL}/get-recommendation/`;
export const GET_PINCODE = `${BASE_URL}/pin-code/`;
export const AUTH_TOKEN = `${BASE_URL}/auth/oauth2-token/`;
export const LOGIN = `${BASE_URL}/auth/login/`;
export const GENERATE_TOKEN = `${BASE_URL}/auth/generate-token/`;
export const REFRESH_TOKEN = `${BASE_URL}/refresh-token/`;
export const VERIFY_TOKEN = `${BASE_URL}/auth/verify-token/`;
export const LOGOUT = `${BASE_URL}/auth/logout/`;
export const OTP = `${BASE_URL}api/otp/`;


// Masters Data
export const OCCUPATION_MASTER = `${BASE_URL}/occupation/`;
export const EDUCATION_MASTER = `${BASE_URL}/education/`;
export const CATEGORY_MASTER = `${BASE_URL}/category/`;
export const JOURNEY_MASTER = `${BASE_URL}/journey/`;
export const LIFE_COVER_MASTER = `${BASE_URL}/life-cover/`;
export const NEED_MASTER = `${BASE_URL}/need-master/`;
export const NATIONALITY_MASTER = `${BASE_URL}/nation-master/`;
export const SALUTATION_MASTER = `${BASE_URL}/salutation/`;
export const MONTHLY_INCOME_MASTER = `${BASE_URL}/monthly-income-master/`;

//Calculators
export const PROTECTION_CALCULATOR = `${BASE_URL}/protection-family-calculator/`;
export const RETIREMENT_CALCULATOR = `${BASE_URL}/save-retirement-calculator/`;


export const SAVE_PRE_NEED_DATA = `${BASE_URL}/save-pre-need-data/`;


// export const EXISTING_CUSTOMER_TYPE = `${BASE_URL}api/customer-type/`;

export const CHANGE_PASSWORD = `${BASE_URL}api/change-password/`;

export const QUOTATION_API = `${BASE_URL}/save-quotation-id/`;
// export const SOLUTION_API = `${BASE_URL}/get-solution-data/`;

// GI Api
export const BI = `${BASE_URL}/get-bi-data/`;

// Get Premium with min, max and recommended values
export const GET_PREMIUM_API = `${BASE_URL}/get-multi-target-premium/`

// Add Remove Assured
export const ADD_REMOVE_ASSURED = `${BASE_URL}/add-remove-insured/`;

export const DESELECT_EXPENSE = `${BASE_URL}/delete-journey-expense/`;
export const DESELECT_EMI = `${BASE_URL}/delete-journey-emi/`;


export const FETCH_BI_DATA_BASED_ON_PROVIDED_RECOMMENDATION = `${BASE_URL}/use-recommended-value/`;

export const GET_ELIGIBLE_PROPOSER_LIST = `${BASE_URL}/get-eligible-proposer-list/`;
export const GET_ELIGIBLE_LA_LIST = `${BASE_URL}/get-eligible-la-list/`;
export const CHANGE_LA = `${BASE_URL}/change-la/`

export const PRAGATI_INBOUND_DATA = `${BASE_URL}/pragati-na-inbound-data/`
export const HL_API = `${BASE_URL}/hl-agent-info-wrapper/`
export const BANCA_DETAILS_API = `${BASE_URL}/banca-wrapper/`
export const GENERATE_SOLUTION_DATA = `${BASE_URL}/generate-solution-data/`

export const SAVE_RISK_TYPE = `${BASE_URL}/save-risk-type/`

/** V2 APIs */
export const SAVE_NEED = `${BASE_URL}/v2/save-need/`;
export const GET_RECOMMENDATION = `${BASE_URL}/v2/get-recommendation/`;
export const CHANGE_LIFE_ASSURED = `${BASE_URL}/v2/change-life-assured/`
export const REMOVE_NEED = `${BASE_URL}/v2/remove-need-solution/`
export const CHANGE_PROPOSER = `${BASE_URL}/v2/change-proposer/`
export const REMOVE_LA = `${BASE_URL}/v2/remove-la/`;
export const RESELECT_LA = `${BASE_URL}/v2/reselect-la/`;
export const SELECT_NEED = `${BASE_URL}/v2/reselect-need-solution/`;
export const ADD_NEED = `${BASE_URL}/v2/add-need/`;
export const ADD_INSURED = `${BASE_URL}/v2/add-insured/`;
export const PRODUCT_CHANGE_REASONS = `${BASE_URL}/change-product-reason/`
export const FETCH_CHANGE_PRODUCT_LIST = `${BASE_URL}/fetch-change-product-list/`
export const CHANGE_PRODUCT = `${BASE_URL}/v2/change-product/`
export const CHANGE_TARGET_AMOUNT = `${BASE_URL}/v2/change-target-amount/`;

export const SAVE_COMMUNICATION_DETAILS = `${BASE_URL}/save-communication-details/`
export const FETCH_MULTIPLE_DMS_DOCUMENT = `${BASE_URL}/fetch_multiple_dms_document/`
export const SEND_PDF = `${BASE_URL}/send-pdf/`
export const SOLUTION_API = `${BASE_URL}/get-solution-data-v2/`;
export const GET_REVISED_RECO_SA_TERM_NEED = `${BASE_URL}/get-revised-reco-sa-term-need/`

export const FETCH_PRODUCT_TIME_LINE = `${BASE_URL}/fetch-product-timeline/`;

// QMS API list 
export const GET_QMS_LIST = `${BASE_URL}/get-qms-list-data/`
export const GET_QMS_LEAD_DETAILS = `${BASE_URL}/get-qms-lead-details/`;
export const FETCH_SOLUTION_PDF_FROM_DMS = `${BASE_URL}/fetch-solution-pdf-from-dms/`;
export const PRAGATI_QMS_INBOUND_DATA = `${BASE_URL}/pragati-qms-inbound-data/`;
export const NA_QUOTE_STATUS_UPDATE = `${BASE_URL}/na-quote-status-update/`;