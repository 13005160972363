import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import FamilyProtectionStore from '../../store/FamilyProtectionStore'

const AmountCalculation = (props) => {
    useEffect(() => {
        // Update the document title using the browser API
        const { cardKey, item, total_price } = props;
        if (FamilyProtectionStore.totalPriceData[cardKey]) {
            FamilyProtectionStore.totalPriceData[cardKey][item.id] = total_price
        } else {
            FamilyProtectionStore.totalPriceData[cardKey] = { [item.id]: total_price }
        }
    });
    return (
        <div className='mt-4 mt-md-5 payment-layout'>
            <AmountCalculationRowItem title='Total Premium' amount={props.total_without_gst || props.totalPremium} titleStyle='font-size-16 font-size-md-24' amountStyle='font-size-16 font-size-md-24' />
            <AmountCalculationRowItem title={`GST@${props.gst_percentage}%`} amount={props.gst} titleStyle='font-size-16 font-size-md-24' amountStyle='font-size-16 font-size-md-24' />
            {(props.riderList && props.riderList.length > 0) && props.riderList.filter((item) => item.is_selected).map((value) => (
                <AmountCalculationRowItem title={value.title} amount={value.sum_assured} titleStyle='font-size-16 font-size-md-24 font-size-lg-24' amountStyle='font-size-16 font-size-md-24 font-size-lg-24 font-weight-bold' />
            ))}
            {(props.riderTax !== undefined && props.riderTax !== null && props.riderTax !== 0) &&
                <AmountCalculationRowItem title='Rider Tax' amount={props.riderTax} titleStyle='font-size-16 font-size-md-24 mb-3' amountStyle='font-size-16 font-size-md-24' />
            }
            <hr className='line my-3' />
            <AmountCalculationRowItem premiumFreq={props.premiumFreq} title='Total Price' amount={props.total_price} titleStyle='font-size-18 font-size-md-24' amountStyle='font-size-18 font-size-md-24' />
            <hr className='line mt-3' />
        </div>
    )
}

const AmountCalculationRowItem = (props) => {
    const { title, amount, titleStyle, amountStyle, premiumFreq } = props;
    return (
        <div className='d-flex justify-content-between'>
            <span className={`text-primary-title-label-color ${titleStyle}`}>{title} {premiumFreq && <span className='text-gray-opacity-49 font-weight-normal'>( {premiumFreq} )</span>}</span>
            <span className={`text-secondary font-weight-bold ${amountStyle}`}> {global.toINR(amount)} </span>
        </div>
    )
}

AmountCalculation.defaultProps = {
    totalPremium: '0',
    gst: '0',
    gst_percentage: '',
    total_price: '0',
    riderList: []
}

AmountCalculation.propTypes = {
    totalPremium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gst: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    gst_percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total_price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default AmountCalculation;