// Package Imports
const { Form } = require("mobx-react-form")
import vjf from "mobx-react-form/lib/validators/VJF"
import validator from 'validator';

// Project Imports
import formObserver from "./observers"
import setInitialDefaults from "./initialDefaults"
import { checkIfOneNeedIsSelected, chooseNeedFieldValidation, validateTargetAmount } from './validation'
import isInteger from 'utils/validations/isInteger'


class ChooseNeedsFormController extends Form {
  plugins() {
    return {
      vjf: vjf(validator),
    }
  }

  setup() {
    return {
      fields: [
        {
          name: "journey_id",
          type: "hidden",
          validators: [checkIfOneNeedIsSelected]
        },
        {
          name: "member_id",
          type: "hidden",
        },
        {
          name: "protection_for_my_family",
          label: "Protection for my Family",
          disabled: true,
          fields: [
            {
              name: "id",
              type: "hidden",
            },
            {
              name: "monthly_income",
              type: "text",
              placeholder: "Monthly Income",
              validators: [chooseNeedFieldValidation('protection_for_my_family'), isInteger],
              value: "",
            },
            {
              name: "dob",
              type: "text",
              placeholder: "dob",
            },
            {
              name: "target_amount_type",
              type: "text",
              placeholder: "",
              validators: [chooseNeedFieldValidation('protection_for_my_family')],
              value: {},
              extra: {
                bottomsheetTitle: "Select an Option",
                optionList: [],
              },
            },
            {
              name: "target_amount_dropdown",
              placeholder: "",
              type: "hidden",
              validators: [validateTargetAmount('protection_for_my_family')],
              extra: {
                bottomsheetTitle: "Select a Target Amount",
              },
            },
            {
              name: "target_amount_value",
              placeholder: "",
              type: "hidden",
              validators: [validateTargetAmount('protection_for_my_family'), isInteger]
            },
            {
              name: "calculator_used",
              type: "hidden",
              value: false,
            },
            {
              name:"is_value_updated",
              value:false
            }
          ],
        },
        {
          name: "protection_for_my_health",
          label: "Protection for my Health",
          disabled: true,
          fields: [
            {
              name: "id",
              type: "hidden",
            },
            {
              name: "target_amount_type",
              value: {
                type: "lumpsum",
              },
              extra: {
                bottomsheetTitle: "Select an Option",
                optionList: [],
              },
            },
            {
              name: "target_amount_dropdown",
              placeholder: "",
              validators: [chooseNeedFieldValidation('protection_for_my_health')],
              value: {},
              extra: {
                bottomsheetTitle: "Select a Target Amount",
                optionList: [],
              },
            },
          ],
        },
        {
          name: "grow_my_money",
          label: "Grow my Money",
          disabled: true,
          fields: [
            {
              name: "id",
              type: "hidden",
            },
            {
              name: "target_amount_type",
              type: "text",
              placeholder: "",
              validators: [chooseNeedFieldValidation('grow_my_money')],
              value: {},
              extra: {
                bottomsheetTitle: "Select an Option",
                optionList: [],
              },
            },
            {
              name: "target_amount_dropdown",
              placeholder: "",
              validators: [chooseNeedFieldValidation('grow_my_money')],
              value: {},
              extra: {
                bottomsheetTitle: "Select a Target Amount",
                optionList: [],
              },
            },
          ],
        },
        {
          name: "save_for_children",
          label: "Save for my Children",
          disabled: false,
          fields: [
            {
              name: "id",
              type: "hidden",
            },
            {
              name: "target_amount_type",
              type: "text",
              placeholder: "",
              validators: [chooseNeedFieldValidation('save_for_children')],
              value: {},
              extra: {
                bottomsheetTitle: "Select an Option",
                allOptionsList: [],
                optionList: [],
              },
            },
            {
              name: "target_amount_dropdown",
              placeholder: "",
              validators: [chooseNeedFieldValidation('save_for_children')],
              value: {},
              extra: {
                bottomsheetTitle: "Select a Target Amount",
                allOptionsList: [],
                optionList: [],
              },
            },
          ],
        },
        {
          name: "save_for_retirement",
          label: "Save for my Retirement",
          disabled: false,
          fields: [
            {
              name: "id",
              type: "hidden",
            },
            {
              name: "target_amount_type",
              type: "text",
              placeholder: "",
              value: {},
              extra: {
                bottomsheetTitle: "Select an Option",
                optionList: [],
              },
            },
            {
              name: "target_amount_dropdown",
              placeholder: "",
              validators: [validateTargetAmount('save_for_retirement')],
              type: "hidden",
              extra: {
                bottomsheetTitle: "Select a Target Amount",
                allOptionsList: [],
                optionList: [],
              },
            },
            {
              name: "target_amount_value",
              placeholder: "",
              validators: [],
              validators: [validateTargetAmount('save_for_retirement'), isInteger],
              type: "hidden",
            },
            {
              name: "calculator_used",
              type: "hidden",
              value: false,
            },
            {
              name:"is_value_updated",
              value:false
            }
          ],
        },
      ],
    }
  }

  setDefaults(journeyId, journeyData) {
    // Set forms initial default value
    setInitialDefaults(this, journeyId, journeyData)

    // Observer fields
    formObserver(this)
  }
}

export default ChooseNeedsFormController
