// React imports
import React from "react"

// Package imports
import PropTypes from 'prop-types';
import { Card } from "react-bootstrap"

// Project imports
import { CustomCheckbox } from "components"
import {toggleDisabledState,showRelationWhenNoName} from "./utils"

const AddInsuredMemberCard = ({
  memberType,
  salutation,
  firstName,
  middleName,
  lastName,
  gender,
  age,
  id,
  formController,
  isSelected,
  name,
}) => {


  const overLayfunction = () => {
    return !formController.$("members").$(name).$("isSelected").value ? "card-overlay": null
  } 
  
  return (
    <div className="position-relative mt-2">
      <div
        className={overLayfunction()}
      ></div>

      <Card className="mb-3 rounded-lg add-insuered-member-detail-card">
        <Card.Body className="p-0 d-flex align-items-center">
          <CustomCheckbox
            id={id}
            shape="rounded"
            title={
              <article className="member-name">
                  {showRelationWhenNoName(salutation,firstName,middleName,lastName,memberType)}
              </article>
            }
            containerStyle="mb-0 p-3 w-100"
            titleStyle="text-primary-title-label-color font-weight-normal font-size-12 pl-2"
            isChecked={isSelected}
            mark="checkmark"
            className="add-Insured-checkbox"
            onChange={(currentState) =>{
              if(currentState) toggleDisabledState(formController, name, currentState)
            }
            }
          />

          <div className="mr-3 font-size-12">
            <span className="font-weight-bold text-dark">{age}</span>
            <span className=" ml-2 font-weight-light text-dark">years</span>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}


AddInsuredMemberCard.propTypes = {
  memberType: PropTypes.string.isRequired,
  salutation:PropTypes.string.isRequired,
  firstName:PropTypes.string.isRequired,
  middleName:PropTypes.string.isRequired,
  lastName:PropTypes.string.isRequired,
  gender:PropTypes.string.isRequired,
  age:PropTypes.number.isRequired,
  formController:PropTypes.object.isRequired,
  isSelected:PropTypes.bool.isRequired,
  name:PropTypes.string.isRequired,
}

export default AddInsuredMemberCard
