// Package Imports
import moment from "moment";

// Project Imports
import AuthStore from "modules/login/store/AuthStore";
import axios from "utils/axios";
import * as APITypes from "constants/API"
import fetchDeselectedExpenses from "utils/fetchDeselectedExpenses";
import fetchDeselectedExpensesForEmi from "utils/fetchDeselectedExpensesForEmi";
import OptimizationStore from "modules/optimization/store/OptimizationStore"
import chooseNeedUtils from "../choose-needs/utils";
import ChooseNeedsStore from "modules/choose-needs/store/ChooseNeedsStore";

const toggleDisableOptions = (formController, sectionName, fieldName, isDisabled) => {
  formController.$(sectionName).$(fieldName).set('disabled', isDisabled)
  if (formController.$(sectionName).$(fieldName).disabled) {
    formController.$(sectionName).$(fieldName).value = {}
    formController.$(sectionName).$(fieldName).resetValidation();
  }
}

const saveProtectionCalculator = async (dataToBeSubmitted,chooseNeedFormController) => {

  const {family_member_id,dob,monthly_income} = dataToBeSubmitted
  OptimizationStore.setFamilyMemberDetails(family_member_id,dob,monthly_income)
  var response = await axios.post(APITypes.PROTECTION_CALCULATOR, dataToBeSubmitted);

  const retirementTargetAmount = chooseNeedFormController?.get().save_for_retirement.fields.target_amount_value.value

  let currentLocation = window.location.pathname
  
    if(retirementTargetAmount !== '' && currentLocation.includes("choose-needs")){
      const {current_life_cover,dob,expense_list,family_member_id,journey_id} = dataToBeSubmitted
      
      const requestBodyData = {current_life_cover,dob,expense_list,family_member_id,journey_id,monthly_income:0}
      
          let {data: {lumpsum}} = await axios.post(APITypes.RETIREMENT_CALCULATOR, requestBodyData);
          chooseNeedUtils.updateTargetAmountValue(chooseNeedFormController, "save_for_retirement", lumpsum)

          chooseNeedFormController.$("save_for_retirement.is_value_updated").set("value",true)
          chooseNeedFormController.$("protection_for_my_family.is_value_updated").set("value",false)
    }
  
  OptimizationStore.setProtectionCalculatorReqestObject(dataToBeSubmitted)
  
  return response
}

const graphDataFunc = (monthlyIncome, monthlyExpense, monthlyEmi, formController) => {

  let yearlyIncomeTotal = monthlyIncome;
  let expenseTotal = 0;
  let emiTotal = 0;

  if (Object.keys(monthlyExpense).length === 0) {
    return;
  } else {
    for (const selectedKey in monthlyExpense) {

      if (monthlyExpense[selectedKey].value) {
        expenseTotal = expenseTotal + (parseInt(monthlyExpense[selectedKey].value));
      }
    }
  }

  if (Object.keys(monthlyEmi).length === 0) {
    return;
  } else {
    for (const selectedKey in monthlyEmi) {

      let isDisabled = formController.$("monthly_emi").$(selectedKey).disabled

      if (!isDisabled) {
        let currentEmi = parseInt(monthlyEmi[selectedKey].option_range.value);
        let paidYrs = monthlyEmi[selectedKey].paid_years.value;
        let totalYrs = monthlyEmi[selectedKey].total_years.value;
        let outStandingEmi = (currentEmi * totalYrs) - (currentEmi * paidYrs);
        emiTotal = emiTotal + currentEmi;
      }
    }
  }

  return {
    data: [
      {
        name: "Self",
        incomeLabel: "Income",
        income: yearlyIncomeTotal,
        expensesLabel: "Expenses",
        expense: -expenseTotal,
        emisLabel: "EMIs",
        emis: -emiTotal,
      },
    ],
  }

}

const changeFieldValue = (formController, sectionName, fieldName, value) => {
  formController.$(sectionName).$(fieldName).value = value
}

const submitCalculatorValue = (formController, setGraphData, setCalculatedData, setShowCalculatedValue, setErrorClass,chooseNeedFormController) => {

  formController.validate({ showErrors: true }).then(async ({ isValid }) => {
   
    let occupation = null
    let education = null
    let location = null
    if (window.location.pathname.includes("customization")) {
      let needFormController = OptimizationStore.needFormController.get()
      education = needFormController.education.value.id;
      occupation =  needFormController.occupation.value.id;
      location = needFormController.location.value[0].id
    }

    let monthly_expense_types = formController.errors().monthly_expense;
      Object.keys(monthly_expense_types).map((ExpenseName, i) => {
      monthly_expense_types[ExpenseName] == null ? setErrorClass("") : setErrorClass("text-danger") 
    })

    if (isValid) {

      let monthleExpenseDeSelectedList = fetchDeselectedExpenses(formController, 'monthly_expense')
      let monthleSavingsDeSelectedList = fetchDeselectedExpenses(formController, 'monthly_savings')
      let currentSavingsDeSelectedList = fetchDeselectedExpenses(formController, 'current_savings')
      let emiDeSelectedList = fetchDeselectedExpensesForEmi(formController, 'monthly_emi')

      let deSelectedExpenseList = [
        ...monthleExpenseDeSelectedList,
        ...monthleSavingsDeSelectedList,
        ...currentSavingsDeSelectedList
      ]

      let expenseList = [];
      let emiList = [];

      const {
        monthly_income,
        date_of_birth: dob,
        current_life_cover,
        monthly_expense: monthlyExpense,
        monthly_emi: monthlyEmi,
        monthly_savings: monthlySaving,
        current_savings: currentSaving,
        journey_id: journeyId,
        family_member_id: familymemberId
      } = formController.values();

      let totalExpenseData = [monthlyExpense, monthlyEmi, monthlySaving, currentSaving];

      let monthlyEmiObject = { ...monthlyEmi };

      for (const selectedKey in monthlyEmiObject) {
        let objPrefix = monthlyEmiObject[selectedKey];
        let isDisabled = formController.$("monthly_emi").$(selectedKey).disabled

        if (!isDisabled) {
          emiList.push({
            action: "save",
            id: objPrefix.option_range.id,
            expense_range_id: objPrefix.option_range.id,
            expense_range_key: objPrefix.option_range.label,
            expense_range_value: objPrefix.option_range.label,
            paid_years: objPrefix.paid_years.value,
            total_years: objPrefix.total_years.value,
            user_journey: journeyId,
          });
        }
      }

      const graphvalue = graphDataFunc(monthly_income, monthlyExpense, monthlyEmi, formController);
      setGraphData(graphvalue);

      totalExpenseData.forEach((obj) => {
        if (Object.keys(obj).length === 0) {
          return;
        }

        for (const selectedKey in obj) {
          const { id, label } = obj[selectedKey];

          if (id && label) {
            expenseList.push({
              action: "save",
              id: obj[selectedKey].id,
              expense_range_id: obj[selectedKey].id,
              expense_range_key: obj[selectedKey].label,
              expense_range_value: obj[selectedKey].label,
              user_journey: journeyId,
            });
          }
        }

      });

      let data = {
        monthly_income: monthly_income,
        dob: moment(dob).format("YYYY-MM-DD"),
        current_life_cover: current_life_cover.id,
        expense_list: [...expenseList],
        emi_list: [...emiList],
        journey_id: journeyId,
        family_member_id: familymemberId,
        occupation: occupation,
        education: education,
        location: location
      };

      let deselectExpensesData = deSelectedExpenseList.map(item => item.journeyExpenseId)

      await axios.post(
        APITypes.DESELECT_EXPENSE,
        {
          "family_member_id": familymemberId,
          "id": deselectExpensesData
        }
      )

      await axios.post(
        APITypes.DESELECT_EMI,
        {
          "family_member_id": familymemberId,
          "id": emiDeSelectedList
        }
      )

      ChooseNeedsStore.setProtectionCalculatorReqObj(data)
      
      const response = await saveProtectionCalculator(data,chooseNeedFormController);

      if (response.status == 'error') {
        return
      } else {
        AuthStore.fetchJourneyData()
        setCalculatedData(response.data)
        setShowCalculatedValue(true);
      }

    } else {
      console.log("Error is ", formController.errors())
    }
  });

}

export { toggleDisableOptions, changeFieldValue, submitCalculatorValue };