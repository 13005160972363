import calculateRiderPremium from 'utils/calculateRiderPremium';
import OptimizationStore from '../../../store/OptimizationStore';
import * as keys from './keys';
import Validator from './validator';

import * as Constants from "constants/Constants"

class Utils {
  getCoverAmountList = () => {
    let list = [];
    for (let index = 1; index <= 100; index++) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + " L",
        });
      } else {
        list.push({
          id: index,
          label: 1 + " Cr.",
        });
      }
    }
    return list;
  };

  getCoverAmountListForCLRnHCB = () => {
    let list = [];
    for (let index = 1; index <= 50; index++) {
      list.push({
        id: index == 0 ? 1 : index,
        label: (index == 0 ? 1 : index) + " L",
      });
    }
    return list;
  };


  updateBi = (key, value, productId, formRef, productData) => {

    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request

    switch (key) {
      case keys.PAYOUT_OPTION:
        biRequest[keys.PAYOUT_OPTION] = value
        break;

      case keys.FREQUENCY:
        let premiumAmount = 0;
        let amount = Number(value.premium);
        switch (Number(value.frequency)) {
          case 1:
            premiumAmount = '' + amount * 1
            break;
          case 2:
            premiumAmount = '' + amount * 2
            break;
          case 3:
            premiumAmount = '' + amount * 4
            break;
          case 4:
            premiumAmount = '' + amount * 12
            break;
        }

        biRequest[keys.PREMIUM] = premiumAmount
        biRequest[keys.FREQUENCY] = value.frequency
        break;

      case keys.PR_PT:
        biRequest[keys.PR_PT] = value
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value
        break;

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value
        break

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = value;
        } else {
          biRequest[keys.ADB_RIDER] = '';
        }
        break;

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = value;
        } else {
          biRequest[keys.ATPD_RIDER] = '';
        }
        break;

      case keys.Critical_ILLNESS_RIDER:
        if (value) {
          biRequest[keys.Critical_ILLNESS_RIDER] = value;
        } else {
          biRequest[keys.Critical_ILLNESS_RIDER] = '';
        }
        break;

      case keys.INCOME_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.INCOME_BENEFIT_RIDER] = value
        } else {
          biRequest[keys.INCOME_BENEFIT_RIDER] = '';
        }
        break;

      case keys.PWB_RIDER:
        if (value) {
          biRequest[keys.PWB_RIDER] = '1'
          biRequest.PWB = '' + value
        } else {
          biRequest[keys.PWB_RIDER] = '';
        }
        break;

    }
    // OptimizationStore.updateBiRequest(productId, biRequest, key, value, productData)
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value);
    // let errorMessage = Validator.valid(formRef, biData)

    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value)
  }

  getTotalPrice = (data) => {
    try {
      return Math.round(data.PREM_DISPLAY)
    } catch (e) {
      return 0
    }
  }

  getRiderTax = (data) => {
    let totalRider = 0;
    data?.parsedResponse?.inputValidationStatus?.map((item) => {
      totalRider += Number(item.Tax)
    })
    return totalRider;
  }

  getIncomeBenefit = () => {
    let initialValue = 75000;
    let values = []

    for (let i = 1; i <= 8; i++) {
      values.push({ id: i * initialValue, label: i * initialValue })
    }

    return values;
  }

  // fetchPolicyTimeline(element, frequency) {
  //   let amount = Math.round(element.MODAL_PREM_TAX);
  //   let premiumAmount = ''
  //   switch (frequency.Value) {
  //     case '1':
  //       premiumAmount = '' + amount * 1
  //       break;
  //     case '2':
  //       premiumAmount = '' + amount * 2
  //       break;
  //     case '3':
  //       premiumAmount = '' + amount * 4
  //       break;
  //     case '4':
  //       premiumAmount = '' + amount * 12
  //       break;
  //   }
  //   let data = {
  //     youPay: Math.round(premiumAmount),
  //     youGet: Math.round(element.TOTAL_BEN),
  //     lifeCover: Math.round(element.DB_G),
  //     isMilestone: Math.round(element.TOTAL_BEN) !== 0,
  //     premiumAmt: Math.round(element.ANN_PREM_YEARLY)
  //   }
  //   return data
  // }

  newPolicyTimeLineData(element,inputString, index, finalYearIndex) {

    const {MODAL_PREM,TOTAL_BEN,SA,DB_G, SA_D, ANN_PREM_YEARLY} = element

    let premiumAmount = 0;

     let inputMode = parseInt(inputString.find(item => item.Key === '@INPUT_MODE').Value)

    //  if (inputMode === 1) {
    //    premiumAmount = Math.round(MODAL_PREM) * Constants.ANNUAL_MODAL_FACTOR;
    //  } else if (inputMode === 2) {
    //    premiumAmount = Math.round(MODAL_PREM) * Constants.SEMI_ANNUAL_MODAL_FACTOR;
    //  } else if (inputMode === 3) {
    //    premiumAmount = Math.round(MODAL_PREM) * Constants.QUATERLY_MODAL_FACTOR;
    //  } else if (inputMode === 4) {
    //    premiumAmount = Math.round(MODAL_PREM) * Constants.MONTHLY_MODAL_FACTOR;
    //  }
   
    let data = {
      youPay:Math.round(ANN_PREM_YEARLY),
      youGet: index == finalYearIndex ?  Math.round(TOTAL_BEN) : 0,
      lifeCover:Math.round(SA_D)
    }

    return data
  }

}

export default new Utils();
