import React, { Component } from 'react'
import { Modal, Card, Row, Col } from 'react-bootstrap'

import PropTypes from 'prop-types'

import './consolidated-premium-amount.css'

class ConsolidatedPremiumAmount extends Component {

    constructor(props) {
        super(props)

        this.state = {

        }
    }

    render() {
        let { showModal, onHide, needDataList, totalPremium, totalTax } = this.props
        return (
            <Modal
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                className='consolidated-premium-amount-modal'
                show={showModal}
                onHide={onHide}
            >
                <Modal.Header closeButton className='border-0 pb-0'></Modal.Header>
                <Modal.Body className='px-3 pt-0'>
                    <div>
                        {
                            needDataList.map((item, index) => 
                                <Row className='px-3 mt-3' key={index}>
                                    <Col className="col-12 px-0">
                                        <span className='font-weight-medium font-size-26 text-primary-title-label-color'>{item.need_name}</span>
                                    </Col> 
                                    {
                                        item.family_member.map((subitem, index) => 
                                            <Col className="col-12 border p-2 mt-2" key={index}>
                                                <div className="d-flex flex-row">
                                                    <span className='font-weight-medium font-size-20 text-secondary ml-2'>{subitem.name}</span>
                                                    <span className='font-weight-medium font-size-20 text-secondary'>({subitem.type})</span>
                                                </div>
                                                <div className="d-flex">
                                                    <div className="col-6 d-flex flex-column">
                                                        <span className='font-weight-normal font-size-18 text-description-text-color'>Premium</span>
                                                        <span className='font-weight-medium font-size-18 text-primary-title-label-color'>{subitem.premium}</span>
                                                    </div>
                                                    <div className="col-6 d-flex flex-column">
                                                        <span className='font-weight-normal font-size-18 text-description-text-color'>Tax</span>
                                                        <span className='font-weight-medium font-size-18 text-primary-title-label-color'>{subitem.tax}</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        )
                                    }  
                                </Row>
                            )
                        } 
                        <div className="mt-2 d-flex flex-column">
                            <hr className="w-100"></hr>
                            <span className='font-weight-normal font-size-20 text-description-text-color'>Total</span>
                            <div className="d-flex">
                                <span className='font-weight-medium font-size-20 text-primary-title-label-color'>{totalPremium}</span>
                                <span className='font-weight-medium font-size-20 text-primary-title-label-color mx-2'>+</span>
                                <span className='font-weight-medium font-size-20 text-primary-title-label-color'>{totalTax}</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        )
    }
}

ConsolidatedPremiumAmount.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired
}

export default ConsolidatedPremiumAmount