// React Imports
import React from "react"

// Package Imports
import PropTypes from "prop-types"
import Modal from "react-bootstrap/Modal"
import { observer } from "mobx-react"

// project imports
import ProtectionForFamilyModalForm from "./family-protection-modal-form"

// CSS Imports
import "./add-insured-protection-pop-up-modal.css"

const AddInsuredProtectionPopUpModal = (props) => {

  return (
    <div className="add-insured-pop-up-div d-flex align-items-center">
      <Modal
        show={props.showProtectionForFamilyModal}
        backdrop="static"
        as="section"
        className="add-insured-pop-up-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <ProtectionForFamilyModalForm
          hideProtectionModal={props.hideProtectionModal}
          openProtectionCalculator={props.openProtectionCalculator}
          getFamilyMemberType={props.getFamilyMemberType}
        />
      </Modal>
    </div>
  )
}

AddInsuredProtectionPopUpModal.propTypes = {
  hideProtectionModal:PropTypes.func.isRequired,
  openProtectionCalculator:PropTypes.func.isRequired
}

export default observer(AddInsuredProtectionPopUpModal)
