import * as keys from "./keys";
import Utils from "./utils";
import ConstUtils from '../../../../../constants/utils';

const initialDefaults = (formRef, data) => {
  let productData = data.data;
  let biRequest = productData.bi_request;
  let frequency = biRequest[keys.FREQUENCY].toString();
  let death_benefit_option = biRequest[keys.DEATH_BENEFIT_OPTION].toString();
  let premium = biRequest[keys.PREMIUM] == 0 ? productData?.firstYearResponse?.MODAL_PREM : biRequest[keys.PREMIUM]

  formRef.$('premium').value = premium;

  formRef.$("policy-term").set("value", {
    id: biRequest.PR_PT,
    label: biRequest.PR_PT,
  });

  switch (biRequest.PR_PPT) {
    case "1":
      formRef.$("premium-term").set("value", {
        id: 1,
        label: 1,
      });
      break;
    default:
      formRef.$("premium-term").set("value", {
        id: 1,
        label: 1,
      });
      break;
  }

  switch (frequency) {
    case "5":
      formRef.$("payment-frequency").set("value", {
        id: "5",
        label: "Single",
      });
      break;
    default:
      formRef.$("payment-frequency").set("value", {
        id: "5",
        label: "Single",
      });
      break;
  }

  switch (death_benefit_option) {
    case "1":
      formRef.$("death-benefit-option").set("value", {
        id: "1",
        label: "Option A",
      });
      break;

    case "2":
      formRef.$("death-benefit-option").set("value", {
        id: "2",
        label: "Option B",
      });
      break;
    default:
      break;
  }

  if (biRequest.ADBRider) {
    formRef.$('ADB').set('value', biRequest.ADBRider !== '')
    if (biRequest.ADBRider) {
      formRef.$('ADB-dropdown').set('value', biRequest.ADBRider)
    }
  }
  if (biRequest.ATPDRider) {
    formRef.$('ATPDRider').set('value', biRequest.ATPDRider !== '')
    if (biRequest.ATPDRider) {
      formRef.$('ATPDRider-dropdown').set('value', biRequest.ATPDRider)
    }
  }
  if (biRequest.IncomeBenefitRider) {
    formRef.$('IncomeBenefitRider').set('value', biRequest.IncomeBenefitRider !== '')
    if (biRequest.IncomeBenefitRider) {
      formRef.$('IncomeBenefitRider-dropdown').set('value', biRequest.IncomeBenefitRider)
    }
  }
  if (biRequest.CriticalIllnessRider) {
    formRef.$('CriticalIllnessRider').set('value', biRequest.CriticalIllnessRider !== '')
    if (biRequest.CriticalIllnessRider) {
      formRef.$('CriticalIllnessRider-dropdown').set('value', biRequest.CriticalIllnessRider)
    }
  }
};

export default initialDefaults;
