import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from "./validator";

class Utils {
  generateSimplyProtectPT() {
    let pt = [];
    for (let i = 10; i <= 40; i++) {
      pt.push({ id: i, label: "" + i });
    }
    pt.push({ id: "80 - @Li_entry_age", label: "upto age 80" });
    return pt;
  }

  calculatePPT() {
    let ppt = [1, 5]
    let list = []

    ppt.forEach((item) => {
      list.push({ id: item, label: "" + item });
    })

    for (let i = 10; i <= 40; i++) {
      list.push({ id: i, label: "" + i });
    }

    list.push({ id: "80 - @Li_entry_age", label: "upto age 80" });
    return list
  }


  updateBi(key, value, productId, formRef, productData) {

    let biData = OptimizationStore.fetchBiRequest(productId)
    let biRequest = biData.data.bi_request
    switch (key) {
      case keys.LI_SMOKE:
        biRequest.LI_SMOKE = value;
        break;

      case keys.PAYOUT_MODE:
        biRequest.SumAssuredpayoutoption = value;
        break;

      case keys.PLAN_OPTION:
        biRequest.PlanOption = value;
        break;

      case keys.PR_PT:
        biRequest.PR_PT = value;
        biRequest.PR_PPT = value;
        break;

      case keys.PR_PPT:
        biRequest.PR_PPT = value;
        break;

      case keys.INPUT_MODE:
        biRequest.INPUT_MODE = value;
        break;

      case keys.SumAssuredpayoutoption:
        biRequest.SumAssuredpayoutoption = value;
        break;

      case keys.PR_ANNPREM:
        biRequest['PR_ANNPREM'] = value;
        break;

      default:
        return;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return
    // }

  }

  getTotalPrice = (biResponse) => {
    try {
      return Number(biResponse.MODAL_PREM_TAX)
    } catch (e) {
      return 0;
    }
  }

  fetchPolicyTimeline(element, payoutModeSP) {
    let data = {
      youPay: Math.round(element.MODAL_PREM_TAX),
      youGet: 0,
      lifeCover: payoutModeSP && payoutModeSP.Value == 'Lumpsum' ? Math.round(element.DB_G) : Math.round(element.TOTAL_BEN),
      isMilestone: false,
      premiumAmt: Math.round(element.TOTAL_PREM),
    };
    return data;
  }
}

export default new Utils();

