// Project Imports
import axios from "utils/axios"
import * as APITypes from "constants/API"
import isObjectEmpty from "utils/isObjectEmpty"
import Utils from "./utils"
import createNewObject from "utils/createNewObject"
import CustomerCommunicationFieldStructureObject from "./CustomerCommunicationFieldStructureObject"
import FamilyDetailsStore from "modules/family-details-form/store/FamilyDetailsStore"
import fetchSalutationMaster from 'utils/fetchSalutationMaster'
import SolutionStore from "modules/solution/store/SolutionStore"
import { toJS } from "mobx"

const initialDefaults = async (formController, journeyData, journeyId) => {
  
  const location = window.location.pathname;

  let proposerIdList = toJS(SolutionStore.proposerIdList)
  let familyDetailList = journeyData.family_details_list


  if(location.includes('customization') || location.includes('solution')){
    familyDetailList = familyDetailList.filter(item => proposerIdList.includes(item.id))
  }


  let saltutionMasterList = FamilyDetailsStore.salutationList.length === 0 ?  await fetchSalutationMaster() : FamilyDetailsStore.salutationList

  const fetchSalutionLabel = (salutationID) => {

    let salutationLabel = saltutionMasterList.find(singleSalution => singleSalution.id === salutationID)

    if(!salutationLabel || isObjectEmpty(salutationLabel)) return " "

    return salutationLabel

  }

  const memberList = familyDetailList?.map((singleMember) => {
    let memberFieldObject = createNewObject(
      CustomerCommunicationFieldStructureObject
    )

    memberFieldObject.label = singleMember.family_member_type.split('_').join(" ")
    memberFieldObject.name = singleMember.family_member_type

    memberFieldObject.fields.forEach((singleField) => {

      switch (singleField.name) {
        case "id":
          singleField.value = singleMember.id
          break

        case "salutation":
          singleField.extra.optionList = saltutionMasterList
          singleField.value = fetchSalutionLabel(singleMember.salutation)
          break

        case "first_name":
          singleField.value = singleMember.first_name

          break

        case "middle_name":
          singleField.value = singleMember.middle_name
          break

        case "last_name":
          singleField.value = singleMember.last_name
          break

        case "mobile_number_code":  
          singleField.value = singleMember.mobile_country_code || +91
          break

        case "mobile_number":
          singleField.value = singleMember.mobile_number
          break;

        case "email":
          singleField.value = singleMember.email
          break;

        case "same_as_mobile_number":  
          singleField.value = singleMember.whatsapp_same_as_mobile || false
        break;

        case "whatsapp_mobile_number_code":  
        singleField.value = singleMember.whatsapp_country_code || +91
        break  

        case "whatsapp_mobile_number":
          singleField.value = singleMember.whatsapp_number
          break;


        default:
          break
      }
    })

    return createNewObject(memberFieldObject)
  })

  memberList?.forEach(item => formController.add(item))

}

export default initialDefaults
