// Ract Imports
import React from "react";

// Package Imports
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { observer } from "mobx-react";
import { ToastContainer, toast } from "react-toastify";

// Project Imports
import NewPassword from "./login/components/NewPassword";
import Dashboard from "./dashboard/components/Dashboard";
import Temp from "./temp";
import Login from "./login/components/Login";
import AuthStore from "../modules/login/store/AuthStore";
import Styleguide from "./styleguide/Styleguide";
import { ErrorModal, CustomizationModal } from "../components";
import HeaderComponent from "./HeaderComponent";
import StorageKeys from "constants/StorageKeys";
import * as Constants from "constants/Constants";

// CSS Imports
require("normalize.css/normalize.css");
require("styles/typo.css");
require("styles/App.css");
require("styles/common-colors.css");
import "react-toastify/dist/ReactToastify.css";
import QmsDashboard from "./dashboard/components/QmsDashboard";
import QmsHeaderComponent from "./QmsHeaderComponent";

const history = createBrowserHistory();

const AppComponent = observer(
  class AppComponent extends React.Component {
    constructor() {
      super();
      this.state = { isLoading: false };
      global.toINR = (value = "", isRound = true) => {
        return (
          <span>
            {" "}
            <span className='rupee-sign hide-rupee-sign'>&#8377;</span> {isRound ? this.getRoundValue(value).toLocaleString("en-IN") : value && value.toLocaleString("en-IN")}
          </span>
        );
      };
      global.toINRForSlider = (value = "", isRound = true) => {
        return (
          <span className='font-size-12'>
            {" "}
            <span className='rupee-sign hide-rupee-sign'>&#8377;</span> {isRound ? this.getRoundValue(value).toLocaleString("en-IN") : value && value.toLocaleString("en-IN")}
          </span>
        );
      };
      global.getRoundValue = this.getRoundValue;
      global.showToast = (toastTitle = "", toastMsg = "") => {
        if (toastTitle == "success") {
          toast.success(toastMsg);
        } else if (toastTitle == "danger") {
          toast.error(toastMsg);
        } else {
          toast.warn(toastTitle);
        }
      };
      global.isLoading = () => this.state.isLoading;
      global.loading = (isLoading) => { this.setState({}); };
      global.mainLoading = (isLoading) => {
        this.setState({ isLoading });
      };
    }
    async componentDidMount() {
      const urlParams = new URLSearchParams(location.search);
      const authToken = urlParams.get("token");
      // let isTokenExpired = await AuthStore.authentication.checkIfTokenExpired(authToken);

      // if(isTokenExpired){
      // Session Expired
      // TODO show expired session popup and redirect back to pragati.
      // }else{
      // Session is valid proceed ahead
      if (authToken !== undefined && authToken !== null) {
        await localStorage.setItem(StorageKeys.AUTH_TOKEN, authToken);
      }
      await AuthStore.authentication.authenticate();
      await AuthStore.authentication.refreshToken();
      // }
    }

    getRoundValue = (value) => Number.parseInt(value) || "0";

    browserHistory = () => {};

    render() {
      if (AuthStore.isUserLogin == null) {
        return <div>loading....</div>;
      }
      // Note : Set isUserLogin value dynamically when login flow working properly
      // const isUserLogin = AuthStore.isUserLogin;

      const isUserLogin = true;
      const { isLoading } = this.state;
      let currentLocation = window.location.pathname

      return (
        <div>
          {isLoading && !currentLocation.includes("customization") && !currentLocation.includes("quotation-preview") && (
            <div className='loader-wrapper'>
              <div className='loader'></div>
            </div>
          )}
          {currentLocation.includes("customization") &&
            <CustomizationModal
              show={isLoading}
              loaderMsg={Constants.CUSTOMIZATION_LOADER_MSG}
              redirectPath="family-details"
            />
          }
          {currentLocation.includes("quotation-preview") &&
            <CustomizationModal
              show={isLoading}
              loaderMsg={Constants.CUSTOMIZATION_LOADER_MSG}
              redirectPath="solution"
            />
          }
          <Router history={history} onUpdate={() => { }}>
            <Switch>
              <AuthRoute exact path='/qms/:selectedPage/:journeyId?' component={QmsHeaderComponent} isUserLogin={isUserLogin} />
              <AuthRoute exact path='/qms' component={QmsDashboard} isUserLogin={isUserLogin} />
              <Route exact path='/newpassword' component={NewPassword} />
              <NoAuthRoute exact path='/temp' component={Temp} />
              <NoAuthRoute exact path='/styleguide' component={Styleguide} />
              <NoAuthRoute exact path='/login' component={Login} isUserLogin={isUserLogin} />
              <NoAuthRoute exact path='/' component={Login} isUserLogin={isUserLogin} />
              <AuthRoute exact path='/:selectedPage/:journeyId?' component={HeaderComponent} isUserLogin={isUserLogin} />
              <AuthRoute exact path='/' component={HeaderComponent} isUserLogin={isUserLogin} />
              <AuthRoute exact path='/' component={Dashboard} isUserLogin={isUserLogin} />
            </Switch>
          </Router>
          <ToastContainer position={toast.POSITION.TOP_RIGHT} />
          <div>
            <form className='d-none' id='redirect-to-pragati-on-exit' action={localStorage.getItem(StorageKeys.PRAGATI_HOME_URL)} method={"POST"}>
              <input type='text' name='Pragati_Token' value={localStorage.getItem(StorageKeys.AUTH_TOKEN)} />
              <input type='text' name='LoginCode' value={localStorage.getItem(StorageKeys.LOGIN_CODE)} />
              <input type='text' name='Vendor' value={localStorage.getItem(StorageKeys.VENDOR)} />
              <input type='text' name='SourceFrom' value={localStorage.getItem(StorageKeys.SOURCE_FROM)} />
              <input type='text' name='LoginAgentCode' value={localStorage.getItem(StorageKeys.LOGIN_AGENT_CODE)} />
              <button type='submit'>Submit</button>
            </form>
          </div>
        </div>
      );
    }
  }
);

function AuthRoute({ component: Component, isUserLogin, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isUserLogin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

function NoAuthRoute({ component: Component, isUserLogin, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        !isUserLogin ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/choose-needs",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

const InvalidJourneyModal = (props) => {
  const closeModal = async () => {
    AuthStore.closeInvalidJourneyIdModal();
    if (AuthStore.axiosMsg.toLowerCase() == "not found") {
      await props.history.push("/choose-needs");
    }
  };

  return <ErrorModal onHideModal={closeModal} showModal={AuthStore.isInvalidJourneyId} title={AuthStore.axiosMsg} hideResumeSession={true} />;
};

AppComponent.defaultProps = {};

export default AppComponent;
