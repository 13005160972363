import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from "./validator";
import calculateRiderPremium from "utils/calculateRiderPremium";
import calculateProductPremiumRelatedValues from "utils/calculateProductPremiumRelatedValues";
class Utils {
  getPremiumTermList = (pt, age) => {
    let premiumTermList = [
      {
        id: 5,
        label: "5 Years",
      },
      {
        id: 8,
        label: "8 Years",
      },
      {
        id: 10,
        label: "10 Years",
      },
      {
        id: 12,
        label: "12 Years",
      },
    ];
    return premiumTermList;
  };

  getTotalPrice = (biResponse, biRequest) => {
    try {
      return Number(biResponse?.TOTAL_PREM_TAX);
    } catch (e) {
      return 0;
    }
  };

  getRiderTax = (data) => {
    let totalRider = 0;
    data?.parsedResponse?.inputValidationStatus?.map((item) => {
      totalRider += Number(item.Tax);
    });
    return totalRider;
  };

  updateBi = (key, value, productId, formRef, productData) => {
    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request;

    switch (key) {
      case keys.FAMILY_INCOME_BENEFIT:
        biRequest[keys.FAMILY_INCOME_BENEFIT] = value;
        break;

      case keys.INCOME_OPTION:
        biRequest[keys.INCOME_OPTION] = value.incomeOption;
        biRequest[keys.GURANTEED_INCOME] = value.guranteedIncome;
        break;

      case keys.GURANTEED_INCOME:
        biRequest[keys.GURANTEED_INCOME] = value
        break;

      case keys.FREQUENCY:
        // let premiumAmount = 0;
        let premiumAmount = value.premium;
        // switch (Number(value.frequency)) {
        //   case 1:
        //     premiumAmount = "" + amount * 1;
        //     break;
        //   case 2:
        //     premiumAmount = "" + amount * 2;
        //     break;
        //   case 3:
        //     premiumAmount = "" + amount * 4;
        //     break;
        //   case 4:
        //     premiumAmount = "" + amount * 12;
        //     break;
        // }

        // biRequest[keys.PREMIUM] = premiumAmount;
        biRequest[keys.FREQUENCY] = value.frequency;
        break;

      // case keys.PREMIUM:
      //   biRequest[keys.PREMIUM] = value;
      //   break;

      case keys.MATURITY_AGE:
        biRequest[keys.MATURITY_AGE] = value;
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value;
        break;

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = value;
        } else {
          biRequest[keys.ADB_RIDER] = "";
        }
        break;

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = value;
        } else {
          biRequest[keys.ATPD_RIDER] = "";
        }
        break;

      case keys.Critical_ILLNESS_RIDER:
        if (value) {
          biRequest[keys.Critical_ILLNESS_RIDER] = value;
        } else {
          biRequest[keys.Critical_ILLNESS_RIDER] = "";
        }
        break;

      case keys.INCOME_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.INCOME_BENEFIT_RIDER] = value;
        } else {
          biRequest[keys.INCOME_BENEFIT_RIDER] = "";
        }
        break;

      case keys.PWB_RIDER:
        if (value) {
          biRequest[keys.PWB_RIDER] = "1";
          biRequest.PWB = "" + value;
        } else {
          biRequest[keys.PWB_RIDER] = "";
        }
        break;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value);
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return;
    // }
  };

  getAdbList = () => {
    let list = [];
    for (let index = 0; index <= 100; index = index + 5) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + " L",
        });
      } else {
        list.push({
          id: index,
          label: 1 + " Cr.",
        });
      }
    }
    return list;
  };

  getAtpdList = () => {
    return this.benefitAmountList(100000, 1000000);
  };

  getCriticalIllnessList = () => {
    return this.benefitAmountList(100000, 500000);
  };

  getIncomeBenefit = () => {
    let initialValue = 75000;
    let values = [];

    for (let i = 1; i <= 8; i++) {
      values.push({ id: i * initialValue, label: i * initialValue });
    }

    return values;
  };

  benefitAmountList = (min, max) => {
    let values = [];

    max = Math.round(max / 100000);
    for (let i = 1; i <= max; i++) {
      values.push({ id: i, label: i + " L" });
    }
    return values;
  };

  // fetchPolicyTimeline(element, frequencyAIP, amountAIP) {
  //   let premiumAmountAIP = ''
  //   switch (frequencyAIP.Value) {
  //     case '1':
  //       premiumAmountAIP = '' + amountAIP * 1
  //       break;
  //     case '2':
  //       premiumAmountAIP = '' + amountAIP * 2
  //       break;
  //     case '3':
  //       premiumAmountAIP = '' + amountAIP * 4
  //       break;
  //     case '4':
  //       premiumAmountAIP = '' + amountAIP * 12
  //       break;
  //   }
  //   let data = {
  //     youPay: Math.round(premiumAmountAIP),
  //     youGet: Math.round(element.TOTAL_SB_G_BS_2) + Math.round(element.TOTAL_MB_G_BS_2),
  //     lifeCover: Math.round(element.TOTAL_DB_G_BS_2),
  //     isMilestone: Math.round(element.TOTAL_MB_G_BS_2) !== 0,
  //     premiumAmt: Math.round(element.ANN_PREM),
  //   };
  //   return data;
  // }

  newPolicyTimeLineData(element, inputString, index) {
    const { SUM_BS_2, DB_G, TOTAL_MB_G_BS_2, ANN_PREM_YEARLY, TOTAL_SB_G_BS_2, TOTAL_DB_G_BS_2 } = element;

    let data = {
      youPay: Math.round(ANN_PREM_YEARLY),
      youGet: Math.round(TOTAL_SB_G_BS_2) + Math.round(TOTAL_MB_G_BS_2),
      lifeCover: Math.round(TOTAL_DB_G_BS_2),
    };

    return data;
  }
}

export default new Utils();
