import React, { Component } from 'react'
import { observer } from 'mobx-react';
import * as Constants from '../../constants/Constants'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { FloatingInputComponent, CustomCheckbox, Button as UiButton, BottomsheetDropdown } from '../../components'
import AuthStore from '../login/store/AuthStore';
import ShareQuotesStore from './store/index';

import './share-quote.css'

const ShareQuote = observer(class ShareQuote extends Component {
  constructor(props) {
    super(props)

    this.state = {
      inputValue: '',
      fieldActive: false,
      email: '',
      sameAsAbove: true,
      countryList: [
        { id: 1, label: 'India', icon: images.IndiaFlagImg, value: '91', isSelected: true },
        { id: 2, label: 'Australia', icon: images.AustraliaFlagImg, value: '61', isSelected: false },
        { id: 3, label: 'Austria', icon: images.AustriaFlagImg, value: '43', isSelected: false }
      ],
      mobileNoSelectedCountry: { id: 1, label: 'India', icon: images.IndiaFlagImg, value: '91', isSelected: true },
      whatsappNoSelectedCountry: { id: 1, label: 'India', icon: images.IndiaFlagImg, value: '91', isSelected: true },
    }

  }


  onSameAbove = (value) => {
    if (value) {
      const { mobileNoSelectedCountry } = this.state
      ShareQuotesStore.whatsappNo = ShareQuotesStore.mobileNo;
      this.setState({ whatsappNoSelectedCountry: mobileNoSelectedCountry, sameAsAbove: value })
    } else {
      this.setState({ sameAsAbove: value })
    }
  }

  previewQuota = () => {

  }

  onMobileDropDownOptionClick = (value) => {
    this.setState({ mobileNoSelectedCountry: value })
    const { sameAsAbove } = this.state
    if (sameAsAbove) {
      this.setState({ whatsappNoSelectedCountry: value })
    }
  }

  onWhatsappDropDownOptionClick = (value) => {
    this.setState({ whatsappNoSelectedCountry: value })
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    ShareQuotesStore.resetForm()
  }

  changeCustName(customerName) {
    ShareQuotesStore.customerName = customerName;
  }
  changeWhatsAppNo(whatsappNo) {
    ShareQuotesStore.whatsappNo = whatsappNo;
  }
  changeEmail(email) {
    ShareQuotesStore.email = email;
  }

  changeMobileNo(mobileNo) {
    {
      if (this.state.sameAsAbove) {
        ShareQuotesStore.mobileNo = mobileNo;
        ShareQuotesStore.whatsappNo = mobileNo;
      } else {
        ShareQuotesStore.mobileNo = mobileNo;
      }
    }

  }

  render() {
    const { countryList, mobileNoSelectedCountry, whatsappNoSelectedCountry } = this.state
    return (
      <Container className='py-3'>
        <Card className='border-0 shadow-sm share-quate-card mx-auto px-md-3'>
          <Card.Body className='py-3 px-3 px-md-5'>
            <h1 className='text-primary-title-label-color text-md-center font-size-32 font-size-md-36 share-quote-title mb-5'>Customer Details</h1>
            <Row>
              <Col xs='12' className='pb-4 mb-md-2'>
                <FloatingInputComponent
                  placeholdertext='Customer Name'
                  inputtype='text'
                  // placeholder='Customer Name'
                  isnumberfield='false'
                  ischarfield='true'
                  textValue={ShareQuotesStore.customerName}
                  onChange={(customerName) => this.changeCustName(customerName)}
                  errormessage={ShareQuotesStore.nameErrorMsg}
                />
              </Col>
              <Col xs='12' className='pb-3 mb-md-2 d-flex'>
                <div className='country-dropdown'>
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey='icon'
                    bottomsheetTitle='Select Country'
                    onSelect={this.onMobileDropDownOptionClick}
                    menuList={countryList}
                    value={mobileNoSelectedCountry}
                    type='icon'
                  />
                </div>
                <div className='country-with-number-container'>
                  <FloatingInputComponent
                    placeholdertext='Phone Number'
                    isnumberfield='true'
                    maxLength='10'
                    ischarfield='false'
                    regexPattern={Constants.NUMBER_REGEX}
                    textValue={ShareQuotesStore.mobileNo}
                    onChange={(mobileNo) => this.changeMobileNo(mobileNo)}
                    errormessage={ShareQuotesStore.mobileNoErrorMsg}

                  // same as
                  // onChange={(value) => this.setState({ mobileNo: value })}
                  />
                </div>
              </Col>
              <Col xs='12' className='pb-3 mb-md-2 text-right'>
                <CustomCheckbox
                  isChecked={this.state.sameAsAbove}
                  id='sameAsAbove'
                  title='Same as above'
                  mark='checkmark'
                  onChange={this.onSameAbove}
                  titleStyle='text-input-placeholder-color-primary font-weight-normal font-size-14 font-size-md-16 pl-2'
                />
              </Col>
              <Col xs='12' className='pb-4 d-flex'>
                <div className='country-dropdown'>
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey='icon'
                    bottomsheetTitle='Select Country'
                    onSelect={this.onWhatsappDropDownOptionClick}
                    menuList={countryList}
                    value={whatsappNoSelectedCountry}
                    type='icon'
                  />
                </div>
                <div className='country-with-number-container'>
                  <FloatingInputComponent
                    placeholdertext='Whatsapp Number'
                    isnumberfield='true'
                    maxLength='10'
                    ischarfield='false'
                    textValue={ShareQuotesStore.whatsappNo}
                    onChange={(whatsappNo) => this.changeWhatsAppNo(whatsappNo)}
                    errormessage={ShareQuotesStore.whatsappNoErrorMsg}
                  />
                </div>
              </Col>
              <Col xs='12' className='pb-4 mb-md-2'>
                <FloatingInputComponent
                  placeholdertext='Email Address'
                  inputtype='email'
                  isnumberfield='false'
                  ischarfield='false'
                  textValue={ShareQuotesStore.email}
                  onChange={(email) => this.changeEmail(email)}
                  errormessage={ShareQuotesStore.emailErrorMsg}
                />
              </Col>
              {/* <Col xs='12' className='mb-md-2 '>
                <div className='share-quote-btn-grp p-3 p-md-0'>
                  <UiButton className='font-size-medium font-size-16 px-2 w-100 mb-md-0' variant='secondary' buttonText='Preview' onClick={this.previewQuota} />
                  <UiButton className='font-size-medium font-size-16 px-2 w-100 mb-md-0 ml-md-3 d-none d-md-inline-block' variant='outline-secondary' buttonText='Cancel' onClick={() => this.props.history.go(-1)} />
                </div>
              </Col> */}

            </Row>
          </Card.Body>
        </Card>
      </Container>
    )
  }
});

export default ShareQuote
