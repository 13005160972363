import Utils from './utils';
import * as keys from './keys';
import ConstUtils from '../../../../../constants/utils';
import ProductConstants from 'constants/ProductConstants';

const initialDefaults = (formRef, data) => {
    let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
    let biResponse = productData.parsedResponse;
    let firstYearResponse = productData.firstYearResponse;
    let biRequest = productData.bi_request;
    let frequency = biRequest.INPUT_MODE
    let guranteedIncome = biRequest.GuaranteedIncomeType
    let incomeOption = biRequest.IncomeOption
    let familyIncomeBenefit = String(biRequest.FamilyIncomeBenefit)
    let wopRider = String(biRequest.WaiverOfPremiumRider)
    let adbRider = String(biRequest[keys.ADB_RIDER])
    let pptList = Utils.getPremiumTermList(biRequest.PR_PT, biRequest.LI_ENTRY_AGE)

    formRef.$('maturity-age').value = {
        id: biRequest.PR_PT,
        label: biRequest.PR_PT && biRequest.PR_PT.split('-@')[0] + ' years'
    }

    formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        optionList: pptList
    })

    formRef.$('premium-term').value = {
        id: biRequest.PR_PPT,
        label: biRequest.PR_PPT + ' Years'
    }


    switch (frequency) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '2':
            formRef.$('payment-frequency').set('value', {
                id: '2',
                label: 'Semi-Annual'
            })
            break;
        case '3':
            formRef.$('payment-frequency').set('value', {
                id: '3',
                label: 'Quarterly'
            })
            break;
        case '4':
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }

    switch (guranteedIncome) {
        case '5':
            formRef.$('guranteed-income').set('value', {
                id: '5',
                label: 'Level'
            })
            break;
        case '6':
            formRef.$('guranteed-income').set('value', {
                id: '6',
                label: 'Increasing'
            })
            break;
        default:
            break;
    }

    switch (incomeOption) {
        case '3':
            formRef.$('income-option').set('value', {
                id: '3',
                label: 'Early Income'
            })
            break;
        case '4':
            formRef.$('income-option').set('value', {
                id: '4',
                label: 'Deferred Income'
            })
            break;
        default:
            break;
    }

    switch (familyIncomeBenefit) {
        case '1':
            formRef.$('family-income').value = true
            break;
        case '2':
            formRef.$('family-income').value = false
            break;
    }

    let premiumAmount = biRequest.PR_ANNPREM == 0 ? firstYearResponse?.MODAL_PREM: biRequest.PR_ANNPREM;

    // switch (frequency) {
    //     case '1':
    //         premiumAmount = biRequest.PR_ANNPREM;
    //         break;
    //     case '2':
    //         premiumAmount = Number(biRequest.PR_ANNPREM) / 2;
    //         break;
    //     case '3':
    //         premiumAmount = Number(biRequest.PR_ANNPREM) / 4;
    //         break;
    //     case '4':
    //         premiumAmount = Number(biRequest.PR_ANNPREM) / 12;
    //         break;

    //     default:
    //         break;
    // }

    formRef.$('premium').set('value', premiumAmount)

    switch (data.productName) {
        case ProductConstants.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
            formRef.$('maturity-age').set('value', {
                id: '99-@li_entry_age',
                label: '99 Years'
            })
            formRef.$('maturity-age').set('disabled', true)
            formRef.$('family-income').set('value', false)
            formRef.$('family-income').set('disabled', true)

            formRef.$('guranteed-income').set('value', {
                id: '5',
                label: 'Level'
            })
            formRef.$('guranteed-income').set('disabled', true)
            break;

        case ProductConstants.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
            formRef.$('maturity-age').set('value', {
                id: '99-@li_entry_age',
                label: '99 Years'
            })
            formRef.$('maturity-age').set('disabled', true)

            formRef.$('guranteed-income').set('value', {
                id: '5',
                label: 'Level'
            })
            formRef.$('guranteed-income').set('disabled', true)
            break;

        case ProductConstants.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
            formRef.$('maturity-age').set('value', {
                id: '99-@li_entry_age',
                label: '99 Years'
            })
            formRef.$('maturity-age').set('disabled', true)
            formRef.$('family-income').set('value', false)
            formRef.$('family-income').set('disabled', true)
            break;

        default:
            break;
    }

    if(Number(incomeOption) === 3) {
        formRef.$('guranteed-income').set('disabled', true)
    } else {
        formRef.$('guranteed-income').set('disabled', false)
    }
    if (biRequest.ADBRider) {
        formRef.$('ADB').set('value', biRequest.ADBRider !== '')
        if (biRequest.ADBRider) {
            formRef.$('ADB-dropdown').set('value', biRequest.ADBRider)
        }
    }
    if (biRequest.ATPDRider) {
        formRef.$('ATPDRider').set('value', biRequest.ATPDRider !== '')
        if (biRequest.ATPDRider) {
            formRef.$('ATPDRider-dropdown').set('value', biRequest.ATPDRider)
        }
    }
    if (biRequest.WaiverOfPremiumRider) {
        formRef.$('WaiverOfPremiumRider').set('value', biRequest.WaiverOfPremiumRider !== '')
    }
    if (biRequest.IncomeBenefitRider) {
        formRef.$('IncomeBenefitRider').set('value', biRequest.IncomeBenefitRider !== '')
        if (biRequest.IncomeBenefitRider) {
            formRef.$('IncomeBenefitRider-dropdown').set('value', biRequest.IncomeBenefitRider)
        }
    }
    if (biRequest.CriticalIllnessRider) {
        formRef.$('CriticalIllnessRider').set('value', biRequest.CriticalIllnessRider !== '')
        if (formRef.$('CriticalIllnessRider').value) {
            formRef.$('CriticalIllnessRider-dropdown').set('value', biRequest.CriticalIllnessRider)
        }
    }
    if (biRequest.PayorWaiverBenefitRider) {
        formRef.$('PayorWaiverBenefitRider').set('value', biRequest.PayorWaiverBenefitRider !== '')
        if (formRef.$('PayorWaiverBenefitRider').value) {
            switch (biRequest.PWB) {
                case '1':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '1',
                        label: 'Option 1 (On Death)'
                    })
                    break;
                case '2':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '2',
                        label: 'Option 2 (CI or ATPD)'
                    })
                    break;
                case '3':
                    formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
                        id: '3',
                        label: 'Option 3 (CI or ATPD or Death)'
                    })
                    break;
                default:
                    break;
            }
        }
    }

}

export default initialDefaults