import React, { Component } from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import { CustomCheckbox, BottomsheetDropdown } from '../../../../../components';
import { InfoCircleIcon } from 'constants/Images';

import './additional-cards.css';


class AdditionalCards extends Component {
  constructor(props) {
    super(props)
  }

  onCheckBoxChange = (checkedValue, selectedObj, isDisabled) => {
    if (!isDisabled) {
      this.props.onClick(checkedValue, selectedObj, this.props.data);
    }
  }

  isBetterHalfEnabled = () => {
    // let isMarried = this.props.data.
    // if ()
  }

  render() {
    const { cardsList, cardTopText, onAmountChange } = this.props;
    // console.log('ADDITIONAL CARDS RENDER >> ', cardsList, (cardsList && cardsList.length > 0))
    return (
      <div className='additional-cards'>
        <p className='mb-3 font-size-16 font-size-md-22 font-size-lg-24 font-weight-bold text-primary-title-label-color card-top-text'>{cardTopText}</p>
        <Card className='border-0 shadow'>
          <Card.Body>
            {(cardsList && cardsList.length > 0) && cardsList.map((value, index) => {
              // console.log('value.checkedValue', value, value.is_selected)
              return (
                <Row key={index} className={`${index > 0 ? 'mt-3' : ''} ${value.isDisabled ? 'disable-card' : ''}`}>
                  <Col className='col-8'>
                    <div className='d-flex align-items-center'>
                      <CustomCheckbox shape='rounded' title={value.title} titleStyle='text-primary-title-label-color font-weight-normal card-top-title font-size-16 font-size-md-20 pl-2' isChecked={value.is_selected} onChange={(checked) => this.onCheckBoxChange(checked, value, value.isDisabled)} mark='checkmark' />
                      <img className={`mb-2 pl-2 ${value.isDisabled ? 'inf0-icon-disable' : ''}`} src={InfoCircleIcon} alt='info' />
                    </div>
                    <p className='mb-0 text-description-text-color font-size-14 font-size-md-16 font-weight-300 sub-left-padding'>
                      {value.description}
                    </p>
                    {(value.is_selected && value.list_data) &&
                      <BottomsheetDropdown
                        dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                        menuItemStyle='d-flex justify-content-between align-items-center'
                        selectedLabelKey='label'
                        bottomsheetTitle=''
                        menuList={value.list_data}
                        value={value.selected_value ? value.selected_value : {}}
                        onSelect={(value) => onAmountChange(value, index)}
                      />}
                  </Col>
                  <Col className='align-items-center col-4 col-border-left d-flex flex-column justify-content-center col-line-height'>
                    {
                      value.sum_assured != '' ? <React.Fragment>
                        <div className='d-flex justify-content-end'>
                          <p className='mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary'> {value.sum_assured == '-' ? value.sum_assured : global.toINR(value.sum_assured)}</p>
                        </div>
                      </React.Fragment> : null
                    }
                  </Col>
                </Row>
              )
            })}
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default AdditionalCards
