const { Form } = require("mobx-react-form");
import moment from "moment";
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";

import Utils from "./utils";
import setInitialDefaults from './initialDefault'
import formObserver from './observers';
import * as keys from "./keys";
import Validator from "validatorjs";
import FamilyDetailsStore from '../../../../family-details-form/store/FamilyDetailsStore';

class WealthSecurePlusFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          disabled: false,
          extra: {
            optionList: [],
          },
        },
        {
          name: "premium-term",
          value: {},
          label: "Premium Paying Term",
          placeholder: "",
          extra: {
            optionList: [],
          },
        },
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Annual",
              },
              {
                id: "2",
                label: "Half-Yearly",
              },
              {
                id: "3",
                label: "Quarterly",
              },
              {
                id: "4",
                label: "Monthly",
              },
            ],
          },
        },
        {
          name: "policy-option",
          label: "Policy Option",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Base",
              },
              {
                id: "2",
                label: "Life Partner",
              },
              {
                id: "133",
                label: "Child (Individual Life)",
              },
              {
                id: "134",
                label: "Child (Joint Life)",
              },
            ],
          },
        },
        {
          name: "fund-value",
          label: "% of Fund Value (per annum) to be Withdrawn",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [],
          },
        },
        {
          name: "fund-strategy",
          label: "Fund Strategy",
          placeholder: "",
          extra: {
            showFundDistributionModal: false,
            isVisible: false,
            optionList: [
              {
                id: "1",
                label: "Self-managed Strategy",
              },
              {
                id: "2",
                label: "Life stage and duration based Strategy",
              },
            ],
            setDistributionButtonText: 'Set Distribution',
            async onSetDistribution(fundList, form, data) {
              let productId = data.data.product_id
              form.$('fund-strategy').set('extra', {
                ...form.$('fund-strategy').extra,
                showFundDistributionModal: false,
                fundList: fundList
              })
              await Utils.setDistribution(fundList, productId, form, data);
            },
            fundList: [
              {
                id: "EquityLargeCapFund",
                label: "Equity Large Cap Fund",
                checkedValue: false,
                key: keys.EQUITY_LARGE_CAP_FUND,
                value: 0,
              },
              {
                id: "EquityTop250Fund",
                label: "Equity Top 250 Fund",
                checkedValue: false,
                key: keys.EQUITY_TOP_20_FUND,
                value: 0,
              },
              {
                id: "EquityMidCapFund",
                label: "Equity Mid-Cap Fund",
                checkedValue: false,
                key: keys.EQUITY_MID_CAP_FUND,
                value: 0,
              },
              {
                id: "EquityBlueChipFund",
                label: "Equity Blue Chip Fund",
                checkedValue: false,
                key: keys.EQUITY_BLUE_CHIP_FUND,
                value: 0,
              },
              {
                id: "GILTFund",
                label: "GILT Fund",
                checkedValue: false,
                key: keys.GLIT_FUND,
                value: 0,
              },
              {
                id: "BondFund",
                label: "Bond Fund",
                checkedValue: false,
                key: keys.BOND_FUND,
                value: 0,
              },
              {
                id: "ManagedFund",
                label: "Managed Fund",
                checkedValue: false,
                key: keys.MANAGED_FUND,
                value: 0,
              },
            ],
            toggleFundDistributionModal(form, status, fundList, productId) {
              form.$('fund-strategy').set('extra', {
                ...form.$('fund-strategy').extra,
                showFundDistributionModal: status,
                fundList: fundList
              })

              // Trigger when modal will close
              if (!status) {
                Utils.updateBi(keys.FUNDSTRATEGYID, "1", productId, form)
              }
            },
          },
        },
        {
          name: "systematic-withdrawal-option",
          label: "Systematic Withdrawal Option",
          placeholder: "",
          extra: {
            showFundDistributionModal: false,
            isVisible: false,
            optionList: [
              {
                id: "5",
                label: "Yes",
              },
              {
                id: "6",
                label: "No",
              },
            ],
          },
        },
        {
          name: "year-swp-payable",
          label: "Policy Year from which SWP payable",
          placeholder: "",
          extra: {
            optionList: [],
          },
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremiun(form, data) {
              // let premiumAmount = 0;
              // let inputMode = Number(form.$('payment-frequency').value.id)
              // let productId = data.data.product_id;
              // let amount = Number(form.$('premium').value)
              // switch (inputMode) {
              //   case 1:
              //     premiumAmount = '' + amount * 1
              //     break;
              //   case 2:
              //     premiumAmount = '' + amount * 2
              //     break;
              //   case 3:
              //     premiumAmount = '' + amount * 4
              //     break;
              //   case 4:
              //     premiumAmount = '' + amount * 12
              //     break;
              // }
              // Utils.updateBi(keys.PREMIUM, premiumAmount, productId, form, data)
            },
          },
        },
        {
          name: "swp-frequency",
          label: "SWP Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              {
                id: "117",
                label: "Annual",
              },
              {
                id: "118",
                label: "Semi-Annual",
              },
              {
                id: "119",
                label: "Quarterly",
              },
              {
                id: "120",
                label: "Monthly",
              },
            ],
          },
        },
        {
          name: "spouse-name",
          label: "Spouse Name",
          placeholder: "",
          extra: {
            onSpouseNameChange(form, data) {
              let name = form.$('spouse-name').value
              Utils.updateBi(keys.SPOUSE_NAME, name, data.data.product_id, form, data)
            }
          }
        },
        {
          name: "spouse-gender",
          label: "Spouse Gender",
          placeholder: "",
          extra: {
            optionList: [
              {
                id: '135',
                label: 'Male',
                key: 'M'
              },
              {
                id: '136',
                label: 'Female',
                key:'F'
              }
            ],
          },
        },
        {
          name: "spouse-dob",
          label: "Spouse Date of Birth",
          placeholder: "",
          extra: {
            changeDob(form, value, data) {
              let productId = data.data.product_id;
              let spouseDOB = value == '' ? FamilyDetailsStore.dateValue : value
              form.$("spouse-dob").set("value", spouseDOB)

              let dob = moment(spouseDOB).year()
              let currentYear = moment().year()
              let age = currentYear - dob;

              let requestData = {
                age: age,
                dob: spouseDOB
              }

              Utils.updateBi(keys.SPOUSE_DOB, requestData, productId, form, data)

            }
          },
        }
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data);
  }
}

export default WealthSecurePlusFormController;
