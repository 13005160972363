export const CovidVaccinationStatusList = [
    {
        id: 0,
        label: "Not Vaccinated"
    },
    {
        id: 1,
        label: "Single Dose taken"
    },
    {
        id: 2,
        label: "Both Doses Taken"
    },
]