import * as keys from './keys'
import OptimizationStore from '../../../store/OptimizationStore';
import Validator from './validator';

class Utils {

  updateBi = (key, value, productId, formRef, productData) => {

    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request

    switch (key) {

      case keys.FREQUENCY:
        biRequest[keys.FREQUENCY] = value
        break;

      case keys.PR_PT:
        biRequest[keys.PR_PT] = value;
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value
        break;

      case keys.POLICY_OPTION:
        if (value == "1") {
          biRequest[keys.LA_NAME] = "";
          biRequest[keys.LA_GENDER] = "";
          biRequest[keys.LA_DOB] = "";
        }
        biRequest[keys.POLICY_OPTION] = value;
        break;

      case keys.LA_NAME:
        biRequest[keys.LA_NAME] = value;
        break;

      case keys.LA_GENDER:
        var gender_value = ""
        if (value == 'F') {
          gender_value = "5"
        }
        else {
          gender_value = "4"
        }
        biRequest[keys.LA_GENDER] = gender_value;
        break;

      case keys.LA_DOB:
        biRequest[keys.LA_DOB] = value.age;
        biRequest[keys.OSN_SPOUSE_DATE_OF_BIRTH] = value.dob;
        break;

      case keys.MANAGED_FUND:
        biRequest[keys.EQUITY_LARGE_CAP_FUND] = value[keys.EQUITY_LARGE_CAP_FUND];
        biRequest[keys.EQUITY_TOP_20_FUND] = value[keys.EQUITY_TOP_20_FUND];
        biRequest[keys.BOND_FUND] = value[keys.BOND_FUND];
        biRequest[keys.MANAGED_FUND] = value[keys.MANAGED_FUND];
        biRequest[keys.EQUITY_MID_CAP_FUND] = value[keys.EQUITY_MID_CAP_FUND];
        biRequest[keys.EQUITY_BLUE_CHIP_FUND] = value[keys.EQUITY_BLUE_CHIP_FUND];
        biRequest[keys.GLIT_FUND] = value[keys.GLIT_FUND];
        biRequest[keys.LONG_TERM_BOND_FUND] = value[keys.LONG_TERM_BOND_FUND];
        break;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(formRef, biData);

    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value);
  };

  getTotalPrice = (data) => {
    try {
      return data.LOAD_ANN_PREM;
    } catch (e) {
      return 0
    }
  };

  setDistribution(fundList, productId, form, data) {
    let fundObject = {}
    fundList.forEach(item => {
      let value = item.value || 0
      let fundKey = item.key
      fundObject[fundKey] = value

    });
    this.updateBi(keys.MANAGED_FUND, fundObject, productId, form, data)

  }

  // fetchPolicyTimeline(element, biJson2Data) {
  //   let data = {
  //     youPay: Math.round(element.LOAD_ANN_PREM),
  //     youGet: Math.round(biJson2Data.FINAL_FV),
  //     lifeCover: Math.round(biJson2Data.DEATH_BEN),
  //     isMilestone: Math.round(biJson2Data.FINAL_FV) !== 0,
  //     premiumAmt: Math.round(element.MODAL_PREM)
  //   }
  //   return data
  // }


  newPolicyTimeLineData(element,inputString, index, finalYearIndex) {

    const {MODAL_PREM,TOTAL_SB,DEATH_BEN, FINAL_FV} = element;

    let data = {
      youPay:Math.round(MODAL_PREM),
      youGet:index == finalYearIndex ?  Math.round(FINAL_FV): 0,
      lifeCover:Math.round(DEATH_BEN)
    }

    return data

  }
}

export default new Utils();
