// package imports
import dvr from "mobx-react-form/lib/validators/DVR"
import vjf from "mobx-react-form/lib/validators/VJF"

import { Form } from "mobx-react-form"
import validatorjs from "validatorjs"

import moment from "moment"

// project imports
import FamilyDetailsStore from "../../../../family-details-form/store/FamilyDetailsStore"
import setInitialDefaults from "./initialDefaults"
import formObserver from "./observers"
import Utils from "./utils"
import * as keys from "./keys"

class ForeverPensionController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
      vjf: vjf(),
    }
  }

  setup() {
    return {
      fields: [
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "Select",
          extra: {
            options: [
              { id: "5", label: "Single" },
            ],
          },
        },
        {
          name: "source-of-purchase-price",
          label: "Source Of Purchase Price",
          placeholder: "Select",
          extra: {
            options: [
              { label: "Open Market", id: "1" },
              // { label: "Proceeds of pension policy of ETLI Co Ltd", id: "2" },
              // { label: "Proceeds of pension policy of other Insurer", id: "3" },
              { label: "Proceeds of NPS (National Pension Scheme )", id: "4" },
            ],
          },
        },
        {
          name: "pran-number",
          label: "PRAN Number",
          value: "",
          extra: {
            onPranNoChange(e,form, data){
              let pranNo = e.target.value;
              let regex = /^(([1-9]\d*))?$/;
              if(pranNo.length <= 12 && regex.test(pranNo)){
                  form.$('pran-number').value = pranNo
                }
              },
              onPranNoBlur(form, data) {
                let name = form.$('pran-number').value
                Utils.updateBi(keys.PRAN_NUMBER, name, data.data.product_id, form, data)
              },
              visible:true,
            },
          },
        {
          name: "annuity-benefit",
          label: "Annuity Benefit",
          placeholder: "Select",
          disabled:false,
          value: {},
          extra: {
            options: [
              { label: "Immediate Annuity", id: "1" },
              { label: "Deferred Annuity", id: "2" },
            ],
            enableDefermentperiodAndChangeOptionList(optionValue, form, data) {
              form.$("annuity-benefit").value = optionValue

              if (form.$("annuity-benefit").value) {
                form.$("deferment-period").set("disabled", false)
              }
              Utils.defermentPeiodOptionList(form,optionValue.id)

              if(optionValue.id === "1"){
                form.$("deferment-period").set("value",{ label: "NA", id: "10" })
              }else{
                form.$("deferment-period").set("value",{ label: "1", id: "11" })
              }
            },
          },
        },
        {
          name: "life-status",
          label: "Life Status",
          placeholder: "Select",
          extra: {
            options: [
              { label: "Single Life", id: "3" },
              { label: "Joint Life", id: "4" },
            ],
          },
        },
        {
          name: "annuity-mode",
          label: "Annuity Mode",
          placeholder: "Select",
          extra: {
            options: [
              { label: "Yearly", id: "21" },
              { label: "Half-yearly", id: "22" },
              { label: "Quarterly", id: "23" },
              { label: "Monthly", id: "24" },
            ],
          },
        },
        {
          name: "purchase-Price-amount",
          label: "Purchase Price Rs",
          type: "number",
          value: "",
          extra: {
            onPurchasePriceAmtChange(e,form, data) {
              let amt = e.target.value
              let regex = /^(([1-9]\d*))?$/;
                if (regex.test(amt)) {
                  form.$('purchase-Price-amount').value = amt
                }
            },

            onPurchasePriceAmtBlur(form, data) {
              let name = form.$('purchase-Price-amount').value
              Utils.updateBi(keys.PURCHASE_PRICE, name, data.data.product_id, form, data)
            }
          }
        },
        {
          name: "deferment-period",
          disabled: true,
          label: "Deferment Period",
          placeholder: "Select",
          value: {},
          extra: {
            isVisible:true,
            options: [
              { label: "1", id: "11" },
              { label: "2", id: "12" },
              { label: "3", id: "13" },
              { label: "4", id: "14" },
              { label: "5", id: "15" },
              { label: "6", id: "16" },
              { label: "7", id: "17" },
              { label: "8", id: "18" },
              { label: "9", id: "19" },
              { label: "10", id: "20" },
            ],
          },
        },
        {
          name: "annuity-option",
          label: "Annuity Option",
          placeholder: "Select",
          extra: {
            options: [
              { label: "Life Annuity", id: "25" },
              {
                label: "Life Annuity with Return of Purchase Price on Death",
                id: "26",
              },
              {
                label:
                  "Life Annuity with Return of Balance Purchase Price on Death",
                id: "27",
              },
              {
                label:
                  "Life Annuity with Return of Purchase Price on Critical Illness (CI) or Accidental Total and Permanent Disability (ATPD) or Death",
                id: "28",
              },
              { label: "Life Annuity (Joint life, Last Survivor)", id: "29" },
              {
                label:
                  "Life Annuity with Return of Purchase Price on Death (Joint life, Last Survivor)",
                id: "30",
              },
              {
                label:
                  "Deferred Annuity with Return of Purchase Price on Death (Single Life)",
                id: "31",
              },
              {
                label:
                  "Deferred Annuity with Return of Purchase Price on Critical Illness (CI) or Accidental Total and Permanent Disability (ATPD) or Death (Single Life)",
                id: "32",
              },
              {
                label:
                  "Deferred Annuity with Return of Purchase Price on Death (Joint Life, Last Survivor)",
                id: "33",
              }
            ],
          },
        },
        {
          name: "secondary-annuitant-name",
          label: "Secondary Annuitant",
          value: "",
          disabled:false,
          extra: {
            onSecondaryAnnuitantNameChange(form, data) {
              let name = form.$('secondary-annuitant-name').value
              Utils.updateBi(keys.NAME_OF_THE_SECONDARY_ANNUITANT, name, data.data.product_id, form, data)
            }
          }
        },
        {
          name: "secondary-annuitant-date-of-birth",
          label: "DOB of the Secondary Annuitant",
          placeholder: "",
          disabled:false,
          extra: {
            changeDob(form, value, data) {
              form.$("secondary-annuitant-date-of-birth").set("value", value)
              let productId = data.data.product_id;
              const dateString = moment(value).format('LL')

              Utils.updateBi(keys.DOB_NAME_OF_THE_SECONDARY_ANNUITANT, dateString, productId, form, data)
            }
          },
        },
        {
          name: "secondary-annuitant-gender",
          label: "Gender of the Secondary Annuitant",
          placeholder: "Select",
          disabled:false,
          extra: {
            options: [
              { label: "Male", id: "8" },
              { label: "Female", id: "9" },
              // { label: "Transgender", id: "7" },
            ],
          },
        },
        // for death cover

        {
          name: "income",
          label: "Income",
          placeholder: "Income",
          value: "",
          extra: {
            calculateIncome(form, data) {
              let income = form.$("income").value
              Utils.updateBi(
                keys.INCOME,
                income,
                data.data.product_id,
                form,
                data
              )
            },
          },
          disabled: true,
        },
        {
          name: "premium-amt",
          label: "Premium",
          placeholder: "Premium",
          disabled: false,
          extra: {
            calculatePremium(form, value, data) {
              // Utils.updateBi(
              //   keys.PREMIUM,
              //   value,
              //   data.data.product_id,
              //   form,
              //   data
              // )

            },
          },
        },
      ],
    }
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data)

    // Observer fields
    formObserver(this, data)
  }
}

export default ForeverPensionController
