export const FREQUENCY = 'INPUT_MODE';
export const PREMIUM = 'PR_ANNPREM';
export const PR_PT = 'PR_PT';
export const PR_PPT = 'PR_PPT';
export const POLICY_OPTION = 'LifeCoverOption';
export const LA_NAME = 'SecondLifeAssuredName';
export const LA_GENDER = 'SecondLifeAssuredGender';
export const LA_DOB = 'SecondLifeAssuredDOB';
export const EQUITY_LARGE_CAP_FUND = 'EquityLargeCapFund';
export const EQUITY_TOP_20_FUND = 'EquityTop250Fund';
export const BOND_FUND = 'BondFund';
export const MANAGED_FUND = 'ManagedFund';
export const EQUITY_MID_CAP_FUND = 'EquityMidCapFund';
export const EQUITY_BLUE_CHIP_FUND = 'EquityBlueChipFund';
export const GLIT_FUND = 'GILTFund';
export const LONG_TERM_BOND_FUND = 'LongTermBondFund';
export const MAMANGE_FUND = 'manage-fund';
export const OSN_SPOUSE_DATE_OF_BIRTH = 'OSNSpouseDateOfBirth'
