// React imports
import React, { useState, useEffect } from "react"

// package imports
import PropTypes from "prop-types"
import { Button as UiButton, CustomCheckbox } from "components"
import { Button, Card, Modal } from "react-bootstrap"
import moment from "moment"
import { observer } from "mobx-react"
import { toJS } from "mobx"

// Project imports

import FamilyDetailsStore from "modules/family-details-form/store/FamilyDetailsStore"
import OptimizationStore from "modules/optimization/store/OptimizationStore"
import AddInsuredMemberCard from "./add-Insured-modal-form"
import {
  FamilyIcon,
  CheckSecondaryImg,
  CloseIconBlack,
  PlusIconFilledIcon,
} from "constants/Images"
import AuthStore from "modules/login/store/AuthStore"
import AddInsuredFormController from "./add-Insured-modal-form/controller"
import FamilyMemberModalTypeEnum from "constants/familyMemberModalTypeEnum"
import FamilyMemberTypeEnum from "modules/family-details-form/FamilymemberTypeEnum"
import isObjectEmpty from "utils/isObjectEmpty"
import NeedTypeEnum from "constants/NeedTypeEnum"


// css imports
import "./add-insured.css"

const AddInsuredModal = (props) => {
  const [formController, setFormController] = useState(undefined);

  let {
    show,
    onAddMember,
    onHide,
    eligibleLaList,
    proposerList
  } = props

  useEffect(() => {
    let controller = new AddInsuredFormController()
    let journeyId = toJS(AuthStore.journeyId)


    let familyMemberData = OptimizationStore.fetchFamilyMemberListForLifeAssured()

    controller.setDefaults(journeyId, familyMemberData)
    setFormController(controller)

  }, [show, eligibleLaList, proposerList])


  useEffect(()=>{

    let memberObjList = []
    let memberArray = formController?.$("members").get()

    for (const key in memberArray) {
      memberObjList.push(memberArray[key])
    }

    memberObjList = memberObjList.map((item) => item.value)


    if(memberObjList.length === 1 && OptimizationStore.familyMemberModalType === FamilyMemberModalTypeEnum.CHANGE_PROPOSER){
      
      let name  = memberObjList[0].name;
      if(!isObjectEmpty(toJS(OptimizationStore.selfFamilyMemberDetail))){

        if((JSON.stringify(memberObjList[0])) !== (JSON.stringify(toJS(OptimizationStore.selfFamilyMemberDetail)))){
          formController.$("members").$(name).$("isSelected").value = true
        }
      } 
      else{
          formController.$("members").$(name).$("isSelected").value = false
        }
    }else{

      let initialFamilyMemberList = toJS(OptimizationStore.familyMemberList)
      let finalFamilyMemberList = memberObjList.map((eachMember) => eachMember.name)
      let listOfNewFamilyMemberAdded = finalFamilyMemberList.filter(eachMember => !initialFamilyMemberList.includes(eachMember))
      console.log('memberObjList',initialFamilyMemberList,finalFamilyMemberList,listOfNewFamilyMemberAdded);

      let firstNewlyAddedItem = listOfNewFamilyMemberAdded[0]

      memberObjList.forEach((item)=>{        

        if(item.name === "SELF"){

          if((JSON.stringify(item)) !== (JSON.stringify(toJS(OptimizationStore.selfFamilyMemberDetail)))){
            formController.$("members").$(item.name).$("isSelected").value = true
          }else{
            formController.$("members").$(item.name).$("isSelected").value = false
          }
        }
        let listOfMembers =formController.$("members").get()
        let ifSelfSelected = !listOfMembers.hasOwnProperty("SELF")
        if(!ifSelfSelected) {
          ifSelfSelected = !formController.$("members").$("SELF").$("isSelected").value
        }
        if((item.name === firstNewlyAddedItem) && ifSelfSelected){
          formController.$("members").$(item.name).$("isSelected").value = true
        }
       else{
          if(item.name !== "SELF"){
            formController.$("members").$(item.name).$("isSelected").value = false
          }
        }
      })
    }

  },[formController])



  // const addIdToOptimisationStore = (memberObjectLength,formController, onHide) => {

  //   if(memberObjectLength <= 1) return

  //   formController.validate({ showErrors: true })
  //     .then(({ isValid }) => {
  //       if (isValid) {
  //         const formFieldValuesArray = formController.get().members.value
  //         for (const key in formFieldValuesArray) {
  //           if (formFieldValuesArray[key].isSelected === true) {
  //             const id = formFieldValuesArray[key].id
  //             OptimizationStore.setSelectedMemberId(id)
  //           }
  //         }
  //         switch (OptimizationStore.familyMemberModalType) {
  //           case FamilyMemberModalTypeEnum.CHANGE_LA:
  //             // props.changeLA()
  //             // OptimizationStore.changeLA()
  //             break;

  //           case FamilyMemberModalTypeEnum.CHANGE_PROPOSER:
  //             OptimizationStore.changeProposer()
  //         }
          
  //         onHide()
  //       }
  //     });
  // }

  if (formController === undefined) return null

  let memberObjList = []
  let memberArray = formController.$("members").get()

  for (const key in memberArray) {
    memberObjList.push(memberArray[key])
  }

  memberObjList = memberObjList.map((item) => item.value)


  //  ! for switching the modal Type

  let modalType

  switch (OptimizationStore.familyMemberModalType) {

    case FamilyMemberModalTypeEnum.CHANGE_LA:
      modalType = "Change Life Assured"
      break;

    case FamilyMemberModalTypeEnum.CHANGE_PROPOSER:
      modalType = "Change Proposer"
      break;

    case FamilyMemberModalTypeEnum.ADD_INSURED:
    default:
      modalType = "Add Life Assured"
      break;
  }

  let showChangeLaValidation = memberObjList.length <= 1 && OptimizationStore.familyMemberModalType !== FamilyMemberModalTypeEnum.ADD_NEED

  const addIdToOptimisationStore = (memberObjectLength, formController, onHide) => {
    if (OptimizationStore.familyMemberModalType === FamilyMemberModalTypeEnum.ADD_INSURED && (OptimizationStore.selectedNeed !== NeedTypeEnum.PROTECTION_FOR_MY_FAMILY && OptimizationStore.selectedNeed !== NeedTypeEnum.PROTECTION_FOR_MY_HEALTH)) {
      showChangeLaValidation = false
    }
    if (showChangeLaValidation) return

    formController.validate({ showErrors: true })
      .then(({ isValid }) => {
        if (isValid) {
          const formFieldValuesArray = formController.get().members.value
          for (const key in formFieldValuesArray) {
            if (formFieldValuesArray[key].isSelected === true) {
              const id = formFieldValuesArray[key].id
              OptimizationStore.setSelectedMemberId(id)
            }
          }
          console.log(OptimizationStore.familyMemberModalType)
          switch (OptimizationStore.familyMemberModalType) {
            case FamilyMemberModalTypeEnum.CHANGE_LA:
              // props.changeLA()
              // OptimizationStore.changeLA()
              break;

            case FamilyMemberModalTypeEnum.CHANGE_PROPOSER:
              OptimizationStore.changeProposer()
          }

          onHide()
        }
      });
  }

  //  ! for modal CTA button
  const modalCtaBtn = OptimizationStore.familyMemberModalType === FamilyMemberModalTypeEnum.CHANGE_LA ||
    OptimizationStore.familyMemberModalType === FamilyMemberModalTypeEnum.CHANGE_PROPOSER ?
    "Change" :
    "Add"


    const ctaMessage = (noOfMember,modalType) => {

      if(noOfMember.length <= 1){

        switch (modalType) {
          case FamilyMemberModalTypeEnum.CHANGE_PROPOSER:
            return <p className="font-weight-medium text-danger font-size-12">Please add a new family member to change the current Proposer.</p>  


          case FamilyMemberModalTypeEnum.ADD_INSURED:
            if (OptimizationStore.selectedNeed === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY || OptimizationStore.selectedNeed === NeedTypeEnum.PROTECTION_FOR_MY_HEALTH) {
              return <p className="font-weight-medium text-danger font-size-12">Please add a new family member to add a new Life Assured</p>
            } else {
              return null
            }

        
          case FamilyMemberModalTypeEnum.CHANGE_LA:
            return <p className="font-weight-medium text-danger font-size-12">Please add a new family member to change the current Life Assured.</p>

          default:
            return null
        }


      }

    }

  return (
    <div>
      <Modal
        className="add-insured-modal left-bottom-swipe-modal"
        show={show}
        onHide={onHide}
        keyboard={false}
      >
        <Modal.Header
          closeButton={false}
          className="pb-0 mx-md-2 border-bottom-0"
        >
          <Modal.Title className="text-primary-title-label-color">
            <img
              src={FamilyIcon}
              alt="family-icon"
              className="add-insured-member-card-modal-top-img"
            />
            <span className="title font-size-18">{modalType}</span>
          </Modal.Title>

          <div className=" mt-2 pt-1">
            <UiButton
              variant=""
              className="d-inline-flex align-items-center p-0"
              type="submit"
              onClick={() => {
                addIdToOptimisationStore(memberObjList.length,formController, onHide, modalCtaBtn)
              }}
            >
              <img
                src={CheckSecondaryImg}
                alt="checked-icon"
                className="fm-modal-btn-icon font-weight-bold"
              />
              <p className="mb-0 font-size-16 font-size-md-12 font-weight-bold text-secondary pl-1">
                {modalCtaBtn}
              </p>
            </UiButton>


            <UiButton
              variant=""
              className="d-inline-flex align-items-center p-0 ml-2"
              onClick={() => {
                OptimizationStore.resetSelectedNeedAndMemberId()
                onHide()
              }}
            >
              <img
                src={CloseIconBlack}
                alt="close-icon"
                className="fm-modal-btn-icon font-weight-bold"
              />
              <p className="mb-0 font-size-16 font-size-md-12 font-weight-bold pl-1">
                Cancel
              </p>
            </UiButton>
          </div>
        </Modal.Header>

        <Modal.Body className="">

          {memberObjList.map((item) => {
            let {
              id,
              name,
              salutation,
              first_name: firstName,
              middle_name: middleName,
              last_name: lastName,
              gender,
              age,
              family_member_type: memberType,
              isSelected,
            } = item
            return (
              <AddInsuredMemberCard
                key={id}
                name={name}
                isSelected={isSelected}
                memberType={memberType}
                salutation={salutation}
                firstName={firstName}
                gender={gender}
                middleName={middleName}
                lastName={lastName}
                age={age}
                formController={formController}
              />
            )
          })}

         
          {ctaMessage(memberObjList,OptimizationStore.familyMemberModalType)}

          <hr className="underline" />

          <Button
            variant=""
            onClick={()=>onAddMember(memberObjList)}
            role="button"
            className="text-scondary w-100 text-right font-weight-light add-Buttton"
          >
            <img
              className="mr-2 plusIconStyle"
              src={PlusIconFilledIcon}
              alt="plus-icon"
            />
            Add/Edit Family Member Details
          </Button>
        </Modal.Body>
      </Modal>
    </div>

  )
}

AddInsuredModal.defaultsProps = {
  onHide: () => { },
}

AddInsuredModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onAddMember: PropTypes.func.isRequired,
  onAddInsured: PropTypes.func.isRequired,
  onAddInsured: PropTypes.func.isRequired,
}


export default observer(AddInsuredModal)