import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import OptimizationStore from '../../../store/OptimizationStore';
import isTotalFundCountValid from 'utils/isTotalFundCountValid';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Minimum Premium Validation for less than 10 PPT
    premiumValidationForLessThan10PPT = (form, data) => {
        // let pt = form.$('policy-term').value.id
        // let ppt = form.$('premium-term').value.id
        // let premium = form.$('premium').value;
        // let frequency = form.$('payment-frequency').value.id;
        // let totamAmount = premium * frequency;

        // if (ppt > 10) {
        //     return;
        // }

        // if (pt >= 10 && pt <= 14 && totamAmount < 48000) {
        //     this.errorMessage.push(errorMessageKeys.lessThan10PPT['10-15PT'])
        // }

        // if (pt >= 15 && pt <= 19 && totamAmount < 36000) {
        //     this.errorMessage.push(errorMessageKeys.lessThan10PPT['15-19PT'])
        // }

        // if (pt == 20 && totamAmount < 24000) {
        //     this.errorMessage.push(errorMessageKeys.lessThan10PPT['20PT'])
        // }

    }

    // Minimum Premium Validation for greater than 10 PPT
    premiumValidationForGreaterThan10PPT = (form, data) => {
        // let pt = form.$('policy-term').value.id
        // let ppt = form.$('premium-term').value.id
        // let premium = form.$('premium').value;
        // let frequency = form.$('payment-frequency').value.id;
        // let totamAmount = premium * frequency;

        // if (ppt < 10) {
        //     return;
        // }

        // if (pt >= 10 && pt <= 14 && totamAmount < 36000) {
        //     this.errorMessage.push(errorMessageKeys.greaterThan10PPT['10-15PT'])
        // }

        // if (pt >= 15 && pt <= 19 && totamAmount < 30000) {
        //     this.errorMessage.push(errorMessageKeys.greaterThan10PPT['15-19PT'])
        // }

        // if (pt == 20 && totamAmount < 18000) {
        //     this.errorMessage.push(errorMessageKeys.greaterThan10PPT['20PT'])
        // }

    }

    // Age wise PPT Selection for 0 to 45 years
    ageWisePPTCombination = (form, data) => {
        // let age = data.data.bi_request.LI_ENTRY_AGE;
        // let ppt = form.$('premium-term').value.id
        // if (age <= 45) {
        //     if (ppt < 5 || ppt > 20) {
        //         this.errorMessage.push(errorMessageKeys.ageValidation['5-20'])
        //     }
        // } else {
        //     if (ppt < 10 || ppt > 20) {
        //         this.errorMessage.push(errorMessageKeys.ageValidation['10-20'])
        //     }
        // }
    }

    // PT PPT validation
    validatePtPpt = (form) => {
        // let pt = Number(form.$('policy-term').value.id)
        // let ppt = Number(form.$('premium-term').value.id)

        // if (pt < ppt) {
        //     this.errorMessage.push(errorMessageKeys.ptPPT)
        // }
    }

    // 100% Fund Allocation validation
    fundAllocationValidations = (formRef) => {
        let fundStratergyElem = formRef.$("fund-strategy");
        let fundsList = fundStratergyElem.extra.fundList;
        let currentFundStratergy = fundStratergyElem.value.id;

        // Return for Life Stage and duration based Strategy
        if (currentFundStratergy != 1) return

        let isCountValid = isTotalFundCountValid(fundsList);

        if (!isCountValid) {
            this.errorMessage.push("Total Fund allocation must be 100%")
        }
    }

}
export default new Validator();