import React, { Component } from 'react';

import { observer } from 'mobx-react';

// PRODUCTS
import ZindagiPlus from '../zindagi-plus/zindagi-plus'
import SimplyProtect from '../simply-protect/simply-protect'
import ActiveIncome from '../active-income/active-income.js'
import WealthUltimaProductCard from '../wealth-ultima/WealthUltimaProductCard';

const AllProducts = observer(class AllProducts extends Component {
    state = {}
    getParentProductName = (productName) => {
        let name = ''
        switch (productName) {
            case 'Zindagi Plus':
                name = 'Zindagi Plus'
                break

            case 'Simply Protect':
                name = 'Simply Protect'
                break

            case 'Active Income Plan':
            // case 'POS - Saral Nivesh':
            case 'Three Generation Plan (Active Income Plan)':
            case 'Active Income Plan with FIB':
            case 'Whole Life Active Income (Active Income Plan)':
            case 'Retire Befiker (Active Income Plan)':
                name = 'Active Income Plan'
                break

            case 'Wealth Ultima':
            case 'Systematic Withdrawal Plan (Wealth Ultima)':
            case 'Little Champ (Wealth Ultima)':
                name = 'Wealth Ultima'
                break
        }

        return name
    }
    render() {
        let { productName, ...prop } = this.props;
        // console.log('ALL PRODUCTS RENDER >> ', productName)
        productName = this.getParentProductName(productName)
        return (
            <div>
                {
                    productName ?
                        <div>
                            {productName == 'Zindagi Plus' ? <ZindagiPlus {...prop} /> : null}
                            {productName == 'Simply Protect' ? <SimplyProtect {...prop} /> : null}
                            {productName == 'Active Income Plan' ? <ActiveIncome {...prop} /> : null}
                            {productName == 'Wealth Ultima' ? <WealthUltimaProductCard {...prop} /> : null}
                        </div>
                        :
                        <div>No right match found. Kindly re-evaluate</div>
                }
            </div>
        );
    }
});

export default AllProducts;