import React from "react"

// Package Imports
import { Card, Image } from "react-bootstrap"

// Project Imports
import { CircularProgressbar } from "components"
import LaAndPremiumRiderComponent from "../la-and-premium-rider-component/LaAndPremiumRiderComponent"
import * as images from "constants/Images"

// css Imports
import "./solution-screen-muliple-la-card.css"

const SolutionScreenMulipleLaCard = ({
  CardName,
  percentValue,
  totalPercentValue,
  detail
}) => {

  const { laList } = detail

  const fetchPercentage = (data) => {
    let percentValue = 0
    let totalTargetAmount = 0;
    let totalRecommendedAmount = 0;
    data.productDetails.forEach((item) => {
      totalTargetAmount += Math.round((Number(item.targetAmount))); 
      totalRecommendedAmount += Math.round((Number(item.recommendedAmount)));
    })
    percentValue = Math.round((Number(totalTargetAmount) / Number(totalRecommendedAmount)) * 100)
    return percentValue > 100 ? 100 : percentValue
  }

  const fetchTotalPercentage = () => {
    let recommendedAmount = 0
    let targetAmount = 0
    laList.forEach((item) => {
      recommendedAmount += Number(item.productDetails[0].recommendedAmount)
      item.productDetails.forEach((productData) => {
        targetAmount += Number(productData.targetAmount)
      })
    })
    let percent = Math.round((targetAmount / recommendedAmount) * 100)
    return percent > 100 ? 100 : percent
  }

  return (
    <Card className="solution-screen-muliple-la-card mt-3">
      <Card.Body>
        <p className="text-center font-size-18 font-weight-medium la-card-title">
          {CardName}
        </p>
        <div className="d-flex justify-content-around align-items-center px-2 px-5 flex-wrap">
          <div className="total-doughnut-container">
            <CircularProgressbar
              singleLaCard={false}
              value={fetchTotalPercentage()}
              valuelabel="covered"
              isSingleImg={true}
              laName=""
              size="" // pass small for smaller doughnut chart and empty string for default doughnut
            />
          </div>

          <div className="d-flex align-items-center my-2 mb-lg-5 equalto-container">
            <div className="d-flex flex-column  mt-md-0 mb-3 mb-lg-0 mx-auto">
              <Image
                width="20"
                className=""
                src={images.LateralLine}
                alt="lateral-line"
              />

              <Image
                width="20"
                className=" mt-1"
                src={images.LateralLine}
                alt="lateral-line"
              />
            </div>
          </div>

          {laList.map((item, index) => {
            if (index == 0) {
              return (
                <div className="row align-items-center">
                  <CircularProgressbar
                    singleLaCard={false}
                    value={fetchPercentage(item)}
                    valuelabel="covered"
                    isSingleImg={true}
                    laName={item.laName}
                    size={window.innerWidth > 768 ? "" : "small"} // pass small for smaller doughnut chart and empty string for default doughnut
                  />
                </div>
              )
            } else {
              return (
                <>
                  <Image
                    width="14"
                    // className="plus-icon"
                    className="mb-5"
                    src={images.PlusIconGreyBold}
                    alt="plus"
                  />
                  <CircularProgressbar
                    singleLaCard={false}
                    value={fetchPercentage(item)}
                    valuelabel="covered"
                    isSingleImg={true}
                    laName={item.laName}
                    size={window.innerWidth > 768 ? "" : "small"} // pass small for smaller doughnut chart and empty string for default doughnut
                  />
                </>
              )
            }
          })}
        </div>

        <div className="d-flex flex-wrap">

          {laList.map((item) => {

            if (item.productDetails.length > 1) {

              return item.productDetails.map((singleProduct) => {
                return (
                  <div className="border border-top-0 px-0 pb-3 col ">
                    <LaAndPremiumRiderComponent
                      // productpremiumType="Product"
                      // productPremiumMode={singleProduct.inputMode}
                      productpremiumType="Product Premium"
                      productPremiumMode="per annum"
                      productPremiumAmount={singleProduct.basePremium}
                      productPremiumGstPercent={singleProduct.gstPercent}
                      productPremiumGstAmount={singleProduct.gstAmount}
                      riderPremiumType="Rider"
                      // riderPremiumMode={singleProduct.inputMode}
                      riderPremiumMode="per annum"
                      riderPremiumAmount={singleProduct.riderBasePremium}
                      riderPremiumGstPercent={singleProduct.riderGstPercentage}
                      riderPremiumGstAmount={singleProduct.riderGstAmount}
                      showRiderValue={singleProduct.riderBasePremium !== 0}
                      percentValue="5555"
                      LaName={item.laName}
                      productName={singleProduct.productName}
                      totalPremiumAmount={singleProduct.totalPremiumAmount}
                      totalPremiumPayable={singleProduct.totalPremiumPayable}
                      inputMode={singleProduct.inputMode}
                    />
                  </div>
                )
              })

            } else {
              return (
                <div className="border border-top-0 px-0 pb-3 col">
                  <LaAndPremiumRiderComponent
                    // productpremiumType="Product"
                    // productPremiumMode={item.productDetails[0].inputMode}
                    productpremiumType="Product Premium"
                    productPremiumMode="per annum"
                    productPremiumAmount={item.productDetails[0].basePremium}
                    productPremiumGstPercent={item.productDetails[0].gstPercent}
                    productPremiumGstAmount={item.productDetails[0].gstAmount}
                    riderPremiumType="Rider"
                    // riderPremiumMode={item.productDetails[0].inputMode}
                    riderPremiumMode="per annum"
                    riderPremiumAmount={item.productDetails[0].riderBasePremium}
                    riderPremiumGstPercent={item.productDetails[0].riderGstPercentage}
                    riderPremiumGstAmount={item.productDetails[0].riderGstAmount}
                    showRiderValue={item.productDetails[0].riderBasePremium !== 0}
                    percentValue="55"
                    LaName={item.laName}
                    productName={item.productDetails[0].productName}
                    totalPremiumAmount={item.productDetails[0].totalPremiumAmount}
                    totalPremiumPayable={item.productDetails[0].totalPremiumPayable}
                    inputMode ={item.productDetails[0].inputMode}
                  />
                </div>
              )
            }
          })}
        </div>
      </Card.Body>
    </Card>
  )
}

export default SolutionScreenMulipleLaCard
