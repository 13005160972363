// project imports
import Utils from "./utils"
import * as keys from "./keys"

const FormObserver = (formRef, props) => {
	let biRequest = props.data.bi_request;
	let liAge = Number(biRequest.LI_ENTRY_AGE)
	let policyTerm = biRequest.PR_PT

	// !PPT
	formRef.$('premium-term').observe({
		key: 'value',
		call: ({ field }) => {
			let planOption = Number(formRef.$('plan-option').value.id)
			let pt = formRef.$('policy-term').value.id
			let ppt = Number(field.value.id)

			// !Income start year
			let incomeStartYearList = Utils.incomeStartYearOptionList(planOption, Number(field.value.id))
			formRef.$('income-start-year').set('extra', { ...formRef.$('income-start-year').extra, optionList: incomeStartYearList })
			if(incomeStartYearList.length > 0) formRef.$('income-start-year').set('value', incomeStartYearList[0])

			// !PT
			let ptList = Utils.policyTermList(planOption, ppt, liAge)
			let ptExist = ptList.some((item)=> item.id.toString() ===  policyTerm.toString())
			formRef.$('policy-term').set('extra', { ...formRef.$('policy-term').extra, optionList: ptList })
			if(!ptExist) formRef.$('policy-term').set('value', ptList[0])

			// !Life Cover Continuation Benefit
			let ptValue = pt === '100-@LI_ENTRY_AGE' ? 100 - liAge : Number(pt)
			let isLifeCoverContinuationBenefitDisabled = Utils.checkLifeCoverDisabled(ppt, ptValue, liAge)
			formRef.$('life-cover').set('disabled', isLifeCoverContinuationBenefitDisabled)
			if (isLifeCoverContinuationBenefitDisabled) {
				formRef.$('life-cover').set('value', { label: 'No', id: "10" })
			}

			let requestData = {
				ppt: field.value.id,
				incomeStartYear: incomeStartYearList.length > 0 ? incomeStartYearList[0].id : "0",
				pt: ptExist ? formRef.$('policy-term').value.id : ptList[0].id,
				lifeCoverContinuationBenefit: formRef.$('life-cover').value.id
			}

			Utils.updateBi(keys.PR_PPT, requestData, props.data.product_id, formRef, props)
		}
	})

	// !Income start year
	formRef.$('income-start-year').observe({
		key: 'value',
		call: ({ field }) => {
			Utils.updateBi(keys.INCOME_START_YEAR, field.value.id, props.data.product_id, formRef, props)
		}
	})

	// !PT
	formRef.$('policy-term').observe({
		key: 'value',
		call: ({ field }) => {

			// !Life Cover Continuation Benefit
			let ppt = formRef.$('premium-term').value.id
			let pt = field.value.id
			let ptValue = pt === '100-@LI_ENTRY_AGE' ? 100 - liAge : Number(pt)
			let isLifeCoverContinuationBenefitDisabled = Utils.checkLifeCoverDisabled(ppt, ptValue, liAge)
			formRef.$('life-cover').set('disabled', isLifeCoverContinuationBenefitDisabled)
			if (isLifeCoverContinuationBenefitDisabled) {
				formRef.$('life-cover').set('value', { label: 'No', id: "10" })
			}

			let requestData = {
				lifeCoverContinuationBenefit: formRef.$('life-cover').value.id,
				pt: field.value.id,
			}

			Utils.updateBi(keys.PR_PT, requestData, props.data.product_id, formRef, props)
		}
	})

	// !Life Cover Continuation Benefit
	formRef.$('life-cover').observe({
		key: 'value',
		call: ({ field }) => {
			Utils.updateBi(keys.LIFE_COVER_CONTINUATION_BENEFIT, field.value.id, props.data.product_id, formRef, props)
		}
	})

	// !Payment frequency
	formRef.$('payment-frequency').observe({
		key: 'value',
		call: ({ field }) => {
			Utils.updateBi(keys.FREQUENCY, field.value.id, props.data.product_id, formRef, props)
		}
	})

	// !Accrual Of Survival Benefits
	formRef.$('accrual-of-survival-benefit').observe({
		key: 'value',
		call: ({ field }) => {
			let requestData = {}
			formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: true })

			if (Number(field.value.id) === 12) {
				requestData = {
					accrualOfSurvivalBenefit: field.value.id,
					personalizePlan: "",
					systematicWithdrawal: "",
					pwStartYear: "",
					pwEndYear: "",
					pwAge: "",
					pwStartAge: "",
					pwEndAge: "",
				}
				formRef.$('personalize-plan').set('extra', { ...formRef.$('personalize-plan').extra, hide: true })
				formRef.$('withdrawal').set('extra', { ...formRef.$('withdrawal').extra, hide: true })
				formRef.$('withdrawal-start-year').set('extra', { ...formRef.$('withdrawal-start-year').extra, hide: true })
				formRef.$('withdrawal-end-year').set('extra', { ...formRef.$('withdrawal-end-year').extra, hide: true })
				formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: true })
				formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, hide: true })
				formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, hide: true })

			} else {
				requestData = {
					accrualOfSurvivalBenefit: field.value.id,
					personalizePlan: "1",
					systematicWithdrawal: "",
					pwStartYear: "",
					pwEndYear: "",
					pwAge: "",
					pwStartAge: "",
					pwEndAge: "",
				}
				formRef.$('personalize-plan').set('value', { label: 'Systematic Withdrawal', id: "1" })
				formRef.$('personalize-plan').set('extra', { ...formRef.$('personalize-plan').extra, hide: false })
				formRef.$('withdrawal').set('extra', { ...formRef.$('withdrawal').extra, hide: false })
				formRef.$('withdrawal-start-year').set('extra', { ...formRef.$('withdrawal-start-year').extra, hide: false })
				formRef.$('withdrawal-end-year').set('extra', { ...formRef.$('withdrawal-end-year').extra, hide: false })
				formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: true })
				formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, hide: true })
				formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, hide: true })
			}
			Utils.updateBi(keys.ACCRUAL_OF_SURVIVAL_BENEFIT, requestData, props.data.product_id, formRef, props)
		}
	})

	// !Please personalize your plan
	formRef.$('personalize-plan').observe({
		key: 'value',
		call: ({ field }) => {
			if (Number(field.value.id) === 1) {
				formRef.$('withdrawal').set('extra', { ...formRef.$('withdrawal').extra, hide: false })
				formRef.$('withdrawal-start-year').set('extra', { ...formRef.$('withdrawal-start-year').extra, hide: false })
				formRef.$('withdrawal-end-year').set('extra', { ...formRef.$('withdrawal-end-year').extra, hide: false })
				formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: true })
				formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, hide: true })
				formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, hide: true })
			}
			if (Number(field.value.id) === 2) {
				formRef.$('withdrawal').set('extra', { ...formRef.$('withdrawal').extra, hide: true })
				formRef.$('withdrawal-start-year').set('extra', { ...formRef.$('withdrawal-start-year').extra, hide: true })
				formRef.$('withdrawal-end-year').set('extra', { ...formRef.$('withdrawal-end-year').extra, hide: true })
				formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: false })
				formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, hide: true })
				formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, hide: true })
			}
			if (Number(field.value.id) === 3) {
				formRef.$('withdrawal').set('extra', { ...formRef.$('withdrawal').extra, hide: true })
				formRef.$('withdrawal-start-year').set('extra', { ...formRef.$('withdrawal-start-year').extra, hide: true })
				formRef.$('withdrawal-end-year').set('extra', { ...formRef.$('withdrawal-end-year').extra, hide: true })
				formRef.$('age-based-withdrawal').set('extra', { ...formRef.$('age-based-withdrawal').extra, hide: true })
				formRef.$('start-age').set('extra', { ...formRef.$('start-age').extra, hide: false })
				formRef.$('end-age').set('extra', { ...formRef.$('end-age').extra, hide: false })

			}
			let requestData = {
				personalizePlan: field.value.id,
				systematicWithdrawal: "",
				pwStartYear: "",
				pwEndYear: "",
				pwAge: "",
				pwStartAge: "",
				pwEndAge: "",
			}
			Utils.updateBi(keys.PERSONALISED_OPTION, requestData, props.data.product_id, formRef, props)
		}
	})

	// !Withdrawal
	formRef.$('withdrawal').observe({
		key: 'value',
		call: ({ field }) => {
			Utils.updateBi(keys.WITHDRAWAL, field.value.id, props.data.product_id, formRef, props)
		}
	})

	// Addtional Rider

	formRef.$('ADB').observe({
		key: 'value',
		call: ({ field }) => {
			let adbValue = formRef.$('ADB-dropdown').value
			Utils.updateBi(keys.ADB_RIDER, adbValue, props.data.product_id, formRef, props)
		}
	})

	formRef.$('ATPDRider').observe({
		key: 'value',
		call: ({ field }) => {
			let atpdValue = formRef.$('ATPDRider-dropdown').value
			Utils.updateBi(keys.ATPD_RIDER, atpdValue, props.data.product_id, formRef, props)
		}
	})

	formRef.$('CriticalIllnessRider').observe({
		key: 'value',
		call: ({ field }) => {
			let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
			Utils.updateBi(keys.Critical_ILLNESS_RIDER, criticalIllness, props.data.product_id, formRef, props)
		}
	})

	formRef.$('IncomeBenefitRider').observe({
		key: 'value',
		call: ({ field }) => {
			let incomeBuilder = formRef.$('IncomeBenefitRider-dropdown').value
			Utils.updateBi(keys.INCOME_BENEFIT_RIDER, incomeBuilder, props.data.product_id, formRef, props)
		}
	})

	formRef.$('PayorWaiverBenefitRider-dropdown').observe({
		key: 'value',
		call: ({ field }) => {
			Utils.updateBi(keys.PWB_RIDER, field.value.id, props.data.product_id, formRef, props)
		}
	})

}

export default FormObserver
