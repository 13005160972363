import React, { Component } from 'react';
import { Col, Row, Card, Modal } from 'react-bootstrap';

import { CustomCheckbox, BottomsheetDropdown } from '../../../../../../../components';
import { InfoCircleIcon } from 'constants/Images';

import './additional-cards.css';
import { observer } from 'mobx-react';
import UiButton from 'components/ui/button/Button';

class AdditionalCards extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isAdditionalBenefitModalOpen: false,
      modalTitle: "",
      modalDescription: "",
    }
  }

  showBenefitModal = (title, description) => {
    this.setState({
      isAdditionalBenefitModalOpen: true,
      modalTitle: title,
      modalDescription: description,
    })
  }

  hideBenefitModal = () => {
    this.setState({ isAdditionalBenefitModalOpen: false })
  }

  render() {
    const { form, data , productData} = this.props

    let maritalStatus = form.$('marital-status').value.id
    return (
      <>
      <div className='additional-cards'>
        <p className='mb-2 pb-1 font-size-14 font-weight-medium text-dark-grey'>Additional Benefits</p>
        <Card className='border'>
          <Card.Body>
            {/* {(cardsList && cardsList.length > 0) && cardsList.map((value, index) => {
              // console.log('value.checkedValue', value, value.is_selected)
              return ( */}
            {/* <Row key={index} className={`${index > 0 ? 'mt-3' : ''} ${value.isDisabled ? 'disable-card' : ''}`}> */}

            {/* 1st */}
            {maritalStatus !== "9" && (
            <Row className={` ${form.$('better-half-benefit').disabled ? 'disable-card' : ''}`}>
              <Col className='col-9'>
                <div className='d-flex align-items-center'>
                  <CustomCheckbox
                    shape='rounded'
                    title={form.$('better-half-benefit').label} titleStyle='font-weight-medium font-size-14 pl-2 text-primary'
                    
                    isChecked={form.$('better-half-benefit').value}
                    onChange={() => form.$('better-half-benefit').extra.changeBenefitStatus(form, data)}
                    mark='checkmark'
                  />
                  <img className={`pl-2 ${form.$('better-half-benefit').disabled ? 'inf0-icon-disable' : ''}`} src={InfoCircleIcon} alt='info' />
                </div>
                <p className='mb-0 font-size-12 font-weight-300 sub-left-padding total-premium-container-heading'>
                  {form.$('better-half-benefit').extra.description}
                </p>
              </Col>
              <Col className='align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height'>
                {
                  productData?.firstYearResponse?.BH_Modal_Prem ? <React.Fragment>
                    <div className='d-flex justify-content-end'>
                      <p className='mb-0 font-weight-light font-size-20 selected-value-color'> {global.toINR(productData?.firstYearResponse?.BH_Modal_Prem)}</p>
                    </div>
                  </React.Fragment> : null
                }
              </Col>
            </Row>
            )}
            {/* 2nd */}
            <Row className={` mt-4 ${form.$('WaiverOfPremiumRider').disabled ? 'disable-card' : ''}`}>
              <Col className='col-9'>
                <div className='d-flex align-items-center'>
                  <CustomCheckbox
                    shape='rounded'
                    title={form.$('WaiverOfPremiumRider').label} titleStyle='font-weight-medium font-size-14 pl-2 text-primary'
                    isChecked={form.$('WaiverOfPremiumRider').value}
                    onChange={() => form.$('WaiverOfPremiumRider').extra.changeBenefitStatus(form, data)}
                    mark='checkmark'
                  />
                  <img className={`pl-2 ${form.$('WaiverOfPremiumRider').disabled ? 'inf0-icon-disable' : ''}`} src={InfoCircleIcon} alt='info'
                  onClick={() => this.showBenefitModal(form.$("WaiverOfPremiumRider").label, form.$("WaiverOfPremiumRider").extra.description)}
                  
                  />
                </div>
                {/* <p className='mb-0 font-size-12 font-weight-300 sub-left-padding total-premium-container-heading'>
                  {form.$('WaiverOfPremiumRider').extra.description}
                </p> */}
              </Col>
              <Col className='align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height'>
                {
                  productData?.firstYearResponse?.WOP_Modal_Prem ? <React.Fragment>
                    <div className='d-flex justify-content-end'>
                      <p className='mb-0 font-weight-light font-size-20 selected-value-color'>{global.toINR(productData?.firstYearResponse?.WOP_Modal_Prem)}</p>
                    </div>
                  </React.Fragment> : null
                }
              </Col>
            </Row>

            {/* 3rd  */}
            <Row className={`mt-3 ${form.$('top-up-benefit').disabled ? 'disable-card' : ''}`}>
              <Col className='col-9'>
                <div className='d-flex align-items-center mb-2'>
                  <CustomCheckbox
                    shape='rounded'
                    title={form.$('top-up-benefit').label} titleStyle='font-weight-medium font-size-14 pl-2 text-primary'
                    isChecked={form.$('top-up-benefit').value}
                    onChange={() => form.$('top-up-benefit').extra.changeBenefitStatus(form, data)}
                    mark='checkmark'
                  />
                  <img className={`pl-2 ${form.$('top-up-benefit').disabled ? 'inf0-icon-disable' : ''}`} src={InfoCircleIcon} alt='info'
                    onClick={() => this.showBenefitModal(form.$("top-up-benefit").label, form.$("top-up-benefit").extra.description)}
                  />
                </div>
                {/* <p className='mb-0 font-size-12 font-weight-300 sub-left-padding total-premium-container-heading'>
                  {form.$('top-up-benefit').extra.description}
                </p> */}
                {(form.$('top-up-benefit').value) &&
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey='label'
                    bottomsheetTitle=''
                    menuList={form.$('top-up-benefit-dropdown').extra.optionList}
                    value={form.$('top-up-benefit-dropdown').value}
                    onSelect={form.$('top-up-benefit-dropdown').onChange}
                  />}
              </Col>
              <Col className='align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height'>
                {
                  data.TOPUP_PREMIUM ? <React.Fragment>
                    <div className='d-flex justify-content-end'>
                      <p className='mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary'> {global.toINR(data.TOPUP_PREMIUM)}</p>
                    </div>
                  </React.Fragment> : null
                }
              </Col>
            </Row> 

            {/* 4th */}
            {/* <Row className={`mt-3 ${form.$('telemedical-option').disabled ? 'disable-card' : ''}`}>
              <Col className='col-9'>
                <div className='d-flex align-items-center'>
                  <CustomCheckbox
                    shape='rounded'
                    title={form.$('telemedical-option').label} titleStyle='font-weight-normal font-size-14 pl-2 additional-benefits-cards-text'
                    isChecked={form.$('telemedical-option').value}
                    onChange={() => form.$('telemedical-option').extra.changeBenefitStatus(form)}
                    mark='checkmark'
                  />
                  <img className={`pl-2 ${form.$('telemedical-option').disabled ? 'inf0-icon-disable' : ''}`} src={InfoCircleIcon} alt='info' />
                </div>
                <p className='mb-0 font-size-12 font-weight-300 sub-left-padding total-premium-container-heading'>
                  {form.$('telemedical-option').extra.description}
                </p>
              </Col>
              <Col className='align-items-center col-3 col-border-left d-flex flex-column justify-content-center col-line-height'>
                {
                  form.$('telemedical-option').extra.sum_assured != '' ? <React.Fragment>
                    <div className='d-flex justify-content-end'>
                      <p className='mb-0 font-weight-bold font-size-16 font-size-md-18 text-secondary'> {form.$('telemedical-option').extra.sum_assured == '-' ? form.$('telemedical-option').extra.sum_assured : global.toINR(form.$('telemedical-option').extra.sum_assured)}</p>
                    </div>
                  </React.Fragment> : null
                }
              </Col>
            </Row> */}
          </Card.Body>
        </Card>
      </div>

      <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          className="rider-info-modal"
          show={this.state.isAdditionalBenefitModalOpen}
          keyboard={false}
        >
          <Modal.Body className="text-center text-primary-title-label-color px-4 pt-4 pb-0">
            <React.Fragment>
              <h3 className="font-weight-medium font-size-22 font-size-md-32 mb-3">
                {this.state.modalTitle}
              </h3>
              <p className="font-weight-normal font-size-20 font-size-md-20 mb-0">
                {this.state.modalDescription}
              </p>
            </React.Fragment>
          </Modal.Body>
          <Modal.Footer className="d-block p-4 border-0">
            <Row className="modal-footer-row">
              <React.Fragment>
                <Col xs="12">
                  <UiButton
                    onClick={this.hideBenefitModal}
                    className="font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0"
                    variant="secondary"
                    buttonText="OKAY"
                  />
                </Col>
              </React.Fragment>
            </Row>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default observer(AdditionalCards)
