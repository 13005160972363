import React, { Component } from 'react';

import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom'

import AuthStore from '../../login/store/AuthStore';
import ChooseNeedsAndSetTargets from '../../choose-needs/ChooseNeedsAndSetTargets';
import FamilyDetailsPage from '../../family-details-form/FamilyDetailsPage';
import Solution from '../../solution/Solution';
import ShareQuote from '../../share-quote-form/ShareQuote';
import JourneyComplete from '../../journey-complete/JourneyComplete';
import FamilyProtection from '../../family-protection/FamilyProtection';
import QuotationPreview from '../../quotation-preview/QuotationPreview';
import ExternalPdfViewer from '../../pdf-viewer/PdfViewer';
import Optimization from '../../optimization/Optimazation';

import '../../../styles/typo.css';

const Dashboardbtn = observer(class Dashboardbtn extends Component {
  constructor(props) {
    super(props);
    const urlString = window.location.href
    const url = new URL(urlString);
    const urlQueryParams = url.search

    const selectedUrl = this.props.match.params.selectedPage;
    const journeyId = this.props.match.params.journeyId;
    if (selectedUrl == undefined) {
      this.props.history.push('/choose-needs')
    } else if (journeyId == undefined) {
      this.props.history.push(`/${selectedUrl}${urlQueryParams}`);
    } else {
      AuthStore.journeyId = journeyId;
      this.props.history.push(`/${selectedUrl}/${journeyId}${urlQueryParams}`);
    }
  }
  render() {
    return (
      <SelectedPage {...this.props} />
    );
  }
});

const SelectedPage = (props) => {
  const { selectedPage } = useParams();
  switch (selectedPage) {
    case 'choose-needs':
      return <ChooseNeedsAndSetTargets {...props} />
    case 'family-details':
      return <FamilyDetailsPage {...props} />
    case 'solution':
      return <Solution {...props} />
    case 'share-quote':
      return <ShareQuote {...props} />
    case 'journey-complete':
      return <JourneyComplete {...props} />
    case 'family-protection':
      return <FamilyProtection {...props} />
    case 'quotation-preview':
      return <QuotationPreview {...props} />
    case 'pdf':
      return <ExternalPdfViewer {...props} />
    case 'customization':
      return <Optimization {...props} />

    default:
      return <div></div>
  }
}

export default Dashboardbtn;
