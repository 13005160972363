//! package import
import { NextButton, TabComponent } from "components";
import { observer } from "mobx-react";
import { Container, Image, Row, Col } from "react-bootstrap";
import React, { Component } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { toJS } from "mobx";

//! Project imports
import utils from "./utils";
import SolutionStore from "modules/solution/store/SolutionStore";

// ! css import
import "./quotation-preview.css";

// !dummy data structure
import solutionDataStructure from "modules/solution/solution-data";
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import StorageKeys from "constants/StorageKeys";
import AuthStore from "modules/login/store/AuthStore";
import savePragatiNAInboundData from "utils/savePragatiNAInboundData";
import QuotationPreviewStore from "./QuotationPreviewStore";
class QuotationPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tabList: [],

      // tabList: solutionDataStructure,
      selectedTabIndex: "0",
      seletedTabData: [],
      numPages: 1,
    };
    this.setSelectedTabIndex = this.setSelectedTabIndex.bind(this);
    // this.onPragatiOutboundClick = this.onPragatiOutboundClick.bind(this);
  }

  setSelectedTabIndex(tabIndex) {
    this.setState({ selectedTabIndex: tabIndex });
    let userListData = toJS(SolutionStore.user_list_data)
    AuthStore.setSolutionData(userListData[Number(tabIndex)])
    QuotationPreviewStore.setSelectedTabData(this.state.tabList[Number(tabIndex)])
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedTabIndex != this.state.selectedTabIndex) {
      this.state.tabList.forEach((item, index) => {
        if (index.toString() === this.state.selectedTabIndex) {
          this.setState({ seletedTabData: item });
          SolutionStore.setSeletedSolutionTabData(item);
        }
      });
    }
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages: numPages });
  };

  successHandler = async () => {
    if (this.props.location.state?.solutionList) {
      let documentData = await utils.fetchDocument(this.props.location.state.solutionList);
      if (documentData) {
        documentData.forEach((item) => {
          item.tabTitle = item.proposer_name;
        });
        this.setState({ tabList: documentData, seletedTabData: documentData ? documentData[0] : [] });
        QuotationPreviewStore.setSelectedTabData(documentData ? documentData[0] : [])
        SolutionStore.dmsDocumentData = documentData || [];
      }
    }
  };

  async componentDidMount() {
    await savePragatiNAInboundData(location)
    this.successHandler();
    this.setState({ seletedTabData: this.state.tabList[0] });
    let userListData = toJS(SolutionStore.user_list_data)
    AuthStore.setSolutionData(userListData[0])
    window.scrollTo(0, 0);
  }

  // async onPragatiOutboundClick() {
  //   let generatedSolutionData = await OptimizationStore.onGenerateSolutionData();
  //   if (generatedSolutionData) {
  //     OptimizationStore.solutionId = generatedSolutionData.data[0].solution_id;
  //     OptimizationStore.leadSpecificId = generatedSolutionData.data[0].la_specific_ids_array[0];
  //   }
  //   let pragatiOutboundFormObj = document.getElementById("pragati-out-oubound-form-post-call");
  //   console.log("onPragatiOutboundClick formObj :: ", pragatiOutboundFormObj);
  //   pragatiOutboundFormObj.submit();
  // }

  render() {
    return (
      <Container className='quotation-preview-page mt-5 mt-md-2 mb-5 pb-5 pb-md-0'>
        <p className='quotation-preview-title font-weight-medium font-size-18 mb-0'>Solution Quote Preview</p>
        <p className='quotation-preview-sub-title font-weight-light font-size-12'>Preview the quotation document before sending to the client.</p>

        {/* <NextButton className='mb-4' disabled={false} buttonText={"Pragati Outbound Test"} onClick={this.onPragatiOutboundClick} /> */}

        <Col xs='12'>
          <TabComponent tabList={this.state.tabList} setSelectedTabIndex={this.setSelectedTabIndex} />
        </Col>

        {this.state.seletedTabData && (
          <center>
            <div className='pdf-container'>
              <Document file={this.state.seletedTabData?.url} onLoadSuccess={this.onDocumentLoadSuccess}>
                {Array.from(new Array(this.state.numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </center>
        )}
        {/* {localStorage.getItem(StorageKeys.LOGIN_AGENT_CODE) ? (
          <div>
            <form className='d-none' id='pragati-out-oubound-form-post-call' action={localStorage.getItem(StorageKeys.PRAGATI_POST_CALL_END_POINT_URL)} method={"POST"}>
              <input type='text' name='Pragati_Token' value={localStorage.getItem(StorageKeys.AUTH_TOKEN)} />
              <input type='text' name='LoginCode' value={localStorage.getItem(StorageKeys.LOGIN_CODE)} />
              <input type='text' name='Vendor' value={localStorage.getItem(StorageKeys.VENDOR)} />
              <input type='text' name='SourceFrom' value={localStorage.getItem(StorageKeys.SOURCE_FROM)} />
              <input type='text' name='LoginAgentCode' value={localStorage.getItem(StorageKeys.LOGIN_AGENT_CODE)} />
              <input type='text' name='Solution_Id' value={OptimizationStore.solutionId ? OptimizationStore.solutionId : ""} />
              <input type='text' name='LA_Specific_Id' value={""} />
              <button type='submit'>Submit</button>
            </form>
          </div>
        ) : (
          <div></div>
        )} */}
      </Container>
    );
  }
}
export default observer(QuotationPreview);
