import Utils from "./utils";

const initialDefaults = (formRef, data) => {
  let productData = data.data;
  let biResponse = productData.parsedResponse;
  let biRequest = productData.bi_request;

  let frequency = biRequest.INPUT_MODE.toString();
  let annuityOption = biRequest.AnnuityOption;
  let sourceofPurchasePrice = biRequest.SourceofPurchasePrice;
  let frequencyofAnnuity = biRequest.FrequencyofAnnuity;
  let premium = biRequest.PR_ANNPREM;
  let areYouNominee = biRequest.AreyoutheNominee;

  formRef.$("premium").value = premium;

  formRef.$("premium-term").value = {
    id: biRequest.PR_PPT,
    label: biRequest.PR_PPT,
  };

  switch (areYouNominee) {
    case "18":
      formRef.$("are-you-nominee").set("value", {
        id: "18",
        label: "Yes",
      });
      break;

    case "19":
      formRef.$("are-you-nominee").set("value", {
        id: "19",
        label: "No",
      });
      break;

    default:
      break;
  }

  switch (frequency) {
    case "5":
      formRef.$("payment-frequency").set("value", {
        id: "5",
        label: "Single",
      });
      break;

    default:
      break;
  }

  switch (annuityOption) {
    case "1":
      formRef.$("annuity-option").set("value", {
        id: "1",
        label: "Life Annuity with Return of Purchase Price",
      });
      break;
    case "2":
      formRef.$("annuity-option").set("value", {
        id: "2",
        label: "Life Annuity",
      });
      break;
    case "3":
      formRef.$("annuity-option").set("value", {
        id: "3",
        label: "Life Annuity Certain for 5 years",
      });
      break;
    case "4":
      formRef.$("annuity-option").set("value", {
        id: "4",
        label: "Life Annuity Certain for 10 years",
      });
      break;
    case "5":
      formRef.$("annuity-option").set("value", {
        id: "5",
        label: "Life Annuity Certain for 15 years",
      });
      break;
    case "6":
      formRef.$("annuity-option").set("value", {
        id: "6",
        label: "Life Annuity Certain for 20 years",
      });
      break;
    case "7":
      formRef.$("annuity-option").set("value", {
        id: "7",
        label: "Life Annuity Increasing at a simple rate of 3% per annum",
      });
      break;
    case "8":
      formRef.$("annuity-option").set("value", {
        id: "8",
        label: "Life Annuity Increasing at a simple rate of 5% per annum",
      });
      break;
    case "9":
      formRef.$("annuity-option").set("value", {
        id: "9",
        label: "Joint Life, Last Survivor",
      });
      break;
    case "10":
      formRef.$("annuity-option").set("value", {
        id: "10",
        label: "Joint Life, Last Survivor with 50% annuity",
      });
      break;
    case "11":
      formRef.$("annuity-option").set("value", {
        id: "11",
        label:
          "Joint Life, Last Survivor with Return of Purcahse Price on Last Death",
      });
      break;

    default:
      break;
  }

  switch (sourceofPurchasePrice) {
    case "15":
      formRef.$("source-of-purchase-price").set("value", {
        id: "15",
        label: "Proceeds of pension policy of ETLI Co Ltd",
      });
      break;
    case "16":
      formRef.$("source-of-purchase-price").set("value", {
        id: "16",
        label: "Proceeds of pension policy of other Insurer",
      });
      break;
    case "17":
      formRef.$("source-of-purchase-price").set("value", {
        id: "17",
        label: "Open Market",
      });
      break;

    default:
      break;
  }

  switch (frequencyofAnnuity) {
    case "20":
      formRef.$("frequency-of-annuity").set("value", {
        id: "20",
        label: "Yearly",
      });
      break;
    case "21":
      formRef.$("frequency-of-annuity").set("value", {
        id: "21",
        label: "Half-Yearly",
      });
      break;
    case "22":
      formRef.$("frequency-of-annuity").set("value", {
        id: "22",
        label: "Quarterly",
      });
      break;
    case "23":
      formRef.$("frequency-of-annuity").set("value", {
        id: "23",
        label: "Monthly",
      });
      break;

    default:
      break;
  }

  if (biRequest.NameoftheSecondaryAnnuitant !== "") {
    formRef.$('name-of-the-secondary-annuitant').value = biRequest.NameoftheSecondaryAnnuitant
  }

  switch (biRequest.GenderofSecondaryAnnuitant) {
    case '13':
      formRef.$('gender-secondary-annuitant').set('value', {
        id: '13',
        label: 'Male'
      })
      break;
    case '14':
      formRef.$('gender-secondary-annuitant').set('value', {
        id: '14',
        label: 'Female'
      })
      break;

    default:
      break;
  }

  if (biRequest.OSNAnnuitantDateOfBirth) {
    formRef.$('dob-of-secondary-annuitant').set('value', biRequest.OSNAnnuitantDateOfBirth)
  }
};

export default initialDefaults;
