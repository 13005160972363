export function atLeastOneShouldBeSelected(target) {
  return ({ field, form }) => {

    let emptyValueArray = [];
    const valueObject = field.get()
    var size = Object.keys(form.values().monthly_expense).length;
    let monthyExpenseForm = form.$("monthly_expense")


    for (const key in valueObject) {
      if(Object.keys(valueObject[key].value).length === 0 && valueObject[key].constructor === Object){
       emptyValueArray.push('empty object')
      }
    }

    const selected = emptyValueArray.length !== size ? true : false;
      
    if(!monthyExpenseForm.has("household")){
        return
    }
    if(!selected){
      monthyExpenseForm.$("household").invalidate('This field is mandatory');
      monthyExpenseForm.$("rent").invalidate('This field is mandatory');
      monthyExpenseForm.$("school_fee").invalidate('This field is mandatory');
      monthyExpenseForm.$("others").invalidate('This field is mandatory');
    }else{
      monthyExpenseForm.$("household").resetValidation();
      monthyExpenseForm.$("rent").resetValidation();
      monthyExpenseForm.$("school_fee").resetValidation();
      monthyExpenseForm.$("others").resetValidation();
    }

  }
}
