import NeedTypeEnum from "./NeedTypeEnum";

class Utils {
  actualValue = (value) => {
    let amount = 0
    if(value.id){
      if (value.label.split(" ")[1] == "L") {
        amount = value.id * 100000;
      }
      if (value.label.split(" ")[1] == "Cr") {
        amount = value.id * 10000000;
      }
    }else{
      amount = value
    }
    return amount;
  };

   premiumAmountLabelFunction = (type,cardKey) => {

    /* Annual, Semi-Annual,Monthly,Quarterly*/
    let amountType = ''

    switch (type) {
      case "Annual":
        amountType = 'per annum'
      break;
        
      case "Semi-Annual":
      case "Half-Yearly":
        amountType = 'per half year'
      break;

      case "Monthly":
        amountType = 'per month'
      break;

      case "Quarterly":
        amountType = 'per quarter'
      break;

      case "Single":
        amountType = '(single)'
      break;
      
      default:
        amountType = ''
        break;
    }

    // if(cardKey === NeedTypeEnum.PROTECTION_FOR_MY_FAMILY){
    //  return amountType = 'lumpsum'
    // }

    return amountType

  }

  rupeesToIndianFormat = (num) => {

    let amount = num
    
      if(amount){
      const curr = num.toLocaleString('en-IN', {
        style: 'currency',
        currency: 'INR'
     });
      
     return curr.slice(1,curr.length - 3)
   }else{
     return ''
   }
  }
  
}

export default new Utils();
