// Project Imports
import axios from "utils/axios"
import * as APITypes from "constants/API"


const fetchPincode = async (pinCode, form) => {
 if (pinCode.charAt(0) == 0 && pinCode.length >= 5) {
   form.$("location").resetValidation()
   form.$("location").invalidate("First number should be non zero")
   form.$("location").set("extra", {
     optionList: [],
   })
   return
 } else if (pinCode.charAt(0) !== 0) {
   form.$("location").resetValidation()
 }

 let regex = /^[1-9][0-9]{5}$/
 if (regex.test(pinCode)) {
   form.$("location").resetValidation()

   var options = []
   try {
     let parsedValue = parseInt(pinCode)

     if (parsedValue === NaN) return
     const response = await axios.get(
       `${APITypes.GET_PINCODE}?pinCode=${pinCode}`
     )

     if (response.data[0].city === undefined) {
       return options
     }
     options.push({
       id: response.data[0].pinCode,
       label: response.data[0].city,
       location: response.data[0].state,
     })
   } catch (error) {
     console.log("Error is ", error)
     form.$("location").invalidate("Enter valid PIN Code")
   } finally {
     form.$("location").set("extra", {
       optionList: options,
     })
     return options
   }
 } else {
   form.$("location").set("extra", {
     optionList: [],
   })
   form.$("location").invalidate("Enter valid PIN Code")
 }
}


export default fetchPincode