// Project Imports
import { NUMBER_REGEX } from 'constants/Constants'

const fetchRiderValidation = (riderSumAssured, riderName) => {
    let validation = ""
    if (riderSumAssured && (!NUMBER_REGEX.test(riderSumAssured) || riderSumAssured.toString().startsWith("0"))) {
        validation = `Please enter valid  ${riderName} rider's sum assured`
    }
    return validation
}

export default fetchRiderValidation