// React Imports
import React, { Component } from "react";

// Package Imports
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { Image } from "react-bootstrap";

// Project Imports
import QmsDetailsPageTabComponent from "modules/qms-flow/components/qms-details-page-tab-component/QmsDetailsPageTabComponent";
import BottomsheetDropdown from "../bottomsheet-dropdown/BottomsheetDropdown";
import qmsDashboardPageStore from "modules/qms-flow/qms-dashboard-page/qms-dashboard-page-store";
import * as images from "constants/Images";
import QmsStorageKeys from "constants/QmsStorageKeys";
import StorageKeys from "constants/StorageKeys";

// CSS Imports
import "./qms-dashboard-breadcrumb.css";
import "../qms-details-breadcrumb/qms-details-breadcrumb.css";

class QmsDashboardBreadcrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabIndex: 0,
    };
  }

  onBackBtnClick() {
    let formObj = document.getElementById("redirect-to-pragati-on-qms-back");
    console.log("handleRedirectionToQMS formObj :: ", formObj);
    formObj.submit();
    return;
  }

  setSelectedTabIndex(tabIndex) {
    this.setState({ selectedTabIndex: tabIndex });
  }

  render() {
    let quotesNApplicationTotalCount = 0;
    let qmsListData = qmsDashboardPageStore.qmsListData ? toJS(qmsDashboardPageStore.qmsListData) : [];
    if (qmsListData && qmsListData?.all_journey_lists?.length > 0) {
      qmsListData?.all_journey_lists.forEach((journeyObj, index) => {
        quotesNApplicationTotalCount = quotesNApplicationTotalCount + journeyObj.journey_list.length;
      });
    }
    return (
      <div className='qms-dashboard-breadcrumb qms-breadcrumb pb-0 pt-0 px-1 w-100'>
        <div className='d-flex align-items-center justify-content-between breadcrumb-content-container'>
          <div className='d-flex' style={{ maxWidth: "900px" }}>
            <div className='d-flex align-items-center' onClick={() => this.onBackBtnClick()}>
              <div className='mb-0 font-size-12 font-weight-400 my-0 mr-1 back-btn cursor-pointer'>
                <Image className='mr-2' src={images.QMSBackHomeBtnIcon} alt='back-arrow' />
                <span>Back</span>
              </div>
            </div>

            <div className='d-none d-md-block border-right border-dark mx-md-4 my-2'></div>

            <div className='d-block d-md-none ml-2'>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey='label'
                bottomsheetTitle={"Quotes & Applications [43]"}
                menuList={[{ label: "Quotes & Applications [43]", id: 0 }]}
                value={{ label: "Quotes & Applications [43]", id: 0 }}
                onSelect={(value) => {}}
                hideBottomsheet={true}
              />
            </div>

            <div className='d-md-block d-none'>
              <QmsDetailsPageTabComponent tabList={[{ title: `Quotes & Applications ${"[ " + quotesNApplicationTotalCount + " ]"}` }]} setSelectedTabIndex={this.setSelectedTabIndex} />
            </div>
          </div>
        </div>

        <div>
          <form className='d-none' id='redirect-to-pragati-on-qms-back' action={localStorage.getItem(QmsStorageKeys.PRAGATI_HOME_URL)} method={"POST"}>
            <input type='text' name='Pragati_Token' value={localStorage.getItem(StorageKeys.AUTH_TOKEN)} />
            <input type='text' name='LoginCode' value={localStorage.getItem(QmsStorageKeys.LOGIN_CODE)} />
            <input type='text' name='Vendor' value={localStorage.getItem(QmsStorageKeys.VENDOR)} />
            <input type='text' name='SourceFrom' value={localStorage.getItem(QmsStorageKeys.SOURCE_FROM)} />
            <input type='text' name='LoginAgentCode' value={localStorage.getItem(QmsStorageKeys.LOGIN_AGENT_CODE)} />
            <button type='submit'>Submit</button>
          </form>
        </div>
      </div>
    );
  }
}

export default observer(QmsDashboardBreadcrumb);
