import { observable, action, makeObservable } from "mobx";
import jwt_decode from "jwt-decode";
import moment from "moment";

import axios from "../../../utils/axios";
import * as APITypes from "../../../constants/API";
import { TOKEN_EXPIRY_TIME_IN_MINUTES, REFRESH_TOKEN_IN_MINUTES } from "constants/Constants";

import Headers from "../../../constants/Headers";
import StorageKeys from "constants/StorageKeys";
//import config from '~/config';

const config = {
  appEnv: "dev", // feel free to remove the appEnv property here
  redirectUrl: "https://etli-need-analysis-manage.osian.dev/verify-token/",
  loginVia: "Edelweiss",
};

class AuthStore {
  isUserLogin = null;

  userData = {};
  expenseList = [];
  lifeCoverList = [];
  journeyList = [];
  needMasterList = [];

  firstTimeUserData = null;
  journeyData = undefined;
  journeyTypeName = undefined;

  isInvalidJourneyId = false;
  journeyId = "";
  axiosMsg = "";

  agentDetails = {};
  agentName = "";
  agentCode = "";
  isSessionExpired = false;

  solutionData = {}

  constructor() {
    makeObservable(this, {
      isUserLogin: observable,
      userData: observable,
      expenseList: observable,
      lifeCoverList: observable,
      firstTimeUserData: observable,
      isInvalidJourneyId: observable,
      journeyData: observable,
      journeyId: observable,
      axiosMsg: observable,
      agentDetails: observable,
      agentName: observable,
      agentCode: observable,
      isSessionExpired: observable,
      loginUser: action,
      setJourneyId: action,
      setNeedMasterList: action,
      setAgentdetails: action,
      setJourneyData: action,
    });
  }

  async loginUser(email) {
    global.loading(true);
    const data = {
      redirectUrl: config.redirectUrl,
      loginVia: config.loginVia,
      email: email,
    };
    try {
      const response = await axios.post(APITypes.LOGIN, data);

      if (response.data.data.url) {
        window.location.href = response.data.data.url;

        setTimeout(async () => {
          const urlParams = new URLSearchParams(location.search);
          const code = urlParams.get("code");

          if (code !== undefined) {
            await this.generateToken(code);
          }
        }, 2000);

        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    } finally {
      global.loading(false);
    }
  }

  db = {
    async saveData(data) {
      try {
        await localStorage.setItem("userData", data ? JSON.stringify(data) : "");
        await Headers.setSessionToken(data ? data.token : "");
      } catch (e) {}
    },
    async getData() {
      let userData = await localStorage.getItem("userData");
      if (userData) userData = JSON.parse(userData);
      return userData;
    },
    async saveToken(data) {
      await localStorage.setItem("token", data ? data : "");
    },
    async getToken() {
      let token = await localStorage.getItem("token");
      return token;
    },
    async saveRefreshToken(data) {
      await localStorage.setItem("refresh_token", data ? data : "");
    },
    async getRefreshToken() {
      let token = await localStorage.getItem("refresh_token");
      return token;
    },
    async saveGeneratedToken(data) {
      await localStorage.setItem("ganerated_token", data ? data : "");
    },
    async getGeneratedToken() {
      let token = await localStorage.getItem("ganerated_token");
      return token;
    },
  };

  authentication = {
    isAuthenticated: false,
    authenticate: async () => {
      var token = await localStorage.getItem(StorageKeys.AUTH_TOKEN);
      
      // var generatedToken = await this.db.getGeneratedToken();
      if (token) {
        try {
          var decodedToken = jwt_decode(token);
          var expiryTime = decodedToken.exp;
          var expiryTimeConstant = TOKEN_EXPIRY_TIME_IN_MINUTES;
          var momentTime = moment.unix(expiryTime).subtract(expiryTimeConstant, "m");

          if (moment().isAfter(momentTime)) {
            await this.authentication.refreshToken();
          }
        } catch (error) {}
      }

      // if (token && generatedToken) {
      //   const data = await this.authentication.verifyToken();
      //   this.isUserLogin = data;
      // }

      this.isUserLogin = false;
      return true;
    },
    signout: () => {
      this.isUserLogin = false;
      this.userData = {};
      this.db.saveData("");
      this.db.saveToken("");
      this.db.saveRefreshToken("");
      this.db.saveGeneratedToken("");
      let pragatiHomeUrl = localStorage.getItem(StorageKeys.PRAGATI_HOME_URL);
      // localStorage.clear();
      localStorage.setItem(StorageKeys.PRAGATI_HOME_URL, pragatiHomeUrl);
    },
    refreshToken: async () => {
      if (localStorage.getItem(StorageKeys.AUTH_TOKEN)) {
        const response = await axios.post(APITypes.REFRESH_TOKEN);
        let refreshedToken = response.data.token;
        if (refreshedToken) {
          localStorage.setItem(StorageKeys.AUTH_TOKEN, refreshedToken);
          this.db.saveToken(refreshedToken);
        }
        setTimeout(() => {
          this.authentication.refreshToken();
        }, REFRESH_TOKEN_IN_MINUTES);
      }

      //  else {
      //   this.isSessionExpired = true;
      // }
      return true;
    },
    checkIfTokenExpired: async (token) => {
      // var token = await localStorage.getItem(StorageKeys.AUTH_TOKEN);
      let isTokenExpired = false;
      
      if (token) {
        try {
          var decodedToken = jwt_decode(token);
          var expiryTime = decodedToken.exp;
          var momentTime = moment.unix(expiryTime);
          if (moment().isAfter(momentTime)) {
            //Session expired.
            isTokenExpired = true;
          }
          else{
            isTokenExpired = false;
          }
        } catch (error) {
          console.log("token decode error :::: ", error);
        }
      } else {
        isTokenExpired = true;
      }

      return isTokenExpired;
    },
    verifyToken: async () => {
      // let authToken = await localStorage.getItem(StorageKeys.AUTH_TOKEN);
      // if (authToken) {
      //   const data = {
      //     accessToken: await localStorage.getItem(StorageKeys.AUTH_TOKEN),
      //   };
      //   var response = await axios.post(APITypes.VERIFY_TOKEN, data);
      //   if (!response.data.data.status) {
      //     // this.logout()
      //     return false;
      //   }
      // } else {
      //   return false;
      // }
      // return true;
    },
  };

  async fetchMasterData() {
    var expenseListResponse = await axios.get(APITypes.FETCH_EXPENSE_LIST);
    var lifeCoverResponse = await axios.get(APITypes.LIFE_COVER_MASTER);
    const journeyTypeRequest = await axios.get(APITypes.JOURNEY_MASTER);

    if (expenseListResponse.data && expenseListResponse.status == "success") {
      this.expenseList = expenseListResponse.data;
      this.lifeCoverList = lifeCoverResponse.data;
      this.journeyList = journeyTypeRequest.data;
    } else {
      this.expenseList = [];
      this.lifeCoverList = [];
      this.journeyList = [];
    }
  }

  async fetchAllData(journeyId) {
    try {
      global.loading(true);

      await this.fetchMasterData();

      if (journeyId === undefined) {
        this.journeyData = {};
        global.loading(false);
        return;
      }
      var response = await axios.get(`${APITypes.FETCH_ALL_DATA}${journeyId}/`);

      if (response.data && response.status == "success") {
        this.journeyData = response.data;
        let journeyType = response.data.journey_details.journey_type;
        let journeyData = this.journeyList.find((item) => item.id == journeyType);
        if (journeyData !== undefined) {
          this.journeyTypeName = journeyData.label;
        }
      } else {
        this.journeyData = {};
      }
    } catch (error) {
      this.expenseList = [];
      this.journeyData = {};
      this.lifeCoverList = [];
      this.journeyTypeName = undefined;
    } finally {
      global.loading(false);
    }
  }

  async fetchJourneyData() {
    let journeyId = this.journeyId;

    if (journeyId === undefined) return;

    var response = await axios.get(`${APITypes.FETCH_ALL_DATA}${journeyId}/`);

    if (response.data && response.status == "success") {
      this.journeyData = response.data;
    }
    return response;
  }

  setJourneyId(journeyId) {
    this.journeyId = journeyId;
  }

  async fetchAuthToken(email) {
    try {
      global.loading(true);

      var response = await axios.post(APITypes.AUTH_TOKEN);

      this.db.saveToken(response.data.access_token);

      if (response.status == "success") {
        this.loginUser(email);
      }

      global.loading(false);
    } catch (error) {
      global.loading(false);
    }
  }

  async generateToken(code) {
    try {
      global.loading(true);

      const data = {
        redirectUrl: config.redirectUrl,
        code: code,
      };

      var response = await axios.post(APITypes.GENERATE_TOKEN, data);

      if (response.data.error !== undefined) {
        return false;
      }

      this.db.saveRefreshToken(response.data.refresh_token);
      this.db.saveGeneratedToken(response.data.access_token);
      this.isUserLogin = true;
      this.props.history.push("/choose-needs");
      return true;
    } catch (error) {
      return false;
    } finally {
      global.loading(false);
    }
  }

  closeInvalidJourneyIdModal() {
    try {
      this.isInvalidJourneyId = false;
      if (this.axiosMsg.toLowerCase() == "not found") {
        this.journeyId = "";
      }
    } catch (error) {
    } finally {
      this.axiosMsg = "";
    }
  }

  setErrorModal(msg) {
    this.axiosMsg = msg;
    this.isInvalidJourneyId = msg !== "" ? true : false;
  }

  setNeedMasterList = (needMasterList) => {
    this.needMasterList = needMasterList;
  };

  async getPragatiData() {
    return this.agentDetails;
  }

  handleRedirectionToPragati = async () => {
    let formObj = document.getElementById("redirect-to-pragati-on-exit");
    console.log("handleRedirectionToPragati formObj :: ", formObj);
    formObj.submit();
    return;
    // let url = await localStorage.getItem(StorageKeys.PRAGATI_HOME_URL);
    // let authToken = await localStorage.getItem(StorageKeys.AUTH_TOKEN);
    // if (url) {
    //   let requestData = {
    //     Pragati_Token: authToken,
    //   };

    //   let requestHeaders ={
    //     headers:{
    //       "Content-Type": "application/x-www-form-urlencoded",
    //     }
    //   } ;
    //   console.log(url, requestData, requestHeaders);
    //   let response = await axios.post(url, requestData, requestHeaders);
    // this.authentication.signout();
    // }
  };

  async getHLAgentApp(rmCode) {
    let response = await axios.post(APITypes.HL_API, {
      agent_code: rmCode,
    });

    return response.data;
  }

  async getBancaPartnerDetails(partnerRmCode, vendorCode, loginCode, loginUniqueId) {
    let requestData = {
      partner_rm_code: partnerRmCode,
      vendor_code: vendorCode,
      login_code: loginCode,
      login_unique_id: loginUniqueId,
    };

    let response = await axios.post(APITypes.BANCA_DETAILS_API, requestData);

    return response;
  }

  setAgentdetails(agentDetailsObj) {
    this.agentDetails = agentDetailsObj;
  }

  setJourneyData(journeyDetails) {
    this.journeyData = journeyDetails
  }

  setSolutionData(solutionDetails) {
    this.solutionData = solutionDetails
  }
}

export default new AuthStore();
