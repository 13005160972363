// React Imports
import React,{useEffect, useState} from "react"
import { observer } from "mobx-react"
import { toJS } from "mobx"

// Package Imports
import { Image } from "react-bootstrap"

// Project Imports
import Utils from "./utils"
import SelectNeedCard from "../NeedCard"
import AuthStore from "modules/login/store/AuthStore"
import OptimizationStore from "modules/optimization/store/OptimizationStore"
import { TitleTextSubtextBottom } from "components"
import {
  ProtectionForMyFamilyImg,
  GrowMyMoneyImg,
  SaveForChildrenImg,
  SaveForRetirementImg,
  ProtectionForMyHealthImg,
  CloseIconBlack,
} from "constants/Images"

import needSelectedDeSelectedFunction from "./needSelectedDeSelectedFunction"
import AddNeedFormController from "./controller"
import fetchNeedMasterData from "utils/fetchNeedMasterData"

const NeedForm = (props) => {
  const [formController, setFormController] = useState(undefined)

  const settingSelectedValueinOptimisationStore = async (needId) => {

    const { performAddOperation,performDeleteOperation, differenceDeSelected,previouslySelected } = needSelectedDeSelectedFunction(formController)
    if(needId){

    let NeedmasterData = await fetchNeedMasterData()

    if (NeedmasterData.length <= 0) return

    let needData = NeedmasterData.find((item) => item.id === needId)

    let needUniqueName = needData?.unique_name

    const { journey_need: journeyNeed } = toJS(AuthStore.journeyData)
    const isSelected = journeyNeed.find(item => item.need === needId)?.is_selected

    if(isSelected) {
      OptimizationStore.onReselectNeed(needId)
      props.hideModal()
    }


    if(!isSelected &&  performAddOperation){
      OptimizationStore.showInsuredModalOnAddingNeed(needUniqueName)
      
      props.hideModal()
    }

    formController.$("final_selected").value = {}
  }

      if(previouslySelected){
        const { journey_need: journeyNeed } = toJS(AuthStore.journeyData)

        previouslySelected && previouslySelected.length > 0 && previouslySelected.forEach(needId => {
          const isSelected = journeyNeed.find(item => item.need === needId)?.is_selected
          if(isSelected) {
            OptimizationStore.onReselectNeed(needId)
          }
        })
        props.hideModal()
      }


    if(performDeleteOperation){
      /* do api class and hide the modal */
      let journeyData = toJS(AuthStore.journeyData)
      differenceDeSelected && differenceDeSelected.length > 0 && differenceDeSelected.map(differeneItem => {
        
        let NeedmasterData = AuthStore.needMasterList
        let needData = NeedmasterData.find((item) => item.id === differeneItem)
        let needUniqueName = needData?.unique_name

        //const need  = journeyData.family_product_details_list.find(item => item.need === differeneItem).need
        const journeyId = journeyData.journey_details.id
        const data = {
          journey_id: journeyId,
          need_id: differeneItem,
          is_selected: true,
          is_removed: true
        }
        OptimizationStore.removeNeed(data, needUniqueName)

      })
      props.hideModal()
    }

    formController.$("final_selected").value = {}
  }

  const onCancel = () => {
    props.hideModal()
  }

  useEffect(()=>{
    let controller = new AddNeedFormController()
    let journeyId = toJS(AuthStore.journeyId);
    let journeyData = toJS(AuthStore.journeyData)
    
    controller.setDefaults(journeyId, journeyData);
    setFormController(controller)
  },[])


  if(formController === undefined) return null

  const selectedNeed = formController.$("final_selected").value

  return (
    <div className="add-Need-modal-field-section pt-4 pb-lg-4 d-flex flex-column justify-content-between">
      <section>
        <div className="d-flex justify-content-end">
          <Image
            src={CloseIconBlack}
            className="close-btn-style"
            onClick={() => props.hideModal()}
          />
        </div>
        <div className="mb-2">
          <TitleTextSubtextBottom
            title={<span>Select a need to be added</span>}
          />
        </div>
        <div className="pl-lg-4 mb-lg-5">

          <div className="font-size-14 font-weight-light select-need-investment-title">
            Investment
          </div>
          <article className="row article-border">
            <div className="col-12 col-md-6 mb-3 mb-lg-0">
              <SelectNeedCard
                label="Grow my Money"
                image={GrowMyMoneyImg}
                isSelected={!formController.$("grow_my_money").disabled}
                uniqueName="Grow my Money"
                toggleNeedSelection={(currentDisabledState) =>
                  Utils.toggleDisabledState(
                    formController,
                    "grow_my_money",
                    currentDisabledState
                  )
                }
              />
            </div>
            <div className="col-12 col-md-6 mb-3 mb-lg-0">
              <SelectNeedCard
                label="Save for my Children"
                image={SaveForChildrenImg}
                isSelected={!formController.$("save_for_children").disabled}
                uniqueName="Save for my children"
                toggleNeedSelection={(currentDisabledState) =>
                  Utils.toggleDisabledState(
                    formController,
                    "save_for_children",
                    currentDisabledState
                  )
                }
              />
            </div>
          </article>

          <div className="font-size-14 font-weight-light select-need-retirement-title">
            Retirement
          </div>
          <article className="row article-border">
            <div className="col-12 col-md-6">
              <SelectNeedCard
                label="Save for my Retirement"
                image={SaveForRetirementImg}
                isSelected={!formController.$("save_for_retirement").disabled}
                uniqueName="Save for my Retirement"
                toggleNeedSelection={(currentDisabledState) =>
                  Utils.toggleDisabledState(
                    formController,
                    "save_for_retirement",
                    currentDisabledState
                  )
                }
              />
            </div>
          </article>


          <div className="font-size-14 font-weight-light select-need-protection-title">
            Protection
          </div>
          <article className="row article-border">
            <div className="col-12 col-md-6 mb-3 mb-lg-0">
              <SelectNeedCard
                label="Protection for my Family"
                image={ProtectionForMyFamilyImg}
                isSelected={
                  !formController.$("protection_for_my_family").disabled
                }
                uniqueName="Protection for my Family"
                toggleNeedSelection={(currentDisabledState) =>
                  Utils.toggleDisabledState(
                    formController,
                    "protection_for_my_family",
                    currentDisabledState
                  )
                }
              />
            </div>
            <div className="col-12 col-md-6 mb-3 mb-lg-0">
              <SelectNeedCard
                label="Protection for my Health"
                image={ProtectionForMyHealthImg}
                isSelected={
                  !formController.$("protection_for_my_health").disabled
                }
                uniqueName="Protection for my Health"
                toggleNeedSelection={(currentDisabledState) =>
                  Utils.toggleDisabledState(
                    formController,
                    "protection_for_my_health",
                    currentDisabledState
                  )
                }
              />
            </div>
          </article>
        </div>
      </section>

      <section className="row mt-5 mt-lg-3 ">
        <div className="col-12 col-lg-5">
          <div className="row">
            <div className="col-6  pb-1 pb-lg-0 order-1 order-lg-0 pl-lg-1">
              <button
                className="btn btn-link btn-lg select-need-modal-cta-btn select-need-modal-cta-btn-link py-2 py-lg-1 font-size-14 font-weight-medium"
                // onClick={() => props.hideModal()}
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>

            <div className="col-6 mb-3 mb-lg-0 order-0 order-lg-1 pr-lg-1">
              <button
                type="submit"
                disabled={!formController.$("enable_proceed").value}
                className={`btn btn-secondary btn-lg select-need-modal-cta-btn py-2 py-lg-1 font-size-14 font-weight-medium`}
                onClick={() =>
                  settingSelectedValueinOptimisationStore(selectedNeed)
                }
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default observer(NeedForm)
