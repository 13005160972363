// package imports
import { Image } from "react-bootstrap";

// Project imports
import { GrowGreenIcon, EscalationYellowIcon, EscalationRedIcon, RiskTabLeftBracket, RiskTabRightBracket } from "constants/Images";
import { CustomCheckbox } from "../../../../components";
import { calculate_input_values, fetchYouPayYouGetPrice } from "utils/optimization/calculateInputOutputValues";

const TitleComponent = (props) => {
  const { data, isSelected, risk, title, amount } = props;
  let riskFactor = "text-success";
  let riskTitle = "Low Risk";
  let riskImg = "";
  let riskSubTitle;

  let outputAmt;
  let yearlyOutputAmount;
  let yearlyOutputAmountBold;
  let totalOutputYear;
  let additionalOutputAmt;

  let inputAmount;
  let yearlyInputAmount;
  let totalInputYear;
  let totalOutputYearSuffixLabel, totalInputYearSuffixLabel;
  let inputOutputValues = calculate_input_values(data);

  switch (risk) {
    case "low":
      riskFactor = "text-success";
      riskTitle = "Low Risk";
      riskImg = GrowGreenIcon;
      outputAmt = "2272200";
      yearlyOutputAmount = "151480";
      totalOutputYear = "15";
      totalOutputYearSuffixLabel = "years";
      additionalOutputAmt = "";
      inputAmount = amount;
      yearlyInputAmount = "100000";
      totalInputYear = "10";
      totalInputYearSuffixLabel = "years";
      riskSubTitle = "Fully guaranteed returns";
      yearlyOutputAmountBold = true;
      break;
    case "medium":
      riskFactor = "mid-risk";
      riskTitle = "Medium Risk";
      riskImg = EscalationYellowIcon;
      outputAmt = "8353479";
      yearlyOutputAmount = "150000";
      totalOutputYear = "36";
      totalOutputYearSuffixLabel = "years";
      additionalOutputAmt = "151480";
      inputAmount = amount;
      yearlyInputAmount = "138318";
      totalInputYear = "10";
      totalInputYearSuffixLabel = "years";
      riskSubTitle = "Partially guaranteed returns";
      yearlyOutputAmountBold = true;
      break;
    case "high":
      riskFactor = "text-danger";
      riskTitle = "High Risk";
      riskImg = EscalationRedIcon;
      outputAmt = "6466120";
      yearlyOutputAmount = "150000";
      totalOutputYear = "30";
      totalOutputYearSuffixLabel = "years";
      additionalOutputAmt = "151480";
      inputAmount = amount;
      yearlyInputAmount = "100000";
      totalInputYear = "10";
      totalInputYearSuffixLabel = "years";
      riskSubTitle = "No guaranteed returns";
      yearlyOutputAmountBold = true;
      break;
  }

  const istabLineSelectedClass = (riskTitle) => {
    if (isSelected) {
      props.onTabSelected(riskTitle);
      return "active";
    } else {
      return "";
    }
  };

  const isTabSelectionHighlight = () => {
    if (isSelected) {
      return "SELECTED";
    } else {
      return "TAP TO SELECT";
    }
  };

  const toggleVisibiltyOfYearlyOutputAmount = () => {
    if (inputOutputValues.yearlyOutputAmount === "" || inputOutputValues.yearlyOutputAmount === 0) {
      return "invisible";
    }
  };

  const toggleVisibiltyOfYearlyInputAmount = () => {
    if (inputOutputValues.yearlyInputAmount === "") {
      return "invisible";
    }
  };

  const showAdditionalOutputAmount = () => {
    if (inputOutputValues.additionalOutputAmt) {
      return (
        <span className='font-weight-light'>
          + <ins>{inputOutputValues.additionalOutputAmt}</ins>
        </span>
      );
    } else {
      return <span></span>;
    }
  };

  return (
    <div className='text-left text-black'>
      <div className={`tab-line ${istabLineSelectedClass(riskTitle)}  mb-1`}></div>
      <CustomCheckbox
        isChecked={isSelected}
        onChange={() => {}}
        shape='rounded'
        title={
          <div className={`m-0 ml-2 font-size-14 font-weight-medium relative ${riskFactor}`}>
            {riskTitle}
            <img src={riskImg} id='risk-img-thumbnail' className='mx-2'></img>
            <span className='font-weight-light text-dark'>[{title}]</span>
            <span className='d-block font-size-12 font-weight-light position-absolute text-description-text-color '>{isTabSelectionHighlight()}</span>
          </div>
        }
        containerStyle='align-items-start align-items-md-center'
        mark='checkmark'
      />

      <div className='mt-3 tab-amount-container'>
        <p className='mb-0 font-size-10 font-weight-light text-description-text-color text-left'>You Get</p>
        <p className='mb-0 font-size-18 text-dark font-weight-medium '>
          {global.toINR(inputOutputValues.outputAmt)} {inputOutputValues.totalOutputAmtSuffixLabel}
        </p>
        <p className={`font-size-14 font-weight-light text-primary mb-0 ${toggleVisibiltyOfYearlyOutputAmount()}`}>
          <Image className='mr-2' src={RiskTabLeftBracket} alt='RiskTabLeftBracket' />
          <span className={`yearly-output-amount-container ${inputOutputValues.yearlyOutputAmountBold && 'font-weight-medium' } `}>
            {inputOutputValues.yearlyOutputAmountBold ? 
              <ins>{global.toINR(inputOutputValues.yearlyOutputAmount)}</ins> :
              <span>{global.toINR(inputOutputValues.yearlyOutputAmount)}</span>
            }
          </span>{" "}
          {inputOutputValues.outputStr} {inputOutputValues.totalOutputYear} {inputOutputValues.totalOutputYearSuffixLabel}
          {showAdditionalOutputAmount()}
          <Image className='ml-2' src={RiskTabRightBracket} alt='RiskTabRightBracket' />{" "}
        </p>
        {inputOutputValues.belowOutputYearSuffixLabel ? <p className={`font-size-12 font-weight-light text-primary`}>{inputOutputValues.belowOutputYearSuffixLabel}</p> : <div></div>}
      </div>

      <div className='tab-amount-container'>
        <p className='mb-0 font-size-10 font-weight-light text-description-text-color'>You Pay</p>
        <p className='mb-0 font-size-18  text-dark font-weight-medium '>{global.toINR(inputOutputValues.inputAmount)}</p>
        <p className={`font-size-14 font-weight-light text-primary ${toggleVisibiltyOfYearlyInputAmount()}`}>
          <Image className='mr-2' src={RiskTabLeftBracket} alt='RiskTabLeftBracket' />
          <span className='font-weight-medium yearly-input-amount-container'>
            <ins>{global.toINR(inputOutputValues.yearlyInputAmount)}</ins>
          </span>{" "}
          {inputOutputValues.inputStr} for {inputOutputValues.totalInputYear} {inputOutputValues.totalInputYearSuffixLabel}
          <Image className='ml-2' src={RiskTabRightBracket} alt='RiskTabRightBracket' />{" "}
        </p>
      </div>

      <div className={`${riskFactor} font-size-14 text-center mb-0`}>{riskSubTitle}</div>
    </div>
  );
};

export default TitleComponent;
