// React Imports
import React from "react";

// Package Imports
import { Col, Row, Card, Image, Form } from "react-bootstrap";
import { observer } from "mobx-react";

// Project Imports
import { CustomCheckbox, BottomsheetDropdown } from "components";
import { ProtectionForMyFamilyImg } from "constants/Images";
import CalculatorCardComponent from "components/ui/calculator-card-component/CalculatorCardComponent";
import * as Constants from "constants/Constants";
import CalculatorTypeEnum from "../CalculatorTypeEnum";

import { NUMBER_REGEX } from 'constants/Constants'
import ChooseNeedUtils from '../form/choose-needs/utils'

// CSS Imports
import "./ProtecttionOfMyFamily.css";

const ProtectionOfMyFamily = (props) => {

  const { isSelected, onMonthlyIncomeChange, monthlyIncomeValue, onTargetAmountTypeChange, formController } = props;
  let showTargetAmountDropdown = formController.$("protection_for_my_family.target_amount_dropdown").type === "text";
  let showTargetAmountValue = formController.$("protection_for_my_family.target_amount_value").type === "text";
  const protectionForMyFamily = formController.$("protection_for_my_family")

  return (
    <div className='d-flex flex-column flex-lg-row position-relative'>
      <div className={!isSelected ? "card-overlay" : null}></div>
      <Card className='border-0 protection-of-my-family-card flex-grow-1'>
        <Card.Body className='p-2 pb-3'>
          <div>
            <Row className='d-flex align-items-start'>
              <Col xs={9} className='pr-0'>
                <CustomCheckbox
                  isChecked={isSelected}
                  onChange={() => props.toggleNeedSelection(isSelected)}
                  id='protectionMyFamily'
                  shape='rounded'
                  title={protectionForMyFamily.label}
                  titleStyle='text-primary font-weight-medium font-size-16 font-size-lg-14 pl-2'
                  containerStyle='align-items-start'
                  checkmarkStyle='radio-margin-top'
                  mark='checkmark'
                  className='choose-Need-checkBox'
                />

                <div className='ml-4 pl-2'>
                  <div className='align-items-center d-flex mb-2 justify-content-between flex-md-column flex-row align-items-md-start'>
                    <div className='d-md-flex align-items-center justify-content-between'>
                      <span className='font-size-14 font-size-md-12 font-weight-light description-text-color mb-0 mt-2 mt-md-0'>My monthly income is </span>

                      <div className='pl-0 pl-lg-2 mt-2 mt-lg-0'>
                        <Form.Control
                          required
                          // type="number"
                          min="0"
                          onBlur={() => ChooseNeedUtils.updateCalculatorValues(monthlyIncomeValue,formController)}
                          placeholder='Monthy Income'
                          value={monthlyIncomeValue}
                          onChange={(event) => {
                            if(NUMBER_REGEX.test(event.target.value)){
                              // ChooseNeedsStore.setMonthlyIncomeOfSelf(event.target.value)
                              return onMonthlyIncomeChange(event.target.value)
                            }
                            if(event.target.value.length <= 1){
                              return onMonthlyIncomeChange('') 
                            }
                          }}
                          style={protectionForMyFamily.$("monthly_income").error ? { border: "1px solid red" } : { border: "1px solid #797979" }}
                          className='monthly-income-field font-size-12 font-weight-light'
                        />
                        {protectionForMyFamily.$("monthly_income").error && <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>{protectionForMyFamily.$("monthly_income").error}</p>}
                      </div>
                    </div>
                  </div>
                  <p className='font-size-14 font-size-md-12 font-weight-light description-text-color mb-1 mt-2 mt-md-0'>To take care of my family's needs,</p>
                </div>
              </Col>
              <Col xs={3} className='text-lg-right'>
                <Image className='family-choose-card-img' src={ProtectionForMyFamilyImg} />
              </Col>
            </Row>
            <div className='ml-4 pl-2 protection-dropdown-row-style'>
              <Row>
                <Col xs={12} lg={7}>
                  <BottomsheetDropdown
                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                    menuItemStyle='d-flex justify-content-between align-items-center'
                    selectedLabelKey='label'
                    bottomsheetTitle={protectionForMyFamily.$("target_amount_type").extra.bottomsheetTitle}
                    menuList={props.targetAmountTypeList}
                    onSelect={(event) => onTargetAmountTypeChange(event)}
                    value={props.targetAmountTypeValue}
                    showErrorMsg={protectionForMyFamily.$("target_amount_type").error}
                    errorMsg={protectionForMyFamily.$("target_amount_type").error}
                    disabled={!isSelected}
                  />
                </Col>

                {showTargetAmountDropdown && (
                  <Col xs={12} lg={5} className="mt-2 mt-lg-0 my-2 my-lg-0">
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      selectedLabelKey='label'
                      bottomsheetTitle={protectionForMyFamily.$("target_amount_dropdown").extra.bottomsheetTitle}
                      menuList={formController.$("protection_for_my_family.target_amount_dropdown").extra.optionList}
                      onSelect={formController.$("protection_for_my_family.target_amount_dropdown").onChange}
                      value={formController.$("protection_for_my_family.target_amount_dropdown").value}
                      showErrorMsg={protectionForMyFamily.$("target_amount_dropdown").error}
                      errorMsg={protectionForMyFamily.$("target_amount_dropdown").error}
                      disabled={!isSelected}
                    />
                  </Col>
                )}

                {showTargetAmountValue && (
                  <Col xs={12} lg={4}>
                    <Form.Control
                      // placeholder='Monthly Income'
                      // type="number"
                      value={formController.$("protection_for_my_family.target_amount_value").value}
                      // onChange={formController.$("protection_for_my_family.target_amount_value").onChange}
                      onChange={(event) => {
                        if(NUMBER_REGEX.test(event.target.value)){
                          return formController.$("protection_for_my_family.target_amount_value").value = event.target.value
                        }
                        if(event.target.value.length <= 1){
                          return formController.$("protection_for_my_family.target_amount_value").value = ""
                        }
                      }}
                      style={formController.$("protection_for_my_family.target_amount_value").error ? { border: "1px solid red" } : { border: "1px solid #797979" }}
                      className='monthly-income-field font-size-12 font-weight-light'
                    />
                    {protectionForMyFamily.$("target_amount_value").error && <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>{protectionForMyFamily.$("target_amount_value").error}</p>}
                    {(props.refreshValues || protectionForMyFamily.$("is_value_updated").value) && <p className='mb-0 font-weight-light font-size-12 align-items-start d-flex' style={{ color: 'green' }}>{"Values refreshed based on new data"}</p>}
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </Card.Body>
      </Card>

      <div className='protection-of-my-family-or-container'>
        <span className='protection-of-my-family-or-text'>OR</span>
      </div>

      <CalculatorCardComponent
        chooseNeedFormController = {props.formController}
        dateOfBirthFromChooseNeeds={props.dateOfBirthFromChooseNeeds}
        setDateOfBirthFromChooseNeeds={props.setDateOfBirthFromChooseNeeds}
        monthlyExpenseValuesFromChooseNeeds={props.monthlyExpenseValuesFromChooseNeeds}
        setMonthlyExpenseValuesFromChooseNeeds={props.setMonthlyExpenseValuesFromChooseNeeds}
        monthlyIncomeValue={monthlyIncomeValue}
        targetAmountTypeValue={props.targetAmountTypeValue}
        calculatorModalType={CalculatorTypeEnum.PROTECTION}
        modalDescription={Constants.CALCULATOR_MODAL_DESCRIPTION}
        title={<p className="font-size-14 font-size-md-12 mb-0 " style={{lineHeight: 1}}>Protection <br/>Calculator</p>}
        useValueCallback={props.useValueCallback}
        thumbNailImg={ProtectionForMyFamilyImg}
      />
    </div>
  );
};

export default observer(ProtectionOfMyFamily);
