// package imports
import dvr from "mobx-react-form/lib/validators/DVR";
import vjf from "mobx-react-form/lib/validators/VJF";
import { Form } from "mobx-react-form";
import validatorjs from "validatorjs";

import moment from "moment";

// project imports
import FamilyDetailsStore from "../../../../family-details-form/store/FamilyDetailsStore";
import setInitialDefaults from "./initialDefaults";
import formObserver from "./observers";
import Utils from "./utils";
import * as keys from "./keys";
import { CovidVaccinationStatusList } from 'constants/CovidVaccinationStatusList';
import OptimizationStore from "modules/optimization/store/OptimizationStore";

class SaralJeevanBimaFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
      vjf: vjf(),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "Select",
          disabled: false,
          extra: {
            // options: [
            //   { id: "1", label: "Annual" },
            //   { id: "2", label: "Semi-Annual" },
            //   { id: "4", label: "Monthly" },
            //   { id: "5", label: "Single" },
            // ],
            options: Utils.generatePPF(),
          },
        },
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "Select",
          extra: {
            options: Utils.generatePT(),
          },
        },
        {
          name: "premium-paying-term",
          label: "Premium Paying Term",
          placeholder: "Select",
          extra: {
            options: [],
          },
        },
        {
          name: "occupation",
          label: "Occupation",
          placeholder: "Select",
          extra: {
            options: [
              { label: "Salaried", id: "SA" },
              { label: "Self Employed (Professional)", id: "SM" },
              { label: "Self Employed (Business)", id: "BM" },
              { label: "Agriculture", id: "A" },
              { label: "Housewife", id: "H" },
              { label: "Retired", id: "R" },
              { label: "Student", id: "ST" },
              { label: "Labourer/Worker/Others", id: "LWO" },
            ],
          },
        },
        {
          name: "occupation-of-parent-spouse",
          label: "Occupation of Parent/Spouse",
          placeholder: "Select",
          extra: {
            options: [
              { label: "Salaried", id: "SA" },
              { label: "Self Employed (Professional)", id: "SM" },
              { label: "Self Employed (Business)", id: "BM" },
              { label: "Agriculture", id: "A" },
              { label: "Housewife", id: "H" },
              { label: "Retired", id: "R" },
              { label: "Student", id: "ST" },
              { label: "Labourer/Worker/Others", id: "LWO" },
            ],
          },
        },
        {
          name: "annual-income-of-proposer/parent/spouse",
          label: "Annual income of Proposer/Parent/Spouse",
          value: "",
          extra: {
            annualIncomeChange(e, form, data) {
              let amt = e.target.value;
              let regex = /^(([1-9]\d*))?$/;
              if (regex.test(amt)) {
                form.$("annual-income-of-proposer/parent/spouse").value = amt;
              }
            },
            annualIncomeBlur(form, data) {
              let annualIncomeValue = form.$("annual-income-of-proposer/parent/spouse").value;
              Utils.updateBi(keys.ZP_ANNINCOME, annualIncomeValue, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "education",
          label: "Education",
          placeholder: "Select",
          extra: {
            options: [
              { label: "Below 10th", id: "1" },
              { label: "10th Passed", id: "2" },
              { label: "12th Passed", id: "3" },
              { label: "Graduate", id: "4" },
              { label: "Post Graduate", id: "5" },
              { label: "Professional", id: "6" },
            ],
          },
        },
        {
          name: "sum-assured",
          label: "Sum Assured",
          value: "",
          extra: {
            sumAssuredAmtChange(e, form, data) {
              let amt = e.target.value;
              let regex = /^(([1-9]\d*))?$/;
              if (regex.test(amt)) {
                form.$("sum-assured").value = amt;
              }
            },
            sumAssuredAmtBlur(form, data) {
              let sumAssuredValue = form.$("sum-assured").value;
              Utils.updateBi(keys.PR_SA, sumAssuredValue, data.data.product_id, form, data);
            },
          },
        },
        // for death cover
        {
          name: "income",
          label: "Income",
          placeholder: "Income",
          value: "",
          extra: {
            calculateIncome(form, data) {
              let income = form.$("income").value;
              Utils.updateBi(keys.INCOME, income, data.data.product_id, form, data);
            },
          },
          disabled: true,
        },
        {
          name: "premium-amt",
          label: "Premium",
          placeholder: "Premium",
          disabled: false,
          extra: {
            calculatePremiun(form, value, data) {
              // let premiumAmount = 0;
              // let inputMode = Number(form.$("payment-frequency").value.id);

              // let amount = Number(value);
              // switch (inputMode) {
              //   case 1:
              //     premiumAmount = "" + amount * 1;
              //     break;
              //   case 2:
              //     premiumAmount = "" + amount * 2;
              //     break;
              //   case 3:
              //     premiumAmount = "" + amount * 4;
              //     break;
              //   case 4:
              //     premiumAmount = "" + amount * 12;
              //     break;
              // }
              // Utils.updateBi(keys.PREMIUM, premiumAmount, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "covid-vaccination-status",
          label: "Covid Vaccination Status",
          placeholder: "Covid Vaccination Status",
          value: {},
          extra: {
            changeVaccinationStatus(form, value, data) {
              let oldVaccinationStatus = form.$("covid-vaccination-status").value.id
              form.$("covid-vaccination-status").value = value
              if (oldVaccinationStatus != value.id) {
                let requestData = {
                  covid_vaccination_status: value.id,
                  family_product_id: data.data.family_product_id,
                  product_id: data.data.product_id,
                  package_id: data.data.package_id
                }
                OptimizationStore.getRevisedRecoSaTermNeedApiCall(requestData)
              }
            },
            optionList: CovidVaccinationStatusList
          },
        }
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);
    // Observer fields
    formObserver(this, data);
  }
}

export default SaralJeevanBimaFormController;
