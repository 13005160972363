// React Imports
import React from "react"

// Package Imports
import { Col, Row } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import ModalBody from "react-bootstrap/ModalBody"
import { observer } from "mobx-react"
import { toJS } from "mobx"

// Project Imports
import { Button as UiButton, NextButton } from "components"
import QmsDetailsPageStore from "../qms-details-page/QmsDetailsPageStore"

// CSS Imports
import "./start-application-modal.css"

const StartApplicationModal = (props) => {
	let applicationDetails = toJS(QmsDetailsPageStore.seletedTabData)
	let laList = QmsDetailsPageStore.fetchLaList(applicationDetails)
	console.log("props.solutionId :: ",props.solutionId)
	console.log("applicationDetails :: ",applicationDetails)

	return (
		<div className="start-application-modal-div ">
			<Modal
				show={QmsDetailsPageStore.showStartApplicationModal}
				backdrop="static"
				onHide={() => { QmsDetailsPageStore.onHideApplication }}
				as="section"
				className="start-application-modal"
				aria-labelledby="contained-modal-title-vcenter"
				centered
				keyboard={false}
			>
				<ModalBody className="start-application-modal-body">
					<div className="start-application-modal-body-container">
						<Row className="start-application-modal-body-main-row">
							<Col md="4" className="title-container solution-application-title-container py-md-5 py-2 pr-md-4">
								<div className="d-flex flex-md-row-reverse">
									<p className="mb-0 font-size-20">Initiate</p>
								</div>
								<div className="d-flex flex-md-row-reverse">
									<p className="mb-0 font-size-24 font-weight-medium solution-application-title">Solution Application</p>
								</div>
								<div className="d-flex flex-md-row-reverse mt-4">
									<p className="mb-0 font-size-12 client-name">{applicationDetails?.proposer_salutation} {applicationDetails?.proposer_first_name} {applicationDetails?.proposer_middle_name} {applicationDetails?.proposer_last_name} [{applicationDetails?.family_member_type}] & Family</p>
								</div>
								<div className="d-flex flex-md-row-reverse">
									<p className="mb-0 font-size-10 font-weight-medium family-id">Family ID:{props.journeyId}</p>
								</div>
							</Col>

							<Col md="8" className="solution-application-title-container py-md-5 py-2 pl-md-5">
								<div >
									{applicationDetails?.solution_id && <p className="mb-0 font-size-12 font-weight-medium solution-id">Soln ID:{applicationDetails?.solution_id || '-'}</p>}
									<p className="mb-0 font-size-12 client-name mt-4">Proposer</p>
									<p className="mb-0 font-size-16 font-weight-medium proposer-name">{applicationDetails?.proposer_salutation} {applicationDetails?.proposer_first_name} {applicationDetails?.proposer_middle_name} {applicationDetails?.proposer_last_name} ({applicationDetails?.family_member_type})</p>
								</div>

								{laList.map((laDetails, laIndex) =>
									<Row key={`start-application-${laIndex}`}>
										<>
											<Col md="5">
												<p className="mb-0 font-size-12 client-name mt-4">Life Assured</p>
												<p className="mb-0 font-size-16 font-weight-medium proposer-name mt-2">{laDetails?.la_salutation} {laDetails?.la_name} ({laDetails?.la_family_member_type})</p>
											</Col>
											<Col md="7">
												<p className="mb-0 font-size-12 client-name mt-4">Product Name</p>
												{laDetails?.product_details.map((productDetails, index) =>
													<p key={"productDetails-"+index} className="mb-0 font-size-16 font-weight-300 proposer-name mt-2">{productDetails.product_name}</p>
												)}
											</Col>

										</>
									</Row>
								)}

								<Col className="pl-0">
									<NextButton
										buttonText="Start Application"
										className="font-size-14 font-weight-medium px-4 py-2 mr-3 mt-5 w-100 next-button"
										onClick={() => QmsDetailsPageStore.startApplication(props.journeyId, applicationDetails?.solution_id)}
									/>
									<UiButton
										className='mr-1 mr-md-3 font-weight-light font-size-14 px-1 py-2 mt-md-5 mt-3 w-100 next-button'
										variant='outline-secondary'
										buttonText="Cancel"
										onClick={() => QmsDetailsPageStore.onHideApplication()}
									/>
								</Col>
							</Col>
						</Row>
					</div>
				</ModalBody>
			</Modal>
		</div >
	)
}
export default observer(StartApplicationModal)
