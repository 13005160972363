const QmsQuoteStatus = {
    LEAD_GENERATED: "lead_generated",
    PROPOSER_INCOMPLETE: "proposal_incomplete",
    PAYMENT_INCOMPLETE: "payment_incomplete",
    DOCUMENT_UPLOAD_INCOMPLETE: "document_upload_incomplete",
    MEDICAL_SCHEDULE_INCOMPLETE: "medical_schedule_incomplete",
    DOCUMENT_UPLOAD_AND_ESIGN_PENDING: "document_uploaded_and_esign_pending",
    DOCUMENT_UPLOAD_AND_PAYMENT_PENDING: "document_uploaded_and_payment_pending",
    ACR_INCOMPLETE: "acr_incomplete",
    PROPOSAL_COMPLETED: "proposal_completed",
}

export default QmsQuoteStatus;
