import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import OptimizationStore from '../../../store/OptimizationStore';
import isTotalFundCountValid from 'utils/isTotalFundCountValid';

class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    getTotalAmount = (premium, frequency) => {
        // let totamAmount = 0;
        // try {
        //     switch (frequency) {
        //         case '1':
        //             totamAmount = premium * frequency;
        //             break;

        //         case '2':
        //             totamAmount = premium * frequency * 2;
        //             break;
        //         case '3':
        //             totamAmount = premium * frequency * 4;
        //             break;

        //         case '4':
        //             totamAmount = premium * frequency * 12;
        //             break;
        //     }
        //     return totamAmount;
        // } catch (error) {
        //     return 0;
        // }
    }

    // Minimum Premium Validation for Base Plan
    premiumValidationForBaseOption = (form, data) => {
        // let premium = form.$('premium').value;
        // let frequency = form.$('payment-frequency').value.id;
        // let ppt = form.$('premium-term').value.id;
        // let planOption = form.$('policy-option').value.id;
        // let totalAmount = this.getTotalAmount(premium, frequency);

        // if (planOption != 1) {
        //     return;
        // }

        // if (ppt < 10 && totalAmount < 24000) {
        //     this.errorMessage.push(errorMessageKeys.minimum_premium_base_plan.minimum_premium_one);
        // }

        // if (ppt >= 10 && totalAmount < 12000) {
        //     this.errorMessage.push(errorMessageKeys.minimum_premium_base_plan.minimum_premium_two)
        // }
    }

    // Minimum Premium Validation for Other Plan
    premiumValidationForOtherOption = (form, data) => {
        // let premium = form.$('premium').value;
        // let frequency = form.$('payment-frequency').value.id;
        // let ppt = form.$('premium-term').value.id;
        // let planOption = form.$('policy-option').value.id;
        // let totalAmount = this.getTotalAmount(premium, frequency);

        // if (planOption == 1) {
        //     return;
        // }

        // if (ppt < 10 && totalAmount < 48000) {
        //     this.errorMessage.push(errorMessageKeys.minimum_premium_other_plan.minimum_premium_one);
        // }

        // if (ppt >= 10 && totalAmount < 24000) {
        //     this.errorMessage.push(errorMessageKeys.minimum_premium_other_plan.minimum_premium_two)
        // }
    }

    // Minimum entry age for plan options
    minimumEntryAgeForPlanOptions = (form, data) => {
        // let age = data.data.age;
        // let planOption = form.$('policy-option').value.id;

        // if (planOption == 2 && age < 18) {
        //     this.errorMessage.push(errorMessageKeys.entry_age.life_partner)
        // }

        // if (planOption >= 133 && age < 18) {
        //     this.errorMessage.push(errorMessageKeys.entry_age.child_variant)
        // }
    }

    maximumEntryAGeForPlanOptions = (form, data) => {
        // let age = data.data.age;
        // let planOption = form.$('policy-option').value.id;
        // let ppt = form.$('premium-term').value.id;

        // if (ppt < 10) {
        //     if (planOption == 1 && age > 50) {
        //         this.errorMessage.push(errorMessageKeys.maturity_age.greater_than_50)
        //     }
        //     if (planOption == 2 && age > 45) {
        //         this.errorMessage.push(errorMessageKeys.maturity_age.greater_than_45)
        //     }
        //     if (planOption >= 133 && age > 40) {
        //         this.errorMessage.push(errorMessageKeys.maturity_age.greater_than_40)
        //     }
        // } else {
        //     if (planOption == 1 && age > 60) {
        //         this.errorMessage.push(errorMessageKeys.maturity_age.greater_than_60)
        //     }
        //     if (planOption == 2 && age > 50) {
        //         this.errorMessage.push(errorMessageKeys.maturity_age.greater_than_50)
        //     }
        //     if (planOption >= 133 && age > 40) {
        //         this.errorMessage.push(errorMessageKeys.maturity_age.greater_than_40)
        //     }
        // }
    }

    // Sub Product validation

    subProductValidation = (form, data) => {
        // let planName = data.data.product_name;
        // let swpValue = form.$('systematic-withdrawal-option').value.id;
        // let planOption = form.$('policy-option').value.id;
        // if (planName == 'Systematic Withdrawal Plan (Wealth Secure+)') {
        //     if (swpValue != 5) {
        //         this.errorMessage.push(errorMessageKeys.sub_product_option.SWP)
        //     }
        // }

        // if (planName == 'Child variant (Wealth Secure+)') {
        //     if (planOption < 133) {
        //         this.errorMessage.push(errorMessageKeys.sub_product_option.child_variant)
        //     }
        // }
    }

    // PT, PPT validation
    validatePPT = (formRef, data) => {
        // let age = 100 - (data.data.bi_request.LI_ENTRY_AGE)
        // let pt = formRef.$('policy-term').value.id;
        // let ppt = formRef.$('premium-term').value.id;
        // if (ppt > pt) {
        //     this.errorMessage.push(errorMessageKeys.normalPPT)
        // }

        // if (pt == age) {
        //     if (ppt < 10) {
        //         this.errorMessage.push(errorMessageKeys.normalPPT)
        //     }
        // }
    }

    // Second Life Assured name validation
    validateSecondLifeAssuredName = (formRef) => {
        // let name = formRef.$('spouse-name').value;
        // let policyOption = formRef.$('policy-option').value.id;

        // if (policyOption == 1 || policyOption == 133) {
        //     return;
        // }

        // if (name?.length == undefined || name?.length == 0) {
        //     this.errorMessage.push(errorMessageKeys.spouse_name_required)
        // }

        // if (name?.length > 20) {
        //     this.errorMessage.push(errorMessageKeys.spouse_name)
        // }
    }

    // Second Life Assured Gender validation
    validateSecondLifeAssuredGender = (formRef, data) => {
        // let gender = data.data.bi_request.LI_GENDER;
        // let spouseGender = formRef.$('spouse-gender').value;
        // let policyOption = formRef.$('policy-option').value.id;

        // if (policyOption == 1 || policyOption == 133) {
        //     return;
        // }

        // if (spouseGender == undefined || spouseGender == '') {
        //     this.errorMessage.push(errorMessageKeys.spouse_gender_required)
        //     return;
        // }

        // if (gender == spouseGender.key) {
        //     this.errorMessage.push(errorMessageKeys.spouse_gender)
        // }
    }


    // Second Life Assured DOB validation
    validateSecondLifeAssuredDob = (formRef, data) => {

        // let spouseDob = formRef.$('spouse-dob').value;
        // let policyOption = formRef.$('policy-option').value.id;

        // if (policyOption == 1 || policyOption == 133) {
        //     return;
        // }

        // if (spouseDob == undefined || spouseDob == '') {
        //     this.errorMessage.push(errorMessageKeys.spouse_dob_required)
        // }
    }

    // 100% Fund Allocation validation
    fundAllocationValidations = (formRef) => {
        let fundStratergyElem = formRef.$("fund-strategy");
        let fundsList = fundStratergyElem.extra.fundList;
        let currentFundStratergy = fundStratergyElem.value.id;
        let isCountValid = false;

        // Return for Life Stage and duration based Strategy
        if (currentFundStratergy != 1) return

        isCountValid = isTotalFundCountValid(fundsList);

        if (!isCountValid) {
            this.errorMessage.push("Total Fund allocation must be 100%")
        }
    }

}
export default new Validator();