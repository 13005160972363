import React, { Component } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import { CustomCheckbox } from "../../../../components";

import { observer } from "mobx-react"

import PropTypes from "prop-types";

const FundDistributionModal = observer(
  class FundDistributionModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedMembers: {},
        fundList: this.props.fundList,
        remainingFund: 100,
        totalFund: 0,
        isFormEditStart: false,
      };
      this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
      this.calculateFund = this.calculateFund.bind(this);
    }

    componentDidMount() {
      this.calculateFund();
    }

    isNormalInteger = (str) => {
      var n = Math.floor(Number(str));
      return n !== Infinity && String(n) === str && n >= 0;
    };

    onCheckBoxChange = (value, item, key) => {
      if (key == "value" && value) {
        console.log(value);
        if (!this.isNormalInteger(value)) {
          return;
        }
        this.setState({ isFormEditStart: true });
      }

      if (key == "checkedValue" && !value) {
        item["value"] = 0;
      }

      item[key] = value;
      this.setState({ fundList: this.state.fundList });
      this.calculateFund();
    };
    calculateFund = () => {
      var total = this.state.fundList
        .filter((item) => this.isNormalInteger(item.value))
        .map((item) => parseInt(item.value))
        .reduce((a, b) => a + b, 0);
      this.setState({ remainingFund: 100 - total, totalFund: total });
    };
    onClose = () => {
      this.props.onHide(this.state.fundList);
    };

    render() {
      const {
        isFormEditStart,
        fundList,
        remainingFund,
        totalFund,
      } = this.state;
      let { show } = this.props;
      return (
        <div>
          <Modal
            size="lg"
            className="save-session-modal fund-distribution-modal"
            show={show}
            centered
            onHide={this.onClose}
            keyboard={false}
          >
            <Modal.Header closeButton className="border-0 fund-distribution-modal-padding">
              <Modal.Title className="font-size-16 text-header-style">
                <div className="font-weight-normal text-primary">
                  Self Managed Strategy - <strong>Fund Distribution</strong>
                </div>
                <small className="font-weight-light font-size-10 fund-distribution-sub-heading">
                  Use this tool to discuss and diversify the investment. Select
                  Funds and allocate the distribution %.
                </small>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="fund-distribution-modal-padding border-0">
              <div className="d-flex flex-wrap align-items-center mb-2">
                <div className="d-flex align-items-center mb-2">
                  <div className="font-weight-light font-size-14">Remaining %</div>
                  <div className="border border-dark p-1 mx-2 total-allocation-value-box rounded-sm">
                    <div className="d-flex justify-content-between">
                    <span className="text-secondary">{ Math.sign(remainingFund) === -1 ? 0 : remainingFund}</span>
                    <span className="text-muted font-weight-light ">%</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center mb-2 position-relative">
                  <div className="font-weight-light font-size-14">Allocated %</div>
                  <div className="border border-dark p-1 mx-2 total-allocation-value-box rounded-sm">
                    <div className="d-flex justify-content-between">
                    <span className="text-success">{totalFund > 100 ? 100 : totalFund}</span>
                    <span className="text-muted font-weight-light ">%</span>
                    </div>
                    {isFormEditStart && totalFund == 100 &&
                    <span className="text-success font-weight-bold font-size-20 position-absolute fund-strategy-tickmark">&#10004;</span>
                    }
                  </div>
                </div>
                {isFormEditStart && totalFund > 100 && (
                  <div className="text-danger font-size-14 mb-2">
                    [ Total allocation cannot be more than 100% ]
                  </div>
                )}
                {isFormEditStart && totalFund == 100 && (
                  <div className="text-success font-size-14 mb-2">[ Allocation Complete ]</div>
                )}
                {isFormEditStart && totalFund < 100 && (
                  <div className="text-danger mb-2 font-size-14">
                    [ Total allocation should be 100% ]
                  </div>
                )}
              </div>
              <Row>
                {fundList.map((item, index) => (
                  <Col key={index} lg={6} className="mb-2">
                    <Row>
                      <Col lg={6} className="d-flex align-items-center">
                        <CustomCheckbox
                          shape="rounded"
                          title={item.label}
                          titleStyle="text-primary-title-label-color font-weight-light font-size-14 card-top-title pl-2 py-2"
                          isChecked={item.checkedValue}
                          onChange={(checked) =>
                            this.onCheckBoxChange(checked, item, "checkedValue")
                          }
                          mark="checkmark"
                        />
                      </Col>
                      {item.checkedValue && (
                        <Col lg={6}>
                          <div className="d-flex align-items-center py-lg-1">
                            <input
                              type="number"
                              className="fund-strategy-input font-size-12 font-weight-medium"
                              value={item.value}
                              onChange={(e) => {
                                let value = e.target.value;
                                this.onCheckBoxChange(value, item, "value");
                              }}
                              min="0"
                              max="100"
                            />
                            <div className="ml-2 font-weight-light font-size-12">% out of <span className="text-secondary font-weight-medium">100%</span></div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                ))}
              </Row>
              <div className="my-3">
                <Button
                  variant="secondary"
                  size="sm"
                  className="font-size-14 m-0 py-1"
                  onClick={() => this.props.onSetDistribution(fundList, this.props.formRef, this.props.data)}
                  disabled={totalFund !== 100}
                >
                  {this.props.submitButtonText}
                </Button>
                <Button
                  variant="outline-secondary"
                  size="lg"
                  className="font-size-14 m-0 py-1 px-4 mx-2"
                  onClick={this.onClose}
                >
                  Cancel
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      );
    }
  }
);

FundDistributionModal.defaultsProps = {
  onHide: () => { },
  onSetDistribution: () => { },
  fundList: []
};

FundDistributionModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onSetDistribution: PropTypes.func,
  submitButtonText: PropTypes.string,
  fundList: PropTypes.array,
  onHide: PropTypes.func,
  formRef: PropTypes.any,
  data: PropTypes.object
};

export default FundDistributionModal;
