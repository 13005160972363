import { EyeIcon } from 'constants/Images';
import React, { Component } from 'react'

import { Form, Image } from 'react-bootstrap';

import './floating-input-component.css';


class FloatingInputComponent extends Component {

  validateValue = (event) => {
    let enteredValue = event.target.value;
    if (this.props.regexPattern) {
      if (enteredValue && !this.props.regexPattern.test(enteredValue)) {
        return
      }
      this.props.onChange(enteredValue)
    } else {
      this.props.onChange(enteredValue)
    }
  }
  render() {
    const { inputtype, righticon, placeholdertext, errormessage, textValue, regexPattern, onChange, ...props } = this.props
    return (
      <div className='text-input-component'>
        <div id='float-label'>
          <Form.Control className={`${textValue.length > 0 ? 'active' : ''} ${errormessage !== '' ? 'validation-border' : ''} input-value`} value={textValue} type={inputtype} {...props} onChange={this.validateValue} />
          {righticon != '' &&
            <div onClick={this.changePassType} className={`text-field-append cursor-pointer ${inputtype == 'Password' ? 'd-block' : 'd-none'} `}>
              <Image src={EyeIcon} alt='eye-icon' />
            </div>
          }
          {placeholdertext != '' &&
            <Form.Label className={`${textValue.length > 0 ? 'active' : ''}`}>
              {placeholdertext}
            </Form.Label>
          }
        </div>
        {errormessage != '' &&
          <p className='text-danger mt-1 mb-0 font-size-12 align-items-start d-flex'>{errormessage}</p>
        }
      </div>
    )
  }
}

FloatingInputComponent.defaultProps = {
  isnumberfield: 'false',
  ischarfield: 'false'
}

export default FloatingInputComponent

