// React Imports
import React, { Component } from "react"

// Package Imports
import Modal from "react-bootstrap/Modal"
import isObjectEmpty from "utils/isObjectEmpty"
import { observer } from "mobx-react"
import * as APITypes from "../../../constants/API"
import axios from "../../../utils/axios"
import { toJS } from "mobx"


// Project Immports
import BottomsheetDropdown from "../bottomsheet-dropdown/BottomsheetDropdown"
import MaturityPayOutModalForm from "./maturityForm"
import maturityPayOutModeFormController from "./maturityForm/controller"
import AuthStore from "modules/login/store/AuthStore"
import OptimizationStore from "modules/optimization/store/OptimizationStore"

// css imports
import "./Maturity-pay-out-modal.css"
class MaturityPayOutModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      formController: undefined,
    }
    this.maturityPayOutModeOnChange = this.maturityPayOutModeOnChange.bind(this)
  }

  async componentDidMount() {
    let controller = new maturityPayOutModeFormController()
    let journeyData = toJS(AuthStore.journeyData)
    let journeyId = toJS(AuthStore.journeyId)

    controller.setDefaults(journeyData, journeyId, this.props.cardkey, this.props.data)

    this.setState({ formController: controller })
  }

  submitData = () => {
    this.state.formController
      .validate({ showErrors: true })
      .then(({ isValid }) => {
        if (isValid) {
          this.changeTargetAmount().then(()=>{
            this.closeModal()
          console.log("maturity payout mode submitted")
          })
        } else {
          console.log("Form errors are ", this.state.formController.errors())
        }
      })
  }

  async changeTargetAmount(data) {
    try {
      global.loading(true);

      let formData = this.state.formController.$("target_amount_dropdown").value;
     
      let risk = this.props.data.selectedRisk ? this.props.data.selectedRisk : "low"
      let productListData = toJS(OptimizationStore.product_list_data);
      let familyProductID="";
      for(let i=0; i<productListData.length; i++){
        let obj = productListData[i];
        if(obj.data.length>0){
          for (let j=0;j<obj.data.length; j++){
            let dataObj = obj.data[j];
            familyProductID = dataObj?.BI[0]?.family_product_id;
          }
        }
      }
      let data = {
        "user_journey_id": AuthStore.journeyId,
        "family_member_id": this.props.familyMemberId,
        "need_id": formData.need_id_id,
        "existing_payout_mode" : formData.mode =="INCOME"?"lumpsum":"income",
        "new_payout_mode" : formData.mode =="INCOME"?"income":"lumpsum",
        "target_amount_id": formData.id,
        "family_product_id": this.props.data.BI[0]?.family_product_id,
        "priority": risk
        }
        
      var response = await axios.post(APITypes.CHANGE_TARGET_AMOUNT, data);
      OptimizationStore.onChangeMaturityPayoutMode(false)
      if (response.data.status == "error") {
        return false;
      } else {

        //let responseDataArr = response.data;
        let responseDataArr = OptimizationStore.formatProductResponseData(response);

        productListData.forEach((item) => {
          if (responseDataArr[0].need_unique_name === item.need_unique_name) {
            item.data.map((productData) => {
              if(Array.isArray(productData.BI) && productData.BI[0]?.family_product_id === responseDataArr[0].data[0].BI[0]?.family_product_id){
                productData.BI = responseDataArr[0].data[0].BI
                productData.payout_mode = responseDataArr[0].data[0].payout_mode
              }
            });
          }
        })
        OptimizationStore.product_list_data = [...productListData];
        return true;
      }
    } catch (error) {
      return false;
    } finally {
      global.loading(false);
    }
  }

  maturityPayOutModeOnChange(selectedObj) {
    OptimizationStore.onChangeMaturityPayoutMode(true)
    this.state.formController.$("target_amount_type").value = selectedObj
    this.props.showBackDropFunction()
  }

  closeModal = () => {
    this.props.hideBackDropFunction()
  }

  render() {
    if (this.state.formController === undefined) return null

    const { formController } = this.state

    return (
      <div className="maturityPayOutMode">
        <div className="row">
          <div className="col">
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle="Maturity Payout Mode"
              menuList={formController.$("target_amount_type").extra.optionList}
              value={formController.$("target_amount_type").value}
              onSelect={(selectedObj) =>
                this.maturityPayOutModeOnChange(selectedObj)
              }
              showErrorMsg={false}
              errorMsg={""}
              disabled={false}
            />
          </div>
        </div>

        {this.props.showModalBackDrop && (
          <MaturityPayOutModalForm
            formController={formController}
            targetAmountType={
              formController?.$("target_amount_type")?.value?.label
            }
            closeModal={this.closeModal}
            submitData={this.submitData}
          />
        )}
      </div>
    )
  }
}

export default observer(MaturityPayOutModal)
