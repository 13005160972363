import StorageKeys from './StorageKeys';

function API() {
  this.sessionToken = global.token;
  this.version = global.version;
  return {
    getCommonHeaderParams() {
      return {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        // 'agent-unique-id': baseConfig.agentId
      };
    },
    setSessionToken(token) {
      this.sessionToken = token;
    },
    setVersion(data) {
      this.version = data;
    },
    async getSessionToken(url) {
      let token = await localStorage.getItem(StorageKeys.AUTH_TOKEN);
      
      return token ? token : '';
    },
    async getCommonHeadersWithToken(url) {

      let sessionToken = await this.getSessionToken(url)

      return {
        ...this.getCommonHeaderParams(),
        Authorization: sessionToken
      };
    }
  };
}

export default new API();
