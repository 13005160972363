import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import Utils from '../../../../../constants/utils';
import OptimizationStore from '../../../store/OptimizationStore';
import fetchRiderValidation from 'utils/fetchRiderValidation'


class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Minimum Premium Validation
    minimalAnnualPremium = (form) => {
        // let frequency = form.$('payment-frequency').value.id.toString()
        // let premium = form.$('premium').value

        // switch (frequency) {
        //     case '1':
        //         if (premium < 50000) {
        //             this.errorMessage.push('Minimum Annual premium is 50000')
        //         }
        //         break;

        //     case '2':
        //         if (premium < 25600) {
        //             this.errorMessage.push('Minimum Half-Yearly premium is 25600')
        //         }
        //         break

        //     case '3':
        //         if (premium < 13000) {
        //             this.errorMessage.push('Minimum Quarterly premium is 13000')
        //         }
        //         break;

        //     case '4':
        //         if (premium < 4400) {
        //             this.errorMessage.push('Minimum Monthly premium is 4400')
        //         }
        //         break;
        // }
    }
   
    // rider sum assured validation
    riderSAValidation = (form, productData) => {
        // let sum_assured = productData.data.firstYearResponse.SA

        // if (form.$('ADB').value) {
        //     var ADB_value = Number(form.$('ADB-dropdown').value)
        //     if (ADB_value > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }

        // if (form.$('ATPDRider').value) {
        //     var ATPDRider_value = Number(form.$('ATPDRider-dropdown').value)
        //     if (ATPDRider_value > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }

        // if (form.$('CriticalIllnessRider').value) {
        //     var CriticalIllnessRider_value = Number(form.$('CriticalIllnessRider-dropdown').value)
        //     if (CriticalIllnessRider_value > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }

        // if (form.$('HospitalityCashBenefitRider').value) {
        //     var HospitalityCashBenefitRider_value = Number(form.$('HospitalityCashBenefitRider-dropdown').value)
        //     if (HospitalityCashBenefitRider_value > sum_assured) {
        //         this.errorMessage.push(errorMessageKeys.rider)
        //     }
        // }
    }

    ridersAgeValidation = (formRef, productData) => {
        // let age = productData.data.bi_request.LI_ENTRY_AGE
        // let adb = formRef.$('ADB').value
        // let atpd = formRef.$('ATPDRider').value
        // let criticalIllness = formRef.$('CriticalIllnessRider').value
        // let wop = formRef.$('WaiverOfPremiumRider').value
        // let hospitalCash = formRef.$('HospitalityCashBenefitRider').value

        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // let isRiderSelected = (wop || adb || atpd || criticalIllness || hospitalCash);

        // if (!isRiderSelected) {
        //     return;
        // }

        // if (isMinimumAge) {
        //     this.errorMessage.push(errorMessageKeys.MINIMUM_RIDER_AGE)
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push(errorMessageKeys.MAXIMUM_RIDER_AGE)
        // }
    }

    // Riders Validation
    ridersValidation = (formRef, productData) => {
        // let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
        // let adb = formRef.$('ADB-dropdown').value
        // let atpd = formRef.$('ATPDRider-dropdown').value
        // let hospital = formRef.$('HospitalityCashBenefitRider-dropdown').value

        // let adbValidation = fetchRiderValidation(adb, formRef.$('ADB-dropdown').label)
        // if (adbValidation) {
        //     this.errorMessage.push(adbValidation)
        // }

        // let atpdValidation = fetchRiderValidation(atpd, formRef.$('ATPDRider-dropdown').label)
        // if (atpdValidation) {
        //     this.errorMessage.push(atpdValidation)
        // }

        // let criticalIllnessValidation = fetchRiderValidation(criticalIllness, formRef.$('CriticalIllnessRider-dropdown').label)
        // if (criticalIllnessValidation) {
        //     this.errorMessage.push(criticalIllnessValidation)
        // }

        // let hospitalValidation = fetchRiderValidation(hospital, formRef.$('HospitalityCashBenefitRider-dropdown').label)
        // if (hospitalValidation) {
        //     this.errorMessage.push(hospitalValidation)
        // }
    }


}

export default new Validator();