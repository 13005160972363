import NeedTypeEnum from "constants/NeedTypeEnum"

const fetchRecommendedTargetLabelBasedOnNeed = (need) => {

    switch(need){

        case NeedTypeEnum.PROTECTION_FOR_MY_FAMILY:
            return "Recommended Cover"
        break


        case NeedTypeEnum.GROW_MY_MONEY:
        case NeedTypeEnum.SAVE_FOR_CHILDREN:
        case NeedTypeEnum.PROTECTION_FOR_MY_HEALTH:
            return "Target"
        break    

        case NeedTypeEnum.SAVE_FOR_RETIREMENT:
            return "Recommended Income"
        break
    }
    return "Recommended Target"

}

export default fetchRecommendedTargetLabelBasedOnNeed