import Utils from './utils';
import ConstUtils from '../../../../../constants/utils';

const initialDefaults = (formRef, data) => {
    let productData = data.data
    let biResponse = productData.parsedResponse;
    let biRequest = productData.bi_request;
    let frequency = biRequest.INPUT_MODE.toString()
    let sum_assured = biRequest.PR_SA
    let pptList = Utils.getPremiumTermList(biRequest.PR_PT, biRequest.LI_ENTRY_AGE)
    let ptList = Utils.getPolicyTermList()

    formRef.$('sum_assured').set('value', sum_assured)

    formRef.$('policy-term').value = {
        id: biRequest.PR_PT,
        label: biRequest.PR_PT
    }

    formRef.$('premium-term').value = {
        id: biRequest.PR_PPT,
        label: biRequest.PR_PPT
    }

    formRef.$('policy-term').set('extra', {
        ...formRef.$('policy-term').extra,
        optionList: ptList
    })

    formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        optionList: pptList
    })


    switch (frequency) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '5':
            formRef.$('payment-frequency').set('value', {
                id: '5',
                label: 'Single'
            })
            break;

        default:
            break;
    }

    if (biRequest.LI_SMOKE === "1") {
        formRef.$('tobacco-user').set('value', {
            id: '1',
            label: 'Yes'
        })
    } else {
        formRef.$('tobacco-user').set('value', {
            id: '0',
            label: 'No'
        })
    }

    formRef.$('ADB').set('value', biRequest.ADBRider !== '')
    if (formRef.$('ADB').value) {
        let adbList = Utils.getAdbList()
        let amount = adbList.filter(item => ConstUtils.actualValue(item) == biRequest.ADBRider)[0]
        formRef.$('ADB-dropdown').set('value', amount)
    }

    formRef.$('ATPDRider').set('value', biRequest.ATPDRider !== '')
    if (formRef.$('ATPDRider').value) {
        let atpdList = Utils.getAtpdList()
        let atpdAmount = atpdList.filter(item => ConstUtils.actualValue(item) == biRequest.ATPDRider)[0]
        formRef.$('ATPDRider-dropdown').set('value', atpdAmount)
    }

    formRef.$('wop').set('value', biRequest.WaiverOfPremiumRider !== '')

    formRef.$('CriticalIllnessRider').set('value', biRequest.CriticalIllnessRider !== '')
    if (formRef.$('CriticalIllnessRider').value) {
        let criticalIllnessList = Utils.getCriticalIllnessList()
        let criticalAmount = criticalIllnessList.filter(item => ConstUtils.actualValue(item) == biRequest.CriticalIllnessRider)[0]
        formRef.$('CriticalIllnessRider-dropdown').set('value', criticalAmount)
    }
}

export default initialDefaults