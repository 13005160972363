const { Form } = require("mobx-react-form");

import dvr from 'mobx-react-form/lib/validators/DVR';
import validatorjs from 'validatorjs';

import formObserver from './observers';
import setInitialDefaults from './initialDefaults.js';
import * as keys from './keys';
import Utils from './utils';
import * as Constants from 'constants/Constants'

class PGIFormController extends Form {
    plugins() {
        return {
            dvr: dvr(validatorjs)
        }
    }

    setup() {
        return {
            fields: [
                {
                    name: "PPT",
                    label: "Premium Paying Term",
                    placeholder: "Select",
                    disabled: false,
                    extra: {
                        optionList: [
                         {label: '5', id: '5'},
                          {label: '8', id: '8'},
                          {label: '10', id: '10'}, 
                         {label: '12', id: '12'},
                        ],
                    }
                },
                {
                    name: "PT",
                    label: "Policy Term",
                    placeholder: "Select",
                    disabled: false,
                    extra: {
                        optionList: [
                    //         {
                    //             id: '10',
                    //             label: '10'
                    //         },
                    //         {
                    //          id: '15',
                    //          label: '15'
                    //      },
                    //      {
                    //       id: '20',
                    //       label: '20'
                    //   },
                        ],
                    }
                },
                {
                    name: "income-benefit-payout-duration",
                    label: "Income Benefit Payout Duration",
                    placeholder: "Select",
                    disabled: false,
                    extra: {
                        hide: false,
                        optionList: [
                        //  {label: 'No', id: '3'},
                        //  {label: 'Family Income Benefit on Critical Illness (CI) and Death', id: 4},
                        //  {label: 'Family Income Benefit on Critical Illness (CI)', id: 5}
                        ]
                },
               },
               {
                    name: "lump-sum-benefit",
                    label: "Lump Sum Benefit",
                    placeholder: "Select",
                    extra: {
                        hide: false,
                        optionList: [{label: 'No', id: '7'}, {label: 'Yes', id: '8'}]
                },
               },
                {
                    name: "payment-frequency",
                    label: "Premium Paying Frequency",
                    placeholder: "",
                    extra: {
                        optionList: [
                         {label: 'Annual', id: '1'},
                         {label: 'Semi-Annual', id: '2'},
                         {label: 'Quarterly', id: '3'}, 
                         {label: 'Monthly', id: '4'}
                        ],
                    }
                },
                {
                    name: "family-income-benefit-option",
                    label: "Family Income Benefit Option",
                    placeholder: '',
                    value: false,
                    disabled : false,
                    extra: {
                        // description: Constants.PWB_RIDER_DESCRIPTION,
                        // paramName: 'PayorWaiverBenefitRider',
                        // rider_name: 'Payor Waiver Benefit Rider',
                        hide: false,
                        changeBenefitStatus(form, data) {
                            let value = form.$('family-income-benefit-option').value
                            form.$('family-income-benefit-option').value = !value

                            if (form.$('family-income-benefit-option').value) {
                                form.$('family-income-benefit-option-dropdown').set('value', form.$('family-income-benefit-option-dropdown').extra.optionList[0])
                            } else {
                                form.$('family-income-benefit-option-dropdown').set('value', {})
                            }
                            Utils.updateBi(keys.FAMILY_BENEFIT_INCOME_OPTION, form.$('family-income-benefit-option').value ? '4' : '3', data.data.product_id, form, data)

                        }
                    }
                },
                {
                    name: 'family-income-benefit-option-dropdown',
                    label: '',
                    placeholder: '',
                    value: {},
                    disabled : false,
                    extra: {
                        optionList: [

                            { label: 'Family Income Benefit on Critical Illness (CI) and Death', id: '4' },
                            { label: 'Family Income Benefit on Critical Illness (CI)', id: '5' }
                        ],
                    }
                },
                {
                 name: "income-benefit-pay-out-frequency",
                 label: "Income Benefit Pay-out Frequency",
                 placeholder: "Select",
                 // disabled: true,
                 extra:{
                  hide:false,
                  optionList:[
                   {label: 'Annual', id: '1'},
                   {label: 'Semi-Annual', id: '2'},
                   {label: 'Quarterly', id: '3'}, 
                   {label: 'Monthly', id: '4'}
                  ]
                 }
             },
             {
              name: "income-benefit-pay-out-type",
              label: "Income Benefit Pay-out type",
              placeholder: "Select",
              disabled: false,
              extra:{
                hide:false,
                optionList:[
                    {label: 'Level', id: '5'},
                    {label: 'Increasing', id: '6'},
                ]
              }
          },

          {
            name: "annualised-premium",
            label: "Annualised Premium",
            value: "",
        },
                // Rider
                {
                    name: 'WaiverOfPremiumRider',
                    label: 'Waiver of Premium',
                    placeholder: '',
                    value: false,
                    disabled: false,
                    extra: {
                        description: Constants.WOP_RIDER_DESCRIPTION,
                        paramName: 'WaiverOfPremiumRider',
                        rider_name: 'Waiver of Premium Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('WaiverOfPremiumRider').value
                            let productId = data.data.product_id
                            let wopRiderValue = '';

                            if (!value) {
                                wopRiderValue = '1';
                            }
                            form.$('WaiverOfPremiumRider').value = !value

                            Utils.updateBi(keys.WOP_RIDER, wopRiderValue, productId, form, data)
                        }
                    }
                },
                {
                    name: 'ADB',
                    label: 'Accidental Death Benefit',
                    placeholder: '',
                    value: false,
                    extra: {
                        description: Constants.ADB_RIDER_DESCRIPTION,
                        paramName: 'ADBRider',
                        rider_name: 'ADB Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('ADB').value
                            form.$('ADB').value = !value

                            if (form.$('ADB').value) {
                                form.$('ADB-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
                            } else {
                                form.$('ADB-dropdown').set('value', "")
                            }
                        }
                    }
                },
                {
                    name: 'ADB-dropdown',
                    label: 'Accidental Death Benefit',
                    placeholder: '',
                    value: "",
                    extra: {
                        optionList: Utils.getAdbList(),
                        onBlur(form, data) {
                            let value = form.$("ADB-dropdown").value
                            Utils.updateBi(keys.ADB_RIDER, value, data.data.product_id, form, data)
                        },
                    },
                },
                {
                    name: 'ATPDRider',
                    label: 'Permanent Disability',
                    placeholder: '',
                    value: false,
                    extra: {
                        description: Constants.ATPD_RIDER_DESCRIPTION,
                        paramName: 'ATPDRider',
                        rider_name: 'ATPD Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('ATPDRider').value
                            form.$('ATPDRider').value = !value

                            if (form.$('ATPDRider').value) {
                                form.$('ATPDRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
                            } else {
                                form.$('ATPDRider-dropdown').set('value', "")
                            }
                        }

                    }
                },
                {
                    name: 'ATPDRider-dropdown',
                    label: 'Permanent Disability',
                    placeholder: '',
                    value: "",
                    extra: {
                        optionList: Utils.getAtpdList(),
                        onBlur(form, data) {
                            let value = form.$("ATPDRider-dropdown").value
                            Utils.updateBi(keys.ATPD_RIDER, value, data.data.product_id, form, data)
                        },
                    }
                },
                {
                    name: 'CriticalIllnessRider',
                    label: 'Critical Illness',
                    placeholder: '',
                    value: false,
                    extra: {
                        description: Constants.CRITICAL_ILLNESS_RIDER_DESCRIPTION,
                        paramName: 'CriticalIllnessRider',
                        rider_name: 'Critical Illness Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('CriticalIllnessRider').value
                            form.$('CriticalIllnessRider').value = !value

                            if (form.$('CriticalIllnessRider').value) {
                                form.$('CriticalIllnessRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
                            } else {
                                form.$('CriticalIllnessRider-dropdown').set('value', "")
                            }
                        }
                    }
                },
                {
                    name: 'CriticalIllnessRider-dropdown',
                    label: 'Critical Illness',
                    placeholder: '',
                    value: "",
                    extra: {
                        optionList: Utils.getCriticalIllnessList(),
                        onBlur(form, data) {
                            let value = form.$("CriticalIllnessRider-dropdown").value
                            Utils.updateBi(keys.Critical_ILLNESS_RIDER, value, data.data.product_id, form, data)
                        },
                    }
                },
                // {
                //     name: 'IncomeBenefitRider',
                //     label: 'Income Benefit',
                //     placeholder: '',
                //     value: false,
                //     extra: {
                //         description: Constants.INCOME_BENEFIT_RIDER_DESCRIPTION,
                //         paramName: 'IncomeBenefitRider',
                //         rider_name: 'Income Benefit Rider',
                //         changeRiderStatus(form, data) {
                //             let value = form.$('IncomeBenefitRider').value
                //             form.$('IncomeBenefitRider').value = !value

                //             if (form.$('IncomeBenefitRider').value) {
                //                 form.$('IncomeBenefitRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
                //             } else {
                //                 form.$('IncomeBenefitRider-dropdown').set('value', "")
                //             }
                //         }
                //     }
                // },
                // {
                //     name: 'IncomeBenefitRider-dropdown',
                //     label: 'Income Benefit',
                //     placeholder: '',
                //     value: "",
                //     extra: {
                //         optionList: Utils.getIncomeBenefit(),
                //         onBlur(form, data) {
                //             let value = form.$("IncomeBenefitRider-dropdown").value
                //             Utils.updateBi(keys.INCOME_BENEFIT_RIDER, value, data.data.product_id, form, data)
                //         },
                //     }
                // },
                {
                    name: 'PayorWaiverBenefitRider',
                    label: 'Payor Waivor Benefit',
                    placeholder: '',
                    value: false,
                    extra: {
                        description: Constants.PWB_RIDER_DESCRIPTION,
                        paramName: 'PayorWaiverBenefitRider',
                        rider_name: 'Payor Waiver Benefit Rider',
                        changeRiderStatus(form, data) {
                            let value = form.$('PayorWaiverBenefitRider').value
                            form.$('PayorWaiverBenefitRider').value = !value

                            if (form.$('PayorWaiverBenefitRider').value) {
                                form.$('PayorWaiverBenefitRider-dropdown').set('value', form.$('PayorWaiverBenefitRider-dropdown').extra.optionList[0])
                            } else {
                                form.$('PayorWaiverBenefitRider-dropdown').set('value', {})
                            }
                        }
                    }
                },
                {
                    name: 'PayorWaiverBenefitRider-dropdown',
                    label: 'Payor Waivor Benefit',
                    placeholder: '',
                    value: {},
                    extra: {
                        optionList: [
                            { id: 1, label: 'Option 1 (On Death)' },
                            { id: 2, label: 'Option 2 (CI or ATPD)' },
                            { id: 3, label: 'Option 3 (CI or ATPD or Death)' },
                        ]
                    }
                },
                {
                    name: 'HospitalityCashBenefitRider',
                    label: 'Hospital Cash Benefit',
                    placeholder: '',
                    value: false,
                    extra: {
                      description: '',
                      paramName: 'HospitalityCashBenefitRider',
                      rider_name: 'Hospital Cash Benefit Rider',
                      changeRiderStatus(form, data) {
                        let value = form.$('HospitalityCashBenefitRider').value
                        form.$('HospitalityCashBenefitRider').value = !value
          
                        if (form.$('HospitalityCashBenefitRider').value) {
                          form.$('HospitalityCashBenefitRider-dropdown').set('value', Constants.RIDERS_DEFAULT_SA)
                        } else {
                          form.$('HospitalityCashBenefitRider-dropdown').set('value', "")
                        }
          
                      }
          
                    }
                  },

                  {
                    name: 'HospitalityCashBenefitRider-dropdown',
                    label: 'Hospital Cash Benefit',
                    placeholder: '',
                    value: "",
                    extra: {
                      optionList:[],
                      onBlur(form, data) {
                        let value = form.$("HospitalityCashBenefitRider-dropdown").value
                        Utils.updateBi(keys.HOSPITALITY_CASH_BENEFIT_RIDER, value, data.data.product_id, form, data)
                      },
                    }
                  },
                {
                    name: "premium",
                    label: "Premium",
                    placeholder: "",
                    disabled: true,
                    extra: {
                        calculatePremiun(form, productId, data) {
                            // let premiumAmount = 0;
                            // let inputMode = Number(form.$('payment-frequency').value.id)

                            // let amount = Number(form.$('premium').value)
                            // switch (inputMode) {
                            //     case 1:
                            //         premiumAmount = '' + amount * 1
                            //         break;
                            //     case 2:
                            //         premiumAmount = '' + amount * 2
                            //         break;
                            //     case 3:
                            //         premiumAmount = '' + amount * 4
                            //         break;
                            //     case 4:
                            //         premiumAmount = '' + amount * 12
                            //         break;
                            // }
                            // Utils.updateBi(keys.PREMIUM, premiumAmount, productId, form, data)
                        }
                    }
                },
            ]
        }
    }

    setDefaults(data) {
        // Set forms initial default value
        setInitialDefaults(this, data)

        // Observer fields
        formObserver(this, data)
    }

}

export default PGIFormController;
