import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Collapse, Button } from '../../../../components'

import FamilyProtectionStore from '../../store/FamilyProtectionStore';
import AddInsuredModal from '../../components/add-insured-modal/AddInsuredModal'
import AddMemberCardModal from '../../../family-details-form/components/AddMemberCardModal';

import ActiveIncome from '../products/active-income/active-income.js'
import WealthUltimaProductCard from '../products/wealth-ultima/WealthUltimaProductCard'

import './save-my-children.css'

import FamilyDetailsStore from '../../../family-details-form/store/FamilyDetailsStore';
import { PlusIcon } from 'constants/Images';

const SaveMyChildrenCard = observer(class SaveMyChildrenCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            selectedCollapseKey: [0],
            showInsuredMemberModal: false,
            showAddFamilyMemberModal: false,
            productName: localStorage.getItem('productName')
        }
    }
    onAddInsured = (e) => {
        e.stopPropagation();
        console.log('add insured')
        this.setState({ showInsuredMemberModal: true })
    }

    onCollapseChange = (value, keyIndex) => {
        // console.log('onCollapseChange >> ', value);
        const { disabled } = this.props;
        if (!disabled) {
            if (value) {
                this.state.selectedCollapseKey.push(keyIndex)
            } else {
                this.state.selectedCollapseKey.map((item, index) => {
                    if (keyIndex == item) {
                        this.state.selectedCollapseKey.splice(index, 1);
                    }
                })
            }
            this.setState({ isCollapseOpen: value })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.disabled && this.props.disabled) {
            this.setState({ isCollapseOpen: false })
        }
    }

    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    onAddInsuredFamily = (value) => {
        // console.log('ADD INSURED FAMILY >> ', value);

        const dataList = toJS(FamilyProtectionStore.dummy_product_list_data)

        value.map((familyData) => {
            if (familyData.isSelected) {
                var isDataAvailable = dataList.some((item) => item.family_member_details.family_member_type == familyData.typeLabel.toLowerCase() && item.title.toLowerCase() == this.props.title.toLowerCase());
                let familyObject = {}
                if (!isDataAvailable || familyData.type == 'Child') {
                    familyObject = {
                        'family_member_type': familyData.typeLabel.toLowerCase(),
                        'gender': familyData.gender == '' ? '' : familyData.gender.genderValue.toLowerCase(),
                        'family_member_name': familyData.name,
                        'dob': familyData.dob,
                        'age': 28,
                        'is_selected': familyData.isSelected,
                        'id': familyData.id

                    }
                    FamilyProtectionStore.updateFamilyMember(familyObject, 'save for children');
                }
            } else {
                FamilyProtectionStore.deleteFamilyMember(familyData);
            }
        })

        this.setState({ showInsuredMemberModal: false, isCollapseOpen: false })
    }
    addInsuredOnHide = () => {
        this.setState({ showInsuredMemberModal: false })
    }
    onAddFamilyMember = (value) => {
        // console.log('ADD MEMBER >> ', value)
        this.setState({ showAddFamilyMemberModal: true })
    }

    componentDidMount() {
        const { data } = this.props;
        Object.values(data).map((item) => {
            console.log('SAVE MY CHILD API PRODUCT KEY >> ', item.product_id);
            FamilyProtectionStore.getBIResponse(item.product_id)
        })
    }

    render() {
        const { isCollapseOpen, disabled, showInsuredMemberModal, showAddFamilyMemberModal, productName } = this.state;
        const { data, title } = this.props;
        // console.log('IS COLLAPSE OPEN >> ', isCollapseOpen, data)
        return (
            <div className='save-my-children'>
                {/* <div className='d-flex justify-content-between align-items-center'>
                    <span className='font-size-20 font-size-sm-32 font-weight-bold text-header-style'>{title}</span>
                    <div>
                        <Button
                            className='font-size-14 font-size-md-24 px-1 w-100'
                            variant='link'
                            buttonText={disabled ? 'Select' : 'Deselect'}
                            onClick={() => this.setState({ disabled: !disabled, isCollapseOpen: false, selectedCollapseKey: [] })}
                        />
                    </div>
                </div> */}
                <div className='d-flex justify-content-between align-items-center pb-md-3'>
                    <div className='d-md-flex d-block align-items-center'>
                        <span className='font-size-20 font-size-md-24 font-weight-bold text-header-style'>{title}</span>
                        <div className='select-deselect-btn'>
                            <Button
                                className='font-size-14 font-size-md-20 px-md-1 px-0 pt-md-3 pt-0'
                                variant='link'
                                buttonText={disabled ? 'Select' : 'Deselect'}
                                onClick={() => this.setState({ disabled: !disabled, isCollapseOpen: false, selectedCollapseKey: [] })}
                            />
                        </div>
                    </div>
                    <div>
                        <Button variant='light' onClick={(e) => this.onAddInsured(e)} role='button' className='rounded title-button px-3 py-1 py-md-3 font-size-13 font-weight-medium d-flex align-items-center px-md-4 py-md-3'><img color='red' className='mr-2 mr-md-3 add-insured-img-style' src={PlusIcon} alt='plus-icon' />Add Insured</Button>
                    </div>
                </div>
                {
                    Object.values(data).map((item, index) => {
                        console.log('SAVE CHILDREN PRODUCTID >> ', this.props.cardKey, item.product_id)
                        return (
                            <Collapse
                                key={index}
                                keyIndex={index}
                                disabled={disabled}
                                open={isCollapseOpen || index == 0}
                                onChange={this.onCollapseChange}
                                title={
                                    <div className={`w-100 mr-2 mr-md-4 select-deselect-btn ${disabled ? 'text-gray' : 'text-description-text-color'}`}>
                                        <span className='font-size-16 font-size-md-24'>Insured <span className={`font-weight-bold ${disabled ? 'text-gray' : 'text-secondary'}`}>{item.typeLabel} {this.capitalize(item.family_member_details.family_member_type)}, {item.family_member_details.age} years old</span></span>
                                        {(this.state.selectedCollapseKey.includes(index)) &&
                                            <div className='remove-insured'>
                                                <Button
                                                    className='font-weight-medium font-size-14 font-size-23 py-0 px-0'
                                                    variant='link'
                                                    buttonText='Remove Insured'
                                                    role='button'
                                                    onClick={(e) => this.onRemoveInsured(e, item)}
                                                />
                                            </div>
                                        }
                                    </div>
                                }>
                                <React.Fragment>
                                    {productName == 'Active Income Plan' || productName == 'POS - Saral Nivesh' ? <ActiveIncome data={item} cardKey={this.props.cardKey} /> : null}
                                    {productName == 'Wealth Ultima' || productName == 'Systematic Withdrawal Plan (Wealth Ultima)' || productName == 'Little Champ (Wealth Ultima)' ? <WealthUltimaProductCard data={item} cardKey={this.props.cardKey} /> : null}

                                </React.Fragment>
                            </Collapse>
                        )
                    })
                }
                <AddInsuredModal show={showInsuredMemberModal} onAddMember={this.onAddFamilyMember} onAddInsured={this.onAddInsuredFamily} onHide={this.addInsuredOnHide} />
                <AddMemberCardModal show={showAddFamilyMemberModal} onHide={() => this.setState({ showAddFamilyMemberModal: false })} />
            </div>
        );
    }
});

SaveMyChildrenCard.defaultProps = {
    disabled: false
}

SaveMyChildrenCard.propTypes = {
    addInsured: PropTypes.func,
    data: PropTypes.array,
    disabled: PropTypes.bool
}

export default SaveMyChildrenCard;