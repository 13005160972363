import NeedTypeEnum from "constants/NeedTypeEnum";
import ProductConstants from "constants/ProductConstants";

const fetchIncomeLabelBasedOnNeed = (need, productTitle, productData) => {

  if(productTitle){

    switch (productTitle) {

      case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM:
      case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
      case ProductConstants.POS_GUARANTEED_SAVINGS_STAR:
        return "Investment Target"
      break
  
      case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME:
      case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
        return "Investment Income"
      break  
    }

  }else {

    switch (need) {
      case NeedTypeEnum.GROW_MY_MONEY:
      case NeedTypeEnum.SAVE_FOR_CHILDREN:
      case "grow_my_money":
      case "save_for_my_children":
        if(productData?.payout_mode === "income") {
          return "Investment Income"
        } else {
          return "Investment Target"
        }
  
      case NeedTypeEnum.PROTECTION_FOR_MY_FAMILY:
      case "protection_for_my_family":
        return "Protection Cover"
  
      case NeedTypeEnum.PROTECTION_FOR_MY_HEALTH:
      case "protection_for_my_health":
        return "Health Cover"
  
      case NeedTypeEnum.SAVE_FOR_RETIREMENT:
      case "retirement":
        return "Retirement Income"
  
    }
  }
}

export default fetchIncomeLabelBasedOnNeed;
