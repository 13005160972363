import React, { Component } from 'react';

import { Row, Col } from 'react-bootstrap';

import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';

import moment from 'moment'

import { CustomCheckbox, BottomsheetDropdown, DeathCoverPriceComponent } from '../../../../../components'

import AmountCalculation from '../../family-protection-card/AmountCalculation';
import ChangeProductModal from '../../change-product-modal/ChangeProductModal';
import ChangeProductReasonModal from '../../change-product-reason-modal/ChangeProductReasonModal';
import AdditionalCards from './AdditionalCards';
import DeathCoverGraphSlider from '../../family-protection-card/DeathCoverGraphSlider';

import FamilyProtectionStore from '../../../store/FamilyProtectionStore'
import AuthStore from '../../../../login/store/AuthStore';
import fetchIncomeLabelBasedOnNeed from 'utils/fetchIncomeLabelBasedOnNeed';

const SimplyProtect = observer(class SimplyProtect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productID: '40012',
            isTobacoUser: false,
            deathCoverPayoutMode: {
                id: 7,
                label: 'Lumpsum'
            },
            disabled: false,
            selectdReason: {},
            changeProductReasonModal: false,
            productReasonList: [
                { reason: 'Recommended product is not fitting the need.', value: 'noNeed' },
                { reason: 'There is a better product that can be offered.', value: 'anotherBetterProduct' },
                { reason: 'System is recommending a wrong product.', value: 'recommendWrongProduct' },
                { reason: 'Other reason.', value: 'otherReason' }
            ],
            isReason: false,
            changeProductModal: false,
            productList: [
                { title: 'Product 1' },
                { title: 'Product 2' },
                { title: 'Product 3' },
                { title: 'Product 4' },
                { title: 'Product 5' },
                { title: 'Product 6' },
                { title: 'Product 7' },
                { title: 'Product 8' },
                { title: 'Product 9' }
            ],
            pt: {
                id: 10,
                label: '10'
            },
            ppt: {
                id: 10,
                label: '10'
            },
            ptList: this.generateSimplyProtectPT(),
            pptList: [],
            planOption: {},
            reqParams: this.getReqParams(),
            isLoading: false
        }
        this.tobaccoUserStatus = this.tobaccoUserStatus.bind(this);
    }
    getReqParams = () => {
        return {
            'PR_ID': '40012',
            'CashFlow': 'N',
            'LI_NAME': 'Ankur Singh',
            'LI_ENTRY_AGE': '21',
            'LI_DOB': '28 Dec 1998',
            'LI_GENDER': 'M',
            'LI_STATE': '0',
            'PROPOSER_NAME': 'Ankur Singh',
            'PROPOSER_AGE': '21',
            'PROPOSER_DOB': '28 Dec 1998',
            'PROPOSER_GENDER': 'M',
            'SameProposer': 'True',
            'COMPANY_STATE': '0',
            'GSTIN': '0',
            'GSTIN_Number': '0',
            'INPUT_MODE': '1',
            'category': '1',
            'PR_CHANNEL': '5',
            'isPdfByte': 'Y',
            'AGENT_ID': '0',
            'LI_MOBILENO': '0',
            'LI_EMAILID': '0',
            'PR_ANNPREM': '',
            'PR_MI': '0',
            'PR_SA': '50000',
            'PR_SAMF': '0',
            'PR_ModalPrem': '',
            'REQUESTSOURCE': '4',
            'emr_rate': '1',
            'flat_rate': '0',
            'PlanOption': '2',
            'LI_SMOKE': '1',
            'PR_PT': '10',
            'PR_PPT': '10',
            'SumAssuredpayoutoption': '7'
        }
    }
    componentDidMount() {
        let data = this.getParseBIData()

        this.state.reqParams = { ...data.bi_request }

        this.setState({
            selectdReason: this.state.productReasonList[0],
            isLoading: true
        })

        // console.log('COMPONNENT DID MOUNT >> ', data)
        if (Object.keys(data.BI).length == 0) {
            this.fetchData()
        } else {
            this.setState({ isLoading: false })
        }

        this.prefieldData()

        this.generatePT()
        this.generatePPT()
    }

    prefieldData = () => {
        const { bi_request } = this.props.data;
        if (bi_request) {
            const { ptList } = this.state;
            const deathCoverPayoutMode = toJS(FamilyProtectionStore.deathCoverPayoutModeList).filter(obj => obj.id == bi_request.SumAssuredpayoutoption)[0]
            if (deathCoverPayoutMode) {
                this.setState({ deathCoverPayoutMode })
            }
            let pt = ptList.filter(obj => obj.id == bi_request.PR_PT)[0]

            if (pt) {
                this.setState({ pt })
            }

            this.setState({
                isTobacoUser: bi_request.LI_SMOKE == '0' ? false : true
            })

            const pptList = this.calculateSimplyProtectPPT(pt)
            let ppt = pptList.filter(obj => obj.id == bi_request.PR_PT)[0]
            if (ppt) {
                this.setState({ ppt })
            }
            const planOptionList = toJS(FamilyProtectionStore.simplyProtectPlanOptions)
            const planOption = planOptionList.filter(obj => obj.id == bi_request.PlanOption)[0] || planOptionList[0]
            if (planOption) {
                this.state.planOption = planOption
                this.setState({ planOption })
            }

            console.log('SIMPLY PROTECT >> DID MOUNT  >> ', planOption, bi_request)

        }
    }

    fetchData = async () => {
        try {
            global.loading(true)
            console.log('FETCH DATA CALLED >> ')
            const response = await FamilyProtectionStore.fetchSimplyProtect(this.state.reqParams)

            let data = JSON.parse(response.JsonOutput);

            if (data.Status == 'Fail') {
                // let error = data.InputValidationStatus[0].ErrorMessage[0].value
                // // console.log('Error is ', error)
                // AuthStore.setErrorModal(error)
                FamilyProtectionStore.showErrorMsg(data)
            }

            data = JSON.parse(data.BIJson);
            let simplyProtectResponseData = data[data.length - 1]
            this.state.reqParams['PR_ANNPREM'] = simplyProtectResponseData.MODAL_PREM_TAX

            FamilyProtectionStore.updateBIResponseToData(this.props.cardKey, this.props.data.product_id, response)

            console.log('SIMPLY PROTECT RESPONSE >> ', simplyProtectResponseData)
            // this.priceSummary = {
            //     'need_title': localStorage.getItem('need_title'),
            //     'total': this.simplyProtectResponseData.MODAL_PREM_TAX
            // }

        } catch (error) {
            console.log('ERROR >> ', error)
        } finally {
            global.loading(false)
        }
    }

    async dataChange(paramName, paramValue) {
        // console.log('Product id is ', paramName)
        // console.log('Value is ', paramValue)
        switch (paramName) {
            case 'isSmoking':
                this.state.reqParams.LI_SMOKE = (paramValue == true) ? '1' : '0'

                break;

            case 'ppt':
                if (paramValue > this.state.reqParams.PR_PT) {
                    this.state.reqParams.PR_PPT = '' + this.state.reqParams.PR_PT
                } else {
                    this.state.reqParams.PR_PPT = '' + paramValue
                }
                break;

            case 'pt':
                this.state.reqParams.PR_PT = '' + paramValue
                if (paramValue > this.state.reqParams.PR_PPT) {
                    this.state.reqParams.PR_PPT = '' + paramValue
                }
                break;

            case 'payoutMode':
                this.state.reqParams.SumAssuredpayoutoption = paramValue
                break;

            case 'benefits':
            case 'planOption':
                this.state.reqParams.PlanOption = paramValue
                break;
        }

        this.fetchData()
    }

    onChangeReason = (e) => {
        console.log('call selecedt reason>>', e);
        this.setState({ selectdReason: e });
    }

    onChangeProductReasonModalOnHide = () => {
        this.setState({ changeProductReasonModal: false })
    }
    onChangeProductModalOnShow = (value) => {
        if (!value) {
            this.setState({ changeProductModal: true, changeProductReasonModal: false })
        } else {
            this.setState({ changeProductReasonModal: true })
        }
    }
    onChangeProductModalOnHide = () => {
        this.setState({ changeProductModal: false })
    }

    onDeathCoverPayoutMode = (value) => {
        this.dataChange('payoutMode', value.id)
        this.setState({ deathCoverPayoutMode: value })
    }

    onPtChange = async (value) => {

        let pptList = await this.calculateSimplyProtectPPT(value);
        let isEightySelected = pptList.some((item) => item.id == '80 - @Li_entry_age')
        let pptData;
        if (isEightySelected) {
            pptData = pptList[pptList.length - 2]
        } else {
            pptData = pptList[pptList.length - 1]
        }

        this.state.reqParams.PR_PPT = pptData.id.toString();
        this.dataChange('pt', value.id);
        this.setState({ pt: value, ppt: pptData, pptList })
    }

    onPptChange = (value) => {
        this.dataChange('ppt', value.id)
        this.setState({ ppt: value })
    }

    tobaccoUserStatus() {
        let isTobacoUser = !this.state.isTobacoUser
        this.dataChange('isSmoking', isTobacoUser)
        this.setState({ isTobacoUser })
    }

    onPlanOptionChange = (value) => {
        this.state.planOption = value
        this.generatePT()
        this.generatePPT()
        this.setState({ planOption: value })
        console.log('PLAN OPTION CHANGE >> ', value)
        if (this.state.pt.id && this.state.ppt.id) {
            this.state.reqParams.PR_PT = this.state.pt.id.toString();
            this.state.reqParams.PR_PPT = this.state.ppt.id.toString();
            this.dataChange('planOption', value.id)
        }
    }

    getPlanOption = () => {
        let planOptionList = toJS(FamilyProtectionStore.simplyProtectPlanOptions)
        const { pt, ppt } = this.state;
        const { data } = this.props;

        let age = moment().diff(data.dob, 'years');

        // let calcAgePT = pt.id == '80 - @Li_entry_age' ? (80 - age) : parseInt(pt.id)
        if (70 - age < 60) {
            planOptionList = planOptionList.filter(plan => plan.id != '2')
        }

        // let calcAgePPT = ppt.id == '80 - @Li_entry_age' ? (80 - age) : parseInt(ppt.id)
        // if (calcAgePPT + age > 70) {
        //     planOptionList = planOptionList.filter(plan => plan.id != '5' && plan.id != '6')
        // }

        return planOptionList
    }

    generateSimplyProtectPT(dob, plan) {
        if (dob) {
            let age = moment().diff(moment(dob, 'DD-MM-YYYY'), 'years');
            let pt = []
            let maxAge = 70 - age
            let finalMaxAge = maxAge > 40 ? 40 : maxAge
            for (let i = 10; i <= finalMaxAge; i++) {
                pt.push({ id: i, label: '' + i })
            }
            console.log('AGE POLICY TERM >> ', age, maxAge)

            // if (maxAge > 40) {
            //     pt.push({ id: maxAge, label: '' + maxAge })
            // }
            return pt
        } else {
            let pt = []
            for (let i = 10; i <= 40; i++) {
                pt.push({ id: i, label: '' + i })
            }
            pt.push({ id: '80 - @Li_entry_age', label: 'upto age 80' })
            return pt
        }
    }

    calculateSimplyProtectPPT(currentPt, isWaiver, dob) {
        let ppt = [1, 5, 10, 15, 20]
        let list = []
        ppt.map((item) => {
            if (currentPt.id >= item) {
                list.push({ id: item, label: item.toString() })
            }
        })
        if (currentPt.id < 25) {
            delete list.splice(list.length - 1, 1);
            list.push({ id: currentPt.id, label: currentPt.label })
        } else if (currentPt.id >= 25) {
            list.push({ id: currentPt.id, label: currentPt.label })
        } else if (currentPt.id == '80 - @Li_entry_age') {
            ppt.map((item) => {
                list.push({ id: item, label: item.toString() })
            })
            if (isWaiver) {
                let age = moment().diff(moment(dob, 'DD-MM-YYYY'), 'years');
                let maxAge = 70 - age
                list.push({ id: maxAge, label: '' + maxAge })
            } else {
                let maxAge = 'upto age 80'
                list.push({
                    id: '80 - @Li_entry_age',
                    label: maxAge.toString()
                })
            }
        }
        return list
    }

    generatePT = () => {
        // {
        //     id: '1',
        //     label: 'Life Cover'
        //   },
        //   {
        //     id: '2',
        //     label: 'Life Cover + Accidental Death Benefit'
        //   },
        //   {
        //     id: '5',
        //     label: 'Life Cover + Waiver of Premium on Accidental Total & Permanent Disability'
        //   },
        //   {
        //     id: '6',
        //     label: 'Life Cover + Waiver of Premium on Critical Illness'
        //   }
        const { planOption } = this.state;
        const { data } = this.props;
        let ptList = []

        switch (planOption.id) {
            case '1':
                ptList = this.generateSimplyProtectPT()
                break
            case '2':
                ptList = this.generateSimplyProtectPT(data.dob)
                break
            case '5':
            case '6':
                ptList = this.generateSimplyProtectPT()
                break
        }
        if (ptList.length > 0) {
            this.state.pt = ptList[ptList.length - 1]
        } else {
            this.state.pt = {}
        }
        console.log('GENERATE PT LIST >> ', ptList)
        this.setState({ ptList })
        return ptList
    }

    generatePPT = () => {
        const { planOption } = this.state;
        const { data } = this.props;
        let pptList = []

        switch (planOption.id) {
            case '5':
            case '6':
                pptList = this.calculateSimplyProtectPPT(this.state.pt, true, data.dob)
                break
            default:
                pptList = this.calculateSimplyProtectPPT(this.state.pt)
                break
        }
        if (pptList.length > 0) {
            this.state.ppt = pptList[pptList.length - 1]
        } else {
            this.state.ppt = {}
        }
        this.setState({ pptList })
        return pptList
    }

    getParseBIData = () => {
        let data = JSON.parse(JSON.stringify(this.props.data));
        // console.log('GET PARSE DATA >> ', data.BI)
        let BI = data.BI
        try {
            if (BI && BI.JsonOutput) {
                BI = JSON.parse(BI.JsonOutput);
                BI = JSON.parse(BI.BIJson);
                if (BI) {
                    BI = BI[BI.length - 1]
                }
            } else {
                BI = {}
            }
        } catch (error) {
            console.log('ERROR >> ', error)
            BI = {}
        }
        if (!BI) {
            BI = {}
        }
        data.BI = BI
        return data
    }

    getTotalPrice = (data) => {
        let totalPremium = data.BI.MODAL_PREM_TAX;
        const { tab, selectedTab } = this.props;
        if (tab && selectedTab) {
            return tab != selectedTab ? 0 : totalPremium
        }

        return totalPremium;
    }

    render() {
        const { isTobacoUser, deathCoverPayoutMode, ptList, pptList, isLoading } = this.state;
        const data = this.getParseBIData()

        const totalPrice = this.getTotalPrice(data)

        console.log('SIMPLY PROTECT RENDER >> ', data)

        // let planOptionList = toJS(FamilyProtectionStore.simplyProtectPlanOptions)
        let planOptionList = this.getPlanOption()

        const deathCoverPayoutModeList = toJS(FamilyProtectionStore.deathCoverPayoutModeList);

        return (
            <div className='family-collapase-view'>
                {isLoading ?
                    <div className="text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div> :
                    Object.keys(data.BI).length > 0 ?
                        <React.Fragment>
                            <Row className='mt-4'>
                                <Col xs={12} md={5}>
                                    <div className=''>
                                        <div className='d-flex align-items-center'>
                                            <div className=' p-md-3 d-flex flex-md-column align-items-center align-items-md-start w-100'>
                                                <span className='mr-3 font-size-16 font-size-md-18 font-size-lg-22 text-description-text-color'>Tobbacco user?</span>
                                                <div className=''>
                                                    <CustomCheckbox className='mr-2' isChecked={isTobacoUser} onChange={() => this.tobaccoUserStatus(data)} titleStyle='font-size-16 font-size-md-20 font-size-lg-24' shape='rounded' title='Yes' mark='checkmark' />
                                                    <CustomCheckbox className='ml-2' isChecked={!isTobacoUser} onChange={() => this.tobaccoUserStatus(data)} titleStyle='font-size-16 font-size-md-20 font-size-lg-24' shape='rounded' title='No' mark='checkmark' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='d-md-block d-none d-md-block d-none'>
                                            <div className='p-3 bg-white rounded'>
                                                <div className='mb-2 text-description-text-color font-size-14 font-size-md-20'>Death cover payout mode options</div>
                                                <BottomsheetDropdown
                                                    dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                                    menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                                    menuItemStyle='d-flex justify-content-between align-items-center'
                                                    selectedLabelKey='label'
                                                    bottomsheetTitle='Select Plans'
                                                    menuList={deathCoverPayoutModeList}
                                                    value={deathCoverPayoutMode}
                                                    onSelect={this.onDeathCoverPayoutMode}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                                <Col md={7} className='col-12'>
                                    <DeathCoverPriceComponent firstAmount={data.BI.SA_D} secondAmount={data.BI.MODAL_PREM_TAX} thirdAmount='980' showAdditionalText={false} showSecondColAmount={false} secondColBottomText='Annual' firstColTitleText={fetchIncomeLabelBasedOnNeed(data.cardKey)} secondColTitleText='Price' />
                                </Col>
                            </Row>

                            <div className='text-primary-title-label-color font-weight-bold font-size-16 mb-3 mt-4 d-md-none'>Maturity Benefits</div>
                            <Row className='mt-md-5'>
                                <Col md={12} className='col-12 mt-4 mt-md-0'>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className='text-description-text-color font-size-12 font-size-md-18 font-size-lg-20 mt-2'>
                                            {/* Underlying product : Simply Protect <UiButton variant='' onClick={this.onChangeProductReasonModalOnShow} buttonText='[Change]' className='px-2 py-1 font-size-12 text-primary font-size-md-16 font-weight-medium cursor-pointer' /> */}
                                Underlying Product : Simply Protect
                            </div>
                                        <div className='shadow p-3 bg-white rounded my-3 w-50'>
                                            <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Plan Option</div>
                                            <BottomsheetDropdown
                                                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                                menuItemStyle='d-flex justify-content-between align-items-center'
                                                selectedLabelKey='label'
                                                bottomsheetTitle='Plan Option'
                                                menuList={planOptionList}
                                                value={this.state.planOption}
                                                onSelect={this.onPlanOptionChange}
                                            />
                                        </div>
                                    </div>
                                    <ChangeProductReasonModal
                                        selectedReasonVal={this.state.selectdReason}
                                        show={this.state.changeProductReasonModal}
                                        onSelectReason={this.onChangeReason}
                                        onHide={this.onChangeProductReasonModalOnHide}
                                        list={this.state.productReasonList}
                                        onSelectProductModalbtnClick={this.onChangeProductModalOnShow}
                                        isReason={this.state.isReason}
                                    />

                                    <ChangeProductModal
                                        show={this.state.changeProductModal}
                                        onSelectReason={() => { }}
                                        onHide={this.onChangeProductModalOnHide}
                                        list={this.state.productList}
                                    />
                                </Col>
                                <Col xs={12} md={5}>
                                    <div className='shadow p-3 bg-white rounded d-md-none d-block'>
                                        <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Death cover payout mode options</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Select Plans'
                                            menuList={deathCoverPayoutModeList}
                                            value={deathCoverPayoutMode}
                                            onSelect={this.onDeathCoverPayoutMode}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md={12} className='col-12 mt-4 mt-md-0'>
                                    <DeathCoverGraphSlider
                                        saLabel='Sum Assured'
                                        productTitle=''
                                        income_value={data.BI.SA_D}
                                        premium_value={''}
                                        showOverlay={false}
                                        productData={this.props.data}
                                        onIncomeChange={() => { }}
                                        openChangeReasonModal={() => { }}
                                        onPremiumChange={() => { }}
                                        dataChange={() => { }}
                                        hideOverLay={() => { }}
                                        productID={() => { }}
                                        onClick={() => { }}
                                        data={data}
                                        isIncomeFieldDisable={true}
                                        isPremiumDisable={true}
                                    />
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded my-3'>
                                        <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Policy Term</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Policy Term'
                                            menuList={ptList}
                                            value={this.state.pt}
                                            onSelect={this.onPtChange}
                                            showErrorMsg={FamilyProtectionStore.isFamilyPolicyTerm}
                                        />
                                    </div>
                                </Col>
                                <Col md={6} className='col-12'>
                                    <div className='shadow p-3 bg-white rounded my-3'>
                                        <div className='mb-2 text-description-text-color font-size-14 font-size-md-16'>Premium Paying Term</div>
                                        <BottomsheetDropdown
                                            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                                            menuItemStyle='d-flex justify-content-between align-items-center'
                                            selectedLabelKey='label'
                                            bottomsheetTitle='Premium Paying Term'
                                            menuList={pptList}
                                            value={this.state.ppt}
                                            onSelect={this.onPptChange}
                                            showErrorMsg={FamilyProtectionStore.isFamilyPremiumTerm}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <AmountCalculation
                                cardKey={this.props.cardKey}
                                item={data}
                                totalPremium={data.BI.TOTAL_PREM}
                                gst={data.BI.TAX_MP}
                                total_price={totalPrice}
                                gst_percentage={(data.BI.TAX_RATE * 100)}
                            />
                        </React.Fragment>
                        : <div>Data Not Found</div>
                }
            </div>
        );
    }
});

SimplyProtect.defaultProps = {
    disabled: false
}

SimplyProtect.propTypes = {
    cardKey: PropTypes.string.isRequired,
    addInsured: PropTypes.func,
    data: PropTypes.object,
    disabled: PropTypes.bool
}

export default SimplyProtect;