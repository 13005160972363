import React, { Component } from 'react';
import { Form, Col, Row, Container, Button } from 'react-bootstrap';


import AuthStore from '../store/AuthStore';
import AxiosErrorModal from '../../axios-error-modal/AxiosErrorModal'

import { observer } from 'mobx-react';

const LogIn = observer(class LogIn extends Component {
  constructor() {
    super();
    this.state = {
      validated: false,
      email: '',
      password: '',
      showFirstTimeModal: false
    };
    this.submit = this.submit.bind(this);
  }
  async submit(event) {
    const { isSubmit } = this.state;
    if (isSubmit) return;

    this.setState({ isSubmit: true })
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      const { email } = this.state;
      await AuthStore.fetchAuthToken(email);
    }
    this.setState({ validated: true, isSubmit: false });
  }

  render() {
    const { validated, email } = this.state;
    return (
      <div className='mainWrapper'>
        <Container>
          <Row>
            <Col lg={{ span: 6, offset: 3 }} xs={12} sm={12}>
              <h2 className='marginTop20 verifiedAccount'>Login</h2>
              <p className='marginTop20 subHeading'>
                Please enter your Email Address
              </p>
            </Col>
          </Row>
          <Form noValidate validated={validated} onSubmit={this.submit}>
            <Form.Row>
              <Col
                className='marginTop20'
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                xs={{ span: 10, offset: 1 }}
                sm={{ span: 10, offset: 1 }}
              >
                <Form.Group className='mb-0 textfield-border' controlId='exampleForm.ControlInput1'>
                  <Form.Control
                    required
                    placeholder='Enter Email Address'
                    value={email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                </Form.Group>
                <Form.Control.Feedback type='invalid'>
                  Enter Email Address!
                  </Form.Control.Feedback>
              </Col>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                xs={{ span: 10, offset: 1 }}
                sm={{ span: 10, offset: 1 }}
                className='mt-4'
              >
                <Button variant='primary' className='w-100 font-size-18 m-0' onClick={this.submit}>
                  Login
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </Container>
        <AxiosErrorModal />
      </div>
    );
  }
});

export default LogIn;
