import React, { Component } from 'react';

import { Modal, Row, Col, Image } from 'react-bootstrap';
import { Button as UiButton } from '../../../components';
import { ResumeSessionIcon } from 'constants/Images';

import './error-modal.css';

class ErrorModal extends Component {
    render() {
        const { onHideModal, showModal, title, subTitle, hideResumeSession, onResumeSession } = this.props;
        return (
            <Modal
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                centered
                backdrop="static"
                className='error-modal'
                show={showModal}
                onHide={onHideModal}
                keyboard={false}
            >
                <Modal.Body className='text-center text-primary-title-label-color px-4 pt-4 pb-0'>

                    <React.Fragment>
                        <Image className='warning-icon mt-md-1 error-img' src={ResumeSessionIcon} alt='share-icon' />
                        <div className='pt-3 pt-md-5'>
                            <span className='font-weight-medium font-size-26 text-primary-title-label-color'>{title}</span>
                        </div>
                        {subTitle && <div className='padding-114 mt-3'>
                            <span className='font-size-md-20 font-size-16 text-primary-title-label-color'>{subTitle}</span>
                        </div>}
                    </React.Fragment>

                </Modal.Body>
                <Modal.Footer className='d-block p-md-4 border-0'>
                    <Row className=''>

                        {hideResumeSession ?
                            <Col xs='12' md='12'>
                                <UiButton onClick={onHideModal} className='font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0' variant='secondary' buttonText='Okay' />
                            </Col>
                            :
                            <React.Fragment>
                                <Col xs='12' md='6'>
                                    <UiButton onClick={onResumeSession} className='font-size-medium font-size-16 px-2 w-100 mb-2 mb-md-0' variant='secondary' buttonText='Resume' />
                                </Col>
                                <Col xs='12' md='6'>
                                    <UiButton onClick={onHideModal} className='font-size-medium font-size-16 px-2 w-100' variant='outline-secondary' buttonText='Cancel' />
                                </Col>
                            </React.Fragment>
                        }

                    </Row>
                </Modal.Footer>
            </Modal>
        );
    }
}


ErrorModal.defaultProps = {
    hideResumeSession: true
};

export default ErrorModal;
