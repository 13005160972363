// package Imports
import { toJS } from "mobx";

// project Imports
import axios from "utils/axios";
import * as APITypes from "constants/API";
import AuthStore from "modules/login/store/AuthStore";

const fetchNeedMaster = async () => {
    const needMasterList = toJS(AuthStore.needMasterList)

    if(needMasterList.length > 0){
        return needMasterList
    }

    const needResponse = await axios.get(APITypes.NEED_MASTER)

    if (needResponse.data && needResponse.status == 'success') {
        AuthStore.setNeedMasterList(needResponse.data)
        return needResponse.data
    }
    return []
}


export default fetchNeedMaster