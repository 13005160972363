const { Form } = require("mobx-react-form");

// Package Imports
import dvr from "mobx-react-form/lib/validators/DVR";
import validatorjs from "validatorjs";
import moment from "moment";

// Project Imports
import formObserver from "./observers";
import setInitialDefaults from "./initialDefaults.js";
import * as Constants from "constants/Constants";
import * as keys from "./keys";
import Utils from "./utils";
import { CovidVaccinationStatusList } from 'constants/CovidVaccinationStatusList';
import OptimizationStore from "modules/optimization/store/OptimizationStore";
class GcapFormController extends Form {
  plugins() {
    return {
      dvr: dvr(validatorjs),
    };
  }

  setup() {
    return {
      fields: [
        {
          name: "tobacco-user",
          label: "Tobacco Consumer?",
          type: "radio",
          options: [
            {
              id: "1",
              label: "Yes",
            },
            {
              id: "0",
              label: "No",
            },
          ],
        },
        {
          name: "policy-term",
          label: "Policy Term",
          placeholder: "",
          disabled: false,
          value: "",
          extra: {
            optionList: [],
            onChangePolicyTerm(policyTermValue, form, data) {
              let pptList = Utils.calculatePPT(policyTermValue.id, data.data.age);

              form.$("premium-term").set("extra", {
                ...form.$("premium-term").extra,
                optionList: pptList,
              });

              let ppt = {}
              
                switch (true) {
                  case policyTermValue.id >= 25:
                  case policyTermValue.id === '80-@LI_ENTRY_AGE':
                  case policyTermValue.id === '85-@LI_ENTRY_AGE':
                  case policyTermValue.id === '100-@LI_ENTRY_AGE':
                    ppt =  { id: 20, label: "20" }
                  break;

                  case policyTermValue.id >= 20 && policyTermValue.id < 25:
                    ppt =  { id: 15, label: "15" }
                  break;

                  case policyTermValue.id >= 15 && policyTermValue.id < 20:
                    ppt =  { id: 10, label: "10" }
                  break;

                  case policyTermValue.id < 15:
                    ppt =  { id: 7, label: "7" }
                  break;
                  
                  default:
                    ppt = pptList.find((item) => item.id === policyTermValue.id);

                }


              form.$("policy-term").set("value", policyTermValue);
              form.$("premium-term").set("value", ppt);

              let requestObject = {
                pt: policyTermValue.id,
                ppt: ppt.id,
              };
              Utils.updateBi(keys.PR_PT, requestObject, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "premium-term",
          label: "Premium Paying Term",
          placeholder: "",
          disabled:false,
          extra: {
            optionList: [],
            onChangePremiumTerm(premiumTermValue, form, data) {
              form.$("premium-term").set("value", premiumTermValue);
              Utils.updateBi(keys.PR_PPT, premiumTermValue.id, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "payment-frequency",
          label: "Premium Paying Frequency",
          placeholder: "",
          extra: {
            isVisible: false,
            optionList: [
              { id: "1", label: "Annual" },
              { id: "2", label: "Semi-Annual" },
              { id: "3", label: "Quarterly" },
              { id: "4", label: "Monthly" },
            ],
          },
        },
        {
          name: "la-annual-income",
          label: "LA Annual Income",
          placeholder: "LA Annual Income",
          disabled: false,
          extra: {},
        },
        {
          name: "income",
          label: "Income",
          placeholder: "",
          disabled: true,
        },
        {
          name: "premium",
          label: "Premium",
          placeholder: "",
          disabled: true,
          extra: {
            calculatePremium(form, data) {
              // let premiumAmount = 0;
              // let inputMode = Number(form.$("payment-frequency").value.id);

              // let amount = Number(form.$("premium").value);
              // switch (inputMode) {
              //   case 1:
              //     premiumAmount = "" + amount * 1;
              //     break;
              //   case 2:
              //     premiumAmount = "" + amount * 2;
              //     break;
              //   case 3:
              //     premiumAmount = "" + amount * 4;
              //     break;
              //   case 4:
              //     premiumAmount = "" + amount * 12;
              //     break;
              // }
              // Utils.updateBi(keys.PREMIUM, premiumAmount, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "la-education",
          label: "LA Education",
          placeholder: "LA Education",
          disabled: false,
          extra: {
            optionList: [
              { label: "Below 10th", id: 1 },
              { label: "10th Passed", id: 2 },
              { label: "12th Passed", id: 3 },
              { label: "Graduate", id: 4 },
              { label: "Post Graduate", id: 5 },
              { label: "Professional", id: 6 },
              { label: "for Others", id: 7 },
              { label: "Student", id: 8 },
            ],
          },
        },
        {
          name: "la-occupation",
          label: "LA Occupation",
          placeholder: "LA Occupation",
          disabled: false,
          extra: {
            optionList: [
              { label: "Salaried", id: "SA" },
              { label: "Self Employed (Professional)", id: "SM" },
              { label: "Self Employed (Business)", id: "BM" },
              { label: "Agriculture", id: "A" },
              { label: "Housewife", id: "H" },
              { label: "Retired", id: "R" },
              { label: "Labourer/Worker", id: "L" },
            ],
          },
        },
        {
          name: "underwriting-status",
          label: "Underwriting status",
          placeholder: "Underwriting status",
          disabled: false,
          value: { label: "Medical", id: 1 },
          extra: {
            optionList: [
              { label: "Medical", id: 1 },
              { label: "Non-Medical", id: 2 },
            ],
          },
        },
        {
          name: "marital-status",
          label: "Marital status",
          placeholder: "Marital status",
          disabled: false,
          extra: {
            optionList: [
              { label: "Single", id: 1 },
              { label: "Married", id: 2 },
            ],
            onChangeMaritalStatus(maritalStatusValue, form, data) {
              form.$("marital-status").set("value", maritalStatusValue);

              if (maritalStatusValue.id === 1) {
                form.$("better-half-benefit").set("disabled", true);
                form.$("better-half-benefit").set("value", false);
                form.$("name-of-spouse").set("value", "");
                form.$("age-of-spouse").set("value", "");
                form.$("gender-of-spouse").set("value", "");
              } else {
                form.$("better-half-benefit").set("disabled", false);
              }

              let requestData = {
                betterHalfBenefit: maritalStatusValue.id === 1 ? 15 : form.$("better-half-benefit").value == true ? 14 : 15,
                maritalStatus: maritalStatusValue.id,
                spouseName: "",
                spouseAge: "",
                spouseDOB: "",
                spouseGender: "",
              };
              Utils.updateBi(keys.MARITAL_STATUS, requestData, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "spouse-tobacco-consumer",
          label: "spouse Tobacco Consumer",
          placeholder: "Underwriting status",
          disabled: true,
          value: { label: "No", id: 137 },
          extra: {
            optionList: [
              { label: "Yes", id: 138 },
              { label: "No", id: 137 },
            ],
          },
        },
        {
          name: "better-half-benefit",
          label: "Better Half Benefit",
          placeholder: "",
          type: "radio",
          value: "",
          extra: {
            description: "Option to get an additional life cover for your spouse.",
            sum_assured: "",
            changeBenefitStatus(form, data) {

              let value = form.$("better-half-benefit").value;
              form.$("better-half-benefit").value = !value;
              value = !value;
              if (!value) {
                form.$("name-of-spouse").set("value", "");
                form.$("age-of-spouse").set("value", "");
                form.$("gender-of-spouse").set("value", "");
              }

              let requestData = {
                betterHalfBenefit: value ? 14 : 15,
                spouseName: "",
                spouseAge: "",
                spouseDOB: "",
                spouseGender: "",
              };
              Utils.updateBi(keys.BETTER_HALF_BENEFIT, requestData, data.data.product_id, form, data);
            },
          },
          disabled: false,
        },
        {
          name: "name-of-spouse",
          label: "Name Of Spouse",
          placeholder: "Name Of Spouse",
          disabled: false,
          value: "",
          extra: {
            onSpouseNameChange(value, form, data) {
              form.$("name-of-spouse").value = value;
              Utils.updateBi(keys.SPOUSE_NAME, value, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "age-of-spouse",
          label: "Age Of Spouse ",
          placeholder: "",
          extra: {
            changeDob(value, form, data) {
              let productId = data.data.product_id;
              let spouseDOB = value == "" ? FamilyDetailsStore.dateValue : value;
              form.$("age-of-spouse").set("value", spouseDOB);
              let dob = moment(spouseDOB).year();
              let currentYear = moment().year();
              let age = currentYear - dob;
              let requestata = {
                age: age,
                dob: moment(spouseDOB).format("DD MMM YYYY"),
              };
              Utils.updateBi(keys.SPOUSE_DOB, requestata, productId, form, data);
            },
          },
        },
        {
          name: "gender-of-spouse",
          label: "Gender of Spouse",
          placeholder: "Gender of Spouse",
          extra: {
            optionList: [
              { label: "Male", id: 18 },
              { label: "Female", id: 19 },
            ],
          },
        },
        {
          name: "plan-option",
          label: "Plan option",
          placeholder: "Plan option",
          extra: {
            optionList: [
              { label: "Life Cover Option", id: 3 },
              { label: "Return of Premium Option", id: 4 },
              { label: "Live Long Option", id: 5 },
            ],
          },
        },
        {
          name: "income-payout-start-age",
          label: "Live long option - Income Payout Start Age",
          placeholder: "Live long option - Income Payout Start Age",
          extra: {
            optionList: [
              { label: "60", id: 6 },
              { label: "65", id: 7 },
            ],
          },
        },
        {
          name: "mode-of-income-pay-out",
          label: "Live long option - Mode of Income pay-out",
          placeholder: "Live long option - Mode of Income pay-out",
          extra: {
            optionList: [
              { label: "Annual", id: 8 },
              { label: "Semi-Annual", id: 9 },
              { label: "Quarterly", id: 10 },
              { label: "Monthly", id: 11 },
            ],
          },
        },
        {
          name: "income-pay-out",
          label: "Live long option - Payout%",
          placeholder: "Live long option - Payout%",
          extra: {
            optionList: [],
          },
        },
        {
          name: "childs-future-protect-benefit",
          label: "Childs Future Protect Benefit",
          placeholder: "Childs Future Protect Benefit",
          type: "radio",
          value: "",
          extra: {
            optionList: [
              { label: "Yes", id: 20 },
              { label: "No", id: 21 },
            ],
            onChangechildBenefit(form, data) {
              let value = form.$("childs-future-protect-benefit").value;
              form.$("childs-future-protect-benefit").value = !value;
              value = !value;

              let lifeAssuredAge = form.$("child-of-life-assured-age").value;
              let additionalSA = form.$("additional-sa").value.id;

              let childDob = "";
              let currentYear = "";
              let age = "";
              if (lifeAssuredAge) {
                childDob = moment(lifeAssuredAge).year();
                currentYear = moment().year();
                age = currentYear - childDob;
              }

              if (value) {
                let requestData = {
                  lifeAssuredAge: age,
                  additionalSA: additionalSA,
                  childBenefitData: 20,
                  lifeAssuredDob: lifeAssuredAge,
                };
                Utils.updateBi(keys.CHILD_FUTURE_PROTECT_BENEFIT, requestData, data.data.product_id, form, data);
              } else {
                let requestData = {
                  lifeAssuredAge: "",
                  additionalSA: "",
                  childBenefitData: 21,
                  lifeAssuredDob: "",
                };

                form.$("child-of-life-assured-age").set("value", "");
                form.$("additional-sa").set("value", "");

                Utils.updateBi(keys.CHILD_FUTURE_PROTECT_BENEFIT, requestData, data.data.product_id, form, data);
              }
            },
          },
        },
        {
          name: "child-of-life-assured-age",
          label: "Child of Life Assured Age",
          placeholder: "Child of Life Assured Age",
          extra: {
            onChangeChildAssuredAge(value, form, data) {
              form.$("child-of-life-assured-age").set("value", value);
              let childDob = moment(value).year();
              let currentYear = moment().year();
              let age = currentYear - childDob;

              let requestData = {
                lifeAssuredAge: age,
                lifeAssuredDob: moment(value).format("DD MMM YYYY"),
              };
              Utils.updateBi(keys.CHILD_DOB, requestData, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "additional-sa",
          label: "Child Future Protect Benefit - Additional SA (as % of BSA)",
          placeholder: "Child Future Protect Benefit - Additional SA (as % of BSA)",
          extra: {
            optionList: [],
            onChangeAdditionalSa(value, form, data) {
              form.$("additional-sa").set("value", value);
              Utils.updateBi(keys.ADDITIONAL_SA, value.id, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "death-benefit-option",
          label: "Death Benefit option",
          placeholder: "Death Benefit option",
          extra: {
            optionList: [
              { label: "Lumpsum", id: 33 },
              { label: "Monthly Income", id: 34 },
              { label: "Lumpsum plus Monthly Income", id: 35 },
            ],
            onDeathBenefitOption(value, form, data) {
              form.$("death-benefit-option").set("value", value);
              let payoutOptionList = Utils.payoutOptionPercentage(value.label);

              form.$("payout-option").set("extra", {
                ...form.$("payout-option").extra,
                optionList: payoutOptionList,
              });
              form.$("payout-option").set("value", payoutOptionList[0]);
              let requestData = {
                deathBenefitOption: value.id,
                payoutOption: payoutOptionList[0].id,
              };

              if (value.id === 33) {
                form.$("payout-option").set("disabled", true);
                form.$("payout-period").set("value", "");
                requestData.payoutPeriod = "";
                Utils.updateBi(keys.DEATH_BENEFIT_PAYOUT_OPTION, requestData, data.data.product_id, form, data);
              }

              if (value.id === 34) {
                form.$("payout-option").set("disabled", false);
                form.$("payout-period").set("value", form.$("payout-period").extra.optionList[0]);
                requestData.payoutPeriod = form.$("payout-period").extra.optionList[0].id;
                Utils.updateBi(keys.DEATH_BENEFIT_PAYOUT_OPTION, requestData, data.data.product_id, form, data);
              }

              if (value.id === 35) {
                form.$("payout-option").set("disabled", false);
                form.$("payout-period").set("value", form.$("payout-period").extra.optionList[0]);
                requestData.payoutPeriod = form.$("payout-period").extra.optionList[0].id;
                Utils.updateBi(keys.DEATH_BENEFIT_PAYOUT_OPTION, requestData, data.data.product_id, form, data);
              }
            },
          },
        },
        {
          name: "payout-period",
          label: "Payout Period(Months)",
          placeholder: "Payout Period",
          extra: {
            optionList: [
              { label: "36", id: 37 },
              { label: "60", id: 38 },
            ],
            onChangePayoutPeriod(value, form, data) {
              form.$("payout-period").set("value", value);
              Utils.updateBi(keys.PAYOUT_PERIOD, value.id, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "payout-option",
          label: "Payout Option (%) - LumpSum",
          placeholder: "Payout Option (%) - LumpSum",
          disabled: false,
          extra: {
            optionList: [], // Utils.payoutOptionPercentage("Lumpsum")
            onChangePayoutOption(value, form, data) {
              form.$("payout-option").set("value", value);
              Utils.updateBi(keys.PAYOUT_OPTIONS, value.id, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "covid-vaccination-status",
          label: "Covid Vaccination Status",
          placeholder: "Covid Vaccination Status",
          value: {},
          extra: {
            changeVaccinationStatus(form, value, data) {
              let oldVaccinationStatus = form.$("covid-vaccination-status").value.id
              form.$("covid-vaccination-status").value = value
              if (oldVaccinationStatus != value.id) {
                let requestData = {
                  covid_vaccination_status: value.id,
                  family_product_id: data.data.family_product_id,
                  product_id: data.data.product_id,
                  package_id: data.data.package_id
                }
                OptimizationStore.getRevisedRecoSaTermNeedApiCall(requestData)
              }
            },
            optionList: CovidVaccinationStatusList
          },
        },
        // Riders Selection(Radio button) Fields
        {
          name: "WaiverOfPremiumRider",
          label: "Waiver of Premium",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.WOP_RIDER_DESCRIPTION,
            paramName: "WaiverOfPremiumRider",
            rider_name: "Waiver of Premium Rider",
            changeRiderStatus(form, data) {
              let value = form.$("WaiverOfPremiumRider").value;
              form.$("WaiverOfPremiumRider").value = !value;
              let productId = data.data.product_id;
              let wopRiderValue = "";

              if (!value) {
                wopRiderValue = "1";
              }
              form.$("WaiverOfPremiumRider").value = !value;

              Utils.updateBi(keys.WOP_RIDER, wopRiderValue, productId, form, data);
            },
          },
        },
        {
          name: "ADB",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.ADB_RIDER_DESCRIPTION,
            paramName: "ADBRider",
            rider_name: "ADB Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ADB").value;
              form.$("ADB").value = !value;

              if (form.$("ADB").value) {
                form.$("ADB-dropdown").set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("ADB-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "ATPDRider",
          label: "Accidental Total/Perm. Disability",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.ATPD_RIDER_DESCRIPTION,
            paramName: "ATPDRider",
            rider_name: "ATPD Rider",
            changeRiderStatus(form, data) {
              let value = form.$("ATPDRider").value;
              form.$("ATPDRider").value = !value;

              if (form.$("ATPDRider").value) {
                form.$("ATPDRider-dropdown").set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("ATPDRider-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "CriticalIllnessRider",
          label: "Critical Illness",
          placeholder: "",
          value: false,
          extra: {
            description: Constants.CRITICAL_ILLNESS_RIDER_DESCRIPTION,
            paramName: "CriticalIllnessRider",
            rider_name: "Critical Illness Rider",
            changeRiderStatus(form, data) {
              let value = form.$("CriticalIllnessRider").value;
              form.$("CriticalIllnessRider").value = !value;

              if (form.$("CriticalIllnessRider").value) {
                form.$("CriticalIllnessRider-dropdown").set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("CriticalIllnessRider-dropdown").set("value", "");
              }
            },
          },
        },
        {
          name: "HospitalityCashBenefitRider",
          label: "Hospital Cash Benefit",
          placeholder: "",
          value: false,
          extra: {
            description: "",
            paramName: "HospitalityCashBenefitRider",
            rider_name: "Hospital Cash Benefit Rider",
            changeRiderStatus(form, data) {
              let value = form.$("HospitalityCashBenefitRider").value;
              form.$("HospitalityCashBenefitRider").value = !value;

              if (form.$("HospitalityCashBenefitRider").value) {
                form.$("HospitalityCashBenefitRider-dropdown").set("value", Constants.RIDERS_DEFAULT_SA);
              } else {
                form.$("HospitalityCashBenefitRider-dropdown").set("value", "");
              }
            },
          },
        },

        // Riders Input Fields
        {
          name: "ADB-dropdown",
          label: "Accidental Death Benefit",
          placeholder: "",
          value: "",
          extra: {
            onBlur(form, data) {
              let value = form.$("ADB-dropdown").value;
              Utils.updateBi(keys.ADB_RIDER, value, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "ATPDRider-dropdown",
          label: "Permanent Disability",
          placeholder: "",
          value: "",
          extra: {
            onBlur(form, data) {
              let value = form.$("ATPDRider-dropdown").value;
              Utils.updateBi(keys.ATPD_RIDER, value, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "CriticalIllnessRider-dropdown",
          label: "Critical Illness",
          placeholder: "",
          value: "",
          extra: {
            onBlur(form, data) {
              let value = form.$("CriticalIllnessRider-dropdown").value;
              Utils.updateBi(keys.Critical_ILLNESS_RIDER, value, data.data.product_id, form, data);
            },
          },
        },
        {
          name: "HospitalityCashBenefitRider-dropdown",
          label: "Hospital Cash Benefit",
          placeholder: "",
          value: "",
          extra: {
            onBlur(form, data) {
              let value = form.$("HospitalityCashBenefitRider-dropdown").value;
              Utils.updateBi(keys.HOSPITALITY_CASH_BENEFIT_RIDER, value, data.data.product_id, form, data);
            },
          },
        },
      ],
    };
  }

  setDefaults(data) {
    // Set forms initial default value
    setInitialDefaults(this, data);

    // Observer fields
    formObserver(this, data);
  }
}

export default GcapFormController;
