import React, { Component } from 'react'
import { Button, Image } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'
import './dropdown-gender.css'

import DropDownComponent from '../dropdown/Dropdown'
import { CloseIconWhite, CheckIcon } from 'constants/Images';

class DropdownGender extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showOverlay: false,
      selectedGender: ''
    }
  }
  onCloseIconClick = () => {
    let selectedGender = this.props.selectedGender
    this.setState({ showOverlay: false, selectedGender })
  }

  onDropDownBtnClick = () => {
    this.setState((state) => ({ showOverlay: true }))
  }

  onCorrectIconClick = () => {
    let { selectedGender } = this.props
    this.onSelect(selectedGender)
    this.setState({ showOverlay: false })
  }

  onSelect = (value) => {
    const { onChange } = this.props
    if (onChange) {
      switch (value.id) {
        case 1:
          value = 'male'
          break
        case 2:
          value = 'female'
          break
      }
      console.log('SELECTED GENDER >> ', value)
      onChange(value)
    }
  }

  getActualObject = value => {
    let genderlist = this.props.genderlist
    switch (value) {
      case 'male':
        return genderlist[0]
      case 'female':
        return genderlist[1]
      // case 'other':
      //   return genderlist[2]
      default:
        return ''
    }
  }

  onGenderOptionClick = (value) => {
    this.setState({ selectedGender: value })
  }

  componentDidMount() {
    if (!this.props.selectedGender) {
      this.setState({ selectedGender: this.props.selectedGender })
    }
  }


  render() {
    let { bottomsheetTitle, dropdownStyle, menuItemStyle, selectedGender, labelKey, showErrorMsg, defaultLabel } = this.props;
    selectedGender = this.getActualObject(selectedGender)
    const { showOverlay, } = this.state;
    const genderlist = this.props.genderlist
    return (
      <React.Fragment>
        <div className='d-md-block d-none'>
          <DropDownComponent menuList={genderlist} onSelect={this.onSelect} selectedLabelKey={labelKey ? labelKey : 'value'} value={selectedGender} {...this.props} />
        </div>
        <div className='d-md-none d-block'>
          <Dropdown show={showOverlay} className='gender-bottomsheet-dropdown d-inline-block' onToggle={this.onDropDownBtnClick}>
            <div className={'dropdown-overlay'} />
            <Dropdown.Toggle variant='' id='dropdown-gender' className={`${dropdownStyle} gender-bottomsheet-dropdown-toggle d-flex align-items-center justify-content-between border-0 ${showErrorMsg ? 'validation-border' : ''}`}>
              <span className='mob-gender-btn-label'>{labelKey ? (selectedGender ? selectedGender[labelKey] : defaultLabel) : (selectedGender ? selectedGender.label : defaultLabel)}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className='gender-custom-menu-list'>
              <div className='d-flex justify-content-between p-4 align-items-start mb-2 bg-gender-bottomsheet-title-bg-color'>
                <Button variant='' className='py-0 px-1 shadow-none' onClick={this.onCloseIconClick}>
                  <Image width='15' height='15' src={CloseIconWhite} alt='close-icon' />
                </Button>
                <h3 className='text-black font-size-16 mb-0'>{bottomsheetTitle}</h3>
                <Button variant='' className='py-0 px-1 shadow-none' onClick={this.onCorrectIconClick}>
                  <Image width='18' height='18' src={CheckIcon} alt='check-icon' />
                </Button>
              </div>
              <div className='px-2 px-md-0'>
                {genderlist.map((item) => {
                  return (
                    <Dropdown.Item
                      className={`${menuItemStyle} gender-bottomsheet-dropdown-menu-item text-center font-size-20 font-size-md-16 ${selectedGender ? selectedGender.id == item.id ? 'active' : '' : ''}`}
                      key={item.id}
                      id={item.id}
                      onClick={() => {
                        this.onSelect(item);
                      }}>
                      {item.value}
                    </Dropdown.Item>
                  )
                })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          {showErrorMsg &&
            <p className='text-danger mb-0 font-weight-light font-size-12 align-items-start d-flex'>Select a value</p>
          }
        </div>
      </React.Fragment>
    )
  }
}

DropdownGender.defaultProps = {
  defaultLabel: 'Gender',
  showErrorMsg: false
}

export default DropdownGender
