export function atLeastOneShouldBeSelected(target) {
  return ({ field, form }) => {
    let emptyValueArray = [];
    const valueObject = field.get();
    var size = Object.keys(form.values().monthly_expense).length;
    let monthyExpenseForm = form.$("monthly_expense");

    let currentLocation = window.location.pathname

    for (const key in valueObject) {
      if (Object.keys(valueObject[key].value).length === 0 && valueObject[key].constructor === Object) {
        emptyValueArray.push("empty object");
      }
    }

    const selected = emptyValueArray.length !== size ? true : false;

    if (!monthyExpenseForm.has("household") || (form.has("show_add_family_member_modal") && form.$("show_add_family_member_modal")?.value == true)) {
      return;
    }

    if (!selected && !currentLocation.includes("family-details")) {
      monthyExpenseForm.$("household").invalidate("Please fill at least one detail");
      monthyExpenseForm.$("rent").invalidate("Please fill at least one detail");
      monthyExpenseForm.$("school_fee").invalidate("Please fill at least one detail");
      monthyExpenseForm.$("others").invalidate("Please fill at least one detail");
    } else {
      monthyExpenseForm.$("household").resetValidation();
      monthyExpenseForm.$("rent").resetValidation();
      monthyExpenseForm.$("school_fee").resetValidation();
      monthyExpenseForm.$("others").resetValidation();
    }


    if(currentLocation.includes("customization")){
      monthyExpenseForm.$("household").resetValidation();
      monthyExpenseForm.$("rent").resetValidation();
      monthyExpenseForm.$("school_fee").resetValidation();
      monthyExpenseForm.$("others").resetValidation();
    }

    return [selected, `Please fill at least one detail`];
    // return [true/false, "error_message"]
  };
}
