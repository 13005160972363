import calculateProductPremiumRelatedValues from "utils/calculateProductPremiumRelatedValues";
import calculateRiderPremium from "utils/calculateRiderPremium";
import OptimizationStore from "modules/optimization/store/OptimizationStore";
import * as Constants from "constants/Constants";
import ProductNames from "constants/ProductNames";
import prefillDeathCoverGraphSliderAllAmounts from "utils/forward-reverse-methods/prefillDeathCoverGraphSliderAllAmounts";

class Utils {
  calcualateTotalPremiumComponentData = (firstYearData, biResponse, requestData, prodcutName, productData) => {
    let productPremiumAmount = 0;
    let productPremiumGstPercent = 0;
    let productPremiumGstAmount = 0;
    let showRiderPremium = false;
    let riderPremiumAmount = 0;
    let riderPremiumGstPercent = 0;
    let riderPremiumGstAmount = 0;
    let totalPayableAmount = 0;
    let multiplier = "";
    let frequencyLabel = "";
    let paymentFrequency = requestData?.INPUT_MODE?.toString();
    let totalPremium = OptimizationStore.fetchProductPrice(prodcutName, firstYearData, biResponse, productData);
    let riderTotalPremium = 0;

    let calculatedData = calculateProductPremiumRelatedValues(paymentFrequency, totalPremium);

    multiplier = calculatedData.multiplier;
    // totalPremium = calculatedData.totalPremium
    frequencyLabel = calculatedData.frequencyLabel;

    let totalRiderPremium = calculateRiderPremium(biResponse?.inputValidationStatus).totalCalculatedValue;

    // productPremiumAmount = Math.round(firstYearData?.MODAL_PREM) || 0;
    let getAllAmounts = prefillDeathCoverGraphSliderAllAmounts(productData);
    productPremiumAmount = getAllAmounts.selectedPremium;
    productPremiumGstPercent = firstYearData?.TAX_RATE ? (firstYearData?.TAX_RATE * 100).toFixed(2) : Constants.PRODUCT_GST_PERCENT;
    productPremiumGstAmount = firstYearData?.TAX_MP ? Math.round(firstYearData?.TAX_MP) : "";

    showRiderPremium = biResponse?.inputValidationStatus?.length > 0;
    riderPremiumAmount = totalRiderPremium;
    riderPremiumGstPercent = Constants.RIDER_GST_PERCENT;
    // riderPremiumGstAmount = requestData?.PR_ID === ProductNames.totalProtectPlus ? firstYearData?.RIDER_TOTAL : firstYearData?.RIDER_TOTAL_TAX ? firstYearData?.RIDER_TOTAL_TAX : 0;
    riderPremiumGstAmount = calculateRiderPremium(biResponse?.inputValidationStatus).totalGstAmount;
    if (multiplier !== "") {
      totalPremium = (productPremiumAmount + productPremiumGstAmount) * multiplier;
      riderTotalPremium = (riderPremiumAmount + riderPremiumGstAmount) * multiplier;
    } else if (productPremiumGstAmount) {
      totalPremium = productPremiumAmount + productPremiumGstAmount;
      riderTotalPremium = riderPremiumAmount + riderPremiumGstAmount;
    }
    if (showRiderPremium) {
      totalPayableAmount = Math.round(totalPremium) + Math.round(riderTotalPremium);
    } else {
      totalPayableAmount = Math.round(totalPremium);
    }

    return {
      productPremiumAmount,
      productPremiumGstPercent,
      productPremiumGstAmount,
      showRiderPremium,
      riderPremiumAmount,
      riderPremiumGstPercent,
      riderPremiumGstAmount,
      totalPayableAmount,
      multiplier,
      frequencyLabel,
    };
  };
}

export default new Utils();
