import AuthStore from '../../../../login/store/AuthStore';
import errorMessageKeys from './errorMessageKeys';
import Utils from '../../../../../constants/utils';
import OptimizationStore from '../../../store/OptimizationStore';
import fetchRiderValidation from 'utils/fetchRiderValidation'
import ProductConstants from 'constants/ProductConstants';


class Validator {
    valid = (formRef, data) => {
        let error = '';
        let validatons = new Validation();
        let keys = Object.keys(validatons);
        for (let i = 0; i <= keys.length; i++) {
            validatons[keys[i]]
            if (validatons[keys[i]] instanceof Function) {
                validatons[keys[i]](formRef, data)
            }
        }

        if (validatons.errorMessage.length > 0) {
            OptimizationStore.validateProduct(data.data.product_id, true)
            validatons.errorMessage.forEach((msg, index) => error += `${index + 1}) ${msg} `)
            AuthStore.setErrorModal(error)
        } else {
            OptimizationStore.validateProduct(data.data.product_id, false)
        }

        return validatons.errorMessage;

    }
}

class Validation {
    errorMessage = [];

    // Minimum Premium Validation
    minimalAnnualPremium = (form) => {
        // let frequency = form.$('payment-frequency').value.id
        // let premium = form.$('premium').value

        // switch (frequency) {
        //     case '1':
        //         if (premium < 50000) {
        //             this.errorMessage.push(errorMessageKeys.premium.annual_premium)
        //         }
        //         break;

        //     case '2':
        //         if (premium < 25600) {
        //             this.errorMessage.push(errorMessageKeys.premium.half_yearly_premium)
        //         }
        //         break

        //     case '3':
        //         if (premium < 13000) {
        //             this.errorMessage.push(errorMessageKeys.premium.quaterly_premium)
        //         }
        //         break;

        //     case '4':
        //         if (premium < 4400) {
        //             this.errorMessage.push(errorMessageKeys.premium.monthly_premium)
        //         }
        //         break;
        // }
    }

    // Family Income Benefit Minimum Entry Age
    familyIncomeBenefitMinimumAge = (form, productData) => {
        // let age = productData.data.bi_request.LI_ENTRY_AGE;
        // let isFamilyIncomeBenefitSelected = form.$('family-income').value

        // if (isFamilyIncomeBenefitSelected && age < 18) {
        //     this.errorMessage.push(errorMessageKeys.family_income_benefit)
        // }
    }

    // Family Income Benefit Maximum Entry Age for 5 PPT
    familyIncomeBenefitMaximumAgeFor5PPT = (form, productData) => {
        // let isFamilyIncomeBenefitSelected = form.$('family-income').value
        // let premiumTerm = form.$('premium-term').value
        // let age = productData.data.bi_request.LI_ENTRY_AGE;
        // let maturityAge = form.$('maturity-age').value
        // let earlyIncome = form.$('income-option').value
        // let isEarlyIncomeSelected = earlyIncome.id == '3';

        // if (!isFamilyIncomeBenefitSelected) {
        //     return;
        // }

        // if (premiumTerm.id != "5") {
        //     return;
        // }

        // if (isEarlyIncomeSelected) {
        //     // Early Income
        //     if (maturityAge.id == '75-@li_entry_age' && age > 50) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 75 Maturity age is 50 years')
        //     }

        //     if (maturityAge.id == '85-@li_entry_age' && age > 45) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 85 Maturity age is 45 years')
        //     }

        //     if (maturityAge.id == '99-@li_entry_age' && age > 40) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 90 Maturity age is 40 years')
        //     }
        // } else {
        //     // Deferred Income
        //     if (maturityAge.id == '75-@li_entry_age' && age > 50) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 75 Maturity age is 50 years')
        //     }

        //     if (maturityAge.id == '85-@li_entry_age' && age > 50) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 85 Maturity age is 50 years')
        //     }

        //     if (maturityAge.id == '99-@li_entry_age' && age > 40) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 90 Maturity age is 40 years')
        //     }
        // }
    }

    // Family Income Benefit Maximum Entry Age for Other PPT excluding 5 PPT
    familyIncomeBenefitMaximumAgeForOtherPPT = (form, productData) => {
        // let isFamilyIncomeBenefitSelected = form.$('family-income').value
        // let premiumTerm = form.$('premium-term').value
        // let age = productData.data.bi_request.LI_ENTRY_AGE;
        // let maturityAge = form.$('maturity-age').value
        // let earlyIncome = form.$('income-option').value
        // let isEarlyIncomeSelected = earlyIncome.id == '3';

        // if (!isFamilyIncomeBenefitSelected) {
        //     return;
        // }

        // if (premiumTerm.id != "5") {
        //     return;
        // }

        // if (isEarlyIncomeSelected) {
        //     // Early Income
        //     if (maturityAge.id == '75-@li_entry_age' && age > 50) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 75 Maturity age is 50 years')
        //     }

        //     if (maturityAge.id == '85-@li_entry_age' && age > 45) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 85 Maturity age is 45 years')
        //     }

        //     if (maturityAge.id == '99-@li_entry_age' && age > 40) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 90 Maturity age is 40 years')
        //     }
        // } else {
        //     // Deferred Income
        //     if (maturityAge.id == '75-@li_entry_age' && age > 50) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 75 Maturity age is 50 years')
        //     }

        //     if (maturityAge.id == '85-@li_entry_age' && age > 45) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 85 Maturity age is 45 years')
        //     }

        //     if (maturityAge.id == '99-@li_entry_age' && age > 40) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 90 Maturity age is 40 years')
        //     }
        // }
    }

    // Non Family Income Benefit Maximum Entry Age for 5 PPT
    nonFamilyIncomeBenefitMaximumAgeFor5PPT = (form, productData) => {
        // let isFamilyIncomeBenefitSelected = form.$('family-income').value
        // let premiumTerm = form.$('premium-term').value
        // let age = productData.data.bi_request.LI_ENTRY_AGE;
        // let maturityAge = form.$('maturity-age').value
        // let earlyIncome = form.$('income-option').value
        // let isEarlyIncomeSelected = earlyIncome.id == '3';

        // if (isFamilyIncomeBenefitSelected) {
        //     return;
        // }

        // if (premiumTerm.id != "5") {
        //     return;
        // }

        // if (isEarlyIncomeSelected) {
        //     // Early Income
        //     if (maturityAge.id == '75-@li_entry_age' && age > 50) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 75 Maturity age is 50 years')
        //     }

        //     if (maturityAge.id == '85-@li_entry_age' && age > 55) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 85 Maturity age is 55 years')
        //     }

        //     if (maturityAge.id == '99-@li_entry_age' && age > 50) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 90 Maturity age is 50 years')
        //     }
        // } else {
        //     // Deferred Income
        //     if (maturityAge.id == '75-@li_entry_age' && age > 50) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 75 Maturity age is 50 years')
        //     }

        //     if (maturityAge.id == '85-@li_entry_age' && age > 55) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 85 Maturity age is 55 years')
        //     }

        //     if (maturityAge.id == '99-@li_entry_age' && age > 55) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 90 Maturity age is 55 years')
        //     }
        // }
    }

    // Non Family Income Benefit Maximum Entry Age for Other PPT excluding 5 PPT
    nonFamilyIncomeBenefitMaximumAgeForOtherPPT = (form, productData) => {
        // let isFamilyIncomeBenefitSelected = form.$('family-income').value
        // let premiumTerm = form.$('premium-term').value
        // let age = productData.data.bi_request.LI_ENTRY_AGE;
        // let maturityAge = form.$('maturity-age').value
        // let earlyIncome = form.$('income-option').value
        // let isEarlyIncomeSelected = earlyIncome.id == '3';

        // if (!isFamilyIncomeBenefitSelected) {
        //     return;
        // }

        // if (premiumTerm.id == "5") {
        //     return;
        // }

        // if (isEarlyIncomeSelected) {
        //     // Early Income
        //     if (maturityAge.id == '75-@li_entry_age' && age > 50) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 75 Maturity age is 50 years')
        //     }

        //     if (maturityAge.id == '85-@li_entry_age' && age > 60) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 85 Maturity age is 60 years')
        //     }

        //     if (maturityAge.id == '99-@li_entry_age' && age > 65) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 90 Maturity age is 65 years')
        //     }
        // } else {
        //     // Deferred Income
        //     if (maturityAge.id == '75-@li_entry_age' && age > 50) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 75 Maturity age is 50 years')
        //     }

        //     if (maturityAge.id == '85-@li_entry_age' && age > 60) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 85 Maturity age is 60 years')
        //     }

        //     if (maturityAge.id == '99-@li_entry_age' && age > 65) {
        //         this.errorMessage.push('For Early Income, Maximum Entry age for 90 Maturity age is 65 years')
        //     }
        // }
    }

    // Riders age validation
    ridersAgeValidation = (form, productData) => {
        // let age = productData.data.bi_request.LI_ENTRY_AGE;
        // let wop = form.$('WaiverOfPremiumRider').value
        // let adb = form.$('ADB').value
        // let atpd = form.$('ATPDRider').value
        // let criticalIllness = form.$('CriticalIllnessRider').value
        // let incomeBenefit = form.$('IncomeBenefitRider').value
        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // let isRiderSelected = (wop || adb || atpd || criticalIllness || incomeBenefit);

        // if (!isRiderSelected) {
        //     return;
        // }

        // if (isMinimumAge) {
        //     this.errorMessage.push('Minimum Entry age for riders is 18 years')
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push('Maximum Entry age for riders is 65 years')
        // }

    }

    // Three generation Plan Validations
    threeGenerationPlanValidation = (form, productData) => {
        // let productName = productData.productName;
        // let maturityAge = form.$('maturity-age').value;
        // let familyIncome = form.$('family-income').value;
        // let incomeType = form.$('guranteed-income').value;

        // if (productName != ProductConstants.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN) {
        //     return;
        // }

        // if (maturityAge.id != '99-@li_entry_age') {
        //     this.errorMessage.push('For Three Generation Plan, Maturity age needs to be 99 Years')
        // }

        // if (incomeType.id != '4') {
        //     this.errorMessage.push('For Three Generation Plan, Guranteed Income needs to be level')
        // }

        // if (familyIncome) {
        //     this.errorMessage.push('For Three Generation Plan, Family Income benefit is not available')
        // }
    }

    // Whole Life Active Income Validation
    wholeLifeActiveIncomeValidation = (form, productData) => {
        // let productName = productData.productName;
        // let maturityAge = form.$('maturity-age').value;
        // let incomeType = form.$('guranteed-income').value;

        // if (productName != ProductConstants.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN) {
        //     return;
        // }

        // if (maturityAge.id != '99-@li_entry_age') {
        //     this.errorMessage.push('For Whole Life Active Income Plan, Maturity age needs to be 99 Years')
        // }

        // if (incomeType.id != '4') {
        //     this.errorMessage.push('For Whole Life Active Income Plan, Guranteed Income needs to be level')
        // }


    }

    // Retire Befiker Validations
    retireBefikerValidation = (form, productData) => {
        // let productName = productData.productName;
        // let maturityAge = form.$('maturity-age').value;
        // let familyIncome = form.$('family-income').value;

        // if (productName != ProductConstants.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN) {
        //     return
        // }

        // if (maturityAge.id != '99-@li_entry_age') {
        //     this.errorMessage.push('For Retire Befiker Plan, Maturity age needs to be 99 Years')
        // }

        // if (familyIncome) {
        //     this.errorMessage.push('For Retire Befiker Plan, Family Income benefit is not available')
        // }
    }

    // Active Income Plan with FIB
    aipWithFIBValidation = (form, productData) => {
        // let familyIncome = form.$('family-income').value;
        // let productName = productData.productName;

        // if (productName != ProductConstants.ACTIVE_INCOME_PLAN_WITH_FIB) {
        //     return
        // }
        // if (familyIncome) {
        //     this.this.errorMessage.push('For Active Income Plan with FIB, Family Income benefit needs to be selected')
        // }
    }

    // rider sum assured validation
    riderSAValidation = (form, productData) => {
        // if (productData.data.firstYearResponse) {
        //     let sum_assured = productData.data.firstYearResponse.SA

        //     if (form.$('ADB').value) {
        //         var ADB_value = Utils.actualValue(form.$('ADB-dropdown').value)
        //         if (ADB_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }

        //     if (form.$('ATPDRider').value) {
        //         var ATPDRider_value = Utils.actualValue(form.$('ATPDRider-dropdown').value)
        //         if (ATPDRider_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }

        //     if (form.$('CriticalIllnessRider').value) {
        //         var CriticalIllnessRider_value = Utils.actualValue(form.$('CriticalIllnessRider-dropdown').value)
        //         if (CriticalIllnessRider_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }

        //     if (form.$('IncomeBenefitRider').value) {
        //         var IncomeBenefitRider_value = form.$('IncomeBenefitRider-dropdown').value.id
        //         if (IncomeBenefitRider_value > sum_assured) {
        //             this.errorMessage.push(errorMessageKeys.rider)
        //         }
        //     }
        // }
    }

    // PWB Riders age validation
    pwbRidersAgeValidation = (form, productData) => {
        // let age = productData.data.bi_request.PROPOSER_AGE;
        // let isSameProposer = productData.data.bi_request.SameProposer == true
        // let PWBRider = form.$('PayorWaiverBenefitRider').value
        // let isMinimumAge = (age < 18)
        // let isMaximumAge = (age > 65)

        // if (!PWBRider) {
        //     return;
        // }

        // if (isSameProposer) {
        //     this.errorMessage.push(errorMessageKeys.PWB_RIDER_VALIDATION)
        // }
        // if (isMinimumAge) {
        //     this.errorMessage.push(errorMessageKeys.MINIMUM_RIDER_AGE)
        // }
        // if (isMaximumAge) {
        //     this.errorMessage.push(errorMessageKeys.MAXIMUM_RIDER_AGE)
        // }

    }

    // Income Option Validation
    incomeOptionValidation = (form, productData) => {
        // let incomeOption = form.$('income-option').value.id
        // let guaranteedIncome = form.$('guranteed-income').value.id

        // if (incomeOption == '4') {
        //     return
        // }

        // if (incomeOption == '3' && guaranteedIncome == '6') {
        //     this.errorMessage.push(errorMessageKeys.INCOME_OPTION_VALIDATION)
        // }
    }

    // Riders Validation
    ridersValidation = (formRef, productData) => {
        // let criticalIllness = formRef.$('CriticalIllnessRider-dropdown').value
        // let adb = formRef.$('ADB-dropdown').value
        // let atpd = formRef.$('ATPDRider-dropdown').value
        // let income = formRef.$('IncomeBenefitRider-dropdown').value

        // let adbValidation = fetchRiderValidation(adb, formRef.$('ADB-dropdown').label)
        // if (adbValidation) {
        //     this.errorMessage.push(adbValidation)
        // }

        // let atpdValidation = fetchRiderValidation(atpd, formRef.$('ATPDRider-dropdown').label)
        // if (atpdValidation) {
        //     this.errorMessage.push(atpdValidation)
        // }

        // let criticalIllnessValidation = fetchRiderValidation(criticalIllness, formRef.$('CriticalIllnessRider-dropdown').label)
        // if (criticalIllnessValidation) {
        //     this.errorMessage.push(criticalIllnessValidation)
        // }

        // let incomeValidation = fetchRiderValidation(income, formRef.$('IncomeBenefitRider-dropdown').label)
        // if (incomeValidation) {
        //     this.errorMessage.push(incomeValidation)
        // }
    }

}

export default new Validator();