// Project Imports
import isArrayEmpty from "./isArrayEmpty"
import isObjectEmpty from "./isObjectEmpty"
import isStringEmpty from "./isStringEmpty"

// Returns False if the value is not empty
// Return True if the value is empty
const checkIsValueEmpty = (value) => {
    if (value === undefined) return true

    if (value === null) return true

    if (typeof value === 'string') {
        return isStringEmpty(value)
    }

    if (Array.isArray(value)) {
        // check for empty array
        return isArrayEmpty(value)
    }

    if (typeof value === 'object') {
        return isObjectEmpty(value)
    }
}

export default checkIsValueEmpty