
const FamilyMemberAttributesEnum = {
 FMAILY_MEMBER_ID:"id",
 SALUTATION:"salutation",
 FIRST_NAME: "first_name",
 MIDDLE_NAME: "middle_name",
 LAST_NAME:"last_name",
 GENDER:"gender",
 DOB:"dob",
 AGE:"age",
 FMAILY_MEMBER_TYPE:"family_member_type",
 NAME:"name",
 IS_SELECTED: 'isSelected'
}

export default FamilyMemberAttributesEnum;
