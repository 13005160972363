export const PR_PT = "PR_PT";
export const PR_PPT = "PR_PPT";
export const FREQUENCY = "INPUT_MODE";
export const DEATH_BENEFIT_OPTION = "DeathBenefitOption";
export const PREMIUM = "PR_ANNPREM";

export const ADB_RIDER = "ADBRider";
export const ATPD_RIDER = "ATPDRider";
export const CRITICAL_ILLNESS_RIDER = "CriticalIllnessRider";
export const INCOME_BENEFIT_RIDER = "IncomeBenefitRider";
