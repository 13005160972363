// Package imports
import { observer } from "mobx-react";
import { Form } from "react-bootstrap";

// Project Imports
import CardTextFieldComponent from "modules/family-details-form/components/CardTextFieldComponent";
import { BottomsheetDropdown } from "components";
import Utils from "./utils";

import SprmDetailForm from "./SPRM/sprmDetailForm";

const PreNeedForm = ({ formController, hideModal, submitData, ...remainingProps }) => {
  return (
    <div className='bg-white modal-field-section pb-3 d-flex flex-column justify-content-between'>
      <div className='row'>
        <div className={`col-12 ${formController.$("journey_type").value.id == 1 ? 'col-lg-7 mt-3 mt-lg-4 pt-lg-3' : 'mt-3 mt-lg-4 pt-lg-3' }`}>
          <div className='row'>
            <div className='col-12'>
              <p className='pre-need-modal-label font-weight-medium'>{formController.$("journey_type").label}</p>
            </div>
          </div>

          <BottomsheetDropdown
            dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
            menuItemStyle='d-flex justify-content-between align-items-center'
            menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
            selectedLabelKey='label'
            bottomsheetTitle={formController.$("journey_type").extra.bottomSheetTitle}
            menuList={formController.$("journey_type").extra.optionList}
            value={formController.$("journey_type").value}
            onSelect={formController.$("journey_type").onChange}
            showErrorMsg={formController.$("journey_type").error !== null}
            errorMsg={formController.$("journey_type").error}
            disabled={false}
          />
        </div>

        {formController.$("journey_type").value.id == 1 ? 
          <div className='col-12 col-lg-5 mt-4 pt-lg-3 d-none d-md-block'>
          <p className='pre-need-modal-label font-weight-medium'>Location</p>
          <CardTextFieldComponent
            className='font-size-12 font-weight-light'
            value={formController.$("location").value}
            menuList={formController.$("location").extra.optionList}
            placeholder={formController.$("location").placeholder}
            onChange={formController.$("location").onChange}
            showErrorMsg={formController.$("location").error !== null}
            errorMsg={formController.$("location").error}
            onSearch={async (value) => await Utils.fetchPincode(value, formController)}
          />
        </div>
         : null}

        {/* modal-new-customer-col */}
        <div className='col-12 customer-Fields-container'>
          <div className='row mt-4'>
            <div className='col-12 col-lg-7'>
              <div className='row pt-1 pb-1'>
                {formController.$("customer_type").extra.optionList.map((item, index) => (
                  <div className='col-6 pr-0' key={item.id}>
                    <label className='cursor-pointer font-size-lg-12 font-size-16 font-weight-light d-inline-flex align-items-center'>
                      <input
                        className='mr-1'
                        type='radio'
                        value={item.value}
                        checked={formController.$("customer_type").value === item.value}
                        name={formController.$("customer_type").name}
                        onChange={formController.$("customer_type").onChange}
                      />{" "}
                      {item.label}
                    </label>
                  </div>
                ))}
              </div>

              {formController.$("customer_type").value === "Existing Customer" && (
                <div className='row'>
                  <div className='col-5 pr-0'>
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      selectedLabelKey='label'
                      bottomsheetTitle={formController.$("existing_customer_type_option_list").extra.bottomSheetTitle}
                      menuList={formController.$("existing_customer_type_option_list").extra.optionList}
                      value={formController.$("existing_customer_type_option_list").value}
                      showErrorMsg={formController.$("existing_customer_type_option_list").error !== null}
                      errorMsg={formController.$("existing_customer_type_option_list").error}
                      onSelect={formController.$("existing_customer_type_option_list").onChange}
                      disabled={formController.$("existing_customer_type_option_list").disabled}
                    />
                  </div>

                  <div className='col-7 pl-2'>
                    <Form.Group className='mb-0'>
                      <Form.Control
                        type={formController.$("existing_customer_type_option_list").type}
                        className='font-size-lg-12 font-size-14 font-weight-light existing-customer-value'
                        placeholder={formController.$("existing_customer_type_option_list").placeholder}
                        value={formController.$("existing_customer_value").value}
                        onChange={(e) => {
                          formController.$("existing_customer_value").validate({ showErrors: true });
                          formController.$("existing_customer_value").onChange(e);
                        }}
                        style={formController.$("existing_customer_value").error ? { border: "1px solid red" } : null}
                      />
                      {formController.$("existing_customer_value").error != null ? (
                        <p className='font-size-11 font-weight-light text-danger'>{formController.$("existing_customer_value").error}</p>
                      ) : null}
                    </Form.Group>
                  </div>
                </div>
              )}
            </div>

            <div className='col-12 col-lg-5 modal-category-col mt-4 mt-lg-0'>
              <div className='row'>
                <div className={`d-flex flex-column flex-lg-row ${formController.$("category").error ? "" : "align-items-lg-center"} mb-lg-2 col-12 col-lg-12`}>
                  <p className={`pre-need-modal-label d-block font-weight-medium mr-1 mb-lg-0 font-size-14 font-size-lg-10 ${formController.$("category").error ? "mt-2" : ""}`}>Category</p>
                  <div className='w-100'>
                    <BottomsheetDropdown
                      dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                      menuItemStyle='d-flex justify-content-between align-items-center'
                      menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                      selectedLabelKey={formController.$("category").extra.selectedOptionLabelKey}
                      bottomsheetTitle={formController.$("category").extra.bottomSheetTitle}
                      menuList={formController.$("category").extra.optionList}
                      value={formController.$("category").value}
                      onSelect={formController.$("category").onChange}
                      showErrorMsg={formController.$("category").error !== null}
                      errorMsg={formController.$("category").error}
                      disabled={formController.$("category").disabled}
                    />
                  </div>
                </div>

                {formController.$("category").value.label === "Staff" && (
                  <Form.Group className='col-6 col-lg-12 mb-0 employee-code'>
                    <Form.Control
                      className='font-size-lg-12 font-size-14 font-weight-light'
                      placeholder={formController.$("employee_code").placeholder}
                      value={formController.$("employee_code").value}
                      onChange={formController.$("employee_code").onChange}
                      style={formController.$("employee_code").error ? { border: "1px solid red" } : null}
                    />
                    {formController.$("employee_code").error != null ? <p className='font-size-11 font-weight-light text-danger'>{formController.$("employee_code").error}</p> : null}
                  </Form.Group>
                )}
                
               { formController.$("journey_type").value.id == 1 &&
                <div className='col-12 col-lg-5 mt-4 pt-lg-3 d-md-none'>
                  <p className='pre-need-modal-label font-weight-medium'>Location</p>
                  <CardTextFieldComponent
                    className='font-size-12 font-weight-light'
                    value={formController.$("location").value}
                    menuList={formController.$("location").extra.optionList}
                    placeholder={formController.$("location").placeholder}
                    onChange={formController.$("location").onChange}
                    showErrorMsg={formController.$("location").error !== null}
                    errorMsg={formController.$("location").error}
                    onSearch={async (value) => await Utils.fetchPincode(value, formController)}
                  />
                </div>}

              </div>
            </div>
          </div>
        </div>
        <div className='col-12 col-lg-7'>
          <div className='row mt-4'>
            <div className='col'>
              <p className='pre-need-modal-label font-weight-medium'>Individual's Occupation</p>
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                selectedLabelKey={formController.$("occupation").extra.selectedOptionLabelKey}
                bottomsheetTitle={formController.$("occupation").extra.bottomSheetTitle}
                menuList={formController.$("occupation").extra.optionList}
                value={formController.$("occupation").value}
                onSelect={formController.$("occupation").onChange}
                showErrorMsg={formController.$("occupation").error !== null}
                errorMsg={formController.$("occupation").error}
                disabled={formController.$("occupation").disabled}
              />
            </div>
          </div>

          <div className='row mt-4'>
            <div className='col'>
              <p className='pre-need-modal-label font-weight-medium'>Individual's Education</p>
            </div>
          </div>

          <div className='row'>
            <div className='col'>
              <BottomsheetDropdown
                dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
                menuItemStyle='d-flex justify-content-between align-items-center'
                menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
                selectedLabelKey={formController.$("education").extra.selectedOptionLabelKey}
                bottomsheetTitle={formController.$("education").extra.bottomSheetTitle}
                menuList={formController.$("education").extra.optionList}
                value={formController.$("education").value}
                onSelect={formController.$("education").onChange}
                showErrorMsg={formController.$("education").error !== null}
                errorMsg={formController.$("education").error}
                disabled={formController.$("education").disabled}
              />
            </div>
          </div>
        </div>
      </div>

      {/* SP/RM Detail Form */}
      <SprmDetailForm formController={formController} />

      <div className='row mt-5 mt-lg-2 mb-3'>
        <div className='col-12 col-lg-7'>
          <div className='row'>
            <div className='col-12 col-lg-6 mb-3 mb-lg-0 pb-1 pb-lg-0 order-0 order-lg-1 pl-lg-1'>
              <button className='btn btn-link btn-lg modal-cta-btn modal-cta-btn-link py-2 py-lg-1 font-size-14 font-weight-medium' onClick={() => Utils.handleModalExit(remainingProps, hideModal)}>
                Exit Tool
              </button>
            </div>
            <div className='col-12 col-lg-6 mb-3 mb-lg-0  order-1 order-lg-0 pr-lg-1'>
              <button
                type='submit'
                disabled={formController.$("proceed_btn").disabled}
                className='btn btn-secondary btn-lg modal-cta-btn py-2 py-lg-1 font-size-14 font-weight-medium'
                onClick={submitData}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(PreNeedForm);
