export default {
    "SPOUSE_DETAILS_MANDATORY": "If marital status is Married, then you have to select Spouse Name, Gender & DOB",
    "INCOME_PAYOUT_START_AGE": "Please select value for Live long option - Income Payout Start Age",
    "MODE_OF_INCOME_PAYOUT": "Please select value for Live long option - Mode of Income pay-out",
    "INCOME_PAYOUT": "Please select value for Live long option - Payout%",
    "CHILD_OF_LIFE_ASSURED_AGE": "Please select value for Child of Life Assured Age",
    "ADDITIONAL_SA": "Please select value for Child Future Protect Benefit - Additional SA (as % of BSA)",
    "SPOUSE_NAME": "Please enter name of spouse",
    "SPOUSE_DOB": "Please enter date of birth of spouse",
    "SPOUSE_GENDER": "Please enter gender of spouse",
}