// React Imports
import React, { Component } from "react";

// Package Imports
import { Image, Container, Row, Col } from "react-bootstrap";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";

// Project Imports
import * as images from "constants/Images";
import ChooseNeedsStore from "modules/choose-needs/store/ChooseNeedsStore";
import FamilyDetailsStore from "modules/family-details-form/store/FamilyDetailsStore";
import WarningModal from "../../ui/warning-modal/WarningModal";
import AuthStore from "modules/login/store/AuthStore";
import CalculatorTypeEnum from "modules/choose-needs/components/CalculatorTypeEnum";
import RetirementCalculatorForm from "modules/choose-needs/components/form/retirement-calculator";
import ProtectionCalculatorForm from "modules/choose-needs/components/form/protection-calculator";

// CSS Imports
import "./CalculatorModalComponent.css";
import OptimizationStore from "modules/optimization/store/OptimizationStore";

const CalculatorModalComponent = observer(
  class CalculatorModalComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        dateOfBirth: "",
        showWarningModal: false,
        dob: "",
        showDobError: false,
        dobErrorMsg: "Please select valid date of birth",
        data: [
          {
            name: "Self",
            income: 400000,
            expense: -150000,
            emis: -100000,
          },
        ],
        showCalculatedValue: false,
        sumAssured: "",
      };
      this.onPrevInsuranceDropDownOptionClick = this.onPrevInsuranceDropDownOptionClick.bind(this);
      this.updateDate = this.updateDate.bind(this);
      this.onCalculateClick = this.onCalculateClick.bind(this);
      // this.onCancelClick = this.onCancelClick.bind(this);
      this.onUseTheValueClick = this.onUseTheValueClick.bind(this);
      this.onResetClick = this.onResetClick.bind(this);
      this.setGraphData = this.setGraphData.bind(this);
    }

    onPrevInsuranceDropDownOptionClick(value) {
      FamilyDetailsStore.onPrevInsuranceDropDownOptionClick(value);
      // this.setState({ selectedPrevInsuranacneOptionValue: value })
    }

    updateDate(key, value) {
      if (key == "dob") {
        this.setState({ dob: value, showDobError: false });
      } else {
        this.setState({ dob: "", showDobError: true });
      }
    }

    async onCalculateClick() {
      const monthlyIncome = toJS(ChooseNeedsStore.selectedNeeds.protectionForMyFamily.monthlyIncome);
      const monthlyExpense = toJS(FamilyDetailsStore.selectedMonthlyOptionalDetailsCards);
      const currentSaving = toJS(FamilyDetailsStore.selectedSavingOptionalDetailsCards);
      const monthlySaving = toJS(FamilyDetailsStore.selectedMonthlySavingOptionalDetailsCards);
      const liability = toJS(FamilyDetailsStore.selectedLiabilityOptionalDetailsCards);

      let expenseList = [];
      let emiList = [];

      const needScreenData = [monthlyExpense, currentSaving, monthlySaving, liability];

      /* Function call to set graph data */
      this.setGraphData(monthlyIncome, monthlyExpense, liability);

      needScreenData.forEach((obj) => {
        if (Object.keys(obj).length === 0) {
          return;
        } else {
          for (const selectedKey in obj) {
            console.log("selectedKey ::: ", selectedKey, "::: Obj :: ", obj);
            console.log("obj ::: ", obj);
            if (selectedKey === "Home Loan EMI") {
              emiList.push({
                action: "save",
                expense_range_id: obj[selectedKey].selectedExpense.id,
                expense_range_key: obj[selectedKey].selectedExpense.key,
                expense_range_value: obj[selectedKey].selectedExpense.key,
                paid_years: obj[selectedKey].selectedPaidYear.key,
                total_years: obj[selectedKey].selectedTotalYear.key,
                user_journey: "1007",
              });
            }
            expenseList.push({
              action: "save",
              expense_range_id: obj[selectedKey].selectedExpense.id,
              expense_range_key: obj[selectedKey].selectedExpense.key,
              expense_range_value: obj[selectedKey].selectedExpense.key,
              user_journey: "1007",
            });
          }
        }
      });

      console.log("data of Calculator modal", {
        monthly_income: toJS(ChooseNeedsStore.selectedNeeds.protectionForMyFamily.monthlyIncome),
        dob: moment(this.state.dob).format("YYYY-MM-DD"),
        current_life_cover: toJS(FamilyDetailsStore.selectedPrevInsuranacneOptionValue.id),
        expense_list: [...expenseList],
        emi_list: [...emiList],
        journey_id: 1,
        family_member_id: 1,
        calculator_modal_type: this.props.calculatorModalType,
      });

      if (monthlyIncome.length === 0) {
        console.log("monthly income length is zero");
        ChooseNeedsStore.isMonthlyIncomeAdded = true;
      } else {
        ChooseNeedsStore.isMonthlyIncomeAdded = false;
      }

      if (expenseList.length === 0) {
        this.setState({ showWarningModal: true });
        return;
      }

      if (!toJS(FamilyDetailsStore.familyMembers)[0].dob) {
        this.setState({ showDobError: true });
      } else if (expenseList.length !== 0) {
        this.setState({ showDobError: false });
      }

      let data = {
        monthly_income: toJS(ChooseNeedsStore.selectedNeeds.protectionForMyFamily.monthlyIncome),
        dob: "2021-10-01",
        current_life_cover: "1",
        expense_list: [...expenseList],
        journey_id: AuthStore.journeyId,
        family_member_id: 1,
      };

      let lumpsumValue = "";
      if (this.props.showfieldsForProtectionSection) {
        lumpsumValue = await ChooseNeedsStore.saveProtectionCalculator(data);
      } else {
        lumpsumValue = await ChooseNeedsStore.saveRetirementCalculator(data);
      }

      this.setState({ showCalculatedValue: true, sumAssured: lumpsumValue });
    }

    onUseTheValueClick = async (value) => {
      OptimizationStore.isCalculatorUsed = true
      await this.props.useValueCallback(value);
      this.props.hideCalculatorModal();
    }

    onResetClick() {
      this.setState({ showCalculatedValue: false });
    }

    setGraphData(monthlyIncome, monthlyExpense, liability) {
      console.log("monthlyExpense ::: ", monthlyIncome);
      console.log("monthlyExpense ::: ", monthlyExpense);
      console.log("EMIS ::: ", liability);
      let yearlyIncomeTotal = monthlyIncome * 12;
      let expenseTotal = 0;
      let emiTotal = 0;

      if (Object.keys(monthlyExpense).length === 0) {
        return;
      } else {
        for (const selectedKey in monthlyExpense) {
          expenseTotal = expenseTotal + parseInt(monthlyExpense[selectedKey].selectedExpense.mid_value);
        }
      }

      if (Object.keys(liability).length === 0) {
        return;
      } else {
        for (const selectedKey in liability) {
          let emiValue = parseInt(liability[selectedKey].selectedExpense.mid_value);
          // let paidYrs = parseInt(liability[selectedKey].selectedPaidYear.key);
          // let totalYrs = parseInt(liability[selectedKey].selectedTotalYear.key);
          /*
          UNcomment the below line when the emi master range value is implemented for now it is commented as th outstanding emi value is going very high due to use of loan master. 
          */
          // let outStandingEMI = emiValue * totalYrs - emiValue * paidYrs;
          let outStandingEMI = emiValue;
          emiTotal = emiTotal + outStandingEMI;
        }
      }
      console.log("yearlyIncomeTotal :::: ", yearlyIncomeTotal, "expenseTotal :::: ", -expenseTotal, "emiTotal :::: ", -emiTotal);
      this.setState({
        data: [
          {
            name: "Self",
            income: yearlyIncomeTotal,
            expense: -expenseTotal,
            emis: -emiTotal,
          },
        ],
      });
    }

    onUpdatedate(value) {
      const selfObject = {
        dob: "",
        firstName: "",
        gender: "",
        id: 1,
        isSelected: true,
        lastName: "",
        middleName: "",
        monthlyIncome: "",
        salutation: "",
        type: "Self",
        typeLabel: "Self",
      };
      FamilyDetailsStore.onUpdateMemberDetails(selfObject, "dob", value);
    }


    changeDateOfBirthCallBack = (date) => {
      this.setState({ dateOfBirth: date })
    }

    render() {
      const { isCalculatorModalOpen,
        hideCalculatorModal,
        thumbNailImg,
        title,
        modalDescription,
        memberType,
        modalSecondColTitle,
        targetAmountTypeValue,
        dateOfBirthFromChooseNeeds,
        setDateOfBirthFromChooseNeeds,
        monthlyExpenseValuesFromChooseNeeds,
        setMonthlyExpenseValuesFromChooseNeeds
      } = this.props;
      return (
        <div className='calculatorModalDiv d-flex align-items-center'>
          <Modal show={isCalculatorModalOpen} onHide={hideCalculatorModal} as='section' className='calculatorModal px-0' size='xl' aria-labelledby='contained-modal-title-vcenter' centered>
            <ModalBody className='calculatorModalBody d-flex flex-row px-1 px-lg-2'>
              <section className='d-flex w-100'>
                <Container className='d-flex'>
                  <Row className='my-3 p-lg-1 calculator-modal-top-row'>
                    <button className='position-absolute close-btn-parent' onClick={hideCalculatorModal}>
                      <Image width='15' height='15' src={images.CloseIconBlack} alt='close-icon' />
                    </button>

                    <Col className="calculator-modal-top-first-col">
                      <div className="d-flex align-items-center align-items-lg-start flex-lg-column mb-4 mb-lg-0">

                        <Image className='calculator-modal-icon-style' src={thumbNailImg} />
                        <p className='calculator-modal-text-heading font-size-18 font-weight-medium text-primary mb-0 mb-lg-1' >{title}</p>
                        <p className='mb-0 mb-lg-2 font-size-lg-14 font-size-16 font-weight-light text-secondary ml-3 ml-lg-0'>
                          for <br /> <span className='font-weight-medium'>{memberType}</span>
                        </p>
                      </div>
                      <p className='calculator-modal-description font-size-lg-10 font-weight-light d-none d-lg-block'>{modalDescription}</p>
                      {/* <div className='d-lg-none'>{modalSecondColTitle}</div> */}
                    </Col>
                    {this.props.calculatorModalType === CalculatorTypeEnum.PROTECTION ? (
                      <>
                        <ProtectionCalculatorForm
                          chooseNeedFormController={this.props.chooseNeedFormController}
                          monthlyIncomeValue={this.props.monthlyIncomeValue}
                          targetAmountTypeValue={targetAmountTypeValue}
                          dateOfBirthFromChooseNeeds={dateOfBirthFromChooseNeeds}
                          setDateOfBirthFromChooseNeeds={setDateOfBirthFromChooseNeeds}
                          monthlyExpenseValuesFromChooseNeeds={monthlyExpenseValuesFromChooseNeeds}
                          setMonthlyExpenseValuesFromChooseNeeds={setMonthlyExpenseValuesFromChooseNeeds}
                          hideCalculatorModal={hideCalculatorModal}
                          onUseTheValueClick={this.onUseTheValueClick}
                        />

                      </>
                    ) : null}
                    {this.props.calculatorModalType === CalculatorTypeEnum.RETIREMENT ? (
                      <>
                        <RetirementCalculatorForm
                          chooseNeedFormController={this.props.chooseNeedFormController}
                          dateOfBirthFromChooseNeeds={dateOfBirthFromChooseNeeds}
                          setDateOfBirthFromChooseNeeds={setDateOfBirthFromChooseNeeds}
                          monthlyExpenseValuesFromChooseNeeds={monthlyExpenseValuesFromChooseNeeds}
                          setMonthlyExpenseValuesFromChooseNeeds={setMonthlyExpenseValuesFromChooseNeeds}
                          hideCalculatorModal={hideCalculatorModal}
                          onUseTheValueClick={this.onUseTheValueClick}
                        />
                      </>
                    ) : null}
                  </Row>
                </Container>
              </section>
            </ModalBody>
          </Modal>

          <WarningModal
            showModal={this.state.showWarningModal}
            onHideModal={() => {
              this.setState({ showWarningModal: false });
            }}
            title='select'
            subTitle='atleast one of the dropdown'
          />
        </div>
      );
    }
  }
);

export default CalculatorModalComponent;
