// React Imports
import React, { useEffect, useState } from 'react'

// Package Imports
import { Image } from "react-bootstrap"
import ModalBody from "react-bootstrap/ModalBody"
import { observer } from "mobx-react"
import PropTypes from 'prop-types';
import { toJS } from 'mobx';

// project imports
import * as images from "constants/Images"
import { RETIREMENT_CALCULATOR_MODAL_DESCRIPTION } from "constants/Constants"
import { BottomsheetDropdown } from "components"
import { onRecommend,onSet,onLaunch } from './utils'
import AddInsuredRetirementFormController from './controller';
import AuthStore from 'modules/login/store/AuthStore';
import { familyMemberAgeRelationNameFunction } from 'utils/familyMemberAgeRelationFunction';



const RetirementModalForm = (props) => {

const {hideRetirementPopUpModal,openRetirementCalculator,getFamilyMemberType } = props

 const [formController,setFormController] = useState(undefined)
  
 useEffect(() => {
   let controller = new AddInsuredRetirementFormController()
   let journeyData = toJS(AuthStore.journeyData)
   let journeyId = toJS(AuthStore.journeyId)
   controller.setDefaults(journeyData, journeyId)
   setFormController(controller)
 }, [])

 let singleFamilyMember = familyMemberAgeRelationNameFunction()

 if (formController === undefined) return null

  return (
    <div>
     <ModalBody className="retirement-modal-form add-insured-pop-up-modal-body">
          <section className="h-100">
            <article className=" add-insured-pop-up-modal-content">
              <div className="row justify-content-end">
                <span className="mr-2 cancel-btn-style">Cancel</span>
                <Image
                  className="close-icon-class"
                  src={images.CrossIcon}
                  onClick={() => hideRetirementPopUpModal()}
                />
              </div>

              <div className="row">
                <p className="font-weight-medium label-color">
                {singleFamilyMember.memberName.length === 0 ? `[${singleFamilyMember.relation}], ` : singleFamilyMember.memberName}
                  <span className="member-age">{singleFamilyMember.age} yrs</span>
                </p>
              </div>

              <div className="row mt-5">
                <p className="font-weight-medium label-color text-center text-lg-left">
                  Select an option for setting the cover amount
                </p>
              </div>

              <div className="row d-flex justify-content-between select-option-container">
                <section className="protection-calculator-section mb-4 mb-lg-0">
                  <div className="">
                    <div className="d-flex justify-content-center align-items-center">
                      <Image
                        className="calculator-modal-icon-style"
                        src={images.SaveForRetirementImg}
                      />
                      <p className="modal-text-heading font-size-16 font-weight-medium text-primary mb-0 mb-lg-1">
                        Retirement <br />
                        Calculator
                      </p>
                    </div>
                    <div className="description font-size-10 font-weight-light d-lg-block text-center">
                      {RETIREMENT_CALCULATOR_MODAL_DESCRIPTION}
                    </div>
                    <div className="mt-4 text-center">
                      <button
                        className="modal-btn py-2 py-lg-1 font-size-14 font-weight-medium"
                        onClick={() => onLaunch(formController,openRetirementCalculator,getFamilyMemberType)}
                      >
                        Launch
                      </button>
                    </div>
                  </div>
                </section>

                <section className="or-section mb-4 mb-lg-0">
                  <div className="d-flex h-100 flex-column justify-content-center relative">
                    <div className="seperator-div d-lg-none"></div>
                    <div className="or-container mb-lg-4">
                      <span className="font-weight-medium font-size-14 text-white">
                        OR
                      </span>
                    </div>
                  </div>
                </section>

                <section className="recommend-section mb-4 mb-lg-0">
                  <div className="d-flex h-100 flex-column justify-content-between">
                    <div></div>
                    <div className="">
                      <p className="modal-text-heading text-lg-center font-size-16 font-weight-medium text-primary mb-2 mb-lg-1">
                      {formController.$("recommendation").label}
                      </p>

                      <div className="w-100">
                        <BottomsheetDropdown
                          dropdownStyle="font-size-lg-12 font-size-14 font-weight-light bg-white"
                          menuItemStyle="d-flex justify-content-between align-items-center"
                          menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                          selectedLabelKey="label"
                          bottomsheetTitle={formController.$("recommendation").extra.bottomSheetTitle}
                          menuList={formController.$("recommendation").extra.optionList}
                          value={formController.$("recommendation").value}
                          onSelect={formController.$("recommendation").onChange}
                          showErrorMsg={formController.$("recommendation").error !== null}
                          errorMsg={formController.$("recommendation").error}
                          disabled={formController.$("recommendation").disabled}
                        />
                      </div>
                    </div>

                    <div className="mt-4 text-center">
                      <button
                        type="submit"
                        disabled={false}
                        className="modal-btn py-2 py-lg-1 font-size-14 font-weight-medium recommend-btn"
                        onClick={() => onRecommend(formController,hideRetirementPopUpModal)}
                      >
                        Recommend
                      </button>
                    </div>
                  </div>
                </section>

                <section className="or-section mb-4 mb-lg-0">
                  <div className="d-flex h-100 flex-column justify-content-center">
                    <div className="seperator-div d-lg-none"></div>
                    <div className="or-container mb-lg-4">
                      <span className="font-weight-medium font-size-14 text-white">
                        OR
                      </span>
                    </div>
                  </div>
                </section>

                <section className="amount-section">
                  <div className="d-flex h-100 flex-column justify-content-between">
                    <div></div>
                    <div className="mt-2 mt-lg-0">
                      <p className="modal-text-heading text-lg-center font-size-16 font-weight-medium text-primary mb-2 mb-lg-1">
                      {formController.$("target_amount_dropdown").label}
                      </p>

                      <div className="w-100">
                        <BottomsheetDropdown
                          dropdownStyle="font-size-lg-12 font-size-14 font-weight-light bg-white"
                          menuItemStyle="d-flex justify-content-between align-items-center"
                          menuItemFontStyle="font-size-lg-12 font-size-14 font-weight-light"
                          selectedLabelKey="label"
                          bottomsheetTitle={formController.$("target_amount_dropdown").extra.bottomSheetTitle}
                          menuList={formController.$("target_amount_dropdown").extra.optionList}
                          value={formController.$("target_amount_dropdown").value}
                          onSelect={formController.$("target_amount_dropdown").onChange}
                          showErrorMsg={
                            formController.$("target_amount_dropdown").error !==
                            null
                          }
                          errorMsg={
                            formController.$("target_amount_dropdown").error
                          }
                          disabled={
                            formController.$("target_amount_dropdown").disabled
                          }
                        />
                      </div>
                    </div>

                    <div className="mt-4 text-center">
                      <button
                        className="modal-btn py-2 py-lg-1 font-size-14 font-weight-medium"
                        onClick={() => onSet(formController,hideRetirementPopUpModal)}
                      >
                        Set
                      </button>
                    </div>
                  </div>
                </section>
              </div>
            </article>
          </section>
        </ModalBody>
    </div>
  )
}


RetirementModalForm.propTypes = {
  openRetirementCalculator: PropTypes.func.isRequired,
  hideRetirementPopUpModal:PropTypes.func,
};

export default observer(RetirementModalForm)

