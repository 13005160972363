import OptimizationStore from "modules/optimization/store/OptimizationStore";
import ProductConstants from "constants/ProductConstants";
import utils from "modules/optimization/components/products/income-builder/utils";
import pgiUtils from "modules/optimization/components/products/premium-guaranteed-income/utils";
import * as Constants from "constants/Constants";
import gisUtils from "modules/optimization/components/products/GIS/utils"
function calculate_input_values(data) {
  /**
   * Parameters required to show the content in tab
   * outputAmt = "2272200"
      yearlyOutputAmount = "151480"
      totalOutputYear = "15"
      totalOutputYearSuffixLabel = "years"
      additionalOutputAmt = ""
      inputAmount = amount
      yearlyInputAmount = "100000"
      totalInputYear = "10"
      totalInputYearSuffixLabel = "years"
   */
  let productName = data.product_name;
  return fetchYouPayYouGetPrice(productName, data);

  let inputFormatString = "";
  let outFormatString = "";
  let prodcutName = data.product_name;
  let inputPremium = OptimizationStore.fetchProductPrice(prodcutName, data.firstYearResponse);
  let outputPremium = OptimizationStore.fetchProductPrice(prodcutName, data.firstYearResponse);
  let totalOutputYearSuffixLabel = "years";
  let totalInputYearSuffixLabel = "years";
  let investment_target = data.investment_target;
  let premium_paying_term = data.bi_request.PR_PPT;
  let input_mode = data.bi_request.INPUT_MODE;

  inputFormatString = getFormatedData(input_mode);

  outFormatString = getFormatedData(input_mode);
  outputPremium = 300000; //TODO this is hard coded value, should get changed

  return {
    inputAmount: inputPremium,
    inputStr: inputFormatString,
    yearlyInputAmount: inputPremium,
    totalInputYear: premium_paying_term,
    totalInputYearSuffixLabel: totalInputYearSuffixLabel,
    yearlyOutputAmount: outputPremium,
    outputStr: outFormatString,
    outputAmt: outputPremium,
    totalOutputYear: premium_paying_term,
    totalOutputYearSuffixLabel: totalOutputYearSuffixLabel,
    yearlyOutputAmountBold: yearlyOutputAmountBold
  };
}

function fetchYouPayYouGetPrice(productName, productDetails) {
  console.log("$%$%$%$%$%$%$%$%$%");
  console.log("fetchYouPayYouGetPrice productName :::: ", productName);
  console.log("fetchYouPayYouGetPrice productDetails :::: ", productDetails);
  console.log("$%$%$%$%$%$%$%$%$%");
  let inputAmount = 0; // You pay total amount
  let yearlyInputAmount = 0; // yearly input amount in you pay
  let inputStr = ""; // string just after the yearly input amount
  let totalInputYear = 0; // number of input years/months/etc
  let totalInputYearSuffixLabel = ""; //suffix text to be shown after number of input years
  let outputAmt = 0; // You get total amount
  let totalOutputAmtSuffixLabel = "";
  let yearlyOutputAmount = 0; // yearly output amount in you get
  let outputStr = ""; // string just after the yearly input amount
  let totalOutputYear = 0; // number of output years/months/etc
  let totalOutputYearSuffixLabel = ""; //suffix text to be shown after number of output years
  let belowOutputYearSuffixLabel = ""; //below suffix text
  let additionalOutputAmt = ""; // additional output amount if any
  let outputNote = "";
  let yearlyOutputAmountBold = true

  if (!productDetails?.isValidationExist) {
    switch (productName) {
      case ProductConstants.FOREVER_PENSION:
      case ProductConstants.FOREVER_PENSION_IMMEDIATE_ANNUITY:
      case ProductConstants.FOREVER_PENSION_DEFERRED_ANNUITY:
      case ProductConstants.POS_FOREVER_PENSION:
        let targetAmountForeverPension = Math.round(productDetails?.biResponseCompleteData.find(item => item.MODAL_ANNUITY_PAYOUT !== 0).MODAL_ANNUITY_PAYOUT)
        if (Number(productDetails?.bi_request?.AnnuityMode) === 21) {
          outputAmt = Math.round(targetAmountForeverPension)
        } else if (Number(productDetails?.bi_request?.AnnuityMode) === 22) {
          outputAmt = Math.round(targetAmountForeverPension * 2)
        } else if (Number(productDetails?.bi_request?.AnnuityMode) === 23) {
          outputAmt = Math.round(targetAmountForeverPension * 4)
        } else {
          outputAmt = Math.round(targetAmountForeverPension * 12)
        }
        inputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM);
        yearlyInputAmount = "";
        inputStr = "";
        totalInputYear = "";
        totalInputYearSuffixLabel = "";
        outputAmt = outputAmt;
        totalOutputAmtSuffixLabel = "per annum" + " " + "for Lifetime";
        yearlyOutputAmount = "";
        outputStr = outputStr;
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.EASY_PENSION:
        let annualPremium = productDetails?.firstYearResponse?.MODAL_PREM_DISP;
        if (productDetails?.bi_request?.INPUT_MODE === 1 || productDetails?.bi_request?.INPUT_MODE === 5) {
          annualPremium = annualPremium;
        } else if (productDetails?.bi_request?.INPUT_MODE === 2) {
          annualPremium = annualPremium * 2;
        } else if (productDetails?.bi_request?.INPUT_MODE === 3) {
          annualPremium = annualPremium * 4;
        } else if (productDetails?.bi_request?.INPUT_MODE === 4) {
          annualPremium = annualPremium * 12;
        }

        inputAmount = Math.round(annualPremium * Number(productDetails?.bi_request?.PR_PPT));
        yearlyInputAmount = Math.round(annualPremium);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";
        outputAmt = Math.round(productDetails?.parsedResponse?.PENSION_AMT_TVB);
        totalOutputAmtSuffixLabel = "per annum for Lifetime";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.SARAL_PENSION:
        if (Number(productDetails?.bi_request?.AnnuityMode) === 8) {
          outputStr = "per annum";
          outputAmt = Math.round(productDetails?.firstYearResponse?.SB_G)
        } else if (Number(productDetails?.bi_request?.AnnuityMode) === 9) {
          outputStr = "per half year";
          outputAmt = Math.round(Math.round(productDetails?.firstYearResponse?.SB_G) * 2)
        } else if (Number(productDetails?.bi_request?.AnnuityMode) === 10) {
          outputStr = "per quarter";
          outputAmt = Math.round(Math.round(productDetails?.firstYearResponse?.SB_G) * 4)
        } else {
          outputStr = "per month";
          outputAmt = Math.round(Math.round(productDetails?.firstYearResponse?.SB_G) * 12)
        }
        inputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM);
        yearlyInputAmount = "";
        inputStr = "";
        totalInputYear = "";
        totalInputYearSuffixLabel = "";
        outputAmt = outputAmt;
        totalOutputAmtSuffixLabel = "per annum" + " " + "for Lifetime";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM:
      case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          inputStr = "per annum";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM);
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          inputStr = "per half year";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          inputStr = "per quarter";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          inputStr = "per month";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12;
        }
        inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";

        outputAmt = Math.round(productDetails?.parsedResponse?.MATURITY_BEN);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
      case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
        let outputFrequencyLabel = "";
        let inputFrequencyLabel = "";
        let incomeBenefitPayoutFrequencyValue = 1;
        let incomePayoutDuration = pgiUtils.fetchIncomePayoutDuration(Number(productDetails?.bi_request?.IncomeBenefitPayoutDuration))

        if (Number(productDetails?.bi_request?.IncomeBenefitPayoutFrequency) === 1) {
          incomeBenefitPayoutFrequencyValue = 1;
        } else if (Number(productDetails?.bi_request?.IncomeBenefitPayoutFrequency) === 2) {
          incomeBenefitPayoutFrequencyValue = 2;
        } else if (Number(productDetails?.bi_request?.IncomeBenefitPayoutFrequency) === 3) {
          incomeBenefitPayoutFrequencyValue = 4;
        } else if (Number(productDetails?.bi_request?.IncomeBenefitPayoutFrequency) === 4) {
          incomeBenefitPayoutFrequencyValue = 12;
        }
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          inputFrequencyLabel = "per annum";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM);
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          inputFrequencyLabel = "per half year";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          inputFrequencyLabel = "per quarter";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          inputFrequencyLabel = "per month";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12;
        }
        productDetails?.biResponseCompleteData?.forEach((item) => {
          if (Number(item.INCOME_BEN_PAY_OUT) != 0 && yearlyOutputAmount === 0) {
            yearlyOutputAmount = item.INCOME_BEN_PAY_OUT;
          }
          outputAmt += Math.round(item.INCOME_BEN_PAY_OUT);
        });
        // productDetails?.biResponseCompleteData?.forEach((item) => {
        //   outputAmt += Math.round(item.INCOME_BEN_PAY_OUT);
        // });
        inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        // totalInputYearSuffixLabel = `years + Rs. ${Math.round(productDetails?.firstYearResponse?.DEATH_BENEFIT || 0)}`;
        totalInputYearSuffixLabel = "years";
        outputAmt = outputAmt;
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = Math.round(yearlyOutputAmount);
        outputStr = "per annum";
        totalOutputYear = `for ${incomePayoutDuration}`;
        totalOutputYearSuffixLabel = "years";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
        let incomePayoutDurationLTI = pgiUtils.fetchIncomePayoutDuration(Number(productDetails?.bi_request?.IncomeBenefitPayoutDuration))
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          inputStr = "per annum";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM);
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          inputStr = "per half year";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          inputStr = "per quarter";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          inputStr = "per month";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12;
        }
        productDetails?.biResponseCompleteData?.forEach((item) => {
          if (Number(item.INCOME_BEN_PAY_OUT) != 0 && yearlyOutputAmount === 0) {
            yearlyOutputAmount = item.INCOME_BEN_PAY_OUT;
          }
          outputAmt += Math.round(item.INCOME_BEN_PAY_OUT);
        });
        // productDetails?.biResponseCompleteData?.forEach((item) => {
        //   outputAmt += Math.round(item.INCOME_BEN_PAY_OUT);
        // });
        let lumpsumBenefit = Number(productDetails?.bi_request?.LumpsumBenefit) !== 7

        inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = `years`;
        outputAmt = Math.round(outputAmt + Math.round(productDetails?.parsedResponse?.MATURITY_BEN));
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = Math.round(yearlyOutputAmount);
        outputStr = "per annum";
        totalOutputYear = `for ${incomePayoutDurationLTI}`;
        totalOutputYearSuffixLabel = lumpsumBenefit ? `years + Rs. ${Math.round(productDetails?.parsedResponse?.MATURITY_BEN || 0)}` : 'years';
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.PREMIER_GUARANTEED_RETIREMENT_INCOME:
        let incomePayoutDurationRI = pgiUtils.fetchIncomePayoutDuration(Number(productDetails?.bi_request?.IncomeBenefitPayoutDuration))
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          inputStr = "per annum";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM);
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          inputStr = "per half year";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          inputStr = "per quarter";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4;

        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          inputStr = "per month";
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12;
        }
        productDetails?.biResponseCompleteData?.forEach((item) => {
          if (Number(item.INCOME_BEN_PAY_OUT) != 0 && yearlyOutputAmount === 0) yearlyOutputAmount = item.INCOME_BEN_PAY_OUT;
        });
        productDetails?.biResponseCompleteData?.forEach((item) => {
          outputAmt += Math.round(item.INCOME_BEN_PAY_OUT);
        });
        if (Number(productDetails?.bi_request?.IncomeBenefitPayoutType) === 6) belowOutputYearSuffixLabel = "Increasing Annually";
        inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = `years`;

        outputAmt = Math.round(outputAmt + Math.round(productDetails?.parsedResponse?.MATURITY_BEN));
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = Math.round(yearlyOutputAmount);
        outputStr = "per annum";
        totalOutputYear = `for ${incomePayoutDurationRI}`;
        totalOutputYearSuffixLabel = `years + Rs. ${Math.round(productDetails?.parsedResponse?.MATURITY_BEN) || 0}`;
        belowOutputYearSuffixLabel = belowOutputYearSuffixLabel;
        break;

      case ProductConstants.ACTIVE_INCOME_PLAN:
      case ProductConstants.THREE_GENERATION_PLAN_ACTIVE_INCOME_PLAN:
      case ProductConstants.ACTIVE_INCOME_PLAN_WITH_FIB:
      case ProductConstants.WHOLE_LIFE_ACTIVE_INCOME_ACTIVE_INCOME_PLAN:
      case ProductConstants.RETIRE_BEFIKER_ACTIVE_INCOME_PLAN:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
        }
        inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
        let targetAmount = 0;
        let totalTargetAmount = 0;
        let earlyIncomeTargetAmt = 0;
        let totalEarlyIncomeTargetAmount = 0;
        productDetails?.biResponseCompleteData?.forEach((item, index) => {
          if (Number(productDetails?.bi_request?.PR_PPT) === index) targetAmount = Math.round(item.TOTAL_SB_G_BS_2);
          if (Number(productDetails?.bi_request?.PR_PPT)  <= index) totalTargetAmount += Math.round(item.TOTAL_SB_G_BS_2);
        });
        if (Number(productDetails?.bi_request?.IncomeOption) === 3) {
          earlyIncomeTargetAmt = Math.round(productDetails?.biResponseCompleteData?.[1].TOTAL_SB_G_BS_2);
          yearlyOutputAmount = earlyIncomeTargetAmt;
          if(earlyIncomeTargetAmt < targetAmount) {
            yearlyOutputAmountBold = false
          }
          productDetails?.biResponseCompleteData?.forEach((item, index) => {
            // if (Number(productDetails?.bi_request?.PR_PPT) - 1 >= index && index !== 0) {
            totalEarlyIncomeTargetAmount += Math.round(item.TOTAL_SB_G_BS_2);

            // }
          });
          totalOutputYear = Number(productDetails?.bi_request?.PR_PPT) - 1;
          totalOutputYearSuffixLabel = <span>years + Rs. <ins className='font-weight-medium'>{targetAmount}</ins> per annum for {Number(productDetails?.bi_request?.PR_PT.split("-")[0]) - Number(productDetails?.bi_request?.LI_ENTRY_AGE) - Number(productDetails?.bi_request?.PR_PPT)} years + Rs. {Math.round(productDetails?.parsedResponse?.TOTAL_MB_G_BS_2)}</span> 
          
          // totalOutputYearSuffixLabel = `years + Rs. ${targetAmount} per annum for ${99 - Number(productDetails?.bi_request?.LI_ENTRY_AGE) - Number(productDetails?.bi_request?.PR_PPT)
          //   } years + Rs. ${Math.round(productDetails?.parsedResponse?.TOTAL_MB_G_BS_2)}`;
          outputAmt = Math.round(totalEarlyIncomeTargetAmount) + Math.round(productDetails?.parsedResponse?.TOTAL_MB_G_BS_2);
        } else {
          yearlyOutputAmount = targetAmount;
          totalOutputYear = Number(productDetails?.bi_request?.PR_PT.split("-")[0]) - Number(productDetails?.bi_request?.LI_ENTRY_AGE) - Number(productDetails?.bi_request?.PR_PPT);
          totalOutputYearSuffixLabel = `years + Rs. ${Math.round(productDetails?.parsedResponse?.TOTAL_MB_G_BS_2)}`;
          outputAmt = Math.round(totalTargetAmount) + Math.round(productDetails?.parsedResponse?.TOTAL_MB_G_BS_2);
        }
        if (Number(productDetails?.bi_request?.GuaranteedIncomeType) === 6) belowOutputYearSuffixLabel = "Increasing Annually";
        inputAmount = inputAmount;
        yearlyInputAmount = yearlyInputAmount;
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";
        outputAmt = outputAmt;
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = yearlyOutputAmount;
        outputStr = "per annum for";
        totalOutputYear = totalOutputYear;
        totalOutputYearSuffixLabel = totalOutputYearSuffixLabel;
        belowOutputYearSuffixLabel = belowOutputYearSuffixLabel;
        yearlyOutputAmountBold = yearlyOutputAmountBold
        break;

      case ProductConstants.CASH_FLOW_PROTECTION_PLUS:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
        }
        inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
        let cFTargetAmount = 0;
        let cFTotalTargetAmount = 0;
        let cFSurvivalBenefit = 0;
        let cFMaturityBenefit = 0;
        productDetails?.biResponseCompleteData?.forEach((item, index) => {
          if (Number(productDetails?.bi_request?.PR_PPT) + 1 === index) cFTargetAmount = item.TOTAL_SB_BS_2;
          // if (Number(productDetails?.bi_request?.PR_PPT) + 1 <= index) cFTotalTargetAmount += Math.round(item.TOTAL_SB_BS_2);
          cFTotalTargetAmount += Math.round(item.TOTAL_SB_BS_2);
          if (Number(productDetails?.bi_request?.PR_PPT) - 1 === index) cFSurvivalBenefit = Math.round(item.TOTAL_SB_BS_2);
        });
        cFMaturityBenefit = Math.round(productDetails?.parsedResponse?.SB_G);
        cFTotalTargetAmount = cFTotalTargetAmount + cFMaturityBenefit;
        totalOutputYear = 100 - Number(productDetails?.bi_request?.LI_ENTRY_AGE) - Number(productDetails?.bi_request?.PR_PPT);

        inputAmount = inputAmount;
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";
        outputAmt = Math.round(cFTotalTargetAmount);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = Math.round(cFTargetAmount);
        outputStr = "per annum";
        totalOutputYear = `for ${totalOutputYear}`;
        totalOutputYearSuffixLabel = `years + Rs. ${Math.round(cFSurvivalBenefit)} + Rs. ${Math.round(cFMaturityBenefit)}`;
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.INCOME_BUILDER:
      case ProductConstants.SECURED_INCOME_OPTION_INCOME_BUILDER:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(Math.round(productDetails?.firstYearResponse?.MODAL_PREM));
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(Math.round(productDetails?.firstYearResponse?.MODAL_PREM)) * 2;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(Math.round(productDetails?.firstYearResponse?.MODAL_PREM)) * 4;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(Math.round(productDetails?.firstYearResponse?.MODAL_PREM)) * 12; 
        }

        inputAmount = Math.round(yearlyInputAmount) * Number(productDetails?.bi_request?.PR_PPT);
        productDetails?.biResponseCompleteData?.forEach((item) => {
          outputAmt += Math.round(item.INCOME_DISPLAY);
          if (Number(item.PAYOUT_PERIOD) == 1) {
            yearlyOutputAmount = Math.round(item.INCOME_DISPLAY);
          }
        });
        let payoutPeriodList = utils.getPayoutPeriodList();
        let payoutPeriodValue = payoutPeriodList.find((item) => Number(item.id) == Number(productDetails?.bi_request?.PayoutPeriodInYears));

        if (Number(productDetails?.bi_request?.MaturityBenefitOption) === 13) {
          totalOutputYear = `for ${payoutPeriodValue?.label || productDetails?.bi_request?.PayoutPeriodInYears}`;
          totalOutputYearSuffixLabel = "years";
        } else {
          let maturityBenefit = 0;
          productDetails?.biResponseCompleteData?.forEach((item) => {
            if (Number(item.INCOME_DISPLAY) != 0 && maturityBenefit === 0) {
              maturityBenefit = Math.round(item.INCOME_DISPLAY);
            }
            if (Number(item.PAYOUT_PERIOD) >= 1) {
              outputAmt += Math.round(item.MODAL_INCOME);
            }
          });

          totalOutputYear = `for ${Number(payoutPeriodValue?.label || productDetails?.bi_request?.PayoutPeriodInYears)}`;
          totalOutputYearSuffixLabel = `years + Rs. ${Math.round(productDetails?.parsedResponse?.INCOME_DISPLAY) - maturityBenefit}`;
        }
        inputAmount = inputAmount;
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = 'per annum';
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";
        
        outputAmt = Math.round(outputAmt);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = yearlyOutputAmount;
        outputStr = "per annum";
        totalOutputYear = totalOutputYear;
        totalOutputYearSuffixLabel = totalOutputYearSuffixLabel;
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM:
      case ProductConstants.GUARANTEED_SAVINGS_STAR_LUMPSUM_FIB:
      case ProductConstants.POS_GUARANTEED_SAVINGS_STAR:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
        }

        inputAmount = Math.round(yearlyInputAmount) * Number(productDetails?.bi_request?.PR_PPT);
        if (Number(productDetails?.bi_request?.MaturityBenefitOption) === 1) {
          outputAmt = Math.round(productDetails?.parsedResponse?.MB_G);
        } else {
          productDetails?.biResponseCompleteData?.forEach((item) => {
            if (Number(item.MB_G) != 0 && yearlyOutputAmount === 0) {
              yearlyOutputAmount = Math.round(item.MB_G);
            }
            outputAmt = outputAmt + Number(item.MB_G);
          });
        }
        inputAmount = inputAmount;
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";
        outputAmt = outputAmt;
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = yearlyOutputAmount;
        outputStr = "per annum";
        totalOutputYear = "for 5 years";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME:
      case ProductConstants.GUARANTEED_SAVINGS_STAR_INCOME_FIB:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(Number(productDetails?.firstYearResponse?.MODAL_PREM));
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(Number(productDetails?.firstYearResponse?.MODAL_PREM)) * 2;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(Number(productDetails?.firstYearResponse?.MODAL_PREM)) * 4;
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(Number(productDetails?.firstYearResponse?.MODAL_PREM)) * 12;
        }

        inputAmount = Math.round(yearlyInputAmount) * Number(productDetails?.bi_request?.PR_PPT);
        if (Number(productDetails?.bi_request?.MaturityBenefitOption) === 1) {
          outputAmt = Math.round(productDetails?.parsedResponse?.MB_G);
        } else {
          productDetails?.biResponseCompleteData?.forEach((item) => {
            if (Number(item.MB_G) != 0 && yearlyOutputAmount === 0) {
              yearlyOutputAmount = Math.round(item.MB_G);
            }
            outputAmt = outputAmt + Number(item.MB_G);
          });
        }
        inputAmount = inputAmount;
        yearlyInputAmount = yearlyInputAmount;
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";
        outputAmt = outputAmt;
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = yearlyOutputAmount;
        outputStr = "per annum";
        totalOutputYear = "for 5 years";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
      case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
      case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
      case ProductConstants.GUARANTEED_INCOME_STAR:
      case ProductConstants.GUARANTEED_INCOME_STAR_FIB:
      case ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:
      case ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
      case ProductConstants.POS_GUARANTEED_INCOME_STAR:
      case ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
        }
        let maturityBenefit = 0;
        let lumpsumBenefitSelected = Number(productDetails?.bi_request?.LumpsumBenefit) == 22;
        let incomeStartOption = gisUtils.fetchIncomeStartOptionValue(productDetails?.bi_request?.IncomeStartOption);
        
        // For Large Income Benefit
        outputStr = "per annum";
        if (Number(productDetails?.bi_request?.IncomeOption) === 6) {
          // Income Benefit Pay-Out (first payout)
          productDetails?.biResponseCompleteData?.forEach((item) => {
            if (Number(item.INCOME_BENEFIT_FINAL) != 0 && yearlyOutputAmount === 0) yearlyOutputAmount = Math.round(item.INCOME_BENEFIT_FINAL);
            outputAmt += Math.round(item.INCOME_BENEFIT_FINAL);
          });
          totalOutputYear = "on every 5th year";

          // Maturity Benefit
          if (lumpsumBenefitSelected) maturityBenefit = Math.round(productDetails?.parsedResponse?.MB_G);

          // Payout Period
          let payoutPeriod = productDetails?.biResponseCompleteData?.length - Number(productDetails?.bi_request?.PR_PPT);
          if (lumpsumBenefitSelected) {
            totalOutputYearSuffixLabel = `for ${payoutPeriod} years + Rs. ${maturityBenefit}`;
          } else {
            totalOutputYearSuffixLabel = `for ${payoutPeriod} years`;
          }
          if (Number(productDetails?.bi_request?.IncomeBenefitPayoutType) == 31) belowOutputYearSuffixLabel = "Increasing every time";
        }

        // For Flexible Income Benefit
        else {
          yearlyOutputAmount = productDetails?.biResponseCompleteData && Math.round(productDetails?.biResponseCompleteData[(Number(productDetails?.bi_request?.PR_PPT)) - 1].INCOME_BENEFIT_FINAL);

          outputAmt = 0
          productDetails?.biResponseCompleteData?.forEach((item) => {
            if (Number(item.INCOME_BENEFIT_FINAL) != 0) outputAmt += Math.round(item.INCOME_BENEFIT_FINAL);
          });


          // Maturity Benefit

          if (lumpsumBenefitSelected) maturityBenefit = Math.round(productDetails?.parsedResponse?.MB_G);

          outputAmt = outputAmt + Math.round(productDetails?.parsedResponse?.MB_G)

          let modalInvestmentIncomePostPPT = productDetails?.biResponseCompleteData && Math.round(productDetails?.biResponseCompleteData[Number(productDetails?.bi_request?.PR_PPT) + 1]?.INCOME_BENEFIT_FINAL);
          let payoutPeriodPostPPT = 0;
          productDetails?.biResponseCompleteData?.forEach((item, index) => {
           if(Number(productDetails?.bi_request?.PR_PPT) <= index && Math.round(item.INCOME_BENEFIT_FINAL) !== 0 ) payoutPeriodPostPPT += 1;
          });
          
          if (Number(incomeStartOption.label) > Number(productDetails?.bi_request?.PR_PPT)) {
            yearlyOutputAmount = productDetails?.biResponseCompleteData && Math.round(productDetails?.biResponseCompleteData[Number(incomeStartOption.label) - 1].INCOME_BENEFIT_FINAL);

            totalOutputYearSuffixLabel = <span>{gisUtils.fetchIncomeDuration(productDetails?.bi_request?.IncomeDuration)} years {lumpsumBenefitSelected && '+ Rs. ' + maturityBenefit}</span> 
            
            // `${gisUtils.fetchIncomeDuration(productDetails?.bi_request?.IncomeDuration)} years ${lumpsumBenefitSelected && '+ Rs. ' + maturityBenefit}}`;
          }else {
            let totalYear = 0
            productDetails?.biResponseCompleteData?.forEach((item, index) => {
              if(Number(productDetails?.bi_request?.PR_PPT) > index && Math.round(item.INCOME_BENEFIT_FINAL) !== 0 ) totalYear += 1;
            });
            if(yearlyOutputAmount < modalInvestmentIncomePostPPT ){
              yearlyOutputAmountBold = false
            }

            totalOutputYear = `${totalYear} years`;
            totalOutputYearSuffixLabel = <span>{totalOutputYear} + Rs. <ins className='font-weight-medium'>{modalInvestmentIncomePostPPT}</ins> {outputStr} for {payoutPeriodPostPPT} years {lumpsumBenefitSelected && '+ Rs. ' + maturityBenefit}</span> 

            // totalOutputYearSuffixLabel = ` ${totalOutputYear} + Rs. ${modalInvestmentIncomePostPPT} ${outputStr} for  ${payoutPeriodPostPPT} years`;
          }

          // if (lumpsumBenefitSelected) {
          //   totalOutputYearSuffixLabel = totalOutputYearSuffixLabel + ` + Rs. ${maturityBenefit}`;
          // } 
          belowOutputYearSuffixLabel = "";
        }

        inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
        yearlyInputAmount = yearlyInputAmount;
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";
        
        outputAmt = outputAmt;
        // totalOutputAmtSuffixLabel = `${totalOutputYearSuffixLabel}`;
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = yearlyOutputAmount;
        outputStr = "per annum";
        // totalOutputYear = ' for';
        totalOutputYear = totalOutputYear || ' for';
        totalOutputYearSuffixLabel = totalOutputYearSuffixLabel;
        belowOutputYearSuffixLabel = belowOutputYearSuffixLabel;
        break;

      case ProductConstants.GCAP:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
        }
        inputAmount = Math.round(yearlyInputAmount) * Number(productDetails?.bi_request?.PR_PPT);
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";
        outputAmt = Math.round(productDetails?.parsedResponse?.MATURITY);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.DHAN_LABH:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
        }
        inputAmount = Math.round(yearlyInputAmount) * Number(productDetails?.bi_request?.PR_PPT);
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";
        outputAmt = Math.round(productDetails?.parsedResponse?.TOTAL_BEN);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.SMART_LIFESTYLE:
      case ProductConstants.FAMILY_PROTECTION_OPTION_SMART_LIFESTYLE:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
        }
        inputAmount = Math.round(yearlyInputAmount) * Number(productDetails?.bi_request?.PR_PPT);
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "years";
        outputAmt = Math.round(productDetails?.parsedResponse?.SB_NG_DIS_BS_2);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.SINGLE_PAY_ENDOWMENT_ASSUARANCE_PLAN:
        inputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM);
        yearlyInputAmount = "";
        inputStr = "";
        totalInputYear = "";
        totalInputYearSuffixLabel = "";
        outputAmt = Math.round(productDetails?.parsedResponse?.SB_G);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.WEALTH_PLUS:
      case ProductConstants.RISING_STAR_WEALTH_PLUS:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.PREMIUM)
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.PREMIUM) * 2
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.PREMIUM) * 4
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.PREMIUM) * 12
        }
        inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = Number(productDetails?.bi_request?.PR_PPT);
        totalInputYearSuffixLabel = "";
        outputAmt = Math.round(productDetails?.parsedResponse?.FINAL_FV);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.WEALTH_PREMIER:
        inputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM);
        yearlyInputAmount = "";
        inputStr = "";
        totalInputYear = "";
        totalInputYearSuffixLabel = "";
        outputAmt = Math.round(productDetails?.parsedResponse?.FINAL_FV);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      case ProductConstants.WEALTH_ULTIMA:
      case ProductConstants.LITTLE_CHAMP_WEALTH_ULTIMA:
        // if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
        //   yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        // } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
        //   yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) / 2
        // } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
        //   yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) / 4
        // } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
        //   yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) / 12
        // }
        inputAmount = Math.round(Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * productDetails?.bi_request?.PR_PPT);
        yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "Years";
        outputAmt = Math.round(productDetails?.parsedResponse?.FINAL_FV);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        break;

      case ProductConstants.SYSTEMATIC_WITHDRAWAL_PLAN_WEALTH_ULTIMA:
        // if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
        //   yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        // } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
        //   yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) / 2
        // } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
        //   yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) / 4
        // } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
        //   yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) / 12
        // }
        let swpStartYear = productDetails?.bi_request?.optionValue6;
        let swpStartYearData = productDetails?.biResponseCompleteData ? productDetails?.biResponseCompleteData[swpStartYear - 1] : 0;
        let targetValue = 0;
        let totalOutPutAmt = 0;
        if ((swpStartYearData?.TOTALPW && swpStartYearData?.TOTALPW) > 0) {
          targetValue = Math.round(swpStartYearData?.TOTALPW);
        }
        productDetails?.biResponseCompleteData?.forEach((item)=> totalOutPutAmt += Math.round(item?.TOTALPW))

        totalOutPutAmt += Math.round(productDetails?.parsedResponse?.FINAL_FV)
        inputAmount = Math.round(Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * productDetails?.bi_request?.PR_PPT);
        yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "Years";
        outputAmt = Math.round(totalOutPutAmt);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = targetValue;
        outputStr = "per annum for ";
        totalOutputYear = parseInt(productDetails?.bi_request?.PR_PT) - parseInt(swpStartYear) + 1;

        totalOutputYearSuffixLabel = "Years ";
        additionalOutputAmt = ` Rs. ${Math.round(productDetails?.parsedResponse?.FINAL_FV)}`;
        outputNote = "Amount changes every year.";
        belowOutputYearSuffixLabel = "Increases every year";
        break;

      case ProductConstants.POS_SARAL_NIVESH:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
        }
        inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "Years";
        outputAmt = Math.round(productDetails?.parsedResponse?.SB_G);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        additionalOutputAmt = "";
        outputNote = "";
        break;


      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME:
      case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME:
        let fspWithoutAccrualTargetAmtPostPPT = 0
        let fspWithoutAccrualTargetAmtDuringPPT = 0
        let fspWithoutAccrualTotalTargetAmt = 0
        let incomeStartYearLessThanPPT = false
        let fspMaturityBenefit = 0
        let fspWithoutAccrualPtValue = productDetails?.bi_request?.PR_PT === '100-@LI_ENTRY_AGE' ? 100 -  Number(productDetails?.bi_request?.LI_ENTRY_AGE) : Number(productDetails?.bi_request?.PR_PT)
        let incomeStartYear = Number(productDetails?.bi_request?.IncomeStartYear)
        if (incomeStartYear === 4) incomeStartYear = 2
        if (incomeStartYear === 5) incomeStartYear = 5
        if (incomeStartYear === 6) incomeStartYear = 9
        if (incomeStartYear === 7) incomeStartYear = 11
        if (incomeStartYear === 8) incomeStartYear = 13
        if (incomeStartYear === 9) incomeStartYear = 16

        let finalpremium = 0;
        if (Number(productDetails?.bi_request?.AccrualOfSurvivalBenefits) === 12) {
          if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
          } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
          } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
          } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
          }
          
          productDetails?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(productDetails?.bi_request?.PR_PPT) === index) fspWithoutAccrualTargetAmtPostPPT = Math.round(item.TOTAL_SB_PAYOUT_BS_2);
            if(fspWithoutAccrualPtValue - 1 === index && fspMaturityBenefit === 0 ) fspMaturityBenefit = Math.round(item?.MATURITY_BENEFIT_BS_2);
          });

          let ptValue = 0;
          if(productDetails?.bi_request?.PR_PT == "100-@LI_ENTRY_AGE"){
            ptValue = 100 - Number(productDetails?.bi_request?.LI_ENTRY_AGE);
          }
          else{
            ptValue = Number(productDetails?.bi_request?.PR_PT)
          }
          let yYears = ptValue - Number(productDetails?.bi_request?.PR_PPT)

          if (incomeStartYear < Number(productDetails?.bi_request?.PR_PPT)) {
            let aYears = Number(productDetails?.bi_request?.PR_PPT) - incomeStartYear + 1
            incomeStartYearLessThanPPT = true

            productDetails?.biResponseCompleteData?.forEach((item, index) => {
              if (fspWithoutAccrualTargetAmtDuringPPT === 0 && Math.round(item.TOTAL_SB_PAYOUT_BS_2) !== 0) fspWithoutAccrualTargetAmtDuringPPT = Math.round(item.TOTAL_SB_PAYOUT_BS_2);

              fspWithoutAccrualTotalTargetAmt += Math.round(item.TOTAL_SB_PAYOUT_BS_2)
            });
            totalOutputYearSuffixLabel = <span>{aYears} years + Rs. <ins className='font-weight-medium'>{fspWithoutAccrualTargetAmtPostPPT}</ins> per annum for {yYears} years + Rs. {Math.round(fspMaturityBenefit)}</span> 
            // totalOutputYearSuffixLabel = `${aYears} years + Rs. ${fspWithoutAccrualTargetAmtPostPPT} per annum for ${yYears} years + Rs. ${Math.round(fspMaturityBenefit)}`
            yearlyOutputAmountBold = false
          } else {

            productDetails?.biResponseCompleteData?.forEach((item, index) => {
              fspWithoutAccrualTotalTargetAmt += Math.round(item.TOTAL_SB_PAYOUT_BS_2);
            });
            totalOutputYearSuffixLabel = <span>{yYears} years + Rs. {Math.round(fspMaturityBenefit)}</span> 
            // totalOutputYearSuffixLabel = `${yYears} years + Rs. ${Math.round(fspMaturityBenefit)}`
          }

          inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
          yearlyInputAmount = Math.round(yearlyInputAmount);
          inputStr = "per annum";
          totalInputYear = productDetails?.bi_request?.PR_PPT;
          totalInputYearSuffixLabel = "Years";
          outputAmt = fspWithoutAccrualTotalTargetAmt + Math.round(fspMaturityBenefit);
          totalOutputAmtSuffixLabel = "";
          yearlyOutputAmount = incomeStartYearLessThanPPT ? fspWithoutAccrualTargetAmtDuringPPT : fspWithoutAccrualTargetAmtPostPPT;
          outputStr = "per annum";
          totalOutputYear = "for";
          totalOutputYearSuffixLabel = totalOutputYearSuffixLabel;
          belowOutputYearSuffixLabel = "";
        }
        break;

      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
      case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
        let fspProWithoutAccrualTargetAmtPostPPT = 0
        let fspProWithoutAccrualTotalTargetAmt = 0
        let fspProMaturityBenefit = 0
        let fspProWithoutAccrualPtValue = productDetails?.bi_request?.PR_PT === '100-@LI_ENTRY_AGE' ? 100 -  Number(productDetails?.bi_request?.LI_ENTRY_AGE) : Number(productDetails?.bi_request?.PR_PT)
        
        if (Number(productDetails?.bi_request?.AccrualOfSurvivalBenefits) === 12) {
          if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
          } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
          } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
          } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
          }

          productDetails?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(productDetails?.bi_request?.PR_PPT) === index) fspProWithoutAccrualTargetAmtPostPPT = Math.round(item.TOTAL_SB_PAYOUT_BS_2);
            if(fspProWithoutAccrualPtValue - 1 === index && fspProMaturityBenefit === 0 ) fspProMaturityBenefit = Math.round(item?.MATURITY_BENEFIT_BS_2);
          });

          productDetails?.biResponseCompleteData?.forEach((item, index) => {
            fspProWithoutAccrualTotalTargetAmt += Math.round(item.TOTAL_SB_PAYOUT_BS_2);
          });

          inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
          yearlyInputAmount = Math.round(yearlyInputAmount);
          inputStr = "per annum";
          totalInputYear = productDetails?.bi_request?.PR_PPT;
          totalInputYearSuffixLabel = "Years";
          outputAmt = fspProWithoutAccrualTotalTargetAmt + Math.round(fspProMaturityBenefit);
          totalOutputAmtSuffixLabel = "";
          yearlyOutputAmount = fspProWithoutAccrualTargetAmtPostPPT;
          outputStr = "per annum";
          totalOutputYear = `+ Rs. ${Math.round(fspProMaturityBenefit)}`;
          totalOutputYearSuffixLabel = "+ Other Income";
          belowOutputYearSuffixLabel = "Refer CashFlow Table for detailed info.";
        }
        break;

      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_SYSTEMATIC_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_BASED_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_SYSTEMATIC_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_BASED_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_AGE_PLUS_INCOME_BASED_WITHDRAWAL:
      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_FLEXI_INCOME_PRO:
      case ProductConstants.FLEXI_SAVINGS_PLAN_FLEXI_INCOME_PRO:
        let fspProWithAccrualTargetAmtPostPPT = 0
        let fspProWithAccrualTotalTargetAmt = 0
        let fspProWithAccrualMaturityBenefit = 0
        let fspProWithAccrualPtValue = productDetails?.bi_request?.PR_PT === '100-@LI_ENTRY_AGE' ? 100 -  Number(productDetails?.bi_request?.LI_ENTRY_AGE) : Number(productDetails?.bi_request?.PR_PT)
        if (Number(productDetails?.bi_request?.AccrualOfSurvivalBenefits) === 13) {

          if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
          } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
          } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
          } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
            yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
          }

          productDetails?.biResponseCompleteData?.forEach((item, index) => {
            if (Number(productDetails?.bi_request?.PR_PPT) === index) fspProWithAccrualTargetAmtPostPPT = Math.round(item.TOTAL_SB_POST_PPT_BS_2);
            if(fspProWithAccrualPtValue - 1 === index  && fspProWithAccrualMaturityBenefit === 0 )  fspProWithAccrualMaturityBenefit = Math.round(item?.MATURITY_BENEFIT_BS_2);
          });
          productDetails?.biResponseCompleteData?.forEach((item, index) => {
            fspProWithAccrualTotalTargetAmt += Math.round(item.TOTAL_SB_PAYOUT_BS_2);
          });

          inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
          yearlyInputAmount = Math.round(yearlyInputAmount);
          inputStr = "per annum";
          totalInputYear = productDetails?.bi_request?.PR_PPT;
          totalInputYearSuffixLabel = "Years";
          outputAmt = fspProWithAccrualTotalTargetAmt + Math.round(fspProWithAccrualMaturityBenefit);
          totalOutputAmtSuffixLabel = "";
          yearlyOutputAmount = fspProWithAccrualTargetAmtPostPPT;
          outputStr = "per annum";
          totalOutputYear = `+ Rs. ${Math.round(fspProWithAccrualMaturityBenefit)}`;
          totalOutputYearSuffixLabel = "+ Other Income";
          belowOutputYearSuffixLabel = "Refer CashFlow Table for detailed info.";
        }
        break;

      case ProductConstants.FLEXI_SAVINGS_PLAN_CHILD_LARGE_SUM_OPTION:
      case ProductConstants.FLEXI_SAVINGS_PLAN_LARGE_SUM_OPTION:
        if (Number(productDetails?.bi_request?.INPUT_MODE) === 1) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM)
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 2) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 2
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 3) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 4
        } else if (Number(productDetails?.bi_request?.INPUT_MODE) === 4) {
          yearlyInputAmount = Math.round(productDetails?.firstYearResponse?.MODAL_PREM) * 12
        }
        let targetAmtFinal = 0;
        productDetails?.biResponseCompleteData?.forEach((item, index) => {
          if ((Number(productDetails?.bi_request?.PR_PT)-1) == index) {
            targetAmtFinal = Math.round(item.MATURITY_BENEFIT_BS_2);
          }
        });

        inputAmount = Math.round(yearlyInputAmount * Number(productDetails?.bi_request?.PR_PPT));
        yearlyInputAmount = Math.round(yearlyInputAmount);
        inputStr = "per annum";
        totalInputYear = productDetails?.bi_request?.PR_PPT;
        totalInputYearSuffixLabel = "Years";
        outputAmt = Math.round(targetAmtFinal);
        totalOutputAmtSuffixLabel = "";
        yearlyOutputAmount = "";
        outputStr = "";
        totalOutputYear = "";
        totalOutputYearSuffixLabel = "";
        belowOutputYearSuffixLabel = "";
        break;

      default:
        break;
    }
  }
  return {
    inputAmount,
    inputStr,
    yearlyInputAmount,
    totalInputYear,
    totalInputYearSuffixLabel,
    outputAmt,
    totalOutputAmtSuffixLabel,
    yearlyOutputAmount,
    outputStr,
    totalOutputYear,
    totalOutputYearSuffixLabel,
    belowOutputYearSuffixLabel,
    additionalOutputAmt,
    outputNote,
    yearlyOutputAmountBold
  };
}

function getFormatedData(input_mode) {
  let frequency = "";
  switch (input_mode) {
    case "1":
      frequency = "per annum";
      break;
    case "2":
      frequency = "half yearly";

      break;
    case "3":
      frequency = "per quarter";

      break;
    case "4":
      frequency = "per month";

      break;
    case "5":
      frequency = "one time";

      break;

    default:
      break;
  }

  return frequency;
}

export { calculate_input_values, fetchYouPayYouGetPrice };
