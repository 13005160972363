import React from 'react'

import './title-text-subtext-bottom.css'

function TitleTextSubtextBottom(props) {
  return (
    <div className='title-text-subtext-bottom'>
      <h2 className='font-size-18 text-primary font-weight-medium mb-0'>{props.title}</h2>
      {!props.hideSubText &&
        <span className='font-size-14 font-size-md-12 font-weight-light text-gray title-text-subtext-bottom-subtext'>{props.subText}</span>
      }
    </div>
  )
}

export default TitleTextSubtextBottom
