import OptimizationStore from '../../../store/OptimizationStore';
import * as keys from './keys'
import Validator from './validator';

class Utils {

  getCoverAmountList = () => {
    let list = []
    for (let index = 0; index <= 100; index = index + 5) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + ' L'
        })
      } else {
        list.push({
          id: index,
          label: 1 + ' Cr.'
        })
      }
    }
    return list
  }

  getCoverAmountListForCLRnHCB = () => {
    let list = []
    for (let index = 0; index <= 6; index = index + 1.5) {
      list.push({
        id: index == 0 ? 1 : index,
        label: (index == 0 ? 1 : index) + ' L'
      })
    }
    return list
  }

  getTotalPrice = (data) => {
    try {
      return Math.round(data.TOTAL_MODALPREM_DISP)
    } catch (e) {
      return 0
    }
  }


  getRiderTax = (data) => {
    let totalRider = 0;
    data?.parsedResponse?.inputValidationStatus?.map((item) => {
      totalRider += Number(item.Tax)
    })
    return totalRider;
  }

  updateBi(key, value, productId, form, productData) {

    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request

    switch (key) {
      case keys.PR_PT:
        biRequest[keys.PR_PT] = value;
        break;

      case keys.PR_PPT:
        biRequest[keys.PR_PPT] = value;
        break;

      case keys.FREQUENCY:
        let premiumAmount = 0;
        let amount = Number(value.premium);
        switch (Number(value.frequency)) {
          case 1:
            premiumAmount = '' + amount * 1
            break;
          case 2:
            premiumAmount = '' + amount * 2
            break;
          case 3:
            premiumAmount = '' + amount * 4
            break;
          case 4:
            premiumAmount = '' + amount * 12
            break;
        }
        biRequest[keys.PREMIUM] = premiumAmount
        biRequest[keys.FREQUENCY] = value.frequency
        break;

      case keys.PREMIUM:
        biRequest[keys.PREMIUM] = value;
        break;

      case keys.ADB_RIDER:
        biRequest[keys.ADB_RIDER] = value.toString();
        break;

      case keys.ATPD_RIDER:
        biRequest[keys.ATPD_RIDER] = value.toString();
        break;

      case keys.CRITICAL_ILLNESS_RIDER:
        biRequest[keys.CRITICAL_ILLNESS_RIDER] = value.toString();
        break;

      case keys.INCOME_BENEFIT_RIDER:
        biRequest[keys.INCOME_BENEFIT_RIDER] = value.toString();
        break;

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value.toString();
        break;

      case keys.PWB:
        if (value) {
          biRequest[keys.PWB_RIDER] = value.toString();
        } else {
          biRequest[keys.PWB_RIDER] = "";
        }
        biRequest[keys.PWB] = value;
        break;

      default:
        return
    }

    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value)
    // let errorMessage = Validator.valid(form, biData);

    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value);

  }

  // fetchPolicyTimeline(element, frequency) {
  //   let amount = Math.round(element.MODAL_PREM_TAX);
  //   let premiumAmount = ''
  //   switch (frequency.Value) {
  //       case '1':
  //           premiumAmount = '' + amount * 1
  //           break;
  //       case '2':
  //           premiumAmount = '' + amount * 2
  //           break;
  //       case '3':
  //           premiumAmount = '' + amount * 4
  //           break;
  //       case '4':
  //           premiumAmount = '' + amount * 12
  //           break;
  //   }
  //   let data = {
  //     youPay: Math.round(premiumAmount),
  //     youGet: Math.round(element.TOTAL_SB_BS_2) + Math.round(element.SB_G),
  //     lifeCover: Math.round(element.TOTAL_DB_BS_2),
  //     isMilestone: Math.round(element.SB_G) !== 0,
  //     premiumAmt: Math.round(element.LOAD_ANN_PREM)
  //   }
  //   return data
  // }


  newPolicyTimeLineData(element,inputString,index, finalYearIndex) {

    const {MODAL_PREM,TOTAL_SB_BS_2,DB_G_DISPLAY,SB_G, LOAD_ANN_PREM, TOTAL_DB_BS_2} = element

    // let cFTargetAmount = 0;
    // let premiumAmount = 0;
    
    // const PR_PPT = inputString.find(item => item.Key === '@PR_PPT').Value

    // if (Number(PR_PPT) + 1 >= index) cFTargetAmount = TOTAL_SB_BS_2;

    // let inputMode = parseInt(inputString.find(item => item.Key === '@INPUT_MODE').Value)
    
    // if (inputMode === 1) {
    //   premiumAmount = Math.round(MODAL_PREM) * 1;
    // } else if (inputMode === 2) {
    //   premiumAmount = Math.round(MODAL_PREM) * 2;
    // } else if (inputMode === 3) {
    //   premiumAmount = Math.round(MODAL_PREM) * 4;
    // } else if (inputMode === 4) {
    //   premiumAmount = Math.round(MODAL_PREM) * 12;
    // }


    let data = {
      youPay:Math.round(LOAD_ANN_PREM),
      youGet:Math.round(TOTAL_SB_BS_2) + (index == finalYearIndex ? Math.round(SB_G): 0),
      lifeCover:Math.round(Number(TOTAL_DB_BS_2))
    }

    return data
  }

}

export default new Utils()


// only take death benefit:
// if no death benefit, check the pdf from the response and search for death benefit.  
