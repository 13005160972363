import Utils from './utils';
import ConstUtils from '../../../../../constants/utils';
import { CovidVaccinationStatusList } from 'constants/CovidVaccinationStatusList';

const initialDefaults = (formRef, data) => {
    let requestData = data.data.bi_request
    let age = data.data.age
    let ptList = Utils.generatePT(age)
    let planOption = requestData.PlanOption
    let ppf = requestData.INPUT_MODE.toString()
    let payoutOption = requestData.DeathBenefitPayoutOption
    let payoutPeriod = requestData.PayoutPeriodMonths
    let payoutPercentageList = Utils.payoutPercentageListGenerator()
    let payoutPercentage = requestData.Lumpsum
    let pptList = Utils.generatePPT(age)
    let isSmoker = requestData.LI_SMOKE
    let maritalStatus = requestData.MaritalStatus
    let underwritingStatus = requestData.Underwriting;

    let covidVaccinationStatus = CovidVaccinationStatusList.find((item) => item.id === data.data.covid_vaccination_status)
    formRef.$('covid-vaccination-status').set('value', covidVaccinationStatus)


    // Initial value of Underwriting Status
  let underwritingStatusLabel = "";
     switch (Number(underwritingStatus)) {
        case 1:
            underwritingStatusLabel = "Medical";
        break;
        case 2:
            underwritingStatusLabel = "Non-Medical";
       break;
    default:
      break;
  }
  
  formRef.$("underwriting-status").set("value", {
    id: underwritingStatus,
    label: underwritingStatusLabel,
  });


    if (maritalStatus === "9") {
        formRef.$('marital-status').set('value', {
            id: '9', label: 'Single'
        })
    } else {
        formRef.$('marital-status').set('value', {
            id: '10', label: 'Married'
        })
    }

    let premiumAmount = ''
    switch (ppf) {
        case '1':
            premiumAmount = requestData.PR_ANNPREM;
            break;
        case '2':
            premiumAmount = Number(requestData.PR_ANNPREM) / 2;
            break;
        case '3':
            premiumAmount = Number(requestData.PR_ANNPREM) / 4;
            break;
        case '4':
            premiumAmount = Number(requestData.PR_ANNPREM) / 12;
            break;

        default:
            break;
    }

    formRef.$('premium-amt').set('value', data.data.firstYearResponse.MODAL_PREM)

    if (isSmoker === "1") {
        formRef.$('tobacco-user').set('value', {
            id: '1',
            label: 'Yes'
        })
    } else {
        formRef.$('tobacco-user').set('value', {
            id: '0',
            label: 'No'
        })
    }

    formRef.$('payout-percentage').set('extra', {
        ...formRef.$('payout-percentage').extra,
        options: payoutPercentageList
    })

    if (payoutPercentage !== '') {

        let payoutPercentageValue = 27;
        for (let index = 1; index <= 99; index++) {
            if (payoutPercentageValue.toString() === payoutPercentage.toString()) {
                formRef.$('payout-percentage').set('value', {
                    id: payoutPercentageValue,
                    label: index + ' %'
                })
            }
            payoutPercentageValue = payoutPercentageValue + 1;
        }

    } else {
        formRef.$('payout-percentage').set('value', {
            id: payoutPercentageList[0].id,
            label: payoutPercentageList[0].label
        })
    }

    formRef.$('policy-term').set('extra', {
        ...formRef.$('policy-term').extra,
        options: ptList
    })

    formRef.$('premium-term').set('extra', {
        ...formRef.$('premium-term').extra,
        options: pptList
    })

    formRef.$('policy-term').set('value', {
        id: requestData.PR_PT,
        label: requestData.PR_PT
    })

    if (requestData.PR_PPT == '60 - @Li_entry_age +1') {
        formRef.$('premium-term').set('value', {
            id: 60,
            label: 'Pay Till 60'
        })
    } else {
        formRef.$('premium-term').set('value', {
            id: requestData.PR_PPT,
            label: requestData.PR_PPT
        })
    }

    switch (planOption) {
        case '1':
            formRef.$('plan-option').set('value', {
                id: '1',
                label: 'Life Cover With Level Sum Assured'
            })
            break;
        case '2':
            formRef.$('plan-option').set('value', {
                id: '2',
                label: 'Life Cover With Decreasing Sum Assured'
            })
            break;

        default:
            break;
    }

    switch (ppf) {
        case '1':
            formRef.$('payment-frequency').set('value', {
                id: '1',
                label: 'Annual'
            })
            break;
        case '2':
            formRef.$('payment-frequency').set('value', {
                id: '2',
                label: 'Half-Yearly'
            })
            break;
        case '3':
            formRef.$('payment-frequency').set('value', {
                id: '3',
                label: 'Quarterly'
            })
            break;
        case '4':
            formRef.$('payment-frequency').set('value', {
                id: '4',
                label: 'Monthly'
            })
            break;

        default:
            break;
    }

    switch (payoutOption) {
        case '16':
            formRef.$('payout-option').set('value', {
                id: '16',
                label: 'Lumpsum'
            })
            break;
        case '17':
            formRef.$('payout-option').set('value', {
                id: '17',
                label: 'Monthly income - Level'
            })

            formRef.$("payout-period").set("extra", {
                ...formRef.$("payout-period").extra,
                hide: false
            })
            break;
        case '18':
            formRef.$('payout-option').set('value', {
                id: '18',
                label: 'Monthly income – Increasing'
            })

            formRef.$("payout-period").set("extra", {
                ...formRef.$("payout-period").extra,
                hide: false
            })
            break;
        case '19':
            formRef.$('payout-option').set('value', {
                id: '19',
                label: 'Lumpsum Plus Monthly Income – Level'
            })
            formRef.$("payout-period").set("extra", {
                ...formRef.$("payout-period").extra,
                hide: false
            })
            formRef.$("payout-percentage").set("extra", {
                ...formRef.$("payout-percentage").extra,
                hide: false
            })
            break;
        case '20':
            formRef.$('payout-option').set('value', {
                id: '20',
                label: 'Lumpsum Plus Monthly Income - increasing'
            })
            formRef.$("payout-period").set("extra", {
                ...formRef.$("payout-period").extra,
                hide: false
            })
            formRef.$("payout-percentage").set("extra", {
                ...formRef.$("payout-percentage").extra,
                hide: false
            })
            break;

        default:
            break;
    }

    switch (payoutPeriod) {
        case '21':
            formRef.$('payout-period').set('value', {
                id: '21',
                label: '36'
            })
            break;
        case '22':
            formRef.$('payout-period').set('value', {
                id: '22',
                label: '60'
            })
            break;
        case '23':
            formRef.$('payout-period').set('value', {
                id: '23',
                label: '120'
            })
            break;


        default:
            break;
    }

    // Addition Benefits
    if (requestData.BetterHalfBenefit) {
        formRef.$('better-half-benefit').set('value', requestData.BetterHalfBenefit == '12')
        if (data.data.firstYearResponse.BH_Modal_Prem) {
            formRef.$('better-half-benefit').set('extra', {
                ...formRef.$('better-half-benefit').extra,
                sum_assured: (data.data.firstYearResponse.BH_Modal_Prem || '').toString()
            }
            )
            formRef.$('better-half-benefit').set('value', true)
        }
    }


    formRef.$('top-up-benefit').set('value', requestData.AdditionalBenefit == '6')

    if (formRef.$('top-up-benefit').value) {
        switch (requestData.TopUpBenefit) {
            case '14':
                formRef.$('top-up-benefit-dropdown').set('value', {
                    label: '5%',
                    id: '14'
                })
                break;

            case '15':
                formRef.$('top-up-benefit-dropdown').set('value', {
                    label: '10%',
                    id: '15'
                })
                break;
            default:
                break;
        }
    }

    if (requestData.AdditionalBenefit) {
        formRef.$('WaiverOfPremiumRider').set('value', requestData.AdditionalBenefit.toString() == '8')
    }

    if (data.data.firstYearResponse.WOP_Modal_Prem) {
        formRef.$('WaiverOfPremiumRider').set('extra', {
            ...formRef.$('WaiverOfPremiumRider').extra,
            sum_assured: (data.data.firstYearResponse.WOP_Modal_Prem || '').toString()
        })
        formRef.$('WaiverOfPremiumRider').set('value', true)
    }

    formRef.$('telemedical-option').set('value', requestData?.Underwriting == '2')

    // Additional Rider
    if (requestData.ADBRider) {
        formRef.$('ADB').set('value', requestData.ADBRider !== '')
        if (requestData.ADBRider) {
            formRef.$('ADB-dropdown').set('value', requestData.ADBRider)
        }
    }
    if (requestData.ATPDRider) {
        formRef.$('ATPDRider').set('value', requestData.ATPDRider !== '')
        if (requestData.ATPDRider) {
            formRef.$('ATPDRider-dropdown').set('value', requestData.ATPDRider)
        }
    }
    if (requestData.CriticalIllnessRider) {
        formRef.$('CriticalIllnessRider').set('value', requestData.CriticalIllnessRider !== '')
        if (requestData.CriticalIllnessRider) {
            formRef.$('CriticalIllnessRider-dropdown').set('value', requestData.CriticalIllnessRider)
        }
    }
    if (requestData.HospitalityCashBenefitRider) {
        formRef.$('HospitalityCashBenefitRider').set('value', requestData.HospitalityCashBenefitRider !== '')
        if (requestData.HospitalityCashBenefitRider) {
            formRef.$('HospitalityCashBenefitRider-dropdown').set('value', requestData.HospitalityCashBenefitRider)
        }
    }

    formRef.$('income').set('value', requestData.PR_SA)
    formRef.$('annual-income').set('value', requestData.ZP_ANNINCOME)
    formRef.$('spouse-name').set('value', requestData.SpouseName)

    switch (requestData.SpouseGender) {
        case '135':
            formRef.$('spouse-gender').set('value', {
                id: '135',
                label: 'Male',
                value: 'M'
            })
            break;
        case '136':
            formRef.$('spouse-gender').set('value', {
                id: '136',
                label: 'Female',
                value: 'F'
            })
            break;

        default:
            break;
    }

    if (requestData.OSNSpouseDateOfBirth) {
        formRef.$('spouse-dob').set('value', requestData.DisplaySpouseDOB)
    }

    // if (requestData.ZP_NATIONALITY) {
    //     switch (requestData.ZP_NATIONALITY.toString()) {
    //         case '1':
    //             formRef.$('nationality').set('value', { id: 1, label: 'Indian' })
    //             break;

    //         case '2':
    //             formRef.$('nationality').set('value', { id: 2, label: 'NRI' })
    //             break;

    //         case '3':
    //             formRef.$('nationality').set('value', { id: 3, label: 'FNIO/PIO' })
    //             break;

    //         case '4':
    //             formRef.$('nationality').set('value', { id: 4, label: 'other' })
    //             break;

    //         default:
    //             break;
    //     }

    // }
    // if (requestData.ZP_OCCUPATION) {
    //     switch (requestData.ZP_OCCUPATION) {
    //         case 'SA':
    //             formRef.$('occupation').set('value', { id: 'SA', label: 'Salaried' })
    //             break;

    //         case 'SM':
    //             formRef.$('occupation').set('value', { id: 'SM', label: 'Self Employed (Professional)' })
    //             break;

    //         case 'BM':
    //             formRef.$('occupation').set('value', { id: 'BM', label: 'Self Employed (Business)' })
    //             break;

    //         case 'A':
    //             formRef.$('occupation').set('value', { id: 'A', label: 'Agriculture' })
    //             break;

    //         case 'H':
    //             formRef.$('occupation').set('value', { id: 'H', label: 'HouseWife' })
    //             break;

    //         case 'R':
    //             formRef.$('occupation').set('value', { id: 'R', label: 'Retired' })
    //             break;

    //         case 'R':
    //             formRef.$('occupation').set('value', { id: 'L', label: 'Labourer/Worker' })
    //             break;

    //         default:
    //             break;
    //     }
    // }
    // if (requestData.ZP_EDUCATION) {
    //     switch (requestData.ZP_EDUCATION.toString()) {
    //         case '5':
    //             formRef.$('education').set('value', { id: 5, label: 'Post Graduate' })
    //             break;

    //         case '4':
    //             formRef.$('education').set('value', { id: 4, label: 'Graduate' })
    //             break;


    //         case '3':
    //             formRef.$('education').set('value', { id: 3, label: '12th' })
    //             break;

    //         case '2':
    //             formRef.$('education').set('value', { id: 2, label: '10th' })
    //             break;

    //         case '1':
    //             formRef.$('education').set('value', { id: 1, label: 'Below 10th' })
    //             break;


    //         case '6':
    //             formRef.$('education').set('value', { id: 6, label: 'Professional' })
    //             break;

    //         case '8':
    //             formRef.$('education').set('value', { id: 8, label: 'Student' })
    //             break;

    //         case '7':
    //             formRef.$('education').set('value', { id: 7, label: 'Others' })
    //             break;
    //         default:
    //             break;
    //     }

    // }

}

export default initialDefaults