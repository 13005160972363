// package import
const _ = require('lodash');

// Project Imports
import checkIsValueEmpty from "utils/checkIsValueEmpty"
import FamilyMemberTypeEnum from "../FamilymemberTypeEnum";
import FamilyDetailsStore from "../store/FamilyDetailsStore";

export function validateSelfDetails({ field }) {

    const fieldNameArray = ['first_name','last_name','dob','gender']

    let isSelected = field.container().extra.isSelected
    let fieldName = field.name
    let isValid = true
    let currentFieldValue = field.value
    let parentName = field.container().name;

    if((field.name=="monthly_income_dropdown_option" && field.type === 'hidden') || 
       (field.name=="monthly_income_value" && field.type === 'hidden')){
        return [true, ""]
    }

    if (parentName === FamilyMemberTypeEnum.SELF.toLowerCase()) {

        if(field.name=="monthly_income_dropdown_option" && currentFieldValue?.id === 1){
             isValid = true    
        }else{
            isValid = !checkIsValueEmpty(currentFieldValue)
        }
    }

    if(isSelected && fieldNameArray.includes(fieldName)){
        isValid = !checkIsValueEmpty(currentFieldValue)
    }

    return [isValid, "This field is mandatory"]

}

export function monthlyExpenseValidation({ field, form }) {

    let monthyExpenseForm = form.$("monthly_expense")

    const monthlyExpenseField = monthyExpenseForm.get()
    
      const expenseArray = Object.keys(monthlyExpenseField)
      const isSelectedArray = Object.values(monthlyExpenseField).map(singleObj => singleObj.disabled)

      const containsFalse = (item) => item === false;

      if(isSelectedArray.some(containsFalse)){
        
        isSelectedArray.forEach((item,index) => {
          
        const fieldValue = expenseArray[index]
          if(!item){
            form.$("monthly_expense").$(fieldValue).invalidate("This field is mandatory");
          }
        })

        const errorObj = form.errors();
        let monthlyExpenseErrorObj = errorObj["monthly_expense"]

        const monthlyErrorList = []
        let monthlyExpenseValueList = {}

        for(let key in monthlyExpenseErrorObj){
          if(monthlyExpenseErrorObj[key]){
            monthlyErrorList.push(key)
          }
        }

        for(let key in monthlyExpenseField){
           let fieldObj = {}
          fieldObj[key] = monthlyExpenseField[key].value
          monthlyExpenseValueList = {...monthlyExpenseValueList,...fieldObj}
        }
      
        FamilyDetailsStore.updateMonthlyExpenseErrorList(monthlyErrorList)
        FamilyDetailsStore.updateMonthExpenseValueList(monthlyExpenseValueList)

      }

}

export function savingsAndInvestmentDetailValidation({ field, form }) {

  let savingsAndInvestmentForm = form.$("savings")

  const savingsAndInvestmentFields = savingsAndInvestmentForm.get()

  const savingsArray = Object.keys(savingsAndInvestmentFields)

  const isSelectedArray = Object.values(savingsAndInvestmentFields).map(singleObj => singleObj.disabled)

  const containsFalse = (item) => item === false;

  if(isSelectedArray.some(containsFalse)){
        
    isSelectedArray.forEach((item,index) => {
      
    const fieldValue = savingsArray[index]
      if(!item){
        form.$("savings").$(fieldValue).invalidate("This field is mandatory");
      }
    })

    const errorObj = form.errors();
    let savingsErrorObj = errorObj["savings"]

    const savingsErrorList = []
    let savingsValueList = {}

    for(let key in savingsErrorObj){
      if(savingsErrorObj[key]){
        savingsErrorList.push(key)
      }
    }

    for(let key in savingsAndInvestmentFields){
      let fieldObj = {}
     fieldObj[key] = savingsAndInvestmentFields[key].value
     savingsValueList = {...savingsValueList,...fieldObj}
   }

    FamilyDetailsStore.updateSavingsErrorList(savingsErrorList)
    FamilyDetailsStore.updateSavingsValueList(savingsValueList)
  }
} 


export function monthlyemiValidation({field, form}){

  let monthyEmiForm = form.$("monthly_emi")
  const monthyEmiFields = monthyEmiForm.get()

  const emiArray = Object.keys(monthyEmiFields)
  const isSelectedArray = Object.values(monthyEmiFields).map(singleObj => singleObj.disabled)

  const emiFields = {}

    for(let key in monthyEmiFields){
      const fields = monthyEmiFields[key].fields
      emiFields[key] = fields 
    }

  const containsFalse = (item) => item === false;
  const isTrue = (item) => item === true;

  if(isSelectedArray.some(containsFalse)){
        
    isSelectedArray.forEach((item,index) => {
      
    const fieldValue = emiArray[index]
      if(!item){

        const field = form.$("monthly_emi").$(fieldValue)

        let optionRange = field.$("option_range").value
        let paidYears = field.$("paid_years").value
        let totalYears = field.$("total_years").value

        if(checkIsValueEmpty(optionRange)) field.$("option_range").invalidate("This field is mandatory")

        if(checkIsValueEmpty(paidYears)) field.$("paid_years").invalidate("This field is mandatory")

        if(checkIsValueEmpty(totalYears)) field.$("total_years").invalidate("This field is mandatory")
      }
    })

    

    const errorObj = form.errors();
    let emiErrorObj = errorObj["monthly_emi"]

    const emiErrorList = []
    let emiValueList = {}

    for(let key in emiErrorObj){
      if((emiErrorObj[key].option_range != null && emiErrorObj[key].option_range.length > 1) ||
        (emiErrorObj[key].paid_years != null && emiErrorObj[key].paid_years.length > 1) ||
        (emiErrorObj[key].total_years != null && emiErrorObj[key].total_years.length > 1)
      ){
        emiErrorList.push(key)
      }
    }

    for(let key in emiFields) {

      if(emiErrorList.includes(key)){
        emiValueList[key] = emiFields[key]
      }
    }

    FamilyDetailsStore.updateMonthlyEmiErrorList(emiErrorList)
    FamilyDetailsStore.updateMonthlyEmiValueList(emiValueList)
  }

  if(isSelectedArray.every(isTrue)){

    const emiErrorList = []
    FamilyDetailsStore.updateMonthlyEmiErrorList(emiErrorList)
  }

}