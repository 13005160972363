import genderTypeEnum from "constants/genderTypeEnum"

export const toggleDisabledState = (formRef, fieldName, value) => {
 formRef.$("members").$(fieldName).$("isSelected").value = value
 const listOfValues = formRef.get().members.value
 let valueKeys = []
 for (const key in listOfValues) {
   valueKeys.push(key)
 }

 valueKeys.forEach((item) => {
   if (item !== fieldName) {
     formRef.$("members").$(item).$("isSelected").value = false
   }
 })
}


export const genderFunction = (gender) => {
  switch (gender) {
    case genderTypeEnum.MALE:
      return "M"
      break

    case genderTypeEnum.FEMALE:
      return "F"
      break

    default:
      return "O"
      break
  }
}

export const addIdToOptimisationStore = (formController,onHide) => {

  formController.validate({ showErrors: true })
    .then(({ isValid }) => {
      if (isValid) {

        const formFieldValuesArray = formController.get().members.value
         for(const key in formFieldValuesArray){
           if(formFieldValuesArray[key].isSelected === true){
            const id = formFieldValuesArray[key].id
            OptimizationStore.setSelectedMemberId(id)
           }
         }
         onHide()
      }
    });
}


export const showRelationWhenNoName = (salutation,fName,mName,lName,relation) => {

  let fullName =  `${fName}${mName}${lName}`
  
    if(fullName.length == 0){
      return <span className="font-weight-bold">
              [{relation}]
              </span>
    } else{
      return <span className="ml-2 font-weight-light">
              {`${salutation} ${fName} ${mName} ${lName} [${relation}]`}
            </span>
    }    
}