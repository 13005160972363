// package Imports
import { toJS } from "mobx"
import AuthStore from "modules/login/store/AuthStore"
import OptimizationStore from "modules/optimization/store/OptimizationStore"

const needSelectedDeSelectedFunction = (formRef) => {

  const fieldData = formRef.get()
  const isSelectedProxyToJS = fieldData.initial_selected.value.map((singleSelected) => toJS(singleSelected))

  // ! list of needs from the productList
  const allNeeds = toJS(OptimizationStore.product_list_data) 

  // ! all Need list
  let needMasterList  = AuthStore.needMasterList.map((singleNeed)=> ({"name":singleNeed.unique_name,"id":singleNeed.id}))

  // ! getting the unique name from the allNeeds
  let needNameFromProductList = allNeeds.map((singleNeed)=>singleNeed.need_unique_name)

  //! filtering out id 
  let needIdListFromProductList = needMasterList.map((singleNeed)=>{
    if(needNameFromProductList.includes(singleNeed.name)){
      return singleNeed.id
    }else{
      return null
    }
  }).filter((item) => item !== null)

  formRef.$("final_selected").value = ''

  let checkBoxList = { ...fieldData }
  delete checkBoxList.journey_id
  delete checkBoxList.member_id
  delete checkBoxList.final_selected
  delete checkBoxList.enable_proceed
  delete checkBoxList.initial_selected

  let finalSelectedArray = []

  for (const key in checkBoxList) {
    if (checkBoxList[key].disabled === false) {
      finalSelectedArray.push(checkBoxList[key].value.id)
    }
  }

  if(isSelectedProxyToJS.length === 0 && finalSelectedArray.length === 0) return
  const initialSelectedArray = isSelectedProxyToJS.map((item) => item.need)
  /* initializing  Flags */
  let isAdded = false
  let isRemoved = false

  /* perFormAddOperation */
   let performAddOperation = false;
   let performDeleteOperation = false

  /* checking difference between finally selected and initially selected  */
  let differenceSelected = finalSelectedArray.filter(
    (x) => initialSelectedArray.indexOf(x) === -1
  )


  const previouslySelected = differenceSelected.filter((item)=> needIdListFromProductList.includes(item))


  if(previouslySelected.length > 0){
    isAdded = true
  }
  
  const newlySelectedNeed = differenceSelected.filter((item)=> !previouslySelected.includes(item))

  if (newlySelectedNeed.length == 1) {
    /* successfully added */
    isAdded = true
    formRef.$("final_selected").value = newlySelectedNeed[0]
  }

  if(newlySelectedNeed.length >= 2){
    isAdded = false
  }

  
  console.log('differenceSelected',previouslySelected,newlySelectedNeed);


  /*  checking difference between initial and final deSelected */
  let differenceDeSelected = initialSelectedArray.filter(
    (x) => finalSelectedArray.indexOf(x) === -1
  )

  if (differenceDeSelected.length > 0) {
    /* successfully removed */
    isRemoved = true
  }

  if(isAdded){
    performAddOperation = true
  }
  
  if (isAdded && !isRemoved) {
      /* perform add operation */
    formRef.$("enable_proceed").set("value", true)
    performAddOperation = true
  }

  if(isRemoved && differenceSelected.length <= 1){
    formRef.$("enable_proceed").set("value", true)
    performDeleteOperation = true
  }

  if(performAddOperation || performDeleteOperation){
    formRef.$("enable_proceed").set("value", true)
  }else{
    formRef.$("enable_proceed").set("value", false)
  }

  return { performAddOperation,performDeleteOperation, differenceDeSelected,previouslySelected }
}

export default needSelectedDeSelectedFunction
