import React, { useEffect, useState } from "react";

import { Col, Row } from "react-bootstrap";

import { observer } from "mobx-react";
import moment from "moment";

import { FormControl } from "react-bootstrap";

import { BottomsheetDropdown, DatepickerModal, ChangeProductSection } from "components";
import AmountCalculation from "../../optimization-card/amount-calculation/AmountCalculation";
import DeathCoverGraphSlider from "../../death-cover-graph-slider/DeathCoverGraphSlider";
import Utils from "./utils";
import fetchIncomeLabelBasedOnNeed from "utils/fetchIncomeLabelBasedOnNeed";

const ImmediateAnnuityPlan = ({ form, data }) => {
  const [showOverlay, setShowOverlay] = useState(false)
  let productData = data?.data?.BI.find((item) => item.risk === data?.data?.risk);
  let biResponse = productData.parsedResponse;
  let firstYearResponse = productData.firstYearResponse;
  let coverLabelBaseOnNeed = fetchIncomeLabelBasedOnNeed(data.cardKey)

  useEffect(() => {
    setShowOverlay(productData.fieldChanged)
  }, [productData.fieldChanged])

  const onHideOverlay = () => {
    setShowOverlay(false)
  }
  return (
    <div className="save-my-children">
      <Row className="align-items-center mt-md-4">
        <Col xs={12} md={6} className="col-12">
          <ChangeProductSection 
            productName={productData.product_name} 
            cardName={coverLabelBaseOnNeed}
            changeProductList={data?.data?.changeProductList}
            productData={data}
          />
        </Col>
      </Row>
      <DeathCoverGraphSlider
        productTitle={productData.product_name}
        openChangeReasonModal={false}
        onIncomeChange={() => { }}
        onPremiumChange={form.$("premium").onChange}
        hideOverLay={onHideOverlay}
        productID={productData.product_id}
        productData={data?.data}
        showOverlay={showOverlay}
        cardKey={data.cardKey}
        income_value=""
        premium_value={form.$("premium").value}
        onClick={() =>
          form.$("premium").extra.calculatePremiun(form, productData.product_id)
        }
        data=""
        isIncomeFieldDisable={form.$("premium").disabled}
        premiumFreq={form.$("payment-frequency").value.label}
        formRef={form}
      />
      <Row className="mt-2 mt-md-4">
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("premium-term").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("premium-term").label}
              menuList={form.$("premium-term").extra.optionList}
              value={form.$("premium-term").value}
              onSelect={form.$("premium-term").onChange}
            />
          </div>
        </Col>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("payment-frequency").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("payment-frequency").label}
              menuList={form.$("payment-frequency").extra.optionList}
              value={form.$("payment-frequency").value}
              onSelect={form.$("payment-frequency").onChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("annuity-option").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("annuity-option").label}
              menuList={form.$("annuity-option").extra.optionList}
              value={form.$("annuity-option").value}
              onSelect={form.$("annuity-option").onChange}
            />
          </div>
        </Col>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("source-of-purchase-price").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("source-of-purchase-price").label}
              menuList={form.$("source-of-purchase-price").extra.optionList}
              value={form.$("source-of-purchase-price").value}
              onSelect={form.$("source-of-purchase-price").onChange}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("frequency-of-annuity").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("frequency-of-annuity").label}
              menuList={form.$("frequency-of-annuity").extra.optionList}
              value={form.$("frequency-of-annuity").value}
              onSelect={form.$("frequency-of-annuity").onChange}
            />
          </div>
        </Col>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("are-you-nominee").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("are-you-nominee").label}
              menuList={form.$("are-you-nominee").extra.optionList}
              value={form.$("are-you-nominee").value}
              onSelect={form.$("are-you-nominee").onChange}
              disabled={form.$("source-of-purchase-price").value.id !== "15"}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("name-of-the-secondary-annuitant").label}
            </div>
            <FormControl
              className="font-size-12 font-weight-light font-size-md-14 font-weight-bold text-description-text-color"
              required
              disabled={form.$('annuity-option').value.id < 9}
              onChange={form.$("name-of-the-secondary-annuitant").onChange}
              value={form.$("name-of-the-secondary-annuitant").value}
              onBlur={() => form.$("name-of-the-secondary-annuitant").extra.onBlur(form, data)}
            />
          </div>
        </Col>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("gender-secondary-annuitant").label}
            </div>
            <BottomsheetDropdown
              dropdownStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemFontStyle='font-size-lg-12 font-size-14 font-weight-light'
              menuItemStyle='d-flex justify-content-between align-items-center'
              selectedLabelKey="label"
              bottomsheetTitle={form.$("gender-secondary-annuitant").label}
              menuList={form.$("gender-secondary-annuitant").extra.optionList}
              value={form.$("gender-secondary-annuitant").value}
              onSelect={form.$("gender-secondary-annuitant").onChange}
              disabled={form.$('annuity-option').value.id < 9}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="col-12">
          <div className="shadow p-3 bg-white rounded mt-4 mb-3">
            <div className="mb-2 text-description-text-color font-size-12 font-weight-light">
              {form.$("dob-of-secondary-annuitant").label}
            </div>
            <DatepickerModal
              textStyle="font-size-12 font-weight-light font-size-md-20 font-weight-normal text-nowrap overflow-hidden txt-overflow"
              onChange={(value) =>
                form
                  .$("dob-of-secondary-annuitant")
                  .extra.setDOBValue(form, value, data)
              }
              disabled={form.$('annuity-option').value.id < 9}
              date={form.$("dob-of-secondary-annuitant").value}
              minDate={moment().subtract(18, 'years').toString()}
            />
          </div>
        </Col>
      </Row>
      <AmountCalculation
        cardKey={data.cardKey}
        item={productData}
        totalPremium={firstYearResponse?.MODAL_PREM_TAX || 0}
        gst={firstYearResponse?.TAX_MP || 0}
        gst_percentage={(firstYearResponse?.TAX_RATE * 100).toFixed(2) || 0}
        total_price={Utils.getTotalPrice(firstYearResponse)}
      />
    </div>
  );
};

export default observer(ImmediateAnnuityPlan);
