import OptimizationStore from "../../../store/OptimizationStore";
import * as keys from "./keys";
import Validator from "./validator";
import calculateRiderPremium from "utils/calculateRiderPremium";
import calculateProductPremiumRelatedValues from "utils/calculateProductPremiumRelatedValues";
import ProductPackageId from "constants/ProductPackageId";
import ProductConstants from "constants/ProductConstants";
import * as Constants from "constants/Constants";

class Utils {
  getPremiumTermList = (pt, age) => {
    let premiumTermList = [
      {
        id: 5,
        label: "5",
      },
      {
        id: 8,
        label: "8",
      },
      {
        id: 10,
        label: "10",
      },
      {
        id: 12,
        label: "12",
      },
    ];
    return premiumTermList;
  };

  ptLogicBasedOnPpt = (pptValue, formRef, productName) => {
    if (productName === ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM || productName === ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB) {
      switch (pptValue) {
        case "5":
        case "8":
          formRef.$("PT").set("extra", {
            ...formRef.$("PT").extra,
            optionList: [
              {
                id: "10",
                label: "10",
              },
              {
                id: "15",
                label: "15",
              },
              {
                id: "20",
                label: "20",
              },
            ],
          });
          break;

        case "10":
          formRef.$("PT").set("extra", {
            ...formRef.$("PT").extra,
            optionList: [
              {
                id: "12",
                label: "12",
              },
              {
                id: "15",
                label: "15",
              },
              {
                id: "20",
                label: "20",
              },
            ],
          });
          break;

        case "12":
          formRef.$("PT").set("extra", {
            ...formRef.$("PT").extra,
            optionList: [
              {
                id: "15",
                label: "15",
              },
              {
                id: "20",
                label: "20",
              },
            ],
          });
          break;

        default:
          break;
      }
    }

    if (productName === ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME || productName === ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB) {
      switch (pptValue) {
        case "8":
          formRef.$("PT").set("value", {
            id: "18",
            label: "18",
          });

          formRef.$("income-benefit-payout-duration").set("value", {
            id: "10",
            label: "8",
          });
          break;

        case "10":
          formRef.$("PT").set("value", {
            id: "22",
            label: "22",
          });

          formRef.$("income-benefit-payout-duration").set("value", {
            id: "11",
            label: "10",
          });
          break;

        case "12":
          formRef.$("PT").set("value", {
            id: "26",
            label: "26",
          });

          formRef.$("income-benefit-payout-duration").set("value", {
            id: '12',
            label: '12',
          });
          break;

        default:
          break;
      }
    }
  };

  incomeBenefitDurationLogicBasedOnPpt = (pptValue, formRef, productName) => {
    if (productName === ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME || productName === ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB) {
      switch (pptValue) {
        case "5":
        case "8":
        case "10":
          formRef.$("income-benefit-payout-duration").set("extra", {
            ...formRef.$("income-benefit-payout-duration").extra,
            optionList: [
              {
                id: "13",
                label: "15",
              },
              {
                id: "14",
                label: "20",
              },
              {
                id: "15",
                label: "25",
              },
              {
                id: "16",
                label: "30",
              },
            ],
          });

          // formRef.$("income-benefit-payout-duration").set("value", {
          //   id: "13",
          //   label: "15",
          // });

          break;

        case "12":
          formRef.$("income-benefit-payout-duration").set("extra", {
            ...formRef.$("income-benefit-payout-duration").extra,
            optionList: [
              {
                id: "13",
                label: "15",
              },
              {
                id: "14",
                label: "20",
              },
              {
                id: "15",
                label: "25",
              },
            ],
          });

          const incomeBenefitDurationValue = formRef.$("income-benefit-payout-duration").value

          if(incomeBenefitDurationValue.id === '16'){
            formRef.$("income-benefit-payout-duration").set("value", {
              id: "15",
              label: "25",
            });
          }

          break;

        default:
          break;
      }
    }
  };

  prefillPtBasedOnpptAndIncomeBenefitPayoutDuration = (formRef, productName) => {
    if (productName === ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME || productName === ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB) {
      const incomeBenefitPayoutDurationValue = formRef.$("income-benefit-payout-duration").value.label;
      const pptValue = formRef.$("PPT").value.id;

      if (pptValue === "5") {
        switch (incomeBenefitPayoutDurationValue) {
          case "15":
            formRef.$("PT").set("value", {
              id: "22",
              label: "22",
            });
            break;

          case "20":
            formRef.$("PT").set("value", {
              id: "27",
              label: "27",
            });
            break;

          case "25":
            formRef.$("PT").set("value", {
              id: "32",
              label: "32",
            });
            break;

          case "30":
            formRef.$("PT").set("value", {
              id: "37",
              label: "37",
            });
            break;

          default:
            break;
        }
      }

      if (pptValue === "8") {
        switch (incomeBenefitPayoutDurationValue) {
          case "15":
            formRef.$("PT").set("value", {
              id: "25",
              label: "25",
            });
            break;

          case "20":
            formRef.$("PT").set("value", {
              id: "30",
              label: "30",
            });
            break;

          case "25":
            formRef.$("PT").set("value", {
              id: "35",
              label: "35",
            });
            break;

          case "30":
            formRef.$("PT").set("value", {
              id: "40",
              label: "40",
            });
            break;

          default:
            break;
        }
      }

      if (pptValue === "10") {
        switch (incomeBenefitPayoutDurationValue) {
          case "15":
            formRef.$("PT").set("value", {
              id: "27",
              label: "27",
            });
            break;

          case "20":
            formRef.$("PT").set("value", {
              id: "32",
              label: "32",
            });
            break;

          case "25":
            formRef.$("PT").set("value", {
              id: "37",
              label: "37",
            });
            break;

          case "30":
            formRef.$("PT").set("value", {
              id: "42",
              label: "42",
            });
            break;

          default:
            break;
        }
      }

      if (pptValue === "12") {
        switch (incomeBenefitPayoutDurationValue) {
          case "15":
            formRef.$("PT").set("value", {
              id: "29",
              label: "29",
            });
            break;

          case "20":
            formRef.$("PT").set("value", {
              id: "34",
              label: "34",
            });
            break;

          case "25":
            formRef.$("PT").set("value", {
              id: "39",
              label: "39",
            });
            break;

          default:
            break;
        }
      }
    }
  };

  getIncomeBenefitOptions = (productName, ppt) => {
    let payoutList = [];
    switch (productName) {
      case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
      case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
        payoutList = [
          { id: "10", label: "8" },
          { id: "11", label: "10" },
          { id: "12", label: "12" },
          { id: "13", label: "15" },
          { id: "14", label: "20" },
          { id: "15", label: "25" },
          { id: "16", label: "30" },
        ];
        break;

      case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
        payoutList.push({ id: "13", label: "15" });
        payoutList.push({ id: "14", label: "20" });
        payoutList.push({ id: "15", label: "25" });

        if (ppt === 5 && ppt === 8 && ppt === 10) {
          payoutList.push({ id: "16", label: "30" });
        }

        break;

      case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM:
      case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
        break;

      case ProductConstants.PREMIER_GUARANTEED_RETIREMENT_INCOME:
        payoutList.push({ id: "14", label: "20" });
        break;

      default:
        break;
    }
    return payoutList;
  };

  getTotalPrice = (productData) => {
    let price = 0;
    try {
      switch (productData.package_id) {
        case ProductPackageId.PREMIER_GUARANTEED_INCOME_LUMPSUM:
        case ProductPackageId.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
          return Math.round(biResponse?.MATURITY_BEN);

        case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
        case ProductPackageId.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
        case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME:
        case ProductPackageId.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
        case ProductPackageId.PREMIER_GUARANTEED_RETIREMENT_INCOME:
          productData.biResponseCompleteData.forEach((item) => {
            if (Number(item.INCOME_BEN_PAY_OUT) != 0 && price === 0) price = item.INCOME_BEN_PAY_OUT;
          });
          return price;

          return 0;

        default:
          return 0;
      }
    } catch (e) {
      return 0;
    }
  };

  getRiderTax = (data) => {
    let totalRider = 0;
    data?.parsedResponse?.inputValidationStatus?.map((item) => {
      totalRider += Number(item.Tax);
    });
    return totalRider;
  };

  updateBi = (key, value, productId, formRef, productData) => {
    let selectedRiskType = productData?.data?.selectedRisk;
    let selectedRiskProductObject = productData?.data?.BI.filter((item) => item.risk == selectedRiskType)[0];
    let biData = OptimizationStore.fetchBiRequest(productId, selectedRiskProductObject.family_product_id);
    let biRequest = biData.data.bi_request;

    switch (key) {
      case keys.PPT:
        biRequest[keys.PPT] = value;
        break;

      case keys.PT:
        biRequest[keys.PT] = value;
        break;

      case keys.LUMPSUM_BENEFIT:
        biRequest[keys.LUMPSUM_BENEFIT] = value;
        break;

      case keys.FREQUENCY:
        // let premiumAmount = 0;
        // let amount = value.premium;
        // switch (Number(value.frequency)) {
        //   case 1:
        //     premiumAmount = "" + amount * 1;
        //     break;
        //   case 2:
        //     premiumAmount = "" + amount * 2;
        //     break;
        //   case 3:
        //     premiumAmount = "" + amount * 4;
        //     break;
        //   case 4:
        //     premiumAmount = "" + amount * 12;
        //     break;
        // }

        // biRequest[keys.PREMIUM] = premiumAmount;
        biRequest[keys.FREQUENCY] = value.frequency;
        break;

      // case keys.PREMIUM:
      //   biRequest[keys.PREMIUM] = value;
      //   break;

      case keys.WOP_RIDER:
        biRequest[keys.WOP_RIDER] = value;
        break;

      case keys.ADB_RIDER:
        if (value) {
          biRequest[keys.ADB_RIDER] = value;
        } else {
          biRequest[keys.ADB_RIDER] = "";
        }
        break;

      case keys.ATPD_RIDER:
        if (value) {
          biRequest[keys.ATPD_RIDER] = value;
        } else {
          biRequest[keys.ATPD_RIDER] = "";
        }
        break;

      case keys.Critical_ILLNESS_RIDER:
        if (value) {
          biRequest[keys.Critical_ILLNESS_RIDER] = value;
        } else {
          biRequest[keys.Critical_ILLNESS_RIDER] = "";
        }
        break;

      case keys.INCOME_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.INCOME_BENEFIT_RIDER] = value;
        } else {
          biRequest[keys.INCOME_BENEFIT_RIDER] = "";
        }
        break;

      case keys.PWB_RIDER:
        if (value) {
          biRequest[keys.PWB_RIDER] = "1";
          biRequest.PWB = "" + value;
        } else {
          biRequest[keys.PWB_RIDER] = "";
        }
        break;

      case keys.HOSPITALITY_CASH_BENEFIT_RIDER:
        if (value) {
          biRequest[keys.HOSPITALITY_CASH_BENEFIT_RIDER] = value;
        } else {
          biRequest[keys.HOSPITALITY_CASH_BENEFIT_RIDER] = "";
        }
        break;

      case keys.FAMILY_BENEFIT_INCOME_OPTION:
        biRequest[keys.FAMILY_BENEFIT_INCOME_OPTION] = value;
        break;

      case keys.INCOME_BENEFIT_PAYOUT_FREQUENCY:
        biRequest[keys.INCOME_BENEFIT_PAYOUT_FREQUENCY] = value;
        let targetAmount = Number(biRequest?.TargetAmount);
        // switch (value) {
        //   case "1":
        //   case 1:
        //   case "5":
        //   case 5:
        //       biRequest["TargetAmount"] = Math.round(targetAmount * 1);
        //       biRequest["ReverseCalcFlag"] = 1;
        //       biRequest[keys.PREMIUM] = 0;
        //       break;
        //   case "2":
        //   case 2:
        //       biRequest["TargetAmount"] = Math.round(targetAmount * 2);
        //       biRequest["ReverseCalcFlag"] = 1;
        //       biRequest[keys.PREMIUM] = 0;
        //       break;
        //   case "3":
        //   case 3:
        //       biRequest["TargetAmount"] = Math.round(targetAmount * 4);
        //       biRequest["ReverseCalcFlag"] = 1;
        //       biRequest[keys.PREMIUM] = 0;
        //       break;
        //   case "4":
        //   case 4:
        //       biRequest["TargetAmount"] = Math.round(targetAmount * 12);
        //       biRequest["ReverseCalcFlag"] = 1;
        //       biRequest[keys.PREMIUM] = 0;
        //       break;
        // }
        break;

      case keys.INCOME_BENEFIT_PAYOUT_TYPE:
        biRequest[keys.INCOME_BENEFIT_PAYOUT_TYPE] = value;
        break;

      case keys.INCOME_BENEFIT_DURATION:
        biRequest[keys.INCOME_BENEFIT_DURATION] = value;
        break;
    }
    OptimizationStore.updateBiRequest(productId, biRequest, productData.data.family_product_id, key, value);
    // let errorMessage = Validator.valid(formRef, biData)

    // if (errorMessage.length > 0) {
    //   return
    // }

    // OptimizationStore.updateStoreRequestData(productId, biRequest, key, value)
  };

  getAdbList = () => {
    let list = [];
    for (let index = 0; index <= 100; index = index + 5) {
      if (index != 100) {
        list.push({
          id: index == 0 ? 1 : index,
          label: (index == 0 ? 1 : index) + " L",
        });
      } else {
        list.push({
          id: index,
          label: 1 + " Cr.",
        });
      }
    }
    return list;
  };

  getAtpdList = () => {
    return this.benefitAmountList(100000, 1000000);
  };

  getCriticalIllnessList = () => {
    return this.benefitAmountList(100000, 500000);
  };

  getIncomeBenefit = () => {
    let initialValue = 75000;
    let values = [];

    for (let i = 1; i <= 8; i++) {
      values.push({ id: i * initialValue, label: i * initialValue });
    }

    return values;
  };

  benefitAmountList = (min, max) => {
    let values = [];

    max = Math.round(max / 100000);
    for (let i = 1; i <= max; i++) {
      values.push({ id: i, label: i + " L" });
    }
    return values;
  };

  fetchPolicyTimeline(element, frequencyAIP, amountAIP) {
    let premiumAmountAIP = "";
    switch (frequencyAIP.Value) {
      case "1":
        premiumAmountAIP = "" + amountAIP * 1;
        break;
      case "2":
        premiumAmountAIP = "" + amountAIP * 2;
        break;
      case "3":
        premiumAmountAIP = "" + amountAIP * 4;
        break;
      case "4":
        premiumAmountAIP = "" + amountAIP * 12;
        break;
    }
    let data = {
      youPay: Math.round(premiumAmountAIP),
      youGet: Math.round(element.TOTAL_SB_G_BS_2) + Math.round(element.TOTAL_MB_G_BS_2),
      lifeCover: Math.round(element.TOTAL_DB_G_BS_2),
      isMilestone: Math.round(element.TOTAL_MB_G_BS_2) !== 0,
      premiumAmt: Math.round(element.ANN_PREM),
    };
    return data;
  }

  fetchPolicyTimeline(element, frequencyAIP, amountAIP) {
    let premiumAmountAIP = "";
    switch (frequencyAIP.Value) {
      case "1":
        premiumAmountAIP = "" + amountAIP * 1;
        break;
      case "2":
        premiumAmountAIP = "" + amountAIP * 2;
        break;
      case "3":
        premiumAmountAIP = "" + amountAIP * 4;
        break;
      case "4":
        premiumAmountAIP = "" + amountAIP * 12;
        break;
    }
    let data = {
      youPay: Math.round(premiumAmountAIP) || 0,
      youGet: Math.round(element.TOTAL_SB_G_BS_2) + Math.round(element.TOTAL_MB_G_BS_2) || 0,
      lifeCover: Math.round(element.TOTAL_DB_G_BS_2) || 0,
      isMilestone: Math.round(element.TOTAL_MB_G_BS_2) !== 0,
      premiumAmt: Math.round(element.ANN_PREM) || 0,
    };
    return data;
  }

  newPolicyTimeLineData(element, inputString, index, productName, finalYearIndex) {
    const { MODAL_PREM, MATURITY_BEN, DEATH_BENEFIT, INCOME_BEN_PAY_OUT, ANN_PREM_YEARLY } = element;

    let IncomeBenefitPayoutFrequency = parseInt(inputString.find((item) => item?.Key === "@INC_BEN_PAY_FREQ")?.Value);
    let yearlyInputAmount = 0;
    let data = {};

    switch (productName) {
      case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM:
      case ProductConstants.PREMIER_GUARANTEED_INCOME_LUMPSUM_FIB:
        // yearlyInputAmount = this.fetchYearlyInputAmount(IncomeBenefitPayoutFrequency, ANN_PREM_YEARLY)
        data = {
          youPay: Math.round(ANN_PREM_YEARLY),
          youGet: Math.round(MATURITY_BEN),
          lifeCover: Math.round(DEATH_BENEFIT),
        };

        return data;

      case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME:
      case ProductConstants.PREMIER_GUARANTEED_SHORT_TERM_INCOME_FIB:
        // yearlyInputAmount = this.fetchYearlyInputAmount(IncomeBenefitPayoutFrequency, MODAL_PREM)

        data = {
          youPay: Math.round(ANN_PREM_YEARLY),
          youGet: Math.round(INCOME_BEN_PAY_OUT),
          lifeCover: Math.round(DEATH_BENEFIT),
        };

        return data;

      case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME:
      case ProductConstants.PREMIER_GUARANTEED_LONG_TERM_INCOME_FIB:
        // yearlyInputAmount = this.fetchYearlyInputAmount(IncomeBenefitPayoutFrequency, MODAL_PREM)
        data = {
          youPay: Math.round(ANN_PREM_YEARLY),
          youGet: Math.round(INCOME_BEN_PAY_OUT) + (index == finalYearIndex ? Math.round(MATURITY_BEN) : 0),
          lifeCover: Math.round(DEATH_BENEFIT),
        };

        return data;

      case ProductConstants.PREMIER_GUARANTEED_RETIREMENT_INCOME:
        // yearlyInputAmount = this.fetchYearlyInputAmount(IncomeBenefitPayoutFrequency, MODAL_PREM)

        data = {
          youPay: Math.round(ANN_PREM_YEARLY),
          youGet: Math.round(INCOME_BEN_PAY_OUT) + (index == finalYearIndex ? Math.round(MATURITY_BEN) : 0),
          lifeCover: Math.round(DEATH_BENEFIT),
        };

        return data;

      default:
        break;
    }

    console.log("element", element, inputString, productName);
  }

  fetchYearlyInputAmount(incomeBenefitPayoutFrequency, modalPrem) {
    let yearlyInputAmount = 0;

    if (incomeBenefitPayoutFrequency === 1) {
      yearlyInputAmount = modalPrem * Constants.ANNUAL_MODAL_FACTOR;
    } else if (incomeBenefitPayoutFrequency === 2) {
      yearlyInputAmount = modalPrem * Constants.SEMI_ANNUAL_MODAL_FACTOR;
    } else if (incomeBenefitPayoutFrequency === 3) {
      yearlyInputAmount = modalPrem * Constants.QUATERLY_MODAL_FACTOR;
    } else if (incomeBenefitPayoutFrequency === 4) {
      yearlyInputAmount = modalPrem * Constants.MONTHLY_MODAL_FACTOR;
    }

    return yearlyInputAmount;
  }

  fetchIncomePayoutDuration(incomePayoutDuration) {
    switch (Number(incomePayoutDuration)) {
      case 10:
        return 8;

      case 11:
        return 10;

      case 12:
        return 12;

      case 13:
        return 15;

      case 14:
        return 20;

      case 15:
        return 25;

      case 16:
        return 30;

      default:
        return Number(incomePayoutDuration)
    }
  }
}

export default new Utils();
