import Utils from './utils';
import * as keys from './keys';
import ProductConstants from 'constants/ProductConstants';


const initialDefaults = (formRef, data) => {
  let productData = data.data
  let productName = productData.product_name
  let biResponse = productData.parsedResponse;
  let biRequest = productData.bi_request;
  let LumpsumBenefit = biRequest.LumpsumBenefit
  let frequency = biRequest.INPUT_MODE
  let pt = biRequest.PR_PT
  let ppt = biRequest.PR_PPT
  let familyIncomeBenefitOption = biRequest.FamilyIncomeBenefitOption
  let incomeBenefitPayOutFrequency = biRequest.IncomeBenefitPayoutFrequency
  let incomeBenefitPayOutType = biRequest.IncomeBenefitPayoutType
  let incomeBenefitPayoutDuration = biRequest.IncomeBenefitPayoutDuration
  let annualisedPremium = biRequest.PR_ANNPREM


  let wopRider = String(biRequest.WaiverOfPremiumRider)
  let adbRider = String(biRequest[keys.ADB_RIDER])
  //  let pptList = Utils.getPremiumTermList(biRequest.PR_PT, biRequest.LI_ENTRY_AGE)

  let IncomeOption = biRequest.IncomeOption
  let incomeStartOption = biRequest.IncomeStartOption
  let incomeDuration = biRequest.IncomeDuration
  //  incomeStartOption
  // IncomeOption
  // incomeDuration

  // ! PPT (done)
  formRef.$('PPT').value = { id: ppt, label: ppt };
  // ! end of PPT

  // ! income option (done)
  
  switch (IncomeOption) {
    case '5':
      formRef.$('income-option').set('value', { label: 'Flexible Income', id: '5' })
      break;

    case '6':
      formRef.$('income-option').set('value', { label: 'Large income Benefit', id: '6' })
      break;
  }
  // ! end of income option


  // ! income start option (done)
  switch (incomeStartOption) {

    case '7':
      formRef.$('income-start-option').set('value', { label: '2', id: '7' })
      break;

    case '8':
      formRef.$('income-start-option').set('value', { label: '5', id: '8' })
      break;

    case '9':
      formRef.$('income-start-option').set('value', { label: '6', id: '9' })
      break;

    case '10':
      formRef.$('income-start-option').set('value', { label: '7', id: '10' })
      break;

    case '11':
      formRef.$('income-start-option').set('value', { label: '8', id: '11' })
      break;

    case '12':
      formRef.$('income-start-option').set('value', { label: '9', id: '12' })
      break;

    case '13':
      formRef.$('income-start-option').set('value', { label: '10', id: '13' })
      break;

    case '14':
      formRef.$('income-start-option').set('value', { label: '11', id: '14' })
      break;

    case '15':
      formRef.$('income-start-option').set('value', { label: '12', id: '15' })
      break;

    case '16':
      formRef.$('income-start-option').set('value', { label: '13', id: '16' })
      break;

    case '17':
      formRef.$('income-start-option').set('value', { label: '14', id: '17' })
      break;

    case '18':
      formRef.$('income-start-option').set('value', { label: '15', id: '18' })
      break;

    case '19':
      formRef.$('income-start-option').set('value', { label: '18', id: '19' })
      break;

    case '20':
      formRef.$('income-start-option').set('value', { label: '20', id: '20' })
      break;

    case '21':
      formRef.$('income-start-option').set('value', { label: '22', id: '21' })
      break;

  }


  //  ! income duration
  switch (incomeDuration) {
    case '24':
      formRef.$('income-duration').set('value', { label: '10', id: '24' });
      break;

    case '25':
      formRef.$('income-duration').set('value', { label: '15', id: '25' })
      break;

    case '26':
      formRef.$('income-duration').set('value', { label: '20', id: '26' })
      break;

    case '27':
      formRef.$('income-duration').set('value', { label: '25', id: '27' })
      break;

    case '28':
      formRef.$('income-duration').set('value', { label: '30', id: '28' });
      break;

    case '29':
      formRef.$('income-duration').set('value', { label: '35', id: '29' });
      break;

    default:
      break;
  }

  if (
    productName === ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS ||
    productName === ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB ||
    productName === ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED ||
    productName === ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB ||
    productName === ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED ||
    productName === ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS
  ) {
    let value = Number(formRef.$('income-start-option').value.label) + Number(formRef.$('income-duration').value.label) - 1
    formRef.$("PT").set("disabled", true)
    formRef.$("PT").set("value", { id: value, label: value })
  }

  if (productName === ProductConstants.GUARANTEED_INCOME_STAR ||
    productName === ProductConstants.GUARANTEED_INCOME_STAR_FIB ||
    productName === ProductConstants.POS_GUARANTEED_INCOME_STAR) {
    formRef.$("PT").set("disabled", true)
    if(Number(IncomeOption) === 6) {
      formRef.$("PT").set("value", { id: "40", label: "40" })
    } else {
      let pt = Number(formRef.$('income-start-option').value.label) + Number(formRef.$('income-duration').value.label) - 1
      formRef.$("PT").set("value", { id: pt, label: pt })
    }
  }

  if (productName === ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED) {
    formRef.$("PT").set("disabled", true)
    formRef.$("PT").set("value", { label: "40", id: "40" })
  }


  // ! LumpsumBenefit (done)
  switch (LumpsumBenefit) {

    case '22':
      formRef.$('lump-sum-benefit').set('value', { label: 'Yes', id: '22' })
      break;
    case '23':
      formRef.$('lump-sum-benefit').set('value', { label: 'No', id: '23' })
      break;
    default:
      break;
  }
  // ! end of LumpsumBenefit

  // ! payment-frequency/input mode (done)
  switch (frequency?.toString()) {
    case '1':
      formRef.$('payment-frequency').set('value', {
        id: '1',
        label: 'Annual'
      })
      break;
    case '2':
      formRef.$('payment-frequency').set('value', {
        id: '2',
        label: 'Semi-Annual'
      })
      break;
    case '3':
      formRef.$('payment-frequency').set('value', {
        id: '3',
        label: 'Quarterly'
      })
      break;
    case '4':
      formRef.$('payment-frequency').set('value', {
        id: '4',
        label: 'Monthly'
      })
      break;

    default:
      break;
  }
  // ! end of payment-frequency


  // ! family income benefit (done)
  switch (Number(familyIncomeBenefitOption)) {

		case 3:
			formRef.$('family-income-benefit-option').set('value',false)
			break;
		case 4:
			formRef.$('family-income-benefit-option').set('value',true)

			formRef.$('family-income-benefit-option-dropdown').set('value', { label: 'Family Income Benefit on Critical Illness Critical Illness and Death', id: '4' })
			break;
		case 6:
			formRef.$('family-income-benefit-option').set('value',true)

			formRef.$('family-income-benefit-option-dropdown').set('value', { label: 'Family Income Benefit on Death', id: '6' })
			break;

		default:
			break;

	}

  switch (data.productName) {
		case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:
		case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:
		case ProductConstants.GUARANTEED_INCOME_STAR_FIB:
      formRef.$('PayorWaiverBenefitRider').set('extra', { ...formRef.$('PayorWaiverBenefitRider').extra, hide: true })
      formRef.$('WaiverOfPremiumRider').set('extra', { ...formRef.$('WaiverOfPremiumRider').extra, hide: true })
			formRef.$('family-income-benefit-option').set('disabled', true)
			break;

		default:

      formRef.$("family-income-benefit-option").set("extra", {
				...formRef.$("family-income-benefit-option").extra,
				hide :true
			})
			formRef.$('family-income-benefit-option').set('disabled', false)
			break
	}

  // ! end of family income benefit


  // ! incomeBenefitPayOutFrequency (done)
  switch (incomeBenefitPayOutFrequency) {

    case '32':
      formRef.$('income-benefit-pay-out-frequency').set('value', { label: 'Annual', id: '32' })
      break;
    case '33':
      formRef.$('income-benefit-pay-out-frequency').set('value', { label: 'Semi-Annual', id: '33' })
      break;
    case '34':
      formRef.$('income-benefit-pay-out-frequency').set('value', { label: 'Quarterly', id: '34' })
      break;
    case '35':
      formRef.$('income-benefit-pay-out-frequency').set('value', { label: 'Monthly', id: '35' })
      break;

    default:
      break;
  }
  // ! end of incomeBenefitPayOutFrequency 


  // ! incomeBenefitPayOutType (done)
  switch (incomeBenefitPayOutType) {

    case '5':
      formRef.$('income-benefit-pay-out-type').set('value', { label: 'Level', id: '5' })
      break;
    case '6':
      formRef.$('income-benefit-pay-out-type').set('value', { label: 'Increasing', id: '6' })
      break;


    default:
      break;
  }
  // ! end of incomeBenefitPayOutType


  let premiumAmount = ''
  switch (frequency) {
    case '1':
      premiumAmount = annualisedPremium;
      break;
    case '2':
      premiumAmount = Number(annualisedPremium) / 2;
      break;
    case '3':
      premiumAmount = Number(annualisedPremium) / 4;
      break;
    case '4':
      premiumAmount = Number(annualisedPremium) / 12;
      break;

    default:
      break;
  }

  //  formRef.$('annualised-premium').set('value', premiumAmount)


  formRef.$("lump-sum-benefit").set("extra", {
    ...formRef.$("lump-sum-benefit").extra,
    optionList: [
      { label: 'Yes', id: '22' },
      { label: 'No', id: '23' }
    ],
  })

  formRef.$("payment-frequency").set("extra", {
    ...formRef.$("payment-frequency").extra,
    optionList: [
      { label: 'Annual', id: '1' },
      { label: 'Semi-Annual', id: '2' },
      { label: 'Quarterly', id: '3' },
      { label: 'Monthly', id: '4' }
    ],
  })

  formRef.$("income-benefit-pay-out-frequency").set("extra", {
    ...formRef.$("income-benefit-pay-out-frequency").extra,
    optionList: [
      { label: 'Annual', id: '32' },
      { label: 'Semi-Annual', id: '33' },
      { label: 'Quarterly', id: '34' },
      { label: 'Monthly', id: '35' }
    ],
  })

  switch (data.productName) {

    case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS:
    case ProductConstants.GUARANTEED_INCOME_STAR_BETTER_INNINGS_FIB:


      formRef.$("PPT").set("extra", {
        ...formRef.$("PPT").extra,
        optionList: [
          { label: '8', id: '8' },
          { label: '10', id: '10' },
          { label: '12', id: '12' }
        ],
      })

      if (ppt === '8') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '9', id: '12' },
            { label: '10', id: '13' },
            { label: '11', id: '14' },
            { label: '18', id: '19' }
          ],
        })
      }

      if (ppt === '10') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '11', id: '14' },
            { label: '12', id: '15' },
            { label: '13', id: '16' },
            { label: '20', id: '20' }
          ],
        })
      }

      if (ppt === '12') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '13', id: '16' },
            { label: '14', id: '17' },
            { label: '15', id: '18' },
            { label: '22', id: '21' }
          ],
        })
      }

      formRef.$('income-benefit-pay-out-type').set('value', '')

      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        isVisible: false
      })


      formRef.$('income-option').set('value', { label: 'Flexible Income', id: '5' })
      formRef.$("income-option").set("disabled", true)

      formRef.$("income-duration").set("extra", {
        ...formRef.$("income-duration").extra,
        optionList: [
          { label: '10', id: '24' },
          { label: '15', id: '25' },
          { label: '20', id: '26' },
          { label: '25', id: '27' },
          { label: '30', id: '28' },
          { label: '35', id: '29' }
        ]
      })

      break;

    case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:
    case ProductConstants.GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED_FIB:

      formRef.$("PPT").set("extra", {
        ...formRef.$("PPT").extra,
        optionList: [
          { label: '5', id: '5' },
          { label: '8', id: '8' },
          { label: '10', id: '10' },
          { label: '12', id: '12' }
        ],
      })

      if (ppt === '5') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '5', id: '8' },
            { label: '6', id: '9' },
            { label: '7', id: '10' },
            { label: '8', id: '11' },
            { label: '15', id: '18' }
          ],
        })
      }

      if (ppt === '8') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '9', id: '12' },
            { label: '10', id: '13' },
            { label: '11', id: '14' },
            { label: '18', id: '19' }
          ],
        })
      }

      if (ppt === '10') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '11', id: '14' },
            { label: '12', id: '15' },
            { label: '13', id: '16' },
            { label: '20', id: '20' }
          ],
        })
      }

      if (ppt === '12') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '13', id: '16' },
            { label: '14', id: '17' },
            { label: '15', id: '18' },
            { label: '22', id: '21' }
          ],
        })
      }


      formRef.$('income-benefit-pay-out-type').set('value', '')

      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        isVisible: false
      })

      formRef.$('income-option').set('value', { label: 'Flexible Income', id: '5' })
      formRef.$("income-option").set("disabled", true)

      if (ppt === '5') {

        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '15', id: '25' },
            { label: '20', id: '26' },
            { label: '25', id: '27' },
            { label: '30', id: '28' },
            { label: '35', id: '29' }
          ]
        })

      } else {

        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '10', id: '24' },
            { label: '15', id: '25' },
            { label: '20', id: '26' },
            { label: '25', id: '27' },
            { label: '30', id: '28' },
            { label: '35', id: '29' }
          ]
        })

      }


      break;

    case ProductConstants.GUARANTEED_INCOME_STAR:
    case ProductConstants.GUARANTEED_INCOME_STAR_FIB:

      formRef.$("PPT").set("extra", {
        ...formRef.$("PPT").extra,
        optionList: [
          { label: '5', id: '5' },
          { label: '8', id: '8' },
          { label: '10', id: '10' },
          { label: '12', id: '12' }
        ],
      })

      if (IncomeOption === '6') {

        formRef.$("income-benefit-pay-out-type").set("extra", {
          ...formRef.$("income-benefit-pay-out-type").extra,
          isVisible: true
        })

        if (ppt === '12') {
          formRef.$('income-start-option').set('value', { label: '15', id: '18' })
        } else if (ppt === '5' || ppt === '8' || ppt === '10') {
          formRef.$('income-start-option').set('value', { label: '10', id: '13' })
        }

        formRef.$("income-start-option").set("disabled", true)

        formRef.$("income-duration").set("value", '')
        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          isVisible: false
        })

      } else if (IncomeOption === '5') {

        if (ppt === '5') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '5', id: '8' },
              { label: '6', id: '9' },
              { label: '7', id: '10' },
              { label: '8', id: '11' },
              { label: '15', id: '18' }
            ],
          })
        }

        if (ppt === '8') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' },
              { label: '9', id: '12' },
              { label: '10', id: '13' },
              { label: '11', id: '14' },
              { label: '18', id: '19' }
            ],
          })
        }

        if (ppt === '10') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' },
              { label: '9', id: '12' },
              { label: '10', id: '13' },
              { label: '11', id: '14' },
              { label: '18', id: '19' }
            ],
          })
        }

        if (ppt === '12') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' },
              { label: '13', id: '16' },
              { label: '14', id: '17' },
              { label: '15', id: '18' },
              { label: '22', id: '21' }
            ],
          })
        }


        if (ppt === '5') {
          formRef.$("income-duration").set("extra", {
            ...formRef.$("income-duration").extra,
            optionList: [
              { label: '10', id: '24' },
              { label: '15', id: '25' },
              { label: '20', id: '26' },
              { label: '25', id: '27' },
              { label: '30', id: '28' },
              { label: '35', id: '29' }
            ]
          })
        } else {
          formRef.$("income-duration").set("extra", {
            ...formRef.$("income-duration").extra,
            optionList: [
              { label: '10', id: '24' },
              { label: '15', id: '25' },
              { label: '20', id: '26' },
              { label: '25', id: '27' },
              { label: '30', id: '28' },
              { label: '35', id: '29' }
            ]
          })
        }
      }



      if (IncomeOption === '5') {
        formRef.$('income-benefit-pay-out-type').set('value', '')

        formRef.$("income-benefit-pay-out-type").set("extra", {
          ...formRef.$("income-benefit-pay-out-type").extra,
          isVisible: false
        })
      } else {
        formRef.$("income-benefit-pay-out-type").set("extra", {
          ...formRef.$("income-benefit-pay-out-type").extra,
          optionList: [
            { label: 'Level', id: '30' },
            { label: 'Increasing', id: '31' }
          ],
        })
      }

      if (IncomeOption === '6') {
        formRef.$('income-benefit-pay-out-frequency').set('value', { label: 'Annual', id: '32' })
        formRef.$("income-benefit-pay-out-frequency").set("disabled", true)
      }

      formRef.$("income-option").set("value", { label: 'Flexible Income', id: 5 })
      formRef.$("income-option").set("extra", {
        ...formRef.$("income-option").extra,
        optionList: [
          { label: 'Flexible Income', id: 5 },
          { label: 'Large income Benefit', id: 6 }
        ],
      })

      Utils.incomeDurationDropDownLogicBasedOnLumpsumOptionAndIncomeStartOption(formRef,ppt,data.productName,LumpsumBenefit,incomeStartOption,data)

      break;

    case ProductConstants.GUARANTEED_INCOME_STAR_MILESTONES_GUARANTEED:


      formRef.$("PPT").set("extra", {
        ...formRef.$("PPT").extra,
        optionList: [
          { label: '8', id: '8' },
          { label: '10', id: '10' },
          { label: '12', id: '12' }
        ],
      })

      if (ppt === '12') {
        formRef.$('income-start-option').set('value', { label: '15', id: '18' })
      } else if (ppt === '8' || ppt === '10') {
        formRef.$('income-start-option').set('value', { label: '10', id: '13' })
      }

      formRef.$("income-start-option").set("disabled", true)

      formRef.$('income-benefit-pay-out-frequency').set('value', { label: 'Annual', id: '32' })
      formRef.$('income-benefit-pay-out-frequency').set('disabled', true)

      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        optionList: [
          { label: 'Level', id: '30' },
          { label: 'Increasing', id: '31' }
        ],
      })

      formRef.$('income-option').set('value', { label: 'Large income Benefit', id: '6' })
      formRef.$("income-option").set("disabled", true)

      formRef.$("income-duration").set("value", '')
      formRef.$("income-duration").set("extra", {
        ...formRef.$("income-duration").extra,
        isVisible: false
      })

      break;

    case ProductConstants.POS_GUARANTEED_INCOME_STAR_CASHBACK_GUARANTEED:


      formRef.$("PPT").set("extra", {
        ...formRef.$("PPT").extra,
        optionList: [
          { label: '5', id: '5' },
          { label: '8', id: '8' },
          { label: '10', id: '10' },
          { label: '12', id: '12' }
        ],
      })

      if (ppt === '5') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '5', id: '8' },
            { label: '6', id: '9' },
            { label: '7', id: '10' },
            { label: '8', id: '11' },
            { label: '15', id: '18' }
          ],
        })
      }

      if (ppt === '8') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '9', id: '12' },
            { label: '10', id: '13' },
            { label: '11', id: '14' }
          ],
        })
      }

      if (ppt === '10') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
            { label: '11', id: '14' }
          ],
        })
      }

      if (ppt === '12') {
        formRef.$("income-start-option").set("extra", {
          ...formRef.$("income-start-option").extra,
          optionList: [
            { label: '2', id: '7' },
            { label: '5', id: '8' },
          ],
        })
      }

      formRef.$('income-benefit-pay-out-type').set('value', '')

      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        isVisible: false
      })

      formRef.$('income-option').set('value', { label: 'Flexible Income', id: '5' })
      formRef.$("income-option").set("disabled", true)

      if (ppt === '5') {
        formRef.$("income-duration").set("value", { label: '15', id: '25' })
        formRef.$("income-duration").set("disabled", true)
      }

      if ((ppt === '8' || ppt === '10' || ppt === '12') && incomeStartOption === '2') {
        formRef.$("income-duration").set("extra", { label: '15', id: '25' })
        formRef.$("income-duration").set("disabled", true)
      }

      if ((ppt === '8' || ppt === '10' || ppt === '12') && (incomeStartOption === '5' || incomeStartOption === '9')) {
        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '10', id: '24' },
            { label: '15', id: '25' }
          ],
        })
      }

      if ((ppt === '8' || ppt === '10') && (incomeStartOption === '10' || incomeStartOption === '11')) {
        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '10', id: '24' },
          ],
        })
      }
      break;

    case ProductConstants.POS_GUARANTEED_INCOME_STAR:


      formRef.$("PPT").set("extra", {
        ...formRef.$("PPT").extra,
        optionList: [
          { label: '5', id: '5' },
          { label: '8', id: '8' },
          { label: '10', id: '10' },
          { label: '12', id: '12' }
        ],
      })

      if (IncomeOption === '5') {

        if (ppt === '5') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '5', id: '8' },
              { label: '6', id: '9' },
              { label: '7', id: '10' },
              { label: '8', id: '11' },
              { label: '15', id: '18' }
            ],
          })
        }

        if (ppt === '8') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' },
              { label: '9', id: '12' },
              { label: '10', id: '13' },
              { label: '11', id: '14' }
            ],
          })
        }

        if (ppt === '10') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' },
              { label: '11', id: '14' }
            ],
          })
        }

        if (ppt === '12') {
          formRef.$("income-start-option").set("extra", {
            ...formRef.$("income-start-option").extra,
            optionList: [
              { label: '2', id: '7' },
              { label: '5', id: '8' }
            ],
          })
        }
      }



      formRef.$('income-benefit-pay-out-type').set('value', '')

      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        isVisible: false
      })

      formRef.$('income-option').set('value', { label: 'Flexible Income', id: '5' })
      formRef.$("income-option").set("disabled", true)

      if (ppt === '5') {
        formRef.$("income-duration").set("value", { label: '15', id: '25' })
        formRef.$("income-duration").set("disabled", true)
      }

      if ((ppt === '8' || ppt === '10' || ppt === '12') && incomeStartOption === '2') {
        formRef.$("income-duration").set("extra", { label: '15', id: '25' })
        formRef.$("income-duration").set("disabled", true)
      }

      if ((ppt === '8' || ppt === '10' || ppt === '12') && (incomeStartOption === '5' || incomeStartOption === '9')) {
        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '10', id: '24' },
            { label: '15', id: '25' }
          ],
        })
      }

      if ((ppt === '8' || ppt === '10') && (incomeStartOption === '10' || incomeStartOption === '11')) {
        formRef.$("income-duration").set("extra", {
          ...formRef.$("income-duration").extra,
          optionList: [
            { label: '10', id: '24' },
          ],
        })
      }

      break;

    case ProductConstants.POS_GUARANTEED_INCOME_STAR_BETTER_INNINGS:


      formRef.$("PPT").set("extra", {
        ...formRef.$("PPT").extra,
        optionList: [
          { label: '8', id: '8' },
          { label: '10', id: '10' },
          { label: '12', id: '12' }
        ],
      })


      if (ppt === '8' || ppt === '10' || ppt === '12') {

        switch (incomeStartOption) {

          case '2':
            formRef.$('income-duration').set('value', { label: '15', id: '25' })
            formRef.$('income-duration').set('disabled', true)
            break;

          case '5':
          case '9':
            formRef.$("income-duration").set("extra", {
              ...formRef.$("income-duration").extra,
              optionList: [
                { label: '10', id: '24' },
                { label: '15', id: '25' }
              ],
            })
            break;

          default:
            break;
        }

      } else if (ppt === '8' || ppt === '10') {
        if (incomeStartOption === '10' || incomeStartOption === '11') {
          formRef.$('income-duration').set('value', { label: '10', id: '24' })
        }
      }

      formRef.$('income-benefit-pay-out-type').set('value', '')

      formRef.$("income-benefit-pay-out-type").set("extra", {
        ...formRef.$("income-benefit-pay-out-type").extra,
        isVisible: false
      })

      formRef.$('income-option').set('value', { label: 'Flexible Income', id: '5' })
      formRef.$("income-option").set("disabled", true)

      formRef.$("income-duration").set("extra", {
        ...formRef.$("income-duration").extra,
        optionList: [
          { label: '10', id: '24' },
          { label: '15', id: '25' },
          { label: '20', id: '26' },
          { label: '25', id: '27' },
          { label: '30', id: '28' },
          { label: '35', id: '29' }
        ],
      })

      break;

    default:

      break;

  }
  if (biRequest.ADBRider) {
    formRef.$('ADB').set('value', biRequest.ADBRider !== '')
    if (biRequest.ADBRider) {
      formRef.$('ADB-dropdown').set('value', biRequest.ADBRider)
    }
  }
  if (biRequest.ATPDRider) {
    formRef.$('ATPDRider').set('value', biRequest.ATPDRider !== '')
    if (biRequest.ATPDRider) {
      formRef.$('ATPDRider-dropdown').set('value', biRequest.ATPDRider)
    }
  }
  if (biRequest.WaiverOfPremiumRider) {
    formRef.$('WaiverOfPremiumRider').set('value', biRequest.WaiverOfPremiumRider !== '')
  }
  if (biRequest.HospitalityCashBenefitRider) {
    formRef.$('HospitalityCashBenefitRider').set('value', biRequest.HospitalityCashBenefitRider !== '')
    if (biRequest.HospitalityCashBenefitRider) {
      formRef.$('HospitalityCashBenefitRider-dropdown').set('value', biRequest.HospitalityCashBenefitRider)
    }
  }
  if (biRequest.CriticalIllnessRider) {
    formRef.$('CriticalIllnessRider').set('value', biRequest.CriticalIllnessRider !== '')
    if (formRef.$('CriticalIllnessRider').value) {
      formRef.$('CriticalIllnessRider-dropdown').set('value', biRequest.CriticalIllnessRider)
    }
  }
  if (biRequest.PayorWaiverBenefitRider) {
    formRef.$('PayorWaiverBenefitRider').set('value', biRequest.PayorWaiverBenefitRider !== '')
    if (formRef.$('PayorWaiverBenefitRider').value) {
      switch (biRequest.PWB) {
        case '1':
          formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
            id: '1',
            label: 'Option 1 (On Death)'
          })
          break;
        case '2':
          formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
            id: '2',
            label: 'Option 2 (CI or ATPD)'
          })
          break;
        case '3':
          formRef.$('PayorWaiverBenefitRider-dropdown').set('value', {
            id: '3',
            label: 'Option 3 (CI or ATPD or Death)'
          })
          break;
        default:
          break;
      }
    }
  }
}

export default initialDefaults