// React Imports
import React from "react"

// Package Imports
import PropTypes from "prop-types"
import Modal from "react-bootstrap/Modal"
import { observer } from "mobx-react"


// project imports
import RetirementModalForm from './retirement-modal-form'

// CSS Imports
import "./add-insured-retirement-pop-up-modal.css"

const AddInsuredRetirementPopUpModal = (props) => {

    return (
    <div className="add-insured-pop-up-div d-flex align-items-center">
      <Modal
        show={props.showRetirementModal}
        backdrop="static"
        onHide={props.hideRetirementPopUpModal}
        as="section"
        className="add-insured-pop-up-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <RetirementModalForm
          hideRetirementPopUpModal={props.hideRetirementPopUpModal}
          relation={props.relation}
          memberName={props.memberName}
          age={props.age}
          openRetirementCalculator={props.openRetirementCalculator}
          getFamilyMemberType={props.getFamilyMemberType}
        />
      </Modal>
    </div>
  )
}

AddInsuredRetirementPopUpModal.propTypes = {
  openRetirementCalculator: PropTypes.func.isRequired,
  hideRetirementPopUpModal:PropTypes.func,
}

export default observer(AddInsuredRetirementPopUpModal)
