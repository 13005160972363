import { observable, toJS, makeObservable } from 'mobx';
import moment from 'moment';

// !dummy Data
import axios from 'axios';
import * as APITypes from "../../../constants/API"
import pragatiData from './pragati-inbound-data';
import QmsStorageKeys from 'constants/QmsStorageKeys';
import qmsDashboardData from './qms-dashboard-data';
import StorageKeys from 'constants/StorageKeys';

class QmsDashboardPageStore {
    qmsListData = {}
    constructor() {
        makeObservable(this, {
            qmsListData: observable
        });
    }

    async savePragatiInBoundData(agentId) {
        localStorage.setItem(QmsStorageKeys.AGENT_ID, agentId)

        const response = await axios(APITypes.PRAGATI_QMS_INBOUND_DATA + `?agent_id=${agentId}`);
        console.log("QMS ::::", response.data);

        localStorage.setItem(QmsStorageKeys.AGENT_NAME, response.data.agent_name)
        localStorage.setItem(QmsStorageKeys.PRAGATI_HOME_URL, response.data.home_url)
        localStorage.setItem(QmsStorageKeys.PRAGATI_POST_CALL_END_POINT_URL, response.data.post_call_end_point)
        localStorage.setItem(QmsStorageKeys.LOGIN_CODE, response.data.login_code)
        localStorage.setItem(QmsStorageKeys.VENDOR, response.data.vendor_code)
        localStorage.setItem(QmsStorageKeys.SOURCE_FROM, "QMS")
        localStorage.setItem(QmsStorageKeys.LOGIN_AGENT_CODE, response.data.login_agent_code)
        localStorage.setItem(QmsStorageKeys.START_APPLICATION_END_POINT_URL, response.data.start_app_end_point)

        let hlData = await this.hlAgentApiCall(response.data.login_agent_code)
        await localStorage.setItem(QmsStorageKeys.AGENT_NAME, hlData.agent_details.agent_name);
        await localStorage.setItem(QmsStorageKeys.AGENT_CODE, hlData.agent_code);
        await this.fetchQmsListData(agentId)
    }

    async hlAgentApiCall(login_agent_code) {
        let hlAgentDetails = await axios.post(APITypes.HL_API, {
            agent_code: login_agent_code,
        });
        return hlAgentDetails.data.response_data?.agentHierarchyResponse
    }

    async fetchQmsListData(agentId) {
        const response = await axios.post(APITypes.GET_QMS_LIST, { agent_id: agentId })
        this.qmsListData = response.data

        // this.qmsListData = qmsDashboardData
    }


    getFamilyMemberName(proposerDetails, showMemberType, journeyData, showJourneyId) {

        try {
            const { salutation, first_name, last_name, middle_name, proposer_member_type } = proposerDetails;

            let proposerName = "";
            if (first_name !== null) {

                if (first_name !== null) {
                    proposerName += first_name + " ";
                }

                if (middle_name !== null) {
                    proposerName += middle_name + " ";
                }

                if (last_name !== null) {
                    proposerName += last_name;
                }

                proposerName.trim();
            }
            if (proposerName === "" && showJourneyId) {
                return `Journey Id - ${journeyData.journey_id}`;
            } else if (proposerName !== "") {
                return `${salutation} ${proposerName}`;

            } else {
                return ""
            }
        } catch (err) {
            return "";
        }
    }

}

export default new QmsDashboardPageStore();
